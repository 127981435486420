import React, { useMemo } from 'react';
import { AutomationBannerWrapper } from './style';
import { Typography } from '@mui/material';
import { ReactComponent as AutomationIcon } from '../../../assets/images/Automation_Icon.svg';
import { LinkedinAutomaticTasksQuantity } from 'crono-fe-common/types/DTO/linkedinAutomaticTasksQuantity';
import {
  CronoExtensionStatus,
  useGetExtensionStatus,
} from 'hooks/services/crono-extension/useGetExtensionStatus';

interface IProps {
  linkedinAutomaticTasks?: LinkedinAutomaticTasksQuantity;
}
const AutomationBanner = ({ linkedinAutomaticTasks }: IProps) => {
  const { data: extensionStatus } = useGetExtensionStatus();
  const isWorkingExtension = useMemo(
    () =>
      extensionStatus
        ? extensionStatus.status ===
          CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE
        : true,
    [extensionStatus],
  );

  return isWorkingExtension && linkedinAutomaticTasks?.inPipeline ? (
    <AutomationBannerWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 18,
          width: 'fit-content',
        }}
      >
        <AutomationIcon />
        <Typography
          fontSize={'14px'}
          lineHeight={'22px'}
          fontWeight={600}
          width="fit-content"
        >
          Please leave Crono opened while performing LinkedIn automations. If
          you close Crono the LinkedIn automations will stop and resume when
          opened again.
        </Typography>
      </div>
    </AutomationBannerWrapper>
  ) : null;
};

export default AutomationBanner;
