import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ProspectImport } from 'crono-fe-common/types/prospectImport';
import { ImportBehavior } from 'crono-fe-common/types/enums/importType';

export interface ImportLeadsRequest {
  data: ProspectImport[];
  importType: ImportBehavior | null;
  fileName: string | null;
  findEmail: boolean;
  findPhone: boolean;
}

export default function useImportLeads() {
  const queryClient = useQueryClient();

  const importLeads = (data: ImportLeadsRequest) => {
    const request: Request = {
      url: `${ENDPOINTS.prospect.importLeads}`,
      config: {
        method: 'post',
        data: {
          data: data.data,
          importType: data.importType,
          fileName: data.fileName,
          findEmail: data.findEmail,
          findPhone: data.findPhone,
        },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, ImportLeadsRequest>(
    importLeads,
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENDPOINTS.import.main]);
        queryClient.invalidateQueries([ENDPOINTS.import.actives]);
      },
    },
  );
}
