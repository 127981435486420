import { TemplateStep } from './enums/templateStep';
import TemplateType from './enums/templateType';
import { Tag } from './tag';

export interface Template {
  id: number;
  userId: number;
  subscriptionId: number;
  title: string;
  subject: string;
  content: string;
  language: string;
  type: TemplateType;
  step?: TemplateStep;
  shared: boolean;
  useCount: number;
  openCount: number;
  answerCount: number;
  templateTags?: Tag[];
  usedForProspect?: boolean | null;
  sequenceStepTemplateId?: number | null;

  isPersonalized?: boolean;
  personalizedTemplateId?: number | null;
}

export enum TemplateVariables {
  PROSPECT_NAME = '{{PROSPECT_NAME}}',
  PROSPECT_SURNAME = '{{PROSPECT_SURNAME}}',
  PROSPECT_TITLE = '{{PROSPECT_TITLE}}',
  COMPANY_NAME = '{{COMPANY_NAME}}',
  USER_NAME = '{{USER_NAME}}',
  USER_SURNAME = '{{USER_SURNAME}}',
  USER_COMPANY_NAME = '{{USER_COMPANY_NAME}}',
}
