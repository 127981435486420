import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const EditableTemplateWrapper = styled.div`
  width: 100%;

  .container-editable-template {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%;
    flex-direction: column;
    overflow: visible;
    padding: 8px;
    gap: 8px;

    .email-subject-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      overflow: hidden;
      gap: 8px;
      .email-subject {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        font-size: 14px;
        color: ${colors.grey11};
        padding: 10px 16px;
        overflow: hidden;

        .email-subject-input {
          all: unset;
          color: ${colors.black};
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }

  .template-editing-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px 8px 4px 8px;

    .cancel-changes-button {
      background: ${colors.grey6} !important;
      border-color: ${colors.grey33};

      &:hover {
        background: ${colors.grey444} !important;
      }
    }
  }

  .edit-zone {
    width: 100%;
    outline: none;
  }

  .toolbar-hidden {
    .ql-toolbar {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
    .quill-editor {
      .ql-container {
        border-bottom: none !important;
      }
    }
  }

  .accordion-text {
    overflow: visible !important;
    max-width: calc(100% - 32px);

    .ql-toolbar {
      // editor(1) - attachments(2) - toolbar(3)
      order: 3;
      border: none !important;
      background: ${colors.grey6} !important;
      max-width: 78%;
      padding: 8px;
    }

    .quill-editor {
      .ql-container {
        width: 100%;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid ${colors.grey3};

        .ql-editor {
          padding: 0 0 8px 0;
          overflow: hidden;
        }
      }
    }

    .variables-picker {
      top: -30px;
      bottom: unset;
    }

    .file-preview {
      padding: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
    .file {
      overflow: hidden;
      display: flex;
      align-items: center;
      height: 30px;
      background: ${colors.callScheduledLightHover};
      border-radius: 8px;
      padding: 8px;
    }
    .delete-attachments-icon {
      cursor: pointer;
    }
  }
`;
