import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'context/auth';
import {
  ExpiredPlanWrapper,
  SignUpWrapper,
} from 'pages/connect/views/main/styles';
import { FeConstants, planFeatures } from 'constants/FeConstants';
import { useFormik } from 'formik';
import { BillingPlan, SingupFormValues, validationSchema } from './model';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import Licenses from './planSteps/licenseAmount';
import Plans from './planSteps/planType';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import useGetSubscriptionProducts from 'hooks/services/subscription/useGetSubscriptionProducts';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { CircularProgress, Typography } from '@mui/material';
import useSiteCheckoutSession, {
  SiteCheckoutSessionInput,
} from 'hooks/services/subscription/useSiteCheckoutSession';
import { useLocation, useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import useStartFreeTrial from 'hooks/services/subscription/useStartFreeTrial';
import useCheckSignUpEnabled from '../../hooks/services/subscription/useCheckSignUpEnabled';
import Logo from 'assets/images/logo_crono.png';
import ImageForExpired from 'assets/images/ImageForExpired.png';
import {
  capitalizeFirstLetter,
  mapSubscriptionToPlanType,
  sortPlans,
} from 'utils/fe-utils';
import UserPickPlanView from './planSteps/planType/userPickPlan';
import { PlanPrices } from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';

export type PlanValues = {
  priceId: string;
  billed: BillingPlan;
  plan: SubscriptionPlanType;
};

export enum Plan {
  FREE_TRIAL = 0,
  STARTER = 1,
  PRO = 2,
  ULTRA = 3,
}

export interface Options {
  features: string[];
  id: string | null;
  subscriptionType: SubscriptionPlanType;
  description: string | null;
  prices: PlanPrices[];
}

const SignUp = () => {
  const analytics = useJuneAnalytics();
  const { user, isLoading: isUserLoading } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [emailFreeTrialError, setEmailFreeTrialError] =
    useState<boolean>(false);

  const token = searchParams.get('token');
  const { data: checkSignUpEnabledResponse, isLoading: isCheckEnabledLoading } =
    useCheckSignUpEnabled(token ?? '');

  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);

  useEffect(() => {
    // Debug/Develop: Comment to force render
    // if (isDev()) return;
    if (!isUserLoading && !isCheckEnabledLoading) {
      if (user) {
        navigate(PATH.HOME);
      }
      if (
        token &&
        checkSignUpEnabledResponse?.data?.data?.enabled === false &&
        checkSignUpEnabledResponse.data?.data?.token?.expired === true
      ) {
        analytics?.track('signup-link-expired', {
          token: token,
        });
        setIsTokenExpired(true);
      } else if (
        !token ||
        checkSignUpEnabledResponse?.data?.data?.enabled === false
      ) {
        analytics?.track('signup-link-not-enabled', {
          token: token,
        });
        navigate(PATH.LOGIN);
      } else {
        const userEmail = checkSignUpEnabledResponse?.data?.data?.token?.email;
        analytics?.track('signup-link-clicked', {
          token: token,
          email: userEmail,
        });
        if (userEmail) {
          formik.values.email = userEmail;
        }
      }
    }
  }, [
    navigate,
    token,
    isUserLoading,
    user,
    isCheckEnabledLoading,
    checkSignUpEnabledResponse,
  ]);

  const { data: subscriptionProducts, isLoading } =
    useGetSubscriptionProducts();

  const {
    mutateAsync: callSiteCheckout,
    error: checkoutError,
    isLoading: checkoutLoading,
  } = useSiteCheckoutSession();

  const {
    mutate: callSiteFreeTrial,
    error: freeTrialError,
    isSuccess: freeTrialSuccess,
    data: startFreeTrialResponse,
    isLoading: loadingStartFreeTrial,
  } = useStartFreeTrial();

  const formik = useFormik<SingupFormValues>({
    initialValues: {
      priceId: '',
      quantity: FeConstants.minLicenseAmount,
      email: '',
      plan: SubscriptionPlanType.FREE_TRIAL,
      billed: 'yearly',
      price: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const siteCheckoutInput: SiteCheckoutSessionInput = {
        priceId: values.priceId,
        quantity: values.quantity,
        email: values.email,
      };

      callSiteCheckout(siteCheckoutInput)
        .then((res) => {
          const url = res.data?.data.url;
          if (url) window.open(url, '_self');
        })
        .catch((_) => {
          console.log({ checkoutError });
        });

      if (analytics) {
        analytics
          .identify(values.email, {
            email: values.email,
          })
          .then((_) => {
            analytics.track('sign-up-self-service', {
              quantity: values.quantity,
              plan: values.plan,
              billed: values.billed,
              email: values.email,
            });
          });
      }
    },
  });

  const handleFreeTrial = () => {
    if (!formik.values.email) {
      setEmailFreeTrialError(true);
      return;
    }
    setEmailFreeTrialError(false);

    const siteFreeTrialInput = {
      // quantity: formik.values.quantity,
      email: formik.values.email ?? '',
      signupToken: token ?? '',
    };

    callSiteFreeTrial(siteFreeTrialInput);
  };

  useEffect(() => {
    if (startFreeTrialResponse?.data) {
      const createPasswordToken = startFreeTrialResponse.data;
      if (analytics) {
        analytics
          .identify(formik.values.email, {
            email: formik.values.email,
          })
          .then((_) => {
            analytics.track('sign-up-free-trial', {
              email: formik.values.email,
            });
          });
      }
      const encodedQueryParams = new URLSearchParams({
        email: formik.values.email,
        token: createPasswordToken,
      });
      navigate(`${PATH.CREATE_PASSWORD}?${encodedQueryParams.toString()}`);
    }
  }, [startFreeTrialResponse]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (freeTrialSuccess) {
      timeout = setTimeout(() => {
        navigate(PATH.SUCCESS_SIGN_UP);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [freeTrialSuccess]);

  useConditionalSnackBar([
    {
      condition: !!checkoutError,
      message: getError(checkoutError) ?? 'Error completing subscription',
      severity: 'error',
    },
    {
      condition: !!freeTrialError,
      message: getError(freeTrialError) ?? 'Error completing free trial',
      severity: 'error',
    },
  ]);

  const plans = useMemo(() => {
    if (
      subscriptionProducts?.data?.data &&
      checkSignUpEnabledResponse?.data?.data?.token
    ) {
      if (!checkSignUpEnabledResponse.data.data.token.onlyPay) {
        return subscriptionProducts.data.data;
      } else {
        return subscriptionProducts.data.data.filter(
          (s) => s.subscriptionType !== SubscriptionPlanType.FREE_TRIAL,
        );
      }
    }

    // Test / Debug
    // return subscriptionProducts?.data?.data;
    return [];
  }, [subscriptionProducts, checkSignUpEnabledResponse]);

  const [showPlanOptions, setShowPlansOptions] = useState<boolean>(true);

  // shared logic for Plans and UserPickPlanView
  // const [searchParams] = useSearchParams();
  const [billingPlan, setBillingPlan] = useState<BillingPlan>('yearly');

  // URL params may change from where this view is called
  // signUp?subscription=[Starter|Pro|Ultra]&pricing=[monthly|yearly]
  const subscription = searchParams.get('subscription') ?? undefined;
  const pricing = searchParams.get('pricing') ?? undefined;
  const [selectedPlan, setSelectedPlan] = useState<number>(
    mapSubscriptionToPlanType(capitalizeFirstLetter(subscription)) ??
      Plan.FREE_TRIAL,
  );

  const options: Options[] = useMemo(() => {
    const plansOptions = (plans ?? [])?.map((plan) => {
      return {
        ...plan,
        features:
          planFeatures[plan.subscriptionType as keyof typeof planFeatures],
      };
    });
    return sortPlans(plansOptions);
  }, [plans]);

  return (
    <SignUpWrapper>
      {isLoading || isCheckEnabledLoading ? (
        <FlexDiv>
          <CircularProgress />
        </FlexDiv>
      ) : isTokenExpired ? (
        <ExpiredPlanWrapper>
          <div className="logo-container">
            <img src={Logo} className="logo" alt="Crono Logo" />
          </div>
          <img src={ImageForExpired} style={{ width: 696, height: 273 }} />
          <Typography
            fontSize={24}
            lineHeight={'30px'}
            fontWeight={700}
            whiteSpace={'pre-wrap'}
            textAlign={'center'}
          >
            This link has expired.
            <br />
            Please request a new free trial link.
          </Typography>
        </ExpiredPlanWrapper>
      ) : showPlanOptions ? (
        <UserPickPlanView
          formik={formik as any}
          subscriptionProducts={plans}
          close={() => setShowPlansOptions(false)}
          billingPlan={billingPlan}
          setBillingPlan={setBillingPlan}
          setSelectedPlan={setSelectedPlan}
          options={options}
          subscription={subscription}
          pricing={pricing}
          selectedPlan={selectedPlan}
        />
      ) : (
        <>
          <Plans
            formik={formik as any}
            subscriptionProducts={plans}
            close={() => setShowPlansOptions(false)}
            billingPlan={billingPlan}
            setBillingPlan={setBillingPlan}
            setSelectedPlan={setSelectedPlan}
            options={options}
            subscription={subscription}
            pricing={pricing}
            selectedPlan={selectedPlan}
          />
          <Licenses
            formik={formik as any}
            handleFreeTrial={handleFreeTrial}
            checkoutLoading={checkoutLoading || loadingStartFreeTrial}
            freeTrialError={emailFreeTrialError}
            setEmailFreeTrialError={setEmailFreeTrialError}
          />
        </>
      )}
    </SignUpWrapper>
  );
};

export default SignUp;
