import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { IntegrationsOnboardingDTO } from 'crono-fe-common/types/DTO/integrationsOnboardingDTO';

export default function usePatchUserOnboardingIntegrations() {
  const queryClient = useQueryClient();
  const patchUserIntegrations = (
    userIntegrations: IntegrationsOnboardingDTO,
  ) => {
    const request: Request = {
      url: ENDPOINTS.user.onboarding.integrations,
      config: {
        method: 'patch',
        data: userIntegrations,
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    IntegrationsOnboardingDTO
  >(patchUserIntegrations, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.user.onboarding.integrations]);
    },
  });

  return { isSuccess, isError, mutate, ...rest };
}
