import { useState } from 'react';
import { CircularProgress, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { AddOpportunityFormWrapper } from 'pages/opportunities/addOpportunity/formComponent/style';

interface IProps {
  close?: () => void;
  handleSubmit: (newName: string) => void;
  createLoading?: boolean;
  existingStatuses?: string[];
}

interface IFormik {
  name: string;
}

const AddStatusForm = ({
  close,
  handleSubmit,
  createLoading,
  existingStatuses,
}: IProps) => {
  const [errorState, setErrorState] = useState<string>('');

  const [nameValue, setNameValue] = useState<string>('');

  const submitName = (name: string) => {
    if (name && existingStatuses?.includes(name.trim())) {
      setErrorState(
        'This name can’t be saved because such status name already exists',
      );
    } else {
      setErrorState('');
      handleSubmit(name);
    }
  };

  return (
    <AddOpportunityFormWrapper>
      <div
        className="form-add-container"
        style={{ paddingBottom: '20px', gap: '60px' }}
        onKeyDown={(ev) => {
          if (ev.code === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
            submitName(nameValue);
          }
        }}
      >
        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Status name:
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              placeholder="Type status name..."
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              name="name"
              value={nameValue}
              onChange={(ev) => {
                setNameValue(ev.target.value);
                setErrorState('');
              }}
              fullWidth
            />
            {errorState && (
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                lineHeight={'22px'}
                color={colors.inactive}
              >
                {errorState}
              </Typography>
            )}
          </div>
        </div>

        <FlexDiv
          width="fit-content"
          height="fit-content"
          style={{ alignSelf: 'flex-end' }}
        >
          <CancelButton
            style={{ width: '120px' }}
            onClick={() => {
              if (close) close();
            }}
          >
            Cancel
          </CancelButton>
          {createLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <MainButton
              onClick={() => submitName(nameValue)}
              style={{ width: '120px' }}
              disabled={nameValue === null || nameValue === ''}
            >
              Create
            </MainButton>
          )}
        </FlexDiv>
      </div>
    </AddOpportunityFormWrapper>
  );
};

export default AddStatusForm;
