import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';
import { DeleteSequenceProspectDTO } from 'crono-fe-common/types/DTO/deleteSequenceProspectDTO';

export default function useDeleteSequenceProspect() {
  const queryClient = useQueryClient();

  const deleteSequenceProspect = (data: DeleteSequenceProspectDTO) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.prospectDelete}`,
      config: {
        method: 'delete',
        data: data,
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, DeleteSequenceProspectDTO>(
    deleteSequenceProspect,
    {
      onSettled: (result, error, variables) => {
        CronoAutomaticTaskExecutor.stopDelay();
        queryClient.invalidateQueries([ENDPOINTS.account.main]);
        queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
        queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey
              .toString()
              .startsWith(ENDPOINTS.prospect.search.main),
        });
        queryClient.invalidateQueries([ENDPOINTS.task.search]);
        queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
        queryClient.invalidateQueries([ENDPOINTS.task.total]);
        queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.prospect]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      },
    },
  );
}
