import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { ProspectSearch } from 'crono-fe-common/types/DTO/prospectSearch';
import { Prospect } from 'crono-fe-common/types/prospect';

export default function useSearchProspects(searchParameters: ProspectSearch) {
  const { selected, ...rest } = searchParameters;
  const request: Request = {
    url: `${ENDPOINTS.prospect.search.main}`,
    config: {
      method: 'post',
      data: { data: rest },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.prospect.search.main, searchParameters],

    queryFn: () => client(request),
  };

  return useQuery<ResponsePaginated<Prospect[]>, CronoError>(requestConfig);
}
