import { CircularProgress } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { TabValue } from 'pages/home';

const StrategyInterceptor = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PATH.HOME, { state: { initialTab: TabValue.STRATEGIES } });
  });
  return (
    <>
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    </>
  );
};

export default StrategyInterceptor;
