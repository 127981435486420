import { OpportunityActivities } from './DTO/opportunityViewResponseDTO';
import { LogCall } from './logCall';
import { LogEmail } from './logEmail';
import { LogLinkedin } from './logLinkedin';
import { Prospect } from './prospect';

export interface Task {
  id: number;
  userId: number;
  owned: boolean;
  objectId: string;
  accountId: string | null;
  prospectId: string | null;
  prospect: Prospect;
  whoId: string;
  whatId: string | null;
  callType: string | null;
  activityDate: string;
  createdDate: string;
  subject: string;
  description: string;
  taskSubtype: string;
  callLog: LogCall | null;
  emailLog: LogEmail | null;
  linkedinLog: LogLinkedin | null;
  user: string | null;

  opportunityId: string | null;
  lastModifiedDate: Date;
  account: {
    objectId: string | null;
    name: string | null;
  } | null;
  opportunity: OpportunityActivities | null;
}

export function isTask(object: any): object is Task {
  return 'taskSubtype' in object;
}
