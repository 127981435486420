import React, { useEffect, useState } from 'react';
import { NotesBoxWrapper } from './style';
import { useSelectedAccount } from 'pages/home/context/account';
import useNotes from 'hooks/services/notes/useNotes';
import {
  Button,
  CircularProgress,
  IconButton,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { colors } from 'crono-fe-common/theme';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import useDeleteNote from 'hooks/services/notes/useDeleteNote';
import { useConfirmModal } from 'context/confirmModal';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { ReactComponent as NoteIcon } from 'crono-fe-common/icons/Note.svg';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import useCreateNote from 'hooks/services/notes/useCreateNote';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { ReactComponent as NoNotes } from 'assets/images/No_notes.svg';
import { useJuneAnalytics } from 'context/june';
import usePatchNote from 'hooks/services/notes/usePatchNote';
import IconBackArrow from 'crono-fe-common/icons/Icon-Back-Arrow';
import { useAuth } from 'context/auth';

type NoteAction = 'create' | 'edit';

interface IProps {
  opportunityId?: string | null;
}

const NotesBox = ({ opportunityId }: IProps) => {
  const { hasOpportunities } = useAuth();
  const { selectedAccountId } = useSelectedAccount();
  const { data: notesResponse, isLoading: loadingNotes } = useNotes(
    selectedAccountId,
    opportunityId,
  );
  const notes = notesResponse?.data?.data;

  const {
    mutate: deleteNote,
    error: errorDeleteNote,
    isSuccess: noteDeleted,
  } = useDeleteNote();

  const {
    mutate: patchNote,
    error: errorPatchNote,
    isSuccess: notePatched,
    isLoading: isLoadingPatchNote,
  } = usePatchNote();

  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteNote = (
    noteId: number,
    cancelFunction: (...args: unknown[]) => unknown = () => null,
  ) => {
    openConfirmModal({
      title: 'Are you sure you want to delete this note?',
      text: 'The note will be deleted forever.',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        deleteNote(noteId);
      },
      cancelFunction,
    });
  };

  const handleExitEditMode = () => {
    openConfirmModal({
      title: 'Are you sure you want to discard changes?',
      text: 'Changes to the note will be lost.',
      cancelText: 'Cancel',
      confirmText: 'Discard',
      confirmFunction: resetNoteActionState,
      cancelFunction: () => null,
      type: 'info',
    });
  };

  const [noteAction, setNoteAction] = useState<{
    action: NoteAction;
    noteId?: number;
  } | null>(null);

  const [noteDescription, setNoteDescription] = useState<string>('');

  const {
    mutate: createNote,
    isSuccess: noteCreated,
    error,
    isLoading: isLoadingCreateNote,
  } = useCreateNote();
  const analytics = useJuneAnalytics();

  const handleEditNote = (noteId: number) => {
    if (notes) {
      const note = notes.find((note) => note.id === noteId);
      if (!note) return;
      setNoteDescription(note.description ?? '');
      setNoteAction({ action: 'edit', noteId: note.id });
    }
  };

  const handleSubmitNoteAction = () => {
    if (!selectedAccountId) return;
    if (noteAction?.action === 'edit' && noteAction.noteId) {
      if (!noteDescription) {
        handleDeleteNote(noteAction.noteId, () => {
          const note = notes?.find((note) => note.id === noteAction.noteId);
          setNoteDescription(note?.description ?? '');
        });
      } else {
        patchNote({
          noteId: noteAction.noteId,
          description: noteDescription,
        });
      }
    } else {
      createNote({
        accountId: selectedAccountId,
        creationDatetime: new Date(),
        description: noteDescription,
        prospectIds: null,
        opportunityId: opportunityId,
      });
      if (analytics) {
        analytics.track('create-note', {
          accountId: selectedAccountId,
        });
      }
    }
  };

  const resetNoteActionState = () => {
    setNoteDescription('');
    setNoteAction(null);
  };
  useEffect(() => {
    if (noteCreated || notePatched || noteDeleted) {
      resetNoteActionState();
    }
  }, [noteCreated, notePatched, noteDeleted]);

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) ?? 'Error creating note',
      severity: 'error',
    },
    {
      condition: !!errorDeleteNote,
      message: getError(errorDeleteNote) ?? 'Error deleting note',
      severity: 'error',
    },
    {
      condition: !!errorPatchNote,
      message: getError(errorPatchNote) ?? 'Error updating note',
      severity: 'error',
    },
    {
      condition: noteCreated,
      message: 'Note created',
      severity: 'success',
    },
    {
      condition: noteDeleted,
      message: 'Note deleted',
      severity: 'success',
    },
    {
      condition: notePatched,
      message: 'Note updated',
      severity: 'success',
    },
  ]);

  return (
    <NotesBoxWrapper>
      {loadingNotes ? (
        <FlexDiv>
          <CircularProgress />
        </FlexDiv>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingRight: 12,
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={600}
              lineHeight={'24px'}
              style={{ display: 'flex' }}
            >
              Notes{' '}
              {!noteAction && notes?.length ? (
                <span className="count-number">{notes?.length}</span>
              ) : null}
            </Typography>
            {!noteAction ? (
              <Button
                className="new-note-button"
                onClick={() => setNoteAction({ action: 'create' })}
              >
                <NoteIcon />
                New note
              </Button>
            ) : (
              <div className="new-note-buttons">
                {noteAction.action === 'edit' ? (
                  <IconButton
                    className={'cancel-edit-note-icon'}
                    onClick={() => {
                      const note = notes?.find(
                        (note) => note.id === noteAction?.noteId,
                      );

                      if (note && note.description != noteDescription) {
                        handleExitEditMode();
                      } else {
                        resetNoteActionState();
                      }
                    }}
                  >
                    <IconBackArrow color={colors.black} strokeWidth={0.5} />
                  </IconButton>
                ) : (
                  <IconButton
                    className={'cancel-create-note-icon'}
                    onClick={resetNoteActionState}
                  >
                    <RemoveIcon color={colors.inactive} />
                  </IconButton>
                )}
                {isLoadingCreateNote || isLoadingPatchNote ? (
                  <CircularProgress size={42} />
                ) : (
                  <IconButton
                    className="confirm-note-icon"
                    onClick={handleSubmitNoteAction}
                  >
                    <CheckMarkIcon color={colors.callScheduled} />
                  </IconButton>
                )}
              </div>
            )}
          </div>
          {!noteAction ? (
            notes?.length ? (
              <div className="note-list">
                {notes?.map((note, index) => (
                  <div key={index} className="note-card">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: colors.grey11,
                        fontSize: 12,
                        height: '20px',
                      }}
                    >
                      <div
                        className="notes-opportunity-container"
                        style={{ gap: '8px' }}
                      >
                        {moment(note.creationDatetime).format('ll')}
                        {note.opportunityId && hasOpportunities ? (
                          <Typography
                            fontSize={'10px'}
                            fontWeight={500}
                            className="notes-opportunity-badge"
                          >
                            Deal
                          </Typography>
                        ) : null}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          onClick={() => handleEditNote(note.id)}
                          className="icon-container edit-icon-container"
                        >
                          <EditIcon />
                        </div>
                        <div
                          onClick={() => handleDeleteNote(note.id)}
                          className="icon-container remove-icon-container"
                        >
                          <RemoveIcon />
                        </div>
                      </div>
                    </div>
                    {note.opportunityId && hasOpportunities ? (
                      <div className="notes-opportunity-container">
                        <Typography
                          fontSize={'14px'}
                          fontWeight={500}
                          color={colors.intenseBlue}
                        >
                          {note.opportunity?.name}
                        </Typography>
                      </div>
                    ) : null}
                    <Typography
                      style={{ wordBreak: 'break-word' }}
                      color={colors.black}
                      whiteSpace={'pre-line'}
                    >
                      {note.description}
                    </Typography>
                  </div>
                ))}
              </div>
            ) : (
              <FlexDiv
                direction="column"
                gap="8px"
                justifyContent="start"
                style={{ paddingRight: '18px' }}
              >
                <NoNotes style={{ width: '100%', maxHeight: '65%' }} />
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  textAlign={'center'}
                  lineHeight={'18px'}
                  color={colors.grey1}
                >
                  Create a new note
                </Typography>
              </FlexDiv>
            )
          ) : (
            <div className="create-note-card">
              <TextareaAutosize
                value={noteDescription}
                onChange={(ev) => setNoteDescription(ev.target.value)}
                className="create-note-textarea"
                placeholder="Start typing your note..."
              />
            </div>
          )}
        </>
      )}
    </NotesBoxWrapper>
  );
};

export default NotesBox;
