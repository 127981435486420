import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import {
  ExternalProperty,
  TableType,
} from 'crono-fe-common/types/externalProperty';

export default function useGetExternalProperty(
  TableType: TableType | null,
  OnlyTag: boolean | null,
  OnlyInsert?: boolean | null,
  WithLead?: boolean | null,
  enabled = true,
) {
  const params: any = {};

  if (TableType) {
    params['TableType'] = TableType;
  }
  if (OnlyTag) {
    params['OnlyTag'] = OnlyTag;
  }
  if (OnlyInsert) {
    params['OnlyInsert'] = OnlyInsert;
  }
  if (WithLead) {
    params['WithLead'] = WithLead;
  }
  const queryString = new URLSearchParams(params).toString();

  const request: Request = {
    url: ENDPOINTS.externalProperty.main + '?' + queryString,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.externalProperty.main, queryString],

    queryFn: () => client(request),
    enabled,
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
  };

  const { data, ...rest } = useQuery<
    ResponsePaginated<ExternalProperty[]>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
