import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

const widthSize = '305px';

export const ChartsGridWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 16px;

  .analytics-charts-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    overflow-x: auto;
    border-radius: 16px;
    background: ${colors.grey6};
    padding-left: 8px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.grey444};
  }

  .filter-container {
    position: sticky;
    display: flex;
    align-items: center;
    gap: 10px;
    background: ${colors.white};
    border-radius: 16px;
    padding: 12px;
  }

  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 0px 6px 0px 6px !important;
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    // background: ${colors.primaryLight};
    color: ${colors.primary};
  }

  .charts-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    gap: 16px;
    background: ${colors.white};
    border-radius: 16px;
    padding: 20px;
    padding-bottom: 0px;
  }
  .chart-container,
  .chart-container-svg,
  .chart-container-canvas {
    height: 380px;
    padding: 24px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
    ::-webkit-scrollbar {
      height: 5px;
    }
    position: relative;
    margin-bottom: 24px;
  }
  //THIS HAS TO BE DONE BECAUSE ANT DESIGN HAS PROBLEMS
  //WHEN ONLY ONE GROUP IS DISPLAYED
  .chart-container-svg div div svg g:nth-child(3) g {
    transform: scale(0.1, 1) translate(455%, 0) !important;
  }
  .chart-container-svg div div svg g:nth-child(2) g {
    transform: scale(1) !important;
  }
  .chart-container-svg div:nth-child(2) div svg {
    height: 310px !important;
  }
  .chart-container-canvas div:nth-child(2) div svg {
    height: 310px !important;
  }
  .chart-container-canvas#deals-overview-bar-chart div div svg g:nth-child(3) {
    transform: scale(1, 0.1) translate(0, 425%);
  }
  .chart-container-canvas#deals-overview-bar-chart
    div
    div
    svg
    g:nth-child(2)
    g {
    transform: scale(1) !important;
  }
  #deals-overview-bar-chart div div svg,
  #deals-overview-bar-chart-multiple-users div div svg {
    overflow: visible !important;
  }

  .legend-chart {
    display: none;
  }
  .chart-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    scroll-margin: 20px;
  }

  #information-placeholder,
  #no-results-placeholder-container {
    width: auto;
  }
  #information-placeholder > svg {
    min-width: 85%;
    margin-top: 35px;
    opacity: 0.5;
  }
  .backdrop-blur-style {
    backdrop-filter: blur(1px);
    min-width: 200px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }
  .title {
    margin: 0px;
    color: ${colors.primary};
  }

  .recharts-responsive-container {
    background-color: ${colors.white};
    border-radius: 5px;
  }

  .recharts-surface {
    padding-left: 5px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .input-date {
    width: 200px;
    background-color: ${colors.white};
    border-radius: 10px;
    height: 40px;
  }

  .input-date div {
    border-radius: 10px;
    height: 40px;
  }

  .input-period {
    background-color: ${colors.white};
    border-radius: 10px;
    height: 40px;
    min-width: 200px;
  }

  .input-period-placeholder {
    background-color: ${colors.white};
    border-radius: 10px;
    color: ${colors.placeholder};
    font-weight: 500;
    height: 40px;
    min-width: 200px;
  }

  // .input-period-field div {
  //   border-radius: 10px;
  //   height: 40px;
  // }

  .input-account {
    width: 250px;
    background-color: ${colors.white};
    border-radius: 10px;
    height: 40px;
  }

  .input-account div {
    height: 40px;
  }

  .input-account-field {
    height: 40px;
  }
  .detail-picker input {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .input-account-field div {
    border-radius: 10px;
    height: 40px;
  }

  .input-user {
    width: 250px;
    background-color: ${colors.white};
    border-radius: 10px;
    height: 40px;
  }
  .input-user div {
    height: 40px;
  }
  .input-user-field {
    height: 40px;
  }
  .input-user-field div {
    border-radius: 10px;
    height: 40px;
  }
  .single-chart-container {
    display: flex;
    padding-inline: 8px;
    width: 100%;
  }
  .detail-picker {
    align-self: flex-start;
  }
  .task-heatmap-tabs {
    gap: 2px;
  }
  .task-heatmap-tab {
    text-transform: unset;
    &.Mui-selected {
      background: ${colors.primaryLight};
      color: ${colors.primary};
      border-bottom: 0px solid transparent;
      border-radius: 4px;
    }
    padding: 8px 16px !important;
    min-height: unset;
    min-width: unset;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    border-radius: 4px;
  }
  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 6px 0px 6px 6px;
    z-index: 100000;
    max-height: 300px;
    overflow: hidden;
    display: flex;
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    z-index: 1000;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    // background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .select-menu {
    padding-inline: 6px;
  }
  .select-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 6px;
    &:hover {
      background: ${colors.primaryLight} !important;
      color: ${colors.primary};
    }
    &.Mui-selected {
      background: ${colors.primaryLight} !important;
      color: ${colors.primary};
      &:hover {
        background: ${colors.primaryLightHover};
      }
    }
  }
  .donuts-container {
    display: flex;
    align-items: center;
  }
  .g2-tooltip {
    border-radius: 16px !important;
    min-width: 180px !important;
  }
`;

export const AnalyticsWrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px;
  width: 100%;
  border-radius: 16px;
  overflow: auto;
  flex: 1;

  .divider {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 10px 0px;
  }

  .general-wrapper .MuiBox-root {
    padding: 0;
  }

  .MuiGrid-item > div > .MuiBox-root {
    padding: 0;
  }

  .tabs-mui-component {
    overflow: visible;
    min-height: unset !important;
  }

  // &.Mui-selected {
  //   background-color: ${colors.primaryLight}
  // }
  // }

  .tabs-container {
    padding: 18px 0px;
    height: 100%;
    overflow: auto;
    width: ${widthSize};
    border-radius: 16px;
    background: ${colors.white};
    display: flex;
    flex-direction: column;
  }
  .tab-content-container {
    height: 100%;
    width: calc(100% - ${widthSize});
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
    background: ${colors.grey6};
  }

  .left-menu-category-container {
  }

  .analytics-container {
    align-items: start;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: 100%;
    width: 100%;
    background: ${colors.grey6};
  }

  .left-menu-category {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 12px 8px;
    color: ${colors.black};
  }

  .left-menu-tab {
    align-items: start;
    text-transform: unset;
    // justify-content: start;
    height: fit-content;
    min-height: unset !important;
    border-radius: 8px;
    padding: 12px 8px;
  }

  left-menu-tab:hover {
    // align-items: start;
    // text-transform: unset;
    // justify-content: start;
    // height: fit-content;
    // min-height: unset !important;
    // border-radius: 16px;
    // padding: 12px 24px;
  }

  @media (min-width: 600px) {
    .tabs-container {
      border-right: 3px solid ${colors.background};
    }
  }

  .settings-grid {
    height: 100%;
  }
`;

export const ChartGeneralWrapper = styled.div`
  display: flex;
  flex-flow: wrap;

  .mail-button-container {
    margin-left: 20px;
    margin-bottom: 40px;
  }

  .mail-button {
    width: 120px;
    position: absolute;
    bottom: 8px;
    left: 8px;
  }

  .tox {
    border-bottom: 0px solid red !important;
    overflow: hidden;
  }
  .editor-container {
    border: 1px solid ${colors.grey444};
    border-top: 0px solid ${colors.grey444};
    height: 420px;
    position: relative;
    background: ${colors.grey6};
    border-radius: 8px;
  }

  .form-control {
    flex-basis: 100%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
  }

  .form-control label {
    display: block;
    margin-bottom: 10px;
  }

  .form-control Input {
    display: block;
    width: 100%;
    height: 10px;
  }

  .mailer-button-container {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .template-button-container {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 15px;
  }

  .template-button {
    width: 100px;
    height: 40px;
    font-size: 12px;
    margin: 10px;
    padding: 10px;
  }

  .select {
    width: 120px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .status-select {
    height: 40px;
    width: 200px;
    background-color: ${colors.lightGrey};
    border-radius: 10px;
    text-align: center;
  }

  .variable-button {
    width: 80px;
    height: 40px;
    font-size: 12px;
    margin-top: 5px;
    margin-right: 20px;
  }

  .success-message {
    color: ${colors.primary};
  }

  .account-info-row {
    min-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    gap: 30px;
  }

  .account-info-row p {
    font-weight: 600;
    width: 180px;
  }

  .add-new-label {
    width: 150px;
    display: inline-block;
    font-weight: bold;
  }

  .add-new-field {
    background-color: ${colors.lightGrey};
    display: inline;
    align-items: center;
    width: 200px;
    margin-bottom: 10px;
  }

  .not-success-message {
    color: ${colors.inactiveRed};
  }

  .user-roles {
    font-size: 14px;
    font-weight: 400 !important;
    color: ${colors.primary};
    margin-left: 10px;
  }

  .connect-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    padding-left: 60px;
    border-radius: 20px;
  }

  .password-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
  }

  .user-form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .mail-form {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    max-width: 800px;
  }

  .connect-text {
    text-align: center;
  }

  .connect-text h2 {
    font-size: 2rem;
  }

  .connect-text p {
    color: ${colors.primary};
  }

  .providers {
    margin: 20px 0px;
  }

  .provider-box {
    background: #f8f9fd;
    margin: 30px 0px;
    padding: 15px 40px;
    height: 100px;
    border: 1px solid #c4c4c4;
    cursor: pointer;
  }

  .provider-box img {
    flex-grow: 1;
  }

  .provider-box h3 {
    flex-grow: 2;
    margin: 0px 40px;
    font-weight: 500;
  }

  .linkedin-title {
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 20px;
  }

  .linkedin-title-container {
    width: 400px;
    margin-bottom: 10px;
  }

  .titles-list-container {
    min-height: 150px;
  }

  .max-titles-div {
    height: 75px;
  }

  .remove-button {
    background-color: ${colors.lightGrey};
  }

  @media screen and (max-width: 768px) {
    .connect-form {
      padding: 20px 0px;
    }
  }

  .settings-hint {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    font-style: italic;
    color: ${colors.mediumGrey};
  }

  .addon-label {
    font-weight: 600;
    font-size: 14px;
  }

  .credits-label {
    font-weight: 500;
    font-size: 13px;
    text-align: right;
  }

  .add-ons-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .credit-container {
    display: flex;
    border-radius: 16px;
    width: 100%;
    padding: 24px;
    align-items: center;
    justify-content: space-between;
    column-gap: 24px;
  }
  .email-credit-container {
    background: ${colors.callScheduledLight};
  }
  .template-credit-container {
    background: ${colors.blueLight};
  }
  .addon-big-icon {
    width: 74px;
    height: 74px;
    padding: 16px;
    border-radius: 999px;
  }
  .email-credit-big-icon {
    background: ${colors.callScheduledLightHover};
  }
  .template-credit-big-icon {
    background: ${colors.blueLightHover};
  }
`;
