import styled from '@emotion/styled';
import { Tooltip, Typography } from '@mui/material';
import IconFindEmail from 'crono-fe-common/icons/Icon-Find-Email';
import IconFindPhone from 'crono-fe-common/icons/Icon-Find-Phone';
import IconRevenue from 'crono-fe-common/icons/Icon-Revenue';
import { colors } from 'crono-fe-common/theme';
import useGetUserCredits from 'hooks/services/user/useGetUserCredits';
import moment from 'moment';
import { SettingsTabs } from 'pages/settings';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';

interface IProps {
  type: 'email' | 'phone';
}

const RemainingCreditsHeaderIcon = ({ type }: IProps) => {
  const { data: userCredits } = useGetUserCredits();
  const remainingCredits = useMemo(() => {
    if (type === 'email') {
      if (
        !userCredits?.data?.data.emailTotal &&
        !userCredits?.data?.data.emailBucketsTotal
      )
        return null;
      return userCredits.data.data.emailLeft;
    } else {
      if (
        !userCredits?.data?.data.phoneTotal &&
        !userCredits?.data?.data.phoneBucketsTotal
      )
        return null;
      return userCredits.data.data.phoneLeft;
    }
  }, [userCredits]);

  const areThere0Credits = useMemo(() => {
    return remainingCredits === 0;
  }, [remainingCredits]);

  const navigate = useNavigate();

  const handleBuyMoreCredits = useCallback(() => {
    navigate(PATH.SETTINGS, {
      state: {
        initialSettingsTab: SettingsTabs.AddOns,
      },
    });
  }, [navigate]);

  return (
    <Tooltip
      arrow
      title={
        <span
          style={{
            whiteSpace: 'pre-line',
            display: 'flex',
            textAlign: 'center',
          }}
        >
          {remainingCredits} find {type} credits left.
          {'\n'}
          Next update:{' '}
          {moment(userCredits?.data?.data.creditsRenewalDate).format(
            'MMM DD, YYYY',
          )}
        </span>
      }
      enterDelay={400}
      enterNextDelay={400}
    >
      <RemainingCreditsWrapper
        insufficientCredits={areThere0Credits}
        onClick={handleBuyMoreCredits}
      >
        {type === 'email' ? (
          <IconFindEmail
            color={areThere0Credits ? colors.inactiveHover : colors.primaryDark}
            className="finder-info-icon"
          />
        ) : (
          <IconFindPhone
            color={areThere0Credits ? colors.inactiveHover : colors.primaryDark}
            className="finder-info-icon"
          />
        )}
        <Typography
          fontSize={12}
          lineHeight={'16px'}
          fontWeight={500}
          color={areThere0Credits ? colors.inactiveHover : colors.primaryDark}
        >
          {remainingCredits}
        </Typography>
        {areThere0Credits && (
          <div className="buy-more-button">
            <IconRevenue color={colors.white} />
            <Typography
              fontSize={11}
              lineHeight={'14px'}
              fontWeight={500}
              color={colors.white}
            >
              Buy
            </Typography>
          </div>
        )}
      </RemainingCreditsWrapper>
    </Tooltip>
  );
};

export default RemainingCreditsHeaderIcon;

const RemainingCreditsWrapper = styled.div<{
  insufficientCredits?: boolean | null;
}>`
  border-radius: 12px;
  padding: 3px 4px;
  background: ${({ insufficientCredits }) =>
    insufficientCredits ? colors.inactiveLight : colors.primaryLight};
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  :hover {
    background: ${({ insufficientCredits }) =>
      insufficientCredits
        ? colors.inactiveLightHover
        : colors.primaryLightHover};
  }
  .finder-info-icon {
    width: 16px;
    height: 16px;
    min-height: 16px;
    min-width: 16px;
  }
  .buy-more-button {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 2px 4px 2px 6px;
    border-radius: 20px;
    height: 18px;
    background: ${colors.inactive};
    &:hover {
      background: ${colors.inactiveHover};
    }
    cursor: pointer;
  }
`;
