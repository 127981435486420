import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StepsWrapper, StrategyTagsContainer, StrategyWrapper } from './style';
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  ClickAwayListener,
  Tooltip,
  Badge,
  styled,
  TooltipProps,
  tooltipClasses,
  CircularProgress,
} from '@mui/material';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { colors } from 'crono-fe-common/theme';
import useSearchStrategies from 'hooks/services/sequence/useSearchStrategies';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import AutoClockSIcon from 'crono-fe-common/icons/Icon-Clock-Auto-S';
import AddedTemplateSIcon from 'crono-fe-common/icons/Icon-Added-Template-S';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import StrategyCreation from './strategyCreation';
import {
  getError,
  getExternalPropertyLabels,
  externalPropertiesToTagInsert,
} from 'crono-fe-common/utils';
import { TagInsert } from 'crono-fe-common/types/tagInsert';
import { Tag } from 'crono-fe-common/types/tag';
import useDeleteStrategy from 'hooks/services/sequence/useDeleteStrategy';
import { useConditionalSnackBar } from 'context/snackbar';
import { useConfirmModal } from 'context/confirmModal';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { Strategy } from 'crono-fe-common/types/strategy';
import Pagination from 'pages/dashboard/pagination';
import Role from 'crono-fe-common/types/enums/role';
import { useAuth } from 'context/auth';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import ExternalPropertyFilter from 'crono-fe-common/types/externalPropertyFilter';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';

import { useJuneAnalytics } from 'context/june';
import { Constants } from 'crono-fe-common/constants/constants';
import TagContainerComponent, {
  TagFilterTooltip,
} from '../Template/tagTooltip';
import { useShortStrategyOverviewContext } from 'context/strategyDetail';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import { SequenceStep } from 'crono-fe-common/types/sequenceStep';
import ThreeDotsIcon from 'crono-fe-common/icons/Icon-Three-dots';
import SequenceOptions from './sequenceOptions';
import useDuplicateStrategy from 'hooks/services/sequence/useDuplicateStrategy';
import useStopStrategy from 'hooks/services/sequence/useStopStrategy';
import { SequenceFilterStatusType } from 'crono-fe-common/types/DTO/strategyDetailSearch';
import { useWindow } from 'context/sideTab';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { ReactComponent as NoStrategies } from 'assets/images/No_strategies.svg';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import { StrategyOverviewTab } from 'context/strategyDetail/types';
import useGetApplySequenceChanges from 'hooks/applySequenceChanges/useGetApplySequenceChanges';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import {
  CreateStrategyModal,
  EStrategyCreationType,
} from './createStrategyModal';
import StrategyLibrary from './strategyLibrary';
import StrategyPreview from './strategyPreview';
import { useSequenceContext } from 'context/strategyDetail/context/sequence';
import { Sequence } from 'crono-fe-common/types/sequence';
import { FeConstants } from 'constants/FeConstants';
import { TabValue } from 'pages/home';
import classNames from 'classnames';
import LoadingOverlay from 'components/LoadingOverlay';

export const CustomOptionsTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & {
    left?: number;
    top?: number;
    minWidth?: number | string;
    width?: number | string;
    height?: number | string;
    maxHeight?: number | string;
    overflow?: string;
    padding?: number | string;
    borderRadius?: number | string;
    zIndex?: number | string;
  }) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(
  ({
    left,
    top,
    width,
    minWidth,
    height,
    maxHeight,
    overflow,
    padding,
    borderRadius,
    zIndex,
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      position: 'relative',
      top: top ? top : -8,
      left: left ? left : -8,
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      height: height ? height : undefined,
      maxHeight: maxHeight ? maxHeight : 100,
      width: width ? width : 500,
      minWidth: minWidth ? minWidth : undefined,
      border: `1px solid ${colors.grey4}`,
      borderRadius: borderRadius ? borderRadius : 8,
      padding: padding ? padding : 0,
      WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      display: 'flex',
      overflow: overflow ? overflow : 'auto',
      zIndex: zIndex ? zIndex : 1600,
    },
  }),
);

const analytics: {
  fieldName: keyof Strategy;
  text: string;
  numberColor: string;
  percentage?: boolean;
  filterStatus: SequenceFilterStatusType;
}[] = [
  {
    fieldName: 'useCount',
    text: 'Total',
    numberColor: colors.black,
    percentage: false,
    filterStatus: SequenceFilterStatusType.ALL,
  },
  {
    fieldName: 'activeCount',
    text: 'Active',
    numberColor: colors.callScheduled,
    percentage: true,
    filterStatus: SequenceFilterStatusType.ACTIVE,
  },
  {
    fieldName: 'stoppedCount',
    text: 'Stopped',
    numberColor: colors.nurture,
    percentage: true,
    filterStatus: SequenceFilterStatusType.STOPPED,
  },
  {
    fieldName: 'completedCount',
    text: 'Finished',
    numberColor: colors.blue,
    percentage: true,
    filterStatus: SequenceFilterStatusType.FINISHED,
  },
  {
    fieldName: 'answerCount',
    text: 'Replied',
    numberColor: colors.purple,
    percentage: true,
    filterStatus: SequenceFilterStatusType.CONVERTED,
  },
];

interface IProps {
  setTabValue: React.Dispatch<React.SetStateAction<TabValue>>;
}

const StrategySettings = ({ setTabValue }: IProps) => {
  const { user, highestRole } = useAuth();
  const analitycs = useJuneAnalytics();
  const [search, setSearch] = useState<string | null>(null);

  const [filterTooltipToggle, setFilterTooltipToggle] =
    useState<boolean>(false);

  const [offset, setOffset] = useState<number>(0);

  const [selectedUser, setSelectedUser] = useState<number | undefined | null>(
    undefined,
  );
  const { data: users } = useGetUsers(highestRole === Role.MANAGER);

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  // Load user preferences
  useEffect(() => {
    if (!user || !users) return;

    const savedSelectedUserId = user.userPreferences?.strategySelectedUser;
    const isUserInList = !!users.data?.data.find(
      (u) => u.id === savedSelectedUserId,
    );
    if (savedSelectedUserId && isUserInList) {
      setSelectedUser(savedSelectedUserId);
    } else {
      setSelectedUser(-1);
    }
  }, [user, users]);

  // Save user preferences
  useEffect(() => {
    if (selectedUser !== undefined && user) {
      user.userPreferences.strategySelectedUser = selectedUser;
      patchUserPreferences({
        strategySelectedUser: selectedUser,
      });
    }
  }, [selectedUser]);

  const [filterExternalProperties, setFilterExternalProperties] = useState<
    ExternalPropertyFilter[]
  >([]);
  const flatTagsFilter = useMemo(() => {
    return externalPropertiesToTagInsert(filterExternalProperties);
  }, [filterExternalProperties]);
  const { data: strategies, isLoading: loadingStrategies } =
    useSearchStrategies({
      limit: Constants.defaultTemplatesLimit,
      offset: offset,
      name: search,
      withSequence: true,
      withAnalytics: true,
      userId: selectedUser !== -1 ? selectedUser : undefined,
      strategyTags: flatTagsFilter.length ? flatTagsFilter : undefined,
      filterByTags: true,
      withUsers: true,
    });

  const {
    mutate: duplicateStrategy,
    isSuccess: successDuplicateSequence,
    error: errorDuplicateSequence,
    isLoading: duplicateLoading,
  } = useDuplicateStrategy();

  const {
    mutate: stopStrategy,
    isSuccess: successStoppedStrategy,
    error: errorStopStrategy,
    isLoading: loadingStopStrategy,
  } = useStopStrategy();

  // -- Calculate dynamically how many steps  ---
  const stepsRef = useRef<HTMLDivElement | null>(null);
  const tagsRef = useRef<HTMLDivElement | null>(null);

  const [nStepsToShow, setNStepsToShow] = useState<number>(8);

  useLayoutEffect(() => {
    if (stepsRef?.current?.clientWidth) {
      setNStepsToShow(stepsRef.current.clientWidth / 48);
      // }
    } else {
      // for first render
      setNStepsToShow(8);
    }
  }, [strategies, stepsRef.current?.clientWidth]);

  //State to store che clientWidth, so that the tags containers can be refreshed, showing the correct number of tags
  const [tagsClientWidth, setTagsClientWidth] = useState<number>(0);

  useLayoutEffect(() => {
    //The width become undefined after a strategy is deleted (due to the bad handling of the refs)
    if (tagsRef?.current?.clientWidth) {
      setTagsClientWidth(tagsRef?.current?.clientWidth);
    }
    // else {
    //   setTagsClientWidth(0);
    // }
  }, [strategies, tagsRef?.current?.clientWidth]);

  // ------------------------------------------------------------------------------

  const { data: externalPropertyTags } = useGetExternalProperty(null, true);

  // When a property is pressed I update the filters
  const handleChangeExternalProperty = (
    externalPropertyId: number,
    option: string,
  ) => {
    // If the option is not selected, I add it to the filters
    if (
      !filterExternalProperties
        ?.filter((ep) => ep.externalPropertyId === externalPropertyId)
        .at(0)
        ?.values?.includes(option)
    ) {
      const externalProperty =
        filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId === externalPropertyId,
        )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
      externalProperty.values = [...(externalProperty.values ?? []), option];
      const newExternalProperties: ExternalPropertyFilter[] = [
        ...(filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId !== externalPropertyId,
        ) || []),
        externalProperty,
      ];

      setFilterExternalProperties([...newExternalProperties]);
    } else {
      //Remove the filter
      const externalProperty: ExternalPropertyFilter =
        filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId === externalPropertyId,
        )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
      externalProperty.values = externalProperty.values?.filter(
        (value) => value !== option,
      );
      // If the externalProperty has no value I have to remove it from the filters (not adding it)
      const newExternalProperties: ExternalPropertyFilter[] = [
        ...(filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId !== externalPropertyId,
        ) || []),
      ];
      if (externalProperty.values && externalProperty.values?.length > 0) {
        newExternalProperties.push(externalProperty);
      }
      setFilterExternalProperties([...newExternalProperties]);
    }
  };

  //Open detail on the provider
  const { setStrategyOverviewContent, strategyOverviewContent } =
    useShortStrategyOverviewContext();

  const [optionSequenceToogle, setOptionSequenceToogle] = useState<
    number | null
  >(null);

  const [enable, setEnable] = useState<boolean>(true);

  const startFetchingActiveSequenceChanges = () => {
    setEnable(true);
  };

  const {
    data: allActiveSequenceChanges,
    isLoading,
    isRefetching,
  } = useGetApplySequenceChanges(enable);

  //We decided not to show in progress changes that are going for more than 5 minutes
  const sequenceChangesShorterThan5Minutes = useMemo(() => {
    return (
      allActiveSequenceChanges?.filter(
        (sc) =>
          new Date().getTime() - new Date(sc.startDate).getTime() <
          5 * 60 * 1000,
      ) ?? []
    );
  }, [allActiveSequenceChanges]);

  useEffect(() => {
    if (
      !isLoading &&
      !isRefetching &&
      sequenceChangesShorterThan5Minutes.length === 0
    ) {
      setEnable(false);
    }
  }, [sequenceChangesShorterThan5Minutes, isLoading, isRefetching]);
  const { windowContent, closeWindow } = useWindow();

  const setEditSequenceId = (sequenceId: number | null) => {
    setStrategyOverviewContent({
      strategyId: sequenceId,
      initialTab: StrategyOverviewTab.Detail,
      onApplySequenceChanges: startFetchingActiveSequenceChanges,
    });
  };
  //When the sequence is created, we open the sequence manager
  const onCreate = (sequenceId: number) => {
    setShowCreateFromScratchModal(false);
    setEditSequenceId(sequenceId);
  };

  const getLabel = (tag: TagInsert | Tag) => {
    const externalProperty = externalPropertyTags?.data?.data?.find(
      (externalProperty) => externalProperty.id === tag.externalPropertyId,
    );
    if (!externalProperty || !externalProperty.options) return null;
    return getExternalPropertyLabels(externalProperty)[
      externalProperty.options?.findIndex((property) => property === tag.value)
    ];
  };

  const {
    mutate: deleteStrategy,
    error: errorDeleteStrategy,
    isSuccess: strategyDeleted,
  } = useDeleteStrategy();

  useConditionalSnackBar([
    {
      condition: !!errorDeleteStrategy,
      message: getError(errorDeleteStrategy) ?? 'Error deleting strategy',
      severity: 'error',
    },
    {
      condition: !!strategyDeleted,
      message: 'Strategy deleted',
      severity: 'success',
    },
    {
      condition: !!errorDuplicateSequence,
      message: getError(errorDuplicateSequence) ?? 'Error duplication strategy',
      severity: 'error',
    },
    {
      condition: !!successDuplicateSequence,
      message: 'Strategy duplicated successfully',
      severity: 'success',
    },
    {
      condition: !!errorStopStrategy,
      message: getError(errorStopStrategy) ?? 'Error pausing strategy',
      severity: 'error',
    },
    {
      condition: !!successStoppedStrategy,
      message: 'Strategy paused',
      severity: 'success',
    },
  ]);

  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteStrategy = (strategyId: number) => {
    openConfirmModal({
      title: 'Are you sure you want to delete this strategy?',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      cancelFunction: () => {
        return;
      },
      confirmFunction: () => {
        deleteStrategy(strategyId);
        if (analitycs) {
          analitycs.track('strategy-deleted', {
            strategyId: strategyId,
          });
        }
      },
    });
  };

  useEffect(() => {
    setOffset(0);
  }, [search]);

  const renderStepComponent = (step: SequenceStep) => {
    let icon = null;
    let title = '';
    let badge = null;
    switch (step.type) {
      case TaskTodoType.CALL:
        icon = (
          <CallIcon
            className={'type-icon call'}
            color={colors.callScheduledHover}
          />
        );
        title = 'Call';
        break;
      case TaskTodoType.EMAIL:
        icon = (
          <EmailIcon
            className={'type-icon email'}
            color={colors.inactiveHover}
          />
        );
        title = step.automatic
          ? 'Auto Email: Template assigned'
          : !step.automatic && step.templateId
            ? 'Email: Template assigned'
            : 'Email';
        badge = step.automatic ? (
          <AutoClockSIcon color={colors.grey11} />
        ) : !step.automatic && step.templateId ? (
          <AddedTemplateSIcon color={colors.grey11} />
        ) : null;
        break;
      case TaskTodoType.LINKEDIN:
        if (step.subtype === TaskTodoSubtype.LinkedinInvitation) {
          icon = <LinkedinInvitationIcon className={'linkedin-icon'} />;
          title = step.automatic
            ? 'Auto Linkedin Invitation: Template assigned'
            : !step.automatic && step.templateId
              ? 'Linkedin Invitation: Template assigned'
              : 'Linkedin Invitation';
        } else if (step.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
          icon = (
            <LinkedinIconInvoice
              color={colors.blueHover}
              className={'linkedin-icon'}
              viewBox="1 2 19 19"
            />
          );
          title = step.templateId
            ? 'Linkedin Voice Note: Script assigned'
            : 'Linkedin Voice Note';
        } else {
          icon = (
            <LinkedinIconMessage
              className={'linkedin-icon'}
              color={colors.blueHover}
            />
          );
          title = step.automatic
            ? 'Auto Linkedin Message: Template assigned'
            : !step.automatic && step.templateId
              ? 'Linkedin Message: Template assigned'
              : 'Linkedin Message';
        }
        badge = step.automatic ? (
          <AutoClockSIcon color={colors.grey11} />
        ) : !step.automatic && step.templateId ? (
          <AddedTemplateSIcon color={colors.grey11} />
        ) : null;
        break;
      case TaskTodoType.IN_MAIL:
        icon = (
          <LinkedinIconInMailCompass
            className={'type-icon in-mail'}
            color={colors.purpleHover}
            viewBox="2 2 20 20"
          />
        );
        title = step.templateId
          ? 'Linkedin InMail: Template assigned'
          : 'Linkedin InMail';
        break;
      default:
        icon = (
          <EmailIcon
            className={'type-icon email'}
            color={colors.inactiveHover}
          />
        );
        title = 'Email';
        break;
    }
    return (
      <Tooltip arrow title={title} placement="top">
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: colors.badgeBackground,
              border: `1px solid ${colors.white}`,
              right: 4,
              top: 3,
              fontSize: '4px',
              lineHeight: '8px',
              padding: 0,
              display: !badge ? 'none' : '',
            },
          }}
          badgeContent={badge}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className={
              step.type === TaskTodoType.LINKEDIN
                ? 'type-icon linkedin-icon'
                : ''
            }
          >
            {icon}
          </span>
        </Badge>
      </Tooltip>
    );
  };

  const sliceAndAddHoverStep = (
    steps: SequenceStep[],
    renderStepsFn: (step: SequenceStep) => JSX.Element,
    stepsToShow: number,
  ) => {
    const allSteps = steps?.map((step, index) => {
      return (
        <>
          {renderStepsFn(step)}
          {steps?.length && index < steps.length - 1 ? (
            <div className="horizontal-divider" />
          ) : null}
        </>
      );
    });
    // on first render, stepsToShow exceeds steps.length
    const limit =
      Math.floor(stepsToShow) - 1 > steps.length
        ? 7
        : Math.floor(stepsToShow) - 1;

    const visibleSteps = steps.slice(0, limit);

    return (
      <>
        {allSteps.length <= visibleSteps.length + 1 ? (
          <>{allSteps}</>
        ) : (
          <>
            {visibleSteps.map((step, index) => {
              return (
                <>
                  {renderStepsFn(step)}
                  <div className="horizontal-divider" />
                </>
              );
            })}
            <CustomOptionsTooltip
              title={<StepsWrapper>{allSteps}</StepsWrapper>}
              placement="bottom"
              borderRadius={'16px'}
              minWidth={'fit-content'}
              left={-217}
              overflow="visible"
              padding={'16px'}
            >
              <span
                style={{ display: 'flex', alignItems: 'center' }}
                className="type-icon extra-steps"
              >
                +{steps.length - visibleSteps.length}
              </span>
            </CustomOptionsTooltip>
          </>
        )}
      </>
    );
  };

  //Not used for the moment but do not delete
  // const renderTags = (
  //   externalProperties: ExternalProperty[],
  //   strategy: Strategy,
  //   tagdDivWidth: number,
  // ) => {
  //   const allTags = [
  //     ...externalProperties
  //       .filter((tag) => tag.tableType === 'Account')
  //       .map((tag, index) => {
  //         //If it exists in the strategy tag I show the component
  //         const strategyTagValue = strategy.strategyTags?.find(
  //           (strategyTag) => strategyTag.externalPropertyId === tag.id,
  //         );
  //         if (strategyTagValue)
  //           return (
  //             <ExternalPropertyComponent
  //               index={index}
  //               indexOption={tag.options?.indexOf(strategyTagValue.value)}
  //               value={getLabel(strategyTagValue)}
  //               key={strategyTagValue.id}
  //             />
  //           );
  //       }),
  //     ...externalProperties
  //       .filter((tag) => tag.tableType !== 'Account')
  //       .map((tag, index) => {
  //         //If it exists in the strategy tag I show the component
  //         const strategyTagValue = strategy.strategyTags?.find(
  //           (strategyTag) => strategyTag.externalPropertyId === tag.id,
  //         );
  //         if (strategyTagValue)
  //           return (
  //             <ExternalPropertyComponent
  //               index={index}
  //               value={getLabel(strategyTagValue)}
  //               key={strategyTagValue.id}
  //             />
  //           );
  //       }),
  //   ].filter((tags) => tags !== undefined);
  //   //Calculate how many tags I have to show
  //   let newTagsToshow = 0;
  //   let remaining = tagdDivWidth ?? 0;
  //   //Plus button
  //   remaining -= 26;
  //   for (let i = 0; i < externalProperties.length; i++) {
  //     const tag = externalProperties[i];
  //     const strategyTagValue = strategy.strategyTags?.find(
  //       (strategyTag) => strategyTag.externalPropertyId === tag.id,
  //     );
  //     if (strategyTagValue) {
  //       //Length of text, constant part and gap
  //       //7 pixels prt character, 32 constant part, 8 gap
  //       const tagValueLength = Math.min(
  //         (getLabel(strategyTagValue)?.length ?? 0) * 7 + 32 + 8,
  //         140,
  //       );
  //       if (remaining > tagValueLength) {
  //         remaining -= tagValueLength;
  //         newTagsToshow++;
  //       } else {
  //         break;
  //       }
  //     }
  //   }
  //   const visibleTags = allTags.slice(0, newTagsToshow);
  //   return (
  //     <>
  //       {allTags?.length <= visibleTags.length ? (
  //         <div className="strategy-tags-container">{allTags}</div>
  //       ) : (
  //         <>
  //           <div className="strategy-tags-container">{visibleTags}</div>
  //           <CustomOptionsTooltip
  //             placement="bottom"
  //             title={<StrategyTagsContainer>{allTags}</StrategyTagsContainer>}
  //             left={20}
  //             width={'100%'}
  //             maxHeight={110}
  //           >
  //             <Typography className="extra-tags-tooltip">
  //               +{allTags?.length - visibleTags.length}
  //             </Typography>
  //           </CustomOptionsTooltip>
  //         </>
  //       )}
  //     </>
  //   );
  // };

  const handleStopStrategy = (
    strategyId: number,
    activeCount?: number | null,
  ) => {
    openConfirmModal({
      title: `Are you sure you want to stop ${activeCount ?? 'all'} active ${activeCount === 1 ? 'contact' : 'contacts'}?`,

      cancelText: 'Cancel',
      confirmText: 'Stop',
      confirmFunction: () => {
        stopStrategy(strategyId);
        if (analitycs) {
          analitycs.track('strategy-stopped', {
            strategyId: strategyId,
          });
        }
      },
      cancelFunction: () => {
        return;
      },
    });
  };

  const handleDuplicateStrategy = (strategyId: number) => {
    duplicateStrategy(strategyId);
    if (analitycs) {
      analitycs.track('strategy-duplicated', {
        strategyId: strategyId,
      });
    }
  };

  const handleSelectedFilter = (
    strategyId: number,
    filterStatus: SequenceFilterStatusType,
  ) => {
    setStrategyOverviewContent({
      strategyId: strategyId,
      initialFilter: filterStatus,
      initialTab: StrategyOverviewTab.Metrics,
      onApplySequenceChanges: startFetchingActiveSequenceChanges,
    });
    if (analitycs) {
      analitycs.track('selected-strategy-analytics', {
        selectedFilter: filterStatus,
      });
    }
  };

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const [showCreateFromScratchModal, setShowCreateFromScratchModal] =
    useState<boolean>(false);

  const [showDuplicateFromLibraryModal, setShowDuplicateFromLibraryModal] =
    useState<boolean>(false);

  const [showStrategyPreviewModal, setShowStrategyPreviewModal] =
    useState<boolean>(false);

  const { setSequence } = useSequenceContext();

  const handleNextOnStrategyCreation = useCallback(
    (selectedStrategy: EStrategyCreationType) => {
      setShowCreateModal(false);

      if (selectedStrategy === EStrategyCreationType.Scratch) {
        // setShowCreateFromScratchModal(true);
        setSequence({
          id: FeConstants.defaultNewStrategyId,
          name: FeConstants.defaultNewStrategyName,
        } as Sequence);
        setStrategyOverviewContent({
          strategyId: FeConstants.defaultNewStrategyId,
          initialTab: StrategyOverviewTab.Detail,
        });
      }

      if (selectedStrategy === EStrategyCreationType.Library) {
        setShowDuplicateFromLibraryModal(true);
      }
    },
    [],
  );

  const handleGoBackFromLibrarySearch = useCallback(() => {
    setShowDuplicateFromLibraryModal(false);
    setShowCreateModal(true);
  }, []);

  const handleGoBackFromStrategyPreview = useCallback(() => {
    setShowStrategyPreviewModal(false);
    setShowDuplicateFromLibraryModal(true);
  }, []);

  const handleAddContacts = () => {
    setTabValue(TabValue.DASHBOARD);
  };

  return (
    <StrategyWrapper>
      {showCreateModal && (
        <CreateStrategyModal
          onClose={() => setShowCreateModal(false)}
          onNext={handleNextOnStrategyCreation}
        />
      )}

      {showCreateFromScratchModal && (
        <StrategyCreation
          setOpen={setShowCreateFromScratchModal}
          onCreate={onCreate}
        />
      )}

      {showDuplicateFromLibraryModal && (
        <StrategyLibrary
          onClose={() => setShowDuplicateFromLibraryModal(false)}
          onGoBack={handleGoBackFromLibrarySearch}
          openStrategyPreview={() => setShowStrategyPreviewModal(true)}
        />
      )}

      {showStrategyPreviewModal && (
        <StrategyPreview
          onClose={() => setShowStrategyPreviewModal(false)}
          onGoBack={handleGoBackFromStrategyPreview}
        />
      )}

      <div className="strategy-settings-container">
        <span
          style={{
            paddingBottom: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            columnGap: 8,
            width: '100%',
            paddingRight: '18px',
          }}
        >
          <div>
            <div style={{ display: 'flex', gap: '8px', marginLeft: '8px' }}>
              <SearchIcon color={colors.grey11} />
              <TextField
                className="text-field"
                variant="standard"
                placeholder="Search..."
                autoComplete="off"
                value={search || ''}
                InputProps={{
                  endAdornment:
                    (search || '') === '' ? null : (
                      <CloseMIcon
                        className="remove-text-icon"
                        color={colors.grey11}
                        onClick={() => setSearch('')}
                      />
                    ),
                }}
                sx={{
                  width: '100%',
                  input: {
                    fontWeight: '400',
                    padding: '4px 0px',
                    '&::placeholder': {
                      opacity: 1,
                      color: colors.grey11,
                    },
                  },
                }}
                onChange={(ev) => setSearch(ev.target.value)}
              />
            </div>
          </div>
          <div style={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography
                fontWeight={500}
                lineHeight={'16px'}
                fontSize={12}
                color={colors.grey11}
                whiteSpace={'nowrap'}
              >
                My strategies
              </Typography>
              <CronoSwitch
                checked={selectedUser === user?.id}
                onChange={(ev) => {
                  setSelectedUser((prev) => {
                    if (prev === user?.id) {
                      return -1;
                    } else {
                      return user?.id;
                    }
                  });
                  // handleChangeOnlyMine(ev.target.checked);
                }}
              />
            </div>
            {highestRole === Role.MANAGER && (
              <FormControl sx={{ minWidth: 120, mt: 1, mb: 1 }} size="small">
                <Select
                  labelId="select-medium"
                  id="select-medium"
                  value={selectedUser ?? -1}
                  onChange={(e) =>
                    setSelectedUser((e.target.value ?? -1) as number)
                  }
                  sx={{
                    fontSize: '14px',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: `1px solid ${colors.grey4}`,
                      borderRadius: 2,
                    },
                    padding: '2px',
                  }}
                  MenuProps={{
                    style: { zIndex: 3501 },
                    PaperProps: {
                      sx: {
                        borderRadius: '8px',
                        paddingInline: '6px',
                        border: '1px solid #dadde9',
                        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                        '& .MuiMenuItem-root': {
                          fontSize: '12px',
                          lineHeight: '16px',
                          height: 'fit-content',
                          padding: '8px',
                          cursor: 'pointer',
                          width: '100%',
                          borderRadius: '8px',
                          '&:hover': {
                            background: colors.primaryLight,
                            color: colors.primary,
                          },
                        },
                        '& .Mui-selected': {
                          color: colors.primary,
                          backgroundColor: 'transparent !important',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {users?.data?.data
                    // .filter((u) => u.id !== user?.id)
                    .map((user) => {
                      return (
                        <MenuItem value={user.id} key={user.id}>
                          {user.firstName} {user.lastName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}

            {/* Tags filter */}
            {(externalPropertyTags?.data?.data.length ?? 0) > 0 && (
              <ClickAwayListener
                onClickAway={() => setFilterTooltipToggle(false)}
              >
                <FormControl sx={{ minWidth: 120, mt: 1, mb: 1 }} size="small">
                  <TagFilterTooltip
                    open={filterTooltipToggle}
                    title={
                      <TagContainerComponent
                        externalProperties={
                          externalPropertyTags?.data?.data.filter(
                            (property) => property.isTag,
                          ) || []
                        }
                        filterExternalProperties={filterExternalProperties}
                        handleChangeExternalProperty={
                          handleChangeExternalProperty
                        }
                      />
                    }
                    right={130}
                  >
                    {flatTagsFilter?.length ? (
                      <div
                        className="tag-filter-container tags-selected"
                        onClick={() =>
                          setFilterTooltipToggle(!filterTooltipToggle)
                        }
                      >
                        <Typography
                          fontSize={'14px'}
                          style={{ flex: 1 }}
                          noWrap
                        >
                          Tags ({flatTagsFilter.length} selected)
                        </Typography>

                        <CloseMIcon
                          className="remove-text-icon"
                          color={colors.grey11}
                          onClick={() => {
                            setFilterExternalProperties([]);
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="tag-filter-container"
                        onClick={() =>
                          setFilterTooltipToggle(!filterTooltipToggle)
                        }
                      >
                        <Typography
                          fontSize={'14px'}
                          style={{ flex: 1 }}
                          noWrap
                        >
                          Tags
                        </Typography>
                        {filterTooltipToggle ? (
                          <TopArrowIcon className="tag-arrow" />
                        ) : (
                          <BottomArrowIcon className="tag-arrow" />
                        )}
                      </div>
                    )}
                  </TagFilterTooltip>
                </FormControl>
              </ClickAwayListener>
            )}
            <MainPrimaryButton
              startIcon={<PlusIcon color={colors.white} />}
              onClick={() => setShowCreateModal(true)}
            >
              Create new
            </MainPrimaryButton>
          </div>
        </span>
        {loadingStrategies ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : strategies && strategies?.data?.data.length ? (
          <>
            <div className="strategy-container">
              {strategies?.data?.data.map((strategy, idx) => {
                const isInActiveSequenceChanges =
                  !!sequenceChangesShorterThan5Minutes?.find(
                    (sc) => sc.strategyId === strategy.id,
                  );
                return (
                  <div
                    className={classNames('strategy-card-wrapper', {
                      'selected-strategy-wrapper':
                        strategyOverviewContent?.strategyId === strategy.id,
                    })}
                    key={strategy.id}
                  >
                    <LoadingOverlay
                      isLoading={isInActiveSequenceChanges}
                      loadingText={'Applying changes...'}
                      className={'strategy-card'}
                    >
                      <div
                        className="strategy-left-container"
                        onClick={() => {
                          if (windowContent) closeWindow();
                          if (strategy.sequence) setEditSequenceId(strategy.id);
                        }}
                      >
                        <div className="strategy-header-left">
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={colors.grey11}
                            noWrap
                          >
                            {strategy.user}
                          </Typography>

                          <Typography fontSize={16} fontWeight={600} noWrap>
                            {strategy.name}
                          </Typography>

                          {/* {externalPropertyTags?.data?.data &&
                          (externalPropertyTags?.data?.data.length ?? 0) >
                            0 && (
                            <div
                              className="left-bottom-tags-wrapper"
                              ref={tagsRef}
                            >
                              {renderTags(
                                externalPropertyTags?.data?.data,
                                strategy,
                                tagsClientWidth,
                              )}
                            </div>
                          )} */}
                          <TagsBoxShowAll
                            type="AccountAndProspect"
                            blockIsEditable={true}
                            disableEdit={true}
                            currentSituationTags={
                              strategy.strategyTags?.map((tag) => {
                                return {
                                  externalPropertyId: tag.externalPropertyId,
                                  option: tag.value,
                                };
                              }) ?? []
                            }
                            showRemovable={false}
                          />
                        </div>
                        <div className="strategy-steps">
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={colors.grey11}
                            className="strategy-total-steps"
                          >
                            Total steps: {strategy.numberOfSteps}
                          </Typography>
                          <div
                            className="strategy-progress-icons"
                            ref={stepsRef}
                          >
                            {strategy.sequence?.steps?.length &&
                              sliceAndAddHoverStep(
                                strategy.sequence.steps,
                                renderStepComponent,
                                nStepsToShow,
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="strategy-right-container">
                        <div className="analitycs-div">
                          {analytics.map((analytic, index) => {
                            return (
                              <>
                                <div
                                  className="analityc-box"
                                  key={index}
                                  onClick={() =>
                                    handleSelectedFilter(
                                      strategy.id,
                                      analytic.filterStatus,
                                    )
                                  }
                                >
                                  <Typography
                                    fontSize={14}
                                    fontWeight={500}
                                    lineHeight={'18px'}
                                    color={colors.grey11}
                                    noWrap
                                  >
                                    {analytic.text}
                                  </Typography>
                                  <Typography
                                    fontSize={14}
                                    fontWeight={500}
                                    lineHeight={'24px'}
                                    color={analytic.numberColor}
                                    noWrap
                                  >
                                    {(strategy[analytic.fieldName] as string) ??
                                      0}{' '}
                                    {analytic.percentage &&
                                      '(' +
                                        Math.round(
                                          ((strategy[
                                            analytic.fieldName
                                          ] as number) /
                                            ((strategy.useCount as number) ||
                                              1)) *
                                            100,
                                        ) +
                                        '%)'}
                                  </Typography>
                                </div>
                                {index < analytics.length - 1 ? (
                                  <div className="divider" />
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                        <ClickAwayListener
                          onClickAway={() => setOptionSequenceToogle(null)}
                        >
                          <div>
                            <CustomOptionsTooltip
                              placement="bottom"
                              title={
                                <SequenceOptions
                                  addContacts={handleAddContacts}
                                  stopStrategy={() =>
                                    handleStopStrategy(
                                      strategy.id,
                                      strategy.activeCount,
                                    )
                                  }
                                  duplicateStrategy={() =>
                                    handleDuplicateStrategy(strategy.id)
                                  }
                                  modifyStrategy={() => {
                                    if (strategy.sequence)
                                      setEditSequenceId(strategy.id);
                                  }}
                                  deleteStrategy={() =>
                                    handleDeleteStrategy(strategy.id)
                                  }
                                  enableResume={true}
                                  sequenceId={strategy.id}
                                />
                              }
                              maxHeight={260}
                              width={240}
                              left={-56}
                              borderRadius={'16px'}
                              open={optionSequenceToogle === strategy.id}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <div
                                className="strategy-options"
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  setOptionSequenceToogle(
                                    (prev: number | null) => {
                                      if (prev === null) {
                                        return strategy.id;
                                      } else {
                                        return null;
                                      }
                                    },
                                  );
                                }}
                              >
                                <ThreeDotsIcon />
                              </div>
                            </CustomOptionsTooltip>
                          </div>
                        </ClickAwayListener>
                      </div>
                    </LoadingOverlay>
                  </div>
                );
              })}
            </div>
            <Pagination
              count={strategies?.data?.count}
              total={strategies?.data?.total}
              itemOffset={offset}
              setItemOffset={setOffset}
              limit={Constants.defaultTemplatesLimit}
            />
          </>
        ) : (
          <FlexDiv
            direction="column"
            gap="20px"
            style={{ paddingBottom: '100px' }}
          >
            <NoStrategies />
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <Typography
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'24px'}
                textAlign={'center'}
              >
                No strategies found with these filters
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                lineHeight={'24px'}
                color={colors.grey11}
                textAlign={'center'}
                maxWidth={'300px'}
              >
                Try to change the applied filters to see all the available
                strategies.
              </Typography>
            </div>
          </FlexDiv>
        )}
      </div>
    </StrategyWrapper>
  );
};

export default StrategySettings;
