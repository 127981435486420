import React, { useMemo, useState } from 'react';
import { IVariable, Variables } from '../model';
import { TemplateVariables } from 'crono-fe-common/types/template';
import { TemplateVariablesSelectorWrapper } from './style';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { getExternalPropertyForVariables } from 'utils/fe-utils';
import { Divider, Typography } from '@mui/material';
import IconRightArrow from 'crono-fe-common/icons/Icon-Right-Arrow';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { createPortal } from 'react-dom';
import ExternalPropertyTemplateVariablesSelector from './externalPropertiesTemplateVariablesSelector';
import { useAuth } from 'context/auth';
import Role from 'crono-fe-common/types/enums/role';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';
import { ITemplateVariables } from 'crono-fe-common/types/templateVariables';

interface IProps {
  addVariables: (variable: TemplateVariables | string) => void;
}

const VariablesSelector = ({ addVariables }: IProps) => {
  const { highestRole } = useAuth();

  const { data: externalProperties } = useGetExternalProperty(null, null);
  const { data: templateVariables } = useGetTemplateVariables();

  const VariablesToShow = useMemo(() => {
    const templateVars: ITemplateVariables | undefined =
      templateVariables?.data?.data;

    const completedVarsList: IVariable[] = [
      ...Variables,
      ...getExternalPropertyForVariables(externalProperties?.data?.data),
    ];

    if (!templateVars) return completedVarsList;

    if (templateVars.productName?.length) {
      completedVarsList.push({
        variable: templateVars.productNameTemplateVariable,
        text: 'Product name',
      });
    }

    if (templateVars.productDescription?.length) {
      completedVarsList.push({
        variable: templateVars.productDescriptionTemplateVariable,
        text: 'Product description',
      });
    }

    if (templateVars.productReferences.length) {
      templateVars.productReferences.slice(0, 3).map((_, i) => {
        completedVarsList.push({
          variable: templateVars.productReferencesTemplateVariables[i],
          text: `Customer reference ${i + 1}`,
        });
      });
    }

    if (templateVars.productBenefits.length) {
      templateVars.productBenefits.slice(0, 3).map((_, i) => {
        completedVarsList.push({
          variable: templateVars.productBenefitsTemplateVariables[i],
          text: `Product benefit ${i + 1}`,
        });
      });
    }

    if (templateVars.productIndustries.length) {
      templateVars.productIndustries.slice(0, 3).map((_, i) => {
        completedVarsList.push({
          variable: templateVars.productIndustriesTemplateVariables[i],
          text: `Industry challenge ${i + 1}`,
        });
      });
    }

    if (templateVars.templateSnippets.length) {
      templateVars.templateSnippets
        .slice(0, 3)
        .map(({ label, templateVariableName }) => {
          completedVarsList.push({
            variable: templateVariableName,
            text: label,
          });
        });
    }

    return completedVarsList;
  }, [externalProperties, templateVariables]);

  const [showOtherVariables, setShowOtherVariables] = useState(false);

  return (
    <>
      {showOtherVariables &&
        //Portal to escape the tooltip
        createPortal(
          <BlurBackgroundDivFixed
            onClick={() => setShowOtherVariables(false)}
            style={{ zIndex: 1000000 }}
          >
            <ExternalPropertyTemplateVariablesSelector
              externalProperties={externalProperties?.data?.data ?? []}
              setShowOtherVariables={setShowOtherVariables}
            />
          </BlurBackgroundDivFixed>,
          document.body,
        )}
      <TemplateVariablesSelectorWrapper>
        {VariablesToShow.map((variable) => {
          return (
            <div
              key={variable.text}
              onClick={() => addVariables(variable.variable)}
              className="variable-item"
            >
              {variable.text}
            </div>
          );
        })}
        {highestRole === Role.MANAGER && (
          <>
            <Divider />
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowOtherVariables(true);
              }}
              className="variable-item other-variables"
            >
              <Typography variant="inherit">Other Variables</Typography>
              <IconRightArrow style={{ width: 16, height: 16 }} />
            </div>
          </>
        )}
      </TemplateVariablesSelectorWrapper>
    </>
  );
};

export default VariablesSelector;
