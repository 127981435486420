import useGetUsers from 'hooks/services/subscription/useGetUsers';
import React, { useMemo, useRef, useState } from 'react';
import { Column } from '@ant-design/charts';
import { GraphData } from '../tabsControllers/model';
import { AnalyticsTask } from 'crono-fe-common/types/analyticsTask';
import { TaskOverviewLegend, colorsChart } from '../legendData';
import { calcWidth, numberOfGroups, selectChartContainerClass } from '../model';
import { Typography } from '@mui/material';
import Legend from '../legend';
import { BarChartPlaceholder } from './chartsPlaceholders';
import { colors } from 'crono-fe-common/theme';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';

interface IProps {
  taskAnalytics: AnalyticsTask | undefined;
  switchRemoveFromCrmValue: boolean;
  onSwitchRemoveFromCrm: () => void;
}

const TaskOverviewChart = ({
  taskAnalytics,
  switchRemoveFromCrmValue,
  onSwitchRemoveFromCrm,
}: IProps) => {
  const { data: users } = useGetUsers();

  const [maxValue, setMaxValue] = useState<number>(0);

  const dataGeneral = useMemo(() => {
    if (!users || !taskAnalytics) return [];
    const userMapping = new Map<number, string>();
    users?.data?.data?.forEach((user) => {
      userMapping.set(user.id, user.firstName + ' ' + user.lastName);
    });
    let newValue = 0;
    const newData: GraphData[] = [];
    taskAnalytics?.overview.Calls?.forEach((call) => {
      const user = userMapping.get(call.userId);
      if (!user) return;
      if (call.count > newValue) newValue = call.count;

      newData.push({
        label: user,
        value: call.count,
        type: 'Calls',
      });
    });
    taskAnalytics?.overview.Emails?.forEach((email) => {
      const user = userMapping.get(email.userId);
      if (!user) return;
      if (email.count > newValue) newValue = email.count;
      newData.push({
        label: user,
        value: email.count,
        type: 'Emails',
      });
    });
    taskAnalytics?.overview.LinkedinInvitations?.forEach(
      (linkedinInvitation) => {
        const user = userMapping.get(linkedinInvitation.userId);
        if (!user) return;

        if (linkedinInvitation.count > newValue)
          newValue = linkedinInvitation.count;
        newData.push({
          label: user,
          value: linkedinInvitation.count,
          type: 'Linkedin invitations',
        });
      },
    );
    taskAnalytics?.overview.Linkedins?.forEach((linkedin) => {
      const user = userMapping.get(linkedin.userId);
      if (!user) return;

      if (linkedin.count > newValue) newValue = linkedin.count;
      newData.push({
        label: user,
        value: linkedin.count,
        type: 'Linkedin messages',
      });
    });
    taskAnalytics?.overview.LinkedinInMails?.forEach((linkedinInMail) => {
      const user = userMapping.get(linkedinInMail.userId);
      if (!user) return;

      if (linkedinInMail.count > newValue) newValue = linkedinInMail.count;
      newData.push({
        label: user,
        value: linkedinInMail.count,
        type: 'Linkedin InMails',
      });
    });
    setMaxValue(newValue);
    return newData;
  }, [taskAnalytics, users]);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const configGeneral = useMemo(() => {
    return {
      data: [...dataGeneral],
      xField: 'label',
      yField: 'value',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index =
          TaskOverviewLegend.findIndex((legend) => legend === type) ?? 0;
        return colorsChart[index];
      }) as any,
      autoFit: false,
      height: 300,
      meta: {
        value: {
          min: 0,
          max: maxValue * 1.2,
        },
      },
      width: calcWidth(
        dataGeneral,
        180,
        chartContainerRef.current?.offsetWidth,
      ),
      isGroup: true,
      columnStyle: {},
      renderer: 'svg' as 'svg' | 'canvas' | undefined,
      //If only one ground is present, the graph doesn't work if dodgePadding is set
      dodgePadding: numberOfGroups(dataGeneral) > 1 ? 6 : undefined,

      maxColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      legend: false as any,
      randomVal: Math.random() * 10000,
    };
  }, [dataGeneral, chartContainerRef.current?.offsetWidth, maxValue]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 24,
        }}
      >
        <Typography className="chart-title">Overall Activity</Typography>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            fontSize={'12px'}
            fontWeight={500}
            lineHeight={'16px'}
            color={colors.grey11}
          >
            Remove CRM tasks
          </Typography>

          <CronoSwitch
            value={switchRemoveFromCrmValue}
            onClick={onSwitchRemoveFromCrm}
          />
        </div>
      </div>

      <div
        className={selectChartContainerClass(dataGeneral)}
        ref={chartContainerRef}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 40,
            position: 'sticky',
            left: 0,
          }}
        >
          <Legend colors={colorsChart} fields={TaskOverviewLegend} />
        </div>
        {configGeneral?.data && configGeneral.data.length ? (
          <Column {...configGeneral} key={configGeneral.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <BarChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default TaskOverviewChart;
