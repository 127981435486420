import { AsyncStatus, defaultInitialState, State } from 'hooks/useAsync';
import HubspotService, {
  HubspotVerifyIntegrationResult,
} from './HubspotService';
import { useCallback, useState } from 'react';

const useVerifyHubspotIntegration = () => {
  const [{ error, isLoading, data, status }, setState] =
    useState<State<HubspotVerifyIntegrationResult>>(defaultInitialState);

  const verifyHubspotIntegration = useCallback(async () => {
    setState({
      error: null,
      status: AsyncStatus.pending,
      data: null,
      isLoading: true,
    });
    const resultPromise = HubspotService.verifyHubspotIntegration();
    resultPromise.then(
      (result) => {
        setState({
          error: null,
          status: AsyncStatus.resolved,
          data: result,
          isLoading: false,
        });
      },
      (error) => {
        setState({
          error: error,
          status: AsyncStatus.rejected,
          data: null,
          isLoading: false,
        });
      },
    );
  }, [setState]);

  return {
    verifyHubspotIntegration,
    error,
    isLoading,
    isError:
      status === AsyncStatus.rejected ||
      (data?.data ? !data?.data.isSuccess : false),
    isSuccess: status === AsyncStatus.resolved && data?.data.isSuccess,
    data,
  };
};

export default useVerifyHubspotIntegration;
