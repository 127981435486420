import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const StageColumnWrapper = styled.div`
  width: 270px !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${colors.white};
  border-radius: 16px;
  border: 1px solid ${colors.grey444};
  padding: 4px 4px 0px 4px;
  .stage-header-box {
    width: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  .stage-opportunities-list {
    flex: 1;
    padding: 12px 12px 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    overflow: auto;
  }
  .load-more-button {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    opacity: 0.5;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
    user-select: none;
  }
  .info-icon {
    width: 12px !important;
    height: 12px !important;
  }
  .info-icon > path {
    stroke: ${colors.black};
    stroke-width: 1.5;
  }
`;
