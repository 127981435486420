import { ENDPOINTS } from 'config/endpoints';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { TaskPatch } from 'crono-fe-common/types/DTO/taskPatch';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';

export default function usePatchTask() {
  const queryClient = useQueryClient();

  const patchTask = (taskPatch: TaskPatch) => {
    const request = createPatchTaskRequest(taskPatch);
    return client(request);
  };

  return useMutation<Response<null>, CronoError, TaskPatch>(patchTask, {
    onSettled: (_d, _e, variables) => {
      if (
        variables.automatic ||
        variables.delay !== undefined ||
        variables.activityDate !== undefined
      ) {
        CronoAutomaticTaskExecutor.stopDelay();
      }
      invalidatePatchTaskQueries(queryClient);
    },
  });
}

export function createPatchTaskRequest(taskPatch: TaskPatch): Request {
  return {
    url: `${ENDPOINTS.task.main}`,
    config: {
      method: 'patch',
      data: { data: taskPatch },
    },
  };
}

export function invalidatePatchTaskQueries(queryClient: QueryClient) {
  return Promise.all([
    queryClient.invalidateQueries([ENDPOINTS.task.search]),
    queryClient.invalidateQueries([ENDPOINTS.task.overdue]),
    queryClient.invalidateQueries([ENDPOINTS.task.next]),
    queryClient.invalidateQueries([ENDPOINTS.task.aggregate]),
    queryClient.invalidateQueries([ENDPOINTS.task.total]),
    queryClient.invalidateQueries([ENDPOINTS.task.automationErrors]),

    queryClient.invalidateQueries([ENDPOINTS.sequence.main]),
    queryClient.invalidateQueries([ENDPOINTS.sequence.prospect]),
    queryClient.invalidateQueries([ENDPOINTS.sequence.search]),
    queryClient.invalidateQueries([ENDPOINTS.sequence.detail]),
    queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]),
    queryClient.invalidateQueries([ENDPOINTS.prospect.search.strategy]),

    queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]),
  ]);
}
