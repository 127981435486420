import { LinkedinSearchCategoryType } from 'crono-fe-common/constants/constants';
import { LinkedinFilterValue } from 'crono-fe-common/types/crono-extension/linkedin';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { CallFeedback, QuickAction } from 'crono-fe-common/types/logCall';

export class FeConstants {
  static emailEditorPlugins =
    'advlist autolink lists link image charmap print preview anchor ' +
    'searchreplace visualblocks code fullscreen ' +
    'insertdatetime media table paste code wordcount link';

  static emailEditorToolbar =
    'undo redo |  fontsize ' +
    'bold italic backcolor forecolor attachmentsButton  templatesButton variablesButton |  |lineheight |fontfamily | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    ' link image';
  static emailEditorTemplateToolbar =
    'undo redo |  fontsize ' +
    'bold italic backcolor forecolor attachmentsButton templatesButton |  |lineheight |fontfamily | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    ' link image';

  static linkedinEditorToolbar =
    'undo redo | attachmentsButton variablesButton';
  static linkedinInvitationEditorToolbar = 'undo redo | variablesButton';

  static editorToolbarFormats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'indent',
    'link',
    'width',
    'color',
    'code-block',
    'placeholder',
    'html-blot',
  ];

  static editorToolbarAdvanceFormattingFormats = ['list', 'bullet'];

  static htmlBlotTagName = 'section';
  static editorSupportedFonts = [
    'Andale Mono',
    'Arial',
    'Arial-Black',
    'Book Antiqua',
    'Comic Sans MS',
    'Courier New',
    'Geneva',
    'Helvetica',
    'Impact',
    'Symbol',
    'Tahoma',
    'Terminal',
    'Times New Roman',
    'Verdana',
    'Roboto',
    'Monospace',
    'Trebuchet MS',
  ];

  static editorSupportedFontSizes = [
    '8pt',
    '10pt',
    '12pt',
    '14pt',
    '18pt',
    '24pt',
    '36pt',
  ];

  static SALESFORCE_LOGIN_URL = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${
    process.env.REACT_APP_SALESFORCE_CLIENT_ID
  }&redirect_uri=${encodeURI(
    process.env.REACT_APP_FE_URL as string,
  )}/connect/salesforce/callback`;

  static gmailScopes =
    'email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/user.emails.read';

  static hubspotScopes =
    'crm.objects.contacts.read crm.objects.contacts.write crm.objects.contacts.read crm.objects.contacts.write crm.objects.companies.write crm.objects.companies.read crm.objects.deals.write crm.objects.deals.read crm.objects.owners.read sales-email-read conversations.read';

  static HUBSPOT_LOGIN_URL = `https://app.hubspot.com/oauth/authorize?client_id=${
    process.env.REACT_APP_HUBSPOT_CLIENT_ID
  }&redirect_uri=${encodeURI(
    process.env.REACT_APP_FE_URL as string,
  )}/connect/hubspot/callback&scope=${encodeURIComponent(
    FeConstants.hubspotScopes,
  )}`;

  static PIPEDRIVE_LOGIN_URL = `https://oauth.pipedrive.com/oauth/authorize?client_id=${
    process.env.REACT_APP_PIPEDRIVE_CLIENT_ID
  }&redirect_uri=${encodeURI(
    process.env.REACT_APP_FE_URL as string,
  )}/connect/pipedrive/callback`;

  static AIRCALL_LOGIN_URL = `https://dashboard.aircall.io/oauth/authorize?client_id=${
    process.env.REACT_APP_AIRCALL_CLIENT_ID
  }&redirect_uri=${encodeURI(
    process.env.REACT_APP_FE_URL as string,
  )}/connect/aircall/callback&response_type=code&scope=public_api`;

  static outlookScopes =
    'openid offline_access https://graph.microsoft.com/mail.send https://graph.microsoft.com/mail.read https://graph.microsoft.com/Mail.ReadWrite';

  static OUTLOOK_LOGIN_URL = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
    process.env.REACT_APP_OUTLOOK_CLIENT_ID
  }&redirect_uri=${encodeURI(
    process.env.REACT_APP_FE_URL as string,
  )}/connect/outlook/callback&response_type=code&response_mode=query&scope=${encodeURIComponent(
    FeConstants.outlookScopes,
  )}`;

  static LINKEDIN_LOGIN_URL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
    process.env.REACT_APP_LINKEDIN_CLIENT_ID
  }&redirect_uri=${encodeURI(
    process.env.REACT_APP_FE_URL as string,
  )}/connect/linkedin/callback&scope=${encodeURIComponent(
    'r_basicprofile r_1st_connections_size',
  )}`; // can't use r_compliance_2l, r_1st_connections

  static urlRegex =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  static websiteRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  static emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static phoneRegex =
    /((\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/;
  static linkedinRegex =
    /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)\/([-a-zA-Z0-9]+)\/*/gm;

  static defaultReportLimit = 100;
  static downloadReportLimit = 1000;
  static maxImportAccounts = 500;
  static maxImportAccountsCronoIntegration = 1000;
  static maxImportProspects = 500;
  static maxImportProspectsCronoIntegration = 1000;

  static externalPropertyProspectsInitialIndex = 7;
  static externalPropertyAccountsInitialIndex = 3;

  static uploadFileLimit = 12;
  static maxFilesPerTransaction = 5;

  static emailBoundary = 'foo_bar_baz';

  static defaultNewStrategyName = 'New strategy';
  static defaultNewStrategyId = -99;

  static cronoSessionCookie = process.env
    .REACT_APP_CRONO_SESSION_COOKIE as string;

  static cronoThankYouCookie = process.env
    .REACT_APP_CRONO_THANK_YOU_COOKIE as string;

  static actionsProgressBatch = 20;

  static minStepForSequence = 1;
  static maxStepForSequence = 16;

  static minLicenseAmount = 1;
  static maxLicenseAmount = 100;

  static MAX_DELAY = 30;
  static MIN_DELAY = 0;

  static strategyLimit = 20;

  static contactPageLimit = 25;

  static freeTrialLength = 14;

  static opportunityStageLimit = 20;

  static MINIMUM_STATUSES = 3;

  static MAX_STATUSES = 12;

  static defaultImageUrl =
    'https://crono-assets.s3.eu-central-1.amazonaws.com/defaultCompanyLogo.png';

  static quickActions = [
    {
      id: 0,
      title: 'Call Back',
      type: QuickAction.CALL_BACK,
    },
    {
      id: 1,
      title: 'Stop Prospect',
      type: QuickAction.STOP_PROSPECT,
    },
    {
      id: 2,
      title: 'Nurture',
      type: QuickAction.NURTURE,
    },
    {
      id: 3,
      title: 'Not in target',
      type: QuickAction.INACTIVE,
    },
    {
      id: 4,
      title: 'Meeting',
      type: QuickAction.CALL_SCHEDULED,
    },
  ];

  static callFeedback = [
    {
      id: 4,
      title: 'Wrong Number',
      type: CallFeedback.WRONG_NUMBER,
    },
    {
      id: 1,
      title: 'Not Answered',
      type: CallFeedback.NOT_ANSWERED,
    },
    {
      id: 2,
      title: 'Voicemail',
      type: CallFeedback.VOICEMAIL,
    },
    {
      id: 3,
      title: 'Gatekeeper',
      type: CallFeedback.GATE_KEEPER,
    },
    {
      id: 0,
      title: 'Connected',
      type: CallFeedback.CONNECTED,
    },
  ];

  static notificationTimeSpan = 4 * 60 * 60 * 1000; // 4 hours

  static gmailInstructionURL = 'https://admin.google.com/ac/owl';

  static gmailClientId =
    '228143010081-5tqb4q8gdnmik5nb3ap976hs70rp7njg.apps.googleusercontent.com';

  static gmailGuideText = `
  1. Whitelist Crono App on Google Workspace. To make sure Crono can safely interact with your Gmail accounts please follow these steps.
  2. Go to this admin page to set up a third-party app. Click on configure new app and select “Client ID”.
  3. Paste our Client ID (${this.gmailClientId}) and click “Search”.
  4. Click “Select” on Crono App.
  5. Set app access to “Trusted” and click “Configure”. Your organization can now connect to Crono!
  6. Now click “Connect” below here to connect your Gmail Account`;

  static abTestingOptions =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  static websitePricingURL = 'http://www.crono.one/pricing';

  static websiteURL = 'http://www.crono.one/';

  static helpLinkDefault = 'https://help.crono.one/en';
  static helpLinkIntegrations = 'https://help.crono.one/en/getting-started';
  static helpLinkContactsCompanies =
    'https://help.crono.one/en/contacts-companies';
  static helpLinkTemplates = 'https://help.crono.one/en/templates';
  static helpLinkStrategy = 'https://help.crono.one/en/strategies';
  // TODO tasks article not created yet
  static helpLinkTask = '';

  static placeholderUrl =
    'https://www.youtube.com/embed/cvh0nX08nRw?si=brVa44uA9XduRrTf';
  static companyContactsSetupUrl =
    'https://www.youtube.com/embed/qz0dOWJjImA?si=HI9SNp_l2bbr-LhM';

  static templateSetupUrl =
    'https://www.youtube.com/embed/5mQunwtyI8A?si=ECRtUjLRktcvinzo';
  static stategySetupUrl =
    'https://www.youtube.com/embed/KrX01L9KBBM?si=n7z1eO41omAKEOT1';
  static taskExecutionUrl =
    'https://www.youtube.com/embed/CfQWCbRVXrk?si=StkkmA0AKd5ugb58';
}

export const linkedinGeoUrns: LinkedinSearchCategoryType[] = [
  { value: '101452733', label: 'Australia' },
  { value: '107042567', label: 'Adelaide, Australia' },
  { value: '100425729', label: 'Bahrain' },
  { value: '104468365', label: 'Brisbane, Australia' },
  { value: '106089960', label: 'Canberra, Australia' },
  { value: '100992797', label: 'Melbourne, Australia' },
  { value: '103392068', label: 'Perth, Australia' },
  { value: '104769905', label: 'Sydney, Australia' },
  { value: '103883259', label: 'Austria' },
  { value: '100565514', label: 'Belgium' },
  { value: '101174742', label: 'Canada' },
  { value: '101728226', label: 'Montreal, Canada' },
  { value: '106234700', label: 'Ottawa, Canada' },
  { value: '100025096', label: 'Toronto, Canada' },
  { value: '103366113', label: 'Vancouver, Canada' },
  { value: '104514075', label: 'Denmark' },
  { value: '102974008', label: 'Estonia' },
  { value: '105015875', label: 'France' },
  { value: '106383538', label: 'Paris, France' },
  { value: '103815258', label: 'Lyon, France' },
  { value: '101282230', label: 'Germany' },
  { value: '100477049', label: 'Munich, Germany' },
  { value: '103035651', label: 'Berlin, Germany' },
  { value: '90009714', label: 'Frankfurt Rhine, Germany' },
  { value: '103239229', label: 'Kuwait' },
  { value: '102478259', label: 'Indonesia' },
  { value: '102644903', label: 'Bekasi, Indonesia' },
  { value: '102708774', label: 'Bogor, Indonesia' },
  { value: '106331850', label: 'Depok, Indonesia' },
  { value: '104370960', label: 'Jakarta, Indonesia' },
  { value: '105898461', label: 'Tangerang, Indonesia' },
  { value: '104738515', label: 'Ireland' },
  { value: '103350119', label: 'Italy' },
  { value: '102873640', label: 'Milan, Italy' },
  { value: '102334534', label: 'Rome, Italy' },
  { value: '106742401', label: 'Turin, Italy' },
  { value: '104948657', label: 'Brescia, Italy' },
  { value: '105768355', label: 'Bologna, Italy' },
  { value: '101544895', label: 'Florence, Italy' },
  { value: '102361685', label: 'Naples, Italy' },
  { value: '104843836', label: 'Abruzzo, Italy' },
  { value: '106271685', label: 'Basilicata, Italy' },
  { value: '104193506', label: 'Calabria, Italy' },
  { value: '101168731', label: 'Campania, Italy' },
  { value: '101165094', label: 'Emilia Romagna, Italy' },
  { value: '100700905', label: 'Friuli-Venezia Giulia, Italy' },
  { value: '104758761', label: 'Lazio, Italy' },
  { value: '106409273', label: 'Liguria, Italy' },
  { value: '102960788', label: 'Lombardia, Italy' },
  { value: '104590035', label: 'Marche, Italy' },
  { value: '101052278', label: 'Molise, Italy' },
  { value: '104396997', label: 'Piemonte, Italy' },
  { value: '103477765', label: 'Puglia, Italy' },
  { value: '106056685', label: 'Sicilia, Italy' },
  { value: '105265841', label: 'Sardegna, Italy' },
  { value: '101083819', label: 'Toscana, Italy' },
  { value: '105285498', label: 'Trentino-Alto Adige, Italy' },
  { value: '102840047', label: 'Umbria, Italy' },
  { value: '100633933', label: 'Valle D’Aosta, Italy' },
  { value: '106933435', label: 'Veneto, Italy' },
  { value: '104341318', label: 'Latvia' },
  { value: '101464403', label: 'Lithuania' },
  { value: '104042105', label: 'Luxemburg' },
  { value: '106808692', label: 'Malaysia' },
  { value: '107510587', label: 'Johor Bahru, Malaysia' },
  { value: '105563270', label: 'Kuala Lumpur, Malaysia' },
  { value: '105521927', label: 'Selangor, Malaysia' },
  { value: '103323778', label: 'Mexico' },
  { value: '102890719', label: 'Netherlands' },
  { value: '103619019', label: 'Oman' },
  { value: '101762205', label: 'Muscat, Oman' },
  { value: '105072130', label: 'Poland' },
  { value: '100364837', label: 'Portugal' },
  { value: '102775852', label: 'Lisbon, Portugal' },
  { value: '104170880', label: 'Qatar' },
  { value: '106203786', label: 'Doha, Qatar' },
  { value: '100459316', label: 'Saudi Arabia' },
  { value: '109019211', label: 'Jeddah, Saudi Arabia' },
  { value: '101336206', label: 'Riyadh, Saudi Arabia' },
  { value: '102454443', label: 'Singapore' },
  { value: '105646813', label: 'Spain' },
  { value: '101734461', label: 'Alicante, Spain' },
  { value: '105088894', label: 'Barcelona, Spain' },
  { value: '101962740', label: 'Bilbao, Spain' },
  { value: '100994331', label: 'Madrid, Spain' },
  { value: '104401670', label: 'Malaga, Spain' },
  { value: '105512687', label: 'Valencia, Spain' },
  { value: '106693272', label: 'Switzerland' },
  { value: '105117694', label: 'Sweden' },
  { value: '105146118', label: 'Thailand' },
  { value: '106343449', label: 'Bangkok, Thailand' },
  { value: '104114836', label: 'Göteborg, Sweden' },
  { value: '101759788', label: 'Malmö, Sweden' },
  { value: '104853962', label: 'Stockholm, Sweden' },
  { value: '101165590', label: 'United Kingdom' },
  { value: '102257491', label: 'London, United Kingdom' },
  { value: '114456426', label: 'Manchester, United Kingdom' },
  { value: '102264497', label: 'Ukraine' },
  { value: '104305776', label: 'United Arab Emirates' },
  { value: '106204383', label: 'Dubai, United Arab Emirates' },
  { value: '103720977', label: 'Abu Dhabi, United Arab Emirates' },
  { value: '103644278', label: 'United States' },
  { value: '90000070', label: 'New York City, United States' },
  { value: '102380872', label: 'Boston, United States' },
  { value: '102394087', label: 'Miami, United States' },
  { value: '102277331', label: 'San Francisco, United States' },
  { value: '103743442', label: 'Houston, United States' },
];

export const linkedinNumberOfEmployees: LinkedinFilterValue[] = [
  { id: 'B', label: '1-10' },
  { id: 'C', label: '11-50' },
  { id: 'D', label: '51-200' },
  { id: 'E', label: '201-500' },
  { id: 'F', label: '501-1000' },
  { id: 'G', label: '1001-5000' },
  { id: 'H', label: '5001-10000' },
  { id: 'I', label: '10001+' },
];

export const rowPlaceholderFillOpacities = [
  0.49, 0.44, 0.39, 0.34, 0.29, 0.24, 0.19, 0.14,
];

export const planFeatures: {
  FreeTrial: string[];
  Starter: string[];
  Pro: string[];
  Ultra: string[];
} = {
  [SubscriptionPlanType.FREE_TRIAL]: [
    'Multichannel sequences',
    'Email Finder (10 credits)',
    'Email Verifier (100 credits)',
    'Phone Number Finder (5 credits)',
    '100 max contacts/companies created',
    'LinkedIn Extension',
    'Email Integration (Gmail, Outlook)',
    'Email Tracking',
    'Analytics & Reports',
    'Suggestions / Live feed',
  ],
  [SubscriptionPlanType.STARTER]: [
    'Multichannel sequences (unlimited)',
    'Email Finder (100 credits/mo)',
    'Email Verifier (100 credits/mo)',
    'Phone Number Finder (10 credits/mo)',
    'AI Sequence Generator',
    'LinkedIn Extension',
    'Email Integration (Gmail, Outlook)',
    'Email Tracking',
    'Analytics & Reports',
    'Suggestions / Live feed',
  ],
  [SubscriptionPlanType.PRO]: [
    'Everything in Starter, plus:',
    '+ Email Finder (300 credits/mo)',
    '+ Email Verifier (300 credits/mo)',
    '+ Phone Number Finder (30 credits/mo)',
    '+ CRM Integration (HubSpot, Pipedrive)',
    '+ Aircall Integration',
    '+ Customer Success',
    '+ AI-Assisted Email Writing',
    '+ Automated Email Sequences',
    '+ Automated LinkedIn Sequences',
    '+ Email Open & Click Tracking',
    '+ AI Generated Icebreakers',
    '+ A/B Testing',
  ],
  [SubscriptionPlanType.ULTRA]: [
    'Everything in Pro, plus:',
    '+ Email Finder (500 credits/mo)',
    '+ Email Verifier (500 credits/mo)',
    '+ Phone Number Finder (50 credits/mo)',
    '+ CRM Integration (Salesforce)',
    '+ Personalised 1:1 onboarding',
    '+ Manager Views',
    '+ Dedicated Customer Success Manager',
    '+ Sales Processes Consulting',
    '+ Templates and Strategies Consulting',
    '+ Custom CRM Fields Mapping',
  ],
};

export const stagesColors = {
  pink: {
    light: '#FFE3F0',
    dark: '#FD67AF',
  },
  fucsia: {
    light: '#FDE5F8',
    dark: '#E769CB',
  },
  purple: {
    light: '#F2EAFF',
    dark: '#8846DC',
  },
  deepblue: {
    light: '#E2E9FF',
    dark: '#627BFE',
  },
  blue: {
    light: '#D3EDFF',
    dark: '#3B85E8',
  },
  lightBlue: {
    light: '#D7F1FA',
    dark: '#1E9EBA',
  },
  acquamarine: {
    light: '#DBF2F2',
    dark: '#0A9B94',
  },
  lime: {
    light: '#EBF1DC',
    dark: '#66AA51',
  },
  yellow: {
    light: '#FEF3D2',
    dark: '#C69812',
  },
  orange: {
    light: '#FFEBDD',
    dark: '#E38D3D',
  },
  green: {
    light: '#DFF1E6',
    dark: '#24BA84',
  },
  red: {
    light: '#FFE2E2',
    dark: '#ED4C5E',
  },
};

export interface StageColors {
  light: string;
  dark: string;
}

export const WonAndLostColors: StageColors[] = [
  stagesColors.green,
  stagesColors.red,
];

export const stagesColorsMap: {
  [key: number]: StageColors[];
} = {
  4: [
    stagesColors.blue,
    stagesColors.yellow,
    stagesColors.green,
    stagesColors.red,
  ],
  5: [
    stagesColors.fucsia,
    stagesColors.blue,
    stagesColors.yellow,
    stagesColors.green,
    stagesColors.red,
  ],
  6: [
    stagesColors.fucsia,
    stagesColors.purple,
    stagesColors.blue,
    stagesColors.yellow,
    stagesColors.green,
    stagesColors.red,
  ],
  7: [
    stagesColors.fucsia,
    stagesColors.purple,
    stagesColors.blue,
    stagesColors.yellow,
    stagesColors.orange,
    stagesColors.green,
    stagesColors.red,
  ],
  8: [
    stagesColors.fucsia,
    stagesColors.purple,
    stagesColors.blue,
    stagesColors.lightBlue,
    stagesColors.yellow,
    stagesColors.orange,
    stagesColors.green,
    stagesColors.red,
  ],
  9: [
    stagesColors.pink,
    stagesColors.fucsia,
    stagesColors.purple,
    stagesColors.blue,
    stagesColors.lightBlue,
    stagesColors.yellow,
    stagesColors.orange,
    stagesColors.green,
    stagesColors.red,
  ],
  10: [
    stagesColors.pink,
    stagesColors.fucsia,
    stagesColors.purple,
    stagesColors.deepblue,
    stagesColors.blue,
    stagesColors.lightBlue,
    stagesColors.yellow,
    stagesColors.orange,
    stagesColors.green,
    stagesColors.red,
  ],
  11: [
    stagesColors.pink,
    stagesColors.fucsia,
    stagesColors.purple,
    stagesColors.deepblue,
    stagesColors.blue,
    stagesColors.lightBlue,
    stagesColors.acquamarine,
    stagesColors.yellow,
    stagesColors.orange,
    stagesColors.green,
    stagesColors.red,
  ],
  12: [
    stagesColors.pink,
    stagesColors.fucsia,
    stagesColors.purple,
    stagesColors.deepblue,
    stagesColors.blue,
    stagesColors.lightBlue,
    stagesColors.acquamarine,
    stagesColors.lime,
    stagesColors.yellow,
    stagesColors.orange,
    stagesColors.green,
    stagesColors.red,
  ],
};
