import { AnalyticsTooltipWrapper } from './style';
import { Button } from '@mui/material';
import PATH from 'routing/path';
import { useNavigate } from 'react-router-dom';
import IconAnalytics from 'crono-fe-common/icons/Icon-Analytics';
import { colors } from 'crono-fe-common/theme';
import IconReports from 'crono-fe-common/icons/Icon-Reports';

interface IProps {
  onClick: () => void;
}

const OpenAnalyticsTooltip = ({ onClick }: IProps) => {
  const navigate = useNavigate();
  return (
    <AnalyticsTooltipWrapper>
      <Button
        className="analytics-button"
        color="secondary"
        fullWidth
        endIcon={<IconAnalytics color={colors.grey11} />}
        onClick={() => {
          navigate(PATH.ANALYTICS);
          onClick();
        }}
      >
        Analytics
      </Button>
      <div className="divider" />
      <Button
        className="analytics-button"
        color="secondary"
        fullWidth
        endIcon={<IconReports color={colors.grey11} />}
        onClick={() => {
          navigate(PATH.REPORTS);
          onClick();
        }}
      >
        Reports
      </Button>
    </AnalyticsTooltipWrapper>
  );
};

export default OpenAnalyticsTooltip;
