import { useAuth } from 'context/auth';
import React, { FC, useEffect } from 'react';
import { ResetPasswordFlowWrapper } from './styles';
import PATH from 'routing/path';
import Logo from 'assets/images/logo_crono.png';
import { useNavigate } from 'react-router-dom';

const ResetPasswordFlowContainer: FC<{ children: any }> = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(PATH.HOME);
    }
  }, [user]);

  if (user) {
    return <></>;
  }

  const redirectToLogin = () => {
    navigate(PATH.LOGIN);
  };

  return (
    <ResetPasswordFlowWrapper>
      <div className="logo-header">
        <img
          src={Logo}
          className="logo"
          alt="Crono Logo"
          onClick={redirectToLogin}
        />
      </div>
      {children}
    </ResetPasswordFlowWrapper>
  );
};

export default ResetPasswordFlowContainer;
