import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';
// import dashedLine from "crono-fe-common/icons/Vertical-dashed-line.svg";

export const StrategyDetailWrapper = styled.div`
  overflow: hidden auto;

  .steps-out-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    background: ${colors.white};
    flex: 1;
    padding: 6px;
    overflow: hidden;
  }

  .overflow-main {
    overflow: hidden auto;
  }

  .steps-container {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    // height: 100%;
    width: 100%;
    z-index: 1;
    padding-right: 2px;
  }

  .steps-line {
    border-left: 1px dashed ${colors.grey33};
    position: absolute;
    left: 30px;
    height: calc(100% + 20px);
    z-index: -1;
  }
  .start-triangle {
    position: absolute;
    left: -7px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-top: 6px solid ${colors.grey33};
  }
  .end-triangle {
    position: absolute;
    left: -7px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 6px solid ${colors.grey33};
  }
  .strategy-options {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid ${colors.grey4};
  }
  .strategy-options:hover {
    background-color: ${colors.blueLightHover};
  }

  .last-step-container {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .number-container {
      margin-left: 30px;
    }

    .add-step-placeholder {
      width: calc(100% - 72px);
    }
  }
`;

export const StrategyOverviewWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 1500;
  -webkit-box-shadow: 5px 6px 14px -2px #757575;
  -moz-box-shadow: 5px 6px 14px -2px #757575;
  -o-box-shadow: 5px 6px 14px -2px #757575;
  box-shadow: 5px 6px 14px -2px #757575;
  width: 73%;

  //Make this 100% if less that 1000px width
  @media (max-width: 1000px) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  z-index: 10;

  .tabs-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 18px;
    border-bottom: 1px solid ${colors.grey444};
    background-color: ${colors.grey6};
  }

  .sequence-detail-header {
    display: flex;
    position: sticky;
    width: 100%;
    background: ${colors.grey6};
    flex-direction: column;
  }
  .sequence-detail-header-title {
    display: flex;
    padding: 18px 18px 0px 18px;
  }
  .header-tags-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0px 0px 12px 16px;
  }
  .close-detail-button {
    background: ${colors.greyBackgroundScheduled} !important;
  }
  .close-button-wrapper {
    width: 60px;
    display: flex;
    height: 48px;
    justify-content: center;
  }
  .header-buttons-container {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .sequence-detail-container {
    // height: calc(100% - 115px);
    background: ${colors.grey6};
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    overflow: hidden auto;
    flex: 1;
  }
  .close-button {
    align-self: flex-end;
    cursor: pointer;
    z-index: 1001;
    height: 24px;
    width: 24px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 8px;
  }
  .title:hover {
    background: ${colors.grey4};
  }
  .tags-container {
    display: flex;
    column-gap: 12px;
    row-gap: 8px;
    flex-wrap: wrap;
  }

  .strategy-options {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid ${colors.grey33};
  }
  .strategy-options:hover {
    background-color: ${colors.blueLightHover};
  }
  .template-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    min-height: 32px;

    .template-button-icon {
      height: 16px;
      width: 16px;
    }
  }
  .template-button-container:hover {
    background-color: ${colors.grey444};
  }

  .insights-button {
    background-color: #ffd748 !important;
    padding-right: 10px;
    margin-inline: 4px;
  }

  .insights-button:hover {
    filter: brightness(0.8);
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .icon-wrapper {
    padding: 4px;
  }
`;

export const BlurStrategyModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 27%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  //Make this 100% if less that 1000px width
  @media (max-width: 1000px) {
    display: none;
  }
`;
