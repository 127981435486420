import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { StrategyLibraryModalWrapper } from './style';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { TextField, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { useCallback, useEffect, useState } from 'react';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import useSearchStrategyLibrary from 'hooks/services/sequence/useSearchStrategyLibrary';
import { Strategy } from 'crono-fe-common/types/strategy';
import { Sequence } from 'crono-fe-common/types/sequence';
import StrategyStepsIcons from '../strategyStepsIcons';
import classNames from 'classnames';
import { useSequenceContext } from 'context/strategyDetail/context/sequence';
import useGetLibraryStrategy from 'hooks/services/sequence/useGetLibraryStrategy';

interface IProps {
  onClose: () => void;
  onGoBack: () => void;
  openStrategyPreview: () => void;
}

const StrategyLibrary: React.FC<IProps> = ({
  onClose,
  onGoBack,
  openStrategyPreview,
}: IProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedStrategyId, setSelectedStrategyId] = useState<number | null>(
    null,
  );

  const { data: libraryStrategies } = useSearchStrategyLibrary({
    limit: 10,
    offset: 0,
    name: searchQuery,
  });

  const { data: fetchedSelectedStrategy, refetch: refetchOneStrategy } =
    useGetLibraryStrategy(
      { strategyId: selectedStrategyId ? selectedStrategyId.toString() : '' },
      !!selectedStrategyId,
    );

  const { setSequence } = useSequenceContext();

  useEffect(() => {
    if (selectedStrategyId) {
      refetchOneStrategy();
    }
  }, [selectedStrategyId, refetchOneStrategy]);

  const sequenceContainsConditionals = useCallback((sequence?: Sequence) => {
    return (
      sequence?.steps?.some((step) => !!step.sequenceStepConditional) ?? false
    );
  }, []);

  const handleNextButton = useCallback(() => {
    if (!selectedStrategyId) return;

    const sequenceData: Sequence | undefined =
      fetchedSelectedStrategy?.data?.data;

    if (!sequenceData) return;

    setSequence(sequenceData);
    onClose();
    openStrategyPreview();
  }, [selectedStrategyId, fetchedSelectedStrategy]);

  return (
    <BlurBackgroundDivFixed onClick={onClose}>
      <StrategyLibraryModalWrapper>
        <div className="strategy-library" onClick={(e) => e.stopPropagation()}>
          <div className="strategy-library__cross">
            <CloseTabButton
              style={{ paddingRight: '24px' }}
              close={() => {
                onClose();
              }}
            />
          </div>

          <div className="strategy-library__title">
            <Typography
              fontSize={24}
              fontWeight={700}
              lineHeight={'30px'}
              color={colors.black}
            >
              Strategy Library
            </Typography>
          </div>

          <div className="strategy-library__search">
            <span className="strategy-library__search-icon">
              <SearchIcon color={colors.grey11} />
            </span>

            <TextField
              variant="standard"
              placeholder="Search..."
              autoComplete="off"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
              fullWidth
              InputProps={{
                endAdornment:
                  searchQuery === '' ? null : (
                    <CloseMIcon
                      className="remove-text-icon"
                      color={colors.grey11}
                      onClick={() => {
                        setSearchQuery('');
                      }}
                    />
                  ),
              }}
            />
          </div>

          <div className="strategy-library__list-container">
            <div className="strategy-library__list">
              {libraryStrategies?.data?.data.map(({ sequence, id }) => {
                const strategyDuration: number =
                  sequence?.steps?.reduce((sum, step) => sum + step.delay, 0) ??
                  0;

                return (
                  <div
                    key={id}
                    className={classNames('strategy-library__item', {
                      'strategy-library__item--selected':
                        selectedStrategyId === id,
                    })}
                    onClick={() => setSelectedStrategyId(id)}
                  >
                    <div className="strategy-library__item-top">
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        lineHeight={'18px'}
                        color={colors.black}
                        marginBottom={'4px'}
                      >
                        {sequence?.name ?? ''}
                      </Typography>

                      <div className="strategy-library__item-info">
                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          lineHeight={'16px'}
                          color={colors.grey11}
                        >
                          {`Total steps: ${sequence?.numberOfSteps ?? 1}`}
                        </Typography>

                        <div className="strategy-library__item-divider" />

                        <Typography
                          fontSize={12}
                          fontWeight={400}
                          lineHeight={'16px'}
                          color={colors.grey11}
                        >
                          {`Duration: ~${strategyDuration ?? 1} days`}
                        </Typography>
                      </div>
                    </div>

                    <div className="strategy-library__item-icons">
                      <StrategyStepsIcons
                        steps={sequence?.steps || []}
                        activeConditionLinkedinInvitation={sequenceContainsConditionals(
                          sequence,
                        )}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="strategy-library__buttons">
            <CancelButton width="120px" fontWeight="400" onClick={onGoBack}>
              Back
            </CancelButton>

            <MainButton
              width="120px"
              fontWeight="400"
              disabled={!selectedStrategyId}
              onClick={handleNextButton}
            >
              Next
            </MainButton>
          </div>
        </div>
      </StrategyLibraryModalWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default StrategyLibrary;
