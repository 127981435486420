import React, { useEffect, useState } from 'react';
import { FiltersWrapper } from './style';
import ProspectFilters from './prospectFilters';
import AccountFilters from './accountFilters';
import { DashboardTabType } from '../index';

interface IFilters {
  tabState: DashboardTabType;
  close: () => void;
}

const Filters = ({ tabState, close }: IFilters) => {
  return (
    <FiltersWrapper>
      <div className="background" onClick={() => close()} />
      {tabState === 'Company' ? (
        <AccountFilters close={close} />
      ) : (
        <ProspectFilters close={close} tabState={tabState} />
      )}
    </FiltersWrapper>
  );
};

export default Filters;
