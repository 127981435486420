import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const VariablesPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  gap: 32px;
  padding-bottom: 24px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .label {
    position: relative;

    &__title {
      margin: 0 0 8px;
      color: ${colors.grey11};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .input {
    height: 48px;
    max-width: 600px;
    width: 100%;
    background-color: ${colors.grey6};
    padding: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    border: none;
    border-radius: 8px;

    &::placeholder {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    &:focus {
      outline: none;
    }

    &--shrinked {
      width: max-content;
    }

    &--textarea {
      resize: none;
      min-height: 48px;
      overflow: hidden;
      box-sizing: border-box;
    }
  }

  .input-wrapper:hover .input {
    background-color: ${colors.grey444};
  }

  .references-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .icon-trash {
    position: absolute;
    top: 9px;
    right: 12px;
    display: none;
    background-color: ${colors.white};
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }

  .input-wrapper {
    position: relative;

    &:hover .icon-trash {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .edit-pencil {
    display: none;
    margin-left: 10px;
  }

  .snippet {
    &__title {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: ${colors.grey11};
    }

    &__top {
      display: flex;
      align-items: center;
      margin: 0 0 8px;
      width: max-content;
      cursor: pointer;

      &:hover .edit-pencil {
        display: inline-flex;
      }

      &:hover .snippet__title {
        color: ${colors.darkGreen};
      }
    }
  }
`;
