import styled from 'styled-components';
import { colors } from 'crono-fe-common/theme';
import { centerFlexColumn } from 'styles/mixins';

export const ScheduleWrapper = styled.div`
  width: 80%;
  max-width: 600px;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.white};
  border-radius: 16px;
  overflow: hidden;
  padding: 24px 36px 50px 36px;
  z-index: 3000;

  .MuiPickersDay-root.Mui-selected {
    border-radius: 18px;
    color: ${colors.white} !important;
    background-color: ${colors.primary} !important;
    &:hover {
      background-color: ${colors.primaryDark} !important;
    }
  }
`;

export const MailerModalWrapper = styled.div`
  width: 100%;
  max-height: calc(100% - 130px);
  .prospect-top-container {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding-bottom: 8px;
    margin-bottom: 4px;
    width: 100%;
  }
  .prospect-top {
    overflow: hidden;
  }
  .prospect-avatar-row {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    margin-right: 16px;
    size: 16px !important;
    font-size: 16px !important;
    height: 32px;
    width: 32px;
  }
  .header-email-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 40px;
  }

  .subject-selector-container {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: flex-end;
    padding-bottom: 6px;
    gap: 8px;
  }
  .subject-selector-container > svg {
    opacity: 1;
    cursor: pointer;
  }
  // .subject-selector-container:hover > svg {
  //   opacity: 1;
  // }

  .bottom-line {
    border-bottom: 1px solid ${colors.grey5};
  }
  .ab-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.purpleLight};
    width: 40px;
    height: 25px;
    border-radius: 12px;
    padding: 4px 8px;
  }

  .mailer-button-container {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .attach-icon {
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid ${colors.borderGrey} !important;
    border-radius: 30%;
    height: 25px;
    width: 25px;
    padding: 2px;
  }

  .file-preview {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .add-new-field {
    width: 300px;
    display: inline;
    margin-top: 10px;
  }

  .file {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 30px;
    background: ${colors.callScheduledLightHover};
    border-radius: 8px;
    padding: 8px;
  }
  .disabled-file {
    cursor: not-allowed;
    opacity: 0.7;
    .delete-attachments-icon {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  login-field {
    width: 500px;
    display: inline;
  }

  .add-new-field-select {
    width: 300px;
    background-color: ${colors.lightGrey};
    height: 40px;
    border-radius: 20px;
  }

  .remove-thread-button {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    border-radius: 8px;
    padding: 8px 8px 8px 12px;
    white-space: nowrap;
    margin: 0px;
    height: 37px;
    border-width: 1px;
  }

  .add-new-auto-complete {
    height: 40px;
    .MuiOutlinedInput-root {
      padding-left: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 40px;
    }

    input {
      padding-left: 10px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 40px !important;
    }
  }

  .add-new-field-select fieldset {
    border: none;
  }

  .scheduled-action-info {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
  }

  .add-new-field input {
    background-color: ${colors.lightGrey};
    height: 30px;
    border: 0px !important;
    border-radius: 20px;
    padding: 5px 10px;
    width: 100%;
  }

  .add-new-field fieldset {
    border: none;
  }

  .message-channel-container {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .delete-attachments-icon {
    cursor: pointer;
  }

  .generate-with-ai-container {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
  }
  .generate-with-ai-button {
    text-transform: unset !important;
    background: ${colors.grey4Hover};
    color: ${colors.black};
    font-weight: 600;
    font-size: 14px;
    padding: 13px 17px;
    border-radius: 8px;
  }

  .editor {
    background: ${colors.black};
  }

  .button-container {
    max-width: 55%;
    display: flex;
    position: absolute;
    bottom: 0;
    margin: 10px;
  }

  .mail-button {
    font-size: 16px;
    padding: 10px 25px;
    border-radius: 40px 0px 0px 40px;
    text-transform: unset !important;
    height: 40px;
  }

  .mail-icon-button {
    font-size: 16px;
    border-radius: 0px 40px 40px 0px;
    background: ${colors.black};
    color: ${colors.white};
  }

  .send-button {
    &:hover {
      background: ${colors.grey1};
    }
  }

  .schedule-button {
    &:hover {
      background: ${colors.grey1};
    }

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 70%;
      top: 50%;
      transform: translateY(-50%);
      left: -0.5px;
      background: ${colors.grey1};
    }
  }

  .scheduled-email-cancel-banner {
    width: 100%;
    margin: 16px;
    display: flex;
    gap: 8px;
    border-radius: 8px;
    background: ${colors.greyBackgroundScheduled};
    color: ${colors.grey11};
    padding: 12px;
    align-items: center;
    .click-to-cancel-text {
      cursor: pointer;
      color: ${colors.black};
      :hover {
        color: ${colors.primary};
      }
    }
  }

  .task-item {
    min-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .thread-button {
    text-transform: unset !important;
    color: ${colors.grey11};
    min-width: 121px;
    white-space: nowrap;
    padding-block: 0px;
  }

  .cc-button {
    margin-left: 10px;
    cursor: pointer;
    font-weight: 500;
    margin-block: 0px;
  }

  .cc-button-selected {
    margin-left: 10px;
    cursor: pointer;
    color: ${colors.primary};
    font-weight: 500;
    margin-block: 0px;
  }

  @media (max-width: 1200px) {
    .modal-box {
      width: 100%;
      border-radius: 0;
    }
  }

  .template-activities-cell > div {
    height: 50%;
    overflow-y: auto;
  }

  .template-activities-cell {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .activities-container {
      padding: 8px;
      flex-grow: 1;
      overflow: auto;
      height: 200px;
    }

    .templates-card {
      height: 200px;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
    }

    .activities-card {
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
    }

    .activities-title-container {
      padding: 10px 20px;

      border-bottom: 1px solid #e5e5e5;
      h2 {
        margin: 0;
      }
    }
  }

  .tox {
    border-bottom: 0px solid red !important;
    overflow: hidden;
  }
  .editor-container {
    position: relative;
    flex: 1;
    border: 1px solid ${colors.grey444};
    border-top: 0px solid ${colors.grey444};
    position: relative;
    background: ${colors.grey6};
    border-radius: 16px;
    margin-top: 16px;
    flex: 1;
    overflow: hidden;

    //the editor
    & > div:first-child {
      height: calc(100% - 60px);

      .ql-container {
        border-radius: 0;
      }
    }
  }
`;

export const TemplatesTooltipCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid ${colors.grey444};
  /* -webkit-box-shadow: -5px 0px 20px -10px #757575;
  -moz-box-shadow: -5px 0px 20px -10px #757575;
  -o-box-shadow: -5px 0px 20px -10px #757575;
  box-shadow: -5px 0px 20px -10px #757575; */
  .template-selector {
    width: 100%;
    height: 100%;
    right: 0;
    background: ${colors.white};
    z-index: 2000;
    overflow: auto;
  }

  .templates-card-header {
    gap: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 18px 10px 10px 16px;
    h2 {
      margin: 0;
    }
    .MuiFormControl-root {
      margin: 0;
    }
  }

  .button-templates {
    background: ${colors.grey4};
    text-transform: unset !important;
    color: ${colors.black};
  }

  .plus-template-button {
    background: ${colors.black};
  }

  .select-medium {
    width: 200px;
  }

  .select-small {
    width: 100px;
  }

  .templates-container {
    min-height: fit-content;
    max-height: 470px;
    display: flex;
    flex-direction: column;
  }

  .add-icon {
    margin-left: 16px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border: 1px solid ${colors.borderGrey} !important;
    border-radius: 30%;
  }

  .template-analytics-header-container {
    height: 20px;
    justify-content: space-between;
    margin: 6px 6px 4px 0px;
    padding: 0px 0px 8px 16px;
    color: ${colors.grey11};
    font-weight: 500;
    border-bottom: 1px solid ${colors.borderGrey} !important;
  }

  .template-analytics-icon-container {
    display: flex;
    justify-content: end;
    color: ${colors.nurtureBlue};
  }

  .template-analytics-header-item-percent {
    margin-right: 27px;
  }

  .template-analytics-header-item {
    margin-right: 26px;
  }

  .no-templates {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${colors.grey11};
    min-height: 200px;
  }

  .no-templates-link {
    color: ${colors.nurtureBlue};
    cursor: pointer;
    font-weight: 500;
  }
  .search-templates {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    .text-field {
      width: 95%;
      .MuiInput-underline:after {
        border-bottom: 2px solid ${colors.grey11};
      }
    }
  }
  .tag-filter-container {
    height: 100%;
    border: 1px solid ${colors.grey3};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 14px;
    width: 90px;
    cursor: pointer;
  }
  .tag-filter-container:hover {
    border: 1px solid ${colors.black};
  }
  .tags-selected {
    background: ${colors.grey4};
    width: auto;
  }
  .number-of {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    height: 24px;
    width: 24px;
    background: ${colors.grey55};
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.grey3};
    border-radius: 8px;
    min-width: 40px;
    height: 40px;
    gap: 4px;
    padding-inline: 8px;
    cursor: pointer;
  }
  .filter-button:hover {
    background-color: ${colors.blueLightHover};
  }
`;

export const TemplatesCardWrapper = styled.div`
  width: 50%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  overflow: hidden;
  border-right: 1px solid ${colors.grey444};
  -webkit-box-shadow: -5px 0px 20px -10px #757575;
  -moz-box-shadow: -5px 0px 20px -10px #757575;
  -o-box-shadow: -5px 0px 20px -10px #757575;
  box-shadow: -5px 0px 20px -10px #757575;
  .template-selector {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    background: ${colors.white};
    z-index: 2000;
  }

  .templates-card-header {
    gap: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 18px 10px 10px 16px;
    h2 {
      margin: 0;
    }
    .MuiFormControl-root {
      margin: 0;
    }
  }

  .button-templates {
    background: ${colors.grey4};
    text-transform: unset !important;
    color: ${colors.black};
  }

  .plus-template-button {
    background: ${colors.black};
  }

  .MuiOutlinedInput-notchedOutline {
    //border-color: ${colors.primary} !important;
  }

  .select-medium {
    width: 200px;
  }

  .select-small {
    width: 100px;
  }

  .templates-container {
    height: calc(100% - 180px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-inline: 5px;
  }

  .add-icon {
    margin-left: 16px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border: 1px solid ${colors.borderGrey} !important;
    border-radius: 30%;
  }

  .template-analytics-header-container {
    height: 20px;
    justify-content: space-between;
    margin: 6px 6px 4px 0px;
    padding: 0px 0px 8px 16px;
    color: ${colors.grey11};
    font-weight: 500;
    border-bottom: 1px solid ${colors.borderGrey} !important;
  }

  .template-analytics-icon-container {
    display: flex;
    justify-content: end;
    color: ${colors.nurtureBlue};
  }

  .template-analytics-header-item-percent {
    margin-right: 27px;
  }

  .template-analytics-header-item {
    margin-right: 26px;
  }

  .no-templates {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${colors.grey11};
  }

  .no-templates-link {
    color: ${colors.nurtureBlue};
    cursor: pointer;
    font-weight: 500;
  }
  .search-templates {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    .text-field {
      width: 95%;
      .MuiInput-underline:after {
        border-bottom: 2px solid ${colors.grey11};
      }
    }
  }
  .tag-filter-container {
    height: 100%;
    border: 1px solid ${colors.grey3};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 14px;
    width: 90px;
    cursor: pointer;
  }
  .tag-filter-container:hover {
    border: 1px solid ${colors.black};
  }
  .tags-selected {
    background: ${colors.grey4};
    width: auto;
  }
`;

export const TemplateWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0px 10px 15px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  margin: 4px;

  ${({ selected }) =>
    selected &&
    `
    background: ${colors.grey6};
    border-left: 4px solid ${colors.black};
  `}

  &:hover {
    background: ${colors.grey6};
  }

  .template-analytics-container {
    justify-content: end;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    color: ${colors.nurtureBlue};
    padding-right: 3px;
  }
  .ab-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.purpleLight};
    width: 40px;
    height: 25px;
    border-radius: 12px;
    padding: 4px 8px;
  }
  .template-title-display {
    width: 100%;
  }
  .template-title-display:hover {
    color: ${colors.darkGreen};
  }

  .template-analytics-item {
    width: 40px;
    display: flex;
    justify-content: center;
    border-radius: 999px;
  }

  .usage-item {
    color: ${colors.blueHover};
  }

  .open-item {
    color: ${colors.prospecting};
  }

  .reply-item {
    color: ${colors.callScheduledHover};
  }

  .used {
    color: ${colors.disabledGrey};
  }

  .template-analytics-item-percent {
    width: 40px;
    display: flex;
    justify-content: end;
    // margin-right: 5px;
    margin: 0px 1px;
  }

  .vertical-divider {
    border: 1px solid ${colors.grey4};
    height: 20px;
  }
  .horizontal-divider {
    border-top: 1px solid ${colors.grey4};
    width: 100%;
  }
  .not-owned-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
  }
  .not-owned-icon > path {
    stroke: ${colors.black};
  }
  .not-owned-icon:hover > path {
    stroke: ${colors.primaryDark};
  }
`;

export const EmailThreadModalWrapper = styled.div`
  z-index: 6000;
  background: ${colors.white};
  width: 100%;
  height: 100%;
  padding: 0px;

  .MuiGrid-item {
    width: 100%;
  }

  h2 {
    margin-bottom: 2px;
  }

  .error {
    color: ${colors.error};
    font-weight: 500;
    font-size: 1.25rem;
  }

  .prospect-container {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    margin-bottom: 10px;
  }

  .prospect-role {
    font-weight: 500;
    font-size: 16px;
  }

  .modal-header {
    height: 100px;
    background-color: white;
    border-radius: 20px;
    padding: 20px 40px 10px 0px;
    margin-bottom: 10px;
  }

  .modal-header h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
  }

  .modal-body {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px 20px 20px 0px;
  }

  .error-mail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .close-modal-icon {
    color: ${colors.primary};
    font-size: 40px;
    cursor: pointer;
  }

  .linkedin-icon {
    color: ${colors.linkedin};
    font-size: 22px;
    cursor: pointer;
    margin-right: 10px;
  }

  .layout-grid {
    width: 100%;
    height: 100%;
    min-height: 600px;
  }

  .error-p {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }

  @media screen and (max-width: 768px) {
    .modal-box {
      width: 100% !important;
    }
  }

  .prospect-name {
    font-weight: 500;
    font-size: 16px;
  }

  .account-name {
    font-weight: 400;
    color: ${colors.primary};
  }

  .modal-button {
    margin-left: 40px;
  }

  .message-container {
    width: 100%;
    margin-bottom: 10px;
    min-height: 40px;
  }

  .message-header {
    cursor: pointer;
  }

  .message-header-selected {
    cursor: pointer;
    border-radius: 8px 8px 0px 0px;
    background-color: ${colors.primaryDisabled};
    border-bottom: solid 0.5px ${colors.black};
  }

  .reply-container {
    margin-bottom: 10px;
    width: 95%;
  }

  .reply-button {
    width: 100px;
    border-radius: 999px;
    text-transform: unset !important;
  }

  .message-sender {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  .message-subject {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 400;
  }

  .message-date {
    margin-right: 20px;
    color: ${colors.grey11};
  }

  .message-body {
    width: 100%;
    margin-left: 30px;
    margin-top: 20px;
    align-items: flex-start;
    border-left: 2px solid ${colors.grey4Hover};
    padding-left: 10px;
  }

  .no-thread {
    white-space: pre-line;
  }
`;

export const BlurInsightsCard = styled.div`
  width: 50%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0;
  right: 150%;
  background: rgba(0, 0, 0, 0.5);
`;

export const InsightsCardWrapper = styled.div`
  width: 50%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  overflow: hidden;
  border-right: 1px solid ${colors.grey444};
  -webkit-box-shadow: -5px 0px 20px -10px #757575;
  -moz-box-shadow: -5px 0px 20px -10px #757575;
  -o-box-shadow: -5px 0px 20px -10px #757575;
  box-shadow: -5px 0px 20px -10px #757575;

  .insight-card-container {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    background: ${colors.white};
    /* overflow: hidden auto; */
  }
  .insight-card-header {
    gap: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 18px 10px 10px 16px;
  }
  .insight-card-body {
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    /* margin-right: 4px; */
    gap: 12px;
    width: 100%;
    max-width: 100%;
    overflow: hidden auto;
  }
  .insights-rewrite-section {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid ${colors.grey444};

    .rewrite-inner-wrapper {
      border-radius: 12px;
      background: ${colors.grey6};
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 4px;
      position: relative;

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .insights-rewrite-response-modal {
    ${centerFlexColumn};
    overflow: hidden;
    gap: 8px;
    position: absolute;
    right: 8px;
    left: 8px;
    bottom: 8px;
    border-radius: 16px;
    border-top: 1px solid ${colors.grey444};
    padding: 16px;
    background: ${colors.white};

    .feedback-divider {
      all: unset;
      height: 1px;
      width: 130%;
      background: ${colors.grey444};
    }

    .response {
      padding: 8px;
      background: ${colors.grey6};
      border-radius: 8px;
      width: 100%;
      max-height: 70vh;
      overflow: auto;

      #editor-wrapper {
        .ql-container {
          border: none;
        }
        .ql-editor {
          padding: 0;
        }
        #insights-editor-toolbar {
          display: none;
        }
      }
    }

    .feedback-container {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      background: ${colors.grey6};
      border-radius: 8px;
      width: 100%;
      gap: 8px;
      overflow: hidden;
      flex-wrap: wrap;

      input {
        all: unset;
        min-width: 200px;
        font-size: 14px;
        flex: 1;
      }

      button {
        margin: 0;
        margin-left: auto;
        height: 32px;
      }
    }
    .buttons-container {
      display: flex;
      gap: 8px;
      width: 100%;
      justify-items: flex-end;

      button {
        height: 42px;
        width: 42px;
      }

      .feedback-icon {
        background: ${colors.grey4};
        margin-right: auto;
        &:hover {
          background: ${colors.grey4Hover};
        }
      }

      .delete-icon {
        background: ${colors.inactiveLight};
        &:hover {
          background: ${colors.inactiveLightHover};
        }
      }

      .confirm-icon {
        background: ${colors.callScheduledLight};
        &:hover {
          background: ${colors.primaryLightHover};
        }
      }
    }
  }
  .insight-company-header,
  .insight-prospect-header {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    gap: 16px;
  }
  .insight-company-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .company-name-industry,
  .prospect-name-title {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .blue-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    padding: 4px;
    border-radius: 4px;
    color: ${colors.intenseBlue};
    background-color: ${colors.blueLight};
  }

  .blue-badge > svg > path {
    stroke: ${colors.intenseBlue};
  }
  .horizontal-divider {
    border-top: 1px solid ${colors.grey4};
    width: 100%;
  }

  .redirect-icon-linkedin-url {
    min-height: 32px;
    padding: 4px;
    min-width: 32px;
    cursor: pointer;
  }

  .confirm-company-card {
    display: flex;
    gap: 6px;
    border: 1px solid ${colors.grey444};
    border-radius: 12px;
    width: 100%;
    padding: 6px 10px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .right-side-button-wrapper {
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    cursor: pointer;
  }

  .remove-icon {
    background-color: ${colors.inactiveLight};
  }
  .remove-icon:hover {
    background-color: ${colors.inactiveLightHover};
  }
  .select-icon {
    padding: 8px;
    background-color: ${colors.primaryLight};
  }
  .select-icon:hover {
    background-color: ${colors.primaryLightHover};
  }
  .search-prospect-url {
    background-color: ${colors.primary};
    border-radius: 20px;
    display: flex;
    align-items: center;
    right: 0px;
    margin: 0px 8px;
    min-height: 32px;
    padding: 4px 4px 4px 8px;
    gap: 4px;
    cursor: pointer;
  }
  .search-prospect-url:hover {
    background-color: ${colors.primaryDark};
  }
  .vertical-divider {
    border: 1px solid ${colors.grey4};
    height: 100%;
  }

  .rewrite-textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border: 0px;
    padding: 12px;
    font-family: inherit;
    font-size: 14px;
    background-color: ${colors.grey6};
    border-radius: 12px;

    &:focus {
      outline: none;
    }
  }

  .revert-button {
    position: absolute;
    left: 12px;
    bottom: 8px;

    height: 38px;
    width: 38px;
    border-radius: 50%;

    background: ${colors.grey444};
    margin-right: auto;
    margin-left: auto;
    &:hover {
      background: ${colors.grey33};
    }

    svg > path {
      fill: black !important;
    }
  }
`;
