import { Account, AccountStatus } from 'crono-fe-common/types/account';
import VerificationStatusType from 'crono-fe-common/types/enums/verificationStatusType';
import { Prospect, ProspectStatus } from 'crono-fe-common/types/prospect';

export const MockProspect: Prospect = {
  accountId: '',
  externalValues: null,
  fromContact: false,
  id: 0,
  isInterested: false,
  isKey: false,
  objectId: '',
  owned: false,
  ownerId: '',
  status: ProspectStatus.NOT_CONTACTED,
  userId: 0,
  user: null,
  step: null,
  firstName: 'Johanna',
  lastName: 'Dow',
  name: 'Johanna Dow',
  title: 'Head of Sales',
  emailStatus: VerificationStatusType.UNKNOWN,
  actualStatus: null,
};

export const MockAccount: Account = {
  id: 0,
  objectId: '',
  ownerId: '',
  userId: 0,
  user: null,
  name: 'ABC Inc.',
  actualStatus: AccountStatus.INACTIVE,
  prospects: [MockProspect],
  linkedin: '',
  currentSolution: '',
  externalValues: null,
  owned: false,
  isSynced: false,
};
