import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InsertLinkedinForCompanyWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.white};
  width: 500px;
  max-height: 80%;
  border-radius: 16px;
  padding: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .confirm-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
  }
  .company-name-industry {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .confirm-company-card {
    display: flex;
    padding: 16px;
    align-items: center;
    column-gap: 12px;
    justify-content: space-between;
    border-radius: 16px;
  }
  .confirm-company-card:hover {
    background: ${colors.grey6};
  }
  .confirm-disabled {
    background: ${colors.grey11};
  }
`;
