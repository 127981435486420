import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const EditColumnsDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 239px;
  height: auto;
  padding: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey444};
  border-radius: 8px;

  .edit-columns-top {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 0 0;
    flex: 1;
    overflow: auto;
  }

  .edit-columns-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 8px;
  }

  .edit-columns-item {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 8px 0;
    cursor: pointer;
  }
`;
