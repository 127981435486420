import { Autocomplete, TextField, Typography } from '@mui/material';
import useSequenceDetailsAnalytics from 'hooks/services/analytics/useSequenceDetailsAnalytics';
import useSearchStrategies from 'hooks/services/sequence/useSearchStrategies';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Strategy } from 'crono-fe-common/types/strategy';
import { FeConstants } from 'constants/FeConstants';
import { calcWidth, hasToGroup } from '../model';
import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import Legend from '../legend';
import { colorsChart, colorsChartConversion } from '../legendData';
import { Line } from '@ant-design/charts';
import { useAuth } from 'context/auth';
import { colors } from 'crono-fe-common/theme';
import moment from 'moment';
import { Constants } from 'crono-fe-common/constants/constants';
import styled from '@emotion/styled';
import { LineChartPlaceholder } from './chartsPlaceholders';
import { GraphData } from '../tabsControllers/model';

interface IProps {
  filterParams: AnalyticsFilters;
}

const SequenceConversionChart = ({ filterParams }: IProps) => {
  const { user } = useAuth();
  const [selectedStrategyConversion, setSelectedStrategyConversion] = useState<
    number | null
  >(null);
  const [searchTextConversion, setSearchTextConversion] = useState<string>('');
  const { data: strategiesConversion } = useSearchStrategies({
    limit: Constants.defaultLimit,
    offset: 0,
    name: searchTextConversion,
  });

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const { data: firstUserStrategy } = useSearchStrategies({
    limit: 1,
    offset: 0,
    name: '',
    userId: user?.id,
  });

  useEffect(() => {
    if (
      selectedStrategyConversion === null &&
      firstUserStrategy?.data?.data &&
      firstUserStrategy?.data?.data.length > 0
    ) {
      setSelectedStrategyConversion(firstUserStrategy?.data?.data[0].id);
      setSearchTextConversion(firstUserStrategy?.data?.data[0].name);
    }
  }, [firstUserStrategy]);

  //If the first strategy of the user does not appear in the list of strategies, it is added
  useEffect(() => {
    if (
      strategiesConversion?.data?.data &&
      firstUserStrategy?.data?.data &&
      firstUserStrategy.data.data.length > 0 &&
      selectedStrategyConversion === firstUserStrategy?.data?.data[0].id &&
      !strategiesConversion?.data?.data.find(
        (strategy: Strategy) =>
          strategy.id === firstUserStrategy?.data?.data[0].id,
      )
    ) {
      strategiesConversion?.data?.data.unshift(
        firstUserStrategy?.data?.data[0],
      );
    }
  }, [strategiesConversion, firstUserStrategy, selectedStrategyConversion]);

  const { data: sequenceConversionAnalytics } = useSequenceDetailsAnalytics(
    {
      strategyId: selectedStrategyConversion ?? 0,
      groupBy: hasToGroup(filterParams) ? 'Week' : 'Day',
      ...filterParams,
    },
    selectedStrategyConversion !== null,
  );

  // useEffect(() => {
  //   console.log(sequenceConversionAnalytics);
  // }, [sequenceConversionAnalytics]);

  const [conversionLegend, setConversionLegend] = useState<string[]>([]);

  const [maxValue, setMaxValue] = useState<number>(0);

  const dataConversion = useMemo(() => {
    const strategyName =
      strategiesConversion?.data?.data.find(
        (item) => item.id === selectedStrategyConversion,
      )?.name ?? 'Selected strategy';

    const groupped = hasToGroup(filterParams);

    let newValue = 0;
    let last = '';
    let valueCount = 1;
    const newData: GraphData[] = [];
    sequenceConversionAnalytics?.data?.data.conversion.AnalyticsSequenceConversion?.forEach(
      (item) => {
        if (item.count === 0) return;
        if (item.count > newValue) {
          newValue = item.count;
        }
        const newLabel = moment(item.label).format('MMM');

        if (newLabel !== last) {
          if (groupped) {
            valueCount = 1;
          } else {
            valueCount = +moment(item.label).format('DD');
          }
          last = newLabel;
        }
        newData.push({
          label: newLabel + (groupped ? ', Week ' : ', ') + valueCount++,
          value: item.count,
          type: strategyName,
        });
      },
    );
    setMaxValue(newValue);
    setConversionLegend([strategyName]);
    return newData;
  }, [sequenceConversionAnalytics]);
  const configConversion = useMemo(() => {
    return {
      data: dataConversion,
      xField: 'label',
      yField: 'value',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index =
          conversionLegend.findIndex((legend) => legend === type) ?? 0;
        return colorsChartConversion[index];
      }) as any,
      autoFit: false,
      height: 300,
      meta: {
        value: {
          min: 0,
          max: maxValue * 1.2,
        },
      },
      width: calcWidth(
        dataConversion,
        100,
        chartContainerRef.current?.offsetWidth,
      ),
      isGroup: true,
      columnStyle: {},
      tooltip: {
        customContent: (title: string, data: any[]) => {
          return (
            <div style={{ padding: '14px 4px' }}>
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
                color={colors.grey11}
              >
                {title}
              </Typography>
              {data.map((item, index) => {
                return (
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    key={index}
                  >
                    <div
                      style={{
                        background: item.color,
                        borderRadius: 999,
                        height: 10,
                        width: 10,
                        marginRight: 4,
                      }}
                    />
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'16px'}
                      color={colors.black}
                    >
                      {item.name}: {item.value + '%'}
                    </Typography>
                  </div>
                );
              })}
            </div>
          );
        },
      },
      yAxis: {
        label: {
          formatter: (text: string, item: any, index: number) => {
            return text + '%';
          },
        },
      },
      smooth: true,
      dodgePadding: 6,
      maxColumnWidth: 12,
      minColumnWidth: 12,
      legend: false as any,
      randomVal: Math.random() * 10000,
    };
  }, [
    dataConversion,
    conversionLegend,
    chartContainerRef.current?.offsetWidth,
    maxValue,
  ]);
  const [firstLoad, setFirstLoad] = useState(true);

  const autocompleteValue = useMemo(() => {
    const val = strategiesConversion?.data?.data.find(
      (item) => item.id === selectedStrategyConversion,
    );
    if (val) {
      return val;
    }
  }, [strategiesConversion, selectedStrategyConversion]);

  return (
    <>
      <Typography className="chart-title">Conversion Rate</Typography>
      <div
        className="chart-container"
        style={{ height: 394 }}
        ref={chartContainerRef}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 12,
            position: 'sticky',
            left: 0,
            zIndex: 10,
          }}
        >
          <Autocomplete
            className="input-account detail-picker"
            onChange={(e, value) => {
              setFirstLoad(false);
              setSelectedStrategyConversion((value as Strategy)?.id ?? '');
            }}
            options={[...(strategiesConversion?.data?.data || []), '']}
            getOptionLabel={(account) => (account as Strategy)?.name ?? ''}
            style={{ fontWeight: 700 }}
            openOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Strategy"
                size="small"
                onChange={(e) => {
                  setFirstLoad(false);
                  setSearchTextConversion(e.target.value);
                }}
              />
            )}
            //This way I can show the default value at the beginning, the first strategy of the user. After something is changed I go back to no value
            //for a better user experience
            {...(firstLoad && { value: autocompleteValue ?? '' })}
            freeSolo
            disablePortal
            disableClearable
            ListboxComponent={styled('ul')`
              overflow-x: hidden;
              width: 100%;
              box-sizing: border-box;
              .MuiAutocomplete-option.Mui-focused {
                color: ${colors.primary} !important;
                background-color: ${colors.primaryLight} !important;
              }
              .MuiAutocomplete-option[aria-selected='true'] {
                color: ${colors.primary} !important;
                background-color: ${colors.white} !important;
              }
              li {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                padding: 8px;
                cursor: pointer;
                width: 99%;
                border-radius: 8px;
                z-index: 1000;
              }
            `}
            PaperComponent={({ children }) => (
              <div className="autocomplete-paper">{children}</div>
            )}
            renderOption={(props, option) => {
              if (option === '') return;
              return (
                <li {...props} className="autocomplete-option">
                  {(option as Strategy)?.name}
                </li>
              );
            }}
          />
          <Legend colors={colorsChartConversion} fields={conversionLegend} />
        </div>
        {configConversion?.data && configConversion.data.length ? (
          <Line {...configConversion} key={configConversion.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <LineChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default SequenceConversionChart;
