import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC } from 'react';
import { Account } from 'crono-fe-common/types/account';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Prospect } from 'crono-fe-common/types/prospect';

import { useAuth } from 'context/auth';
import { getFullName } from 'crono-fe-common/types/user';
import { User } from 'crono-fe-common/types/user';
import { CronoButton } from 'crono-fe-common/components/CronoButton';
import { ReportMessage } from 'pages/reports';
import { EmailThreadModalWrapper } from 'crono-fe-common/components/Modal/styles';
import {
  transformToValidUrl,
  cleanPixelFromHtml,
  modalAccount,
  modalProspect,
} from 'utils/fe-utils';

interface MessageReportModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  account: Account | null;
  prospect: Prospect | null;
  reportMessage: ReportMessage;
}

const MessageReportModal: FC<MessageReportModalProps> = ({
  open,
  setOpen,
  account,
  prospect,
  reportMessage,
}) => {
  const { user } = useAuth();

  function handleClose() {
    setOpen(false);
  }

  const handleOpenLinkedin = (linkedin: string) => {
    if (transformToValidUrl(linkedin) != null) {
      window.open(transformToValidUrl(linkedin)!, '_blank');
    }
  };

  const modalLinkedin = (prospect: Prospect | null) => {
    if (
      prospect &&
      prospect.linkedin &&
      transformToValidUrl(prospect.linkedin) != null
    ) {
      return (
        <LinkedInIcon
          className="linkedin-icon"
          onClick={() => handleOpenLinkedin(prospect.linkedin!)}
        />
      );
    }
    return null;
  };

  const renderMessage = (reportMessage: ReportMessage, user: User) => {
    return (
      <FlexDiv
        className="message-container"
        justifyContent="center"
        direction="column"
      >
        <FlexDiv
          className={'message-header-selected'}
          justifyContent="space-between"
        >
          <div className="message-sender">
            {' '}
            {getFullName(user)}
            {reportMessage.subject && ' - '}
            {reportMessage.subject && (
              <span className="message-subject">{reportMessage.subject}</span>
            )}
          </div>
          <p className="message-date">{reportMessage.sentTime}</p>
        </FlexDiv>
        <FlexDiv
          className="message-body"
          justifyContent="space-between"
          direction="column"
        >
          {/* selectedMessage is without pixel, while message not */}
          <div
            style={{ width: '100%' }}
            dangerouslySetInnerHTML={{
              __html:
                reportMessage.type === 'email'
                  ? cleanPixelFromHtml(reportMessage.content)
                  : reportMessage.content,
            }}
          />
          <FlexDiv
            className="reply-container"
            direction="row"
            justifyContent="end"
          >
            <CronoButton
              variant="contained"
              color="primary"
              className="reply-button"
              onClick={() => setOpen(false)}
            >
              Close
            </CronoButton>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    );
  };

  return (
    <EmailThreadModalWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={'modal-box'}>
        <FlexDiv
          direction="column"
          justifyContent="space-around"
          className="modal-header"
        >
          <FlexDiv justifyContent="space-between" alignItems="center">
            <h2>
              {reportMessage.type === 'email' ? 'Email' : 'LinkedIn'}{' '}
              {account && ' - '}
              <span className="account-name">{modalAccount(account)}</span>
            </h2>
            <CancelOutlinedIcon
              className="close-modal-icon"
              onClick={handleClose}
            />
          </FlexDiv>
          {prospect && (
            <div className="prospect-container">
              {modalLinkedin(prospect)}
              <span className="prospect-name">{modalProspect(prospect)}</span>
              &nbsp;
              {prospect?.title && (
                <span className="prospect-role"> {' - ' + prospect.title}</span>
              )}
            </div>
          )}
        </FlexDiv>

        <div className="modal-body">
          {user && renderMessage(reportMessage, user)}
        </div>
      </div>
    </EmailThreadModalWrapper>
  );
};

export default MessageReportModal;
