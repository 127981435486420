import { CronoButton } from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EnrichFromImportWrapper, ImportWrapper } from './styles';
import { FileContent } from 'use-file-picker';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { parse } from 'papaparse';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import useImportAccounts from 'hooks/services/account/useImportAccounts';
import useImportProspects from 'hooks/services/prospect/useImportProspects';
import useImportLeads from 'hooks/services/prospect/useImportLeads';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { FeConstants } from 'constants/FeConstants';
import { colors } from 'crono-fe-common/theme';

import { ReactComponent as DocumentImportSuccess } from 'crono-fe-common/icons/Document-Import-Success.svg';
import { ReactComponent as DocumentImportError } from 'crono-fe-common/icons/Document-Import-Error.svg';
import { ReactComponent as DocumentImportErrorRows } from 'crono-fe-common/icons/Document-Import-Error-Rows.svg';
import { ReactComponent as DocumentIconWhite } from 'crono-fe-common/icons/Document-CSV-Icon.svg';
import { ReactComponent as InformationIcon } from 'crono-fe-common/icons/Icon-S-Information.svg';

import { FileDrop } from 'react-file-drop';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { ImportBehavior } from 'crono-fe-common/types/enums/importType';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import useGetImports from 'hooks/services/import/useGetImports';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';
import { useConfirmModal } from 'context/confirmModal';
import moment from 'moment';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import { trimObject } from 'crono-fe-common/utils/object';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import DataImportStep0 from './DataImportStep0';
import StepsProgress from './Steps';
import { ReactComponent as ContactsIllustration } from 'crono-fe-common/icons/Illustration_contact.svg';
import { ReactComponent as CompaniesIllustration } from 'crono-fe-common/icons/Illustration_companies.svg';
import { Import } from 'crono-fe-common/types/import';
import { useJuneAnalytics } from 'context/june';
import IconFindEmail from 'crono-fe-common/icons/Icon-Find-Email';
import RemainingTokensBadge from 'pages/searchComponent/searchContactTab/insertMultipleContacts/remainingTokensBadge';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import IconFindPhone from 'crono-fe-common/icons/Icon-Find-Phone';
import IconSWarning from 'crono-fe-common/icons/Icon-S-Warning';
import { checkObjectIncludeValue } from 'utils/fe-utils';

export const FailedImportTooltip = styled(
  ({ className, style, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(({ style }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: 300,
    overflow: 'auto',
    position: 'relative',
    top: 0,
    left: 0,
    border: '1px solid #dadde9',
    borderRadius: 16,
    padding: 12,
    maxWidth: 500,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    ...style,
    // "::-webkit-scrollbar": {
    //   width: "0.4em"
    // }
  },
}));

enum ImportType {
  Accounts = 'Accounts',
  Contacts = 'Contacts',
  Leads = 'Leads',
}

export type ImportTypeMessage = {
  type: ImportType;
  description: string;
};

const encodyingFormat = 'UTF-8';

const prospectHeaders = [
  'firstName',
  'lastName',
  'title',
  'email',
  'phone',
  'mobilePhone',
  'linkedin',
  'company',
  'companyWebsite',
  'companyLinkedin',
  'companyCountry',
  'companyIndustry',
  'companyAnnualRevenue',
  'companyNumberOfEmployees',
  'owner',
];

const accountHeaders = [
  'name',
  'numberOfEmployees',
  'annualRevenue',
  'website',
  'linkedin',
  'industry',
  'country',
  'owner',
  'phone',
];

const ImportData: FC = () => {
  const { user } = useAuth();
  const [importType, setImportType] = useState<ImportType | null>(null);
  const [importError, setImportError] = useState<string | null>(null);
  const [importCheckError, setImportCheckError] = useState<string | null>(null);
  // const [loaded, setLoaded] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [headersToMap, setHeadersToMap] = useState<string[]>([]);
  const [cronoHeaders, setCronoHeaders] = useState<string[]>([]);
  const [csvHeadersMap, setCsvHeadersMap] = useState<any>({});
  const [headersErrors, setHeadersErrors] = useState<any>({});
  const [importFileLoading, setImportFileLoading] = useState<boolean>(false);
  const [importTypeMessages, setImportTypeMessages] = useState<
    ImportTypeMessage[]
  >([]);

  useEffect(() => {
    const importTypes: ImportTypeMessage[] = [
      {
        type: ImportType.Accounts,
        description: 'The entries in the file will be imported as accounts.',
      },
      {
        type: ImportType.Contacts,
        description:
          "The entries in the file will be imported as contacts. Accounts will be created if they don't exist.",
      },
    ];
    if (user?.company?.integrationType === IntegrationType.SALESFORCE) {
      importTypes.push({
        type: ImportType.Leads,
        description: 'The entries in the file will be imported as leads.',
      });
    }
    setImportTypeMessages(importTypes);
  }, [user]);

  const [dataExample, setDataExample] = useState<any[]>([]);

  const checkMatch = (header: string, toMatch: string) => {
    const input = toMatch.toLocaleLowerCase().replace(/\s+/g, '');
    return (
      header.toLocaleLowerCase().replace(/\s+/g, '') === input ||
      printCronoHeader(header)
        .toLocaleLowerCase()
        .replace(/\s+/g, '')
        .replace('*', '') === input
    );
  };

  const parseCSVFile = (content: string) => {
    content = content.replace(/\r?\n$/, '');
    //console.log("Content", content);
    parse(content, {
      header: true,
      complete: (results: any) => {
        setImportFileLoading(false);
        if (results.errors.length > 0) {
          setImportError(
            `The csv file is not formatted correctly. Check row ${
              results.errors[0].row + 1
            }.`,
          );
          setDisableNext(true);
        } else {
          if (results.data.length > 0 && results.meta.fields) {
            const trimmed = results.data.map((row: any) => trimObject(row));
            setDataExample(Object.values(trimmed.at(0)) ?? []);
            setHeaders(results.meta.fields ?? []);
            setData(trimmed);
            setDisableNext(false);
            setImportError(null);
            // * setImportType is now set in step 1, no need to set it here
            //I count how many matches there are between the headers of the file and the headers of companies and contacts
            // let accountCounter = 0;

            // for (let i = 0; i < results.meta.fields.length; i++) {
            //   const cronoHeader = accountHeaders.find((h) => {
            //     return checkMatch(h, results.meta.fields[i]);
            //   });

            //   if (cronoHeader) {
            //     accountCounter++;
            //   }
            // }

            // let prospectCounter = 0;
            // for (let i = 0; i < results.meta.fields.length; i++) {
            //   const cronoHeader = prospectHeaders.find((h) => {
            //     return checkMatch(h, results.meta.fields[i]);
            //   });

            //   if (cronoHeader) {
            //     prospectCounter++;
            //   }
            // }
            // ? What happens with leads? are there csv specific for leads that shoud be managed differently?
            // if (accountCounter >= prospectCounter) {
            //   setImportType(ImportType.Accounts);
            // } else {
            //   setImportType(ImportType.Contacts);
            // }
          }
        }
      },
    });
  };

  const getTableType = (importType: ImportType) => {
    switch (importType) {
      case ImportType.Accounts:
        return 'Account';
      case ImportType.Contacts:
        return 'Prospect';
      case ImportType.Leads:
        return 'Lead';
      default:
        return null;
    }
  };

  const {
    data: externalProperties,
    isLoading: loadingExternalProperties,
    refetch: getExternalProperties,
  } = useGetExternalProperty(
    getTableType(importType ?? ImportType.Accounts),
    false,
    false,
    null,
    importType !== null,
  );

  const externalPropertiesEditable = useMemo(() => {
    return externalProperties?.data?.data?.filter(
      (externalProperty) => externalProperty.isEditable,
    );
  }, [externalProperties]);

  //Check if the property is an external property
  const getExternalProperty = useCallback(
    (property: number) => {
      return externalPropertiesEditable?.find(
        (externalProperty) => externalProperty.id === property,
      );
    },
    [externalPropertiesEditable],
  );

  //Get the public name of the external property
  const getPublicName = useCallback(
    (id: number) => {
      return (
        externalPropertiesEditable?.find(
          (externalProperty) => externalProperty.id === id,
        )?.publicName ?? id + ''
      );
    },
    [externalPropertiesEditable],
  );

  const printCronoHeader = (header: string) => {
    switch (header) {
      case 'name':
        return 'Company Name*';
      case 'numberOfEmployees':
        return 'Company size';
      case 'annualRevenue':
        return 'Annual revenue';
      case 'industry':
        return 'Industry';
      case 'website':
        return 'Website';
      case 'linkedin':
        return 'Linkedin';
      case 'firstName':
        return 'First name*';
      case 'lastName':
        return 'Last name*';
      case 'title':
        return 'Title';
      case 'email':
        return 'Email*';
      case 'phone':
        return 'Phone';
      case 'mobilePhone':
        return 'Mobile phone';
      case 'company':
        return 'Company Name';
      case 'country':
      case 'companyCountry':
        return 'Company country';
      case 'companyWebsite':
        return 'Company website';
      case 'companyLinkedin':
        return 'Company linkedin';
      case 'companyIndustry':
        return 'Company industry';
      case 'companyAnnualRevenue':
        return 'Company annual revenue';
      case 'companyNumberOfEmployees':
        return 'Company size';
      case 'owner':
        return 'Owner';
      default:
        //ExternalProperty
        return getPublicName(Number(header));
    }
  };

  const [file, setFile] = useState<FileContent | null>(null);

  useEffect(() => {
    if (!file) return;
    //console.log("File", file);
    parseCSVFile(file.content);
  }, [file]);

  const handleDropFiles = (files: FileList | null) => {
    if (!files || files.length === 0) return;
    const file = files.item(0);
    if (!file) return;
    if (file.type !== 'text/csv') {
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const content = event.target?.result;
      //console.log("First content", content);
      setFile({
        name: file.name,
        content: content as string,
        lastModified: file.lastModified,
      } as FileContent);
    });
    reader.readAsText(file, encodyingFormat);
    setDisableNext(false);
  };

  const { data: importedFiles, refetch: reloadImportedFiles } =
    useGetImports(5);

  useEffect(() => {
    if (
      !(
        importedFiles &&
        importedFiles.data &&
        importedFiles.data.data.length > 0 &&
        importedFiles.data.data.find(
          (f) =>
            f.status === ImportStatusType.OnGoing ||
            f.status === ImportStatusType.Started,
        )
      )
    )
      return;
    const interval = setInterval(() => {
      reloadImportedFiles();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [importedFiles]);

  //Ref to use the file selector with the drop library
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  //To handle file selection with the drop library
  const onFileInputChange = (event: any) => {
    const { files }: { files: FileList } = event.target;
    //console.log("Files", files);
    if (!files || files.length === 0) return;
    const file = files.item(0);
    if (!file) return;
    if (file.type !== 'text/csv' || file.size > 5 * 1024 * 1024) {
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const content = event.target?.result;
      //console.log("First content", content);
      setFile({
        name: file.name,
        content: content as string,
        lastModified: file.lastModified,
      } as FileContent);
    });
    reader.readAsText(file, encodyingFormat);
    setDisableNext(false);
  };

  const handleTargetClick = () => {
    fileInputRef?.current?.click();
  };

  // const { openModal: openConfirmModal } = useConfirmModal();

  // This handler is called by the handlers in Edit span, Cancel/Back button and CloseIcon (step 2)
  const handleBack = (step: number) => {
    // Nasty hack. Maybe move to a different handler
    // if (step === -1) {
    //   openConfirmModal({
    //     title: "Are you sure you want to delete this import?",
    //     text: "If you delete the import, then the data from this file won't be uploaded",
    //     cancelText: "Cancel",
    //     confirmText: "Delete",
    //     confirmFunction: () => {
    //       reset();
    //     },
    //     cancelFunction: () => {}
    //   });
    // }

    // Step 1 - just go back and reset chosen import type -> Resets everything.
    if (activeStep === 0) {
      // reset();
      setShowSteps(false);
      setImportType(null);
      return;
    }

    // Step 2.0 - Picked import type, file uploaded OR not uploaded -> Resets file, goes to step 1
    // Step 3 - Mapped file -> Edit button goes to step 1, back button goes to step 2
    // if a file is loaded, user can go back and forth
    if (file) {
      setDisableNext(false);
    }
    if (importError) {
      setDisableNext(true);
    }
    setActiveStep(step);
  };

  const reset = () => {
    setHeaders([]);
    setData([]);
    setHeadersToMap([]);
    setCronoHeaders([]);
    setCsvHeadersMap({});
    // setLoaded(true);
    setHeadersErrors({});
    resetImportAccounts();
    resetImportProspects();
    resetImportLeads();
    setImportType(null);
    // steps states reset
    setActiveStep(0);
    setShowSteps(false);
    setDisableNext(true);
    setFile(null);
  };

  const mapCsvHeader = (cronoHeader: string, headerToMap: string) => {
    for (const [key, value] of Object.entries(csvHeadersMap)) {
      if (key === cronoHeader) {
        headersToMap.push(value as string);
      }
    }

    setCsvHeadersMap({
      ...csvHeadersMap,
      [cronoHeader]: headerToMap,
    });
    setHeadersToMap(headersToMap.filter((h) => h !== headerToMap));

    if (needErrorCheck(cronoHeader)) {
      checkError(headerToMap, cronoHeader);
    }
  };

  const unmapCsvHeader = (cronoHeader: string) => {
    const csvHeader = csvHeadersMap[cronoHeader];
    setCsvHeadersMap(
      Object.fromEntries(
        Object.entries(csvHeadersMap).filter(
          ([key, value]) => key !== cronoHeader,
        ),
      ),
    );
    setHeadersToMap([...headersToMap, csvHeader]);
    if (needErrorCheck(cronoHeader)) {
      setHeadersErrors(
        Object.fromEntries(
          Object.entries(headersErrors).filter(
            ([key, value]) => key !== cronoHeader,
          ),
        ),
      );
    }
  };

  const checkError = (headerToMap: string, cronoHeader: string) => {
    const column = data.map((row) => row[headerToMap]);
    // check if column has duplicates
    const hasDuplicates = column.some(
      (item, index) => column.indexOf(item) !== index,
    );
    if (hasDuplicates) {
      setHeadersErrors({
        ...headersErrors,
        [cronoHeader]:
          'Column ' +
          headerToMap +
          ' in the CSV file contains duplicates. ' +
          printCronoHeader(cronoHeader) +
          ' should not contains duplicates.',
      });
    }
  };

  const {
    mutate: importAccounts,
    isLoading: importAccountsLoading,
    isSuccess: importAccountsSuccess,
    isError: importAccountsError,
    error: importAccountsErrorObject,
    reset: resetImportAccounts,
  } = useImportAccounts();

  const {
    mutate: importProspects,
    isLoading: importProspectsLoading,
    isSuccess: importProspectsSuccess,
    isError: importProspectsError,
    error: importProspectsErrorObject,
    reset: resetImportProspects,
  } = useImportProspects();

  const {
    mutate: importLeads,
    isLoading: importLeadsLoading,
    isSuccess: importLeadsSuccess,
    isError: importLeadsError,
    error: importLeadsErrorObject,
    reset: resetImportLeads,
  } = useImportLeads();

  const loadingImports = useMemo(
    () => importAccountsLoading || importProspectsLoading || importLeadsLoading,
    [importAccountsLoading, importProspectsLoading, importLeadsLoading],
  );

  useEffect(() => {
    if (importAccountsSuccess || importProspectsSuccess || importLeadsSuccess) {
      reset();
    }
  }, [importAccountsSuccess, importProspectsSuccess, importLeadsSuccess]);

  useConditionalSnackBar([
    {
      condition: !!importAccountsError,
      message:
        (importAccountsErrorObject && getError(importAccountsErrorObject)) ??
        'Error while importing companies',
      severity: 'error',
    },

    {
      condition: !!importProspectsError,
      message:
        (importProspectsErrorObject && getError(importProspectsErrorObject)) ??
        'Error while importing contacts',
      severity: 'error',
    },
    {
      condition: !!importLeadsError,
      message:
        (importLeadsErrorObject && getError(importLeadsErrorObject)) ??
        'Error while importing leads',
      severity: 'error',
    },
    {
      condition: importAccountsSuccess,
      message: 'Import started successfully',
      severity: 'success',
    },
    {
      condition: importProspectsSuccess,
      message: 'Import started successfully',
      severity: 'success',
    },
    {
      condition: importLeadsSuccess,
      message: 'Import started successfully',
      severity: 'success',
    },
    {
      condition: !!importCheckError,
      message: importCheckError ?? 'Error before importing',
      severity: 'error',
    },
  ]);

  const checkValueExternalProperty = (
    externalProperty: ExternalProperty,
    value: string,
  ) => {
    switch (externalProperty.valueType) {
      case 'Boolean':
        return value === 'true' || value === 'false' ? value : null;
      case 'Numeric':
        return !isNaN(Number(value)) ? value : null;
      case 'String':
      case 'Text':
        return value;
      case 'List':
        //Check if the value is one of the options, if not check if it is one of the labels, returning the corresponding option
        if (
          externalProperty.options?.find((option) => option === value) !==
          undefined
        )
          return value;
        else {
          const option = externalProperty.labels?.findIndex(
            (option) => option === value,
          );
          if (option !== undefined && option !== -1 && externalProperty.options)
            return externalProperty.options[option];
          else return null;
        }
      case 'MultiSelect':
        //Check if the value is one of the options, if not check if it is one of the labels, returning the corresponding option. This time the value
        //passed is a string with the options separated by commas
        const values = value.split(',').map((value) => value.trim());
        const options: string[] = [];
        values.forEach((value) => {
          const option = externalProperty.options?.find(
            (option) => option === value,
          );
          if (option !== undefined) options.push(option);
          else {
            const option = externalProperty.labels?.findIndex(
              (option) => option === value,
            );
            if (
              option !== undefined &&
              option !== -1 &&
              externalProperty.options
            )
              options.push(externalProperty.options[option]);
          }
        });
        return options.length > 0 ? options.join(';') : null;
      default:
        return null;
    }
  };

  const { openModal } = useConfirmModal();

  const handleOpenConfirmImportStrings = (mappedData: any[]) => {
    openModal({
      title: 'Some fields are not mapped correctly',
      text: 'Number of employees (company size) is required to be a number, but texts were found. Texts are not gonna be imported, continue with the import?',
      cancelText: 'Cancel',
      cancelFunction: () => {},
      confirmText: 'Continue anyway',
      confirmFunction: () => {
        finalizeImport(mappedData);
      },
    });
  };
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (importCheckError) {
      timeout = setTimeout(() => {
        setImportCheckError(null);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [importCheckError]);

  const mapAndImport = () => {
    let stringsFound = false;
    const mappedData: any[] = data.map((row) => {
      const mappedRow: any = {};
      const mappedExternalProperties: { [key: string]: any } = {};
      for (const [key, value] of Object.entries(csvHeadersMap)) {
        const externalProperty = getExternalProperty(Number(value));
        if (externalProperty) {
          //We have to check if the value is one of the option/label of the external property
          const rowValue = row[key];
          mappedExternalProperties[value as string] =
            checkValueExternalProperty(externalProperty, rowValue);
        } else {
          //Number of employees must be a number
          if (
            value === 'numberOfEmployees' ||
            value === 'companyNumberOfEmployees'
          ) {
            if (!isNaN(Number(row[key]))) {
              mappedRow[value as string] = Number(row[key]);
            } else {
              stringsFound = true;
              mappedRow[value as string] = null;
            }
          } else {
            mappedRow[value as string] = row[key];
          }
        }
      }
      mappedRow['externalValues'] = mappedExternalProperties;
      return mappedRow;
    });

    if (stringsFound) {
      handleOpenConfirmImportStrings(mappedData);
      return;
    }
    finalizeImport(mappedData);
  };
  const analytics = useJuneAnalytics();

  const finalizeImport = (mappedData: any[]) => {
    if (importType === ImportType.Accounts) {
      if (!mappedData.some((row) => row.name)) {
        setImportCheckError("The 'name' field is not mapped");
        return;
      }

      if (!mappedData.some((row) => row.name && row.name.length > 0)) {
        setImportCheckError("The 'name' field is empty");
        return;
      }
    } else {
      if (
        !mappedData.some((row) => row.firstName) &&
        !mappedData.some((row) => row.lastName) &&
        !mappedData.some((row) => row.email)
      ) {
        setImportCheckError(
          "None of the fields 'firstName', 'lastName' or 'email' are mapped",
        );
        return;
      }

      if (
        !mappedData.some((row) => row.firstName && row.firstName.length > 0) &&
        !mappedData.some((row) => row.lastName && row.lastName.length > 0) &&
        !mappedData.some((row) => row.email && row.email.length > 0)
      ) {
        setImportCheckError(
          "All the fields 'firstName', 'lastName' or 'email' are empty",
        );
        return;
      }
    }

    // setImportCheckError(null);
    if (importType === ImportType.Accounts) {
      const data = mappedData
        .filter((row) => row.name && row.name.length > 0)
        .slice(0, getMaxImport(importType, user?.company?.integrationType));
      importAccounts({
        fileName: file?.name ?? null,
        importType: importBehavior,
        data: data,
      });
      if (analytics) {
        analytics.track('import-accounts-from-file', {
          count: data.length,
        });
      }
    } else if (importType === ImportType.Contacts) {
      const data = mappedData
        .filter(
          (row) =>
            (row.email && row.email.length > 0) ||
            (row.firstName && row.firstName.length > 0) ||
            (row.lastName && row.lastName.length > 0),
        )
        .slice(0, getMaxImport(importType, user?.company?.integrationType));
      importProspects({
        fileName: file?.name ?? null,
        importType: importBehavior,
        data: data,
        findEmail: searchEmails,
        findPhone: searchPhones,
      });
      if (analytics) {
        analytics.track('import-prospects-from-file', {
          count: data.length,
        });
      }
    } else if (importType === ImportType.Leads) {
      const data = mappedData
        .filter(
          (row) =>
            (row.email && row.email.length > 0) ||
            (row.firstName && row.firstName.length > 0) ||
            (row.lastName && row.lastName.length > 0),
        )
        .slice(0, getMaxImport(importType, user?.company?.integrationType));
      importLeads({
        fileName: file?.name ?? null,
        importType: importBehavior,
        data: data,
        findEmail: searchEmails,
        findPhone: searchPhones,
      });
      if (analytics) {
        analytics.track('import-leads-from-file', {
          count: data.length,
        });
      }
    }
  };

  const needErrorCheck = (header: string) => {
    switch (header) {
      case 'name':
      case 'email':
      case 'linkedin':
      case 'phone':
        return true;
      default:
        return false;
    }
  };

  function getMaxImport(
    importType: ImportType | undefined,
    integrationType: IntegrationType | undefined,
  ) {
    if (!importType || !integrationType) return FeConstants.maxImportAccounts;

    switch (importType) {
      case ImportType.Accounts:
        if (integrationType === IntegrationType.CRONO)
          return FeConstants.maxImportAccountsCronoIntegration;
        else return FeConstants.maxImportAccounts;
      case ImportType.Contacts:
      case ImportType.Leads:
        if (integrationType === IntegrationType.CRONO)
          return FeConstants.maxImportProspectsCronoIntegration;
        else return FeConstants.maxImportProspects;

      default:
        return FeConstants.maxImportAccounts;
    }
  }

  const [importBehavior, setImportBehavior] = useState<ImportBehavior>(
    ImportBehavior.IgnoreDuplicates,
  );

  const getImportTypeText = (importType: ImportType) => {
    switch (importType) {
      case ImportType.Accounts:
        return 'companies';
      case ImportType.Contacts:
        return 'contacts';
      case ImportType.Leads:
        return 'leads';
      default:
        return '';
    }
  };

  // useEffect(() => {
  //   if (cronoHeaders.length === 0) return;
  //   setLoaded(false);
  // }, [cronoHeaders]);

  // Steps handlers and state
  const [activeStep, setActiveStep] = useState(0);
  const [disableNext, setDisableNext] = useState<boolean>(true);
  const [showSteps, setShowSteps] = useState<boolean>(false);

  const handleStartSteps = () => {
    setShowSteps(true);
  };

  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   console.log({ disableNext, file, importType, activeStep });
  // }, [disableNext, file, importType, activeStep]);

  const handleRemoveFile = () => {
    setFile(null);
    setDisableNext(true);
  };

  // useEffect(() => {
  //   if (!externalPropertiesEditable || !importType) return;
  //   //To avoid external Properties selected to clear themself when alt+tab
  //   if (cronoHeaders.length > 0) return;
  //   if (importType === ImportType.Accounts) {
  //     const values = accountHeaders.concat(
  //       externalPropertiesEditable
  //         .filter((externalProperty) => !!externalProperty.publicName)
  //         .map((externalProperty) => "" + externalProperty.id) ?? []
  //     );
  //     setCronoHeaders(values);
  //     setHeadersToMap(values);
  //   } else {
  //     const values = prospectHeaders.concat(
  //       externalPropertiesEditable
  //         ?.filter((externalProperty) => !!externalProperty.publicName)
  //         .map((externalProperty) => "" + externalProperty.id) ?? []
  //     );
  //     setCronoHeaders(values);
  //     setHeadersToMap(values);
  //   }
  // }, [externalPropertiesEditable, importType, cronoHeaders]);

  const mapValues = (values: string[]) => {
    //Set the possible headers
    setCronoHeaders(values);
    const toMap: any = {};
    const headersMapped: string[] = [];
    for (let i = 0; i < values.length; i++) {
      const cronoHeader = headers.find((h) => {
        return checkMatch(values[i], h);
      });

      if (cronoHeader) {
        toMap[cronoHeader] = values[i];
        headersMapped.push(values[i]);
      }
    }
    //Set the matching ones
    setCsvHeadersMap(toMap);
    for (const [key, value] of Object.entries(toMap)) {
      if (needErrorCheck(key)) {
        checkError(value as string, key);
      }
    }
    //Set the ones that are not mapped as to map
    setHeadersToMap(values.filter((h) => !headersMapped.includes(h)));
  };

  useEffect(() => {
    async function loadHeaders() {
      if (activeStep !== 2 || !importType) return;
      const externalProperties = await getExternalProperties();
      const externalPropertiesEditable =
        externalProperties?.data?.data?.data.filter(
          (externalProperty) => externalProperty.isEditable,
        ) ?? [];
      let values =
        importType === ImportType.Accounts ? accountHeaders : prospectHeaders;
      values = values.concat(
        externalPropertiesEditable
          ?.filter((externalProperty) => !!externalProperty.publicName)
          .map((externalProperty) => '' + externalProperty.id) ?? [],
      );
      mapValues(values);
    }
    loadHeaders();
  }, [activeStep, importType]);

  // useEffect(() => {
  //   if (
  //     cronoHeaders.length > 0 &&
  //     headersToMap.length > 0 &&
  //     headers.length > 0 &&
  //     //Object.entries(csvHeadersMap).length === 0 &&
  //     !loaded
  //   ) {
  //     // setLoaded(true);
  //     const toMap: any = {};
  //     const headersMapped: string[] = [];
  //     for (let i = 0; i < headersToMap.length; i++) {
  //       const cronoHeader = headers.find((h) => {
  //         return checkMatch(headersToMap[i], h);
  //       });

  //       if (cronoHeader) {
  //         toMap[cronoHeader] = headersToMap[i];
  //         headersMapped.push(headersToMap[i]);
  //       }
  //     }
  //     if (headersMapped.length > 0) {
  //       setCsvHeadersMap(toMap);
  //       setHeadersToMap(headersToMap.filter((h) => !headersMapped.includes(h)));
  //       for (const [key, value] of Object.entries(toMap)) {
  //         if (needErrorCheck(key)) {
  //           checkError(value as string, key);
  //         }
  //       }
  //     }
  //   }
  // }, [cronoHeaders, headersToMap, csvHeadersMap, loaded, headers]);

  const handlePickType = (type: ImportType) => {
    setImportType(type);
    setDisableNext(false);
  };

  // Steps components
  // TODO: refactor each form to individual files
  const ContentPickerForm: JSX.Element = (
    <FormGroup id="step-1">
      <RadioGroup name="file-type">
        <div className="file-card-container">
          {user?.company?.integrationType === IntegrationType.SALESFORCE && (
            <div
              className="file-type-card"
              onClick={() => handlePickType(ImportType.Leads)}
              style={{
                backgroundColor:
                  importType === ImportType.Leads ? colors.primaryLight : '',
                borderColor:
                  importType === ImportType.Leads
                    ? colors.primaryDark
                    : colors.grey33,
              }}
            >
              <div style={{ width: '100%' }}>
                <FormControlLabel
                  value="leads"
                  control={
                    <Radio
                      sx={{
                        color: colors.grey11,
                        height: '24px',
                        width: '24px',
                        '&.Mui-checked': {
                          color: colors.black,
                        },
                      }}
                    />
                  }
                  label=""
                  checked={importType === ImportType.Leads}
                  radioGroup="file-type"
                  sx={{
                    margin: '0px',
                    padding: '16px 0px 0px 16px',
                    '& .MuiRadio': {
                      padding: '0px',
                      height: '24px',
                      width: '24px',
                    },
                  }}
                />
              </div>
              <div>
                <ContactsIllustration />
              </div>
              <div className="text-container">
                <Typography fontWeight={600} fontSize={16}>
                  Leads
                </Typography>
                <Typography color={colors.grey11}>
                  The data will be added like leads
                </Typography>
              </div>
            </div>
          )}
          <div
            className="file-type-card"
            onClick={() => handlePickType(ImportType.Contacts)}
            style={{
              backgroundColor:
                importType === ImportType.Contacts ? colors.primaryLight : '',
              borderColor:
                importType === ImportType.Contacts
                  ? colors.primaryDark
                  : colors.grey33,
            }}
          >
            <div style={{ width: '100%' }}>
              <FormControlLabel
                value="contacts"
                control={
                  <Radio
                    sx={{
                      color: colors.grey11,
                      height: '24px',
                      width: '24px',
                      '&.Mui-checked': {
                        color: colors.black,
                      },
                    }}
                  />
                }
                label=""
                checked={importType === ImportType.Contacts}
                sx={{
                  margin: '0px',
                  padding: '16px 0px 0px 16px',
                  '& .MuiRadio': {
                    padding: '0px',
                    height: '24px',
                    width: '24px',
                  },
                }}
              />
            </div>
            <div>
              <ContactsIllustration />
            </div>
            <div className="text-container">
              <Typography fontWeight={600} fontSize={16}>
                Contacts
              </Typography>
              <Typography color={colors.grey11}>
                The data will be added like contacts
              </Typography>
            </div>
          </div>
          <div
            className="file-type-card"
            onClick={() => handlePickType(ImportType.Accounts)}
            style={{
              backgroundColor:
                importType === ImportType.Accounts ? colors.primaryLight : '',
              borderColor:
                importType === ImportType.Accounts
                  ? colors.primaryDark
                  : colors.grey33,
            }}
          >
            <div style={{ width: '100%' }}>
              <FormControlLabel
                value="companies"
                control={
                  <Radio
                    sx={{
                      color: colors.grey11,
                      height: '24px',
                      width: '24px',
                      '&.Mui-checked': {
                        color: colors.black,
                      },
                    }}
                  />
                }
                label=""
                checked={importType === ImportType.Accounts}
                radioGroup="file-type"
                sx={{
                  margin: '0px',
                  padding: '16px 0px 0px 16px',
                  '& .MuiRadio': {
                    padding: '0px',
                    height: '24px',
                    width: '24px',
                  },
                }}
              />
            </div>
            <div>
              <CompaniesIllustration />
            </div>
            <div className="text-container">
              <Typography fontWeight={600} fontSize={16}>
                Companies
              </Typography>
              <Typography color={colors.grey11}>
                The data will be added like companies
              </Typography>
            </div>
          </div>
        </div>
      </RadioGroup>
    </FormGroup>
  );

  const DataImportForm: JSX.Element = (
    <div id="step-2">
      <div style={{ width: '580px', height: '286px' }}>
        {importedFiles && !file ? (
          <>
            {/* Check loading step when should it appear */}
            {importFileLoading && (
              <BlurBackgroundDivFixed>
                <div className="loading-import-file">
                  <span className="loader" />
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    lineHeight={'30px'}
                  >
                    Preparing the file for import
                  </Typography>
                </div>
              </BlurBackgroundDivFixed>
            )}
            <input
              onChange={(ev) => {
                onFileInputChange(ev);
              }}
              onClick={() => {
                if (fileInputRef?.current) fileInputRef.current.value = '';
              }}
              ref={fileInputRef}
              type="file"
              style={{ display: 'none' }}
              accept=".csv"
            />
            <FileDrop
              onDrop={(files) => {
                handleDropFiles(files);
              }}
              onTargetClick={handleTargetClick}
            >
              <Typography fontSize={16} lineHeight={'24px'} fontWeight={500}>
                Drag file here or{' '}
                <span
                  style={{ color: colors.primary }}
                  //onClick={() => openFileSelector()}
                >
                  Click to browse
                </span>
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
                color={colors.grey11}
              >
                Format: CSV · Max file size 5MB
              </Typography>
            </FileDrop>
          </>
        ) : (
          <>
            <FlexDiv
              direction="column"
              style={{ rowGap: 12 }}
              alignItems="start"
            >
              <Typography fontWeight={600} fontSize={14} lineHeight={'22px'}>
                Uploaded file
              </Typography>
              <div
                className={`file-uploaded ${
                  importError ? 'file-upload-error' : ''
                }`}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {importError ? (
                    <DocumentImportError />
                  ) : (
                    <DocumentImportSuccess />
                  )}
                  <div className="file-name">
                    <Tooltip arrow title={file?.name} placement="top">
                      <Typography
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={'22px'}
                        color={colors.black}
                      >
                        {file?.name}
                      </Typography>
                    </Tooltip>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      lineHeight={'18px'}
                      color={colors.black}
                    >
                      {data.length} entries
                    </Typography>
                  </div>
                  <CloseMIcon
                    className="remove-file-icon"
                    color={colors.grey1}
                    onClick={handleRemoveFile}
                  />
                </div>
              </div>
              <Typography
                fontWeight={400}
                fontSize={14}
                lineHeight={'24px'}
                color={colors.inactive}
              >
                {importError}
              </Typography>
              <FlexDiv direction="column" justifyContent="start">
                <FlexDiv direction="column" alignItems="start">
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    lineHeight={'22px'}
                    paddingBottom={'8px'}
                  >
                    Choose how to import{' '}
                    {getImportTypeText(importType as ImportType)}*
                  </Typography>
                  <Select
                    fullWidth
                    value={importBehavior}
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${colors.grey3}`,
                        borderRadius: 2,
                      },
                      height: 40,
                    }}
                    MenuProps={{
                      style: { zIndex: 3501 },
                      PaperProps: {
                        sx: {
                          borderRadius: '8px',
                          paddingInline: '6px',
                          border: '1px solid #dadde9',
                          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                          '& .MuiMenuItem-root': {
                            fontSize: '12px',
                            lineHeight: '16px',
                            height: 'fit-content',
                            padding: '8px',
                            cursor: 'pointer',
                            width: '100%',
                            borderRadius: '8px',
                            '&:hover': {
                              background: colors.primaryLight,
                              color: colors.primary,
                            },
                          },
                          '& .Mui-selected': {
                            color: colors.primary,
                            backgroundColor: 'transparent !important',
                          },
                        },
                      },
                    }}
                    onChange={(ev) => {
                      setImportBehavior(ev.target.value as ImportBehavior);
                    }}
                  >
                    <MenuItem value={ImportBehavior.IgnoreDuplicates}>
                      Ignore duplicates
                    </MenuItem>
                    <MenuItem value={ImportBehavior.UpdateDuplicates}>
                      Update duplicates
                    </MenuItem>
                    <MenuItem
                      value={ImportBehavior.UpdateDuplicatesAndChangeOwnership}
                    >
                      Update duplicates and change ownership
                    </MenuItem>
                  </Select>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </>
        )}
      </div>
    </div>
  );

  const HeaderMapperForm: JSX.Element = (
    <FlexDiv alignItems="center" direction="column" style={{ rowGap: 24 }}>
      {/* <FlexDiv
        className="back-container"
        direction="row"
        justifyContent="start"
      > */}
      {/* Use this logic somewhere else ? */}
      {/* {!importLeadsLoading &&
          !importProspectsLoading &&
          !importAccountsLoading &&
          !importAccountsSuccess &&
          !importProspectsSuccess &&
          !importLeadsSuccess &&
          null}
      </FlexDiv> */}
      <FlexDiv direction="column" alignItems="center" style={{ rowGap: 18 }}>
        {/* <FlexDiv justifyContent="start" direction="row">
          <p>
            We found {data.length} {importType}, with the following headers:
            <br />
            <span className="bold">{headers.join(", ")}.</span>
          </p>
        </FlexDiv>
        <FlexDiv justifyContent="start" direction="row">
          <p>Map the fields in the CSV to the corresponding Crono's fields.</p>
        </FlexDiv>
        <FlexDiv justifyContent="start" direction="row">
          <p>
            {importType === ImportType.Accounts
              ? "Name field is required. Entries with empty name will be ignored."
              : "Either first name or last name or email field is required. Entries without value will be ignored."}
          </p>
        </FlexDiv>
        <FlexDiv justifyContent="start" direction="row">
          <p>
            {importType === ImportType.Accounts
              ? "You can import up to " +
                getMaxImport(importType, user?.company?.integrationType) +
                " companies. Others will be ignored."
              : importType === ImportType.Contacts
              ? "You can import up to " +
                getMaxImport(importType, user?.company?.integrationType) +
                " contacts or leads. Others will be ignored."
              : ""}
          </p>
        </FlexDiv> */}

        <FlexDiv direction="column" alignItems="center">
          <Grid container xs={10} className="import-data-table">
            <Grid container className="import-data-table-header">
              <Grid item xs={3} className="import-data-table-header-cell">
                Your field name
              </Grid>
              <Grid item xs={3} className="import-data-table-header-cell">
                Data example
              </Grid>
              <Grid item xs={2} className="import-data-table-header-cell">
                Status
              </Grid>
              <Grid item xs={3} className="import-data-table-header-cell">
                Crono field name
              </Grid>
            </Grid>

            {headers.map((header, index) => {
              return (
                <Grid container className="import-data-table-row" key={header}>
                  <Grid
                    item
                    xs={3}
                    className="import-data-table-body-cell"
                    style={{ borderRight: `1px solid ${colors.grey444}` }}
                  >
                    <Typography
                      fontSize={14}
                      lineHeight={'18px'}
                      fontWeight={500}
                      noWrap
                    >
                      {header}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} className="import-data-table-body-cell">
                    <Typography
                      noWrap
                      fontSize={14}
                      fontWeight={500}
                      lineHeight={'22px'}
                      color={colors.grey11}
                    >
                      {dataExample[index]}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} className="import-data-table-body-cell">
                    {csvHeadersMap[header] ? (
                      <div className="status-map status-mapped">Mapped</div>
                    ) : (
                      <div className="status-map status-not-mapped">
                        Not mapped
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    className="import-data-table-body-cell"
                    style={{ paddingRight: '24px' }}
                  >
                    <Select
                      value={csvHeadersMap[header] ?? ''}
                      onChange={(e) => {
                        mapCsvHeader(header, e.target.value as string);
                      }}
                      fullWidth
                      sx={{
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': {
                          border: `1px solid ${colors.grey3}`,
                          borderRadius: 2,
                        },
                        height: 40,
                      }}
                      MenuProps={{
                        style: { zIndex: 3501 },
                        PaperProps: {
                          sx: {
                            borderRadius: '8px',
                            paddingInline: '6px',
                            border: '1px solid #dadde9',
                            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                            '& .MuiMenuItem-root': {
                              fontSize: '12px',
                              lineHeight: '16px',
                              height: 'fit-content',
                              padding: '8px',
                              cursor: 'pointer',
                              width: '100%',
                              borderRadius: '8px',
                              '&:hover': {
                                background: colors.primaryLight,
                                color: colors.primary,
                              },
                            },
                            '& .Mui-selected': {
                              color: colors.primary,
                              backgroundColor: 'transparent !important',
                            },
                          },
                        },
                      }}
                    >
                      {cronoHeaders.map((cronoHeader) => (
                        <MenuItem
                          key={cronoHeader}
                          value={cronoHeader}
                          disabled={
                            !headersToMap.find((h) => h === cronoHeader) &&
                            cronoHeader !== (csvHeadersMap[header] ?? '')
                          }
                          onClick={(ev) => {
                            if (csvHeadersMap[header] === cronoHeader) {
                              ev.stopPropagation();
                              ev.preventDefault();
                              unmapCsvHeader(header);
                            }
                          }}
                        >
                          {printCronoHeader(cronoHeader)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </FlexDiv>
        {/* <FlexDiv
          className="select-field-container"
          direction="row"
          justifyContent="space-between"
        >
          <p className="crono-header">Crono {importType}</p>
          <p className="csv-header">CSV</p>
        </FlexDiv> */}
        {/*cronoHeaders.map((cronoHeader) => (
          <FlexDiv direction="column">
            <FlexDiv
              className="select-field-container"
              direction="row"
              justifyContent="space-between"
            >
              <p className="crono-header-name">
                {printCronoHeader(cronoHeader)}
              </p>
              <FlexDiv justifyContent="end">
                <Select
                  value={csvHeadersMap[cronoHeader] ?? ""}
                  className="input-select"
                  onChange={(e) =>
                    mapCsvHeader(cronoHeader, e.target.value as string)
                  }
                >
                  {headers.map((header) => (
                    <MenuItem
                      value={header}
                      disabled={!headersToMap.find((h) => h === header)}
                    >
                      {header}
                    </MenuItem>
                  ))}
                </Select>
                <HighlightOffIcon
                  className={
                    csvHeadersMap[cronoHeader]
                      ? "clear-icon"
                      : "clear-icon-hidden"
                  }
                  onClick={() =>
                    csvHeadersMap[cronoHeader]
                      ? unmapCsvHeader(cronoHeader)
                      : null
                  }
                />
              </FlexDiv>
            </FlexDiv>
            <FlexDiv direction="row" justifyContent="start">
              {needErrorCheck(cronoHeader) ? (
                <p className="error-message">{headersErrors[cronoHeader]}</p>
              ) : (
                <p></p>
              )}
            </FlexDiv>
          </FlexDiv>
              ))*/}

        {/*Object.entries(headersErrors).length > 0 && (
          <FlexDiv direction="column">
            <FlexDiv justifyContent="start" direction="row">
              <p className="duplicates-error-message">
                There are some errors in the CSV. Do you want to check?
              </p>
            </FlexDiv>
            <FlexDiv
              justifyContent="start"
              direction="row"
              className="settings-hint"
            >
              <p>The procedure will clean duplicates automatically.</p>
            </FlexDiv>
          </FlexDiv>
        )*/}
        {/* <FlexDiv justifyContent="start" direction="row">
          {headersToMap.length > 0 ? (
            <p>
              There {headersToMap.length === 1 ? "is" : "are"} still{" "}
              {headersToMap.length} headers to map.
            </p>
          ) : (
            <p>All headers have been mapped. Click to import!</p>
          )}
        </FlexDiv> */}

        {/* Review logic checks. Loaders should go in step 2 ? */}
        {/* {headersToMap.length !== cronoHeaders.length &&
          (!importLeadsLoading ||
          !importProspectsLoading ||
          !importAccountsLoading
            ? null
            : // TODO: open modal here
              // {<FlexDiv direction="column">
              //   <CircularProgress />
              //   <p>Importing...</p>
              // </FlexDiv>}
              (importAccountsSuccess ||
                importProspectsSuccess ||
                importLeadsSuccess) && (
                <FlexDiv direction="column">
                  <h2 className="import-success">Imported successfully!</h2>
                  <CronoButton
                    variant="contained"
                    color="primary"
                    size="large"
                    className="import-button"
                    onClick={() => navigate(PATH.HOME)}
                  >
                    Next
                  </CronoButton>
                </FlexDiv>
              ))} */}
      </FlexDiv>
    </FlexDiv>
  );

  const [searchEmails, setSearchEmails] = useState<boolean>(false);
  const [searchPhones, setSearchPhones] = useState<boolean>(false);

  const EnrichFromImport: JSX.Element = (
    <EnrichFromImportWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '580px',
        }}
      >
        <div style={{ marginBottom: '8px' }}>
          <Typography fontSize={14} lineHeight={'22px'} fontWeight={600}>
            Find contact data:
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px 24px 12px 20px',
              borderRadius: '8px',
              border: `1px solid ${colors.grey444}`,
              background: searchEmails
                ? colors.sequenceCardHover
                : colors.white,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <IconFindEmail color={colors.primaryDark} />

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontSize={14} lineHeight={'24px'}>
                  Find email addresses
                </Typography>

                <Typography
                  fontSize={12}
                  lineHeight={'16px'}
                  color={colors.grey11}
                >
                  <span style={{ fontWeight: 600 }}>
                    {data.length} {data.length > 1 ? 'credits' : 'credit'}
                  </span>{' '}
                  will be consumed
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <RemainingTokensBadge requiredLength={data.length} type="email" />
              <Checkbox
                checked={searchEmails}
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  setSearchEmails(!searchEmails);
                }}
                checkedIcon={<IconChecked color={colors.mainDark} />}
                icon={<IconUnchecked />}
                style={{ padding: 0 }}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px 24px 12px 20px',
              borderRadius: '8px',
              border: `1px solid ${colors.grey444}`,
              background: searchPhones
                ? colors.sequenceCardHover
                : colors.white,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <IconFindPhone
                width={'24'}
                height={'24'}
                color={colors.primaryDark}
              />

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontSize={14} lineHeight={'24px'}>
                  Find phone numbers
                </Typography>

                <Typography
                  fontSize={12}
                  lineHeight={'16px'}
                  color={colors.grey11}
                >
                  <span style={{ fontWeight: 600 }}>
                    {data.length} {data.length > 1 ? 'credits' : 'credit'}
                  </span>{' '}
                  will be consumed
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <RemainingTokensBadge requiredLength={data.length} type="phone" />

              <Checkbox
                checked={searchPhones}
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  setSearchPhones(!searchPhones);
                }}
                checkedIcon={<IconChecked color={colors.mainDark} />}
                icon={<IconUnchecked />}
                style={{ padding: 0 }}
              />
            </div>
          </div>

          {!(
            checkObjectIncludeValue(csvHeadersMap, 'linkedin') ||
            checkObjectIncludeValue(csvHeadersMap, 'companyWebsite')
          ) &&
            (searchEmails || searchPhones) && (
              <div className="warning-box">
                <div className="icon-container">
                  <IconSWarning color={colors.white} />
                </div>

                <div className="warning-box__info">
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    fontWeight={400}
                    color={colors.inactiveHover}
                  >
                    Missing informations for enrichment
                  </Typography>

                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    fontWeight={600}
                    color={colors.inactiveHover}
                  >
                    Company Website or Contact Linkedin URL are mandatory to
                    find email address and find phone numbers
                  </Typography>
                </div>
              </div>
            )}
        </div>
      </div>
    </EnrichFromImportWrapper>
  );

  const stepContent = [
    // Step 1 - Select type of file
    ContentPickerForm,
    // Step 2 - Upload file
    DataImportForm,
    // Step 3 - Map file missing fields
    HeaderMapperForm,
    // Step 4 - Enrich data from your file
    EnrichFromImport,
  ];

  function calculateFailedRows(okQuantity: number, quantity: number) {
    let result = quantity - okQuantity;
    if (result < 0) {
      result = 0;
    }
    return result;
  }

  function isCompletedWithError(file: Import) {
    return (
      file.status === ImportStatusType.CompletedWithErrors &&
      file.okQuantity !== 0
    );
  }

  const ImportedFiles = importedFiles?.data?.data && (
    <FlexDiv
      direction="column"
      alignItems="start"
      // style={{ rowGap: 16 }}
      width={'500px'}
    >
      {importedFiles.data.data.find(
        (file) =>
          file.status === ImportStatusType.Started ||
          file.status === ImportStatusType.OnGoing,
      ) && (
        <Typography
          fontSize={24}
          fontWeight={700}
          lineHeight={'30px'}
          paddingBottom={'24px'}
        >
          Imported files
        </Typography>
      )}
      {/* Only ongoing and started files shown as imported */}
      {importedFiles.data.data
        .filter(
          (file) =>
            file.status === ImportStatusType.Started ||
            file.status === ImportStatusType.OnGoing,
        )
        .map((file) => {
          // const importCompleted =
          //   file.status !== ImportStatusType.OnGoing &&
          //   file.status !== ImportStatusType.Started;
          return (
            <div
              className={`imported-file`}
              style={{ marginBottom: '32px' }}
              key={file.id}
            >
              <div className="imported-file-content">
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="import-completed"
                >
                  {(file.status === ImportStatusType.Completed ||
                    file.status === ImportStatusType.OnGoing) && (
                    <DocumentImportSuccess />
                  )}
                  {/* {file.status === ImportStatusType.CompletedWithErrors ? (
                    file.okQuantity > 0 && file.okQuantity < file.quantity ? (
                      <DocumentImportErrorRows />
                    ) : (
                      <DocumentImportError />
                    )
                  ) : null} */}
                  <div className="uploaded-file-name">
                    <Tooltip arrow title={file.fileName} placement="top">
                      <Typography
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        fontSize={14}
                        fontWeight={600}
                        lineHeight={'22px'}
                        color={
                          file.status === ImportStatusType.CompletedWithErrors
                            ? colors.inactive
                            : colors.black
                        }
                      >
                        {file.fileName}
                      </Typography>
                    </Tooltip>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    paddingRight: '16px',
                  }}
                >
                  {/* {!importCompleted ? ( */}
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    lineHeight={'16px'}
                    color={
                      file.status === ImportStatusType.CompletedWithErrors
                        ? colors.inactive
                        : colors.primary
                    }
                  >
                    {file.progress}%
                  </Typography>
                  {/* ) : ( */}
                  {/* <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "24px"
                      }}
                    >
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        lineHeight={"16px"}
                        color={colors.grey11}
                      >
                        {moment(file.startDate).format("ll")}
                      </Typography>
                      {file.status === ImportStatusType.Completed && (
                        <CheckMarkIcon
                          className="icon import-completed-icon"
                          color={colors.callScheduled}
                        />
                      )}
                      {file.status === ImportStatusType.CompletedWithErrors && (
                        <CloseMIcon
                          className="icon import-completed-with-errors-icon"
                          color={colors.inactive}
                        />
                      )}
                    </div>
                  )} */}
                </div>
              </div>
              {file.progress < 100 && (
                <Slider
                  value={file.progress}
                  className={`slider`}
                  sx={{
                    '& .MuiSlider-track': {
                      color: colors.primary,
                    },
                    '& .MuiSlider-rail': {
                      color: colors.primaryLight,
                    },
                  }}
                />
              )}
            </div>
          );
        })}
      {importedFiles.data.data.find(
        (file) =>
          file.status === ImportStatusType.Completed ||
          file.status === ImportStatusType.CompletedWithErrors,
      ) && (
        <Typography
          fontSize={14}
          fontWeight={600}
          lineHeight={'30px'}
          paddingBottom={'8px'}
        >
          Last added
        </Typography>
      )}
      {/* Only completed files shown */}
      {importedFiles.data.data
        .filter(
          (file) =>
            file.status === ImportStatusType.Completed ||
            file.status === ImportStatusType.CompletedWithErrors,
        )
        .map((file) => {
          return (
            <div className={`imported-file`} key={file.id}>
              <div className="imported-file-content">
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="import-completed"
                >
                  {file.status === ImportStatusType.Completed ? (
                    <DocumentImportSuccess />
                  ) : file.status === ImportStatusType.CompletedWithErrors ? (
                    file.okQuantity > 0 && file.okQuantity < file.quantity ? (
                      <DocumentImportErrorRows />
                    ) : (
                      <DocumentImportError />
                    )
                  ) : null}
                  <div className="uploaded-file-name">
                    <Tooltip arrow title={file.fileName} placement="top">
                      <Typography
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        fontSize={14}
                        fontWeight={600}
                        lineHeight={'22px'}
                        color={
                          isCompletedWithError(file) ||
                          file.status === ImportStatusType.Completed
                            ? colors.black
                            : colors.inactive
                        }
                      >
                        {file.fileName}
                      </Typography>
                    </Tooltip>
                    <Typography
                      fontSize={12}
                      lineHeight={'18px'}
                      fontWeight={400}
                      color={colors.grey11}
                      display={'flex'}
                    >
                      {file.status === ImportStatusType.Completed
                        ? `${file.quantity} rows`
                        : isCompletedWithError(file)
                          ? `${file.quantity} rows | ${calculateFailedRows(
                              file.okQuantity,
                              file.quantity,
                            )} not imported`
                          : 'Failed import'}
                      {file.warningQuantity > 0 &&
                        file.warningQuantity < file.quantity && (
                          <FailedImportTooltip
                            placement="top"
                            title={
                              <div>
                                {file.warnings?.map((row, idx) => (
                                  <p key={idx}>{row}</p>
                                ))}
                              </div>
                            }
                          >
                            <span style={{ paddingLeft: '4px' }}>
                              <InformationIcon />
                            </span>
                          </FailedImportTooltip>
                        )}
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    paddingRight: '16px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '24px',
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'16px'}
                      color={colors.grey11}
                    >
                      {moment(file.startDate).format('ll')}
                    </Typography>
                    {file.status === ImportStatusType.Completed ||
                    isCompletedWithError(file) ? (
                      <CheckMarkIcon
                        className="icon import-completed-icon"
                        color={colors.callScheduled}
                      />
                    ) : (
                      <CloseMIcon
                        className="icon import-completed-with-errors-icon"
                        color={colors.inactive}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </FlexDiv>
  );

  return (
    <ImportWrapper>
      {showSteps ? (
        <>
          {/* <ClickAwayListener onClickAway={() => handleBack(-1)}> */}
          <StepsProgress
            handleBack={handleBack}
            mapAndImport={mapAndImport}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            disableNext={disableNext}
            setDisableNext={setDisableNext}
            resetSelectedType={() => {
              if (!file) {
                setDisableNext(true);
              }
            }}
            isImporting={loadingImports}
            importType={importType}
            importError={importError}
          >
            {stepContent[activeStep]}
          </StepsProgress>
          {/* </ClickAwayListener> */}
        </>
      ) : (
        <>
          <DataImportStep0
            setDisableNext={setDisableNext}
            activeStep={activeStep}
          />
          <Button
            className="example-file-download"
            startIcon={<DocumentIconWhite />}
            color="secondary"
            style={{ width: '188px', height: '48px' }}
            onClick={handleStartSteps}
          >
            Upload CSV file
          </Button>
          {importedFiles?.data?.data && ImportedFiles}
        </>
      )}
    </ImportWrapper>
  );
};

export default ImportData;
