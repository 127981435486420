import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { ProspectLinkedin } from 'crono-fe-common/types/prospectLinkedin';
import {
  ISearchParametersProspectLinkedin,
  ProspectLinkedinMultiple,
} from 'crono-fe-common/types/prospectLinkedinMultiple';

interface IProps {
  searchParameters: ISearchParametersProspectLinkedin[];
}

export default function useProspectLinkedinMultiple() {
  const useSearchProspectLinkedinMultiple = ({ searchParameters }: IProps) => {
    const request: Request = {
      url: ENDPOINTS.prospect.linkedin.multiple,
      config: {
        method: 'POST',
        data: {
          data: searchParameters,
        },
      },
    };
    return client(request);
  };

  return useMutation<Response<ProspectLinkedinMultiple[]>, CronoError, IProps>(
    useSearchProspectLinkedinMultiple,
    {},
  );
}
