import { Account } from 'crono-fe-common/types/account';
import * as Yup from 'yup';
export interface CompanyInformation {
  linkedin: string;
  phone: string;
  website: string;
}

export const getInformation = (key: keyof Account) => {
  if (key === 'linkedin') return 'linkedin';
  if (key === 'phone') return 'phone';
  return 'website';
};

export const validationSchema = Yup.object({
  phone: Yup.number(),
});
