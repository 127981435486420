import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SequenceCardWrapper = styled.div`
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  .steps-counter-container {
    border: 2px solid ${colors.grey4};
    border-radius: 999px;
    width: fit-content;
    padding: 4px 8px;
  }
`;
