import { DeleteOutlineRounded } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { useSelectedAccount } from 'pages/home/context/account';
import { Account } from 'crono-fe-common/types/account';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import { ReactComponent as WebsiteIcon } from 'crono-fe-common/icons/website.svg';

import { ReactComponent as IndustryIcon } from 'crono-fe-common/icons/Industry.svg';
import { ReactComponent as MoneyIcon } from 'crono-fe-common/icons/Money.svg';
import { ReactComponent as ToolsIcon } from 'crono-fe-common/icons/Tools.svg';
import NotesBox from '../notesBox';
import ExternalPropertyNotTag from '../externalPropertyNotTag';
import { useEffect, useMemo, useState } from 'react';
import { getError, getNewOptionsWhenSelecting } from 'crono-fe-common/utils';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import useDisableAccount from 'hooks/services/account/useDisableAccount';
import { useConfirmModal } from 'context/confirmModal';
import useEditAccount from 'hooks/services/account/useEditAccount';
import { ReactComponent as EditIcon } from 'crono-fe-common/icons/Edit.svg';
import { ReactComponent as CRMIcon } from 'crono-fe-common/icons/CRM.svg';
import { ReactComponent as RedirectIcon } from 'crono-fe-common/icons/Redirect.svg';
import { ReactComponent as ContactsIcon } from 'crono-fe-common/icons/Contacts.svg';
import { RemoveButton } from 'crono-fe-common/components/CronoButton';
import { useConditionalSnackBar } from 'context/snackbar';
import InsertLinkedinForCompany from 'pages/searchComponent/searchContactTab/insertLinkedinForCompany';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { CompanyInformation, getInformation, validationSchema } from './models';
import { useFormik } from 'formik';
import IconCopy from 'crono-fe-common/icons/Icon-Copy';
import {
  Constants,
  linkedinIndustries,
} from 'crono-fe-common/constants/constants';
import {
  generateAccountCrmLink,
  getImageFromUrl,
  stringAvatarAccount,
  transformToValidUrl,
} from 'utils/fe-utils';
import { colors } from 'crono-fe-common/theme';
import IconXSCheckmark from 'crono-fe-common/icons/Icon-XS-CheckMark';
import { useLinkedinGetCompanyInfo } from 'crono-fe-common/hooks/crono-extension/gateway';
import { AccountInfoInputs } from 'crono-fe-common/types/DTO/accountInfoInputs';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import { CronoSelect, CronoMenuItem } from 'crono-fe-common/components/Select';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import CronoStatus from 'components/CronoStatus';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';

interface IInformation {
  name: keyof Account;
  label?: string;
  icon: JSX.Element;
}

const contactInformation: IInformation[] = [
  {
    name: 'phone',
    label: 'Phone',
    icon: <CallIcon className="info-icon" />,
  },
  {
    name: 'website',
    label: 'Website',
    icon: <WebsiteIcon className="info-icon" />,
  },
  {
    name: 'linkedin',
    label: 'LinkedIn',
    icon: <LinkedinIcon className="info-icon" />,
  },
];

const accountInformation: IInformation[] = [
  // {
  //   name: "location",
  //   icon: ,
  // },
  {
    name: 'country',
    label: 'Country',
    icon: <IndustryIcon className="account-info-icon" />,
  },
  {
    name: 'industry',
    label: 'Industry',
    icon: <IndustryIcon className="account-info-icon" />,
  },
  {
    name: 'annualRevenue',
    label: 'Revenue',
    icon: <MoneyIcon className="account-info-icon" />,
  },

  {
    name: 'numberOfEmployees',
    label: 'Employees',
    icon: <ContactsIcon className="account-info-icon" />,
  },
  {
    name: 'currentSolution',
    label: 'Solution',
    icon: <ToolsIcon className="account-info-icon" />,
  },
];

export const AddExternalPropertyTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: 300,
    maxWidth: 400,
    position: 'relative',
    top: 0,
    left: 0,
    border: '1px solid #dadde9',
    borderRadius: 16,
    padding: 12,
    zIndex: 150000,
    overflow: 'auto',
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

interface IProps {
  setTabValue: (value: number) => void;
}

const AccountView = ({ setTabValue }: IProps) => {
  const { user } = useAuth();
  const { account } = useSelectedAccount();

  const { data: externalProperties } = useGetExternalProperty('Account', false);

  const { openModal: openConfirmModal } = useConfirmModal();
  const [error, setError] = useState<string | null>(null);
  const {
    mutate: updateAccount,
    error: errorUpdateAccount,
    isSuccess: isSuccessEditAccount,
  } = useEditAccount();

  const {
    result: linkedinCompanyInfo,
    call: getLinkedinCompanyInfo,
    isLoading: loadingLinkedinCompanyInfo,
    error: errorLinkedinGetCompanyInfo,
  } = useLinkedinGetCompanyInfo();

  useEffect(() => {
    if (errorLinkedinGetCompanyInfo) {
      setError(
        'Error retrieving the website. Please check that the linkedin URL is valid',
      );
    }
  }, [errorLinkedinGetCompanyInfo]);

  useConditionalSnackBar([
    {
      condition: !!errorUpdateAccount,
      message: getError(errorUpdateAccount) ?? 'Error update company',
      severity: 'error',
    },
    {
      condition: !!error,
      message: error ?? 'Error update company',
      severity: 'error',
    },
  ]);

  const {
    isLoading: loadingDisableAccount,
    mutate: disableAccount,
    error: errorDisableAccount,
  } = useDisableAccount();
  const accountCrmLink = useMemo(() => {
    return generateAccountCrmLink(account, user ?? null);
  }, [account, user]);

  useConditionalSnackBar([
    {
      condition: !!errorDisableAccount,
      message: getError(errorDisableAccount) ?? 'Error disabling account',
      severity: 'error',
    },
  ]);

  const [showConfirmCompany, setShowConfirmCompany] = useState<boolean>(false);

  const formik = useFormik<CompanyInformation>({
    initialValues: {
      phone: account?.phone || '',
      linkedin: account?.linkedin || '',
      website: account?.website || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    formik.setFieldValue('linkedin', account?.linkedin || '');
  }, [account?.linkedin]);
  useEffect(() => {
    formik.setFieldValue('website', account?.website || '');
  }, [account?.website]);
  useEffect(() => {
    formik.setFieldValue('phone', account?.phone || '');
  }, [account?.phone]);

  // keeps track of successful edited fields
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const [editedAccount, setEditedAccount] = useState<keyof Account | null>(
    null,
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSuccessEditAccount) {
      setShowBadge(isSuccessEditAccount);
      timeout = setTimeout(() => {
        setEditedAccount(null);
        setShowBadge(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccessEditAccount]);

  const handleSearchInformation = () => {
    if (!account?.linkedin) {
      setError('Missing linkedin URL');
    } else {
      getLinkedinCompanyInfo({ url: account.linkedin }).then();
    }
  };

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (error) {
      t = setTimeout(() => {
        setError(null);
      }, 2000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [error]);

  useEffect(() => {
    if (!linkedinCompanyInfo) return;
    if (linkedinCompanyInfo?.company && account?.objectId) {
      const accountPatch: AccountInfoInputs = {
        accountId: account.objectId,
      };

      if (linkedinCompanyInfo.company.numberOfEmployees) {
        accountPatch.numberOfEmployees =
          linkedinCompanyInfo.company.numberOfEmployees;
      }
      if (linkedinCompanyInfo.company.phone) {
        accountPatch.phone = linkedinCompanyInfo.company.phone;
      }
      if (linkedinCompanyInfo.company.website) {
        accountPatch.website = linkedinCompanyInfo.company.website;
      }
      if (linkedinCompanyInfo.company.country) {
        accountPatch.country = linkedinCompanyInfo.company.country;
      }

      updateAccount(accountPatch);
    } else if (!linkedinCompanyInfo.company?.website) {
      setError('No website found for this company');
    }
  }, [linkedinCompanyInfo]);

  // linkedin company url provided from CRM could be invalid
  const validValue = useMemo(
    () =>
      LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(account?.linkedin)[0] !==
      null,
    [account],
  );

  const { data: usersDb } = useGetUsers();

  const users = useMemo(() => {
    return usersDb?.data?.data ?? [];
  }, [usersDb]);
  if (!account) return <></>;

  const handleDisableAccount = (accountId: string) => {
    if (account.owned) {
      openConfirmModal({
        title:
          'By clicking Delete, you will eliminate the company and all its contacts',
        text: '',
        cancelText: 'Cancel',
        confirmText: 'Delete',
        confirmFunction: () => disableAccount({ accountId }),
        cancelFunction: () => null,
      });
    }
  };

  //Function to handle the change of information of an account
  //This is called when the enter is pressed
  const handleChange = (accountId: string, event: any) => {
    setShowBadge(false);
    setEditedAccount(null);
    const { name, value } = event.target;
    //I update the account with the new information
    if ((account[name as keyof Account] ?? '') !== value) {
      updateAccount({
        accountId,
        [name]: value,
      });
      setEditedAccount(name);
    }
  };

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index?: number,
  ) => {
    const externalPropertyId = externalProperty.id;
    const finalOption = getNewOptionsWhenSelecting({
      currentExternalValues: account.externalValues,
      option,
      externalPropertyId: externalPropertyId,
      valueType: externalProperty.valueType,
    });
    updateAccount({
      accountId: account.objectId,
      externalValues: {
        [externalPropertyId]: finalOption,
      },
    });
  };

  return (
    <div className="layout-grid">
      {showConfirmCompany && (
        <InsertLinkedinForCompany
          close={() => setShowConfirmCompany(false)}
          company={account}
        />
      )}
      {/*Left side, containing company information */}
      <div className="layout-grid-item container">
        <div className="grid-item">
          <div className="top-half">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 9,
                gap: '8px',
              }}
            >
              <div style={{ overflow: 'hidden' }}>
                <Tooltip
                  arrow
                  title={account.name}
                  enterDelay={800}
                  enterNextDelay={800}
                >
                  <div className="editable-div">
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        className: 'account-name-div',
                      }}
                      name="name"
                      autoComplete="off"
                      fullWidth
                      //onChange={(ev) => handleChange(prospect.objectId, ev)}
                      defaultValue={account.name}
                      key={account.name}
                      placeholder="Insert a name..."
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChange(account.objectId, ev);
                        }
                      }}
                      onBlur={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChange(account.objectId, ev);
                      }}
                    />
                    {showBadge && editedAccount === 'name' && (
                      <p className="edited-field-badge">
                        Saved
                        <IconXSCheckmark color={colors.primaryDark} />
                      </p>
                    )}
                  </div>
                </Tooltip>

                {account && (
                  <CronoStatus
                    type="account"
                    currentActualStatus={account.actualStatus}
                    currentExternalValues={account.externalValues}
                    objectToUpdate={account.objectId}
                    disabled={!account.owned}
                    style={{
                      padding: '4px 16px',
                    }}
                  />
                )}
              </div>
              {/* <img
                src={
                  account.website
                    ? getImageFromUrl(account.website, null)
                    : require("./defaultImage.jpg")
                }
                className="profile-image"
              /> */}
              <Avatar
                key={account.objectId}
                src={
                  account.website ? getImageFromUrl(account.website, null) : ''
                }
                className={
                  account.website
                    ? 'profile-image'
                    : 'profile-image account-avatar white'
                }
              >
                {stringAvatarAccount(account.name || '')}
              </Avatar>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                rowGap: 4,
                columnGap: 4,
                marginBottom: 9,
              }}
            >
              <TagsBoxShowAll
                type="Account"
                blockIsEditable={true}
                //For now we decided to always allow users to change account tags
                disableEdit={false}
                currentSituationTags={
                  account.externalValues
                    ?.filter((ep) => ep.isTag && ep.value !== null)
                    .map((ep) => {
                      return {
                        externalPropertyId: ep.externalPropertyId,
                        option: ep.value!,
                      };
                    }) ?? []
                }
                handleClickTag={handleClickTag}
                showRemovable={false}
              />
            </div>
            {contactInformation.map((field, index) => {
              const link = account[field.name] as string;
              return (
                <div
                  key={index + account.objectId}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  className="editable-div"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    {field.name === 'linkedin' ? (
                      <Tooltip
                        enterDelay={400}
                        enterNextDelay={400}
                        title={`${
                          validValue ? 'Valid' : 'Invalid'
                        } linkedin url`}
                        arrow
                      >
                        <Badge
                          sx={{
                            '& .MuiBadge-badge': {
                              right: 6,
                              top: 6,
                              background: validValue
                                ? colors.callScheduled
                                : colors.inactive,
                            },
                          }}
                          id="email-badge"
                          variant="dot"
                        >
                          {field.icon}
                        </Badge>
                      </Tooltip>
                    ) : (
                      field.icon
                    )}
                    <TextField
                      name={field.name}
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      fullWidth
                      placeholder="Insert a new information..."
                      // onInput={(ev) =>
                      //   handleChange(prospect.objectId, ev)
                      // }
                      onChange={(ev) => {
                        formik.setFieldValue(field.name, ev.target.value);
                      }}
                      value={formik.values[getInformation(field.name)]}
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChange(account.objectId, ev);
                        }
                      }}
                      onBlur={(ev: any) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChange(account.objectId, ev);
                      }}
                    ></TextField>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 4,
                    }}
                  >
                    {showBadge && editedAccount === field.name && (
                      <p className="edited-field-badge">
                        Saved
                        <IconXSCheckmark color={colors.primaryDark} />
                      </p>
                    )}
                    <span
                      style={{ height: 24 }}
                      className="edit-editable-div-icon"
                    >
                      <EditIcon />
                    </span>
                    {(field.name === 'website' &&
                      account.website !== '' &&
                      account.website) ||
                    (field.name === 'linkedin' &&
                      account.linkedin !== '' &&
                      account.linkedin) ? (
                      <IconButton
                        onClick={() => {
                          if (link && transformToValidUrl(link) !== null)
                            window.open(transformToValidUrl(link)!, '_blank');
                        }}
                      >
                        <RedirectIcon />
                      </IconButton>
                    ) : field.name === 'website' ? (
                      loadingLinkedinCompanyInfo ? (
                        <FlexDiv style={{ width: 40, height: 40 }}>
                          <CircularProgress style={{ width: 30, height: 30 }} />
                        </FlexDiv>
                      ) : (
                        <IconButton
                          onClick={() => handleSearchInformation()}
                          sx={{
                            padding: '3px 2px 3px 6px !important',
                            backgroundColor: colors.primary,
                            width: '76px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '6px',
                            borderRadius: '34px',
                            cursor: undefined,
                            '&.MuiIconButton-root:hover': {
                              backgroundColor: colors.primaryDark,
                            },
                          }}
                        >
                          <Typography fontSize={'14px'} color={colors.white}>
                            Find
                          </Typography>

                          <SearchIcon color={colors.white} />
                        </IconButton>
                      )
                    ) : field.name === 'linkedin' ? (
                      <IconButton
                        onClick={() => setShowConfirmCompany(true)}
                        sx={{
                          padding: '3px 2px 3px 6px !important',
                          backgroundColor: colors.primary,
                          width: '76px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '6px',
                          borderRadius: '34px',
                          cursor: undefined,
                          '&.MuiIconButton-root:hover': {
                            backgroundColor: colors.primaryDark,
                          },
                        }}
                      >
                        <Typography fontSize={'14px'} color={colors.white}>
                          Find
                        </Typography>

                        <SearchIcon color={colors.white} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(link);
                        }}
                      >
                        <IconCopy />
                      </IconButton>
                    )}
                  </div>
                </div>
              );
            })}
            {/*CRM link */}
            {user?.company?.integrationType !== IntegrationType.CRONO && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                className="editable-div"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <CRMIcon className="info-icon" />
                  <TextField
                    name="crm"
                    variant="standard"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    defaultValue={accountCrmLink}
                    disabled
                  ></TextField>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 4,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      if (
                        accountCrmLink &&
                        transformToValidUrl(accountCrmLink) !== null
                      )
                        window.open(
                          transformToValidUrl(accountCrmLink)!,
                          '_blank',
                        );
                    }}
                  >
                    <RedirectIcon />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
          <div className="divider" style={{ marginTop: 18 }} />

          <div className="bottom-half">
            {accountInformation.map((field, index) => {
              let link = account[field.name] as string;
              if (field.name === 'prospects') {
                link = '' + account.totalProspects ?? account.prospects?.length;
              }
              if (field.name === 'industry') {
                return (
                  <div
                    key={index + account.objectId}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className="information-editable-container"
                  >
                    <div>
                      <Tooltip arrow title={field.label}>
                        <Typography className="information-label account-information">
                          {field.label}:
                        </Typography>
                      </Tooltip>
                    </div>
                    <CronoAutocomplete
                      key={account.objectId}
                      className="field-input no-padding"
                      defaultValue={link}
                      freeSolo
                      fullWidth
                      size="small"
                      onChange={(ev, value) => {
                        handleChange(account.objectId, {
                          target: { name: field.name, value: value ?? '' },
                        });
                      }}
                      onInput={(ev: any) => {
                        handleChange(account.objectId, {
                          target: {
                            name: field.name,
                            value: ev.target.value ?? '',
                          },
                        });
                      }}
                      blurOnSelect
                      options={linkedinIndustries.map(
                        (industry) => industry.label,
                      )}
                      placeholder="Add information..."
                      slotProps={{
                        popper: {
                          sx: { zIndex: '35001 !important' },
                        },
                      }}
                      sx={{
                        boxShadow: 'none',
                        height: 20,
                        '& .MuiInputBase-sizeSmall': {
                          borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                          padding: '0',
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                          {
                            border: 0,
                          },
                      }}
                      disablePortal={false}
                    />
                  </div>
                );
              } else
                return (
                  <div
                    key={index + account.objectId}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className="information-editable-container"
                  >
                    <div>
                      <Tooltip arrow title={field.label}>
                        <Typography className="information-label account-information">
                          {field.label}:
                        </Typography>
                      </Tooltip>
                    </div>
                    {/* <div className="editable-div"> */}
                    <TextField
                      className="field-input"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name={field.name}
                      autoComplete="off"
                      fullWidth
                      defaultValue={link || ''}
                      placeholder="Add information..."
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChange(account.objectId, ev);
                        }
                      }}
                      onBlur={(ev: any) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChange(account.objectId, ev);
                      }}
                    ></TextField>
                    {/* </div> */}
                    {/* <span>
                      <EditIcon />
                    </span> */}
                  </div>
                );
            })}
            {(externalProperties?.data?.data || [])
              .filter((externalProperty) => !externalProperty.isTag)
              .map((externalProperty, index) => (
                <div
                  key={
                    '' + index + externalProperty?.valueType + account.objectId
                  }
                  className="information-editable-container"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin:
                      externalProperty.valueType === 'Text' ? '9px 0px' : '',
                  }}
                >
                  <div className="external-properties-tag">
                    <Tooltip arrow title={externalProperty.publicName}>
                      <Typography className="information-label">
                        {externalProperty.publicName}:
                      </Typography>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <ExternalPropertyNotTag
                      type="Account"
                      externalValues={account.externalValues}
                      externalProperty={externalProperty}
                      objectId={account.objectId}
                      //We allow everyone to edit, even if he is not the owner
                      owned={true}
                      key={index}
                    />
                  </div>
                </div>
              ))}
            <div className="information-editable-container">
              <div className="external-properties-tag">
                <Typography className="information-label">Owner:</Typography>
              </div>
              <CronoSelect
                value={account.userId}
                onChange={(ev) => {
                  updateAccount({
                    accountId: account.objectId,
                    userId: ev.target.value as number,
                  });
                }}
                style={{ height: 32, overflow: 'hidden', flex: 1 }}
                MenuProps={{
                  disablePortal: true,
                }}
              >
                {users.map((user) => {
                  return (
                    <CronoMenuItem value={user.id} key={user.id}>
                      {user.firstName} {user.lastName}
                    </CronoMenuItem>
                  );
                })}
              </CronoSelect>
            </div>
          </div>
        </div>

        {(((account.objectId.startsWith(Constants.accountPrefix) ||
          !account.isSynced) &&
          account.prospects &&
          account.totalProspects === 0) ||
          user?.company?.integrationType === IntegrationType.CRONO ||
          (user?.otherSettings?.hasLateSync && !account.isSynced)) &&
          account.owned &&
          (loadingDisableAccount ? (
            <CircularProgress size={20} />
          ) : (
            <span>
              <Tooltip
                arrow
                title={
                  account.owned
                    ? 'Delete company'
                    : "You can't delete this company"
                }
              >
                <RemoveButton
                  className="remove-button"
                  startIcon={<DeleteOutlineRounded />}
                  variant="contained"
                  onClick={() => handleDisableAccount(account.objectId)}
                >
                  Delete
                </RemoveButton>
              </Tooltip>
            </span>
          ))}
      </div>

      <div className="layout-grid-item container">
        <div className="grid-item">
          <NotesBox />
        </div>
      </div>
    </div>
  );
};

export default AccountView;
