import React, { useMemo, useRef, useState } from 'react';
import { Column } from '@ant-design/charts';
import { GraphData } from '../tabsControllers/model';
import { LinkedinSmallLegend, colorsLinkedinSmall } from '../legendData';
import { calcWidth, numberOfGroups, selectChartContainerClass } from '../model';
import { Typography } from '@mui/material';
import Legend from '../legend';
import { AnalyticsTemplate } from 'crono-fe-common/types/analyticsTemplate';
import { BarChartPlaceholder } from './chartsPlaceholders';

interface IProps {
  templateAnalytics: AnalyticsTemplate | undefined;
}

const TemplateLinkedinChart = ({
  templateAnalytics: taskAnalytics,
}: IProps) => {
  const [maxValue, setMaxValue] = useState<number>(0);

  const dataGeneral = useMemo(() => {
    if (!taskAnalytics) return [];
    let newValue = 0;
    const newData: GraphData[] = [];
    taskAnalytics?.linkedinTemplateReplyRate.TotalCount?.forEach((total) => {
      if (total.count === 0) return;
      if (total.count > newValue) newValue = total.count;
      newData.push({
        label: total.label,
        value: total.count,
        type: 'Sent',
      });
    });
    taskAnalytics?.linkedinTemplateReplyRate.ReplyCount?.forEach((reply) => {
      if (reply.count === 0) return;
      if (reply.count > newValue) newValue = reply.count;
      newData.push({
        label: reply.label,
        value: reply.count,
        type: 'Replied',
      });
    });
    setMaxValue(newValue);
    return newData;
  }, [taskAnalytics]);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const configGeneral = useMemo(() => {
    return {
      data: dataGeneral,
      xField: 'label',
      yField: 'value',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index =
          LinkedinSmallLegend.findIndex((legend) => legend === type) ?? 0;
        return colorsLinkedinSmall[index];
      }) as any,
      autoFit: false,
      height: 300,
      width: calcWidth(
        dataGeneral,
        180,
        chartContainerRef.current?.offsetWidth,
      ),
      meta: {
        value: {
          min: 0,
          max: maxValue * 1.2,
        },
      },
      isGroup: true,
      columnStyle: {},
      renderer: 'svg' as 'svg' | 'canvas' | undefined,
      //If only one ground is present, the graph doesn't work if dodgePadding is set
      dodgePadding: numberOfGroups(dataGeneral) > 1 ? 6 : undefined,

      maxColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      minColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      legend: false as any,
      randomVal: Math.random() * 10000,
    };
  }, [dataGeneral, chartContainerRef.current?.offsetWidth, maxValue]);

  return (
    <>
      <Typography className="chart-title">
        Linkedin Template Conversion Rate
      </Typography>
      <div
        className={selectChartContainerClass(dataGeneral)}
        ref={chartContainerRef}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 40,
            position: 'sticky',
            left: 0,
          }}
        >
          <Legend colors={colorsLinkedinSmall} fields={LinkedinSmallLegend} />
        </div>
        {configGeneral?.data && configGeneral.data.length ? (
          <Column {...configGeneral} key={configGeneral.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <BarChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default TemplateLinkedinChart;
