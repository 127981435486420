import { LinkedinFilterValue } from './linkedin';

export const LinkedinAnnualRevenueMap: LinkedinFilterValue[] = [
  { id: '0', label: '0' },
  { id: '0.5', label: '0.5' },
  { id: '1', label: '1' },
  { id: '2.5', label: '2.5' },
  { id: '5', label: '5' },
  { id: '10', label: '10' },
  { id: '20', label: '20' },
  { id: '50', label: '50' },
  { id: '100', label: '100' },
  { id: '500', label: '500' },
  { id: '1000', label: '1000' },
  { id: '1001', label: '1000+' },
];

export const LinkedinYearsInCurrentPositionMap: LinkedinFilterValue[] = [
  {
    label: 'Less than 1 year',
    id: '1',
  },
  {
    label: '1 to 2 years',
    id: '2',
  },
  {
    label: '3 to 5 years',
    id: '3',
  },
  {
    label: '6 to 10 years',
    id: '4',
  },
  {
    label: 'More than 10 years',
    id: '5',
  },
];

export const LinkedinCurrencyMap: LinkedinFilterValue[] = [
  { id: 'USD', label: '$' },
  { id: 'AED', label: 'د.إ' },
  { id: 'AUD', label: '$' },
  { id: 'BRL', label: '$' },
  { id: 'CAD', label: '$' },
  { id: 'CNY', label: '¥' },
  { id: 'DKK', label: 'kr' },
  { id: 'EUR', label: '€' },
  { id: 'GBP', label: '£' },
  { id: 'HKD', label: '$' },
  { id: 'IDR', label: 'Rp' },
  { id: 'ILS', label: '₪' },
  { id: 'INR', label: '₹' },
  { id: 'JPY', label: '¥' },
  { id: 'NOK', label: 'kr' },
  { id: 'NZD', label: '$' },
  { id: 'RUB', label: 'руб' },
  { id: 'SEK', label: 'kr' },
  { id: 'SGD', label: '$' },
  { id: 'THB', label: '฿' },
  { id: 'TRY', label: 'TL' },
  { id: 'TWD', label: '$' },
];

export const LinkedinDepartmentMap: LinkedinFilterValue[] = [
  { id: '1', label: 'Accounting' },
  { id: '2', label: 'Administrative' },
  { id: '3', label: 'Arts and Design' },
  { id: '4', label: 'Business Development' },
  { id: '5', label: 'Community and Social Services' },
  { id: '6', label: 'Consulting' },
  { id: '7', label: 'Education' },
  { id: '8', label: 'Engineering' },
  { id: '9', label: 'Entrepreneurship' },
  { id: '10', label: 'Finance' },
  { id: '11', label: 'Healthcare Services' },
  { id: '12', label: 'Human Resources' },
  { id: '13', label: 'Information Technology' },
  { id: '14', label: 'Legal' },
  { id: '15', label: 'Marketing' },
  { id: '16', label: 'Media and Communication' },
  { id: '17', label: 'Military and Protective Services' },
  { id: '18', label: 'Operations' },
  { id: '19', label: 'Product Management' },
  { id: '20', label: 'Program and Project Management' },
  { id: '21', label: 'Purchasing' },
  { id: '22', label: 'Quality Assurance' },
  { id: '23', label: 'Real Estate' },
  { id: '24', label: 'Research' },
  { id: '25', label: 'Sales' },
  { id: '26', label: 'Support' },
];

export const LinkedinSeniorityMap: LinkedinFilterValue[] = [
  {
    label: 'Owner / Partner',
    id: '320',
  },
  {
    label: 'CXO',
    id: '310',
  },
  {
    label: 'Vice President',
    id: '300',
  },
  {
    label: 'Director',
    id: '220',
  },
  {
    label: 'Experienced Manager',
    id: '210',
  },
  {
    label: 'Entry Level Manager',
    id: '200',
  },
  {
    label: 'Strategic',
    id: '130',
  },
  {
    label: 'Senior',
    id: '120',
  },
  {
    label: 'Entry Level',
    id: '110',
  },
  {
    label: 'In Training',
    id: '100',
  },
];

export const SalesNavCompanyHeadcountMap: LinkedinFilterValue[] = [
  { label: 'Self-employed', id: 'A' },
  { label: '1-10', id: 'B' },
  { label: '11-50', id: 'C' },
  { label: '51-200', id: 'D' },
  { label: '201-500', id: 'E' },
  { label: '501-1000', id: 'F' },
  { label: '1001-5000', id: 'G' },
  { label: '5001-10,000', id: 'H' },
  { label: '10,000+', id: 'I' },
];
