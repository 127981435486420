import { ClickAwayListener, Typography } from '@mui/material';
import { EditColumnsDropdownWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import {
  CancelSmallButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'context/auth';
import { UserPreferences } from 'crono-fe-common/types/userPreferences';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { EditColumnsType } from 'pages/searchComponent/editColumnsModal';
import { FeConstants } from 'constants/FeConstants';
import { useJuneAnalytics } from 'context/june';
import { useConfirmModal } from 'context/confirmModal';
import { DashboardTabType } from '../index';

export enum EditListColumnsType {
  Contacts,
  Companies,
}

export const defaultColumnsContacts =
  'contactName,companyName,status,email,phone,owner,progress,lastActivity,createdDate,activities';

export const defaultColumnsCompanies =
  'companyName,status,owner,allContacts,inStrategy,lastActivity,createdDate,activities';

//each tab can be visible in multiple places
interface IColumnType {
  columnTitle: string;
  columnId: string;
  columnsTypes?: EditListColumnsType[];
}

const editColumns: IColumnType[] = [
  {
    columnTitle: 'Contact Name',
    columnId: 'contactName',
    columnsTypes: [],
  },
  {
    columnTitle: 'Company name',
    columnId: 'companyName',
    columnsTypes: [EditListColumnsType.Contacts],
  },
  {
    columnTitle: 'Status',
    columnId: 'status',
    columnsTypes: [EditListColumnsType.Contacts, EditListColumnsType.Companies],
  },
  {
    columnTitle: 'Email',
    columnId: 'email',
    columnsTypes: [EditListColumnsType.Contacts],
  },
  {
    columnTitle: 'Phone',
    columnId: 'phone',
    columnsTypes: [EditListColumnsType.Contacts],
  },
  {
    columnTitle: 'Owner',
    columnId: 'owner',
    columnsTypes: [EditListColumnsType.Contacts, EditListColumnsType.Companies],
  },
  {
    columnTitle: 'Progress',
    columnId: 'progress',
    columnsTypes: [EditListColumnsType.Contacts],
  },
  {
    columnTitle: 'Last Activity',
    columnId: 'lastActivity',
    columnsTypes: [EditListColumnsType.Contacts, EditListColumnsType.Companies],
  },
  {
    columnTitle: 'Created Date',
    columnId: 'createdDate',
    columnsTypes: [EditListColumnsType.Contacts, EditListColumnsType.Companies],
  },
  {
    columnTitle: 'Activities',
    columnId: 'activities',
    columnsTypes: [EditListColumnsType.Contacts, EditListColumnsType.Companies],
  },
  {
    columnTitle: 'All Contacts',
    columnId: 'allContacts',
    columnsTypes: [EditListColumnsType.Companies],
  },
  {
    columnTitle: 'In Strategy',
    columnId: 'inStrategy',
    columnsTypes: [EditListColumnsType.Companies],
  },
];

interface IProps {
  editColumnsType: EditListColumnsType;
  onClose: () => void;
  onApply: () => void;
  // used only in ContactTable
  tabState?: DashboardTabType;
}

const EditColumnsDropdown = ({
  editColumnsType,
  onClose,
  onApply,
  tabState,
}: IProps) => {
  const { user } = useAuth();
  const { mutate: patchUserPreferences } = usePatchUserPreferences();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const { data: exPropContacts } = useGetExternalProperty(
    tabState === 'Lead' ? 'Lead' : 'Prospect',
    true,
    false,
  );

  const { data: exPropCompanies } = useGetExternalProperty(
    'Account',
    true,
    false,
    true,
  );

  const filteredColumns: IColumnType[] = useMemo(() => {
    const defaultColumns: IColumnType[] = editColumns.filter((column) =>
      column.columnsTypes?.includes(editColumnsType),
    );
    switch (editColumnsType) {
      case EditListColumnsType.Contacts:
        if (exPropContacts?.data?.data) {
          defaultColumns.splice(
            FeConstants.externalPropertyProspectsInitialIndex,
            0,
            ...exPropContacts.data.data.map((p) => {
              return {
                columnId: String(p.id),
                columnTitle: String(p.publicName),
              };
            }),
          );
        }
        break;
      case EditListColumnsType.Companies:
        if (exPropCompanies?.data?.data) {
          defaultColumns.splice(
            FeConstants.externalPropertyAccountsInitialIndex,
            0,
            ...exPropCompanies.data.data.map((p) => {
              return {
                columnId: String(p.id),
                columnTitle: String(p.publicName),
              };
            }),
          );
        }
        break;
    }

    return defaultColumns;
  }, [editColumnsType, exPropCompanies, exPropContacts]);

  useEffect(() => {
    if (!user) return;

    const userPreferenceKey =
      editColumnsType === EditListColumnsType.Contacts
        ? 'listProspectColumns'
        : 'listAccountColumns';

    const defaultColumnsString =
      editColumnsType === EditListColumnsType.Contacts
        ? defaultColumnsContacts
        : defaultColumnsCompanies;

    const existingPreferences = user.userPreferences?.[userPreferenceKey];

    if (existingPreferences) {
      setSelectedColumns(existingPreferences.split(','));
    } else {
      const defaultColumnValues: string[] = defaultColumnsString.split(',');

      const externalProperties =
        editColumnsType === EditListColumnsType.Contacts
          ? exPropContacts?.data?.data
          : exPropCompanies?.data?.data;

      if (analytics) {
        analytics.track('edit-columns-find-new', {});
      }
      if (externalProperties) {
        const spliceIndex: number =
          editColumnsType === EditListColumnsType.Contacts
            ? FeConstants.externalPropertyProspectsInitialIndex
            : FeConstants.externalPropertyAccountsInitialIndex;
        defaultColumnValues.splice(
          spliceIndex,
          0,
          ...externalProperties.map((p) => String(p.id)),
        );
      }

      setSelectedColumns(defaultColumnValues);
    }
  }, [user, exPropCompanies, exPropContacts, editColumnsType]);

  const [hasChanges, setHasChanges] = useState(false);

  const handleChangeColumn = (columnId: string) => {
    setHasChanges(true);
    setSelectedColumns((prev) =>
      prev.includes(columnId)
        ? prev.filter((column) => column !== columnId)
        : [...prev, columnId],
    );
  };

  const updateUserPreferences = (
    columns: string[],
    type: keyof Pick<
      UserPreferences,
      'listProspectColumns' | 'listAccountColumns'
    >,
  ) => {
    if (!user) return;

    const value = columns.join(',');

    if (!user.userPreferences) {
      user.userPreferences = {} as UserPreferences;
    }

    user.userPreferences[type] = value;

    patchUserPreferences({
      [type]: value,
    });
  };

  const analytics = useJuneAnalytics();

  const handleApply = () => {
    switch (editColumnsType) {
      case EditListColumnsType.Contacts:
        updateUserPreferences(selectedColumns, 'listProspectColumns');

        if (analytics) {
          analytics.track('edit-columns-main-list-prospect', {});
        }
        break;
      case EditListColumnsType.Companies:
        updateUserPreferences(selectedColumns, 'listAccountColumns');

        if (analytics) {
          analytics.track('edit-columns-main-list-account', {});
        }
        break;
    }
    onApply();
  };

  const { openModal } = useConfirmModal();

  const handleOpenConfirm = () => {
    if (!hasChanges) {
      onClose();
      return;
    }
    openModal({
      title: 'Do you want to save your changes?',
      confirmText: 'Save',
      cancelText: 'Discard',
      type: 'danger',
      confirmFunction: () => {
        handleApply();
        onClose();
      },
      cancelFunction: onClose,
    });
  };

  return (
    <ClickAwayListener onClickAway={handleOpenConfirm}>
      <EditColumnsDropdownWrapper>
        <div className="edit-columns-top">
          <Typography
            fontSize={'12px'}
            lineHeight={'16px'}
            fontWeight={500}
            color={colors.grey11}
            marginBottom={'8px'}
            marginLeft={'8px'}
          >
            Shown columns:
          </Typography>

          <div className="edit-columns-list">
            {filteredColumns.map(({ columnTitle, columnId }) => (
              <div className="edit-columns-item" key={columnId}>
                <div className="edit-columns-check">
                  <CronoCheckbox
                    checked={selectedColumns.includes(columnId)}
                    onChange={() => handleChangeColumn(columnId)}
                  />
                </div>

                <Typography
                  fontSize={'12px'}
                  lineHeight={'16px'}
                  fontWeight={500}
                  color={colors.black}
                >
                  {columnTitle}
                </Typography>
              </div>
            ))}
          </div>
        </div>

        <div className="edit-columns-buttons">
          <CancelSmallButton onClick={onClose} fullWidth>
            <Typography
              fontSize={'14px'}
              lineHeight={'18px'}
              fontWeight={500}
              color={colors.black}
            >
              Cancel
            </Typography>
          </CancelSmallButton>

          <MainButton
            height={'32px'}
            margin={'0px'}
            fullWidth
            onClick={handleApply}
          >
            <Typography
              fontSize={'14px'}
              lineHeight={'18px'}
              fontWeight={500}
              color={colors.white}
            >
              Apply
            </Typography>
          </MainButton>
        </div>
      </EditColumnsDropdownWrapper>
    </ClickAwayListener>
  );
};

export default EditColumnsDropdown;
