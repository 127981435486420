import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type ProspectDisableInputs = {
  AccountId: string;
  ProspectId: string;
};

export default function useDisableProspect() {
  const queryClient = useQueryClient();

  const disableProspect = (data: ProspectDisableInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.prospect.disable}`,
      config: {
        method: 'delete',
        data: data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    ProspectDisableInputs
  >(disableProspect, {
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.account.main),
      });
    },
  });

  return { data, mutate, ...rest };
}
