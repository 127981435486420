import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React from 'react';
import { Typography } from '@mui/material';
import AddStatusForm from '../formComponent';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useJuneAnalytics } from 'context/june';
import { AddContainerWrapper } from 'pages/addComponents/style';

interface IProps {
  close: () => void;
  existingStatuses?: string[];
  handleCreateNewStatus: (newStatus: string) => void;
  createLoading: boolean;
}

const AddStatusContainer = ({
  close,
  existingStatuses: existingStages,
  handleCreateNewStatus,
  createLoading,
}: IProps) => {
  const analytics = useJuneAnalytics();

  const handleSubmit = (newStatus: string) => {
    handleCreateNewStatus(newStatus);

    if (analytics) {
      analytics.track('create-new-status', {});
    }
  };

  return (
    <BlurBackgroundDivFixed onClick={close}>
      <AddContainerWrapper
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
      >
        <CloseTabButton close={close} style={{ marginRight: '18px' }} />
        <div className="add-main-container" style={{ gap: '32px' }}>
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Create new status
          </Typography>
          <AddStatusForm
            close={close}
            handleSubmit={handleSubmit}
            createLoading={createLoading}
            existingStatuses={existingStages}
          />
        </div>
      </AddContainerWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default AddStatusContainer;
