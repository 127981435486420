import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { useAuth } from 'context/auth';
import { CompleteSubscriptionWrapper } from './style';

import {
  SubscriptionImageContainer,
  SubscriptionStepWrapper,
} from 'pages/connect/views/main/styles';
import Logo from 'assets/images/logo_crono.png';
import { colors } from 'crono-fe-common/theme';
import SubscriptionStep from './integrationStep';

import SubscriptionStep1Image from 'assets/images/ill_Step1.png';
import SubscriptionStep2Image from 'assets/images/ill_Step2.png';
import SubscriptionStep3Image from 'assets/images/ill_Step3.png';

export interface ISubscriptionStep {
  name: 'welcome' | 'integrations' | 'invite';
  stepText: {
    title: string;
    subtitle: string;
  };
  image: any;
}

const subscriptionSteps: ISubscriptionStep[] = [
  {
    name: 'welcome',
    stepText: {
      title: 'Welcome to Crono!',
      subtitle:
        "Let's finish setting up your account to start using the platform",
    },
    image: (
      <div
        className="image-container-background"
        style={{ background: colors.grey6 }}
      >
        <img src={SubscriptionStep1Image} className="subscription-image" />
      </div>
    ),
  },
  {
    name: 'integrations',
    stepText: {
      title: 'Choose your integrations',
      subtitle: 'Integrate your usual CRM to transfer all prospects in seconds',
    },
    image: (
      <div
        className="image-container-background"
        style={{ background: colors.primaryLight }}
      >
        <img src={SubscriptionStep2Image} className="subscription-image" />
      </div>
    ),
  },
  {
    name: 'invite',
    stepText: {
      title: 'Invite your team',
      subtitle:
        'Please invite new users to join you on Crono and be part of your team',
    },
    image: (
      <div
        className="image-container-background"
        style={{ background: colors.grey6 }}
      >
        <img src={SubscriptionStep3Image} className="subscription-image" />
      </div>
    ),
  },
];

const CompleteSubscription: FC = () => {
  const { user, useAuthGuard } = useAuth();
  useAuthGuard();

  // Similar to onboardingState, a subscriptionState could be useful to keep track of the user advance in the process,
  // should it close the tab of window, or some error occur. Or it could be treated as a form, so it the user closes
  // something, it should restart from scratch.

  //Current step showed (the user can go back and forward to update entered data)
  const [currentStep, setCurrentStep] = useState<number>(0);

  const handleGoNextStep = () => {
    setCurrentStep((prev) => {
      return currentStep === subscriptionSteps.length - 1
        ? currentStep
        : prev + 1;
    });
  };

  const handleBack = () => {
    setCurrentStep((prev) => {
      return currentStep === 0 ? currentStep : prev - 1;
    });
  };

  return (
    <CompleteSubscriptionWrapper>
      <SubscriptionStepWrapper>
        <div className="logo-container">
          <img src={Logo} className="logo" alt="Crono Logo" />
        </div>
        <div className="divider" />
        <div className="subscription-steps-container">
          <div className="subscription-step">
            <div className="step-title-container">
              <Typography
                fontSize={'28px'}
                fontWeight={700}
                lineHeight={'32px'}
              >
                {subscriptionSteps[currentStep ?? 0].stepText.title}
              </Typography>
              <Typography
                fontSize={'16px'}
                fontWeight={500}
                lineHeight={'24px'}
                color={colors.grey11}
                textAlign={'center'}
                maxWidth={'320px'}
              >
                {subscriptionSteps[currentStep ?? 0].stepText.subtitle}
              </Typography>
            </div>
            {/* Step Component */}
            <div style={{ width: '100%', flex: 1, overflow: 'auto' }}>
              <SubscriptionStep
                user={user}
                step={subscriptionSteps[currentStep ?? 0]}
                handleGoNextStep={handleGoNextStep}
                handleBack={handleBack}
              />
            </div>
          </div>
        </div>
      </SubscriptionStepWrapper>
      <SubscriptionImageContainer>
        {subscriptionSteps[currentStep ?? 0].image}
      </SubscriptionImageContainer>
    </CompleteSubscriptionWrapper>
  );
};

export default CompleteSubscription;
