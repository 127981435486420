import React from 'react';
import { Typography } from '@mui/material';
import { FeatureListItemWrapper } from './style';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { colors } from 'crono-fe-common/theme';
import classNames from 'classnames';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';

interface IProps {
  type: SubscriptionPlanType;
  feature: string;
  firstFeature: boolean;
}

const FeatureListItem = ({ type, feature, firstFeature }: IProps) => {
  return (
    <FeatureListItemWrapper
      className={classNames({
        'includes-previous-feature': firstFeature,
      })}
    >
      <Typography fontSize={'14px'} lineHeight={'20px'}>
        {feature}
      </Typography>
      <span
        className={classNames({
          'includes-previous-feature-icon': firstFeature,
          'feature-checkmark': !firstFeature,
          'free-trial-feature': type === SubscriptionPlanType.FREE_TRIAL,
        })}
      >
        <CheckMarkIcon color={colors.darkGreen} strokeWidth={2} />
      </span>
    </FeatureListItemWrapper>
  );
};

export default FeatureListItem;
