import React, { useEffect, useMemo } from 'react';
import { SubscriptionStepContainer } from './styles';
import { colors } from 'crono-fe-common/theme';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { FormikConfig } from 'formik/dist/types';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { ReactComponent as PrincingIcon } from 'assets/images/Princing_icon.svg';
import { User } from 'crono-fe-common/types/user';
import { TextField, Typography } from '@mui/material';
import useGetSubscriptionProducts from 'hooks/services/subscription/useGetSubscriptionProducts';
import { FeConstants } from 'constants/FeConstants';

interface IProps {
  //Bad but no type support found
  formikWelcome: any;
  user: User | null | undefined;
}

const WelcomeStep = ({ formikWelcome, user }: IProps) => {
  const { data: subscriptionProducts } = useGetSubscriptionProducts();

  const selectedProduct = useMemo(() => {
    let productSelected: { billed: string | null; price: number | null } = {
      billed: null,
      price: null,
    };
    if (subscriptionProducts) {
      subscriptionProducts.data?.data.forEach((product) => {
        product?.prices.forEach((price) => {
          if (price.current) {
            productSelected = {
              billed: price?.paymentCycle,
              price: price?.monthlyTotalCycle,
            };
          }
        });
      });
    }
    return productSelected;
  }, [subscriptionProducts]);

  const refContainer = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refContainer.current) {
      refContainer.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const isFreeTrial = useMemo(() => {
    return user?.subscriptionType === SubscriptionPlanType.FREE_TRIAL;
  }, [user?.subscriptionType]);

  return (
    <SubscriptionStepContainer ref={refContainer}>
      <div className="step-card-container">
        <div className="form-box">
          <div className="form-pricing">
            <div
              className={`pricing-icon-container ${
                isFreeTrial ? 'FreeTrial' : user?.subscriptionType ?? 'Starter'
              }`}
            >
              <PrincingIcon />
            </div>
            <div className={`princing-plan-info`}>
              <Typography
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'24px'}
                color={colors.black}
              >
                {isFreeTrial ? 'Trial' : user?.subscriptionType ?? 'Starter'}{' '}
                plan
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                lineHeight={'18px'}
                color={colors.grey11}
              >
                {isFreeTrial
                  ? 'Free'
                  : `billed ${selectedProduct.billed ?? 'monthly'}`}
              </Typography>
            </div>
            <div className={`user-month-price`}>
              {isFreeTrial ? (
                <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
                  {FeConstants.freeTrialLength} days
                </Typography>
              ) : (
                <>
                  <span className="price-tag">€{selectedProduct.price} </span>
                  User/month
                </>
              )}
            </div>
          </div>
          <div className="form-names">
            <TextField
              label="First Name*:"
              placeholder="James"
              value={formikWelcome.values.firstName}
              onChange={(ev: any) => {
                formikWelcome.setFieldValue('firstName', ev.target.value);
              }}
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
                style: { color: colors.primary },
              }}
              error={
                formikWelcome.touched.firstName &&
                Boolean(formikWelcome.errors.firstName)
              }
              helperText={
                formikWelcome.touched.firstName &&
                formikWelcome.errors.firstName
              }
            />
            <TextField
              label="Last Name*:"
              placeholder="Smith"
              value={formikWelcome.values.lastName}
              onChange={(ev: any) => {
                formikWelcome.setFieldValue('lastName', ev.target.value);
              }}
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
                style: { color: colors.primary },
              }}
              error={
                formikWelcome.touched.lastName &&
                Boolean(formikWelcome.errors.lastName)
              }
              helperText={
                formikWelcome.touched.lastName && formikWelcome.errors.lastName
              }
            />
          </div>
          <TextField
            label="Company name*:"
            placeholder="Type company name..."
            value={formikWelcome.values.companyName}
            onChange={(ev: any) => {
              formikWelcome.setFieldValue('companyName', ev.target.value);
            }}
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
              style: { color: colors.primary },
            }}
            error={
              formikWelcome.touched.companyName &&
              Boolean(formikWelcome.errors.companyName)
            }
            helperText={
              formikWelcome.touched.companyName &&
              formikWelcome.errors.companyName
            }
          />
          <TextField
            label="Company website*:"
            placeholder="Insert website URL..."
            value={formikWelcome.values.companyWebsite}
            onChange={(ev: any) => {
              formikWelcome.setFieldValue('companyWebsite', ev.target.value);
            }}
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
              style: { color: colors.primary },
            }}
            error={
              formikWelcome.touched.companyWebsite &&
              Boolean(formikWelcome.errors.companyWebsite)
            }
            helperText={
              formikWelcome.touched.companyWebsite &&
              formikWelcome.errors.companyWebsite
            }
          />
          <TextField
            label="Company LinkedIn:"
            placeholder="Insert LinkedIn URL..."
            value={formikWelcome.values.companyLinkedinUrl}
            onChange={(ev: any) => {
              formikWelcome.setFieldValue(
                'companyLinkedinUrl',
                ev.target.value,
              );
            }}
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
              style: { color: colors.primary },
            }}
            error={
              formikWelcome.touched.companyLinkedinUrl &&
              Boolean(formikWelcome.errors.companyLinkedinUrl)
            }
            helperText={
              formikWelcome.touched.companyLinkedinUrl &&
              formikWelcome.errors.companyLinkedinUrl
            }
          />
        </div>
      </div>

      <div className="onboarding-navigation">
        <MainButton
          style={{ width: '120px' }}
          onClick={() => formikWelcome.handleSubmit()}
        >
          Next
        </MainButton>
      </div>
    </SubscriptionStepContainer>
  );
};

export default WelcomeStep;
