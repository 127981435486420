import { Account } from './account';
import { ExternalValue } from './externalValue';
import { SequenceInstance } from './sequenceInstance';
import { TemplateStep } from './enums/templateStep';
import { Suggestion } from './suggestion';
import { LinkedinConnectionStatus } from 'crono-fe-common/types/crono-extension/linkedin';
import VerificationStatusType from './enums/verificationStatusType';
import { DefaultContactStatusOptions } from 'crono-fe-common/types/model';

export interface Prospect {
  account?: Account | null;
  accountId: string;
  avatar?: React.ReactNode;

  createdDate?: string;

  email?: string;
  emailValid?: boolean;
  externalValues: ExternalValue[] | null;
  firstName?: string;
  fromContact: boolean;
  id: number;
  isInterested: boolean;
  isKey: boolean;

  lastActivityDate?: string;
  lastModifiedDate?: string;

  lastName?: string;
  linkedin?: string;
  linkedinStatus?: LinkedinConnectionStatus | null;
  clearPhone?: string;
  clearMobilePhone?: string;
  mobilePhone?: string;

  name?: string;
  objectId: string;
  owned: boolean;
  ownerId: string;
  phone?: string;
  phoneValid?: boolean;
  photoUrl?: string;
  suggestions?: Suggestion[] | null;
  sequenceInstance?: SequenceInstance | null;
  sequenceInstanceId?: number;
  status: ProspectStatus;
  actualStatus: DefaultContactStatusOptions | null;
  title?: string;
  userId: number;
  user: string | null;
  step: TemplateStep | null;
  totalCall?: number | null;
  totalLinkedin?: number | null;
  totalEmail?: number | null;
  totalActivity?: number | null;

  onlyInCrm?: boolean | null;
  emailStatus: VerificationStatusType | null;

  lastEmailNotFoundDate?: Date | null;
  lastPhoneNotFoundDate?: Date | null;
}

export enum ProspectStatus {
  NOT_CONTACTED = 'NotContacted',
  FIRST_CONTACT = 'FirstContact',
  WARM = 'Warm',
  COLD = 'Cold',
  NOT_INTERESTED = 'NotInterested',
}

export function hasCall(prospect: Prospect) {
  if (prospect.phone && prospect.phoneValid && prospect.phone.length > 0) {
    return true;
  }

  return false;
}

export function hasEmail(prospect: Prospect) {
  if (prospect.email && prospect.emailValid && prospect.email.length > 0) {
    return true;
  }

  return false;
}

export function hasLinkedin(prospect: Prospect) {
  if (prospect.linkedin && prospect.linkedin.length > 0) {
    return true;
  }

  return false;
}
