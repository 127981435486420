import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TokenErrorPageWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 55px;

  img {
    width: 700px;
  }

  .text {
    max-width: 500px;
    text-align: center;

    h2 {
      font-size: 24px;
      line-height: 30px;
      margin: 10px;

      &:first-child {
        margin: 0;
      }
    }

    p {
      font-size: 14px;
      line-height: 24px;
      color: ${colors.grey11};
    }
  }
`;
