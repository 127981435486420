import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const DeleteTemplateModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: ${colors.white};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 50%;
  .close-button {
    align-self: flex-end;
    cursor: pointer;
  }
  .delete-template-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    row-gap: 16px;
    height: 100%;
    overflow: hidden;
  }
  .strategies-remove-template-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: auto;
  }
`;
