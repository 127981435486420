import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InnerTextAccordionWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: fit-content;

  &.selectable {
    border: 1px solid ${colors.grey444};
    border-radius: 15px;
    padding: 4px 8px;
    align-items: start;
  }

  .inner-text-accordion-body {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    padding-right: 4px;
  }
  .inner-toggle-button {
    cursor: pointer;
    color: ${colors.darkGreen};
    height: 100%;
    min-width: fit-content;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: flex-end;
  }
  .inner-toggle-button:hover {
    color: ${colors.darkGreenHover};
  }
  .insight-icon-wrapper {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-top: 4px;
    margin-right: 8px;
  }

  .profile-description-icon {
    background-color: ${colors.primaryLightHover};
  }
`;
