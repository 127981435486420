import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import { AnalyticsTemplate } from 'crono-fe-common/types/analyticsTemplate';
import { AnalyticsSuggestion } from 'crono-fe-common/types/analyticsSuggestion';

export default function useSuggestionAnalytics(
  analyticsFilters: AnalyticsFilters,
) {
  const params: any = {};

  if (analyticsFilters.accountId) {
    params.accountId = analyticsFilters.accountId;
  }

  if (analyticsFilters.userId) {
    params.userId = analyticsFilters.userId;
  }

  if (analyticsFilters.since) {
    params.since = analyticsFilters.since;
  }

  if (analyticsFilters.to) {
    params.to = analyticsFilters.to;
  }

  const request: Request = {
    url: ENDPOINTS.analytics.suggestion,
    config: {
      method: 'post',
      data: params,
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.analytics.suggestion, params],
    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<Response<AnalyticsSuggestion>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
