import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC, useEffect, useState } from 'react';

import { EmailThreadModalWrapper } from '../../pages/accountTab/emailView/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { EmailMessage } from 'crono-fe-common/types/emailMessage';
import { Prospect } from 'crono-fe-common/types/prospect';
import useGetThread from 'hooks/services/email/useGetThread';

import { CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import { useAuth } from 'context/auth';
import { getFullName } from 'crono-fe-common/types/user';
import { User } from 'crono-fe-common/types/user';
import { CronoButton } from 'crono-fe-common/components/CronoButton';
import { cleanPixel, transformToValidUrl, prospectName } from 'utils/fe-utils';
import { ReactComponent as ErrorEmailThread } from '../../assets/images/Error_email_thread.svg';

interface EmailThreadModalProps {
  prospect: Prospect | null;
  openReply: (threadId: string | null, toAll?: boolean) => void;
  owned: boolean;
  threadId: string | null;
}

const EmailThreadModal: FC<EmailThreadModalProps> = ({
  prospect,
  openReply,
  owned,
  threadId,
}) => {
  const { user } = useAuth();

  const [selectedMessage, setSelectedMessage] = useState<EmailMessage | null>(
    null,
  );

  const {
    data: threadMessages,
    isLoading: isLoadingThreadMessages,
    isError: isErrorGetThread,
  } = useGetThread(threadId ?? '', !!threadId && owned);

  useEffect(() => {
    if (
      threadMessages?.data?.data &&
      threadMessages.data.data.length > 0 &&
      !selectedMessage
    ) {
      setSelectedMessage(cleanPixel(threadMessages.data.data[0]));
    }
  }, [threadMessages]);

  const handleOpenLinkedin = (linkedin: string) => {
    if (transformToValidUrl(linkedin) != null) {
      window.open(transformToValidUrl(linkedin)!, '_blank');
    }
  };

  const modalLinkedin = (prospect: Prospect | null) => {
    if (
      prospect &&
      prospect.linkedin &&
      transformToValidUrl(prospect.linkedin) != null
    ) {
      return (
        <LinkedInIcon
          className="linkedin-icon"
          onClick={() => handleOpenLinkedin(prospect.linkedin!)}
        />
      );
    }
    return null;
  };

  const renderMessage = (
    message: EmailMessage,
    user: User,
    prospect: Prospect,
  ) => {
    const handleSelectMessage = (message: EmailMessage) => {
      if (selectedMessage?.id === message.id) {
        setSelectedMessage(null);
      } else {
        setSelectedMessage(cleanPixel(message));
      }
    };
    const clearMessage = cleanPixel(message);
    return (
      <FlexDiv
        className="message-container"
        justifyContent="center"
        direction="column"
      >
        <FlexDiv
          className={
            'message-header'
            // message.id === selectedMessage?.id
            //   ? "message-header-selected"
            //   : "message-header"
          }
          justifyContent="space-between"
          onClick={() => handleSelectMessage(clearMessage)}
        >
          <div className="message-sender">
            <Typography fontSize={14} fontWeight={500}>
              {clearMessage.sentFromUser
                ? getFullName(user)
                : prospectName(prospect)}
            </Typography>
            <Typography fontSize={12} fontWeight={400}>
              to: {clearMessage.receiver}
            </Typography>
            {clearMessage.cc && clearMessage.cc.length > 0 && (
              <Typography fontSize={12} fontWeight={400}>
                cc: {clearMessage.cc.join(', ')}
              </Typography>
            )}
          </div>
          <Typography className="message-date" fontWeight={500} fontSize={12}>
            {moment(clearMessage.sentTime).format('LLL')}
          </Typography>
        </FlexDiv>
        {clearMessage.id === selectedMessage?.id && (
          <>
            <FlexDiv
              className="message-body"
              justifyContent="space-between"
              direction="column"
            >
              {/* selectedMessage is without pixel, while message not */}
              <div
                dangerouslySetInnerHTML={{ __html: selectedMessage.content }}
                style={{ width: '100%' }}
              />
            </FlexDiv>
            <FlexDiv
              className="reply-container"
              direction="row"
              justifyContent="end"
            >
              <CronoButton
                variant="contained"
                color="primary"
                className="reply-button"
                onClick={() => openReply(threadId)}
                style={{
                  width: 120,
                }}
                disableElevation
              >
                Reply
              </CronoButton>
              {clearMessage.cc &&
                clearMessage.cc.length > 0 &&
                clearMessage.cc.some((val: string) => val.length > 0) && (
                  <CronoButton
                    variant="contained"
                    color="primary"
                    className="reply-button"
                    style={{
                      width: 120,
                    }}
                    onClick={() => openReply(threadId, true)}
                    disableElevation
                  >
                    Reply to all
                  </CronoButton>
                )}
            </FlexDiv>
          </>
        )}
      </FlexDiv>
    );
  };

  return (
    <EmailThreadModalWrapper style={{ flex: 1, overflow: 'hidden' }}>
      <FlexDiv
        direction="column"
        className="modal-header"
        justifyContent="start"
        alignItems="start"
      >
        <FlexDiv justifyContent="space-between" alignItems="center">
          <h2>{threadMessages?.data?.data.at(0)?.subject}</h2>
        </FlexDiv>
        {/* {prospect && (
          <div className="prospect-container">
            {modalLinkedin(prospect)}
            <span className="prospect-name">{modalProspect(prospect)}</span>
            &nbsp;
            {prospect?.title && (
              <span className="prospect-role"> {" - " + prospect.title}</span>
            )}
          </div>
        )} */}
      </FlexDiv>

      <div className="modal-body">
        {isLoadingThreadMessages && (
          <FlexDiv justifyContent="center" style={{ marginTop: '50px' }}>
            <CircularProgress />
          </FlexDiv>
        )}
        {isErrorGetThread && (
          <FlexDiv justifyContent="center" style={{ marginTop: '50px' }}>
            <div className="error-mail-container">
              <ErrorEmailThread />
              <Typography
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'24px'}
              >
                Sorry, this thread could not be retrieved
              </Typography>
            </div>
          </FlexDiv>
        )}
        {/* This is replaced by EmailLogModal */}
        {/* {!owned && (
          <FlexDiv justifyContent="center" style={{ marginTop: "50px" }}>
            <p className="error">You are not the owner of this thread</p>
          </FlexDiv>
        )} */}
        {threadMessages?.data?.data &&
          prospect &&
          user &&
          threadMessages.data?.data
            .sort(
              (a, b) =>
                +new Date(b.sentTime).getTime() -
                +new Date(a.sentTime).getTime(),
            )
            .map((message: EmailMessage) =>
              renderMessage(cleanPixel(message), user, prospect),
            )}
      </div>
    </EmailThreadModalWrapper>
  );
};

export default EmailThreadModal;
