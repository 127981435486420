import { Typography } from '@mui/material';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import { colors } from 'crono-fe-common/theme';
import React, { useState } from 'react';
import { EmailNotFoundStateWrapper } from './style';
import DomainEditor from 'pages/dashboard/contactTable/prospectRow/domainEditor';
import useEditProspect from 'hooks/services/prospect/useEditProspect';

interface IProps {
  prospectId: string;
  accountId: string | null;
  website: string | null;
}

const EmailNotFoundState = ({ accountId, website, prospectId }: IProps) => {
  const [openWebsiteEditor, setOpenWebsiteEditor] = useState(false);
  const { mutate: editProspect } = useEditProspect();

  const handleConfirmWebsite = () => {
    editProspect({
      prospectId: prospectId,
      clearLastEmailNotFoundDate: true,
    });
  };
  return (
    <>
      {openWebsiteEditor && (
        <DomainEditor
          accountId={accountId}
          close={() => setOpenWebsiteEditor(false)}
          initialWebsite={website}
          onSave={() => {
            /** */
          }}
        />
      )}
      <EmailNotFoundStateWrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            background: colors.inactiveExtraLight,
            padding: 4,
            borderRadius: 8,
          }}
        >
          <Typography fontSize={14} lineHeight={'18px'} color={colors.inactive}>
            Email not found
          </Typography>
          <CloseMIcon
            color={colors.grey11}
            className="close-icon"
            onClick={handleConfirmWebsite}
          />
        </div>
        <span
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            overflow: 'hidden',
          }}
        >
          <Typography fontSize={12} lineHeight={'16px'} color={colors.grey11}>
            Website used:
          </Typography>
          <div
            className="website-name"
            onClick={() => setOpenWebsiteEditor(true)}
          >
            <Typography noWrap fontSize={12} lineHeight={'16px'}>
              {website ?? 'No website available'}
            </Typography>
            <EditPencilIconS
              color={colors.primary}
              className="edit-pencil-icon"
            />
          </div>
        </span>
      </EmailNotFoundStateWrapper>
    </>
  );
};

export default EmailNotFoundState;
