export interface EmailSettings {
  integrationType: EmailIntegrationType;
  signature: string | null;
}

export enum EmailIntegrationType {
  GMAIL = 'GMail',
  OUTLOOK = 'Outlook',
  CUSTOM = 'Custom',
}
