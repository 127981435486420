import React from 'react';
import IProps from './interface';

const IconRevenue = ({ color, className, style }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <path
        d="M9.51088 3.68593C9.51167 2.8192 7.75468 2.11719 5.58982 2.11719C3.42496 2.11719 1.66954 2.81998 1.66797 3.68593C1.66797 4.55266 3.42339 5.25467 5.58982 5.25467C7.75625 5.25467 9.51167 4.55266 9.51167 3.68593"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50781 3.68591V7.41951"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9667 7.85733C15.3449 9.23547 15.3449 11.4709 13.9667 12.8491C12.5886 14.2272 10.3531 14.2272 8.97501 12.8491C7.59687 11.4709 7.59687 9.23547 8.97501 7.85733C10.3531 6.4792 12.5886 6.4792 13.9667 7.85733"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33825 8.29424C7.63075 8.57269 6.66048 8.74525 5.58903 8.74525C3.42339 8.74525 1.66797 8.04324 1.66797 7.17651"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.29197 11.8779C7.58839 12.1469 6.64009 12.314 5.58982 12.314C3.42417 12.314 1.66875 11.612 1.66797 10.7452V3.68591"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconRevenue;
