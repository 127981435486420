import { createTheme } from '@mui/material/styles';

export const colors = {
  grey: '#222',
  mediumGrey: '#666666',
  disabledGrey: '#D3D3D3',
  borderGrey: '#E5E5E5',
  checkboxGrey: '#A0A0A0',
  lightGrey: '#f8f9fd',
  iconBlack: '#33333354',
  primaryDisabled: '#ADE2DF',
  backgroundPrimary: '#07C8C020',
  backgroundOpportunity: '#EBFBFA',
  background: '#F7F8FB',
  inactiveRed: '#F32424',
  error: '#F32424',
  backgroundRed: '#FEEDED',
  nurtureBlue: '#1F84CD',
  backgroundAvatarActive: '#C8FBC590',
  backgroundAvatarDisabled: '#BDBDBD',
  backgroundBlue: '#EDF5FE',
  workingLightBlue: '#2AC7EA',
  backgroundLightBlue: '#EEFBFD',
  callScheduledGreen: '#0AD437',
  backgroundGreen: '#EEFDF1',
  customerYellow: '#FF9F2E',
  lightYellow: '#FFC000',
  backgroundYellow: '#FFF7EE',
  dialerBlue: '#1E394C',
  dialerBackground: '#F8F9FD',
  dialerGreen: '#6BCC92',
  placeholder: '#00000048',
  linkedin: '#0077B5',
  chart1: '#AC92EB',
  chart1Lightened: '#DFC5FF',
  chart2: '#4FC1E8',
  chart3: '#A0D568',
  chart3Lightened: '#EDFFB5',
  chart4: '#FFCE54',
  chart5: '#ED5564',
  success: '#00c851',
  workingAccount: '#1F84CD',
  recommendedColor: '#37FF2C',
  actions: [
    '#1F84CD',
    '#FFCE54',
    '#B9DA7C',
    '#AC92EB',
    '#ED5564',
    '#000000',
    '#F1C63B',
    '#0000F6',
    '#00F700',
    '#F60000',
  ],
  linkedinAction: '#E8F3F1',
  newsAction: '#D1FAE8',
  findAction: '#e6f1f9',
  emailAction: '#FFF5F4',
  callAction: '#E5FFFC',
  searchProspectAction: '#feffef',
  searchAccountAction: '#FFF0FF',
  activateAction: '#efffe9',
  reminderAction: '#FFDCA940',

  //V2
  inactive: '#FD6778',
  inactiveHover: '#ED4C5E',
  inactiveLight: '#FFE2E2',
  inactiveExtraLight: '#FFF0F0',
  inactiveLightHover: '#FDD0D0',
  nurture: '#F9BB06',
  nurtureHover: '#E2AD13',
  nurtureLight: '#FEF3D2',
  nurtureLightHover: '#F8E6B1',
  prospecting: '#4E9AFF',
  prospectingHover: '#3880DF',
  prospectingLight: '#EAF1FB',
  prospectingLightHover: '#D5E0F0',
  callScheduled: '#24BA84',
  callScheduledHover: '#1A9D6E',
  callScheduledLight: '#E9F8F8',
  callScheduledLightHover: '#CEEDED',
  openOpportunity: '#A35AFF',
  openOpportunityHover: '#8846DC',
  openOpportunityLight: '#F2EAFF',
  openOpportunityLightHover: '#E4D8F6',
  customer: '#F376D8',
  customerHover: '#E769CB',
  customerLight: '#FDE5F8',
  customerLightHover: '#F3CEEB',
  darkGreen: '#0A9B94',
  darkGreenHover: '#10635F',
  lightGreen: '#55B77B',
  activeScrollbarGreen: '#07c8c0',
  analyticsGreen: '#E5F4EB',
  intenseLightBlue: '#1E9EBA',
  intenseBlue: '#2C66B1',
  intenseBlueChipBackground: '#E2EBF7',
  importFileError: '#FFEFEF',
  badgeBackground: '#EEF2F5',
  sequenceCardHover: '#f6faff',
  repliedEmailLightGreen: '#DFF1E6',
  linkedinRepliedMessageLightBlue: '#EAF3FB',
  lightBlueBackground: '#DDEAFB',
  popularPlanBadge: '#31ABA5',
  popularPlanGreen: '#0A9B94',
  ultraPlanDarkGreen: '#035C6B',
  ultraPlanBadge: '#ceeded33',
  selectedOptionGrey: '#EAF1FB',
  freeTrialYellow: '#FEF8E5',
  freeTrialDarkYellow: '#C69812',
  darkYellow: '#C69812',
  lightGreenBlue: '#EAF3FB',

  white: '#ffffff',
  black: '#010E27',
  mainDark: '#08193B',
  primary: '#1EBAB2',
  primaryDark: '#1EA59E',
  primaryLight: '#E9F8F8',
  primaryLightHover: '#CEEDED',
  primaryLightHoverHover: '#D7F0F0',
  primaryLightBackground: '#F4FCFB',
  placeholderGrey: '#F5F7FB',
  featureOnboardingHover: '#D9F1F1',

  closeRed: '#fd6878',
  backgroundCloseRed: '#ffe2e1',
  backgroundSaveGreen: '#e7f8f7',

  purple: '#A35AFF',
  purpleHover: '#8846DC',
  purpleLight: '#F2EAFF',
  purpleLightHover: '#E4D8F6',
  purpleLight2: '#995AFF',

  blue: '#5370F6',
  blueHover: '#3B58DB',
  blueLight: '#EAF1FB',
  blueLightHover: '#D5E0F0',

  orange: '#FD8267',
  orangeDark: '#E46935',
  orangeLight: '#FFEBDD',
  orangeLightHover: '#FFD8C7',

  green: '#1A9D6E',
  greenLight: '#DFF1E6',
  greenLightHover: '#C3E5D1',
  greenLight2: '#E5F5EB',

  grey1: '#3E485B',
  grey11: '#7A8395',
  grey2: '#AFB5BF',
  grey3: '#D0DCED',
  grey33: '#D7DCEB',
  grey4: '#EAF1FB',
  grey44: '#E4E7EB',
  grey444: '#E6E9F2',
  grey4Hover: '#D5E0F0',
  grey55: '#EAF1FB',
  grey5: '#E4E5E8',
  grey6: '#F5F7F9',
  grey66: '#EEF7FF',
  greyGreenLight: '#EDF9F9',
  greyHover: '#f6f9ff',
  greyLight: '#FAFCFF',
  greyButtonHoverLight: '#010E27cc',
  tableHover: '#f8fbff',
  hubspotColor: '#FF5C35',
  hubspotOrange: '#FF7A59',
  salesforceColor: '#22A0E0',
  salesforceBlue: '#20A0DF',
  lightBlue: '#DEECFF',
  pipedriveColor: '#017737',
  greyExpandButton: '#E3EFFF',
  greyHoverNote: '#F4F8FF',
  greyButtonHover: '#BFCDE1',
  greySelectBorder: '#dadde9',
  greySelectBoxShadow: '#0000001a',
  greyBackgroundScheduled: '#E9ECF3',
  greyFieldBorder: '#cdcdcd',
  greyIconCompletedTask: '#8AA0BF',
  greyScrollbarBg: '#d7dceb',
  nullStatusBackground: '#E6E9F2',
  nullStatusText: '#7A8395',

  // Insight colors

  profileViewLight: '#faf6ff',
  socialPresenceLight: '#ebf7ff',
  keyTopicsLight: '#fef7e0',
  jobOpeningsLight: '#feedfa',
  companyNewsLight: '#eef8e3',
  fundingRounds: '#FFEFE3',

  socialPresenceIcon: '#bbe2fe',
  companyNewsIcon: '#d3e9bc',
  fundingRoundsIcon: '#FED6C2',

  socialPresenceCounter: '#53b5fc',
  companyNewsCounter: '#8ac051',
  fundingRoundsCounter: '#FD9467',

  //Analytics on extension
  inStrategyCompaniesMain: '#536DE5',
  inStrategyCompaniesLight: '#ced5f6',

  completedTasksMain: '#60A54F',
  completedTasksLight: '#E8F5D9',

  errorTasksMain: '#FD6778',
  errorTasksLight: '#FFE9E9',

  mostConvertedLabel: '#3EA768',

  templatesPurple: '#D4BFFF',
  templatesPurpleHover: '#c3a7fc',

  insightsYellow: '#FFD748',
  insightsYellowHover: '#edc32d',

  rewriteWithAILightBlue: '#BFDCFF',
  rewriteWithAILightBlueHover: '#8dafd9',

  // Tag colors
  firstTagLight: [
    '#FFF5C9',
    '#F6F7C1',
    '#EDF7C8',
    '#E0F2CD',
    '#D5ECCF',
    '#D3EEDD',
    '#CEEFE3',
    '#D4E6CE',
    '#DEE6CA',
    '#E9E2C2',
    '#DFDAC5',
    '#D8D6CE',
  ],
  firstTagLightHover: [
    '#E5DCB5',
    '#DDDEAE',
    '#D5DEB4',
    '#CADAB8',
    '#C0D4BA',
    '#BED6C7',
    '#B9D7CC',
    '#BFCFB9',
    '#C8CFB6',
    '#D2CBAF',
    '#C9C4B1',
    '#C2C1B9',
  ],
  firstTag: [
    '#F0C62F',
    '#B4B662',
    '#A8C052',
    '#71B161',
    '#3EA768',
    '#3EA768',
    '#0A9B78',
    '#789C6D',
    '#95A077',
    '#AAA27F',
    '#968D68',
    '#7F7A66',
  ],
  secondTagLight: [
    '#D7F1FA',
    '#D3EDFF',
    '#DEECFF',
    '#E4E4FF',
    '#F2EAFF',
    '#DFD1F1',
    '#CBCBEB',
    '#C1D4ED',
    '#BED7E9',
    '#C4E1EB',
    '#CBE9EE',
    '#D2E7E8',
  ],
  secondTagLightHover: [
    '#C1D9E1',
    '#BED5E5',
    '#C8D4E5',
    '#CDCDE5',
    '#E4D8F6',
    '#C9BCD9',
    '#B7B7D3',
    '#AEBFD5',
    '#ABC1D2',
    '#B0CAD3',
    '#B7D2D6',
    '#BDD0D1',
  ],
  secondTag: [
    '#2DC2F1',
    '#53B5FC',
    '#4E9AFF',
    '#826DFF',
    '#995AFF',
    '#8960BD',
    '#6E5DDD',
    '#5073A2',
    '#2974AB',
    '#44869E',
    '#2596AB',
    '#498E92',
  ],
  thirdTagLight: [
    '#FFE0FC',
    '#FFE3F0',
    '#FFE2E2',
    '#FFEBDD',
    '#FAEED7',
    '#F4E4C6',
    '#F3E0D3',
    '#F1D7D5',
    '#EED3DD',
    '#F2CEDE',
    '#EDD2EB',
    '#DFD0E3',
  ],
  thirdTagLightHover: [
    '#E5CAE3',
    '#E5CCD8',
    '#E5CBCB',
    '#E5D3C7',
    '#E1D6C1',
    '#DCCDB2',
    '#DBCABE',
    '#D9C1C0',
    '#D6BEC7',
    '#DAB9C8',
    '#D5BDD3',
    '#C9BBCC',
  ],
  thirdTag: [
    '#E469E7',
    '#FD67AF',
    '#FD6778',
    '#FD9467',
    '#F7A253',
    '#CAA050',
    '#C28E6A',
    '#BD6868',
    '#A5607A',
    '#AB5079',
    '#A76AA3',
    '#8D6090',
  ],
};
export const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      light: colors.primaryLight,
      dark: colors.primaryDark,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.black,
      contrastText: colors.white,
    },
  },
});

theme.components = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 12,
      },
    },
  },
  //Not working
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#151618',
      },
      arrow: {
        color: '#151618',
      },
    },
  },
};
