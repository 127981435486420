import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const NoteIcon = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M21 13.067V18.972C21 20.092 20.099 21 18.987 21H5.013C3.901 21 3 20.092 3 18.972V6.028C3 4.908 3.901 4 5.013 4H12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.82812 17.172L11.5591 16.239C11.7351 16.195 11.8951 16.104 12.0241 15.976L20.4141 7.58599C21.1951 6.80499 21.1951 5.53899 20.4141 4.75799L20.2421 4.58599C19.4611 3.80499 18.1951 3.80499 17.4141 4.58599L9.02412 12.976C8.89612 13.104 8.80513 13.265 8.76113 13.441L7.82813 17.172"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8301 6.17L18.8301 9.17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5463 16.242C11.5603 16.136 11.5783 16.031 11.5783 15.921C11.5783 14.54 10.4593 13.421 9.07832 13.421C8.96832 13.421 8.86332 13.44 8.75732 13.453"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoteIcon;
