import useAddProspectToSequence from 'hooks/services/sequence/useAddProspectToSequence';
import React, { useEffect, useState } from 'react';
import { Prospect } from 'crono-fe-common/types/prospect';
import { ResumeSequenceWrapper } from './style';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import SequenceCard from '../sequenceCard';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { Add } from '@mui/icons-material';
import useGetStrategyById from 'hooks/services/sequence/useGetStrategyById';
import SequenceSelector from '../sequenceSelector';
import { colors } from 'crono-fe-common/theme';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  prospect: Prospect;
  setOpenTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  addSequenceOrientation: 'left' | 'right';
}

export const CustomSecondaryTooltipRight = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'absolute',
    bottom: -17,
    left: 104,
    maxWidth: 350,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

export const CustomSecondaryTooltipLeft = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'absolute',
    bottom: -17,
    right: 240,
    maxWidth: 350,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

const ResumeSequence = ({
  prospect,
  setOpenTooltip,
  addSequenceOrientation,
}: IProps) => {
  const analytics = useJuneAnalytics();

  const { data: strategy } = useGetStrategyById(
    prospect.sequenceInstance?.strategyId,
  );
  const {
    mutate: addToSequence,
    error: errorAddProspectToSequence,
    isLoading: addingProspect,
    isSuccess: prospectAdded,
  } = useAddProspectToSequence();

  useConditionalSnackBar([
    {
      condition: !!errorAddProspectToSequence,
      message:
        getError(errorAddProspectToSequence) ?? 'Error adding to strategy',
      severity: 'error',
    },
  ]);

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleResume = () => {
    if (prospect.sequenceInstance) {
      addToSequence({
        accountId: prospect.accountId,
        prospectId: prospect.objectId,
        strategyId: prospect.sequenceInstance?.strategyId,
        resume: true,
      });
      if (analytics) {
        analytics.track('add-to-sequence', {
          type: 'resume',
        });
      }
    }
  };

  useEffect(() => {
    if (prospectAdded) {
      setOpenTooltip(false);
    }
  }, [prospectAdded]);

  return (
    <ResumeSequenceWrapper>
      <Typography variant="subtitle1" fontWeight={700} fontSize={14}>
        Do you want to resume the strategy?
      </Typography>
      {prospect.sequenceInstance && strategy && (
        <div className="strategy-to-resume">
          <SequenceCard sequence={strategy.data.data} />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {addingProspect ? (
          <CircularProgress />
        ) : (
          <MainButton onClick={handleResume}>Resume</MainButton>
        )}
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            {addSequenceOrientation === 'left' ? (
              <CustomSecondaryTooltipLeft
                title={
                  <SequenceSelector
                    prospectId={prospect.objectId}
                    accountId={prospect.accountId}
                    setOpenTooltip={setOpen}
                    callOnSucces={() => {
                      setOpen(false);
                      setOpenTooltip(false);
                    }}
                  />
                }
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Button
                  startIcon={<Add fontSize="large" />}
                  color="inherit"
                  className="base-button"
                  size="small"
                  onClick={handleTooltipOpen}
                >
                  Add to new strategy
                </Button>
              </CustomSecondaryTooltipLeft>
            ) : (
              <CustomSecondaryTooltipRight
                title={
                  <SequenceSelector
                    prospectId={prospect.objectId}
                    accountId={prospect.accountId}
                    setOpenTooltip={setOpen}
                  />
                }
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Button
                  startIcon={<Add fontSize="large" />}
                  color="inherit"
                  className="base-button"
                  size="small"
                  onClick={handleTooltipOpen}
                >
                  Add to new strategy
                </Button>
              </CustomSecondaryTooltipRight>
            )}
          </div>
        </ClickAwayListener>
      </div>
    </ResumeSequenceWrapper>
  );
};

export default ResumeSequence;
