import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InsertCompanyWrapper = styled.div`
  // position: fixed;
  // top: 0;
  // left: 0;
  // height: 100%;
  // width: 100%;
  // z-index: 100;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  .background {
    background: ${colors.grey1}66;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .update-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${colors.white};
    width: 600px;
    max-height: 80%;
    border-radius: 16px;
    padding: 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    z-index: 11;
  }

  .close-button {
    align-self: flex-end;
    cursor: pointer;
    z-index: 1001;
    height: 24px;
    width: 24px;
  }
  .insert-form {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 16px;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .company-logo {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .editable-div {
    border-radius: 8px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    min-height: 26px !important;
    height: 36px !important;
  }
  .editable-div > span > svg {
    opacity: 0;
  }
  .editable-div:hover > span > svg {
    opacity: 1;
  }
  .editable-div > div > span {
    opacity: 0;
  }
  .editable-div > div > div > input:focus ~ .editable-div {
    background: ${colors.inactive};
  }
  .editable-div:hover {
    background: ${colors.grey6};
  }
  .editable-div:hover > div > span {
    opacity: 1;
  }
  .info-icon {
    min-width: 30px;
    min-height: 30px;
    padding: 3px;
    border-radius: 999px;
    border: 2px solid ${colors.grey4};
    margin-right: 4px;
  }
  .divider {
    border-top: 1px solid ${colors.grey4};
  }
  .information-label {
    color: ${colors.grey11};
    margin-right: 8px;
  }
  .information-editable-container {
    min-height: 26px !important;
    height: 30px !important;
    width: 100% !important;
    display: flex;
    align-items: center;
  }
  .new-company-badge {
    padding: 2px 6px !important;
    background: ${colors.linkedinRepliedMessageLightBlue};
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${colors.intenseBlue};
  }
  .similar-companies-found-box {
    background: ${colors.primaryLightBackground};
    border-radius: 8px;
    padding: 16px;
    height: 48px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
`;
