import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const SubscriptionIcon = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M18.355 17.087C21.882 13.622 21.882 8.063 18.355 4.598C14.828 1.133 9.171 1.133 5.645 4.598C2.119 8.063 2.118 13.622 5.645 17.087"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1 21H20.1H4.1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6V7.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V14.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.487 9C14.422 8.024 13.617 7.25 12.625 7.25H11.255C10.285 7.25 9.5 8.036 9.5 9.005C9.5 9.81 10.048 10.512 10.828 10.708L13.171 11.296C13.952 11.492 14.499 12.194 14.499 12.999C14.499 13.969 13.713 14.754 12.744 14.754H11.374C10.38 14.754 9.575 13.978 9.511 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SubscriptionIcon;
