import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const OpportunityCardWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid ${colors.grey4};
  display: flex;
  flex-direction: column;

  .deal-header-container {
    display: flex;
    width: 100%;
    gap: 12px;
    padding: 16px;
  }
  .account-avatar {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    font-size: 20px !important;
    border: 1px solid ${colors.grey4Hover};
  }
  .white {
    background: ${colors.white} !important;
  }
  .divider {
    border-top: 1px solid ${colors.grey3};
    opacity: 0.5;
    margin: 0 !important;
  }

  .deal-fields-notes-container {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 4px;
  }

  .status {
    font-size: 12px;
    min-width: 90px;
    width: fit-content;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    cursor: default !important;
    width: fit-content;
  }
  .inner-expand {
    padding: 16px;
  }
  .inner-expand-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 55%;
    border-right: 1px solid ${colors.grey4};
  }
  .inner-expand-right {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    padding: 18px 2px 0px 6px;

    & > div {
      display: flex;
      flex-direction: column;

      .note-list {
        height: 100%;
        flex: 1;
      }
    }
  }
  .type-icon,
  .disabled-type-icon {
    margin-right: 8px;
    border-radius: 999px;
    border: 2px solid ${colors.grey4};
    padding: 4px;
    cursor: pointer;
  }
  .information-editable-container {
    display: flex;
    align-items: center;
    margin: 3px 0px;
    min-height: 25px;
  }
  .external-properties-tag {
    max-width: 50%;
  }
  .field-input {
    width: 100%;
    border-radius: 8px;
    padding: 4px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-input {
      padding: 0px;
    }
    & .MuiSelect-select {
      padding: 0px 26px 0px 4px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .field-input:hover {
    background: ${colors.grey6};
  }

  .opportunity-info-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 14px;
  }

  .top-container-name-title-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .left-side {
    display: flex;
    overflow: hidden;
    justify-content: start;
    gap: 12px;
  }

  .left-name-lead {
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .pipeline-stage {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .right-side-deal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    gap: 8px;
  }

  .edited-field-badge {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70px;
    font-size: 0.8rem;
    border-radius: 16px;
    color: ${colors.primaryDark};
    background-color: ${colors.backgroundSaveGreen};
    padding: 4px 6px;
  }
  .absolute-right {
    position: absolute;
    right: 5px;
  }

  .number-of-tasks {
    position: absolute;
    top: -5px;
    right: -5px;
    min-width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.primary};
    color: ${colors.white};
    border-radius: 12px;
    font-size: 10px;
    text-align: center;
    padding: 3px 4px;
    font-weight: 600;
  }
  .show-more-fields {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    padding: 4px;
    color: ${colors.primary};
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    &:hover {
      background-color: ${colors.grey6};
    }
  }
  .show-more-fields > svg > path {
    stroke: ${colors.primary};
  }
  .show-more-fields:hover {
    color: ${colors.primaryDark};
  }
  .show-more-fields:hover > svg > path {
    stroke: ${colors.primaryDark};
  }
`;
