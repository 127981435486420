import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const DomainEditorWrapper = styled.div`
  display: flex;
  background: ${colors.white};
  border-radius: 16px;
  width: 530px;
  padding: 24px;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content-domain-editor {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px 24px 24px 24px;
    .domain-input-block {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .button-container {
      width: 100%;
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: flex-end;
      .button {
        margin: 0px;
        width: 132px;
      }
    }
  }
`;
