import { StatusComponentOptions } from 'crono-fe-common/types/StatusOptions';
import React, { useEffect, useMemo, useState } from 'react';
import { CronoStatusWrapper, StatusTagButtonWrapper } from './style';
import {
  DefaultCompanyStatus,
  DefaultCompanyStatusOptions,
  DefaultContactStatus,
  DefaultContactStatusOptions,
} from 'crono-fe-common/types/model';
import {
  getColorsStatus,
  getExternalPropertyLabels,
} from 'crono-fe-common/utils';
import { colors } from 'crono-fe-common/theme';
import {
  ClickAwayListener,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import StatusTooltipPicker from './statusTooltipPicker';
import useEditAccount from 'hooks/services/account/useEditAccount';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import { ExternalValue } from 'crono-fe-common/types/externalValue';
import IconTopArrow from 'crono-fe-common/icons/Icon-Top-Arrow';
import IconBottomArrow from 'crono-fe-common/icons/Icon-Bottom-Arrow';
import { useGetStatusesOptions } from 'utils/fe-utils';
import StatusSettingsIcon from 'crono-fe-common/icons/Icon-Status-Settings';

interface IProps {
  type: StatusComponentOptions;
  //If passed the object will call also the API to update the entity
  objectToUpdate?: string;
  currentActualStatus: string | null;
  currentExternalValues: ExternalValue[] | null;
  //This is used when the status is held locally, for example in the status picker in the footer of the tables
  //To this one both actualStatus and option can be passed
  currentPickedStatus?: string | null;
  disabled?: boolean;
  fixedWidth?: boolean;
  nullAsTagPicker?: boolean;
  //Value passed to the status picker to show how many entities will be affected (used in the footer of the tables)
  howManyAffected?: number;
  handleSetStatus?: (status: string) => void;

  style?: any;
}

const CronoStatus = ({
  type,
  objectToUpdate,
  currentActualStatus,
  currentExternalValues,
  disabled = false,
  fixedWidth = false,
  nullAsTagPicker = false,
  howManyAffected,
  currentPickedStatus,
  handleSetStatus,
  style = {},
}: IProps) => {
  const { externalPropertyStatusId, statuses, isExternalPropertyStatus } =
    useGetStatusesOptions(type);

  const currentOption = useMemo(() => {
    if (currentPickedStatus) return currentPickedStatus;
    if (isExternalPropertyStatus) {
      return currentExternalValues?.find(
        (externalValue) =>
          externalValue.externalPropertyId === externalPropertyStatusId,
      )?.value;
    } else {
      return currentActualStatus;
    }
  }, [
    currentPickedStatus,
    currentActualStatus,
    currentExternalValues,
    externalPropertyStatusId,
    isExternalPropertyStatus,
  ]);

  const currentStatus = useMemo(() => {
    return statuses.find((status) => status.option === currentOption);
  }, [statuses, currentOption]);

  const currentColors = useMemo(() => {
    const currentIndex = statuses.findIndex(
      (status) => status.option === currentOption,
    );
    if (currentIndex === -1) return null;
    return getColorsStatus(currentIndex, statuses.length);
  }, [statuses, currentOption]);

  const backgroundColor =
    currentStatus && currentColors
      ? currentColors.dark
      : colors.nullStatusBackground;

  const textColor = currentStatus ? colors.white : colors.nullStatusText;

  const [statusTooltipOpen, setStatusTooltipOpen] = useState(false);
  const handleCloseTooltip = () => {
    if (statusTooltipOpen) {
      setStatusTooltipOpen(false);
    }
  };

  const { mutate: updateAccount, isSuccess: isSuccessUpdateAccount } =
    useEditAccount();

  const { mutate: updateProspect, isSuccess: isSuccessUpdateProspect } =
    useEditProspect();

  useEffect(() => {
    if (isSuccessUpdateAccount || isSuccessUpdateProspect) {
      handleCloseTooltip();
    }
  }, [isSuccessUpdateAccount, isSuccessUpdateProspect]);

  const handleUpdateStatus = (status: string) => {
    if (objectToUpdate) {
      if (type === 'account') {
        if (isExternalPropertyStatus) {
          updateAccount({
            accountId: objectToUpdate,
            externalValues: {
              [externalPropertyStatusId!]: status,
            },
          });
        } else {
          updateAccount({
            accountId: objectToUpdate,
            status: status as DefaultCompanyStatusOptions,
          });
        }
      } else {
        if (isExternalPropertyStatus) {
          updateProspect({
            prospectId: objectToUpdate,
            externalValues: {
              [externalPropertyStatusId!]: status,
            },
          });
        } else {
          updateProspect({
            prospectId: objectToUpdate,
            actualStatus: status as DefaultContactStatusOptions,
          });
        }
      }
    } else if (handleSetStatus) {
      handleSetStatus(status);
      handleCloseTooltip();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <div>
        <StatusTooltip
          title={
            <StatusTooltipPicker
              onClick={handleCloseTooltip}
              statuses={statuses}
              currentStatus={currentOption ?? undefined}
              setStatus={handleUpdateStatus}
            />
          }
          open={statusTooltipOpen && !disabled}
          onClose={() => setStatusTooltipOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <div>
            {nullAsTagPicker ? (
              //To show a different type that is the one that looks like a tag
              <StatusTagButtonWrapper
                onClick={() => {
                  if (!disabled) {
                    setStatusTooltipOpen((prev) => !prev);
                  }
                }}
              >
                <StatusSettingsIcon className="status-settings-icon" />
                <Typography fontSize={12} lineHeight={'16px'} noWrap>
                  Status
                  {currentOption &&
                    `(${howManyAffected ?? 0}): ${statuses.find((status) => status.option === currentOption)?.label}`}
                </Typography>
                {statusTooltipOpen ? (
                  <IconTopArrow className="tag-arrow" />
                ) : (
                  <IconBottomArrow className="tag-arrow" />
                )}
              </StatusTagButtonWrapper>
            ) : (
              <CronoStatusWrapper
                style={{
                  background: backgroundColor,
                  color: textColor,
                  cursor: disabled ? 'default' : 'pointer',
                  width: fixedWidth ? 130 : 'fit-content',
                  ...style,
                }}
                onClick={() => {
                  if (!disabled) {
                    setStatusTooltipOpen((prev) => !prev);
                  }
                }}
              >
                <Tooltip
                  arrow
                  title={currentStatus?.label ?? null}
                  enterDelay={800}
                  enterNextDelay={800}
                >
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    fontWeight={500}
                    noWrap
                  >
                    {currentStatus?.label ?? 'No Value'}
                  </Typography>
                </Tooltip>
              </CronoStatusWrapper>
            )}
          </div>
        </StatusTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CronoStatus;

export const StatusTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    arrow={props.arrow ? props.arrow : false}
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 440,
    width: 'fit-content',
    border: '1px solid #dadde9',
    borderRadius: 16,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));
