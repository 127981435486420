import React from 'react';
import { SequenceFilterStatusType } from 'crono-fe-common/types/DTO/strategyDetailSearch';
import { ProspectSearchStrategyResponse } from 'crono-fe-common/types/prospectSearchStrategy';
import ReactQuill from 'react-quill';
import { RewriteSubmittedContext } from 'pages/accountTab/emailView/insightsCard';
import { RewriteSequenceInstanceInput } from 'crono-fe-common/types/DTO/rewriteSequenceInstanceInput';
import { RevertRewrittenSequenceInstanceInput } from 'crono-fe-common/types/DTO/revertRewrittenSequenceInstanceInput';

export interface StrategyOverviewContextType {
  strategyOverviewContent: StrategyOverviewContent | null;
  setStrategyOverviewContent: React.Dispatch<
    React.SetStateAction<StrategyOverviewContent | null>
  >;
  insightsContext: {
    insightsCardRef: React.MutableRefObject<HTMLDivElement | null>;
    insightsButtonRef: React.MutableRefObject<HTMLDivElement | null>;
    prospect: ProspectSearchStrategyResponse | null;
    setProspect: React.Dispatch<
      React.SetStateAction<ProspectSearchStrategyResponse | null>
    >;
    showInsightTab: boolean;
    setShowInsightTab: React.Dispatch<React.SetStateAction<boolean>>;
    rewriteEnabledMode: RewriteEnabledMode | null;
    setRewriteEnabledMode: React.Dispatch<
      React.SetStateAction<RewriteEnabledMode | null>
    >;
    editorInFocus: ReactQuill | null;
    setEditorInFocus: React.Dispatch<React.SetStateAction<ReactQuill | null>>;
    resetInsightsContext: () => void;
    trackTemplateSaveIfNeeded: (text: string) => void;
    setRewriteSubmittedContext: React.Dispatch<
      React.SetStateAction<RewriteSubmittedContext | null>
    >;
    rewriteSequenceInstance: (
      request: RewriteSequenceInstanceInput,
    ) => Promise<void>;
    isRewriteSequenceInstanceInProgress: boolean;
    revertRewrittenSequenceInstance: (
      request: RevertRewrittenSequenceInstanceInput,
    ) => Promise<void>;
    isRevertInProgress: boolean;
  };
}
export type RewriteEnabledMode = 'sequenceInstance' | 'template';

export interface StrategyOverviewContent {
  strategyId: number | null;
  initialTab: StrategyOverviewTab;
  initialFilter?: SequenceFilterStatusType;
  onApplySequenceChanges?: () => void;
}

export enum StrategyOverviewTab {
  Detail = 'Detail',
  Metrics = 'Metrics',
  Personalise = 'Personalise',
}
