import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Subscription } from 'crono-fe-common/types/subscription';
import { SubscriptionStripe } from 'crono-fe-common/types/subscriptionStripe';

export default function useGetSubscription() {
  const requestConfig = {
    queryKey: [ENDPOINTS.subscription.manager],
    queryFn: () => {
      const request: Request = {
        url: `${ENDPOINTS.subscription.manager}`,
        config: {
          method: 'get',
        },
      };
      return client(request);
    },
  };

  return useQuery<Response<SubscriptionStripe>, Error>(requestConfig);
}
