import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { getError } from 'crono-fe-common/utils';
import useGetSequenceInstance from 'hooks/services/sequence/useGetSequenceInstance';
import usePatchTask from 'hooks/services/task/usePatchTask';
import RescheduleCalendar from 'pages/taskBoard/rescheduleCalendar';
import SequenceInstanceContent from 'pages/taskBoard/sequenceInstance/sequenceInstanceContent';
import { SequenceInstanceWrapper } from 'pages/taskBoard/sequenceInstance/style';
import React, { useEffect, useState } from 'react';
import { useSafeStrategyOverviewContext } from '../index';

interface IProps {
  sequenceId: number;
}

const StrategyInstancePersonalization = ({ sequenceId }: IProps) => {
  const {
    insightsContext: { prospect },
  } = useSafeStrategyOverviewContext();

  const { data: sequenceInstance } = useGetSequenceInstance(
    prospect ? prospect.sequenceInstanceId ?? 0 : 0,
    prospect ? prospect.objectId ?? '' : '',
    true,
  );

  const {
    mutate: patchTask,
    isLoading: patchLoading,
    isSuccess: taskPatched,
    error: patchError,
  } = usePatchTask();

  useEffect(() => {
    if (taskPatched) {
      setShowRescheduleTask(null);
    }
  }, [taskPatched]);
  const analytics = useJuneAnalytics();
  const handleChangeTaskDate = (id: number, newDate: Date) => {
    patchTask({
      id: id,
      activityDate: newDate,
    });
    if (analytics) {
      analytics.track('patch-task', {
        type: 'reschedule',
      });
    }
  };

  const [showRescheduleTask, setShowRescheduleTask] = useState<{
    index: number;
    initialDate: Date;
    type: TaskTodoType;
  } | null>(null);

  const handleOpenDateCalendar = (task: TaskTodo) => {
    //handleChangeTaskDate(index);
    setShowRescheduleTask({
      index: task.id,
      initialDate: task.activityDate,
      type: task.type,
    });
  };

  const handleChangeDate = (newDate: Date) => {
    handleChangeTaskDate(showRescheduleTask!.index, newDate);
  };

  useConditionalSnackBar([
    {
      condition: !!patchError,
      message: getError(patchError) ?? 'Error while rescheduling the task',
      severity: 'error',
    },
  ]);

  return (
    <SequenceInstanceWrapper
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {showRescheduleTask && (
        <RescheduleCalendar
          isRescheduleLoading={patchLoading}
          close={() => setShowRescheduleTask(null)}
          handleChangeDate={handleChangeDate}
          initialDate={showRescheduleTask.initialDate}
          taskType={showRescheduleTask.type}
        />
      )}
      {sequenceInstance?.data?.data && (
        <SequenceInstanceContent
          allowToPersonalize={true}
          sequenceInstance={sequenceInstance.data.data}
          handleOpenDateCalendar={(taskTodo: TaskTodo) => {
            handleOpenDateCalendar(taskTodo);
          }}
          onClose={() => {}}
          prospectId={prospect?.objectId}
          sequenceInstanceId={sequenceInstance.data.data.id}
        />
      )}
    </SequenceInstanceWrapper>
  );
};

export default StrategyInstancePersonalization;
