import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { ProspectLinkedin } from 'crono-fe-common/types/prospectLinkedin';

interface IProps {
  name: string;
  publicId?: string;
  leadId?: string;
  companyName?: string;
  companyPublicId?: string;
}

export function createGetProspectLinkedinRequest({
  name,
  publicId,
  leadId,
  companyName,
  companyPublicId,
}: IProps) {
  // get key value object from searchParameters
  const queryString = new URLSearchParams({
    name: name.trim(),
    ...(publicId && { publicId: publicId.trim() }),
    ...(leadId && { leadId: leadId.trim() }),
    ...(companyName && { companyName: companyName?.trim() }),
    ...(companyPublicId && { companyPublicId: companyPublicId?.trim() }),
  }).toString();

  const request: Request = {
    url: ENDPOINTS.prospect.linkedin.main + '?' + queryString,
    config: {
      method: 'get',
    },
  };
  return request;
}

export default function useProspectLinkedin({
  enabled = true,
  name,
  publicId,
  leadId,
  companyName,
  companyPublicId,
}: IProps & {
  enabled?: boolean;
}) {
  const request = createGetProspectLinkedinRequest({
    name: name,
    publicId: publicId,
    leadId: leadId,
    companyName: companyName,
    companyPublicId: companyPublicId,
  });

  const requestConfig = {
    queryKey: [ENDPOINTS.prospect.linkedin.main, { name }],
    queryFn: () => client(request),
    enabled: enabled,
  };

  const { data, ...rest } = useQuery<
    Response<{ prospects: ProspectLinkedin[]; accounts: AccountLinkedin[] }>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
