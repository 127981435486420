import { useState } from 'react';
import { AddOpportunityFormWrapper } from '../../addOpportunity/formComponent/style';
import { CircularProgress, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { StageInput } from 'hooks/services/stage/useCreateStage';

interface IProps {
  close?: () => void;
  handleSubmit: (values: any) => void;
  createLoading?: boolean;
  pipelineId: number;
  existingStages?: string[];
}

const AddStageForm = ({
  close,
  handleSubmit,
  createLoading,
  pipelineId,
  existingStages,
}: IProps) => {
  const [errorState, setErrorState] = useState<string>('');
  const formik = useFormik<StageInput>({
    initialValues: {
      pipelineId: pipelineId,
      publicName: null,
    },
    onSubmit: async (values) => {
      const stageInsert: StageInput = {
        pipelineId: pipelineId,
        publicName: values.publicName,
      };
      if (
        values.publicName &&
        existingStages?.includes(values?.publicName?.toLowerCase().trim())
      ) {
        setErrorState(
          'This name can’t be saved because such stage name already exists',
        );
      } else {
        setErrorState('');
        handleSubmit(stageInsert);
      }
    },
  });

  return (
    <AddOpportunityFormWrapper>
      <form
        className="form-add-container"
        style={{ paddingBottom: '20px', gap: '60px' }}
        onSubmit={formik.handleSubmit}
        onKeyDown={(ev) => {
          if (ev.code === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
          }
        }}
      >
        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Stage name:
          </Typography>
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder="Type stage name..."
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              name="publicName"
              value={formik.values.publicName}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.publicName && Boolean(errorState)}
              helperText={formik.touched.publicName && errorState}
            />
          </div>
        </div>

        <FlexDiv
          width="fit-content"
          height="fit-content"
          style={{ alignSelf: 'flex-end' }}
        >
          <CancelButton
            style={{ width: '120px' }}
            onClick={() => {
              if (close) close();
            }}
          >
            Cancel
          </CancelButton>
          {createLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <MainButton
              type="submit"
              style={{ width: '120px' }}
              disabled={
                formik.values.publicName === null ||
                formik.values.publicName === ''
              }
            >
              Create
            </MainButton>
          )}
        </FlexDiv>
      </form>
    </AddOpportunityFormWrapper>
  );
};

export default AddStageForm;
