import {
  Avatar,
  Badge,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import Page from 'crono-fe-common/components/Layout/Page';
import { useAuth } from 'context/auth';
import useCRMIntegrationGuard from 'hooks/guards/useCRMIntegrationGuard';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { CronoService } from 'services/CronoService';
import { HomeWrapper } from './style';
import Dashboard from 'pages/dashboard';
import Tasks from 'pages/taskBoard';
import { ReactComponent as AnalyticsIcon } from 'crono-fe-common/icons/Report+Analytics.svg';
import Logo from '../../assets/images/logo_crono.png';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import PATH from 'routing/path';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { colors } from 'crono-fe-common/theme';
import OpenSettingsTooltip from './openSettingsTooltip';
import OpenAnalyticsTooltip from './openAnalyticsTooltip';
import DashboardIcon from 'crono-fe-common/icons/Icon-Dashboard';
import IconTask from 'crono-fe-common/icons/Icon-Task';
import TemplatesSettings from 'pages/settings/Template';
import AddTemplateIcon from 'crono-fe-common/icons/Icon-Add-Template';
import IconStrategySettings from 'crono-fe-common/icons/Icon-Strategy-Settings';
import StrategySettings from 'pages/settings/Strategy';
import Settings, { SettingsTabs } from 'pages/settings';
import Analytics, { AnalyticsTabs } from 'pages/analytics';
import Reports from 'pages/reports';
import axios from 'axios';
import { ENDPOINTS } from 'config/endpoints';
import { USER_TOKEN_KEY } from 'config/localStorage';
import DisconnectedBanner from 'pages/dashboard/DisconnectedBanner';
import ExtensionStatusBanner from 'pages/dashboard/ExtensionStatusBanner';
import { fullname, getImageFromUrl, isDev } from 'utils/fe-utils';
import { useJuneAnalytics } from 'context/june';
import { useWindow } from 'context/sideTab';
import StrategyOverviewProvider from 'context/strategyDetail';
import { useCustomerly } from 'react-live-chat-customerly';

import { OnboardingFeatures } from 'context/onboardingFeatures';
import MigrationComponent from 'pages/migration/MigrationComponent';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';

import moment from 'moment';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';

// import usePatchUserOnboardingFeatures from "hooks/services/user/usePatchUserOnboardingFeatures";
import AutomatedTasks from './AutomatedTasksTooltip';
import useGetLinkedinAutomaticTasks from 'hooks/services/task/useGetLinkedinAutomaticTasks';
import IconOpportunity from 'crono-fe-common/icons/Icon-Opportunity';
import Opportunities from 'pages/opportunities';
import { useQueryClient } from 'react-query';
import TaskFilterProvider from 'pages/taskBoard/filters/taskFIlterContext';

import FinishOnboarding from './finishOnboarding';
import CircularDeterminate from 'crono-fe-common/components/CircularProgress';

import AutomationBanner from 'pages/dashboard/AutomationBanner';

import { useGetAutomationsErrorBanner } from 'pages/taskBoard/useGetAutomationsErrorBanner';
import IconErrorIndicatorS from 'crono-fe-common/icons/Icon-Error-Indicator-S';
import notificationInstance from 'services/CronoNotification';
import FrillWidget from './CronoNewsButton';
import SearchComponent from 'pages/searchComponent';
import { Account } from 'crono-fe-common/types/account';
import FindNewTabProvider from './context/findNewContext';
import TemplateType from 'crono-fe-common/types/enums/templateType';

// import usePatchUserOnboardingIntegrations from "hooks/services/user/usePatchUserOnboardingIntegrations";

export const SettingsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
      PopperProps={{
        sx: {
          zIndex: '500 !important',
        },
      }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: 300,
    width: 220,
    position: 'relative',
    top: 0,
    left: 0,
    border: '1px solid #dadde9',
    borderRadius: 16,
    padding: 10,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

export const NotificationsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: `min(80vh, 800px)`,
    overflow: 'auto',
    width: 300,
    position: 'relative',
    top: 0,
    right: 0,
    border: '1px solid #dadde9',
    borderRadius: 16,
    zIndex: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

export enum TabValue {
  FIND_NEW = 0,
  DASHBOARD = 1,
  TEMPLATES = 2,
  STRATEGIES = 3,
  TASKS = 4,
  OPPORTUNITIES = 5,
  ONBOARDING_FEATURES = 6,
}

const Home = () => {
  const {
    user,
    useAuthGuard,
    onboardingState,
    migrationStatus,
    hasOpportunities,
  } = useAuth();
  useAuthGuard();

  const { load, event } = useCustomerly();

  //Load the message service for clients
  useEffect(() => {
    if (!user) return;
    //Clarity setup to pass also the userId
    (function (c: any, l: any, a: any, r: any, i: any, t?: any, y?: any) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
      c.clarity('set', 'userId', '' + user.id);
    })(window, document, 'clarity', 'script', process.env.REACT_APP_CLARITY_ID);

    // show customerly only in prod
    if (isDev()) return;
    load({
      user_id: user.id,
      name: fullname(user.firstName, user.lastName),
      company: {
        company_id: user.company?.id ?? user.company?.name ?? 'No_id_or_name',
        name: user.company?.name ?? undefined,
      },
      attributes: {
        subscriptionPlan:
          user.subscriptionType ?? 'No data for subscription plan',
      },
      email: user.email,
    });
  }, [user]);

  // Tally currently commented to be reintroduced in the future
  // useEffect(() => {
  //   if (!user) return;
  //   //We do not show the for to the free trial users
  //   if (user.subscriptionType === SubscriptionPlanType.FREE_TRIAL) {
  //     return;
  //   }
  //   const formId = 'w8d65r';
  //   window.Tally.openPopup(formId, {
  //     hiddenFields: {
  //       email: user.email,
  //     },
  //     doNotShowAfterSubmit: true,
  //   });
  // }, [user]);

  const { isLoading: crmGuardLoading } = useCRMIntegrationGuard();

  const location = useLocation();

  const initialTab: TabValue = location.state?.initialTab;
  const initialTabFindNew = location.state?.initialTabFindNew;
  const initialCompanyFindNew = location.state?.initialCompanyFindNew;
  const initialSettingsTab: SettingsTabs = location.state?.initialSettingsTab;
  const initialTemplateTab: TemplateType = location.state?.initialTemplateTab;
  const navigate = useNavigate();

  const analytics = useJuneAnalytics();

  const queryClient = useQueryClient();
  useEffect(() => {
    if (user && user?.otherSettings?.hasLinkedin) {
      CronoService.setQueryClient(queryClient);
      CronoService.checkLinkedinExtensionsUpdates(analytics).then();

      const intervalId = setInterval(() => {
        CronoService.checkLinkedinExtensionsUpdates(analytics).then();
      }, 160000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [user, analytics, queryClient]);

  useEffect(() => {
    if (analytics) {
      CronoAutomaticTaskExecutor.setAnalytics(analytics);
    }
  }, [analytics]);

  useEffect(() => {
    if (user) {
      CronoAutomaticTaskExecutor.start(user).then();
    }
    return () => {
      CronoAutomaticTaskExecutor.stop();
    };
  }, [user]);

  useEffect(() => {
    if (user) {
      notificationInstance.start(queryClient);
    }
    return () => {
      notificationInstance.stop();
    };
  }, [user]);

  const [tabValue, setTabValue] = useState<TabValue>(
    initialTab || TabValue.DASHBOARD,
  );
  useEffect(() => {
    if (initialTab !== null && initialTab !== undefined) {
      setTabValue(initialTab);
    }
  }, [initialTab]);
  const { closeWindow, windowContent } = useWindow();

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  //const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleSettingsTooltipClose = () => {
    if (settingsOpen) {
      setSettingsOpen(false);
    }
  };

  const handleSettingsTooltipOpen = () => {
    setSettingsOpen(true);
  };

  const handleAnalyticsTooltipClose = () => {
    if (analyticsOpen) {
      setAnalyticsOpen(false);
    }
  };

  const handleCallBob = async () => {
    const authToken = localStorage.getItem(USER_TOKEN_KEY);
    const result = await axios.get(
      `${process.env.REACT_APP_BE_URL}/${ENDPOINTS.status.bob}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  };

  const handleGoHome = () => {
    navigate(PATH.HOME);
    setTabValue(TabValue.DASHBOARD);
  };

  const [initialValuesFindNew, setInitialValuesFindNew] = useState<
    { initialTab?: 'company' | 'contact'; initialCompany?: Account } | undefined
  >({
    initialTab: initialTabFindNew,
    initialCompany: initialCompanyFindNew,
  });

  useEffect(() => {
    if (
      (initialTabFindNew !== null && initialTabFindNew !== undefined) ||
      (initialCompanyFindNew !== null && initialCompanyFindNew !== undefined)
    ) {
      setInitialValuesFindNew({
        initialTab: initialTabFindNew,
        initialCompany: initialCompanyFindNew,
      });
    }
  }, [initialTabFindNew, initialCompanyFindNew]);

  //CLear when exiting find_new tab
  useEffect(() => {
    if (
      tabValue !== TabValue.FIND_NEW &&
      initialValuesFindNew?.initialCompany
    ) {
      setInitialValuesFindNew(undefined);
    }
  }, [tabValue]);

  const handleOpenFindNew = (
    initialTab?: 'company' | 'contact',
    initialCompany?: Account,
  ) => {
    setInitialValuesFindNew({ initialTab, initialCompany });
    navigate(PATH.HOME, {
      state: {
        initialTab: TabValue.FIND_NEW,
        initialCompanyFindNew: initialCompany,
        initialTabFindNew: initialTab,
      },
    });
    setTabValue(TabValue.FIND_NEW);
  };

  useEffect(() => {
    //If the sideTab is opened and I move to strategies or templates page I close the sideTab
    if (
      windowContent &&
      (tabValue === TabValue.STRATEGIES || tabValue === TabValue.TEMPLATES)
    ) {
      closeWindow();
    }
  }, [tabValue]);

  const [sequenceTabValue, setSequenceTabValue] = useState<AnalyticsTabs>(
    AnalyticsTabs.TaskOverallActivity,
  );

  // Onboarding logic ---------------------------
  const { onboardingFeatures } = OnboardingFeatures();

  //If there are onboardingFeatures redirect to the onboarding page as default the first time he enters Crono
  useLayoutEffect(() => {
    const onboardingAlreadyDoneName = 'crono_onboarding_page';
    const alreadyRedirected = localStorage.getItem(onboardingAlreadyDoneName);
    if (!onboardingState && onboardingFeatures && !alreadyRedirected) {
      //Avoid redirecting him again
      localStorage.setItem(onboardingAlreadyDoneName, '1');
      setTabValue(TabValue.ONBOARDING_FEATURES);
    }
  }, [onboardingFeatures, onboardingState]);

  // const { mutate: patchOnboarding } = usePatchUserOnboardingIntegrations();

  // const { mutate: patchOnboardingFeatures } = usePatchUserOnboardingFeatures();
  // const handleDisconnectIntegrations = () => {
  //   patchOnboardingFeatures({
  //     createStrategy: false,
  //     integrations: false,
  //     createContactOrCompany: false,
  //     createTemplate: false,
  //     executeTask: false,
  //   });
  //   patchOnboarding({
  //     // adminCrmConnected: false,
  //     // crmConnected: false,
  //     emailConnected: false,
  //     linkedinConnected: false,
  //     linkedinConnectedOnboarding: true,
  //     emailConnectedOnboarding: true,
  //     // clearSettings: true
  //   });
  // };

  //To remove the state when reaching the home page, without this after a redirect which sets the initialTab, that state would be saved and
  //each refresh would redirect to the tab set instead of the home page
  //This is actually not working
  useEffect(() => {
    window.history.replaceState({}, '');
  }, []);

  const expirationDate = useMemo(() => {
    const today = moment();
    const expireDate = moment(user?.company?.expirationDate);
    return expireDate.diff(today, 'days');
  }, [user?.company?.expirationDate]);

  const { data: linkedinAutomaticTasks } = useGetLinkedinAutomaticTasks();

  // Task errors badge -----
  const { automationErrorBanner } = useGetAutomationsErrorBanner();

  const taskErrors = useMemo(() => {
    if (automationErrorBanner.errorsCount > 0) {
      return (
        <div className="task-automation-error">
          <IconErrorIndicatorS />
          <Typography fontSize={'12px'} lineHeight={'16px'} fontWeight={500}>
            Some of your tasks received an error
          </Typography>
        </div>
      );
    } else {
      return null;
    }
  }, [automationErrorBanner]);

  // --------------------------------------------
  if (user && !user?.firstIntegration) {
    return <MigrationComponent />;
  }
  if (
    !user ||
    crmGuardLoading ||
    onboardingState === undefined ||
    onboardingFeatures === undefined ||
    migrationStatus === undefined
  ) {
    // todo maybe show something different for loading integration
    return (
      <FlexDiv
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </FlexDiv>
    );
  }
  return (
    <Page style={{ padding: 0 }}>
      <HomeWrapper>
        {/* Used for testing */}

        {/* {isDev() && (
          <button
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1000000000,
              height: 50,
              background: 'green',
            }}
            onClick={handleDisconnectIntegrations}
          >
            RemoveAllIntegrations
          </button>
          // <button
          //   style={{
          //     position: "absolute",
          //     top: 0,
          //     right: 0,
          //     zIndex: 1000000000,
          //     height: 50,
          //     background: "green"
          //   }}
          //   onClick={() => navigate(PATH.COMPLETE)}
          // >
          //   CANDIDATE SUBSCRIPTION
          // </button>
        )} */}
        <Grid
          container
          className="layout-grid"
          alignContent={'flex-start'}
          width={'100%'}
        >
          {/*Top part */}
          <div
            className="top-section"
            style={{
              display: 'flex',
              overflow: 'hidden',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflow: 'auto',
              }}
            >
              <Routes>
                <Route
                  path="*"
                  element={
                    <img
                      src={Logo}
                      alt="Crono"
                      className="logo"
                      onClick={() => handleGoHome()}
                    />
                  }
                />
              </Routes>

              <Routes>
                <Route
                  path={PATH.HOME}
                  element={
                    <>
                      <div className="vertical-divider" />
                      <Tabs
                        value={tabValue}
                        onChange={(_, newValue: TabValue) => {
                          setTabValue(newValue);
                        }}
                        className="tabs-container"
                        variant="scrollable"
                      >
                        <Tooltip
                          title={
                            <Typography fontSize={'12px'} lineHeight={'16px'}>
                              Find new
                            </Typography>
                          }
                          arrow
                          placement="bottom"
                          enterDelay={800}
                          enterNextDelay={800}
                        >
                          <Tab
                            icon={<SearchIcon color={colors.primaryDark} />}
                            label={
                              <span className="tab-button-label">Find new</span>
                            }
                            color="primary"
                            value={TabValue.FIND_NEW}
                            className={
                              tabValue === TabValue.FIND_NEW
                                ? 'tab'
                                : 'tab-not-selected'
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <Typography fontSize={'12px'} lineHeight={'16px'}>
                              Lists
                            </Typography>
                          }
                          arrow
                          placement="bottom"
                          enterDelay={800}
                          enterNextDelay={800}
                        >
                          <Tab
                            icon={<DashboardIcon color={colors.primaryDark} />}
                            label={
                              <span className="tab-button-label">Lists</span>
                            }
                            color="primary"
                            value={TabValue.DASHBOARD}
                            className={
                              tabValue === TabValue.DASHBOARD
                                ? 'tab'
                                : 'tab-not-selected'
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <Typography fontSize={'12px'} lineHeight={'16px'}>
                              Templates
                            </Typography>
                          }
                          arrow
                          placement="bottom"
                          enterDelay={800}
                          enterNextDelay={800}
                        >
                          <Tab
                            icon={
                              <AddTemplateIcon color={colors.primaryDark} />
                            }
                            label={
                              <span className="tab-button-label">
                                Templates
                              </span>
                            }
                            color="primary"
                            value={TabValue.TEMPLATES}
                            className={
                              tabValue === TabValue.TEMPLATES
                                ? 'tab'
                                : 'tab-not-selected'
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <Typography fontSize={'12px'} lineHeight={'16px'}>
                              Strategies
                            </Typography>
                          }
                          arrow
                          placement="bottom"
                          enterDelay={800}
                          enterNextDelay={800}
                        >
                          <Tab
                            icon={
                              <IconStrategySettings
                                color={colors.primaryDark}
                              />
                            }
                            label={
                              <span className="tab-button-label">
                                Strategies
                              </span>
                            }
                            color="primary"
                            value={TabValue.STRATEGIES}
                            className={
                              tabValue === TabValue.STRATEGIES
                                ? 'tab'
                                : 'tab-not-selected'
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <div className="tasks-error-tooltip">
                              <Typography
                                className="task-tooltip-title"
                                fontSize={'12px'}
                                lineHeight={'16px'}
                              >
                                Tasks
                              </Typography>
                              {taskErrors}
                            </div>
                          }
                          arrow
                          placement="bottom"
                          enterDelay={800}
                          enterNextDelay={800}
                        >
                          <Tab
                            icon={
                              <Badge
                                sx={{
                                  '& .MuiBadge-badge': {
                                    right: 0,
                                    top: 6,
                                  },
                                }}
                                badgeContent={<IconErrorIndicatorS />}
                                invisible={!taskErrors}
                              >
                                <IconTask
                                  className="tab-icon"
                                  color={colors.primaryDark}
                                />
                              </Badge>
                            }
                            label={
                              <span className="tab-button-label">Tasks</span>
                            }
                            color="primary"
                            value={TabValue.TASKS}
                            className={
                              tabValue === TabValue.TASKS
                                ? 'tab'
                                : 'tab-not-selected'
                            }
                          />
                        </Tooltip>
                        {hasOpportunities && (
                          <Tooltip
                            title={
                              <Typography fontSize={'12px'} lineHeight={'16px'}>
                                Deals
                              </Typography>
                            }
                            arrow
                            placement="bottom"
                            enterDelay={800}
                            enterNextDelay={800}
                          >
                            <Tab
                              icon={
                                <IconOpportunity color={colors.primaryDark} />
                              }
                              label={
                                <span className="tab-button-label">Deals</span>
                              }
                              color="primary"
                              value={TabValue.OPPORTUNITIES}
                              className={
                                tabValue === TabValue.OPPORTUNITIES
                                  ? 'tab'
                                  : 'tab-not-selected'
                              }
                            />
                          </Tooltip>
                        )}
                      </Tabs>
                    </>
                  }
                />
              </Routes>
            </div>

            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {/* <Button onClick={handleCallBob}>Bob</Button> */}
              <AutomatedTasks
                linkedinAutomaticTasks={linkedinAutomaticTasks?.data?.data}
              />
              {/* {user && onboardingFeatures && (
                <DeprecatedFinishOnboarding
                  onboardingFeatures={onboardingFeatures}
                  setTabValue={setTabValue}
                  handleFindNew={handleFindNew}
                  user={user}
                  shrink={!!linkedinAutomaticTasks?.data?.data?.inPipeline}
                />
              )} */}
              {/* <MainButton
                startIcon={<SearchIcon color="white" />}
                size="large"
                onClick={handleFindNew}
              >
                <Typography fontSize={15}>Find new</Typography>
              </MainButton> */}

              <ClickAwayListener onClickAway={handleAnalyticsTooltipClose}>
                <div>
                  <SettingsTooltip
                    title={
                      <OpenAnalyticsTooltip
                        onClick={() => handleAnalyticsTooltipClose()}
                      />
                    }
                    onClose={handleAnalyticsTooltipClose}
                    open={analyticsOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton
                      className="button"
                      size="large"
                      onClick={() => setAnalyticsOpen((prev) => !prev)}
                    >
                      <AnalyticsIcon />
                    </IconButton>
                  </SettingsTooltip>
                </div>
              </ClickAwayListener>
              <FrillWidget />
              {/* <ClickAwayListener onClickAway={handleNotificationTooltipClose}>
                <div>
                  <NotificationsTooltip
                    title={
                      <NotificationBoxTooltip
                        close={() => handleNotificationTooltipClose()}
                        showClose={true}
                      />
                    }
                    onClose={handleNotificationTooltipClose}
                    open={notificationsOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="bottom-end"
                  >
                    <IconButton
                      className="button"
                      size="large"
                      onClick={handleNotificationTooltipOpen}
                    ><Badge
                        variant="dot"
                        anchorOrigin={{ horizontal: "right", vertical: "top" }}
                        invisible={!newNotifications}
                        color="primary"
                        overlap="circular"
                        style={{ height: "fit-content" }}
                      >
                        <NotificationIcon className="icon" />
                      </Badge>
                    </IconButton>
                  </NotificationsTooltip>
                </div>
              </ClickAwayListener> */}
              <ClickAwayListener onClickAway={handleSettingsTooltipClose}>
                <div>
                  <SettingsTooltip
                    title={
                      <OpenSettingsTooltip
                        onboardingFeatures={onboardingFeatures}
                        openOnboardingView={() => {
                          setSettingsOpen(false);
                          navigate(PATH.HOME);
                          setTabValue(TabValue.ONBOARDING_FEATURES);
                        }}
                      />
                    }
                    onClose={handleSettingsTooltipClose}
                    open={settingsOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div
                      className="name-icon"
                      onClick={handleSettingsTooltipOpen}
                    >
                      <Typography
                        variant="body1"
                        alignSelf="center"
                        className="name"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        maxWidth={'150px'}
                      >
                        <span style={{ fontSize: '14px', fontWeight: 500 }}>
                          {user.firstName} {user.lastName}
                        </span>
                        <div className="company-name-container">
                          <span className="company-name">
                            {user.company?.name}
                          </span>
                          {user?.subscriptionType ===
                          SubscriptionPlanType.FREE_TRIAL ? (
                            <span className="trial-badge">
                              Trial: {expirationDate}d left
                            </span>
                          ) : null}
                        </div>
                      </Typography>
                      {/* <img src="getImageFromUrl(user.company.website)"/> TODO put company image as logo, and everywhere for companies :) */}

                      <div className="onboarding-features-button-circular">
                        <Avatar
                          src={
                            (user.company?.website &&
                              getImageFromUrl(user.company.website, null)) ||
                            ''
                          }
                          className="profile-icon"
                        />
                        {onboardingFeatures && (
                          <div style={{ position: 'absolute' }}>
                            <CircularDeterminate
                              value={onboardingFeatures?.percentage ?? 0}
                            />
                          </div>
                        )}
                      </div>
                      {/* <IconButton
                        className="profile-icon"
                        size="large"
                        style={{ padding: 0 }}
                      >
                        <AccountCircleRounded
                          className="button"
                          style={{ color: colors.workingLightHover }}
                        />
                      </IconButton> */}
                    </div>
                  </SettingsTooltip>
                </div>
              </ClickAwayListener>
            </div>
          </div>
          {/*Context used to preserve the state of the results in the find new tab even when the user changes page */}
          <FindNewTabProvider>
            <Routes>
              <Route
                path={PATH.HOME}
                element={
                  <Grid item container xs={12} className="table">
                    <>
                      {tabValue !== TabValue.ONBOARDING_FEATURES && (
                        <>
                          <DisconnectedBanner />
                          <ExtensionStatusBanner />
                          <AutomationBanner
                            linkedinAutomaticTasks={
                              linkedinAutomaticTasks?.data?.data
                            }
                          />
                        </>
                      )}
                      {tabValue === TabValue.DASHBOARD ? (
                        <Dashboard />
                      ) : tabValue === TabValue.TASKS ? (
                        <TaskFilterProvider>
                          <Tasks setTabValue={setTabValue} />
                        </TaskFilterProvider>
                      ) : tabValue === TabValue.STRATEGIES ? (
                        <StrategyOverviewProvider setTabValue={setTabValue}>
                          <StrategySettings setTabValue={setTabValue} />
                        </StrategyOverviewProvider>
                      ) : tabValue === TabValue.TEMPLATES ? (
                        <TemplatesSettings initialTab={initialTemplateTab} />
                      ) : tabValue === TabValue.OPPORTUNITIES &&
                        hasOpportunities ? (
                        <Opportunities />
                      ) : tabValue === TabValue.ONBOARDING_FEATURES ? (
                        <FinishOnboarding
                          onboardingFeatures={onboardingFeatures}
                          user={user}
                          setTabValue={setTabValue}
                          handleFindNew={() => {
                            event('find_new');
                            handleOpenFindNew();
                          }}
                          shrink={
                            !!linkedinAutomaticTasks?.data?.data?.inPipeline
                          }
                        />
                      ) : tabValue === TabValue.FIND_NEW ? (
                        <SearchComponent
                          initialTab={initialValuesFindNew?.initialTab}
                          initialCompany={initialValuesFindNew?.initialCompany}
                        />
                      ) : null}
                    </>
                  </Grid>
                }
              />
              <Route
                path={PATH.SETTINGS}
                element={
                  <div className="table">
                    <Settings initialTab={initialSettingsTab} />
                  </div>
                }
              />
              <Route
                path={PATH.ANALYTICS}
                element={
                  <div className="table">
                    <Analytics tabValue={sequenceTabValue} />
                  </div>
                }
              />{' '}
              <Route
                path={PATH.REPORTS}
                element={
                  <div className="table">
                    <Reports />
                  </div>
                }
              />
              <Route path={'*'} element={<Navigate to={PATH.HOME} />} />
            </Routes>
          </FindNewTabProvider>
        </Grid>
      </HomeWrapper>
    </Page>
  );
};

export default Home;
