import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const ReplyThreadIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        id="Path"
        d="M5.99927 1.43576H17.0039"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M17.0035 6.99826H7.99976"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M10.0007 12.5627H17.0036"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M1.49703 0.935547C1.22078 0.935547 0.996826 1.1595 0.996826 1.43576C0.996826 1.71201 1.22078 1.93596 1.49703 1.93596C1.77329 1.93596 1.99724 1.71201 1.99724 1.43576C1.99724 1.1595 1.77329 0.935547 1.49703 0.935547"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M3.49801 6.49805C3.22175 6.49805 2.9978 6.722 2.9978 6.99826C2.9978 7.27451 3.22175 7.49846 3.49801 7.49846C3.77427 7.49846 3.99822 7.27451 3.99822 6.99826C3.99822 6.722 3.77427 6.49805 3.49801 6.49805"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_6"
        d="M5.49899 12.0625C5.22273 12.0625 4.99878 12.2865 4.99878 12.5627C4.99878 12.839 5.22273 13.0629 5.49899 13.0629C5.77525 13.0629 5.9992 12.839 5.9992 12.5627C5.9992 12.2865 5.77525 12.0625 5.49899 12.0625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReplyThreadIcon;
