import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const UpgradeDeniedModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  border-radius: 16px;
  width: 468px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  gap: 12px;

  .close-button {
    margin: 16px 0 0 0;
    width: 120px;
  }
`;
