import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconMLead = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
      onClick={onClick}
    >
      <path
        d="M14.2678 10.9822C15.2441 11.9585 15.2441 13.5415 14.2678 14.5178C13.2915 15.4941 11.7085 15.4941 10.7322 14.5178C9.75592 13.5415 9.75592 11.9585 10.7322 10.9822C11.7085 10.0059 13.2915 10.0059 14.2678 10.9822"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7538 4.98223C20.7302 5.95854 20.7302 7.54145 19.7538 8.51776C18.7775 9.49407 17.1946 9.49407 16.2183 8.51776C15.242 7.54145 15.242 5.95854 16.2183 4.98223C17.1946 4.00592 18.7775 4.00592 19.7538 4.98223"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 12.25C20.033 12.25 21.5 12.783 22.5 13.583"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.78168 4.98223C9.75799 5.95854 9.75799 7.54145 8.78168 8.51776C7.80537 9.49407 6.22246 9.49407 5.24615 8.51776C4.26984 7.54145 4.26984 5.95854 5.24615 4.98223C6.22246 4.00592 7.80537 4.00592 8.78168 4.98223"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 13.583C3.5 12.783 4.967 12.25 6.5 12.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0909 19.75C15.9259 18.849 14.2519 18.25 12.4999 18.25C10.7479 18.25 9.07394 18.849 7.90894 19.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMLead;
