import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC, useEffect, useMemo } from 'react';

import { Prospect } from 'crono-fe-common/types/prospect';

import { CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import { useAuth } from 'context/auth';
import { getFullName } from 'crono-fe-common/types/user';
import { transformToValidUrl, prospectName } from 'utils/fe-utils';
import { useLinkedinGetSalesNavConversation } from 'crono-fe-common/hooks/crono-extension/gateway';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import { LinkedinSalesNavMessage } from 'crono-fe-common/types/crono-extension/linkedin';
import { InMailModalWrapper } from './style';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import { colors } from 'crono-fe-common/theme';

interface InMailModalProps {
  prospect: Prospect | null;
}

const InMailModal: FC<InMailModalProps> = ({ prospect }) => {
  const { user } = useAuth();

  const {
    call: getSalesNavConversation,
    result: salesNavConversationResult,
    error: salesNavConversationError,
    isLoading: isSalesNavConversationLoading,
  } = useLinkedinGetSalesNavConversation();

  useEffect(() => {
    if (prospect) {
      const publicId = LinkedinUrlUtils.getIdFromLinkedinProfileUrl(
        prospect.linkedin,
      );

      if (publicId) {
        getSalesNavConversation({
          publicId,
        }).then();
      }
    }
  }, []);

  const conversation = useMemo(() => {
    if (!salesNavConversationResult) return [];

    return salesNavConversationResult.sort(
      (a, b) =>
        +new Date(a.timestamp).getTime() - +new Date(b.timestamp).getTime(),
    );
  }, [salesNavConversationResult]);

  const handleOpenLinkedin = (linkedin: string) => {
    if (transformToValidUrl(linkedin) != null) {
      window.open(transformToValidUrl(linkedin)!, '_blank');
    }
  };

  const modalLinkedin = (prospect: Prospect | null) => {
    if (
      prospect &&
      prospect.linkedin &&
      transformToValidUrl(prospect.linkedin) != null
    ) {
      return (
        <div
          className="linkedin-icon"
          onClick={() => handleOpenLinkedin(prospect.linkedin!)}
        >
          <LinkedinIcon color={colors.blue} />
        </div>
      );
    }
    return null;
  };

  const renderMessage = (
    linkedinMessage: LinkedinSalesNavMessage,
    isReply = false,
  ) => (
    <div className={`message-container ${isReply ? 'reply' : ''}`}>
      <div className="message-header">
        <p className="author">{linkedinMessage.user}</p>
        <p className="date">
          {moment(linkedinMessage.timestamp).format('LLL')}
        </p>
      </div>

      {linkedinMessage.message.split('\n').map((line: string, idx) => (
        <p key={idx}>{line}</p>
      ))}
    </div>
  );

  return (
    <InMailModalWrapper>
      <div className="participants">
        <div className="receiver">
          <p>
            <span className="label">To:</span> {prospectName(prospect)}
          </p>
          {modalLinkedin(prospect)}
        </div>

        <p className="sender">
          <span className="label">From:</span> {user ? getFullName(user) : ''}
        </p>
      </div>

      {!isSalesNavConversationLoading && (
        <p className="subject">
          <span className="label">Subject: </span>{' '}
          {conversation[0]?.subject ?? 'No subject'}
        </p>
      )}

      <div className="conversation">
        {isSalesNavConversationLoading && (
          <FlexDiv justifyContent="center" style={{ marginTop: '50px' }}>
            <CircularProgress />
          </FlexDiv>
        )}

        {salesNavConversationError && (
          <FlexDiv justifyContent="center" style={{ marginTop: '50px' }}>
            <Typography fontSize={'16px'} fontWeight={600} lineHeight={'24px'}>
              Sorry, this conversation could not be retrieved
            </Typography>
          </FlexDiv>
        )}

        {!isSalesNavConversationLoading &&
          conversation.map((message: LinkedinSalesNavMessage, idx) =>
            renderMessage(message, idx !== 0),
          )}
      </div>
    </InMailModalWrapper>
  );
};

export default InMailModal;
