import { gatewayService } from 'crono-fe-common/hooks/crono-extension/gateway';
import {
  AsyncJobImportCompany,
  AsyncJobImportProspect,
  CronoGatewayBackgroundGetAsyncQueueState,
} from 'crono-fe-common/types/crono-extension/background-script';
import { useQuery } from 'react-query';

interface IGetAsyncQueueState {
  isImportingCompanies: boolean;
  isImportingContacts: boolean;
  companiesToImport: Omit<AsyncJobImportCompany, 'state'>[];
  contactsToImport: Omit<AsyncJobImportProspect, 'state'>[];
  importedCompanies: Omit<AsyncJobImportCompany, 'state'>[];
  importedContacts: Omit<AsyncJobImportProspect, 'state'>[];
  companiesToImportCount: number;
  contactsToImportCount: number;
  importedCompaniesCount: number;
  importedContactsCount: number;
}

export function useGetAsyncQueueState() {
  return useQuery<IGetAsyncQueueState>({
    queryKey: 'useGetAsyncQueueState',
    queryFn: async (): Promise<IGetAsyncQueueState> => {
      const queueState =
        await gatewayService.execute<CronoGatewayBackgroundGetAsyncQueueState>({
          target: 'background-script',
          methodName: 'getAsyncQueueState',
          params: {},
        });
      // ------- Companies data -------
      const filterByCompanies = 'importCompany';

      const isImportingCompanies = Boolean(
        queueState?.runningJobs.filter((j) => j.type === filterByCompanies)
          .length ||
          queueState?.queuedJobs.filter((j) => j.type === filterByCompanies)
            .length,
      );

      const companiesToImport = [
        ...queueState.queuedJobs.filter((j) => j.type === filterByCompanies),
        ...queueState.runningJobs.filter((j) => j.type === filterByCompanies),
      ] as Omit<AsyncJobImportCompany, 'state'>[];

      const importedCompanies = queueState.completedJobs.filter(
        (j) => j.type === filterByCompanies,
      ) as Omit<AsyncJobImportCompany, 'state'>[];

      const companiesToImportCount = companiesToImport.length;

      const importedCompaniesCount = importedCompanies.length;

      // ------- Contacts data -------
      const filterByContacts = 'importProspect';

      const isImportingContacts = Boolean(
        queueState?.runningJobs.filter((j) => j.type === filterByContacts)
          .length ||
          queueState?.queuedJobs.filter((j) => j.type === filterByContacts)
            .length,
      );

      const contactsToImport = [
        ...queueState.queuedJobs.filter((j) => j.type === filterByContacts),
        ...queueState.runningJobs.filter((j) => j.type === filterByContacts),
      ] as Omit<AsyncJobImportProspect, 'state'>[];

      const importedContacts = queueState.completedJobs.filter(
        (j) => j.type === filterByContacts,
      ) as Omit<AsyncJobImportProspect, 'state'>[];

      const contactsToImportCount = contactsToImport.length;

      const importedContactsCount = importedContacts.length;

      return {
        isImportingCompanies,
        isImportingContacts,
        companiesToImport,
        contactsToImport,
        importedCompanies,
        importedContacts,
        companiesToImportCount,
        contactsToImportCount,
        importedCompaniesCount,
        importedContactsCount,
      };
    },
    refetchInterval: 2500,
    initialData: {
      isImportingCompanies: false,
      isImportingContacts: false,
      companiesToImport: [],
      contactsToImport: [],
      importedCompanies: [],
      importedContacts: [],
      companiesToImportCount: 0,
      contactsToImportCount: 0,
      importedCompaniesCount: 0,
      importedContactsCount: 0,
    },
  });
}
