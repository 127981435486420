import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React from 'react';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import {
  Avatar,
  CircularProgress,
  Divider,
  Tooltip,
  Typography,
} from '@mui/material';
import { getImageFromUrl } from 'utils/fe-utils';
import { colors } from 'crono-fe-common/theme';
import {
  MainPrimaryButton,
  RedButton,
} from 'crono-fe-common/components/CronoButton';
import LinkChainIconS from 'crono-fe-common/icons/Icon-Link-Chain-S';
import { useConfirmModal } from 'context/confirmModal';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { SimilarContactsModalWrapper } from 'pages/searchComponent/searchContactTab/contactInsertCard/style';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';

interface IProps {
  close: () => void;
  similarAccounts: AccountLinkedin[];
  handleClickLink: (account: AccountLinkedin) => void;
  removeSimilarCompany: (index: number) => void;
  isLoading?: boolean;
}

const SimilarCompanyModal = ({
  close,
  similarAccounts,
  handleClickLink,
  removeSimilarCompany,
  isLoading = false,
}: IProps) => {
  const { openModal } = useConfirmModal();

  const handleClick = (account: AccountLinkedin) => {
    openModal({
      title: 'Are you sure you want to link this company to the one in Crono?',
      text: 'The company will be linked with the existing one',
      cancelFunction: () => {},
      confirmFunction: () => {
        handleClickLink(account);
      },
      cancelText: 'Cancel',
      confirmText: 'Link',
    });
  };

  return (
    <BlurBackgroundDivFixed>
      <SimilarContactsModalWrapper>
        <CloseTabButton close={close} />
        <div className="similar-contacts-body">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Similar companies to:
          </Typography>
          {isLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <div className="similar-contacts-list">
              {similarAccounts.map((account, index) => (
                <>
                  <div className="similar-contact-card" key={account.objectId}>
                    <Avatar
                      src={getImageFromUrl(account.website ?? null, null)}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          overflow: 'hidden',
                          gap: 6,
                        }}
                      >
                        <Tooltip
                          title={account.name}
                          enterDelay={800}
                          enterNextDelay={800}
                          arrow
                        >
                          <Typography
                            fontSize={14}
                            lineHeight={'18px'}
                            fontWeight={500}
                            noWrap
                          >
                            {account.name}
                          </Typography>
                        </Tooltip>
                      </div>
                    </div>
                    <MainPrimaryButton
                      startIcon={<LinkChainIconS color={colors.white} />}
                      style={{
                        height: 40,
                        margin: 0,
                        fontWeight: 500,
                        fontSize: 14,
                      }}
                      onClick={() => {
                        handleClick(account);
                      }}
                    >
                      Link
                    </MainPrimaryButton>
                    <RedButton
                      startIcon={<CloseMIcon color={colors.white} />}
                      onClick={() => removeSimilarCompany(index)}
                    >
                      Discard
                    </RedButton>
                  </div>
                  <Divider />
                </>
              ))}
            </div>
          )}
        </div>
      </SimilarContactsModalWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default SimilarCompanyModal;
