import { TableCell, Tooltip, Typography } from '@mui/material';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import { useLinkedinGetCompanyInfo } from 'crono-fe-common/hooks/crono-extension/gateway';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { extractDomainFromWebsite } from 'utils/fe-utils';
import styled from '@emotion/styled';
import useFindPhone from 'hooks/services/scrape/useFindPhone';
import IconCopy from 'crono-fe-common/icons/Icon-Copy';
import useGetUserCredits from 'hooks/services/user/useGetUserCredits';
import { Prospect } from 'crono-fe-common/types/prospect';
import { Account } from 'crono-fe-common/types/account';
import useEditAccount from 'hooks/services/account/useEditAccount';
import { AccountInfoInputs } from 'crono-fe-common/types/DTO/accountInfoInputs';
import { getError } from 'crono-fe-common/utils';
import useScrapeWebsite from 'hooks/services/scrape/useScrapeWebsite';
import { LinkedinCompanyCompleteInfo } from 'crono-fe-common/types/crono-extension/linkedin';
import useEditProspect from 'hooks/services/prospect/useEditProspect';

interface IProps {
  prospect: Prospect;
  account?: Account | null;
  disabled?: boolean;
  enrichingPhoneInProgress: boolean;
  alreadySearching: boolean;
  setAlreadySearching: React.Dispatch<React.SetStateAction<boolean>>;
}

const FindPhoneProspectRow = ({
  prospect,
  account,
  disabled,
  enrichingPhoneInProgress,
  alreadySearching,
  setAlreadySearching,
}: IProps) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [scrapeFailed, setScrapeFailed] = useState<boolean>(false);

  //==============================================================================
  const analytics = useJuneAnalytics();
  const [searchPhoneError, setSearchPhoneError] = useState<string | null>(null);
  const [searchPhoneHint, setSearchPhoneHint] = useState<string | null>(null);
  const [nProvider, setNProvider] = useState<number>(0);

  const prospectLinkedinUrl = useMemo(() => {
    return prospect?.linkedin ?? null;
  }, [prospect]);

  const {
    isLoading: isProspectScrapeLoading,
    data: prospectScrapeData,
    error: prospectScrapeError,
    mutateAsync: findPhone,
  } = useFindPhone();

  const { data: userCredits } = useGetUserCredits();

  useEffect(() => {
    if (prospectScrapeData) {
      if (prospectScrapeData.data?.data) {
        setIsSearching(false);
      } else {
        checkIfCompanyPhoneIsPresent();
      }
    }
  }, [prospectScrapeData]);

  const { result: linkedinCompanyInfo, call: getLinkedinCompanyInfo } =
    useLinkedinGetCompanyInfo();

  const { mutate: updateProspect } = useEditProspect();

  const { mutate: updateAccount, error: errorUpdateAccount } = useEditAccount();

  const { mutateAsync: scrapeWebsiteAsync, isLoading: isLoadingScrapeWebsite } =
    useScrapeWebsite();

  useEffect(() => {
    const handle = async () => {
      if (prospectScrapeError) {
        const foundByCompany = await checkIfCompanyPhoneIsPresent();
        if (foundByCompany) return;
        setScrapeFailed(true);
        setIsSearching(false);
        if (prospectScrapeError.response?.status === 409) {
          setSearchPhoneError(
            `The found phone number ${prospectScrapeData?.data?.data.phoneNumber ?? ''} is already in use in the CRM`,
          );
        }
        if (prospectScrapeError?.response?.status === 404) {
          setSearchPhoneError('Phone not found');
        } else if (prospectScrapeError?.response?.status === 403) {
          setSearchPhoneError('Scrape not enabled or credit exhausted');
        } else {
          setSearchPhoneError('Error while finding phone');
        }
      }
    };
    handle();
  }, [prospectScrapeError, isProspectScrapeLoading]);

  const checkIfCompanyPhoneIsPresent = async () => {
    let companyInfo = linkedinCompanyInfo;
    if (!companyInfo) {
      const accountLinkedin = account?.linkedin ?? null;
      companyInfo = await getLinkedinCompanyInfo({
        url: accountLinkedin,
        numericId: account?.linkedinNumericId,
      });
    }
    //If we found a phone in the company we use thatone to fill the field and patch the prospect if it is an already in Crono prospect
    if (companyInfo?.company?.phone) {
      updateProspect({
        prospectId: prospect.objectId,
        phone: companyInfo.company.phone,
      });
      return true;
    }
    return false;
  };

  useConditionalSnackBar([
    {
      condition: !!searchPhoneError,
      message: searchPhoneError || '',
      severity: 'error',
    },
    {
      condition: !!searchPhoneHint,
      message: searchPhoneHint || '',
      severity: 'info',
    },
    {
      condition: !!errorUpdateAccount,
      message: getError(errorUpdateAccount) ?? 'Error update company',
      severity: 'error',
    },
  ]);

  const handleFindPhone = async () => {
    const accountLink = account?.website ?? null;
    const accountLinkedin = account?.linkedin ?? null;
    //I have no information to find the phone
    if (!accountLink && !accountLinkedin && !prospectLinkedinUrl) {
      setSearchPhoneHint('Add website to company info to find phone');
      return;
    }
    setIsSearching(true);
    //Reset errors for the new research
    setSearchPhoneError(null);
    setSearchPhoneHint(null);
    const website = await scrapeWebsiteAsync({
      website: accountLink ?? '',
    });
    if (website.data?.data?.redirectUrl) {
      const domain = extractDomainFromWebsite(website.data.data.redirectUrl);
      validateInputAndFindPhone(domain, prospectLinkedinUrl);
      if (website.data.data.redirectUrl !== accountLink && account) {
        updateAccount({
          accountId: account.objectId,
          website: website.data.data.redirectUrl,
        });
      }
    }
    if (accountLinkedin) {
      const companyInfo = await getLinkedinCompanyInfo({
        url: account?.linkedin,
        numericId: account?.linkedinNumericId,
      });
      searchWithLinkedinCompanyInfo(companyInfo?.company);
      return;
    }
    if (prospectLinkedinUrl) {
      validateInputAndFindPhone(null, prospectLinkedinUrl);
      return;
    }
  };

  const searchWithLinkedinCompanyInfo = async (
    company: LinkedinCompanyCompleteInfo | null | undefined,
  ) => {
    if (company && account) {
      if (company.website) {
        const website = await scrapeWebsiteAsync({
          website: company.website ?? '',
        });
        if (website.data?.data?.redirectUrl) {
          const domain = extractDomainFromWebsite(
            website.data.data.redirectUrl,
          );
          validateInputAndFindPhone(domain, prospectLinkedinUrl);
        }
        const patchAccount: AccountInfoInputs = {
          accountId: account.objectId,
        };

        patchAccount.website = website.data?.data?.redirectUrl;

        if (!account.numberOfEmployees && company.numberOfEmployees) {
          patchAccount.nOfEmployees = company.numberOfEmployees;
        }

        if (!account.industry && company.industry) {
          patchAccount.industry = company.industry;
        }

        if (!account.country && company.country) {
          patchAccount.country = company.country;
        }

        updateAccount(patchAccount);
      } else {
        const res = await checkIfCompanyPhoneIsPresent();
        if (!res) {
          setScrapeFailed(true);
          setIsSearching(false);
          setSearchPhoneHint('Add website to company info to find phone');
          setTimeout(() => {
            setSearchPhoneHint(null);
          }, 2000);
        }
      }
    }
  };

  const validateInputAndFindPhone = (
    domain: string | null,
    linkedinUrl: string | null = null,
  ) => {
    const prospectFirstName = prospect?.firstName ?? undefined;
    const prospectLastName = prospect?.lastName ?? undefined;
    //The linkedinUrl is enough to enable the search, otherwise I need domain, firstname and lastName
    if (
      !linkedinUrl &&
      (!domain ||
        domain.length === 0 ||
        !prospectFirstName ||
        !prospectLastName)
    ) {
      setIsSearching(false);
      setScrapeFailed(true);
      setSearchPhoneError('Fill company domain or linkedin url');
      return;
    }
    findPhone({
      firstName: prospectFirstName!,
      lastName: prospectLastName!,
      domain: domain!,
      linkedinUrl: linkedinUrl,
      prospectId: prospect.objectId,
    });
    if (analytics) {
      analytics.track('search-phone', {});
    }
    return;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isProspectScrapeLoading) {
      timer = setInterval(() => {
        setNProvider((prev) => {
          if (prev >= 10) return 10;
          return prev + 1;
        });
      }, 2500);
    } else {
      setNProvider(1);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isProspectScrapeLoading]);

  const areThere0Credits = useMemo(() => {
    return userCredits?.data?.data.phoneLeft === 0;
  }, [userCredits]);
  //==============================================================================

  //Update the other rows that one is already looking for the email
  useEffect(() => {
    setAlreadySearching(isSearching);
  }, [isSearching]);

  return (
    <TableCell
      style={{
        width: 150,
        minWidth: 150,
        paddingBlock: 12,
        borderRight: `1px solid ${colors.grey444}`,
      }}
    >
      <span
        style={{
          width: 150,
          minWidth: 150,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          minHeight: 24,
        }}
      >
        {prospect.phone ? (
          <>
            <Typography
              fontSize={14}
              lineHeight={'24px'}
              color={colors.grey11}
              noWrap
            >
              {prospect.phone}
            </Typography>
            <IconCopy
              onClick={() => {
                navigator.clipboard.writeText(prospect.phone || '');
              }}
              className="icon-copy"
            />
          </>
        ) : enrichingPhoneInProgress ? (
          <span
            className="loader"
            style={{ fontSize: 3, marginLeft: 12 }}
          ></span>
        ) : isSearching || isLoadingScrapeWebsite ? (
          <>
            <Typography
              fontSize={'14px'}
              fontWeight={500}
              color={colors.intenseBlue}
            >
              {nProvider} provider...
            </Typography>
            <span
              className="loader"
              style={{ fontSize: 3, marginLeft: 12 }}
            ></span>
          </>
        ) : scrapeFailed || prospect.lastPhoneNotFoundDate ? (
          <>
            <Typography
              fontSize={14}
              lineHeight={'24px'}
              color={colors.inactive}
              marginRight={'4px'}
            >
              Phone not found
            </Typography>
            <SearchIcon
              color={colors.inactive}
              style={{ cursor: 'pointer' }}
              onClick={() => handleFindPhone()}
            />
          </>
        ) : (
          <Tooltip
            arrow
            enterDelay={400}
            enterNextDelay={400}
            title={
              areThere0Credits ? 'You have 0 find phone credits left' : null
            }
          >
            <FindPhoneContactSearchWrapper
              onClick={() => {
                // if (disabled) return;
                if (alreadySearching || disabled || areThere0Credits) return;
                handleFindPhone();
              }}
              className="find-button"
              style={{
                cursor:
                  alreadySearching || disabled || areThere0Credits
                    ? 'default'
                    : 'pointer',
                opacity: alreadySearching ? 0.5 : 1,
              }}
            >
              <Typography
                fontSize={'14px'}
                lineHeight={'24px'}
                color={areThere0Credits ? colors.grey11 : colors.primaryDark}
              >
                Find phone
              </Typography>
              <SearchIcon
                color={areThere0Credits ? colors.grey11 : colors.primaryDark}
              />
            </FindPhoneContactSearchWrapper>
          </Tooltip>
        )}
      </span>
    </TableCell>
  );
};

export default FindPhoneProspectRow;

const FindPhoneContactSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;
