import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconRegenerate = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      {...style}
    >
      <path
        d="M18.5173 7.37156C17.0676 5.33333 14.6916 4 12 4C7.58133 4 4 7.58133 4 12C4 12.4791 4.04978 12.9458 4.13067 13.4009"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.26562 18.7502V16.6285H7.38918"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.48242 16.6283C6.9322 18.6665 9.3082 19.9998 11.9998 19.9998C16.4184 19.9998 19.9998 16.4185 19.9998 11.9998C19.9998 11.5207 19.95 11.0541 19.8691 10.599"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7339 5.24957V7.37134H16.6104"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconRegenerate;
