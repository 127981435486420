import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SearchLinkedinWrapper = styled.div`
  width: 800px;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
  display: flex;
  overflow: hidden;
  .loading {
    display: flex;
    min-height: 200px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px !important;
    margin: 0px !important;
    gap: 10px;
    flex: 1;
    overflow: hidden auto;
    width: 100%;
  }

  .insert-prospect-container {
    margin-top: -20px;
    padding-top: -20px;
  }
  .modal-button {
    margin: 0px;
  }
`;

export const ProfileWrapper = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${colors.grey4};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  .icon-container {
    width: 20px;
    padding-top: 0.5rem;
  }

  .lead-title {
    color: ${colors.nurtureBlue};
    font-weight: 600;
  }

  .lead-title-container {
  }

  h1,
  h2 {
    white-space: nowrap;
  }

  .lead-name {
    font-size: 1.1rem;
    color: ${colors.black};
    // font-weight: 600;
    // margin-left: 0.5rem;
    // margin-top: 1.5rem;
  }

  .lead-target {
    font-size: 1rem;
    color: ${colors.black};
    // font-weight: 500;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // width: 100%;
  }

  .lead-target > span {
    margin: 0 0.5rem;
  }

  .add-profile-button {
    width: 100px !important;
    height: 40px;
  }
  .go-to-linkedin-icon {
    background: ${colors.blueLight};
    width: 40px;
    height: 40px;
  }
  .linkedin-icon {
    width: 30px;
    height: 30px;
  }

  .action-close-icon {
    position: absolute;
    top: 5px;
    right: 4px;
  }

  .divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
  }
`;

export const LeadWrapper = styled.div`
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  .icon-container {
    width: 20px;
    padding-top: 0.5rem;
  }

  .lead-title {
    color: ${colors.nurtureBlue};
    font-weight: 600;
  }

  h1,
  h2 {
    white-space: nowrap;
  }

  .lead-container-top {
    position: relative;
    padding-left: 1rem;
  }

  .lead-name {
    font-size: 1.1rem;
    color: ${colors.black};
    font-weight: 600;
    margin-left: 0.5rem;
    margin-top: 1.5rem;
  }

  .lead-target {
    font-size: 1rem;
    color: ${colors.black};
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .lead-target > span {
    margin: 0 0.5rem;
  }

  .lead-button {
    margin-right: 0.9rem;
    min-width: 70px;
    max-width: 100px;
  }

  .action-close-icon {
    position: absolute;
    top: 5px;
    right: 4px;
  }

  .divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
  }

  @media screen and (min-width: 900px) and (max-width: 1300px) {
    .lead-container-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .lead-button {
      margin-right: 0;
      margin-bottom: 0.5rem;
      justify-self: flex-start;
      align-self: start;
    }
  }

  @media (max-width: 400px) {
    .lead-container-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .lead-button {
      margin-right: 0;
      margin-bottom: 0.5rem;
      justify-self: flex-start;
      align-self: start;
    }
  }
`;
