import { FC } from 'react';
import { GeneralWrapper } from '../styles';
import { Typography } from '@mui/material';
import { StatusSettingsWrapper } from './styles';
import SingleStatusComponent from './SingleStatusComponent';

const StatusSettings: FC = () => {
  return (
    <GeneralWrapper style={{ width: '100%' }}>
      <StatusSettingsWrapper>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Status management
          </Typography>
        </div>
        <div className="statuses-container">
          <SingleStatusComponent type="account" />
          <SingleStatusComponent type="prospect" />
        </div>
      </StatusSettingsWrapper>
    </GeneralWrapper>
  );
};

export default StatusSettings;
