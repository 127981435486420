import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 918px;

  .subscription-preview-card {
    width: 100%;
    border-radius: 16px;
    padding: 12px;
    background: ${colors.primaryLight};
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .left-side {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    height: 100%;
    padding: 4px;
    flex: 1;

    .divider-change-plan-card {
      width: 1px;
      height: 100%;
      border-left: 1px solid ${colors.primaryLightHover};
    }
  }
  .icon-inside-circular {
    position: absolute;
    top: 7px;
    left: 7px;
    width: 24px;
    height: 24px;
  }
  .add-more-container {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .add-more-container:hover > button {
    color: ${colors.darkGreenHover};
    background-color: transparent !important;
  }
  .add-more-container:hover > button > span > svg > path {
    stroke: ${colors.darkGreenHover};
  }
  .add-more-button {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: ${colors.primaryDark};
    text-transform: unset;
    height: 22px;
  }
  .illustration-change-subscription {
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 16px;
    background: ${colors.primaryDark};
    color: ${colors.white};
    padding: 16px;
    width: 240px;
  }
  .illustration-contact-us-subscription {
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 16px;
    background: ${colors.white};
    color: ${colors.mainDark};
    border: 1px solid ${colors.primaryLightHover};
    padding: 16px;
    width: 240px;
  }
`;

export const UsersTableWrapper = styled.div`
  .user-list-container {
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
  }
  .user-card {
    padding: 16px 24px 16px 24px;
    gap: 48px;
    border-bottom: 1px solid ${colors.grey444};
    display: flex;
    width: 100%;
    align-items: center;
    overflow: hidden;
  }
  .user-card:last-child {
    border-bottom: none;
  }
  .active-badge,
  .pending-badge,
  .expired-badge {
    border-radius: 4px;
    padding: 7px 16px;
    gap: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
  .active-badge {
    background: ${colors.callScheduledLight};
    color: ${colors.callScheduled};
  }
  .pending-badge {
    background: ${colors.nurtureLight};
    color: ${colors.darkYellow};
  }
  .expired-badge {
    background: ${colors.inactiveLight};
    color: ${colors.inactive};
  }
  .admin-checkbox {
    display: flex;
  }
  .buttons-user-container {
    display: flex;
    gap: 8px;
  }
  .button-edit-user {
    background: ${colors.primaryLight};
    border-radius: 50%;
    &:hover {
      background: ${colors.primaryLightHover};
    }
    width: 32px;
    height: 32px;
    padding: 6px;
  }
  .button-delete-user {
    background: ${colors.inactiveLight};
    border-radius: 50%;
    &:hover {
      background: ${colors.inactiveLightHover};
    }
    width: 32px;
    height: 32px;
    padding: 6px;
  }
`;
