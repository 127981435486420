import React, { useEffect, useRef, useState } from 'react';
import { StrategyCreationWrapper } from './style';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import {
  Checkbox,
  Radio,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { useConditionalSnackBar } from 'context/snackbar';
import useCreateStrategy from 'hooks/services/sequence/useCreateStrategy';
import { getError, getNewOptionsWhenSelecting } from 'crono-fe-common/utils';
import MinusSmallIcon from 'crono-fe-common/icons/Icon-Minus-Small';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import { StrategyInsert } from 'crono-fe-common/types/DTO/strategyInsert';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { FeConstants } from 'constants/FeConstants';
import { useAuth } from 'context/auth';
import Role from 'crono-fe-common/types/enums/role';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { ReactComponent as UncheckedIcon } from 'crono-fe-common/icons/Unchecked.svg';
import { ReactComponent as DragIcon } from 'crono-fe-common/icons/Drag.svg';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { ITagSelected } from './types';
import { useJuneAnalytics } from 'context/june';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { IChannel, getPercentages } from 'utils/fe-utils';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import { useCustomerly } from 'react-live-chat-customerly';
import { createPortal } from 'react-dom';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';

interface IProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCreate: (sequenceId: number) => void;
}
const channels: IChannel[] = [
  {
    name: 'Email',
    icon: <EmailIcon className="channel-icon" />,
    iconColored: <EmailIcon className="channel-icon" color={colors.inactive} />,
    type: TaskTodoType.EMAIL,
  },
  {
    name: 'Linkedin',
    icon: <LinkedinIcon className="channel-icon" />,
    iconColored: <LinkedinIcon className="channel-icon" color={colors.blue} />,
    type: TaskTodoType.LINKEDIN,
  },
  {
    name: 'Call',
    icon: <CallIcon className="channel-icon" />,
    iconColored: (
      <CallIcon className="channel-icon" color={colors.callScheduled} />
    ),
    type: TaskTodoType.CALL,
  },
];

export const AddToStrategyExternalPropertyTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: 300,
    overflow: 'hidden',
    maxWidth: 400,
    border: '1px solid #dadde9',
    borderRadius: 16,
    padding: '12px 0px 12px 12px',
    zIndex: 600001,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

//This is necessary since the drag and drop is inside a div that is transformed (-50%, -50%)
//This creates a portal with full width and height that allows the component to be use correctly
//https://github.com/DucktorDanny/react-beautiful-dnd-example
const useDraggableInPortal = () => {
  const self = useRef({} as any).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render: any) =>
    (provided: any, ...args: any) => {
      const element = render(provided, ...args);
      if (provided.draggableProps.style.position === 'fixed') {
        return createPortal(element, self.elt);
      }
      return element;
    };
};

const StrategyCreation = ({ setOpen, onCreate }: IProps) => {
  const { user } = useAuth();

  const analytics = useJuneAnalytics();

  const [name, setName] = useState<string>('');
  const [nSteps, setNSteps] = useState<number>(8);
  const [firstStepType, setFirstStepType] = useState<TaskTodoType | null>(null);
  const [channelsEnabled, setChannelsEnabled] = useState<boolean[]>([
    true,
    true,
    true,
  ]);
  const [createPrivate, setCreatePrivate] = useState<boolean>(true);
  const { data: externalPropertyTags } = useGetExternalProperty(null, true);
  const [error, setError] = useState<string | null>();

  const {
    mutate: createStrategy,
    data: createStrategyData,
    error: createStrategyError,
    isSuccess: strategyCreated,
  } = useCreateStrategy();

  //Customerly for events for workflows
  const { event } = useCustomerly();

  useEffect(() => {
    if (strategyCreated) {
      event('create_strategy');
    }
  }, [strategyCreated]);

  //UseEffect called when the strategy is created
  useEffect(() => {
    if (createStrategyData?.data?.data) {
      onCreate(createStrategyData?.data?.data);
    }
  }, [createStrategyData]);

  useConditionalSnackBar([
    {
      condition: !!error,
      message: error ?? 'Input data error',
      severity: 'error',
    },
    {
      condition: !!createStrategyError,
      message: getError(createStrategyError) ?? 'Error while create strategy',
      severity: 'error',
    },
    {
      condition: strategyCreated,
      message: 'Strategy created',
      severity: 'success',
    },
  ]);

  const [tagSelected, setTagSelected] = useState<ITagSelected[]>([]);

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const externalPropertyId = externalProperty.id;
    const newFilters = [...tagSelected];

    const newValueOption = getNewOptionsWhenSelecting({
      currentExternalValues: newFilters.map((tag) => tag.tag),
      externalPropertyId,
      option: option,
      valueType: externalProperty.valueType,
    });
    const newExternalValue: ITagSelected = {
      indexForColor: index,
      tag: {
        externalPropertyId: externalPropertyId,
        value: newValueOption!,
      },
    };
    const oldTag = tagSelected.find(
      (tag) => tag.tag.externalPropertyId === externalPropertyId,
    );
    //If option is null I have to remove the externalPropertyId selected
    if (oldTag && (oldTag?.tag.value === option || option === null)) {
      newFilters.splice(
        newFilters.findIndex(
          (tag) => tag.tag.externalPropertyId === externalPropertyId,
        ),
        1,
      );
    } else if (oldTag) {
      newFilters.splice(
        newFilters.findIndex(
          (tag) => tag.tag.externalPropertyId === externalPropertyId,
        ),
        1,
        newExternalValue,
      );
    } else {
      newFilters.push(newExternalValue);
    }

    setTagSelected(newFilters);
  };

  function handleCreate() {
    let inputError = false;
    if (name.length === 0) {
      setError('Please insert strategy name');
      inputError = true;
    }

    if (nSteps < FeConstants.minStepForSequence) {
      setError(
        'Number of steps should be at least: ' + FeConstants.minStepForSequence,
      );
      inputError = true;
    }

    if (nSteps > FeConstants.maxStepForSequence) {
      setError(
        'Number of steps should be at most: ' + FeConstants.maxStepForSequence,
      );
      inputError = true;
    }

    const { emailPercent, callPercent, linkedinPercent } =
      getPercentages(rankingList);

    if (!inputError) {
      const strategyInsert: StrategyInsert = {
        name,
        numberOfSteps: nSteps,
        firstStep: firstStepType,
        emailPercent: emailPercent,
        callPercent: callPercent,
        linkedinPercent: linkedinPercent,
        shared: user?.userRoles.includes(Role.MANAGER) ? !createPrivate : false,
        strategyTags: tagSelected.map((tag) => tag.tag),
      };

      createStrategy(strategyInsert);

      if (analytics) {
        analytics.track('strategy_created', {});
      }
    } else {
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  }

  const handleChangeNumber = (value: number) => {
    if (
      nSteps + value >= FeConstants.minStepForSequence &&
      nSteps + value <= FeConstants.maxStepForSequence
    ) {
      setNSteps(nSteps + value);
    }
  };

  const [rankingList, setRankingList] = useState<IChannel[]>([]);
  //When the selected channels change, the ranking list is updated
  useEffect(() => {
    const aux: IChannel[] = [];
    channelsEnabled.forEach((enabled, index) => {
      if (enabled) {
        aux.push(channels[index]);
      }
    });
    setRankingList(aux);
  }, [channelsEnabled]);

  const reorder = (list: IChannel[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      rankingList,
      result.source.index,
      result.destination.index,
    );

    setRankingList(items);
  };

  const getTaskTodoType = (index: number) => {
    return index === 0
      ? TaskTodoType.EMAIL
      : index === 1
        ? TaskTodoType.LINKEDIN
        : TaskTodoType.CALL;
  };

  const handleChangeSelectChannel = (index: number) => {
    const newChannelsEnabled = [...channelsEnabled];
    newChannelsEnabled[index] = !newChannelsEnabled[index];
    if (newChannelsEnabled.filter((enabled) => enabled).length === 0) {
      //It's not possible to disable all channels
      return;
    }
    if (
      !newChannelsEnabled[index] &&
      firstStepType === getTaskTodoType(index)
    ) {
      setFirstStepType(null);
    }
    setChannelsEnabled(newChannelsEnabled);
  };

  const renderDraggable = useDraggableInPortal();

  return (
    <StrategyCreationWrapper onClick={() => setOpen(false)}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="strategy-creation-container"
      >
        <CloseTabButton
          style={{ paddingRight: '24px' }}
          close={() => setOpen(false)}
        />
        <div className="strategy-creation-block-container">
          <Typography fontSize={24} fontWeight={700}>
            Create strategy:
          </Typography>
          <div className="strategy-creation-block">
            <Typography fontSize={14} fontWeight={600}>
              Name:*
            </Typography>
            <Typography fontSize={12} color={colors.grey11}>
              Select a name for this strategy
            </Typography>
            <TextField
              variant="standard"
              placeholder="Strategy name..."
              InputProps={{
                disableUnderline: true,
              }}
              value={name}
              onChange={(e) => setName(e.target.value ?? '')}
            />
          </div>
          <div className="strategy-creation-block">
            <Typography fontSize={14} fontWeight={600}>
              Number of steps:*
            </Typography>
            <Typography fontSize={12} color={colors.grey11}>
              Select amount of steps in your strategy
            </Typography>
            <div className="number-picker">
              <Typography
                className="number-picker-button"
                onClick={() => handleChangeNumber(-1)}
              >
                <MinusSmallIcon
                  color={
                    nSteps === FeConstants.minStepForSequence
                      ? colors.grey3
                      : colors.grey11
                  }
                />
              </Typography>
              <Typography fontWeight={500}>{nSteps}</Typography>
              <Typography
                className="number-picker-button"
                onClick={() => handleChangeNumber(1)}
              >
                <PlusSmallIcon
                  color={
                    nSteps === FeConstants.maxStepForSequence
                      ? colors.grey3
                      : colors.grey11
                  }
                />
              </Typography>
            </div>
          </div>
          <div className="strategy-creation-block">
            <Typography fontSize={14} fontWeight={600}>
              Selected channels:*
            </Typography>
            <Typography fontSize={12} color={colors.grey11}>
              Crono will offer only selected communication channel
            </Typography>
            <div className="channels-container">
              {channels.map((channel, index) => (
                <div
                  key={index}
                  className={`channel-container`}
                  onClick={() => handleChangeSelectChannel(index)}
                >
                  <Checkbox
                    checked={channelsEnabled[index]}
                    style={{ borderRadius: 24 }}
                    className="channel-checkbox"
                    checkedIcon={<CheckedIcon />}
                    icon={<UncheckedIcon />}
                  />
                  {channelsEnabled[index] ? channel.iconColored : channel.icon}
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    className={` ${
                      channelsEnabled[index] && `selected-${channel.name}`
                    }`}
                  >
                    {channel.name}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
          <div className="strategy-creation-block">
            <Typography fontSize={14} fontWeight={600}>
              First step (optional):
            </Typography>
            <div className="channels-container">
              {channels.map((channel, index) => (
                <div key={index} className="channel-container">
                  <Radio
                    checked={firstStepType === channel.type}
                    className="channel-checkbox"
                    color="secondary"
                    disabled={!channelsEnabled[index]}
                    onClick={() => {
                      setFirstStepType(channel.type);
                    }}
                  />
                  {firstStepType === channel.type
                    ? channel.iconColored
                    : channel.icon}
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    className={` ${
                      firstStepType === channel.type &&
                      `selected-${channel.name}`
                    }`}
                  >
                    {channel.name}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
          <div className="strategy-creation-block">
            <Typography fontSize={14} fontWeight={600}>
              Ranking channels:*
            </Typography>
            <Typography fontSize={12} color={colors.grey11}>
              Select the actions hierachy
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="ranking-container"
                  >
                    <div className="ranking-numbers">
                      {rankingList.map((_, index) => (
                        <Typography className="number" key={index}>
                          {index + 1}
                        </Typography>
                      ))}
                    </div>
                    <div style={{ width: '100%' }}>
                      {rankingList.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={'' + index}
                          index={index}
                        >
                          {renderDraggable((provided: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              //The style is here since the component is created thanks to a portal, so the style cannot be taken from the class name
                              style={{
                                ...provided.draggableProps.style,
                                // top: "auto",
                                // left: "auto",
                                margin: 6,
                                borderRadius: 8,
                                padding: '10px 10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                ...(item.name === 'Call'
                                  ? {
                                      background: colors.callScheduledLight,
                                      color: colors.callScheduled,
                                    }
                                  : item.name === 'Email'
                                    ? {
                                        background: colors.inactiveLight,
                                        color: colors.inactive,
                                      }
                                    : {
                                        background: colors.blueLight,
                                        color: colors.blue,
                                      }),
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {item.iconColored}
                                <Typography
                                  fontSize={12}
                                  fontWeight={500}
                                  style={{ marginLeft: 16 }}
                                >
                                  {item.name}
                                </Typography>
                              </div>
                              <DragIcon />
                            </div>
                          ))}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {externalPropertyTags?.data?.data.length !== 0 && (
            <div className="strategy-creation-block tags">
              <Typography fontSize={14} fontWeight={600}>
                Add tags:
              </Typography>
              <div className="tag-container">
                <TagsBoxShowAll
                  type="AccountAndProspect"
                  handleClickTag={handleClickTag}
                  currentSituationTags={tagSelected.map((tag) => ({
                    externalPropertyId: tag.tag.externalPropertyId,
                    option: tag.tag.value,
                  }))}
                />
              </div>
            </div>
          )}
          <FlexDiv
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <div className="switch-container">
              {user?.userRoles.includes(Role.MANAGER) && (
                <>
                  <Typography
                    fontWeight={500}
                    lineHeight={'16px'}
                    fontSize={12}
                    color={colors.grey11}
                  >
                    Private
                  </Typography>
                  <CronoSwitch
                    checked={createPrivate}
                    onChange={(ev) => {
                      setCreatePrivate(ev.target.checked);
                    }}
                    disabled={!user?.userRoles.includes(Role.MANAGER)}
                  />
                </>
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CancelButton onClick={() => setOpen(false)}>Cancel</CancelButton>
              <MainButton onClick={() => handleCreate()}>Create</MainButton>
            </div>
          </FlexDiv>
        </div>
      </div>
    </StrategyCreationWrapper>
  );
};

export default StrategyCreation;
