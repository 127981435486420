import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type OpportunityDisableInputs = {
  AccountId: string | null;
  OpportunityId: string | null;
};

export default function useDisableOpportunity() {
  const queryClient = useQueryClient();

  const disableOpportunity = (data: OpportunityDisableInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.opportunity.disable}`,
      config: {
        method: 'delete',
        data: data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    OpportunityDisableInputs
  >(disableOpportunity, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.opportunity.main]);
      queryClient.invalidateQueries([ENDPOINTS.opportunity.search]);
    },
  });

  return { data, mutate, ...rest };
}
