import Connect from 'pages/connect/views/main';
import SalesforceLoginHandler from './views/salesforce';
import LinkedinLoginHandler from './views/linkedin';
import HubspotLoginHandler from './views/hubspot';
import PipedriveLoginHandler from './views/pipedrive';
import { RouteObject } from 'react-router-dom';
import OutlookLoginHandler from './views/outlook';
import AircallLoginHandler from './views/aircall';

const Routes: RouteObject[] = [
  {
    index: true,
    element: <Connect />,
  },
  {
    path: 'salesforce/callback',
    element: <SalesforceLoginHandler />,
  },
  {
    path: 'hubspot/callback',
    element: <HubspotLoginHandler />,
  },
  {
    path: 'linkedin/callback',
    element: <LinkedinLoginHandler />,
  },
  {
    path: 'pipedrive/callback',
    element: <PipedriveLoginHandler />,
  },
  {
    path: 'outlook/callback',
    element: <OutlookLoginHandler />,
  },
  {
    path: 'aircall/callback',
    element: <AircallLoginHandler />,
  },
];

export default Routes;
