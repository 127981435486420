import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const EditableTemplateModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .background {
    background: ${colors.grey1}66;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .modal-container {
    background: ${colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    max-height: 85%;
    border-radius: 16px;
    z-index: 20;
    overflow: auto;

    &::-webkit-scrollbar-track {
      margin-block: 16px;
    }
  }

  .modal-body {
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    position: relative;

    .text-filed {
      label {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: ${colors.grey11};
      }
    }

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      position: sticky;
      bottom: 0;
      background: ${colors.white};
      z-index: 30;
    }

    .modal-button {
      border-radius: 999px;
      text-transform: none;
      margin: 4px;
      font-size: 16px;
      height: 48px;
      padding-block: 12px;
    }
  }
`;
