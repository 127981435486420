import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect } from 'react';
import { AddContainerWrapper } from '../../addOpportunity/style';
import { Typography } from '@mui/material';
import AddPipelineForm from '../formComponent';

import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import useCreatePipeline, {
  PipelineInput,
} from 'hooks/services/pipeline/useCreatePipeline';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  close: () => void;
  existingPipelines?: string[];
}

const AddPipelineContainer = ({ close, existingPipelines }: IProps) => {
  const {
    mutate: createPipeline,
    isSuccess,
    error,
    isLoading: createLoading,
  } = useCreatePipeline();

  const analytics = useJuneAnalytics();

  const handleSubmit = (newPipeline: PipelineInput) => {
    createPipeline(newPipeline);
    if (analytics) {
      analytics.track('create-pipeline', {});
    }
  };

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) || 'Error while creating pipeline',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <BlurBackgroundDivFixed>
      <AddContainerWrapper>
        <CloseTabButton close={close} style={{ marginRight: '18px' }} />
        <div className="add-main-container" style={{ gap: '32px' }}>
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Create new pipeline
          </Typography>
          <AddPipelineForm
            close={close}
            handleSubmit={handleSubmit}
            createLoading={createLoading}
            existingPipelines={existingPipelines}
          />
        </div>
      </AddContainerWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default AddPipelineContainer;
