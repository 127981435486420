import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const UpgradeSubscriptionModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 1030px;
  padding: 48px;
  background-color: ${colors.white};
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .cross {
    position: absolute;
    top: 24px;
    right: 0;
  }

  .container {
    display: flex;
    width: 100%;
    gap: 80px;
    justify-content: space-between;

    .upgrade-options {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-self: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 48px;

      .header {
        padding-top: 8px;
      }

      .features-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 24px;
        row-gap: 4px;
      }

      .upgrade-panel {
        background: ${colors.primaryLight};
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        .badge {
          background-color: ${colors.primary};
          color: ${colors.white};

          svg > path {
            stroke: ${colors.white};
          }
        }
      }
    }
  }
`;
