import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const RescheduleIcon = ({ color = '#9E9E9E', className, onClick }: IProps) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M11.9999 22.0036H6.99783C5.34029 22.0036 3.99658 20.6599 3.99658 19.0023V6.99734C3.99658 5.3398 5.34029 3.99609 6.99783 3.99609H19.0028C20.6604 3.99609 22.0041 5.3398 22.0041 6.99734V11.9994"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5864 17.2711H22.004V15.8555"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4219 20.333H16.0073V21.7486"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0038 19.8691C21.8544 20.3206 21.6028 20.7314 21.2685 21.0696C20.0183 22.3195 17.9917 22.3195 16.7416 21.0696C16.5243 20.8534 16.3397 20.6067 16.1934 20.3373"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8159 17.2703C21.3333 16.3839 20.4609 15.7769 19.462 15.6325C18.463 15.4882 17.4545 15.8234 16.7407 16.537C16.4058 16.8752 16.1532 17.286 16.0024 17.7375"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0041 8.99826H3.99658"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7497 12.4502C14.7226 12.4502 14.7007 12.4722 14.7007 12.4992C14.7007 12.5263 14.7226 12.5483 14.7497 12.5483C14.7768 12.5483 14.7987 12.5263 14.7987 12.4992C14.7987 12.4722 14.7768 12.4502 14.7497 12.4502"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2482 12.4502C11.2353 12.4502 11.223 12.4554 11.2139 12.4646C11.2049 12.4739 11.1999 12.4863 11.2002 12.4992C11.2002 12.5192 11.2123 12.5371 11.2307 12.5446C11.2492 12.5521 11.2703 12.5478 11.2843 12.5335C11.2982 12.5193 11.3021 12.4981 11.2942 12.4798C11.2863 12.4615 11.2681 12.4498 11.2482 12.4502"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.74681 12.4497C7.73355 12.4494 7.72076 12.4546 7.71147 12.4641C7.70219 12.4736 7.69723 12.4865 7.69779 12.4997C7.69779 12.5197 7.70985 12.5376 7.72829 12.5451C7.74673 12.5526 7.76789 12.5483 7.78183 12.534C7.79577 12.5198 7.79971 12.4986 7.79181 12.4803C7.7839 12.462 7.76572 12.4503 7.74581 12.4507"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2482 15.4512C11.2353 15.4512 11.223 15.4564 11.2139 15.4656C11.2049 15.4748 11.1999 15.4873 11.2002 15.5002C11.2002 15.5201 11.2123 15.5381 11.2307 15.5456C11.2492 15.5531 11.2703 15.5487 11.2843 15.5345C11.2982 15.5203 11.3021 15.499 11.2942 15.4808C11.2863 15.4625 11.2681 15.4508 11.2482 15.4512"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.74681 15.4507C7.73355 15.4504 7.72076 15.4556 7.71147 15.4651C7.70219 15.4746 7.69723 15.4875 7.69779 15.5007C7.69779 15.5206 7.70985 15.5386 7.72829 15.5461C7.74673 15.5536 7.76789 15.5492 7.78183 15.535C7.79577 15.5208 7.79971 15.4995 7.79181 15.4813C7.7839 15.463 7.76572 15.4513 7.74581 15.4517"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2482 18.4532C11.2353 18.4531 11.223 18.4583 11.2139 18.4676C11.2049 18.4768 11.1999 18.4893 11.2002 18.5022C11.2002 18.5221 11.2123 18.54 11.2307 18.5475C11.2492 18.5551 11.2703 18.5507 11.2843 18.5365C11.2982 18.5222 11.3021 18.501 11.2942 18.4827C11.2863 18.4644 11.2681 18.4527 11.2482 18.4532"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.74679 18.4532C7.73371 18.4529 7.72108 18.458 7.71183 18.4672C7.70258 18.4765 7.6975 18.4891 7.69777 18.5022C7.69778 18.5221 7.70983 18.54 7.72827 18.5475C7.74671 18.5551 7.76788 18.5507 7.78181 18.5365C7.79575 18.5222 7.79969 18.501 7.79179 18.4827C7.78389 18.4644 7.76571 18.4527 7.74579 18.4532"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RescheduleIcon;
