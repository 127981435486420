import { useAuth } from 'context/auth';
import { FC, LegacyRef, useEffect, useMemo, useRef, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AnalyticsWrapper, ChartsGridWrapper } from './style';

import useGetUsers from 'hooks/services/subscription/useGetUsers';
// import { Account } from "crono-fe-common/types/account";
import { User } from 'crono-fe-common/types/user';
import { useJuneAnalytics } from 'context/june';
// import useSearchAccounts from "hooks/services/account/useSearchAccounts";
import AnalyticSequenceTabController from './tabsControllers/analyticSequenceTabController';
import AnalyticTaskTabController from './tabsControllers/analyticTaskTabController';
import AnalyticTemplateTabController from './tabsControllers/analyticTemplateTabController';
import AnalyticSuggestionTabController from './tabsControllers/analyticSuggestionTabController';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import {
  newDateWithInitialTime,
  newDateWithEndTime,
  fullname,
} from 'utils/fe-utils';
import { colors } from 'crono-fe-common/theme';
import React from 'react';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import AnalyticDealsTabController from './tabsControllers/analyticDealsTabController';
import useGetPipelines from 'hooks/services/pipeline/useGetPipelines';
import { Pipeline } from 'crono-fe-common/types/pipeline';

enum SequenceTabValue {
  Sequence,
  Task,
  Template,
  Suggestion,
  Deals,
}

export enum AnalyticsTabs {
  // Sequences
  OverallSequences = '0_0',
  SequenceDetail = '0_1',
  ConversionRate = '0_2',
  // Tasks
  TaskOverallActivity = '1_0',
  EmailConversionRate = '1_1',
  LinkedinActivity = '1_2',
  Calls = '1_3',
  ActivityHeatmap = '1_4',
  // Templates
  EmailTemplateConversionRate = '2_0',
  LinkedinTemplateConversionRate = '2_1',
  // Suggestions
  SuggestionsOverallActivity = '3_0',
  DataHealth = '3_1',
  // Deals
  OverallDeals = '4_0',
  OverallDealValue = '4_1',
}

const analyticsTabsArray = Object.values(AnalyticsTabs).filter((v) =>
  isNaN(Number(v)),
);

interface GraphsIProps {
  tabValue: SequenceTabValue;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

const AnalyticsGraphs = React.forwardRef((props: GraphsIProps, ref: any) => {
  const { tabValue } = props;
  const { user, useAuthGuard } = useAuth();
  useAuthGuard();
  const [since, setSince] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [period, setPeriod] = useState<string | null>('');
  // const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  // const [searchText, setSearchText] = useState<string>("");
  const [searchUserText, setSearchUserText] = useState<string>('');

  const juneAnalytics = useJuneAnalytics();

  useEffect(() => {
    if (user?.userPreferences?.analyticsFilters) {
      const analyticsFilters = JSON.parse(
        user?.userPreferences?.analyticsFilters,
      );
      if (analyticsFilters.userId) {
        setUserId(analyticsFilters.userId);
      }
      if (analyticsFilters.since) {
        setSince(new Date(analyticsFilters.since));
      }
      if (analyticsFilters.to) {
        setTo(new Date(analyticsFilters.to));
      }
      if (analyticsFilters.period) {
        setPeriod(analyticsFilters.period);
      }
    }
  }, [user]);

  // const { data: accounts } = useSearchAccounts({
  //   name: searchText,
  //   limit: 10,
  //   status: null,
  //   offset: 0,
  //   IsManager: true,
  //   sort: "Name",
  // });

  const { data: users } = useGetUsers();
  const { data: pipelines } = useGetPipelines();
  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline | null>(
    null,
  );

  useEffect(() => {
    if (pipelines?.data?.data.length && selectedPipeline === null) {
      setSelectedPipeline(pipelines?.data?.data[0]);
    }
  }, [pipelines]);

  const analyticsFilters = useMemo(() => {
    return {
      ...(userId && { userId }),
      ...(since && { since }),
      ...(to && { to }),
      // ...(selectedAccount && { accountId: selectedAccount }),
    };
  }, [userId, since, to /*,selectedAccount*/]);

  useEffect(() => {
    //Update the userPreferences for analytics filters
    if (user) {
      if (user.userPreferences) {
        user.userPreferences.analyticsFilters = JSON.stringify({
          ...analyticsFilters,
          period,
        });
      } else {
        user.userPreferences = {
          analyticsFilters: JSON.stringify({ ...analyticsFilters, period }),
        };
      }
    }
    patchUserPreferences({
      analyticsFilters: JSON.stringify({ ...analyticsFilters, period }),
    });
  }, [analyticsFilters, period]);

  useEffect(() => {
    if (juneAnalytics) {
      juneAnalytics.track('view-analytics', {});
    }
  }, [juneAnalytics]);

  function updateSince(value: Date | null) {
    setSince(value);
    setPeriod('');
  }

  function updateTo(value: Date | null) {
    setTo(value);
    setPeriod('');
  }

  function updatePeriod(value: string | null) {
    setPeriod(value);
    let newSince = null,
      newTo = null;
    if (value === '') {
      newSince = null;
      newTo = null;
    } else if (value === 'today') {
      // setSince to yesterday
      newSince = newDateWithInitialTime();
      newTo = null;
    } else if (value === 'this-week') {
      // find last monday
      const lastMonday = newDateWithInitialTime();
      lastMonday.setDate(lastMonday.getDate() - lastMonday.getDay() + 1);
      newSince = lastMonday;
      newTo = new Date();
    } else if (value === 'this-month') {
      // setSince to last month
      // set date to the first of this month
      const lastMonth = newDateWithInitialTime();
      lastMonth.setDate(1);
      newSince = lastMonth;
      newTo = new Date();
    } else if (value === 'this-quarter') {
      // setSince to last month
      // set date to the begin of this quarter
      const lastQuarter = newDateWithInitialTime();
      lastQuarter.setMonth(Math.floor(lastQuarter.getMonth() / 3) * 3);
      lastQuarter.setDate(1);
      newSince = lastQuarter;
      newTo = new Date();
    } else if (value === 'last-week') {
      // setSince to last week
      // set date to the first of last week
      const lastWeekMonday = newDateWithInitialTime();
      lastWeekMonday.setDate(
        lastWeekMonday.getDate() - lastWeekMonday.getDay() + 1,
      );
      lastWeekMonday.setDate(lastWeekMonday.getDate() - 7);
      newSince = lastWeekMonday;
      const lastWeekSunday = newDateWithEndTime();
      lastWeekSunday.setDate(
        lastWeekSunday.getDate() - lastWeekSunday.getDay(),
      );
      newTo = lastWeekSunday;
    } else if (value === 'last-month') {
      // setSince to last month
      // set date to the first of last month
      const lastMonth = newDateWithInitialTime();
      lastMonth.setDate(1);
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      newSince = lastMonth;
      const lastMonthEnd = newDateWithEndTime();
      lastMonthEnd.setDate(0);
      newTo = lastMonthEnd;
    } else if (value === 'last-quarter') {
      // setSince to last month
      // set date to the begin of last quarter
      const lastQuarter = new Date();
      lastQuarter.setMonth(Math.floor(lastQuarter.getMonth() / 3) * 3 - 3);
      lastQuarter.setDate(1);
      newSince = lastQuarter;
      const lastQuarterEnd = newDateWithEndTime();
      lastQuarterEnd.setMonth(Math.floor(lastQuarterEnd.getMonth() / 3) * 3);
      lastQuarterEnd.setDate(0);
      newTo = lastQuarterEnd;
    }
    setSince(newSince);
    setTo(newTo);
  }

  function choosePeriodSelectClassName(value: string | null): string {
    if (!value || value === '') {
      return 'input-period-placeholder';
    }
    return 'input-period';
  }

  return (
    <ChartsGridWrapper>
      <div className="analytics-charts-container">
        <div className="filter-container">
          {tabValue === SequenceTabValue.Deals && (
            <CronoSelect
              style={{ height: 40, minWidth: '200px' }}
              value={selectedPipeline?.id}
              sx={{ fontSize: '12px' }}
              onChange={(event) => {
                //find can return us undefined but will never be the case
                //because the items as well choosed from the same array
                const pipeline: Pipeline = pipelines?.data?.data.find(
                  (p) => p.id === event.target.value,
                ) as Pipeline;
                setSelectedPipeline(pipeline);
              }}
            >
              {pipelines?.data?.data
                .map((pipeline) => {
                  return (
                    <CronoMenuItem value={pipeline.id} key={pipeline.id}>
                      {pipeline.publicName}
                    </CronoMenuItem>
                  );
                })
                .concat([])}
            </CronoSelect>
          )}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={since}
              format="dd/MM/yyyy"
              onChange={(value) => updateSince(value)}
              className="input-date"
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              slots={(params: any) => (
                <TextField
                  className="input-date"
                  name="scheduleDate"
                  {...params}
                />
              )}
            />
            <DatePicker
              value={to}
              format="dd/MM/yyyy"
              onChange={(value) => updateTo(value)}
              className="input-date"
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              slots={(params: any) => (
                <TextField
                  className="input-date"
                  name="scheduleDate"
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>

          <FormControl>
            <CronoSelect
              value={period}
              className={choosePeriodSelectClassName(period)}
              onChange={(e) => {
                updatePeriod((e.target.value ?? '') as string);
              }}
              displayEmpty={true}
            >
              <CronoMenuItem value="" className="select-option">
                Period
              </CronoMenuItem>
              <CronoMenuItem value="today" className="select-option">
                Today
              </CronoMenuItem>
              <CronoMenuItem value="this-week" className="select-option">
                This Week
              </CronoMenuItem>
              <CronoMenuItem value="this-month" className="select-option">
                This Month
              </CronoMenuItem>
              <CronoMenuItem value="this-quarter" className="select-option">
                This Quarter
              </CronoMenuItem>
              <CronoMenuItem value="last-week" className="select-option">
                Last Week
              </CronoMenuItem>
              <CronoMenuItem value="last-month" className="select-option">
                Last Month
              </CronoMenuItem>
              <CronoMenuItem value="last-quarter" className="select-option">
                Last Quarter
              </CronoMenuItem>
            </CronoSelect>
          </FormControl>
          {/* <Autocomplete
            className="input-account"
            onChange={(e, value) => {
              setSelectedAccount((value as Account)?.objectId ?? "");
            }}
            options={[...(accounts?.data?.data || [])]}
            getOptionLabel={(account) => (account as Account).name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                className="input-account-field"
                variant="outlined"
                placeholder="Company"
                size="small"
                onChange={(e) => setSearchText(e.target.value)}
              />
            )}
            disablePortal
            PaperComponent={({ children }) => (
              <div className="autocomplete-paper">{children}</div>
            )}
            renderOption={(props, option) => (
              <li {...props} className="autocomplete-option">
                {option.name}
              </li>
            )}
          /> */}

          <CronoAutocomplete
            className="input-user"
            value={users?.data?.data.find((u) => u.id === userId) ?? null}
            onChange={(e, value) => {
              setUserId((value as User)?.id ?? '');
            }}
            options={[
              ...(users?.data?.data.filter((u) =>
                fullname(u.firstName, u.lastName)
                  .toLowerCase()
                  .includes(searchUserText.toLowerCase()),
              ) || []),
            ]}
            getOptionLabel={(user) =>
              fullname((user as User).firstName, (user as User).lastName) || ''
            }
            setSearchText={(newVal: string) => {
              setSearchUserText(newVal);
            }}
            textFieldParams={{
              placeholder: 'User',
            }}
            disablePortal={false}
          />
        </div>

        <div className="charts-grid" ref={ref}>
          {tabValue === SequenceTabValue.Sequence ? (
            <AnalyticSequenceTabController filterParams={analyticsFilters} />
          ) : tabValue === SequenceTabValue.Task ? (
            <>
              <AnalyticTaskTabController filterParams={analyticsFilters} />
            </>
          ) : tabValue === SequenceTabValue.Template ? (
            <>
              <AnalyticTemplateTabController filterParams={analyticsFilters} />
            </>
          ) : tabValue === SequenceTabValue.Suggestion ? (
            <>
              <AnalyticSuggestionTabController
                filterParams={analyticsFilters}
              />
            </>
          ) : tabValue === SequenceTabValue.Deals ? (
            <>
              <AnalyticDealsTabController
                filterParams={analyticsFilters}
                pipeline={selectedPipeline}
              />
            </>
          ) : null}
        </div>
      </div>
    </ChartsGridWrapper>
  );
});

interface AnalyticsIProps {
  tabValue: AnalyticsTabs;
}
('');

const tabsCustomStyle = {
  color: colors.grey11,
  '.MuiTab-root': {
    '&:hover': {
      background: colors.primaryLight,
      color: colors.primaryDark,
    },
  },
  '.MuiTabs-flexContainer': {
    flexDirection: 'column',
    overflowX: 'auto',
    padding: '0px 16px',
  },
  '.MuiTabs-indicator': {
    display: 'none',
  },
  '.Mui-selected': {
    backgroundColor: colors.primaryLight,
  },
  // ".MuiTabs-scroller": {
  //   overflow: "auto !important"
  // }
};

const tabCustomStyle = {
  display: 'flex',
  textAlign: 'left',
};

const Analytics = ({ tabValue }: AnalyticsIProps) => {
  const { user } = useAuth();

  const [value, setValue] = useState<AnalyticsTabs>(tabValue);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue as AnalyticsTabs);
  };

  const listRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const index = +value.split('_')[1];
    let element = listRef.current?.children[2 * index];
    if (element?.tagName === 'DIV' && element.children[0]?.tagName === 'P') {
      element = element?.children[0];
    }
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [value, listRef.current]);

  return (
    <>
      {user && (
        <AnalyticsWrapper className="analytics-wrapper">
          <div className="analytics-container">
            <div style={{ display: 'flex', height: '100%', overflow: 'auto' }}>
              <div className="tabs-container">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  className="tabs-mui-component"
                  sx={tabsCustomStyle}
                >
                  <Typography className="left-menu-category">Tasks</Typography>

                  <Tab
                    value={AnalyticsTabs.TaskOverallActivity}
                    label="Overall Activity"
                    className="left-menu-tab"
                  />
                  <Tab
                    value={AnalyticsTabs.EmailConversionRate}
                    label="Email Conversion Rate"
                    className="left-menu-tab"
                  />
                  <Tab
                    value={AnalyticsTabs.LinkedinActivity}
                    label="Linkedin Activity"
                    className="left-menu-tab"
                  />
                  <Tab
                    value={AnalyticsTabs.Calls}
                    label="Calls"
                    className="left-menu-tab"
                  />
                  <Tab
                    value={AnalyticsTabs.ActivityHeatmap}
                    label="Activity Heatmap"
                    className="left-menu-tab"
                  />
                </Tabs>

                <div className="divider" />

                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  className="tabs-mui-component"
                  sx={tabsCustomStyle}
                >
                  <Typography className="left-menu-category">
                    Strategies
                  </Typography>
                  <Tab
                    value={AnalyticsTabs.OverallSequences}
                    label="Overall Strategies"
                    className="left-menu-tab"
                  />
                  <Tab
                    value={AnalyticsTabs.SequenceDetail}
                    label="Strategy Detail"
                    className="left-menu-tab"
                  />
                  <Tab
                    value={AnalyticsTabs.ConversionRate}
                    label="Conversion Rate"
                    className="left-menu-tab"
                  />
                </Tabs>

                <div className="divider" />

                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  className="tabs-mui-component"
                  sx={tabsCustomStyle}
                >
                  <Typography className="left-menu-category">
                    Templates
                  </Typography>
                  <Tab
                    value={AnalyticsTabs.EmailTemplateConversionRate}
                    label="Email Template Conversion Rate"
                    className="left-menu-tab"
                    sx={tabCustomStyle}
                  />
                  <Tab
                    value={AnalyticsTabs.LinkedinTemplateConversionRate}
                    label="Linkedin Template Conversion Rate"
                    className="left-menu-tab"
                    sx={tabCustomStyle}
                  />
                </Tabs>

                <div className="divider" />

                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  className="tabs-mui-component"
                  sx={tabsCustomStyle}
                >
                  <Typography className="left-menu-category">
                    Suggestions
                  </Typography>
                  <Tab
                    value={AnalyticsTabs.SuggestionsOverallActivity}
                    label="Overall Activity"
                    className="left-menu-tab"
                  />
                  <Tab
                    value={AnalyticsTabs.DataHealth}
                    label="Data Health"
                    className="left-menu-tab"
                  />
                </Tabs>

                {user.otherSettings?.hasOpportunities && (
                  <>
                    <div className="divider" />

                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      className="tabs-mui-component"
                      sx={tabsCustomStyle}
                    >
                      <Typography className="left-menu-category">
                        Deals
                      </Typography>

                      <Tab
                        value={AnalyticsTabs.OverallDeals}
                        label="Overall Deals"
                        className="left-menu-tab"
                      />

                      <Tab
                        value={AnalyticsTabs.OverallDealValue}
                        label="Overall Deal View"
                        className="left-menu-tab"
                      />
                    </Tabs>
                  </>
                )}
              </div>
            </div>

            <div className="tab-content-container">
              <AnalyticsGraphs
                tabValue={convertAnalyticsTabs(value)}
                ref={listRef}
              />
            </div>
          </div>
        </AnalyticsWrapper>
      )}
    </>
  );
};

const convertAnalyticsTabs = (value: AnalyticsTabs): SequenceTabValue => {
  switch (value) {
    case AnalyticsTabs.OverallSequences:
    case AnalyticsTabs.SequenceDetail:
    case AnalyticsTabs.ConversionRate:
      return SequenceTabValue.Sequence;
    case AnalyticsTabs.TaskOverallActivity:
    case AnalyticsTabs.EmailConversionRate:
    case AnalyticsTabs.LinkedinActivity:
    case AnalyticsTabs.Calls:
    case AnalyticsTabs.ActivityHeatmap:
      return SequenceTabValue.Task;
    case AnalyticsTabs.EmailTemplateConversionRate:
    case AnalyticsTabs.LinkedinTemplateConversionRate:
      return SequenceTabValue.Template;
    case AnalyticsTabs.SuggestionsOverallActivity:
    case AnalyticsTabs.DataHealth:
      return SequenceTabValue.Suggestion;
    case AnalyticsTabs.OverallDeals:
    case AnalyticsTabs.OverallDealValue:
      return SequenceTabValue.Deals;
    default:
      return SequenceTabValue.Sequence;
  }
};

export default Analytics;
