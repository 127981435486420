import { Button, Typography } from '@mui/material';
import { useAuth } from 'context/auth';
import React, { useEffect, useState } from 'react';
import SearchAccordionSingleAutocomplete from '../searchAccordion/searchAccordionSingleAutocomplete';
import SearchAccordionMultipleAutocomplete from '../searchAccordion/searchAccordionMultipleAutocomplete';
import { ReactComponent as LocationIcon } from 'crono-fe-common/icons/Icon-Geography.svg';
import { ReactComponent as IndustryIcon } from 'crono-fe-common/icons/Icon-Industry.svg';
import { ReactComponent as LocationPinIcon } from 'crono-fe-common/icons/Location.svg';
import { ReactComponent as TechnologyIcon } from 'crono-fe-common/icons/Technologies.svg';

import { ReactComponent as RemoveSEmpty } from 'crono-fe-common/icons/Remove-S-empty.svg';
import { ReactComponent as JobOpportunitiesIcon } from 'crono-fe-common/icons/Job-opportunities.svg';
import { ReactComponent as AnnualRevenueIcon } from 'crono-fe-common/icons/Revenue.svg';
import { ReactComponent as HeadcountGrowthIcon } from 'crono-fe-common/icons/Growth.svg';
import { ReactComponent as DeptHeadcount } from 'crono-fe-common/icons/Department-size.svg';
import { ReactComponent as DeptHeadcountGrowth } from 'crono-fe-common/icons/Department-growth.svg';

import {
  linkedinGeoUrns,
  linkedinNumberOfEmployees,
} from 'constants/FeConstants';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import ContactsIconS from 'crono-fe-common/icons/Icon-Contacts-S';
import { useSearchCompanyTab } from './searchCompanyTabContext';
import { colors } from 'crono-fe-common/theme';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import SearchAccordionRangesSelect from '../searchAccordion/searchAccordionRangesSelect';
import {
  LinkedinAnnualRevenueMap,
  LinkedinCurrencyMap,
  LinkedinDepartmentMap,
} from 'crono-fe-common/types/crono-extension/linkedin-constants-handly-written';
import SearchAccordionRangesInput from '../searchAccordion/searchAccordionRangesInput';
import { LinkedinFilterValue } from 'crono-fe-common/types/crono-extension/linkedin';

interface IProps {
  applyFilters: () => void;
  isDisabledExtension: boolean;
}

export type OptionsForAccordionRange = 'min' | 'max' | 'mainValue';

export type ValuesToOnChangeAnnualRevenue = {
  newLabel: LinkedinFilterValue | null;
  valueToChange: OptionsForAccordionRange;
};
export type ValuesToOnChange =
  | {
      newLabel: LinkedinFilterValue | null;
      valueToChange: 'mainValue';
    }
  | {
      newLabel: string | null;
      valueToChange: 'min' | 'max';
    };

const SearchCompanyTabFilters = ({
  applyFilters,
  isDisabledExtension,
}: IProps) => {
  const { hasSalesNavWithCookie } = useAuth();

  const { handleChangeFormik, values, filtersChanged, handleClearFilters } =
    useSearchCompanyTab();

  const handleChangeRangeAnnualRevenue = ({
    newLabel,
    valueToChange,
  }: ValuesToOnChangeAnnualRevenue) => {
    const propertyToChange = `annualRevenue.${valueToChange === 'mainValue' ? 'currency' : valueToChange}`;
    if (newLabel) {
      if (
        valueToChange === 'min' &&
        Number(newLabel.id) >= Number(values.annualRevenue?.max?.id)
      ) {
        //If we are changing the min to a value grater than the max, we set the max to the max value
        handleChangeFormik(
          `annualRevenue.max`,
          LinkedinAnnualRevenueMap.find((v) => v.id === '1001'),
        );
      }
      handleChangeFormik(propertyToChange, newLabel);
    } else {
      handleChangeFormik(propertyToChange, null);
    }
  };

  const handleChangeRangeInputs = (
    newLabel: string | LinkedinFilterValue | null,
    valueToChange: OptionsForAccordionRange,
    baseNameForFormik: string,
    mainPath: string,
  ) => {
    const propertyToChange = `${baseNameForFormik}.${valueToChange === 'mainValue' ? mainPath : valueToChange}`;
    if (newLabel) {
      handleChangeFormik(propertyToChange, newLabel);
    } else {
      handleChangeFormik(propertyToChange, null);
    }
  };
  const disableApplyDueToNoFilters =
    (hasSalesNavWithCookie &&
      Object.values(values).filter((v) => v).length === 0) ??
    false;

  const [expanded, setExpanded] = useState<keyof typeof values | null>(null);

  return (
    <div className="search-filters-section">
      <div className="left-side-container">
        <div className="filter-box first-filter">
          <Typography fontSize={16} fontWeight={600} lineHeight={'24px'}>
            Filters
          </Typography>
          <Button
            className="clear-filters-button"
            endIcon={<RemoveSEmpty />}
            onClick={handleClearFilters}
          >
            Clear
          </Button>
        </div>
        <div className="filters-accordions-container">
          {hasSalesNavWithCookie ? (
            <>
              <Typography
                fontSize={14}
                fontWeight={500}
                lineHeight={'18px'}
                color={colors.grey11}
                padding={'12px 24px 12px 24px'}
              >
                Company information
              </Typography>
              <SearchAccordionRangesSelect
                currentValue={values.annualRevenue?.currency}
                options={LinkedinCurrencyMap}
                displayLabel="Annual revenue"
                id="annualRevenueAccordion"
                placeholder="Type currency..."
                onChange={handleChangeRangeAnnualRevenue}
                icon={<AnnualRevenueIcon />}
                currentMin={values.annualRevenue?.min ?? null}
                currentMax={values.annualRevenue?.max ?? null}
                unitOfMeasureLabel="(Mln)"
                optionsRangeMin={LinkedinAnnualRevenueMap.filter(
                  (v) => v.id !== '1001',
                ).sort((a, b) => Number(a.id) - Number(b.id))}
                optionsRangeMax={LinkedinAnnualRevenueMap.sort(
                  (a, b) => Number(a.id) - Number(b.id),
                ).filter(
                  (option) =>
                    !values.annualRevenue?.min ||
                    Number(option.id) > Number(values.annualRevenue.min?.id),
                )}
                disableTopBorder={true}
                expanded={expanded === 'annualRevenue'}
                setExpanded={() =>
                  setExpanded(
                    expanded === 'annualRevenue' ? null : 'annualRevenue',
                  )
                }
              />

              <SearchAccordionMultipleAutocomplete
                displayLabel="Company size"
                currentValue={values.companySize ?? []}
                onChange={(e, selectedCompanySizes) =>
                  handleChangeFormik('companySize', selectedCompanySizes)
                }
                options={linkedinNumberOfEmployees}
                placeholder="Type size range..."
                id="companySizeAutocomplete"
                icon={<ContactsIconS />}
                handleSelectSingle={(companySize: LinkedinFilterValue) => {
                  if (!values.companySize) return;
                  handleChangeFormik(
                    'companySize',
                    values.companySize.filter((cs) => cs !== companySize) || [],
                  );
                }}
                expanded={expanded === 'companySize'}
                setExpanded={() =>
                  setExpanded(expanded === 'companySize' ? null : 'companySize')
                }
                orderForIds={true}
              />
              <SearchAccordionRangesInput
                showMainSelect={false}
                displayLabel="Headcount growth"
                id="headcountGrowthAccordion"
                onChange={({ newLabel, valueToChange }: ValuesToOnChange) =>
                  handleChangeRangeInputs(
                    newLabel,
                    valueToChange,
                    'headcountGrowth',
                    '',
                  )
                }
                icon={<HeadcountGrowthIcon />}
                currentMin={values.headcountGrowth?.min}
                currentMax={values.headcountGrowth?.max}
                unitOfMeasureLabel="%"
                expanded={expanded === 'headcountGrowth'}
                setExpanded={() =>
                  setExpanded(
                    expanded === 'headcountGrowth' ? null : 'headcountGrowth',
                  )
                }
              />
              <SearchAccordionMultipleAutocomplete
                displayLabel="Industry"
                currentValue={values.industry ?? []}
                onChange={(e, selectedIndustries) =>
                  handleChangeFormik('industry', selectedIndustries)
                }
                placeholder="Type keyword..."
                id="industryAutocomplete"
                icon={<IndustryIcon />}
                handleSelectSingle={(industry) => {
                  if (!values.industry) return;
                  handleChangeFormik(
                    'industry',
                    values.industry.filter((i) => i !== industry) || [],
                  );
                }}
                expanded={expanded === 'industry'}
                setExpanded={() =>
                  setExpanded(expanded === 'industry' ? null : 'industry')
                }
                filterType={{
                  type: 'salesNav',
                  filterName: 'INDUSTRY',
                }}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.headquarterLocation ?? []}
                displayLabel="Headquarters location"
                icon={<LocationPinIcon />}
                id="companyHeadquartersLocation"
                placeholder="Type location..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('headquarterLocation', selectedOptions);
                }}
                handleSelectSingle={(headcount: LinkedinFilterValue) => {
                  if (!values.headquarterLocation) return;
                  handleChangeFormik(
                    'headquarterLocation',
                    values.headquarterLocation.filter(
                      (i) => i.id !== headcount.id,
                    ),
                  );
                }}
                expanded={expanded === 'headquarterLocation'}
                setExpanded={() =>
                  setExpanded(
                    expanded === 'headquarterLocation'
                      ? null
                      : 'headquarterLocation',
                  )
                }
                filterType={{
                  type: 'salesNav',
                  filterName: 'BING_GEO',
                }}
              />
              <div className="single-line-find-new-filter">
                <div className="inner-text-section">
                  <JobOpportunitiesIcon />
                  <div className="inner-text-with-tooltip">
                    Job opportunities
                  </div>
                </div>
                <CronoSwitch
                  checked={values.hiringOnLinkedin ?? false}
                  onChange={(e) => {
                    handleChangeFormik('hiringOnLinkedin', e.target.checked);
                  }}
                />
              </div>
              <SearchAccordionRangesInput
                currentValue={values.departmentHeadcount?.department ?? null}
                showMainSelect={true}
                placeholder="Type department..."
                options={LinkedinDepartmentMap}
                displayLabel="Department headcount"
                id="departmentHeadcountAccordion"
                onChange={({ newLabel, valueToChange }: ValuesToOnChange) =>
                  handleChangeRangeInputs(
                    newLabel,
                    valueToChange,
                    'departmentHeadcount',
                    'department',
                  )
                }
                icon={<DeptHeadcount />}
                currentMin={values.departmentHeadcount?.min}
                currentMax={values.departmentHeadcount?.max}
                unitOfMeasureLabel=""
                expanded={expanded === 'departmentHeadcount'}
                setExpanded={() =>
                  setExpanded(
                    expanded === 'departmentHeadcount'
                      ? null
                      : 'departmentHeadcount',
                  )
                }
              />
              <SearchAccordionRangesInput
                currentValue={
                  values.departmentHeadcountGrowth?.department ?? null
                }
                showMainSelect={true}
                placeholder="Type department..."
                options={LinkedinDepartmentMap}
                displayLabel="Dept headcount growth"
                id="departmentHeadcountGrowthAccordion"
                onChange={({ newLabel, valueToChange }: ValuesToOnChange) =>
                  handleChangeRangeInputs(
                    newLabel,
                    valueToChange,
                    'departmentHeadcountGrowth',
                    'department',
                  )
                }
                icon={<DeptHeadcountGrowth />}
                currentMin={values.departmentHeadcountGrowth?.min}
                currentMax={values.departmentHeadcountGrowth?.max}
                unitOfMeasureLabel="%"
                expanded={expanded === 'departmentHeadcountGrowth'}
                setExpanded={() =>
                  setExpanded(
                    expanded === 'departmentHeadcountGrowth'
                      ? null
                      : 'departmentHeadcountGrowth',
                  )
                }
              />
              <SearchAccordionMultipleAutocomplete
                displayLabel="Technologies used"
                currentValue={values.technologyUsed ?? []}
                onChange={(e, selectedCompanySizes) =>
                  handleChangeFormik('technologyUsed', selectedCompanySizes)
                }
                placeholder="Type technology..."
                id="companyTechnologyUsed"
                icon={<TechnologyIcon />}
                handleSelectSingle={(technologyUsed: LinkedinFilterValue) => {
                  if (!values.technologyUsed) return;
                  handleChangeFormik(
                    'technologyUsed',
                    values.technologyUsed.filter(
                      (cs) => cs.id !== technologyUsed.id,
                    ) || [],
                  );
                }}
                expanded={expanded === 'technologyUsed'}
                setExpanded={() =>
                  setExpanded(
                    expanded === 'technologyUsed' ? null : 'technologyUsed',
                  )
                }
                filterType={{
                  type: 'salesNav',
                  filterName: 'TECHNOLOGIES_USED',
                }}
              />
            </>
          ) : (
            <>
              <SearchAccordionMultipleAutocomplete
                currentValue={values.geoUrn ?? null}
                displayLabel="Location"
                id="geoUrnSelect"
                placeholder="Type city/country name..."
                onChange={(e, label) => {
                  handleChangeFormik('geoUrn', label);
                }}
                handleSelectSingle={(geoUrn: LinkedinFilterValue) => {
                  if (!values.geoUrn) return;
                  handleChangeFormik(
                    'geoUrn',
                    values.geoUrn.filter((gu) => gu !== geoUrn) || [],
                  );
                }}
                icon={<LocationIcon />}
                expanded={expanded === 'geoUrn'}
                setExpanded={() =>
                  setExpanded(expanded === 'geoUrn' ? null : 'geoUrn')
                }
                disableTopBorder={true}
                filterType={{
                  type: 'linkedin',
                  filterName: 'GEO',
                }}
              />
              <SearchAccordionMultipleAutocomplete
                displayLabel="Company size"
                currentValue={values.companySize ?? []}
                options={linkedinNumberOfEmployees}
                onChange={(e, selectedCompanySizes) =>
                  handleChangeFormik('companySize', selectedCompanySizes)
                }
                placeholder="Type size range..."
                id="companySizeAutocomplete"
                icon={<ContactsIconS />}
                handleSelectSingle={(companySize: LinkedinFilterValue) => {
                  if (!values.companySize) return;
                  handleChangeFormik(
                    'companySize',
                    values.companySize.filter((cs) => cs !== companySize) || [],
                  );
                }}
                expanded={expanded === 'companySize'}
                setExpanded={() =>
                  setExpanded(expanded === 'companySize' ? null : 'companySize')
                }
              />
              <SearchAccordionMultipleAutocomplete
                displayLabel="Industry"
                currentValue={values.industry ?? []}
                onChange={(e, selectedIndustries) =>
                  handleChangeFormik('industry', selectedIndustries)
                }
                placeholder="Type keyword..."
                id="industryAutocomplete"
                icon={<IndustryIcon />}
                handleSelectSingle={(industry) => {
                  if (!values.industry) return;
                  handleChangeFormik(
                    'industry',
                    values.industry.filter((i) => i !== industry) || [],
                  );
                }}
                expanded={expanded === 'industry'}
                setExpanded={() =>
                  setExpanded(expanded === 'industry' ? null : 'industry')
                }
                filterType={{
                  type: 'linkedin',
                  filterName: 'INDUSTRY',
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="apply-filter-container">
        <MainButton
          fullWidth
          onClick={applyFilters}
          className={!filtersChanged ? 'apply-filters-disabled' : undefined}
          disabled={
            !filtersChanged || isDisabledExtension || disableApplyDueToNoFilters
          }
        >
          Apply filter
        </MainButton>
      </div>
    </div>
  );
};

export default SearchCompanyTabFilters;
