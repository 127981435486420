import { SuggestionPreferences } from 'crono-fe-common/types/suggestionPreferences';
import React from 'react';
import { SuggestionPreferencesWrapper } from './style';
import { TextField } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';

interface IProps {
  updateSuggestionPreferences: (
    name: keyof SuggestionPreferences,
    value: any,
  ) => void;
  parameters: SuggestionPreferences | null;
}

const SuggestionPreferencesDataQuality = ({
  updateSuggestionPreferences,
  parameters,
}: IProps) => {
  return (
    <>
      <SuggestionPreferencesWrapper>
        <CronoSwitch
          checked={!!parameters?.preconditionEnabled}
          onChange={(_) => {
            updateSuggestionPreferences(
              'preconditionEnabled',
              !parameters?.preconditionEnabled as boolean,
            );
          }}
        />
        <div
          className="container-text-suggestion"
          style={{
            color: !parameters?.preconditionEnabled
              ? colors.grey2
              : colors.black,
          }}
        >
          <div className="container-text-line" style={{ marginTop: 6 }}>
            Add contact information if missing/invalid but required for future
            tasks
          </div>
        </div>
      </SuggestionPreferencesWrapper>
      <SuggestionPreferencesWrapper>
        <CronoSwitch
          checked={!!parameters?.clearDataEnabled}
          onChange={(_) => {
            updateSuggestionPreferences(
              'clearDataEnabled',
              !parameters?.clearDataEnabled as boolean,
            );
          }}
        />
        <div
          className="container-text-suggestion"
          style={{
            color: !parameters?.clearDataEnabled ? colors.grey2 : colors.black,
          }}
        >
          <div className="container-text-line" style={{ marginTop: 6 }}>
            Change contact information if resulted invalid (es. invalid email)
            and was not
          </div>
          <div className="container-text-line">
            changed in the last
            {(parameters?.clearDataDays ?? -1) >= 0 && (
              <>
                <TextField
                  defaultValue={parameters?.clearDataDays}
                  type="number"
                  className="day-picker-preferences"
                  onChange={(ev) => {
                    if (
                      ev.target.value &&
                      !Number.isNaN(Number(ev.target.value))
                    ) {
                      updateSuggestionPreferences(
                        'clearDataDays',
                        ev.target.value,
                      );
                    }
                  }}
                  InputProps={{
                    sx: {
                      height: 30,
                      width: 50,
                      marginInline: 1,
                      borderRadius: 2,
                      color: 'inherit',
                      '&:hover': {
                        background: colors.grey6,
                      },
                      '& fieldset': {
                        border: `1px solid ${colors.grey444}`,
                      },
                      '&:hover fieldset': {
                        border: `1px solid ${colors.grey5}`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `1px solid ${colors.grey5}`,
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      border: 'none',
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '18px',
                    },
                  }}
                />
                days
              </>
            )}
          </div>
        </div>
      </SuggestionPreferencesWrapper>
      <SuggestionPreferencesWrapper>
        <CronoSwitch
          checked={!!parameters?.suggestNewCompanyEnabled}
          onChange={(_) => {
            updateSuggestionPreferences(
              'suggestNewCompanyEnabled',
              !parameters?.suggestNewCompanyEnabled as boolean,
            );
          }}
        />
        <div
          className="container-text-suggestion"
          style={{
            color: !parameters?.suggestNewCompanyEnabled
              ? colors.grey2
              : colors.black,
          }}
        >
          <div className="container-text-line" style={{ marginTop: 6 }}>
            Update contact information when moving to different company
          </div>
        </div>
      </SuggestionPreferencesWrapper>
    </>
  );
};

export default SuggestionPreferencesDataQuality;
