import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { ChoosePlanContainer, PlanWrapper } from './style';
import { SubscriptionProductDTO } from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';
import { BillingPlan } from 'pages/signup/model';
import { PlansCarousel } from './plansCarousel';
import { Options } from 'pages/signup';
import BillingSwitch from './billingSwitch';

interface IProps {
  formik: any;
  subscriptionProducts: SubscriptionProductDTO[] | undefined;
  close: () => void;
  billingPlan: BillingPlan;
  setBillingPlan: React.Dispatch<React.SetStateAction<BillingPlan>>;
  setSelectedPlan: React.Dispatch<React.SetStateAction<number>>;
  options: Options[];
  subscription: string | undefined;
  pricing: string | undefined;
  selectedPlan: number;
}

const Plans = ({
  formik,
  subscriptionProducts,
  setBillingPlan,
  setSelectedPlan,
  options,
  selectedPlan,
  billingPlan,
}: IProps) => {
  useEffect(() => {
    if (formik && options[selectedPlan]) {
      const { id, monthlyTotalCycle } = options[selectedPlan].prices.filter(
        (price) => price.paymentCycle === billingPlan,
      )[0];
      formik.setFieldValue('plan', options[selectedPlan]?.subscriptionType);
      formik.setFieldValue('billed', billingPlan);
      formik.setFieldValue('priceId', id);
      formik.setFieldValue('price', monthlyTotalCycle);
    }
  }, [selectedPlan, billingPlan, subscriptionProducts]);

  return (
    <ChoosePlanContainer id="plans-container">
      <PlanWrapper id="inner">
        <div className="center-container">
          <div className="plan-container">
            <div className="suggestion-container">
              <div className="title">
                <Typography
                  fontSize={'28px'}
                  fontWeight={700}
                  lineHeight={'30px'}
                  textAlign={'center'}
                >
                  1. Choose plan
                </Typography>
              </div>
              <BillingSwitch
                options={options}
                selectedPlan={selectedPlan}
                billingPlan={billingPlan}
                setBillingPlan={setBillingPlan}
              />
            </div>
            <PlansCarousel
              billingPlan={billingPlan}
              setSelectedPlan={setSelectedPlan}
              options={options}
              selectedPlan={selectedPlan}
            />
          </div>
        </div>
      </PlanWrapper>
    </ChoosePlanContainer>
  );
};

export default Plans;
