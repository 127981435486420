import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { TemplateSearch } from 'crono-fe-common/types/DTO/templateSearch';
import { Template } from 'crono-fe-common/types/template';

export default function useSearchTemplates(searchParameters: TemplateSearch) {
  const request: Request = {
    url: `${ENDPOINTS.template.search}`,
    config: {
      method: 'post',
      data: { data: searchParameters },
    },
  };
  const stringifiedSearchParameters = JSON.stringify(searchParameters);
  const requestConfig = {
    queryKey: [ENDPOINTS.template.search, stringifiedSearchParameters],

    queryFn: () => client(request),
  };

  return useQuery<ResponsePaginated<Template[]>, CronoError>(requestConfig);
}
