import {
  TableRow,
  TableCell,
  ClickAwayListener,
  Checkbox,
  Avatar,
  Tooltip,
  Typography,
  Badge,
} from '@mui/material';
import TagsBoxSingleTag from 'components/TagsBoxShowAll/tagsBoxSingleTag';
import { useWindow } from 'context/sideTab';
import { WindowTab } from 'context/sideTab/types';
import { useConditionalSnackBar } from 'context/snackbar';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { Prospect } from 'crono-fe-common/types/prospect';
import { isComplete } from 'crono-fe-common/types/sequenceInstance';
import {
  getError,
  getOptionsMultiselect,
  setOptionsMultiselect,
} from 'crono-fe-common/utils';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import useStopProspectSequence from 'hooks/services/sequence/useStopProspectSequence';
import ActivitiesFromTotals from 'pages/dashboard/activitiesFromTotals';
import { useSelectedAccount } from 'pages/home/context/account';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getImageFromUrl,
  stringAvatarProspect,
  prospectName,
  extractDomainFromWebsite,
} from 'utils/fe-utils';
import { CustomTooltip, IInformationForSelectedScrapeMissing } from '..';
import SequenceButton from '../SequenceButton';
import LastActivityProspect from '../components/lastActivity';
import ProgressProspect from '../components/progress';
import ResumeSequence from '../resumeSequence';
import SequenceSelector from '../sequenceSelector';
import { colors } from 'crono-fe-common/theme';
import { User } from 'crono-fe-common/types/user';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { ReactComponent as LightBulbIcon } from '../../../../assets/images/Light-bulb.svg';
import { ReactComponent as NotOwnedIcon } from 'crono-fe-common/icons/Not-owned-by-the-user.svg';
import CronoStatus from 'components/CronoStatus';
import { useJuneAnalytics } from 'context/june';
import { SequenceStatusTypeDTO } from 'crono-fe-common/types/enums/prospectSequenceStatus';
import { SequenceInstanceStatus } from 'crono-fe-common/types/enums/sequenceInstanceStatus';
import FindEmailContactSearch from 'pages/searchComponent/searchContactTab/contactInsertCard/findEmailContactSearch';
import FindEmailProspectRow from './findEmailProspectRow';
import VerificationStatusType from 'crono-fe-common/types/enums/verificationStatusType';
import FindPhoneProspectRow from './findPhoneProspectRow';
import { KeyboardReturnOutlined } from '@mui/icons-material';
import { useAuth } from 'context/auth';

interface IProps {
  prospect: Prospect;
  externalProperties: ExternalProperty[] | undefined;
  handleSelected: (
    prospectId: string,
    accountId: string,
    additionalInformation: IInformationForSelectedScrapeMissing,
    inSequence: boolean,
    sequenceStopped: boolean,
  ) => void;
  selected: boolean;
  onlyOwned: boolean;
  handleShowSequenceInstance: (
    sequenceInstanceId: number,
    prospect: Prospect,
  ) => void;
  disableCheckbox?: boolean;
  alreadySearchingEmail: boolean;
  setAlreadySearchingEmail: React.Dispatch<React.SetStateAction<boolean>>;
  alreadySearchingPhone: boolean;
  setAlreadySearchingPhone: React.Dispatch<React.SetStateAction<boolean>>;
  enrichingEmailInProgress: boolean;
  enrichingPhoneInProgress: boolean;
}

const ProspectRow = ({
  prospect,
  externalProperties,
  selected,
  handleSelected,
  onlyOwned,
  handleShowSequenceInstance,
  disableCheckbox,
  alreadySearchingEmail,
  alreadySearchingPhone,
  setAlreadySearchingEmail,
  setAlreadySearchingPhone,
  enrichingEmailInProgress,
  enrichingPhoneInProgress,
}: IProps) => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const { mutate: deleteSequence, error: errorStopProspectSequence } =
    useStopProspectSequence();

  useConditionalSnackBar([
    {
      condition: !!errorStopProspectSequence,
      message:
        getError(errorStopProspectSequence) ??
        'Error stopping prospect strategy',
      severity: 'error',
    },
  ]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const analytics = useJuneAnalytics();
  const stopSequence = (prospectId: string, sequenceInstanceId: number) => {
    deleteSequence({ sequenceInstanceId, prospectId });
    if (analytics) {
      analytics.track('stop-sequence', {});
    }
  };

  // I get the function to open the window when an account is selected
  const { openWindow } = useWindow();

  const { setSelectedAccountId, setSelectedProspectId, selectedProspectId } =
    useSelectedAccount();

  const { mutate: patchProspect } = useEditProspect();

  // Called when a prospect is selected
  const handleClick = (
    objectId: string,
    accountId: string,
    tab?: WindowTab,
  ) => {
    setSelectedAccountId(accountId);
    setSelectedProspectId(objectId);
    openWindow({
      windowType: 'account',
      tab: tab ?? 'prospect',
      selectedProspectId: objectId,
      forceReload: Math.random() * 1000000,
    });
  };

  // Called when an account is selected
  const handleClickActivities = (objectId: string, tab?: WindowTab) => {
    setSelectedAccountId(objectId);
    openWindow({
      windowType: 'account',
      tab: tab ?? 'account',
      forceReload: Math.random() * 1000000,
    });
  };

  const [backgroundColorSelected, setBackgroundColorSelected] =
    useState<string>(colors.white);

  useEffect(() => {
    if (selectedProspectId === prospect.objectId) {
      setBackgroundColorSelected(`${colors.grey55}`);
    } else {
      setBackgroundColorSelected(colors.white);
    }
  }, [selectedProspectId]);

  const handlePatchProspect = (
    option: string | null,
    externalPropertyId: number,
  ) => {
    if (!prospect) return;

    patchProspect({
      prospectId: prospect.objectId,
      accountId: prospect.accountId,
      externalValues: {
        [externalPropertyId]: option,
      },
    });
  };

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
  ) => {
    const externalPropertyId = externalProperty.id;

    const externalValue = prospect.externalValues?.find(
      (externalValue) =>
        externalValue.externalPropertyId === externalProperty.id,
    );
    if (option === null) {
      handlePatchProspect(null, externalPropertyId);
      return;
    }
    if (externalProperty?.valueType === 'List') {
      handlePatchProspect(
        option === externalValue?.value ? null : option,
        externalPropertyId,
      );
    } else if (externalProperty?.valueType === 'MultiSelect') {
      const options = getOptionsMultiselect(externalValue?.value ?? null);
      if (options.includes(option ?? '')) {
        options.splice(options.indexOf(option ?? ''), 1);
      } else {
        options.push(option ?? '');
      }
      handlePatchProspect(
        options.length ? setOptionsMultiselect(options) : null,
        externalPropertyId,
      );
    }
  };

  const showTableHead = useCallback(
    (columnId: string) => {
      if (!user) return false;
      if (!user.userPreferences.listProspectColumns) return true;
      if (user.userPreferences.listProspectColumns.includes(columnId))
        return true;
    },
    [user],
  );

  return (
    <TableRow
      style={{
        background: backgroundColorSelected,
        height: 50,
      }}
      onMouseEnter={(ev) => {
        if (selectedProspectId !== prospect.objectId) {
          setBackgroundColorSelected(colors.tableHover);
          ev.stopPropagation();
          ev.preventDefault();
        }
      }}
      onMouseLeave={(ev) => {
        if (selectedProspectId !== prospect.objectId) {
          setBackgroundColorSelected(colors.white);
          ev.stopPropagation();
          ev.preventDefault();
        }
      }}
    >
      <TableCell
        onClick={(ev) => {
          handleClick(prospect.objectId, prospect.accountId);
        }}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 8,
          background: backgroundColorSelected,
          width: 250,
          minWidth: 250,
          paddingRight: 8,
          paddingLeft: 4,
          cursor: 'pointer',
          paddingBlock: 4,
          borderLeft:
            selectedProspectId === prospect.objectId
              ? `4px solid ${colors.black}`
              : 'none',
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // width: "170px"
          }}
        >
          <label>
            <Checkbox
              checked={selected}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleSelected(
                  prospect.objectId,
                  prospect.accountId,
                  {
                    domain: extractDomainFromWebsite(
                      prospect.account?.website ?? '',
                    ),
                    firstName: prospect.firstName ?? null,
                    lastName: prospect.lastName ?? null,
                    linkedinUrl: prospect.linkedin ?? null,
                    prospectId: prospect.objectId,
                  },
                  (prospect.owned &&
                    prospect.sequenceInstance &&
                    !isComplete(prospect.sequenceInstance) &&
                    prospect.sequenceInstance.active) ??
                    false,
                  (prospect.owned &&
                    prospect.sequenceInstance &&
                    !isComplete(prospect.sequenceInstance) &&
                    !prospect.sequenceInstance.active) ??
                    false,
                );
              }}
              disabled={disableCheckbox}
              icon={
                <IconUnchecked
                  color={disableCheckbox ? colors.grey4 : colors.grey3}
                />
              }
              checkedIcon={<CheckedIcon />}
              color="secondary"
              sx={{
                padding: 'inherit',
                margin: 'inherit',
                '&:hover': {
                  backgroundColor: colors.grey4,
                },
                '&.Mui-checked': {
                  '&:hover': {
                    opacity: 0.7,
                  },
                },
                marginRight: 1,
                marginLeft: 1,
                // "&.MuiCheckbox-root": {
                //   color: colors.grey33 + " !important",
                // },
              }}
            />
          </label>
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                right: 6,
                top: 6,
                background: colors.nurture,
                height: '16px',
                width: '16px',
                minWidth: '16px',
                minHeight: '16px',
                padding: '1px',
                border: `1px solid ${colors.white}`,
              },
            }}
            badgeContent={
              !prospect.suggestions?.length ? null : (
                <LightBulbIcon
                  onClick={(ev: any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    handleClick(prospect.objectId, prospect.accountId, 'tasks');
                  }}
                />
              )
            }
          >
            <Avatar
              key={prospect.objectId}
              src={
                prospect?.account?.website &&
                getImageFromUrl(prospect.account.website, null)
              }
              className="prospect-avatar-row"
            >
              {stringAvatarProspect(prospect)}
            </Avatar>
          </Badge>
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '170px',
              marginLeft: 8,
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip
                arrow
                title={prospectName(prospect)}
                enterDelay={800}
                enterNextDelay={800}
              >
                <span
                  style={{
                    display: 'inline-block',
                    flexDirection: 'row',
                    // width: 125,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    margin: 0,
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  {prospectName(prospect)}
                </span>
              </Tooltip>
              {user?.company?.integrationType === IntegrationType.SALESFORCE &&
                !prospect.fromContact && (
                  <span className="lead-badge">Lead</span>
                )}
            </span>
            <Tooltip
              arrow
              title={prospect.title}
              enterDelay={800}
              enterNextDelay={800}
            >
              <span
                style={{
                  width: 170,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  margin: 0,
                  fontSize: '12px',
                }}
              >
                {prospect.title}
              </span>
            </Tooltip>
          </span>
        </span>
      </TableCell>

      {/*Start/stop sequence */}
      <TableCell
        style={{
          position: 'sticky',
          left: 250,
          zIndex: 8,
          background: backgroundColorSelected,
          borderRight: `1px solid ${colors.grey444}`,
          overflow: 'hidden',
          paddingBlock: 4,
          paddingInline: 0,
        }}
      >
        {prospect.owned ? (
          prospect.sequenceInstance &&
          !isComplete(prospect.sequenceInstance) ? (
            prospect.sequenceInstance.active ? (
              /*If there is an active sequanceInstance */
              <Tooltip arrow title="Stop strategy" placement="top">
                <SequenceButton
                  onClick={() => {
                    if (prospect.sequenceInstance)
                      stopSequence(
                        prospect.objectId,
                        prospect.sequenceInstance.id,
                      );
                  }}
                  action="stop"
                  size="small"
                />
              </Tooltip>
            ) : (
              /*If there is an inactive sequanceInstance */
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <CustomTooltip
                    title={
                      <ResumeSequence
                        prospect={prospect}
                        //This has to be passed in order to set the state to false after the action performed
                        //without this the tooltip would open again after the next click on the pause button
                        setOpenTooltip={setOpen}
                        addSequenceOrientation="right"
                      />
                    }
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <SequenceButton
                      onClick={handleTooltipOpen}
                      action="start"
                      size="small"
                    />
                  </CustomTooltip>
                </div>
              </ClickAwayListener>
            )
          ) : (
            /*If there is no sequanceInstance */
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <CustomTooltip
                  title={
                    <SequenceSelector
                      prospectId={prospect.objectId}
                      accountId={prospect.accountId}
                      setOpenTooltip={setOpen}
                      callOnSucces={() => setOpen(false)}
                    />
                  }
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <SequenceButton
                    onClick={handleTooltipOpen}
                    action="add"
                    size="small"
                  />
                </CustomTooltip>
              </div>
            </ClickAwayListener>
          )
        ) : (
          /*If the prospect is not owned */
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tooltip
              arrow
              title={`The owner is ${prospect.user}`}
              placement="top"
            >
              <NotOwnedIcon />
            </Tooltip>
          </div>
        )}
      </TableCell>

      {/*Company name*/}
      {showTableHead('companyName') && (
        <TableCell
          style={{
            width: 150,
            minWidth: 150,
            margin: 0,
            paddingBlock: 4,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                width: 120,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                margin: 0,
                cursor: 'pointer',
              }}
              onClick={() =>
                handleClick(prospect.objectId, prospect.accountId, 'account')
              }
            >
              <Tooltip
                arrow
                title={prospect.account?.name}
                enterDelay={800}
                enterNextDelay={800}
              >
                <Typography fontSize={'14px'} fontWeight={500} noWrap>
                  {prospect.account?.name}
                </Typography>
              </Tooltip>
            </span>
          </span>
        </TableCell>
      )}

      {/*Status */}
      {showTableHead('status') && (
        <TableCell
          style={{
            paddingBlock: 4,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <CronoStatus
            type="prospect"
            currentActualStatus={prospect.actualStatus}
            currentExternalValues={prospect.externalValues}
            objectToUpdate={prospect.objectId}
            fixedWidth={true}
            disabled={!prospect.owned}
          />
        </TableCell>
      )}

      {/*Email */}
      {showTableHead('email') && (
        <FindEmailProspectRow
          alreadySearching={alreadySearchingEmail}
          setAlreadySearching={setAlreadySearchingEmail}
          enrichingEmailInProgress={enrichingEmailInProgress}
          account={prospect.account}
          prospect={prospect}
        />
      )}

      {/*Phone */}
      {showTableHead('phone') && (
        <FindPhoneProspectRow
          alreadySearching={alreadySearchingPhone}
          setAlreadySearching={setAlreadySearchingPhone}
          enrichingPhoneInProgress={enrichingPhoneInProgress}
          account={prospect.account}
          prospect={prospect}
        />
      )}

      {/* <FindEmailProspectRow 
        alreadySearching={alreadySearchingEmail}
        setAlreadySearching={setAlreadySearchingEmail}

      /> */}

      {/*Owner */}
      {!onlyOwned && showTableHead('owner') && (
        <TableCell
          style={{
            paddingBlock: 4,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                width: 110,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {prospect.user}
            </span>
          </span>
        </TableCell>
      )}

      {/*ExternalProperties */}
      {externalProperties?.map((externalProperty, index) => {
        // I get the externalValue of the prospect that matches the name of the externalProperty
        const externalValue = prospect.externalValues?.find(
          (externalValue) =>
            externalValue.externalPropertyId === externalProperty.id,
        );

        if (showTableHead(String(externalProperty.id))) {
          return (
            <TableCell
              key={index}
              style={{
                paddingBlock: 4,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <TagsBoxSingleTag
                indexOfTag={index}
                isEditable={externalProperty.isEditable && prospect.owned}
                option={externalValue?.value ?? null}
                tag={externalProperty}
                handleClickTag={(option, externalProperty, index) =>
                  handleClickTag(option, externalProperty)
                }
                indexGeneralArray={index}
                showRemovable={true}
                showAsPlus={true}
                show2AndPlus={true}
              />
            </TableCell>
          );
        }

        return null;
      })}

      {/*Progress */}
      {showTableHead('progress') && (
        <TableCell
          style={{
            paddingBlock: 4,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          {prospect.sequenceInstance?.active && prospect.owned ? (
            <ProgressProspect
              prospect={prospect}
              onClick={() => {
                if (
                  prospect.sequenceInstance?.id &&
                  prospect.sequenceInstance?.active
                )
                  handleShowSequenceInstance(
                    prospect.sequenceInstance.id,
                    prospect,
                  );
              }}
            />
          ) : (
            <Tooltip
              arrow
              title={
                prospect.owned
                  ? prospect.sequenceInstance?.status ===
                    SequenceInstanceStatus.Completed
                    ? 'Strategy completed'
                    : 'Strategy paused'
                  : 'You are not the owner of this prospect'
              }
              placement="top-start"
            >
              <ProgressProspect prospect={prospect} />
            </Tooltip>
          )}
        </TableCell>
      )}

      {showTableHead('lastActivity') && (
        <TableCell
          style={{
            paddingBlock: 4,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <LastActivityProspect prospect={prospect} />
        </TableCell>
      )}

      {showTableHead('createdDate') && (
        <TableCell
          style={{
            paddingBlock: 4,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                width: 110,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {prospect.createdDate &&
                new Date(prospect.createdDate).toLocaleDateString()}
            </span>
          </span>
        </TableCell>
      )}

      {showTableHead('activities') && (
        <TableCell
          onClick={() => handleClickActivities(prospect.accountId)}
          style={{
            paddingBlock: 4,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <ActivitiesFromTotals
            data={prospect}
            className="activities-container"
            color={colors.grey11}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default ProspectRow;
