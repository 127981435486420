import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AnalyticsSequenceDetailsFilter } from 'crono-fe-common/types/analyticsFilters';
import { AnalyticsSequenceDetails } from 'crono-fe-common/types/analyticsSequenceDetails';

export default function useSequenceDetailsAnalytics(
  analyticsFilters: AnalyticsSequenceDetailsFilter,
  enabled = true,
) {
  const params: any = {};

  if (analyticsFilters.accountId) {
    params.accountId = analyticsFilters.accountId;
  }

  if (analyticsFilters.userId) {
    params.userId = analyticsFilters.userId;
  }

  if (analyticsFilters.since) {
    params.since = analyticsFilters.since;
  }

  if (analyticsFilters.to) {
    params.to = analyticsFilters.to;
  }

  if (analyticsFilters.strategyId) {
    params.strategyId = analyticsFilters.strategyId;
  }

  if (analyticsFilters.groupBy) {
    params.groupBy = analyticsFilters.groupBy;
  }

  const request: Request = {
    url: ENDPOINTS.analytics.sequence.detail,
    config: {
      method: 'post',
      data: params,
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.analytics.sequence.detail, params],
    queryFn: () => client(request),
    enabled,
  };

  const { data, ...rest } = useQuery<
    Response<AnalyticsSequenceDetails>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
