import { useEffect } from 'react';
import useVerifyGoogleIntegration from 'hooks/services/google/useVerifyGoogleIntegration';
import { useAuth } from 'context/auth';
import useVerifyOutlookIntegration from 'hooks/services/outlook/useVerifyGoogleIntegration';
import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';
import useVerifyCustomEmailProviderIntegration from 'hooks/services/customEmailIntegration/useVerifyCustomEmailProviderIntegration';

const useEmailIntegrationGuard = () => {
  const { user } = useAuth();
  const {
    isLoading: isLoadingGoogle,
    isError: isErrorGoogle,
    isSuccess: isSuccessGoogle,
    verifyGoogleIntegration,
    error: errorGoogle,
    data: dataGoogle,
  } = useVerifyGoogleIntegration();

  const {
    isLoading: isLoadingOutlook,
    isError: isErrorOutlook,
    isSuccess: isSuccessOutlook,
    verifyOutlookIntegration,
    error: errorOutlook,
    data: dataOutlook,
  } = useVerifyOutlookIntegration();

  const {
    isLoading: isLoadingCustomEmailProvider,
    isError: isErrorCustomEmailProvider,
    isSuccess: isSuccessCustomEmailProvider,
    verifyCustomEmailProviderIntegration,
    error: errorCustomEmailProvider,
    data: dataCustomEmailProvider,
  } = useVerifyCustomEmailProviderIntegration();

  useEffect(() => {
    if (user && user.otherSettings?.hasEmail) {
      if (user.company?.emailIntegrationType === EmailIntegrationType.GMAIL) {
        verifyGoogleIntegration();
      } else if (
        user.company?.emailIntegrationType === EmailIntegrationType.OUTLOOK
      ) {
        verifyOutlookIntegration();
      } else if (
        user.company?.emailIntegrationType === EmailIntegrationType.CUSTOM
      ) {
        verifyCustomEmailProviderIntegration();
      }
    }
  }, [user]);

  const verifyIntegration = () => {
    if (user && user.otherSettings?.hasEmail) {
      if (user.company?.emailIntegrationType === EmailIntegrationType.GMAIL) {
        verifyGoogleIntegration();
      } else if (
        user.company?.emailIntegrationType === EmailIntegrationType.OUTLOOK
      ) {
        verifyOutlookIntegration();
      } else if (
        user.company?.emailIntegrationType === EmailIntegrationType.CUSTOM
      ) {
        verifyCustomEmailProviderIntegration();
      }
    }
  };

  return {
    isLoading:
      isLoadingGoogle || isLoadingOutlook || isLoadingCustomEmailProvider,
    isError: isErrorGoogle || isErrorOutlook || isErrorCustomEmailProvider,
    isSuccess:
      isSuccessGoogle || isSuccessOutlook || isSuccessCustomEmailProvider,
    error: errorGoogle || errorOutlook || errorCustomEmailProvider,
    data: dataGoogle || dataOutlook || dataCustomEmailProvider,
    verifyIntegration: verifyIntegration,
    // isLoadingGoogle,
    // isSuccessGoogle,
    // isErrorGoogle,
    // verifyGoogleIntegration,
    // errorGoogle,
    // isLoadingOutlook,
    // isSuccessOutlook,
    // isErrorOutlook,
    // verifyOutlookIntegration,
    // errorOutlook,
  };
};

export default useEmailIntegrationGuard;
