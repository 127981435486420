import styled from '@emotion/styled';
import { MenuItem, MenuItemProps, Select, SelectProps } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import React from 'react';

//This doesn't work when the select is inside a position absolute container
//seems like a conflict between disablePortal true, needed to apply the styles, and the absolute position

const StyledSelect = styled(Select)``;

export const CronoSelect = React.forwardRef((props: SelectProps, ref: any) => {
  const { MenuProps, variant, ...rest } = props;
  const { PaperProps, ...menuProps } = MenuProps || {};
  return (
    <StyledSelect
      ref={ref}
      {...rest}
      MenuProps={{
        ...menuProps,
        style: { zIndex: 35001 },
        PaperProps: {
          ...PaperProps,
          style: {
            borderRadius: '8px',
            paddingInline: '6px',
            border: '1px solid #dadde9',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
          },
        },
      }}
    />
  );
});

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 6px;
  &:hover {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
  }
  &:hover > svg > path {
    stroke: ${colors.primary} !important;
  }
  &.Mui-selected {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    &:hover {
      background: ${colors.primaryLightHover};
    }
  }
  &.Mui-selected > svg > path {
    stroke: ${colors.primary} !important;
  }
`;

export const CronoMenuItem = (props: MenuItemProps) => {
  return <StyledMenuItem {...props} />;
};
