import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const CheckYourEmailWrapper = styled('div')`
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  img {
    width: 187px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;

    h2 {
      font-size: 28px;
      margin: 0;
    }

    p {
      margin-top: 5px;
      font-size: 14px;
      color: ${colors.grey11};
      margin-bottom: 0;
    }

    p:last-child {
      color: ${colors.darkGreen};
    }
  }

  button {
    margin: 10px 0 0 0;
  }

  .go-back {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 20px;
    cursor: pointer;
    background-color: ${colors.primaryLight};
  }
  .go-back:hover {
    background-color: ${colors.primaryLightHover};
  }
`;
