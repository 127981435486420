import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SyncStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background: ${colors.white};
  padding: 24px;
  border-radius: 16px;
  .close-button {
    cursor: pointer;
  }
  .add-tag-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    row-gap: 24px;
  }
  .options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .tabs-tag-container {
    display: flex;
    column-gap: 16px;
  }
  .tab-option {
    display: flex;
    align-items: center;
  }
  .options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    max-height: 300px;
    overflow-y: auto;
  }
  .insert-value-text-field {
    width: 100%;
    display: flex;
  }
  .manage-value-buttons-container {
    column-gap: 6px;
  }
  .manage-value-button {
    height: 32px;
    width: 32px;
    padding: 3px;
  }
  .alert-property-already-used-as-tag {
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 16px 10px 16px 16px;
    gap: 8px;
    background: ${colors.inactiveLight};
    align-items: center;
    .warning-icon-already-used {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 50%;
      background: ${colors.inactive};
    }
  }
`;
