import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type SiteFreeTrialInput = {
  // quantity: number;
  email: string;
  signupToken: string;
};

export default function useStartFreeTrial() {
  const callSiteFreeTrial = (input: SiteFreeTrialInput) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.startFreeTrial}`,
      config: {
        method: 'POST',
        data: input,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<string>,
    Error,
    SiteFreeTrialInput
  >(callSiteFreeTrial, {});

  return { data: data?.data, mutate, ...rest };
}
