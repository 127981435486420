import styled from 'styled-components';
import { colors } from 'crono-fe-common/theme';
import { centerFlexColumn } from 'styles/mixins';

export const SignatureGeneralFormWrapper = styled.form`
  #editor-wrapper,
  .html-div-container {
    width: 700px;
    height: 270px;
  }

  .html-div-container {
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${colors.grey444};
    background-color: ${colors.grey6};
    padding: 12px;
    display: flex;
    border-radius: 16px;

    overflow: auto;
  }

  .html-div {
    text-align: left;
    width: 100%;
    height: 100%;
    background-color: ${colors.grey6};
    overflow: auto;

    font-family: 'Poppins';
    p {
      font-size: 13px;
      margin: 0;
    }

    &:focus {
      outline: none;
    }
  }

  .editor-container {
    border: 1px solid ${colors.grey444};
    position: relative;
    background: ${colors.grey6};
    border-radius: 16px;
    height: 300px;
    max-width: 702px;

    //the editor
    & > div:first-child {
      .ql-toolbar {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
      .ql-container {
        border-radius: 0;
        border: none;
      }
    }
  }

  .ql-picker-options {
    text-align: left;
  }
`;

export const SignatureCreateViewWrapper = styled.div`
  ${centerFlexColumn};
  gap: 40px;
  height: 100%;
  width: 100%;
  text-align: center;

  .cards-container {
    display: flex;
    gap: 24px;
    height: 288px;
  }

  .buttons-container {
    display: flex;
    gap: 8px;

    button {
      margin: 0;
      width: 96px;
    }
  }
`;

export const ExistingSignatureViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    gap: 32px;
    width: 100%;

    .text-container {
      display: flex;
      flex-direction: column;
      width: 550px;
      gap: 18px;
    }
  }

  .html-div-container {
    height: 300px;
  }

  #editor-wrapper {
    height: 240px;
  }

  .html-div {
    height: 220px;
  }

  .left-control-buttons {
    position: absolute;
    bottom: 12px;
    left: 12px;
    display: flex;
    gap: 4px;

    button {
      height: 40px;
      margin: 0;
    }
    .reload-signature-button {
      background: ${colors.mainDark};
      &:hover {
        background: ${colors.mainDark}cc;
      }
    }
  }

  .control-buttons {
    position: absolute;
    bottom: 12px;
    right: 12px;
    display: flex;
    gap: 4px;

    button {
      height: 40px;
      margin: 0;
    }
  }
`;
