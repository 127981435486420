import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinInvitationIcon = ({
  color = colors.blueHover,
  className,
  onClick,
  style,
  viewBox = '0 0 17 17',
  fill = colors.linkedinRepliedMessageLightBlue,
}: IProps) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        id="linkedin_invitation_letter"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08595 5.83295H8.12865V6.85045C8.4229 6.26525 9.1775 5.73945 10.3111 5.73945C12.4841 5.73945 13 6.90435 13 9.04165V10.0142V13H10.8V10.0142V9.5284C10.8 8.31125 10.5058 7.62485 9.75665 7.62485C8.7177 7.62485 8.28595 8.3646 8.28595 9.52785V13H6.08595V5.83295ZM2.3135 12.9065H4.5135V5.73945H2.3135V12.9065ZM4.82865 3.4025C4.82873 3.5869 4.79216 3.76948 4.72106 3.93962C4.64997 4.10977 4.54576 4.26408 4.4145 4.3936C4.14852 4.65794 3.7885 4.80591 3.4135 4.805C3.03916 4.80475 2.67997 4.65716 2.4136 4.39415C2.28282 4.26419 2.17896 4.1097 2.10799 3.93954C2.03702 3.76937 2.00032 3.58687 2 3.4025C2 3.03015 2.1485 2.67375 2.41415 2.41085C2.68029 2.14749 3.03963 1.99984 3.41405 2C3.78915 2 4.14885 2.14795 4.4145 2.41085C4.6796 2.67375 4.82865 3.03015 4.82865 3.4025Z"
        fill={color}
      />
      <mask
        id="path-2-outside-1_4861_246114"
        maskUnits="userSpaceOnUse"
        x="7.5"
        y="8"
        width="9"
        height="9"
        fill="black"
      >
        <rect fill="white" x="7.5" y="8" width="9" height="9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0172 10.6929C13.0172 10.3102 12.7069 10 12.3242 10C11.9415 10 11.6313 10.3102 11.6313 10.6929V12.1322H10.1929C9.81024 12.1322 9.5 12.4424 9.5 12.8251C9.5 13.2078 9.81024 13.5181 10.1929 13.5181H11.6313V14.9572C11.6313 15.3399 11.9415 15.6501 12.3242 15.6501C12.7069 15.6501 13.0172 15.3399 13.0172 14.9572V13.5181H14.4572C14.8399 13.5181 15.1501 13.2078 15.1501 12.8251C15.1501 12.4424 14.8399 12.1322 14.4572 12.1322H13.0172V10.6929Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0172 10.6929C13.0172 10.3102 12.7069 10 12.3242 10C11.9415 10 11.6313 10.3102 11.6313 10.6929V12.1322H10.1929C9.81024 12.1322 9.5 12.4424 9.5 12.8251C9.5 13.2078 9.81024 13.5181 10.1929 13.5181H11.6313V14.9572C11.6313 15.3399 11.9415 15.6501 12.3242 15.6501C12.7069 15.6501 13.0172 15.3399 13.0172 14.9572V13.5181H14.4572C14.8399 13.5181 15.1501 13.2078 15.1501 12.8251C15.1501 12.4424 14.8399 12.1322 14.4572 12.1322H13.0172V10.6929Z"
        fill={color}
      />
      <path
        id="cross_outside_border"
        d="M11.6313 12.1322V13.1982C12.22 13.1982 12.6973 12.721 12.6973 12.1322H11.6313ZM11.6313 13.5181H12.6973C12.6973 12.9293 12.22 12.452 11.6313 12.452V13.5181ZM13.0172 13.5181V12.452C12.4284 12.452 11.9511 12.9293 11.9511 13.5181H13.0172ZM15.1501 12.8251L14.0841 12.8251V12.8251H15.1501ZM13.0172 12.1322H11.9511C11.9511 12.721 12.4284 13.1982 13.0172 13.1982V12.1322ZM12.3242 11.0661C12.1181 11.0661 11.9511 10.899 11.9511 10.6929H14.0832C14.0832 9.72147 13.2957 8.93394 12.3242 8.93394V11.0661ZM12.6973 10.6929C12.6973 10.899 12.5303 11.0661 12.3242 11.0661V8.93394C11.3528 8.93394 10.5652 9.72147 10.5652 10.6929H12.6973ZM12.6973 12.1322V10.6929H10.5652V12.1322H12.6973ZM10.1929 13.1982H11.6313V11.0661H10.1929V13.1982ZM10.5661 12.8251C10.5661 13.0312 10.399 13.1982 10.1929 13.1982V11.0661C9.22147 11.0661 8.43394 11.8537 8.43394 12.8251H10.5661ZM10.1929 12.452C10.399 12.452 10.5661 12.6191 10.5661 12.8251H8.43394C8.43394 13.7966 9.22147 14.5841 10.1929 14.5841V12.452ZM11.6313 12.452H10.1929V14.5841H11.6313V12.452ZM12.6973 14.9572V13.5181H10.5652V14.9572H12.6973ZM12.3242 14.5841C12.5303 14.5841 12.6973 14.7511 12.6973 14.9572H10.5652C10.5652 15.9286 11.3528 16.7162 12.3242 16.7162V14.5841ZM11.9511 14.9572C11.9511 14.7511 12.1182 14.5841 12.3242 14.5841V16.7162C13.2957 16.7162 14.0832 15.9286 14.0832 14.9572H11.9511ZM11.9511 13.5181V14.9572H14.0832V13.5181H11.9511ZM14.4572 12.452H13.0172V14.5841H14.4572V12.452ZM14.0841 12.8251C14.0841 12.6191 14.2511 12.452 14.4572 12.452V14.5841C15.4286 14.5841 16.2162 13.7966 16.2162 12.8251H14.0841ZM14.4572 13.1982C14.2511 13.1982 14.0841 13.0312 14.0841 12.8251L16.2162 12.8251C16.2162 11.8537 15.4286 11.0661 14.4572 11.0661V13.1982ZM13.0172 13.1982H14.4572V11.0661H13.0172V13.1982ZM11.9511 10.6929V12.1322H14.0832V10.6929H11.9511Z"
        fill={fill}
        mask="url(#path-2-outside-1_4861_246114)"
      />
      <path
        id="vertical_stroke"
        d="M12.3262 10.6929V14.9571"
        stroke={color}
        strokeWidth="1.38588"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="horizontal_stroke"
        d="M14.457 12.8252L10.1928 12.8252"
        stroke={color}
        strokeWidth="1.38588"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinInvitationIcon;
