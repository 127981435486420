import { CircularProgress, Typography } from '@mui/material';
import useGetUserCredits from 'hooks/services/user/useGetUserCredits';
import { FC, useEffect, useMemo, useState } from 'react';
import { GeneralWrapper } from '../styles';
import useGetSubscriptionProductsCredits from 'hooks/services/subscription/useGetSubscriptionProductsCredits';
import { ProductCreditsTypes } from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';
import AddOnsCard from './AddOnsCard';

const AddOns: FC = () => {
  const { data: userCredits, isLoading, isError } = useGetUserCredits();

  const memoizedUserCredits = userCredits?.data?.data ?? null;

  const { data: productsCredits } = useGetSubscriptionProductsCredits();

  const { templateCredits, phoneCredits, emailCredits } = useMemo(() => {
    let templateCredits = null;
    let phoneCredits = null;
    let emailCredits = null;

    if (productsCredits?.data?.data) {
      //Order in growing order
      const products = productsCredits?.data?.data.sort((a, b) => {
        return (a.price?.amount ?? 0) - (b.price?.amount ?? 0);
      });
      templateCredits =
        products.filter((el) => el.type === ProductCreditsTypes.TEMPLATE) ??
        null;

      phoneCredits =
        products.filter((el) => el.type === ProductCreditsTypes.PHONE) ?? null;

      emailCredits =
        products.filter((el) => el.type === ProductCreditsTypes.EMAIL) ?? null;
    }

    return { templateCredits, phoneCredits, emailCredits };
  }, [productsCredits]);

  return (
    <GeneralWrapper style={{ width: '100%' }}>
      <div className="add-ons-container">
        <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
          Add-ons
        </Typography>
        {isLoading && <CircularProgress />}
        {isError && <p>Can't get credits</p>}
        {userCredits?.data?.data && (
          <div className="user-form" style={{ width: '100%' }}>
            <AddOnsCard
              currentDate={memoizedUserCredits?.creditsRenewalDate ?? null}
              data={{
                bucketsCredits:
                  memoizedUserCredits?.emailBucketsCredits ?? null,
                bucketsTotal: memoizedUserCredits?.emailBucketsTotal ?? null,
                credits: memoizedUserCredits?.emailCredits ?? 0,
                total: memoizedUserCredits?.emailTotal ?? 0,
                left: memoizedUserCredits?.emailLeft ?? 0,
              }}
              products={emailCredits}
              type={ProductCreditsTypes.EMAIL}
            />
            <AddOnsCard
              currentDate={memoizedUserCredits?.creditsRenewalDate ?? null}
              data={{
                bucketsCredits:
                  memoizedUserCredits?.templateBucketsCredits ?? null,
                bucketsTotal: memoizedUserCredits?.templateBucketsTotal ?? null,
                credits: memoizedUserCredits?.assistantCredits ?? 0,
                total: memoizedUserCredits?.assistantTotal ?? 0,
                left: memoizedUserCredits?.assistantLeft ?? 0,
              }}
              products={templateCredits}
              type={ProductCreditsTypes.TEMPLATE}
            />
            <AddOnsCard
              currentDate={memoizedUserCredits?.creditsRenewalDate ?? null}
              data={{
                bucketsCredits:
                  memoizedUserCredits?.phoneBucketsCredits ?? null,
                bucketsTotal: memoizedUserCredits?.phoneBucketsTotal ?? null,
                credits: memoizedUserCredits?.phoneCredits ?? 0,
                total: memoizedUserCredits?.phoneTotal ?? 0,
                left: memoizedUserCredits?.phoneLeft ?? 0,
              }}
              products={phoneCredits}
              type={ProductCreditsTypes.PHONE}
            />
          </div>
        )}
      </div>
    </GeneralWrapper>
  );
};

export default AddOns;
