import { PlayArrowRounded } from '@mui/icons-material';
import { colors } from 'crono-fe-common/theme';
import { useState } from 'react';
import ReactPlayer from 'react-player';
interface IProps {
  handleClick?: () => void;
  title: string;
  width: string;
  height: string;
  src: string;
  frameborder?: string;
  style?: any;
}

const VideoPlayerPreview = ({
  title,
  width,
  height,
  src,
  frameborder,
  style,
  ...rest
}: IProps) => {
  const [playing, setPlaying] = useState<boolean>(false);

  const handleTogglePlay = () => setPlaying((prev) => !prev);
  return (
    <ReactPlayer
      url={src}
      light={true}
      playing={playing}
      controls
      width={width}
      height={height}
      style={{
        borderRadius: '8px',
      }}
      playIcon={
        <PlayArrowRounded
          onClick={handleTogglePlay}
          sx={{
            '&.MuiSvgIcon-root': {
              backgroundColor: colors.white,
              fill: colors.black,
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              padding: '6px',
              filter: 'drop-shadow(2px 4px 6px black)',
            },
            '&.MuiSvgIcon-root:hover': {
              backgroundColor: colors.grey444,
            },
          }}
        />
      }
    />
  );
};

export default VideoPlayerPreview;
