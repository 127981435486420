export class Constants {
  static highestZIndex = 999999999;
  static beforeHighestZIndex = 999999998;

  static accountPrefix = 'Account_';
  static linkedinBaseUrl = 'https://www.linkedin.com';
  static numberOfTags = 3;
  static numberOfTagsColor = 12;
  static defaultLimit = 50;
  static defaultLanguage = 'en';
  static defaultTemplatesLimit = 50;
  static defaultExportDataLimit = 1000;
  static defaultExportAccountsCSVName = 'companies.csv';
  static defaultExportProspectsCSVName = 'contacts.csv';
  static defaultExportLeadsCSVName = 'leads.csv';
  static defaultImageUrl =
    'https://crono-assets.s3.eu-central-1.amazonaws.com/defaultCompanyLogo.png';
  static websiteRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  static allLanguage = 'all';
  static templateLanguagesInput = ['en', 'es', 'fr', 'de', 'it', 'pt'];
  static templateLanguages = [
    ...Constants.templateLanguagesInput,
    Constants.allLanguage,
  ];

  static emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static phoneRegex =
    /((\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/;

  static linkedinInvitationMaxCharsSalesNav = 300;

  static linkedinInvitationMaxChars = 200;

  static maxAutomaticDailyDefault = 50;

  static maxAutomaticDailySalesNav = 100;

  static languages: {
    [key: string]: string;
  } = {
    en: 'Eng',
    es: 'Esp',
    fr: 'Fra',
    de: 'Deu',
    it: 'Ita',
    pt: 'Por',
    all: 'All',
  };

  static monthsDictionary: Record<number, string> = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  static linkedinActivityRedirectUrl = (publicId: string) =>
    `${Constants.linkedinBaseUrl}/in/${publicId}/recent-activity/all`;
  static linkedinExperiencesRedirectUrl = (publicId: string) =>
    `${Constants.linkedinBaseUrl}/in/${publicId}/details/experience/`;
  static linkedinSalesNavLeadRedirectUrl = (leadId: string) =>
    `${Constants.linkedinBaseUrl}/sales/lead/${leadId},NAME_SEARCH`;
  static linkedinSalesNavCompanyRedirectUrl = (numericId: string) =>
    `${Constants.linkedinBaseUrl}/sales/company/${numericId}`;
}

export interface StatusColors {
  light: string;
  lightHover: string;
  dark: string;
}

export const statusColors = {
  red: {
    light: '#FFE2E2',
    lightHover: '#FDD0D0',
    dark: '#FD6778',
  },
  pink: {
    light: '#FDE5F8',
    lightHover: '#F3CEEB',
    dark: '#F376D8',
  },
  violet: {
    light: '#F2EAFF',
    lightHover: '#E4D8F6',
    dark: '#995AFF',
  },
  purple: {
    light: '#E4E4FF',
    lightHover: '#CDCDE5',
    dark: '#6E5DDD',
  },
  blue: {
    light: '#DEECFF',
    lightHover: '#C8D4E5',
    dark: '#3B58DB',
  },
  cyan: {
    light: '#D3EDFF',
    lightHover: '#BED5E5',
    dark: '#509AFC',
  },
  lightBlue: {
    light: '#D7F1FA',
    lightHover: '#C1D9E1',
    dark: '#2DC2F1',
  },
  acquamarine: {
    light: '#CEEDED',
    lightHover: '#B9D7CC',
    dark: '#1EBAB2',
  },
  green: {
    light: '#D5ECCF',
    lightHover: '#C0D4BA',
    dark: '#24BA84',
  },
  lime: {
    light: '#EDF7C8',
    lightHover: '#D5DEB4',
    dark: '#A8C052',
  },
  yellow: {
    light: '#FEF3D2',
    lightHover: '#F4E4C6',
    dark: '#F9BB06',
  },
  orange: {
    light: '#FFEBDD',
    lightHover: '#F3E0D3',
    dark: '#FD9467',
  },
};

export const statusColorsMap: {
  [key: number]: StatusColors[];
} = {
  1: [statusColors.red],
  2: [statusColors.red, statusColors.green],
  3: [statusColors.red, statusColors.green, statusColors.yellow],
  4: [
    statusColors.red,
    statusColors.cyan,
    statusColors.green,
    statusColors.yellow,
  ],
  5: [
    statusColors.red,
    statusColors.violet,
    statusColors.cyan,
    statusColors.green,
    statusColors.yellow,
  ],
  6: [
    statusColors.red,
    statusColors.pink,
    statusColors.violet,
    statusColors.cyan,
    statusColors.green,
    statusColors.yellow,
  ],
  7: [
    statusColors.red,
    statusColors.pink,
    statusColors.violet,
    statusColors.cyan,
    statusColors.green,
    statusColors.yellow,
    statusColors.orange,
  ],
  8: [
    statusColors.red,
    statusColors.pink,
    statusColors.violet,
    statusColors.blue,
    statusColors.cyan,
    statusColors.green,
    statusColors.yellow,
    statusColors.orange,
  ],
  9: [
    statusColors.red,
    statusColors.pink,
    statusColors.violet,
    statusColors.blue,
    statusColors.cyan,
    statusColors.acquamarine,
    statusColors.green,
    statusColors.yellow,
    statusColors.orange,
  ],
  10: [
    statusColors.red,
    statusColors.pink,
    statusColors.violet,
    statusColors.blue,
    statusColors.cyan,
    statusColors.acquamarine,
    statusColors.green,
    statusColors.lime,
    statusColors.yellow,
    statusColors.orange,
  ],
  11: [
    statusColors.red,
    statusColors.pink,
    statusColors.violet,
    statusColors.purple,
    statusColors.blue,
    statusColors.cyan,
    statusColors.acquamarine,
    statusColors.green,
    statusColors.lime,
    statusColors.yellow,
    statusColors.orange,
  ],
  12: [
    statusColors.red,
    statusColors.pink,
    statusColors.violet,
    statusColors.purple,
    statusColors.blue,
    statusColors.cyan,
    statusColors.lightBlue,
    statusColors.acquamarine,
    statusColors.green,
    statusColors.lime,
    statusColors.yellow,
    statusColors.orange,
  ],
};

export interface LinkedinSearchCategoryType {
  value: string;
  label: string;
}

export const linkedinIndustries: LinkedinSearchCategoryType[] = [
  { value: '3246', label: 'Accessible Architecture and Design' },
  { value: '120', label: 'Alternative Dispute Resolution' },
  { value: '50', label: 'Architecture and Planning' },
  { value: '3198', label: 'Automotive' },
  { value: '41', label: 'Banking' },
  { value: '142', label: 'Beverage Manufacturing' },
  { value: '11', label: 'Business Consulting and Services' },
  { value: '54', label: 'Chemical Manufacturing' },
  { value: '2247', label: 'Commercial and Industrial Machinery Maintenance' },
  { value: '48', label: 'Construction' },
  { value: '2019', label: 'Cosmetology and Barber School' },
  { value: '99', label: 'Design services' },
  { value: '1999', label: 'Education' },
  { value: '2240', label: 'Electronic and Precision Equipment Maintenance' },
  { value: '28', label: 'Entertainment Providers' },
  { value: '110', label: 'Events Services' },
  { value: '615', label: 'Fashion Accessories Manufacturing' },
  { value: '43', label: 'Financial Services' },
  { value: '23', label: 'Food and Beverage Manufacturing' },
  { value: '34', label: 'Food and Beverage Services' },
  { value: '2255', label: 'Footwear and Leather Goods Repair' },
  { value: '140', label: 'Graphic Design' },
  { value: '137', label: 'Human Resources Services' },
  { value: '9', label: 'Law Practice' },
  { value: '10', label: 'Legal Services' },
  { value: '31', label: 'Hospitality' },
  { value: '14', label: 'Hospitals and Health Care' },
  { value: '2194', label: 'Hotels and Motels' },
  { value: '135', label: 'Industrial Machinery Manufacturing' },
  { value: '42', label: 'Insurance' },
  { value: '3126', label: 'Interior Design' },
  { value: '96', label: 'IT Services and IT Consulting' },
  { value: '3103', label: 'IT System Operations and Maintenance' },
  { value: '77', label: 'Law Enforcement' },
  { value: '616', label: 'Leather Product Manufacturing' },
  { value: '25', label: 'Manufacturing' },
  { value: '1862', label: 'Marketing Services' },
  { value: '17', label: 'Medical Equipment Manufacturing' },
  { value: '113', label: 'Online Audio and Video Media' },
  { value: '2282', label: 'Pet Services' },
  { value: '15', label: 'Pharmaceutical Manufacturing' },
  { value: '136', label: 'Photography' },
  { value: '98', label: 'PR and Communications Service' },
  { value: '1810', label: 'Professional Services' },
  { value: '105', label: 'Professional Training and Coaching' },
  { value: '98', label: 'Public Relations and Communications Services' },
  { value: '44', label: 'Real Estate' },
  { value: '2225', label: 'Repair and Maintenance' },
  { value: '27', label: 'Retail' },
  { value: '3256', label: 'Regenerative Design' },
  { value: '19', label: 'Retail Apparel and Fashion' },
  { value: '32', label: 'Restaurants' },
  { value: '143', label: 'Retail Luxury Goods and Jewelry' },
  { value: '3250', label: 'Retail Pharmacies' },
  { value: '4', label: 'Software Development' },
  { value: '2142', label: 'Sports Teams and Clubs' },
  { value: '104', label: 'Staffing and Recruiting' },
  { value: '2018', label: 'Technical and Vocational Training' },
  { value: '1594', label: 'Technology, Information and Media' },
  { value: '6', label: 'Technology, Information and Internet' },
  {
    value: '116',
    label: 'Transportation, Logistics, Supply Chain',
  },
  { value: '59', label: 'Utilities' },
  { value: '2226', label: 'Vehicle Repair and Maintenance' },
  { value: '106', label: 'Venture Capital and Private Equity Principals' },
  { value: '124', label: 'Wellness and Fitness Services' },
  { value: '2500', label: 'Wineries' },
  { value: '1257', label: 'Wholesale Chemical and Allied Products' },
  { value: '1208', label: 'Wholesale Luxury Goods and Jewelry' },
];
