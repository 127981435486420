import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from 'context/auth';
import useGoogleAuthCallback from 'hooks/services/google/useGoogleAuthCallback';
import useDisconnectGoogleIntegration from 'hooks/services/google/useDisconnectGoogleIntegration';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { FeConstants } from 'constants/FeConstants';
import GmailLogo from 'assets/images/gmail_logo.svg';
import ShieldLock from 'assets/images/Shield-lock-icon.svg';
import OutlookLogo from 'assets/images/outlook.svg';
import useEmailIntegrationGuard from 'hooks/guards/useEmailIntegrationGuard';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import { IconButton, TextField, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';
import useDisconnectOutlookIntegration from 'hooks/services/outlook/useDisconnectGoogleIntegration';
import TextAccordion from 'crono-fe-common/components/TextAccordion';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import IconCopy from 'crono-fe-common/icons/Icon-Copy';
import useDisconnectCustomEmailProviderIntegration from 'hooks/services/customEmailIntegration/useDisconnectCustomEmailProviderIntegration';
import IconCustomEmailProvider from 'crono-fe-common/icons/Icon-Custom-Email-Provider';
import CustomEmailProviderSettingsModal from 'pages/settings/Integrations/customEmailProviderSettingsModal';

export const gmailInstructions = (onCopy: (text: string) => void) => [
  {
    id: 1,
    instruction: (
      <>
        <Typography fontSize={'14px'}>
          Whitelist Crono App on Google Workspace
        </Typography>
        <Typography fontSize={'14px'} lineHeight={'18px'} color={colors.grey11}>
          To make sure Crono can safely interact with your Gmail accounts please
          follow these steps
        </Typography>
      </>
    ),
  },
  {
    id: 2,
    instruction: (
      <>
        <Typography fontSize={'14px'}>
          Go to this{' '}
          <span
            className="share-guide"
            onClick={() =>
              window.open(FeConstants.gmailInstructionURL, '_blank')
            }
          >
            admin page
          </span>{' '}
          to set up a third-party app.
        </Typography>
        <Typography fontSize={'14px'}>
          Click on configure new app and select “Client ID”
        </Typography>
      </>
    ),
  },
  {
    id: 3,
    instruction: (
      <>
        <Typography fontSize={'14px'}>
          Paste our Client ID and click “Search”
        </Typography>
        <div className="copy-paste-client-id-wrapper">
          <TextField
            name="ClientId"
            variant="standard"
            autoComplete="off"
            InputProps={{
              disableUnderline: true,
              style: {
                background: 'white',
                border: `1px solid ${colors.grey4}`,
                borderRadius: '8px',
                fontSize: '14px',
                width: 'fit-content',
                minWidth: '100%',
              },
            }}
            fullWidth
            value={FeConstants.gmailClientId}
          />
          <IconButton onClick={() => onCopy(FeConstants.gmailClientId)}>
            <IconCopy color={colors.black} />
          </IconButton>
        </div>
      </>
    ),
  },
  {
    id: 4,
    instruction: (
      <Typography fontSize={'14px'}>Click “Select” on Crono App</Typography>
    ),
  },
  {
    id: 5,
    instruction: (
      <>
        <Typography fontSize={'14px'}>
          Set app access to “Trusted” and click “Configure”.
        </Typography>
        <Typography fontSize={'14px'}>
          Your organization can now connect to Crono!
        </Typography>
      </>
    ),
  },
  {
    id: 6,
    instruction: (
      <Typography fontSize={'14px'}>
        Now click “Connect” below here to connect your Gmail Account
      </Typography>
    ),
  },
];

const EmailIntegration: FC = () => {
  const navigate = useNavigate();

  const [scopeNotAcceptedError, setScopeNotAcceptedError] =
    useState<boolean>(false);

  const { user, refreshUser } = useAuth();

  const analytics = useJuneAnalytics();
  const [copyText, setCopyText] = useState<boolean>(false);

  useConditionalSnackBar([
    {
      condition: !!scopeNotAcceptedError,
      message: 'Accept all options to proceed with the integration',
      severity: 'info',
    },
    {
      condition: !!copyText,
      message: 'Copied to clipboard',
      severity: 'success',
    },
  ]);

  const {
    // isSuccessGoogle: googleIntegrated,
    // isErrorGoogle: googleNotIntegrated,
    // errorGoogle: googleError,
    // verifyGoogleIntegration,
    // isSuccessOutlook: outlookIntegrated,
    // isErrorOutlook: outlookNotIntegrated,
    // isLoadingOutlook,
    // errorOutlook: outlookError,
    // verifyOutlookIntegration,
    isSuccess: emailIntegrated,
    isError: emailNotIntegrated,
    error: emailError,
    isLoading: isLoadingEmail,
    verifyIntegration,
  } = useEmailIntegrationGuard();

  const { isSuccess: googleDisconnected, disconnectGoogleIntegration } =
    useDisconnectGoogleIntegration();

  const { isSuccess: outlookDisconnected, disconnectOutlookIntegration } =
    useDisconnectOutlookIntegration();

  const {
    isSuccess: customEmailProviderDisconnected,
    disconnectCustomEmailProviderIntegration,
  } = useDisconnectCustomEmailProviderIntegration();

  useEffect(() => {
    if (googleDisconnected) {
      verifyIntegration();
    }
  }, [googleDisconnected]);

  useEffect(() => {
    if (outlookDisconnected) {
      verifyIntegration();
    }
  }, [outlookDisconnected]);

  useEffect(() => {
    if (customEmailProviderDisconnected) {
      verifyIntegration();
    }
  }, [customEmailProviderDisconnected]);

  useEffect(() => {
    if (emailError?.response?.status === 401) {
      navigate(PATH.LOGIN);
    }
  }, [emailError]);

  const {
    isLoading: isLoadingGoogleAuthCallback,
    isSuccess: isSuccessGoogleAuthCallback,
    error: errorGoogleAuthCallback,
    googleAuthCallback,
  } = useGoogleAuthCallback();

  useEffect(() => {
    if (isSuccessGoogleAuthCallback) {
      verifyIntegration();
      if (analytics) {
        analytics.track('mail-integration', {
          crm: 'google',
        });
      }
      refreshUser();
    }
  }, [isSuccessGoogleAuthCallback]);

  const outlookLogin = () => {
    window.location.replace(FeConstants.OUTLOOK_LOGIN_URL);
  };

  const googleLogin = useGoogleLogin({
    scope: FeConstants.gmailScopes,
    onSuccess: async (tokenResponse) => {
      const acceptedScopes = tokenResponse.scope
        .split(' ')
        .filter((scope) => scope !== '');
      if (
        acceptedScopes.includes('email') &&
        acceptedScopes.includes('https://www.googleapis.com/auth/gmail.send') &&
        acceptedScopes.includes(
          'https://www.googleapis.com/auth/gmail.readonly',
        )
      ) {
        googleAuthCallback(tokenResponse.code);
      } else {
        setScopeNotAcceptedError(true);
        setTimeout(() => {
          setScopeNotAcceptedError(false);
        }, 3000);
      }
    },

    flow: 'auth-code',
  });

  const [expanded, setExpanded] = useState<boolean>(true);

  const handleCopyClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopyText(true);
    setTimeout(() => setCopyText(false), 3000);
  };

  const checkEmailIntegration = (emailIntegration: EmailIntegrationType) => {
    return (
      user?.otherSettings?.hasEmail &&
      user.company?.emailIntegrationType === emailIntegration
    );
  };

  const [
    showModalConnectCustomEmailProvider,
    setShowModalConnectCustomEmailProvider,
  ] = useState<boolean>(false);

  const handleConnectCustomEmailProvider = () => {
    setShowModalConnectCustomEmailProvider(true);
  };

  return (
    <div className="crm-connect-card">
      {showModalConnectCustomEmailProvider && (
        <CustomEmailProviderSettingsModal
          close={() => setShowModalConnectCustomEmailProvider(false)}
          onSuccess={() => {
            verifyIntegration();
            setShowModalConnectCustomEmailProvider(false);
          }}
        />
      )}
      <div className="connect-card">
        <div
          className={`providers ${
            checkEmailIntegration(EmailIntegrationType.GMAIL)
              ? 'gmail-integration'
              : ''
          }`}
        >
          {/* Gmail */}
          {checkEmailIntegration(EmailIntegrationType.GMAIL) && (
            <>
              <div
                className="gmail-instructions-wrapper"
                style={{
                  height: expanded ? 'fit-content' : undefined,
                  alignItems: expanded ? 'flex-start' : undefined,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className={`icon-logo-container gmail-instructions-icon`}
                  >
                    <img
                      src={ShieldLock}
                      alt={'Instructions'}
                      className={`instruction-button-wrapper shield-lock`}
                    />
                  </div>
                  <div className={`connection-label-instructions`}>
                    <Typography
                      fontSize={'16px'}
                      fontWeight={600}
                      lineHeight={'24px'}
                      color={colors.black}
                    >
                      Gmail authorization instructions
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={400}
                      lineHeight={'18px'}
                      color={colors.grey11}
                    >
                      Crono needs to be marked as trusted app in your Google
                      Workspace. If you are not the{' '}
                      <span style={{ fontWeight: 600 }}>Admin</span> of the
                      workspace, please{' '}
                      <span
                        className="share-guide"
                        onClick={() =>
                          handleCopyClipboard(FeConstants.gmailGuideText)
                        }
                      >
                        share this guide
                      </span>{' '}
                      with the <span style={{ fontWeight: 600 }}>Admin</span>
                    </Typography>
                  </div>
                  {/* Instructions button */}
                  <div className={'instructions-button'}>
                    <IconButton
                      className="arrow-icon-button"
                      style={{
                        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                      }}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        ev.preventDefault();
                        setExpanded((prev) => !prev);
                      }}
                    >
                      <BottomArrow />
                    </IconButton>
                  </div>
                </div>
                <TextAccordion
                  text={null}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  hideArrow
                  style={{
                    display: !expanded ? 'none' : undefined,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '16px',
                    }}
                  >
                    <div className="horizontal-divider" />
                    <div className="instructions-container">
                      {gmailInstructions(handleCopyClipboard).map(
                        ({ id, instruction }) => {
                          return (
                            <div className="instruction-wrapper" key={id}>
                              <Typography
                                className="instruction-index"
                                fontWeight={600}
                                color={colors.prospectingHover}
                              >
                                {id}
                              </Typography>
                              <div className="instruction">{instruction}</div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                </TextAccordion>
              </div>

              {/* Gmail */}
              <div className="provider-box">
                <div className={`icon-logo-container email`}>
                  <img
                    src={GmailLogo}
                    alt={'gmail'}
                    className={`connection-image gmail`}
                  />
                </div>

                <div className={`connection-label`}>
                  <Typography
                    fontSize={'16px'}
                    fontWeight={600}
                    lineHeight={'24px'}
                    color={colors.black}
                  >
                    {EmailIntegrationType.GMAIL}
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    fontWeight={500}
                    lineHeight={'18px'}
                    color={
                      (isLoadingGoogleAuthCallback && !emailIntegrated) ||
                      isSuccessGoogleAuthCallback ||
                      (emailIntegrated && !isSuccessGoogleAuthCallback)
                        ? colors.darkGreen
                        : colors.inactiveHover
                    }
                  >
                    {emailIntegrated &&
                      !isSuccessGoogleAuthCallback &&
                      'Connected'}
                    {(emailNotIntegrated || googleDisconnected) &&
                      !isSuccessGoogleAuthCallback &&
                      'Login required'}
                    {isLoadingGoogleAuthCallback &&
                      !emailIntegrated &&
                      'Connecting'}
                    {isSuccessGoogleAuthCallback && 'Integration complete!'}
                    {errorGoogleAuthCallback && 'Error during integration'}
                  </Typography>
                </div>

                {/* Login button */}
                {((emailNotIntegrated && !isSuccessGoogleAuthCallback) ||
                  (googleDisconnected && !isSuccessGoogleAuthCallback)) && (
                  <div className={'icons-buttons-container not-connected '}>
                    <div
                      className="connection-status pointer"
                      onClick={() => googleLogin()}
                    >
                      <Typography fontSize={'14px'} fontWeight={500}>
                        Connect
                      </Typography>
                    </div>
                  </div>
                )}
                {/* Logout */}
                {(emailIntegrated || isSuccessGoogleAuthCallback) &&
                  !(googleDisconnected && !isSuccessGoogleAuthCallback) && (
                    <div
                      className={
                        'icons-buttons-container disconnect-integration'
                      }
                    >
                      <div
                        className="pointer"
                        onClick={() => {
                          disconnectGoogleIntegration();
                        }}
                      >
                        <Typography fontSize={'14px'} fontWeight={500}>
                          Disconnect
                        </Typography>
                      </div>
                    </div>
                  )}
              </div>
            </>
          )}

          {/* Outlook */}
          {checkEmailIntegration(EmailIntegrationType.OUTLOOK) && (
            <div className="provider-box">
              <div className={`icon-logo-container email`}>
                <img
                  src={OutlookLogo}
                  alt={'outlook'}
                  className={`connection-image outlook`}
                />
              </div>
              <div className={`connection-label`}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'24px'}
                  color={colors.black}
                >
                  {EmailIntegrationType.OUTLOOK}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  lineHeight={'18px'}
                  color={
                    !isLoadingEmail && emailIntegrated
                      ? colors.darkGreen
                      : colors.inactiveHover
                  }
                >
                  {emailIntegrated && !isLoadingEmail && 'Connected'}
                  {emailNotIntegrated && !isLoadingEmail && 'Login required'}
                </Typography>
              </div>
              {!isLoadingEmail && emailNotIntegrated ? (
                <div className={'icons-buttons-container not-connected'}>
                  <div
                    className="connection-status pointer"
                    onClick={() => outlookLogin()}
                  >
                    <Typography fontWeight={500}>Connect</Typography>
                  </div>
                </div>
              ) : (
                <div
                  className={'icons-buttons-container disconnect-integration'}
                >
                  <div
                    className="pointer"
                    onClick={() => {
                      disconnectOutlookIntegration();
                    }}
                  >
                    <Typography fontWeight={500}>Disconnect</Typography>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* Custom */}
          {checkEmailIntegration(EmailIntegrationType.CUSTOM) && (
            <div className="provider-box">
              <div className={`icon-logo-container email`}>
                <IconCustomEmailProvider
                  className={`connection-image custom`}
                />
              </div>
              <div className={`connection-label`}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'24px'}
                  color={colors.black}
                >
                  Other email provider (SMTP/IMAP)
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  lineHeight={'18px'}
                  color={
                    !isLoadingEmail && emailIntegrated
                      ? colors.darkGreen
                      : colors.inactiveHover
                  }
                >
                  {emailIntegrated && !isLoadingEmail && 'Connected'}
                  {emailNotIntegrated && !isLoadingEmail && 'Login required'}
                </Typography>
              </div>
              {!isLoadingEmail && emailNotIntegrated ? (
                <div className={'icons-buttons-container not-connected'}>
                  <div
                    className="connection-status pointer"
                    onClick={handleConnectCustomEmailProvider}
                  >
                    <Typography fontWeight={500}>Connect</Typography>
                  </div>
                </div>
              ) : (
                <div
                  className={'icons-buttons-container disconnect-integration'}
                >
                  <div
                    className="pointer"
                    onClick={() => {
                      disconnectCustomEmailProviderIntegration();
                    }}
                  >
                    <Typography fontWeight={500}>Disconnect</Typography>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailIntegration;
