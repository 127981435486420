import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ModifyTagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background: ${colors.white};
  padding: 24px;
  border-radius: 16px;
  .close-button {
    cursor: pointer;
  }
  .modify-tag-container {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px 20px;
    row-gap: 24px;
  }
  .options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .insert-value-text-field {
    width: 100%;
    display: flex;
  }
  .manage-value-buttons-container {
    column-gap: 6px;
  }
  .manage-value-button {
    height: 32px;
    width: 32px;
    padding: 3px;
  }
`;
