import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import useSuggestionAnalytics from 'hooks/services/analytics/useSuggestionAnalytics';
import SuggestionOverviewChart from '../charts/suggestionOverviewChart';
import SuggestionDataHealthChart from '../charts/suggestionDatahealthChart';

interface IProps {
  filterParams: AnalyticsFilters;
}

const AnalyticSuggestionTabController = ({ filterParams }: IProps) => {
  const { data: analyticsSuggestion } = useSuggestionAnalytics(filterParams);

  return (
    <>
      <SuggestionOverviewChart
        suggestionAnalytics={analyticsSuggestion?.data?.data}
      />

      <SuggestionDataHealthChart
        suggestionAnalytics={analyticsSuggestion?.data?.data}
      />
    </>
  );
};

export default AnalyticSuggestionTabController;
