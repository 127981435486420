import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ChartsGridWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${colors.white};
  border-radius: 16px;
  padding: 20px;

  .chart-title {
    font-weight: 500;
    background-color: ${colors.white};
    color: ${colors.nurtureBlue};
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    margin: 0px;
    text-align: center;
    font-weight: 600;
    padding-left: 60px;
  }

  .title {
    margin: 0px;
    color: ${colors.primary};
  }
  .icon-link-S {
    width: 16px !important;
    min-width: 16px !important;
    height: 16px !important;
    min-height: 16px !important;
  }
  .charts-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
    overflow: auto;
  }

  .chart-container {
    height: 450px;
    margin: 20px;
    width: fit-content;
    max-width: 100%;
    margin-bottom: 40px;
  }
  .blur-backdrop-effect {
    flex-direction: column;
    gap: 12px;
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    backdrop-filter: blur(2px);
    margin-top: 20px;
  }

  .table-select {
    max-width: 140px;
    min-width: 140px;
    background-color: ${colors.white};
    height: 30px;
    border-radius: 10px;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    margin-right: 10px;
  }

  .table-select-placeholder {
    max-width: 140px;
    min-width: 140px;
    background-color: ${colors.white};
    height: 30px;
    border-radius: 10px;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    margin-right: 10px;
    color: ${colors.placeholder};
  }

  .table-header {
    background-color: ${colors.white};
    height: 40px;
    border-radius: 10px 10px 0px 0px;
  }

  .MuiDataGrid-root {
    background-color: ${colors.white};
  }

  .recharts-responsive-container {
    background-color: ${colors.white};
    border-radius: 5px;
  }

  .recharts-surface {
    padding: 10px;
  }

  .filter-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .input-date {
    width: 160px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .input-date div {
    border-radius: 10px;
  }

  .input-period {
    width: 160px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .input-period-placeholder {
    width: 160px;
    background-color: ${colors.white};
    border-radius: 10px;
    color: ${colors.placeholder};
    font-weight: 500;
  }

  .input-period-field div {
    border-radius: 10px;
  }

  .input-account {
    width: 250px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding-inline: 6px;
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    // background: ${colors.primaryLight};
    color: ${colors.primary};
  }

  .input-account-field div {
    border-radius: 10px;
  }

  .input-user {
    width: 250px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .input-user-field div {
    border-radius: 10px;
  }
`;
