import { SuggestionPreferences } from 'crono-fe-common/types/suggestionPreferences';
import React from 'react';
import { SuggestionPreferencesWrapper } from './style';
import { TextField } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';

interface IProps {
  updateSuggestionPreferences: (
    name: keyof SuggestionPreferences,
    value: any,
  ) => void;
  parameters: SuggestionPreferences | null;
}

const PreferencesNewData: {
  enabled:
    | 'generateAccountsEnabled'
    | 'generateLeadsEnabled'
    | 'generateLeadsIfNoEngagementEnabled';
  days:
    | 'generateAccountsDays'
    | 'generateLeadsDays'
    | 'generateLeadsIfNoEngagementDays';
  text1: string;
  text2?: string;
}[] = [
  {
    enabled: 'generateAccountsEnabled',
    days: 'generateAccountsDays',
    text1: 'Find new companies if no company has been created in the last ',
  },
  {
    enabled: 'generateLeadsEnabled',
    days: 'generateLeadsDays',
    text1: 'Find new contacts if no company has been created in the last ',
  },
  {
    enabled: 'generateLeadsIfNoEngagementEnabled',
    days: 'generateLeadsIfNoEngagementDays',
    text1:
      'Find new contacts within a specific company if no contacts have been available',
    text2: 'to be engaged in the last ',
  },
];

const SuggestionPreferencesNewData = ({
  updateSuggestionPreferences,
  parameters,
}: IProps) => {
  return (
    <>
      {PreferencesNewData.map((newData, idx) => {
        return (
          <SuggestionPreferencesWrapper key={idx}>
            <CronoSwitch
              checked={!!parameters?.[newData.enabled]}
              onChange={() => {
                updateSuggestionPreferences(
                  newData.enabled,
                  !parameters?.[newData.enabled] as boolean,
                );
              }}
            />
            <div
              className="container-text-suggestion"
              style={{
                color: !parameters?.[newData.enabled]
                  ? colors.grey2
                  : colors.black,
              }}
            >
              <div className="container-text-line" style={{ marginTop: 6 }}>
                {newData.text1}
                {!newData.text2 && (parameters?.[newData.days] ?? -1) >= 0 && (
                  <>
                    <TextField
                      defaultValue={parameters?.[newData.days]}
                      type="number"
                      className="day-picker-preferences"
                      onChange={(ev) => {
                        if (
                          ev.target.value &&
                          !Number.isNaN(Number(ev.target.value))
                        ) {
                          updateSuggestionPreferences(
                            newData.days,
                            ev.target.value,
                          );
                        }
                      }}
                      InputProps={{
                        sx: {
                          height: 30,
                          width: 50,
                          marginInline: 1,
                          borderRadius: 2,
                          color: 'inherit',
                          '&:hover': {
                            background: colors.grey6,
                          },
                          '& fieldset': {
                            border: `1px solid ${colors.grey444}`,
                          },
                          '&:hover fieldset': {
                            border: `1px solid ${colors.grey5}`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `1px solid ${colors.grey5}`,
                          },
                        },
                      }}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          border: 'none',
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '18px',
                        },
                      }}
                    />
                    days.
                  </>
                )}
              </div>
              {newData.text2 && (
                <div className="container-text-line">
                  {newData.text2}{' '}
                  {(parameters?.[newData.days] ?? -1) >= 0 && (
                    <>
                      <TextField
                        defaultValue={parameters?.[newData.days]}
                        type="number"
                        className="day-picker-preferences"
                        onChange={(ev) => {
                          if (
                            ev.target.value &&
                            !Number.isNaN(Number(ev.target.value))
                          ) {
                            updateSuggestionPreferences(
                              newData.days,
                              ev.target.value,
                            );
                          }
                        }}
                        InputProps={{
                          sx: {
                            height: 30,
                            width: 50,
                            marginInline: 1,
                            borderRadius: 2,
                            color: 'inherit',
                            '&:hover': {
                              background: colors.grey6,
                            },
                            '& fieldset': {
                              border: `1px solid ${colors.grey444}`,
                            },
                            '&:hover fieldset': {
                              border: `1px solid ${colors.grey5}`,
                            },
                            '&.Mui-focused fieldset': {
                              border: `1px solid ${colors.grey5}`,
                            },
                          },
                        }}
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            border: 'none',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '18px',
                          },
                        }}
                      />
                      days.
                    </>
                  )}
                </div>
              )}
            </div>
          </SuggestionPreferencesWrapper>
        );
      })}
    </>
  );
};

export default SuggestionPreferencesNewData;
