import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useMemo, useState } from 'react';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { FeConstants, planFeatures } from 'constants/FeConstants';
import { fullname, mapSubscriptionToPlanType, sortPlans } from 'utils/fe-utils';
import { Options, Plan } from '../../signup';
import useGetSubscriptionProducts from 'hooks/services/subscription/useGetSubscriptionProducts';
import PlanShortCard from '../../signup/planSteps/planType/planShortCard';
import { CircularProgress, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import FeatureListItem from '../../signup/planSteps/planType/featureListItem';
import BillingSwitch from '../../signup/planSteps/planType/billingSwitch';
import { BillingPlan } from '../../signup/model';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import UpgradeDeniedModal from './upgradeDenied';
import { UpgradeSubscriptionModalWrapper } from './style';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useAuth } from 'context/auth';
import Role from 'crono-fe-common/types/enums/role';
import useCheckoutSession from 'hooks/services/subscription/useCheckoutSession';
import useGetSubscription from 'hooks/services/subscription/useGetSubscription';
import { useJuneAnalytics } from 'context/june';
import { useUpgradeModal } from 'context/upgradeModal';
import { Constants } from 'crono-fe-common/constants/constants';
import { cookieDomain, cookies } from 'constants/crono-cookie';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';

const UpgradeSubscriptionModal = () => {
  const { closeModal, isUpgradeModalVisible } = useUpgradeModal();

  const {
    data: subscriptionProducts,
    isLoading: isSubscriptionProductsLoading,
  } = useGetSubscriptionProducts();

  const { user } = useAuth();

  const options: Options[] = useMemo(() => {
    let allowedPlans = (subscriptionProducts?.data?.data ?? []).filter(
      (s) => s.subscriptionType !== SubscriptionPlanType.FREE_TRIAL,
    );

    if (
      user?.subscriptionType === SubscriptionPlanType.STARTER ||
      user?.subscriptionType === SubscriptionPlanType.PRO
    ) {
      allowedPlans = allowedPlans.filter(
        (s) => s.subscriptionType !== SubscriptionPlanType.STARTER,
      );
    }

    if (user?.subscriptionType === SubscriptionPlanType.PRO) {
      allowedPlans = allowedPlans.filter(
        (s) => s.subscriptionType !== SubscriptionPlanType.PRO,
      );
    }

    const plansOptions = allowedPlans.map((plan) => {
      return {
        ...plan,
        features:
          planFeatures[plan.subscriptionType as keyof typeof planFeatures],
      };
    });
    return sortPlans(plansOptions);
  }, [subscriptionProducts, user]);

  const [billingPlan, setBillingPlan] = useState<BillingPlan>('yearly');
  const currentSubscriptionType = SubscriptionPlanType.FREE_TRIAL;

  const [planSelectedIndex, setPlanSelectedIndex] = React.useState<number>(0);

  const selectedPlan = options[planSelectedIndex];

  const [isUpgradeDenied, setIsUpgradeDenied] = useState(false);

  const { mutateAsync: callCheckout, isLoading: callingCheckout } =
    useCheckoutSession();

  const { data: subscriptionDataDb } = useGetSubscription();

  const firstSubscriptionManager = useMemo(() => {
    if (subscriptionDataDb) {
      const user = subscriptionDataDb?.data?.data.users?.find((user) =>
        user.userRoles.includes(Role.SUBSCRIPTION_MANAGER),
      );
      if (user) {
        return fullname(user.firstName, user.lastName);
      }
    }
    return 'subscription manager';
  }, [subscriptionDataDb]);

  const activeLicenses = useMemo(() => {
    return subscriptionDataDb?.data?.data.users?.length ?? 0;
  }, [subscriptionDataDb]);

  const analytics = useJuneAnalytics();

  const handleCheckout = (priceId: string) => {
    callCheckout({ priceId, quantity: activeLicenses }).then((res) => {
      const url = res.data?.data.url;
      if (url) {
        if (analytics) {
          const plan = options.find((plan) => {
            const price = plan?.prices.find((price) => price?.id === priceId);
            return price;
          });
          //Set the thank-you cookie to show the thank you page after the payment
          cookies.set(FeConstants.cronoThankYouCookie, '1', {
            domain: cookieDomain,
            path: '/',
            //Expires in 20 minutes
            expires: new Date(Date.now() + 20 * 60 * 1000),
            sameSite: 'none',
            secure: true,
          });

          analytics.track('user-checkout-created', {
            priceId,
            billingPlan,
            quantity: activeLicenses,
            plan: plan?.subscriptionType,
          });
        }
        window.open(url, '_self');
      }
    });
  };

  if (!isUpgradeModalVisible) return <></>;

  return (
    <BlurBackgroundDivFixed
      onClick={closeModal}
      style={{ zIndex: Constants.beforeHighestZIndex }}
    >
      {isUpgradeDenied ? (
        <UpgradeDeniedModal
          onClose={() => setIsUpgradeDenied(false)}
          manager={firstSubscriptionManager}
        />
      ) : (
        <UpgradeSubscriptionModalWrapper onClick={(e) => e.stopPropagation()}>
          <div className="cross">
            <CloseTabButton
              style={{ paddingRight: '24px' }}
              close={closeModal}
            />
          </div>
          {isSubscriptionProductsLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <div className={'container'}>
              <div className={'info'}>
                <div className={'header'}>
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    lineHeight={'30px'}
                  >
                    This action is not supported by{' '}
                    {currentSubscriptionType ===
                    SubscriptionPlanType.FREE_TRIAL ? (
                      <span style={{ color: colors.freeTrialDarkYellow }}>
                        the Free trial
                      </span>
                    ) : (
                      'your plan'
                    )}
                  </Typography>
                  <Typography fontSize={16} fontWeight={400}>
                    Please upgrade to enjoy more features from Crono
                  </Typography>
                </div>
                <div className={'features-list'}>
                  {selectedPlan?.features.map((feature, i) => {
                    const type = selectedPlan.subscriptionType;

                    const firstFeature =
                      i === 0 &&
                      type !== SubscriptionPlanType.FREE_TRIAL &&
                      type !== SubscriptionPlanType.STARTER;

                    return (
                      <FeatureListItem
                        key={i}
                        feature={feature}
                        firstFeature={firstFeature}
                        type={type}
                      />
                    );
                  })}
                </div>
                <div className={'upgrade-panel'}>
                  <BillingSwitch
                    options={options}
                    selectedPlan={
                      mapSubscriptionToPlanType(
                        selectedPlan?.subscriptionType,
                      ) ?? Plan.STARTER
                    }
                    billingPlan={billingPlan}
                    setBillingPlan={setBillingPlan}
                    badgePlacement={'top'}
                  />
                  <MainButton
                    style={{ margin: 0 }}
                    onClick={() => {
                      if (analytics) {
                        analytics.track(
                          'subscription-popup-upgrade-clicked',
                          {},
                        );
                      }
                      if (
                        user &&
                        user.userRoles?.includes(Role.SUBSCRIPTION_MANAGER)
                      ) {
                        const priceId = selectedPlan?.prices.filter(
                          (price) => price.paymentCycle === billingPlan,
                        )[0]?.id;

                        if (priceId && !callingCheckout) {
                          handleCheckout(priceId);
                        }
                      } else {
                        setIsUpgradeDenied(true);
                      }
                    }}
                  >
                    Upgrade €
                    {
                      selectedPlan?.prices?.find(
                        (p) => p.paymentCycle === billingPlan,
                      )?.monthlyTotalCycle
                    }
                  </MainButton>
                </div>
              </div>
              <div className={'upgrade-options'}>
                {options.map((option, index) => {
                  const price = option?.prices.filter(
                    (price) => price.paymentCycle === billingPlan,
                  )[0];
                  const type = option?.subscriptionType;

                  return (
                    <PlanShortCard
                      selected={planSelectedIndex === index}
                      type={type}
                      price={price}
                      billingPlan={billingPlan}
                      key={index}
                      onClick={() => setPlanSelectedIndex(index)}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </UpgradeSubscriptionModalWrapper>
      )}
    </BlurBackgroundDivFixed>
  );
};

export default UpgradeSubscriptionModal;
