import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Template } from 'crono-fe-common/types/template';
import { GenerateIcebreakersInput } from 'crono-fe-common/types/DTO/generateIcebreakers';
import { GenerateIcebreakersResponse } from 'crono-fe-common/types/DTO/generateIcebreakersResponse';

export default function useGenerateIcebreakers() {
  const generateIcebreakers = (icebreakerInput: GenerateIcebreakersInput) => {
    const request: Request = {
      url: `${ENDPOINTS.assistant.icebreaker}`,
      config: {
        method: 'post',
        data: icebreakerInput,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<GenerateIcebreakersResponse>,
    CronoError,
    GenerateIcebreakersInput
  >(generateIcebreakers, {});

  return { data, mutate, ...rest };
}
