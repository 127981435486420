import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Account } from 'crono-fe-common/types/account';
import { AccountInfoInputs } from 'crono-fe-common/types/DTO/accountInfoInputs';

export default function useEditAccount() {
  const queryClient = useQueryClient();

  const editAccount = (accountInfo: AccountInfoInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.account.main}`,
      config: {
        method: 'patch',
        data: accountInfo,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<Account>,
    CronoError,
    AccountInfoInputs
  >(editAccount, {
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.account.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.search]);
    },
  });

  return { data, mutate, ...rest };
}
