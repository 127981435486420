import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import AccountViewType from 'crono-fe-common/types/enums/accountViewType';
import AccountSortType from 'crono-fe-common/types/enums/accountSortType';
import { UpdateAccountMultiple } from 'crono-fe-common/types/DTO/updateAccountMultiple';
import { DefaultCompanyStatusOptions } from 'crono-fe-common/types/model';

export interface EditAccountsMultiple {
  selectAll: boolean;
  checkedAccounts: string[];
  unCheckedAccounts: string[];
  onlyOwner: boolean;
  status: DefaultCompanyStatusOptions | null;
  working: boolean | null;
  filters: Filters;
}
export interface Filters {
  limit: number;
  offset: number;
  name?: string | null;
  status?: DefaultCompanyStatusOptions[] | null;
  view?: AccountViewType;
  sort?: AccountSortType;
}

export default function useEditAccountsMultiple() {
  const queryClient = useQueryClient();

  const editAccounts = (data: UpdateAccountMultiple[]) => {
    const request: Request = {
      url: `${ENDPOINTS.account.multiple}`,
      config: {
        method: 'patch',
        data: { data: data },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    UpdateAccountMultiple[]
  >(editAccounts, {
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.account.main),
      });
    },
  });

  return { data, mutate, ...rest };
}
