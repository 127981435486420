import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { Input } from 'crono-fe-common/components/FormComponents';
import { underlineInputStyles } from 'utils/fe-utils';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import CloseIcon from 'crono-fe-common/icons/Icon-Close';
import { ReactComponent as PasswordHiddenIcon } from 'crono-fe-common/icons/Password-Hidden-Icon.svg';
import { ReactComponent as PasswordVisibleIcon } from 'crono-fe-common/icons/Password-Showed-Icon.svg';

export const NewPasswordForm: FC<{
  // Pass only formik that has newPassword and confirmPassword
  formik: any;
}> = ({ formik }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validationInfo, setValidationInfo] = useState<
    { isSatisfied: boolean; condition: string }[]
  >([]);

  useEffect(() => {
    const password = formik.values.newPassword;

    let validation = [
      {
        isSatisfied: password.length >= 14,
        condition: 'At least 14 characters',
      },
      {
        isSatisfied: /[A-Z]/.test(password),
        condition: 'At least 1 capital letter',
      },
      {
        isSatisfied: /[a-z]/.test(password),
        condition: 'At least 1 small letter',
      },
      { isSatisfied: /[0-9]/.test(password), condition: 'At least 1 number' },
      {
        isSatisfied: /[!@#$%^&*()_+{}[\]:;"'<>,.?/~`|\\-]/.test(password),
        condition: 'At least 1 symbol',
      },
    ];

    if (password.length >= 32) {
      validation = validation.filter(
        (item) => item.condition !== 'Minimum 14 characters',
      );
      validation = [
        { isSatisfied: false, condition: 'Less then 32 characters' },
        ...validation,
      ];
    }

    setValidationInfo(validation);
  }, [formik.values.newPassword]);

  return (
    <>
      <div className="form-control">
        <Typography
          color={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
              ? colors.inactive
              : colors.primary
          }
          fontSize={12}
          fontWeight={500}
          lineHeight={'16px'}
        >
          New password:
        </Typography>
        <Input
          value={formik.values.newPassword}
          type={showNewPassword ? 'text' : 'password'}
          variant="standard"
          placeholder="Type new password..."
          InputProps={{
            sx: {
              paddingBlock: '6px',
            },
            endAdornment:
              formik.values.newPassword &&
              (showNewPassword ? (
                <PasswordVisibleIcon
                  onClick={() => setShowNewPassword(false)}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <PasswordHiddenIcon
                  onClick={() => setShowNewPassword(true)}
                  style={{ cursor: 'pointer' }}
                />
              )),
          }}
          sx={underlineInputStyles(
            formik.touched.newPassword && Boolean(formik.errors.newPassword),
          )}
          onChange={(value) =>
            formik.setFieldValue('newPassword', value.target.value)
          }
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
        />
        {formik.values.newPassword && (
          <div>
            {validationInfo.map((info, index) => (
              <FlexDiv height={'auto'} justifyContent={'start'} key={index}>
                {info.isSatisfied ? (
                  <CheckMarkIcon
                    color={colors.primary}
                    size={16}
                    strokeWidth={'2.5'}
                  />
                ) : (
                  <CloseIcon
                    color={colors.inactive}
                    size={16}
                    strokeWidth={'2.5'}
                  />
                )}
                <Typography
                  color={info.isSatisfied ? colors.primary : colors.inactive}
                  fontSize={12}
                  fontWeight={400}
                  lineHeight={'16px'}
                >
                  {info.condition}
                </Typography>
              </FlexDiv>
            ))}
          </div>
        )}

        {!formik.values.newPassword &&
          formik.touched.newPassword &&
          Boolean(formik.errors.newPassword) && (
            <Typography
              color={colors.inactive}
              fontSize={12}
              fontWeight={400}
              lineHeight={'16px'}
            >
              New password is required
            </Typography>
          )}
      </div>

      <div className="form-control">
        <Typography
          color={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
              ? colors.inactive
              : colors.primary
          }
          fontSize={12}
          fontWeight={500}
          lineHeight={'16px'}
        >
          Confirm new password:
        </Typography>
        <Input
          value={formik.values.confirmPassword}
          onChange={(value) =>
            formik.setFieldValue('confirmPassword', value.target.value)
          }
          type={showConfirmPassword ? 'text' : 'password'}
          variant="standard"
          placeholder="Confirm new password..."
          InputProps={{
            sx: {
              paddingBlock: '6px',
            },
            endAdornment:
              formik.values.confirmPassword &&
              (showConfirmPassword ? (
                <PasswordVisibleIcon
                  onClick={() => setShowConfirmPassword(false)}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <PasswordHiddenIcon
                  onClick={() => setShowConfirmPassword(true)}
                  style={{ cursor: 'pointer' }}
                />
              )),
          }}
          sx={underlineInputStyles(
            formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword),
          )}
        />
        {formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword) && (
            <Typography
              color={colors.inactive}
              fontSize={12}
              fontWeight={400}
              lineHeight={'16px'}
            >
              {formik.errors.confirmPassword}
            </Typography>
          )}
      </div>
    </>
  );
};
