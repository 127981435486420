import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconFindEmail = ({
  color = colors.grey3,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        d="M19.796 14.3107C20.9211 15.4358 20.9211 17.2589 19.796 18.384C18.6709 19.5091 16.8479 19.5091 15.7227 18.384C14.5976 17.2589 14.5976 15.4358 15.7227 14.3107C16.8479 13.1856 18.6719 13.1856 19.796 14.3107"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6797 10.5869V5.79743"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0399 16.3469H5.27986C4.21906 16.3469 3.35986 15.4877 3.35986 14.4269V5.75999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7493 3.86687H5.29042C4.22386 3.86687 3.35986 4.73087 3.35986 5.79743V5.79743C3.35986 6.43775 3.67762 7.03583 4.20754 7.39487L9.35026 10.8806C10.6607 11.7686 12.381 11.7686 13.6914 10.8806L18.8331 7.39583C19.3621 7.03679 19.6799 6.43775 19.6799 5.79839V5.79743C19.6799 4.73087 18.8159 3.86687 17.7493 3.86687V3.86687Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5997 20.1888L19.7949 18.384"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconFindEmail;
