import { IconButton, Tooltip, Typography } from '@mui/material';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import useEditAccount from 'hooks/services/account/useEditAccount';
import { useLinkedinGetCompanyInfo } from 'crono-fe-common/hooks/crono-extension/gateway';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import { ProspectInputs } from 'pages/searchComponent/searchContactTab/insertContact/model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { AccountInfoInputs } from 'crono-fe-common/types/DTO/accountInfoInputs';
import { Account, isAccount } from 'crono-fe-common/types/account';
import {
  LinkedinCompanyCompleteInfo,
  LinkedinCompanyShortInfo,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { Prospect } from 'crono-fe-common/types/prospect';
import { getError } from 'crono-fe-common/utils';
import { extractDomainFromWebsite } from 'utils/fe-utils';
import useFindPhone from 'hooks/services/scrape/useFindPhone';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { LoadingSpinnerCircles } from 'pages/searchComponent/style';
import useGetUserCredits from 'hooks/services/user/useGetUserCredits';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { SettingsTabs } from 'pages/settings';
import IconRevenue from 'crono-fe-common/icons/Icon-Revenue';
import useScrapeWebsite from 'hooks/services/scrape/useScrapeWebsite';

interface IProps {
  account?: Account | AccountLinkedin | null;
  prospect?: Prospect | null;
  setPhone?: React.Dispatch<React.SetStateAction<string | null>>;
  //To call the fined from the contact insert
  prospectInputs?: ProspectInputs | null;
  accountInputs?: LinkedinCompanyShortInfo | null;
  handleAddAccountInformation?: (
    website: string | null,
    nOfEmployees: number | null,
    industry: string | null,
    country?: string | null,
  ) => void;
  iconColor?: string;
  hasLabel?: boolean;
  disabled?: boolean;
  onClickBuyMore?: () => void;
  makeBEPatchProspect?: boolean;
}

const FindPhonePreconditionButton = ({
  account,
  prospect,
  setPhone,
  prospectInputs,
  accountInputs,
  handleAddAccountInformation,
  iconColor,
  hasLabel,
  disabled,
  onClickBuyMore,
  makeBEPatchProspect = false,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const [searchPhoneError, setSearchPhoneError] = useState<string | null>(null);
  const [searchPhoneHint, setSearchPhoneHint] = useState<string | null>(null);
  const [nProvider, setNProvider] = useState<number>(0);

  const prospectLinkedinUrl = useMemo(() => {
    return prospect?.linkedin ?? prospectInputs?.linkedin ?? null;
  }, [prospect, prospectInputs]);

  const {
    isLoading: isProspectScrapeLoading,
    data: prospectScrapeData,
    error: prospectScrapeError,
    mutateAsync: findPhone,
  } = useFindPhone();

  const { data: userCredits } = useGetUserCredits();

  const { result: linkedinCompanyInfo, call: getLinkedinCompanyInfo } =
    useLinkedinGetCompanyInfo();

  const { mutate: updateAccount, error: errorUpdateAccount } = useEditAccount();

  const { mutate: updateProspect } = useEditProspect();

  const { mutateAsync: scrapeWebsiteAsync, isLoading: isLoadingScrapeWebsite } =
    useScrapeWebsite();

  const checkIfCompanyPhoneIsPresent = async () => {
    let companyInfo = linkedinCompanyInfo;
    if (!companyInfo) {
      const accountLinkedin = account?.linkedin ?? accountInputs?.url ?? null;
      companyInfo = await getLinkedinCompanyInfo({
        url: accountLinkedin,
        numericId: accountInputs?.numericId,
      });
    }
    //If we found a phone in the company we use thatone to fill the field and patch the prospect if it is an already in Crono prospect
    if (companyInfo?.company?.phone) {
      if (setPhone) {
        setPhone(companyInfo.company.phone);
      }
      if (prospect?.objectId) {
        updateProspect({
          prospectId: prospect.objectId,
          phone: companyInfo.company.phone,
        });
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (prospectScrapeData) {
      if (prospectScrapeData.data?.data) {
        if (prospectScrapeData.data?.data.phoneNumber && setPhone) {
          setPhone(prospectScrapeData.data?.data.phoneNumber);
        } else {
          checkIfCompanyPhoneIsPresent();
        }
      } else {
        checkIfCompanyPhoneIsPresent();
      }
    }
  }, [prospectScrapeData]);

  useEffect(() => {
    const handle = async () => {
      if (prospectScrapeError) {
        const foundByCompany = await checkIfCompanyPhoneIsPresent();
        if (foundByCompany) return;
        if (prospectScrapeError.response?.status === 409) {
          setSearchPhoneError(
            `The found phone number ${prospectScrapeData?.data?.data.phoneNumber ?? ''} is already in use in the CRM`,
          );
        } else if (prospectScrapeError?.response?.status === 404) {
          setSearchPhoneError('Phone not found');
        } else if (prospectScrapeError?.response?.status === 403) {
          setSearchPhoneError('Scrape not enabled or credit exhausted');
        } else {
          setSearchPhoneError('Error while finding phone');
        }
      }
    };
    handle();
  }, [prospectScrapeError]);

  useConditionalSnackBar([
    {
      condition: !!searchPhoneError,
      message: searchPhoneError || '',
      severity: 'error',
    },
    {
      condition: !!searchPhoneHint,
      message: searchPhoneHint || '',
      severity: 'info',
    },
    {
      condition: !!errorUpdateAccount,
      message: getError(errorUpdateAccount) ?? 'Error update company',
      severity: 'error',
    },
  ]);

  const handleFindPhone = async () => {
    const accountLink = account?.website ?? null;
    const accountLinkedin = account?.linkedin ?? accountInputs?.url ?? null;
    //I have no information to find the phone
    if (
      !accountLink &&
      !accountLinkedin &&
      !prospectLinkedinUrl &&
      !accountInputs?.numericId
    ) {
      setSearchPhoneHint('Add website to company info to find phone');
      return;
    }
    //Reset errors for the new research
    setSearchPhoneError(null);
    setSearchPhoneHint(null);
    const website = await scrapeWebsiteAsync({
      website: accountLink ?? '',
    });
    if (website.data?.data?.redirectUrl) {
      const domain = extractDomainFromWebsite(website.data.data.redirectUrl);
      validateInputAndFindPhone(domain, prospectLinkedinUrl);
      if (website.data.data.redirectUrl !== website) {
        updateAccount({
          accountId: account?.objectId ?? '',
          website: website.data.data.redirectUrl,
        });
      }
      return;
    }
    if (accountLinkedin || accountInputs?.numericId) {
      const linkedinCompanyInfo = await getLinkedinCompanyInfo({
        url: accountLinkedin,
        numericId: accountInputs?.numericId,
      });
      await searchWithLinkedinCompanyInfo(linkedinCompanyInfo?.company);
      return;
    }
    if (prospectLinkedinUrl) {
      validateInputAndFindPhone(null, prospectLinkedinUrl);
      return;
    }
  };

  const searchWithLinkedinCompanyInfo = async (
    company: LinkedinCompanyCompleteInfo | null | undefined,
  ) => {
    if (company && account) {
      if (company.website) {
        const website = await scrapeWebsiteAsync({
          website: company.website ?? '',
        });
        if (website.data?.data?.redirectUrl) {
          const domain = extractDomainFromWebsite(
            website.data?.data?.redirectUrl,
          );
          validateInputAndFindPhone(domain, prospectLinkedinUrl);
        }
        const patchAccount: AccountInfoInputs = {
          accountId: account.objectId,
        };

        patchAccount.website = website.data?.data?.redirectUrl;

        if (!account.numberOfEmployees && company.numberOfEmployees) {
          patchAccount.nOfEmployees = company.numberOfEmployees;
        }

        if (!account.industry && company.industry) {
          patchAccount.industry = company.industry;
        }

        if ((!isAccount(account) || !account.country) && company.country) {
          patchAccount.country = company.country;
        }

        updateAccount(patchAccount);
      } else {
        const res = await checkIfCompanyPhoneIsPresent();
        if (!res) {
          setSearchPhoneHint('Add website to company info to find phone');
          setTimeout(() => {
            setSearchPhoneHint(null);
          }, 2000);
        }
      }
    } else if (company && accountInputs) {
      const website = await scrapeWebsiteAsync({
        website: company.website ?? '',
      });
      if (website.data?.data?.redirectUrl && company) {
        const domain = extractDomainFromWebsite(
          website.data?.data?.redirectUrl,
        );
        validateInputAndFindPhone(domain, prospectLinkedinUrl);
        //I call the function passed to save the additional information obtained
        if (handleAddAccountInformation)
          handleAddAccountInformation(
            website.data?.data?.redirectUrl,
            company.numberOfEmployees,
            company.industry,
            company.country,
          );
      } else {
        const res = await checkIfCompanyPhoneIsPresent();
        if (!res) {
          setSearchPhoneHint('Add website to company info to find phone');
          setTimeout(() => {
            setSearchPhoneHint(null);
          }, 2000);
        }
      }
    }
  };

  const validateInputAndFindPhone = (
    domain: string | null,
    linkedinUrl: string | null = null,
  ) => {
    const prospectFirstName =
      prospect?.firstName ?? prospectInputs?.firstName ?? undefined;
    const prospectLastName =
      prospect?.lastName ?? prospectInputs?.lastName ?? undefined;
    //The linkedinUrl is enough to enable the search, otherwise I need domain, firstname and lastName
    if (
      !linkedinUrl &&
      (!domain ||
        domain.length === 0 ||
        !prospectFirstName ||
        !prospectLastName)
    ) {
      setSearchPhoneError('Fill company domain or linkedin url');
      return;
    }
    findPhone({
      firstName: prospectFirstName!,
      lastName: prospectLastName!,
      domain: domain!,
      linkedinUrl: linkedinUrl,
      ...(makeBEPatchProspect && prospect?.objectId
        ? { prospectId: prospect?.objectId }
        : {}),
    });
    if (analytics) {
      analytics.track('search-phone', {});
    }
    return;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isProspectScrapeLoading) {
      timer = setInterval(() => {
        setNProvider((prev) => {
          if (prev >= 10) return 10;
          return prev + 1;
        });
      }, 2500);
    } else {
      setNProvider(1);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isProspectScrapeLoading]);

  const are0CreditsLeft = useMemo(() => {
    return userCredits?.data?.data.phoneLeft === 0;
  }, [userCredits]);

  const navigate = useNavigate();

  const handleBuyMoreCredits = () => {
    navigate(PATH.SETTINGS, {
      state: {
        initialSettingsTab: SettingsTabs.AddOns,
      },
    });
    if (onClickBuyMore) {
      onClickBuyMore();
    }
  };

  return are0CreditsLeft ? (
    <Tooltip
      title={
        <span
          style={{
            whiteSpace: 'pre-line',
            display: 'flex',
            textAlign: 'center',
          }}
        >
          There are no more credits left. <br />
          Please buy more credits to search the phone number.
        </span>
      }
      placement="top"
      arrow
    >
      <IconButton
        onClick={() => {
          handleBuyMoreCredits();
        }}
        className="find-button"
        sx={{
          padding: '3px 2px 3px 6px !important',
          backgroundColor: colors.inactive,
          width: '76px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px',
          borderRadius: '34px',
          cursor: 'pointer',
          '&.MuiIconButton-root:hover': {
            backgroundColor: colors.inactiveHover,
          },
        }}
      >
        <Typography fontSize={'14px'} color={colors.white}>
          Buy
        </Typography>
        <IconRevenue
          color={colors.white}
          style={{
            height: 24,
            width: 24,
          }}
        />
      </IconButton>
    </Tooltip>
  ) : isProspectScrapeLoading || isLoadingScrapeWebsite ? (
    <LoadingSpinnerCircles>
      <Typography fontSize={'14px'} fontWeight={500} color={colors.primaryDark}>
        {nProvider} provider...
      </Typography>
      <span className="loader"></span>
    </LoadingSpinnerCircles>
  ) : hasLabel ? (
    <Tooltip
      title={
        <span
          style={{
            whiteSpace: 'pre-line',
            display: 'flex',
            textAlign: 'center',
          }}
        >
          {disabled
            ? `Please empty the field 
          to enable the Find function`
            : `${userCredits?.data?.data.phoneLeft} Find phone credits left.
              Next update: ${moment(userCredits?.data?.data.creditsRenewalDate).format('MMM DD, YYYY')}`}
        </span>
      }
      placement="top"
      arrow
    >
      <IconButton
        onClick={() => {
          if (disabled) return;
          handleFindPhone();
        }}
        className="find-button"
        sx={{
          padding: '3px 2px 3px 6px !important',
          backgroundColor: disabled
            ? `${colors.grey11} !important`
            : colors.primary,
          width: '76px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px',
          borderRadius: '34px',
          cursor: disabled ? 'auto' : undefined,
          '&.MuiIconButton-root:hover': {
            backgroundColor: disabled
              ? `${colors.grey11} !important`
              : colors.primaryDark,
          },
        }}
      >
        <Typography fontSize={'14px'} color={iconColor}>
          Find
        </Typography>
        <SearchIcon color={iconColor} />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton
      onClick={() => {
        if (disabled) return;
        handleFindPhone();
      }}
      sx={{
        padding: '3px !important',
      }}
    >
      <SearchIcon color={iconColor} />
    </IconButton>
  );
};

export default FindPhonePreconditionButton;
