import { IconButton, Tooltip, Typography } from '@mui/material';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import TextAccordion from 'crono-fe-common/components/TextAccordion';
import { useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { Template } from 'crono-fe-common/types/template';

import parse from 'html-react-parser';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import { useAuth } from 'context/auth';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { ReactComponent as PrivateLock } from 'crono-fe-common/icons/Private-lock.svg';
import { replaceAllTemplateVariables } from 'utils/fe-utils';
import { Account } from 'crono-fe-common/types/account';
import { Prospect } from 'crono-fe-common/types/prospect';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';

interface IProps {
  template: Template;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<Template | null | undefined>
  >;
  showOpenRate?: boolean;
  handleRemoveTemplateClick: (template: Template) => void;
  account?: Account | null;
  prospect?: Prospect | null;
}

const TemplateDisplay = ({
  template,
  setSelectedTemplate,
  showOpenRate,
  handleRemoveTemplateClick,
  prospect,
  account,
}: IProps) => {
  const { user } = useAuth();
  const { data: users } = useGetUsers();
  const total = template.useCount;
  const replyRate =
    template.answerCount !== 0 && total !== 0
      ? Math.round((template.answerCount / total) * 100)
      : 0;
  const openRate =
    template.openCount !== 0 && total !== 0
      ? Math.round((template.openCount / total) * 100)
      : 0;

  const [expanded, setExpanded] = useState<boolean>(false);
  const { data: templateVariables } = useGetTemplateVariables();

  const subjectReplaced = useMemo(() => {
    if (!template.subject) return '';
    return parse(
      replaceAllTemplateVariables(
        template.subject,
        prospect ?? null,
        account ?? null,
        user ?? null,
        templateVariables?.data?.data ?? null,
      ),
    );
  }, [template, prospect, account, user]);

  const templateOwner = useMemo(() => {
    return users?.data?.data?.find((u) => u.id === template.userId);
  }, [users, template]);

  const templateOwnerName = useMemo(() => {
    return (
      <div
        style={{
          display: 'flex',
          gap: '2px',
          alignItems: 'center',
        }}
      >
        {!template.shared && <PrivateLock />}

        <Typography
          color={!template.shared ? colors.intenseBlue : colors.grey1}
          fontSize={'12px'}
          fontWeight={500}
          noWrap
        >
          {template?.userId
            ? `${templateOwner?.firstName} ${templateOwner?.lastName}`
            : 'No owner'}
        </Typography>
      </div>
    );
  }, [template, templateOwner]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        style={{
          width: 94,
          display: 'flex',
          alignItems: 'center',
          marginRight: 6,
          columnGap: 6,
        }}
      >
        {template.userId === user?.id && (
          <IconButton
            className="remove-icon-template-settings"
            onClick={() => handleRemoveTemplateClick(template)}
          >
            <RemoveIcon color={colors.inactive} />
          </IconButton>
        )}
        <IconButton
          className="edit-icon-template-settings"
          onClick={() => setSelectedTemplate(template)}
        >
          <EditIcon color={colors.callScheduled} />
        </IconButton>
      </div>
      <div
        className="template-item"
        style={{
          width: `calc(100% - ${showOpenRate ? 300 : 240}px)`,
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 8,
            flexDirection: 'column',
            gap: 4,
          }}
        >
          {template.templateTags && template.templateTags.length > 0 && (
            <div className="tags-container-template-creation">
              <TagsBoxShowAll
                type="AccountAndProspect"
                currentSituationTags={
                  template.templateTags?.map((tag) => ({
                    externalPropertyId: tag.externalPropertyId,
                    option: tag.value,
                  })) ?? []
                }
                disableEdit={true}
                blockIsEditable={true}
                showRemovable={false}
              />
              {templateOwnerName}
            </div>
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography fontSize={'14px'} fontWeight={600} noWrap>
              {template.title}
            </Typography>
            {(!template.templateTags || template.templateTags.length === 0) &&
              templateOwnerName}
          </div>
          {(template.type === TemplateType.EMAIL ||
            template.type === TemplateType.IN_MAIL) && (
            <Typography
              fontWeight={400}
              fontSize={'14px'}
              noWrap
              color={colors.grey1}
            >
              Subject: {subjectReplaced}
            </Typography>
          )}
        </div>
        <TextAccordion
          text={parse(template.content)}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <Tooltip arrow title="Total use" placement="bottom">
          <div className="template-rate usage-rate">{total}</div>
        </Tooltip>
        {showOpenRate && (
          <Tooltip arrow title="Open rate" placement="bottom">
            <div className="template-rate open-rate">{openRate}%</div>
          </Tooltip>
        )}
        <Tooltip
          arrow
          title={`${
            template.type === TemplateType.INVITATION
              ? 'Accepted rate'
              : 'Reply rate'
          }`}
          placement="bottom"
        >
          <div className="template-rate reply-rate">{replyRate}%</div>
        </Tooltip>
      </div>
    </div>
  );
};

export default TemplateDisplay;
