import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import React from 'react';
import { colors } from 'crono-fe-common/theme';

interface IProps {
  close: () => void;
  style?: any;
  className?: string;
}

const CloseTabButton = ({ close, style, className }: IProps) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        alignSelf: 'flex-end',
        cursor: 'pointer',
        display: 'flex',
        height: 'fit-content',
      }}
      onClick={(ev) => {
        close();
        ev.stopPropagation();
        ev.preventDefault();
      }}
    >
      <CloseMIcon color={colors.grey11} />
    </div>
  );
};

export default CloseTabButton;
