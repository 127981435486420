import { Modal } from '@mui/material';
import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ModalWrapper = styled(Modal)`
  .MuiGrid-item {
    width: 100%;
  }

  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    border: 3px solid ${colors.primary};
    border-radius: 20px;
    background-color: white;
    box-shadow: 24;
    max-height: 100%;
    overflow-y: auto;
  }

  .modal-header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    background-color: white;
    border-radius: 20px;
    padding: 20px 40px 10px 40px;
  }

  .modal-header h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
  }

  .modal-body {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px;
  }

  .close-modal-icon {
    color: ${colors.primary};
    font-size: 40px;
    cursor: pointer;
  }

  .error-p {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }

  @media screen and (max-width: 768px) {
    .modal-box {
      width: 100% !important;
    }
  }
`;

export const ModalFormWrapper = styled.div`
  .prospect-info-row {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border-bottom: solid 0.5px ${colors.lightGrey};
    padding: 5px;
  }

  .account-info-select {
    width: 190px;
    margin-right: 110px;
    align-items: center;
    background-color: ${colors.lightGrey};
    height: 40px;
    border-radius: 13px;
    font-family: Poppins;
    color: ${colors.black};
    font-size: 12px;
    border: 0px !important;
  }

  .account-info-textarea {
    background-color: ${colors.lightGrey} !important;
    height: 90px;
    border: 0px !important;
    border-radius: 20px;
    padding: 5px 10px;
    width: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Poppins;
    resize: none;
  }

  .prospect-info-row p {
    font-weight: 600;
    width: 130px;
  }

  .add-new-label {
    width: 150px;
    display: inline-block;
    font-weight: bold;
  }

  .add-new-field {
    width: 300px;
    display: inline;
  }

  .add-new-textarea {
    width: 300px;
  }

  .switch-container {
    width: 235px;
    margin-left: 65px;
  }

  .switch-container-lead {
    width: 250;
    display: flex;
    margin-right: 115px;
  }

  .switch-label {
    margin: 10px;
    font-weight: 500;
  }

  login-field {
    width: 500px;
    display: inline;
  }

  .add-new-field-select {
    width: 300px;
    background-color: ${colors.lightGrey};
    height: 40px;
    border-radius: 20px;
  }

  .prospect-field-select {
    width: 190px;
    background-color: ${colors.lightGrey};
    height: 40px;
    border-radius: 20px;
    margin-right: 110px;
  }

  .add-new-auto-complete {
    height: 40px;
    .MuiOutlinedInput-root {
      padding-left: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 40px;
    }

    input {
      padding-left: 10px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 40px !important;
    }
  }

  .prospect-info-chip {
    width: 190px;
    height: 40px;
    margin-right: 110px;
  }

  .add-new-field-select fieldset {
    border: none;
  }

  .add-new-field input {
    background-color: ${colors.lightGrey};
    height: 30px;
    border: 0px !important;
    border-radius: 20px;
    padding: 5px 10px;
    width: 100%;
  }

  .add-new-textarea {
    background-color: ${colors.lightGrey};
    border: 0px ${colors.lightGrey}!important;
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
  }

  .char-count {
    text-align: right;
    font-size: 0.75rem;
  }

  .hint {
    margin-left: 30px;
  }

  .hint-box {
    display: flex;
    align-items: center;
  }

  .add-new-field textarea {
    height: 30px !important;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    padding-left: 1em;
  }

  .add-new-field fieldset {
    border: none;
  }

  .login-field input {
    background-color: ${colors.lightGrey};
    height: 30px;
    border: 0px !important;
    border-radius: 20px;
    padding: 5px 10px;
    width: 100%;
  }

  .login-field fieldset {
    border: none;
  }

  .add-new-field textarea {
    background-color: ${colors.lightGrey};
    height: 30px;
    border-radius: 20px;
    padding: 5px 10px;
  }

  .textarea-parent div:first-child {
    padding: 0px;
  }

  form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button-container {
    align-self: end;
    padding: 20px;
  }

  .prospect-button-container {
    display: flex;
    padding: 20px;
  }

  .account-button-container {
    display: flex;
    padding: 20px;
  }

  .modal-button {
    margin-left: 40px;
  }

  .scrape-button {
    margin-left: -80px;
  }
`;

export const EmailThreadModalWrapper = styled(Modal)`
  .MuiGrid-item {
    width: 100%;
  }

  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    border: 3px solid ${colors.primary};
    border-radius: 20px;
    background-color: white;
    box-shadow: 24;
    max-height: 100%;
    overflow-y: auto;
    min-height: 75%;
  }

  h2 {
    margin-bottom: 2px;
  }

  .error {
    color: ${colors.error};
    font-weight: 500;
    font-size: 1.25rem;
  }

  .prospect-container {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    margin-bottom: 10px;
  }

  .prospect-role {
    font-weight: 500;
    font-size: 16px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    background-color: white;
    border-radius: 20px;
    padding: 20px 40px 10px 40px;
    margin-bottom: 10px;
  }

  .modal-header h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
  }

  .modal-body {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px;
  }

  .close-modal-icon {
    color: ${colors.primary};
    font-size: 40px;
    cursor: pointer;
  }

  .linkedin-icon {
    color: ${colors.linkedin};
    font-size: 22px;
    cursor: pointer;
    margin-right: 10px;
  }

  .layout-grid {
    width: 100%;
    height: 100%;
    min-height: 600px;
  }

  .error-p {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }

  @media screen and (max-width: 768px) {
    .modal-box {
      width: 100% !important;
    }
  }

  .prospect-name {
    font-weight: 500;
    font-size: 16px;
  }

  .account-name {
    font-weight: 400;
    color: ${colors.primary};
  }

  .modal-button {
    margin-left: 40px;
  }

  .message-container {
    width: 90%;
    margin-left: 20px;
    margin-bottom: 10px;
    min-height: 40px;
    border: solid 0.5px ${colors.black};
    border-radius: 8px;
  }

  .message-header {
    cursor: pointer;
  }

  .message-header-selected {
    cursor: pointer;
    border-radius: 8px 8px 0px 0px;
    background-color: ${colors.primaryDisabled};
    border-bottom: solid 0.5px ${colors.black};
  }

  .reply-container {
    margin-bottom: 10px;
    width: 95%;
  }

  .reply-button {
    width: 100px;
    text-transform: unset !important;
  }

  .message-sender {
    margin-left: 20px;
    font-size: 15px;
    font-weight: 600;
  }

  .message-subject {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 400;
  }

  .message-date {
    margin-right: 50px;
  }

  .message-body {
    width: 100%;
    min-height: 240px;
    margin-left: 30px;
    margin-top: 20px;
    align-items: flex-start;
  }
`;

export const LinkedinExtensionModalWrapper = styled(Modal)`
  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    border-radius: 16px;
    background-color: ${colors.white};
    max-height: 100%;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
  .close-button {
    cursor: pointer;
  }
  .inner-content-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    row-gap: 16px;
  }
`;
