import React, { useEffect, useState } from 'react';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { DomainEditorWrapper } from './style';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { CircularProgress, TextField, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import useEditAccount from 'hooks/services/account/useEditAccount';
import { getError } from 'crono-fe-common/utils';
import { useConditionalSnackBar } from 'context/snackbar';
import { checkIfValidWebsite } from 'utils/fe-utils';
import styled from '@emotion/styled';

interface IProps {
  accountId: string | null;
  initialWebsite: string | null;
  close: () => void;
  onSave: () => void;
}

const DomainEditor = ({ accountId, initialWebsite, close, onSave }: IProps) => {
  const [website, setWebsite] = useState(initialWebsite);

  const {
    mutateAsync: editAccountAsync,
    isLoading: isLoadingEditAccount,
    error: errorEditAccount,
  } = useEditAccount();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (error) {
      t = setTimeout(() => {
        setError(null);
      }, 2000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [error]);

  const handleSave = async () => {
    if (!accountId) return;
    try {
      if (!website) return;
      if (!checkIfValidWebsite(website)) {
        setError('Invalid website');
        return;
      }
      await editAccountAsync({
        accountId,
        website,
      });
      onSave();
      close();
    } catch (e) {
      /** */
    }
  };

  useConditionalSnackBar([
    {
      condition: !!errorEditAccount,
      message:
        getError(errorEditAccount) ?? 'Error while inserting new website',
      severity: 'error',
    },
    {
      condition: !!error,
      message: error ?? 'Invalid website',
      severity: 'error',
    },
  ]);

  return (
    <BlurBackgroundDivFixed onClick={close}>
      <DomainEditorWrapper
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
      >
        <CloseTabButton close={close} />
        <div className="content-domain-editor">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Edit company domain:
          </Typography>
          <div className="domain-input-block">
            <Typography
              fontSize={14}
              lineHeight={'22px'}
              fontWeight={600}
              color={colors.grey11}
            >
              Company website:
            </Typography>
            <TextField
              variant="standard"
              value={website}
              onChange={(ev) => {
                setWebsite(ev.target.value);
              }}
            />
          </div>
          <div className="button-container">
            <CancelButton onClick={close} className="button">
              Cancel
            </CancelButton>
            {isLoadingEditAccount ? (
              <CircularProgress />
            ) : (
              <MainButton
                className="button"
                onClick={handleSave}
                disabled={!website}
              >
                Save
              </MainButton>
            )}
          </div>
        </div>
      </DomainEditorWrapper>
    </BlurBackgroundDivFixed>
  );
};

export const TooltipEmailNotFound = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  .pencil-icon {
    flex-shrink: 0;
  }
  .confirm-button {
    height: 16px;
    width: 46px;
    border-radius: 8px;
    font-size: 8.73px;
    line-height: 11.64px;
    background: ${colors.white};
    color: ${colors.mainDark};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: 4px;
  }
`;

export default DomainEditor;
