import React, { useEffect, useMemo, useState } from 'react';
import { InsertMultipleWrapper } from './style';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { colors } from 'crono-fe-common/theme';
import { Avatar, Typography } from '@mui/material';
import {
  CancelButton,
  MainButton,
  TabSelectionButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import { AccountInsert } from 'crono-fe-common/types/DTO/accountInsert';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useConditionalSnackBar } from 'context/snackbar';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useEnqueueAsyncJob } from 'crono-fe-common/hooks/crono-extension/gateway';
import { Constants } from 'crono-fe-common/constants/constants';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import { AsyncJobImportCompany } from 'crono-fe-common/types/crono-extension/background-script';
import { useJuneAnalytics } from 'context/june';
import { CompanyForSearchTable } from '../model';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { getNewOptionsWhenSelectingWithStrings } from 'crono-fe-common/utils';

interface IProps {
  close: () => void;
  companies: CompanyForSearchTable[];
  onSubmit: () => void;
  selectedUserIdToAddTo: number | null;
}

const InsertMultipleCompanies = ({
  close,
  companies,
  onSubmit,
  selectedUserIdToAddTo,
}: IProps) => {
  const { user } = useAuth();
  const [externalValuesTag, setExternalValuesTag] = useState<(string | null)[]>(
    new Array(Constants.numberOfTags).fill(null),
  );
  const { data: externalProperties } = useGetExternalProperty(
    'Account',
    true,
    true,
  );

  const externalPropertiesTag = useMemo(() => {
    return (
      externalProperties?.data?.data.filter(
        (property) => property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  //Initialize the externalValuesTag that has default value
  useEffect(() => {
    if (!externalPropertiesTag) return;
    const newExternalProperties: string[] = new Array(
      Constants.numberOfTags,
    ).fill(null);
    externalPropertiesTag.forEach((property, index) => {
      if (property.defaultValue) {
        newExternalProperties[index] = property.defaultValue;
      }
    });
    setExternalValuesTag(newExternalProperties);
  }, [externalPropertiesTag]);

  const { call: enqueueAsyncJob } = useEnqueueAsyncJob();

  //Insert multiple related
  const [accountsToAdd, setAccountsToAdd] = useState<CompanyForSearchTable[]>([
    ...companies,
  ]);

  const [errorMandatoryFields, setErrorMandatoryFields] = useState<
    string | null
  >(null);

  //To clear the error after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorMandatoryFields) {
      timeout = setTimeout(() => {
        setErrorMandatoryFields(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [errorMandatoryFields]);

  const analytics = useJuneAnalytics();

  const handleInsertMultipleCompany = async () => {
    //Check if the mandatory fields are filled
    const externalValuesToAdd: {
      [key: number]: string;
    } = {};
    externalValuesTag.forEach((value, index) => {
      if (value && externalPropertiesTag[index].id) {
        externalValuesToAdd[externalPropertiesTag[index].id] = value;
      }
    });
    const mandatoryToFill: string[] = [];
    externalProperties?.data?.data.forEach((property) => {
      if (property.isMandatory && !externalValuesToAdd[property.id]) {
        mandatoryToFill.push(
          property.publicName ?? property.externalName ?? 'Unnamed property',
        );
      }
    });
    //If anyone is missing, I show the error
    if (mandatoryToFill.length > 0) {
      setErrorMandatoryFields(mandatoryToFill.join(', '));
      return;
    }

    const params = accountsToAdd.map(
      (account): Omit<AsyncJobImportCompany, 'state' | 'id'> => {
        const newAccount: AccountInsert = {
          name: account.name,
          linkedin: account.url,
          numberOfEmployees:
            typeof account.empolyees === 'string' ? null : account.empolyees,
          industry: account.industry,
          website: account.website,
          country: account.location,
          linkedinNumericId: account.numericId,
          externalValues: externalValuesToAdd,
          createInCrm: createInCrm,
          userId: selectedUserIdToAddTo,
          phone: account.phone,
        };

        return {
          source: 'crono-fe',
          type: 'importCompany',
          account: newAccount,
          alreadyInDatabase: account.alreadyInDatabase,
        };
      },
    );
    if (analytics) {
      analytics.track('import-multiple-accounts', {
        count: params.length,
      });
    }
    await enqueueAsyncJob(params);
    onSubmit();
  };

  const removeSelectedAccount = (id: string | undefined) => {
    const newAccounts = accountsToAdd.filter((account) => account.id !== id);
    setAccountsToAdd(newAccounts);
  };

  useConditionalSnackBar([
    {
      condition: !!errorMandatoryFields,
      message: `The empty fields ${errorMandatoryFields} are mandatory. Add a value to continue`,
      severity: 'error',
    },
  ]);

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const [createInCrm, setCreateInCrm] = useState(true);

  useEffect(() => {
    if (user?.company?.integrationType === IntegrationType.SALESFORCE) {
      setCreateInCrm(true);
    }
  }, [user]);

  const handleChangeCreateInCrm = () => {
    const newCrm = !createInCrm;
    setCreateInCrm(newCrm);
    patchUserPreferences({
      createInCrm: newCrm,
    });
    if (user) {
      if (user.userPreferences) {
        user.userPreferences.createInCrm = newCrm;
      } else {
        user.userPreferences = {
          createInCrm: newCrm,
        };
      }
    }
  };
  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const finalOption = getNewOptionsWhenSelectingWithStrings({
      currentValue: externalValuesTag[index],
      option,
      valueType: externalProperty.valueType,
    });
    const newExternalValues = [...externalValuesTag];
    newExternalValues[index] = finalOption;
    setExternalValuesTag(newExternalValues);
  };
  return (
    <BlurBackgroundDivFixed>
      <InsertMultipleWrapper>
        <CloseTabButton close={close} />
        <div className="body-container">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Insert {accountsToAdd.length} Companies:
          </Typography>
          {user?.company?.integrationType === IntegrationType.SALESFORCE && (
            <div>
              <TabSelectionButton
                selected={createInCrm}
                size="large"
                sx={{ position: 'relative', margin: '6px', marginLeft: 0 }}
                onClick={() => handleChangeCreateInCrm()}
                disableElevation
              >
                Create in Crm
              </TabSelectionButton>
              <TabSelectionButton
                selected={!createInCrm}
                sx={{ position: 'relative', right: 20, margin: '6px' }}
                size="large"
                onClick={() => handleChangeCreateInCrm()}
                disableElevation
              >
                Only in crono
              </TabSelectionButton>
            </div>
          )}
          <div className="companies-container">
            {accountsToAdd.map((company, index) => (
              <>
                <div className="company-card-multiple" key={index}>
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    lineHeight={'24px'}
                  >
                    {company.name}
                  </Typography>
                  <Avatar
                    src={company.pictureUrl ?? undefined}
                    className="company-logo"
                  ></Avatar>
                  <span
                    className="cancel-opacity-icon"
                    onClick={() => removeSelectedAccount(company.id)}
                  >
                    <RemoveIcon
                      color={colors.inactive}
                      className="remove-icon-multiple"
                    />
                  </span>
                </div>
                <div className="divider" />
              </>
            ))}
          </div>
          {(externalPropertiesTag?.length ?? 0) !== 0 && (
            <>
              <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
                Add properties tag to companies
              </Typography>

              <TagsBoxShowAll
                currentSituation={externalValuesTag}
                type="Account"
                handleClickTag={handleClickTag}
                onlyInsert={true}
                showMandatoryAsStar={true}
              />
            </>
          )}

          <FlexDiv
            width="fit-content"
            height="fit-content"
            style={{ alignSelf: 'flex-end' }}
          >
            <CancelButton onClick={() => close()}>Cancel</CancelButton>
            <MainButton onClick={handleInsertMultipleCompany}>
              Insert
            </MainButton>
          </FlexDiv>
        </div>
      </InsertMultipleWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default InsertMultipleCompanies;
