import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { TaskTotal } from 'crono-fe-common/types/DTO/taskTotal';

export default function useTasksTotal(
  searchParameters: TaskTotal,
  enabled = true,
) {
  const request: Request = {
    url: ENDPOINTS.task.total,
    config: {
      method: 'post',
      data: { data: searchParameters },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.task.total, searchParameters],

    queryFn: () => client(request),
    enabled,
  };

  return useQuery<
    Response<{
      call: number;
      email: number;
      linkedin: number;
      inMail: number;
    }> & {
      data: {
        count: number;
      };
    },
    CronoError
  >(requestConfig);
}
