import { DelayButtonWrapper } from './style';
import { Tooltip, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import MinusSmallIcon from 'crono-fe-common/icons/Icon-Minus-Small';
import { FeConstants } from 'constants/FeConstants';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import React from 'react';
import classNames from 'classnames';

interface IProps {
  delay: number;
  decreaseDelay: () => void;
  increaseDelay: () => void;
  dateLabel?: string;
  lightTheme?: boolean;
  tooltipTitle?: string;
  delayLabel?: string;
  minusDisabled?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
}

const DelayButton = ({
  delay,
  decreaseDelay,
  increaseDelay,
  dateLabel,
  lightTheme,
  tooltipTitle,
  delayLabel = 'Wait:',
  minusDisabled = false,
  children,
  disabled = false,
}: IProps) => {
  const delayPicker = (
    <div
      className={classNames('delay-picker', {
        'delay-picker-light': lightTheme,
        'delay-picker-disabled': disabled,
      })}
    >
      <Typography
        className="delay-picker-button"
        onClick={decreaseDelay}
        style={{ display: 'inline-flex' }}
      >
        <MinusSmallIcon
          color={
            delay === FeConstants.MIN_DELAY || minusDisabled || disabled
              ? colors.grey3
              : colors.grey11
          }
        />
      </Typography>

      <Typography fontSize={'12px'} fontWeight={500}>
        {delay + ' days'}
      </Typography>

      <Typography
        className="delay-picker-button"
        onClick={increaseDelay}
        style={{ display: 'inline-flex' }}
      >
        <PlusSmallIcon
          color={
            delay === FeConstants.MAX_DELAY || disabled
              ? colors.grey3
              : colors.grey11
          }
        />
      </Typography>
    </div>
  );

  const delayOptionContainer = (
    <div className="delay-option-container">
      {tooltipTitle ? (
        <Tooltip
          placement="top"
          title={tooltipTitle}
          arrow
          enterDelay={400}
          leaveDelay={400}
          componentsProps={{
            tooltip: {
              sx: {
                textAlign: 'center',
              },
            },
          }}
        >
          {delayPicker}
        </Tooltip>
      ) : (
        delayPicker
      )}
    </div>
  );

  return (
    <DelayButtonWrapper>
      {dateLabel ? (
        <div className="delay-container">
          <Typography fontSize={12} fontWeight={500}>
            {delayLabel}
          </Typography>
          {delayOptionContainer}
          <Typography
            className="step-date"
            fontSize={12}
            fontWeight={500}
            color={colors.grey11}
          >
            {dateLabel}
          </Typography>
          {children}
        </div>
      ) : (
        delayOptionContainer
      )}
    </DelayButtonWrapper>
  );
};

export default DelayButton;
