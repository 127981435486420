import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ConditionalWindow, WindowContent, WindowContextType } from './types';
import { SpringValue, animated, useTransition } from '@react-spring/web';
import AccountTab from 'pages/accountTab';
import { useSelectedAccount } from 'pages/home/context/account';
import { useAuth } from 'context/auth';

export const WindowContext = createContext<WindowContextType | undefined>(
  undefined,
);

// Provider of the sideTab
const SideTabProvider: FC<{ children: any }> = ({ children }) => {
  const [windowContent, setWindowContent] = useState<WindowContent | null>(
    null,
  );
  const { user, isLoading } = useAuth();
  //If there is no user I prevent the window from opening
  const open = useMemo(() => {
    //The loading is needed to provent this set to erase the interceptors work
    if (!user && !isLoading) {
      //Without this after the next login the sidetab would open
      setWindowContent(null);
      return undefined;
    }
    return windowContent?.windowType;
  }, [user, isLoading, windowContent?.windowType]);

  //This function set the parameters for the appeance of the side tab
  //I use windowContent.windowType to avoid rerendering if the same status is set. If rerendering is okay, refer to windowContent
  // For instance, if a different windowType is passed, the animation will be weird, since one will leave and one enter at the same time
  const transitions = useTransition(open, {
    from: { width: '0%' },
    enter: { width: '50%' },
    leave: { width: '0%' },
    config: { duration: 400 },
    // from: { opacity: 0 },
    // enter: { opacity: 1 },
    // leave: { opacity: 0 },
    // config: { duration: 400 },
  });

  const { setSelectedProspectId, setSelectedAccountId } = useSelectedAccount();

  const handleRemoveModal = () => {
    setWindowContent({
      ...windowContent,
      modalVisible: undefined,
    });
  };

  const handleCloseSideTab = () => {
    setSelectedAccountId(null);
    setSelectedProspectId(null);
    setWindowContent(null);
  };

  let choosenWindow = <></>;
  switch (windowContent?.windowType) {
    case 'account':
      choosenWindow = (
        <AccountTab
          {...windowContent}
          close={handleCloseSideTab}
          handleRemoveModal={handleRemoveModal}
        />
      );
      break;
    default:
      choosenWindow = <h1></h1>;
      break;
  }

  const [show, setShow] = useState(false);
  // I think timeouts are not being cleaned here on unmount cycle. TBD
  useEffect(() => {
    if (windowContent?.windowType) {
      setTimeout(() => setShow(true), 1);
    } else {
      setTimeout(() => setShow(false), 1);
    }
  }, [windowContent?.windowType]);

  // Removed the windowContent && cause it's implemented by the transitions. Be care of what is passed in the windowContent.windowType,
  // because that's what make everything works
  return (
    <WindowContext.Provider
      value={{
        openWindow: setWindowContent,
        windowContent,
        closeWindow: handleCloseSideTab,
      }}
    >
      {transitions((props: { width: SpringValue<string> }) => (
        <animated.div style={props} className="animated-lateral">
          {/* Render the correct choosenWindow, depending on the attribute passed */}
          {show && choosenWindow}
        </animated.div>
      ))}
      {children}
    </WindowContext.Provider>
  );
};

export function useWindow() {
  const context = useContext(WindowContext);
  if (context === undefined) {
    throw new Error(`useWindow must be used within a SideTabProvider`);
  }

  return context;
}

export function useConditionalWindow(conditionalWindow: ConditionalWindow) {
  try {
    const { openWindow } = useWindow();

    useEffect(() => {
      if (conditionalWindow.condition) {
        openWindow(conditionalWindow);
      }
    }, [conditionalWindow.condition]);
  } catch (e) {
    throw new Error(
      `useConditionalWindow must be used within a SideTabProvider`,
    );
  }
}

export default SideTabProvider;
