import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TagsSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  gap: 24px;
  overflow-y: hidden;

  .tags-settings-container {
    width: 100%;
    overflow: auto;
    flex: 1;
    padding-right: 12px;
  }
  .company-contact-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .company-contact-title {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .tags-container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    margin-block: 12px;
  }

  .tag-card {
    width: 100%;
    border: 1px solid ${colors.grey4};
    padding: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .edit-button {
    background: ${colors.callScheduledLight};
    width: fit-content;
    height: fit-content;
  }
  .resync-crm-button {
    width: 40px;
    height: 40px;
    border: 1px solid ${colors.grey444};
    background: ${colors.grey4};
    &:hover {
      background: ${colors.grey4Hover};
    }
  }
  .remove-button {
    background: ${colors.inactiveLight};
    width: fit-content;
    height: fit-content;
  }
  .company-contact-icon {
    width: fit-content;
  }
`;
