import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { CompanyScrapeFind } from 'crono-fe-common/types/DTO/companyScrapeFind';
import { CompanyScrape } from 'crono-fe-common/types/companyScrape';

export default function useSearchCompanyScrape() {
  const postCompanyScrape = (companyScrapeFind: CompanyScrapeFind[]) => {
    const request: Request = {
      url: `${ENDPOINTS.companyScrape.search}`,
      config: {
        method: 'post',
        data: { data: companyScrapeFind },
      },
    };
    return client(request);
  };

  return useMutation<
    Response<CompanyScrape[]>,
    CronoError,
    CompanyScrapeFind[]
  >(postCompanyScrape, {
    onSettled: () => {},
  });
}
