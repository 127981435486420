import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { Account } from 'crono-fe-common/types/account';
import { AccountSearch } from 'crono-fe-common/types/DTO/accountSearch';

export default function useSearchAccounts(searchParameters: AccountSearch) {
  const { selected, ...rest } = searchParameters;
  const request: Request = {
    url: ENDPOINTS.account.search.main,
    config: {
      method: 'post',
      data: { data: rest },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.account.search.main, searchParameters],

    queryFn: () => client(request),
  };

  return useQuery<ResponsePaginated<Account[]>, CronoError>(requestConfig);
}
