import { Column } from '@ant-design/charts';
import useSequenceAnalytics from 'hooks/services/analytics/useSequenceAnalytics';
import React, { useMemo, useRef, useState } from 'react';
import { SequencesOverviewLegend, colorsChart } from '../legendData';
import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import { Typography } from '@mui/material';
import Legend from '../legend';
import { calcWidth, numberOfGroups, selectChartContainerClass } from '../model';
import { GraphData } from '../tabsControllers/model';
import { BarChartPlaceholder } from './chartsPlaceholders';

interface IProps {
  filterParams: AnalyticsFilters;
}

const SequenceOverviewChart = ({ filterParams }: IProps) => {
  const { data: sequenceAnalytics } = useSequenceAnalytics(filterParams);

  const [maxValue, setMaxValue] = useState<number>(0);

  const dataGeneral = useMemo(() => {
    const newData: GraphData[] = [];
    let maxValue = 0;
    sequenceAnalytics?.data?.data.overview.AnalyticsSequenceCount?.forEach(
      (count) => {
        if (count.count === 0) return;
        if (maxValue < count.count) {
          maxValue = count.count;
        }
        newData.push({
          label: count.label,
          value: count.count,
          type: 'Usage',
        });
      },
    );
    //To show them in descending order of usage
    newData.sort((a, b) => (a.value > b.value ? -1 : 1));
    sequenceAnalytics?.data?.data.overview.AnalyticsSequenceActive?.forEach(
      (active) => {
        if (active.count === 0) return;
        if (maxValue < active.count) {
          maxValue = active.count;
        }
        newData.push({
          label: active.label,
          value: active.count,
          type: 'Active',
        });
      },
    );
    sequenceAnalytics?.data?.data.overview.AnalyticsSequenceStopped?.forEach(
      (stopped) => {
        if (stopped.count === 0) return;
        if (maxValue < stopped.count) {
          maxValue = stopped.count;
        }
        newData.push({
          label: stopped.label,
          value: stopped.count,
          type: 'Stopped',
        });
      },
    );
    sequenceAnalytics?.data?.data.overview.AnalyticsSequenceAnswer?.forEach(
      (answer) => {
        if (answer.count === 0) return;
        if (maxValue < answer.count) {
          maxValue = answer.count;
        }
        newData.push({
          label: answer.label,
          value: answer.count,
          type: 'Answered',
        });
      },
    );
    sequenceAnalytics?.data?.data.overview.AnalyticsSequenceCompleted?.forEach(
      (completed) => {
        if (completed.count === 0) return;
        if (maxValue < completed.count) {
          maxValue = completed.count;
        }
        newData.push({
          label: completed.label,
          value: completed.count,
          type: 'Completed',
        });
      },
    );
    setMaxValue(maxValue);
    return newData;
  }, [sequenceAnalytics]);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const configGeneral = useMemo(() => {
    return {
      data: dataGeneral,
      xField: 'label',
      yField: 'value',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index =
          SequencesOverviewLegend.findIndex((legend) => legend === type) ?? 0;
        return colorsChart[index];
      }) as any,
      autoFit: false,
      meta: {
        value: {
          min: 0,
          max: maxValue * 1.2,
        },
      },
      height: 300,
      width: calcWidth(
        dataGeneral,
        180,
        chartContainerRef.current?.offsetWidth,
      ),
      isGroup: true,
      columnStyle: {},

      renderer: 'svg' as 'svg' | 'canvas' | undefined,
      dodgePadding: numberOfGroups(dataGeneral) > 1 ? 6 : undefined,
      maxColumnWidth: numberOfGroups(dataGeneral) > 1 ? 12 : 150,
      minColumnWidth: numberOfGroups(dataGeneral) > 1 ? 12 : 150,
      legend: false as any,
      randomVal: Math.random() * 100000,
    };
  }, [dataGeneral, chartContainerRef.current?.offsetWidth, maxValue]);

  return (
    <>
      <Typography className="chart-title">Overall strategies</Typography>
      <div
        className={selectChartContainerClass(dataGeneral)}
        ref={chartContainerRef}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 40,
            position: 'sticky',
            left: 0,
          }}
        >
          <Legend colors={colorsChart} fields={SequencesOverviewLegend} />
        </div>

        {configGeneral?.data && configGeneral.data.length ? (
          <Column {...configGeneral} key={configGeneral.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <BarChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default SequenceOverviewChart;
