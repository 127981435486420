import { CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import SequenceSelector from '../sequenceSelector';
import useAddMultipleProspectToSequence from 'hooks/services/sequence/useAddMultipleProspectToSequence';
import { ProspectAndAccountIds } from 'crono-fe-common/types/DTO/ProspectAndAccountIds';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';
import { Strategy } from 'crono-fe-common/types/strategy';

interface IProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selected: ProspectAndAccountIds[];
  handlePickStrategy: (strategyPicked: Strategy) => void;
  onSuccess: () => void;
}

const MultipleSequenceAdd = ({
  setOpen,
  selected,
  onSuccess,
  handlePickStrategy,
}: IProps) => {
  const {
    mutate: addToSequence,
    error: errorAddMultipleProspectToSequence,
    isSuccess,
    isLoading: loadingAddMultipleProspectToSequence,
    data,
  } = useAddMultipleProspectToSequence();

  const analytics = useJuneAnalytics();

  const handleMultipleAdd = (strategyId: number) => {
    addToSequence({ strategyId, prospects: selected });
    if (analytics) {
      analytics.track('add-multiple-to-sequence', {
        count: selected.length,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  useConditionalSnackBar([
    {
      condition: !!errorAddMultipleProspectToSequence,
      message:
        getError(errorAddMultipleProspectToSequence) ??
        'Error add multiple prospect to strategy',
      severity: 'error',
    },
    {
      condition: !!isSuccess && !!data?.data?.data,
      message: `${data?.data?.data} prospects added to strategy successfully.`,
      severity: 'success',
    },
  ]);

  return (
    <>
      <Typography style={{ padding: 6 }} fontSize={14} fontWeight={500}>
        Add the contacts to the strategy
      </Typography>
      {loadingAddMultipleProspectToSequence ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 4,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <SequenceSelector
          setOpenTooltip={setOpen}
          returnSelectedStrategy={handlePickStrategy}
        />
      )}
    </>
  );
};

export default MultipleSequenceAdd;
