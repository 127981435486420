const PATH = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CREATE_PASSWORD: '/create-password',
  CONNECT: '/connect',
  HOME: '/',
  SETTINGS: '/settings',
  INTEGRATION: '/integration',
  ANALYTICS: '/analytics',
  REPORTS: '/reports',
  COMPANY: `/company`,
  CONTACT: '/contact',
  STRATEGY: '/strategy',
  TEMPLATE: '/template',
  TASK: '/task',
  TASKS_MAIN_PAGE: '/tasks',
  ONBOARDING: '/onboarding',
  MIGRATION: '/migrating',
  COMPLETE: '/complete',
  SUBSCRIPTION_PAGE: '/subscription-page',
  EXPIRED: '/expired',
  SIGN_UP: '/signup',
  SUCCESS_SIGN_UP: '/success-signup',
  THANK_YOU: '/thank-you',
  CONNECTION_HUB: '/api/notificationshub/nots',
  REDIRECT_SETTINGS: '/redirect-settings',
};

export default PATH;
