import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SelectTemplateProps } from './types';
import { TemplatesTooltipCardWrapper } from './styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Typography,
  TextField,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import Template from './template';
import { useAuth } from 'context/auth';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { Template as _Template } from 'crono-fe-common/types/template';
import { useNavigate } from 'react-router-dom';

import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import { colors } from 'crono-fe-common/theme';
import useSearchTemplates from 'hooks/services/templates/useSearchTemplates';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { Constants } from 'crono-fe-common/constants/constants';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import TagContainerComponent, {
  TagFilterTooltip,
} from '../../settings/Template/tagTooltip/index';
import ExternalPropertyFilter from 'crono-fe-common/types/externalPropertyFilter';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { externalPropertiesToTagInsert } from 'crono-fe-common/utils';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import PATH from 'routing/path';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { createPortal } from 'react-dom';
import TemplateManagement from 'pages/settings/Template/TemplateManagement';
import { TuneRounded } from '@mui/icons-material';
import IconTopArrow from 'crono-fe-common/icons/Icon-Top-Arrow';
import Role from 'crono-fe-common/types/enums/role';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { MY_TEMPLATES } from 'config/localStorage';
import { TabValue } from 'pages/home';

const customMenuProps = {
  style: { zIndex: 10000 },
  PaperProps: {
    sx: {
      zIndex: 10001,
      borderRadius: '8px',
      paddingInline: '6px',
      border: '1px solid #dadde9',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      '& .MuiMenuItem-root': {
        fontSize: '12px',
        lineHeight: '16px',
        height: 'fit-content',
        padding: '8px',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '8px',
        '&:hover': {
          background: colors.primaryLight,
          color: colors.primary,
        },
      },
      '& .Mui-selected': {
        color: colors.primary,
        backgroundColor: 'transparent !important',
      },
    },
  },
};

const TemplatesTooltipCard: FC<SelectTemplateProps> = ({
  accountId,
  prospectId,
  selectedTemplateId,
  setSelectedTemplate,
  selectedType,
  hideShared,
  hideAll,
  showAnalytics,
  usedTemplateIds,
  close,
  account,
  prospect,
  sequenceStepTemplateId,
  allowTemplateCreation = true,
}) => {
  const { user, highestRole } = useAuth();
  const { data: users } = useGetUsers();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');
  //To show the add manually tooltips
  const [templateManagement, setOpenTemplateManagement] =
    useState<boolean>(false);

  const [selectedLanguage, setSelectedLanguage] = React.useState(
    user?.userPreferences?.templateLanguage &&
      Constants.templateLanguages.includes(
        user.userPreferences.templateLanguage,
      )
      ? user.userPreferences.templateLanguage
      : Constants.templateLanguages[0],
  );

  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [showSelectUserFilter, setShowSelectUserFilter] =
    useState<boolean>(false);

  // tag filters --------------------
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<number>(0);
  const [tooltipToggle, setTooltipToggle] = useState<boolean>(false);
  const { data: externalProperties } = useGetExternalProperty(null, true);
  const [filterExternalProperties, setFilterExternalProperties] = useState<
    ExternalPropertyFilter[]
  >([]);
  const flatTagsFilter = useMemo(() => {
    return externalPropertiesToTagInsert(filterExternalProperties);
  }, [filterExternalProperties]);
  // When a property is pressed I update the filters
  const handleChangeExternalProperty = (
    externalPropertyId: number,
    option: string,
  ) => {
    // If the option is not selected, I add it to the filters
    if (
      !filterExternalProperties
        ?.filter((ep) => ep.externalPropertyId === externalPropertyId)
        .at(0)
        ?.values?.includes(option)
    ) {
      const externalProperty =
        filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId === externalPropertyId,
        )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
      externalProperty.values = [...(externalProperty.values ?? []), option];
      const newExternalProperties: ExternalPropertyFilter[] = [
        ...(filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId !== externalPropertyId,
        ) || []),
        externalProperty,
      ];

      setFilterExternalProperties([...newExternalProperties]);
    } else {
      //Remove the filter
      const externalProperty: ExternalPropertyFilter =
        filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId === externalPropertyId,
        )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
      externalProperty.values = externalProperty.values?.filter(
        (value) => value !== option,
      );
      // If the externalProperty has no value I have to remove it from the filters (not adding it)
      const newExternalProperties: ExternalPropertyFilter[] = [
        ...(filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId !== externalPropertyId,
        ) || []),
      ];
      if (externalProperty.values && externalProperty.values?.length > 0) {
        newExternalProperties.push(externalProperty);
      }
      setFilterExternalProperties([...newExternalProperties]);
    }
  };

  const [selectedUser, setSelectedUser] = useState<number | undefined | null>(
    -1,
  );

  //Load from localStorage if we have saved my templates
  useEffect(() => {
    const myTemplates = localStorage.getItem(MY_TEMPLATES);
    if (myTemplates) {
      setSelectedUser(user?.id);
    }
  }, []);

  //Save in the localStorage if I'm the user
  useEffect(() => {
    if (selectedUser === user?.id) {
      localStorage.setItem(MY_TEMPLATES, 'true');
    } else {
      localStorage.removeItem(MY_TEMPLATES);
    }
  }, [selectedUser]);

  useEffect(() => {
    let count = 0;
    if (flatTagsFilter) {
      count = flatTagsFilter.length ?? 0;
    }
    if (selectedUser !== -1) {
      count++;
    }
    setAppliedFilters(count);
  }, [flatTagsFilter, selectedUser]);
  // --------------------------

  const {
    data: templates,
    isLoading: loadingTemplates,
    isError: fetchTemplatesError,
  } = useSearchTemplates({
    limit: Constants.defaultTemplatesLimit,
    offset: 0,
    accountId: accountId,
    prospectId: prospectId,
    type: selectedType,
    language: selectedLanguage === 'all' ? undefined : selectedLanguage,
    userId: selectedUser === -1 ? undefined : selectedUser,
    title: search,
    templateTags: flatTagsFilter.length ? flatTagsFilter : undefined,
    filterByTags: flatTagsFilter.length ? true : false,
    sequenceStepTemplateId: sequenceStepTemplateId,
    //If we are looking for the templates of another user, we have to show only the shared ones if we are not a manager
    ...(selectedUser !== user?.id &&
      highestRole !== Role.MANAGER && { shared: true }),
  });

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setSelectedLanguage(event.target.value);
    if (user) {
      if (user.userPreferences) {
        user.userPreferences.templateLanguage = event.target.value;
      } else {
        user.userPreferences = {
          templateLanguage: event.target.value,
        };
      }
    }
    patchUserPreferences({
      templateLanguage: event.target.value,
    });
  };

  if (fetchTemplatesError) throw fetchTemplatesError;

  function renderTemplateAnalyticsHeader() {
    return (
      <FlexDiv className="template-analytics-header-container">
        <Typography fontSize={'12px'} fontWeight={500}>
          Name
        </Typography>

        <div
          style={{
            display: 'flex',
            fontSize: 12,
            justifyContent: 'flex-end',
            textAlign: 'center',
          }}
        >
          <span style={{ width: 54 }}>Usage</span>
          {selectedType === TemplateType.EMAIL && (
            <span style={{ width: 54 }}>Open</span>
          )}
          <span style={{ width: 54 }}>
            {selectedType === TemplateType.INVITATION ? 'Accept' : 'Reply'}
          </span>
        </div>
      </FlexDiv>
    );
  }

  const openSettings = useCallback(() => {
    navigate(PATH.HOME, {
      state: {
        initialTab: TabValue.TEMPLATES,
        initialTemplateTab: selectedType,
      },
    });
  }, [navigate, selectedType]);

  const handleSelectTemplate = (template: _Template) => {
    setSelectedTemplate(template);
    close();
  };

  return (
    <>
      {templateManagement &&
        createPortal(
          <TemplateManagement
            close={() => {
              setOpenTemplateManagement(false);
            }}
            selectedTemplate={null}
            actualType={selectedType}
            style={{
              zIndex: 5001,
            }}
          />,
          document.body,
        )}
      <ClickAwayListener
        onClickAway={() => {
          if (!templateManagement) {
            close();
          }
        }}
        id="clickaway-template-tooltip"
      >
        <div id="wrapper-template-tooltip">
          <TemplatesTooltipCardWrapper>
            <div
              className="template-selector"
              style={{
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div className="templates-card-header">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    width: '100%',
                  }}
                >
                  <div className="search-templates">
                    <SearchIcon color={colors.grey11} />
                    <TextField
                      className="text-field"
                      variant="standard"
                      placeholder="Search..."
                      autoComplete="off"
                      InputProps={{
                        endAdornment:
                          (search || '') === '' ? null : (
                            <CloseMIcon
                              className="remove-text-icon"
                              color={colors.grey11}
                              onClick={() => setSearch('')}
                            />
                          ),
                      }}
                      sx={{
                        width: '100%',
                        input: {
                          fontWeight: '400',
                          padding: '4px 0px',
                          '&::placeholder': {
                            opacity: 1,
                            color: colors.grey11,
                          },
                        },
                      }}
                      value={search}
                      onChange={(ev) => setSearch(ev.target.value)}
                    />
                    {allowTemplateCreation && (
                      <MainButton
                        startIcon={<PlusIcon color={colors.white} />}
                        onClick={() => {
                          setOpenTemplateManagement(true);
                        }}
                        style={{
                          height: '32px',
                          margin: '0px 6px 0px 0px',
                          paddingRight: '24px',
                          fontSize: '14px',
                          width: '80px',
                          gap: '2px',
                        }}
                        sx={{
                          '& .MuiButton-startIcon': {
                            margin: 0,
                          },
                        }}
                      >
                        New
                      </MainButton>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      width: '100%',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        flex: 1,
                      }}
                    >
                      <ClickAwayListener
                        onClickAway={() => setShowSelect(false)}
                      >
                        <FormControl
                          sx={{ minWidth: 40, mt: 1, mb: 1 }}
                          size="small"
                        >
                          <Select
                            labelId="select-small"
                            id="select-small"
                            value={selectedLanguage}
                            onClick={() => setShowSelect((prev) => !prev)}
                            onChange={handleChangeLanguage}
                            open={showSelect}
                            sx={{
                              fontSize: '14px',
                              boxShadow: 'none',
                              height: '40px',
                              '.MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${colors.grey3}`,
                                borderRadius: 2,
                              },
                            }}
                            MenuProps={customMenuProps}
                          >
                            {(hideAll
                              ? Constants.templateLanguagesInput
                              : Constants.templateLanguages
                            ).map((language) => (
                              <MenuItem value={language} key={language}>
                                {Constants.languages[language]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </ClickAwayListener>
                      {!filtersVisible && (
                        <div
                          className="filter-button"
                          onClick={() => setFiltersVisible(true)}
                        >
                          <TuneRounded />
                          {appliedFilters ? (
                            <span className="number-of">{appliedFilters}</span>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        lineHeight={'16px'}
                        fontSize={12}
                        color={colors.grey11}
                        whiteSpace={'nowrap'}
                      >
                        My templates
                      </Typography>
                      <CronoSwitch
                        checked={selectedUser === user?.id}
                        onChange={(ev) => {
                          setSelectedUser((prev) => {
                            if (prev === user?.id) {
                              return -1;
                            } else {
                              return user?.id;
                            }
                          });
                        }}
                      />
                      {filtersVisible && (
                        <IconButton
                          onClick={(ev) => {
                            ev.stopPropagation();
                            ev.preventDefault();
                            setFiltersVisible(false);
                          }}
                          style={{ height: 'fit-content' }}
                        >
                          <IconTopArrow />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  {filtersVisible && (
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        width: '100%',
                        alignItems: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      {/* Tags filter */}
                      {(externalProperties?.data?.data.length ?? 0) > 0 && (
                        <ClickAwayListener
                          onClickAway={() => setTooltipToggle(false)}
                        >
                          <FormControl
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: '40px',
                            }}
                            size="small"
                          >
                            <TagFilterTooltip
                              open={tooltipToggle}
                              title={
                                <TagContainerComponent
                                  externalProperties={
                                    externalProperties?.data?.data.filter(
                                      (property) => property.isTag,
                                    ) || []
                                  }
                                  filterExternalProperties={
                                    filterExternalProperties
                                  }
                                  handleChangeExternalProperty={
                                    handleChangeExternalProperty
                                  }
                                />
                              }
                              sx={{
                                zIndex: '5001 !important',
                              }}
                            >
                              {flatTagsFilter?.length ? (
                                <div
                                  className="tag-filter-container tags-selected"
                                  onClick={() =>
                                    setTooltipToggle(!tooltipToggle)
                                  }
                                >
                                  <Typography
                                    fontSize={'14px'}
                                    style={{ flex: 1 }}
                                    noWrap
                                  >
                                    Tags ({flatTagsFilter.length} selected)
                                  </Typography>

                                  <CloseMIcon
                                    className="remove-text-icon"
                                    color={colors.grey11}
                                    onClick={() => {
                                      setFilterExternalProperties([]);
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="tag-filter-container"
                                  onClick={() =>
                                    setTooltipToggle(!tooltipToggle)
                                  }
                                >
                                  <Typography
                                    fontSize={'14px'}
                                    style={{ flex: 1 }}
                                    noWrap
                                  >
                                    Tags
                                  </Typography>
                                  {tooltipToggle ? (
                                    <TopArrowIcon className="tag-arrow" />
                                  ) : (
                                    <BottomArrowIcon className="tag-arrow" />
                                  )}
                                </div>
                              )}
                            </TagFilterTooltip>
                          </FormControl>
                        </ClickAwayListener>
                      )}
                      {/* User filter */}
                      <ClickAwayListener
                        onClickAway={() => setShowSelectUserFilter(false)}
                      >
                        <FormControl
                          sx={{ minWidth: 120, mt: 1, mb: 1 }}
                          size="small"
                        >
                          <Select
                            labelId="select-medium"
                            id="select-medium"
                            value={selectedUser ?? -1}
                            onClick={() =>
                              setShowSelectUserFilter((prev) => !prev)
                            }
                            onChange={(e) => {
                              setSelectedUser((e.target.value ?? -1) as number);
                            }}
                            open={showSelectUserFilter}
                            sx={{
                              fontSize: '14px',
                              boxShadow: 'none',
                              height: '40px',
                              '.MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${colors.grey3}`,
                                borderRadius: 2,
                              },
                            }}
                            MenuProps={customMenuProps}
                          >
                            <MenuItem value={-1}>All</MenuItem>
                            {users?.data?.data.map((user) => {
                              return (
                                <MenuItem value={user.id} key={user.id}>
                                  {user.firstName} {user.lastName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </ClickAwayListener>
                    </div>
                  )}
                </div>
              </div>
              {loadingTemplates ? (
                //The height here is given so that the first time the template loads it goes at full height, so that the tooltip can position it well
                <FlexDiv style={{ minHeight: 500 }}>
                  <CircularProgress />
                </FlexDiv>
              ) : (
                <div className="templates-container">
                  {showAnalytics &&
                    (templates?.data?.data.length || 0) > 0 &&
                    renderTemplateAnalyticsHeader()}
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden auto',
                    }}
                  >
                    {templates?.data?.data.map((template) => {
                      return (
                        <>
                          <Template
                            key={template.id}
                            template={template}
                            showAnalytics={showAnalytics}
                            handleSelectTemplate={handleSelectTemplate}
                            selected={selectedTemplateId === template.id}
                            // used={usedTemplateIds && usedTemplateIds.includes(template.id)}
                            usedForProspect={template.usedForProspect}
                            prospect={prospect}
                            account={account}
                            showTags
                          />
                          <div
                            style={{ border: `1px solid ${colors.grey4}` }}
                          />
                        </>
                      );
                    })}
                  </div>

                  {showAnalytics && templates?.data?.data.length === 0 && (
                    <div className="no-templates">
                      <Typography fontSize="14px">
                        <span
                          className="no-templates-link"
                          onClick={() => openSettings()}
                        >
                          Click
                        </span>{' '}
                        to create a new template.
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          </TemplatesTooltipCardWrapper>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default TemplatesTooltipCard;
