import { ENDPOINTS } from 'config/endpoints';
import { useAsync } from 'hooks/useAsync';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';

const useAircallAuthCallback = () => {
  const { error, isLoading, isError, isSuccess, run } = useAsync<boolean>();

  const aircallAuthCallback = (code: string) => {
    const request: Request = {
      url: ENDPOINTS.aircall.authCallback,
      config: {
        method: 'post',
        data: code,
      },
    };

    run(
      client(request).then(() => {
        return true;
      }),
    );
  };

  return { aircallAuthCallback, error, isLoading, isError, isSuccess };
};

export default useAircallAuthCallback;
