//ORDER IS IMPORTANT. IT HAS TO MATCH THE ORDER OF THE VALUES PASSED TO THE GRAPH
export const colorsChart = [
  '#D7DCEB',
  '#24BA84',
  '#F9BB06',
  '#F376D8',
  '#4E9AFF',
  '#995AFF',
  '#FD6778',
  '#1EBAB2',
];
//To avoid using grey as long as only 1 sequence is shown
export const colorsChartConversion = [
  '#24BA84',
  '#F9BB06',
  '#F376D8',
  '#4E9AFF',
  '#995AFF',
  '#FD6778',
  '#1EBAB2',
  '#D7DCEB',
];

export const SequencesOverviewLegend = [
  'Usage',
  'Active',
  'Stopped',
  'Answered',
  'Completed',
];

export const TaskOverviewLegend = [
  'Calls',
  'Emails',
  'Linkedin invitations',
  'Linkedin messages',
  'Linkedin InMails',
];

export const colorsMailTemplate = ['#FDD0D0', '#FEABB5', '#4E9AFF', '#FD6778'];

export const colorsMail = [
  '#FDD0D0',
  '#FEABB5',
  '#D7DCEB',
  '#4E9AFF',
  '#FD6778',
];

export const MailLegendTemplate = [
  'Sent',
  'Opened',
  /*"Bounced", */ 'Link clicked',
  'Replied',
];
export const MailLegend = [
  'Sent',
  'Opened',
  'Bounced',
  'Link clicked',
  'Replied',
];

export const colorsLinkedin = [
  '#FDF1CD',
  '#FCDD82',
  '#F9BB06',
  '#D4E7FE',
  '#9FC9FF',
  '#4E9AFF',
];

export const LinkedinLegend = [
  'Sent Invitations',
  'Accepted Invitations',
  'Sent Messages',
  'Replied Messages',
  'Sent InMails',
  'Replied InMails',
];

export const colorsLinkedinSmall = ['#D4E7FE', '#4E9AFF'];

export const LinkedinSmallLegend = ['Sent', 'Replied'];

export const colorsTaskCall = [
  '#F376D8',
  '#F9BB06',
  '#24BA84',
  '#4E9AFF',
  '#FD6778',
];

export const TaskCallLegend = [
  'Connected',
  'Not Answered',
  'Gatekeeper',
  'VoiceMail',
  'Wrong Number',
];

export const SuggestionOverallLegend = ['Pending', 'Completed', 'Deleted'];
export const colorsSuggestionOverall = ['#F9BB06', '#24BA84', '#FD6778'];

export const colorsHeatmapAll = [
  '#F4FCFB',
  '#DDF5F4',
  '#C0ECE9',
  '#A5E3E0',
  '#83D9D5',
  '#80D8D4',
  '#1EBAB2',
];

export const colorsHeatMapCall = [
  '#F4FCF9',
  '#DEF5ED',
  '#C2ECDD',
  '#A7E3CE',
  '#87D9BB',
  '#5BCBA3',
  '#24BA84',
];

export const colorsHeatMapEmail = [
  '#FFF7F8',
  '#FFE8EB',
  '#FED4D9',
  '#FEC2C9',
  '#FEABB5',
  '#FE8D9A',
  '#FD6778',
];

export const colorsHeatMapLinkedin = [
  '#F6FAFF',
  '#E4F0FF',
  '#CDE3FF',
  '#B8D7FF',
  '#9EC7FF',
  '#7AB3FF',
  '#4E9AFF',
];

export const suggestionsDonutTitle = [
  'Missing email',
  'Missing phone',
  'Missing LinkedIn URL',
  'Missing contact title',
];

export const colorsSuggestionsDonut = [
  //["#0D8680", "#CEEDED"],
  ['#FD6778', '#FDD0D0'],
  ['#24BA84', '#E9F8F8'],
  ['#4E9AFF', '#EAF1FB'],
  ['#F376D8', '#FDE5F8'],
];

//Pairs of base color and hover color
export const colorsSuggestionsDonutHover = [
  ['#0D8680', '#0C5551'],
  ['#1EBAB2', '#0A9B94'],
  ['#E9F8F8', '#CEEDED'],
  ['#FD6778', '#ED4C5E'],
  ['#FFE2E2', '#FDD0D0'],
  ['#24BA84', '#1A9D6E'],
  ['#E9F8F8', '#CEEDED'],
  ['#4E9AFF', '#3B85E8'],
  ['#EAF1FB', '#D5E0F0'],
  ['#F9BB06', '#E2AD13'],
  ['#FEF3D2', '#F8E6B1'],
  ['#F376D8', '#E769CB'],
  ['#FDE5F8', '#F3CEEB'],
];

export const suggestionsDonutTooltipTitle = [
  'emails',
  'phones',
  'LinkedIn URLs',
  'contact titles',
];
