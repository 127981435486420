import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { EmailSettings } from 'crono-fe-common/types/emailSettings';

export default function usePatchGoogleSignature() {
  const patchGoogleSignature = () => {
    const request: Request = {
      url: `${ENDPOINTS.google.signature}`,
      config: {
        method: 'patch',
      },
    };
    return client(request);
  };

  return useMutation<Response<EmailSettings | null>, CronoError, void>(
    patchGoogleSignature,
    {
      onSettled: () => {},
    },
  );
}
