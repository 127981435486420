import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const AddTemplateIconS = ({
  color = colors.grey11,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
      id="add_template_icon"
    >
      <path
        id="Path"
        d="M3 11.498V7.27582C3 6.2936 3.895 5.49805 5 5.49805H16C17.105 5.49805 18 6.2936 18 7.27582V19.7203C18 20.7025 17.105 21.498 16 21.498H11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M18 15.4961L18 19.7183C18 20.7005 17.105 21.4961 16 21.4961L5 21.4961C3.895 21.4961 3 20.7005 3 19.7183L3 7.27387C3 6.29165 3.895 5.49609 5 5.49609L10 5.49609"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M6 5.49805V4.27582C6 3.2936 6.895 2.49805 8 2.49805H19C20.105 2.49805 21 3.2936 21 4.27582V16.7203C21 17.7025 20.105 18.498 19 18.498H18"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M15.0037 8.99826H6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M15 12.9961H6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        id="Path_6"
        d="M12 17H6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddTemplateIconS;
