import { useSelectedAccount } from 'pages/home/context/account';
import { useEffect, useState } from 'react';
import { AccountTabWrapper, HideProgressWrapper } from './style';
import { Typography } from '@mui/material';
import ContactsView from './contactView';
import WorkloadsView from './taskView';
import { PhoneOption, WindowTab, parseTab } from 'context/sideTab/types';

import EmailView from './emailView/refactoredEmailView';
import { useWindow } from 'context/sideTab';
import { CloseButton } from 'crono-fe-common/components/CronoButton';
import { colors } from 'crono-fe-common/theme';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import AccountView from './accountView';
import CallLogView from './callLogView';
import { Task } from 'crono-fe-common/types/task';
import LinkedinView from './linkedinView/index';
import { useHideTaskConstruction } from './hideTaskConstructionContext';
import { ReactComponent as IconPrepareNextTask } from 'crono-fe-common/icons/Preparing_next_task.svg';
import OpportunitiesView from './opportunityView';
import CallView from './callView';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';
import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import IconCheckmarkCalendar from 'crono-fe-common/icons/Icon-Checkmark-Calendar';
import IconPriceTag from 'crono-fe-common/icons/Icon-Pricetag';
import AccountTabTab from './tab';
import { AccountTabsContainer } from './tab/style';
import LinkedinIconInMailOnlyCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass';
import InMailView from './inmailView';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import CloseSIcon from 'crono-fe-common/icons/Icon-Close-S';
import { useAuth } from 'context/auth';

export type LinkedinTabLabel = 'Message' | 'Voicenote' | 'Invitation';

interface IProps {
  selectedProspectId?: string;
  modalVisible?: WindowTab;
  selectedThreadId?: {
    threadId: string | null;
    toAll: boolean;
  } | null;
  callOnSuccess?: () => void;
  suggestionId?: number | null;
  close: () => void;
  handleRemoveModal: () => void;
  defaultOpenTemplateTab?: boolean;
  activity?: Task | null;
  selectedOpportunityId?: string | null;
  initialPhoneSelected?: PhoneOption;
}

const AccountTab = ({
  selectedProspectId,
  modalVisible: modalVisibleDefault,
  selectedThreadId,
  callOnSuccess,
  suggestionId,
  close,
  handleRemoveModal,
  defaultOpenTemplateTab,
  activity,
  selectedOpportunityId,
  initialPhoneSelected,
}: IProps) => {
  const { hasOpportunities } = useAuth();
  const { account } = useSelectedAccount();
  const [value, setValue] = useState<number>(0);

  const [modalVisible, setModalVisible] = useState<WindowTab | null>(
    modalVisibleDefault ?? null,
  );

  const { hideProgressTask, setHideProgressTask } = useHideTaskConstruction();

  //Use effect to remove the hide progress task when the component is unmounted before the complete construction of the task
  useEffect(() => {
    return () => {
      setHideProgressTask(false);
    };
  }, []);

  useEffect(() => {
    setModalVisible(modalVisibleDefault ?? null);
  }, [modalVisibleDefault]);

  useEffect(() => {
    if (modalVisible) {
      setValue(parseTab(modalVisible));
    }
  }, [modalVisible]);

  const { windowContent } = useWindow();
  const task = windowContent?.task;

  useEffect(() => {
    if (windowContent?.tab) {
      setValue(parseTab(windowContent?.tab));
    }
  }, [windowContent?.tab, windowContent?.forceReload]);

  const handleCloseModal = () => {
    //If I'm in the modalTab, I go back to the company
    if (value >= 3) {
      setValue(0);
    }
    setModalVisible(null);
    handleRemoveModal();
  };

  //Label to show in the linkedin tab depending on the type of linkedin task
  const [linkedinTabLabel, setLinkedinTabLabel] =
    useState<LinkedinTabLabel>('Message');

  return (
    <AccountTabWrapper id="account-tab-wrapper" padding="0px">
      {hideProgressTask && (
        <HideProgressWrapper>
          <CloseButton
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              close();
            }}
            style={{ width: 'fit-content' }}
          >
            <CloseMIcon color={colors.grey11} />
          </CloseButton>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              alignContent: 'center',
              width: '100%',
              gap: 12,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 12,
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  position: 'relative',
                }}
              >
                <IconPrepareNextTask
                  style={{ width: '116px', height: '90px' }}
                />
                <span className="loader" />
              </div>
              <Typography
                fontSize={16}
                fontWeight={600}
                lineHeight={'24px'}
                width={'fit-content'}
              >
                We're preparing the next task
              </Typography>
            </div>
          </div>
        </HideProgressWrapper>
      )}
      <div
        id="account-tab-top-header"
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 18,
          paddingLeft: 18,
          paddingTop: 18,
          backgroundColor: colors.grey6,
        }}
      >
        <CloseButton
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            close();
          }}
        >
          <CloseMIcon color={colors.grey11} />
        </CloseButton>
        <Typography variant="h5" fontWeight={700}>
          {account?.name}
        </Typography>
      </div>

      <div id="tab-header" className="tabs-header-container">
        <AccountTabsContainer>
          {modalVisible === 'email' ? (
            <AccountTabTab
              label="Email"
              selected={value === 3}
              onClick={() => setValue(3)}
              endIcon={
                <CloseSIcon
                  color={colors.grey11}
                  onClick={(ev: any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    handleCloseModal();
                  }}
                />
              }
              icon={
                <EmailIcon
                  color={value === 3 ? colors.darkGreen : colors.black}
                />
              }
            />
          ) : modalVisible === 'linkedin' ? (
            <AccountTabTab
              label={linkedinTabLabel}
              selected={value === 4}
              onClick={() => setValue(4)}
              endIcon={
                <CloseSIcon
                  color={colors.grey11}
                  onClick={(ev: any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    handleCloseModal();
                  }}
                />
              }
              icon={
                <LinkedinIcon
                  color={value === 4 ? colors.darkGreen : colors.black}
                />
              }
            />
          ) : modalVisible === 'call' ? (
            <AccountTabTab
              label="Call"
              selected={value === 5}
              onClick={() => setValue(5)}
              endIcon={
                <CloseSIcon
                  color={colors.grey11}
                  onClick={(ev: any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    handleCloseModal();
                  }}
                />
              }
              icon={
                <CallIcon
                  color={value === 5 ? colors.darkGreen : colors.black}
                />
              }
            />
          ) : modalVisible === 'callLog' ? (
            <AccountTabTab
              label="Call Log"
              selected={value === 6}
              onClick={() => setValue(6)}
              endIcon={
                <CloseSIcon
                  color={colors.grey11}
                  onClick={(ev: any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    handleCloseModal();
                  }}
                />
              }
              icon={
                <CallIcon
                  color={value === 6 ? colors.darkGreen : colors.black}
                />
              }
            />
          ) : modalVisible === 'inmail' ? (
            <AccountTabTab
              label="InMail"
              selected={value === 8}
              onClick={() => setValue(8)}
              endIcon={
                <CloseSIcon
                  color={colors.grey11}
                  onClick={(ev: any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    handleCloseModal();
                  }}
                />
              }
              icon={
                <LinkedinIconInMailOnlyCompass
                  color={value === 8 ? colors.darkGreen : colors.black}
                />
              }
            />
          ) : null}
          {modalVisible && (
            <div
              className="tabs-divider"
              style={{
                visibility: value === 0 ? 'hidden' : 'visible',
              }}
            />
          )}
          <AccountTabTab
            selected={value === 0}
            onClick={() => setValue(0)}
            icon={
              <IconMCompany
                color={value === 0 ? colors.darkGreen : colors.black}
              />
            }
            label="Company"
          />
          <div
            className="tabs-divider"
            style={{
              visibility: value === 0 || value === 1 ? 'hidden' : 'visible',
            }}
          />
          <AccountTabTab
            selected={value === 1}
            onClick={() => setValue(1)}
            icon={
              <IconMContact
                color={value === 1 ? colors.darkGreen : colors.black}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'inherit',
                }}
              >
                Contacts
                {value === 1 && (
                  <span className="inner-number-tab">
                    {account?.totalProspects ??
                      account?.prospects?.length ??
                      ''}
                  </span>
                )}
              </div>
            }
          />
          <div
            className="tabs-divider"
            style={{
              visibility: value === 1 || value === 2 ? 'hidden' : 'visible',
            }}
          />
          <AccountTabTab
            selected={value === 2}
            onClick={() => setValue(2)}
            icon={
              <IconCheckmarkCalendar
                color={value === 2 ? colors.darkGreen : colors.black}
              />
            }
            label="Activities"
          />
          {hasOpportunities && (
            <>
              <div
                className="tabs-divider"
                style={{
                  visibility: value === 2 || value === 7 ? 'hidden' : 'visible',
                }}
              />
              <AccountTabTab
                selected={value === 7}
                onClick={() => setValue(7)}
                icon={
                  <IconPriceTag
                    color={value === 7 ? colors.darkGreen : colors.black}
                  />
                }
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Deals
                    {value === 7 && (
                      <span className="inner-number-tab">
                        {account?.totalOpportunities ?? ''}
                      </span>
                    )}
                  </div>
                }
              />
            </>
          )}
        </AccountTabsContainer>
      </div>
      {/* Fixed tabs */}
      {value === 0 ? (
        <AccountView setTabValue={setValue} />
      ) : value === 1 ? (
        <ContactsView
          selectedProspectId={selectedProspectId}
          setTabValue={setValue}
          close={close}
        />
      ) : value === 2 ? (
        <WorkloadsView setTabValue={setValue} />
      ) : value === 7 ? (
        <OpportunitiesView
          setTabValue={setValue}
          selectedOpportunityId={selectedOpportunityId}
        />
      ) : null}
      {/* Dynamic/Variable tabs */}
      {modalVisible === 'email' ? (
        <EmailView
          task={task}
          selectedProspectId={selectedProspectId}
          selectedThreadId={selectedThreadId}
          callOnSuccess={callOnSuccess}
          suggestionId={suggestionId}
          handleCloseModal={handleCloseModal}
          defaultOpenTemplateTab={defaultOpenTemplateTab}
          visibility={value === 3 ? 'visible' : 'none'}
          setTabValue={setValue}
        />
      ) : modalVisible === 'linkedin' ? (
        <LinkedinView
          task={task}
          selectedProspectId={selectedProspectId}
          callOnSuccess={callOnSuccess}
          suggestionId={suggestionId}
          handleCloseModal={handleCloseModal}
          defaultOpenTemplateTab={defaultOpenTemplateTab}
          visibility={value === 4 ? 'visible' : 'none'}
          close={close}
          setTabValue={setValue}
          setLinkedinTabLabel={setLinkedinTabLabel}
        />
      ) : modalVisible === 'call' ? (
        <CallView
          selectedProspectId={selectedProspectId}
          setTabValue={setValue}
          task={task}
          callOnSuccess={callOnSuccess}
          suggestionId={suggestionId}
          handleCloseModal={handleCloseModal}
          visibility={value === 5 ? 'visible' : 'none'}
          initialPhoneSelected={initialPhoneSelected}
        />
      ) : modalVisible === 'callLog' ? (
        value === 6 && (
          <CallLogView
            selectedProspectId={selectedProspectId}
            activity={activity}
            visibility={value === 6 ? 'visible' : 'none'}
          />
        )
      ) : modalVisible === 'inmail' ? (
        <InMailView
          task={task}
          selectedProspectId={selectedProspectId}
          callOnSuccess={callOnSuccess}
          suggestionId={suggestionId}
          handleCloseModal={handleCloseModal}
          defaultOpenTemplateTab={defaultOpenTemplateTab}
          visibility={value === 8 ? 'visible' : 'none'}
          close={close}
          setTabValue={setValue}
        />
      ) : null}
    </AccountTabWrapper>
  );
};

export default AccountTab;
