import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { PlanWrapper, UserPickPlanOptionsWrapper } from './style';
import { SubscriptionProductDTO } from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';
import { BillingPlan } from 'pages/signup/model';
import { PlanOptions } from './planOptions';
import { Options, Plan } from 'pages/signup';
import BillingSwitch from './billingSwitch';

interface IProps {
  formik: any;
  subscriptionProducts: SubscriptionProductDTO[] | undefined;
  close: () => void;
  billingPlan: BillingPlan;
  setBillingPlan: React.Dispatch<React.SetStateAction<BillingPlan>>;
  setSelectedPlan: React.Dispatch<React.SetStateAction<number>>;
  options: Options[];
  subscription: string | undefined;
  pricing: string | undefined;
  selectedPlan: number;
}

const UserPickPlans = ({
  formik,
  subscriptionProducts,
  close,
  setBillingPlan,
  setSelectedPlan,
  options,
  subscription,
  pricing,
  selectedPlan,
  billingPlan,
}: IProps) => {
  // Values set from URL on mounting component
  useEffect(() => {
    if (subscription && pricing) {
      setBillingPlan(pricing as BillingPlan);
      setSelectedPlan((prev) => {
        switch (subscription) {
          case SubscriptionPlanType.STARTER:
            return Plan.STARTER;
          case SubscriptionPlanType.PRO:
            return Plan.PRO;
          case SubscriptionPlanType.ULTRA:
            return Plan.ULTRA;
          default:
            return prev;
        }
      });
      if (options[selectedPlan]) {
        const { id, monthlyTotalCycle } = options[selectedPlan].prices.filter(
          (price) => price.paymentCycle === billingPlan,
        )[0];
        formik.setFieldValue('plan', subscription);
        formik.setFieldValue('billed', pricing);
        formik.setFieldValue('priceId', id);
        formik.setFieldValue('price', monthlyTotalCycle);
      }
    }
  }, []);

  useEffect(() => {
    if (formik && options[selectedPlan]) {
      const { id, monthlyTotalCycle } = options[selectedPlan].prices.filter(
        (price) => price.paymentCycle === billingPlan,
      )[0];
      formik.setFieldValue('plan', options[selectedPlan]?.subscriptionType);
      formik.setFieldValue('billed', billingPlan);
      formik.setFieldValue('priceId', id);
      formik.setFieldValue('price', monthlyTotalCycle);
    }
  }, [selectedPlan, billingPlan, subscriptionProducts]);

  return (
    <UserPickPlanOptionsWrapper id="user-pick-plans-options">
      <PlanWrapper id="inner-wrapper">
        <div className="center-container">
          <div className="plan-container">
            <div className="suggestion-container">
              <div className="title">
                <Typography
                  fontSize={'28px'}
                  fontWeight={700}
                  lineHeight={'30px'}
                  textAlign={'center'}
                >
                  1. Choose plan
                </Typography>
              </div>
              <BillingSwitch
                options={options}
                selectedPlan={selectedPlan}
                billingPlan={billingPlan}
                setBillingPlan={setBillingPlan}
              />
            </div>
            <PlanOptions
              close={close}
              billingPlan={billingPlan}
              setSelectedPlan={setSelectedPlan}
              options={options}
              selectedPlan={selectedPlan}
            />
          </div>
        </div>
      </PlanWrapper>
    </UserPickPlanOptionsWrapper>
  );
};

export default UserPickPlans;
