import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SubscriptionPlansBodyWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;
  .suggestion-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    position: relative;
  }
  .blur {
    &:after {
      content: '';
      position: absolute;
      inset: 0%;
      backdrop-filter: brightness(1.1);
      -webkit-mask: linear-gradient(180deg, transparent, white 90%);
      height: 50px;
      top: 225px;
    }
  }
  .compare-features {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: ${colors.primaryDark};
  }
  .compare-features > svg > path {
    stroke: ${colors.primaryDark};
  }
  .compare-features:hover {
    color: ${colors.darkGreenHover};
    cursor: pointer;
  }
  .compare-features:hover > svg > path {
    stroke: ${colors.darkGreenHover};
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-width: 330px;
  }
  .billing-switch-container {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .badge {
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 8px;
  }
  .discount {
    color: ${colors.darkGreen};
    background-color: ${colors.primaryLight};
  }
  .popular-plan,
  .ultra-plan {
    color: ${colors.white};
  }
  .current-plan {
    color: ${colors.intenseBlue};
    background-color: ${colors.intenseBlueChipBackground};
  }
  .popular-plan {
    background-color: ${colors.popularPlanBadge};
  }
  .ultra-plan {
    background-color: ${colors.ultraPlanBadge};
  }

  .bold {
    font-weight: 700;
  }
  .plans-container {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    padding-bottom: 24px;
  }
  .plan {
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 290px;
    width: 330px;
  }
  .plan-header {
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
    padding: 24px;
    gap: 20px;
    position: relative;
    min-height: 212px;
  }
  .Pro {
    color: ${colors.white};
    background-color: ${colors.popularPlanGreen};
  }
  .Ultra {
    color: ${colors.white};
    background-color: ${colors.ultraPlanDarkGreen};
    position: relative;
  }
  .ultra-logo-container {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .plan-pricing {
    display: flex;
    gap: 20px;
  }
  .plan-billing {
    display: flex;
    flex-direction: column;
  }
  .upgrade-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 32px;
    color: ${colors.black};
    background-color: ${colors.white};
    cursor: pointer;
    height: 48px !important;
  }
  .upgrade-button:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
  .Pro,
  .Ultra,
  .Pro-upgrade-button,
  .Ultra-upgrade-button {
    border: 1px solid transparent;
  }
  .Starter-upgrade-button {
    border: 1px solid ${colors.grey444};
  }
  .Starter-upgrade-button:hover {
    border: 1px solid ${colors.primary};
  }
  .plan-footer {
    display: flex;
    flex-direction: column;
  }
  .feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
  }
  .feature-checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primaryLight};
    width: 24px;
    height: 24px;
    padding: 5px;
    border-radius: 12px;
  }
  .includes-previous-feature {
    background-color: ${colors.primaryLight};
    border-radius: 12px;
    font-weight: 700;
    color: ${colors.darkGreenHover};
  }
  .includes-previous-feature-icon {
    background-color: ${colors.primary};
  }

  @media screen and (max-width: 768px) {
    .suggestion-container {
      flex-direction: column;
      gap: 20px;
    }
    .title {
      text-align: center;
    }
  }
  .disable-plan {
    opacity: 0.6;
    cursor: not-allowed;
    .upgrade-button {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .disable-due-to-upgrade {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
  }
`;
