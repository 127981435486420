import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { JumpToSequenceInstanceStepInputs } from 'crono-fe-common/types/DTO/jumpToSequenceInstanceStepInputs';

export default function useJumpToSequenceInstanceStep() {
  const queryClient = useQueryClient();

  const jumpToSequenceInstanceStep = (
    data: JumpToSequenceInstanceStepInputs,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.prospect}`,
      config: {
        method: 'patch',
        data: data,
      },
    };
    return client(request);
  };

  return useMutation<
    Response<null>,
    CronoError,
    JumpToSequenceInstanceStepInputs
  >(jumpToSequenceInstanceStep, {
    onSettled: (result, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.account.main]);
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.search.strategy]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.prospect]);
    },
  });
}
