import { useJuneAnalytics } from 'context/june';
import { FC, useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { ErrorPageWrapper } from './errorPageStyle';

import Logo from '../assets/images/logo_crono.png';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import { ReactComponent as ErrorGear } from '../assets/images/Error_gear.svg';

const FullpageErrorFallBack: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const analytics = useJuneAnalytics();

  useEffect(() => {
    if (analytics) {
      analytics.track('fatal-error-app', {
        error: error,
      });
    }
  }, []);

  return (
    <ErrorPageWrapper>
      <img src={Logo} alt="logo" className="error-logo" />
      <div className="error-container">
        <div>
          <ErrorGear />
        </div>
        <Typography
          fontSize={24}
          fontWeight={700}
          lineHeight={'30px'}
          textAlign={'center'}
        >
          Oops! System Recovery in Progress
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={500}
          lineHeight={'24px'}
          color={colors.grey11}
          align="center"
        >
          Sorry, the system is currently down.
          <br />
          We are already working on fixing the problem.
        </Typography>
        <MainPrimaryButton onClick={resetErrorBoundary}>
          Back to Dashboard
        </MainPrimaryButton>
      </div>
    </ErrorPageWrapper>
  );
};

export default FullpageErrorFallBack;
