import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SequenceStepTemplatePatch } from 'crono-fe-common/types/DTO/sequenceStepTemplatePatch';
import { PersonalizedTemplatePostDTO } from 'crono-fe-common/types/DTO/personalizedTemplatePostDTO';

export default function useCreatePersonalizedTemplate() {
  const queryClient = useQueryClient();

  const createPersonalizedTemplate = (
    personalizedTemplatePost: PersonalizedTemplatePostDTO,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.personalizedTemplate.main}`,
      config: {
        method: 'post',
        data: { data: personalizedTemplatePost },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, PersonalizedTemplatePostDTO>(
    createPersonalizedTemplate,
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENDPOINTS.task.search]);
        queryClient.invalidateQueries([ENDPOINTS.task.overdue]);
        queryClient.invalidateQueries([ENDPOINTS.task.next]);
        queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
        queryClient.invalidateQueries([ENDPOINTS.task.total]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.prospect]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
        queryClient.invalidateQueries([ENDPOINTS.prospect.search.strategy]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
        queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);
      },
    },
  );
}
