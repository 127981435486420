import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

interface SyncExternalPropertyDTO {
  externalPropertyIds: number[];
}

export default function useSyncExternalProperty() {
  const queryClient = useQueryClient();

  const syncExternalProperty = (data: SyncExternalPropertyDTO) => {
    const request: Request = {
      url: `${ENDPOINTS.externalProperty.sync}`,
      config: {
        method: 'post',
        data: data,
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, SyncExternalPropertyDTO>(
    syncExternalProperty,
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENDPOINTS.externalProperty.main]);
        queryClient.invalidateQueries([ENDPOINTS.externalProperty.status.main]);
        queryClient.invalidateQueries([ENDPOINTS.externalProperty.usage]);
      },
    },
  );
}
