import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import { Note } from 'crono-fe-common/types/note';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export default function useCreateNote() {
  const queryClient = useQueryClient();

  const createNote = (noteInput: Omit<Note, 'id'>) => {
    const request: Request = {
      url: `${ENDPOINTS.notes.main}`,
      config: {
        method: 'post',
        data: { data: noteInput },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    Omit<Note, 'id'>
  >(createNote, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.notes.main]);
      queryClient.invalidateQueries([ENDPOINTS.opportunity.main]);
      queryClient.invalidateQueries([ENDPOINTS.opportunity.search]);
    },
  });

  return { data, mutate, ...rest };
}
