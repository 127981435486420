import {
  CreateStrategyViewWrapper,
  AddStepNumberCountWrapper,
  AddStepPlaceholderWrapper,
} from './style';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import React from 'react';

import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import { ReactComponent as CreateStrategyIllustration } from 'assets/images/Illustration_Create_Strategy.svg';

interface IProps {
  onAddClick: () => void;
}

const CreateStrategyView = ({ onAddClick }: IProps) => {
  return (
    <CreateStrategyViewWrapper>
      <div className="dashed-line">
        <AddStepNumberCount
          number={1}
          className="dashed-line__number-container"
        />
        <div className="dashed-line__triangle" />
      </div>

      <AddStepPlaceholder onClick={onAddClick} text={'Add first step'} />

      <div className={'instructions-container'}>
        <CreateStrategyIllustration />
        <Typography fontSize={16} lineHeight={'24px'} fontWeight={600}>
          Start creating your strategy
        </Typography>
        <Typography
          fontSize={14}
          lineHeight={'24px'}
          color={colors.grey11}
          width={390}
          textAlign={'center'}
        >
          Change the strategy title, assign tags and start adding steps to build
          a successful strategy
        </Typography>
        <div className={'background-blur-panel'} />
      </div>
    </CreateStrategyViewWrapper>
  );
};

interface IAddStepNumberCountProps {
  number: number;
  className?: string;
}

const AddStepNumberCount = ({
  number,
  className,
}: IAddStepNumberCountProps) => {
  return (
    <AddStepNumberCountWrapper className={className}>
      <span className={`number-count`}>{number}</span>
    </AddStepNumberCountWrapper>
  );
};

interface IAddStepPlaceholderProps {
  onClick: () => void;
  text: string;
  className?: string;
}

const AddStepPlaceholder = ({
  onClick,
  text,
  className,
}: IAddStepPlaceholderProps) => {
  return (
    <AddStepPlaceholderWrapper className={className} onClick={onClick}>
      <div className={'plus-icon'}>
        <PlusSmallIcon color={colors.white} />
      </div>
      <Typography fontSize={14} fontWeight={500}>
        {text}
      </Typography>
    </AddStepPlaceholderWrapper>
  );
};

export { AddStepNumberCount, CreateStrategyView, AddStepPlaceholder };
