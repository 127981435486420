import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconAircallWhite = ({
  color = colors.white,
  className,
  onClick,
  logoColor = '#24BA84',
}: IProps & { logoColor?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.096 0.516C16.74 0.204 14.52 0 12 0C9.48 0 7.26 0.204 5.904 0.516C3.204 1.08 1.08 3.204 0.516 5.904C0.204 7.26 0 9.48 0 12C0 14.52 0.204 16.74 0.516 18.096C1.08 20.796 3.204 22.92 5.904 23.484C7.26 23.796 9.48 24 12 24C14.52 24 16.74 23.796 18.096 23.484C20.796 22.92 22.92 20.796 23.484 18.096C23.796 16.74 24 14.52 24 12C24 9.48 23.796 7.26 23.484 5.904C22.92 3.204 20.796 1.08 18.096 0.516Z"
          fill={color}
        />
        <path
          d="M14.88 18.4195C14.652 17.8795 14.172 17.4715 13.584 17.3515C13.236 17.2675 12.648 17.2195 12 17.2195C11.352 17.2195 10.764 17.2675 10.416 17.3515C9.82802 17.4715 9.34802 17.8795 9.12002 18.4195C9.03602 18.6115 8.84402 18.7195 8.65202 18.6955C8.60402 18.6955 8.55602 18.6835 8.52002 18.6835C7.58402 18.5875 6.84002 18.4555 6.40802 18.3115C6.12002 18.2035 5.91602 17.9275 5.91602 17.6035C5.91602 17.5915 5.91602 17.5915 5.91602 17.5795C5.91602 17.5675 5.91602 17.5555 5.91602 17.5435V17.5315C5.91602 17.5195 5.91602 17.5195 5.91602 17.5075C5.98802 16.5235 6.58802 14.2315 7.47602 11.6155C8.49602 8.6275 9.55202 6.2035 10.032 5.7235C10.104 5.6395 10.2 5.5795 10.296 5.5315C10.332 5.5075 10.38 5.4835 10.44 5.4715C10.752 5.3635 11.34 5.2915 12 5.2915C12.66 5.2915 13.248 5.3635 13.56 5.4715C13.62 5.4955 13.668 5.5075 13.704 5.5315C13.8 5.5795 13.884 5.6515 13.968 5.7235C14.448 6.1915 15.504 8.6155 16.524 11.6155C17.412 14.2315 18.012 16.5235 18.084 17.5075C18.084 17.5195 18.084 17.5195 18.084 17.5315V17.5435C18.084 17.5555 18.084 17.5675 18.084 17.5795C18.084 17.5915 18.084 17.5915 18.084 17.6035C18.084 17.9275 17.88 18.2035 17.592 18.3115C17.16 18.4675 16.428 18.5995 15.48 18.6835C15.444 18.6835 15.384 18.6955 15.336 18.6955C15.144 18.7075 14.964 18.5995 14.88 18.4195Z"
          fill={logoColor}
        />
      </svg>
    </svg>
  );
};

export default IconAircallWhite;
