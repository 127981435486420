export enum SuggestionType {
  // Find new contacts within
  GenerateLeads = 'GenerateLeads',

  // Find new companies
  GenerateAccounts = 'GenerateAccounts',

  // Find phone number/linkedin/email (they are in the old one)
  Precondition = 'Precondition',

  // The first one in the UI
  FollowUpMeeting = 'FollowUpMeeting',

  // First in the UI with different text
  FollowUpOpportunity = 'FollowUpOpportunity',

  // Change text for the two (yes/no) on yes start of the sequence
  StartSequenceForOldProspects = 'StartSequenceForOldProspects',
  StartSequenceForNewProspects = 'StartSequenceForNewProspects',

  // Second in the UI
  EmailMultipleRead = 'EmailMultipleRead',

  // Second in the UI with different text
  EmailReplied = 'EmailReplied',

  // Second in the UI with yet another different text
  LinkedinReplied = 'LinkedinReplied',

  // Yes/no (stopSequence)
  StopSequencesForReply = 'StopSequencesForReply',

  // Yes/no (stopSequence)
  StopSequencesForChangeStatus = 'StopSequencesForChangeStatus',

  //ATTENTION: this is not used for the moment. If ever resumed, be careful that the new status logic is not implemented for it
  // Last in the UI (yes/no) api changeStatus
  SuggestedAccountStatus = 'SuggestedAccountStatus',

  // Suggest the user to change an invalid email/phone
  SuggestClearData = 'SuggestClearData',

  // Suggest the user to follow up a prospect after this one has changed role
  SuggestNewRole = 'SuggestNewRole',

  // Suggest the user to update the company of a prospect when a new one is found
  SuggestNewCompany = 'SuggestNewCompany',
}
