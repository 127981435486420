import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const BlurBackgroundDivFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.black}66;
  z-index: 5000;
`;

export const BlurBackgroundDivAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.black}66;
  z-index: 5000;
`;
