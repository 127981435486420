import Home, { TabValue } from 'pages/home';
import PATH from './path';

//module routes
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import Login from 'pages/login';
import connectRoutes from 'pages/connect/routes';
import FullpageErroFallBack from 'app/errorPage';
import CompanyInterceptor from './companyInterceptor';
import ContactInterceptor from './contactInterceptor';
import StrategyInterceptor from './strategyInterceptor';
import TemplateInterceptor from './templateInterceptor';
import TaskInterceptor from './taskInterceptor';
import OnBoarding from 'pages/onboarding';
import Migration from 'pages/migration';
import CompleteSubscription from 'pages/subscription';
import ExpiredPlan from 'pages/subscriptionPlans/expired';
import SubscriptionPageInterceptor from './subscriptionPageInterceptor';
import SignUp from 'pages/signup';
import SuccessSignUpInterceptor from './successSignUpInterceptor';
import ThankYouPage from 'pages/thankYouPage';
import ResetPasswordPage from 'pages/resetPassword';
import ForgotPasswordPage from 'pages/forgotPassword';
import SettingsInterceptor from './settingsInterceptor';
import TasksMainPageInterceptor from './tasksMainPageInterceptor';

export const Routes: RouteObject[] = [
  {
    path: PATH.CONNECT,
    element: <Outlet />,
    children: connectRoutes,
  },
  {
    path: PATH.THANK_YOU,
    element: <ThankYouPage />,
  },
  {
    path: PATH.SUCCESS_SIGN_UP,
    element: <SuccessSignUpInterceptor />,
  },
  {
    path: PATH.SIGN_UP,
    element: <SignUp />,
  },
  {
    path: PATH.EXPIRED,
    element: <ExpiredPlan />,
  },
  {
    path: PATH.COMPLETE,
    element: <CompleteSubscription />,
  },
  {
    path: PATH.ONBOARDING,
    element: <OnBoarding />,
  },
  {
    path: PATH.MIGRATION,
    element: <Migration />,
  },
  {
    path: PATH.LOGIN,
    element: <Login />,
  },
  {
    path: PATH.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
  },
  {
    path: PATH.RESET_PASSWORD,
    element: <ResetPasswordPage />,
  },
  {
    path: PATH.CREATE_PASSWORD,
    element: <ResetPasswordPage createPasswordView={true} />,
  },
  {
    path: PATH.COMPANY,
    element: <CompanyInterceptor />,
  },
  {
    path: PATH.CONTACT,
    element: <ContactInterceptor />,
  },
  {
    path: PATH.STRATEGY,
    element: <StrategyInterceptor />,
  },
  {
    path: PATH.TEMPLATE,
    element: <TemplateInterceptor />,
  },
  {
    path: PATH.REDIRECT_SETTINGS,
    element: <SettingsInterceptor />,
  },
  {
    path: PATH.TASK,
    element: <TaskInterceptor />,
  },
  {
    path: PATH.TASKS_MAIN_PAGE,
    element: <TasksMainPageInterceptor />,
  },
  {
    path: PATH.SUBSCRIPTION_PAGE,
    element: <SubscriptionPageInterceptor />,
  },
  {
    path: '/*',
    element: <Home />,
  },
];
