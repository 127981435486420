import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { LogCall } from 'crono-fe-common/types/logCall';

export default function useLogCall() {
  const queryClient = useQueryClient();

  const insertCall = (logCall: LogCall) => {
    const request: Request = {
      url: `${ENDPOINTS.events.call}`,
      config: {
        method: 'post',
        data: { data: logCall },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    LogCall
  >(insertCall, {
    onSettled: (_r, _e, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.events.task.main]);
      queryClient.invalidateQueries([ENDPOINTS.events.event]);
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
      queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
      queryClient.invalidateQueries([ENDPOINTS.task.total]);
      //WARNING: this is commented since the next task is called from the context of multiple execution manually, to improve synchronization
      // queryClient.invalidateQueries([ENDPOINTS.task.next]),
      queryClient.invalidateQueries([ENDPOINTS.task.overdue]);
      queryClient.invalidateQueries([ENDPOINTS.user.onboarding.features]);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.sequence.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);

      queryClient.invalidateQueries([
        ENDPOINTS.account.main,
        variables.accountId,
      ]);
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
    },
  });
  return { data, mutate, ...rest };
}
