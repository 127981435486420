import { IconButton, Tooltip, Typography } from '@mui/material';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import useEditAccount from 'hooks/services/account/useEditAccount';
import { useLinkedinGetCompanyInfo } from 'crono-fe-common/hooks/crono-extension/gateway';
import useFindScrape from 'hooks/services/scrape/useFindScrape';
import { ProspectInputs } from 'pages/searchComponent/searchContactTab/insertContact/model';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { AccountInfoInputs } from 'crono-fe-common/types/DTO/accountInfoInputs';
import { Account, isAccount } from 'crono-fe-common/types/account';
import { LinkedinCompanyShortInfo } from 'crono-fe-common/types/crono-extension/linkedin';
import { Prospect } from 'crono-fe-common/types/prospect';
import { getError } from 'crono-fe-common/utils';
import { extractDomainFromWebsite } from 'utils/fe-utils';
import { EmailScrapeResult } from 'crono-fe-common/types/enums/verificationStatusType';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { LoadingSpinnerCircles } from 'pages/searchComponent/style';
import useGetUserCredits from 'hooks/services/user/useGetUserCredits';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { SettingsTabs } from 'pages/settings';
import PATH from 'routing/path';
import IconRevenue from 'crono-fe-common/icons/Icon-Revenue';
import useScrapeWebsite from 'hooks/services/scrape/useScrapeWebsite';

interface IProps {
  account?: Account | AccountLinkedin | null;
  prospect?: Prospect | null;
  setEmail?: React.Dispatch<React.SetStateAction<EmailScrapeResult | null>>;
  //To call the fined from the contact insert
  prospectInputs?: ProspectInputs | null;
  accountInputs?: LinkedinCompanyShortInfo | null;
  handleAddAccountInformation?: (
    website: string | null,
    nOfEmployees: number | null,
    industry: string | null,
    country?: string | null,
  ) => void;
  iconColor?: string;
  hasLabel?: boolean;
  disabled?: boolean;
  onClickBuyMore?: () => void;
  //If this is true and we have the prospectId let's pass it to the find so that the BE will patch once found
  makeBEPatchProspect?: boolean;
}

const FindEmailPreconditionButton = ({
  account,
  prospect,
  setEmail,
  prospectInputs,
  accountInputs,
  handleAddAccountInformation,
  iconColor,
  hasLabel,
  disabled,
  onClickBuyMore,
  makeBEPatchProspect = false,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const [searchEmailError, setSearchEmailError] = useState<string | null>(null);
  const [searchEmailHint, setSearchEmailHint] = useState<string | null>(null);
  const [nProvider, setNProvider] = useState<number>(0);

  const {
    isLoading: isProspectScrapeLoading,
    data: prospectScrapeData,
    error: prospectScrapeError,
    mutateAsync: findEmail,
  } = useFindScrape();

  const { data: userCredits } = useGetUserCredits();

  useEffect(() => {
    if (prospectScrapeData) {
      if (prospectScrapeData.data?.data) {
        if (prospectScrapeData.data?.data.email && setEmail) {
          setEmail({
            email: prospectScrapeData.data?.data.email,
            verificationStatus:
              prospectScrapeData.data?.data.verificationStatus,
          });
        }
      }
    }
  }, [prospectScrapeData]);

  const { result: linkedinCompanyInfo, call: getLinkedinCompanyInfo } =
    useLinkedinGetCompanyInfo();

  const { mutate: updateAccount, error: errorUpdateAccount } = useEditAccount();

  const { mutateAsync: scrapeWebsiteAsync, isLoading: isLoadingScrapeWebsite } =
    useScrapeWebsite();

  useEffect(() => {
    const handle = async () => {
      if (linkedinCompanyInfo && account) {
        if (linkedinCompanyInfo?.company?.website) {
          const website = await scrapeWebsiteAsync({
            website: linkedinCompanyInfo?.company?.website ?? '',
          });
          if (website.data?.data?.redirectUrl) {
            const domain = extractDomainFromWebsite(
              website.data?.data?.redirectUrl,
            );
            validateInputAndFindEmail(domain);
          }
          const patchAccount: AccountInfoInputs = {
            accountId: account.objectId,
          };

          patchAccount.website = website.data?.data?.redirectUrl;

          if (
            !account.numberOfEmployees &&
            linkedinCompanyInfo.company.numberOfEmployees
          ) {
            patchAccount.nOfEmployees =
              linkedinCompanyInfo.company.numberOfEmployees;
          }

          if (!account.industry && linkedinCompanyInfo.company.industry) {
            patchAccount.industry = linkedinCompanyInfo.company.industry;
          }

          if (
            (!isAccount(account) || !account.country) &&
            linkedinCompanyInfo.company.country
          ) {
            patchAccount.country = linkedinCompanyInfo.company.country;
          }

          updateAccount(patchAccount);
        } else {
          console.log('Error');
          setSearchEmailHint('Add website to company info to find email');
          setTimeout(() => {
            setSearchEmailHint(null);
          }, 2000);
        }
      } else if (linkedinCompanyInfo && accountInputs) {
        const website = await scrapeWebsiteAsync({
          website: linkedinCompanyInfo?.company?.website ?? '',
        });
        if (website.data?.data?.redirectUrl) {
          const domain = extractDomainFromWebsite(
            website.data?.data?.redirectUrl,
          );
          validateInputAndFindEmail(domain);
        }
        if (website.data?.data?.redirectUrl && linkedinCompanyInfo.company) {
          const domain = extractDomainFromWebsite(
            website.data.data.redirectUrl,
          );
          validateInputAndFindEmail(domain);
          //I call the function passed to save the additional information obtained
          if (handleAddAccountInformation)
            handleAddAccountInformation(
              website.data.data.redirectUrl,
              linkedinCompanyInfo.company.numberOfEmployees,
              linkedinCompanyInfo.company.industry,
              linkedinCompanyInfo.company.country,
            );
        } else {
          setSearchEmailHint('Add website to company info to find email');
          setTimeout(() => {
            setSearchEmailHint(null);
          }, 2000);
        }
      }
    };
    handle();
  }, [linkedinCompanyInfo]);

  useEffect(() => {
    if (prospectScrapeError) {
      if (prospectScrapeError.response?.status === 409) {
        setSearchEmailError(
          `The found email ${prospectScrapeData?.data?.data.email ?? ''} is already in use in the CRM`,
        );
      } else if (prospectScrapeError?.response?.status === 404) {
        setSearchEmailError('Email not found');
      } else if (prospectScrapeError?.response?.status === 403) {
        setSearchEmailError('Scrape not enabled or credit exhausted');
      } else {
        setSearchEmailError('Error while finding email');
      }
    }
  }, [prospectScrapeError]);

  useConditionalSnackBar([
    {
      condition: !!searchEmailError,
      message: searchEmailError || '',
      severity: 'error',
    },
    {
      condition: !!searchEmailHint,
      message: searchEmailHint || '',
      severity: 'info',
    },
    {
      condition: !!errorUpdateAccount,
      message: getError(errorUpdateAccount) ?? 'Error update company',
      severity: 'error',
    },
  ]);

  const handleFindEmail = async () => {
    if (!account) {
      if (!accountInputs) return;
      setSearchEmailError(null);
      setSearchEmailHint(null);
      if (accountInputs.url || accountInputs.numericId) {
        getLinkedinCompanyInfo({
          url: accountInputs.url,
          numericId: accountInputs.numericId,
        }).then();
      } else {
        setSearchEmailHint('Add website to company info to find email');
      }
      return;
    }
    setSearchEmailError(null);
    setSearchEmailHint(null);
    const website = await scrapeWebsiteAsync({
      website: account.website ?? '',
    });
    if (website.data?.data?.redirectUrl) {
      const domain = extractDomainFromWebsite(website.data?.data?.redirectUrl);
      validateInputAndFindEmail(domain);
      if (website.data.data.redirectUrl !== account.website) {
        updateAccount({
          accountId: account.objectId,
          website: website.data.data.redirectUrl,
        });
      }
    } else if (account.linkedin || account.linkedinNumericId) {
      getLinkedinCompanyInfo({
        url: account.linkedin,
        numericId: account.linkedinNumericId,
      }).then();
    } else {
      setSearchEmailHint('Add website to company info to find email');
    }
  };

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (searchEmailHint) {
      t = setTimeout(() => {
        setSearchEmailHint(null);
      }, 2000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [searchEmailHint]);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (searchEmailError) {
      t = setTimeout(() => {
        setSearchEmailError(null);
      }, 2000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [searchEmailError]);

  const validateInputAndFindEmail = (domain: string) => {
    if (!domain || domain.length === 0) {
      setSearchEmailError('Fill company domain');
      return;
    }
    if (!prospect) {
      if (!prospectInputs) return;
      if (analytics) {
        analytics.track('search-email', {});
      }
      findEmail({
        firstName: prospectInputs.firstName,
        lastName: prospectInputs.lastName,
        domain: domain,
        linkedinUrl: prospectInputs.linkedin,
      });

      return;
    }
    if (
      !prospect.firstName ||
      !prospect.lastName ||
      prospect.firstName === '' ||
      prospect.lastName === ''
    ) {
      setSearchEmailError('Fill prospect name');
      return;
    }

    if (analytics) {
      analytics.track('search-email', {});
    }
    findEmail({
      firstName: prospect.firstName,
      lastName: prospect.lastName,
      domain,
      linkedinUrl: prospect.linkedin,
      ...(makeBEPatchProspect ? { prospectId: prospect.objectId } : {}),
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isProspectScrapeLoading) {
      timer = setInterval(() => {
        setNProvider((prev) => {
          if (prev >= 10) return 10;
          return prev + 1;
        });
      }, 2500);
    } else {
      setNProvider(1);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isProspectScrapeLoading]);

  const are0CreditsLeft = useMemo(() => {
    return userCredits?.data?.data.emailLeft === 0;
  }, [userCredits]);

  const navigate = useNavigate();

  const handleBuyMoreCredits = () => {
    navigate(PATH.SETTINGS, {
      state: {
        initialSettingsTab: SettingsTabs.AddOns,
      },
    });
    if (onClickBuyMore) {
      onClickBuyMore();
    }
  };
  return are0CreditsLeft ? (
    <Tooltip
      title={
        <span
          style={{
            whiteSpace: 'pre-line',
            display: 'flex',
            textAlign: 'center',
          }}
        >
          There are no more credits left. <br />
          Please buy more credits to search the email.
        </span>
      }
      placement="top"
      arrow
    >
      <IconButton
        onClick={() => {
          handleBuyMoreCredits();
        }}
        className="find-button"
        sx={{
          padding: '3px 2px 3px 6px !important',
          backgroundColor: colors.inactive,
          width: '76px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px',
          borderRadius: '34px',
          cursor: 'pointer',
          '&.MuiIconButton-root:hover': {
            backgroundColor: colors.inactiveHover,
          },
        }}
      >
        <Typography fontSize={'14px'} color={colors.white}>
          Buy
        </Typography>
        <IconRevenue
          color={colors.white}
          style={{
            height: 24,
            width: 24,
          }}
        />
      </IconButton>
    </Tooltip>
  ) : isProspectScrapeLoading || isLoadingScrapeWebsite ? (
    <LoadingSpinnerCircles>
      <Typography fontSize={'14px'} fontWeight={500} color={colors.primaryDark}>
        {nProvider} provider...
      </Typography>
      <span className="loader"></span>
    </LoadingSpinnerCircles>
  ) : hasLabel ? (
    <Tooltip
      title={
        <span
          style={{
            whiteSpace: 'pre-line',
            display: 'flex',
            textAlign: 'center',
          }}
        >
          {disabled
            ? `Please empty the field 
            to enable the Find function`
            : `${userCredits?.data?.data.emailLeft} Find email credits left.
              Next update: ${moment(userCredits?.data?.data.creditsRenewalDate).format('MMM DD, YYYY')}`}
        </span>
      }
      placement="top"
      arrow
    >
      <IconButton
        onClick={() => {
          if (disabled) return;
          handleFindEmail();
        }}
        className="find-button"
        sx={{
          padding: '3px 2px 3px 6px !important',
          backgroundColor: disabled
            ? `${colors.grey11} !important`
            : colors.primary,
          width: '76px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px',
          borderRadius: '34px',
          cursor: disabled ? 'auto' : undefined,
          '&.MuiIconButton-root:hover': {
            backgroundColor: disabled
              ? `${colors.grey11} !important`
              : colors.primaryDark,
          },
        }}
      >
        <Typography fontSize={'14px'} color={iconColor}>
          Find
        </Typography>
        <SearchIcon color={iconColor} />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton
      onClick={() => {
        if (disabled) return;
        handleFindEmail();
      }}
      sx={{
        padding: '3px !important',
      }}
    >
      <SearchIcon color={iconColor} />
    </IconButton>
  );
};

export default FindEmailPreconditionButton;
