import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ConfirmSkipModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 476px;
  border-radius: 16px;
  border: 1px solid ${colors.grey444};
  padding: 40px;
  gap: 16px;
  text-align: center;
  .close-confirm-modal-cross {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .icon-warning {
    height: 72px;
    width: 72px;
    background: ${colors.inactiveLight};
    border-radius: 50%;
    padding: 16px;
    overflow: visible;
  }
  .button-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    > button {
      width: 100%;
      height: 48px;
    }
  }
  .warning-container {
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 16px 10px 16px 16px;
    gap: 12px;
    background: ${colors.inactiveLight};
    align-items: center;
    text-align: left;
    margin-bottom: 8px;

    .warning-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 50%;
      background: ${colors.inactive};
    }
  }
`;
