export interface LogLinkedinInsert {
  taskTodoId: number | null;
  suggestionId?: number | null;
  accountId: string;
  prospectId: string;
  content: string;
  subject?: string | null;
  linkedinType: LinkedinType;
  templateId: number | null;
  previousMessageReplied?: boolean;
  sequenceStepTemplateId?: number | null;
}

export enum LinkedinType {
  Message = 'Message',
  Invitation = 'Invitation',
  InMail = 'InMail',
}
