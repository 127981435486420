import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const DelayButtonWrapper = styled.div`
  .delay-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 42px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    padding: 0px 12px;
    background: ${colors.white};
  }
  .delay-option-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 1px;
    border-radius: 8px;
    background: ${colors.grey6};
  }

  .delay-picker {
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    display: flex;
    padding: 4px 12px;
    column-gap: 16px;
    align-items: center;
    width: 140px;
    justify-content: space-between;
    height: 32px;
    background: ${colors.grey6};
    cursor: pointer;
  }
  .delay-picker-light {
    background: ${colors.white};
  }
  .delay-picker-disabled {
    color: ${colors.grey3};
    cursor: initial;
  }
  .delay-picker:not(.delay-picker-disabled):hover {
    background: ${colors.greyBackgroundScheduled};
  }
  .delay-picker-button {
    font-size: 19px;
  }
  .step-date {
    display: flex;
    align-items: center;
  }
`;
