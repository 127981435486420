import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SequenceInstanceTaskInsert } from 'crono-fe-common/types/DTO/sequenceInstanceTaskInsert';

export default function useAddSequenceInstanceStep() {
  const queryClient = useQueryClient();
  const createTask = (taskInsert: SequenceInstanceTaskInsert) => {
    const request: Request = {
      url: `${ENDPOINTS.task.sequence}`,
      config: {
        method: 'post',
        data: taskInsert,
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, SequenceInstanceTaskInsert>(
    createTask,
    {
      onSettled: (result, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.task.main]);
        queryClient.invalidateQueries([ENDPOINTS.task.next]);
        queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
        queryClient.invalidateQueries([ENDPOINTS.task.total]);
        queryClient.invalidateQueries([ENDPOINTS.task.search]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.prospect]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
        queryClient.invalidateQueries([ENDPOINTS.account.main]);
        queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
        queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey
              .toString()
              .startsWith(ENDPOINTS.prospect.search.main),
        });
      },
    },
  );
}
