import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const CreateStrategyModalWrapper = styled.div`
  .create-strategy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 670px;
    height: 524px;
    padding: 48px;
    background-color: ${colors.white};
    border-radius: 16px;
    overflow: hidden;
    box-sizing: border-box;

    &__cross {
      position: absolute;
      top: 24px;
      right: 0;
    }

    &__title {
      margin-bottom: 32px;
      text-align: center;
    }

    &__cards {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      height: 286px;
    }

    &__next {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 48px;
      border-radius: 24px;
      background-color: ${colors.black};
      color: ${colors.white};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      cursor: pointer;

      &--disabled {
        background-color: ${colors.grey11};
        cursor: not-allowed;
      }
    }
  }
`;
