import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type RestartSequenceInputs = {
  prospectId?: string | null;
  strategyId: number;
};

export default function useRestartSequence() {
  const queryClient = useQueryClient();

  const restartSequence = (data: RestartSequenceInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.restart}`,
      config: {
        method: 'post',
        data: data,
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, RestartSequenceInputs>(
    restartSequence,
    {
      onSettled: (result, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.account.main]);
        queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
        queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey
              .toString()
              .startsWith(ENDPOINTS.prospect.search.main),
        });
        queryClient.invalidateQueries([ENDPOINTS.task.search]);
        queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);

        queryClient.invalidateQueries([ENDPOINTS.task.total]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.prospect]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.search]);

        queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
        queryClient.invalidateQueries([
          ENDPOINTS.sequence.main,
          variables.strategyId,
        ]);
        queryClient.invalidateQueries([
          ENDPOINTS.sequence.strategy,
          variables.strategyId,
        ]);
        queryClient.invalidateQueries([
          ENDPOINTS.sequence.detail,
          variables.strategyId,
        ]);
        queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);
      },
    },
  );
}
