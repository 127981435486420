import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ExternalPropertyOptionPatch } from 'crono-fe-common/types/DTO/externalPropertyOptionPatch';

export default function usePatchExternalPropertyOption() {
  const queryClient = useQueryClient();

  const patchExternalPropertyOption = (data: ExternalPropertyOptionPatch) => {
    const request: Request = {
      url: `${ENDPOINTS.externalProperty.option}`,
      config: {
        method: 'patch',
        data: data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    ExternalPropertyOptionPatch
  >(patchExternalPropertyOption, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.externalProperty.main]);
      queryClient.invalidateQueries([ENDPOINTS.externalProperty.status.main]);
    },
  });

  return { data, mutate, ...rest };
}
