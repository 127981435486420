// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import { ConfirmModalContent, ConfirmModalContextType } from './types';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { ConfirmSkipModalWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import {
  RedButton,
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import IconSWarning from 'crono-fe-common/icons/Icon-S-Warning';
import { Typography } from '@mui/material';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { Constants } from 'crono-fe-common/constants/constants';

export const ConfirmModalContext = createContext<
  ConfirmModalContextType | undefined
>(undefined);
ConfirmModalContext.displayName = 'ConfirmModalContext';

const ConfirmModalProvider: FC<{ children: any }> = ({ children }) => {
  const [confirmModalContent, setConfirmModalContent] =
    useState<ConfirmModalContent | null>(null);

  const modalConfirm = () => {
    if (confirmModalContent?.confirmFunction)
      confirmModalContent.confirmFunction();
    setConfirmModalContent(null);
  };

  const modalCancel = () => {
    if (confirmModalContent?.cancelFunction)
      confirmModalContent.cancelFunction();
    setConfirmModalContent(null);
  };

  const cancelButton = useMemo(
    () => (
      <CancelButton
        onClick={modalCancel}
        style={{
          margin: 0,
          ...confirmModalContent?.styles?.cancelButton,
        }}
      >
        {confirmModalContent?.cancelText ?? 'No'}
      </CancelButton>
    ),
    [
      confirmModalContent?.cancelFunction,
      confirmModalContent?.cancelText,
      confirmModalContent?.styles?.cancelButton,
    ],
  );

  return (
    <ConfirmModalContext.Provider value={{ openModal: setConfirmModalContent }}>
      {confirmModalContent && (
        <BlurBackgroundDivFixed
          style={{ zIndex: Constants.highestZIndex }}
          onClick={() => {
            if (confirmModalContent.disableClickOnBackground) return;
            if (confirmModalContent.cancelFunction)
              confirmModalContent.cancelFunction();
            setConfirmModalContent(null);
          }}
        >
          <ConfirmSkipModalWrapper
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
          >
            {confirmModalContent.showCloseCrossTopRight && (
              <CloseTabButton
                className="close-confirm-modal-cross"
                close={() => setConfirmModalContent(null)}
              />
            )}
            {confirmModalContent.type === 'danger' && (
              <IconSWarning className="icon-warning" color={colors.inactive} />
            )}
            <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
              {confirmModalContent.title}
            </Typography>
            {confirmModalContent.text && (
              <Typography
                fontSize={14}
                lineHeight={'24px'}
                color={colors.grey11}
              >
                {confirmModalContent.text}
              </Typography>
            )}
            {confirmModalContent.warning && (
              <div className="warning-container" style={{ marginTop: -16 }}>
                <div className="warning-icon">
                  <IconSWarning color={colors.white} />
                </div>
                <Typography
                  fontSize={12}
                  lineHeight={'16px'}
                  fontWeight={500}
                  color={colors.inactive}
                >
                  {confirmModalContent.warning}
                </Typography>
              </div>
            )}
            {confirmModalContent.type === 'info' ? (
              <div className="button-footer">
                {cancelButton}
                {confirmModalContent.confirmFunction && (
                  <MainButton onClick={modalConfirm}>
                    {confirmModalContent.confirmText ?? 'Yes'}
                  </MainButton>
                )}
              </div>
            ) : (
              <div className="button-footer">
                {confirmModalContent.confirmFunction && (
                  <RedButton onClick={modalConfirm}>
                    {confirmModalContent.confirmText ?? 'Yes'}
                  </RedButton>
                )}
                {cancelButton}
              </div>
            )}
          </ConfirmSkipModalWrapper>
        </BlurBackgroundDivFixed>
      )}
      {children}
    </ConfirmModalContext.Provider>
  );
};

export function useConfirmModal() {
  const context = useContext(ConfirmModalContext);
  if (context === undefined) {
    throw new Error(
      `useConfirmModal must be used within a ConfirmModalProvider`,
    );
  }

  return context;
}

export default ConfirmModalProvider;
