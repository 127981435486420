import { Typography } from '@mui/material';
import { DataProspect } from './types';
import { HeadCell } from './types';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { colors } from 'crono-fe-common/theme';
import RemainingCreditsHeaderIcon from 'pages/searchComponent/searchContactTab/remainingEmailCredits';
import { UserPreferences } from 'crono-fe-common/types/userPreferences';
import { FeConstants } from 'constants/FeConstants';

// Function that returns the columns of the table Account, inserting also the externalProperties
export const getAccountHeadCells = (
  externalProperties: ExternalProperty[] = [],
  userPreferencesColumns: string | null,
) => {
  const defaultCells: HeadCell[] = [
    {
      id: 'name',
      label: 'Company Name',
      sortable: true,
      isSticky: true,
      minWidth: 270,
      width: '12%',
      stickyOffset: 0,
      border: true,
    },
    {
      id: 'status',
      label: 'Status',
      sortable: true,
      isSticky: false,
      minWidth: 160,
      width: '12%',
    },
    {
      id: 'owner',
      label: 'Owner',
      sortable: true,
      isSticky: false,
      minWidth: 200,
      width: 200,
    },
    {
      id: 'allContacts',
      label: 'All Contacts',
      sortable: false,
      isSticky: false,
      minWidth: 130,
      width: '12%',
    },
    {
      id: 'inStrategy',
      label: 'In Strategy',
      sortable: false,
      isSticky: false,
      minWidth: 150,
      width: '12%',
    },
    {
      id: 'lastActivity',
      label: 'Last Activity',
      sortable: true,
      isSticky: false,
      minWidth: 140,
      width: '12%',
    },
    {
      id: 'createdDate',
      label: 'Created Date',
      sortable: true,
      isSticky: false,
      minWidth: 140,
      width: '12%',
    },
    {
      id: 'activities',
      label: 'Activities',
      //To false for problems with large customers
      sortable: false,
      isSticky: false,
      minWidth: 180,
      width: '12%',
    },
  ];

  defaultCells.splice(
    FeConstants.externalPropertyAccountsInitialIndex,
    0,
    ...externalProperties.map((externalProperty): HeadCell => {
      return {
        id: '' + externalProperty.id,
        label: externalProperty.publicName || '',
        sortable: false,
        isSticky: false,
        minWidth: 150,
        width: '12%',
      };
    }),
  );

  if (!userPreferencesColumns) return defaultCells;

  const withPreferences: HeadCell[] = [
    defaultCells[0],
    ...defaultCells.filter((cell) =>
      userPreferencesColumns.split(',').includes(cell.id),
    ),
  ];

  return withPreferences;
};

// Function that returns the columns of the table Prospect, inserting also the externalProperties
//If onlyOwned is true, the column Owner will be hidden
export const getProspectHeadCells = (
  externalProperties: ExternalProperty[] = [],
  userPreferencesColumns: string | null,
) => {
  const defaultCells: HeadCell[] = [
    {
      id: 'name',
      label: 'Contact Name',
      sortable: true,
      isSticky: true,
      minWidth: 250,
      width: 0.01,
      stickyOffset: 0,
    },
    {
      id: 'sequence',
      label: '',
      sortable: false,
      isSticky: true,
      minWidth: 60,
      width: 0.01,
      stickyOffset: 250,
      padding: 0,
      border: true,
    },

    {
      id: 'companyName',
      label: 'Company Name',
      sortable: true,
      isSticky: false,
      minWidth: 150,
      width: 150,
    },
    {
      id: 'status',
      label: 'Status',
      sortable: false,
      isSticky: false,
      minWidth: 150,
      width: '12%',
      tooltip: '', // todo insert here the explanation of status
    },
    {
      id: 'email',
      label: (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Typography
            fontSize={'inherit'}
            lineHeight={'16px'}
            fontWeight={500}
            color={colors.grey11}
          >
            Email
          </Typography>
          <RemainingCreditsHeaderIcon type="email" />
        </span>
      ),
      sortable: false,
      isSticky: false,
      minWidth: 150,
      width: 150,
    },
    {
      id: 'phone',
      label: (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Typography
            fontSize={'inherit'}
            lineHeight={'16px'}
            fontWeight={500}
            color={colors.grey11}
          >
            Phone
          </Typography>
          <RemainingCreditsHeaderIcon type="phone" />
        </span>
      ),
      sortable: false,
      isSticky: false,
      minWidth: 150,
      width: 150,
    },
    {
      id: 'owner',
      label: 'Owner',
      sortable: true,
      isSticky: false,
      minWidth: 200,
      width: 0.01,
    },
    {
      id: 'progress',
      label: 'Progress',
      sortable: false,
      isSticky: false,
      minWidth: 150,
      width: '12%',
    },
    {
      id: 'lastActivity',
      label: 'Last Activity',
      sortable: true,
      isSticky: false,
      minWidth: 150,
      width: '10%',
    },
    {
      id: 'createdDate',
      label: 'Created Date',
      sortable: true,
      isSticky: false,
      minWidth: 150,
      width: '10%',
    },
    {
      id: 'activities',
      label: 'Activities',
      sortable: false,
      isSticky: false,
      minWidth: 180,
      width: '10%',
    },
  ];

  defaultCells.splice(
    FeConstants.externalPropertyProspectsInitialIndex,
    0,
    ...externalProperties.map((externalProperty): HeadCell => {
      return {
        id: '' + externalProperty.id,
        label: externalProperty.publicName || '',
        sortable: false,
        isSticky: false,
        minWidth: 150,
        width: '12%',
      };
    }),
  );

  if (!userPreferencesColumns) return defaultCells;

  const withPreferences: HeadCell[] = [
    defaultCells[0],
    defaultCells[1],
    ...defaultCells.filter((cell) =>
      userPreferencesColumns.split(',').includes(cell.id),
    ),
  ];

  return withPreferences;
};
