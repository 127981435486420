import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TaskFilterModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .background {
    background: ${colors.grey1}66;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .filters-container {
    background: ${colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 38%;
    max-height: 85%;
    border-radius: 16px;
    z-index: 20;

    &::-webkit-scrollbar-track {
      margin-block: 16px;
    }
  }

  .filter-body {
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    position: relative;

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .filter-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    position: sticky;
    background: ${colors.white};
    z-index: 30;
  }

  .button-filter {
    border-radius: 999px;
    text-transform: none;
    margin: 4px;
    font-size: 16px;
    width: 120px;
    height: 48px;
    padding-block: 12px;
  }

  .crm-switch-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .switch-typography-container {
    }
  }

  .text-field {
    width: 100%;
    padding-inline: 0px;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .radio-options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .radio-option,
  .radio-option-selected {
    width: fit-content;
    justify-content: center;
    padding: 12px;
    border: 1px solid ${colors.grey3};
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  .radio-option:hover {
    background: ${colors.grey6};
  }

  .radio-option-selected {
    background: ${colors.selectedOptionGrey};
    &:hover {
      background: ${colors.grey4Hover};
    }
  }
`;
