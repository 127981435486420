import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ExternalPropertyWrapper = styled.div`
  cursor: pointer;
  .tag-button,
  .tag-add-button {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    width: fit-content;
    max-width: 140px;
    // min-height: 29px;
    // &:hover {
    //   background: ${colors.customer} !important;
    // }
  }
  .only-plus-add-button {
    height: 16px !important;
    padding-right: 2px !important;
  }
  .only-plus-add-button > svg {
    margin-right: 0px !important;
  }
  .tag-button {
    border: 1px solid transparent;
  }
  .empty-selection {
    background: ${colors.white} !important;
    border: 1px solid ${colors.grey4};
    :hover {
      background: ${colors.grey444} !important;
    }
  }
  .tag-add-button {
    border: 1px solid ${colors.grey3};
    &:hover {
      background: ${colors.grey4};
      border-color: ${colors.grey4};
    }
  }
  .tag-button > svg,
  .tag-add-button > svg {
    margin-right: 12px;
  }
  .checkmark-icon {
    width: 12px !important;
    height: 12px !important;
    padding: 0px !important;
    margin: 0px !important;
  }
`;

export const TagCheckmarkIconWrapper = styled.div`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  display: flex;
  align-items: center;
  margin-right: 4px;
`;
