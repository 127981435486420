import React from 'react';
import IProps from './interface';
import { colors } from 'crono-fe-common/theme';

const IconCopy = ({
  color = colors.grey2,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <rect
        width="14.0058"
        height="14.0058"
        rx="2"
        transform="matrix(-1 0 0 1 17.002 6.99792)"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0022 17.002H19.0031C20.1081 17.002 21.0039 16.1062 21.0039 15.0012V4.99705C21.0039 3.89202 20.1081 2.99622 19.0031 2.99622H8.99891C7.89388 2.99622 6.99807 3.89202 6.99807 4.99705V6.99788"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCopy;
