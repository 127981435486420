import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ImportWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 100%;
  flex-direction: column;
  gap: 32px;

  .clear-icon {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    cursor: pointer !important;
    color: ${colors.inactiveRed};
  }

  .crono-header-name {
    width: 200px;
  }

  .clear-icon-hidden {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    color: ${colors.white};
  }

  .error-message {
    color: ${colors.inactiveRed};
    font-weight: 300;
    font-size: 14px;
    margin-left: 83px;
  }

  .clear-icon:hover {
    color: ${colors.primary};
  }

  .highlighted {
    color: ${colors.primary};
    font-weight: 500;
  }

  .bold {
    font-weight: 500;
  }

  .input-select {
    width: 160px;
    background-color: ${colors.white};
    border-radius: 10px;
    margin-top: 10px;
  }

  .input-type-select {
    width: 115px;
    height: 45px;
    background-color: ${colors.white};
    border-radius: 5px;
    margin-top: 10px;
  }

  .select-field-container {
    width: 80%;
    font-weight: 500;
  }

  .crono-header {
    font-size: 20px;
    font-weight: 600;
    color: ${colors.primary};
  }

  .csv-header {
    font-size: 20px;
    font-weight: 600;
    margin-right: 60px;
  }

  .load-button {
    margin-top: 50px;
  }

  .duplicates-error-message {
    color: ${colors.inactiveRed};
    font-weight: 300;
    font-size: 14px;
  }

  .import-success {
    color: ${colors.callScheduledGreen};
  }

  .file-uploaded,
  .example-file-download {
    background: ${colors.primary};
    color: ${colors.white};
    padding: 16px;
    display: flex;
    // gap: 10px;
    border-radius: 34px;
    text-transform: unset;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .file-uploaded {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    background: ${colors.primaryLight};
    color: ${colors.black};
    border-radius: 16px;
  }

  .file-upload-error {
    background: ${colors.importFileError};
  }

  .file-name {
    width: 485px;
    padding-left: 8px;
  }

  .uploaded-file-name {
    width: 275px;
    padding-left: 8px;
  }

  .remove-file-icon {
    cursor: pointer;
  }

  .file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='black' stroke-width='1' stroke-dasharray='22%2c 22' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 16px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.primaryLight};
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%231EA59EFF' stroke-width='1' stroke-dasharray='22%2c 22' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
    }
  }

  .file-drop > .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;

    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
  }

  //Dragging inside the browser window
  .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    box-shadow: none;
    z-index: 50;
    opacity: 1;

    /* typography */
    box-shadow: 0 0 13px 1px ${colors.primary};
    background: ${colors.primaryLight};
    border-radius: 16px;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    background: ${colors.primaryLightHover};
  }

  .import-data-table {
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
  }
  .import-data-table-header {
    // display: flex;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${colors.grey11};
    border-bottom: 1px solid ${colors.grey444};
  }
  .import-data-table-header-cell {
    padding-top: 24px;
    padding-left: 30px;
    padding-bottom: 8px;
  }
  .import-data-table-row {
    // padding-left: 16px;
  }
  .import-data-table-body-cell {
    padding: 8px 8px 8px 24px;
    display: flex;
    align-items: center;
  }
  .status-map {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }
  .status-mapped {
    background: ${colors.callScheduledLight};
    color: ${colors.callScheduled};
  }
  .status-not-mapped {
    background: ${colors.inactiveLight};
    color: ${colors.inactive};
  }
  .loading-import-file {
    border-radius: 16px;
    padding: 56px;
    background: ${colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loader {
    height: 150px;
    position: relative;
    animation: flippx 2s infinite linear;
  }
  .loader:before {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${colors.primary};
    transform-origin: -24px 50%;
    animation: spin 1s infinite linear;
  }
  .loader:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${colors.primary};
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  @keyframes flippx {
    0%,
    49% {
      transform: scaleX(1);
    }
    50%,
    100% {
      transform: scaleX(-1);
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .imported-file {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .import-completed {
    // -webkit-filter: grayscale(1);
    padding: 16px;
  }
  .imported-file-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .slider {
    height: 1px;
    cursor: initial;
    padding: 2px;
  }
  .slider > .MuiSlider-thumb {
    display: none;
  }
  .import-completed-icon {
    background: ${colors.callScheduledLight};
    border-radius: 999px;
    padding: 4px;
  }
  .import-completed-with-errors-icon {
    background: ${colors.inactiveLight};
    border-radius: 999px;
    padding: 4px;
  }
  // .icon-completed-with-errors {
  //   padding: 1px;
  // }
  // .icon-completed-success {
  //   padding: 2px;
  // }
  .file-card-container {
    display: flex;
    gap: 24px;
  }
  .file-type-card {
    width: 270px;
    height: 286px;
    border: 1px solid ${colors.grey33};
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .file-type-card:hover {
    // width: 270px;
    // height: 286px;
    border: 1px solid #0a9b94;
    // border-radius: 24px;
    background-color: ${colors.sequenceCardHover};
  }
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 182px;
    text-align: center;
    padding-bottom: 24px;
    gap: 6px;
  }

  .step-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
`;

export const EnrichFromImportWrapper = styled.div`
  .warning-box {
    display: flex;
    width: 100%;
    margin-top: 32px;
    padding: 16px;
    background-color: #ffe8eb;
    border-radius: 16px;
  }

  .icon-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    margin-right: 16px;
    border-radius: 50%;
    background: ${colors.inactive};
  }
`;
