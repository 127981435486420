import {
  Avatar,
  Checkbox,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAuth } from 'context/auth';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { colors } from 'crono-fe-common/theme';
import React, { useEffect, useState } from 'react';
import { getImageFromUrl, stringAvatarAccount } from 'utils/fe-utils';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import { Account } from 'crono-fe-common/types/account';
import useSyncAccounts from 'hooks/services/account/useSyncAccounts';
import useLookSync from 'hooks/services/sync/useLookSync';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';

interface IAccountExternalRow {
  account: Account;
  handleSelectedExternal: (accountId: string) => void;
  selected: boolean;
  disableCheckbox?: boolean;
  onAdd: () => void;
  onErrorImporting: () => void;
  onSuccessImporting: () => void;
  syncing?: boolean;
}

const AccountExternalRow = ({
  account,
  selected,
  handleSelectedExternal,
  disableCheckbox,
  onAdd,
  syncing = false,
  onErrorImporting,
  onSuccessImporting,
}: IAccountExternalRow) => {
  const { user } = useAuth();
  const [backgroundColorSelected, setBackgroundColorSelected] =
    useState<string>(colors.greyLight);

  const {
    mutate: syncAccounts,
    isLoading: syncingAccounts,
    error: errorSyncingAccounts,
    isSuccess: successSyncingAccount,
    data: dataSyncingAccount,
  } = useSyncAccounts();

  const { mutateAsync: lookSync } = useLookSync();

  const startLookSync = async (id: number) => {
    const t = setInterval(async () => {
      const res = await lookSync(id);
      if (res.data?.data.status === ImportStatusType.CompletedWithErrors) {
        clearInterval(t);
        onErrorImporting();
      } else if (res.data?.data.status === ImportStatusType.Completed) {
        onSuccessImporting();
        clearInterval(t);
      }
    }, 5000);
  };

  useEffect(() => {
    if (successSyncingAccount) {
      onAdd();
      if (dataSyncingAccount.data?.data) {
        startLookSync(dataSyncingAccount.data.data);
      }
    }
  }, [successSyncingAccount]);

  const handleAddAccount = () => {
    syncAccounts({
      data: [account.objectId],
    });
  };

  useConditionalSnackBar([
    {
      condition: !!errorSyncingAccounts,
      message:
        getError(errorSyncingAccounts) ?? 'Error while adding company from CRM',
      severity: 'error',
    },
  ]);

  return (
    <div
      style={{
        background: backgroundColorSelected,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${colors.grey4}`,
      }}
      onMouseEnter={(ev) => {
        setBackgroundColorSelected(colors.grey4);
        ev.stopPropagation();
        ev.preventDefault();
      }}
      onMouseLeave={(ev) => {
        setBackgroundColorSelected(colors.greyLight);
        ev.stopPropagation();
        ev.preventDefault();
      }}
    >
      <div
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 8,
          background: backgroundColorSelected,
          width: 316,
          minWidth: 316,
          padding: '15px 8px 15px 4px',
          borderRight: `1px solid ${colors.grey4}`,
        }}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <label>
            <Checkbox
              checked={selected && !syncing}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleSelectedExternal(account.objectId);
              }}
              icon={
                <IconUnchecked
                  color={disableCheckbox ? colors.grey4 : colors.grey3}
                />
              }
              checkedIcon={<CheckedIcon />}
              color="secondary"
              disabled={disableCheckbox || syncing}
              sx={{
                padding: 'inherit',
                margin: 'inherit',
                '&:hover': {
                  backgroundColor: colors.grey4,
                },
                '&.Mui-checked': {
                  '&:hover': {
                    opacity: 0.7,
                  },
                },
                marginRight: 1,
                marginLeft: 1,
                // "&.MuiCheckbox-root": {
                //   color: colors.grey33 + " !important",
                // },
              }}
            />
          </label>
          <Avatar
            key={account.objectId}
            src={account?.website && getImageFromUrl(account.website, null)}
            className="prospect-avatar-row"
          >
            {stringAvatarAccount(account.name)}
          </Avatar>
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '210px',
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip
                arrow
                title={account.name}
                enterDelay={800}
                enterNextDelay={800}
              >
                <span
                  style={{
                    display: 'inline-block',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    margin: 0,
                    fontSize: '1rem',
                    fontWeight: 500,
                  }}
                >
                  {account.name}
                </span>
              </Tooltip>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          flex: 1,
          padding: '12px 16px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
          {!syncing && (
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight={'16px'}
              color={colors.grey11}
            >
              Company exists in {user?.company?.integrationType}
            </Typography>
          )}
          {syncingAccounts ? (
            <CircularProgress />
          ) : syncing ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
                color={colors.primaryDark}
              >
                Syncing
              </Typography>
            </div>
          ) : (
            <MainPrimaryButton
              style={{
                margin: 0,
                height: 40,
                fontSize: 12,
                lineHeight: '18px',
                fontWeight: 500,
              }}
              startIcon={<PlusSmallIcon color={colors.white} />}
              onClick={handleAddAccount}
            >
              Add to Crono
            </MainPrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountExternalRow;
