import { ENDPOINTS } from 'config/endpoints';
import { useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AddProspectToSequenceInputs } from 'crono-fe-common/types/DTO/addProspectToSequenceInputs';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';
import { useLimitedMutation } from '../useLimitedMutation';

export default function useAddProspectToSequence() {
  const queryClient = useQueryClient();

  const addProspectToSequence = (data: AddProspectToSequenceInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.prospect}`,
      config: {
        method: 'post',
        data: data,
      },
    };
    return client(request);
  };

  return useLimitedMutation<
    Response<null>,
    CronoError,
    AddProspectToSequenceInputs
  >(addProspectToSequence, {
    onSettled: (result, error, variables) => {
      CronoAutomaticTaskExecutor.stopDelay();
      queryClient.invalidateQueries([ENDPOINTS.account.main]);
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.prospect.search.strategy]);

      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([
        ENDPOINTS.sequence.detail,
        variables.strategyId,
      ]);
      queryClient.invalidateQueries([
        ENDPOINTS.sequence.strategy,
        variables.strategyId,
      ]);
      queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
    },
  });
}
