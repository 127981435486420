import { useEffect, useState } from 'react';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';

export default function useGetNextAutomaticTaskDate() {
  const [next, setNext] = useState<Date | null>(null);

  useEffect(() => {
    const listener = (data: { next: Date | null }) => {
      setNext(data.next);
    };

    CronoAutomaticTaskExecutor.nextTaskEventEmitter.addListener(
      'next',
      listener,
    );

    return () => {
      CronoAutomaticTaskExecutor.nextTaskEventEmitter.off('next', listener);
    };
  }, []);

  return next;
}
