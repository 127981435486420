import { CircularProgress } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useWindow } from 'context/sideTab';
import useGetAccount from 'hooks/services/account/useGetAccount';
import { useSelectedAccount } from 'pages/home/context/account';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PATH from 'routing/path';
import useProspectExists from 'hooks/services/prospect/useProspectExists';
import useSearchProspects from 'hooks/services/prospect/useSearchProspects';
import useGetProspect from 'hooks/services/prospect/useGetProspectMutate';
import useGetProspectMutate from 'hooks/services/prospect/useGetProspectMutate';

const ContactInterceptor = () => {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId') ?? undefined;
  const prospectId = searchParams.get('prospectId') ?? undefined;

  const { openWindow } = useWindow();
  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();

  const { isSuccess, error, data: selectedAccount } = useGetAccount(accountId);

  const { mutateAsync: getProspectMutate } = useGetProspectMutate();

  const navigate = useNavigate();

  useEffect(() => {
    const handleCall = async () => {
      if (isSuccess) {
        if (prospectId) {
          //I search if the contact is in the account
          const contact = await getProspectMutate({
            prospectId,
            withExternalProperties: false,
            withSequenceInstance: false,
            withAccount: false,
          });
          if (contact.data?.data) {
            setSelectedAccountId(selectedAccount?.data?.data.objectId ?? null);
            setSelectedProspectId(prospectId);
            openWindow({
              tab: 'prospect',
              windowType: 'account',
              selectedProspectId: prospectId,
            });
          }
        }
        navigate(PATH.HOME);
      }
    };
    handleCall();
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      navigate(PATH.HOME);
    }
  }, [error]);
  return (
    <>
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    </>
  );
};

export default ContactInterceptor;
