import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Import } from 'crono-fe-common/types/import';
import { TableType } from 'crono-fe-common/types/externalProperty';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';

export default function useGetImports(
  Limit: number,
  TableType?: TableType,
  StatusType?: ImportStatusType,
  enabled = true,
) {
  const queryParams = new URLSearchParams({
    Limit: Limit.toString(),
    ...(TableType ? { TableType: TableType.toString() } : undefined),
    ...(StatusType ? { StatusType: StatusType.toString() } : undefined),
  }).toString();
  const request: Request = {
    url: `${ENDPOINTS.import.main}?${queryParams}`,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.import.main],
    queryFn: () => client(request),
    enabled,
  };

  const { data, ...rest } = useQuery<Response<Import[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
