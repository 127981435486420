import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinIconInMailCompass = ({
  color = colors.black,
  className,
  onClick,
  style,
  viewBox = '0 0 20 20',
}: IProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4795 4.75C7.32656 4.75 4.75 7.32656 4.75 10.4795C4.75 13.6055 7.2828 16.165 10.399 16.2084L10.399 14.7082C8.11115 14.6648 6.25 12.7771 6.25 10.4795C6.25 8.15499 8.15499 6.25 10.4795 6.25C12.804 6.25 14.709 8.15499 14.709 10.4795C14.709 10.7739 14.6784 11.0616 14.6203 11.3396L16.1442 11.3396C16.1869 11.0589 16.209 10.7717 16.209 10.4795C16.209 7.32656 13.6324 4.75 10.4795 4.75Z"
        fill={color}
      />
      <path
        d="M12 13.9917V17.6663C12 18.3107 12.5223 18.833 13.1667 18.833H17.8333C18.4777 18.833 19 18.3107 19 17.6663V13.9921"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8102 16.0014L18.5625 14.8134C18.8351 14.6291 18.9981 14.3214 18.9981 13.9924V13.9924C18.9981 13.4441 18.554 13 18.006 13H12.996C12.448 13 12.0039 13.4441 12.0039 13.9921V13.9921C12.0039 14.3211 12.1669 14.6287 12.4395 14.8134L14.1918 16.0014C14.9824 16.5373 16.0196 16.5373 16.8102 16.0014V16.0014Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector"
        d="M11.8192 9.05575C11.896 9.03208 11.9679 9.10401 11.9442 9.18078L11.3085 11.2424C11.2987 11.274 11.274 11.2987 11.2424 11.3085L9.18078 11.9442C9.10401 11.9679 9.03208 11.896 9.05575 11.8192L9.69153 9.75762C9.70128 9.72602 9.72602 9.70128 9.75762 9.69153L11.8192 9.05575Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinIconInMailCompass;
