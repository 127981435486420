import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { visuallyHidden } from '@mui/utils';
import { Order } from 'crono-fe-common/types/userPreferences';
import { colors } from 'crono-fe-common/theme';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import Tooltip from '@mui/material/Tooltip';
import { Checkbox } from '@mui/material';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { ReactComponent as IndeterminateIcon } from 'crono-fe-common/icons/IndeterminateCheck.svg';
import { HeadCellSearchTab } from './searchContactTab/model';

interface EnhancedTableProps {
  headCells: HeadCellSearchTab[];
  showSelectAll?: boolean;
  // 0 - None, 1 - Some, 2 - All
  selectedStatus?: number;
  clickSelectAll?: () => void;
  disableCheckbox?: boolean;
}

export default function CustomTableHeadSearchContactTab(
  props: EnhancedTableProps,
) {
  const {
    headCells,
    showSelectAll,
    selectedStatus,
    clickSelectAll,
    disableCheckbox,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          const firstColumnWithCheckbox =
            showSelectAll && selectedStatus !== undefined && index == 0;
          return (
            <TableCell
              key={headCell.id}
              className={headCell.isSticky ? 'first-column' : 'column'}
              padding={'normal'}
              sx={{
                left: headCell.stickyOffsetLeft ?? undefined,
                right: headCell.stickyOffsetRight ?? undefined,
                width: headCell.width,
                minWidth: headCell.minWidth,
                paddingInline: headCell.padding ?? undefined,
                top: -2,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                borderRight: headCell.borderRight
                  ? `1.5px solid ${colors.grey33}`
                  : 'unset',
                borderLeft: headCell.borderLeft
                  ? `1.5px solid ${colors.grey33}`
                  : 'unset',

                paddingLeft: firstColumnWithCheckbox ? '4px' : undefined,
              }}
            >
              {firstColumnWithCheckbox && (
                <label>
                  <Checkbox
                    icon={
                      <IconUnchecked
                        color={disableCheckbox ? colors.grey4 : colors.grey3}
                      />
                    }
                    checkedIcon={<CheckedIcon />}
                    indeterminateIcon={<IndeterminateIcon />}
                    indeterminate={selectedStatus === 1}
                    checked={selectedStatus === 2}
                    disabled={disableCheckbox}
                    onClick={(ev) => {
                      if (clickSelectAll) clickSelectAll();
                      ev.preventDefault();
                      ev.stopPropagation();
                    }}
                    sx={{
                      padding: 'inherit',
                      margin: 'inherit',
                      '&:hover': {
                        backgroundColor: colors.grey4,
                      },
                      '&.Mui-checked': {
                        '&:hover': {
                          opacity: 0.7,
                        },
                      },
                      marginRight: 1,
                      marginLeft: 1,
                    }}
                  />
                </label>
              )}
              <Tooltip title={headCell.tooltip ?? ''} placement="top" arrow>
                <span style={{ fontWeight: 500, color: colors.grey11 }}>
                  {headCell.label}
                </span>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
