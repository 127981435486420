import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Task } from 'crono-fe-common/types/task';

export default function useGetTaskEmail(
  prospectId: string | null,
  emailId: string | null,
) {
  const queryParams = queryString.stringify({
    ProspectId: prospectId,
    EmailId: emailId,
  });

  const request: Request = {
    url: `${ENDPOINTS.events.task.email}?${queryParams}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.events.task.email, prospectId, emailId],
    queryFn: () => client(request),
    staleTime: 5 * 60 * 1000,
    enabled: prospectId !== null && emailId !== null,
  };

  const { data, ...rest } = useQuery<Response<Task | null>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
