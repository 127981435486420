import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { TaskHomeWrapper } from './style';
import useSearchTasks from 'hooks/services/task/useSearchTasks';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import CalendarRescheduleIcon from 'crono-fe-common/icons/Icon-Calendar-Reschedule';

import { colors } from 'crono-fe-common/theme';
import WeekCalendar from './calendar';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import moment from 'moment';
import usePatchTask from 'hooks/services/task/usePatchTask';
import { useWindow } from 'context/sideTab';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { useSelectedAccount } from 'pages/home/context/account';
import SuggestionsBox from './suggestionsBox';
import { useConfirmModal } from 'context/confirmModal';
import SequenceInstanceDisplay from './sequenceInstance';
import RescheduleCalendar from './rescheduleCalendar';
import { useExecuteMultipleTasks } from 'pages/accountTab/startMultipleTasksContext';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';
import ManualTasksResume from './manualTasksResume';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';

import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { ReactComponent as IndeterminateIcon } from 'crono-fe-common/icons/IndeterminateCheck.svg';
import usePatchMultipleTasks from 'hooks/services/task/usePatchMultipleTasks';
import Pagination from 'pages/dashboard/pagination';
import NotificationBoxTooltip from 'pages/home/notificationBoxTooltip';
import { ReactComponent as ErrorIndicator } from 'crono-fe-common/icons/Error-Indicator-S.svg';
import { Constants } from 'crono-fe-common/constants/constants';
import { TaskTabButton } from 'crono-fe-common/components/CronoButton';
import useSearchOverdueTasks from 'hooks/services/task/useSearchOverdueTask';
import {
  getImageFromUrl,
  prospectName,
  stringAvatarAccount,
  getTextOfAutomationError,
} from 'utils/fe-utils';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import useTasksTotal from 'hooks/services/task/useTasksTotal';
import { TaskTotal } from 'crono-fe-common/types/DTO/taskTotal';
import useDeleteTask from 'hooks/services/task/useDeleteTask';
import { rowPlaceholderFillOpacities } from 'constants/FeConstants';
import TaskRowPlaceholder from 'assets/images/Exceutable_task_placeholder.png';
import { ReactComponent as NoExecutableTasks } from 'assets/images/No_excecutable_tasks.svg';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { TabValue } from 'pages/home';
import useGetLinkedinAutomaticTasks from 'hooks/services/task/useGetLinkedinAutomaticTasks';
import AutomateTasksIcon from 'crono-fe-common/icons/Icon-Automate-Tasks';
import usePatchMultipleLinkedinTasks from 'hooks/services/task/usePatchMultipleLinkedinTasks';
import { TuneRounded } from '@mui/icons-material';
import { useAuth } from 'context/auth';

import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import { useTasksFilters } from './filters/taskFIlterContext';
import TaskFilterModal from './filters/taskFilterModal';
import IconSWarning from 'crono-fe-common/icons/Icon-S-Warning';
import AircallLogo from 'assets/images/aircall.png';
import useStartDialerCampaign from 'hooks/services/task/useStartDialerCampaign';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import LinkedinIconInMailOnlyCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass';
import { useGetAutomationsErrorBanner } from './useGetAutomationsErrorBanner';
import { SettingsTabs } from 'pages/settings';
import classNames from 'classnames';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import IconInformationS from 'crono-fe-common/icons/Icon-Information-Small';

export const dateCompare = (a: Date, b: Date) => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};

const isLessThanTomorrow = (a: Date) => {
  return (
    a.getFullYear() === new Date().getFullYear() &&
    a.getMonth() === new Date().getMonth() &&
    a.getDate() <= new Date().getDate()
  );
};

const isLessThanToday = (a: Date) => {
  return (
    a.getFullYear() === new Date().getFullYear() &&
    a.getMonth() === new Date().getMonth() &&
    a.getDate() < new Date().getDate()
  );
};

export type TaskPageTab = 'selectedDate' | 'overdue';

interface IProps {
  setTabValue: React.Dispatch<React.SetStateAction<TabValue>>;
}

const Tasks = ({ setTabValue }: IProps) => {
  const { automationErrorBanner, handleAutomationErrorBannerClose } =
    useGetAutomationsErrorBanner();

  const { user, hasSalesNav, automaticLinkedinTaskExecutionLimit } = useAuth();
  const analytics = useJuneAnalytics();

  const navigate = useNavigate();

  const [isFilterModalVisible, setIsFilterModalVisible] =
    useState<boolean>(false);

  //Undefined is used to mean "Not already loaded"
  const [filterType, setFilterType] = useState<TaskTodoType | null | undefined>(
    undefined,
  );

  const {
    searchTasksFilters,
    setSearchTasksFilters,
    numberOfFilters,
    renderedCountableFilters,
  } = useTasksFilters();

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const yesterday = useMemo<Date>(() => {
    const date = new Date(new Date().setDate(new Date().getDate() - 1));
    date.setHours(23, 59, 59, 59);
    return date;
  }, []);
  const pastMonth = useMemo<Date>(() => {
    const date = new Date(new Date().setMonth(new Date().getMonth() - 1));
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const setOffset = (offset: number) => {
    setSearchTasksFilters((prev) => ({
      ...prev,
      offset: offset,
    }));
  };

  const setAutomationErrorFilter = (automationError: boolean | null) => {
    setSearchTasksFilters((prev) => ({
      ...prev,
      hasAutomationError: automationError,
    }));
  };

  const { data: selectedDateTasks, isLoading: loadingSelectedDateTasks } =
    useSearchTasks({
      ...searchTasksFilters,
      //We do this way since when false we want all the tasks, when true only the Crono ones
      fromCrm: searchTasksFilters.fromCrm ? false : null,
      hasAutomationError: searchTasksFilters.hasAutomationError,
      date: selectedDate,
      completed: false,
      type: filterType,
    });
  //This is used only to know if we have to show the overdue task, since we show it only if we have at least one overdue task (without filters applied)
  const { data: pastMonthTasksToShowOverdueTab } = useSearchOverdueTasks({
    limit: 1,
    offset: 0,
    since: pastMonth,
    to: yesterday,
  });
  const showOverdueTab = pastMonthTasksToShowOverdueTab?.data?.count !== 0;
  const { data: pastMonthTasks, isLoading: loadingPastMonthTasks } =
    useSearchOverdueTasks({
      ...searchTasksFilters,
      //We do this way since when false we want all the tasks, when true only the Crono ones
      fromCrm: searchTasksFilters.fromCrm ? false : null,
      since: pastMonth,
      to: yesterday,
      type: filterType,
    });

  const { data: linkedinAutomaticTasks } = useGetLinkedinAutomaticTasks();

  const availableAutomatableLinkedinTasks = useMemo(() => {
    return (
      linkedinAutomaticTasks?.data?.data &&
      linkedinAutomaticTasks?.data?.data?.remaining &&
      linkedinAutomaticTasks?.data?.data?.remaining === 0
    );
  }, [linkedinAutomaticTasks]);

  //If I perform an overdue and after that I'm left without overdue, since the Overdue tab will disappear, I mode to the selectedDate tab
  useEffect(() => {
    if (!showOverdueTab) {
      setSelectedTab('selectedDate');
    }
  }, [showOverdueTab]);

  const [selectedTab, setSelectedTab] = useState<TaskPageTab>('selectedDate');

  const { openModal: openConfirmModal } = useConfirmModal();

  const {
    mutate: patchTask,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
    variables: patchTaskVariables,
    error: errorPatchTask,
  } = usePatchTask();

  const handleFilterChange = (newFilter: TaskTodoType | 'All') => {
    if (newFilter === 'All' || newFilter === filterType) setFilterType(null);
    else setFilterType(newFilter);
    setOffset(0);
  };

  const {
    mutate: deleteTask,
    isSuccess: taskDeleted,
    error: errorDeleteTask,
  } = useDeleteTask();

  const handleSkipTask = (id: number) => {
    openConfirmModal({
      title: 'Are you sure you want to skip this task?',
      text: 'The task will be skipped',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        patchTask({
          id: id,
          skipped: true,
        });

        if (analytics) {
          analytics.track('patch-task', {
            type: 'skip',
          });
        }
      },
      cancelFunction: () => null,
    });
  };

  const handleDeleteManualTask = (id: number) => {
    openConfirmModal({
      title: 'Are you sure you want to delete this task?',
      text: 'The task will be deleted',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        deleteTask(id);

        if (analytics) {
          analytics.track('patch-task', {
            type: 'delete',
          });
        }
      },
      cancelFunction: () => null,
    });
  };

  const { openWindow, closeWindow } = useWindow();
  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();
  const handleExecuteTask = (task: TaskTodo, selectedDateTask: boolean) => {
    if (selectedDateTask) {
      // if is a selectedDate task and the day is less than today, execute it in single mode
      if (isLessThanToday(selectedDate)) {
        executeSingleTask(task);
      }
      // if is a selectedDate task and the day is today, execute it in multiple mode
      else if (isLessThanTomorrow(selectedDate)) {
        startExecuting(
          task.id,
          filterType ?? null,
          !!searchTasksFilters.fromCrm,
          searchTasksFilters.sortBy!,
          searchTasksFilters.hasAutomationError === undefined
            ? null
            : searchTasksFilters.hasAutomationError,
          searchTasksFilters.strategyId === undefined
            ? null
            : searchTasksFilters.strategyId,
        );
      }
      // if is in the future don't execute it
    } else {
      // if is a task from overdue tab or it is a scheduled email, execute it in single mode
      executeSingleTask(task);
    }
  };

  const [selectedTaskToDisplay, setSelectedTaskToDisplay] =
    useState<TaskTodo | null>(null);

  const handleShowSequenceInstance = (task: TaskTodo) => {
    setSelectedTaskToDisplay(task);
  };

  const handleChangeTaskDate = (id: number, newDate: Date) => {
    patchTask({
      id: id,
      activityDate: newDate,
    });
    if (analytics) {
      analytics.track('patch-task', {
        type: 'reschedule',
      });
    }
  };

  const [showRescheduleTask, setShowRescheduleTask] = useState<{
    index: number;
    initialDate: Date;
    type: TaskTodoType;
  } | null>(null);

  const handleOpenDateCalendar = (task: TaskTodo) => {
    //handleChangeTaskDate(index);
    setShowRescheduleTask({
      index: task.id,
      initialDate: task.activityDate,
      type: task.type,
    });
  };

  const handleChangeDate = (newDate: Date) => {
    handleChangeTaskDate(showRescheduleTask!.index, newDate);
  };

  const {
    currentTaskBeingExecuted,
    startExecuting,
    executeSingleTask,
    cancelMultipleExecution,
  } = useExecuteMultipleTasks();

  const handleMutlipleStart = (tasks: TaskTodo[] | undefined) => {
    const notAutomaticTasks = tasks?.filter((t) => !t.automatic) || [];
    if (notAutomaticTasks.length === 0) return;
    //Start from the first task that is not automatic
    startExecuting(
      null,
      filterType ?? null,
      !!searchTasksFilters.fromCrm,
      searchTasksFilters.sortBy!,
      searchTasksFilters.hasAutomationError === undefined
        ? null
        : searchTasksFilters.hasAutomationError,
      searchTasksFilters.strategyId === undefined
        ? null
        : searchTasksFilters.strategyId,
    );
    return;
  };

  useEffect(() => {
    //Set filters in the context only if tasks are being executed

    //N.B!! The current behavior desired is that, if during the execution of a task someone changes the filters, the task execution will start from the first one
    //(that is equivalent to setting the id of the first task to null)
    if (currentTaskBeingExecuted) {
      startExecuting(
        null,
        filterType ?? null,
        !!searchTasksFilters.fromCrm,
        searchTasksFilters.sortBy!,
        searchTasksFilters.hasAutomationError === undefined
          ? null
          : searchTasksFilters.hasAutomationError,
        searchTasksFilters.strategyId === undefined
          ? null
          : searchTasksFilters.strategyId,
      );
    }
  }, [
    filterType,
    searchTasksFilters.fromCrm,
    searchTasksFilters.sortBy,
    searchTasksFilters.hasAutomationError,
    searchTasksFilters.strategyId,
  ]);

  useEffect(() => {
    if (patchSuccess) setShowRescheduleTask(null);
  }, [patchSuccess]);

  //After rescheduling a manual task I close the modal
  useEffect(() => {
    if (
      patchSuccess &&
      patchTaskVariables?.activityDate &&
      selectedManualTask
    ) {
      setSelectedManualTask(null);
    }
  }, [patchSuccess, patchTaskVariables]);

  const handleOpenContact = (task: TaskTodo) => {
    setSelectedAccountId(task.account.objectId);
    setSelectedProspectId(task.prospect.objectId ?? null);
    openWindow({
      windowType: 'account',
      tab: 'prospect',
      selectedProspectId: task.prospect.objectId ?? undefined,
    });
  };

  const [selectedManualTask, setSelectedManualTask] = useState<TaskTodo | null>(
    null,
  );

  const handleShowManualTaskResume = (task: TaskTodo) => {
    setSelectedManualTask(task);
    console.log({ task });
  };

  const [selected, setSelected] = useState<number[]>([]);

  //Reset the selection when tab is changed (should not be necessary, but to be sure is not bad)
  useEffect(() => {
    setSelected([]);
  }, [selectedTab]);

  const handleClickSelectAll = () => {
    if (selectedTab === 'overdue') {
      if (selected.length === pastMonthTasks?.data?.data.length) {
        setSelected([]);
      } else {
        setSelected(
          pastMonthTasks?.data?.data
            ?.filter((task) => task.id !== undefined)
            .map((task) => {
              return task.id;
            }) ?? [],
        );
      }
    } else {
      if (selected.length === selectedDateTasks?.data?.data.length) {
        setSelected([]);
      } else {
        setSelected(
          selectedDateTasks?.data?.data
            ?.filter((task) => task.id !== undefined)
            .map((task) => {
              return task.id;
            }) ?? [],
        );
      }
    }
  };

  const handleSelect = (id: number) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((taskId) => taskId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };
  //When the selected date on the calendar is changed, I refresh the selected tasks
  useEffect(() => {
    setSelected([]);
  }, [selectedDate]);

  const [showChangeDateMultipleTasks, setShowChangeDateMultipleTasks] =
    useState<boolean>(false);

  const handleOpenDateCalendarMultiple = () => {
    setShowChangeDateMultipleTasks(true);
  };

  const {
    mutate: patchMultipleTasks,
    isLoading: patchingMultipleTasks,
    error: errorPatchMultipleTasks,
    isSuccess: multipleTasksPatchedSuccessfully,
  } = usePatchMultipleTasks();

  useEffect(() => {
    if (multipleTasksPatchedSuccessfully) {
      setShowChangeDateMultipleTasks(false);
      setSelected([]);
    }
  }, [multipleTasksPatchedSuccessfully]);

  const handleChangeMultipleDate = (date?: Date) => {
    if (selected.length === 0 || !date) return;
    patchMultipleTasks({
      ids: selected,
      activityDate: date,
    });
    if (analytics) {
      analytics.track('patch-task-multiple', {
        type: 'reschedule',
      });
    }
  };

  const handleSkipTaskMultiple = () => {
    openConfirmModal({
      title: 'Are you sure you want to skip these tasks?',
      text: 'The tasks will be skipped',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        if (selected.length !== 0) {
          patchMultipleTasks({ ids: selected, skipped: true });
          if (analytics) {
            analytics.track('patch-task-multiple', {
              type: 'skip',
            });
          }
        }
      },
      cancelFunction: () => null,
    });
  };

  const {
    mutate: startDialerCampaign,
    isLoading: startingDialerCampaing,
    isSuccess: dialerCampaignStarted,
    error: errorStartingDialerCampaign,
  } = useStartDialerCampaign();

  useEffect(() => {
    if (dialerCampaignStarted) {
      setSelected([]);
    }
  }, [dialerCampaignStarted]);

  const handleStartDialerCampaign = () => {
    if (!callSelectedTasks || callSelectedTasks?.length === 0) return;
    startDialerCampaign(callSelectedTasks.map((task) => task.id));
    if (analytics) {
      analytics.track('start-dialer-campaign', {});
    }
  };

  const {
    mutate: patchMultipleLinkedinTasks,
    isLoading: patchingMultipleLinkedinTasks,
    error: errorPatchMultipleLinkedinTasks,
    isSuccess: multipleLinkedinTasksPatchedSuccessfully,
  } = usePatchMultipleLinkedinTasks();

  useEffect(() => {
    if (multipleLinkedinTasksPatchedSuccessfully) {
      setSelected([]);
    }
  }, [multipleLinkedinTasksPatchedSuccessfully]);

  const handleAutomateMultipleLinkedinTasks = () => {
    if (!linkedinSelectedTasks || linkedinSelectedTasks.length === 0) return;

    const filteredIds = linkedinSelectedTasks.map((task) => task.id);
    if (
      filteredIds.length > (linkedinAutomaticTasks?.data?.data?.remaining ?? 0)
    ) {
      openConfirmModal({
        title: `The number of tasks exceeds the daily limit. The first ${automaticLinkedinTaskExecutionLimit} tasks will be automated.`,
        text: '',
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        confirmFunction: () => {
          patchMultipleLinkedinTasks({
            ids: filteredIds,
            automatic: true,
          });
        },
        cancelFunction: () => null,
      });
    } else {
      patchMultipleLinkedinTasks({
        ids: filteredIds,
        automatic: true,
      });
    }
    if (analytics) {
      analytics.track('automate-linkedin-task', {});
    }
  };

  const linkedinSelectedTasks = useMemo(() => {
    // check if any of the ids inside selected is a linkedin task
    if (selected.length && selectedDateTasks?.data?.data) {
      const linkedinTasks = selectedDateTasks?.data?.data.filter((task) => {
        return (
          selected.includes(task.id) &&
          task.type === TaskTodoType.LINKEDIN &&
          task.automatic === false
        );
      });

      return linkedinTasks;
    }
  }, [selectedDateTasks, selected]);
  const callSelectedTasks = useMemo(() => {
    //If the user has no aircall settings it means that he is not logged in aircall, so we don't care about
    //the call tasks, we return [] so that also the both memo will be false
    if (!user?.aircallSettings) return [];
    // check if any of the ids inside selected is a call task
    if (selected.length && selectedDateTasks?.data?.data) {
      const callTasks = selectedDateTasks?.data?.data.filter((task) => {
        return (
          selected.includes(task.id) &&
          task.type === TaskTodoType.CALL &&
          task.inChargeStatus !== 'Completed'
        );
      });

      return callTasks;
    }
  }, [selectedDateTasks, selected]);

  const bothCallAndLinkedinSelected = useMemo(() => {
    return (
      callSelectedTasks &&
      callSelectedTasks.length > 0 &&
      linkedinSelectedTasks &&
      linkedinSelectedTasks.length > 0
    );
  }, [callSelectedTasks, linkedinSelectedTasks]);

  const searchParamsTotal: TaskTotal = useMemo(() => {
    const baseParams: TaskTotal = {
      completed: false,
      hasAutomationError: searchTasksFilters.hasAutomationError,
      automatic: searchTasksFilters.automatic,
      strategyId: searchTasksFilters.strategyId,
      //We do this way since when false we want all the tasks, when true only the Crono ones
      fromCrm: searchTasksFilters.fromCrm ? false : null,
    };

    if (selectedTab === 'overdue') {
      return {
        ...baseParams,
        since: pastMonth,
        to: yesterday,
      };
    } else {
      return {
        ...baseParams,
        date: selectedDate,
      };
    }
  }, [
    selectedTab,
    selectedDate,
    pastMonth,
    yesterday,
    searchTasksFilters.hasAutomationError,
    searchTasksFilters.automatic,
    searchTasksFilters.strategyId,
  ]);

  const { data: tasksTotal } = useTasksTotal(
    searchParamsTotal,
    searchParamsTotal !== undefined,
  );

  useConditionalSnackBar([
    {
      condition: !!errorPatchTask,
      message: getError(errorPatchTask) ?? 'Error updating task',
      severity: 'error',
    },
    {
      condition: !!errorPatchMultipleTasks,
      message: getError(errorPatchMultipleTasks) ?? 'Error updating tasks',
      severity: 'error',
    },
    {
      condition: !!errorPatchMultipleLinkedinTasks,
      message:
        getError(errorPatchMultipleLinkedinTasks) ??
        'Error automating linkedin tasks',
      severity: 'error',
    },
    {
      condition: !!availableAutomatableLinkedinTasks,
      message: 'No available automated tasks left',
      severity: 'warning',
    },
    {
      condition: !!multipleTasksPatchedSuccessfully,
      message: 'Tasks updated successfully',
      severity: 'success',
    },
    {
      condition: !!multipleLinkedinTasksPatchedSuccessfully,
      message:
        'Only LinkedIn Messages with assigned templates or Invitations will be automated',
      severity: 'success',
    },
    {
      condition: !!taskDeleted,
      message: 'Task deleted successfully',
      severity: 'success',
    },
    {
      condition: !!errorDeleteTask,
      message: getError(errorDeleteTask) ?? 'Error deleting task',
      severity: 'error',
    },
    {
      condition: !!(patchSuccess && patchTaskVariables?.skipped),
      message: 'Task skipped successfully',
      severity: 'success',
    },
    {
      condition: !!(patchSuccess && patchTaskVariables?.activityDate),
      message: 'Task rescheduled successfully',
      severity: 'success',
    },
    {
      condition: !!errorStartingDialerCampaign,
      message:
        getError(errorStartingDialerCampaign) ??
        'Error starting dialer campaign',
      severity: 'error',
    },
  ]);

  const renderTaskTypeIcon = (task: TaskTodo) => {
    let icon: ReactElement | null = null;
    switch (task.type) {
      case TaskTodoType.CALL:
        icon = (
          <CallIcon
            className={'type-icon call'}
            color={colors.callScheduledHover}
          />
        );
        break;
      case TaskTodoType.EMAIL:
        icon = (
          <EmailIcon
            className={'type-icon email'}
            color={colors.inactiveHover}
          />
        );
        break;
      case TaskTodoType.LINKEDIN:
        if (task.subtype === TaskTodoSubtype.LinkedinInvitation) {
          icon = <LinkedinInvitationIcon className={'linkedin-icon'} />;
        } else if (task.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
          icon = (
            <LinkedinIconInvoice
              className={'linkedin-icon'}
              viewBox="2 3 17 17"
              color={colors.blueHover}
            />
          );
        } else {
          icon = (
            <LinkedinIconMessage
              className={'linkedin-icon'}
              color={colors.blueHover}
            />
          );
        }
        break;
      case TaskTodoType.IN_MAIL:
        icon = (
          <LinkedinIconInMailCompass
            className={'type-icon in-mail'}
            color={colors.purpleHover}
            viewBox="2 2 20 20"
          />
        );
        break;
      default:
        icon = (
          <EmailIcon
            className={'type-icon email'}
            color={colors.inactiveHover}
          />
        );
        break;
    }
    return (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        className={
          task.type === TaskTodoType.LINKEDIN ? 'type-icon linkedin-icon' : ''
        }
      >
        <Tooltip
          arrow
          title={
            task.automatic &&
            'Task is automatic, it can not be performed manually'
          }
          placement="top"
        >
          {icon}
        </Tooltip>
      </span>
    );
  };

  const checkTaskType = (task: TaskTodo) => {
    let type = task.type as string;
    if (task.type === TaskTodoType.LINKEDIN) {
      if (task.subtype === TaskTodoSubtype.LinkedinInvitation) {
        type = 'Invitation';
      } else {
        type = 'Message';
      }
    }
    return type;
  };

  const hasExcecutableTasks = () => {
    return (
      (selectedDateTasks && (selectedDateTasks.data?.data?.length ?? 0) > 0) ||
      (pastMonthTasks && (pastMonthTasks.data?.data?.length ?? 0) > 0)
    );
  };

  const rowPlaceholder = rowPlaceholderFillOpacities.map((el, index) => {
    return (
      <div key={index} style={{ width: '100%' }}>
        <img
          src={TaskRowPlaceholder}
          alt="Empty task placeholder"
          style={{ opacity: el, width: '100%' }}
        />
      </div>
    );
  });

  const handleGoHome = () => {
    navigate(PATH.HOME);
    setTabValue(TabValue.DASHBOARD);
  };

  //====== Filters ======
  const noTasksView = (
    <FlexDiv
      direction="column"
      position="relative"
      justifyContent="start"
      style={{ overflow: 'hidden', padding: '25px' }}
    >
      {rowPlaceholder}
      <FlexDiv
        position="absolute"
        alignItems="center"
        direction="column"
        style={{ paddingBottom: '150px', gap: '20px' }}
      >
        <NoExecutableTasks />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography
            fontSize={'16px'}
            fontWeight={600}
            lineHeight={'24px'}
            textAlign={'center'}
          >
            Tasks will be displayed here
          </Typography>
          <Typography
            fontSize={'14px'}
            fontWeight={400}
            lineHeight={'24px'}
            maxWidth={'380px'}
            textAlign={'center'}
            color={colors.grey11}
          >
            Add at least one contact to a strategy from your{' '}
            <span className="lists-redirect" onClick={handleGoHome}>
              Lists
            </span>{' '}
            or assign a task to the contact to see tasks here
          </Typography>
        </div>
      </FlexDiv>
    </FlexDiv>
  );

  return (
    <TaskHomeWrapper>
      {isFilterModalVisible && (
        <TaskFilterModal close={() => setIsFilterModalVisible(false)} />
      )}
      {selectedTaskToDisplay && (
        <SequenceInstanceDisplay
          task={selectedTaskToDisplay}
          onClose={() => setSelectedTaskToDisplay(null)}
          handleOpenDateCalendar={handleOpenDateCalendar}
        />
      )}
      {selectedManualTask && (
        <ManualTasksResume
          task={selectedManualTask}
          onClose={() => setSelectedManualTask(null)}
          handleOpenDateCalendar={handleOpenDateCalendar}
          handleShowManualTaskResume={handleShowManualTaskResume}
        />
      )}
      {showRescheduleTask && (
        <RescheduleCalendar
          isRescheduleLoading={patchLoading}
          close={() => setShowRescheduleTask(null)}
          handleChangeDate={handleChangeDate}
          initialDate={showRescheduleTask.initialDate}
          taskType={showRescheduleTask.type}
        />
      )}
      {showChangeDateMultipleTasks && (
        <RescheduleCalendar
          isRescheduleLoading={patchingMultipleTasks}
          close={() => setShowChangeDateMultipleTasks(false)}
          handleChangeDate={handleChangeMultipleDate}
          initialDate={selectedDate}
        />
      )}
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={6} className="section">
          <div className="inner-section">
            <WeekCalendar
              currentDate={selectedDate}
              minDate={pastMonth}
              setCurrentDate={setSelectedDate}
              setSelectedTab={setSelectedTab}
            />
            <div className="divider" />
            <div className="tasks-tabs-container">
              <label>
                <Checkbox
                  icon={<IconUnchecked />}
                  checkedIcon={<CheckedIcon />}
                  indeterminateIcon={<IndeterminateIcon />}
                  indeterminate={
                    selected.length > 0 &&
                    selected.length <
                      (selectedDateTasks?.data?.data.length ?? 0)
                  }
                  checked={
                    selected.length === selectedDateTasks?.data?.data.length &&
                    selectedDateTasks?.data?.data.length !== 0
                  }
                  onClick={(ev) => {
                    handleClickSelectAll();
                    ev.preventDefault();
                    ev.stopPropagation();
                  }}
                  sx={{
                    padding: 'inherit',
                    margin: 'inherit',
                    '&:hover': {
                      backgroundColor: colors.grey4,
                    },
                    '&.Mui-checked': {
                      '&:hover': {
                        opacity: 0.7,
                      },
                    },
                    marginRight: 1,
                    marginLeft: 1,
                  }}
                />
              </label>
              {selected.length === 0 ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                  }}
                >
                  <div className="left-task-tabs">
                    <TaskTabButton
                      selected={selectedTab === 'selectedDate'}
                      size="large"
                      onClick={() => setSelectedTab('selectedDate')}
                      disableElevation
                      style={{
                        minWidth: '85px',
                      }}
                    >
                      <Typography
                        className="task-tab-button"
                        fontSize={14}
                        fontWeight={600}
                        noWrap
                        // paddingLeft={"8px"}
                      >
                        {dateCompare(selectedDate, new Date()) ? (
                          <>
                            Today
                            <div className="counter-task today-task">
                              {selectedDateTasks?.data?.total || '0'}
                            </div>
                          </>
                        ) : dateCompare(
                            selectedDate,
                            new Date(
                              new Date().setDate(new Date().getDate() + 1),
                            ),
                          ) ? (
                          <>Tomorrow</>
                        ) : (
                          <>
                            {moment(selectedDate).format(
                              new Date().getFullYear() ===
                                selectedDate.getFullYear()
                                ? 'MMM D'
                                : 'MMM D, YYYY',
                            )}
                          </>
                        )}
                      </Typography>
                    </TaskTabButton>
                    {showOverdueTab && !isLessThanToday(selectedDate) && (
                      <TaskTabButton
                        selected={selectedTab === 'overdue'}
                        size="large"
                        onClick={() => {
                          setSelectedTab('overdue');
                          // // date is reseted here since clicking in the calendar for today's date did not changed tab if previously showing overdue tab
                          // setSelectedDate(new Date());
                        }}
                        disableElevation
                      >
                        <Typography
                          className="task-tab-button"
                          fontSize={14}
                          fontWeight={600}
                          paddingLeft={'8px'}
                          noWrap
                        >
                          Overdue
                          <span className="counter-task overdue-task">
                            {pastMonthTasks?.data?.total || '0'}
                          </span>
                        </Typography>
                      </TaskTabButton>
                    )}
                  </div>
                  <div className="right-icons-button-container">
                    <div className="tabs-group">
                      {hasSalesNav && (
                        <IconButton
                          className={
                            filterType === TaskTodoType.IN_MAIL
                              ? 'tab'
                              : 'tab-not-selected'
                          }
                          onClick={() =>
                            handleFilterChange(TaskTodoType.IN_MAIL)
                          }
                        >
                          <LinkedinIconInMailOnlyCompass
                            width="16px"
                            height="16px"
                          />
                          <Typography paddingLeft={'4px'} fontWeight={500}>
                            {tasksTotal?.data?.data.inMail ?? 0}
                          </Typography>
                        </IconButton>
                      )}
                      <IconButton
                        className={
                          filterType === TaskTodoType.CALL
                            ? 'tab'
                            : 'tab-not-selected'
                        }
                        onClick={() => handleFilterChange(TaskTodoType.CALL)}
                      >
                        <CallIcon />
                        <Typography paddingLeft={'4px'} fontWeight={500}>
                          {tasksTotal?.data?.data.call ?? 0}
                        </Typography>
                      </IconButton>
                      <IconButton
                        className={
                          filterType === TaskTodoType.EMAIL
                            ? 'tab'
                            : 'tab-not-selected'
                        }
                        onClick={() => handleFilterChange(TaskTodoType.EMAIL)}
                      >
                        <EmailIcon />
                        <Typography paddingLeft={'4px'} fontWeight={500}>
                          {tasksTotal?.data?.data.email ?? 0}
                        </Typography>
                      </IconButton>
                      <IconButton
                        className={
                          filterType === TaskTodoType.LINKEDIN
                            ? 'tab'
                            : 'tab-not-selected'
                        }
                        onClick={() =>
                          handleFilterChange(TaskTodoType.LINKEDIN)
                        }
                      >
                        <LinkedinIcon />
                        <Typography paddingLeft={'4px'} fontWeight={500}>
                          {tasksTotal?.data?.data.linkedin ?? 0}
                        </Typography>
                      </IconButton>
                    </div>
                    <Tooltip
                      title="Advanced filters"
                      enterDelay={800}
                      enterNextDelay={800}
                    >
                      <IconButton
                        className="button-filters"
                        onClick={() => {
                          cancelMultipleExecution();
                          closeWindow();
                          setIsFilterModalVisible(true);
                        }}
                      >
                        <TuneRounded />
                        {numberOfFilters > 0 && (
                          <Typography
                            fontSize={11}
                            fontWeight={500}
                            lineHeight={'14px'}
                            style={{
                              width: 18,
                              height: 18,
                              background: colors.grey4,
                              borderRadius: 9,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {numberOfFilters}
                          </Typography>
                        )}
                      </IconButton>
                    </Tooltip>
                    {dateCompare(selectedDate, new Date()) &&
                      //We don't show the start button if we are filtering for automatic
                      !searchTasksFilters.automatic &&
                      selectedTab !== 'overdue' && (
                        <Button
                          color="secondary"
                          variant="contained"
                          className="button"
                          style={{
                            width: 'fit-content',
                            height: '38px',
                            padding: '6px 14px',
                            whiteSpace: 'nowrap',
                            margin: 0,
                          }}
                          onClick={() => {
                            handleMutlipleStart(selectedDateTasks?.data?.data);
                          }}
                          disabled={
                            selectedDateTasks?.data?.totalToConsider === 0
                          }
                        >
                          Open {selectedDateTasks?.data?.totalToConsider || '0'}{' '}
                          tasks
                        </Button>
                      )}
                  </div>
                </div>
              ) : (
                <div className="selected-tasks-tabs-container">
                  <div
                    className="selected-tasks"
                    onClick={() => setSelected([])}
                  >
                    <Typography
                      fontSize={14}
                      lineHeight={'18px'}
                      fontWeight={500}
                      color={colors.black}
                    >
                      {selected.length} selected
                    </Typography>
                    <CloseMIcon
                      style={{ width: 30, height: 30 }}
                      color={colors.grey11}
                    />
                  </div>

                  <div className="selected-tasks-actions">
                    {/*Button to automate linkedin tasks */}
                    {dateCompare(selectedDate, new Date()) &&
                      (linkedinSelectedTasks?.length ?? 0) > 0 && (
                        <>
                          <Tooltip
                            arrow
                            placement="top"
                            title={`You can automate up to ${automaticLinkedinTaskExecutionLimit} LinkedIn tasks daily`}
                          >
                            <div
                              className="action-button-container"
                              onClick={() => {
                                if (!patchingMultipleLinkedinTasks)
                                  handleAutomateMultipleLinkedinTasks();
                              }}
                            >
                              {patchingMultipleLinkedinTasks ? (
                                <CircularProgress size={20} />
                              ) : (
                                <>
                                  <AutomateTasksIcon />
                                  {!bothCallAndLinkedinSelected && (
                                    <>
                                      <Typography
                                        fontSize={14}
                                        lineHeight={'18px'}
                                        fontWeight={500}
                                      >
                                        Automate
                                      </Typography>
                                      <Typography
                                        fontSize={14}
                                        lineHeight={'18px'}
                                        fontWeight={500}
                                      >
                                        <span
                                          style={{
                                            color:
                                              selected.length >
                                              (linkedinAutomaticTasks?.data
                                                ?.data?.remaining ?? 0)
                                                ? colors.inactive
                                                : colors.black,
                                          }}
                                        >
                                          {linkedinSelectedTasks?.length ?? 0}
                                        </span>
                                        /
                                        {linkedinAutomaticTasks?.data?.data
                                          ? linkedinAutomaticTasks?.data?.data
                                              ?.remaining
                                          : automaticLinkedinTaskExecutionLimit}
                                      </Typography>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </Tooltip>
                          <div className="vertical-divider" />
                        </>
                      )}
                    {/*Button to start a dialer campaign in Aircall */}
                    {dateCompare(selectedDate, new Date()) &&
                      (callSelectedTasks?.length ?? 0) > 0 && (
                        <>
                          <Tooltip
                            arrow
                            placement="top"
                            title={`Start power dialer campaign in Aircall`}
                            enterDelay={400}
                            enterNextDelay={400}
                          >
                            <div
                              className="action-button-container"
                              onClick={() => {
                                if (!startingDialerCampaing)
                                  handleStartDialerCampaign();
                              }}
                            >
                              {startingDialerCampaing ? (
                                <CircularProgress size={20} />
                              ) : (
                                <>
                                  <img
                                    src={AircallLogo}
                                    className="aircall-logo-start-campaign"
                                  />
                                  {!bothCallAndLinkedinSelected && (
                                    <Typography
                                      fontSize={14}
                                      lineHeight={'18px'}
                                      fontWeight={500}
                                    >
                                      Automate Calls
                                    </Typography>
                                  )}
                                </>
                              )}
                            </div>
                          </Tooltip>
                          <div className="vertical-divider" />
                        </>
                      )}
                    <div
                      className="action-button-container"
                      onClick={() => handleOpenDateCalendarMultiple()}
                    >
                      <CalendarRescheduleIcon />
                      <Typography
                        fontSize={14}
                        lineHeight={'18px'}
                        fontWeight={500}
                      >
                        Reschedule
                      </Typography>
                    </div>
                    <div className="vertical-divider" />
                    <div
                      className="action-button-container"
                      onClick={() => handleSkipTaskMultiple()}
                    >
                      <CloseMIcon />
                      <Typography
                        fontSize={14}
                        lineHeight={'18px'}
                        fontWeight={500}
                      >
                        Skip tasks
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {numberOfFilters > 0 && (
              <div className="countable-filters-container">
                {renderedCountableFilters}
              </div>
            )}
            {automationErrorBanner.isVisible && (
              <div className="automation-error-banner">
                <div className="icon-warning">
                  <IconSWarning color={colors.white} />
                </div>

                <Typography fontSize={14} lineHeight={'18px'} fontWeight={500}>
                  <span
                    onClick={() => setAutomationErrorFilter(true)}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >{`${automationErrorBanner.errorsCount} ${automationErrorBanner.errorsCount > 1 ? 'tasks' : 'task'}`}</span>{' '}
                  could not be completed.
                  <br />
                  You can check each error at the relative task
                </Typography>
                <CloseMIcon
                  className="close-btn"
                  size={30}
                  color={colors.grey11}
                  onClick={handleAutomationErrorBannerClose}
                />
              </div>
            )}
            {/* Excecutable tasks list */}
            {loadingSelectedDateTasks || loadingPastMonthTasks ? (
              <FlexDiv>
                <CircularProgress />
              </FlexDiv>
            ) : hasExcecutableTasks() ? (
              <>
                {/*  selectedDate tasks */}
                {selectedTab === 'selectedDate' &&
                  selectedDateTasks &&
                  ((selectedDateTasks.data?.data?.length ?? 0) > 0 ? (
                    <>
                      <div className="executable-tasks-list">
                        {(linkedinAutomaticTasks?.data?.data.queued ?? 0) > 0 &&
                          //Show this only when there are linkedin tasks (so filtered by linkedin or ALL)
                          (!filterType ||
                            filterType === TaskTodoType.LINKEDIN) && (
                            <div className="automatic-limit-exceeded-banner">
                              <div className="icon-info-exceeded">
                                <IconInformationS color={colors.white} />
                              </div>

                              <Typography
                                fontWeight={500}
                                lineHeight={'18px'}
                                fontSize={14}
                                whiteSpace={'pre-wrap'}
                              >
                                The {automaticLinkedinTaskExecutionLimit} limits
                                for LinkedIn automated tasks has been exceeded.
                                <br />
                                {linkedinAutomaticTasks?.data?.data.queued}{' '}
                                tasks will be executed when possible
                                {hasSalesNav &&
                                  automaticLinkedinTaskExecutionLimit <
                                    Constants.maxAutomaticDailySalesNav && (
                                    <span
                                      style={{
                                        fontSize: 'inherit',
                                        lineHeight: 'inherit',
                                        fontWeight: 'inherit',
                                      }}
                                    >
                                      {' '}
                                      or click{' '}
                                      <span
                                        style={{
                                          fontSize: 'inherit',
                                          lineHeight: 'inherit',
                                          fontWeight: 'inherit',
                                          textDecoration: 'underline',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          navigate(PATH.SETTINGS, {
                                            state: {
                                              tab: SettingsTabs.Integrations,
                                            },
                                          });
                                        }}
                                      >
                                        here
                                      </span>{' '}
                                      to change this limit
                                    </span>
                                  )}
                              </Typography>
                            </div>
                          )}
                        {selectedDateTasks?.data?.data.map((task, index) => {
                          return (
                            <div
                              key={index}
                              className={classNames('task-card-wrapper', {
                                'automatic-task': task.automatic,
                                'task-executing':
                                  currentTaskBeingExecuted?.id === task.id,
                                'greater-than-today':
                                  selectedDate.getDay() > new Date().getDay(),
                              })}
                            >
                              {task.automationError && (
                                <div className={'task-card-error'}>
                                  <ErrorIndicator />
                                  <Typography fontSize={12} fontWeight={500}>
                                    {getTextOfAutomationError(
                                      task.automationError,
                                    )}
                                  </Typography>
                                </div>
                              )}
                              <div className={'task-card'}>
                                <div
                                  className="inner-task-card"
                                  onClick={(ev: any) => {
                                    ev.stopPropagation();
                                    ev.preventDefault();
                                    if (!task.automatic)
                                      handleExecuteTask(task, true);
                                    if (task.scheduledEmailId) {
                                      handleExecuteTask(task, false);
                                    }
                                  }}
                                >
                                  <div className="card-left-side">
                                    <label>
                                      <Checkbox
                                        icon={<IconUnchecked />}
                                        checkedIcon={<CheckedIcon />}
                                        indeterminateIcon={
                                          <IndeterminateIcon />
                                        }
                                        checked={selected.includes(task.id)}
                                        onClick={(ev) => {
                                          handleSelect(task.id);
                                          ev.preventDefault();
                                          ev.stopPropagation();
                                        }}
                                        sx={{
                                          padding: 'inherit',
                                          margin: 'inherit',
                                          '&:hover': {
                                            backgroundColor: colors.grey4,
                                          },
                                          '&.Mui-checked': {
                                            '&:hover': {
                                              opacity: 0.7,
                                            },
                                          },
                                          marginRight: 1,
                                          marginLeft: 1,
                                        }}
                                      />
                                    </label>
                                    <div className="prospect-info-tags-container">
                                      <div className="prospect-avatar-name-title">
                                        <Tooltip
                                          arrow
                                          title={task.account.name}
                                        >
                                          <Avatar
                                            key={task.account.objectId}
                                            src={
                                              task.account.website &&
                                              getImageFromUrl(
                                                task.account.website,
                                                null,
                                              )
                                            }
                                            className={
                                              task.account.website
                                                ? 'account-avatar white'
                                                : 'account-avatar'
                                            }
                                          >
                                            {stringAvatarAccount(
                                              task.account.name || '',
                                            )}
                                          </Avatar>
                                        </Tooltip>

                                        <div className="prospect-name-container">
                                          <Tooltip
                                            arrow
                                            title={prospectName(task.prospect)}
                                          >
                                            <Typography
                                              className="prospect-name"
                                              fontSize={14}
                                              lineHeight={'18px'}
                                              fontWeight={500}
                                              noWrap
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                handleOpenContact(task);
                                              }}
                                            >
                                              {prospectName(task.prospect)}
                                            </Typography>
                                          </Tooltip>
                                          <Typography
                                            fontSize={12}
                                            fontWeight={400}
                                            lineHeight={'16px'}
                                            color={colors.grey11}
                                            noWrap
                                          >
                                            {task.prospect.title}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          gap: 6,
                                        }}
                                      >
                                        <TagsBoxShowAll
                                          type={
                                            task.prospect.fromContact
                                              ? 'Prospect'
                                              : 'Lead'
                                          }
                                          blockIsEditable={true}
                                          disableEdit={true}
                                          showRemovable={false}
                                          currentSituationTags={
                                            task.prospect.externalValues
                                              ?.filter(
                                                (ev) =>
                                                  ev.isTag && ev.value !== null,
                                              )
                                              .map((ev) => ({
                                                externalPropertyId:
                                                  ev.externalPropertyId,
                                                option: ev.value!,
                                              })) ?? []
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="progress">
                                    <div className="icon-task-info">
                                      {renderTaskTypeIcon(task)}
                                      <div className="sequence-type-container">
                                        <div className="sequence-type">
                                          <Tooltip
                                            title={
                                              task.automatic
                                                ? "This task is automatic, you can't perform it manually"
                                                : ''
                                            }
                                            placement="top"
                                            arrow
                                          >
                                            <Typography
                                              fontSize={14}
                                              fontWeight={500}
                                              className="sequence-type-container-title"
                                              noWrap
                                              maxWidth={'110px'}
                                              color={
                                                task.automatic
                                                  ? `${colors.black} !important`
                                                  : undefined
                                              }
                                            >
                                              {task.sequenceInstanceId
                                                ? `${checkTaskType(task)}: ${
                                                    task.order
                                                  }/${task.total}`
                                                : `${task.subject ?? task.type}`}
                                            </Typography>
                                          </Tooltip>
                                          {task.automatic && (
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                columnGap: 4,
                                              }}
                                            >
                                              <span
                                                className={
                                                  task.scheduledEmailId !== null
                                                    ? 'scheduled-label'
                                                    : 'auto-label'
                                                }
                                              >
                                                {task.scheduledEmailId !== null
                                                  ? 'Scheduled'
                                                  : 'Auto'}
                                              </span>
                                            </div>
                                          )}
                                          {task.type === TaskTodoType.CALL &&
                                            task.inChargeStatus &&
                                            task.inChargeStatus !==
                                              'NotInCharge' && (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  columnGap: 4,
                                                }}
                                              >
                                                <span
                                                  className={
                                                    task.inChargeStatus ===
                                                    'InCharge'
                                                      ? 'in-charge-label'
                                                      : 'completed-label'
                                                  }
                                                >
                                                  {task.inChargeStatus ===
                                                  'InCharge'
                                                    ? 'Dialer'
                                                    : 'Completed'}
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                        <div
                                          className="sequence-type-container-subtitle"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            if (task.sequenceInstanceId)
                                              handleShowSequenceInstance(task);
                                            else
                                              handleShowManualTaskResume(task);
                                          }}
                                        >
                                          <Typography
                                            fontSize={12}
                                            fontWeight={500}
                                            noWrap
                                            marginTop={'2px'}
                                            maxWidth={'110px'}
                                          >
                                            {task.sequenceInstanceId !== null
                                              ? task.sequenceInstance?.name
                                              : 'Task'}
                                          </Typography>
                                          <EditIcon
                                            style={{ width: 20, height: 20 }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="task-card-buttons-section">
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title="Reschedule task"
                                  >
                                    <span
                                      style={{
                                        display: 'flex',
                                        height: 'fit-content',
                                      }}
                                    >
                                      <CalendarRescheduleIcon
                                        color={colors.grey11}
                                        className="pointer"
                                        onClick={() =>
                                          handleOpenDateCalendar(task)
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={
                                      task.sequenceInstanceId !== null
                                        ? 'Skip task'
                                        : 'Delete task'
                                    }
                                  >
                                    <span
                                      style={{
                                        display: 'flex',
                                        height: 'fit-content',
                                      }}
                                    >
                                      <CloseMIcon
                                        color={colors.grey11}
                                        className="pointer"
                                        onClick={() => {
                                          if (
                                            task.sequenceInstanceId !== null
                                          ) {
                                            handleSkipTask(task.id);
                                          } else {
                                            handleDeleteManualTask(task.id);
                                          }
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    noTasksView
                  ))}

                {/* overdue tasks */}
                {selectedTab === 'overdue' &&
                  pastMonthTasks &&
                  ((pastMonthTasks.data?.data?.length ?? 0) > 0 ? (
                    <>
                      <div className="executable-tasks-list">
                        {pastMonthTasks?.data?.data.map((task, index) => {
                          return (
                            <div
                              key={index}
                              className={classNames('task-card-wrapper', {
                                'task-executing':
                                  currentTaskBeingExecuted?.id === task.id,
                              })}
                            >
                              {task.automationError && (
                                <div className={'task-card-error'}>
                                  <ErrorIndicator />
                                  <Typography fontSize={12} fontWeight={500}>
                                    {getTextOfAutomationError(
                                      task.automationError,
                                    )}
                                  </Typography>
                                </div>
                              )}
                              <div className={'task-card'}>
                                <div
                                  className="inner-task-card"
                                  onClick={(ev: any) => {
                                    ev.stopPropagation();
                                    ev.preventDefault();
                                    if (!task.automatic)
                                      handleExecuteTask(task, false);
                                  }}
                                >
                                  <div className="card-left-side">
                                    <label>
                                      <Checkbox
                                        icon={<IconUnchecked />}
                                        checkedIcon={<CheckedIcon />}
                                        indeterminateIcon={
                                          <IndeterminateIcon />
                                        }
                                        checked={selected.includes(task.id)}
                                        onClick={(ev) => {
                                          handleSelect(task.id);
                                          ev.preventDefault();
                                          ev.stopPropagation();
                                        }}
                                        sx={{
                                          padding: 'inherit',
                                          margin: 'inherit',
                                          '&:hover': {
                                            backgroundColor: colors.grey4,
                                          },
                                          '&.Mui-checked': {
                                            '&:hover': {
                                              opacity: 0.7,
                                            },
                                          },
                                          marginRight: 1,
                                          marginLeft: 1,
                                        }}
                                      />
                                    </label>
                                    <div className="prospect-info-tags-container">
                                      <div className="prospect-avatar-name-title">
                                        <Tooltip
                                          arrow
                                          title={task.account.name}
                                        >
                                          <Avatar
                                            key={task.account.objectId}
                                            src={
                                              task.account.website &&
                                              getImageFromUrl(
                                                task.account.website,
                                                null,
                                              )
                                            }
                                            className={
                                              task.account.website
                                                ? 'account-avatar white'
                                                : 'account-avatar'
                                            }
                                          >
                                            {stringAvatarAccount(
                                              task.account.name || '',
                                            )}
                                          </Avatar>
                                        </Tooltip>

                                        <div className="prospect-name-container">
                                          <Tooltip
                                            arrow
                                            title={prospectName(task.prospect)}
                                          >
                                            <Typography
                                              className="prospect-name"
                                              fontSize={14}
                                              lineHeight={'18px'}
                                              fontWeight={500}
                                              noWrap
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                handleOpenContact(task);
                                              }}
                                            >
                                              {prospectName(task.prospect)}
                                            </Typography>
                                          </Tooltip>
                                          <Typography
                                            fontSize={12}
                                            fontWeight={400}
                                            lineHeight={'16px'}
                                            color={colors.grey11}
                                            noWrap
                                          >
                                            {task.prospect.title}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          gap: 6,
                                        }}
                                      >
                                        <TagsBoxShowAll
                                          type={
                                            task.prospect.fromContact
                                              ? 'Prospect'
                                              : 'Lead'
                                          }
                                          currentSituationTags={
                                            task.prospect.externalValues
                                              ?.filter(
                                                (ev) =>
                                                  ev.isTag && ev.value !== null,
                                              )
                                              .map((ev) => ({
                                                externalPropertyId:
                                                  ev.externalPropertyId,
                                                option: ev.value!,
                                              })) ?? []
                                          }
                                          blockIsEditable={true}
                                          disableEdit={true}
                                          showRemovable={false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="progress">
                                    <div className="icon-task-info">
                                      {renderTaskTypeIcon(task)}
                                      <div className="sequence-type-container">
                                        <Typography
                                          fontSize={12}
                                          fontWeight={500}
                                          color={colors.inactiveHover}
                                          noWrap
                                        >
                                          Overdue since{' '}
                                          {moment(task.activityDate).format(
                                            'MMM, Do',
                                          )}
                                        </Typography>

                                        <div className="sequence-type">
                                          <Typography
                                            fontSize={14}
                                            fontWeight={500}
                                            className="sequence-type-container-title"
                                            noWrap
                                            maxWidth={'110px'}
                                          >
                                            {task.sequenceInstanceId
                                              ? `${checkTaskType(task)}: ${
                                                  task.order
                                                }/${task.total}`
                                              : `${task.subject ?? task.type}`}
                                          </Typography>
                                          {task.automatic && (
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                columnGap: 4,
                                              }}
                                            >
                                              <span
                                                className={
                                                  task.scheduledEmailId !== null
                                                    ? 'scheduled-label'
                                                    : 'auto-label'
                                                }
                                              >
                                                {task.scheduledEmailId !== null
                                                  ? 'Scheduled'
                                                  : 'Auto'}
                                              </span>
                                            </div>
                                          )}
                                          {task.type === TaskTodoType.CALL &&
                                            task.inChargeStatus &&
                                            task.inChargeStatus !==
                                              'NotInCharge' && (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  columnGap: 4,
                                                }}
                                              >
                                                <span
                                                  className={
                                                    task.inChargeStatus ===
                                                    'InCharge'
                                                      ? 'in-charge-label'
                                                      : 'completed-label'
                                                  }
                                                >
                                                  {task.inChargeStatus ===
                                                  'InCharge'
                                                    ? 'Dialer'
                                                    : 'Completed'}
                                                </span>
                                              </div>
                                            )}
                                        </div>
                                        <div
                                          className="sequence-type-container-subtitle"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            if (task.sequenceInstanceId)
                                              handleShowSequenceInstance(task);
                                            else
                                              handleShowManualTaskResume(task);
                                          }}
                                        >
                                          <Typography
                                            fontSize={12}
                                            fontWeight={500}
                                            noWrap
                                            marginTop={'2px'}
                                            maxWidth={'110px'}
                                          >
                                            {task.sequenceInstanceId !== null
                                              ? task.sequenceInstance?.name
                                              : 'Task'}
                                          </Typography>
                                          <EditIcon
                                            style={{ width: 20, height: 20 }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="task-card-buttons-section">
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title="Reschedule task"
                                  >
                                    <span
                                      style={{
                                        display: 'flex',
                                        height: 'fit-content',
                                      }}
                                    >
                                      <CalendarRescheduleIcon
                                        color={colors.grey11}
                                        className="pointer"
                                        onClick={() =>
                                          handleOpenDateCalendar(task)
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={
                                      task.sequenceInstanceId !== null
                                        ? 'Skip task'
                                        : 'Delete task'
                                    }
                                  >
                                    <span
                                      style={{
                                        display: 'flex',
                                        height: 'fit-content',
                                      }}
                                    >
                                      <CloseMIcon
                                        color={colors.grey11}
                                        className="pointer"
                                        onClick={() => {
                                          if (
                                            task.sequenceInstanceId !== null
                                          ) {
                                            handleSkipTask(task.id);
                                          } else {
                                            handleDeleteManualTask(task.id);
                                          }
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    noTasksView
                  ))}

                {selectedTab === 'selectedDate' &&
                selectedDateTasks?.data &&
                selectedDateTasks?.data?.total ? (
                  <Pagination
                    count={selectedDateTasks?.data?.count}
                    total={selectedDateTasks?.data?.total}
                    itemOffset={searchTasksFilters.offset}
                    setItemOffset={setOffset}
                    limit={Constants.defaultLimit}
                    selectorStyle={{
                      width: '100%',
                      marginLeft: '24px',
                    }}
                  />
                ) : null}
                {selectedTab === 'overdue' &&
                pastMonthTasks?.data &&
                pastMonthTasks?.data?.total ? (
                  <Pagination
                    count={pastMonthTasks?.data?.count}
                    total={pastMonthTasks?.data?.total}
                    itemOffset={searchTasksFilters.offset}
                    setItemOffset={setOffset}
                    limit={Constants.defaultLimit}
                  />
                ) : null}
              </>
            ) : (
              noTasksView
            )}
          </div>
        </Grid>

        {/*Live feed */}
        <Grid item xs={3} className="section">
          <div
            className="inner-section-half live-feed"
            style={{ paddingRight: 0 }}
          >
            <NotificationBoxTooltip />
          </div>
        </Grid>
        {/*Suggestions */}
        <Grid item xs={3} className="section">
          <div
            className="inner-section-half suggestion"
            style={{ paddingRight: 0 }}
          >
            <SuggestionsBox />
          </div>
        </Grid>
      </Grid>
    </TaskHomeWrapper>
  );
};

export default Tasks;
