import { CheckRounded } from '@mui/icons-material';
import { Typography, Slider } from '@mui/material';
import React, { useState } from 'react';
import { Prospect } from 'crono-fe-common/types/prospect';
import { ProspectProgressWrapper } from './style';
import { colors } from 'crono-fe-common/theme';

interface IProps {
  prospect: Prospect;
  onClick?: () => void;
}

const ProgressProspect = React.forwardRef((props: IProps, ref: any) => {
  const { prospect, onClick, ...rest } = props;
  const [hovered, setHovered] = useState(false);
  return (
    <div
      onMouseEnter={(ev) => {
        setHovered(true);
      }}
      onMouseLeave={(ev) => {
        setHovered(false);
      }}
    >
      {prospect.sequenceInstance ? (
        <ProspectProgressWrapper
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 0,
          }}
          onClick={onClick}
          ref={ref}
          {...rest}
        >
          <CheckRounded
            style={{
              background: prospect.sequenceInstance.active
                ? hovered
                  ? colors.primaryLightHover
                  : colors.primaryLight
                : hovered
                  ? colors.grey33
                  : colors.grey444,
              borderRadius: 999,
              padding: 3,
              color: prospect.sequenceInstance.active
                ? colors.primaryDark
                : colors.mainDark,
              marginRight: 12,
            }}
          />
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 0,
            }}
          >
            <Typography
              variant="body1"
              fontWeight={500}
              style={{
                color: prospect.sequenceInstance.active
                  ? colors.primaryDark
                  : colors.mainDark,
              }}
            >
              {prospect.sequenceInstance.actualStep}
            </Typography>
            <Slider
              value={
                (prospect.sequenceInstance.actualStep /
                  prospect.sequenceInstance.numberOfSteps) *
                100
              }
              className="slider"
              style={{ marginInline: 12, cursor: 'pointer' }}
              sx={{
                '& .MuiSlider-track': {
                  color: prospect.sequenceInstance.active
                    ? colors.primaryDark
                    : colors.mainDark,
                },
                '& .MuiSlider-rail': {
                  color: colors.grey44,
                },
              }}
            />
            <Typography
              variant="body1"
              fontWeight={500}
              style={{ color: hovered ? colors.grey1 : colors.grey11 }}
            >
              {prospect.sequenceInstance.numberOfSteps}
            </Typography>
          </span>
        </ProspectProgressWrapper>
      ) : (
        <div>-</div>
      )}
    </div>
  );
});

export default ProgressProspect;
