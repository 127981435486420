import { FC, useEffect } from 'react';
import { ProfileWrapper } from './styles';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';

import { ProfileProps } from './types';
import { getError } from 'crono-fe-common/utils';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { useConditionalSnackBar } from 'context/snackbar';
import { useSelectedAccount } from 'pages/home/context/account';
import { colors } from 'crono-fe-common/theme';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import { transformToValidUrl, fullname } from 'utils/fe-utils';

const Profile: FC<ProfileProps> = ({
  profile,
  prospect,
  setOpen,
  callOnSuccess,
}) => {
  function openProfileUrl() {
    if (profile?.url && transformToValidUrl(profile.url) != null) {
      window.open(transformToValidUrl(profile.url)!, '_blank');
    }
  }

  const { setSelectedAccountId } = useSelectedAccount();

  const {
    mutate: editProspect,
    error: updateProspectError,
    isLoading,
    data: prospectEditedData,
    isSuccess: updateProspectSuccess,
  } = useEditProspect();

  function handleAddClick() {
    editProspect({
      prospectId: prospect.objectId,
      linkedin: profile.url,
      linkedinLeadId: profile.profileId,
    });
  }

  useEffect(() => {
    if (updateProspectSuccess) {
      if (callOnSuccess) callOnSuccess();
    }
  }, [updateProspectSuccess]);

  useEffect(() => {
    if (prospectEditedData && prospectEditedData.data.isSuccess) {
      setSelectedAccountId(prospect.accountId);
      setOpen(false);
    }
  }, [prospectEditedData]);

  useConditionalSnackBar([
    {
      condition:
        !!updateProspectError && updateProspectError.response?.status === 403,
      message: 'You are not the owner of this contact',
      severity: 'error',
    },
    {
      condition:
        !!updateProspectError && updateProspectError.response?.status !== 403,
      message:
        'Update contact error: ' + getError(updateProspectError) ??
        'Unknown error',
      severity: 'error',
    },
    {
      condition: !!prospectEditedData,
      message: 'Succesfully update contact LinkedIn profile',
      severity: 'success',
    },
  ]);

  return (
    <ProfileWrapper>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          padding: 8,
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            overflow: 'hidden',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Typography fontSize={16} fontWeight={500}>
            {fullname(profile.firstName, profile.lastName)}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color={colors.grey11}>
            {profile.title}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <IconButton
                className="go-to-linkedin-icon"
                onClick={() => openProfileUrl()}
              >
                <LinkedinIcon
                  color={colors.blueHover}
                  className="linkedin-icon"
                />
              </IconButton>
              <MainPrimaryButton
                className="add-profile-button"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleAddClick();
                }}
              >
                Add
              </MainPrimaryButton>
            </>
          )}
        </div>
      </div>
    </ProfileWrapper>
  );
};

export default Profile;
