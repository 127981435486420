import { TaskTodo } from './cronoTaskTodo';
import { SequenceInstanceStatus } from './enums/sequenceInstanceStatus';
import { Prospect } from './prospect';

export interface SequenceInstance {
  id: number;
  subscriptionId: number;
  userId: number;
  strategyId: number;
  sequenceId: number;
  accountId: string | null;
  prospectId: string | null;
  actualStep: number;
  numberOfSteps: number;
  startDate?: string;
  endDate?: string;
  lastStepDate?: string;
  active: boolean;
  tasks: TaskTodo[];
  name?: string | null;
  prospect?: Prospect;
  isPersonalized: boolean;

  status: SequenceInstanceStatus | null;
}

export const isComplete = (sequenceInstance: SequenceInstance) => {
  return sequenceInstance.actualStep === sequenceInstance.numberOfSteps;
};
