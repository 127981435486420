import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import { GraphData } from './tabsControllers/model';
import moment from 'moment';

export const numberOfGroups = (data: GraphData[]) => {
  const s = new Set(data.map((d) => d.label));
  return s.size;
};

export const calcWidth = (
  data: GraphData[],
  step: number,
  width: number | null | undefined,
) => {
  return Math.max(numberOfGroups(data) * step, 0.97 * (width ?? 0));
};

export const hasToGroup = (filterParams: AnalyticsFilters) => {
  if (!filterParams.since && !filterParams.to) return true;
  const since = filterParams.since ? new Date(filterParams.since) : new Date();
  const to = filterParams.to ? new Date(filterParams.to) : new Date();
  const diff = moment(to).diff(moment(since), 'days');
  return diff > 15;
};

//ForceMoreThanOne is used so that we can use this function for all graphs, but allowing some of them to have a fixed className
// (for example the horizontal bar chart)
export const selectChartContainerClass = (
  data: GraphData[],
  forceMoreThanOne = false,
) => {
  if (forceMoreThanOne || numberOfGroups(data) > 1)
    return 'chart-container-canvas';
  return 'chart-container-svg';
};
