import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Account } from 'crono-fe-common/types/account';

export default function useAccountExists(
  name: string | null,
  editingComplete: boolean,
) {
  // get key value object from searchParameters
  const queryString = new URLSearchParams({
    name: name ? name.trim() : '',
    equal: 'true',
  }).toString();

  const request: Request = {
    url: ENDPOINTS.account.exists + '?' + queryString,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.account.exists, queryString],
    queryFn: () => client(request),
    enabled: name !== null && name.length > 0 && editingComplete,
  };

  const { data, ...rest } = useQuery<Response<Account[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
