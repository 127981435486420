import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ExternalPropertyWrapper = styled.div`
  cursor: pointer;
  .tag-button {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    width: fit-content;
    max-width: 180px;
  }
  .tag-button > svg {
    margin-right: 12px;
  }
  .remove-icon {
    margin-right: 0px !important;
    width: 16px !important;
    height: 16px !important;
  }
`;
