import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { colors } from 'crono-fe-common/theme';
import { EditableTemplateWrapper } from './style';
import TextAccordion from 'crono-fe-common/components/TextAccordion';
import { Template } from 'crono-fe-common/types/template';
import {
  CancelSmallButton,
  MainPrimarySmallButton,
} from 'crono-fe-common/components/CronoButton';
import EditableTemplateModal from './editableTemplateModal';
import { ClickAwayListener, Typography } from '@mui/material';
import { useConfirmModal } from '../../../confirmModal';
import { useConditionalSnackBar } from '../../../snackbar';
import {
  calculateTemplateLength,
  formatHtmlContent,
  replaceAllTemplateVariables,
} from 'utils/fe-utils';
import { useLinkedinGetInvitationsLimit } from 'crono-fe-common/hooks/crono-extension/gateway';
import { Constants } from 'crono-fe-common/constants/constants';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useAuth } from 'context/auth';
import useGetProspect from 'hooks/services/prospect/useGetProspect';
import useGetAccount from 'hooks/services/account/useGetAccount';
import CronoEditor from 'components/CronoEditor/CronoEditor';
import ReactQuill from 'react-quill';
import { useAttachmentsFilePicker } from 'hooks/useAttachmentsFilePicker';
import { FileContent } from 'use-file-picker';
import useGetTemplateAttachments from 'hooks/services/templates/useGetTemplateAttachments';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { ReactComponent as DocumentIcon } from 'crono-fe-common/icons/Document-Stroke.svg';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import { useSafeStrategyOverviewContext } from '../../index';
import { CustomButton } from 'components/CronoEditor/CronoEditorToolbar';
import LoadingOverlay from 'components/LoadingOverlay';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';

interface IProps {
  previewOnly?: boolean;
  subject?: string;
  emailThreadButton?: ReactElement;
  enableSubjectMutation?: boolean;
  template: Template;
  stepId: number;
  isABTesting?: boolean;
  isConditional?: boolean;
  onSuccess: () => void;
  id?: string;
  //Function used to get the status of the edit also outside of the component (maybe to show something in parent component)
  setObserverEditMode?: (value: boolean) => void;

  onSaveReturnTemplate?: (
    subject: string,
    content: string,
    attachments?: (FileContent | CronoAttachment)[],
  ) => void;

  //For template variables substitution
  isSequenceInstance?: boolean;
  prospectId?: string | null;
  accountId?: string | null;

  //For conditionals and ABTesting we want to be sure that, even in the case in which the same template is used for the same type (something that should not be possible) the editor does not crash
  uniqueIdentifierOfTemplate?: number;

  allowToPersonalize?: boolean;
  //HACK to make conditional styling on editMode=true
  applyIdOnlyOnEditMode?: boolean;
}

const EditableTemplate = ({
  previewOnly = false,
  template,
  stepId,
  subject,
  emailThreadButton,
  enableSubjectMutation = true,
  isABTesting = false,
  isConditional = false,
  setObserverEditMode,
  onSuccess,
  id,
  onSaveReturnTemplate,
  isSequenceInstance = false,
  prospectId,
  accountId,
  uniqueIdentifierOfTemplate = 0,
  allowToPersonalize,
  applyIdOnlyOnEditMode,
}: IProps) => {
  const { user } = useAuth();
  const {
    insightsContext: {
      insightsCardRef,
      insightsButtonRef,
      prospect,
      setShowInsightTab,
      setRewriteEnabledMode,
      editorInFocus,
      setEditorInFocus,
      trackTemplateSaveIfNeeded,
      setRewriteSubmittedContext,
      isRewriteSequenceInstanceInProgress,
    },
  } = useSafeStrategyOverviewContext();

  const account = prospect?.account;
  const prospectIdToUse = prospectId ?? prospect?.objectId;
  const accountIdToUse = accountId ?? account?.objectId;

  const { data: prospectData } = useGetProspect(
    !isSequenceInstance ? undefined : prospectIdToUse,
    true,
    false,
    false,
  );

  const { data: accountData } = useGetAccount(
    !isSequenceInstance ? undefined : accountIdToUse ?? undefined,
    0,
    0,
    null,
    false,
  );

  const resetParsedSubject = () => {
    setParsedSubject(
      subject !== undefined &&
        (template.type === TemplateType.IN_MAIL ||
          template.type === TemplateType.EMAIL)
        ? parseTemplateVariablesSubject(subject)
        : undefined,
    );
  };

  useEffect(() => {
    if (!isSequenceInstance) {
      return;
    }
    setTemplateContentValue(parseTemplateVariablesContent(template.content));
    resetParsedSubject();
  }, [prospectData, accountData]);

  const { data: templateVariables } = useGetTemplateVariables();

  const parseTemplateVariablesContent = (content: string) => {
    if (!isSequenceInstance) {
      return formatHtmlContent(content);
    }
    return replaceAllTemplateVariables(
      formatHtmlContent(content),
      prospectData?.data?.data ?? null,
      accountData?.data.data ?? null,
      user ?? null,
      templateVariables?.data?.data ?? null,
    );
  };

  const parseTemplateVariablesSubject = (subject: string) => {
    if (!isSequenceInstance) {
      return subject;
    }
    return replaceAllTemplateVariables(
      subject,
      prospectData?.data?.data ?? null,
      accountData?.data.data ?? null,
      user ?? null,
      templateVariables?.data?.data ?? null,
    );
  };

  const parseSingleTemplateVariable = (variable: string) => {
    return replaceAllTemplateVariables(
      variable,
      prospectData?.data?.data ?? null,
      accountData?.data.data ?? null,
      user ?? null,
      templateVariables?.data?.data ?? null,
    );
  };

  const [templateContentValue, setTemplateContentValue] = useState<string>(
    parseTemplateVariablesContent(template.content),
  );
  const [templateContentError, setTemplateContentError] =
    useState<boolean>(false);

  const [parsedSubject, setParsedSubject] = useState<string | undefined>(
    subject !== undefined &&
      (template.type === TemplateType.IN_MAIL ||
        template.type === TemplateType.EMAIL)
      ? parseTemplateVariablesSubject(subject)
      : undefined,
  );

  const [subjectError, setSubjectError] = useState<boolean>(false);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showToolbar, setShowToolbar] = useState<boolean>(false);

  useEffect(() => {
    if (!expanded && editorRef.current) {
      setShowToolbar(false);
      editorRef.current.blur();
    }
  }, [expanded]);

  //Replicate the editMode state to the parent component
  useEffect(() => {
    if (setObserverEditMode) {
      setObserverEditMode(editMode);
    }
  }, [editMode]);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [typedCharacters, setTypedCharacters] = useState<number>(0);

  const { openModal: openConfirmModal } = useConfirmModal();

  const resetChanges = () => {
    setTemplateContentValue(parseTemplateVariablesContent(template.content));
    resetParsedSubject();
    if (existingAttachments?.data?.data) {
      setAttachments(existingAttachments.data.data);
    }
  };

  const handleSave = () => {
    const sanitizedHTML = templateContentValue
      .replace(/(<([^>]+)>)/gi, '')
      .trim();
    if (sanitizedHTML) {
      //If I passed a function it means that I want the value to be returned instead of handled inside
      trackTemplateSaveIfNeeded(templateContentValue);
      if (onSaveReturnTemplate) {
        onSaveReturnTemplate(
          parsedSubject ?? '',
          formatHtmlContent(templateContentValue),
          attachments,
        );
        setEditMode(false);
        handleClose();
        return;
      }
      setModalVisible(true);
    } else {
      setTemplateContentError(true);
    }
  };

  const handleCancel = () => {
    resetChanges();
    setEditMode(false);
    if (editorRef.current) {
      setShowToolbar(false);
      editorRef.current.blur();
    }
    // we submitted the rewrite, but then we didn't save the template -> june event should not be sent
    setRewriteSubmittedContext(null);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const clickAwayHandler = (e: any) => {
    if (
      editMode &&
      (!insightsButtonRef?.current ||
        !insightsButtonRef.current.contains(e.target)) &&
      (!insightsCardRef?.current || !insightsCardRef.current.contains(e.target))
    ) {
      e.preventDefault();
      e.stopPropagation();
      const sanitizedHTML = templateContentValue
        .replace(/(<([^>]+)>)/gi, '')
        .trim();

      if (!sanitizedHTML) {
        setTemplateContentError(true);
      } else {
        openConfirmModal({
          title:
            'Would you like to save edited template or discard the changes?',
          confirmText: 'Discard',
          cancelText: 'Save',
          confirmFunction: handleCancel,
          cancelFunction: handleSave,
          showCloseCrossTopRight: true,
        });
      }
    }
  };

  const handleSubjectChange = (e: any) => {
    if (!editMode) {
      setEditMode(true);
    }
    setParsedSubject(e.target.value);
  };

  const handleSuccess = () => {
    onSuccess();

    setEditMode(false);
    handleClose();
  };

  const handleTitleBlur = () => {
    if (!parsedSubject) {
      setSubjectError(true);
      resetParsedSubject();
    }
  };

  useEffect(() => {
    if (parsedSubject) {
      setSubjectError(false);
    }
  }, [parsedSubject]);

  useEffect(() => {
    if (templateContentError) {
      setTemplateContentError(false);
    }
  }, [templateContentError]);

  const [maxLinkedinInvitationChars, setMaxLinkedinInvitationChars] =
    useState<number>(Constants.linkedinInvitationMaxChars);

  const { call: getLinkedinInvitationLimits } =
    useLinkedinGetInvitationsLimit();

  useEffect(() => {
    getLinkedinInvitationLimits()
      .then((linkedinInvitationLimits) => {
        setMaxLinkedinInvitationChars(
          linkedinInvitationLimits?.maxMessageLength ??
            Constants.linkedinInvitationMaxChars,
        );
      })
      .catch((err) => {
        console.log('Error', err);
      });
  }, []);

  const editorRef = useRef<ReactQuill | null>(null);

  const [openFileSelector, { filesContent, errorMessage }] =
    useAttachmentsFilePicker();

  const [showVariablesPicker, setShowVariablesPicker] =
    useState<boolean>(false);

  const [attachments, setAttachments] = useState<
    (FileContent | CronoAttachment)[] | undefined
  >(undefined);
  const { data: existingAttachments } = useGetTemplateAttachments(template.id);

  useEffect(() => {
    if (existingAttachments?.data?.data) {
      setAttachments(existingAttachments.data.data);
    }
  }, [existingAttachments]);

  const handleAttachments = (files: FileContent[]) => {
    if (files && files.length > 0) {
      setEditMode(true);
      setAttachments((prev) => (prev || []).concat(files));
    }
  };

  useEffect(() => {
    handleAttachments(filesContent);
  }, [filesContent]);

  const removeAttachment = (index: number) => {
    setAttachments((attachments) => attachments?.filter((_, i) => i !== index));
  };

  useConditionalSnackBar([
    {
      condition: subjectError,
      message: "Can't save with empty Subject",
      severity: 'error',
    },
    {
      condition: templateContentError,
      message: "You can't save template without the text",
      severity: 'error',
    },
    {
      condition: !!errorMessage,
      message: errorMessage ?? 'Error uploading file',
      severity: 'error',
    },
  ]);

  const toolbarId = `toolbar-${template.id}-${stepId}-${uniqueIdentifierOfTemplate}`;

  // [HACK] To make toolbar visible when click on dropdown since we hide it on editor blur
  useEffect(() => {
    const toolbarElement = document.getElementById(toolbarId);

    const handleClick = (event: any) => {
      if (!event.target.id.includes('ql-rewrite')) {
        setEditMode(true);
      }
    };

    if (toolbarElement) {
      toolbarElement.addEventListener('click', handleClick);

      return () => {
        toolbarElement.removeEventListener('click', handleClick);
      };
    }
  }, [toolbarId]);

  const onRewriteWithAIClick = () => {
    setShowInsightTab((prevShowInsightTab) => {
      let prevRewriteEnabledMode;
      setRewriteEnabledMode((prev) => {
        prevRewriteEnabledMode = prev;
        return prevShowInsightTab && prev ? null : 'template';
      });

      return !(prevShowInsightTab && prevRewriteEnabledMode);
    });
  };

  // we update reference of editorInFocus one that got template changed
  useEffect(() => {
    if (editorInFocus !== null) {
      setEditorInFocus(editorRef.current);
    }
  }, [template]);

  const customToolbarButtons = useMemo(() => {
    const customButtons: CustomButton[] = [];

    if (
      template.type !== TemplateType.INVITATION &&
      template.type !== TemplateType.SCRIPT
    ) {
      customButtons.push({
        type: 'attachments',
        onClick: openFileSelector,
      });
    }

    customButtons.push({
      type: 'variables',
      showLabel: true,
      onClick: () => setShowVariablesPicker((prev) => !prev),
    });

    if (isSequenceInstance && allowToPersonalize) {
      customButtons.push({
        type: 'rewrite',
        showLabel: true,
        onClick: onRewriteWithAIClick,
      });
    }

    return customButtons;
  }, [template.type, isSequenceInstance]);

  return (
    <EditableTemplateWrapper
      id={
        id && (applyIdOnlyOnEditMode ? editMode : true)
          ? id
          : 'editable-template-wrapper'
      }
    >
      <ClickAwayListener
        onClickAway={clickAwayHandler}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <div
          className="container-editable-template"
          id={`container-editable-${toolbarId}`}
        >
          {parsedSubject !== undefined && (
            <div className="email-subject-container">
              <div className="email-subject">
                <Typography
                  fontSize={14}
                  lineHeight={'16px'}
                  color={colors.grey11}
                >
                  Subject:
                </Typography>

                {enableSubjectMutation ? (
                  <input
                    className="email-subject-input"
                    onChange={handleSubjectChange}
                    onBlur={handleTitleBlur}
                    value={parsedSubject}
                    disabled={previewOnly}
                  />
                ) : (
                  <span>{parsedSubject}</span>
                )}
              </div>
              {emailThreadButton && !previewOnly && (
                <div onClick={clickAwayHandler}>{emailThreadButton}</div>
              )}
            </div>
          )}
          <LoadingOverlay
            isLoading={isRewriteSequenceInstanceInProgress}
            loadingText={'Rewriting template...'}
            className={`full-width ${showToolbar || (editMode && expanded) ? '' : 'toolbar-hidden'}`}
          >
            <TextAccordion
              text={null}
              hideArrow={editMode}
              expanded={expanded}
              setExpanded={setExpanded}
              style={{
                border: '1px solid transparent',
                borderRadius: '8px',
                backgroundColor: colors.grey6,
                padding: '4px 12px 4px 12px',
                color: colors.black,
                fontSize: '14px',
              }}
            >
              <CronoEditor
                readOnly={previewOnly}
                toolbarId={toolbarId}
                editorRef={editorRef}
                value={templateContentValue}
                imageSupport={template.type === TemplateType.EMAIL}
                placeholder={'Type text...'}
                hideStylingButtons={template.type !== TemplateType.EMAIL}
                showVariablesPicker={showVariablesPicker}
                setShowVariablesPicker={setShowVariablesPicker}
                customButtonsToRight={false}
                parseVariable={
                  isSequenceInstance ? parseSingleTemplateVariable : undefined
                }
                onAttachmentDrop={handleAttachments}
                onBlur={() => setShowToolbar(false)}
                onFocus={() => {
                  if (previewOnly) return;

                  if (editorInFocus !== editorRef.current) {
                    setEditorInFocus(editorRef.current);
                  }
                  setExpanded(true);
                  setShowToolbar(true);
                }}
                onChange={(text, _, source) => {
                  if (previewOnly) return;

                  if (!editMode && source === 'user') {
                    setEditMode(true);
                    setExpanded(true);
                  }
                  setTemplateContentValue(text);

                  setTypedCharacters(calculateTemplateLength(text, true) ?? 0);
                }}
                customButtons={customToolbarButtons}
              >
                <>
                  {expanded && attachments && attachments.length > 0 ? (
                    <div className="file-preview">
                      {attachments?.map((attachment, index) => (
                        <div className="file" key={index}>
                          {isSequenceInstance ? (
                            <DocumentIcon />
                          ) : (
                            <CloseMIcon
                              className="delete-attachments-icon"
                              onClick={() => {
                                setEditMode(true);
                                removeAttachment(index);
                              }}
                            />
                          )}
                          {attachment.name}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                  {editMode && (
                    <div className="template-editing-buttons">
                      <CancelSmallButton
                        onClick={handleCancel}
                        className={'cancel-changes-button'}
                      >
                        Cancel
                      </CancelSmallButton>

                      <MainPrimarySmallButton
                        onClick={handleSave}
                        disabled={
                          template.type === TemplateType.INVITATION &&
                          typedCharacters > maxLinkedinInvitationChars
                        }
                      >
                        Save
                      </MainPrimarySmallButton>
                    </div>
                  )}
                </>
              </CronoEditor>
            </TextAccordion>
            {editMode && (
              <FlexDiv
                style={{
                  alignItems: 'flex-start',
                }}
              >
                {template.type === TemplateType.INVITATION && (
                  <>
                    <Typography
                      color={colors.grey11}
                      fontSize={'12px'}
                      fontWeight={500}
                      lineHeight={'18px'}
                      minWidth={'fit-content'}
                    >
                      {typedCharacters} / {maxLinkedinInvitationChars}
                    </Typography>
                    {typedCharacters > maxLinkedinInvitationChars ? (
                      <Typography
                        color={colors.inactive}
                        fontSize={'12px'}
                        fontWeight={500}
                        lineHeight={'18px'}
                        minWidth={'fit-content'}
                      >
                        The maximum number of characters for a Linkedin
                        invitation is {maxLinkedinInvitationChars}
                      </Typography>
                    ) : null}
                  </>
                )}
              </FlexDiv>
            )}
          </LoadingOverlay>

          {modalVisible && (
            <EditableTemplateModal
              close={handleClose}
              onSuccess={handleSuccess}
              template={template}
              stepId={stepId}
              content={templateContentValue}
              subject={parsedSubject ?? ''}
              attachments={attachments ? attachments : []}
              initialAttachments={
                existingAttachments?.data?.data
                  ? existingAttachments.data.data
                  : []
              }
              isABTesting={isABTesting}
              isConditional={isConditional}
            />
          )}
        </div>
      </ClickAwayListener>
    </EditableTemplateWrapper>
  );
};

export default EditableTemplate;
