import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';

export const Accordion = styled(
  ({
    disableTopBorder,
    ...props
  }: AccordionProps & { disableTopBorder?: boolean }) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ),
)(({ theme, disableTopBorder }) => ({
  borderBlock: `1px solid ${colors.grey4}`,
  borderTop: disableTopBorder ? 0 : `1px solid ${colors.grey4}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<BottomArrow />} {...props} />
))(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16px',
  height: 'fit-content',
  flex: 1,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '8px',
    overflow: 'hidden',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0px 18px 18px 18px',
}));
