import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { SnippetModalWrapper } from './style';
import { CircularProgress, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { useCallback, useEffect, useState } from 'react';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { ITemplateSnippet } from 'crono-fe-common/types/templateVariables';

interface IProps {
  onClose: () => void;
  onCreate: (label: string, content: string) => void;
  onUpdate: (label: string, content: string, id: number) => void;
  snippet: ITemplateSnippet | null;
}

const SnippetModal = ({ onClose, onCreate, onUpdate, snippet }: IProps) => {
  const [label, setLabel] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (snippet) {
      setLabel(snippet.label);
      setContent(snippet.content);
    }
  }, [snippet]);

  const handleSave = useCallback(() => {
    if (!label.length || !content.length) return;
    setIsLoading(true);

    //edit mode
    if (snippet) {
      onUpdate(label, content, snippet.id);
      return;
    }

    onCreate(label, content);
  }, [label, content]);

  return (
    // when copying text inside the content sometimes the popup was closing,
    // so we remove the click outside to close for the moment

    // <BlurBackgroundDivFixed onClick={onClose}>
    <BlurBackgroundDivFixed>
      <SnippetModalWrapper>
        <div
          // onClick={(e) => e.stopPropagation()}
          className="snippet-modal"
        >
          <Typography
            fontSize={24}
            fontWeight={700}
            lineHeight={'30px'}
            color={colors.black}
            marginBottom={'32px'}
          >
            {`${!snippet ? 'Create' : 'Edit'} snippet`}
          </Typography>

          <Typography
            fontSize={14}
            fontWeight={600}
            lineHeight={'22px'}
            color={colors.black}
          >
            Snippet name
          </Typography>

          <input
            className="snippet-modal__name"
            type="text"
            placeholder="Insert variable name..."
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />

          <textarea
            className="input snippet-modal__text-area"
            placeholder="Snippet content.."
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />

          <div className="snippet-modal__buttons">
            <CancelButton width="120px" fontWeight="400" onClick={onClose}>
              Back
            </CancelButton>

            {!isLoading ? (
              <MainButton
                width="120px"
                fontWeight="400"
                disabled={!label.length || !content.length}
                onClick={handleSave}
              >
                Save
              </MainButton>
            ) : (
              <FlexDiv justifyContent="center" width="132px">
                <CircularProgress />
              </FlexDiv>
            )}
          </div>
        </div>
      </SnippetModalWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default SnippetModal;
