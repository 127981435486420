import { Account, AccountStatus } from 'crono-fe-common/types/account';
import { Prospect, ProspectStatus } from 'crono-fe-common/types/prospect';
import { ExternalValue } from 'crono-fe-common/types/externalValue';
import {
  ExternalProperty,
  ExternalPropertyValueType,
} from 'crono-fe-common/types/externalProperty';
import { Tag } from 'crono-fe-common/types/tag';
import { TagInsert } from 'crono-fe-common/types/tagInsert';
import CronoError from 'crono-fe-common/types/error';
import { CallFeedback } from 'crono-fe-common/types/logCall';
import ExternalPropertyFilter from 'crono-fe-common/types/externalPropertyFilter';
import {
  Company,
  LinkedinCompany,
} from 'crono-fe-common/types/crono-extension/linkedin';
import {
  Constants,
  statusColors,
  StatusColors,
  statusColorsMap,
} from 'crono-fe-common/constants/constants';
import { ProspectLinkedin } from 'crono-fe-common/types/prospectLinkedin';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { ProductCreditsDTO } from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';
import moment from 'moment';

if (typeof window !== 'undefined') {
  window.Buffer = window.Buffer || require('buffer').Buffer;
}

export const getExternalPropertyLabels = (
  property: ExternalProperty | ExternalValue,
) => {
  return property.labels || property.options || [];
};

export const getExternalPropertyOptions = (
  property: ExternalProperty | ExternalValue,
) => {
  return property.options || [];
};
export const getLabel = (
  externalPropertyTags: ExternalProperty[],
  tag: Tag | TagInsert,
) => {
  const externalProperty = externalPropertyTags?.find(
    (externalProperty) => externalProperty.id === tag.externalPropertyId,
  );
  if (!externalProperty || !externalProperty.options) return null;
  return getExternalPropertyLabels(externalProperty)[
    externalProperty.options?.findIndex((property) => property === tag.value)
  ];
};

export function getTags(
  externalValues: ExternalValue[] | undefined | null,
  filterNull = false,
) {
  if (!externalValues) {
    return [];
  }
  if (!filterNull) return externalValues.filter((ev) => ev.isTag);
  return externalValues
    .filter((ev) => ev.isTag)
    .filter((value) => {
      return value.value != null && value.value !== '';
    });
}

//Function to get the status string to display when the type of status is unknown
export function getStringFromUnknownStatus(
  status: AccountStatus | ProspectStatus | null,
) {
  return (
    getStringFromAccountStatus(status as AccountStatus) ||
    getStringFromProspectStatus(status as ProspectStatus)
  );
}

export function getStringFromAccountStatus(status: AccountStatus | null) {
  switch (status) {
    case AccountStatus.INACTIVE:
      return 'Not in target';
    case AccountStatus.NURTURE:
      return 'Nurture';
    case AccountStatus.WORKING:
      return 'Prospecting';
    case AccountStatus.CALL_SCHEDULED:
      return 'Meeting';
    case AccountStatus.OPEN_OPPORTUNITY:
      return 'Opportunity';
    case AccountStatus.CUSTOMER:
      return 'Customer';
    default:
      return '';
  }
}

export function getStringFromProspectStatus(status: ProspectStatus) {
  switch (status) {
    case ProspectStatus.COLD:
      return 'Cold';
    case ProspectStatus.NOT_CONTACTED:
      return 'Not Contacted';
    case ProspectStatus.FIRST_CONTACT:
      return 'First Contact';
    case ProspectStatus.WARM:
      return 'Warm';
    case ProspectStatus.NOT_INTERESTED:
      return 'Not Interested';
    default:
      return '';
  }
}

export function getError(error: CronoError | null) {
  return error?.response?.data?.errors && error.response.data.errors.length > 0
    ? error!.response.data.errors![0]!.message
    : error?.message;
}

export function printCallFeedback(callFeedback: CallFeedback) {
  switch (callFeedback) {
    case CallFeedback.CONNECTED:
      return 'Connected';
    case CallFeedback.NOT_ANSWERED:
      return 'No Answer';
    case CallFeedback.GATE_KEEPER:
      return 'Gatekeeper';
    case CallFeedback.VOICEMAIL:
      return 'Voicemail';
    case CallFeedback.WRONG_NUMBER:
      return 'Wrong Number';
    default:
      return '';
  }
}

export function compareVersions(a: string, b: string): number {
  // this is a simple algorithm. If version format gets more complicated, we should switch to 'semver' package
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}

export const externalPropertiesToTagInsert = (
  input: ExternalPropertyFilter[],
) => {
  const templateTags: TagInsert[] = [];

  input.forEach((ep) => {
    ep?.values?.forEach((option) => {
      templateTags.push({
        externalPropertyId: ep.externalPropertyId,
        value: option,
      });
    });
  });

  return templateTags;
};

export function isCompany(object: any): object is Company {
  return 'role' in object;
}

function isLinkedinCompany(object: any): object is LinkedinCompany {
  return 'title' in object;
}

export const extractTitleFromLinkedinCompanyOrCompany = (
  company: Company | LinkedinCompany | null,
): string | null => {
  if (!company) return null;
  const title = isCompany(company)
    ? company.role
    : isLinkedinCompany(company)
      ? company.title
      : null;
  return title ?? null;
};

export const parseExternalPropertyName = (name: string) => {
  return name.toLocaleLowerCase().split(' ').join('_');
};

export const createVariableFormatFromExternalValue = (
  externaValue: ExternalValue,
) => {
  return `{{EP_${externaValue.externalPropertyId}_${parseExternalPropertyName(
    externaValue.name,
  )}}}`;
};
export const getColorsStatus = (
  statusOrder: number,
  totalNumberOfStatus: number,
) => {
  const numberOfColors = Object.keys(statusColors).length;
  let totalColors: StatusColors[] = [];

  //If the total number of statuss is less than the number of colors we have, we take the colors from the map
  if (totalNumberOfStatus <= numberOfColors) {
    totalColors = statusColorsMap[totalNumberOfStatus];
  } else {
    //In case we have more than the max number of colors we always take 12, erasing the last 2 colors and all the excessive ones at the end
    let currentTotal = totalNumberOfStatus;
    while (currentTotal > 0) {
      totalColors = totalColors.concat(statusColorsMap[numberOfColors]);
      currentTotal -= numberOfColors;
    }
  }

  return totalColors[statusOrder] ?? statusColorsMap[numberOfColors][0];
};

export function checkIfValidPhone(phone: string): boolean {
  const regex = Constants.phoneRegex;
  return regex.test(phone);
}

export function checkIfValidEmail(email: string): boolean {
  const regex = Constants.emailRegex;
  return regex.test(email);
}

interface DateObject {
  month?: number;
  year: number;
}

export const calculateDuration = (
  startDate: DateObject,
  endDate?: DateObject,
): { years: number; months: number } => {
  const currentDate = new Date();
  const end = endDate
    ? new Date(endDate.year, (endDate.month ?? 1) - 1)
    : currentDate;
  const start = new Date(startDate.year, (startDate.month ?? 1) - 1);

  let years = end.getFullYear() - start.getFullYear();
  let months = end.getMonth() - start.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }

  return { years, months };
};

interface TimePeriod {
  timePeriod?: {
    startDate: DateObject;
    endDate?: DateObject;
  };
}

export const sortByTimePeriod = (a: TimePeriod, b: TimePeriod) => {
  if (!a.timePeriod || !b.timePeriod) {
    return 0;
  }
  if (a.timePeriod.startDate.year < b.timePeriod.startDate.year) {
    return 1;
  } else if (a.timePeriod.startDate.year > b.timePeriod.startDate.year) {
    return -1;
  } else {
    if (
      (a.timePeriod.startDate.month ?? 1) < (b.timePeriod.startDate.month ?? 1)
    ) {
      return 1;
    } else if (
      (a.timePeriod.startDate.month ?? 1) > (b.timePeriod.startDate.month ?? 1)
    ) {
      return -1;
    } else {
      return 0;
    }
  }
};

export const roundDateToMinutes = (date: Date) => {
  const roundedValue = new Date(date);
  roundedValue.setHours(date.getHours());
  roundedValue.setMinutes(date.getMinutes());
  roundedValue.setSeconds(0);
  roundedValue.setMilliseconds(0);
  return roundedValue;
};

export const getCompanyIdFromUrn = (urn: string) => {
  // get all digits from the end of the string
  const match = urn.match(/(\d+)$/);
  return match ? match[0] : null;
};

export function htmlToText(html: string) {
  //remove code brakes and tabs
  html = html.replace(/\n/g, '');
  html = html.replace(/\t/g, '');

  //keep html brakes and tabs
  html = html.replace(/<br><\/p>/g, '\n');

  html = html.replace(/<\/td>/g, '\t');
  html = html.replace(/<\/table>/g, '\n');
  html = html.replace(/<\/tr>/g, '\n');
  html = html.replace(/<\/p>/g, '\n');
  html = html.replace(/<\/div>/g, '\n');
  html = html.replace(/<\/h>/g, '\n');
  html = html.replace(/<br>/g, '\n');
  html = html.replace(/<br( )*\/>/g, '\n');

  //parse html into text
  const dom = new DOMParser().parseFromString(
    '<!doctype html><body>' + html,
    'text/html',
  );
  return dom.body.textContent;
}

export function convertPeopleStringToNumber(
  peopleString: number | string | null,
): number | null {
  if (!peopleString) return null;
  if (typeof peopleString === 'number') return peopleString;
  peopleString = peopleString.toLowerCase();
  // Removing commas and plus sign
  peopleString = peopleString.replace(/,/g, '.').replace(/\+/g, '').trim();

  // Handling different suffixes
  if (peopleString.toLowerCase().includes('k')) {
    return parseFloat(peopleString.replace(/k/i, '')) * 1000;
  } else if (peopleString.toLowerCase().includes('m')) {
    return parseFloat(peopleString.replace(/m/i, '')) * 1000000;
  } else {
    return parseFloat(peopleString);
  }
}

export const mapProspectToProspectLinkedinAndCorrect = (
  prospect: Prospect | null,
): ProspectLinkedin | null => {
  if (
    !prospect ||
    !prospect.objectId ||
    !prospect.accountId ||
    !prospect.account?.objectId
  )
    return null;
  const newProspect: ProspectLinkedin = {
    ...prospect,
    name: prospect.name ?? null,
    correct: true,
    owner: prospect?.owned ?? false,
    firstName: prospect.firstName ?? null,
    lastName: prospect.lastName ?? null,
    linkedin: prospect.linkedin ?? null,
    email: prospect.email ?? null,
    phone: prospect.phone ?? null,
    title: prospect.title ?? '',
    account: {
      ...prospect.account,
      owner: prospect.account?.owned ?? false,
      canModify: prospect.account?.owned ?? false,
    },
  };
  return newProspect;
};

export const mapAccountToAccountLinkedinAndCorrect = (
  account: Account | null,
): AccountLinkedin | null => {
  if (!account || !account.objectId) return null;
  const newAccount: AccountLinkedin = {
    ...account,
    owner: account.owned ?? false,
    canModify: account.owned ?? false,
    correct: true,
  };
  return newAccount;
};

export const ensureArray = <T>(anyOrArray: T | T[]) => {
  return Array.isArray(anyOrArray) ? anyOrArray : [anyOrArray];
};

export const getCreditProductLabels = (credits: ProductCreditsDTO[]) => {
  return credits.map((el) => getCreditProductLabel(el));
};

export const getCreditProductLabel = (el: ProductCreditsDTO) => {
  const credits = el.credits ?? 0;
  const price = el.price?.amount ?? 0;
  const currency = el.price?.currency?.toLowerCase() === 'eur' ? '€' : '$';
  return `${credits} - ${price}${currency}`;
};

export const getOptionsMultiselect = (option: string | null) => {
  if (!option) return [];
  return option.split(',');
};

export const setOptionsMultiselect = (options: string[] | null) => {
  if (!options || options.length === 0) return null;
  return options.join(',');
};

export const getNewOptionsWhenSelecting = ({
  externalPropertyId,
  valueType,
  option,
  currentExternalValues,
}: {
  externalPropertyId: number;
  valueType: ExternalPropertyValueType;
  option: string | null;
  currentExternalValues: (ExternalValue | TagInsert)[] | null;
}) => {
  if (option === null) {
    return null;
  }
  const old = currentExternalValues?.find(
    (tag: any) => tag.externalPropertyId === externalPropertyId,
  );
  let newOptions: string | string[] | null | undefined = null;
  let finalOption: string | null = null;
  if (valueType === 'MultiSelect') {
    newOptions = getOptionsMultiselect(old?.value ?? null);
    if (newOptions.includes(option)) {
      newOptions = newOptions.filter((el) => el !== option);
    } else {
      newOptions.push(option);
    }
    if (newOptions.length > 0) {
      finalOption = setOptionsMultiselect(newOptions);
    }
  } else {
    newOptions = option;

    if (old && old.value === newOptions) {
      newOptions = null;
    }
    finalOption = newOptions ?? null;
  }
  return finalOption;
};

export const getNewOptionsWhenSelectingWithStrings = ({
  valueType,
  option,
  currentValue,
}: {
  valueType: ExternalPropertyValueType;
  option: string | null;
  currentValue: string | null;
}) => {
  if (option === null) {
    return null;
  }
  let newOptions: string | string[] | null | undefined = null;
  let finalOption: string | null = null;
  if (valueType === 'MultiSelect') {
    newOptions = getOptionsMultiselect(currentValue ?? null);

    if (newOptions.includes(option)) {
      newOptions = newOptions.filter((el) => el !== option);
    } else {
      newOptions.push(option);
    }

    if (newOptions.length > 0) {
      finalOption = setOptionsMultiselect(newOptions);
    }
  } else {
    newOptions = option;

    if (currentValue === newOptions) {
      newOptions = null;
    }
    finalOption = newOptions ?? null;
  }
  return finalOption;
};

export const toFixedOnlyIfNeeded = (value: number) => {
  return Math.round(value * 100) / 100;
};

export const getDateDiff = (date: Date) => {
  let diff = moment(moment()).diff(date, 'months');
  let time = 'm';

  if (diff === 0) {
    diff = moment(moment()).diff(date, 'weeks');
    time = 'w';
    if (diff === 0) {
      diff = moment(moment()).diff(date, 'days');
      time = 'd';
      if (diff === 0) {
        diff = moment(moment()).diff(date, 'hours');
        time = 'h';
      }
    }
  }

  return diff === 0 ? 'Just now' : `${diff}${time} ago`;
};

export function checkIfValidWebsite(url: string): boolean {
  const regex = Constants.websiteRegex;
  return regex.test(url);
}

export function extractDomainFromWebsite(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }
  try {
    // test url regex
    if (checkIfValidWebsite(url)) {
      const u = new URL(url);
      return u.host.replace('www.', '');
    } else {
      return '';
    }
  } catch (e) {
    //console.log("error extracting domain: " + url);
    return '';
  }
}

export const getImageFromUrl = (url: string | null, size: number | null) => {
  if (!url) return Constants.defaultImageUrl;
  const domain = extractDomainFromWebsite(url);
  if (!domain) return Constants.defaultImageUrl;

  if (!size) size = 64;

  // we use url instead of domain because it retrieves icon in more cases
  return `https://www.google.com/s2/favicons?sz=${size}&domain_url=${url}`;
};

export const stringAvatarAccount = (name: string) =>
  name.toUpperCase().substring(0, 2);
