import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const SelectedTemplateIcon = ({
  color = colors.darkGreen,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 1.74805C20.4337 1.74805 21.75 2.79878 21.75 4.27582V11.9374C21.2913 11.5864 20.788 11.2909 20.25 11.0608V4.27582C20.25 3.78842 19.7763 3.24805 19 3.24805H8C7.22373 3.24805 6.75 3.78842 6.75 4.27582V4.74609L10 4.74609V4.74805H16C17.4337 4.74805 18.75 5.79878 18.75 7.27582V10.6113C18.3444 10.5382 17.9267 10.5 17.5 10.5C17.4163 10.5 17.333 10.5015 17.25 10.5044V7.27582C17.25 6.78842 16.7763 6.24805 16 6.24805H5C4.22476 6.24805 3.75126 6.78699 3.75 7.27388L3.75 19.7183C3.75 20.2057 4.22373 20.7461 5 20.7461L11.2965 20.7461C11.5835 21.2934 11.9408 21.7981 12.3564 22.248H11V22.2461L5 22.2461C3.56627 22.2461 2.25 21.1954 2.25 19.7183V11.498V7.27582L2.25 7.27052C2.25199 5.79524 3.56736 4.74609 5 4.74609H5.25V4.27582C5.25 2.79878 6.56627 1.74805 8 1.74805H19ZM21.75 14.0087V16.7203C21.75 18.1973 20.4337 19.248 19 19.248H18.75V19.7183V19.7203C18.75 21.1973 17.4337 22.248 16 22.248H14.7223C14.0704 21.8659 13.5042 21.3533 13.0596 20.7461H16C16.7763 20.7461 17.25 20.2057 17.25 19.7183L17.25 15.4961V12.0056C17.3329 12.0019 17.4162 12 17.5 12C17.93 12 18.3484 12.0493 18.75 12.1427V15.4961L18.75 17.748H19C19.7763 17.748 20.25 17.2077 20.25 16.7203V12.7358C20.8235 13.0676 21.3315 13.4999 21.75 14.0087ZM12.8742 12.2461H6V13.7461H11.5906C11.9464 13.1872 12.3793 12.6822 12.8742 12.2461ZM13.4802 13.7461C13.9144 13.2814 14.4283 12.892 15 12.5997V13.7461H13.4802ZM10.6113 16.25H6V17.75H10.5044C10.5015 17.667 10.5 17.5837 10.5 17.5C10.5 17.0733 10.5382 16.6556 10.6113 16.25ZM6 8.24826H15.0037V9.74825H6V8.24826Z"
        fill={color}
      />
      <circle cx="17.5" cy="17.5" r="5.5" fill={color} />
      <path
        d="M23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12C20.5376 12 23 14.4624 23 17.5Z"
        fill={color}
      />
      <path
        d="M15.5312 17.125L17.1063 18.625L19.4688 16.375"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SelectedTemplateIcon;
