import { Company } from './company';
import { Token } from './token';
import Role from './enums/role';
import { SalesforceSettings } from './salesforceSettings';
import { GoogleSettings } from './googleSettings';
import { EmailSettings } from './emailSettings';
import { OtherSettings } from './otherSettings';
import { HubspotSettings } from './hubspotSettings';
import { UserPreferences } from './userPreferences';
import { PipedriveSettings } from './pipedriveSettings';
import { AircallSettings } from './aircallSettings';
import { IntegrationsOnboarding } from './DTO/integrationsOnboarding';
import IntegrationType from './enums/integrationType';
import { FeaturesOnboarding } from './DTO/featuresOnboarding';
import SubscriptionPlanType from './enums/subscriptionPlanType';
import { CustomEmailProviderSettings } from './customEmailProviderSettings';
import UserStatus from './enums/userStatus';

export interface User {
  id: number;
  subscriptionId: number;
  email: string;
  firstName: string | null;
  lastName: string | null;
  company: Company | null;
  userRoles: Role[];
  token: Token;
  salesforceSettings: SalesforceSettings | null;
  customEmailProviderSettings: CustomEmailProviderSettings | null;
  googleSettings: GoogleSettings | null;
  hubspotSettings: HubspotSettings | null;
  pipedriveSettings: PipedriveSettings | null;
  emailSettings: EmailSettings | null;
  aircallSettings: AircallSettings | null;
  userPreferences: UserPreferences;
  integrationsOnboarding?: IntegrationsOnboarding | null;

  //After the first integration is completed, in this is going to be set the integration type
  featuresOnboarding: FeaturesOnboarding | null;
  integrationType?: IntegrationType | null;

  otherSettings: OtherSettings | null;
  firstIntegration: boolean;
  active: boolean | null;
  status: UserStatus | null;
  candidate?: boolean;
  lastLogin?: Date | null;
  subscriptionType?: SubscriptionPlanType | null;
  isStripeCustomer: boolean;
}

export function getFullName(user: User): string {
  return `${user.firstName || ''} ${user.lastName || ''}`;
}

export function isIntegrated(user: User | null) {
  if (!user) return false;
  return (
    user.firstIntegration ||
    (!user.otherSettings?.waitFirstIntegration && !user.firstIntegration)
  );
}

export function needsIntegration(user: User | null) {
  return (
    user && !user.firstIntegration && user.otherSettings?.waitFirstIntegration
  );
}
