import React, { useEffect, useState } from 'react';
import { ThankYouPageWrapper } from './style';
import Logo from 'assets/images/logo_crono.png';
import { ReactComponent as IllustrationPaymentSuccessful } from 'assets/images/illustration_payment successful.svg';
import { CircularProgress, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { FeConstants } from 'constants/FeConstants';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { ENDPOINTS } from 'config/endpoints';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

import client from 'utils/clients/client';
import { User } from 'crono-fe-common/types/user';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { useAuth } from 'context/auth';
import { cookieDomain, cookies } from 'constants/crono-cookie';

const ThankYouPage = () => {
  const { setNewUser } = useAuth();
  const navigate = useNavigate();
  //To hide the thank you page while getting the cookie
  const [isToken, setIsToken] = useState<boolean>(false);
  //If there is a cookie I can stay in this page, otherwise Redirect to home
  useEffect(() => {
    const token = cookies.get(FeConstants.cronoThankYouCookie);
    if (!token) {
      navigate(PATH.HOME);
    } else {
      setIsToken(true);
    }
  }, []);

  //Redirect when the authMe returns the user as a stripeCustomer not expired
  useEffect(() => {
    let timeout: NodeJS.Timer;
    if (isToken) {
      timeout = setInterval(async () => {
        const request: Request = {
          url: ENDPOINTS.auth.me,
          config: {
            method: 'post',
          },
        };
        const user = await client(request)
          .then((result: Response<User>) => {
            if (result.data) {
              const user = result.data.data;
              return user;
            }
          })
          .catch();
        if (user?.isStripeCustomer && !user.company?.expired) {
          cookies.remove(FeConstants.cronoThankYouCookie, {
            path: '/',
            domain: cookieDomain,
          });
          setNewUser(user);
          navigate(PATH.HOME);
        }
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isToken]);

  const [showGoHomeButton, setShowGoHomeButton] = useState<boolean>(false);

  //After 10 seconds show a button to redirect the user to the home page
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isToken) {
      timeout = setTimeout(() => {
        setShowGoHomeButton(true);
      }, 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isToken]);

  const handleGoHome = () => {
    cookies.remove(FeConstants.cronoThankYouCookie, {
      path: '/',
      domain: cookieDomain,
    });
    navigate(PATH.HOME);
  };

  if (!isToken) {
    return <CircularProgress />;
  }
  return (
    <ThankYouPageWrapper>
      <div className="header-thank-you">
        <img src={Logo} alt="Crono" className="logo" />
      </div>
      <div className="body-thank-you">
        <span className="loader" />
        <IllustrationPaymentSuccessful />
      </div>
      <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
        Your payment was successful!
      </Typography>
      <Typography
        fontSize={14}
        lineHeight={'24px'}
        color={colors.grey11}
        width={'340px'}
        align="center"
      >
        Please wait while we complete upgrading your subscription. This usually
        takes few seconds
      </Typography>
      {showGoHomeButton && (
        <MainButton style={{ margin: 0, height: 40 }} onClick={handleGoHome}>
          Go to Home
        </MainButton>
      )}
    </ThankYouPageWrapper>
  );
};

export default ThankYouPage;
