import { Strategy } from 'crono-fe-common/types/strategy';
import { SequenceCardWrapper } from './style';
import { Typography } from '@mui/material';
import TagsBoxShowAll from 'components/TagsBoxShowAll';

interface IProps {
  sequence: Strategy | undefined;
}

const SequenceCard = ({ sequence }: IProps) => {
  if (!sequence) return <></>;
  return (
    <SequenceCardWrapper>
      <Typography fontSize={12.25} fontWeight={700}>
        {sequence.name}
      </Typography>
      {!!sequence.numberOfSteps && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 6,
            rowGap: 6,
            marginTop: 8,
            flexWrap: 'wrap',
          }}
        >
          <div className="steps-counter-container">
            {sequence.numberOfSteps} steps
          </div>
          <TagsBoxShowAll
            type="AccountAndProspect"
            currentSituationTags={
              sequence.strategyTags?.map((tag) => ({
                externalPropertyId: tag.externalPropertyId,
                option: tag.value,
              })) ?? []
            }
            disableEdit={true}
            blockIsEditable={true}
            showRemovable={false}
          />
        </div>
      )}
    </SequenceCardWrapper>
  );
};

export default SequenceCard;
