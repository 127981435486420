import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TemplateVariablesSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.grey444};
  max-height: 250px;
  width: 182px;
  overflow-y: auto;
  .variable-item {
    border-radius: 8px;
    padding: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background-color: ${colors.primaryLight};
      color: ${colors.primary};
    }
  }
  .other-variables {
    color: ${colors.grey11};
    &:hover {
      background-color: ${colors.primaryLight};
      color: ${colors.primary};
      svg {
        path {
          stroke: ${colors.primary};
        }
      }
    }
  }
`;
