import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Account } from 'crono-fe-common/types/account';

export default function useGetAccount(
  id?: string,
  prospectLimit?: number,
  prospectOffset?: number,
  selectedProspectId?: string | null,
  onlyOwned = false,
) {
  const requestConfig = {
    queryKey: [
      ENDPOINTS.account.main,
      id,
      prospectLimit,
      prospectOffset,
      selectedProspectId,
      onlyOwned,
    ],
    queryFn: () => {
      let queryParams = '';

      if (prospectLimit) {
        queryParams += `?prospectLimit=${prospectLimit}`;
        if (prospectOffset) {
          queryParams += `&prospectOffset=${prospectOffset}`;
        }
      }
      if (selectedProspectId) {
        queryParams += `&selectedProspectId=${selectedProspectId}`;
      }
      if (onlyOwned) {
        queryParams += `&onlyOwned=${onlyOwned}`;
      }

      const request: Request = {
        url: `${ENDPOINTS.account.main}/${id}${queryParams}`,
        config: {
          method: 'get',
        },
      };
      return client(request);
    },
    enabled: !!id,
    staleTime: 30 * 1000,
  };

  return useQuery<Response<Account>, CronoError>(requestConfig);
}
