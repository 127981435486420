import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export interface StageDeleteInputs {
  PipelineId: number;
  StageId: number;
}

export default function useDeleteStage() {
  const queryClient = useQueryClient();

  const deleteStage = (deleteInput: StageDeleteInputs) => {
    const queryParams = queryString.stringify(deleteInput);

    const request: Request = {
      url: `${ENDPOINTS.pipeline.stage}?${queryParams}`,
      config: {
        method: 'delete',
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    StageDeleteInputs
  >(deleteStage, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.pipeline.main]);
      queryClient.invalidateQueries([ENDPOINTS.pipeline.sync]);
      queryClient.invalidateQueries([ENDPOINTS.opportunity.main]);
      queryClient.invalidateQueries([ENDPOINTS.opportunity.search]);
    },
  });

  return { data, mutate, ...rest };
}
