import { CircularProgress } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PATH from 'routing/path';

const SettingsInterceptor = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? undefined;

  useEffect(() => {
    navigate(
      PATH.SETTINGS,
      tab
        ? {
            state: { initialSettingsTab: tab },
          }
        : undefined,
    );
  });
  return (
    <>
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    </>
  );
};

export default SettingsInterceptor;
