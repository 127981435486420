import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const ConditionalForkIcon = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        id="Path"
        d="M19.4142 16.5858C20.1953 17.3668 20.1953 18.6332 19.4142 19.4142C18.6332 20.1953 17.3668 20.1953 16.5858 19.4142C15.8047 18.6332 15.8047 17.3668 16.5858 16.5858C17.3668 15.8047 18.6332 15.8047 19.4142 16.5858"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M7.41421 16.5858C8.19526 17.3668 8.19526 18.6332 7.41421 19.4142C6.63316 20.1953 5.36683 20.1953 4.58579 19.4142C3.80474 18.6332 3.80474 17.3668 4.58579 16.5858C5.36684 15.8047 6.63317 15.8047 7.41421 16.5858"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M13.4142 4.58579C14.1953 5.36684 14.1953 6.63317 13.4142 7.41421C12.6332 8.19526 11.3668 8.19526 10.5858 7.41421C9.80474 6.63316 9.80474 5.36683 10.5858 4.58579C11.3668 3.80474 12.6332 3.80474 13.4142 4.58579"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 173"
        d="M6 16V14C6 12.8954 6.89543 12 8 12H12H16C17.1046 12 18 12.8954 18 14V16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 174"
        d="M12 8V12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ConditionalForkIcon;
