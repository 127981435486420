import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export const cookieDomain = (() => {
  let cookieDomain = encodeURI(process.env.REACT_APP_FE_URL as string);
  // remove https://
  cookieDomain = cookieDomain.replace(/http(s?):\/\//, '');
  return cookieDomain.indexOf('localhost') === 0 ? undefined : cookieDomain;
})();
