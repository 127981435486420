import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect } from 'react';
import { Template } from 'crono-fe-common/types/template';
import { DeleteTemplateModalWrapper } from './style';
import useGetTemplateUsage from 'hooks/services/templates/useGetTemplateUsage';
import { colors } from 'crono-fe-common/theme';
import { CircularProgress, Typography } from '@mui/material';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import useDeleteTemplate from 'hooks/services/templates/useDeleteTemplate';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useJuneAnalytics } from 'context/june';
import { a } from '@react-spring/web';

interface IProps {
  template: Template;
  close: () => void;
}

const DeleteTemplateModal = ({ template, close }: IProps) => {
  const { data: templateUsage } = useGetTemplateUsage(template.id);

  const analytics = useJuneAnalytics();

  const {
    mutate: deleteTemplate,
    isSuccess,
    error,
    isLoading,
  } = useDeleteTemplate();

  useConditionalSnackBar([
    {
      condition: !!isSuccess,
      message: 'Template deleted successfully',
      severity: 'success',
    },
    {
      condition: !!error,
      message: getError(error) ?? 'Error deleting template',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  const handleDeleteTemplate = (templateId: number) => {
    if (analytics) {
      analytics.track('delete-template', {
        templateId: templateId,
      });
    }
    deleteTemplate(templateId);
  };

  return (
    <BlurBackgroundDivFixed>
      <DeleteTemplateModalWrapper>
        <CloseTabButton close={close} />
        <div className="delete-template-container">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Are you sure you want to delete template '{template.title}'?
          </Typography>
          {templateUsage?.data?.data.strategies.length === 0 ? (
            <Typography
              fontSize={16}
              fontWeight={400}
              lineHeight={'24px'}
              color={colors.grey11}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 130px)',
              }}
            >
              Currently this template is used by 0 strategies
            </Typography>
          ) : (
            <Typography
              fontSize={16}
              fontWeight={400}
              lineHeight={'24px'}
              color={colors.grey11}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 130px)',
              }}
            >
              This template is currently being used in the following active
              strategies:
              <ul className="strategies-remove-template-list">
                {templateUsage?.data?.data?.strategies.map((strategy) => (
                  <li key={strategy.id}>{strategy.name}</li>
                ))}
              </ul>
            </Typography>
          )}
          <div style={{ alignSelf: 'flex-end' }}>
            <CancelButton onClick={close}>Cancel</CancelButton>
            {!isLoading ? (
              <MainButton
                style={{ background: colors.inactive }}
                onClick={() => handleDeleteTemplate(template.id)}
              >
                Delete
              </MainButton>
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      </DeleteTemplateModalWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default DeleteTemplateModal;
