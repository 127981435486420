import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect, useState } from 'react';
import { CustomEmailProviderSettingsModalWrapper } from './styles';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { CircularProgress, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import {
  CustomEmailProviderSettingsForm,
  customEmailProviderSettingsFormSchema,
} from './customEmailProviderModel';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as PasswordHiddenIcon } from 'crono-fe-common/icons/Password-Hidden-Icon.svg';
import { ReactComponent as PasswordVisibleIcon } from 'crono-fe-common/icons/Password-Showed-Icon.svg';
import { useAuth } from 'context/auth';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import useConnectCustomEmailProviderIntegration from 'hooks/services/customEmailIntegration/useConnectCustomEmailProviderIntegration';
import useVerifyCustomEmailProviderIntegration from 'hooks/services/customEmailIntegration/useVerifyCustomEmailProviderIntegration';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import IconErrorIndicatorS from 'crono-fe-common/icons/Icon-Error-Indicator-S';
import useTestSmtpCustomEmailProviderIntegration from 'hooks/services/customEmailIntegration/useTestSmtpCustomEmailProviderIntegration';
import useTestImapCustomEmailProviderIntegration from 'hooks/services/customEmailIntegration/useTestImapCustomEmailProviderIntegration';

interface IProps {
  close: () => void;
  onSuccess: () => void;
}

const CustomEmailProviderSettingsModal = ({ close, onSuccess }: IProps) => {
  const { user } = useAuth();

  const {
    verifyCustomEmailProviderIntegration,
    data: initialValues,
    isLoading: loadingInitialValues,
  } = useVerifyCustomEmailProviderIntegration();

  const [triedSubmissionGeneral, setTriedSubmissionGeneral] = useState(false);
  const [triedSubmissionSmtp, setTriedSubmissionSmtp] = useState(false);
  const [triedSubmissionImap, setTriedSubmissionImap] = useState(false);
  const {
    mutate: connectCustomEmailProvider,
    isLoading: loadingConnectCustomEmailProvider,
    isSuccess: successConnectCustomEmailProvider,
    error: errorConnectCustomEmailProvider,
  } = useConnectCustomEmailProviderIntegration();
  const formik = useFormik<CustomEmailProviderSettingsForm>({
    initialValues: initialValues?.data.data || {
      senderName: user?.customEmailProviderSettings?.senderName ?? null,
      email: user?.customEmailProviderSettings?.email ?? null,
      smtpUser: user?.customEmailProviderSettings?.smtpUser ?? null,
      smtpHost: user?.customEmailProviderSettings?.smtpHost ?? null,
      smtpPort: user?.customEmailProviderSettings?.smtpPort ?? null,
      imapUser: user?.customEmailProviderSettings?.imapUser ?? null,
      imapHost: user?.customEmailProviderSettings?.imapHost ?? null,
      imapPort: user?.customEmailProviderSettings?.imapPort ?? null,
      imapPassword: null,
      smtpPassword: null,
    },

    validationSchema: customEmailProviderSettingsFormSchema,
    onSubmit: (values) => {
      console.log(values);
      connectCustomEmailProvider({
        senderName: values.senderName!,
        email: values.email!,
        smtpUser: values.smtpUser!,
        smtpPassword: values.smtpPassword!,
        smtpHost: values.smtpHost!,
        smtpPort: values.smtpPort!,
        imapUser: values.imapUser!,
        imapPassword: values.imapPassword!,
        imapHost: values.imapHost!,
        imapPort: values.imapPort!,
      });
    },
  });

  useEffect(() => {
    if (!initialValues?.data.data) return;
    formik.setValues(initialValues.data.data);
  }, [initialValues]);

  useEffect(() => {
    verifyCustomEmailProviderIntegration();
  }, []);

  const [showSMTPPassword, setShowSMTPPassword] = useState(false);
  const [showIMAPPassword, setShowIMAPPassword] = useState(false);

  useEffect(() => {
    if (successConnectCustomEmailProvider) {
      onSuccess();
    }
  }, [successConnectCustomEmailProvider]);

  const {
    mutate: testSmtp,
    isSuccess: successTestSmtp,
    error: errorTestSmtp,
    isLoading: isLoadingTestSmtp,
  } = useTestSmtpCustomEmailProviderIntegration();

  //We show the send preview email button in the smtp box only if all the fields are filled
  const showSendEmailPreviewButton =
    formik.values.smtpHost &&
    formik.values.smtpPort &&
    formik.values.smtpUser &&
    formik.values.smtpPassword;

  const handleSendPreviewEmail = async () => {
    const _res = await formik.validateForm();
    setTriedSubmissionSmtp(true);
    if (
      !_res.email &&
      !_res.smtpHost &&
      !_res.smtpPort &&
      !_res.smtpUser &&
      !_res.smtpPassword
    ) {
      testSmtp({
        email: formik.values.email!,
        user: formik.values.smtpUser!,
        password: formik.values.smtpPassword!,
        host: formik.values.smtpHost!,
        port: formik.values.smtpPort!,
      });
    }
  };

  const {
    mutate: testImap,
    isSuccess: successTestImap,
    error: errorTestImap,
    isLoading: isLoadingTestImap,
  } = useTestImapCustomEmailProviderIntegration();

  const handleVerifyImap = async () => {
    const _res = await formik.validateForm();
    setTriedSubmissionImap(true);
    if (
      !_res.email &&
      !_res.imapHost &&
      !_res.imapPort &&
      !_res.imapUser &&
      !_res.imapPassword
    ) {
      testImap({
        email: formik.values.email!,
        user: formik.values.imapUser!,
        password: formik.values.imapPassword!,
        host: formik.values.imapHost!,
        port: formik.values.imapPort!,
      });
    }
  };

  useEffect(() => {
    if (
      !formik.values.imapHost ||
      !formik.values.imapPort ||
      !formik.values.imapUser ||
      !formik.values.imapPassword
    )
      return;
    const debounce = setTimeout(() => {
      handleVerifyImap();
    }, 1500);
    return () => clearTimeout(debounce);
  }, [
    formik.values.email,
    formik.values.imapHost,
    formik.values.imapPort,
    formik.values.imapUser,
    formik.values.imapPassword,
  ]);

  useConditionalSnackBar([
    {
      condition: successConnectCustomEmailProvider,
      message: 'Custom email provider settings saved successfully',
      severity: 'success',
    },
    {
      condition: !!errorConnectCustomEmailProvider,
      message:
        getError(errorConnectCustomEmailProvider) ??
        'Error saving custom email provider settings',
      severity: 'error',
    },
    {
      condition: !!successTestSmtp,
      message: 'Smtp connection correctly verified',
      severity: 'success',
    },
    {
      condition: !!errorTestSmtp,
      message: getError(errorTestSmtp) ?? 'Error verifying smtp connection',
      severity: 'error',
    },
    {
      condition: !!successTestImap,
      message: 'Imap connection correctly verified',
      severity: 'success',
    },
    {
      condition: !!errorTestImap,
      message: getError(errorTestImap) ?? 'Error verifying imap connection',
      severity: 'error',
    },
  ]);

  return (
    <BlurBackgroundDivFixed>
      <CustomEmailProviderSettingsModalWrapper
        onClick={(ev: any) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
      >
        <CloseTabButton close={close} />
        <div className="inner-content-container">
          <Typography fontSize={24} lineHeight={'30px'} fontWeight={700}>
            Fill email provider details
          </Typography>
          {loadingInitialValues ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <>
              <div className="inner-content-container-body">
                <div className="inner-content-container-line">
                  <div className="inner-content-container-line-item">
                    <Typography className="inner-content-container-line-item-title">
                      Sender name
                    </Typography>
                    <TextField
                      value={formik.values.senderName}
                      onChange={(ev) => {
                        formik.setFieldValue('senderName', ev.target.value);
                      }}
                      variant="standard"
                      placeholder="Insert sender name..."
                      helperText={
                        triedSubmissionGeneral && formik.errors.senderName
                      }
                      error={
                        triedSubmissionGeneral &&
                        Boolean(formik.errors.senderName)
                      }
                    />
                  </div>
                  <div className="inner-content-container-line-item">
                    <Typography className="inner-content-container-line-item-title">
                      Email address
                    </Typography>
                    <TextField
                      value={formik.values.email}
                      onChange={(ev) => {
                        formik.setFieldValue('email', ev.target.value);
                      }}
                      variant="standard"
                      placeholder="Insert email..."
                      helperText={
                        (triedSubmissionImap ||
                          triedSubmissionSmtp ||
                          triedSubmissionGeneral) &&
                        formik.errors.email
                      }
                      error={
                        (triedSubmissionImap ||
                          triedSubmissionSmtp ||
                          triedSubmissionGeneral) &&
                        Boolean(formik.errors.email)
                      }
                    />
                  </div>
                </div>
                <div
                  className="inner-content-container-block"
                  style={{ marginTop: 8 }}
                >
                  <div className="inner-content-container-block-title">
                    <Typography
                      fontSize={14}
                      lineHeight={'22px'}
                      fontWeight={600}
                    >
                      Custom provider settings
                    </Typography>
                    <Typography
                      fontSize={12}
                      lineHeight={'16px'}
                      color={colors.grey11}
                    >
                      Here're the advanced SMTP & IMAP settings of your custom
                      provider
                    </Typography>
                  </div>
                  <div className="inner-content-container-option-box">
                    <div
                      style={{ display: 'flex', gap: 8, alignItems: 'center' }}
                    >
                      <Typography
                        fontSize={14}
                        lineHeight={'22px'}
                        fontWeight={600}
                      >
                        SMTP settings
                      </Typography>
                      {errorTestSmtp && (
                        <div className="connect-email-provider-error-with-icon">
                          <IconErrorIndicatorS
                            fill={colors.inactiveLightHover}
                            color={colors.inactive}
                          />{' '}
                          Not configured: The configuration was unsuccessful
                        </div>
                      )}
                      {successTestSmtp && (
                        <div className="connect-email-provider-success">
                          Configured
                        </div>
                      )}
                    </div>
                    <div className="inner-content-container-line">
                      <TextField
                        value={formik.values.smtpUser}
                        onChange={(ev) => {
                          formik.setFieldValue('smtpUser', ev.target.value);
                        }}
                        variant="standard"
                        placeholder="Insert login..."
                        helperText={
                          triedSubmissionSmtp && formik.errors.smtpUser
                        }
                        error={
                          triedSubmissionSmtp && Boolean(formik.errors.smtpUser)
                        }
                        fullWidth
                      />
                      <TextField
                        value={formik.values.smtpPassword}
                        onChange={(ev) => {
                          formik.setFieldValue('smtpPassword', ev.target.value);
                        }}
                        placeholder="Insert password..."
                        InputProps={{
                          endAdornment:
                            formik.values.smtpPassword &&
                            (showSMTPPassword ? (
                              <PasswordVisibleIcon
                                onClick={() => setShowSMTPPassword(false)}
                                style={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <PasswordHiddenIcon
                                onClick={() => setShowSMTPPassword(true)}
                                style={{ cursor: 'pointer' }}
                              />
                            )),
                        }}
                        variant="standard"
                        helperText={
                          triedSubmissionSmtp && formik.errors.smtpPassword
                        }
                        error={
                          triedSubmissionSmtp &&
                          Boolean(formik.errors.smtpPassword)
                        }
                        fullWidth
                        type={showSMTPPassword ? 'text' : 'password'}
                      />
                    </div>
                    <div className="inner-content-container-line">
                      <TextField
                        value={formik.values.smtpHost}
                        onChange={(ev) => {
                          formik.setFieldValue('smtpHost', ev.target.value);
                        }}
                        variant="standard"
                        placeholder="Insert server address..."
                        helperText={
                          triedSubmissionSmtp && formik.errors.smtpHost
                        }
                        error={
                          triedSubmissionSmtp && Boolean(formik.errors.smtpHost)
                        }
                        fullWidth
                      />
                      <TextField
                        value={formik.values.smtpPort}
                        onChange={(ev) => {
                          formik.setFieldValue('smtpPort', ev.target.value);
                        }}
                        variant="standard"
                        placeholder="Insert port (ie: 587)..."
                        helperText={
                          triedSubmissionSmtp && formik.errors.smtpPort
                        }
                        error={
                          triedSubmissionSmtp && Boolean(formik.errors.smtpPort)
                        }
                        fullWidth
                      />
                    </div>

                    <div className="inner-content-container-line">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: -6,
                        }}
                      >
                        <CronoCheckbox
                          checked={true}
                          disabled={true}
                          style={{ marginLeft: 0 }}
                        />
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                        >
                          Enable SSL
                        </Typography>
                      </div>
                      {showSendEmailPreviewButton &&
                        (isLoadingTestSmtp ? (
                          <CircularProgress
                            size={16}
                            style={{ marginRight: 20 }}
                          />
                        ) : (
                          <CancelButton
                            style={{
                              margin: 0,
                              height: 32,
                              padding: '7px 12px 7px 12px',
                            }}
                            endIcon={<EmailIcon />}
                            onClick={handleSendPreviewEmail}
                          >
                            Send test email
                          </CancelButton>
                        ))}
                    </div>
                  </div>
                  <div className="inner-content-container-option-box">
                    <div
                      style={{ display: 'flex', gap: 8, alignItems: 'center' }}
                    >
                      <Typography
                        fontSize={14}
                        lineHeight={'22px'}
                        fontWeight={600}
                      >
                        IMAP settings
                      </Typography>
                      {errorTestImap && (
                        <div className="connect-email-provider-error">
                          Not configured
                        </div>
                      )}
                      {successTestImap && (
                        <div className="connect-email-provider-success">
                          Configured
                        </div>
                      )}
                      {isLoadingTestImap && (
                        <CircularProgress
                          size={16}
                          style={{ marginRight: 20 }}
                        />
                      )}
                    </div>
                    <div className="inner-content-container-line">
                      <TextField
                        value={formik.values.imapUser}
                        onChange={(ev) => {
                          formik.setFieldValue('imapUser', ev.target.value);
                        }}
                        variant="standard"
                        placeholder="Insert login..."
                        helperText={
                          triedSubmissionImap && formik.errors.imapUser
                        }
                        error={
                          triedSubmissionImap && Boolean(formik.errors.imapUser)
                        }
                        fullWidth
                      />
                      <TextField
                        value={formik.values.imapPassword}
                        onChange={(ev) => {
                          formik.setFieldValue('imapPassword', ev.target.value);
                        }}
                        placeholder="Insert password..."
                        variant="standard"
                        InputProps={{
                          endAdornment:
                            formik.values.imapPassword &&
                            (showSMTPPassword ? (
                              <PasswordVisibleIcon
                                onClick={() => setShowIMAPPassword(false)}
                                style={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <PasswordHiddenIcon
                                onClick={() => setShowIMAPPassword(true)}
                                style={{ cursor: 'pointer' }}
                              />
                            )),
                        }}
                        helperText={
                          triedSubmissionImap && formik.errors.imapPassword
                        }
                        error={
                          triedSubmissionImap &&
                          Boolean(formik.errors.imapPassword)
                        }
                        fullWidth
                        type={showIMAPPassword ? 'text' : 'password'}
                      />
                    </div>
                    <div className="inner-content-container-line">
                      <TextField
                        value={formik.values.imapHost}
                        onChange={(ev) => {
                          formik.setFieldValue('imapHost', ev.target.value);
                        }}
                        variant="standard"
                        placeholder="Insert server address..."
                        helperText={
                          triedSubmissionImap && formik.errors.imapHost
                        }
                        error={
                          triedSubmissionImap && Boolean(formik.errors.imapHost)
                        }
                        fullWidth
                      />
                      <TextField
                        value={formik.values.imapPort}
                        onChange={(ev) => {
                          formik.setFieldValue('imapPort', ev.target.value);
                        }}
                        variant="standard"
                        placeholder="Insert port (ie: 993)..."
                        helperText={
                          triedSubmissionImap && formik.errors.imapPort
                        }
                        error={
                          triedSubmissionImap && Boolean(formik.errors.imapPort)
                        }
                        fullWidth
                      />
                    </div>

                    <div className="inner-content-container-line">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: -6,
                        }}
                      >
                        <CronoCheckbox
                          checked={true}
                          disabled={true}
                          style={{ marginLeft: 0 }}
                        />
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                        >
                          Enable SSL
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner-content-footer">
                <CancelButton onClick={close} style={{ margin: 0 }}>
                  Cancel
                </CancelButton>
                {loadingConnectCustomEmailProvider ? (
                  <CircularProgress />
                ) : (
                  <MainButton
                    onClick={() => {
                      setTriedSubmissionSmtp(true);
                      setTriedSubmissionImap(true);
                      setTriedSubmissionGeneral(true);
                      formik.handleSubmit();
                    }}
                    style={{ margin: 0 }}
                  >
                    Save
                  </MainButton>
                )}
              </div>
            </>
          )}
        </div>
      </CustomEmailProviderSettingsModalWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default CustomEmailProviderSettingsModal;
