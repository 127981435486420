import React, { FC, useState } from 'react';
import { ForgotPasswordPageWrapper } from './styles';
import { useFormik } from 'formik';
import { CircularProgress, Typography } from '@mui/material';
import { Input } from 'crono-fe-common/components/FormComponents';
import { colors } from 'crono-fe-common/theme';
import * as Yup from 'yup';
import useSendResetPasswordEmail from 'hooks/services/auth/useSendResetPasswordEmail';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import CheckYourEmail from './checkYourEmail';
import ResetPasswordFlowContainer from 'components/ResetPasswordFlowContainer';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';

const ForgotPasswordPage: FC = () => {
  const {
    mutateAsync: sendResetPasswordEmail,
    isLoading,
    error,
  } = useSendResetPasswordEmail();
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [resetEmail, setResetEmail] = useState<string>('');

  const formik = useFormik<{ email: string }>({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    }),
    onSubmit: async (values) => {
      await sendResetPasswordEmail({ userEmail: values.email });
      setIsEmailSent(true);
      setResetEmail(values.email);
    },
  });

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) ?? 'Error while sending reset password',
      severity: 'error',
    },
  ]);

  return (
    <ResetPasswordFlowContainer>
      <ForgotPasswordPageWrapper onSubmit={formik.handleSubmit}>
        {isEmailSent ? (
          <CheckYourEmail
            onClick={formik.submitForm}
            isResending={isLoading}
            resetEmail={resetEmail}
            goBack={() => setIsEmailSent(false)}
          />
        ) : (
          <>
            <div className="form-header">
              <h2>Forgot your password?</h2>
              <p>
                Please enter your email address to receive a password reset
                link.
              </p>
            </div>

            <div className="form-body">
              <Typography
                color={colors.primary}
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
              >
                Email:
              </Typography>
              <Input
                defaultValue={resetEmail}
                value={formik.values.email ?? resetEmail}
                onChange={formik.handleChange}
                id="email"
                variant="standard"
                placeholder="Insert email..."
                sx={{
                  width: '100%',
                  '& .MuiInput': {
                    paddingBlock: '6px',
                    width: '100%',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor: colors.grey444,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: colors.primary,
                  },
                }}
              />
              {formik.touched.email && Boolean(formik.errors.email) && (
                <Typography
                  color={colors.inactive}
                  fontSize={12}
                  fontWeight={400}
                  lineHeight={'16px'}
                >
                  {formik.errors.email}
                </Typography>
              )}
            </div>

            {isLoading ? (
              <FlexDiv justifyContent="center">
                <CircularProgress />
              </FlexDiv>
            ) : (
              <MainButton fullWidth type="submit">
                Reset password
              </MainButton>
            )}
          </>
        )}
      </ForgotPasswordPageWrapper>
    </ResetPasswordFlowContainer>
  );
};

export default ForgotPasswordPage;
