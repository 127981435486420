import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconSearchInCRM = ({
  color = colors.black,
  className,
  onClick,
  ...rest
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...rest}
    >
      <path
        d="M16.6569 5.34315C19.781 8.46734 19.781 13.5327 16.6569 16.6569C13.5327 19.781 8.46734 19.781 5.34315 16.6569C2.21895 13.5327 2.21895 8.46734 5.34315 5.34315C8.46734 2.21895 13.5327 2.21895 16.6569 5.34315"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20L17 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9997 9.57997L10.0882 14.1191L13.2384 14.2432"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.76131 7.75662L11.9118 7.88075L7.00035 12.4199"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSearchInCRM;
