import React, {
  FC,
  createContext,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  StrategyOverviewContextType,
  StrategyOverviewContent,
  RewriteEnabledMode,
} from './types';
import { useAuth } from 'context/auth';
import StrategyOverview from './strategyOverview';
import { ProspectSearchStrategyResponse } from 'crono-fe-common/types/prospectSearchStrategy';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import ReactQuill from 'react-quill';
import { createPortal } from 'react-dom';
import InsightsCard, {
  RewriteSubmittedContext,
} from 'pages/accountTab/emailView/insightsCard';
import { TabValue } from 'pages/home';
import { useJuneAnalytics } from '../june';
import { useSequenceContext } from './context/sequence';
import useRewriteSequenceInstance from 'hooks/services/assistant/useRewriteSequenceInstance';
import { RewriteSequenceInstanceInput } from 'crono-fe-common/types/DTO/rewriteSequenceInstanceInput';
import useRevertRewrittenSequenceInstance from 'hooks/services/assistant/useRevertRewrittenSequenceInstance';
import { RevertRewrittenSequenceInstanceInput } from 'crono-fe-common/types/DTO/revertRewrittenSequenceInstanceInput';
import { useConditionalSnackBar } from '../snackbar';
import { getError } from 'crono-fe-common/utils';

export const StrategyOverviewContext = createContext<
  StrategyOverviewContextType | undefined
>(undefined);

const StrategyOverviewProvider: FC<{
  children: any;
  setTabValue: React.Dispatch<React.SetStateAction<TabValue>>;
}> = ({ children, setTabValue }) => {
  const [strategyOverviewContent, setStrategyOverviewContent] =
    useState<StrategyOverviewContent | null>(null);
  const [prospect, setProspect] =
    useState<ProspectSearchStrategyResponse | null>(null);

  const { user } = useAuth();
  //If there is no user I prevent the window from opening
  const open = useMemo(() => {
    if (!user) return undefined;
    return !!strategyOverviewContent;
  }, [user, strategyOverviewContent]);

  const insightsCardRef = useRef<HTMLDivElement | null>(null);
  const insightsButtonRef = useRef<HTMLDivElement | null>(null);
  const [editorInFocus, setEditorInFocus] = useState<ReactQuill | null>(null);
  const [showInsightTab, setShowInsightTab] = useState<boolean>(false);
  const [rewriteEnabledMode, setRewriteEnabledMode] =
    useState<RewriteEnabledMode | null>(null);
  const [selectedText, setSelectedText] = useState<string | undefined>(
    undefined,
  );
  const [wholeMessage, setWholeMessage] = useState<string | undefined>(
    undefined,
  );
  const [selection, setSelection] = useState({ start: 0, length: 0 });

  const handleChangeSelection = (range: any, source: any, editor: any) => {
    const quill = editorInFocus?.getEditor();
    if (quill && range && range.length > 0) {
      const selectedDelta = quill.getContents(range.index, range.length);
      const converter = new QuillDeltaToHtmlConverter(selectedDelta.ops ?? [], {
        inlineStyles: true,
      });
      const selectedHtml = converter.convert();
      setSelection({ start: range.index, length: range.length });
      setSelectedText(selectedHtml);
    } else {
      const totalLength = quill?.getLength() ?? 0;
      setSelection({ start: 0, length: totalLength });
      setSelectedText(quill?.root.innerHTML ?? '');
    }
    setWholeMessage(quill?.root.innerHTML ?? '');
  };

  useEffect(() => {
    const quill = editorInFocus?.getEditor();
    if (quill) {
      quill.on('selection-change', handleChangeSelection);
      handleChangeSelection(quill.getSelection(), 'user', quill);
    }
    return () => {
      quill?.off('selection-change', handleChangeSelection);
    };
  }, [editorInFocus]);

  const showSelection = () => {
    const editor = editorInFocus?.getEditor();
    if (editor) {
      editor.setSelection(selection.start, selection.length);
    }
  };

  const replaceSelectedText = (html: string) => {
    const editor = editorInFocus?.getEditor();
    if (editor) {
      editor.deleteText(selection.start, selection.length);
      editor.clipboard.dangerouslyPasteHTML(selection.start, html, 'user');
    }
  };

  const analytics = useJuneAnalytics();

  const [rewriteSubmittedContext, setRewriteSubmittedContext] =
    useState<RewriteSubmittedContext | null>(null);

  const trackTemplateSaveIfNeeded = (templateText: string) => {
    if (rewriteSubmittedContext && analytics) {
      analytics.track('sent-with-rewrite', {
        ...rewriteSubmittedContext,
        actualMessage: templateText,
      });
    }
    setRewriteSubmittedContext(null);
  };

  const resetInsightsContext = () => {
    setSelectedText(undefined);
    setWholeMessage(undefined);
    setSelection({ start: 0, length: 0 });
    setShowInsightTab(false);
    setRewriteEnabledMode(null);
    setProspect(null);
    setEditorInFocus(null);
  };

  const handleRedirectToHome = () => {
    setTabValue(TabValue.DASHBOARD);
    setStrategyOverviewContent(null);
  };

  const { sequence, setSequence } = useSequenceContext();

  const {
    mutateAsync: rewriteSequenceInstanceAsync,
    isLoading: isRewriteSequenceInstanceInProgress,
    isSuccess: isRewriteSequenceInstanceSuccess,
    error: rewriteSequenceInstanceError,
  } = useRewriteSequenceInstance();

  const rewriteSequenceInstance = async (
    request: RewriteSequenceInstanceInput,
  ) => {
    await rewriteSequenceInstanceAsync(request);
    setSequence({ ...sequence, id: -1 });
  };

  const {
    mutateAsync: revertRewrittenSequenceInstanceAsync,
    isLoading: isRevertInProgress,
    isSuccess: isRevertSuccess,
    error: revertError,
  } = useRevertRewrittenSequenceInstance();

  const revertRewrittenSequenceInstance = async (
    request: RevertRewrittenSequenceInstanceInput,
  ) => {
    await revertRewrittenSequenceInstanceAsync(request);
    setSequence({ ...sequence, id: -1 });
  };

  useConditionalSnackBar([
    {
      condition: !!rewriteSequenceInstanceError,
      message:
        getError(rewriteSequenceInstanceError) ??
        'Error during rewrite process',
      severity: 'error',
    },
    {
      condition: isRewriteSequenceInstanceSuccess,
      message: 'The strategy was successfully rewritten',
      severity: 'success',
    },
    {
      condition: !!revertError,
      message: getError(revertError) ?? 'Error during revert process',
      severity: 'error',
    },
    {
      condition: isRevertSuccess,
      message: 'All templates were successfully reverted',
      severity: 'success',
    },
  ]);

  return (
    <StrategyOverviewContext.Provider
      value={{
        setStrategyOverviewContent,
        strategyOverviewContent,
        insightsContext: {
          insightsCardRef,
          insightsButtonRef,
          prospect,
          setProspect,
          showInsightTab,
          setShowInsightTab,
          rewriteEnabledMode,
          setRewriteEnabledMode,
          editorInFocus,
          setEditorInFocus,
          resetInsightsContext,
          trackTemplateSaveIfNeeded,
          setRewriteSubmittedContext,
          rewriteSequenceInstance,
          isRewriteSequenceInstanceInProgress,
          revertRewrittenSequenceInstance,
          isRevertInProgress,
        },
      }}
    >
      {showInsightTab &&
        createPortal(
          <InsightsCard
            close={() => {
              setRewriteEnabledMode(null);
              setShowInsightTab(false);
            }}
            prospect={prospect}
            account={prospect?.account ?? null}
            wholeMessage={wholeMessage}
            partToRewrite={selectedText}
            showSelection={showSelection}
            replaceSelectedText={replaceSelectedText}
            isRewriteResponseHtml={true}
            style={{ left: 0, width: '27%' }}
            setRewriteSubmittedContext={setRewriteSubmittedContext}
          />,
          document.body,
        )}
      {open && strategyOverviewContent?.strategyId && (
        <StrategyOverview
          sequenceId={strategyOverviewContent.strategyId}
          setStrategyDetailContent={setStrategyOverviewContent}
          initialTab={strategyOverviewContent.initialTab}
          initialFilter={strategyOverviewContent.initialFilter}
          onApplySequenceChanges={
            strategyOverviewContent.onApplySequenceChanges
          }
          handleRedirectToHome={handleRedirectToHome}
        />
      )}
      {children}
    </StrategyOverviewContext.Provider>
  );
};

export function useShortStrategyOverviewContext(): Exclude<
  StrategyOverviewContextType,
  'insightsContext'
> {
  const context = useContext(StrategyOverviewContext);
  if (context === undefined) {
    throw new Error(
      `useShortStrategyOverviewContext must be used within a StrategyOverviewProvider`,
    );
  }

  return context;
}

export function useSafeStrategyOverviewContext(): StrategyOverviewContextType {
  const context = useContext(StrategyOverviewContext);
  if (context === undefined) {
    // fallback for strategy detail tabs that don't need insights but has <EditableTemplate />
    return {
      setStrategyOverviewContent: () => {},
      strategyOverviewContent: null,
      insightsContext: {
        insightsCardRef: { current: null },
        insightsButtonRef: { current: null },
        prospect: null,
        setProspect: () => {},
        showInsightTab: false,
        setShowInsightTab: () => {},
        rewriteEnabledMode: null,
        setRewriteEnabledMode: () => {},
        editorInFocus: null,
        setEditorInFocus: () => {},
        resetInsightsContext: () => {},
        trackTemplateSaveIfNeeded: () => {},
        setRewriteSubmittedContext: () => {},
        rewriteSequenceInstance: () => Promise.resolve(),
        isRewriteSequenceInstanceInProgress: false,
        revertRewrittenSequenceInstance: () => Promise.resolve(),
        isRevertInProgress: false,
      },
    };
  }

  return context;
}

export default StrategyOverviewProvider;
