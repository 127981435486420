import React, { useRef } from 'react';
import { ReactComponent as LeftArrowIcon } from 'crono-fe-common/icons/Left-Arrow.svg';
import { ReactComponent as RightArrowIcon } from 'crono-fe-common/icons/Right-Arrow.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Typography } from '@mui/material';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { BillingPlan } from 'pages/signup/model';
import { Options } from 'pages/signup';
import FeatureListItem from './featureListItem';
import PlanShortCard from './planShortCard';

interface IProps {
  billingPlan: BillingPlan;
  setSelectedPlan: React.Dispatch<React.SetStateAction<number>>;
  options: Options[];
  selectedPlan: number;
}

export const PlansCarousel = ({
  billingPlan,
  setSelectedPlan,
  options,
  selectedPlan,
}: IProps) => {
  const swiperRef = useRef<SwiperType>();
  return (
    <>
      <Swiper
        allowTouchMove={false}
        slidesPerView={2}
        spaceBetween={10}
        onBeforeInit={(swiper: SwiperType) => {
          swiperRef.current = swiper;
        }}
        centeredSlides={true}
        modules={[Navigation]}
        initialSlide={selectedPlan}
        style={{ userSelect: 'text' }}
      >
        {options.map((option, index) => {
          const price = option?.prices.filter(
            (price) => price.paymentCycle === billingPlan,
          )[0];
          const type = option?.subscriptionType;
          const selected = selectedPlan === index;
          return (
            <SwiperSlide key={index}>
              <div
                id={`plan-options-carousel-${index}`}
                className="card-navigation"
              >
                <div
                  className={`plan ${
                    selected
                      ? type === SubscriptionPlanType.FREE_TRIAL
                        ? 'plan-selected-free-trial'
                        : 'plan-selected'
                      : ''
                  }`}
                >
                  <PlanShortCard
                    type={type}
                    billingPlan={billingPlan}
                    key={index}
                    price={price}
                    selected={selected}
                  />
                  <div className="plan-footer">
                    {option?.features.map((feature, i) => {
                      const firstFeature =
                        i === 0 &&
                        type !== SubscriptionPlanType.FREE_TRIAL &&
                        type !== SubscriptionPlanType.STARTER;

                      return (
                        <FeatureListItem
                          key={i}
                          feature={feature}
                          firstFeature={firstFeature}
                          type={type}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="vertical-filler" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="navigation-container">
        {swiperRef?.current?.activeIndex === 0 ? (
          <div style={{ minWidth: '40px' }} />
        ) : (
          <div
            className={
              swiperRef?.current?.activeIndex === 0
                ? 'arrow-disabled'
                : 'arrow-icon arrow-left'
            }
            onClick={() => {
              swiperRef.current?.slidePrev();
              setSelectedPlan((prev) => {
                if (prev === 0) return prev;
                else return prev - 1;
              });
            }}
          >
            <LeftArrowIcon style={{ width: 34, height: 34 }} />
          </div>
        )}

        <Typography fontSize="16px" fontWeight="500">
          {options[swiperRef?.current?.activeIndex ?? 0]?.subscriptionType ===
          SubscriptionPlanType.FREE_TRIAL
            ? 'Free plan'
            : `${options[swiperRef?.current?.activeIndex ?? 0]?.subscriptionType} plan`}
        </Typography>
        {swiperRef?.current?.activeIndex === options.length - 1 ? (
          <div style={{ minWidth: '40px' }} />
        ) : (
          <div
            className={
              swiperRef?.current?.activeIndex === options.length - 1
                ? 'arrow-disabled'
                : 'arrow-icon arrow-right'
            }
            onClick={() => {
              swiperRef.current?.slideNext();
              setSelectedPlan((prev) => {
                if (prev === options.length - 1) return prev;
                else return prev + 1;
              });
            }}
          >
            <RightArrowIcon style={{ width: 34, height: 34 }} />
          </div>
        )}
      </div>
    </>
  );
};
