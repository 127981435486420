import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Account } from 'crono-fe-common/types/account';
import { Prospect } from 'crono-fe-common/types/prospect';
import queryString from 'query-string';

interface IGetProspectMutate {
  prospectId: string;
  withExternalProperties: boolean;
  withSequenceInstance: boolean;
  withAccount: boolean;
}

export default function useGetProspectMutate() {
  // const requestConfig = {
  //   queryKey: [ENDPOINTS.prospect.main, prospectId],
  //   queryFn: () => {
  //     const queryParams = new URLSearchParams({
  //       withExternalProperties: withExternalProperties ? "true" : "false",
  //       withSequenceInstance: withSequenceInstance ? "true" : "false",
  //       withAccount: withAccount ? "true" : "false"
  //     }).toString();

  //     const request: Request = {
  //       url: `${ENDPOINTS.account.main}/${prospectId}${queryParams}`,
  //       config: {
  //         method: "get"
  //       }
  //     };
  //     return client(request);
  //   },
  //   staleTime: 30 * 1000
  // };

  const getProspectMutate = ({ prospectId, ...rest }: IGetProspectMutate) => {
    const queryParams = queryString.stringify({ ...rest });

    const request: Request = {
      url: `${ENDPOINTS.prospect.main}/${prospectId}?${queryParams}`,
      config: {
        method: 'get',
      },
    };
    return client(request);
  };

  const { data, ...rest } = useMutation<
    Response<Prospect | null>,
    CronoError,
    IGetProspectMutate
  >(getProspectMutate);

  return { data, ...rest };

  // return useQuery<Response<Prospect>, CronoError>(requestConfig);
}
