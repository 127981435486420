import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const AddContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  max-height: 90%;
  background: ${colors.white};
  // overflow: auto;
  padding: 18px 0px 18px 18px;
  border-radius: 16px;

  overflow: hidden;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .close-button {
    cursor: pointer;
  }
  .add-main-container {
    display: flex;
    flex-direction: column;
    padding: 0px 38px 0px 20px;
    row-gap: 20px;
    // height: calc(100% - 250px);
    overflow: auto;
    // padding-top: 8px;
    // padding-inline: 16px;
  }
  .search-linkedin-icon {
    cursor: pointer;
  }
  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding-inline: 6px;
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    // background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .manual-task-settings {
    display: flex;
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
  }
  .type-icon,
  .selected-type-icon {
    width: 34px;
    height: 34px;
    margin-right: 8px;
    border-radius: 999px;
    border: 2px solid ${colors.grey4};
    padding: 4px;
    cursor: pointer;
  }
  .type-icon:hover {
    background: ${colors.grey4};
  }
  .selected-type-icon {
    // filter: invert(1);
    background: ${colors.grey3};
  }
  .selected-type-icon:hover {
    opacity: 0.7;
  }
  .check-badge-icon {
    width: 15px;
    height: 15px;
  }
  .divider {
    border: 1px solid ${colors.grey4};
  }
  .task-component-button {
    cursor: pointer;
    margin-inline: 16px 0px;
  }
  .task-component-button-disabled {
    cursor: not-allowed;
    margin-inline: 16px 0px;
  }
  .information-card-manual-task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .account-avatar {
    width: 30px;
    height: 30px;
  }
  .manual-task-remove-icon {
    cursor: pointer;
    justify-self: flex-end;
  }
  .select-date-time-button {
    cursor: pointer;
    display: flex;
    text-transform: unset;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${colors.grey11};
    padding-inline: 4px;
    min-width: 120px;
  }
`;
