import { CircularProgress, ClickAwayListener } from '@mui/material';
import { GreyButton } from 'crono-fe-common/components/CronoButton';
import React, { useState } from 'react';
import IcebreakersList from './icebreakersList';
import { Prospect } from 'crono-fe-common/types/prospect';
import { colors } from 'crono-fe-common/theme';
import { TooltipIcebreakers } from './index';

interface IProps {
  showIcebreakersTab: boolean;
  setShowIcebreakersTab: React.Dispatch<React.SetStateAction<boolean>>;
  prospect: Prospect | null;
  publicId: string | null;
  handleSelectIcebreaker: (icebreaker: string) => void;
  handleGenerateIcebreakers: () => void;
}

const IcebreakersButton = ({
  showIcebreakersTab,
  setShowIcebreakersTab,
  prospect,
  publicId,
  handleSelectIcebreaker,
  handleGenerateIcebreakers,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <div className="generate-with-ai-container">
      <ClickAwayListener
        onClickAway={() => {
          setShowIcebreakersTab(false);
          setIsLoading(false);
        }}
      >
        <div>
          <TooltipIcebreakers
            arrow={false}
            title={
              <IcebreakersList
                prospect={prospect}
                publicId={publicId}
                open={showIcebreakersTab}
                close={() => {
                  setShowIcebreakersTab(false);
                  setIsLoading(false);
                }}
                onSelect={handleSelectIcebreaker}
                setIsLoading={setIsLoading}
              />
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            //The experience of closing this is done by the IcebreakersList component, in order to preserve data inside it
            open={true}
            placement="top-start"
          >
            {isLoading ? (
              <CircularProgress
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  margin: '20px 16px',
                }}
              />
            ) : (
              <GreyButton
                style={{
                  background: colors.grey4Hover,
                  borderRadius: 20,
                  height: 40,
                }}
                onClick={handleGenerateIcebreakers}
              >
                Generate icebreakers
              </GreyButton>
            )}
          </TooltipIcebreakers>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default IcebreakersButton;
