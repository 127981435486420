import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const FiltersWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .background {
    background: ${colors.grey1}66;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .filters-container {
    background: ${colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-height: 85%;
    border-radius: 16px;
    z-index: 20;
    overflow: auto;
  }
  .filters-container::-webkit-scrollbar-track {
    margin-block: 16px;
  }
  .filter-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    position: sticky;
    top: 0;
    background: ${colors.white};
    z-index: 30;
    padding: 36px 36px 12px 36px;
  }
  .filter-body {
    padding: 0 36px 36px 36px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    position: relative;
  }
  .button-filter {
    border-radius: 999px;
    text-transform: none;
    margin: 4px;
    font-size: 16px;
    width: 120px;
    padding-block: 12px;
  }
  .text-field {
    width: 100%;
    padding-inline: 0px;
  }
  .select {
    width: 100%;
    height: 38px;
  }
  .status-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .external-properties-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .external-property {
    border-radius: 8px;
    border: 1px solid ${colors.grey3};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
      background: ${colors.grey6};
    }
  }
  .external-property > svg {
    margin-right: 12px;
  }
  .sizes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .option,
  .option-selected,
  .option-negative-selected,
  .size,
  .size-selected {
    width: 150px;
    padding: 8px 8px 8px 16px;
    border: 1px solid ${colors.grey3};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .option,
  .option-selected,
  .option-negative-selected {
    width: fit-content;
    padding: 8px 12px;
    justify-content: center;
    gap: 8px;
  }
  .option:hover,
  .size:hover {
    background: ${colors.grey6};
  }
  .size-selected {
    background: ${colors.selectedOptionGrey};
    &:hover {
      background: ${colors.grey4Hover};
    }
  }

  .option {
    background-color: ${colors.white};
  }

  .option-selected {
    background-color: ${colors.white};
    color: ${colors.green};
    border-color: ${colors.green};

    &:hover {
      background: ${colors.grey6};
    }
  }

  .option-negative-selected {
    background-color: ${colors.white};
    color: ${colors.inactiveHover};
    border-color: ${colors.inactiveHover};

    &:hover {
      background: ${colors.grey6};
    }
  }

  .remove-size-icon {
    width: 18px;
    height: 18px;
  }
  .filter-section {
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .strategy-picker {
    width: 100%;
    border-radius: 12px;
    padding: 6px 4px 6px 14px;
    border: 1px solid #0000003a;
    cursor: pointer;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .more-filters-button {
    text-transform: unset !important;
    color: ${colors.grey11};
    width: fit-content;
    align-self: center;
  }
  .date-picker-range {
    width: 200px;
  }
  .date-range-picker-container {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    width: fit-content;
    align-items: center;
    height: 40px;
  }
  .email-phone-status {
    &-container {
      display: flex;
      gap: 8px;
    }

    display: flex;
    align-items: center;
    padding: 12px 17px;
    border: 1px solid ${colors.grey3};
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: ${colors.grey6};
    }

    &--selected {
      background: ${colors.selectedOptionGrey};
      
      &:hover {
        background: ${colors.grey4Hover};
      }
    }

    &__icon {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      min-width: 6px;
      margin-right: 9px;

      &--green {
        background-color: ${colors.callScheduled}
      }
      
      &--yellow {
        background-color: ${colors.nurture}
      }
      
      &--red {
        background-color: ${colors.inactiveHover}
      }
      
      &--grey {
        background-color: ${colors.grey2}
      }
    }
  }
`;
