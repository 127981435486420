import React, { useCallback, useEffect, useState } from 'react';
import { NoSalesNavigatorLicenseInmailViewWrapper } from './style';
import { ReactComponent as NoSalesNavIllustration } from 'assets/images/NoSalesNavIllustration.svg';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';

import { CancelButton } from 'crono-fe-common/components/CronoButton';
import { Checkbox, Divider, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import usePatchTask from 'hooks/services/task/usePatchTask';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import usePatchMultipleTasksInmail from 'hooks/services/task/usePatchTaskMultipleInmail';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  taskId: number;
  callOnSuccess?: (keepTemplatesOpened?: boolean) => void;
}

const NoSalesNavigatorLicenseInmailView = ({
  taskId,
  callOnSuccess,
}: IProps) => {
  const [applyToAll, setApplyToAll] = useState(false);

  const {
    mutate: patchTask,
    isSuccess: taskPatched,
    error: errorPatchingTask,
  } = usePatchTask();

  const {
    mutate: patchMultipleTask,
    isSuccess: multipleTasksPatched,
    error: errorPatchingMultipleTasks,
  } = usePatchMultipleTasksInmail();

  useEffect(() => {
    if (taskPatched || multipleTasksPatched) {
      if (callOnSuccess) {
        callOnSuccess();
      }
    }
  }, [taskPatched, multipleTasksPatched]);

  const analytics = useJuneAnalytics();

  const handleChangeType = useCallback(
    (taskType: TaskTodoType) => {
      if (applyToAll) {
        patchMultipleTask({
          type: taskType,
        });
        if (analytics) {
          analytics.track('patch-multiple-task-inmail', {
            type: 'type',
          });
        }
      } else {
        patchTask({
          id: taskId,
          type: taskType,
        });
        if (analytics) {
          analytics.track('patch-task', {
            type: 'type',
          });
        }
      }
    },
    [applyToAll],
  );

  const handleSkip = useCallback(() => {
    if (applyToAll) {
      patchMultipleTask({
        skipped: true,
      });
      if (analytics) {
        analytics.track('patch-multiple-task-inmail', {
          type: 'skip',
        });
      }
    } else {
      patchTask({
        id: taskId,
        skipped: true,
      });
      if (analytics) {
        analytics.track('patch-task', {
          type: 'skip',
        });
      }
    }
  }, [applyToAll]);

  useConditionalSnackBar([
    {
      condition: !!taskPatched,
      message: 'Task updated successfully',
      severity: 'success',
    },
    {
      condition: !!errorPatchingTask,
      message: getError(errorPatchingTask) ?? 'Error updating task',
      severity: 'error',
    },
    {
      condition: !!multipleTasksPatched,
      message: 'Tasks updated successfully',
      severity: 'success',
    },
    {
      condition: !!errorPatchingMultipleTasks,
      message: getError(errorPatchingMultipleTasks) ?? 'Error updating tasks',
      severity: 'error',
    },
  ]);

  return (
    <NoSalesNavigatorLicenseInmailViewWrapper>
      <NoSalesNavIllustration />
      <Typography
        fontSize={16}
        lineHeight={'24px'}
        fontWeight={600}
        whiteSpace={'pre-line'}
        textAlign={'center'}
      >
        You cannot send a LinkedIn Inmail as you don’t
        <br /> have a Sales Navigator license
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 16,
          justifyContent: 'center',
        }}
      >
        <CancelButton style={{ margin: 0 }} onClick={handleSkip}>
          Skip task
        </CancelButton>
        <Typography fontSize={14} lineHeight={'18px'} fontWeight={500}>
          OR
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <div
            className="new-type-option-container call-type"
            onClick={() => handleChangeType(TaskTodoType.CALL)}
          >
            <CallIcon color={colors.callScheduledHover} />
          </div>
          <div
            className="new-type-option-container email-type"
            onClick={() => handleChangeType(TaskTodoType.EMAIL)}
          >
            <EmailIcon color={colors.inactiveHover} />
          </div>
          <div
            className="new-type-option-container linkedin-type"
            onClick={() => handleChangeType(TaskTodoType.LINKEDIN)}
          >
            <LinkedinIcon color={colors.blueHover} />
          </div>
        </div>
      </div>
      <Divider style={{ width: 324 }} />
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <label>
          <Checkbox
            checked={applyToAll}
            icon={<IconUnchecked />}
            checkedIcon={<CheckedIcon />}
            color="secondary"
            sx={{
              padding: 'inherit',
              margin: 'inherit',
              '&:hover': {
                backgroundColor: colors.grey4,
              },
              '&.Mui-checked': {
                '&:hover': {
                  opacity: 0.7,
                },
              },
              marginRight: 1,
              marginLeft: 1,
            }}
            onChange={(e) => setApplyToAll(e.target.checked)}
          />
        </label>
        <Typography fontSize={12} lineHeight={'16px'}>
          Apply same behaviour to all InMail tasks of today
        </Typography>
      </div>
    </NoSalesNavigatorLicenseInmailViewWrapper>
  );
};

export default NoSalesNavigatorLicenseInmailView;
