import { ExternalValue } from './externalValue';
import { Prospect } from './prospect';
import { Suggestion } from './suggestion';

export interface Account {
  correct?: boolean;
  id: number;
  objectId: string;
  ownerId: string;
  userId: number;
  user: string | null;
  name: string;
  actualStatus: AccountStatus;
  prospects: Prospect[] | null;
  emailDomain?: string;
  photoUrl?: string;
  website?: string;
  industry?: string;
  annualRevenue?: string;
  numberOfEmployees?: number;
  phone?: string;
  linkedin: string;
  linkedinNumericId?: string;
  currentSolution: string;
  country?: string | null;
  externalValues: ExternalValue[] | null;
  owned: boolean;
  suggestions?: Suggestion[] | null;
  isSynced: boolean;
  lastActivityDate?: string;
  createdDate?: string;

  totalProspects?: number | null;
  totalCall?: number | null;
  totalEmail?: number | null;
  totalLinkedin?: number | null;
  totalActivity?: number | null;
  totalOpportunities?: number | null;

  onlyInCrm?: boolean | null;
}

export const isAccount = (obj: any): obj is Account => {
  return 'country' in obj;
};

export enum AccountStatus {
  INACTIVE = 'Inactive',
  NURTURE = 'Nurture',
  WORKING = 'Working',
  CALL_SCHEDULED = 'CallScheduled',
  OPEN_OPPORTUNITY = 'OpenOpportunity',
  CUSTOMER = 'Customer',
}

export const isAccountStatus = (obj: string | null): obj is AccountStatus => {
  return (
    obj === AccountStatus.INACTIVE ||
    obj === AccountStatus.NURTURE ||
    obj === AccountStatus.WORKING ||
    obj === AccountStatus.CALL_SCHEDULED ||
    obj === AccountStatus.OPEN_OPPORTUNITY ||
    obj === AccountStatus.CUSTOMER
  );
};
