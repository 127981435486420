import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinInvitationIconDark = ({
  color = colors.white,
  color2 = colors.blueHover,
  className,
  onClick,
  style,
  viewBox = '0 0 32 32',
}: IProps) => {
  return (
    <svg
      width="32"
      height="32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={className}
      onClick={onClick}
      {...style}
    >
      <rect width="32" height="32" rx="16" fill={color2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0859 13.833L16.1286 13.833V14.8505C16.4229 14.2653 17.1775 13.7395 18.3111 13.7395C20.4841 13.7395 21 14.9044 21 17.0417V17.0686C20.7918 16.9819 20.5635 16.934 20.3239 16.934C19.6727 16.934 19.1041 17.2878 18.8 17.8138V17.5284C18.8 16.3113 18.5058 15.6249 17.7567 15.6249C16.7177 15.6249 16.286 16.3646 16.286 17.5279V21H14.0859L14.0859 13.833ZM20.2862 18.001C19.9212 18.0208 19.6313 18.323 19.6313 18.6929V20.1322H19.6309V18.693C19.6309 18.3229 19.921 18.0206 20.2862 18.001ZM12.5135 20.9065L12.5135 13.7395H10.3135L10.3135 20.9065H12.5135ZM12.8287 11.4025C12.8287 11.5869 12.7922 11.7695 12.7211 11.9396C12.65 12.1098 12.5458 12.2641 12.4145 12.3936C12.1485 12.6579 11.7885 12.8059 11.4135 12.805C11.0392 12.8047 10.68 12.6572 10.4136 12.3942C10.2828 12.2642 10.179 12.1097 10.108 11.9395C10.037 11.7694 10.0003 11.5869 10 11.4025C10 11.0302 10.1485 10.6738 10.4142 10.4109C10.6803 10.1475 11.0396 9.99984 11.4141 10C11.7892 10 12.1488 10.148 12.4145 10.4109C12.6796 10.6738 12.8287 11.0302 12.8287 11.4025Z"
        fill={color}
      />
      <path
        d="M20.3262 18.6934V22.9576"
        stroke={color}
        strokeWidth="1.38588"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.457 20.8262L18.1928 20.8262"
        stroke={color}
        strokeWidth="1.38588"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinInvitationIconDark;
