import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { FileContent } from 'use-file-picker';

export interface SendPreviewEmailDto {
  emailInfo: {
    subject: string;
    contentHtml: string;
  };
  attachments?: FileContent[];
  attachmentIds?: number[];
}

export default function useSendPreviewEmail() {
  const sendPreviewEmail = (sendPreviewEmailDto: SendPreviewEmailDto) => {
    const request: Request = {
      url: `${ENDPOINTS.email.sendPreview}`,
      config: {
        method: 'post',
        data: { data: sendPreviewEmailDto },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    SendPreviewEmailDto
  >(sendPreviewEmail, {});

  return { data, mutate, ...rest };
}
