import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ProspectScrape } from 'crono-fe-common/types/prospectScrape';
import { VerifyResult } from 'crono-fe-common/types/verifyResult';

export type FindVerifyScrapeInputs = {
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  linkedinUrl?: string | null;
};

export default function useFindVerify() {
  const findScrape = (data: FindVerifyScrapeInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.scrape.verifyScrape}`,
      config: {
        method: 'post',
        data: data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<VerifyResult>,
    CronoError,
    FindVerifyScrapeInputs
  >(findScrape, {});

  return { data, mutate, ...rest };
}
