import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const StrategyContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .sequence-detail-container {
    flex: 1;
    background: ${colors.white};
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    overflow: auto;
  }
  .strategy-options {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid ${colors.grey4};
  }
  .strategy-options:hover {
    background-color: ${colors.blueLightHover};
  }

  .strategy-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    overflow: auto;
    padding: 24px;
  }
  .strategy-right-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  .analitycs-div {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
  .divider {
    height: 80px;
    border-left: 1px solid ${colors.grey444};
  }
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }
  .no-contacts-in-strategy {
    display: flex;
    flex-direction: column;
    width: 370px;
    text-align: center;
    gap: 8px;
  }
  .no-contacts-found {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .table-container {
    width: 100%;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  .table-header {
    // border-bottom: 1px solid ${colors.grey444};
    // width: calc(100% - 5px);
    // display: flex;
    // flex: 1;
  }
  .table-content {
    display: flex;
    // flex: 1;
    flex-direction: column;
    // width: calc(100% - 5px);
    border-bottom: 1px solid ${colors.grey444};
    // border-top: 1px solid ${colors.grey444};
    // width: fit-content;
    // background: repeating-linear-gradient(
    //   323deg,
    //   transparent,
    //   transparent 9px,
    //   ${colors.grey444} 10px,
    //   ${colors.grey444} 10px
    // );
  }
  .filter-steps-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.grey444};
    // width: 100%;
  }
  .table-header-filter {
    min-width: 380px;
    width: 380px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px 8px 8px 16px;
  }
  .table-header-steps {
    display: flex;
    width: fit-content;
    height: 100%;
  }
  .table-row {
    display: flex;
    // width: 100%;
    height: 80px;
    border-bottom: 1px solid ${colors.grey444};
    // width: fit-content;

    flex: 1;
  }
  .table-row-prospect-info {
    display: flex;
    min-width: 380px;
    width: 380px;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 8px 16px;
    border-right: 1px solid ${colors.grey444};
    background: ${colors.white};
    cursor: pointer;
  }
  .row-container {
    display: flex;
    width: 100%;
    align-items: center;
    background: repeating-linear-gradient(
      323deg,
      transparent,
      transparent 9px,
      ${colors.grey444} 10px,
      ${colors.grey444} 10px
    );
  }
  .prospect-avatar-row {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    margin-right: 16px;
    size: 16px !important;
    font-size: 16px !important;
    width: 30px;
    height: 30px;
  }
  .prospect-status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 20px;
    min-width: 80px;
    width: 80px;
  }
  .active {
    color: ${colors.callScheduledHover};
    background-color: ${colors.greenLight2};
  }
  .stopped {
    color: ${colors.nurtureHover};
    background-color: ${colors.nurtureLight};
  }
  .finished {
    color: ${colors.prospectingHover};
    background-color: ${colors.prospectingLight};
  }
  .converted {
    color: ${colors.purpleHover};
    background-color: ${colors.purpleLight};
  }

  .table-row-step-info {
    display: flex;
    flex-direction: column;
    min-width: 125px;
    width: 125px;
    // height: 80px;
    padding: 8px;
    align-items: center;
    // justify-content: center;
    gap: 4px;
    border-right: 1px solid ${colors.grey444};
    background: ${colors.white};
    height: 100%;
  }

  .filtered-by-step {
    border-right: 1px solid ${colors.primary};
    border-left: 1px solid ${colors.primary};
  }
  .not-filtered-by-step {
    opacity: 0.6;
  }
  .empty-placeholder {
    height: 100%;
    background: transparent;
  }
  .type-icon {
    width: 30px;
    height: 30px;
    border-radius: 16px;
    padding: 7px;
    // cursor: pointer;
  }
  .show-filter-container {
    height: 32px;
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 14px;
    width: 111px;
    cursor: pointer;
    background-color: ${colors.grey4};
  }
  .show-filter-container:hover {
    border: 1px solid ${colors.black};
  }
  .shared-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    padding-top: 40px;
  }
  .not-owned-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
  .not-owned-icon > path {
    stroke: ${colors.grey11};
  }
  .not-owned-icon:hover > path {
    stroke: ${colors.black};
  }
`;

export const ShowFilterOptionsWrapper = styled.div`
  padding: 8px;
  width: 100%;
  .show-filter-option {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
  }
  .show-filter-option:hover {
    background-color: ${colors.sequenceCardHover};
  }
`;
type AnalyticsWrapperProp = {
  selected?: boolean;
};

export const AnalyticsWrapper = styled.div<AnalyticsWrapperProp>`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 125px;
  padding: 12px 12px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  &:hover {
    background-color: ${(props) =>
      props.selected
        ? colors.primaryLightHover
        : colors.sequenceCardHover} !important;
  }
`;

type TableHeaderStepsProps = {
  hasUsers?: boolean;
  selected?: boolean;
};

export const TableHeaderStepsWrapper = styled.div<TableHeaderStepsProps>`
  display: flex;
  min-width: 125px;
  width: 125px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.selected ? `4px solid ${colors.primary}` : ''};

  .users-on-step {
    padding: 1px 4px;
    border-radius: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: ${(props) =>
      props.hasUsers ? colors.blueLight : colors.grey6};
  }
  .users-on-step:hover {
    background-color: ${(props) =>
      props.hasUsers ? colors.prospectingHover : ''};
  }
  .users-on-step:hover > p {
    color: ${(props) => (props.hasUsers ? colors.white : '')};
  }
  .users-on-step:hover > svg > path {
    stroke: ${(props) => (props.hasUsers ? colors.white : '')};
  }
`;
