import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { StrategyDetailSearch } from 'crono-fe-common/types/DTO/strategyDetailSearch';
import { StrategyDetail } from 'crono-fe-common/types/DTO/strategyDetail';

export default function useGetStrategyDetail(
  searchParameters: StrategyDetailSearch,
  enabled = true,
) {
  const request: Request = {
    url: ENDPOINTS.sequence.detail,
    config: {
      method: 'post',
      data: searchParameters,
    },
  };
  const queryString = JSON.stringify(searchParameters);
  const requestConfig = {
    queryKey: [
      ENDPOINTS.sequence.detail,
      searchParameters.strategyId,
      queryString,
    ],

    queryFn: () => client(request),
    enabled,
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
  };

  return useQuery<ResponsePaginated<StrategyDetail>, CronoError>(requestConfig);
}
