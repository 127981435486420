import React, { useEffect, useState } from 'react';
import { ChooseLicenseWrapper, LicenseFormContainer } from './styles';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { ReactComponent as PrincingIcon } from 'assets/images/Princing_icon.svg';
import { CircularProgress, TextField, Typography } from '@mui/material';
import MinusSmallIcon from 'crono-fe-common/icons/Icon-Minus-Small';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import { FeConstants } from 'constants/FeConstants';
import Logo from 'assets/images/logo_crono.png';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';

interface IProps {
  //Bad but no type support found
  formik: any;
  handleFreeTrial: () => void;
  checkoutLoading: boolean;
  freeTrialError: boolean;
  setEmailFreeTrialError: React.Dispatch<React.SetStateAction<boolean>>;
}

const Licenses = ({
  formik,
  handleFreeTrial,
  checkoutLoading,
  freeTrialError,
  setEmailFreeTrialError,
}: IProps) => {
  const handleChangeAmount = (value: number) => {
    const licenseAmount = formik.values.quantity;
    if (
      licenseAmount + value >= FeConstants.minLicenseAmount &&
      licenseAmount + value <= FeConstants.maxLicenseAmount
    ) {
      formik.setFieldValue('quantity', licenseAmount + value);
    }
  };

  const handleEmailChange = (value: string) => {
    formik.setFieldValue('email', value);
    if (freeTrialError && value.match(FeConstants.emailRegex)) {
      setEmailFreeTrialError(false);
    }
  };

  useEffect(() => {
    if (formik.values.plan === SubscriptionPlanType.ULTRA) {
      formik.setFieldValue('quantity', 5);
    }
  }, [formik.values.plan]);

  const navigate = useNavigate();

  const handleRedirectLogin = () => {
    navigate(PATH.LOGIN);
  };

  return (
    <ChooseLicenseWrapper id="licenses-container">
      <div className="logo-container">
        <img src={Logo} className="logo" alt="Crono Logo" />
      </div>
      <div className="subscription-license-container">
        <div className="subscription-license">
          <div className="license-title-container">
            <Typography fontSize={'28px'} fontWeight={700} lineHeight={'32px'}>
              2. Complete your subscription
            </Typography>
          </div>
          <div style={{ width: '100%', flex: 1, overflow: 'auto' }}>
            <LicenseFormContainer>
              <div className="license-card-container">
                <div className="form-box">
                  <TextField
                    label="Company email:"
                    placeholder="Insert email..."
                    value={formik.values.email}
                    onChange={(ev: any) => {
                      handleEmailChange(ev.target.value);
                    }}
                    fullWidth
                    variant="standard"
                    sx={{
                      '.Mui-error': {
                        color: `${colors.inactive} !important`,
                      },
                      '.Mui-error:before': {
                        borderBottomColor: `${colors.inactive} !important`,
                      },
                      '.Mui-error:after': {
                        borderBottomColor: `${colors.inactive} !important`,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color:
                          (formik.touched.email &&
                            Boolean(formik.errors.email)) ||
                          freeTrialError
                            ? colors.inactive
                            : colors.primary,
                        fontSize: '15px',
                        fontWeight: 500,
                      },
                    }}
                    error={
                      (formik.touched.email && Boolean(formik.errors.email)) ||
                      freeTrialError
                    }
                    helperText={
                      (formik.touched.email && formik.errors.email) ||
                      (freeTrialError && formik.errors.email)
                    }
                  />
                  {formik.values.plan !== SubscriptionPlanType.FREE_TRIAL && (
                    <div className="amount-block">
                      <Typography
                        fontSize={'12px'}
                        fontWeight={500}
                        color={
                          formik.values.quantity ===
                          FeConstants.maxLicenseAmount
                            ? colors.inactive
                            : colors.primary
                        }
                      >
                        User licenses:
                      </Typography>
                      <div className="number-picker">
                        <Typography
                          className="number-picker-button"
                          onClick={() => {
                            if (
                              formik.values.plan ===
                                SubscriptionPlanType.ULTRA &&
                              formik.values.quantity === 5
                            ) {
                              return;
                            }
                            handleChangeAmount(-1);
                          }}
                        >
                          <MinusSmallIcon
                            color={
                              formik.values.quantity ===
                                FeConstants.minLicenseAmount ||
                              (formik.values.plan ===
                                SubscriptionPlanType.ULTRA &&
                                formik.values.quantity === 5)
                                ? colors.grey2
                                : colors.grey11
                            }
                          />
                        </Typography>
                        <Typography fontSize={'14px'} fontWeight={500}>
                          {formik.values.quantity}
                        </Typography>
                        <Typography
                          className="number-picker-button"
                          onClick={() => handleChangeAmount(1)}
                        >
                          <PlusSmallIcon
                            color={
                              formik.values.quantity ===
                              FeConstants.maxLicenseAmount
                                ? colors.grey2
                                : colors.grey11
                            }
                          />
                        </Typography>
                      </div>
                      {formik.values.quantity ===
                      FeConstants.maxLicenseAmount ? (
                        <Typography
                          fontSize={11}
                          fontWeight={500}
                          color={colors.inactive}
                        >
                          The maximum limit is 100. Contact us to add more
                          licenses{' '}
                        </Typography>
                      ) : null}
                    </div>
                  )}
                  <div className="form-pricing">
                    <div
                      className={`pricing-icon-container ${formik.values.plan}`}
                    >
                      <PrincingIcon />
                    </div>
                    <div className="princing-plan-info">
                      <Typography
                        fontSize={'16px'}
                        fontWeight={600}
                        lineHeight={'24px'}
                        color={colors.black}
                      >
                        {formik.values.plan === SubscriptionPlanType.FREE_TRIAL
                          ? 'Trial plan'
                          : `${formik.values.plan} plan`}
                      </Typography>
                      <Typography
                        fontSize={'14px'}
                        fontWeight={500}
                        lineHeight={'18px'}
                        color={colors.grey11}
                      >
                        {formik.values.plan === SubscriptionPlanType.FREE_TRIAL
                          ? 'Free'
                          : `billed ${
                              formik.values.billed === 'yearly'
                                ? 'annually'
                                : formik.values.billed
                            }`}
                      </Typography>
                    </div>
                    <div className={`user-month-price`}>
                      {formik.values.plan ===
                      SubscriptionPlanType.FREE_TRIAL ? (
                        <span className="price-tag">14 days</span>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Total:
                          <span className="price-tag">
                            {formik.values.billed === 'yearly'
                              ? `€ ${
                                  formik.values.price *
                                  formik.values.quantity *
                                  12
                                }`
                              : `€ ${
                                  formik.values.price * formik.values.quantity
                                }`}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="onboarding-navigation">
                <CancelButton
                  style={{ width: '120px' }}
                  onClick={() => window.open(FeConstants.websitePricingURL)}
                >
                  Back
                </CancelButton>
                {checkoutLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '120px',
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <MainButton
                    style={{ width: '120px' }}
                    onClick={() => {
                      if (
                        formik.values.plan === SubscriptionPlanType.FREE_TRIAL
                      ) {
                        handleFreeTrial();
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    {formik.values.plan === SubscriptionPlanType.FREE_TRIAL
                      ? 'Start'
                      : 'Checkout'}
                  </MainButton>
                )}
              </div>
            </LicenseFormContainer>
          </div>
        </div>
      </div>
      <Typography fontSize={14} lineHeight={'24px'} marginBottom={'24px'}>
        Are you already a Crono user?{' '}
        <span className="login-link" onClick={handleRedirectLogin}>
          Click here to login
        </span>
      </Typography>
    </ChooseLicenseWrapper>
  );
};

export default Licenses;
