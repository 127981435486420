import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Account } from 'crono-fe-common/types/account';

interface ISearchAccountsExternal {
  name: string;
  linkedinPublicId?: string | null;
}

export default function useSearchAccountsExternal() {
  const searchAccountExternal = (updateAccount: ISearchAccountsExternal) => {
    const request: Request = {
      url: `${ENDPOINTS.account.search.external}`,
      config: {
        method: 'post',
        data: { data: updateAccount },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<Account[]>,
    CronoError,
    ISearchAccountsExternal
  >(searchAccountExternal, {});

  return { data, mutate, ...rest };
}
