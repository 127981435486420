import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SuggestionPreferencesWrapper = styled.div`
  display: flex;

  .container-text-suggestion {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  .container-text-line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .select-suggestions-preferences {
    margin-inline: 8px;
  }
  .remove-selection {
    position: absolute;
    right: 18px;
    top: 8px;
    background: white;
    z-index: 10;
    cursor: pointer;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background: ${colors.grey6};
    }
  }
`;
