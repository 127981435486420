import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect } from 'react';
import { AddContainerWrapper } from '../style';
import { Typography } from '@mui/material';
import AddOpportunityForm from '../formComponent';

import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { OpportunityInsert } from 'crono-fe-common/types/DTO/opportunityInsert';
import useCreateOpportunity from 'hooks/services/opportunity/useCreateOpportunity';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { Account } from 'crono-fe-common/types/account';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  close: () => void;
  initialCompany?: Account | null;
}

const AddOpportunityContainer = ({ close, initialCompany }: IProps) => {
  const {
    mutate: createOpportunity,
    isSuccess,
    error,
    isLoading: createLoading,
  } = useCreateOpportunity();

  const analytics = useJuneAnalytics();

  const handleSubmit = (newOpportunity: OpportunityInsert) => {
    createOpportunity(newOpportunity);
    if (analytics) {
      analytics.track('create-deal', {});
    }
  };

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) || 'Error while creating deal',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <BlurBackgroundDivFixed>
      <AddContainerWrapper>
        <CloseTabButton close={close} style={{ marginRight: '18px' }} />
        <div className="add-main-container">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Create new deal:
          </Typography>
          <AddOpportunityForm
            close={close}
            handleSubmit={handleSubmit}
            createLoading={createLoading}
            initialCompany={initialCompany}
          />
        </div>
      </AddContainerWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default AddOpportunityContainer;
