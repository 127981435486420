import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ResetPasswordDTO } from 'crono-fe-common/types/DTO/resetPasswordDTO';

export default function useResetPassword() {
  const resetPassword = (data: ResetPasswordDTO) => {
    const request: Request = {
      url: ENDPOINTS.auth.resetPassword,
      config: {
        method: 'post',
        data,
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    ResetPasswordDTO
  >(resetPassword, {});

  return { isSuccess, isError, mutate, ...rest };
}
