import {
  TableContainer,
  Table,
  TableBody,
  Typography,
  CircularProgress,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
  Checkbox,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAccountHeadCells } from '../columns';
import CustomTableHead from '../tableHead';
import { DataAccount } from '../types';
import Pagination from '../pagination';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import useSearchAccounts from 'hooks/services/account/useSearchAccounts';
import {
  AccountSearch,
  AccountSearchSort,
} from 'crono-fe-common/types/DTO/accountSearch';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useAuth } from 'context/auth';
import { Order } from 'crono-fe-common/types/userPreferences';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useFilters } from 'pages/home/context/filters';
import { colors } from 'crono-fe-common/theme';
import AccountSelectionBottom from './accountSelectionBottom';
import { Constants } from 'crono-fe-common/constants/constants';
import { ReactComponent as NoCompanies } from '../../../assets/images/No_companies_imported.svg';
import { rowPlaceholderFillOpacities } from 'constants/FeConstants';
import CompanyRowPlaceholder from '../../../assets/images/Company-row-placeholder.png';
import StrokeDivider from '../../../assets/images/Stroke-divider.png';
import AccountRow from './accountRow';
import { trimObject } from 'crono-fe-common/utils/object';
import useSearchAccountsExternal from 'hooks/services/account/useSearchAccountsExternal';
import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import IconSearchInCRM from 'crono-fe-common/icons/Icon-Search-In-CRM';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import IconIndeterminate from 'crono-fe-common/icons/Icon-Indeterminate';
import CrmIcon from 'components/CrmIcon';
import { Account } from 'crono-fe-common/types/account';
import { ReactComponent as IllustrationCompanyNotFound } from 'assets/images/IllustrationCompanyNotFound.svg';
import { ReactComponent as NoResultsFoundCrono } from 'assets/images/IllustractionNoResultsCrono.svg';
import { ReactComponent as NoCompaniesFoundFilters } from 'assets/images/IllustrationNoCompaniesFound.svg';

import AccountExternalRow from './externalAccountRow';
import { useQueryClient } from 'react-query';
import { ENDPOINTS } from 'config/endpoints';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { TabValue } from 'pages/home';
import EditColumnsDropdown, {
  EditListColumnsType,
} from '../editColumnsDropdown';

const CompanyTable = () => {
  // We must use the userPreferences, contained in this object, to obtain the previous settings for the table
  // at user.userPreferences.accountTableFilters, which is a string containing a json that has to be handled
  // front-end side
  const { user } = useAuth();
  // This object is gonna be usefull to be stringyfied each time the patch of the tablePreferences will be called
  const userPreferences: AccountSearch = JSON.parse(
    user?.userPreferences?.accountTableFilters || '{}',
  );
  const {
    searchAccountParameters: searchParameters,
    setSearchAccountParameters: setSearchParameters,
    resetAccountFilters: resetFilters,
    areThereAccountFiltersApplied: areAccountFiltersModified,
    resetAccountFiltersAndOwnedFalse: resetFiltersAndOwner,
    defaultFilters,
    updateUserPreferences,
  } = useFilters();

  //For a coherent visualization (about the checkbox and the fact that we allow max 50 selected rows at each moment), when the filters change clear the selection
  useEffect(() => {
    clearSelected();
  }, [searchParameters]);

  // If there are already preferences saved inside the userPreferences I set them in the current states
  // This effect runs only when the entire variable user changes, not when one of its property does (no run on each
  // update of sort, sortby and so on)
  useEffect(() => {
    // console.log("UserPreferences: ", userPreferences);
    if (userPreferences.sort) {
      //Based on the userPreferences I set the sort and the sortby
      setOrder(userPreferences.sort.includes('Desc') ? 'desc' : 'asc');
      setOrderBy(userPreferences.sort.replace('Desc', '') as keyof DataAccount);
    }
  }, [user]);

  const [selected, setSelected] = useState<string[]>([]);
  //Array to store the id of the selected accounts usefull only for the standalone version
  const [selectedOwned, setSelectedOwned] = useState<string[]>([]);

  // Paginate handling
  const [itemOffset, setItemOffset] = useState(0);

  const queryClient = useQueryClient();

  // I get what I need from the customHook for the accounts
  const {
    data: accounts,
    isLoading,
    error,
  } = useSearchAccounts({ ...searchParameters, searchIncludeProspects: true });

  useEffect(() => {
    if (error && error.response?.status === 400) {
      resetFilters();
    }
  }, [error]);

  const { data: externalProperties } = useGetExternalProperty('Account', true);

  //To handle the filters and the sort
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof DataAccount>('Name');
  const onRequestSort = (property: keyof DataAccount) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder: Order = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    // Reset to the first page
    setItemOffset(0);
    sortAPIs(newOrder, property);
  };

  useEffect(() => {
    setSearchParameters((prev) => ({ ...prev, offset: itemOffset }));
    clearSelected();
  }, [itemOffset]);

  const { mutate: patchUserPreferences } = usePatchUserPreferences();
  //In this function I call the APIs to change the sort. I have to change the search parameters (to obtain the new
  // order requested) and the userPreferences
  // I also update the user status of the app
  const sortAPIs = (newOrder: Order, property: keyof DataAccount) => {
    // I create the correct format for the sort
    const newSort: AccountSearchSort = property.concat(
      newOrder === 'asc' ? '' : 'Desc',
    ) as AccountSearchSort;
    // I update the search parameters
    setSearchParameters({
      ...searchParameters,
      sort: newSort,
    });

    userPreferences.sort = newSort;
    userPreferences.offset = 0;
    updateUserPreferences('Company', userPreferences);
  };

  const handleSelectAccount = (accountId: string) => {
    if (selected.includes(accountId)) {
      setSelected(selected.filter((id) => id !== accountId));
      if (selectedOwned.includes(accountId)) {
        setSelectedOwned(selectedOwned.filter((id) => id !== accountId));
      }
    } else {
      setSelected([...selected, accountId]);
      if (
        accounts?.data?.data.find((account) => account.objectId === accountId)
          ?.owned
      ) {
        setSelectedOwned([...selectedOwned, accountId]);
      }
    }
  };

  const clearSelected = () => {
    setSelected([]);
    setSelectedOwned([]);
    setSelectedExternal([]);
  };

  const handleClickSelectAll = () => {
    if (selected.length === accounts?.data?.data.length) {
      clearSelected();
    } else {
      setSelected(
        accounts?.data?.data
          ?.filter((account) => account.objectId !== undefined)
          .map((account) => {
            return account.objectId;
          }) ?? [],
      );
      setSelectedOwned(
        accounts?.data?.data
          ?.filter((account) => account.objectId !== undefined && account.owned)
          .map((account) => {
            return account.objectId;
          }) ?? [],
      );
    }
  };

  const navigate = useNavigate();

  const handleOpenFindNew = (accountId: string) => {
    const initialCompany = accounts?.data?.data.find(
      (account) => account.objectId === accountId,
    );
    if (!initialCompany) return;
    navigate(PATH.HOME, {
      state: {
        initialTab: TabValue.FIND_NEW,
        initialTabFindNew: 'contact',
        initialCompanyFindNew: initialCompany,
      },
    });
  };

  const rowPlaceholder = rowPlaceholderFillOpacities.map((el, index) => {
    return (
      <div key={index} className="empty-state-placeholder">
        <img
          src={StrokeDivider}
          alt="Grey horizontal stroke"
          style={{ opacity: el, width: '100%' }}
        />
        <img
          src={CompanyRowPlaceholder}
          alt="Empty company placeholder"
          style={{ opacity: el, width: '100%' }}
        />
      </div>
    );
  });

  const handleOpenSearch = () => {
    navigate(PATH.HOME, {
      state: {
        initialTab: TabValue.FIND_NEW,
        initialTabFindNew: 'company',
      },
    });
  };

  const onlyMineShown = searchParameters.userId === user?.id;

  const {
    mutate: searchAccountExtrnal,
    isLoading: searchingAccountExternal,
    data: dataAccountExternal,
    reset: resetDataAccountExternal,
  } = useSearchAccountsExternal();

  const [selectedExternal, setSelectedExternal] = useState<string[]>([]);
  //When the name field changes I enable again the search in CRM
  useEffect(() => {
    if (dataAccountExternal) {
      resetDataAccountExternal();
      setSelectedExternal([]);
    }
  }, [searchParameters.name]);

  const searchInCrm = () => {
    searchAccountExtrnal(
      trimObject({
        name: searchParameters.name,
        getAccountInCrm: true,
      }),
    );
  };

  const handleSearchInAllCompanies = useCallback(() => {
    if (onlyMineShown) {
      setSearchParameters((prev) => ({ ...prev, userId: null }));
    } else {
      searchInCrm();
    }
  }, [onlyMineShown, searchParameters.name]);

  const handleSelectedExternal = (accountId: string) => {
    const newSelected =
      selectedExternal.findIndex((selected) => selected === accountId) >= 0
        ? selectedExternal.filter((s) => s !== accountId)
        : [...selectedExternal, accountId];
    setSelectedExternal(newSelected);
  };

  const handleClickSelectAllExternal = () => {
    if (selectedExternal.length === availableToSync.length) {
      setSelectedExternal([]);
    } else {
      setSelectedExternal(availableToSync.map((account) => account.objectId));
    }
  };

  //This state is used to store the accounts that are being synced, to show the syncing badge while waiting for the BE to import them
  //This state is refreshed when changing page
  const [idsSyncing, setIdsSyncing] = useState<string[]>([]);

  const availableToSync = useMemo(() => {
    return (
      dataAccountExternal?.data?.data.filter(
        (selected) => !idsSyncing.includes(selected.objectId),
      ) ?? []
    );
  }, [dataAccountExternal, idsSyncing]);

  //Remove the already synced account from the selected
  const checkSelectedExternal = (accountId: string) => {
    const newSelected = [...selectedExternal].filter(
      (selected) => selected !== accountId,
    );
    return setSelectedExternal(newSelected);
  };

  const handleSuccessfullImport = (accountId: string) => {
    setIdsSyncing((prev) => prev.filter((id) => id !== accountId));
    queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
    searchInCrm();
  };

  const handleCompleteMultipleSync = () => {
    setIdsSyncing([]);
    queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
    searchInCrm();
  };

  //To enable or disable the checkbox if the other type of import is selected
  const enableSelectionExternal = selected.length === 0;

  const enableSelectionInternal = selectedExternal.length === 0;

  //This call is used to know if there is at least one company in Crono for the empty state
  const { data: atLeastOneCompany } = useSearchAccounts({
    ...(defaultFilters as AccountSearch),
    userId: null,
    limit: 1,
    name: null,
    searchIncludeProspects: true,
  });

  const [showEditColumns, setShowEditColumns] = useState<boolean>(false);

  return (
    <>
      {/* {accounts?.data?.data.length === 0 ? (
        <FlexDiv
          direction="column"
          position="relative"
          justifyContent="start"
          style={{ overflow: "hidden", padding: "0px 20px 20px 20px" }}
        >
          {rowPlaceholder}
          <FlexDiv
            className="table-container"
            position="absolute"
            style={{ paddingBottom: "100px" }}
          >
            <div className="error-container">
              <div>
                <NoCompanies />
              </div>
              <Typography
                fontSize={24}
                fontWeight={700}
                lineHeight={"30px"}
                textAlign={"center"}
              >
                You don't have any companies yet
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={500}
                lineHeight={"24px"}
                color={colors.grey11}
                align="center"
              >
                Click{" "}
                <span className="find-new" onClick={handleOpenSearch}>
                  Find new
                </span>{" "}
                button to add companies or integrate them from your CRM by going
                to the settings page.
              </Typography>
            </div>
          </FlexDiv>
        </FlexDiv>
      ) : ( */}
      {atLeastOneCompany?.data?.data.length === 0 ? (
        //No companies for this user, initial empty state
        <>
          <FlexDiv
            direction="column"
            position="relative"
            justifyContent="start"
            style={{ overflow: 'hidden', padding: '0px 20px 20px 20px' }}
          >
            {rowPlaceholder}
            <FlexDiv
              className="table-container"
              position="absolute"
              style={{ paddingBottom: '100px' }}
            >
              <div className="error-container">
                <div>
                  <NoCompanies />
                </div>
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'30px'}
                  textAlign={'center'}
                >
                  You don't have any company yet
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  align="center"
                >
                  Click{' '}
                  <span className="find-new" onClick={handleOpenSearch}>
                    Find new
                  </span>{' '}
                  button to add companies or integrate them from your CRM by
                  going to the settings page.
                </Typography>
              </div>
            </FlexDiv>
          </FlexDiv>
        </>
      ) : accounts?.data?.data.length === 0 &&
        (dataAccountExternal?.data?.data.length ?? 0) === 0 ? (
        areAccountFiltersModified ? (
          <FlexDiv
            direction="column"
            position="relative"
            justifyContent="start"
            style={{ overflow: 'hidden', padding: '0px 20px 20px 20px' }}
          >
            {rowPlaceholder}
            <FlexDiv
              className="table-container"
              position="absolute"
              style={{ paddingBottom: '100px' }}
            >
              <div className="error-container">
                <div>
                  <NoCompaniesFoundFilters />
                </div>
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'30px'}
                  textAlign={'center'}
                >
                  No companies found with these filters
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  align="center"
                  whiteSpace={'pre-line'}
                >
                  Try to change the applied filters to see different <br />
                  companies or{' '}
                  <span
                    className="find-new"
                    onClick={() => resetFiltersAndOwner()}
                  >
                    Click here
                  </span>{' '}
                  to search among all <br /> companies and get more results
                </Typography>
              </div>
            </FlexDiv>
          </FlexDiv>
        ) : (
          //If I have not search external I show the message to search for them
          <FlexDiv
            direction="column"
            position="relative"
            justifyContent="start"
            style={{ overflow: 'hidden', padding: '0px 20px 20px 20px' }}
          >
            {rowPlaceholder}
            <FlexDiv
              className="table-container"
              position="absolute"
              style={{ paddingBottom: '100px' }}
            >
              <div className="error-container">
                {dataAccountExternal === undefined ? (
                  <>
                    <div>
                      <NoResultsFoundCrono />
                    </div>
                    <Typography
                      fontSize={'16px'}
                      fontWeight={600}
                      lineHeight={'24px'}
                      textAlign={'center'}
                    >
                      No results found with this request in Crono
                    </Typography>
                    {onlyMineShown ? (
                      <div className="search-in-crm-row">
                        <div
                          className="search-in-crm-row-clickable"
                          onClick={handleSearchInAllCompanies}
                        >
                          <IconMCompany
                            color={colors.darkGreen}
                            className="icon"
                          />
                          <Typography
                            fontSize={'14px'}
                            lineHeight={'18px'}
                            fontWeight={500}
                            color={colors.darkGreen}
                          >
                            Click here to search in all companies
                          </Typography>
                        </div>
                      </div>
                    ) : user?.integrationType !== IntegrationType.CRONO &&
                      //We shown the search in CRM only if the name is at least 3 characters long
                      (searchParameters.name?.length ?? 0) > 2 ? (
                      <div className="search-in-crm-row">
                        <div
                          className={`search-in-crm-row-clickable ${
                            searchingAccountExternal
                              ? 'search-in-crm-row-clickable-disabled'
                              : ''
                          }`}
                          onClick={() => {
                            if (!searchingAccountExternal)
                              handleSearchInAllCompanies();
                          }}
                        >
                          <IconSearchInCRM
                            color={colors.darkGreen}
                            className="icon"
                          />
                          <Typography
                            fontSize={'14px'}
                            lineHeight={'18px'}
                            fontWeight={500}
                            color={colors.darkGreen}
                          >
                            Search in CRM for more results
                          </Typography>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <IllustrationCompanyNotFound />
                    <Typography
                      fontSize={'16px'}
                      lineHeight={'24px'}
                      fontWeight={600}
                      whiteSpace={'pre-line'}
                      textAlign={'center'}
                    >
                      Sorry, we didn't find any results
                      <br /> for this request in you CRM
                    </Typography>
                  </>
                )}
              </div>
            </FlexDiv>
          </FlexDiv>
        )
      ) : (
        <>
          {showEditColumns && (
            <div
              style={{
                position: 'absolute',
                zIndex: 100,
                top: '123px',
                right: '12px',
                maxHeight: 'calc(100% - 135px)',
                display: 'flex',
              }}
            >
              <EditColumnsDropdown
                editColumnsType={EditListColumnsType.Companies}
                onClose={() => setShowEditColumns(false)}
                onApply={() => setShowEditColumns(false)}
              />
            </div>
          )}
          <TableContainer className="table-container">
            <Table stickyHeader>
              <CustomTableHead
                toggleEditDropdown={() => setShowEditColumns(!showEditColumns)}
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                headCells={getAccountHeadCells(
                  externalProperties?.data?.data,
                  user?.userPreferences?.listAccountColumns || null,
                )}
                onlyOwned={user ? searchParameters.userId === user.id : false}
                showSelectAll={true}
                selectedStatus={
                  !accounts?.data?.data || selected.length === 0
                    ? 0
                    : selected.length !== accounts?.data?.data.length
                      ? 1
                      : 2
                }
                clickSelectAll={handleClickSelectAll}
                disableCheckbox={!enableSelectionInternal}
              />
              {isLoading ? (
                <FlexDiv height="80%" style={{ position: 'fixed' }}>
                  <CircularProgress />
                </FlexDiv>
              ) : (
                <TableBody>
                  {accounts?.data?.data.map((account) => {
                    return (
                      <AccountRow
                        account={account}
                        externalProperties={externalProperties?.data?.data}
                        onlyOwned={
                          user ? searchParameters.userId === user.id : false
                        }
                        key={account.id}
                        selected={selected.includes(account.objectId)}
                        handleSelected={handleSelectAccount}
                        handleOpenFindNew={handleOpenFindNew}
                        disableCheckbox={!enableSelectionInternal}
                      />
                    );
                  })}
                </TableBody>
              )}
            </Table>
            {!isLoading &&
              (dataAccountExternal === undefined ? (
                (searchParameters.name?.length ?? 0) > 2 ? (
                  onlyMineShown ? (
                    <div className="search-in-crm-row">
                      <div
                        className="search-in-crm-row-clickable"
                        onClick={handleSearchInAllCompanies}
                      >
                        <IconMCompany
                          color={colors.darkGreen}
                          className="icon"
                        />
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                          color={colors.darkGreen}
                        >
                          Click here to search in all companies
                        </Typography>
                      </div>
                    </div>
                  ) : user?.integrationType !== IntegrationType.CRONO ? (
                    //We shown the search in CRM only if the name is at least 3 characters long
                    <div className="search-in-crm-row">
                      <div
                        className={`search-in-crm-row-clickable ${
                          searchingAccountExternal
                            ? 'search-in-crm-row-clickable-disabled'
                            : ''
                        }`}
                        onClick={() => {
                          if (!searchingAccountExternal)
                            handleSearchInAllCompanies();
                        }}
                      >
                        <IconSearchInCRM
                          color={colors.darkGreen}
                          className="icon"
                        />
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                          color={colors.darkGreen}
                        >
                          Search in CRM for more results
                        </Typography>
                      </div>
                    </div>
                  ) : null
                ) : null
              ) : dataAccountExternal.data?.data.length === 0 ? (
                <div className="search-in-crm-row-no-results">
                  <IllustrationCompanyNotFound />
                  <Typography
                    fontSize={16}
                    lineHeight={'24px'}
                    fontWeight={600}
                    whiteSpace={'pre-line'}
                    textAlign={'center'}
                  >
                    Sorry, we didn't find any results
                    <br /> for this request in you CRM
                  </Typography>
                </div>
              ) : (
                <div className="search-in-crm-results">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      padding: '6px 16px 6px 4px',
                      gap: 4,
                      borderBlock: `1px solid ${colors.grey4}`,
                      width: '100%',
                    }}
                  >
                    <label>
                      <Checkbox
                        checked={
                          !(
                            !availableToSync.length ||
                            selectedExternal.length === 0
                          ) &&
                          selectedExternal.length === availableToSync.length
                        }
                        indeterminate={
                          !(
                            !availableToSync.length ||
                            selectedExternal.length === 0
                          ) &&
                          selectedExternal.length !== availableToSync.length
                        }
                        disabled={!enableSelectionExternal}
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleClickSelectAllExternal();
                        }}
                        icon={
                          <IconUnchecked
                            color={
                              !enableSelectionExternal
                                ? colors.grey4
                                : colors.grey3
                            }
                          />
                        }
                        checkedIcon={<CheckedIcon />}
                        indeterminateIcon={
                          <IconIndeterminate color={colors.mainDark} />
                        }
                        color="secondary"
                        sx={{
                          padding: 'inherit',
                          margin: 'inherit',
                          '&:hover': {
                            backgroundColor: colors.grey4,
                          },
                          '&.Mui-checked': {
                            '&:hover': {
                              opacity: 0.7,
                            },
                          },
                          marginRight: 1,
                          marginLeft: 1,
                          // "&.MuiCheckbox-root": {
                          //   color: colors.grey33 + " !important",
                          // },
                        }}
                      />
                    </label>
                    <CrmIcon color={colors.grey11} />
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'16px'}
                      color={colors.grey11}
                    >
                      Results in CRM (
                      {dataAccountExternal.data?.data.length ?? 0})
                    </Typography>
                  </div>
                  {dataAccountExternal.data?.data.map(
                    (accountExternal: Account, index: number) => {
                      return (
                        <AccountExternalRow
                          account={accountExternal}
                          key={index}
                          selected={
                            selectedExternal.findIndex(
                              (selected) =>
                                selected === accountExternal.objectId,
                            ) >= 0
                          }
                          handleSelectedExternal={handleSelectedExternal}
                          disableCheckbox={!enableSelectionExternal}
                          onAdd={() => {
                            setIdsSyncing((prev) => [
                              ...prev,
                              accountExternal.objectId,
                            ]);
                            checkSelectedExternal(accountExternal.objectId);
                          }}
                          onErrorImporting={() => {
                            setIdsSyncing((prev) =>
                              prev.filter(
                                (id) => id !== accountExternal.objectId,
                              ),
                            );
                          }}
                          onSuccessImporting={() => {
                            handleSuccessfullImport(accountExternal.objectId);
                          }}
                          syncing={idsSyncing.includes(
                            accountExternal.objectId,
                          )}
                        />
                      );
                    },
                  )}
                </div>
              ))}
          </TableContainer>
          <Pagination
            total={accounts?.data?.total}
            count={accounts?.data?.count}
            itemOffset={searchParameters.offset ?? 0}
            setItemOffset={setItemOffset}
            limit={Constants.defaultLimit}
            selectionComponent={
              <AccountSelectionBottom
                selected={selected}
                selectedOwned={selectedOwned}
                clearSelected={clearSelected}
                selectedExternal={selectedExternal}
                onExternalAdd={() => {
                  setIdsSyncing((prev) => [...prev, ...selectedExternal]);
                  setSelectedExternal([]);
                }}
                onCompleteSync={() => handleCompleteMultipleSync()}
              />
            }
            style={{
              height: 56,
            }}
          />
        </>
      )}
    </>
  );
};

export const StatusTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    arrow={props.arrow ? props.arrow : false}
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    position: 'relative',
    top: 0,
    left: 170,
    border: '1px solid #dadde9',
    borderRadius: 16,
    padding: 6,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

export const ExternalPropertyTooltip = styled(
  ({ className, style, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(({ style }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: 300,
    overflow: 'auto',
    position: 'relative',
    top: 0,
    left: 0,
    border: '1px solid #dadde9',
    borderRadius: 16,
    padding: 12,
    maxWidth: 200,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    ...style,
    // "::-webkit-scrollbar": {
    //   width: "0.4em"
    // }
  },
}));

export default CompanyTable;
