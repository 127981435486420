import { ENDPOINTS } from 'config/endpoints';
import { QueryClient, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AccountInsert } from 'crono-fe-common/types/DTO/accountInsert';
import { Account } from 'crono-fe-common/types/account';
import { useLimitedMutation } from '../useLimitedMutation';

export default function useCreateAccount() {
  const queryClient = useQueryClient();

  const createAccount = (accountInput: AccountInsert) => {
    const request: Request = {
      url: `${ENDPOINTS.account.main}`,
      config: {
        method: 'post',
        data: { data: accountInput },
      },
    };
    return client(request);
  };

  return useLimitedMutation<
    Response<Account | null>,
    CronoError,
    AccountInsert
  >(createAccount, {
    onSettled: () => {
      invalidationUseCreateAccount(queryClient);
    },
  });
}

export const invalidationUseCreateAccount = (queryClient: QueryClient) => {
  queryClient.invalidateQueries([ENDPOINTS.account.main]);
  queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
  queryClient.invalidateQueries([ENDPOINTS.account.linkedin.main]);
  queryClient.invalidateQueries([ENDPOINTS.suggestion.search]);
  queryClient.invalidateQueries([ENDPOINTS.user.onboarding.features]);
};
