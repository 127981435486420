import React from 'react';
import ContactsInStrategyList from './contactsInStrategyList';
import StrategyInstancePersonalization from './strategyInstancePersonalization';
import { ReactComponent as IllustrationNoContactsInStrategy } from 'assets/images/No-active-strategy-illustration.svg';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { useSafeStrategyOverviewContext } from '../index';

interface IProps {
  sequenceId: number;
}

const StrategyPersonalise = ({ sequenceId }: IProps) => {
  const {
    insightsContext: { prospect },
  } = useSafeStrategyOverviewContext();

  return (
    <>
      <ContactsInStrategyList sequenceId={sequenceId} />
      {prospect ? (
        <StrategyInstancePersonalization sequenceId={sequenceId} />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IllustrationNoContactsInStrategy />
          <Typography fontSize={16} lineHeight={'24px'} fontWeight={600}>
            There are no active contacts in this strategy
          </Typography>
          <Typography
            fontSize={14}
            lineHeight={'24px'}
            color={colors.grey11}
            width={390}
            textAlign={'center'}
          >
            Please enrol new contacts into this strategy to see and personalize
            all the strategy steps of those contacts
          </Typography>
        </div>
      )}
    </>
  );
};

export default StrategyPersonalise;
