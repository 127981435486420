import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type DeleteConditionalSequenceStepParams = {
  stepId: number;
};

export default function useDeleteConditionalSequenceStep() {
  const queryClient = useQueryClient();

  const deleteSequenceStep = ({
    stepId,
  }: DeleteConditionalSequenceStepParams) => {
    const queryParams = queryString.stringify({
      stepId,
    });

    const request: Request = {
      url: `${ENDPOINTS.sequence.conditional.main}?${queryParams}`,
      config: {
        method: 'delete',
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    DeleteConditionalSequenceStepParams
  >(deleteSequenceStep, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
    },
  });

  return { data, mutate, ...rest };
}
