import React, { useState, useEffect } from 'react';

import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { Button, TextField, Typography } from '@mui/material';
import { EditableTemplateModalWrapper } from './style';
import { CancelButton } from 'crono-fe-common/components/CronoButton';
import useEditTemplate from 'hooks/services/templates/useEditTemplate';
import useCreateTemplate from 'hooks/services/templates/useCreateTemplate';
import usePatchSequenceStep from 'hooks/services/sequence/usePatchSequenceStep';
import { useSequenceContext } from '../../../context/sequence';
import { Template } from 'crono-fe-common/types/template';
import useAddSequenceStepTemplate from 'hooks/services/sequence/useAddSequenceStepTemplate';
import useDeleteSequenceStepTemplate from 'hooks/services/sequence/useDeleteSequenceStepTemplate';
import { FileContent } from 'use-file-picker';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import { isFileContent } from 'pages/settings/Template/model';
import useDeleteTemplateAttachment from 'hooks/services/templates/useDeleteTemplateAttachment';
import useAddTemplateAttachment from 'hooks/services/templates/useAddTemplateAttachment';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';
import { formatHtmlContent } from 'utils/fe-utils';
import usePatchConditionalSequenceStep from 'hooks/services/sequence/usePatchConditionalSequenceStep';

interface IProps {
  stepId: number;
  template: Template;
  content: string;
  subject: string;
  attachments: (FileContent | CronoAttachment)[];
  initialAttachments: CronoAttachment[];
  close: () => void;
  onSuccess: () => void;
  isABTesting: boolean;
  isConditional: boolean;
}

const EditableTemplateModal = ({
  content,
  close,
  stepId,
  template,
  subject,
  attachments,
  onSuccess,
  isABTesting,
  isConditional,
  initialAttachments,
}: IProps) => {
  const [title, setTitle] = useState<string>(template.title);
  const [error, setError] = useState<boolean>(false);
  const { sequence } = useSequenceContext();

  const {
    isSuccess: isSuccessEdit,
    mutateAsync: editTemplate,
    error: editTemplateError,
  } = useEditTemplate();

  const { mutateAsync: createTemplate, error: createTemplateError } =
    useCreateTemplate();

  const { mutate: patchSequenceStep, isSuccess: sequenceStepPatched } =
    usePatchSequenceStep();

  const {
    mutate: patchConditionalSequenceStep,
    isSuccess: sequenceConditionalStepPatched,
  } = usePatchConditionalSequenceStep();

  const {
    mutate: deleteSequenceStepTemplate,
    isSuccess: sequenceStepTemplateDeleted,
  } = useDeleteSequenceStepTemplate();

  const { mutateAsync: addSequenceStepTemplateAsync } =
    useAddSequenceStepTemplate();

  useEffect(() => {
    if (
      isSuccessEdit ||
      sequenceStepTemplateDeleted ||
      sequenceStepPatched ||
      sequenceConditionalStepPatched
    ) {
      onSuccess();
    }
  }, [
    isSuccessEdit,
    sequenceStepTemplateDeleted,
    sequenceStepPatched,
    sequenceConditionalStepPatched,
  ]);

  const { mutateAsync: deleteAttachment, error: deleteAttachmentError } =
    useDeleteTemplateAttachment();

  const { mutateAsync: addAttachmentToTemplate, error: addAttachmentError } =
    useAddTemplateAttachment();

  const analytics = useJuneAnalytics();

  const handleEdit = async () => {
    await editTemplate({
      ...template,
      content: formatHtmlContent(content),
      title,
      subject,
    });
    if (analytics) {
      analytics.track('edit-template', {
        fromCustomization: true,
      });
    }
    const attachmentsToDelete = initialAttachments.filter(
      (ia) => !attachments.some((a) => !isFileContent(a) && a.id === ia.id),
    );
    const promises = [];
    for (const attachment of attachmentsToDelete) {
      promises.push(
        deleteAttachment({
          templateId: template.id,
          attachmentId: attachment.id,
        }),
      );
    }
    await Promise.all(promises);

    const attachmentsToAdd = attachments.filter((a) =>
      isFileContent(a),
    ) as FileContent[];
    await addAttachmentToTemplate({
      templateId: template.id,
      attachments: attachmentsToAdd,
    });
  };

  const handleCreate = async () => {
    if (title === template.title) {
      setError(true);
    } else {
      const oldAttachments = attachments.filter(
        (attachment) => !isFileContent(attachment),
      ) as CronoAttachment[];

      const newAttachments = attachments.filter((attachment) =>
        isFileContent(attachment),
      ) as FileContent[];

      const { data: response } = await createTemplate({
        ...template,
        content: formatHtmlContent(content),
        title,
        subject,
        attachments: newAttachments,
        attachmentIds: oldAttachments.map((attachment) => attachment.id),
      });
      if (analytics) {
        analytics.track('create-template', {
          fromCustomization: true,
        });
      }
      if (response?.data?.id) {
        const createdTemplateId = response?.data?.id;

        if (isABTesting) {
          const responseAdd = await addSequenceStepTemplateAsync({
            sequenceStepId: stepId,
            templateId: createdTemplateId,
            templateType: template.type,
          });

          if (responseAdd?.data?.data) {
            const sequenceStepTemplateId = sequence.steps
              .find((step) => step.id === stepId)
              ?.sequenceStepTemplates?.find(
                (st) => st.template?.id === template.id,
              )?.id;

            if (sequenceStepTemplateId) {
              deleteSequenceStepTemplate({
                sequenceStepTemplateId: sequenceStepTemplateId,
              });
            }
          }
        } else if (isConditional) {
          const step = sequence.steps.find((step) => step.id === stepId);
          if (step && step.sequenceStepConditional) {
            patchConditionalSequenceStep({
              sequenceStepId: stepId,
              type: step.sequenceStepConditional.type,
              templateId: response?.data?.id,
            });
          }
        } else {
          patchSequenceStep({
            id: stepId,
            strategyId: sequence.strategyId,
            templateId: response?.data?.id,
          });
        }
      }
    }
  };

  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
    setError(false);
  };

  const handleTitleBlur = () => {
    if (!title) {
      setTitle(template.title);
    }
  };

  useConditionalSnackBar([
    {
      condition: !!createTemplateError,
      message: getError(createTemplateError) ?? 'Error creating a template',
      severity: 'error',
    },
    {
      condition: !!editTemplateError,
      message:
        getError(editTemplateError) ?? 'Error on saving template changes',
      severity: 'error',
    },
    {
      condition: !!deleteAttachmentError,
      message:
        getError(deleteAttachmentError) ?? 'Error on deleting attachments',
      severity: 'error',
    },
    {
      condition: !!addAttachmentError,
      message: getError(addAttachmentError) ?? 'Error on saving attachments',
      severity: 'error',
    },
  ]);

  return (
    <EditableTemplateModalWrapper>
      <div className="background" onClick={close} />

      <div className="modal-container">
        <div className="modal-body">
          <CloseTabButton close={close} className="closeButton" />
          <Typography variant="h5" fontWeight={700}>
            Save templates as:
          </Typography>

          <div>
            <TextField
              className="text-filed"
              variant="standard"
              fullWidth
              label="Template name"
              placeholder="insert text…"
              color="secondary"
              value={title}
              onBlur={handleTitleBlur}
              onChange={handleTitleChange}
              error={error}
              helperText={'To save as a new template, use a different name'}
            />
          </div>

          <div className="footer">
            <CancelButton
              variant="outlined"
              className="modal-button"
              onClick={handleCreate}
              disableElevation
            >
              Create new
            </CancelButton>
            <Button
              color="secondary"
              variant="contained"
              className="modal-button"
              onClick={handleEdit}
              disableElevation
            >
              Edit existing
            </Button>
          </div>
        </div>
      </div>
    </EditableTemplateModalWrapper>
  );
};

export default EditableTemplateModal;
