import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InsertMultipleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.white};
  width: 600px;
  height: 70%;
  border-radius: 16px;
  padding: 24px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .close-button {
    align-self: flex-end;
    cursor: pointer;
    z-index: 1001;
    height: 24px;
    width: 24px;
  }

  .body-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    height: calc(100% - 24px);
  }
  .companies-container {
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow: auto;
  }
  .company-card-multiple {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    width: 100%;
    border-radius: 8px;
    position: relative;
    border-radius: 8px;
  }
  .company-card-multiple > span {
    opacity: 0;
  }
  .company-card-multiple:hover > span {
    opacity: 1;
  }
  .company-card-multiple:hover {
    background: ${colors.inactiveLight}44;
  }
  .remove-icon-multiple {
    width: 34px;
    height: 34px;
  }
  .divider {
    border-top: 1px solid ${colors.grey44};
    margin-inline: 12px;
  }
  .cancel-opacity-icon {
    position: absolute;
    right: 17px;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.inactiveLight};
    border-radius: 999px;
    cursor: pointer;
  }
`;

export const InsertingMultipleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 24px;
  background: ${colors.white};
  border-radius: 16px;
  width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .slider {
    height: 3px;
    cursor: initial;
  }
  .slider > .MuiSlider-thumb {
    display: none;
  }
`;
