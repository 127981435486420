import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { PersonalizedTemplatePatchDTO } from 'crono-fe-common/types/DTO/personalizedTemplatePatchDTO';

export default function usePatchPersonalizedTemplate() {
  const queryClient = useQueryClient();

  const patchPersonalizedTemplate = (
    personalizedTemplatePatch: PersonalizedTemplatePatchDTO,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.personalizedTemplate.main}`,
      config: {
        method: 'patch',
        data: { data: personalizedTemplatePatch },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, PersonalizedTemplatePatchDTO>(
    patchPersonalizedTemplate,
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENDPOINTS.task.search]);
        queryClient.invalidateQueries([ENDPOINTS.task.overdue]);
        queryClient.invalidateQueries([ENDPOINTS.task.next]);
        queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
        queryClient.invalidateQueries([ENDPOINTS.task.total]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.prospect]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
        queryClient.invalidateQueries([ENDPOINTS.prospect.search.strategy]);
        queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);
      },
    },
  );
}
