import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconSWarning = ({ color = colors.white, className, onClick }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M7.99984 8.74626V6.25293"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99917 10.7497C7.90717 10.7497 7.83251 10.8243 7.83317 10.9163C7.83317 11.0083 7.90784 11.083 7.99984 11.083C8.09184 11.083 8.16651 11.0083 8.16651 10.9163C8.16651 10.8243 8.09184 10.7497 7.99917 10.7497"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35267 2.75913L14.4587 11.6951C15.052 12.7338 14.302 14.0265 13.106 14.0265H2.894C1.69734 14.0265 0.947338 12.7338 1.54134 11.6951L6.64734 2.75913C7.24534 1.7118 8.75467 1.7118 9.35267 2.75913Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSWarning;
