import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ExternalPropertyTemplateVariablesSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${colors.white};
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 486px;
  max-height: 80%;
  height: fit-content;
  overflow: hidden;
  .container-other-variables {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px 24px 24px 24px;
    height: 100%;
    overflow: hidden;
  }
  .other-variables-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }
  .other-variable-item {
    display: flex;
    height: 40px;
    min-height: 40px;
    align-items: center;
  }
`;
