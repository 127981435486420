import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const SearchIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        d="M16.2443 6.1008C19.0454 8.90186 19.0454 13.4433 16.2443 16.2443C13.4433 19.0454 8.90186 19.0454 6.1008 16.2443C3.29973 13.4433 3.29973 8.90186 6.1008 6.1008C8.90186 3.29973 13.4433 3.29973 16.2443 6.1008"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9997 19.9999L16.2397 16.2399"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
