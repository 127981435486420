import React, { useEffect, useMemo } from 'react';
import { SubscriptionStepContainer } from './styles';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { MenuItem, Select, Typography } from '@mui/material';
import { ReactComponent as AircallLogo } from 'assets/images/Aircall-menu-item-icon.svg';
import { ReactComponent as GmailLogo } from 'assets/images/Gmail-menu-item-icon.svg';
import { ReactComponent as OutlookLogo } from 'assets/images/Outlook-menu-item-icon.svg';
import IconHubspot from 'crono-fe-common/icons/Icon-Hubspot';
import IconSalesforce from 'crono-fe-common/icons/Icon-Salesforce';
import IconPipedrive from 'crono-fe-common/icons/Icon-Pipedrive';
import IconCrono from 'crono-fe-common/icons/Icon-Crono';
import { User } from 'crono-fe-common/types/user';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import IconCustomEmailProvider from 'crono-fe-common/icons/Icon-Custom-Email-Provider';

export const customSelectPaperProps = {
  sx: {
    borderRadius: '8px',
    paddingInline: '6px',
    border: `1px solid ${colors.greySelectBorder}`,
    boxShadow: `0px 4px 8px 0px ${colors.greySelectBoxShadow}`,
    '& .MuiMenuItem-root': {
      fontSize: '12px',
      lineHeight: '16px',
      height: 'fit-content',
      padding: '8px',
      cursor: 'pointer',
      width: '100%',
      borderRadius: '8px',
      display: 'flex',
      gap: '8px',
      '& .menu-item-icon': {
        borderRadius: '4px',
        padding: '4px',
        width: '24px',
        height: '24px',
      },
      '& .GMail': {
        backgroundColor: colors.grey6,
      },
      '& .Outlook': {
        backgroundColor: colors.grey6,
      },
      '& .Custom': {
        backgroundColor: colors.grey6,
      },
      '& .None': {
        backgroundColor: colors.grey4Hover,
      },
      '& .icon': {
        padding: '4px',
        backgroundColor: colors.grey6,
      },
      '& .Hubspot': {
        backgroundColor: colors.hubspotOrange,
      },
      '& .Salesforce': {
        backgroundColor: colors.salesforceBlue,
      },
      '& .Pipedrive': {
        backgroundColor: colors.pipedriveColor,
      },
      '& .Crono': {
        backgroundColor: colors.primary,
      },
      '&:hover': {
        background: colors.primaryLight,
        color: colors.primary,
      },
    },
    '& .Mui-selected': {
      color: colors.primary,
      backgroundColor: 'transparent !important',
    },
  },
};

export interface IntegrationMenuItem {
  src: any;
  name: EmailIntegrationType | IntegrationType | 'None';
  label: string;
}
// Name is used exclusively for styles and display here.
// Value type can differ depending on each specific menu item (CRM, email, call)
export const crmIntegrationsMenuItems: IntegrationMenuItem[] = [
  {
    src: <IconHubspot color={colors.white} />,
    name: IntegrationType.HUBSPOT,
    label: IntegrationType.HUBSPOT,
  },
  {
    src: <IconSalesforce color={colors.white} />,
    name: IntegrationType.SALESFORCE,
    label: IntegrationType.SALESFORCE,
  },
  {
    src: <IconPipedrive />,
    name: IntegrationType.PIPEDRIVE,
    label: IntegrationType.PIPEDRIVE,
  },
  {
    src: <IconCrono />,
    name: IntegrationType.CRONO,
    label: IntegrationType.CRONO,
  },
];

export const emailIntegrationsMenuItems: IntegrationMenuItem[] = [
  {
    src: <GmailLogo width={16} height={16} />,
    name: EmailIntegrationType.GMAIL,
    label: 'Gmail business',
  },
  {
    src: <OutlookLogo />,
    name: EmailIntegrationType.OUTLOOK,
    label: 'Outlook business',
  },
  {
    src: <IconCustomEmailProvider />,
    name: EmailIntegrationType.CUSTOM,
    label: 'Other email provider (SMTP/IMAP)',
  },
  {
    src: <IconCrono />,
    name: 'None',
    label: 'None',
  },
];

interface IProps {
  user: User | null | undefined;
  selectedCRM: IntegrationType | null;
  selectedEmail: EmailIntegrationType | null;
  aircallActive: boolean;
  setSelectedCRM: React.Dispatch<React.SetStateAction<IntegrationType | null>>;
  setSelectedEmail: React.Dispatch<
    React.SetStateAction<EmailIntegrationType | null>
  >;
  setAircallActive: React.Dispatch<React.SetStateAction<boolean>>;
  handleBack: () => void;
  handleGoNextStep: () => void;
}

const IntegrationsStep = ({
  user,
  selectedCRM,
  selectedEmail,
  aircallActive,
  setSelectedCRM,
  setSelectedEmail,
  setAircallActive,
  handleBack,
  handleGoNextStep,
}: IProps) => {
  const renderIntegrationMenuItem = (item: IntegrationMenuItem) => {
    return (
      <MenuItem value={item.name}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={`menu-item-icon ${item.name}`}
        >
          {item.src}
        </div>
        <Typography fontSize={12} lineHeight={'16px'} fontWeight={500}>
          {item.label}
        </Typography>
      </MenuItem>
    );
  };

  const renderValueCRMSelect = (
    value: IntegrationType | EmailIntegrationType | null,
  ) => {
    const item = [
      ...crmIntegrationsMenuItems,
      ...emailIntegrationsMenuItems,
    ].find((el) => el.name === value);
    return item ? (
      <div className={`value-select`}>
        <div className={`value-select-icon ${item.name.toLocaleLowerCase()}`}>
          {item.src}
        </div>
        <Typography fontSize={12} lineHeight={'16px'} fontWeight={500}>
          {item.label}
        </Typography>
      </div>
    ) : (
      <div />
    );
  };

  //To allow or not the user to click on next depending on the subscription type
  const disableNext = useMemo(() => {
    if (
      !selectedEmail ||
      (user?.subscriptionType !== SubscriptionPlanType.FREE_TRIAL &&
        user?.subscriptionType !== SubscriptionPlanType.STARTER &&
        !selectedCRM)
    ) {
      return true;
    }
  }, [selectedCRM, selectedEmail]);

  const refContainer = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refContainer.current) {
      refContainer.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <SubscriptionStepContainer ref={refContainer}>
      <div className="step-card-container">
        <div className="form-box">
          {user?.subscriptionType !== SubscriptionPlanType.FREE_TRIAL &&
            user?.subscriptionType !== SubscriptionPlanType.STARTER && (
              <div className="integration-select">
                <Typography fontSize={'14px'} fontWeight={600}>
                  CRM integration
                </Typography>
                <Select
                  value={selectedCRM}
                  onChange={(ev) => {
                    setSelectedCRM(ev.target.value as IntegrationType);
                  }}
                  className="select"
                  sx={{
                    height: '40px',
                  }}
                  MenuProps={{
                    style: { zIndex: 35001 },
                    PaperProps: customSelectPaperProps,
                  }}
                  renderValue={(value) => {
                    return renderValueCRMSelect(value);
                  }}
                >
                  {crmIntegrationsMenuItems.map((el) => {
                    return renderIntegrationMenuItem(el);
                  })}
                </Select>
              </div>
            )}

          <div className="integration-select">
            <Typography fontSize={'14px'} fontWeight={600}>
              Email provider
            </Typography>

            <Select
              value={selectedEmail ?? -1}
              onChange={(ev) => {
                setSelectedEmail(ev.target.value as EmailIntegrationType);
              }}
              className="select"
              MenuProps={{
                style: { zIndex: 35001 },
                PaperProps: customSelectPaperProps,
              }}
              sx={{
                height: '40px',
              }}
              renderValue={(value) => {
                if (value === -1) {
                  return (
                    <Typography color={colors.grey11}>Choose one</Typography>
                  );
                }
                return renderValueCRMSelect(value);
              }}
            >
              {emailIntegrationsMenuItems.map((el) =>
                renderIntegrationMenuItem(el),
              )}
            </Select>
          </div>

          <div className="integration-select">
            <Typography fontSize={'14px'} fontWeight={600}>
              Call system integration
            </Typography>
            {/* <Select
                    // value={}
                    // onChange={() => {}}
                    className="select"
                    MenuProps={{
                      style: { zIndex: 35001 },
                      PaperProps: customSelectPaperProps
                    }}
                  >
                    {callIntegrationsMenuItems.map((el) =>
                      renderIntegrationMenuItem(el)
                    )}
                  </Select> */}
            <div className="call-integration-system-div">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <AircallLogo />
                <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
                  Aircall
                </Typography>
              </div>
              <CronoSwitch
                checked={aircallActive}
                onChange={() => {
                  setAircallActive((prev) => !prev);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="onboarding-navigation">
        <CancelButton style={{ width: '120px' }} onClick={handleBack}>
          Back
        </CancelButton>
        <MainButton
          style={{ width: '120px' }}
          onClick={handleGoNextStep}
          disabled={disableNext}
        >
          Next
        </MainButton>
      </div>
    </SubscriptionStepContainer>
  );
};

export default IntegrationsStep;
