import styled from 'styled-components';
import { colors } from 'crono-fe-common/theme';
import { FeConstants } from 'constants/FeConstants';

const customScrollBar = (selector: string) =>
  `${selector} {
        &::-webkit-scrollbar-thumb {
          background: ${colors.greyScrollbarBg};
          border-color: ${colors.greyScrollbarBg};
        }
        &::-webkit-scrollbar-thumb:hover {
          background: ${colors.activeScrollbarGreen};
        }
        &::-webkit-scrollbar-thumb:active {
          background: ${colors.activeScrollbarGreen};
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          border-color: ${colors.activeScrollbarGreen};
        }
        &::-webkit-scrollbar-track:hover {
          background: transparent;
        }
        &::-webkit-scrollbar-track:active {
          background: transparent;
        }
  }`;

export const EditorToolbarWrapper = styled.div`
  font-family: Poppins;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid ${colors.grey444} !important;
  background-color: ${colors.white} !important;

  .toolbar {
    display: flex;
    gap: 8px;
    width: 100%;
    align-self: center;
    justify-content: space-between;
    .buttons-right,
    .buttons-left {
      display: flex;
      gap: 8px;
      align-items: start;
      flex-wrap: wrap;

      & > * {
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 500;
      }
    }

    .buttons-left {
      flex: 1;
    }
    ${customScrollBar('.buttons-left')}

    .buttons-group {
      display: flex;
    }

    button,
    .ql-picker-label {
      padding: 2px 4px;
      width: fit-content;
      border-radius: 5px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    button:hover,
    .ql-picker-label:hover,
    button:focus,
    .ql-picker-label:focus,
    button.ql-active,
    .ql-picker-label.ql-active {
      background: ${colors.primaryLight};
      color: ${colors.darkGreen} !important;

      .ql-fill {
        fill: ${colors.darkGreen} !important;
      }

      .ql-stroke {
        stroke: ${colors.darkGreen} !important;
      }
    }

    .ql-redo,
    .ql-undo {
      svg {
        margin-bottom: 2px;
      }
    }

    .ql-variables {
      position: relative;

      .variables-picker {
        position: absolute;
        color: ${colors.black};
        top: 30px;
        right: 0;
        z-index: 10;
      }
    }

    .ql-image-upload {
      path {
        stroke: ${colors.black};
      }
    }

    .ql-image-upload:hover,
    .ql-image-upload:focus,
    .ql-image-upload:active,
    .ql-variables:hover,
    .ql-variables:focus,
    .ql-variables:active {
      path {
        stroke: ${colors.darkGreen};
      }

      circle {
        fill: ${colors.darkGreen};
      }
    }

    .ql-attachments:hover,
    .ql-attachments:focus,
    .ql-attachments:active {
      path {
        fill: ${colors.darkGreen};
      }
    }

    .ql-templates:hover,
    .ql-templates:focus,
    .ql-templates:active,
    .ql-insights:hover,
    .ql-insights:focus,
    .ql-insights:active {
      filter: brightness(0.8);
      color: ${colors.black} !important;
    }

    .ql-templates {
      background-color: #d4bfff !important;
      padding: 10px;
      height: 32px;
    }
    .ql-insights {
      background-color: #ffd748 !important;
      padding: 10px;
      height: 32px;
    }

    .ql-rewrite:hover,
    .ql-rewrite:focus,
    .ql-rewrite:active,
    .ql-rewrite {
      all: unset;
      box-sizing: border-box;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      height: 24px;
      margin-left: 4px;

      font-size: 12px;
      padding: 4px 8px 4px 6px;
      background: ${colors.blueLightHover};
      border-radius: 20px;
      cursor: pointer;
      color: ${colors.black} !important;

      svg {
        pointer-events: none;
        height: 16px;
        width: 16px;
      }

      &:hover {
        background: ${colors.greyButtonHover};
      }
    }

    .highlighted-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      border-radius: 8px;
      padding: 4px;
      cursor: pointer;
      min-height: 32px;
      padding-right: 10px;
      padding-left: 6px;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;

      .highlighted-button-icon {
        height: 16px;
        width: 16px;
      }
    }
    .flex-center {
      display: flex;
      align-items: center;
    }
    .icon-wrapper {
      padding: 4px;
    }

    .ql-picker-item:hover,
    .ql-picker-label:hover {
      color: ${colors.darkGreen};

      .ql-fill {
        fill: ${colors.darkGreen} !important;
      }

      .ql-stroke {
        stroke: ${colors.darkGreen} !important;
      }
    }

    .ql-font .ql-picker-options {
      max-height: 250px;
      overflow: auto;
    }

    .ql-size,
    .ql-font {
      width: fit-content;

      .ql-picker-label {
        padding-right: 20px;
        justify-content: flex-start;
      }

      svg {
        right: 0;
      }

      // This will assign render each font option in toolbar dropdown with it's font family
      ${FeConstants.editorSupportedFonts
        .map((font) => `[data-value='${font}']{font-family: ${font}}`)
        .join(' ')}
    }

    .ql-font .ql-picker-label:before {
      width: 55px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ql-formats {
      margin-right: 0;
    }
  }
`;

export const EditorWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .quill-editor {
    overflow: hidden;
    height: 100%;

    .quill {
      height: 100%;
    }

    .ql-container {
      height: 100%;
      font-family: Poppins, sans-serif;
      border: 1px solid ${colors.grey444};
      border-top: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background: ${colors.grey6};

      ${customScrollBar('.ql-editor')}

      .placeholder {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 20vh;
        background: #eee;
        background: linear-gradient(
          110deg,
          #ececec 8%,
          #f5f5f5 18%,
          #ececec 33%
        );
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
      }
    }

    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }
  }
`;
