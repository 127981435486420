import { AccountStatus } from 'crono-fe-common/types/account';
import { SuggestionType } from 'crono-fe-common/types/enums/suggestionType';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { getStringFromAccountStatus } from 'crono-fe-common/utils';

interface SuggestionInfo {
  accountName?: any | null;
  prospectName?: any | null;
  lastActivityDate?: string | null;
  newStatus?: AccountStatus | null;
  type?: TaskTodoType | null;
}

interface SuggestionDictionary {
  type: SuggestionType;
  title: (suggestionInfo: SuggestionInfo) => any;
  description: (suggestionInfo: SuggestionInfo) => any;
}

const Dictionary: SuggestionDictionary[] = [
  {
    type: SuggestionType.FollowUpMeeting,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          You recently had a meeting with {suggestionInfo.prospectName}
        </span>
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Follow up on your meeting with {suggestionInfo.prospectName}!
        </span>
      );
    },
  },
  {
    type: SuggestionType.SuggestNewRole,
    title: (suggestionInfo: SuggestionInfo) => {
      return <span>Contact received a promotion and changed role</span>;
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>Would you like to contact {suggestionInfo.prospectName}?</span>
      );
    },
  },
  {
    type: SuggestionType.FollowUpOpportunity,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          There have not been recent updates on your opportunity with{' '}
          {suggestionInfo.prospectName}
        </span>
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Follow up on your opportunity with {suggestionInfo.prospectName}
        </span>
      );
    },
  },
  {
    type: SuggestionType.GenerateAccounts,
    title: () => {
      return 'Increase your target companies to get more results';
    },
    description: () => {
      return 'Find new companies to work on';
    },
  },
  {
    type: SuggestionType.GenerateLeads,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>Company {suggestionInfo.accountName} is not converting</span>
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return <span>Find a new lead for {suggestionInfo.accountName}</span>;
    },
  },
  {
    type: SuggestionType.Precondition,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          {suggestionInfo.prospectName}’s{' '}
          {getChannelFromType(suggestionInfo.type ?? null)} is missing or
          invalid.
        </span>
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Add or change {getChannelFromType(suggestionInfo.type ?? null)} now!
        </span>
      );
    },
  },

  {
    type: SuggestionType.SuggestClearData,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          {suggestionInfo.prospectName}’s{' '}
          {getChannelFromType(suggestionInfo.type ?? null)} is invalid.
        </span>
      );
    },
    description: (_: SuggestionInfo) => {
      return <span>Change it now!</span>;
    },
  },
  {
    type: SuggestionType.EmailReplied,
    title: (suggestionInfo: SuggestionInfo) => {
      return <span>{suggestionInfo.prospectName} replied to your email</span>;
    },
    description: () => {
      return <span>Check it out!</span>;
    },
  },
  {
    type: SuggestionType.LinkedinReplied,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          {suggestionInfo.prospectName} replied to your LinkedIn message
        </span>
      );
    },
    description: () => {
      return <span>Check it out!</span>;
    },
  },
  {
    type: SuggestionType.EmailMultipleRead,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Your email to {suggestionInfo.prospectName} was read more than 5 times
        </span>
      );
    },
    description: () => {
      return <span>Follow up now!</span>;
    },
  },
  {
    type: SuggestionType.StartSequenceForNewProspects,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Contact {suggestionInfo.prospectName} of {suggestionInfo.accountName}{' '}
          has been recently created
        </span>
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return <span>Add {suggestionInfo.prospectName} to a strategy now!</span>;
    },
  },
  {
    type: SuggestionType.StartSequenceForOldProspects,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Contact {suggestionInfo.prospectName} of {suggestionInfo.accountName}{' '}
          has not been contacted {suggestionInfo.lastActivityDate}
        </span>
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return <span>Add {suggestionInfo.prospectName} to a stategy now!</span>;
    },
  },
  {
    type: SuggestionType.StopSequencesForReply,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          {suggestionInfo.prospectName} from {suggestionInfo.accountName}{' '}
          replied to your message
        </span>
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Do you want to stop all strategies for {suggestionInfo.accountName}?
        </span>
      );
    },
  },
  {
    type: SuggestionType.StopSequencesForChangeStatus,
    title: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          {suggestionInfo.accountName} has changed status to{' '}
          {getStringFromAccountStatus(suggestionInfo.newStatus ?? null)}
        </span>
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Do you want to stop all strategies for {suggestionInfo.accountName}?
        </span>
      );
    },
  },
  {
    type: SuggestionType.SuggestedAccountStatus,
    title: (suggestionInfo: SuggestionInfo) => {
      return calculateTitleForSuggestedAccountStatus(
        suggestionInfo.accountName,
        suggestionInfo.newStatus,
      );
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Do you want to change the company status to{' '}
          {getStringFromAccountStatus(suggestionInfo.newStatus ?? null)}?
        </span>
      );
    },
  },
  {
    type: SuggestionType.SuggestNewCompany,
    title: (suggestionInfo: SuggestionInfo) => {
      return <span>Contact moved to a different company</span>;
    },
    description: (suggestionInfo: SuggestionInfo) => {
      return (
        <span>
          Would you like to update the company of {suggestionInfo.prospectName}?
        </span>
      );
    },
  },
];

export const consultDictionary = (type: SuggestionType) => {
  return Dictionary.find((d) => d.type === type);
};

function getChannelFromType(type: TaskTodoType | null) {
  let channel = '';
  if (type === 'Email') {
    channel = 'email address';
  } else if (type === 'Call') {
    channel = 'phone number';
  } else {
    channel = 'LinkedIn url';
  }
  return channel;
}

function calculateTitleForSuggestedAccountStatus(
  accountName: any,
  newStatus?: AccountStatus | null,
) {
  switch (newStatus) {
    case AccountStatus.NURTURE:
      return (
        <span>Your efforts for {accountName} has not been successful</span>
      );
    case AccountStatus.CALL_SCHEDULED:
      return <span>We have found a meeting for {accountName}</span>;
    case AccountStatus.OPEN_OPPORTUNITY:
      return <span>We have found an opportunity for {accountName}</span>;
    case AccountStatus.CUSTOMER:
      return <span>We have found a won opportunity for {accountName}</span>;
    default:
      return '';
  }
}
