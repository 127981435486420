import { CircularProgress, Typography } from '@mui/material';
import { useSelectedAccount } from 'pages/home/context/account';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import OpportunityCard from './opportunityCard';
import { rowPlaceholderFillOpacities } from 'constants/FeConstants';
import { colors } from 'crono-fe-common/theme';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { ReactComponent as NoContactsForCompany } from '../../../assets/images/No_contacts_for_company.svg';
import ContactRowPlaceholder from '../../../assets/images/Contact_row_placeholder.png';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import useGetOpportunity from 'hooks/services/opportunity/useGetOpportunity';
import useGetPipelines from 'hooks/services/pipeline/useGetPipelines';
import AddOpportunityContainer from 'pages/opportunities/addOpportunity/container';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { createPortal } from 'react-dom';

interface IProps {
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  selectedOpportunityId?: string | null;
}

const OpportunitiesView = ({ setTabValue, selectedOpportunityId }: IProps) => {
  const { selectedAccountId, account } = useSelectedAccount();
  const [ownedDeals, setOwnedDeals] = useState<boolean>(false);

  const [initialOpportunityId, setInitialOpportunityId] = useState<
    string | null
  >(selectedOpportunityId ?? null);

  const { data: opportunities, isLoading: loadingOpportunities } =
    useGetOpportunity(selectedAccountId, 20, ownedDeals, initialOpportunityId);

  //The API works that if I pass an opportunityId the owner filter does not apply, so I remove the opportunityIdPassed
  useEffect(() => {
    if (ownedDeals) {
      setInitialOpportunityId(null);
    }
  }, [ownedDeals]);

  useEffect(() => {
    if (selectedOpportunityId) {
      setInitialOpportunityId(selectedOpportunityId);
      setOwnedDeals(false);
    }
  }, [selectedOpportunityId]);

  // Pipeline/stage logic ----------------------------
  const { data: pipelines } = useGetPipelines();
  const [selectedPipelineId, setSelectedPipelineId] = useState<number | null>(
    null,
  );
  const selectedPipeline = useMemo(() => {
    return pipelines?.data?.data.find(
      (pipeline) => pipeline.id === selectedPipelineId,
    );
  }, [selectedPipelineId]);

  useEffect(() => {
    //When loaded set the initial pipeline to the first one
    if (pipelines?.data?.data.length && selectedPipelineId === null) {
      setSelectedPipelineId(pipelines?.data?.data[0].id);
    }
  }, [pipelines]);

  //The stages that has to be shown in the correct order
  const stages = useMemo(() => {
    if (!selectedPipeline?.stages) return [];
    //I order them by stageOrder
    const entries = Object.entries(selectedPipeline?.stages ?? []);
    entries.sort((a, b) => {
      return a[1].displayOrder - b[1].displayOrder;
    });
    //Remove the name, since it is already contained as property
    return entries.map((entry) => entry[1]);
  }, [selectedPipeline]);
  //  -------------------------------

  const listRef = useRef<HTMLDivElement | null>(null);

  const [showCreation, setShowCreation] = useState<boolean>(false);

  const rowPlaceholder = rowPlaceholderFillOpacities.map((el, index) => {
    return (
      <div key={index} style={{ width: '100%' }}>
        <img
          src={ContactRowPlaceholder}
          alt="Empty contact placeholder"
          style={{ opacity: el - 0.2, width: '100%' }}
        />
      </div>
    );
  });

  if (!account) return <></>;

  return (
    <>
      {showCreation &&
        createPortal(
          <AddOpportunityContainer
            close={() => setShowCreation(false)}
            initialCompany={account}
          />,
          document.body,
        )}
      <div className="deals-header-container">
        <div style={{ display: 'flex', gap: '8px' }}>
          <Typography fontSize={'1.2em'} fontWeight={600} lineHeight={'24px'}>
            Deals
          </Typography>
          {opportunities?.data?.data && opportunities?.data?.data.length ? (
            <span className="number-of">
              {opportunities?.data?.data.length}
            </span>
          ) : null}
        </div>

        <div className="switch-container">
          <Typography
            fontWeight={500}
            lineHeight={'16px'}
            fontSize={12}
            color={colors.grey11}
          >
            My deals
          </Typography>
          <CronoSwitch
            checked={ownedDeals}
            onChange={(ev) => {
              setOwnedDeals(ev.target.checked);
            }}
          />
          <MainButton
            startIcon={<PlusIcon color={colors.white} />}
            onClick={() => setShowCreation(true)}
            style={{ height: '40px', margin: 0 }}
          >
            New deal
          </MainButton>
        </div>
      </div>
      <div className="deals-container" ref={listRef}>
        {loadingOpportunities ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : opportunities?.data?.data && opportunities?.data?.data.length ? (
          opportunities?.data?.data.map((opportunity) => (
            <OpportunityCard
              opportunity={opportunity}
              key={opportunity.objectId}
              setTabValue={setTabValue}
              stages={stages}
            />
          ))
        ) : (
          <FlexDiv
            direction="column"
            position="relative"
            justifyContent="start"
            style={{ overflow: 'hidden' }}
          >
            {rowPlaceholder}
            <FlexDiv className="blur-backdrop-effect">
              <div className="img-wrapper">
                <NoContactsForCompany />
              </div>
              <Typography
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'24px'}
                textAlign={'center'}
              >
                There are no deals for this company
              </Typography>
            </FlexDiv>
          </FlexDiv>
        )}
      </div>
    </>
  );
};

export default OpportunitiesView;
