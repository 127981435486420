import styled from '@emotion/styled';

export const LegendWrapper = styled.div`
  display: flex;
  gap: 20px;
  position: absolute;
  top: 0px;
  right: 24px;
  //better UI in small screens
  flex-wrap: nowrap;

  .legend-item {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  .legend-color {
    // the icon was shrinking when not enough space
    flex-shrink: 0;
    border-radius: 999px;
    height: 8px;
    width: 8px;
  }
`;
