import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const AddManuallyTooltipWrapper = styled.div`
  width: 200px;
  position: relative;

  .create-manually-button {
    text-transform: unset !important;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 8px;
    padding: 8px;
    color: ${colors.black};
    filter: grayscale(1);
  }
  .create-manually-button:hover {
    color: ${colors.primary};
    filter: grayscale(0);
  }
  .divider {
    border-top: 1px solid ${colors.grey4};
  }
`;
