import { FC, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
//import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { LogEmail } from 'crono-fe-common/types/logEmail';
import { TableProps } from '../model';
import moment from 'moment';
import { CronoButton } from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import useDownloadEmailsReport from 'hooks/services/event/useDownloadEmailsReport';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { FeConstants } from 'constants/FeConstants';
import DownloadIcon from '@mui/icons-material/Download';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { CronoEmailSearchSortType } from 'crono-fe-common/types/enums/cronoEmailSearchSortType';
import { useSelectedAccount } from 'pages/home/context/account';
import { useWindow } from 'context/sideTab';
import {
  formatJsonToCSV,
  formatTodayDate,
  downloadCSVFile,
} from 'utils/fe-utils';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import { EmailLink } from 'crono-fe-common/types/emailLink';
import { colors } from 'crono-fe-common/theme';
import IconLinkS from 'crono-fe-common/icons/Icon-Link-S';
import { renderNoChartResults } from '..';

const EmailsTable: FC<TableProps<LogEmail>> = ({
  data,
  total,
  limit,
  offset,
  setOffset,
  handleSelectReportMessage,
  isAnsweredEmail,
  setIsAnsweredEmail,
  isOpenedEmail,
  setIsOpenedEmail,
  isClickedEmail,
  setIsClickedEmail,
  userId,
  since,
  to,
  selectedAccount,
  setSortModelEmail,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [downloadComplete, setDownloadComplete] = useState<boolean>(false);

  const {
    data: emailsReport,
    mutate: downloadEmailsReport,
    isLoading: isLoadingEmailsReport,
    error: errorEmailsReport,
    reset: resetEmailsReport,
  } = useDownloadEmailsReport();

  useConditionalSnackBar([
    {
      condition: !!errorEmailsReport,
      message:
        getError(errorEmailsReport) ?? 'Error while downloading email report',
      severity: 'error',
    },
    {
      condition: downloadComplete,
      message: 'Email report downloaded successfully',
      severity: 'success',
    },
    {
      condition: !!errorMessage,
      message: errorMessage ?? 'Error while downloading email report',
      severity: 'error',
    },
    {
      condition: !!infoMessage,
      message: infoMessage ?? '',
      severity: 'info',
    },
  ]);

  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();
  const { openWindow } = useWindow();

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      width: 130,
      filterable: false,
      sortable: true,
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 130,
      filterable: false,
      sortable: true,
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          e.stopPropagation();
          if (!params.row.accountId) return;
          setSelectedAccountId(params.row.accountId);
          setTimeout(() => {
            openWindow({
              windowType: 'account',
              tab: 'account',
            });
          }, 100);
        };

        return (
          <Typography
            fontSize={14}
            fontWeight={500}
            onClick={onClick}
            noWrap
            style={{ cursor: 'pointer' }}
          >
            {params.row.company}
          </Typography>
        );
      },
    },
    {
      field: 'contact',
      headerName: 'Contact',
      width: 130,
      filterable: false,
      sortable: true,
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          e.stopPropagation();
          if (!params.row.prospectId || !params.row.accountId) return;
          setSelectedAccountId(params.row.accountId);
          setSelectedProspectId(params.row.prospectId);
          setTimeout(() => {
            openWindow({
              windowType: 'account',
              selectedProspectId: params.row.prospectId,
              tab: 'prospect',
            });
          }, 100);
        };

        return (
          <Typography
            fontSize={14}
            fontWeight={500}
            onClick={onClick}
            noWrap
            style={{ cursor: 'pointer' }}
          >
            {params.row.contact}
          </Typography>
        );
      },
    },
    {
      field: 'to',
      headerName: 'To',
      width: 145,
      filterable: false,
      sortable: false,
    },
    {
      field: 'isBounced',
      headerName: 'Bounced',
      width: 80,
      filterable: false,
      sortable: true,
    },
    {
      field: 'isOpened',
      headerName: 'Opened',
      width: 80,
      filterable: false,
      sortable: true,
    },
    {
      field: 'isAnswered',
      headerName: 'Replied',
      width: 80,
      filterable: false,
      sortable: true,
    },
    {
      field: 'templateTitle',
      headerName: 'Template',
      width: 140,
      filterable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip
            enterDelay={400}
            enterNextDelay={400}
            title={params.row.templateTitle}
            arrow
          >
            <Typography noWrap>{params.row.templateTitle}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'emailLinks',
      headerName: 'Clicked',
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params: any) => {
        return params.row.emailLinks?.length === 1 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              paddingTop: 2,
              width: '100%',
              overflow: 'hidden',
              paddingInline: 12,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <IconLinkS className="icon-link-S" />
              <Typography
                noWrap
                fontSize={12}
                lineHeight={'16px'}
                fontWeight={500}
                color={colors.mainDark}
              >
                {params.row.emailLinks[0]?.url ?? ''}
              </Typography>
            </div>
            <Typography
              fontSize={12}
              lineHeight={'16px'}
              fontWeight={500}
              color={colors.grey11}
            >
              {params.row.emailLinks[0]?.trackCount || 0}
            </Typography>
          </div>
        ) : (params.row.emailLinks?.length ?? 0) > 0 ? (
          <CronoSelect
            defaultValue={0}
            sx={{
              width: 180,
              height: 32,
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxWidth: '500px !important',
                  maxHeight: '300px !important',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  width: 'fit-content',
                },
              },
            }}
            renderValue={(value: any) => {
              const selectedLink = params.row.emailLinks[value];
              return selectedLink ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    paddingTop: 2,
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      flex: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <IconLinkS className="icon-link-S" />
                    <Tooltip
                      title={selectedLink?.url}
                      placement="top"
                      enterDelay={800}
                      enterNextDelay={800}
                    >
                      <Typography
                        noWrap
                        fontSize={12}
                        lineHeight={'16px'}
                        fontWeight={500}
                      >
                        {selectedLink?.url ?? ''}
                      </Typography>
                    </Tooltip>
                  </div>
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    fontWeight={500}
                    color={colors.grey11}
                  >
                    {selectedLink?.trackCount || 0}
                  </Typography>
                </div>
              ) : (
                ''
              );
            }}
          >
            <CronoMenuItem
              value={-1}
              disabled
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '6px 6px 6px 16px',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Typography
                fontSize={12}
                lineHeight={'16px'}
                fontWeight={500}
                color={colors.grey11}
              >
                Link({params.row.emailLinks?.length ?? 0})
              </Typography>
              <Typography
                fontSize={12}
                lineHeight={'16px'}
                fontWeight={500}
                color={colors.grey11}
              >
                Clicked
              </Typography>
            </CronoMenuItem>
            {params.row.emailLinks?.map((link: EmailLink, index: number) => {
              return (
                <CronoMenuItem
                  value={index}
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    justifyContent: 'space-between',
                    padding: '6px 16px 6px 16px',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    fontWeight={500}
                    noWrap
                  >
                    {link.url}
                  </Typography>
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    fontWeight={500}
                    color={colors.grey11}
                  >
                    {link.trackCount}
                  </Typography>
                </CronoMenuItem>
              );
            })}
          </CronoSelect>
        ) : (
          <></>
        );
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      width: 135,
      filterable: false,
      sortable: true,
    },
    {
      field: 'view',
      headerName: 'View',
      filterable: false,
      sortable: false,
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          e.stopPropagation();
          if (handleSelectReportMessage) {
            handleSelectReportMessage({
              //From old platform, accountObject and prospectObject are not populated but this still works
              account: params.row.accountObject,
              prospect: params.row.prospectObject,
              content: params.row.content,
              sentTime: params.row.date,
              subject: null,
              type: 'email',
            });
          }
        };

        return <CronoButton onClick={onClick}>Open</CronoButton>;
      },
    },
  ];

  const getEmailRows = (data: LogEmail[]) => {
    return data.map((email, index) => ({
      id: index,
      date: moment(email.eventDatetime).local().format('DD/MM/YYYY HH:mm'),
      company: email.account?.name ?? '',
      contact: email.prospect?.name ?? '',
      to: email.to,
      isBounced: email.isBounced ? 'Yes' : 'No',
      isAnswered: email.isAnswered ? 'Yes' : 'No',
      isOpened:
        (email.trackCount ?? 0) > 0 ? 'Yes (' + email.trackCount + ')' : 'No',
      owner: email.owner ?? '',
      content: email.contentHtml,
      accountId: email.accountId,
      prospectId: email.prospectId,
      accountObject: email.account,
      prospectObject: email.prospect,
      emailLinks: email.emailLinks,
    }));
  };

  const getEmailRowsForReport = (data: LogEmail[]) => {
    return data.map((email, index) => ({
      date: moment(email.eventDatetime).local().format('DD/MM/YYYY HH:mm'),
      company: email.account?.name ?? '',
      contact: email.prospect?.name ?? '',
      to: email.to,
      isBounced: email.isBounced ? 'Yes' : 'No',
      reply: email.isAnswered ? 'Yes' : 'No',
      open:
        (email.trackCount ?? 0) > 0 ? 'Yes (' + email.trackCount + ')' : 'No',
      owner: email.owner ?? '',
      templateTitle: email.templateTitle ?? '',
    }));
  };

  const downloadReport = () => {
    downloadEmailsReport({
      ...(userId && { userId }),
      ...(since && { since }),
      ...(to && { to }),
      ...{ limit: FeConstants.downloadReportLimit, offset: 0 },
      ...(selectedAccount && { accountId: selectedAccount }),
      ...(isAnsweredEmail && { isAnsweredEmail }),
      ...(isOpenedEmail && { isOpenedEmail }),
      ...(isClickedEmail && { isClickedEmail }),
    });
  };

  useEffect(() => {
    if (emailsReport?.data && emailsReport?.data.data) {
      setDownloadComplete(false);
      setErrorMessage(null);
      setInfoMessage(null);
      if (total > FeConstants.downloadReportLimit) {
        setInfoMessage(
          `The report will be limited to the first ${FeConstants.downloadReportLimit} emails`,
        );
      }
      const emailRows = getEmailRowsForReport(emailsReport.data.data);
      if (emailRows.length === 0) {
        setErrorMessage('No data to download');
        return;
      }
      try {
        const csv = formatJsonToCSV(emailRows);

        // get filename from today's date
        const date = formatTodayDate();

        downloadCSVFile(date + ' - email report.csv', csv);
      } catch (e) {
        setErrorMessage('Error while downloading email report');
        return;
      }

      setDownloadComplete(true);
      setTimeout(() => {
        setDownloadComplete(false);
        setErrorMessage(null);
        setInfoMessage(null);
      }, 3000);
      resetEmailsReport();
    }
  }, [emailsReport, resetEmailsReport]);

  const [view, setView] = useState<string>(
    isAnsweredEmail === false
      ? 'NotReplied'
      : isAnsweredEmail === true
        ? 'Replied'
        : isOpenedEmail === false
          ? 'NotOpened'
          : isOpenedEmail === true
            ? 'Opened'
            : isClickedEmail === false
              ? 'NotClicked'
              : isClickedEmail === true
                ? 'Clicked'
                : 'All',
  );

  const handleChangeView = (value: string) => {
    if (!setIsAnsweredEmail || !setIsOpenedEmail || !setIsClickedEmail) return;
    setView(value);
    switch (value) {
      case 'All':
        setIsAnsweredEmail(null);
        setIsOpenedEmail(null);
        setIsClickedEmail(null);
        break;
      case 'Replied':
        setIsAnsweredEmail(true);
        setIsOpenedEmail(null);
        setIsClickedEmail(null);
        break;
      case 'NotReplied':
        setIsAnsweredEmail(false);
        setIsOpenedEmail(null);
        setIsClickedEmail(null);
        break;
      case 'Opened':
        setIsAnsweredEmail(null);
        setIsOpenedEmail(true);
        setIsClickedEmail(null);
        break;
      case 'NotOpened':
        setIsAnsweredEmail(null);
        setIsOpenedEmail(false);
        setIsClickedEmail(null);
        break;
      case 'Clicked':
        setIsAnsweredEmail(null);
        setIsOpenedEmail(null);
        setIsClickedEmail(true);
        break;
      case 'NotClicked':
        setIsAnsweredEmail(null);
        setIsOpenedEmail(null);
        setIsClickedEmail(false);
        break;
      default:
        return;
    }
  };

  const handleChangeSortModel = (model: GridSortModel) => {
    if (model.length === 0) {
      if (!setSortModelEmail) return;
      setSortModelEmail(null);
      return;
    }
    const field = model[0].field;
    const sort = model[0].sort;
    let newModel: CronoEmailSearchSortType | null = null;
    switch (field) {
      case 'date':
        newModel =
          sort === 'asc'
            ? CronoEmailSearchSortType.DATE
            : CronoEmailSearchSortType.DATEDESC;
        break;
      case 'company':
        newModel =
          sort === 'asc'
            ? CronoEmailSearchSortType.COMPANYNAME
            : CronoEmailSearchSortType.COMPANYNAMEDESC;
        break;
      case 'contact':
        newModel =
          sort === 'asc'
            ? CronoEmailSearchSortType.CONTACTNAME
            : CronoEmailSearchSortType.CONTACTNAMEDESC;
        break;
      case 'isBounced':
        newModel =
          sort === 'asc'
            ? CronoEmailSearchSortType.BOUNCED
            : CronoEmailSearchSortType.BOUNCEDDESC;
        break;
      case 'isOpened':
        newModel =
          sort === 'asc'
            ? CronoEmailSearchSortType.OPEN
            : CronoEmailSearchSortType.OPENDESC;
        break;
      case 'isAnswered':
        newModel =
          sort === 'asc'
            ? CronoEmailSearchSortType.REPLIED
            : CronoEmailSearchSortType.REPLIEDDESC;
        break;
      case 'owner':
        newModel =
          sort === 'asc'
            ? CronoEmailSearchSortType.OWNERFULLNAME
            : CronoEmailSearchSortType.OWNERFULLNAMEDESC;
        break;
      default:
        break;
    }
    if (!setSortModelEmail) return;
    setSortModelEmail(newModel);
  };

  const rows = useMemo(() => {
    return getEmailRows(data);
  }, [data]);

  return (
    <>
      <FlexDiv
        direction="row"
        justifyContent="space-between"
        className="table-header"
      >
        <h2 className="chart-title">Email</h2>
        {setIsAnsweredEmail && (
          <Select
            sx={{
              boxshadow: 'none',
              '.MuiOutlinedInput-notchedOutline': {
                border: 0,
              },
            }}
            className={
              isAnsweredEmail === null &&
              isOpenedEmail === null &&
              isClickedEmail === null
                ? 'table-select-placeholder'
                : 'table-select'
            }
            id="emailFeedback"
            name="emailFeedback"
            value={view}
            onChange={(e) => {
              handleChangeView(e.target.value as string);
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Replied">Replied</MenuItem>
            <MenuItem value="NotReplied">Not Replied</MenuItem>
            <MenuItem value="Opened">Opened</MenuItem>
            <MenuItem value="NotOpened">Not Opened</MenuItem>
            <MenuItem value="Clicked">Clicked</MenuItem>
            <MenuItem value="NotClicked">Not Clicked</MenuItem>
          </Select>
        )}
        <FlexDiv justifyContent="center" style={{ width: 40 }}>
          {isLoadingEmailsReport ? (
            <CircularProgress size={20} style={{ marginRight: 5 }} />
          ) : (
            <IconButton onClick={() => downloadReport()}>
              <DownloadIcon />
            </IconButton>
          )}
        </FlexDiv>
      </FlexDiv>
      {data.length === 0 ? (
        <>{renderNoChartResults('email')}</>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          rowCount={total}
          pageSizeOptions={[limit]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: limit,
                page: offset / limit,
              },
            },
          }}
          onSortModelChange={handleChangeSortModel}
          paginationMode="server"
          pagination
          onPaginationModelChange={(model: GridPaginationModel) => {
            setOffset(model.page * limit);
          }}
          sortingMode="server"
        />
      )}
    </>
  );
};

export default EmailsTable;
