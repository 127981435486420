import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

export const CronoNewsButtonWrapper = styled(IconButton)`
  margin-left: 6px;
  margin-right: 12px;
  background: ${colors.nurtureLight};
  :hover {
    background: ${colors.nurtureLightHover};
  }
`;
