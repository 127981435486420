import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { PatchSuggestionPreferences } from 'crono-fe-common/types/patchSuggestionPreferencesDTO';

export default function usePatchSuggestionPreferences() {
  const queryClient = useQueryClient();
  const patchSuggestionPreferences = (
    SuggestionPreferences: PatchSuggestionPreferences,
  ) => {
    const request: Request = {
      url: ENDPOINTS.suggestion.preferences,
      config: {
        method: 'patch',
        data: { data: SuggestionPreferences },
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    PatchSuggestionPreferences
  >(patchSuggestionPreferences, {
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries(ENDPOINTS.suggestion.preferences);
    },
  });

  return { isSuccess, isError, mutate, ...rest };
}
