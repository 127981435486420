import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const FinishOnboardingContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: -6px;
`;

type OnboardingProps = {
  progress?: number;
  completed?: boolean;
};

export const OnboardingStepsWrapper = styled.div<OnboardingProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  gap: 8px;
  padding-bottom: 24px;

  .horizontal-divider {
    display: flex;
    align-self: center;
    border-top: 1px solid ${colors.grey444};
    width: 100%;
  }

  .onboarding-header-container {
    display: flex;
    padding: 32px 0px 16px 0px;
    justify-content: space-between;
  }
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
  }

  .integration-setup-button {
    display: flex;
    gap: 6px;
    padding: 3px 7px;
    border: 1px solid ${colors.grey33};
    border-radius: 34px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  .integration-setup-button:hover {
    background-color: ${colors.grey444};
  }
  .integration-icon-button {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    padding-bottom: 1px;
  }
  .integration-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
  }
  .integration {
    width: 490px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    padding: 14px;
  }
  .completed {
    background-color: ${colors.grey6};
  }
  .onboarding-step-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    position: relative;
    background-color: ${colors.white};
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
  }
  .onboarding-step-header-wrapper {
    display: flex;
    width: 100%;
    padding: 16px 24px;
    align-items: center;
    gap: 16px;
  }
  .onboarding-step-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .completed-step {
    color: ${colors.grey2};
    text-decoration: line-through;
    text-decoration-thickness: from-font;
  }
  .onboarding-step-subtitle {
    display: flex;
    align-items: center;
    width: 300px;
  }
  .onboarding-component-container {
    margin-left: 50px;
  }
  .onboardingStepsOptions-components {
    display: flex;
    padding: 0px 24px 12px 24px;
    gap: 16px;
    justify-content: space-between;
  }
  .completed-checkmark {
    background-color: ${colors.primary} !important;
    width: 26px;
    height: 26px;
    padding: 4px;
    margin-right: 7px;
  }
  .check-instructions-link {
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${colors.primaryDark};
  }
  .check-instructions-link > svg > path {
    stroke: ${colors.primaryDark};
  }
  .check-instructions-link:hover {
    color: ${colors.darkGreenHover};
    cursor: pointer;
  }
  .check-instructions-link:hover > svg > path {
    stroke: ${colors.darkGreenHover};
  }
  .react-player__preview {
    border-radius: 8px;
  }
`;
