import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconNotOwned = ({
  color = colors.primaryDark,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        d="M8.00007 10.6673H6.32807C5.82407 10.6673 5.33874 10.858 4.96874 11.2L4.92607 11.24C4.6914 11.4573 4.50874 11.724 4.39007 12.0213L4.15674 12.6073"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.79075 8.79067C9.68742 8.46733 10.3334 7.62333 10.3334 6.614C10.3334 5.32533 9.28875 4.28 7.99942 4.28C6.99009 4.28 6.14609 4.926 5.82275 5.82333"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.63974 5.30734C1.50974 7.55668 1.87907 10.3667 3.7564 12.244C5.6344 14.122 8.44507 14.4907 10.6944 13.36"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75586 3.75666V3.75666C6.09986 1.41266 9.89986 1.41266 12.2432 3.75666C14.5865 6.09999 14.5865 9.89932 12.2439 12.2433"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3332 13.3333L2.6665 2.66666"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconNotOwned;
