import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import React, { useEffect, useState } from 'react';
import { TagCheckmarkIconWrapper } from 'crono-fe-common/components/ExternalProperty/style';
import IconXSCheckmark from 'crono-fe-common/icons/Icon-XS-CheckMark';
import {
  findExternalPropertyIcon,
  findTagColors,
} from 'crono-fe-common/utils/externalProperties';
import { colors } from 'crono-fe-common/theme';

interface IProps {
  externalProperty: ExternalProperty;
  handleChangeExternalProperty: (
    externalPropertyId: number,
    option: string,
  ) => void;
  index: number;
  index2: number;
  isSelected: boolean;
  option: string;
  showCheckmark?: boolean | null;
  className?: string;
}

const FilterExternalProperty = ({
  externalProperty,
  handleChangeExternalProperty,
  index,
  index2,
  isSelected,
  option: label,
  showCheckmark,
  className,
}: IProps) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={'external-property ' + className}
      onClick={() => {
        if (externalProperty.options) {
          handleChangeExternalProperty(
            externalProperty.id,
            externalProperty.options[index2],
          );
        }
      }}
      style={{
        background: isSelected
          ? findTagColors(index, index2).at(hover ? 2 : 0)
          : undefined,
        borderColor: isSelected
          ? findTagColors(index, index2).at(hover ? 2 : 0)
          : undefined,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {showCheckmark ? (
        <TagCheckmarkIconWrapper>
          <IconXSCheckmark className="checkmark-icon" color={colors.black} />
        </TagCheckmarkIconWrapper>
      ) : (
        findExternalPropertyIcon(index, findTagColors(index, index2).at(1))
      )}
      {label}
    </div>
  );
};

export default FilterExternalProperty;
