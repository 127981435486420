import { Request } from 'crono-fe-common/types/request';
import client from 'utils/clients/client';
import { ENDPOINTS } from '../../../config/endpoints';
import { GoogleSettings } from 'crono-fe-common/types/googleSettings';

export type GoogleVerifyIntegrationResult = {
  data: { isSuccess: boolean; data: GoogleSettings | null };
};

class GoogleService {
  private _alreadyRunningVerifyGoogleIntegrationCall: Promise<GoogleVerifyIntegrationResult> | null =
    null;

  public async verifyGoogleIntegration(): Promise<GoogleVerifyIntegrationResult> {
    if (this._alreadyRunningVerifyGoogleIntegrationCall) {
      return await this._alreadyRunningVerifyGoogleIntegrationCall;
    } else {
      this._alreadyRunningVerifyGoogleIntegrationCall =
        this._verifyGoogleIntegration();
      try {
        const verifyResult =
          await this._alreadyRunningVerifyGoogleIntegrationCall;
        return verifyResult;
      } catch (e) {
        return {
          data: {
            isSuccess: false,
            data: null,
          },
        };
      } finally {
        this._alreadyRunningVerifyGoogleIntegrationCall = null;
      }
    }
  }

  private async _verifyGoogleIntegration(): Promise<GoogleVerifyIntegrationResult> {
    const request: Request = {
      url: ENDPOINTS.google.verify,
      config: {
        method: 'get',
      },
    };

    const result = await client(request);
    return result as GoogleVerifyIntegrationResult;
  }
}

// Esporto una sola istanza del servizio, in modo che tutti gli altri file che importano
// questo file importeranno sempre la stessa istanza
export default new GoogleService();
