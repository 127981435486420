import React, { useMemo, useState } from 'react';
import {
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { TagsWrapper } from './style';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { getExternalPropertyLabels } from 'crono-fe-common/utils';
import FilterExternalProperty from 'pages/dashboard/filters/filterExternalProperty';
import ExternalPropertyFilter from 'crono-fe-common/types/externalPropertyFilter';

export const TagFilterTooltip = styled(
  ({ className, right, ...props }: TooltipProps & { right?: number }) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(({ right }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: 300,
    maxWidth: 400,
    position: 'relative',
    top: -8,
    left: right ? undefined : 140,
    right: right ? right : undefined,
    border: '1px solid #dadde9',
    borderRadius: 16,
    padding: '12px 0px 12px 16px',
    zIndex: 150000,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

interface IProps {
  externalProperties: ExternalProperty[];
  filterExternalProperties: ExternalPropertyFilter[];
  handleChangeExternalProperty: (
    externalPropertyId: number,
    option: string,
  ) => void;
}

const TagContainerComponent = ({
  externalProperties,
  handleChangeExternalProperty,
  filterExternalProperties,
}: IProps) => {
  const groupedTags = useMemo(() => {
    return externalProperties.reduce(
      (acc, tag) => {
        if (tag.tableType === 'Account') {
          acc.accounts.push(tag);
        } else if (tag.tableType === 'Prospect') {
          acc.prospects.push(tag);
        } else if (tag.tableType === 'Lead') {
          acc.leads.push(tag);
        }
        return acc;
      },
      {
        accounts: [] as ExternalProperty[],
        prospects: [] as ExternalProperty[],
        leads: [] as ExternalProperty[],
      },
    );
  }, [externalProperties]);

  const renderTags = (tags: ExternalProperty[]) =>
    tags.map((externalProperty, index) => {
      return (
        <div key={index} className="external-property-container">
          <Typography
            fontSize={12}
            fontWeight={500}
            lineHeight={'16px'}
            color={colors.grey11}
          >
            {externalProperty.publicName || externalProperty.externalName}
          </Typography>
          <div className="external-values-container">
            {getExternalPropertyLabels(externalProperty).map(
              (label, index2) => {
                const isSelected = externalProperty.options
                  ? filterExternalProperties
                      ?.filter(
                        (ep) => ep.externalPropertyId === externalProperty.id,
                      )
                      .at(0)
                      ?.values?.includes(externalProperty.options[index2])
                  : false;

                return (
                  <FilterExternalProperty
                    key={label}
                    className="template-tags-filters"
                    option={label}
                    isSelected={isSelected ?? false}
                    showCheckmark={isSelected ?? false}
                    externalProperty={externalProperty}
                    index={index}
                    index2={index2}
                    handleChangeExternalProperty={handleChangeExternalProperty}
                  />
                );
              },
            )}
          </div>
        </div>
      );
    });
  return (
    <TagsWrapper>
      {renderTags(groupedTags.accounts)}
      {renderTags(groupedTags.prospects)}
      {renderTags(groupedTags.leads)}
    </TagsWrapper>
  );
};

export default TagContainerComponent;
