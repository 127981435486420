import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const MultipleTagWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  .remaining-tags-button {
    height: 24px;
    min-width: 24px;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.grey4};
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }
`;
