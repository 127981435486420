import { useWindow } from 'context/sideTab';
import { parseTaskType } from 'context/sideTab/types';
import useSearchNextTask from 'hooks/services/task/useSearchNextTask';
import { useSelectedAccount } from 'pages/home/context/account';
import {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { useHideTaskConstruction } from '../hideTaskConstructionContext';
import { useCustomerly } from 'react-live-chat-customerly';
import { TaskSortType } from 'crono-fe-common/types/enums/taskSortType';

interface ExecuteMultipleTasksContextType {
  currentTaskBeingExecuted: TaskTodo | null;
  cancelMultipleExecution: () => void;
  startExecuting: (
    id: number | null,
    taskType: TaskTodoType | null,
    crmTasks: boolean,
    sortBy: TaskSortType,
    hasAutomationError: boolean | null,
    strategyId: number | null,
  ) => void;
  setFilters: (taskType: TaskTodoType | null, crmTasks: boolean) => void;
  executeSingleTask: (task: TaskTodo) => void;
}

export const ExecuteMultipleTasksContext = createContext<
  ExecuteMultipleTasksContextType | undefined
>(undefined);

ExecuteMultipleTasksContext.displayName = 'ExecuteMultipleTasksContext';

const ExecuteMultipleTasksProvider: FC<{ children: any }> = ({ children }) => {
  const { openWindow, windowContent } = useWindow();
  const { setSelectedAccountId } = useSelectedAccount();

  const [taskBeingExecuted, setTaskBeingExecuted] = useState<TaskTodo | null>(
    null,
  );

  const keepTemplateOpen = useRef(false);

  const [startTaskId, setStartTaskId] = useState<number | null | undefined>(
    undefined,
  );
  const [taskType, setTaskType] = useState<TaskTodoType | null | undefined>(
    undefined,
  );
  const [crmTasks, setCrmTasks] = useState<boolean | undefined>(undefined);
  const [hasAutomationError, setHasAutomationError] = useState<
    boolean | null | undefined
  >(undefined);
  const [sortTasksType, setSortsTasksType] = useState<TaskSortType | undefined>(
    undefined,
  );
  const [strategyId, setStrategyId] = useState<number | undefined | null>(
    undefined,
  );

  const { event } = useCustomerly();

  const { setHideProgressTask } = useHideTaskConstruction();
  const performTask = (task: TaskTodo, executeOnSuccess?: () => void) => {
    //To avoid multiple calls on the same task, may happen due useEffect being called when refocus on the crono window
    if (taskBeingExecuted?.id === task.id) return;
    setHideProgressTask(true);
    const tabValue = parseTaskType(task.type);
    setSelectedAccountId(task.account.objectId);
    setTaskBeingExecuted(task);
    setTimeout(() => {
      openWindow({
        windowType: 'account',
        task: task,
        tab: tabValue,
        modalVisible: tabValue,
        selectedProspectId: task.prospect.objectId ?? undefined,
        //Pass keepTemplateOpen.current instead of false if we want to keep the template tab opened between different tasks
        defaultOpenTemplateTab: false,
        callOnSuccess: (
          keepTemplatesOpened = false,
          executeTheSame = false,
        ) => {
          //To hide all the changes to the sideTab that are performed during the change of the task
          setHideProgressTask(true);

          //Customerly event
          event('task_execution');

          keepTemplateOpen.current = keepTemplatesOpened;
          //Clear to correctly perform the flow
          setTaskBeingExecuted(null);
          if (executeOnSuccess) executeOnSuccess();
          if (executeTheSame) {
            //Refetch with the same task
            getNextTaskToExecute();
          } else if (startTaskId !== undefined && taskType !== undefined) {
            //Get the next one if I have the data, otherwise we are here for the single execution one
            //The current behavior is that, once I got one tasks, I will receive the id of the next one to call
            setStartTaskId(taskToExecute?.data?.nextId ?? null);
          }
        },
        forceReload: Math.random() * 10000,
      });
    }, 500);
  };

  const cancelMultipleExecution = () => {
    keepTemplateOpen.current = false;
    setTaskBeingExecuted(null);
    setStartTaskId(undefined);
    setTaskType(undefined);
    setCrmTasks(undefined);
    setHasAutomationError(undefined);
    setSortsTasksType(undefined);
    setStrategyId(undefined);
  };

  const closeSideTab = () => {
    setSelectedAccountId(null);
    setTimeout(() => openWindow(null), 200);
  };

  //This is done to clear the tasks when the modal is closed/changed with a manual action of the same type or different
  useEffect(() => {
    if (
      taskBeingExecuted &&
      (windowContent?.modalVisible !== taskBeingExecuted?.type.toLowerCase() ||
        windowContent?.task?.id !== taskBeingExecuted?.id)
    ) {
      cancelMultipleExecution();
    }
  }, [windowContent?.modalVisible, windowContent?.task?.id]);

  const {
    data: taskToExecute,
    isLoading: isLoadingTask,
    isRefetching: isRefetchingTask,
    refetch: getNextTaskToExecute,
  } = useSearchNextTask(
    {
      id: startTaskId ?? null,
      type: taskType,
      fromCrm: crmTasks ? false : null,
      hasAutomationError: hasAutomationError,
      strategyId: strategyId,
      sortBy: sortTasksType ?? 'CreatedDate',
    },
    startTaskId !== undefined &&
      taskType !== undefined &&
      crmTasks !== undefined &&
      sortTasksType !== undefined &&
      hasAutomationError !== undefined &&
      strategyId !== undefined,
  );

  useEffect(() => {
    if (!taskToExecute || isLoadingTask || isRefetchingTask) return;
    if (taskToExecute?.data?.data === null) {
      //No more task to execute
      cancelMultipleExecution();
      closeSideTab();
    } else if (
      taskToExecute?.data?.data &&
      //To avoid multiple calls on the same task, may happen due useEffect being called when refocus on the crono window
      (!taskBeingExecuted?.id ||
        taskToExecute?.data?.data?.id !== taskBeingExecuted?.id)
    ) {
      performTask(taskToExecute?.data?.data);
    }
  }, [taskToExecute, isLoadingTask, isRefetchingTask]);

  //The id passed is the one of the task that has to be started. If null the first task will be executed
  const startExecuting = (
    id: number | null,
    taskType: TaskTodoType | null,
    crmTasks: boolean,
    order: TaskSortType,
    hasAutomationError: boolean | null,
    strategyId: number | null,
  ) => {
    setStartTaskId(id);
    setTaskType(taskType);
    setCrmTasks(crmTasks);
    setHasAutomationError(hasAutomationError);
    setSortsTasksType(order);
    setStrategyId(strategyId);
  };

  const setFilters = (taskType: TaskTodoType | null, crmTasks: boolean) => {
    setTaskType(taskType);
    setCrmTasks(crmTasks);
  };

  const handleCallOnSuccess = () => {
    cancelMultipleExecution();
    closeSideTab();
  };

  const executeSingleTask = (task: TaskTodo) => {
    performTask(task, handleCallOnSuccess);
  };

  return (
    <ExecuteMultipleTasksContext.Provider
      value={{
        currentTaskBeingExecuted: taskBeingExecuted,
        cancelMultipleExecution,
        startExecuting,
        executeSingleTask,
        setFilters,
      }}
    >
      {children}
    </ExecuteMultipleTasksContext.Provider>
  );
};

export default ExecuteMultipleTasksProvider;

export function useExecuteMultipleTasks() {
  const context = useContext(ExecuteMultipleTasksContext);
  if (context === undefined) {
    throw new Error(
      `useExecuteMultipleTasks must be used within a ExecuteMultipleTasksProvider`,
    );
  }

  return context;
}
