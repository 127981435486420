import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { Prospect } from 'crono-fe-common/types/prospect';
import { OpportunityViewResponseDTO } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';
import { OpportunitySearch } from 'crono-fe-common/types/DTO/opportunitySearch';

export default function useSearchOpportunity(
  searchParameters: OpportunitySearch,
  enabled = true,
) {
  const request: Request = {
    url: `${ENDPOINTS.opportunity.search}`,
    config: {
      method: 'post',
      data: { data: searchParameters },
    },
  };
  const params = JSON.stringify(searchParameters);
  const requestConfig = {
    queryKey: [ENDPOINTS.opportunity.search, params],

    queryFn: () => client(request),
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled,
  };

  return useQuery<ResponsePaginated<OpportunityViewResponseDTO>, CronoError>(
    requestConfig,
  );
}
