import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconFilledDownArrow = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87995 4.19946L5.00052 6.32003L7.12109 4.19946"
        fill={color}
      />
      <path
        d="M2.87995 4.19946L5.00052 6.32003L7.12109 4.19946L2.87995 4.19946Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconFilledDownArrow;
