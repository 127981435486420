import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SuggestionCardWrapper = styled.div`
  display: flex;

  .top-header-suggestion-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 4px;
  }
  .suggestion-avatar {
    font-size: 16px;
    margin-right: 10px;
    margin-top: 4px;
  }
  .suggestion-avatar {
    background: ${colors.nurtureBlue};
  }
  .search-avatar {
    background: ${colors.callScheduledLight};
    height: 30px;
    width: 30px;
    margin-right: 10px;
    margin-top: 4px;
  }
  .search-avatar-icon {
    height: 18px;
    width: 18px;
  }
  .action-icon {
    width: 34px;
    height: 34px;
    margin-right: 8px;
    border-radius: 999px;
    padding: 6px;
    cursor: pointer;
  }
  .email-icon {
    background: ${colors.inactiveLight};
  }
  .call-icon {
    background: ${colors.callScheduledLight};
  }
  .linkedin-icon {
    background: ${colors.blueLight};
  }
  .find-information-button {
    padding-left: 0px;
    width: 200px;
    align-items: center;
    justify-content: space-between;
    text-transform: unset !important;
  }
  .name-link {
    cursor: pointer;
  }
  .text-field-suggestion {
    width: 200px;
  }
  .save-button {
    height: 30px;
    margin-right: 20px;
  }
  .search-linkedin-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: ${colors.black}55;
    z-index: 1000;
  }
  .badge-suggestion {
    height: fit-content;
  }
  .skip-suggestion-x {
    cursor: pointer;
    opacity: 0;
    height: 0px;
    position: relative;
    bottom: 4px;
  }
  &:hover .skip-suggestion-x {
    opacity: 1;
  }
  .icon-suggestion-badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20px;
    font-size: 0.8rem;
    border-radius: 16px;
    padding: 5px;
    margin: 0px 2px;
    cursor: pointer;
  }
  .clear {
    background-color: ${colors.backgroundCloseRed};
  }
  .save {
    background-color: ${colors.backgroundSaveGreen};
  }
`;
