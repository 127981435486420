import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ResumeSequenceWrapper = styled.div`
  width: 350px;
  font-size: 14px;
  padding: 8px;
  overflow: hidden;
  .base-button {
    color: ${colors.grey11};
    text-transform: unset !important;
    font-size: 14px;
    height: 40px !important;
    border-radius: 999px;
    padding-inline: 12px;
  }
  .strategy-to-resume {
    border-left: 4px solid ${colors.grey4};
    border-radius: 4px;
    padding-left: 6px;
    margin-block: 12px;
  }
`;
