import { useAuth } from 'context/auth';

import SalesforceLogo from 'assets/images/Icon_Salesforce_Webhooks.svg';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';

interface IProps {
  completed: boolean;
}

const AdminSalesforce = ({ completed }: IProps) => {
  const { user } = useAuth();

  const cronoSalesforceWebhooks = () => {
    window.open(
      `${process.env.REACT_APP_BE_URL}/api/webhookcreator/?token=${user?.token.token}`,
      '_blank',
    );
  };

  return (
    <div className="crm-connect-card">
      <div className="connect-card">
        <div className="providers">
          <div className="provider-box">
            <div className="icon-logo-container">
              <img
                src={SalesforceLogo}
                alt={'salesforce'}
                className={`connection-image salesforce-admin`}
              />
            </div>
            <div className={`connection-label`}>
              <Typography
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'24px'}
                color={colors.black}
              >
                Salesforce Workspace (Admin Only)
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                lineHeight={'18px'}
                color={completed ? colors.darkGreen : colors.inactiveHover}
              >
                {completed ? 'Created' : 'Not created'}
              </Typography>
            </div>
            <div
              className={`icons-buttons-container ${
                completed ? 'connected' : 'not-connected'
              }`}
            >
              {completed ? (
                <div className="connection-status">
                  <Typography fontSize={'14px'} fontWeight={500}>
                    Done
                  </Typography>
                  <CheckMarkIcon color={colors.callScheduled} />
                </div>
              ) : (
                <div
                  className="connection-status pointer"
                  onClick={() => cronoSalesforceWebhooks()}
                >
                  <Typography fontSize={'14px'} fontWeight={500}>
                    Set up
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSalesforce;
