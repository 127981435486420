import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SearchComponentWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: hidden;
  /* border: 1px solid #ebebeb;
  border-radius: 16px 16px 16px 16px; */
  display: flex;
  gap: 8px;
  .search-component-filters-column {
    width: 338px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .search-component-open-filters {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${colors.grey444};
    &:hover {
      > svg > path {
        stroke: ${colors.primaryDark};
      }
    }
    > svg {
      transform: rotate(180deg);
    }
  }
  .search-component-filters-container {
    width: 100%;
    background: ${colors.white};
    flex: 1;
    border-radius: 0px 0px 16px 16px;
    border: 1px solid ${colors.grey44};
    overflow: hidden;
  }
  .remove-after-modifiers-last {
    li.selected:after {
      display: none;
    }
  }
  .remove-before-modifiers-first {
    li.selected:before {
      display: none;
    }
  }
  .search-component-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 16px 24px;
    row-gap: 16px;
  }
  .search-component-body {
    display: flex;
    width: 100%;
    flex: 1;
    overflow: auto;
    background: ${colors.grey6};
    gap: 8px;
  }
  .controls-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .dashboard-tabs-container {
    background: ${colors.primaryLight};
    padding: 0;
    width: fit-content;
    border-radius: 8px;
    &:hover {
      background: ${colors.primaryLightHover};
    }
  }
  .importing-container {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
  }
  .column,
  .first-column {
    background: white;
    color: ${colors.grey11} !important;
  }
  .first-column {
    z-index: 11;
    color: ${colors.grey11} !important;
  }
  .icon-copy {
    min-width: 24px;
    cursor: pointer;
  }
  .importing-badge {
    background: ${colors.blueLight};
    border-radius: 20px;
    color: ${colors.intenseBlue};
    height: 40px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0px 12px;

    &:hover {
      background: ${colors.blueLightHover};
    }
  }
  .importing-tooltip {
    background: ${colors.white};
    z-index: 9999;
    position: absolute;
    bottom: 56px;
    width: 392px;
    border-radius: 8px;
    max-height: 500px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border: 1px solid ${colors.grey444};
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  .importing-tooltip-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stop-button {
      position: relative;
      cursor: pointer;
      padding: 7px 10px;
      border: 1px solid ${colors.grey444};
      width: 80px;
      border-radius: 16px;

      &::after {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        border-radius: 0.75px;
        background: ${colors.black};
      }

      &:hover {
        background: ${colors.grey33};
      }
    }
  }
  .importing-tooltip-body {
    overflow-y: scroll;
  }
  .importing-tooltip-row {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid ${colors.grey444};

    display: grid;
    grid-template-columns: 50% 25% 25%;

    .col-1 {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  .importing-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
  }
  .importing-loader {
    transform: scale3d(0.3, 0.3, 1);
  }
  .search-filters-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
  }
  .expand-icon-wrapper {
    align-self: flex-start;
    margin-top: 12px;
  }
  .single-line-find-new-filter {
    display: flex;
    align-items: center;
    padding: 8px 12px 8px 24px;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid ${colors.grey4};
    .inner-text-section {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      .inner-text-with-tooltip {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
  .filter-box {
    border-block: 1px solid ${colors.grey44};
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .first-filter {
    border-top: none;
  }
  .filters-accordions-container {
    height: calc(100% - 60px);
    overflow: auto;
  }
  .clear-filters-button {
    color: ${colors.grey11};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: unset;
  }
  .search-results-section {
    flex: 1;
    overflow: hidden;
    background: ${colors.white};
    border-radius: 16px;
    border: 1px solid ${colors.grey444};
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .load-more-results-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    position: sticky;
    left: 0;
    cursor: pointer;
    .inner-button {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 4px;
      border-radius: 8px;
      &:hover {
        background: ${colors.primaryLight};
      }
    }
  }
  .add-new-auto-complete {
    width: 100%;
  }
  .inner-toggle-button {
    cursor: pointer;
    color: ${colors.darkGreen};
    height: 100%;
    min-width: fit-content;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .inner-toggle-button:hover {
    color: ${colors.darkGreenHover};
  }
  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding-inline: 6px;

    & .MuiAutocomplete-listbox {
      max-height: 26vh;
    }
  }
  .autocomplete-paper-auto-height {
    & .MuiAutocomplete-listbox {
      max-height: unset;
      height: auto;
    }
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    // background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:checked {
    // background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-selected-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    gap: 8px;
    position: relative;
    .MuiButtonBase-root {
      padding: 0px;
    }
  }
  .autocomplete-selected-item-with-image {
    height: 42px;
    min-height: 42px;
    > #autocomplete-select-checkbox {
      margin-left: 8px;
      margin-right: 4px;
    }
    padding-left: 5px;
    .autocomplete-select-item-image {
      background-size: cover;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      z-index: 1;
    }
  }
  .autocomplete-selected-item:hover {
    background-color: ${colors.sequenceCardHover};
    .autocomplete-select-item-image {
      z-index: 0;
    }
  }
  .autocomplete-selected-item-checked {
    .autocomplete-select-item-image {
      z-index: 0;
    }
  }
  .industry-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .industry-size-container {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  .industry-checkbox {
    padding: 0px;
    padding-right: 8px;
  }
  .redirect-icon-contact-row {
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
    cursor: pointer;

    &:hover > path {
      stroke: ${colors.black};
    }
  }
  .search-results-header {
    padding: 12px 30px;
    display: flex;
    align-items: center;
    column-gap: 16px;
    //To prevent resizing of the header due to the absence of the recently viewed button
    height: 62px;
  }
  .no-results {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    text-align: center;
    gap: 8px;
    padding: 16px;
    overflow: hidden;
  }

  .no-results-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
    position: absolute;
    top: 120px;
  }
  .recently-viewed-button {
    text-transform: unset !important;
    border-radius: 8px;
    background: ${colors.grey444};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  .suggestion-search-button {
    text-transform: unset !important;
    color: ${colors.grey2};
  }
  .apply-filters-disabled {
    background: ${colors.grey11};
  }
  .company-list,
  .contact-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding: 16px;
    padding-top: 0px;
    row-gap: 8px;
  }
  .company-card,
  .contact-card {
    padding: 14px 24px;
    width: 100%;
    background: ${colors.white};
    border-radius: 16px;
    display: flex;
    column-gap: 24px;
    align-items: center;
    justify-content: space-between;
  }
  .email-result {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon-email-result {
      min-width: 16px;
      min-height: 16px;
      width: 16px;
      height: 16px;
    }
  }
  .company-logo,
  .contact-logo {
    cursor: pointer;
  }

  .contact-name {
    cursor: pointer;
  }
  .company-name {
    width: 25%;
    cursor: pointer;
  }
  .linkedin-icon {
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    //Coloring the icon is difficult :)
    filter: grayscale(1);
  }
  .linkedin-icon:hover {
    background: ${colors.blueLight};
    filter: grayscale(0);
  }
  .company-information,
  .contact-information {
    width: 20%;
  }
  .select-similar-container {
    background: ${colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    border-radius: 16px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  .close-button {
    align-self: flex-end;
    cursor: pointer;
    z-index: 1001;
    height: 24px;
    width: 24px;
  }
  .personas-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-top: 4px;
  }
  .persona-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 8px;
    svg {
      fill: ${colors.grey11};
      &:hover {
        fill: ${colors.black};
      }
    }
  }
  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    width: 100%;
    padding: 16px;
    position: relative;
    bottom: 0;
  }
  .left-side-container {
    height: calc(100% - 84px);
    background-color: ${colors.white};
  }
  .apply-filter-container {
    display: flex;
    position: sticky;
    width: 100%;
    padding: 12px;
    bottom: 0;
    background-color: ${colors.white};
  }
  .similar-found-badge {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    & .icon-similar-contat {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }
  }

  .autocomplete-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;

    .autocomplete-counter {
      margin-right: 8px;
      border-radius: 50%;
      background: ${colors.selectedOptionGrey};

      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;

      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .edit-columns-btn {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .edit-columns-dropdown {
    position: absolute;
    z-index: 10000;
    top: 66px;
    right: 22px;
    max-height: calc(100% - 78px);
    display: flex;
    overflow: hidden;
  }
`;

export const SequenceSelectorWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;

  .remove-text-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 35001;
  }
`;

export const AutocompleteMultipleOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  height: 32px;
  width: 100%;
  background: ${colors.lightBlue};

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .remove-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      fill: ${colors.grey11};
    }

    &:hover {
      svg {
        fill: ${colors.black};
      }
    }
  }
`;

export const LoadingSpinnerCircles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 34px;
  background-color: ${colors.primaryLight};
  width: 125px;
  min-width: 125px;
  height: 32px;

  .loader {
    margin-left: 4px;
    color: ${colors.primaryDark};
    font-size: 3px;
  }
`;
