import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { TemplateUsage } from 'crono-fe-common/types/templateUsage';

export default function useGetTemplateUsage(templateId: number) {
  const queryParams = templateId ? `?TemplateId=${templateId}` : '';
  const request: Request = {
    url: `${ENDPOINTS.template.usage}${queryParams}`,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.template.usage],
    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<Response<TemplateUsage>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
