import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const RemoveSmallIcon = ({ color = colors.black, className }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.33398 5.32793H11.666"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.142 5.32779V11.0887C11.142 11.6674 10.6733 12.1361 10.0946 12.1361H5.90485C5.32615 12.1361 4.85742 11.6674 4.85742 11.0887V5.32779"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57101 3.62578H6.42871"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95229 7.42252V10.0411"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0475 7.42252V10.0411"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemoveSmallIcon;
