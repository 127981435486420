import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { IntegrationsOnboarding } from 'crono-fe-common/types/DTO/integrationsOnboarding';

export default function useGetUserOnboardingIntegrations(enabled = true) {
  const request: Request = {
    url: ENDPOINTS.user.onboarding.integrations,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.user.onboarding.integrations],
    queryFn: () => client(request),
    enabled,
  };

  const { data, ...rest } = useQuery<
    Response<IntegrationsOnboarding | null>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
