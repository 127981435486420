import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const PasswordIcon = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21H7C5.895 21 5 20.105 5 19V11C5 9.895 5.895 9 7 9H17C18.105 9 19 9.895 19 11V19C19 20.105 18.105 21 17 21Z"
        stroke={color}
        strokeWidth="1.4655"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9V7C16 4.791 14.209 3 12 3V3C9.791 3 8 4.791 8 7V9"
        stroke={color}
        strokeWidth="1.4031"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.998 14.5C11.722 14.5 11.498 14.724 11.5 15C11.5 15.276 11.724 15.5 12 15.5C12.276 15.5 12.5 15.276 12.5 15C12.5 14.724 12.276 14.5 11.998 14.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PasswordIcon;
