import React, { useMemo } from 'react';
import {
  DateRangePicker as LibraryPicker,
  RangeKeyDict,
} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRangePickerWrapper } from './style';

interface IProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  handleChangeRange: (item: RangeKeyDict) => void;
  minDate?: Date;
  format?: string;
}

const DateRangePicker = ({
  startDate,
  endDate,
  handleChangeRange,
  minDate,
  format,
}: IProps) => {
  const range = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    };
  }, [startDate, endDate]);

  return (
    <DateRangePickerWrapper>
      <LibraryPicker
        dateDisplayFormat={format}
        minDate={minDate}
        ranges={[range]}
        onChange={handleChangeRange}
        showDateDisplay={false}
        staticRanges={[]}
        inputRanges={[]}
        classNames={{
          definedRangesWrapper: 'rdrDefinedRangesWrapper',
          inRange: 'rdrDaySelected',
          startEdge: 'rdrDaySelected',
          endEdge: 'rdrDaySelected',
          dayInPreview: 'rdrDayInPreview',
          dayEndPreview: 'rdrDayInPreview',
          dayStartPreview: 'rdrDayInPreview',
        }}
      />
    </DateRangePickerWrapper>
  );
};

export default DateRangePicker;
