import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

export const TableFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 8px;
  margin-left: 72px;
  .selected-button {
    height: 32px;
    background: ${colors.grey6};
    color: ${colors.black};
    text-transform: unset;
    border-radius: 8px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    :hover {
      background: ${colors.grey5};
    }
  }
  .sequence-action-button {
    height: 28px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    text-transform: unset;
    padding-inline: 8px;
    padding-block: 4px;
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
  }
  .sequence-action-icon {
    width: 16px;
    height: 16px;
  }
  .divider {
    height: 32px;
    border-right: 1px solid ${colors.grey444};
    margin-inline: 6px;
  }
  .tag-arrow {
    margin-right: -6px !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px;
  }
  .null-value-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
  }
  .apply-button {
    height: 32px;
    font-size: 14px;
    line-height: 18px !important;
    font-weight: 500;
  }
  .remove-icon-owner {
    margin-left: 8px;
    margin-right: -8px;
    min-width: 16px;
  }
  .strategy-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
`;

export const StopStrategyButton = styled.div``;

export const GreyOwnerBadgeWrapper = styled(Button)`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 999px;
  text-transform: unset;
  padding-inline: 12px;
  padding-block: 8px;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  background: ${colors.grey55};
  .sequence-action-icon {
    width: 16px;
    height: 16px;
  }
`;

export const StrategyPickerBottomWrapper = styled.div`
  cursor: pointer;
  .strategy-picker-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
  .strategy-picker-disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: default;
  }
  .strategy-picker {
    &__add-only {
      display: flex;
      align-items: center;
      border-radius: 99px;
      gap: 8px;
      background-color: ${colors.primaryLight};
      padding: 8px;
      :hover {
        background-color: ${colors.primaryLightHover};
      }
      .add-strategy-plus {
        background: ${colors.primary};
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }
    }
    &__add-only-selected {
      max-width: 200px;
      overflow: hidden;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 999px;
      padding-inline: 12px;
      padding-block: 8px;
      font-weight: 400;
      line-height: 16px;
      font-size: 12px;
      background: ${colors.grey6};
    }
    &__border-style {
      display: flex;
      align-items: center;
      border-radius: 99px;
      gap: 8px;
      border: 1px solid ${colors.grey444};
      padding: 3px 3px 3px 8px;
      .button-strategy-picker-pause,
      .button-strategy-picker-add,
      .button-strategy-picker-add-disabled {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .action-icon {
          width: 16px;
          height: 16px;
          min-width: 16px;
        }
      }
      .button-strategy-picker-pause {
        background: ${colors.grey55};
        :hover {
          background: ${colors.grey5};
        }
      }
      .button-strategy-picker-add {
        background: ${colors.primaryLight};
        :hover {
          background: ${colors.primaryLightHover};
        }
      }
      .button-strategy-picker-add-disabled {
        cursor: default;
        background: ${colors.grey6};
      }
    }
  }
`;

export const SequenceActionTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;

  .sequence-action-tooltip-button {
    text-transform: unset;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    border-radius: 8px;
    color: ${colors.black};
    &:hover {
      color: ${colors.primary};
    }
  }
  .selected-user {
    color: ${colors.primary};
    background: ${colors.primaryLight};
  }
  .div-stop-strategy-rectangle,
  .div-add-strategy-plus,
  .div-resume-strategy-triangle {
    width: 24px;
    height: 24px;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .div-stop-strategy-rectangle {
    background: ${colors.orangeLight};
    .stop-strategy-rectangle {
    }
  }
  .div-add-strategy-plus {
    background: ${colors.primaryLight};
    .add-strategy-plus {
    }
  }
  .div-resume-strategy-triangle {
    background: ${colors.callScheduledLight};
    .resume-strategy-triangle {
    }
  }
`;
