import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const StrategyLibraryModalWrapper = styled.div`
  .strategy-library {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 930px;
    height: 670px;
    padding: 48px 0 42px 48px;
    background-color: ${colors.white};
    border-radius: 16px;
    overflow: hidden;
    box-sizing: border-box;

    &__cross {
      position: absolute;
      top: 24px;
      right: 0;
    }

    &__title {
      margin-bottom: 16px;
    }

    &__search {
      display: flex;
      align-items: center;
      height: 26px;
      width: 345px;

      &-icon {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    &__list {
      &-container {
        height: 406px;
        margin: 24px 0 18px;
        overflow-y: auto;
      }

      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 406px;
      height: 122px;
      padding: 16px;
      border-radius: 16px;
      border: 1px solid ${colors.grey33};
      transition:
        border-color 300ms,
        background-color 300ms;

      &:hover {
        background-color: ${colors.sequenceCardHover};
      }

      &--selected {
        border-color: ${colors.darkGreen};
        background-color: ${colors.primaryLight};

        &:hover {
          background-color: ${colors.primaryLight};
        }
      }

      &-info {
        display: flex;
        gap: 8px;
      }

      &-divider {
        width: 1px;
        height: 16px;
        background-color: ${colors.grey444};
      }

      &-icons {
      }
    }

    &__buttons {
      align-self: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .remove-text-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
