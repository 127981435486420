import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinIconInvoice = ({
  color = colors.black,
  className,
  onClick,
  style,
  viewBox = '0 0 20 20',
}: IProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1286 8.83295H9.08595V16H11.286V12.5279C11.286 11.3646 11.7177 10.6249 12.7567 10.6249C13.3507 10.6249 13.6587 11.0565 13.7608 11.8422C14.2108 11.2114 14.9486 10.8 15.7823 10.8C15.8278 10.8 15.8729 10.8012 15.9177 10.8036C15.7028 9.45864 15.0185 8.73945 13.3111 8.73945C12.1775 8.73945 11.4229 9.26525 11.1286 9.85045V8.83295ZM7.5135 8.73945V15.9065H5.3135V8.73945H7.5135ZM7.72106 6.93962C7.79216 6.76948 7.82873 6.5869 7.82865 6.4025C7.82865 6.03015 7.6796 5.67375 7.4145 5.41085C7.14885 5.14795 6.78915 5 6.41405 5C6.03963 4.99984 5.68029 5.14749 5.41415 5.41085C5.1485 5.67375 5 6.03015 5 6.4025C5.00032 6.58687 5.03702 6.76937 5.10799 6.93954C5.17896 7.1097 5.28282 7.26419 5.4136 7.39415C5.67997 7.65716 6.03916 7.80475 6.4135 7.805C6.7885 7.80591 7.14852 7.65794 7.4145 7.3936C7.54576 7.26408 7.64997 7.10977 7.72106 6.93962Z"
        fill={color}
      />
      <path
        id="Path"
        d="M18.5621 15.3965C18.5621 16.9323 17.3169 18.1775 15.7811 18.1775V18.1775C14.2453 18.1775 13 16.9323 13 15.3965"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8008 15.4207C14.8008 15.9625 15.2405 16.4023 15.7823 16.4023C16.3241 16.4023 16.7639 15.9625 16.7639 15.4207V13.3331C16.7639 12.7913 16.3241 12.3516 15.7823 12.3516C15.2405 12.3516 14.8008 12.7913 14.8008 13.3331V15.4207Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7823 18.2974V19.1153"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinIconInvoice;
