import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const FilterModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  max-height: 90%;
  background: ${colors.white};
  padding: 18px;
  border-radius: 16px;

  overflow: hidden;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .close-button {
    cursor: pointer;
  }
  .add-main-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .modal-header {
    display: flex;
    flex-direction: column;
    padding: 8px 14px 0px 38px;
  }
  .search-linkedin-icon {
    cursor: pointer;
  }
  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding-inline: 6px;
    font-family: 'Poppins', sans-serif;
  }
  .popper-style {
    transform: translate(56px, 178px) !important;
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    color: ${colors.primary};
  }
  .manual-task-settings {
    display: flex;
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
  }
  .type-icon,
  .selected-type-icon {
    width: 34px;
    height: 34px;
    margin-right: 8px;
    border-radius: 999px;
    border: 2px solid ${colors.grey4};
    padding: 4px;
    cursor: pointer;
  }
  .type-icon:hover {
    background: ${colors.grey4};
  }
  .selected-type-icon {
    background: ${colors.grey3};
  }
  .selected-type-icon:hover {
    opacity: 0.7;
  }
  .check-badge-icon {
    width: 15px;
    height: 15px;
  }
  .divider {
    border: 1px solid ${colors.grey4};
  }
  .task-component-button {
    cursor: pointer;
    margin-inline: 16px 0px;
  }
  .task-component-button-disabled {
    cursor: not-allowed;
    margin-inline: 16px 0px;
  }
  .information-card-manual-task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .account-avatar {
    width: 30px;
    height: 30px;
  }
  .manual-task-remove-icon {
    cursor: pointer;
    justify-self: flex-end;
  }
  .select-date-time-button {
    cursor: pointer;
    display: flex;
    text-transform: unset;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${colors.grey11};
    padding-inline: 4px;
    min-width: 100px;
  }
`;
export const FilterFormWrapper = styled.div`
  .form-add-container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 32px 38px;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .MuiAutocomplete-popper {
    transform: translate(56px, 20px) !important;
  }
  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding-inline: 6px;
    font-family: 'Poppins', sans-serif;
    max-height: 120px;
    & .MuiAutocomplete-listbox {
      max-height: 120px;
    }
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    color: ${colors.primary};
  }
  .number-of-employees-container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
  .number-of-employees-add {
  }
  .number-of-employees-add,
  .number-of-employees-add-selected {
    width: 100px;
    padding: 8px 8px 8px 16px;
    border: 1px solid ${colors.grey3};
    border-radius: 8px;
    cursor: pointer;
  }
  .number-of-employees-add-selected {
    background: ${colors.grey4};
  }
  .info-icon {
    min-width: 32px;
    min-height: 32px;
    padding: 4px;
    border-radius: 999px;
    border: 2px solid ${colors.grey4};
    margin-right: 8px;
  }
  .tags-container {
    display: flex;
    column-gap: 12px;
  }
  .information-editable-container {
    display: flex;
    align-items: center;
    margin: 3px 0px;
  }
  .external-properties-tag {
    max-width: 50%;
    min-width: 80px;
  }
  .information-label {
    width: 100%;
    color: ${colors.grey11};
    margin-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .field-input {
    max-width: 100%;
    border-radius: 8px;
    padding: 4px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-input {
      padding: 0px;
    }
    & .MuiSelect-select {
      padding: 0px 30px 0px 4px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .field-input:hover {
    background: ${colors.grey6};
  }
  .sizes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .option,
  .option-selected {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: 1px solid ${colors.grey3};
    border-radius: 8px;
    cursor: pointer;
    gap: 8px;
  }
  .option:hover {
    background: ${colors.grey6};
  }
  .option-selected {
    background: ${colors.selectedOptionGrey};
    &:hover {
      background: ${colors.grey4Hover};
    }
  }

  .select {
    border-radius: 8px;
    height: 40px;
    min-width: 135px;
  }
  .date-range-picker-container {
    display: flex;
    gap: 2px;
    align-items: center;
  }
  .clear-filter {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    background-color: ${colors.inactiveLight};
    color: ${colors.inactiveHover};
    border-radius: 24px;
    padding: 11px 16px;
  }
  .clear-filter:hover {
    background-color: ${colors.inactiveLightHover};
  }
  .show-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #c5c5c5;
    border-radius: 12px;
    padding: 12px;
    height: 40px;
    background-color: ${colors.grey4};
  }
`;
