import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const CloseSIcon = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        d="M11.2995 11.5273L4.69984 4.92768"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.7002 11.5273L11.2999 4.92768"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseSIcon;
