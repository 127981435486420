import React, { useEffect, useState } from 'react';
import { SequenceSelectorWrapper } from './style';
import useSearchStrategies from 'hooks/services/sequence/useSearchStrategies';
import { CircularProgress, TextField } from '@mui/material';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { colors } from 'crono-fe-common/theme';
import SequenceCard from '../sequenceCard';
import useAddProspectToSequence from 'hooks/services/sequence/useAddProspectToSequence';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { FeConstants } from 'constants/FeConstants';
import { useJuneAnalytics } from 'context/june';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { Either, Either3 } from 'utils/fe-utils';
import { Strategy } from 'crono-fe-common/types/strategy';

type IProps = {
  setOpenTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  //This function is fired when the prospect is correctly added to the sequence
  callOnSucces?: () => void;
} & Either3<
  {
    prospectId: string;
    accountId: string;
  },
  {
    handleMultipleAdd: (strategyId: number) => void;
  },
  {
    returnSelectedStrategy: (strategy: Strategy) => void;
  }
>;

const SequenceSelector = (props: IProps) => {
  const { callOnSucces, setOpenTooltip } = props;
  const analytics = useJuneAnalytics();

  const [search, setSearch] = useState<string>('');
  const { data: strategies } = useSearchStrategies({
    limit: FeConstants.strategyLimit,
    offset: 0,
    name: search,
  });

  const {
    mutate: addToSequence,
    isSuccess,
    error: errorAddProspectToSequence,
    isLoading: addingProspect,
  } = useAddProspectToSequence();

  useConditionalSnackBar([
    {
      condition: !!errorAddProspectToSequence,
      message:
        getError(errorAddProspectToSequence) ?? 'Error adding to strategy',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (isSuccess) {
      if (callOnSucces) callOnSucces();
      if (setOpenTooltip) setOpenTooltip(false);
    }
  }, [isSuccess]);

  const handleAddStrategy = (strategy: Strategy) => {
    //If the selector has been opened by the multiple add tooltip
    if ('handleMultipleAdd' in props && props.handleMultipleAdd) {
      props.handleMultipleAdd(strategy.id);
    } else if ('prospectId' in props && props.prospectId) {
      addToSequence({
        accountId: props.accountId,
        prospectId: props.prospectId,
        strategyId: strategy.id,
      });

      if (analytics) {
        analytics.track('add-to-sequence', {
          type: 'add',
        });
      }
    } else if (
      'returnSelectedStrategy' in props &&
      props.returnSelectedStrategy
    ) {
      props.returnSelectedStrategy(strategy);
      if (callOnSucces) callOnSucces();
      if (setOpenTooltip) setOpenTooltip(false);
    }
  };

  return (
    <SequenceSelectorWrapper>
      <SearchIcon color={colors.grey11} />
      <TextField
        variant="standard"
        InputProps={{
          disableUnderline: true,
          endAdornment:
            search === '' ? null : (
              <CloseMIcon
                className="remove-text-icon"
                color={colors.grey11}
                onClick={() => setSearch('')}
              />
            ),
        }}
        placeholder="Search..."
        value={search}
        onChange={(ev) => setSearch(ev.target.value)}
        style={{ marginLeft: 12, marginBottom: 12, width: '85%' }}
      />
      {addingProspect ? (
        <CircularProgress />
      ) : (
        <div className="sequences-container">
          {strategies?.data?.data.map((strategy, index) => {
            return (
              <div
                key={strategy.id}
                style={{
                  borderBottom: `1px solid ${colors.grey4}`,
                  cursor: 'pointer',
                }}
                onClick={() => handleAddStrategy(strategy)}
              >
                <SequenceCard sequence={strategy} key={index} />
              </div>
            );
          })}
        </div>
      )}
    </SequenceSelectorWrapper>
  );
};

export default SequenceSelector;
