import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import {
  ExternalProperty,
  TableType,
} from 'crono-fe-common/types/externalProperty';

export default function useGetExternalPropertyStatus(
  TableType: TableType,
  enabled = true,
) {
  const params: any = {};

  if (TableType) {
    params['TableType'] = TableType;
  }
  const queryString = new URLSearchParams(params).toString();

  const request: Request = {
    url: ENDPOINTS.externalProperty.status.main + '?' + queryString,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.externalProperty.status.main, queryString],

    queryFn: () => client(request),
    staleTime: 60 * 60 * 1000,
    enabled,
  };

  const { data, ...rest } = useQuery<
    ResponsePaginated<ExternalProperty | null>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
