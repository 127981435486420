import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC, useEffect } from 'react';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

enum ImportType {
  Accounts = 'Accounts',
  Contacts = 'Contacts',
  Leads = 'Leads',
}

export type ImportTypeMessage = {
  type: ImportType;
  description: string;
};

interface IProps {
  setDisableNext: (val: boolean) => void;
  activeStep: number;
}

const DataImportStep0: FC<IProps> = ({
  setDisableNext,
  activeStep,
}: IProps) => {
  useEffect(() => {
    return () => {
      if (activeStep === 0) {
        setDisableNext(true);
      }
    };
  }, []);

  return (
    <FlexDiv
      alignItems="start"
      justifyContent="start"
      style={{ rowGap: 18 }}
      direction="column"
      width={'500px'}
    >
      <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
        Import data
      </Typography>
      <FlexDiv justifyContent="start">
        <Typography
          fontSize={16}
          lineHeight={'24px'}
          color={colors.grey11}
          maxWidth={500}
        >
          File should be in CSV format, with comma "," as separator. The first
          line should identify the headers of the columns.
        </Typography>
      </FlexDiv>
      <FlexDiv alignItems="start" direction="column" style={{ gap: 12 }}>
        <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
          Download example file of{' '}
          <a
            href="https://crono-assets.s3.eu-central-1.amazonaws.com/import_contacts_example.csv"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#0A9B94' }}
          >
            contacts
          </a>{' '}
          or{' '}
          <a
            href="https://crono-assets.s3.eu-central-1.amazonaws.com/import_account_example.csv"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#0A9B94' }}
          >
            companies
          </a>
        </Typography>
      </FlexDiv>
    </FlexDiv>
  );
};

export default DataImportStep0;
