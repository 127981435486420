import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect } from 'react';
import { AddContainerWrapper } from '../style';
import { Typography } from '@mui/material';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import AddCompanyForm from '../addCompanyForm';
import { AccountInsert } from 'crono-fe-common/types/DTO/accountInsert';
import useCreateAccount from 'hooks/services/account/useCreateAccount';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';

interface IProps {
  close: () => void;
}

const AddCompanyContainer = ({ close }: IProps) => {
  const {
    mutate: addAccount,
    isSuccess,
    error,
    isLoading: createLoading,
  } = useCreateAccount();

  const handleSubmit = (newAccount: AccountInsert) => {
    addAccount(newAccount);
  };

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) || 'Error while creating company',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <BlurBackgroundDivFixed>
      <AddContainerWrapper>
        <CloseTabButton close={close} style={{ marginRight: '18px' }} />
        <div className="add-main-container">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Add new company:
          </Typography>
          <AddCompanyForm
            showSubmit={true}
            close={close}
            handleSubmit={handleSubmit}
            createLoading={createLoading}
          />
        </div>
      </AddContainerWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default AddCompanyContainer;
