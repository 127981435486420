import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconDrag = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        d="M14.9972 17.5068C14.7221 17.5068 14.497 17.7319 14.5 18.007C14.5 18.2822 14.7251 18.5073 15.0002 18.5073C15.2754 18.5073 15.5004 18.2822 15.5004 18.007C15.5004 17.7319 15.2754 17.5068 14.9972 17.5068"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9972 11.5029C14.7221 11.5029 14.497 11.728 14.5 12.0031C14.5 12.2783 14.7251 12.5033 15.0002 12.5033C15.2754 12.5033 15.5004 12.2783 15.5004 12.0031C15.5004 11.728 15.2754 11.5029 14.9972 11.5029"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9972 5.50098C14.7221 5.50098 14.497 5.72607 14.5 6.00118C14.5 6.2763 14.7251 6.50139 15.0002 6.50139C15.2754 6.50139 15.5004 6.2763 15.5004 6.00118C15.5004 5.72607 15.2754 5.50098 14.9972 5.50098"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99724 17.5068C8.72212 17.5068 8.49703 17.7319 8.50003 18.007C8.50003 18.2822 8.72512 18.5073 9.00024 18.5073C9.27535 18.5073 9.50045 18.2822 9.50045 18.007C9.50045 17.7319 9.27535 17.5068 8.99724 17.5068"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99724 11.5029C8.72212 11.5029 8.49703 11.728 8.50003 12.0031C8.50003 12.2783 8.72512 12.5033 9.00024 12.5033C9.27535 12.5033 9.50045 12.2783 9.50045 12.0031C9.50045 11.728 9.27535 11.5029 8.99724 11.5029"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99724 5.50098C8.72212 5.50098 8.49703 5.72607 8.50003 6.00118C8.50003 6.2763 8.72512 6.50139 9.00024 6.50139C9.27535 6.50139 9.50045 6.2763 9.50045 6.00118C9.50045 5.72607 9.27535 5.50098 8.99724 5.50098"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDrag;
