import { MainButton } from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { SettingsTabs } from 'pages/settings';
import {
  useGetExtensionStatus,
  CronoExtensionStatus,
} from 'hooks/services/crono-extension/useGetExtensionStatus';
import { Typography } from '@mui/material';
import { ReactComponent as InstallExtensionIcon } from '../../../assets/images/Install-chrome-extension.svg';
import { colors } from 'crono-fe-common/theme';

interface IProps {
  close: () => void;
}

const LinkedinNotLogged = ({ close }: IProps) => {
  const navigate = useNavigate();

  const icon = <InstallExtensionIcon />;

  let message =
    'Use Chrome to integrate with your LinkedIn account and install the Crono-Linkedin extension.';

  let button = 'Install';

  let text = '';

  const { data: extensionStatus } = useGetExtensionStatus();
  if (extensionStatus?.status === CronoExtensionStatus.NOT_INSTALLED) {
    message =
      'Install the Crono extension on Chrome to use your LinkedIn account and execute LinkedIn tasks.';
    button = 'Install';
    text = 'Go to Settings page to integrate with LinkedIn';
  } else if (
    extensionStatus?.status === CronoExtensionStatus.NOT_CHROME_BASED_BROWSER
  ) {
    message =
      'Use Chrome to integrate with your LinkedIn account and install the Crono-Linkedin extension.';
    button = 'Install';
  } else if (
    extensionStatus?.status ===
    CronoExtensionStatus.INSTALLED_WITHOUT_LINKEDIN_COOKIE
  ) {
    message =
      'Open LinkedIn in another tab to integrate with your LinkedIn account.';
    button = 'Open LinkedIn';
  }

  return (
    <FlexDiv direction="column" gap="20px">
      {icon}
      <Typography
        fontSize={16}
        fontWeight={600}
        maxWidth={'450px'}
        textAlign={'center'}
      >
        {message}
      </Typography>
      {text ? (
        <Typography
          fontSize={14}
          color={colors.grey11}
          maxWidth={'350px'}
          textAlign={'center'}
        >
          {text}
        </Typography>
      ) : null}
      <MainButton
        style={{
          width: '140px',
        }}
        onClick={() => {
          if (
            extensionStatus?.status === CronoExtensionStatus.NOT_INSTALLED ||
            extensionStatus?.status ===
              CronoExtensionStatus.NOT_CHROME_BASED_BROWSER
          ) {
            navigate(PATH.SETTINGS, {
              state: { tab: SettingsTabs.Integrations },
            });
            close();
          } else if (
            extensionStatus?.status ===
            CronoExtensionStatus.INSTALLED_WITHOUT_LINKEDIN_COOKIE
          ) {
            window.open('https://www.linkedin.com', '_blank');
          }
        }}
      >
        {button}
      </MainButton>
    </FlexDiv>
  );
};

export default LinkedinNotLogged;
