import React from 'react';
import { Switch, SwitchProps } from '@mui/material';
import { CronoSwitchWrapper } from './style';
import { colors } from 'crono-fe-common/theme';

interface IProps extends SwitchProps {
  sameSwitchColor?: boolean;
}

const CronoSwitch = (props: IProps) => {
  return (
    <CronoSwitchWrapper sameSwitchColor={props.sameSwitchColor}>
      <Switch
        {...props}
        classes={{
          thumb: 'switch-thumb',
          track: 'switch-track',
        }}
        sx={{
          '.MuiSwitch-switchBase:hover': {
            backgroundColor: props.sameSwitchColor
              ? `rgba(30, 186, 178, 0.04) !important`
              : undefined,
          },
          '.Mui-checked': {
            '.MuiSwitch-thumb': {
              color: colors.primary,
            },
          },
        }}
      />
    </CronoSwitchWrapper>
  );
};

export default CronoSwitch;
