import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconStrategySettings = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M20 12V20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7002 14.67V20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 17V20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2998 13.6V20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9396 8.50856C16.623 9.19198 16.623 10.3 15.9396 10.9834C15.2562 11.6668 14.1481 11.6668 13.4647 10.9834C12.7813 10.3 12.7813 9.19197 13.4647 8.50856C14.1481 7.82514 15.2562 7.82514 15.9396 8.50856"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3424 4.00556C22.0258 4.68898 22.0258 5.79702 21.3424 6.48043C20.659 7.16385 19.551 7.16385 18.8675 6.48043C18.1841 5.79701 18.1841 4.68897 18.8675 4.00556C19.551 3.32214 20.659 3.32214 21.3424 4.00556"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.13245 9.40256C5.81587 10.086 5.81587 11.194 5.13245 11.8774C4.44903 12.5609 3.34099 12.5609 2.65758 11.8774C1.97417 11.194 1.97417 10.086 2.65758 9.40256C3.341 8.71914 4.44904 8.71914 5.13245 9.40256"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5363 4.90056C11.2197 5.58397 11.2197 6.69202 10.5363 7.37543C9.85284 8.05884 8.7448 8.05884 8.06139 7.37543C7.37797 6.69201 7.37797 5.58397 8.06139 4.90056C8.74481 4.21714 9.85285 4.21714 10.5363 4.90056"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.77 6.36L16.04 8.63"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.24 8.78L10.75 7.11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24023 9.53L7.96023 7.26"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconStrategySettings;
