import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const PlusIcon = ({ color = colors.black, className, style }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <path
        d="M19 12.0001L5 12.0001"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <path
        d="M12 18.9999L12 4.99988"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PlusIcon;
