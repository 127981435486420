import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const AutomationBannerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 8px;
  margin-bottom: 8px;
  background: ${colors.grey4};
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
