import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordPageWrapper } from './styles';
import ResetPasswordFlowContainer from 'components/ResetPasswordFlowContainer';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { NewPasswordForm } from '../settings/Password/NewPasswordForm';
import { useFormik } from 'formik';
import {
  initialValues,
  PasswordInputs,
  validationSchema,
} from '../settings/Password/model';
import { colors } from 'crono-fe-common/theme';
import useResetPassword from 'hooks/services/auth/useResetPassword';
import PATH from 'routing/path';
import TokenErrorPage from './tokenErrorPage';
import { useConditionalSnackBar } from '../../context/snackbar';
import { CircularProgress } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useAuth } from '../../context/auth';

interface IProps {
  createPasswordView?: boolean;
}

const ResetPasswordPage: FC<IProps> = ({ createPasswordView = false }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  //We need to keep in mind that if we will ever change BE implementation
  // to produce tokens with length !== 36, we will need to change this condition
  const isTokenValid = token && token.length === 36;

  useEffect(() => {
    if (!token) {
      navigate(PATH.LOGIN);
    }
  }, [token]);

  const {
    mutate: resetPassword,
    error,
    isSuccess,
    isLoading,
  } = useResetPassword();

  const { login, error: loginError } = useAuth();

  const validationSchemaWithoutOldPassword = validationSchema.omit([
    'oldPassword',
  ]);

  const formik = useFormik<Omit<PasswordInputs, 'oldPassword'>>({
    initialValues: {
      ...initialValues,
    },
    validationSchema: validationSchemaWithoutOldPassword,
    onSubmit: async (values) => {
      if (token) {
        resetPassword({ newPassword: values.newPassword, token: token });
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      if (email) {
        login({
          email,
          password: formik.values.newPassword,
        });
      } else {
        navigate(PATH.LOGIN);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (loginError) {
      navigate(PATH.LOGIN);
    }
  }, [loginError]);

  useConditionalSnackBar([
    {
      condition: isSuccess,
      message: `Your password has been successfully ${createPasswordView ? 'created' : 'changed'}`,
      severity: 'success',
    },
  ]);

  return (
    <ResetPasswordFlowContainer>
      {!error && isTokenValid && (
        <ResetPasswordPageWrapper>
          <div className="header">
            <h2>
              {createPasswordView ? 'Create password' : 'Create a new password'}
            </h2>
            <p>
              {createPasswordView
                ? 'Please create your password in order to access your Crono account'
                : 'You new password should be different from the one previously used'}
            </p>
          </div>

          <form className="form" onSubmit={formik.handleSubmit}>
            <NewPasswordForm formik={formik} />

            {isLoading ? (
              <FlexDiv justifyContent="center">
                <CircularProgress />
              </FlexDiv>
            ) : (
              <MainButton
                fullWidth
                type="submit"
                style={{
                  background: !(formik.isValid && formik.dirty)
                    ? colors.grey11
                    : undefined,
                }}
              >
                {createPasswordView ? 'Create password' : 'Reset password'}
              </MainButton>
            )}
          </form>
        </ResetPasswordPageWrapper>
      )}
      {!isTokenValid ||
      (error &&
        !!error?.response?.data?.errors?.find(
          (e) => e.code === 'TokenDoesNotExistError',
        )) ? (
        <TokenErrorPage
          titles={[
            'This link is invalid.',
            'Please try again or contact us if you need any assistance',
          ]}
        />
      ) : (
        <></>
      )}
      {error &&
        !!error.response?.data?.errors?.find(
          (e) => e.code === 'TokenExpiredError',
        ) && (
          <TokenErrorPage
            titles={[
              `Please request a new one to ${createPasswordView ? 'create' : 'reset'} your password`,
            ]}
            subTitles={[
              `The password could not be ${createPasswordView ? 'created' : 'reset'} as this link was expired.`,
            ]}
          />
        )}
    </ResetPasswordFlowContainer>
  );
};

export default ResetPasswordPage;
