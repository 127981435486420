import { MainButton } from 'crono-fe-common/components/CronoButton';
import { Input } from 'crono-fe-common/components/FormComponents';
import { useAuth } from 'context/auth';
import { useConditionalSnackBar } from 'context/snackbar';
import { useFormik } from 'formik';
import useUpdatePassword from 'hooks/services/user/useUpdatePassword';
import { FC, useEffect, useState } from 'react';
import { GeneralWrapper } from '../styles';
import { initialValues, PasswordInputs, validationSchema } from './model';
import { getError } from 'crono-fe-common/utils';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as PasswordHiddenIcon } from 'crono-fe-common/icons/Password-Hidden-Icon.svg';
import { ReactComponent as PasswordVisibleIcon } from 'crono-fe-common/icons/Password-Showed-Icon.svg';
import { useJuneAnalytics } from 'context/june';
import { underlineInputStyles } from 'utils/fe-utils';
import { NewPasswordForm } from './NewPasswordForm';

const PasswordSettings: FC = () => {
  const { user } = useAuth();
  const analytics = useJuneAnalytics();
  const formik = useFormik<PasswordInputs>({
    initialValues: {
      ...initialValues,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (user) {
        updatePassword({
          email: user?.email,
          password: values.oldPassword,
          newPassword: values.newPassword,
        });
        if (analytics) {
          analytics.track('password-changed', {
            user: user.id,
          });
        }
      }
    },
  });

  const {
    error: errorDeletePassword,
    isSuccess,
    mutate: updatePassword,
  } = useUpdatePassword();

  useConditionalSnackBar([
    {
      condition: !!errorDeletePassword,
      message: getError(errorDeletePassword) ?? 'Error during update password',
      severity: 'error',
    },
    {
      condition: !!isSuccess,
      message: 'Succesfully update password',
      severity: 'success',
    },
  ]);

  const [showOldPassword, setShowOldPassword] = useState(false);

  return (
    <GeneralWrapper>
      <form onSubmit={formik.handleSubmit} className="password-form">
        <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
          Reset password
        </Typography>
        <div className="form-control">
          <Typography
            color={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
                ? colors.inactive
                : colors.primary
            }
            fontSize={12}
            fontWeight={500}
            lineHeight={'16px'}
          >
            Old password:
          </Typography>
          <Input
            value={formik.values.oldPassword}
            type={showOldPassword ? 'text' : 'password'}
            variant="standard"
            placeholder="Type old password..."
            InputProps={{
              sx: {
                paddingBlock: '6px',
              },
              endAdornment:
                formik.values.oldPassword &&
                (showOldPassword ? (
                  <PasswordVisibleIcon
                    onClick={() => setShowOldPassword(false)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <PasswordHiddenIcon
                    onClick={() => setShowOldPassword(true)}
                    style={{ cursor: 'pointer' }}
                  />
                )),
            }}
            sx={underlineInputStyles(
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword),
            )}
            onChange={(value) =>
              formik.setFieldValue('oldPassword', value.target.value)
            }
          />
          {formik.touched.oldPassword && Boolean(formik.errors.oldPassword) && (
            <Typography
              color={colors.inactive}
              fontSize={12}
              fontWeight={400}
              lineHeight={'16px'}
            >
              {formik.errors.oldPassword}
            </Typography>
          )}
        </div>

        <NewPasswordForm formik={formik} />

        <MainButton
          style={{
            padding: '16px 24px',
            background: !(formik.isValid && formik.dirty)
              ? colors.grey11
              : undefined,
          }}
          type="submit"
        >
          Reset
        </MainButton>
      </form>
    </GeneralWrapper>
  );
};

export default PasswordSettings;
