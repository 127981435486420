export enum CronoEmailSearchSortType {
  DATE = 'Date',
  DATEDESC = 'DateDesc',
  COMPANYNAME = 'CompanyName',
  COMPANYNAMEDESC = 'CompanyNameDesc',
  CONTACTNAME = 'ContactName',
  CONTACTNAMEDESC = 'ContactNameDesc',
  BOUNCED = 'Bounced',
  BOUNCEDDESC = 'BouncedDesc',
  OPEN = 'Open',
  OPENDESC = 'OpenDesc',
  REPLIED = 'Replied',
  REPLIEDDESC = 'RepliedDesc',
  OWNERFULLNAME = 'OwnerFullName',
  OWNERFULLNAMEDESC = 'OwnerFullNameDesc',
}
