import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const LinkChainIconM = ({ color = colors.black, className }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            d="M15.9655 6.03575C17.3465 4.65475 19.5845 4.65475 20.9655 6.03575V6.03575C22.3465 7.41675 22.3465 9.65475 20.9655 11.0357L15.0865 16.9148C13.7055 18.2958 11.4675 18.2958 10.0865 16.9148V16.9148C8.70553 15.5338 8.70553 13.2958 10.0865 11.9148L10.9655 11.0357"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M8.03575 18.9636C6.65475 20.3446 4.41675 20.3446 3.03575 18.9636V18.9636C1.65475 17.5826 1.65475 15.3446 3.03575 13.9636L8.91475 8.08458C10.2958 6.70358 12.5338 6.70358 13.9148 8.08458V8.08458C15.2958 9.46558 15.2958 11.7036 13.9148 13.0846L12.9998 13.9996"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default LinkChainIconM;
