import { Typography } from '@mui/material';
import { useAuth } from 'context/auth';
import React, { useEffect, useMemo, useState } from 'react';

import { ExpiredPlanWrapper } from 'pages/connect/views/main/styles';
import Logo from 'assets/images/logo_crono.png';
import { colors } from 'crono-fe-common/theme';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import SubscriptionPlans from '..';
import ImageForExpired from 'assets/images/ImageForExpired.png';
import { isDev } from 'utils/fe-utils';
import useGetSubscription from 'hooks/services/subscription/useGetSubscription';
import Role from 'crono-fe-common/types/enums/role';
import usePortalSession from 'hooks/services/subscription/usePortalSession';

interface ExpiredPlanOption {
  type: SubscriptionPlanType[];
  message: JSX.Element;
  detail: JSX.Element;
}

const ExpiredPlan = () => {
  const { user, isSubscriptionManager, useAuthGuard, isExpired, logout } =
    useAuth();
  useAuthGuard();
  const { data: subscriptionDataDb } = useGetSubscription();
  const navigate = useNavigate();

  const [showPlans, setShowPlans] = useState(false);

  useEffect(() => {
    // Debug
    // if (isDev()) return;
    if (!isExpired) {
      navigate(PATH.HOME);
    }
  }, [isExpired]);

  const firstSubscriptionManager = useMemo(() => {
    if (subscriptionDataDb) {
      const user = subscriptionDataDb.data?.data?.users?.find((user) =>
        user.userRoles.includes(Role.SUBSCRIPTION_MANAGER),
      );
      return `${user?.firstName} ${user?.lastName}`;
    }
    return '';
  }, [subscriptionDataDb]);

  const isMultiCustomer = useMemo(() => {
    return subscriptionDataDb?.data?.data?.multiCustomer ?? false;
  }, [subscriptionDataDb]);

  const { mutateAsync: createPortalSession } = usePortalSession();

  const handleCustomerPortal = () => {
    createPortalSession({}).then((res) => {
      window.open(res.data?.data.url, '_blank');
    });
  };

  const options: ExpiredPlanOption[] = useMemo(() => {
    return [
      {
        type: [SubscriptionPlanType.FREE_TRIAL],
        message: (
          <>
            <span>Your free trial has expired.</span>
            <br />
          </>
        ),
        detail: isSubscriptionManager ? (
          <>
            <span>
              Please select a plan to renew your subscription and continue
              enjoying Crono
            </span>
          </>
        ) : (
          <>
            <span>Please contact your subscription</span>
            <br />
            <span>
              <span
                style={{ fontWeight: 500, color: colors.grey1 }}
              >{`Admin (${firstSubscriptionManager})`}</span>{' '}
              to renew your plan
            </span>
          </>
        ),
      },
      {
        type: [
          SubscriptionPlanType.STARTER,
          SubscriptionPlanType.PRO,
          SubscriptionPlanType.ULTRA,
        ],
        message: (
          <>
            <span>Your subscription has expired.</span>
            <br />
            <span>Renew it now</span>
          </>
        ),
        detail: isSubscriptionManager ? (
          <>
            <span>
              Please check your payment details and proceed to renew your
              subscription
            </span>
          </>
        ) : (
          <>
            <span>Please contact your subscription</span>
            <br />
            <span>
              <span
                style={{ fontWeight: 500, color: colors.grey1 }}
              >{`Admin (${firstSubscriptionManager})`}</span>{' '}
              to renew your plan
            </span>
          </>
        ),
      },
    ];
  }, [firstSubscriptionManager, isSubscriptionManager]);

  const expiredOption = useMemo(() => {
    if (!user?.subscriptionType) return [];
    return options
      .filter((option) => option.type.includes(user.subscriptionType!))
      .map((option, idx) => {
        return (
          <div className="action-card" key={idx}>
            <div className="action-message">
              <Typography
                fontSize={'24px'}
                fontWeight={700}
                lineHeight={'30px'}
                width={'500px'}
              >
                {option.message}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}
                color={colors.grey11}
                width={'340px'}
              >
                {option.detail}
              </Typography>
            </div>
            {isSubscriptionManager &&
              (isMultiCustomer ? (
                <MainButton
                  className="action-button"
                  onClick={handleCustomerPortal}
                >
                  Customer portal
                </MainButton>
              ) : (
                <MainButton
                  className="action-button"
                  onClick={() => {
                    setShowPlans(true);
                  }}
                >
                  Choose
                </MainButton>
              ))}
          </div>
        );
      });
  }, [user, options]);

  if (!user) return null;

  return showPlans ? (
    <SubscriptionPlans />
  ) : (
    <ExpiredPlanWrapper>
      <div className="logo-container">
        <img src={Logo} className="logo" alt="Crono Logo" />
        <CancelButton
          className="logout"
          color="secondary"
          onClick={() => logout()}
        >
          Log out
        </CancelButton>
      </div>
      <img src={ImageForExpired} style={{ width: 696, height: 273 }} />
      {expiredOption}
    </ExpiredPlanWrapper>
  );
};

export default ExpiredPlan;
