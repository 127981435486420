enum VerificationStatusType {
  VALID = 'Valid',
  ACCEPT_ALL = 'AcceptAll',
  UNKNOWN = 'Unknown',
  INVALID = 'Invalid',
}

export interface EmailScrapeResult {
  email: string;
  verificationStatus: VerificationStatusType;
}

export default VerificationStatusType;
