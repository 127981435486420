import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InsightsAccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 12px;

  .insights-container {
    display: flex;
    flex-direction: column;
  }
  .insights-inner-top-header,
  .insights-top-header {
    position: relative;
    border: 1px solid ${colors.grey444};
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom: transparent;
    overflow: hidden;
  }
  .insights-inner-top-header {
    border-radius: 0px;
    border-top: 1px solid ${colors.grey444};
  }
  .insights-icon-position-container {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px;
    /* max-height: 52px; */
  }
  .insight-position-experience {
    display: flex;
    flex-direction: column;
    width: 99%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .greyed > div > span {
    filter: grayscale(1);
  }

  .insights-bottom-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: 1px solid ${colors.grey444};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 100%;
    cursor: pointer;
  }
  .insights-bottom-footer:hover {
    background-color: ${colors.grey6};
  }
  .top-border {
    border: none;
    border-top: 1px solid ${colors.grey444};
  }
  .insight-icon-wrapper {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }
  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    margin-left: 4px;
    border-radius: 25px;
    color: ${colors.white};
    font-weight: 500;
    font-size: 11px;
  }
  .SOCIAL_PRESENCE-header,
  .KEY_TOPICS-header,
  .COMPANY_NEWS-header,
  .JOB_OPENINGS-header,
  .FUNDING_ROUNDS-header {
    border-radius: 12px;
    border: 1px solid transparent;
  }
  .JOB_OPENINGS-header {
    flex-wrap: wrap;
  }
  .SOCIAL_PRESENCE-header-expanded,
  .KEY_TOPICS-header-expanded,
  .COMPANY_NEWS-header-expanded,
  .JOB_OPENINGS-header-expanded,
  .FUNDING_ROUNDS-header-expanded {
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .SOCIAL_PRESENCE-counter {
    background-color: ${colors.socialPresenceCounter};
  }
  .KEY_TOPICS-counter {
    background-color: ${colors.nurture};
  }
  .JOB_OPENINGS-counter {
    background-color: ${colors.customer};
  }
  .COMPANY_NEWS-counter {
    background-color: ${colors.companyNewsCounter};
  }
  .FUNDING_ROUNDS-counter {
    background-color: ${colors.fundingRoundsCounter};
  }

  /* InsightType */
  .PROFILE_VIEW {
    background-color: ${colors.profileViewLight};
  }
  .SOCIAL_PRESENCE {
    background-color: ${colors.socialPresenceLight};
  }
  .KEY_TOPICS {
    background-color: ${colors.keyTopicsLight};
  }
  .JOB_OPENINGS {
    background-color: ${colors.jobOpeningsLight};
  }
  .COMPANY_NEWS {
    background-color: ${colors.companyNewsLight};
  }
  .FUNDING_ROUNDS {
    background-color: ${colors.fundingRounds};
  }
  .PROFILE_VIEW-ICON {
    background-color: ${colors.purpleLightHover};
  }
  .SOCIAL_PRESENCE-ICON {
    background-color: ${colors.socialPresenceIcon};
  }
  .KEY_TOPICS-ICON {
    background-color: ${colors.nurtureLightHover};
  }
  .JOB_OPENINGS-ICON {
    background-color: ${colors.customerLightHover};
  }
  .COMPANY_NEWS-ICON {
    background-color: ${colors.companyNewsIcon};
  }
  .FUNDING_ROUNDS-ICON {
    background-color: ${colors.fundingRoundsIcon};
  }

  .insight-social-activity-container {
    display: flex;
    padding: 8px;
    background-color: ${colors.grey6};
    border-radius: 8px;
    width: 100%;
    gap: 8px;
  }
  .insight-social-activity {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
  }

  .insight-linkedin-activity {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    background-color: ${colors.grey6};
    border-radius: 8px;
  }
  .linkedin-job-comment,
  .linkedin-job-post,
  .linkedin-comment,
  .linkedin-post {
    border-radius: 12px;
    font-weight: 500;
    font-size: 11px;
    color: ${colors.white};
    padding: 0px 4px;
    height: fit-content;
  }

  .linkedin-comment {
    background-color: ${colors.socialPresenceCounter};
  }
  .linkedin-post {
    background-color: ${colors.prospecting};
  }
  .linkedin-job-comment,
  .linkedin-job-post {
    background-color: ${colors.companyNewsCounter};
  }
  .redirect-icon-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .redirect-icon-wrapper > svg > path {
    stroke: ${colors.darkGreen};
  }
  .activity-url > svg > path {
    stroke: ${colors.grey11};
  }
  .empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: ${colors.grey6};
    border-radius: 8px;
    width: 95%;
  }
  .negative-growth,
  .positive-growth {
    background-color: ${colors.white};
    border-radius: 16px;
    display: flex;
    font-size: 11px;
    align-items: center;
    gap: 4px;
    padding: 0px 4px;
    width: fit-content;
  }

  .negative-growth {
    color: ${colors.inactive};
  }
  .positive-growth {
    color: ${colors.darkGreen};
  }
`;
