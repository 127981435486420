import { useSelectedAccount } from 'pages/home/context/account';
import SuggestionsBox from 'pages/taskBoard/suggestionsBox';
import PreviousActivitiesBox from '../accountView/previousActivitiesBox';
import FutureTasksBox from '../accountView/futureTasksBox';

interface IProps {
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
}
const WorkloadsView = ({ setTabValue }: IProps) => {
  const { selectedAccountId } = useSelectedAccount();

  return (
    <>
      <div className="layout-grid">
        <div className="layout-grid-item container">
          <div className="grid-item-tasks-suggestions">
            <PreviousActivitiesBox setTabValue={setTabValue} />
          </div>
        </div>
        <div className="layout-grid-item">
          <div
            className="grid-item container half-item"
            style={{ marginBottom: 16 }}
          >
            <FutureTasksBox accountId={selectedAccountId} />
          </div>

          <div className="grid-item container half-item">
            <SuggestionsBox accountId={selectedAccountId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkloadsView;
