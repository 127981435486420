import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  height: 100%;
  .error-logo {
    width: 100px;
  }
  .error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    row-gap: 16px;
  }
`;
