import React, { useEffect } from 'react';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { SequenceInstanceWrapper } from './style';
import useGetSequenceInstance from 'hooks/services/sequence/useGetSequenceInstance';
import { useConditionalSnackBar } from 'context/snackbar';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { getError } from 'crono-fe-common/utils';
import { ReactComponent as NotOwnedIcon } from 'crono-fe-common/icons/Not-owned-by-the-user.svg';
import { ReactComponent as CiruclarArrowsIcon } from '../../../assets/images/Icon-circular-arrows.svg';
import useStopProspectSequence from 'hooks/services/sequence/useStopProspectSequence';
import { Prospect } from 'crono-fe-common/types/prospect';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { getNextStepOrder } from 'utils/fe-utils';
import CronoStatus from 'components/CronoStatus';
import SequenceInstanceContent from './sequenceInstanceContent';
import { PauseRounded } from '@mui/icons-material';
import { useConfirmModal } from 'context/confirmModal';
import useRestartSequence from 'hooks/services/sequence/useRestartSequence';
import { useJuneAnalytics } from 'context/june';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import IconRectangle from 'crono-fe-common/icons/Icon-Rectangle';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import useDeleteSequenceProspect from 'hooks/services/sequence/useDeleteProspectSequence';

interface IProps {
  //The possibilities are to pass sequenceInstanceId with prospect or to pass the task, depending of what it's possible to have
  sequenceInstanceId?: number;
  prospect?: Prospect;
  task?: TaskTodo;
  onClose: () => void;
  handleOpenDateCalendar: (task: TaskTodo) => void;
}

const SequenceInstanceDisplay = ({
  sequenceInstanceId,
  prospect,
  task,
  onClose,
  handleOpenDateCalendar,
}: IProps) => {
  const { openModal: openConfirmModal } = useConfirmModal();
  const { data: sequenceInstance, isError: errorGetSequenceInstance } =
    useGetSequenceInstance(
      sequenceInstanceId ? sequenceInstanceId : task?.sequenceInstanceId ?? 0,
      prospect ? prospect.objectId : task?.prospectId ?? '',
      true,
    );
  const analytics = useJuneAnalytics();

  if (!prospect) {
    prospect = task?.prospect;
  }

  const {
    mutate: deleteSequence,
    isSuccess: sequenceStopped,
    error: errorStopProspectSequence,
  } = useStopProspectSequence();

  const {
    mutate: restartSequence,
    isSuccess: sequenceRestarted,
    error: errorRestartSequence,
  } = useRestartSequence();

  const stopSequence = (prospectId: string, sequenceInstanceId: number) => {
    deleteSequence({ sequenceInstanceId, prospectId });
    if (analytics) {
      analytics.track('stop-sequence', {});
    }
  };

  useEffect(() => {
    if (sequenceStopped) {
      onClose();
    }
  }, [sequenceStopped]);

  useEffect(() => {
    if (sequenceRestarted) {
      onClose();
    }
  }, [sequenceRestarted]);

  const handleRestartStrategy = () => {
    openConfirmModal({
      title: 'Are you sure you want to restart this strategy?',
      text: 'If you restart the strategy you will lose all your tasks personalization',
      confirmText: 'Restart',
      cancelText: 'Cancel',
      confirmFunction: () => {
        if (sequenceInstance?.data?.data.strategyId) {
          restartSequence({
            prospectId: prospect?.objectId,
            strategyId: sequenceInstance?.data?.data.strategyId,
          });
          if (analytics) {
            analytics.track('sequence-restarted', {
              prospectId: prospect?.objectId,
              strategyId: sequenceInstance?.data?.data.strategyId,
            });
          }
        }
      },
      cancelFunction: () => {},
      disableClickOnBackground: true,
    });
  };

  const {
    mutate: deleteSequenceProspect,
    isLoading: isLoadingDeleteSequenceProspect,
    isSuccess: isSuccessDeleteSequenceProspect,
    error: errorDeleteSequenceProspect,
  } = useDeleteSequenceProspect();

  const handleRemoveFromStrategy = () => {
    if (!prospect || !prospect.sequenceInstance?.id) return;
    deleteSequenceProspect({
      prospectId: prospect?.objectId,
      sequenceInstanceId: prospect.sequenceInstance?.id,
    });
    if (analytics) {
      analytics.track('remove-prospect-from-strategy', {});
    }
  };

  useEffect(() => {
    if (isSuccessDeleteSequenceProspect) {
      onClose();
    }
  }, [isSuccessDeleteSequenceProspect]);

  useConditionalSnackBar([
    {
      condition: !!errorGetSequenceInstance,
      message: 'Error getting strategy instance',
      severity: 'error',
    },
    {
      condition: !!sequenceStopped,
      message: 'Strategy correctly stopped',
      severity: 'success',
    },
    {
      condition: !!errorStopProspectSequence,
      message:
        getError(errorStopProspectSequence) ??
        'Error stopping strategy for this contact',
      severity: 'error',
    },
    {
      condition: !!sequenceRestarted,
      message: 'Strategy correctly restarted',
      severity: 'success',
    },
    {
      condition: !!errorRestartSequence,
      message:
        getError(errorRestartSequence) ??
        'Error restarting strategy for this contact',
      severity: 'error',
    },
    {
      condition: !!errorDeleteSequenceProspect,
      message:
        getError(errorDeleteSequenceProspect) ?? 'Error removing contact',
      severity: 'error',
    },
    {
      condition: !!isSuccessDeleteSequenceProspect,
      message: 'Contact removed from strategy',
      severity: 'success',
    },
  ]);
  if (!sequenceInstance) return null;
  return (
    <SequenceInstanceWrapper>
      <div className="background-component" id="sequence-instance-modal">
        <div className="container">
          <CloseTabButton close={onClose} />
          <div className="sequence-instance-container">
            <Typography fontSize={24} fontWeight={700}>
              {sequenceInstance?.data?.data?.name}
            </Typography>
            <div className="prospect-info-container">
              <div className="row">
                <div className="prospect-name-title-container">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                      flex: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <Typography fontSize={14} fontWeight={500} noWrap>
                      {prospect?.name}{' '}
                      <span style={{ color: colors.primaryDark }}>
                        at{' '}
                        {prospect?.account?.name ??
                          sequenceInstance.data?.data.prospect?.account?.name}
                      </span>
                    </Typography>
                    <CronoStatus
                      currentActualStatus={prospect?.actualStatus ?? null}
                      currentExternalValues={prospect?.externalValues ?? []}
                      disabled={true}
                      type="prospect"
                      style={{ height: 24, paddingBlock: 4 }}
                    />
                  </div>

                  <Typography fontSize={14} color={colors.grey11} noWrap>
                    {prospect!.title}
                  </Typography>
                </div>

                <div className="right-side-sequence-handle">
                  <Typography
                    fontWeight={500}
                    fontSize={12}
                    color={colors.grey11}
                  >
                    Progress:{' '}
                    <span style={{ color: colors.black }}>
                      {getNextStepOrder(sequenceInstance.data?.data?.tasks)}/
                    </span>
                    {sequenceInstance.data?.data.numberOfSteps}
                  </Typography>

                  {prospect && prospect.owned && prospect.sequenceInstance ? (
                    prospect.sequenceInstance.active ? (
                      /*If there is an active sequanceInstance */
                      <>
                        <Tooltip
                          arrow
                          title="Stop strategy"
                          placement="top"
                          enterDelay={400}
                          enterNextDelay={400}
                        >
                          <div
                            className="stop-strategy-button"
                            onClick={() => {
                              if (prospect!.sequenceInstance) {
                                stopSequence(
                                  prospect!.objectId,
                                  prospect!.sequenceInstance.id,
                                );
                              }
                            }}
                          >
                            <IconRectangle
                              color={colors.orange}
                              className="rectangle-icon"
                            />
                            <Typography
                              fontSize={14}
                              lineHeight={'18px'}
                              fontWeight={500}
                              color={colors.orange}
                              maxWidth={300}
                              noWrap
                            >
                              {prospect.sequenceInstance.name ??
                                'Stop strategy'}
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title="Restart strategy for this contact"
                          placement="top"
                          enterDelay={400}
                          enterNextDelay={400}
                          PopperProps={{ sx: { zIndex: '10001 !important' } }}
                        >
                          <div
                            className="restart-button"
                            onClick={handleRestartStrategy}
                          >
                            <CiruclarArrowsIcon />
                          </div>
                        </Tooltip>
                        {isLoadingDeleteSequenceProspect ? (
                          <CircularProgress size={30} />
                        ) : (
                          <Tooltip
                            arrow
                            placement="top"
                            enterDelay={400}
                            enterNextDelay={400}
                            PopperProps={{ sx: { zIndex: '10001 !important' } }}
                            title="Remove contact from strategy"
                            onClick={handleRemoveFromStrategy}
                          >
                            <IconButton className="remove-from-strategy-button">
                              <TrashCanDeleteIcon color={colors.white} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      <></>
                    )
                  ) : (
                    /*If the prospect is not owned */
                    <Tooltip
                      arrow
                      title={`The owner is ${
                        prospect?.user || task?.prospect?.user
                      }`}
                      placement="top"
                    >
                      <NotOwnedIcon style={{ width: 26, height: 26 }} />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="tags-container">
                  <TagsBoxShowAll
                    //since we are passing prospect + sequenceInstanceId or task
                    type={
                      prospect?.fromContact || task?.prospect?.fromContact
                        ? 'Prospect'
                        : 'Lead'
                    }
                    currentSituationTags={
                      prospect?.externalValues
                        ?.filter((ev) => ev.isTag && ev.value !== null)
                        .map((ev) => ({
                          externalPropertyId: ev.externalPropertyId,
                          option: ev.value!,
                        })) ?? []
                    }
                    disableEdit={true}
                    blockIsEditable={true}
                    showRemovable={false}
                  />
                </div>
              </div>
            </div>
            {sequenceInstance.data?.data && (
              <SequenceInstanceContent
                sequenceInstance={sequenceInstance.data.data}
                handleOpenDateCalendar={handleOpenDateCalendar}
                onClose={onClose}
                prospectId={prospect?.objectId}
                sequenceInstanceId={sequenceInstance.data.data.id}
                taskOrder={task?.order ?? undefined}
              />
            )}
          </div>
        </div>
      </div>
    </SequenceInstanceWrapper>
  );
};

export default SequenceInstanceDisplay;
