import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { Account } from 'crono-fe-common/types/account';
import { AccountGetParameters } from 'crono-fe-common/types/DTO/accountGetParameters';

export default function useAccounts(
  searchParameters: AccountGetParameters,
  offset = 0,
) {
  // get key value object from searchParameters
  const params: any = {
    limit: searchParameters.limit,
    offset,
  };

  if (searchParameters.name) {
    params.name = searchParameters.name;
  }
  if (searchParameters.status) {
    params.status = searchParameters.status;
  }

  if (searchParameters.Sort) {
    params.sort = searchParameters.Sort;
  }

  if (searchParameters.View) {
    params.view = searchParameters.View;
  }

  if (searchParameters.IsManager) {
    params.isManager = searchParameters.IsManager;
  }

  let statusQuery;
  if (searchParameters.Status) {
    statusQuery = searchParameters.Status.reduce(
      (prev, curr) => prev + `Status=${curr}&`,
      '&',
    ).slice(0, -1);
  }

  const queryString = new URLSearchParams(params).toString();

  const request: Request = {
    url: ENDPOINTS.account.main + '?' + queryString + (statusQuery ?? ''),
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.account.main, queryString + (statusQuery ?? '')],

    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<ResponsePaginated<Account[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
