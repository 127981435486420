import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { UpdateProspectMultiple } from 'crono-fe-common/types/DTO/updateProspectMultiple';

export default function useEditProspectMultiple() {
  const queryClient = useQueryClient();

  const editProspectMultiple = (updateProspect: UpdateProspectMultiple[]) => {
    const request: Request = {
      url: `${ENDPOINTS.prospect.multiple}`,
      config: {
        method: 'patch',
        data: { data: updateProspect },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    UpdateProspectMultiple[]
  >(editProspectMultiple, {
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.account.main) ||
          query.queryKey.toString().startsWith(ENDPOINTS.prospect.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.search.main]);
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
    },
  });

  return { data, mutate, ...rest };
}
