import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconThread = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
      onClick={onClick}
    >
      <path
        d="M8.99951 6.43667H20.0041"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0037 12H11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.001 17.5633H20.0039"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.49728 5.93646C4.22102 5.93646 3.99707 6.16041 3.99707 6.43667C3.99707 6.71293 4.22102 6.93688 4.49728 6.93688C4.77354 6.93688 4.99749 6.71293 4.99749 6.43667C4.99749 6.16041 4.77354 5.93646 4.49728 5.93646"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49826 11.4998C6.222 11.4998 5.99805 11.7237 5.99805 12C5.99805 12.2762 6.222 12.5002 6.49826 12.5002C6.77451 12.5002 6.99846 12.2762 6.99846 12C6.99846 11.7237 6.77451 11.4998 6.49826 11.4998"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49923 17.063C8.22297 17.063 7.99902 17.287 7.99902 17.5633C7.99902 17.8395 8.22297 18.0635 8.49923 18.0635C8.77549 18.0635 8.99944 17.8395 8.99944 17.5633C8.99944 17.287 8.77549 17.063 8.49923 17.063"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconThread;
