import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const CheckMarkIcon = ({
  color = colors.black,
  color2 = 'transparent',
  className,
  onClick,
  strokeWidth = '1.5',
  size = 24,
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect width="24" height="24" rx="16" fill={color2} />
      <path
        d="M5.5 11.5L10.5 16.5L19.5 7.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckMarkIcon;
