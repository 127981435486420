import {
  Badge,
  CircularProgress,
  Tooltip,
  Typography,
  styled,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import useTasks from 'hooks/services/event/useTasks';
import { useSelectedAccount } from 'pages/home/context/account';
import { Prospect } from 'crono-fe-common/types/prospect';
import { colors } from 'crono-fe-common/theme';

import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import EmailRepliedIcon from 'crono-fe-common/icons/Icon-Email-Replied';
import EmailOpenedBadgeIcon from 'crono-fe-common/icons/Icon-Email-Opened-Badge';
import EmailBouncedIcon from 'crono-fe-common/icons/Icon-Email-Bounced-New';

import LinkedinMessageNewIcon from 'crono-fe-common/icons/Icon-Linkedin-Message-New';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';

import VideoIcon from 'crono-fe-common/icons/Icon-Video';
import useEvents from 'hooks/services/event/useEvents';
import { Event } from 'crono-fe-common/types/event';
import { useEffect, useState } from 'react';
import { Task, isTask } from 'crono-fe-common/types/task';
import { LinkedinType } from 'crono-fe-common/types/logLinkedin';
import moment from 'moment';

import { useWindow } from 'context/sideTab';
import { useEmailThread } from 'context/EmailThreadTab';
import { useLinkedinContext } from 'context/linkedinTab';
import NoteIcon from 'crono-fe-common/icons/Icon-Note';
import { printCallFeedback, prospectName } from 'utils/fe-utils';
import CallLogIcon from 'crono-fe-common/icons/Icon-Call-Log';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import LinkChainIconS from 'crono-fe-common/icons/Icon-Link-Chain-S';
import LinkChainIconM from 'crono-fe-common/icons/Icon-Link-Chain-M';
import { LinkUrlWrapper } from 'pages/accountTab/style';
import { rowPlaceholderFillOpacities } from 'constants/FeConstants';
import { ReactComponent as NoPreviousActivity } from '../../../../assets/images/No_previous_activity.svg';
import ActivityPlaceholderLinkedinMessage from '../../../../assets/images/Activity_placeholder_linkedin.png';
import ActivityPlaceholderEmailOpened from '../../../../assets/images/Activity_placeholder_email_opened.png';
import ActivityPlaceholderEmailReplied from '../../../../assets/images/Activity_placeholder_email_replied.png';
import ActivityPlaceholderVideo from '../../../../assets/images/Activity_placeholder_video.png';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';

export const LinksTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    arrow={props.arrow ? props.arrow : false}
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    position: 'relative',
    display: 'flex',
    width: 292,
    minHeight: 110,
    maxHeight: 290,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    padding: 0,
    right: 45,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));
interface IProps {
  setTabValue: (value: number) => void;
  prospectId?: string | null;
  closeTooltip?: () => void;
}

const ActivitiesBox = ({ setTabValue, prospectId, closeTooltip }: IProps) => {
  const { selectedAccountId, account } = useSelectedAccount();
  const { data: tasks, isLoading: isLoadingTasks } = useTasks(
    selectedAccountId,
    true,
    prospectId,
  );
  const { data: events, isLoading: isLoadingEvents } = useEvents(
    selectedAccountId || '',
    prospectId,
  );

  const [activitiesToShow, setActivitiesToShow] = useState<(Task | Event)[]>(
    [],
  );

  const getDate = (activity: Task | Event) => {
    if (isTask(activity)) {
      return activity.activityDate;
    } else {
      return activity.activityDateTime;
    }
  };
  // const getSubtype = (activity: Task | Event) => {
  //   if (isTask(activity)) {
  //     return activity.taskSubtype;
  //   } else {
  //     return activity.eventSubtype;
  //   }
  // };

  useEffect(() => {
    const newActivitiesToShow = [
      ...(tasks?.data?.data || []),
      ...(events?.data?.data || []),
    ];
    setActivitiesToShow(
      newActivitiesToShow.sort((a, b) => {
        return new Date(getDate(b)).getTime() - new Date(getDate(a)).getTime();
      }),
    );
    return () => {
      setActivitiesToShow([]);
    };
  }, [tasks, events]);

  const { setEmailThreadContent } = useEmailThread();
  const { openWindow } = useWindow();
  const { setLinkedinContent } = useLinkedinContext();

  if (!account) return <></>;

  //Function to get the prospect given the Id found of the task
  // const getProspect = async (prospectId: string | null) => {
  //   if (!prospectId) return null;

  //   const prospect = await getProspectMutate({
  //     prospectId,
  //     withAccount: true,
  //     withExternalProperties: true,
  //     withSequenceInstance: true
  //   });

  //   return prospect.data?.data ?? null;
  // };

  const renderActivityIcon = (activity: Task | Event) => {
    if (isTask(activity)) {
      switch (activity.taskSubtype.toLowerCase()) {
        case 'call':
          return (
            <CallIcon
              className="activity-icon call-icon"
              color={colors.callScheduled}
            />
          );
        case 'email':
          if (activity.emailLog?.isBounced)
            return (
              <Tooltip arrow title={'Email bounced'}>
                <span className="activity-icon activity-icon-container email-icon">
                  <EmailBouncedIcon color={colors.inactive} />
                </span>
              </Tooltip>
            );
          if (activity.emailLog?.isAnswered)
            return (
              <Tooltip arrow title="Email answered">
                <span className="activity-icon activity-icon-container email-icon">
                  <EmailRepliedIcon color={colors.inactive} />
                </span>
              </Tooltip>
            );
          if ((activity.emailLog?.trackCount || 0) > 0)
            return (
              <Tooltip
                arrow
                title={
                  'Opened ' +
                  (activity.emailLog?.trackCount || 1) +
                  ((activity.emailLog?.trackCount || 1) > 1
                    ? ' times'
                    : ' time')
                }
              >
                <span className="activity-icon activity-icon-container email-icon">
                  <Badge
                    sx={{
                      '& .MuiBadge-badge': {
                        right: 8,
                        top: 3,
                        fontSize: '7px',
                        fontWeight: '700',
                        lineHeight: '8px',
                        color: colors.inactiveHover,
                      },
                    }}
                    badgeContent={activity.emailLog?.trackCount || 0}
                  >
                    <EmailOpenedBadgeIcon color={colors.inactive} />
                  </Badge>
                </span>
              </Tooltip>
            );
          else
            return (
              <Tooltip arrow title="Email not opened">
                <span>
                  <EmailIcon
                    className="activity-icon email-icon"
                    color={colors.inactive}
                  />
                </span>
              </Tooltip>
            );
        case 'linkedin':
          if (activity.linkedinLog?.linkedinType === LinkedinType.InMail)
            return (
              <Tooltip arrow title={'Linkedin InMail'}>
                <span className="activity-icon activity-icon-container inmail-icon">
                  <LinkedinIconInMailCompass
                    viewBox="1 1 21 21"
                    color={colors.purpleHover}
                  />
                </span>
              </Tooltip>
            );
          if (activity.linkedinLog?.linkedinType === LinkedinType.Invitation)
            return (
              <Tooltip arrow title={'Linkedin Invitation'}>
                <span className="activity-icon activity-icon-container linkedin-icon">
                  <LinkedinInvitationIcon />
                </span>
              </Tooltip>
            );
          if (activity.linkedinLog?.linkedinType === LinkedinType.VoiceNote)
            return (
              <Tooltip arrow title={'Linkedin Invitation'}>
                <span className="activity-icon activity-icon-container linkedin-icon">
                  <LinkedinIconInvoice
                    viewBox="1 1 20 20"
                    color={colors.blue}
                  />
                </span>
              </Tooltip>
            );
          else
            return (
              <Tooltip arrow title={'Linkedin Message'}>
                <span className="activity-icon activity-icon-container linkedin-icon">
                  <LinkedinMessageNewIcon color={colors.blue} />
                </span>
              </Tooltip>
            );
        default:
          return (
            <VideoIcon
              className="activity-icon meeting-icon"
              color={colors.nurture}
            />
          );
      }
    } else {
      switch (activity.eventSubtype.toLowerCase()) {
        case 'note':
          return (
            <NoteIcon
              className="activity-icon note-icon"
              color={colors.purple}
            />
          );
        case 'meeting':
          return (
            <VideoIcon
              className="activity-icon meeting-icon"
              color={colors.nurture}
            />
          );
        default:
          return (
            <VideoIcon
              className="activity-icon meeting-icon"
              color={colors.nurture}
            />
          );
      }
    }
  };

  const renderSubtitle = (activity: Task | Event) => {
    if (isTask(activity)) {
      switch (activity.taskSubtype.toLowerCase()) {
        case 'call':
          return activity.callLog?.callFeedback
            ? printCallFeedback(activity.callLog?.callFeedback)
            : '';
        case 'email':
          return (
            activity.emailLog?.subject || activity?.subject || 'No subject'
          );
        case 'linkedin':
          return activity.linkedinLog?.content;
        case 'note':
          return activity.description;
        case 'task':
          return activity.subject;
        default:
          return 'No subject found';
      }
    } else {
      return 'Meeting';
    }
  };

  const handleClickActivity = async (activity: Task | Event) => {
    if (isTask(activity)) {
      const subType = activity.taskSubtype.toLowerCase();
      switch (subType) {
        case 'email':
          // TODO: specific case for email log pending (hubspot)
          if (activity.emailLog?.isBounced) return;
          //In case of email I open the thread with the corresponding threadId
          const threadId = activity.emailLog?.threadId ?? null;
          // if (threadId === null) return;
          const prospect = activity.prospect;
          setEmailThreadContent({
            account: account,
            prospect: prospect,
            threadId: threadId,
            owned: activity?.owned ?? false,
            openReply: (_threadId: string | null, toAll = false) => {
              setEmailThreadContent(null);
              setTabValue(3);
              openWindow({
                windowType: 'account',
                tab: 'email',
                modalVisible: 'email',
                selectedProspectId: activity.prospectId ?? undefined,
                selectedThreadId: {
                  threadId,
                  toAll,
                },
              });
            },
            activity,
          });

          break;
        case 'linkedin':
          if (
            activity.linkedinLog?.linkedinType === LinkedinType.InMail ||
            !activity.owned
          ) {
            setLinkedinContent({
              prospect: activity.prospect,
              activity,
            });
          } else {
            setTabValue(4);
            openWindow({
              windowType: 'account',
              tab: 'linkedin',
              modalVisible: 'linkedin',
              selectedProspectId: activity.prospectId ?? undefined,
            });
          }
          break;
        case 'call':
          setTabValue(6);
          openWindow({
            windowType: 'account',
            tab: 'callLog',
            modalVisible: 'callLog',
            selectedProspectId: activity.prospectId ?? undefined,
            activity,
          });
          break;
        default:
          return null;
      }
      if (closeTooltip) closeTooltip();
    }
  };

  const renderTitle = (activity: Task | Event, prospect: Prospect | null) => {
    if (isTask(activity)) {
      switch (activity.taskSubtype.toLowerCase()) {
        case 'note':
          return 'Note';
        case 'call':
        case 'email':
        case 'linkedin':
        case 'task':
          return prospectName(prospect) ?? account.name ?? 'No name found';

        default:
          return 'No subject found';
      }
    } else {
      return prospectName(prospect) ?? 'Meeting';
    }
  };

  const renderLinks = (activity: Task | Event) => {
    if (isTask(activity)) {
      const links = activity.emailLog?.emailLinks?.map((link, idx) => {
        return (
          <div className="links-container" key={idx}>
            <div
              className="link-row"
              onClick={() => window.open(link?.url ?? '', '_blank')}
            >
              <LinkChainIconM />
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
                width={'155px'}
                noWrap
                paddingLeft={'12px'}
              >
                {link?.url}
              </Typography>
            </div>
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight={'16px'}
              color={colors.grey11}
              marginLeft={'20px'}
            >
              {link?.trackCount}
            </Typography>
          </div>
        );
      });
      return (
        <LinkUrlWrapper id="link-wrapper">
          <div className="link-headers">
            <Typography fontSize={12} color={colors.grey11}>
              Link
            </Typography>
            <Typography fontSize={12} color={colors.grey11}>
              Clicked
            </Typography>
          </div>
          {links}
        </LinkUrlWrapper>
      );
    }
  };

  const rowPlaceholder = rowPlaceholderFillOpacities.map((el, index) => {
    return (
      <div key={index} style={{ width: '100%', padding: '4px' }}>
        <img
          src={ActivityPlaceholderLinkedinMessage}
          alt="Empty contact placeholder"
          style={{
            opacity: (el - 0.15) / (index + 1),
            width: '100%',
            padding: '4px',
          }}
        />
        <img
          src={ActivityPlaceholderEmailOpened}
          alt="Empty contact placeholder"
          style={{
            opacity: (el - 0.25) / (index + 1),
            width: '100%',
            padding: '4px',
          }}
        />
        <img
          src={ActivityPlaceholderEmailReplied}
          alt="Empty contact placeholder"
          style={{
            opacity: (el - 0.35) / (index + 1),
            width: '100%',
            padding: '4px',
          }}
        />
        <img
          src={ActivityPlaceholderVideo}
          alt="Empty contact placeholder"
          style={{
            opacity: (el - 0.45) / (index + 1),
            width: '100%',
            padding: '4px',
          }}
        />
      </div>
    );
  });

  return (
    <div style={{ height: '100%' }}>
      {isLoadingTasks || isLoadingEvents ? (
        <FlexDiv>
          <CircularProgress />
        </FlexDiv>
      ) : (
        <>
          {!prospectId && (
            <Typography
              fontSize={16}
              fontWeight={600}
              lineHeight={'24px'}
              style={{ marginBottom: 12, marginLeft: 12 }}
            >
              Activities
            </Typography>
          )}
          <div
            className={
              prospectId ? 'prospect-activities-list' : 'activities-list'
            }
          >
            {activitiesToShow.length ? (
              activitiesToShow.map((activity, index) => {
                // eslint-disable-next-line no-console
                // console.log({ index, activity });
                // In order to show the separator of the date
                // let breakDate;
                // if (index === 0) {
                //   breakDate = (
                //     <Typography variant="body1" style={{ marginBottom: 16 }}>
                //       {new Date(task.activityDate).toLocaleDateString()}
                //     </Typography>
                //   );
                // } else if (
                //   new Date(task.activityDate).getDay() !==
                //   new Date(
                //     tasks?.data?.data[index - 1].activityDate ||
                //       new Date(task.activityDate).getDay()
                //   ).getDay()
                // ) {
                //   breakDate = (
                //     <div style={{ marginBottom: 16 }}>
                //       <div className="divider" />
                //       <Typography variant="body1">
                //         {new Date(task.activityDate).toLocaleDateString()}
                //       </Typography>
                //     </div>
                //   );
                // }
                const prospect = activity.prospect;
                const callDuration =
                  isTask(activity) && activity.callLog?.externalDuration
                    ? moment
                        .utc(activity.callLog.externalDuration * 1000)
                        .format('mm:ss')
                    : '';

                const callLogSummary = isTask(activity) && (
                  <>
                    <div
                      style={{
                        border: '1px solid #EAF1FB',
                        height: '16px',
                      }}
                    />
                    {callDuration}
                    <Tooltip
                      arrow
                      title={
                        !activity?.callLog?.externalDirectLink
                          ? 'No registration recorded'
                          : ''
                      }
                    >
                      <span
                        style={{ display: 'flex' }}
                        className={
                          activity?.callLog?.externalDirectLink ? '' : ''
                        }
                      >
                        <CallLogIcon color={colors.grey11} />
                      </span>
                    </Tooltip>
                  </>
                );

                return (
                  <div key={index}>
                    {/* {breakDate} */}
                    <div className="activity">
                      <div
                        className="activity-content"
                        onClick={() => handleClickActivity(activity)}
                      >
                        <div style={{ display: 'flex', overflow: 'hidden' }}>
                          {renderActivityIcon(activity)}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              overflow: 'hidden',
                            }}
                          >
                            <Typography
                              fontWeight={600}
                              margin={0}
                              marginTop={'2px'}
                              fontSize={'0.9rem'}
                              noWrap
                            >
                              {renderTitle(activity, prospect)}
                            </Typography>
                            <div
                              className={
                                isTask(activity) &&
                                activity?.callLog?.externalDirectLink
                                  ? 'call-log-icon'
                                  : 'call-log-unavailable'
                              }
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                color: `${colors.grey11}`,
                                width: '155px',
                              }}
                              onClick={(ev) => {
                                if (
                                  !isTask(activity) ||
                                  !activity?.callLog?.externalDirectLink
                                )
                                  return;
                                ev.stopPropagation();
                                ev.preventDefault();
                                if (activity?.callLog?.externalDirectLink) {
                                  window.open(
                                    activity.callLog.externalDirectLink,
                                    '_blank',
                                  );
                                }
                              }}
                            >
                              <Typography
                                color={colors.grey11}
                                fontSize={12}
                                margin={0}
                                noWrap
                              >
                                {renderSubtitle(activity)}
                              </Typography>
                              {/* call log component */}
                              {isTask(activity) &&
                                activity.callLog &&
                                activity.callLog.externalDirectLink &&
                                callLogSummary}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: 4,
                          }}
                        >
                          {isTask(activity) &&
                          activity.emailLog?.emailLinks?.length ? (
                            <LinksTooltip
                              placement="top"
                              title={renderLinks(activity)}
                            >
                              <span className="links-icon-container">
                                <LinkChainIconS
                                  color={colors.grey11}
                                  className="links-icon"
                                />
                              </span>
                            </LinksTooltip>
                          ) : null}
                          <Typography fontSize={12} width={85}>
                            {moment(getDate(activity)).format('ll')}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <FlexDiv
                direction="column"
                position="relative"
                justifyContent="start"
                style={{
                  overflow: 'hidden',
                }}
              >
                {rowPlaceholder}
                <FlexDiv direction="column" position="absolute" gap="12px">
                  <NoPreviousActivity />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <Typography
                      fontSize={'14px'}
                      fontWeight={500}
                      textAlign={'center'}
                      lineHeight={'18px'}
                      color={colors.grey1}
                    >
                      No previous activity
                    </Typography>
                  </div>
                </FlexDiv>
              </FlexDiv>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ActivitiesBox;
