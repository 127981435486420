import React, { useCallback, useEffect, useState } from 'react';
import { BasePropsExportDataModal, ExportDataModal, IColumnsToSelect } from '.';
import { useConditionalSnackBar } from 'context/snackbar';
import { Constants } from 'crono-fe-common/constants/constants';
import { getError } from 'crono-fe-common/utils';
import { useFilters } from 'pages/home/context/filters';
import { ProspectSearchColumns } from 'crono-fe-common/types/DTO/prospectSearchForExport';
import useSearchProspectsForExport from 'hooks/services/prospect/useSearchProspectsForExport';
import { useJuneAnalytics } from 'context/june';

interface IProps extends BasePropsExportDataModal {
  setExportInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  downloadCSV: (name: string, data: any, columns: any) => void;
  isLeadExport: boolean;
}

const prospectColumnsToSelect: IColumnsToSelect[] = [
  { label: 'First Name', value: 'firstName' },
  { label: 'Industry', value: 'industry' },

  { label: 'Last Name', value: 'lastName' },
  { label: 'Employees', value: 'numberOfEmployees' },

  { label: 'Company Name', value: 'companyName' },
  { label: 'Owner', value: 'owner' },

  { label: 'Email', value: 'email' },
  { label: 'Last Activity', value: 'lastActivityDate' },

  { label: 'Phone number', value: 'phone' },
  { label: 'Created Date', value: 'createdDate' },

  { label: 'Mobile phone number', value: 'mobilePhone' },
  { label: 'Linkedin', value: 'linkedin' },
];

const ExportDataModalContacts = ({
  onClose,
  total,
  exportInProgress,
  setExportInProgress,
  downloadCSV,
  isLeadExport,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const { searchProspectParameters, searchLeadParameters } = useFilters();

  const {
    data: prospectsForExport,
    mutate: searchProspectsForExport,
    error: prospectsForExportError,
  } = useSearchProspectsForExport();
  const [columnsSelectedForProspects, setColumnsSelectedForProspects] =
    useState<ProspectSearchColumns>({
      firstName: true,
      lastName: true,
      companyName: true,
      email: true,
      phone: true,
      mobilePhone: true,
      industry: true,
      numberOfEmployees: true,
      owner: true,
      lastActivityDate: true,
      createdDate: true,
      linkedin: true,
    });

  useEffect(() => {
    if (prospectsForExport?.data?.data?.length && exportInProgress) {
      const columns = [
        'id',
        ...Object.keys(columnsSelectedForProspects).filter(
          (key) =>
            columnsSelectedForProspects[key as keyof ProspectSearchColumns],
        ),
      ];
      const data = prospectsForExport.data.data.map((prospect, index) => {
        const prospectForExport: any = prospect;
        prospectForExport.id = index + 1;

        return prospectForExport;
      });
      downloadCSV(
        isLeadExport
          ? Constants.defaultExportLeadsCSVName
          : Constants.defaultExportProspectsCSVName,
        data,
        columns,
      );

      if (analytics) {
        analytics.track('export-contacts', {
          count: prospectsForExport.data.data.length,
        });
      }

      onClose();
    }
  }, [prospectsForExport]);

  const handleSubmit = useCallback(
    (columns: ProspectSearchColumns) => {
      setExportInProgress(true);
      searchProspectsForExport({
        ...(isLeadExport ? searchLeadParameters : searchProspectParameters),
        limit: Constants.defaultExportDataLimit,
        columns,
      });
    },
    [
      setExportInProgress,
      searchProspectParameters,
      searchLeadParameters,
      isLeadExport,
      searchProspectsForExport,
    ],
  );

  useConditionalSnackBar([
    {
      condition: !!prospectsForExportError,
      message:
        getError(prospectsForExportError) ?? 'Error on exporting contacts',
      severity: 'error',
    },
  ]);

  return (
    <ExportDataModal
      columnsSelected={columnsSelectedForProspects}
      setColumnsSelected={setColumnsSelectedForProspects}
      exportDataName={isLeadExport ? 'leads' : 'contacts'}
      onClose={onClose}
      onSubmit={handleSubmit}
      optionsToSelect={prospectColumnsToSelect}
      exportInProgress={exportInProgress}
      total={total}
    />
  );
};

export default ExportDataModalContacts;
