import { FC, createContext, useContext, useMemo, useState } from 'react';
import { EmailThreadContextType, EmailThreadContent } from './types';
import { CloseButton } from 'crono-fe-common/components/CronoButton';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import EmailThreadModal from './EmailThreadModal';
import EmailLogModal from './EmailLogModal';
import { useAuth } from 'context/auth';
import useGetTaskEmail from 'hooks/services/event/useGetTaskEmail';

export const EmailThreadContext = createContext<
  EmailThreadContextType | undefined
>(undefined);

// Provider of the sideTab
const EmailThreadProvider: FC<{ children: any }> = ({ children }) => {
  const [emailThreadContent, setEmailThreadContent] =
    useState<EmailThreadContent | null>(null);
  const { user } = useAuth();
  //If there is no user I prevent the window from opening
  const open = useMemo(() => {
    if (!user) return undefined;
    return !!emailThreadContent;
  }, [user, emailThreadContent]);

  //We retrieve the emailTask so that if we don't have the activity we can show something
  const { data: emailTask } = useGetTaskEmail(
    emailThreadContent?.prospect?.objectId ?? null,
    emailThreadContent?.emailId ?? null,
  );

  return (
    <EmailThreadContext.Provider
      value={{ setEmailThreadContent, emailThreadContent: emailThreadContent }}
    >
      {open && emailThreadContent ? (
        emailThreadContent.threadId === null || !emailThreadContent.owned ? (
          <div className="email-thread-container">
            <CloseButton
              size="large"
              onClick={() => setEmailThreadContent(null)}
            >
              <CloseMIcon color={colors.grey11} />
            </CloseButton>
            <EmailLogModal
              prospect={emailThreadContent.prospect}
              activity={
                emailThreadContent.activity ??
                emailTask?.data?.data ??
                undefined
              }
            />
          </div>
        ) : (
          <div
            className="email-thread-container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <CloseButton
              size="large"
              onClick={() => setEmailThreadContent(null)}
              style={{ width: 'fit-content' }}
            >
              <CloseMIcon color={colors.grey11} />
            </CloseButton>
            <EmailThreadModal
              openReply={emailThreadContent.openReply}
              owned={emailThreadContent.owned}
              prospect={emailThreadContent.prospect}
              threadId={emailThreadContent.threadId}
            />
          </div>
        )
      ) : null}
      {children}
    </EmailThreadContext.Provider>
  );
};

export function useEmailThread() {
  const context = useContext(EmailThreadContext);
  if (context === undefined) {
    throw new Error(`useEmailThread must be used within a EmailThreadProvider`);
  }

  return context;
}

export default EmailThreadProvider;
