import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as LeftArrowIcon } from 'crono-fe-common/icons/Left-Arrow.svg';
import { ReactComponent as RightArrowIcon } from 'crono-fe-common/icons/Right-Arrow.svg';
import CalendarIcon from 'crono-fe-common/icons/Calendar-Icon';
import useGetTasksAggregate from 'hooks/services/task/useGetTasksAggregate';
import moment from 'moment';
import { colors } from 'crono-fe-common/theme';
import { TaskPageTab } from '..';

export const dateCompareUTC = (a: Date, b: Date) => {
  return (
    a.getUTCFullYear() === b.getUTCFullYear() &&
    a.getUTCMonth() === b.getUTCMonth() &&
    a.getUTCDate() === b.getUTCDate()
  );
};

interface IProps {
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  minDate: Date;
  setSelectedTab: React.Dispatch<React.SetStateAction<TaskPageTab>>;
}

const WeekCalendar = ({
  currentDate,
  setCurrentDate,
  minDate,
  setSelectedTab,
}: IProps) => {
  // initialDate used to fetch/update aggregate tasks only for current week
  const [initialDate, setInitialDate] = useState<Date>(currentDate);
  const today = useMemo(() => new Date(), []);

  const handleChangeDate = (increment: number) => {
    const newDate = new Date(initialDate);
    newDate.setDate(newDate.getDate() + increment);
    setInitialDate(newDate);
  };

  //I get the aggretaed tasks for the week
  const { data: numberOfTasks } = useGetTasksAggregate(
    initialDate,
    new Date(new Date(initialDate).setDate(initialDate.getDate() + 7)),
  );

  const handleChangeDatePicker = (newDate: Date) => {
    setInitialDate(newDate);
    setCurrentDate(newDate);
    setSelectedTab('selectedDate');
  };
  const handlePickedDate = (day: Date) => {
    setCurrentDate(day);
    setSelectedTab('selectedDate');
  };

  return (
    <div
      id="week-calendar"
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        paddingLeft: '24px',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="select-date-time-button"
          minDate={minDate}
          value={currentDate}
          onChange={(newValue) => {
            if (newValue) handleChangeDatePicker(newValue);
          }}
          slotProps={{
            popper: { style: { zIndex: 9999 } },
            textField: {
              variant: 'standard',
              InputProps: { disableUnderline: true },
            },
          }}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
          sx={{
            fontSize: 12,
            '& .MuiInput-input': {
              display: 'none',
            },
            '& .MuiInputAdornment-root': {
              margin: 0,
            },
            '& .MuiIconButton-root': {
              width: '52px',
              height: '52px',
              margin: 0,
              borderRadius: '8px',
              border: `1px solid ${colors.grey444}`,
            },
          }}
        />
      </LocalizationProvider>

      <div className="calendar">
        <LeftArrowIcon
          className="arrow-icon"
          onClick={() => handleChangeDate(-1)}
        />
        {[0, 1, 2, 3, 4, 5, 6].map((offset) => {
          const day = new Date(initialDate);
          day.setDate(initialDate.getDate() + offset);
          // server dates are UTC:0
          const nTask = numberOfTasks?.data?.data.find((d) => {
            return dateCompareUTC(day, new Date(d.date));
          })?.quantity;
          const selectedDate = dateCompareUTC(day, currentDate);
          const isToday = dateCompareUTC(day, today);
          const isPastDate = moment(day).isBefore(moment(today));
          return (
            <div
              className={`day ${selectedDate ? 'selected' : 'not-selected'} ${
                isToday ? 'today' : ''
              } ${isPastDate ? 'past-day' : ''}`}
              key={offset}
              onClick={() => handlePickedDate(day)}
            >
              <div className="day-name">{moment(day).format('ddd')}</div>
              <Typography className={'day-number'}>
                {nTask && (
                  <div
                    className={`number-of-tasks ${
                      selectedDate ? 'picked' : ''
                    }`}
                  >
                    {nTask}
                  </div>
                )}
                {moment(day).format('D')}
              </Typography>
            </div>
          );
        })}
        <RightArrowIcon
          className="arrow-icon"
          onClick={() => handleChangeDate(1)}
        />
      </div>
    </div>
  );
};

export default WeekCalendar;
