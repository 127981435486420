import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SequenceInstance } from 'crono-fe-common/types/sequenceInstance';

export default function useGetSequenceInstance(
  sequenceInstanceId: number,
  prospectId: string,
  withLogs: boolean,
) {
  const queryParams = queryString.stringify({
    sequenceInstanceId,
    prospectId,
    withLogs,
  });

  const request: Request = {
    url: `${ENDPOINTS.sequence.prospect}?${queryParams}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.sequence.prospect, sequenceInstanceId],
    queryFn: () => client(request),
    enabled: sequenceInstanceId > 0,
  };

  const { data, ...rest } = useQuery<Response<SequenceInstance>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
