import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type DeleteAllConditionalsParams = {
  strategyId: number;
  invitationStepId: number;
};

export default function useDeleteAllConditionals() {
  const queryClient = useQueryClient();

  const deleteAllConditionals = (params: DeleteAllConditionalsParams) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.conditional.invitation}`,
      config: {
        method: 'delete',
        data: { data: params },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    DeleteAllConditionalsParams
  >(deleteAllConditionals, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
    },
  });

  return { data, mutate, ...rest };
}
