import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AccountStatus } from 'crono-fe-common/types/account';
import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';

export interface OtherSettingsInputs {
  hasLinkedin?: boolean;
  hasEmail?: boolean;
  hasCall?: boolean;
  syncStatus?: AccountStatus;
  emailIntegrationType?: EmailIntegrationType | null;
}

export default function useEditOtherSettings() {
  const editOtherSettings = (otherSettingsInputs: OtherSettingsInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.otherSettings}`,
      config: {
        method: 'patch',
        data: otherSettingsInputs,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    OtherSettingsInputs
  >(editOtherSettings, {});

  return { data, mutate, ...rest };
}
