import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { TableType } from 'crono-fe-common/types/externalProperty';
import { Sync } from 'crono-fe-common/types/sync';

export default function useGetApplyStatusMigration(TableType: TableType) {
  const queryParams = new URLSearchParams({
    TableType: TableType.toString(),
  }).toString();
  const request: Request = {
    url: `${ENDPOINTS.applyStatusMigration.main}?${queryParams}`,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.applyStatusMigration.main, TableType],
    queryFn: () => client(request),
    staleTime: 30 * 1000,
  };

  const { data, ...rest } = useQuery<Response<Sync>, CronoError>(requestConfig);

  return { data, ...rest };
}
