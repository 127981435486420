import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export interface UserSubscription {
  firstName: string;
  lastName: string;
  id: number;
  email: string;
}

export default function useGetUsers(enabled = true) {
  const request: Request = {
    url: ENDPOINTS.subscription.users.main,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.subscription.users.main],
    queryFn: () => client(request),
    enabled,
    staleTime: 180 * 60 * 1000,
  };

  const { data, ...rest } = useQuery<Response<UserSubscription[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
