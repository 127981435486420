import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { NextTaskSearch } from 'crono-fe-common/types/DTO/nextTaskSearch';

export default function useSearchNextTask(
  searchParameters: NextTaskSearch,
  enabled = true,
) {
  const request: Request = {
    url: ENDPOINTS.task.next,
    config: {
      method: 'post',
      //If no date is passed I get the next of today
      data: { data: { date: new Date(), ...searchParameters } },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.task.next, searchParameters],

    queryFn: () => client(request),
    enabled,
  };

  return useQuery<
    Response<TaskTodo | null> & {
      data: {
        nextId: number | null;
        count: number;
      };
    },
    CronoError
  >(requestConfig);
}
