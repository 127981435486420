import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const StrategyPreviewModalWrapper = styled.div`
  .strategy-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 930px;
    height: 670px;
    background-color: ${colors.white};
    border-radius: 16px;
    overflow: hidden;
    box-sizing: border-box;

    &__top {
      padding: 48px 0 0 48px;
    }

    &__cross {
      position: absolute;
      top: 24px;
      right: 0;
    }

    &__title {
      margin-bottom: 16px;
    }

    &__preview {
      height: 480px;
      overflow-y: scroll;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 48px 0px 42px;
      height: 96px;
      border-top: 1px solid ${colors.grey33};
      background-color: ${colors.grey6};
      z-index: 1000;

      &-right {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    &__language-preview {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;
