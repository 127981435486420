import { gatewayService } from 'crono-fe-common/hooks/crono-extension/gateway';
import { CronoGatewayBackgroundGetExtensionVersion } from 'crono-fe-common/types/crono-extension/background-script';
import { useQuery } from 'react-query';

export default function useGetExtensionVersion() {
  return useQuery({
    queryKey: 'getExtensionVersion',
    queryFn: async () => {
      return await gatewayService.execute<CronoGatewayBackgroundGetExtensionVersion>(
        {
          target: 'background-script',
          methodName: 'getExtensionVersion',
          params: {},
        },
      );
    },
    refetchInterval: 60_000,
  });
}
