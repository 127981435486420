import { Avatar, Checkbox, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import { MainPrimarySmallButton } from 'crono-fe-common/components/CronoButton';
import { colors } from 'crono-fe-common/theme';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import useEditAccount from 'hooks/services/account/useEditAccount';
import { useConditionalSnackBar } from 'context/snackbar';
import {
  getError,
  mapAccountToAccountLinkedinAndCorrect,
} from 'crono-fe-common/utils';
import SimilarCompanyModal from './similarCompanyModal';
import { useGetAsyncQueueState } from 'hooks/services/crono-extension/useGetAsyncQueueState';
import { CompanyForSearchTable } from '../model';
import { useAuth } from 'context/auth';
import IconCheckedCrono from 'crono-fe-common/icons/Icon-Checked-Crono';
import IconRedirect from 'crono-fe-common/icons/Icon-Redirect';
import { SimilarContactsButton } from 'pages/searchComponent/searchContactTab/contactInsertCard/style';
import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import {
  useBackgroundMarkJobsAsRead,
  useLinkedinGetCompanyInfoFromNumericId,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import { useSelectedAccount } from 'pages/home/context/account';
import { useWindow } from 'context/sideTab';
import { FindNewTableCell } from 'pages/searchComponent/searchContactTab/contactInsertCard';
import {
  defaultColumnsCompaniesLinkedin,
  defaultColumnsCompaniesSalesNav,
} from 'pages/searchComponent/editColumnsModal';
import CompanyInformationColumns from './companyInformationColumns';

interface IProps {
  company: CompanyForSearchTable;
  selected: string[];
  handleSelectCompany: (numericId: string) => void;
  handleAddClick: (company: CompanyForSearchTable) => void;
  setIsCompanySelectable: (value: boolean) => void;
  handleChangeSingleCompany: (company: CompanyForSearchTable) => void;
  alreadyEnriching: boolean;
  setAlreadyEnriching: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyInsertCard = ({
  company,
  selected,
  handleSelectCompany,
  handleAddClick,
  setIsCompanySelectable,
  handleChangeSingleCompany,
  alreadyEnriching,
  setAlreadyEnriching,
}: IProps) => {
  const { user, hasSalesNavWithCookie } = useAuth();

  const [accountInCrono, setAccountInCrono] = useState<boolean>(false);
  const [isAccountImporting, setIsAccountImporting] = useState<boolean>(false);

  const { data: queue } = useGetAsyncQueueState();

  //This state is used to know if the user has already tried to import this company in order to avoid multiple execution of the effect
  const [isImportedChecked, setIsImportedChecked] = useState<boolean>(false);

  useEffect(() => {
    const isImporting = Boolean(
      queue?.companiesToImport.find(
        (c) =>
          c.account.linkedinNumericId === company.numericId ||
          c.account.linkedinPublicId === company.id,
      ),
    );

    setIsAccountImporting(isImporting);

    //If we try to import we reset also the state to check if we already tried to import to get the new result
    if (isImporting) {
      setIsImportedChecked(false);
    }

    // Hack to make "Select all" button do not select companies that are already in queue to be imported
    if (isImporting) {
      setIsCompanySelectable(false);
    }
  }, [queue?.companiesToImport]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleShowError = (message: string) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  const { call: markJobsAsRead } = useBackgroundMarkJobsAsRead();

  useEffect(() => {
    //If we have already checked if the company is imported we don't have to do it again
    if (queue && queue.importedCompanies.length > 0 && !isImportedChecked) {
      const importedCompany = queue.importedCompanies.find(
        (c) =>
          c.account.linkedinNumericId === company.numericId ||
          c.account.linkedinPublicId === company.id,
      );
      if (!importedCompany) return;
      setIsImportedChecked(true);
      markJobsAsRead({
        jobIds: [importedCompany.id],
      });
      if (importedCompany.error) {
        handleShowError(
          importedCompany.error === 'missingCookie'
            ? 'Error while importing, check to be logged into linkedin with Crono'
            : importedCompany.error,
        );
        setIsAccountImporting(false);
        setIsCompanySelectable(true);
        return;
      }
      setIsAccountImporting(false);
      const newAccount = mapAccountToAccountLinkedinAndCorrect(
        importedCompany.accountCreated ?? null,
      );
      handleChangeSingleCompany({
        ...company,
        ...(newAccount
          ? {
              similarCompanies: [
                {
                  ...newAccount,
                  correct: true,
                },
              ],
            }
          : {}),
        correct: true,
      });
      setIsCompanySelectable(false);
      setAccountInCrono(true);
    }
  }, [queue?.importedCompanies]);

  const columnsToShow = useMemo(() => {
    if (!user) return '';
    const columns = (
      user.userPreferences.findNewAccountColumns ??
      (hasSalesNavWithCookie
        ? defaultColumnsCompaniesSalesNav
        : defaultColumnsCompaniesLinkedin)
    ).split(',');
    return columns;
  }, [user?.userPreferences.findNewAccountColumns, hasSalesNavWithCookie]);

  const [similarAccounts, setSimilarAccounts] = useState<
    AccountLinkedin[] | null
  >(null);

  useEffect(() => {
    if (company.correct) {
      setAccountInCrono(true);
      setIsCompanySelectable(false);
      setSimilarAccounts(null);
      return;
    }
    //If we haven't already loaded the simila companies we check if we have to
    if (similarAccounts !== null) return;
    if (company.similarCompanies && company.similarCompanies.length > 0) {
      setIsCompanySelectable(false);
      setSimilarAccounts(company.similarCompanies);
    } else {
      setAccountInCrono(false);
      setIsCompanySelectable(true);
      setSimilarAccounts(null);
    }
  }, [company, company.correct]);

  const [showLinkedinSimilarCompany, setShowLinkSimilarCompany] =
    useState<boolean>(false);

  const {
    mutateAsync: editAccount,
    isSuccess: accountEdited,
    error: errorEditingAccount,
  } = useEditAccount();

  const { call: getCompanyInfo } = useLinkedinGetCompanyInfoFromNumericId();

  const handleClickLink = async (account: AccountLinkedin) => {
    const numericId = company.numericId;
    let newUrl = company.url;
    let website = company.website;
    //If we are in a salesNav situation, we don't have the real url, so we have to parse the numericId to publicId and then to url
    if (hasSalesNavWithCookie && numericId) {
      const companyInfo = await getCompanyInfo({ numericId: numericId });
      if (!companyInfo?.publicId) {
        handleShowError(
          'Error while retrieving the information for the contact, please try again later',
        );
        return;
      }
      newUrl = companyInfo.url ?? '';
      website = companyInfo.website ?? '';
    }

    const res = await editAccount({
      accountId: account.objectId,
      linkedin: newUrl,
      website: website,
      linkedinNumericId: numericId,
    });
    if (res.data.isSuccess) {
      handleChangeSingleCompany({
        ...company,
        correct: true,
        similarCompanies: [{ ...account, correct: true }],
      });
      setAccountInCrono(true);
      setIsCompanySelectable(false);
      setShowLinkSimilarCompany(false);
    }
  };

  useConditionalSnackBar([
    {
      condition: !!errorEditingAccount,
      message: getError(errorEditingAccount) ?? 'Error while editing company',
      severity: 'error',
    },
    {
      condition: accountEdited,
      message: 'Company linked successfully',
      severity: 'success',
    },
    {
      condition: !!errorMessage,
      message: errorMessage ?? 'Error while processing the operation',
      severity: 'error',
    },
  ]);
  const handleRemoveSingleSimilarCompany = (index: number) => {
    if (!similarAccounts) return;
    const newSimilar = similarAccounts.filter((_, i) => i !== index);
    //If there are no more similar we can now select this company and we close the modal
    if (newSimilar.length === 0) {
      setShowLinkSimilarCompany(false);
      setIsCompanySelectable(true);
    }
    setSimilarAccounts(newSimilar);
  };

  const disableRow = isAccountImporting || accountInCrono;
  const [isHover, setIsHover] = useState(false);
  const showBackgroundHover = isHover && !disableRow;

  const { setSelectedAccountId } = useSelectedAccount();
  const { openWindow } = useWindow();

  const handleOpenCompanyInCrono = () => {
    const correctAccount = company.similarCompanies?.find((a) => a.correct);
    if (!correctAccount) return;
    setSelectedAccountId(correctAccount.objectId);
    openWindow({
      windowType: 'account',
      tab: 'account',
    });
  };

  return (
    <>
      {showLinkedinSimilarCompany && (
        <SimilarCompanyModal
          close={() => setShowLinkSimilarCompany(false)}
          similarAccounts={similarAccounts ?? []}
          handleClickLink={handleClickLink}
          // isLoading={isAccountsLinkedinLoading}
          removeSimilarCompany={handleRemoveSingleSimilarCompany}
        />
      )}
      <TableRow
        style={{
          opacity: disableRow ? 0.55 : 1,
          background: showBackgroundHover ? colors.grey6 : colors.white,
          verticalAlign: 'text-top',
        }}
        onMouseEnter={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          setIsHover(true);
        }}
        onMouseLeave={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          setIsHover(false);
        }}
      >
        {/*Avatar and name */}
        <FindNewTableCell
          style={{
            position: 'sticky',
            left: 0,
            minWidth: 320,
            width: 320,
            overflow: 'hidden',
            padding: '8px 12px',
            background: showBackgroundHover ? colors.grey6 : colors.white,
            borderRight: `1.5px solid ${colors.grey33}`,
            zIndex: 1,
          }}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 16,
              minWidth: 320,
              width: 320,
              overflow: 'hidden',
            }}
          >
            <Tooltip
              arrow
              enterDelay={400}
              enterNextDelay={400}
              title={
                (similarAccounts?.length ?? 0) > 0
                  ? 'Similar accounts found, click on "Show Similar" to check the existing companies'
                  : null
              }
            >
              <div style={{ height: 'fit-content', width: 'fit-content' }}>
                <Checkbox
                  checked={
                    accountInCrono ||
                    (company.id ? selected.includes(company.id) : false)
                  }
                  style={{ borderRadius: 24, padding: 0 }}
                  checkedIcon={
                    accountInCrono ? <IconCheckedCrono /> : <CheckedIcon />
                  }
                  icon={
                    <IconUnchecked
                      color={
                        isAccountImporting || (similarAccounts?.length ?? 0) > 0
                          ? colors.grey6
                          : colors.grey2
                      }
                    />
                  }
                  onClick={() => {
                    if (company.id && !accountInCrono)
                      handleSelectCompany(company.id);
                  }}
                  disabled={
                    accountInCrono ||
                    isAccountImporting ||
                    (similarAccounts?.length ?? 0) > 0
                  }
                />
              </div>
            </Tooltip>
            <Avatar
              key={company.pictureUrl}
              src={company.pictureUrl ?? ''}
              className="contact-logo"
              sx={{
                width: '24px !important',
                height: '24px !important',
              }}
              onClick={() => {
                if (company.id && !accountInCrono)
                  handleSelectCompany(company.id);
              }}
            />
            <div style={{ width: '100%', overflow: 'hidden' }}>
              <Tooltip
                title={company.name}
                enterDelay={800}
                enterNextDelay={800}
                arrow
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  lineHeight={'18px'}
                  className="contact-name"
                  noWrap
                  onClick={() => {
                    if (company.id && !accountInCrono)
                      handleSelectCompany(company.id);
                  }}
                >
                  {company.name}
                </Typography>
              </Tooltip>
            </div>
          </span>
        </FindNewTableCell>
        {/*Industry */}
        {columnsToShow.includes('companyIndustry') && (
          <FindNewTableCell
            style={{
              paddingLeft: 12,
              width: 250,
              minWidth: 250,
              borderRight: `1px solid ${colors.grey444}`,
            }}
          >
            <span
              style={{
                width: 250,
                minWidth: 250,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <Typography
                fontSize={14}
                lineHeight={'24px'}
                color={colors.grey11}
                noWrap
              >
                {company.industry}
              </Typography>
            </span>
          </FindNewTableCell>
        )}
        <CompanyInformationColumns
          company={company}
          columnsToShow={columnsToShow}
          setCompany={handleChangeSingleCompany}
          setIsCompanySelectable={setIsCompanySelectable}
          alreadyEnriching={alreadyEnriching}
          setAlreadyEnriching={setAlreadyEnriching}
          handleShowError={handleShowError}
        />
        {/*Link */}
        {columnsToShow.includes('linkedinLink') && (
          <FindNewTableCell
            style={{
              paddingLeft: 12,
              minWidth: 400,
              width: 400,
            }}
          >
            <span
              style={{
                minWidth: 400,
                width: 400,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                gap: 4,
              }}
            >
              <Typography
                fontSize={14}
                lineHeight={'24px'}
                color={colors.grey11}
                noWrap
              >
                {company.urlForRedirect}
              </Typography>

              <IconRedirect
                onClick={() => {
                  window.open(company.urlForRedirect, '_blank');
                }}
                color={colors.grey2}
                className="redirect-icon-contact-row"
              />
            </span>
          </FindNewTableCell>
        )}
        {/*Add button */}
        <FindNewTableCell
          style={{
            width: 106,
            minWidth: 106,
            position: 'sticky',
            right: 0,
            background: showBackgroundHover ? colors.grey6 : colors.white,
            padding: '8px 12px',
            borderLeft: `1.5px solid ${colors.grey33}`,
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 32,
            }}
          > */}
          {accountInCrono ? (
            <span
              className="message"
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 14,
                lineHeight: '18px',
                fontWeight: 500,
                color: colors.grey11,
                gap: 4,
                cursor: 'pointer',
              }}
              onClick={handleOpenCompanyInCrono}
            >
              In Crono{' '}
              <IconRedirect
                color={colors.grey11}
                style={{ width: 20, height: 20 }}
              />
            </span>
          ) : similarAccounts && similarAccounts?.length > 0 ? (
            //Button to open the similar option to allow the user to remove those

            <SimilarContactsButton
              startIcon={<IconMCompany color={colors.primaryDark} />}
              onClick={() => setShowLinkSimilarCompany(true)}
              fullWidth
              style={{
                width: '100%',
                minWidth: 88,
              }}
            >
              Show Similar
            </SimilarContactsButton>
          ) : isAccountImporting ? (
            <div
              className="importing-loader-container"
              style={{ width: 78, height: 32 }}
            >
              <span className="loader importing-loader"></span>
            </div>
          ) : (
            <MainPrimarySmallButton
              startIcon={<PlusIcon color={colors.white} />}
              onClick={() => handleAddClick(company)}
              style={{ minWidth: 78, height: 32 }}
              fullWidth
            >
              Add
            </MainPrimarySmallButton>
          )}
          {/* </div> */}
        </FindNewTableCell>
      </TableRow>
    </>
  );
};

export const AboutContainer = ({ about }: { about: string }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [hasToOverflow, setHasToOverflow] = useState<boolean>(false);
  const measuredRef = useCallback((node: HTMLParagraphElement) => {
    if (node !== null) {
      //The height is actyally 24, since we forced 18 we have to add 6 so that
      //we can show the "More" button
      setHasToOverflow(node.scrollHeight > node.clientHeight + 6);
    }
  }, []);
  return (
    <span
      style={{
        display: 'flex',
        alignItems: expanded ? 'flex-end' : 'center',
        overflow: 'hidden',
      }}
    >
      <Typography
        fontSize={14}
        lineHeight={'18px'}
        color={colors.grey11}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: expanded ? 'fit-content' : '18px',
          flex: 1,
        }}
        ref={measuredRef}
      >
        {about}
      </Typography>
      {(hasToOverflow || expanded) && (
        <div className="inner-toggle-button">
          <Typography
            fontSize={'12px'}
            fontWeight={500}
            lineHeight={'18px'}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              setExpanded((prev) => !prev);
            }}
          >
            {expanded ? 'Less' : 'More'}
          </Typography>
        </div>
      )}
    </span>
  );
};

export default CompanyInsertCard;
