import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { AccountSearchForExport } from 'crono-fe-common/types/DTO/accountSearchForExport';
import { AccountForExport } from 'crono-fe-common/types/accountForExport';

export default function useSearchAccountsForExport() {
  const searchAccountsForExport = (
    searchParameters: AccountSearchForExport,
  ) => {
    const { selected, ...rest } = searchParameters;

    const request: Request = {
      url: ENDPOINTS.account.search.export,
      config: {
        method: 'post',
        data: { data: rest },
      },
    };

    return client(request);
  };

  return useMutation<
    ResponsePaginated<AccountForExport[]>,
    CronoError,
    AccountSearchForExport
  >(searchAccountsForExport, {});
}
