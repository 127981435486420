import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const EditPencilIconS = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        d="M11.6933 6.74449L9.25354 4.30469"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16813 13.9986H2.00098L2.00098 11.8314C2.00098 11.6548 2.07097 11.4848 2.19629 11.3602L11.0849 2.47087C11.3455 2.21023 11.7675 2.21023 12.0275 2.47087L13.528 3.97142C13.7887 4.23206 13.7887 4.65403 13.528 4.91401L4.63943 13.8033C4.51477 13.9286 4.34479 13.9986 4.16813 13.9986V13.9986Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditPencilIconS;
