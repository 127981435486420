import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ProspectProgressWrapper = styled.span`
  display: flex;
  align-items: center;
  padding-left: 0;
  cursor: pointer;
  .slider {
    width: 32px;
    height: 3px;
    cursor: initial;
  }
  .slider > .MuiSlider-thumb {
    display: none;
  }
`;
