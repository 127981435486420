import { CompanyForSearchTable } from 'pages/searchComponent/searchCompanyTab/model';
import { ContactForSearchTable } from 'pages/searchComponent/searchContactTab/model';
import React, { createContext, FC, useContext, useState } from 'react';

interface FindNewTabContextType {
  companiesListPersistent: CompanyForSearchTable[] | null;
  setCompaniesListPersistent: React.Dispatch<
    React.SetStateAction<CompanyForSearchTable[] | null>
  >;
  countCompaniesListPersistent: number | null;
  setCountCompaniesListPersistent: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  totalCompaniesListPersistent: number | null;
  setTotalCompaniesListPersistent: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  initialPageToLoadCompaniesListPersistent: number | null;
  setInitialPageToLoadCompaniesListPersistent: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  nextPageToLoadCompaniesListPersistent: number | null;
  setNextPageToLoadCompaniesListPersistent: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  filtersUserCompaniesListPersistent: any;
  setFiltersUserCompaniesListPersistent: React.Dispatch<
    React.SetStateAction<any>
  >;
  contactsListPersistent: ContactForSearchTable[] | null;
  setContactsListPersistent: React.Dispatch<
    React.SetStateAction<ContactForSearchTable[] | null>
  >;
  countContactsListPersistent: number | null;
  setCountContactsListPersistent: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  totalContactsListPersistent: number | null;
  setTotalContactsListPersistent: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  initialPageToLoadContactsListPersistent: number | null;
  setInitialPageToLoadContactsListPersistent: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  nextPageToLoadContactsListPersistent: number | null;
  setNextPageToLoadContactsListPersistent: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  filtersUserContactsListPersistent: any;
  setFiltersUserContactsListPersistent: React.Dispatch<
    React.SetStateAction<any>
  >;
}

export const FindNewTabContext = createContext<
  FindNewTabContextType | undefined
>(undefined);

FindNewTabContext.displayName = 'FindNewTabContext';

const FindNewTabProvider: FC<{
  children: any;
}> = ({ children }) => {
  const [companiesListPersistent, setCompaniesListPersistent] = useState<
    CompanyForSearchTable[] | null
  >(null);
  const [countCompaniesListPersistent, setCountCompaniesListPersistent] =
    useState<number | null>(null);
  const [totalCompaniesListPersistent, setTotalCompaniesListPersistent] =
    useState<number | null>(null);
  const [
    initialPageToLoadCompaniesListPersistent,
    setInitialPageToLoadCompaniesListPersistent,
  ] = useState<number | null>(null);
  const [
    nextPageToLoadCompaniesListPersistent,
    setNextPageToLoadCompaniesListPersistent,
  ] = useState<number | null>(null);
  const [
    filtersUserCompaniesListPersistent,
    setFiltersUserCompaniesListPersistent,
  ] = useState<any>(null);

  const [contactsListPersistent, setContactsListPersistent] = useState<
    ContactForSearchTable[] | null
  >(null);
  const [countContactsListPersistent, setCountContactsListPersistent] =
    useState<number | null>(null);
  const [totalContactsListPersistent, setTotalContactsListPersistent] =
    useState<number | null>(null);
  const [
    initialPageToLoadContactsListPersistent,
    setInitialPageToLoadContactsListPersistent,
  ] = useState<number | null>(null);
  const [
    nextPageToLoadContactsListPersistent,
    setNextPageToLoadContactsListPersistent,
  ] = useState<number | null>(null);
  const [
    filtersUserContactsListPersistent,
    setFiltersUserContactsListPersistent,
  ] = useState<any>(null);

  return (
    <FindNewTabContext.Provider
      value={{
        companiesListPersistent,
        setCompaniesListPersistent,
        countCompaniesListPersistent,
        setCountCompaniesListPersistent,
        totalCompaniesListPersistent,
        setTotalCompaniesListPersistent,
        initialPageToLoadCompaniesListPersistent,
        setInitialPageToLoadCompaniesListPersistent,
        nextPageToLoadCompaniesListPersistent,
        setNextPageToLoadCompaniesListPersistent,
        filtersUserCompaniesListPersistent,
        setFiltersUserCompaniesListPersistent,
        contactsListPersistent,
        setContactsListPersistent,
        countContactsListPersistent,
        setCountContactsListPersistent,
        totalContactsListPersistent,
        setTotalContactsListPersistent,
        nextPageToLoadContactsListPersistent,
        setNextPageToLoadContactsListPersistent,
        initialPageToLoadContactsListPersistent,
        setInitialPageToLoadContactsListPersistent,
        filtersUserContactsListPersistent,
        setFiltersUserContactsListPersistent,
      }}
    >
      {children}
    </FindNewTabContext.Provider>
  );
};

export default FindNewTabProvider;

export function useFindNewTabContext() {
  const context = useContext(FindNewTabContext);
  if (context === undefined) {
    throw new Error(
      `useFindNewTabContext must be used within a FindNewTabProvider`,
    );
  }

  return context;
}
