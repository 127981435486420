import { Request } from 'crono-fe-common/types/request';
import client from 'utils/clients/client';
import { ENDPOINTS } from '../../../config/endpoints';

export type PipedriveVerifyIntegrationResult = {
  data: { isSuccess: boolean };
};

class PipedriveService {
  private _alreadyRunningVerifyPipedriveIntegrationCall: Promise<PipedriveVerifyIntegrationResult> | null =
    null;

  public async verifyPipedriveIntegration(): Promise<PipedriveVerifyIntegrationResult> {
    if (this._alreadyRunningVerifyPipedriveIntegrationCall) {
      return await this._alreadyRunningVerifyPipedriveIntegrationCall;
    } else {
      this._alreadyRunningVerifyPipedriveIntegrationCall =
        this._verifyPipedriveIntegration();
      try {
        const verifyResult =
          await this._alreadyRunningVerifyPipedriveIntegrationCall;
        return verifyResult;
      } catch (e) {
        return {
          data: {
            isSuccess: false,
          },
        };
      } finally {
        this._alreadyRunningVerifyPipedriveIntegrationCall = null;
      }
    }
  }

  private async _verifyPipedriveIntegration(): Promise<PipedriveVerifyIntegrationResult> {
    const request: Request = {
      url: ENDPOINTS.pipedrive.verify,
      config: {
        method: 'get',
      },
    };

    const result = await client(request);
    return result as PipedriveVerifyIntegrationResult;
  }
}

// Esporto una sola istanza del servizio, in modo che tutti gli altri file che importano
// questo file importeranno sempre la stessa istanza
export default new PipedriveService();
