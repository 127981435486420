import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconTopArrow = ({
  color = colors.grey11,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        transform="rotate(90, 12, 12)"
        d="M14.25 16.5L9.75 12L14.25 7.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTopArrow;
