import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const ImportDataIcon = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M5.16016 6.16066C5.62116 5.62066 6.14616 5.13966 6.72216 4.72266"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0004 19.4824C15.5704 20.4404 13.8514 21.0004 12.0004 21.0004C11.0884 21.0004 10.2094 20.8624 9.38037 20.6104"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13L12 16L9 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.34944 19C5.06844 17.965 4.08444 16.583 3.52344 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.52322 9C3.19022 9.94 3.00022 10.946 3.00022 12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.463 17C20.427 15.569 21 13.855 21 12C21 7.029 16.971 3 12 3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImportDataIcon;
