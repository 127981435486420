import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthContextType } from './types';
import { Credentials } from 'crono-fe-common/types/credentials';
import client from 'utils/clients/client';
import { ENDPOINTS } from 'config/endpoints';
import { Request } from 'crono-fe-common/types/request';
import { getFullName, User } from 'crono-fe-common/types/user';
import { useAsync } from 'hooks/useAsync';
import { SALESFORCE_DOMAIN_KEY, USER_TOKEN_KEY } from 'config/localStorage';
import PATH from 'routing/path';
import { Response } from 'crono-fe-common/types/response';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { FeConstants } from 'constants/FeConstants';
import { useJuneAnalytics } from 'context/june';
import jwt_decode from 'jwt-decode';
import Role from 'crono-fe-common/types/enums/role';
import { isOnboardingToDo } from 'utils/fe-utils';
import useGetUserOnboardingIntegrations from 'hooks/services/user/useGetUserOnboardingIntegrations';
import useGetActiveMigration from 'hooks/services/migration/useGetActiveMigration';
import { MigrationStatusType } from 'crono-fe-common/types/enums/migrationStatusType';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useLinkedinHasSalesNav } from 'crono-fe-common/hooks/crono-extension/gateway';
import { Constants } from 'crono-fe-common/constants/constants';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { cookieDomain, cookies } from 'constants/crono-cookie';

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);
AuthContext.displayName = 'UserContext';

const AuthProvider: FC<{ children: any }> = ({ children }) => {
  const {
    data: user,
    error,
    isLoading,
    isError,
    setData,
    run,
    reset,
  } = useAsync<User>();

  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [highestRole, setHighestRole] = useState<Role | null>(null);
  const analytics = useJuneAnalytics();

  function setUserTokens(user: User) {
    if (analytics) {
      //Identify the user only if it is not candidate, since candidates have a fake ID. The identification process will be done by after the candidate is converted to user
      if (!user.candidate) {
        analytics.identify(user.id.toString(), {
          email: user.email,
          name: getFullName(user),
        });

        if (user.company) {
          analytics.group(user.company.id.toString(), {
            id: user.company.id,
            userId: user.id,
            name: user.company.name,
            plan: user.subscriptionType,
          });
        }
      }
    }

    localStorage.setItem(USER_TOKEN_KEY, user.token.token);
    cookies.set(FeConstants.cronoSessionCookie, user.token.token, {
      domain: cookieDomain,
      path: '/',
      expires: new Date(user.token.validTo),
      sameSite: 'none',
      secure: true,
    });

    if (user.salesforceSettings) {
      localStorage.setItem(
        SALESFORCE_DOMAIN_KEY,
        user.salesforceSettings.domain,
      );
    }
    if (highestRole) return;
    //We update the role only if it has changed
    if (user.userRoles && user.userRoles.includes(Role.MANAGER)) {
      if (highestRole !== Role.MANAGER) setHighestRole(Role.MANAGER);
    } else if (highestRole !== Role.BASIC) {
      setHighestRole(Role.BASIC);
    }
  }

  const isSubscriptionManager = useMemo(() => {
    if (user) {
      return user.userRoles.includes(Role.SUBSCRIPTION_MANAGER);
    }
    return false;
  }, [user]);

  const isExpired = useMemo(() => {
    return (user && user.company?.expired) ?? false;
  }, [user]);

  //Every time I try to move I redirect to the expired if the user is expired
  useEffect(() => {
    if (isExpired) {
      navigate(PATH.EXPIRED);
    }
  }, [isExpired, location]);

  useEffect(() => {
    // token from cookies may be newer with respect to the one from local storage
    // when extensions refreshes the token while Crono FE is closed
    let token = cookies.get(FeConstants.cronoSessionCookie);
    if (token) {
      localStorage.setItem(USER_TOKEN_KEY, token);
    } else {
      token = localStorage.getItem(USER_TOKEN_KEY);
    }
    //If there is a cookieToken and we are in the thank-you page I don't want to call the authMe, othetwise we would be rendered again back to the expired page, outside of the thank you
    const cookieToken = cookies.get(FeConstants.cronoThankYouCookie);
    const pathname = location.pathname;
    if (token && !(cookieToken && pathname === PATH.THANK_YOU)) {
      //In this case we also set, since it may be the first time
      refreshUser();
    }

    if (!token) {
      setData(null);
    }
  }, [run, reset]);
  // }, [run, reset, refresh]);

  const [showErrorLogin, setShowErrorLogin] = useState<string | null>(null);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (showErrorLogin) {
      t = setTimeout(() => {
        setShowErrorLogin(null);
      }, 3000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [showErrorLogin]);

  useConditionalSnackBar([
    {
      condition: !!showErrorLogin,
      message:
        showErrorLogin ?? 'Something went wrong during the login process',
      severity: 'error',
    },
  ]);

  //To set the user given the User data (for example after a subscriptionComplete API call)
  const setNewUser = (newUser: User) => {
    setData(newUser);
    setUserTokens(newUser);
  };

  const login = ({ email, password }: Credentials) => {
    const request: Request = {
      url: ENDPOINTS.auth.login,
      config: {
        method: 'post',
        data: { email, password },
      },
    };

    run(
      client(request)
        .then((result: Response<User>) => {
          if (result.data) {
            const user = result.data.data;
            setUserTokens(user);
            return user;
          }
        })
        .catch((error) => {
          //In this case it is an expired subscription
          if (error.response.status === 402) {
            const user = error.response.data.data;
            setUserTokens(user);
            return user;
          } else {
            setShowErrorLogin(getError(error) ?? 'Something went wrong');
          }
        }),
    );
  };

  const refreshUserNotSet = useCallback(() => {
    const request: Request = {
      url: ENDPOINTS.auth.me,
      config: {
        method: 'post',
      },
    };

    client(request)
      .then((result: Response<User>) => {
        if (result.data) {
          //To allow the migration to check again, for example after a user starts an integration from integrations settings
          setEnabledMigration(true);
          const user = result.data.data;
          setUserTokens(user);
        }
      })
      .catch((error) => {
        //In this case it is an expired subscription
        if (error.response.status === 402) {
          const user = error.response.data.data;
          setUserTokens(user);
          setNewUser(user);
        }
      });
  }, []);
  const refreshUser = useCallback(() => {
    const request: Request = {
      url: ENDPOINTS.auth.me,
      config: {
        method: 'post',
      },
    };
    run(
      client(request)
        .then((result: Response<User>) => {
          if (result.data) {
            //To allow the migration to check again, for example after a user starts an integration from integrations settings
            setEnabledMigration(true);
            const user = result.data.data;
            setOnboardingCallEnabled(true);
            setUserTokens(user);
            return user;
          }
        })
        .catch((error) => {
          //In this case it is an expired subscription
          if (error.response.status === 402) {
            const user = error.response.data.data;
            setUserTokens(user);
            return user;
          }
        }),
    );
  }, []);

  useEffect(() => {
    //I call the AUTH/ME to refresh the token every hour
    const interval = setInterval(refreshUserNotSet, 60 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [refreshUser]);

  const logout = () => {
    setData(null);
    setHighestRole(null);
    cookies.remove(FeConstants.cronoSessionCookie, { domain: cookieDomain });
    queryClient.clear();
    localStorage.removeItem(USER_TOKEN_KEY);
    navigate(PATH.LOGIN);
  };

  useEffect(() => {
    //After logout the user is set to null, after this I can call the onboarding API or the Migration
    if (user === null) {
      setOnboardingCallEnabled(true);
      setEnabledMigration(true);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.candidate) {
      navigate(PATH.COMPLETE);
    }
  }, [user]);

  const useAuthGuard = () => {
    const token = localStorage.getItem(USER_TOKEN_KEY);

    //I check if the token is expired
    let expiringDate = 0;
    if (token) expiringDate = (jwt_decode(token) as any).exp * 1000;
    useEffect(() => {
      if (!token || expiringDate < Date.now()) {
        logout();
      }
    }, [user]);
  };

  const enabledContextCalls = useMemo(() => {
    return !!user && !user.candidate && !user.company?.expired;
  }, [user]);

  //To prevent the call from being fired multiple times. When the onboardingState === null after checking with the user this is set to false
  const [onboardingCallEnabled, setOnboardingCallEnabled] =
    useState<boolean>(true);

  const {
    data: userOnboardingIntegrations,
    refetch: refetchOnboardingIntegration,
  } = useGetUserOnboardingIntegrations(
    enabledContextCalls && onboardingCallEnabled,
  );
  //Calculate the current state of the onBoarding:
  //- undefined: calculating;
  //- null: onBoarding done or user missing;
  //- else: onboarding to finish
  const onboardingState = useMemo(() => {
    //I make sure that both the API have been called
    if (user === undefined || userOnboardingIntegrations === undefined)
      return undefined;
    if (!user) return null;
    const state = isOnboardingToDo(
      user,
      userOnboardingIntegrations?.data?.data ??
        user.integrationsOnboarding ??
        null,
    );
    if (state !== null) {
      navigate(PATH.ONBOARDING);
      return state;
    }
    setOnboardingCallEnabled(false);
    return state;
  }, [user, userOnboardingIntegrations]);

  // ====================================================================================================
  // Migration
  // To prevent multiple calls after the first one
  const [enabledMigration, setEnabledMigration] = useState<boolean>(true);

  //Probably not reloading after connect
  const { data: migration } = useGetActiveMigration(
    enabledContextCalls &&
      (enabledMigration ||
        (!user?.firstIntegration && !!user!.integrationType)),
  );

  const currentStateMigration: MigrationStatusType | null | undefined =
    useMemo(() => {
      if (migration === undefined) return MigrationStatusType.Completed;
      setEnabledMigration(false);
      if (!migration) return null;
      const status = migration?.data?.data?.status ?? null;

      return status;
    }, [migration]);

  useEffect(() => {
    // Put the condition that we want to be satisfied to redirect the user to the migration page
    if (currentStateMigration === MigrationStatusType.Started) {
      navigate(PATH.MIGRATION);
    } else if (
      currentStateMigration === MigrationStatusType.Created &&
      user?.firstIntegration
    ) {
      navigate(PATH.CONNECT);
    }
  }, [currentStateMigration]);

  const [hasSalesNav, setHasSalesNav] = useState<boolean | null>(null);

  const [hasSalesNavCookie, setHasSalesNavCookie] = useState<boolean>(false);

  //If this is true the user is a salesNav and we can provide him with the salesNav funcionalities since we have the cookie
  const hasSalesNavWithCookie = useMemo(() => {
    return (hasSalesNav && hasSalesNavCookie) ?? false;
  }, [hasSalesNav, hasSalesNavCookie]);

  const { call: getHasSalesNav } = useLinkedinHasSalesNav();
  useEffect(() => {
    if (enabledContextCalls) {
      getHasSalesNav().then((response) => {
        setHasSalesNav(response ?? false);
      });
    }
  }, [enabledContextCalls]);

  const automaticLinkedinTaskExecutionLimit =
    user?.userPreferences?.automaticTaskLimit ??
    Constants.maxAutomaticDailyDefault;

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  useEffect(() => {
    if (hasSalesNav === false) {
      //If the user has no salesNav but we see that in the userPreferences he has the values for more task execution set to something else
      // (this can happen for users that had salesNav in the past), put back to default
      if (
        user?.userPreferences?.hasSalesNav ||
        user?.userPreferences?.automaticTaskLimit !==
          Constants.maxAutomaticDailyDefault
      ) {
        patchUserPreferences({
          automaticTaskLimit: Constants.maxAutomaticDailyDefault,
          hasSalesNav: false,
        });
        if (user) {
          user.userPreferences.automaticTaskLimit =
            Constants.maxAutomaticDailyDefault;
          user.userPreferences.hasSalesNav = false;
        }
      }
    }
  }, [hasSalesNav, user?.userPreferences]);

  const hasOpportunities = user?.otherSettings?.hasOpportunities ?? false;

  const value = {
    user,
    error,
    isLoading,
    isError,
    cookies,
    highestRole: highestRole ?? user?.userRoles[0] ?? Role.BASIC,
    login,
    logout,
    useAuthGuard,
    refreshUser,
    onboardingState,
    refetchOnboardingIntegration,
    migrationStatus: currentStateMigration,
    isSubscriptionManager,
    setNewUser,
    enabledContextCalls,
    isExpired,
    hasSalesNav,
    automaticLinkedinTaskExecutionLimit,
    setHasSalesNavCookie,
    hasSalesNavWithCookie,
    hasOpportunities,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}
