import { useEffect, useMemo, useState } from 'react';
import { AddCompanyFormWrapper } from './style';
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import {
  AccountInsert,
  AccountInsertCodeDTO,
  TagCreation,
} from 'crono-fe-common/types/DTO/accountInsert';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as WebsiteIcon } from 'crono-fe-common/icons/website.svg';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import {
  CancelButton,
  MainButton,
  TabSelectionButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import ResultsFromLinkedinAdd from './resultsFromLinkedinAdd';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { validationSchema } from './model';
import useAccountExists from 'hooks/services/account/useAccountExists';
import { useConditionalSnackBar } from 'context/snackbar';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import InputExternalPropertyNotTag from 'pages/accountTab/externalPropertyNotTag/inputExternalPropertyNotTag';
import { LinkedinCompanyCompleteInfo } from 'crono-fe-common/types/crono-extension/linkedin';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import { AnyBrowserPlugin } from '@june-so/analytics-next';
import { linkedinIndustries } from 'crono-fe-common/constants/constants';
import { getNewOptionsWhenSelecting } from 'crono-fe-common/utils';

interface IProps {
  showSubmit: boolean;
  close?: () => void;
  handleSubmit: (values: AccountInsert) => void;
  createLoading?: boolean;
}

const AddCompanyForm = ({
  showSubmit,
  close,
  handleSubmit,
  createLoading,
}: IProps) => {
  const { user } = useAuth();

  const [createInCrm, setCreateInCrm] = useState<boolean>(true);

  useEffect(() => {
    if (user && user.userPreferences) {
      setCreateInCrm(user.userPreferences.createInCrm ?? true);
    }
  }, [user]);

  const { data: externalProperties } = useGetExternalProperty(
    'Account',
    null,
    true,
  );

  const externalPropertyTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter((property) => property.isTag) ?? []
    );
  }, [externalProperties]);

  const externalPropertyNotTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter((property) => !property.isTag) ??
      []
    );
  }, [externalProperties]);

  const [errorMandatoryFields, setErrorMandatoryFields] = useState<
    string | null
  >(null);

  //To clear the error after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorMandatoryFields) {
      timeout = setTimeout(() => {
        setErrorMandatoryFields(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [errorMandatoryFields]);

  const formik = useFormik<AccountInsertCodeDTO>({
    initialValues: {
      name: '',
      annualRevenue: null,
      country: null,
      externalValues: [],
      industry: null,
      linkedin: null,
      numberOfEmployees: null,
      phone: null,
      website: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const externalValuesToAdd: { [key: number]: string } = {};
      formik.values.externalValues.forEach((val) => {
        externalValuesToAdd[val.externalPropertyId] = val.value;
      });

      //Check if the mandatory fields are filled
      const mandatoryToFill: string[] = [];
      externalProperties?.data?.data.forEach((property) => {
        if (property.isMandatory && !externalValuesToAdd[property.id]) {
          mandatoryToFill.push(
            property.publicName ?? property.externalName ?? 'Unnamed property',
          );
        }
      });
      if (mandatoryToFill.length > 0) {
        setErrorMandatoryFields(mandatoryToFill.join(', '));
        return;
      }

      const accountInsert: AccountInsert = {
        name: values.name,
        annualRevenue: values.annualRevenue,
        country: values.country,
        externalValues: externalValuesToAdd,
        industry: values.industry,
        linkedin: values.linkedin,
        createInCrm: createInCrm,
        numberOfEmployees: values.numberOfEmployees,
        phone: values.phone,
        website: values.website,
        linkedinNumericId: values.linkedinNumericId,
      };
      // console.log("AccountInser", accountInsert);
      handleSubmit(accountInsert);
    },
  });

  //Initialize the externalValues that has default value
  useEffect(() => {
    if (!externalProperties) return;
    const newExternalProperties: TagCreation[] = [];
    externalProperties.data?.data.forEach((property) => {
      if (property.defaultValue) {
        newExternalProperties.push({
          externalPropertyId: property.id,
          value: property.defaultValue,
          isTag: property.isTag,
        });
      }
    });
    formik.setFieldValue('externalValues', newExternalProperties);
  }, [externalProperties]);

  const searchCompanyOnLinkedin = () => {
    setShowSelectCompanyLinkedin(true);
  };

  const [showSelectCompanyLinkedin, setShowSelectCompanyLinkedin] =
    useState<boolean>(false);

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const externalPropertyId = externalProperty.id;
    const newValues = [...formik.values.externalValues];

    const newValueOption = getNewOptionsWhenSelecting({
      currentExternalValues: newValues,
      externalPropertyId,
      option: option,
      valueType: externalProperty.valueType,
    });
    //This is going to be used only when the option is not null, so it is safe to use the !
    const newExternalValue = {
      externalPropertyId,
      value: newValueOption!,
      isTag: true,
    };
    const oldValue = newValues.filter(
      (val) => val.externalPropertyId === externalPropertyId,
    );
    //If option is null it means that the tag has to be removed, so I enter this condition
    if (oldValue.length > 0 || newValueOption === null) {
      newValues.splice(newValues.indexOf(oldValue[0]), 1);
      if (oldValue[0].value !== option) {
        newValues.push(newExternalValue);
      }
      formik.setFieldValue('externalValues', newValues);
    } else {
      newValues.push(newExternalValue);
      formik.setFieldValue('externalValues', newValues);
    }
  };

  //Set the values obtained searching on linkedin
  const handleSetValuesFromLinkedin = (info: LinkedinCompanyCompleteInfo) => {
    setShowSelectCompanyLinkedin(false);
    formik.setFieldValue('name', info.name);
    formik.setFieldValue('website', info.website);
    formik.setFieldValue('country', info.country);
    formik.setFieldValue('industry', info.industry);
    formik.setFieldValue('numberOfEmployees', info.numberOfEmployees);
    formik.setFieldValue('country', info.country);
    formik.setFieldValue('phone', info.phone);
    formik.setFieldValue('linkedin', info.url);
    formik.setFieldValue('linkedinNumericId', info.numericId);
  };

  const [editingComplete, setEditingComplete] = useState<boolean>(false);

  const { data: accountsExisting, isLoading: accountExistsloading } =
    useAccountExists(formik.values.name, editingComplete);

  const accountAlreadyExists = useMemo(() => {
    return !!accountsExisting && (accountsExisting?.data?.data.length || 0) > 0;
  }, [accountsExisting]);

  useConditionalSnackBar([
    {
      condition: !!errorMandatoryFields,
      message: `The empty fields ${errorMandatoryFields} are mandatory. Add a value to continue`,
      severity: 'error',
    },
  ]);

  const handleSetExternalValueNotTag = (
    externalPropertyId: number,
    value: string,
  ) => {
    const newValues = [...formik.values.externalValues];
    const newExternalValue = {
      externalPropertyId,
      value: value,
      isTag: false,
    };
    const oldValue = newValues.filter(
      (val) => val.externalPropertyId === externalPropertyId,
    );
    if (oldValue.length > 0) {
      newValues.splice(newValues.indexOf(oldValue[0]), 1);

      newValues.push(newExternalValue);

      formik.setFieldValue('externalValues', newValues);
    } else {
      newValues.push(newExternalValue);
      formik.setFieldValue('externalValues', newValues);
    }
  };

  return (
    <AddCompanyFormWrapper>
      {showSelectCompanyLinkedin && (
        <ResultsFromLinkedinAdd
          close={() => setShowSelectCompanyLinkedin(false)}
          searchText={formik.values.name}
          setValues={handleSetValuesFromLinkedin}
        />
      )}
      <form
        className="form-add-container"
        onSubmit={formik.handleSubmit}
        onKeyDown={(ev) => {
          if (ev.code === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
          }
        }}
      >
        {user?.company?.integrationType === IntegrationType.SALESFORCE && (
          <div>
            <TabSelectionButton
              selected={createInCrm}
              size="large"
              sx={{ position: 'relative', margin: '6px', marginLeft: 0 }}
              onClick={() => setCreateInCrm(true)}
              disableElevation
            >
              Create in Crm
            </TabSelectionButton>
            <TabSelectionButton
              selected={!createInCrm}
              sx={{ position: 'relative', right: 20, margin: '6px' }}
              size="large"
              onClick={() => setCreateInCrm(false)}
              disableElevation
            >
              Only in crono
            </TabSelectionButton>
          </div>
        )}
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Company details
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="editable-div"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <LinkedinIcon className="info-icon" />
              <TextField
                name="linkedin"
                variant="standard"
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                placeholder="Insert LinkedIn URL"
                onChange={formik.handleChange}
                value={formik.values.linkedin}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="editable-div"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <WebsiteIcon className="info-icon" />
              <TextField
                name="website"
                variant="standard"
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                placeholder="Insert website URL"
                onChange={formik.handleChange}
                value={formik.values.website}
              />
            </div>
          </div>
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Name*
          </Typography>
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder="Type company name..."
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              onFocus={() => setEditingComplete(false)}
              onBlur={() => setEditingComplete(true)}
              onMouseEnter={() => {
                setEditingComplete(false);
              }}
              onMouseLeave={() => {
                setEditingComplete(true);
              }}
              name="name"
              value={formik.values.name}
              onChange={(ev) => {
                if (!editingComplete) {
                  formik.handleChange(ev);
                }
              }}
              fullWidth
              error={accountAlreadyExists}
              helperText={
                accountAlreadyExists
                  ? 'An account with the same name already exists'
                  : ''
              }
            />
            {formik.values.name && formik.values.name !== '' && (
              <SearchIcon
                className="search-linkedin-icon"
                onClick={() => searchCompanyOnLinkedin()}
              />
            )}
          </div>
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Number of employees
          </Typography>
          <TextField
            placeholder="Type the number of employees..."
            variant="standard"
            type="number"
            sx={{
              '& .MuiInput-underline:before': {
                borderBottomColor: colors.grey444,
              },
            }}
            autoComplete="off"
            name="numberOfEmployees"
            value={formik.values.numberOfEmployees}
            onChange={formik.handleChange}
            fullWidth
          />
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Turnover
          </Typography>
          <TextField
            placeholder="Type company turnover..."
            variant="standard"
            autoComplete="off"
            sx={{
              '& .MuiInput-underline:before': {
                borderBottomColor: colors.grey444,
              },
            }}
            name="annualRevenue"
            value={formik.values.annualRevenue}
            onChange={formik.handleChange}
            fullWidth
          />
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Country
          </Typography>
          <TextField
            placeholder="Type the country of the company..."
            variant="standard"
            autoComplete="off"
            sx={{
              '& .MuiInput-underline:before': {
                borderBottomColor: colors.grey444,
              },
            }}
            name="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            fullWidth
          />
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Industry
          </Typography>
          <Autocomplete
            id="companySelect"
            className="add-new-auto-complete"
            // value={{
            //   id: selectedCompany,
            //   name: mappedAccount.find(
            //     (el) => el.objectId === selectedCompany
            //   )?.name,
            // }}
            freeSolo
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            onChange={(e, value) => {
              if (value) {
                if (typeof value === 'string')
                  return formik.setFieldValue('industry', value);
                else formik.setFieldValue('industry', value.value);
              } else formik.setFieldValue('industry', null);
            }}
            onInput={(ev: any) => {
              formik.setFieldValue('industry', ev.target.value);
            }}
            options={linkedinIndustries}
            getOptionLabel={(option) => {
              if (typeof option === 'string') return option;
              else return option.label;
            }}
            PaperComponent={({ children }) => (
              <div className="autocomplete-paper">{children}</div>
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                value={option.value || undefined}
                className="autocomplete-option"
              >
                {option.label}
              </li>
            )}
            fullWidth
            disablePortal
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="standard"
                placeholder="Start typing keywords..."
                fullWidth
                value={formik.values.industry}
                onChange={formik.handleChange}
              />
            )}
          />
        </div>
        {externalPropertyTags.filter((tag) => tag.isEditable).length !== 0 && (
          <div className="form-box">
            <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
              Add tags:
            </Typography>
            <div className="tags-container">
              <TagsBoxShowAll
                type="Account"
                currentSituationTags={formik.values?.externalValues
                  .filter((prop) => prop.isTag && prop.value !== null)
                  .map((prop) => ({
                    externalPropertyId: prop.externalPropertyId,
                    option: prop.value!,
                  }))}
                onlyInsert={true}
                handleClickTag={handleClickTag}
                showMandatoryAsStar={true}
              />
            </div>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          {externalPropertyNotTags.map((property, index) => {
            return (
              <InputExternalPropertyNotTag
                key={property.id}
                externalProperty={property}
                owned={true}
                externalValues={formik.values.externalValues}
                handleSetValue={handleSetExternalValueNotTag}
              />
            );
          })}
        </div>
        {(showSubmit === undefined ? true : showSubmit) && (
          <FlexDiv
            width="fit-content"
            height="fit-content"
            style={{ alignSelf: 'flex-end' }}
          >
            <CancelButton
              onClick={() => {
                if (close) close();
              }}
            >
              Cancel
            </CancelButton>
            {accountExistsloading || createLoading ? (
              <CircularProgress />
            ) : (
              <MainButton
                type="submit"
                disabled={
                  accountAlreadyExists || !formik.isValid || !formik.dirty
                }
                style={{
                  background: accountAlreadyExists ? colors.grey2 : undefined,
                }}
              >
                Add
              </MainButton>
            )}
          </FlexDiv>
        )}
      </form>
    </AddCompanyFormWrapper>
  );
};

export default AddCompanyForm;
