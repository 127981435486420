import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { useCallback } from 'react';

type ScrapeWebsiteInput = {
  website: string | null;
};

export default function useScrapeWebsite() {
  const scrapeWebsite = (data: ScrapeWebsiteInput) => {
    const params = new URLSearchParams({ website: data.website || '' });
    const request: Request = {
      url: `${ENDPOINTS.scrape.website}?${params.toString()}`,
      config: {
        method: 'get',
      },
    };
    return client(request);
  };

  const { data, mutate, mutateAsync, ...rest } = useMutation<
    Partial<
      Response<{
        website: string | null;
        redirectUrl: string | null;
      } | null>
    >,
    CronoError,
    ScrapeWebsiteInput
  >(scrapeWebsite, {});

  const mutateAsyncWrapper: typeof mutateAsync = async (variables, options) => {
    if (!variables.website)
      return {
        data: {
          data: {
            website: null,
            redirectUrl: null,
          },
          isSuccess: true,
        },
      };
    if (variables.website.includes('linktr.ee')) {
      return {
        data: {
          data: {
            website: null,
            redirectUrl: null,
          },
          isSuccess: true,
        },
      };
    }
    if (
      !variables.website.includes('bit.ly') &&
      !variables.website.includes('t.ly')
    ) {
      return {
        data: {
          data: {
            website: variables.website,
            redirectUrl: variables.website,
          },
          isSuccess: true,
        },
      };
    }
    //Call only if needed
    try {
      const res = await mutateAsync(variables, options);
      return res;
    } catch (e) {
      return {
        data: {
          data: {
            website: null,
            redirectUrl: null,
          },
          isSuccess: false,
          error: e,
        },
      };
    }
  };

  return {
    data,
    mutate,
    mutateAsync: useCallback(mutateAsyncWrapper, [mutateAsync]),
    ...rest,
  };
}
