import { CircularProgress } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';

const SuccessSignupInterceptor = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PATH.LOGIN, { state: { firstLoginMessageToShow: true } });
  }, []);
  return (
    <>
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    </>
  );
};

export default SuccessSignupInterceptor;
