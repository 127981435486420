import { CircularProgress, Typography } from '@mui/material';
import { useAuth } from 'context/auth';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import MigrationComponent from './MigrationComponent';

const Migration = () => {
  const { migrationStatus, user } = useAuth();

  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate(PATH.HOME);
    }
  }, [user]);
  useEffect(() => {
    if (migrationStatus === null) {
      navigate(PATH.HOME);
    }
  }, [migrationStatus]);
  //If we are checking add a loading
  if (migrationStatus === undefined) return <CircularProgress />;
  //If null it means that the migration is done
  if (migrationStatus === null) {
    return <></>;
  }

  return <MigrationComponent />;
};

export default Migration;
