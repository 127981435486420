import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Template } from 'crono-fe-common/types/template';
import queryString from 'query-string';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';

export default function useGetTemplateAttachmentsMutate() {
  const getTemplateAttachments = (templateId: number) => {
    const request = createGetTemplateAttachmentsRequest(templateId);
    return client(request);
  };

  const { data, ...rest } = useMutation<
    Response<CronoAttachment[]>,
    CronoError,
    number
  >(getTemplateAttachments);

  return { data, ...rest };
}

export function createGetTemplateAttachmentsRequest(
  templateId: number,
): Request {
  const queryParams = queryString.stringify({ templateId });
  return {
    url: `${ENDPOINTS.template.attachment.main}?${queryParams}`,
    config: {
      method: 'get',
    },
  };
}
