import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ForgotPasswordPageWrapper = styled('form')`
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .form-header {
    text-align: center;

    h2 {
      font-size: 28px;
      margin: 0;
    }

    p {
      margin-top: 5px;
      font-size: 16px;
      color: ${colors.grey11};
    }
  }

  .form-body {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 0 5px;
  }

  button {
    margin: 20px 0 0 0;
  }
`;
