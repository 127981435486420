import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { UserPreferences } from 'crono-fe-common/types/userPreferences';

export default function usePatchUserPreferences() {
  const patchUserPreferences = (userPreferences: UserPreferences) => {
    const request: Request = {
      url: ENDPOINTS.user.preferences,
      config: {
        method: 'patch',
        data: userPreferences,
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    UserPreferences
  >(patchUserPreferences, {});

  return { isSuccess, isError, mutate, ...rest };
}
