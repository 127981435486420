import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconInformationS = ({
  color = colors.black,
  className,
  onClick,
  ...rest
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...rest}
    >
      <path
        d="M7.99804 4.88901C7.89104 4.88901 7.80404 4.97601 7.80404 5.08301C7.80404 5.19001 7.89104 5.27701 7.99804 5.27701C8.10504 5.27701 8.19204 5.19001 8.19204 5.08301C8.19304 4.97601 8.10604 4.88901 7.99804 4.88901"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15V15C4.134 15 1 11.866 1 8V8C1 4.134 4.134 1 8 1V1C11.866 1 15 4.134 15 8V8C15 11.866 11.866 15 8 15Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8V11.889"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconInformationS;
