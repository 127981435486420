import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { EmailSettingsInput } from 'crono-fe-common/types/DTO/emailSignature';

export default function useEmailSaveSettings() {
  const queryClient = useQueryClient();

  const saveSettings = (settingsInput: EmailSettingsInput) => {
    const request: Request = {
      url: `${ENDPOINTS.email.saveSettings}`,
      config: {
        method: 'post',
        data: { data: settingsInput },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    EmailSettingsInput
  >(saveSettings, {
    onSettled: () => queryClient.invalidateQueries([ENDPOINTS.auth.login]),
  });

  return { data, mutate, ...rest };
}
