import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconSalesforce = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M12.5601 4.34766C12.0595 4.34766 11.5795 4.43707 11.1595 4.6153C10.6696 3.83824 9.74026 3.30295 8.70031 3.30295C8.31953 3.30295 7.94281 3.37197 7.59351 3.50574C7.24422 3.63951 6.92979 3.83517 6.66973 4.0806L6.65973 4.07177C6.3672 3.73728 5.99139 3.46664 5.56138 3.2808C5.13136 3.09495 4.65866 2.99888 4.17983 3.00001C2.47991 3.00001 1.07996 4.23177 1.07996 5.76765C1.07996 6.15236 1.16996 6.52707 1.33995 6.86648C0.93037 7.08224 0.591459 7.38833 0.356529 7.75467C0.121598 8.121 -0.00126642 8.53497 9.8424e-06 8.95589C9.8424e-06 10.2953 1.20063 11.3665 2.6699 11.3665C2.85989 11.3665 3.03988 11.3582 3.21987 11.3218C3.41924 11.8128 3.78558 12.2375 4.26883 12.5379C4.75208 12.8383 5.32865 12.9997 5.91976 13C6.48673 12.9992 7.04078 12.8505 7.51226 12.5726C7.98374 12.2947 8.35161 11.9001 8.56965 11.4382C8.89963 11.5724 9.26962 11.6612 9.6696 11.6612C10.1209 11.6613 10.5639 11.5535 10.9513 11.3491C11.3387 11.1447 11.656 10.8515 11.8695 10.5006C12.0902 10.5365 12.3195 10.5624 12.5495 10.5624C14.4594 10.5624 16 9.16942 16 7.4553C16 5.74118 14.4594 4.34766 12.5601 4.34766Z"
        fill={color}
      />
    </svg>
  );
};

export default IconSalesforce;
