import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { InnerTextAccordionWrapper } from './style';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';

interface IProps {
  text?: string | null;
  isLoading?: boolean;
  style?: any;
  minHeight?: string;
  fontSize?: string;
  toggleSelectedOption?: () => void;
  isSelected?: boolean;
}

const InnerTextAccordion = ({
  text,
  isLoading,
  style,
  minHeight,
  toggleSelectedOption,
  isSelected,
  fontSize = '12px',
}: IProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const [hasToOverflow, setHasToOverflow] = useState<boolean>(false);
  const measuredRef = useCallback((node: HTMLParagraphElement) => {
    if (node !== null) {
      setHasToOverflow(node.scrollHeight > node.clientHeight);
    }
  }, []);
  return (
    <InnerTextAccordionWrapper
      style={style}
      className={toggleSelectedOption ? 'selectable' : ''}
    >
      {isLoading ? (
        <FlexDiv>
          <CircularProgress />
        </FlexDiv>
      ) : text ? (
        <>
          {toggleSelectedOption !== undefined && isSelected !== undefined && (
            <span className={'insight-icon-wrapper profile-description-icon'}>
              <CronoCheckbox
                checked={isSelected}
                onClick={toggleSelectedOption}
              />
            </span>
          )}
          <Typography
            className="inner-text-accordion-body"
            fontSize={fontSize}
            ref={measuredRef}
            style={{
              flexDirection: 'column',
              overflow: expanded ? undefined : 'hidden',
              height: expanded ? 'fit-content' : minHeight,
              display: expanded ? 'flex' : undefined,
              ...(minHeight ? { minHeight: minHeight } : {}),
            }}
            whiteSpace={'pre-wrap'}
          >
            {text}
          </Typography>
          {(hasToOverflow || expanded) && (
            <div className="inner-toggle-button">
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  setExpanded((prev) => !prev);
                }}
              >
                {expanded ? 'Less' : 'More'}
              </Typography>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </InnerTextAccordionWrapper>
  );
};

export default InnerTextAccordion;
