import { AsyncStatus, defaultInitialState, State } from 'hooks/useAsync';
import { useCallback, useState } from 'react';
import GoogleService, { GoogleVerifyIntegrationResult } from './GoogleService';

const useVerifyGoogleIntegration = () => {
  const [{ error, isLoading, data, status }, setState] =
    useState<State<GoogleVerifyIntegrationResult>>(defaultInitialState);

  const verifyGoogleIntegration = useCallback(async () => {
    setState({
      error: null,
      status: AsyncStatus.pending,
      data: null,
      isLoading: true,
    });
    const resultPromise = GoogleService.verifyGoogleIntegration();
    resultPromise.then(
      (result) => {
        setState({
          error: null,
          status: AsyncStatus.resolved,
          data: result,
          isLoading: false,
        });
      },
      (error) => {
        setState({
          error: error,
          status: AsyncStatus.rejected,
          data: null,
          isLoading: false,
        });
      },
    );
  }, [setState]);

  return {
    verifyGoogleIntegration,
    error,
    isLoading,
    isError:
      status === AsyncStatus.rejected ||
      (data?.data ? !data?.data.isSuccess : false),
    isSuccess: status === AsyncStatus.resolved && data?.data.isSuccess,
    data,
  };
};

export default useVerifyGoogleIntegration;
