import React from 'react';
import IProps from './interface';
import { colors } from 'crono-fe-common/theme';

const IconLinkS = ({ color = colors.mainDark, className, style }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <path
        d="M10.643 4.024C11.5637 3.10333 13.0557 3.10333 13.9764 4.024V4.024C14.897 4.94466 14.897 6.43666 13.9764 7.35733L10.057 11.2767C9.13637 12.1973 7.64437 12.1973 6.7237 11.2767V11.2767C5.80304 10.356 5.80304 8.864 6.7237 7.94333L7.3097 7.35733"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.35782 12.6426C4.43715 13.5633 2.94515 13.5633 2.02448 12.6426V12.6426C1.10382 11.722 1.10382 10.23 2.02448 9.3093L5.94382 5.38996C6.86448 4.4693 8.35648 4.4693 9.27715 5.38996V5.38996C10.1978 6.31063 10.1978 7.80263 9.27715 8.7233L8.66715 9.3333"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconLinkS;
