import React from 'react';
import IProps from './interface';

const IconWebsite = ({ color, className, style }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <path
        d="M8 2C11.3213 2 14 4.67867 14 8C14 11.3213 11.3213 14 8 14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14C4.67867 14 2 11.3213 2 8C2 4.67867 4.67867 2 8 2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7785 2.70665C4.85183 5.92332 4.85183 10.0773 6.7785 13.294C7.3425 14.236 8.65783 14.236 9.22183 13.294C11.1485 10.0773 11.1485 5.92332 9.22183 2.70665C8.65716 1.76465 7.3425 1.76465 6.7785 2.70665Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 7.99999H14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconWebsite;
