import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { EmailMessage } from 'crono-fe-common/types/emailMessage';

export default function useGetThread(threadId: string, enabled: boolean) {
  const queryParams = queryString.stringify({ threadId });

  const request: Request = {
    url: `${ENDPOINTS.email.thread.main}?${queryParams}`,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.email.thread.main, threadId],
    queryFn: () => client(request),
    enabled: enabled && threadId.length > 0,
  };

  const { data, ...rest } = useQuery<Response<EmailMessage[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
