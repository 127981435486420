import { colors } from 'crono-fe-common/theme';
import IconRectangle from 'crono-fe-common/icons/Icon-Rectangle';
import IconCircle from 'crono-fe-common/icons/Icon-Circle';
import IconTriangle from 'crono-fe-common/icons/Icon-Triangle';
import { TagIconWrapper } from './style';
import React from 'react';
import { Constants } from 'crono-fe-common/constants/constants';

//Find the background color of the external property based on the index
export const findBackgroundColor = (index: number) => {
  switch (index) {
    case 0:
      return colors.callScheduledLight;
    case 1:
      return colors.blueLight;
    case 2:
      return colors.purpleLight;
    default:
      return colors.inactiveLight;
  }
};

// export const findBackgroundTagColor = (index: number, indexOption: number) => {
//   const newIndex =
//     (index * Constants.numberOfTags + indexOption) %
//     Constants.numberOfTagsColor;
//   return colors.tagColorsLight[newIndex];
// };

export const findTagColors = (index: number, indexOption: number) => {
  //
  const newIndex = indexOption % Constants.numberOfTagsColor;
  switch (index) {
    case 0:
      return [
        colors.firstTagLight[newIndex],
        colors.firstTag[newIndex],
        colors.firstTagLightHover[newIndex],
      ];
    case 1:
      return [
        colors.secondTagLight[newIndex],
        colors.secondTag[newIndex],
        colors.secondTagLightHover[newIndex],
      ];
    case 2:
      return [
        colors.thirdTagLight[newIndex],
        colors.thirdTag[newIndex],
        colors.thirdTagLightHover[newIndex],
      ];
    default:
      return [
        colors.firstTagLight[newIndex],
        colors.firstTag[newIndex],
        colors.firstTagLightHover[newIndex],
      ];
  }
};
//Find the icon of the external property based on the index
export const findExternalPropertyIcon = (index: number, color?: string) => {
  switch (index) {
    case 0:
      return (
        <TagIconWrapper>
          <IconRectangle
            style={{ minWidth: 8, width: 8, height: 8, minHeight: 8 }}
            color={color}
          />
        </TagIconWrapper>
      );
    case 1:
      return (
        <TagIconWrapper>
          <IconCircle
            style={{ minWidth: 8, width: 8, height: 8 }}
            color={color}
          />
        </TagIconWrapper>
      );
    case 2:
      return (
        <TagIconWrapper>
          <IconTriangle
            style={{ minWidth: 8, width: 8, height: 8 }}
            color={color}
          />
        </TagIconWrapper>
      );
    default:
      return (
        <TagIconWrapper>
          <IconRectangle
            style={{ minWidth: 8, width: 8, height: 8 }}
            color={color}
          />
        </TagIconWrapper>
      );
  }
};
