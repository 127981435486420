import { TextField, Typography } from '@mui/material';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { colors } from 'crono-fe-common/theme';
import { useFilters } from 'pages/home/context/filters';
import React, { useEffect, useState } from 'react';
import { DashboardTabType } from './index';

interface IProps {
  tabState: DashboardTabType | null;
}

const SearchInput = ({ tabState }: IProps) => {
  //Value of the search input
  const [search, setSearch] = useState<string>('');

  //Value used to filter based on the name searched. This has to be used since we don't want to filter every time the search value changes,
  //but only when on enter or after 1 second of inactivity
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');
  useEffect(() => {
    const timeout = setTimeout(() => {
      //We decided to have at least 3 characters to search for name
      if (search.length < 3) setSearchFilterValue('');
      else setSearchFilterValue(search);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [search]);

  const {
    setSearchAccountParameters,
    setSearchProspectParameters,
    setSearchLeadParameters,
  } = useFilters();

  // Set the search parameters of the current visualized table
  // And transfer search string to current tab on tab change
  useEffect(() => {
    switch (tabState) {
      case 'Company':
        setSearchAccountParameters((prev) => {
          return { ...prev, name: searchFilterValue };
        });
        break;
      case 'Contact':
        setSearchProspectParameters((prev) => {
          return { ...prev, name: searchFilterValue };
        });
        break;
      case 'Lead':
        setSearchLeadParameters((prev) => {
          return { ...prev, name: searchFilterValue };
        });
        break;
    }
  }, [searchFilterValue, tabState]);

  const [showLabel, setShowLabel] = useState<boolean>(false);

  return (
    <span
      style={{
        height: '100%',
        padding: 12,
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <SearchIcon color={colors.grey11} style={{ marginTop: 4 }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
        className="text-field-search"
      >
        <TextField
          className="text-field"
          onBlur={() => setShowLabel(false)}
          variant="standard"
          placeholder="Search..."
          autoComplete="off"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.stopPropagation();
              ev.preventDefault();
              setSearchFilterValue(search);
              setShowLabel(false);
            }
          }}
          onFocus={() => setShowLabel(true)}
          InputProps={{
            endAdornment:
              search === '' ? null : (
                <CloseMIcon
                  className="remove-text-icon"
                  color={colors.grey11}
                  onClick={() => {
                    setSearch('');
                    //For immediate rerendering
                    setSearchFilterValue('');
                  }}
                />
              ),
          }}
          sx={{
            width: '100%',
            input: {
              fontWeight: '400',
              padding: '4px 0px',
              '&::placeholder': {
                opacity: 1,
                color: colors.grey11,
              },
            },
          }}
        />
        {search.length < 3 && showLabel && (
          <Typography fontSize={11} lineHeight={'14px'} color={colors.grey11}>
            Type at least 3 characters
          </Typography>
        )}
      </div>
    </span>
  );
};

export default SearchInput;
