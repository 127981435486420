import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { IPostSnippetVariable } from './usePostSnippetVariable';

export interface IPatchSnippetVariable extends IPostSnippetVariable {
  id: number;
}

export default function usePatchSnippetVariable() {
  const queryClient = useQueryClient();

  const patchSnippetVariable = (snippetVariable: IPatchSnippetVariable) => {
    const request: Request = {
      url: `${ENDPOINTS.templateVariables.snippet}`,
      config: {
        method: 'patch',
        data: {
          data: snippetVariable,
        },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    IPatchSnippetVariable
  >(patchSnippetVariable, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.templateVariables.main]);
    },
  });

  return { data, mutate, ...rest };
}
