import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SettingsTooltipWrapper = styled.div`
  width: 200px;

  .settings-button {
    text-transform: unset !important;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 10px;
    border: 1px solid transparent;
  }
  .finish-onboarding,
  .settings-button:hover {
    background-color: ${colors.primaryLight};
  }
  .finish-onboarding:hover {
    border: 1px solid ${colors.primaryDark};
  }
  .divider {
    border-top: 1px solid ${colors.grey4};
  }
`;
