import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const CallIcon = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.23727 8.76265C6.45727 7.98265 5.86927 7.10932 5.4786 6.22332C5.39593 6.03599 5.4446 5.81665 5.58927 5.67199L6.13527 5.12665C6.5826 4.67932 6.5826 4.04665 6.19193 3.65599L5.40927 2.87332C4.8886 2.35265 4.0446 2.35265 3.52393 2.87332L3.08927 3.30799C2.59527 3.80199 2.38927 4.51465 2.5226 5.22132C2.85193 6.96332 3.86393 8.87065 5.4966 10.5033C7.12927 12.136 9.0366 13.148 10.7786 13.4773C11.4853 13.6107 12.1979 13.4047 12.6919 12.9107L13.1259 12.4767C13.6466 11.956 13.6466 11.112 13.1259 10.5913L12.3439 9.80932C11.9533 9.41865 11.3199 9.41865 10.9299 9.80932L10.3279 10.412C10.1833 10.5567 9.96393 10.6053 9.7766 10.5227C8.8906 10.1313 8.01727 9.54265 7.23727 8.76265Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CallIcon;
