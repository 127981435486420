import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BlurStrategyModal, StrategyDetailWrapper } from './style';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import moment from 'moment-business-days';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import usePatchSequenceStep from 'hooks/services/sequence/usePatchSequenceStep';
import { useConditionalSnackBar } from 'context/snackbar';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { FeConstants } from 'constants/FeConstants';
import { getError } from 'crono-fe-common/utils';
import useDeleteSequenceStep from 'hooks/services/sequence/useDeleteSequenceStep';
import { useJuneAnalytics } from 'context/june';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import { useConfirmModal } from 'context/confirmModal';
import useAddSequenceStep from 'hooks/services/sequence/useAddSequenceStep';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import StepDetailComponent from './stepDetailComponent';
import { SequenceStepWrapper } from './stepDetailComponent/style';
import { SequenceStep } from 'crono-fe-common/types/sequenceStep';
import { Template } from 'crono-fe-common/types/template';
import useDeleteConditionalSequenceStep from 'hooks/services/sequence/useDeleteConditonalSequenceStep';
import usePatchConditionalSequenceStep from 'hooks/services/sequence/usePatchConditionalSequenceStep';
import { SequenceConditionalStepPatch } from 'crono-fe-common/types/DTO/sequenceConditionalStepPatch';
import { SwitchToTask } from 'crono-fe-common/types/enums/switchToConditionalStep';
import useAddSequenceStepTemplate from 'hooks/services/sequence/useAddSequenceStepTemplate';
import useDeleteSequenceStepTemplate from 'hooks/services/sequence/useDeleteSequenceStepTemplate';
import usePatchSequenceStepTemplate from 'hooks/services/sequence/usePatchSequenceStepTemplate';
import { useSequenceContext } from './context/sequence';
import {
  IndexFirstLinkedin,
  IndexFirstLinkedinAfterInvitation,
} from 'utils/fe-utils';
import TemplatesTooltipCard from 'pages/accountTab/emailView/TemplatesTooltipCard';
import useEnableConditionalForSequence from 'hooks/services/sequence/useEnableConditionalForSequence';
import { EnableConditionalForSequence } from 'crono-fe-common/types/DTO/enableConditionalForSequence';
import StrategyXarrow, {
  InvitationAcceptedBadge,
  InvitationNotAcceptedBadge,
} from './strategyXarrow';
import useDeleteAllConditionals from 'hooks/services/sequence/useDeleteAllConditionals';
import classNames from 'classnames';
import { AddStepModal } from './addStepModal';
import {
  AddStepNumberCount,
  AddStepPlaceholder,
  CreateStrategyView,
} from './createStrategyView';
import { StrategyInsert } from 'crono-fe-common/types/DTO/strategyInsert';
import Role from 'crono-fe-common/types/enums/role';
import useCreateStrategy from 'hooks/services/sequence/useCreateStrategy';
import { useCustomerly } from 'react-live-chat-customerly';
import { StrategyOverviewTab } from './types';
import { useAuth } from '../auth';
import { useShortStrategyOverviewContext } from '.';

interface IProps {
  changed?: React.MutableRefObject<boolean>;
  autoSaveShowed?: boolean;
  setAutoSaveShowd?: React.Dispatch<React.SetStateAction<boolean>>;
  onApplySequenceChanges?: () => void;
  handleClickOnBackground?: () => void;
  previewOnly?: boolean;
}

const StrategyDetailModal = ({
  changed,
  autoSaveShowed,
  setAutoSaveShowd,
  onApplySequenceChanges,
  handleClickOnBackground,
  previewOnly = false,
}: IProps) => {
  const analytics = useJuneAnalytics();

  const { sequence, setSequence, isNewSequenceView } = useSequenceContext();

  const {
    mutate: patchSequenceStep,
    error: errorPatchSequenceStep,
    isSuccess: sequenceStepPatched,
    mutateAsync: patchSequenceStepAsync,
  } = usePatchSequenceStep();

  const {
    mutate: deleteSequenceStep,
    error: errorDeleteSequenceStep,
    isSuccess: sequenceStepDeleted,
  } = useDeleteSequenceStep();

  //  Conditional sequence step logic ------------

  const {
    mutate: deleteConditionalSequenceStep,
    error: errorDeleteConditionalPatchSequenceStep,
    // isSuccess: conditionalSequenceStepDeleted
  } = useDeleteConditionalSequenceStep();

  const {
    mutate: patchConditionalSequenceStep,
    error: errorConditionalPatchSequenceStep,
    // isSuccess: conditionalSequenceStepPatched
  } = usePatchConditionalSequenceStep();

  const {
    mutate: enableConditionalForSequence,
    error: errorEnableConditionalForSequence,
    isSuccess: successEnableConditionalForSequence,
  } = useEnableConditionalForSequence();

  const handlePatchConditionalSequenceStep = (
    data: SequenceConditionalStepPatch,
  ) => {
    if (changed !== undefined) {
      changed.current = true;
    }

    //I change also the id to refresh the sequence API call
    setSequence({ ...sequence, id: -1 });
    patchConditionalSequenceStep(data);
  };

  const handleEnableConditionalForSequence = (
    data: EnableConditionalForSequence,
  ) => {
    if (changed !== undefined) {
      changed.current = true;
    }

    //I change also the id to refresh the sequence API call
    setSequence({ ...sequence, id: -1 });
    enableConditionalForSequence(data);
    if (analytics) {
      analytics.track('enable-conditional-for-sequence', {});
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sequenceStepPatched) {
      if (!autoSaveShowed && !previewOnly && setAutoSaveShowd) {
        setAutoSaveShowd(true);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [sequenceStepPatched]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sequenceStepDeleted) {
      if (!autoSaveShowed && !previewOnly && setAutoSaveShowd) {
        setAutoSaveShowd(true);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [sequenceStepDeleted]);

  const handleChangeDelay = (increment: number, index: number) => {
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];

    //Update the step
    if (
      steps[index].delay + increment >= FeConstants.MIN_DELAY &&
      steps[index].delay + increment <= FeConstants.MAX_DELAY
    )
      steps[index].delay = steps[index].delay + increment;

    //Update the sequence
    setSequence({ ...sequence, steps });

    patchSequenceStep({
      id: steps[index].id,
      delay: steps[index].delay,
      strategyId: sequence.strategyId,
    });

    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'delay',
      });
    }
  };

  const handleChangeDelayConditional = (increment: number, index: number) => {
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];
    const stepToChange = steps[index].sequenceStepConditional;
    //Update the step
    if (
      !stepToChange ||
      stepToChange.daysAfterConditionNotAccepted + increment <
        FeConstants.MIN_DELAY ||
      stepToChange.daysAfterConditionNotAccepted + increment >
        FeConstants.MAX_DELAY
    ) {
      return;
    }

    stepToChange.daysAfterConditionNotAccepted += increment;

    setSequence({ ...sequence, steps });

    patchConditionalSequenceStep({
      sequenceStepId: steps[index].id,
      type: stepToChange.type,
      daysAfterConditionNotAccepted:
        stepToChange?.daysAfterConditionNotAccepted,
    });

    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'delay-conditional',
      });
    }
  };

  const handleChangeUntilAccepted = (increment: number) => {
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];
    const stepToChange =
      steps[indexFirstMsgAfterInvitation].sequenceStepConditional;

    if (
      !stepToChange ||
      stepToChange.linkedinUntilAcceptedDays + increment <
        FeConstants.MIN_DELAY ||
      stepToChange.linkedinUntilAcceptedDays + increment > FeConstants.MAX_DELAY
    ) {
      return;
    }

    stepToChange.linkedinUntilAcceptedDays += increment;
    //Update the sequence
    setSequence({ ...sequence, steps });

    patchConditionalSequenceStep({
      sequenceStepId: steps[indexFirstMsgAfterInvitation].id,
      type: stepToChange.type,
      linkedinUntilAcceptedDays: stepToChange?.linkedinUntilAcceptedDays,
    });

    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'linkedin-until-accepted',
      });
    }
  };

  const handleChangeAutomatic = (
    automatic: boolean,
    index: number,
    conditionalStepPatch?: boolean,
  ) => {
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];

    if (conditionalStepPatch) {
      //Update the step
      steps[index].sequenceStepConditional!.automatic = automatic;
      if (!automatic) {
        steps[index].sequenceStepConditional!.scheduleTime = null;
      }
      //Update the sequence
      setSequence({ ...sequence, steps });
      patchConditionalSequenceStep({
        sequenceStepId: steps[index].id,
        type: SwitchToTask.TO_EMAIL,
        automatic: steps[index].sequenceStepConditional!.automatic,
        scheduleTime: null,
      });
    } else {
      //Update the step
      steps[index].automatic = automatic;
      if (!automatic) {
        steps[index].scheduleTime = null;
      }
      //Update the sequence
      setSequence({ ...sequence, steps });
      patchSequenceStep({
        id: steps[index].id,
        automatic: steps[index].automatic,
        strategyId: sequence.strategyId,
      });
    }
    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'automatic',
      });
    }
  };

  const clearOtherInvitation = async (
    steps: SequenceStep[],
    currentIndex: number,
  ) => {
    //Go through all the steps and if there is an invitation I remove it
    for (let i = currentIndex + 1; i < steps.length; i++) {
      if (
        steps[i].subtype === TaskTodoSubtype.LinkedinInvitation &&
        steps[i].type === TaskTodoType.LINKEDIN
      ) {
        steps[i].subtype = TaskTodoSubtype.LinkedinMessage;
        steps[i].templateId = null;
        steps[i].template = null;
        await patchSequenceStepAsync({
          id: steps[i].id,
          type: TaskTodoType.LINKEDIN,
          subtype: TaskTodoSubtype.LinkedinMessage,
          templateId: 0,
          strategyId: sequence.strategyId,
        });
        return;
      }
    }
    return;
  };

  const handleChangeStepType = async (
    newType: { type: TaskTodoType; subtype: TaskTodoSubtype },
    index: number,
  ) => {
    //If the type is the same
    if (newType.type === sequence.steps[index].type) {
      if (
        newType.type !== TaskTodoType.LINKEDIN ||
        newType.subtype === sequence.steps[index].subtype
      ) {
        return;
      }
    }
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];
    //Update the step

    //If the type is changed to linkedin, I must check that all the later steps are not invitations
    if (newType.type === TaskTodoType.LINKEDIN) {
      await clearOtherInvitation(steps, index);
    }

    steps[index].type = newType.type;
    steps[index].subtype = newType.subtype;
    //Update the sequence
    //I make sure that the new step is not automatic if switched from an email
    if (newType.type !== TaskTodoType.EMAIL) {
      steps[index].automatic = false;
      steps[index].scheduleTime = null;
    }

    //Clear the template
    steps[index].template = null;
    steps[index].templateId = null;

    // Clear the conditional step, if any
    steps[index].sequenceStepConditional = null;

    // Clear the sequenceStepTemplates, if any
    steps[index].sequenceStepTemplates = null;

    await patchSequenceStepAsync({
      id: steps[index].id,
      type: steps[index].type,
      subtype: steps[index].subtype,
      automatic: steps[index].automatic,
      templateId: steps[index].templateId ?? undefined,
      strategyId: sequence.strategyId,
    });
    setSequence({ ...sequence, steps, id: -1 });
    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'stepType',
      });
    }
  };

  const handleScheduleTime = (
    time: Date,
    index: number,
    conditionalStepPatch?: boolean,
  ) => {
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];
    //Update the step

    // time in format HH:MM+TZ
    const scheduleTime =
      moment(time).format('HH:mm') + moment(time).format('Z').slice(0, 6);

    if (moment(time).isValid()) {
      if (conditionalStepPatch) {
        steps[index].sequenceStepConditional!.scheduleTime = scheduleTime;
        setSequence({ ...sequence, steps });
        patchConditionalSequenceStep({
          sequenceStepId: steps[index].id,
          type: SwitchToTask.TO_EMAIL,
          scheduleTime: scheduleTime,
        });
      } else {
        steps[index].scheduleTime = scheduleTime;
        setSequence({ ...sequence, steps });
        patchSequenceStep({
          id: steps[index].id,
          scheduleTime: scheduleTime,
          strategyId: sequence.strategyId,
        });
      }
      if (analytics) {
        analytics.track('patch_sequence_step', {
          patch: 'scheduleTime',
        });
      }
    }
  };

  const handleReplyToThread = (
    replyToThread: boolean,
    index: number,
    conditionalStepPatch?: boolean,
  ) => {
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];

    if (conditionalStepPatch) {
      if (
        steps[index].sequenceStepConditional!.replyToThread !== replyToThread
      ) {
        patchConditionalSequenceStep({
          sequenceStepId: steps[index].id,
          type: SwitchToTask.TO_EMAIL,
          replyToThread: replyToThread,
        });
      }
    } else {
      //Update the step only if value is different from previous value
      if (steps[index].replyToThread !== replyToThread) {
        steps[index].replyToThread = replyToThread;
        //Update the sequence
        setSequence({ ...sequence, steps });
        patchSequenceStep({
          id: steps[index].id,
          strategyId: sequence.strategyId,
          replyToThread: replyToThread,
        });
      }
    }
    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'replyToThread',
      });
    }
  };

  const handleChangeTemplate = (
    template: Template,
    index: number,
    conditionalStepPatch?: boolean,
  ) => {
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];
    if (conditionalStepPatch) {
      if (steps[index].sequenceStepConditional!.templateId !== template.id) {
        //Currently used only to remove the template, so I just remove everything
        steps[index].sequenceStepConditional!.templateId = null;
        steps[index].sequenceStepConditional!.template = null;
        steps[index].sequenceStepConditional!.automatic = false;
        //Update the sequence
        setSequence({ ...sequence, steps });
        patchConditionalSequenceStep({
          sequenceStepId: steps[index].id,
          type: SwitchToTask.TO_EMAIL,
          templateId: template.id,
          automatic: false,
        });
      }
    } else {
      //Update the step only if value is different from previous value
      if (steps[index].templateId !== template.id) {
        //Currently used only to remove the template, so I just remove everything
        steps[index].templateId = null;
        steps[index].template = null;
        steps[index].automatic = false;
        steps[index].stopSequenceInstanceIfInvitationReplied = false;
        //Update the sequence
        setSequence({ ...sequence, steps });
        patchSequenceStep({
          id: steps[index].id,
          strategyId: sequence.strategyId,
          stopSequenceInstanceIfInvitationReplied: false,
          templateId: template.id,
          automatic: false,
        });
      }
    }
    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'template',
      });
    }
    setStepTemplateIndex(null);
    setConditionalStepTemplate(null);
  };

  const handleDeleteStep = (index: number, conditionalStepPatch?: boolean) => {
    const steps = [...(sequence?.steps || [])];
    // Delete conditional step
    steps[index].sequenceStepConditional = null;
    if (conditionalStepPatch) {
      if (changed !== undefined) {
        changed.current = true;
      }

      deleteConditionalSequenceStep({
        stepId: sequence.steps[index].id,
      });
    } else {
      steps.splice(index, 1);
      deleteSequenceStep({
        strategyId: sequence.strategyId,
        sequenceId: sequence.id,
        stepId: sequence.steps[index].id,
      });
      if (analytics) {
        analytics.track('delete_sequence_step', {});
      }
    }
    setSequence({ ...sequence, steps, id: -1 });
  };

  const handleChangeSubtype = (subtype: TaskTodoSubtype, index: number) => {
    if (subtype === sequence.steps[index].subtype) return;

    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];
    //Update the step
    steps[index].subtype = subtype;
    steps[index].templateId = null;
    steps[index].template = null;
    setSequence({ ...sequence, steps });
    patchSequenceStep({
      id: steps[index].id,
      subtype: steps[index].subtype,
      templateId: steps[index].templateId ?? undefined,
      strategyId: sequence.strategyId,
    });
    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'subtype',
      });
    }
  };

  const handleDeleteConditionals = (index: number) => {
    deleteAllConditionals({
      strategyId: sequence.strategyId,
      invitationStepId: sequence.steps[index]?.id,
    });
    if (analytics) {
      analytics.track('delete-conditionals-for-sequence', {});
    }
    setSequence({ ...sequence, id: -1 });
  };

  const handleStopSequenceInstanceIfInvitationReplied = (
    checked: boolean,
    index: number,
  ) => {
    setSequence((prev) => ({
      ...prev,
      id: -1,
    }));
    patchSequenceStep({
      id: sequence.steps[index].id,
      strategyId: sequence.strategyId,
      stopSequenceInstanceIfInvitationReplied: checked,
    });
  };

  //If a step changes I update the ref
  useEffect(() => {
    if ((sequenceStepPatched || sequenceStepDeleted) && changed !== undefined)
      changed.current = true;
  }, [sequenceStepPatched, sequenceStepDeleted]);

  const handleAllChanges = (type: string, payload: any, index: number) => {
    //In order to update the sequences
    switch (type) {
      case 'delay':
        handleChangeDelay(payload, index);
        break;
      case 'delay-conditional':
        handleChangeDelayConditional(payload, index);
        break;
      case 'delete-conditionals':
        handleDeleteConditionals(index);
        break;
      case 'untilAccepted':
        handleChangeUntilAccepted(payload);
        break;
      case 'automatic':
        handleChangeAutomatic(payload, index);
        break;
      case 'type':
        handleChangeStepType(payload, index);
        break;
      case 'delete':
        handleDeleteStep(index);
        break;
      case 'scheduleTime':
        handleScheduleTime(payload, index);
        break;
      case 'subtype':
        handleChangeSubtype(payload, index);
        break;
      case 'replyToThread':
        handleReplyToThread(payload, index);
        break;
      case 'template':
        handleChangeTemplate(payload, index);
        break;
      case 'stopSequenceInstanceIfInvitationReplied':
        handleStopSequenceInstanceIfInvitationReplied(payload, index);
        break;
      default:
        break;
    }
  };

  const handleAllChangesConditionalStep = (
    type: string,
    payload: any,
    index: number,
  ) => {
    //In order to update the sequences
    switch (type) {
      case 'delay':
        handleChangeDelay(payload, index);
        break;
      case 'automatic':
        handleChangeAutomatic(payload, index, true);
        break;
      case 'scheduleTime':
        handleScheduleTime(payload, index, true);
        break;
      case 'replyToThread':
        handleReplyToThread(payload, index, true);
        break;
      case 'template':
        handleChangeTemplate(payload, index, true);
        break;
      case 'delete':
        handleDeleteStep(index, true);
        break;
      default:
        break;
    }
  };

  const handleAllChangesSequenceStepTemplate = (
    type: string,
    payload: any,
    index: number,
  ) => {
    if (changed !== undefined) {
      changed.current = true;
    }

    //In order to update the sequences
    switch (type) {
      case 'enabled':
        handleEnableSequenceStepTemplate(payload, index);
        break;
      case 'delete':
        handleDeleteSequenceStepTemplate(payload, index);
        break;
      case 'template':
        handleTemplateSequenceStepTemplate(payload, index);
        break;
      default:
        break;
    }
  };

  const handleEnableSequenceStepTemplate = (
    {
      templateIndex,
      enabled,
      sequenceStepTemplateId,
    }: {
      templateIndex: number;
      enabled: boolean;
      sequenceStepTemplateId: number;
    },
    stepIndex: number,
  ) => {
    //Copy the array of steps
    const steps = [...(sequence?.steps || [])];

    //Update the step
    steps[stepIndex].sequenceStepTemplates![templateIndex].enabled = enabled;

    //Update the sequence
    setSequence({ ...sequence, steps });
    patchSequenceStepTemplate({
      sequenceStepTemplateId: sequenceStepTemplateId,
      enabled,
    });

    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'automatic',
      });
    }
  };

  const handleDeleteSequenceStepTemplate = (
    {
      templateIndex,
      sequenceStepTemplateId,
    }: {
      templateIndex: number;
      sequenceStepTemplateId: number;
    },
    stepIndex: number,
  ) => {
    const steps = [...(sequence?.steps || [])];

    // Delete sequenceStepTemplate
    steps[stepIndex].sequenceStepTemplates!.splice(templateIndex, 1);

    // Set template to step.template if it was the last one
    if (steps[stepIndex].sequenceStepTemplates?.length === 1) {
      const temp = steps[stepIndex].sequenceStepTemplates![0];
      steps[stepIndex].sequenceStepTemplates = null;
      steps[stepIndex].template = temp.template as Template;
      steps[stepIndex].templateId = temp.templateId;
      // Update the sequence
      patchSequenceStep({
        id: steps[stepIndex].id,
        strategyId: sequence.strategyId,
        templateId: temp.templateId,
      });
    }
    setSequence({ ...sequence, steps });
    deleteSequenceStepTemplate({ sequenceStepTemplateId });
    analytics?.track('remove-ab-testing', {
      templateId: sequenceStepTemplateId,
    });
  };

  const handleTemplateSequenceStepTemplate = (
    {
      templateIndex,
      sequenceStepTemplateId,
    }: {
      templateIndex: number;
      sequenceStepTemplateId: number;
    },
    stepIndex: number,
  ) => {
    openConfirmModal({
      title: 'Are you sure you want to replace current template?',
      text: 'If you replace the current template then all its statistics will be deleted',
      cancelText: 'Cancel',
      confirmText: 'Replace',
      confirmFunction: () => {
        setSequenceStepTemplateId(sequenceStepTemplateId);
        setReplacingTemplate(sequenceStepTemplateId);
        setSequenceStepTemplate(true);

        setStepTemplateIndex(stepIndex);
        analytics?.track('update-ab-testing', {
          templateId: sequenceStepTemplateId,
        });
      },
      cancelFunction: () => {
        return;
      },
    });
  };

  const [stepTemplateIndex, setStepTemplateIndex] = useState<number | null>(
    null,
  );

  const [conditionalStepTemplate, setConditionalStepTemplate] =
    useState<SwitchToTask | null>(null);
  const [sequenceStepTemplate, setSequenceStepTemplate] =
    useState<boolean>(false);

  const handleTemplatePicker = (type: SwitchToTask) => {
    setConditionalStepTemplate(type);
  };

  const {
    mutateAsync: addSequenceStepTemplateAsync,
    isSuccess: sequenceStepTemplateAdded,
    error: errorAddingSequenceStepTemplate,
  } = useAddSequenceStepTemplate();

  const {
    mutate: patchSequenceStepTemplate,
    isSuccess: sequenceStepTemplatePatched,
    variables: sequenceStepTemplatePatchedVariables,
    error: errorPatchingSequenceStepTemplate,
  } = usePatchSequenceStepTemplate();

  useEffect(() => {
    if (sequenceStepTemplatePatched) {
      analytics?.track('enable-ab-testing', {
        templateId:
          sequenceStepTemplatePatchedVariables?.sequenceStepTemplateId,
        enabled: sequenceStepTemplatePatchedVariables?.enabled,
      });
    }
  }, [sequenceStepTemplatePatched]);

  const {
    mutate: deleteSequenceStepTemplate,
    mutateAsync: deleteSequenceStepTemplateAsync,
    isSuccess: sequenceStepTemplateDeleted,
    error: errorDeletingSequenceStepTemplate,
  } = useDeleteSequenceStepTemplate();

  //To know if the template picker is open to replace an existing AB testing template
  const [replacingTemplate, setReplacingTemplate] = useState<number | null>(
    null,
  );

  const onAdd = async (
    template: Template,
    conditionalStep?: SwitchToTask | null,
    sequenceStepTemplate?: boolean,
    replaceTemplate?: number | null,
  ) => {
    if (stepTemplateIndex === null) return;
    if (changed !== undefined) {
      changed.current = true;
    }
    const steps = [...(sequence?.steps || [])];
    if (replaceTemplate !== null && replaceTemplate !== undefined) {
      //Replace of a template inside the AB testing list
      const responseAdd = await addSequenceStepTemplateAsync({
        sequenceStepId: steps[stepTemplateIndex].id,
        templateId: template.id,
        templateType: template.type,
      });
      //In case of success I add the new template
      if (responseAdd?.data?.data) {
        const sequenceStepTemplates = responseAdd.data.data;

        // if this is the first AB testing template added, I need to add the original template + the new AB testing template one
        // if there is more than one AB testing template, I just need to add the new one
        if (sequenceStepTemplates.length === 2) {
          steps[stepTemplateIndex].sequenceStepTemplates =
            sequenceStepTemplates;
        } else {
          //Put the new one in the same position of the one to replace that is going to be removed
          steps[stepTemplateIndex].sequenceStepTemplates?.splice(
            steps[stepTemplateIndex].sequenceStepTemplates?.findIndex(
              (el) => el.id === replaceTemplate,
            ) ?? 0,
            0,
            sequenceStepTemplates[0],
          );
        }
        steps[stepTemplateIndex].template = null;
        const responseDelete = await deleteSequenceStepTemplateAsync({
          sequenceStepTemplateId: replaceTemplate,
        });
        //Remove the deleted one
        if (responseDelete.status === 200) {
          steps[stepTemplateIndex].sequenceStepTemplates = steps[
            stepTemplateIndex
          ].sequenceStepTemplates?.filter((el) => el.id !== replaceTemplate);
        }
        setSequence({ ...sequence, steps });
        analytics?.track('update-ab-testing', {
          originalTemplateId: replaceTemplate,
          newTemplateId: template.id,
        });
      }
    } else if (sequenceStepTemplate) {
      const response = await addSequenceStepTemplateAsync({
        sequenceStepId: steps[stepTemplateIndex].id,
        templateId: template.id,
        templateType: template.type,
      });
      if (response?.data?.data) {
        const sequenceStepTemplates = response.data.data;

        // if this is the first AB testing template added, I need to add the original template plus the new AB testing template one
        // if there is more than one AB testing template, I just need to add the new one
        if (sequenceStepTemplates.length === 2) {
          steps[stepTemplateIndex].sequenceStepTemplates =
            sequenceStepTemplates;
        } else {
          steps[stepTemplateIndex].sequenceStepTemplates?.push(
            sequenceStepTemplates[0],
          );
        }
        steps[stepTemplateIndex].template = null;
      }
      setSequence({ ...sequence, steps, id: -1 });
      analytics?.track('add-ab-testing', {
        templateId: template.id,
      });
    } else if (conditionalStep) {
      steps[stepTemplateIndex].sequenceStepConditional!.template = template;
      steps[stepTemplateIndex].sequenceStepConditional!.templateId =
        template.id;
      setSequence({ ...sequence, steps });
      patchConditionalSequenceStep({
        sequenceStepId: steps[stepTemplateIndex].id,
        type: conditionalStep,
        templateId: template.id,
      });
    } else {
      steps[stepTemplateIndex].template = template;
      steps[stepTemplateIndex].templateId = template.id;
      setSequence({ ...sequence, steps });
      patchSequenceStep({
        id: sequence.steps[stepTemplateIndex].id,
        strategyId: sequence.strategyId,
        templateId: template.id,
      });
    }
    if (analytics) {
      analytics.track('patch_sequence_step', {
        patch: 'template',
      });
    }
    setStepTemplateIndex(null);
    setConditionalStepTemplate(null);
    setSequenceStepTemplate(false);
    setReplacingTemplate(null);
  };

  const isFirstLinkedin = (index: number) => {
    for (let i = 0; i < index; i++) {
      if (sequence.steps[i].type === TaskTodoType.LINKEDIN) return false;
    }
    return sequence.steps[index].type === TaskTodoType.LINKEDIN;
  };

  const { openModal: openConfirmModal } = useConfirmModal();

  const {
    mutateAsync: addSequenceStep,
    isLoading: loadingAddSequenceStep,
    variables: variablesAddSequenceStep,
    isSuccess: stepAdded,
    error: errorAddingStep,
  } = useAddSequenceStep();

  const indexFirstLinkedin = useMemo(() => {
    return IndexFirstLinkedin(sequence.steps);
  }, [sequence]);

  const sequenceContainsConditionals = useMemo(() => {
    return (
      sequence?.steps?.some((step) => !!step.sequenceStepConditional) ?? false
    );
  }, [sequence]);

  //-1 if we don't want to show the until badge
  const indexFirstMsgAfterInvitation = useMemo(() => {
    return IndexFirstLinkedinAfterInvitation(sequence.steps);
  }, [sequence]);

  const calculatedDates: { step: string; stepConditional?: string }[] =
    useMemo(() => {
      let totalDelay = 0;
      let delayBetweenInvitationAndConditional = 0;
      let conditionalFlowTotalDelay = 0;
      let firstConditional = true;

      return (
        sequence?.steps?.map((step, index) => {
          if (!step.sequenceStepConditional) {
            totalDelay += step.delay;
            conditionalFlowTotalDelay += step.delay;

            if (indexFirstLinkedin !== null && index > indexFirstLinkedin) {
              delayBetweenInvitationAndConditional += step.delay;
            }

            return {
              step: moment().businessAdd(totalDelay).format('ll'),
            };
          } else {
            if (firstConditional) {
              if (
                delayBetweenInvitationAndConditional <
                step.sequenceStepConditional.linkedinUntilAcceptedDays
              ) {
                totalDelay +=
                  step.sequenceStepConditional.linkedinUntilAcceptedDays -
                  delayBetweenInvitationAndConditional;
              }
              // start of conditional flow difference
              conditionalFlowTotalDelay = totalDelay;
              firstConditional = false;
            }

            totalDelay += step.delay;
            conditionalFlowTotalDelay +=
              step.sequenceStepConditional.daysAfterConditionNotAccepted;

            return {
              step: moment().businessAdd(totalDelay).format('ll'),
              stepConditional: moment()
                .businessAdd(conditionalFlowTotalDelay)
                .format('ll'),
            };
          }
        }) ?? []
      );
    }, [sequence, sequence.steps, indexFirstMsgAfterInvitation]);

  const { mutate: deleteAllConditionals, error: errorDeleteAllConditionals } =
    useDeleteAllConditionals();

  useConditionalSnackBar([
    {
      condition:
        !!errorPatchSequenceStep || !!errorConditionalPatchSequenceStep,
      message: getError(errorPatchSequenceStep) ?? 'Error updating the step',
      severity: 'error',
    },
    {
      condition:
        !!errorDeleteSequenceStep || !!errorDeleteConditionalPatchSequenceStep,
      message: getError(errorDeleteSequenceStep) ?? 'Error deleting the step',
      severity: 'error',
    },
    {
      condition: !!errorAddingStep,
      message: getError(errorAddingStep) ?? 'Error adding the step',
      severity: 'error',
    },
    {
      condition: !!stepAdded,
      message: 'Step added',
      severity: 'success',
    },
    {
      condition: !!errorEnableConditionalForSequence,
      message:
        getError(errorEnableConditionalForSequence) ?? 'Error on add condition',
      severity: 'error',
    },
    {
      condition: successEnableConditionalForSequence,
      message:
        'A condition has been added successfully to all LinkedIn messages',
      severity: 'success',
    },
    {
      condition: !!sequenceStepTemplateAdded,
      message: 'Template added',
      severity: 'success',
    },
    {
      condition: !!errorAddingSequenceStepTemplate,
      message:
        getError(errorAddingSequenceStepTemplate) ?? 'Error adding template',
      severity: 'error',
    },
    {
      condition: !!errorDeletingSequenceStepTemplate,
      message:
        getError(errorDeletingSequenceStepTemplate) ??
        'Error deleting template',
      severity: 'error',
    },
    {
      condition: !!errorDeleteAllConditionals,
      message:
        getError(errorDeleteAllConditionals) ??
        'Error while removing conditional steps',
      severity: 'error',
    },
  ]);

  const [sequenceStepTemplateId, setSequenceStepTemplateId] = useState<
    number | null
  >(null);

  // console.log({ stepTemplateIndex });

  // TODO Template selection logic accounts if the template is from normal step, conditional step or first linkedin step accomplished
  // First: Check if template belongs to which conditional step (2 possibilities: InMail or Email)
  // Second: Check if template belongs to the top level step (same 2 possibilities)
  // Third: If step is of Linkedin Type, check if it is the first Linkedin sent (2 possibilities: Invitation or VoiceNote)
  // Last: If none of the above, it is a Linkedin Message
  const templateType = useMemo(() => {
    if (stepTemplateIndex !== null) {
      return conditionalStepTemplate === SwitchToTask.TO_CALL
        ? TemplateType.SCRIPT
        : conditionalStepTemplate === SwitchToTask.TO_EMAIL
          ? TemplateType.EMAIL
          : conditionalStepTemplate === SwitchToTask.IN_MAIL
            ? TemplateType.IN_MAIL
            : sequence.steps[stepTemplateIndex].type === TaskTodoType.CALL
              ? TemplateType.SCRIPT
              : sequence.steps[stepTemplateIndex].type === TaskTodoType.EMAIL
                ? TemplateType.EMAIL
                : sequence.steps[stepTemplateIndex].type ===
                    TaskTodoType.IN_MAIL
                  ? TemplateType.IN_MAIL
                  : isFirstLinkedin(stepTemplateIndex) &&
                      sequence.steps[stepTemplateIndex].subtype ===
                        TaskTodoSubtype.LinkedinInvitation
                    ? TemplateType.INVITATION
                    : sequence.steps[stepTemplateIndex].subtype ===
                        TaskTodoSubtype.LinkedinVoiceNote
                      ? TemplateType.SCRIPT
                      : TemplateType.LINKEDIN;
    } else {
      return TemplateType.LINKEDIN;
    }
  }, [stepTemplateIndex, conditionalStepTemplate]);

  const memoizedTemplateComponent = useMemo(() => {
    if (stepTemplateIndex !== null) {
      return (
        <TemplatesTooltipCard
          selectedTemplateId={
            conditionalStepTemplate
              ? sequence.steps[stepTemplateIndex].sequenceStepConditional
                  ?.template?.id ?? null
              : sequence.steps[stepTemplateIndex].template?.id ?? null
          }
          setSelectedTemplate={(template: Template) =>
            onAdd(
              template,
              conditionalStepTemplate,
              sequenceStepTemplate,
              replacingTemplate,
            )
          }
          hideShared={false}
          hideAll={false}
          selectedType={templateType}
          showAnalytics={true}
          close={() => {
            setStepTemplateIndex(null);
            setConditionalStepTemplate(null);
            setSequenceStepTemplate(false);
            setSequenceStepTemplateId(null);
            setReplacingTemplate(null);
          }}
          sequenceStepTemplateId={sequenceStepTemplateId}
          visibility={'visible'}
        />
      );
    } else {
      return null;
    }
  }, [
    stepTemplateIndex,
    conditionalStepTemplate,
    sequenceStepTemplate,
    replacingTemplate,
    templateType,
    sequenceStepTemplateId,
  ]);

  //Since the arrows are declared at this level they do not notice if the child component changes, so we need to make it trigger
  //The ping from child is called everytime the child component changes
  const [keyArrow, setKeyArrow] = useState<number>(0);

  const elementRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      setKeyArrow(Math.random() * 100000);
    });
    resizeObserver.observe(node);
  }, []);

  const [addStepModalIndex, setAddStepModalIndex] = useState<null | number>(
    null,
  );
  const handleCreateStrategy = (
    type: TaskTodoType,
    subtype: TaskTodoSubtype | null,
  ) => {
    const strategyInsert: StrategyInsert = {
      name: sequence.name ?? FeConstants.defaultNewStrategyName,
      numberOfSteps: 1,
      firstStep: type,
      firstStepSubType: subtype,
      emailPercent: type === TaskTodoType.EMAIL ? 100 : 0,
      callPercent: type === TaskTodoType.CALL ? 100 : 0,
      linkedinPercent: type === TaskTodoType.LINKEDIN ? 100 : 0,
      shared: user?.userRoles.includes(Role.MANAGER) ? sequence.shared : false,
      strategyTags: sequence.tags,
    };

    createStrategy(strategyInsert);
  };

  const {
    mutate: createStrategy,
    data: createStrategyData,
    error: createStrategyError,
    isSuccess: strategyCreated,
  } = useCreateStrategy();

  //Customerly for events for workflows
  const { event } = useCustomerly();
  const { user } = useAuth();
  const { setStrategyOverviewContent } = useShortStrategyOverviewContext();

  useEffect(() => {
    if (strategyCreated && createStrategyData?.data?.data) {
      event('create_strategy');
      setStrategyOverviewContent({
        strategyId: createStrategyData?.data?.data,
        initialTab: StrategyOverviewTab.Detail,
      });
    }
  }, [strategyCreated]);

  useConditionalSnackBar([
    {
      condition: !!createStrategyError,
      message: getError(createStrategyError) ?? 'Error while create strategy',
      severity: 'error',
    },
  ]);

  const handleAddStep = async (
    type: TaskTodoType,
    subtype: TaskTodoSubtype | null,
  ) => {
    if (isNewSequenceView) {
      handleCreateStrategy(type, subtype);
    } else {
      if (!sequence.strategyId || addStepModalIndex == null) return;
      if (changed !== undefined) {
        changed.current = true;
      }

      const newStep = await addSequenceStep({
        strategyId: sequence.strategyId,
        type,
        subtype,
        order: addStepModalIndex + 1,
        active: true,
        delay: 1,
        scheduleTime: null,
        automatic: false,
        templateId: null,
      });
      const steps = [...(sequence?.steps || [])];

      if (newStep.data?.data) {
        steps.splice(addStepModalIndex, 0, newStep.data?.data);
        setSequence({ ...sequence, steps, id: -1 });
      }

      if (analytics) {
        analytics.track('patch_sequence', {
          patch: 'add_step',
          strategyId: sequence.strategyId,
        });
      }
    }
  };

  return (
    <>
      {addStepModalIndex !== null && (
        <AddStepModal
          onClose={() => setAddStepModalIndex(null)}
          onSubmit={handleAddStep}
        />
      )}
      {handleClickOnBackground && (
        <BlurStrategyModal onClick={handleClickOnBackground} />
      )}

      <StrategyDetailWrapper
        style={
          isNewSequenceView ? { height: '100%', overflow: 'hidden' } : undefined
        }
      >
        {isNewSequenceView ? (
          <CreateStrategyView onAddClick={() => setAddStepModalIndex(0)} />
        ) : (
          <div
            className={classNames('steps-out-container')}
            style={previewOnly ? { padding: '0' } : {}}
          >
            <div className="steps-container" ref={elementRef} key={sequence.id}>
              {sequence?.steps?.map((step, index, steps) => {
                const prevStep = index !== 0 ? steps[index - 1] : null;
                const enableBadges = index === indexFirstMsgAfterInvitation;
                const conditionalAfterInvitation =
                  prevStep?.subtype === TaskTodoSubtype.LinkedinInvitation &&
                  !!step.sequenceStepConditional;

                return (
                  <>
                    <StepDetailComponent
                      previewOnly={previewOnly}
                      step={step}
                      linkedinUntilAcceptedDays={
                        steps[indexFirstMsgAfterInvitation]
                          ?.sequenceStepConditional?.linkedinUntilAcceptedDays
                      }
                      handleChange={handleAllChanges}
                      handleChangeConditionalStep={
                        handleAllChangesConditionalStep
                      }
                      index={index}
                      calculatedDate={calculatedDates[index].step}
                      calculatedDateConditional={
                        calculatedDates[index].stepConditional
                      }
                      openTemplatePicker={() => {
                        setStepTemplateIndex(index);
                        setConditionalStepTemplate(null);
                        setSequenceStepTemplate(false);
                        setSequenceStepTemplateId(null);
                        setReplacingTemplate(null);
                      }}
                      openConditionalTemplatePicker={(type: SwitchToTask) => {
                        handleTemplatePicker(type);
                        setStepTemplateIndex(index);
                        setReplacingTemplate(null);
                        setSequenceStepTemplate(false);
                        setSequenceStepTemplateId(null);
                      }}
                      openABTestingTemplatePicker={(
                        sequenceStepId: number | null,
                      ) => {
                        setStepTemplateIndex(index);
                        setSequenceStepTemplateId(sequenceStepId);
                        //This method is used for the add template, not to replace an existing one
                        setReplacingTemplate(null);
                        setSequenceStepTemplate(true);
                        setConditionalStepTemplate(null);
                      }}
                      //This is a trick to force the re-render of the component when the template is changed
                      key={`${step.templateId}${
                        step.template?.content.length ?? '1'
                      }${index}`}
                      firstLinkedin={indexFirstLinkedin === index}
                      beforeFirstLinkedin={
                        (indexFirstLinkedin ?? sequence.steps.length) > index
                      }
                      loadingAdd={
                        loadingAddSequenceStep &&
                        variablesAddSequenceStep &&
                        variablesAddSequenceStep.order === index + 1
                      }
                      openAddStepModal={(index: number) =>
                        setAddStepModalIndex(index)
                      }
                      sequence={sequence}
                      patchConditionalStep={handlePatchConditionalSequenceStep}
                      enableConditionalForSequence={
                        handleEnableConditionalForSequence
                      }
                      handleChangeSequenceStepTemplate={
                        handleAllChangesSequenceStepTemplate
                      }
                      onSuccessEditTemplate={() => {
                        setSequence({ ...sequence, id: -1 });
                      }}
                      closeTemplateTooltip={() => setStepTemplateIndex(null)}
                      showTemplateTab={stepTemplateIndex === index}
                      templateComponent={memoizedTemplateComponent}
                    />
                    {indexFirstLinkedin !== null &&
                      index > indexFirstLinkedin &&
                      sequenceContainsConditionals &&
                      prevStep && (
                        <>
                          <StrategyXarrow
                            key={`${index}-1-${keyArrow}`}
                            gridBreak={
                              conditionalAfterInvitation ? '50%' : '100%-80'
                            }
                            start={prevStep.id.toString()}
                            end={step.id.toString()}
                            color={
                              !step.sequenceStepConditional &&
                              !prevStep.sequenceStepConditional
                                ? colors.grey33
                                : colors.darkGreen
                            }
                            labels={
                              enableBadges
                                ? {
                                    middle: (
                                      <InvitationAcceptedBadge
                                        extraMargin={
                                          !conditionalAfterInvitation
                                        }
                                      />
                                    ),
                                  }
                                : undefined
                            }
                          />
                          {prevStep.sequenceStepConditional ||
                          step.sequenceStepConditional ? (
                            <StrategyXarrow
                              key={`${index}-2-${keyArrow}`}
                              gridBreak={
                                conditionalAfterInvitation ? '50%' : '100%-80'
                              }
                              start={
                                prevStep.sequenceStepConditional
                                  ? `${prevStep.sequenceStepConditional.id}-conditional`
                                  : prevStep.id.toString()
                              }
                              end={
                                step.sequenceStepConditional
                                  ? `${step.sequenceStepConditional.id.toString()}-conditional`
                                  : step.id.toString()
                              }
                              color={colors.inactive}
                              labels={
                                enableBadges
                                  ? {
                                      middle: (
                                        <InvitationNotAcceptedBadge
                                          extraMargin={
                                            !conditionalAfterInvitation
                                          }
                                        />
                                      ),
                                    }
                                  : undefined
                              }
                            />
                          ) : null}
                        </>
                      )}
                  </>
                );
              })}

              {!previewOnly && (
                <SequenceStepWrapper>
                  <div className="step-row">
                    {loadingAddSequenceStep &&
                    variablesAddSequenceStep?.order ===
                      sequence.steps.length + 1 ? (
                      <CircularProgress
                        size={18}
                        style={{
                          marginInline: '21px',
                        }}
                      />
                    ) : (
                      <div className={'last-step-container'}>
                        <AddStepNumberCount
                          number={sequence.steps.length + 1}
                          className="number-container"
                        />
                        <AddStepPlaceholder
                          onClick={() => {
                            if (!loadingAddSequenceStep)
                              setAddStepModalIndex(sequence.steps.length);
                          }}
                          text={'Add step'}
                          className={'add-step-placeholder'}
                        />
                      </div>
                    )}
                  </div>
                </SequenceStepWrapper>
              )}

              <div
                className="steps-line"
                style={previewOnly ? { left: '20px' } : {}}
              >
                <div className="start-triangle" />
                <div className="end-triangle" />
              </div>
            </div>
          </div>
        )}
      </StrategyDetailWrapper>
    </>
  );
};

export default StrategyDetailModal;
