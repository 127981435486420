import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { FileContent } from 'use-file-picker';

export interface TemplateAttachmentInput {
  templateId: number;
  attachments: FileContent[];
}

export default function useAddTemplateAttachment() {
  const queryClient = useQueryClient();

  const createTemplateAttachment = (
    templateAttachmentInput: TemplateAttachmentInput,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.template.attachment.main}`,
      config: {
        method: 'post',
        data: templateAttachmentInput,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    TemplateAttachmentInput
  >(createTemplateAttachment, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.template.attachment.main]);
    },
  });

  return { data, mutate, ...rest };
}
