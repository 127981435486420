import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Strategy } from 'crono-fe-common/types/strategy';

export interface IDuplicateStrategyLibrary {
  strategyId: number;
  language?: string;
}

interface DuplicateStrategyResponse {
  data: Strategy[];
  total: number;
  count: number;
  limit: number;
  offset: number;
  someNumber: number;
}

export default function useDuplicateStrategyLibrary() {
  const queryClient = useQueryClient();

  const request: Request = {
    url: ENDPOINTS.sequence.libraryDuplicate,
    config: {
      method: 'post',
    },
  };

  const mutationFn = async (strategyData: IDuplicateStrategyLibrary) => {
    const response = await client({
      ...request,
      config: { ...request.config, data: strategyData },
    });
    return response.data;
  };

  const requestConfig = {
    mutationFn,
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
    },
    // Add enabled to the mutation options if needed, typically not used with useMutation
  };

  return useMutation<
    Response<DuplicateStrategyResponse>,
    CronoError,
    IDuplicateStrategyLibrary
  >(mutationFn, requestConfig);
}
