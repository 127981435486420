import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type SiteCheckoutSessionInput = {
  priceId: string | null;
  quantity: number;
  email: string | null;
};

export default function useSiteCheckoutSession() {
  const callSiteCheckout = (input: SiteCheckoutSessionInput) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.site.checkout}`,
      config: {
        method: 'POST',
        data: input,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<{ url: string }>,
    Error,
    SiteCheckoutSessionInput
  >(callSiteCheckout, {});

  return { data, mutate, ...rest };
}
