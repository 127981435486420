import { FC, useEffect, useMemo, useState } from 'react';
import { LogLinkedin } from 'crono-fe-common/types/logLinkedin';
import { TableProps } from '../model';
import moment from 'moment';
import { CronoButton } from 'crono-fe-common/components/CronoButton';
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import useDownloadLinkedinsReport from 'hooks/services/event/useDownloadLinkedinsReport';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { FeConstants } from 'constants/FeConstants';
import DownloadIcon from '@mui/icons-material/Download';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { CronoLinkedinSearchSortType } from 'crono-fe-common/types/enums/cronoLinkedinSearchSortType';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';
import {
  formatJsonToCSV,
  formatTodayDate,
  downloadCSVFile,
} from 'utils/fe-utils';
import { renderNoChartResults } from '..';

const LinkedinsTable: FC<TableProps<LogLinkedin>> = ({
  data,
  total,
  limit,
  offset,
  setOffset,
  handleSelectReportMessage,
  isAnsweredLinkedin,
  setIsAnsweredLinkedin,
  userId,
  since,
  to,
  selectedAccount,
  setSortModelLinkedin,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [downloadComplete, setDownloadComplete] = useState<boolean>(false);

  const {
    data: linkedinsReport,
    mutate: downloadLinkedinsReport,
    isLoading: isLoadingLinkedinsReport,
    error: errorLinkedinsReport,
    reset: resetLinkedinsReport,
  } = useDownloadLinkedinsReport();

  useConditionalSnackBar([
    {
      condition: !!errorLinkedinsReport,
      message:
        getError(errorLinkedinsReport) ??
        'Error while downloading LinkedIn report',
      severity: 'error',
    },
    {
      condition: downloadComplete,
      message: 'LinkedIn report downloaded successfully',
      severity: 'success',
    },
    {
      condition: !!errorMessage,
      message: errorMessage ?? 'Error while downloading LinkedIn report',
      severity: 'error',
    },
    {
      condition: !!infoMessage,
      message: infoMessage ?? '',
      severity: 'info',
    },
  ]);

  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();
  const { openWindow } = useWindow();

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      width: 140,
      filterable: false,
      sortable: true,
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 140,
      filterable: false,
      sortable: true,
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          e.stopPropagation();
          if (!params.row.accountId) return;
          setSelectedAccountId(params.row.accountId);
          setTimeout(() => {
            openWindow({
              windowType: 'account',
              tab: 'account',
            });
          }, 100);
        };

        return (
          <Typography
            fontSize={14}
            fontWeight={500}
            onClick={onClick}
            noWrap
            style={{ cursor: 'pointer' }}
          >
            {params.row.company}
          </Typography>
        );
      },
    },
    {
      field: 'contact',
      headerName: 'Contact',
      width: 140,
      filterable: false,
      sortable: true,
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          e.stopPropagation();
          if (!params.row.prospectId || !params.row.accountId) return;
          setSelectedAccountId(params.row.accountId);
          setSelectedProspectId(params.row.prospectId);
          setTimeout(() => {
            openWindow({
              windowType: 'account',
              selectedProspectId: params.row.prospectId,
              tab: 'prospect',
            });
          }, 100);
        };

        return (
          <Typography
            fontSize={14}
            fontWeight={500}
            onClick={onClick}
            noWrap
            style={{ cursor: 'pointer' }}
          >
            {params.row.contact}
          </Typography>
        );
      },
    },
    {
      field: 'templateTitle',
      headerName: 'Template',
      width: 140,
      filterable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip
            enterDelay={400}
            enterNextDelay={400}
            title={params.row.templateTitle}
            arrow
          >
            <Typography noWrap>{params.row.templateTitle}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'linkedinType',
      headerName: 'Type',
      width: 140,
      filterable: false,
      sortable: true,
    },
    {
      field: 'isAnswered',
      headerName: 'Replied',
      width: 70,
      filterable: false,
      sortable: true,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      width: 135,
      filterable: false,
      sortable: true,
    },
    {
      field: 'view',
      headerName: 'View',
      filterable: false,
      sortable: false,
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          if (handleSelectReportMessage) {
            handleSelectReportMessage({
              account: params.row.accountObject,
              prospect: params.row.prospectObject,
              content: params.row.content,
              sentTime: params.row.date,
              subject: null,
              type: 'linkedin',
            });
          }
        };

        if (params.row.content) {
          return <CronoButton onClick={onClick}>Open</CronoButton>;
        }

        return <div />;
      },
    },
  ];

  const getLinkedinRows = (data: LogLinkedin[]) => {
    return data.map((linkedin, index) => ({
      id: index,
      date: moment(linkedin.eventDatetime).local().format('DD/MM/YYYY HH:mm'),
      company: linkedin.account?.name ?? '',
      contact: linkedin.prospect?.name ?? '',
      linkedinType: linkedin.linkedinType,
      isAnswered: linkedin.isAnswered ? 'Yes' : 'No',
      owner: linkedin.owner ?? '',
      content: linkedin.content,
      accountObject: linkedin.account,
      prospectObject: linkedin.prospect,
      prospectId: linkedin.prospectId,
      accountId: linkedin.accountId,
      templateTitle: linkedin.templateTitle ?? '',
    }));
  };

  const getLinkedinRowsForReport = (data: LogLinkedin[]) => {
    return data.map((linkedin, index) => ({
      date: moment(linkedin.eventDatetime).local().format('DD/MM/YYYY HH:mm'),
      company: linkedin.account?.name ?? '',
      contact: linkedin.prospect?.name ?? '',
      type: linkedin.linkedinType,
      reply: linkedin.isAnswered ? 'Yes' : 'No',
      owner: linkedin.owner ?? '',
    }));
  };

  const downloadReport = () => {
    downloadLinkedinsReport({
      ...(userId && { userId }),
      ...(since && { since }),
      ...(to && { to }),
      ...{ limit: FeConstants.downloadReportLimit, offset: 0 },
      ...(selectedAccount && { accountId: selectedAccount }),
      ...(isAnsweredLinkedin && { isAnsweredLinkedin }),
    });
  };

  useEffect(() => {
    if (linkedinsReport?.data && linkedinsReport?.data.data) {
      setDownloadComplete(false);
      setErrorMessage(null);
      setInfoMessage(null);
      if (total > FeConstants.downloadReportLimit) {
        setInfoMessage(
          `The report will be limited to the first ${FeConstants.downloadReportLimit} linkedin messages`,
        );
      }
      const linkedinRows = getLinkedinRowsForReport(linkedinsReport.data.data);
      if (linkedinRows.length === 0) {
        setErrorMessage('No data to download');
        return;
      }
      try {
        const csv = formatJsonToCSV(linkedinRows);

        // get filename from today's date
        const date = formatTodayDate();

        downloadCSVFile(date + ' - linkedin report.csv', csv);
      } catch (e) {
        setErrorMessage('Error while downloading linkedin report');
        return;
      }

      setDownloadComplete(true);
      setTimeout(() => {
        setDownloadComplete(false);
        setErrorMessage(null);
        setInfoMessage(null);
      }, 3000);
      resetLinkedinsReport();
    }
  }, [linkedinsReport, resetLinkedinsReport]);

  const handleChangeSortModel = (model: GridSortModel) => {
    if (model.length === 0) {
      if (!setSortModelLinkedin) return;
      setSortModelLinkedin(null);
      return;
    }
    const field = model[0].field;
    const sort = model[0].sort;
    let newModel: CronoLinkedinSearchSortType | null = null;
    switch (field) {
      case 'date':
        newModel =
          sort === 'asc'
            ? CronoLinkedinSearchSortType.DATE
            : CronoLinkedinSearchSortType.DATEDESC;
        break;
      case 'company':
        newModel =
          sort === 'asc'
            ? CronoLinkedinSearchSortType.COMPANYNAME
            : CronoLinkedinSearchSortType.COMPANYNAMEDESC;
        break;
      case 'contact':
        newModel =
          sort === 'asc'
            ? CronoLinkedinSearchSortType.CONTACTNAME
            : CronoLinkedinSearchSortType.CONTACTNAMEDESC;
        break;

      case 'isAnswered':
        newModel =
          sort === 'asc'
            ? CronoLinkedinSearchSortType.REPLIED
            : CronoLinkedinSearchSortType.REPLIEDDESC;
        break;
      case 'owner':
        newModel =
          sort === 'asc'
            ? CronoLinkedinSearchSortType.OWNERFULLNAME
            : CronoLinkedinSearchSortType.OWNERFULLNAMEDESC;
        break;
      case 'linkedinType':
        newModel =
          sort === 'asc'
            ? CronoLinkedinSearchSortType.TYPE
            : CronoLinkedinSearchSortType.TYPEDESC;
        break;
      default:
        break;
    }
    if (!setSortModelLinkedin) return;
    setSortModelLinkedin(newModel);
  };

  const rows = useMemo(() => {
    return getLinkedinRows(data);
  }, [data]);

  return (
    <>
      <FlexDiv
        direction="row"
        justifyContent="space-between"
        className="table-header"
      >
        <h2 className="chart-title">LinkedIn</h2>
        {setIsAnsweredLinkedin && (
          <Select
            sx={{
              boxshadow: 'none',
              '.MuiOutlinedInput-notchedOutline': {
                border: 0,
              },
            }}
            className={
              isAnsweredLinkedin === null
                ? 'table-select-placeholder'
                : 'table-select'
            }
            id="linkedinFeedback"
            name="linkedinFeedback"
            value={isAnsweredLinkedin ?? 'none'}
            onChange={(e) => {
              const value = e.target.value;
              if (value === 'none') {
                setIsAnsweredLinkedin(null);
              } else {
                setIsAnsweredLinkedin(value === 'true' ? true : false);
              }
            }}
          >
            <MenuItem value="none">All</MenuItem>
            <MenuItem value={'true'}>Replied</MenuItem>
            <MenuItem value={'false'}>Not Replied</MenuItem>
          </Select>
        )}
        <FlexDiv justifyContent="center" style={{ width: 40 }}>
          {isLoadingLinkedinsReport ? (
            <CircularProgress size={20} style={{ marginRight: 5 }} />
          ) : (
            <IconButton onClick={() => downloadReport()}>
              <DownloadIcon />
            </IconButton>
          )}
        </FlexDiv>
      </FlexDiv>
      {data.length === 0 ? (
        <>{renderNoChartResults('linkedin')}</>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          rowCount={total}
          pageSizeOptions={[limit]}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleChangeSortModel}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: limit,
                page: offset / limit,
              },
            },
          }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setOffset(model.page * limit);
          }}
        />
      )}
    </>
  );
};

export default LinkedinsTable;
