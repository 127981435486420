import { Add, PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { colors } from 'crono-fe-common/theme';
import styled from '@emotion/styled';
import IconRectangle from 'crono-fe-common/icons/Icon-Rectangle';

type Action = 'add' | 'start' | 'stop';

interface IProps {
  onClick?: () => void;
  action: Action;
  iconButtonStyle?: any;
  size?: 'small' | 'medium' | 'large';
  iconFill?: string;
  disableHover?: boolean;
  disableTooltip?: boolean;
}

const SequenceButton = React.forwardRef((props: IProps, ref: any) => {
  const {
    onClick,
    action,
    iconButtonStyle,
    size,
    iconFill,
    disableTooltip,
    ...rest
  } = props;
  return (
    <SequenceButtonWrapper ref={ref} {...rest}>
      <Tooltip
        arrow
        title={
          disableTooltip
            ? null
            : action === 'add'
              ? 'Add to strategy'
              : action === 'start'
                ? 'Resume strategy'
                : null
        }
        placement="top"
      >
        <IconButton
          onClick={() => {
            onClick && onClick();
          }}
          className={
            action === 'add'
              ? 'green-sequence-button'
              : action === 'stop'
                ? 'orange-sequence-button'
                : 'resume-sequence-button'
          }
          size={size}
          style={iconButtonStyle}
        >
          {action === 'add' ? (
            <Add
              fontSize={size}
              sx={{
                '&.MuiSvgIcon-root': {
                  fill: iconFill,
                },
              }}
            />
          ) : action === 'start' ? (
            <PlayArrowRounded
              fontSize={size}
              sx={{
                '&.MuiSvgIcon-root': {
                  fill: iconFill,
                },
              }}
            />
          ) : (
            <IconRectangle
              color={colors.orangeDark}
              className="stop-strategy-rectangle"
            />
          )}
        </IconButton>
      </Tooltip>
    </SequenceButtonWrapper>
  );
});

const SequenceButtonWrapper = styled(Box)<{ disableHover?: boolean }>`
  display: flex;
  justify-content: center;
  .resume-sequence-button {
    border-radius: 999px;
    background: ${colors.callScheduledLight};
    color: ${colors.callScheduledHover};
    padding: 5px;
    &:hover {
      background: ${({ disableHover }) =>
        disableHover ? undefined : colors.callScheduledLightHover};
      color: ${colors.callScheduledHover};
    }
  }
  .green-sequence-button {
    border-radius: 999px;
    background: ${colors.primaryLight};
    color: ${colors.primary};
    &:hover {
      background: ${({ disableHover }) =>
        disableHover ? undefined : colors.primaryLightHover};
      color: ${colors.primaryDark};
    }
  }
  .orange-sequence-button {
    border-radius: 999px;
    background: ${colors.orangeLight};
    color: ${colors.orange};
    padding: 9px;
    .stop-strategy-rectangle {
      width: 12px;
      height: 12px;
    }
    &:hover {
      background: ${({ disableHover }) =>
        disableHover ? undefined : colors.orangeLightHover};
      color: ${colors.orangeDark};
    }
  }
`;

export default SequenceButton;
