import { FC, useEffect } from 'react';
import Logo from '../../assets/images/logo_crono.png';
import { ReactComponent as NoMobileSupport } from '../../assets/images/No-mobile-support.svg';
import { NotSupportedView } from './styles';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { useJuneAnalytics } from 'context/june';
import useScreenSize from 'hooks/useScreenSize';

const ResolutionNotSupported: FC = () => {
  const analytics = useJuneAnalytics();
  const { width, height } = useScreenSize();
  useEffect(() => {
    if (analytics) {
      analytics.track('small-screen-tentative-access', {
        width,
        height,
      });
    }
  }, []);
  return (
    <NotSupportedView id="resolution-not-supported">
      <img src={Logo} alt="Crono" className="logo" />
      <div className="no-resolution-support">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            paddingBottom: '100px',
          }}
        >
          <NoMobileSupport />
          <Typography
            color={colors.grey1}
            fontSize={'20px'}
            fontWeight={500}
            textAlign={'center'}
            lineHeight={'24px'}
            maxWidth={'280px'}
          >
            Crono currently does not support this device resolution
          </Typography>
          <Typography
            color={colors.grey11}
            fontSize={'16px'}
            textAlign={'center'}
            lineHeight={'24px'}
            maxWidth={'230px'}
          >
            Please change devices or increase your screen size
          </Typography>
        </div>
      </div>
    </NotSupportedView>
  );
};

export default ResolutionNotSupported;
