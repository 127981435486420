import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const RemainingTokensBadgeWrapper = styled.div`
  cursor: pointer;
  .remaining-tokens,
  .remaining-tokens-unsufficient {
    display: flex;
    width: fit-content;
    gap: 4px;
    border-radius: 20px;
    align-items: center;
    height: 26px;
  }
  .remaining-tokens {
    padding: 4px 8px;
    background: ${colors.primaryLight};
    color: ${colors.primaryDark};
    &:hover {
      background: ${colors.primaryLightHover};
    }
  }
  .remaining-tokens-unsufficient {
    padding: 4px 4px 4px 8px;
    background: ${colors.inactiveLight};
    color: ${colors.inactiveHover};
    &:hover {
      background: ${colors.inactiveLightHover};
    }
  }
  .buy-more-button {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 2px 4px 2px 6px;
    border-radius: 20px;
    height: 18px;
    background: ${colors.inactive};
    &:hover {
      background: ${colors.inactiveHover};
    }
    cursor: pointer;
  }
`;
