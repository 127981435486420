import {
  AccountIdentifiers,
  CronoGatewayLinkedinAction,
  ProspectIdentifiers,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { CronoGatewayBackgroundAction } from 'crono-fe-common/types/crono-extension/background-script';
import { CronoGatewayCronoAction } from 'crono-fe-common/types/crono-extension/crono';
import { compareVersions } from 'crono-fe-common/utils';
import { CronoGatewayGoogleAction } from 'crono-fe-common/types/crono-extension/google';

export type CronoGatewayAction =
  | CronoGatewayLinkedinAction
  | CronoGatewayCronoAction
  | CronoGatewayBackgroundAction
  | CronoGatewayGoogleAction;

export interface CronoGatewayResponse<T extends CronoGatewayAction> {
  status:
    | 'callSuccess'
    | 'unknownMethod'
    | 'invalidParams'
    | 'error'
    | 'authError'
    | 'tooManyRequests'
    | 'profileError';
  result?: T['result'];
  reason?: string;
}

export type CronoGatewayParams<T extends CronoGatewayAction> =
  | CronoGatewayParamsProspect<T>
  | CronoGatewayParamsAccount<T>
  | T['params'];

export type CronoGatewayParamsProspect<T extends CronoGatewayAction> =
  T['params'] & ProspectIdentifiers;

export type CronoGatewayParamsAccount<T extends CronoGatewayAction> =
  T['params'] & AccountIdentifiers;

export const requiredExtensionVersionByActionTargetAndMethodName: Partial<
  Record<
    `${CronoGatewayAction['target']}:${CronoGatewayAction['methodName']}`,
    string
  >
> = {
  'linkedin:getAllInvitations': '1.3.14',
  'background-script:getExtensionVersion': '1.3.15',
  'linkedin:getPublicIdFromLeadId': '2.0.3',
};

export const latestKnownExtensionVersion = Object.values(
  requiredExtensionVersionByActionTargetAndMethodName,
)
  .sort(compareVersions)
  .reverse()[0];
