import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  getError,
  getNewOptionsWhenSelectingWithStrings,
} from 'crono-fe-common/utils';
import {
  ProspectUpdateInputs,
  initialValues,
  validationSchemaUpdate,
} from './model';
import { useFormik } from 'formik';
import { trimObject } from 'crono-fe-common/utils/object';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  CancelButton,
  MainButton,
  MainPrimaryButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useConditionalSnackBar } from 'context/snackbar';
import { InsertCompanyWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import { Account, AccountStatus } from 'crono-fe-common/types/account';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import { ReactComponent as ToolsIcon } from 'crono-fe-common/icons/Tools.svg';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import FindEmailPreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findEmailPreconditionButton';
import useAccounts from 'hooks/services/account/useAccounts';
import Role from 'crono-fe-common/types/enums/role';
import useAccountLinkedin from 'hooks/services/account/useAccountLinkedin';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import ExternalPropertyNotTag from 'pages/accountTab/externalPropertyNotTag';
import { IExternalValueInsert } from 'pages/searchComponent/searchCompanyTab/insertCompany/model';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useLinkedinGetCompanyInfo } from 'crono-fe-common/hooks/crono-extension/gateway';
import { Constants } from 'crono-fe-common/constants/constants';
import FindPhonePreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findPhonePreconditionButton';
import VerificationStatusType, {
  EmailScrapeResult,
} from 'crono-fe-common/types/enums/verificationStatusType';
import useFindVerify from 'hooks/services/scrape/useFindVerify';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import { Prospect } from 'crono-fe-common/types/prospect';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import usePatchProspectAccount from 'hooks/services/prospect/usePatchProspectAccount';
import { UpdateProspectAccount } from 'crono-fe-common/types/DTO/updateProspectAccount';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import useGetProspect from 'hooks/services/prospect/useGetProspect';
import { UpdateProspect } from 'crono-fe-common/types/DTO/updateProspect';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import MobilePhoneIcon from 'crono-fe-common/icons/Icon-Mobile-Phone';

interface IProps {
  selectedContact: Prospect;
  close: () => void;
  newCompanyName: string | null;
  newCompanyPublicId: string | null;
  newRole: string | null;
  onSend: () => void;
}

interface IInformation {
  name: keyof ProspectUpdateInputs;
  label?: string;
  icon: JSX.Element;
}

const contactInformation: IInformation[] = [
  {
    name: 'phone',
    label: 'Phone',
    icon: <CallIcon className="info-icon" />,
  },
  {
    name: 'mobilePhone',
    label: 'Mobile Phone',
    icon: <MobilePhoneIcon className="info-icon" />,
  },
  {
    name: 'email',
    label: 'Email',
    icon: <EmailIcon className="info-icon" />,
  },
  {
    name: 'linkedin',
    label: 'LinkedIn',
    icon: <LinkedinIcon className="info-icon" />,
  },
];

const prospectInformation: IInformation[] = [
  {
    name: 'firstName',
    label: 'First Name',
    icon: <ToolsIcon className="info-icon" />,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    icon: <ToolsIcon className="info-icon" />,
  },
  {
    name: 'title',
    label: 'Title',
    icon: <ToolsIcon className="info-icon" />,
  },
];

const UpdateContact = ({
  selectedContact,
  close,
  newCompanyName,
  newCompanyPublicId,
  newRole,
  onSend,
}: IProps) => {
  const { data: companiesFound } = useAccountLinkedin({
    name: newCompanyName ?? '',
    publicId: newCompanyPublicId ?? '',
    searchInCrm: true,
    enabled: true,
  });
  const { highestRole } = useAuth();
  const analytics = useJuneAnalytics();

  const { data: prospect } = useGetProspect(
    selectedContact.objectId,
    true,
    false,
    false,
  );

  //To know if we need to call the updateProspect API or not. If a newRole is passed than this starts as true, since we will have to update the prospect anyway
  const [hasProspectChanged, setHasProspectChanged] = useState(
    newRole !== null,
  );

  const handleChangeProspect = () => {
    setHasProspectChanged(true);
  };

  const { call: getCompanyInfo, result: createNewCompanyInfo } =
    useLinkedinGetCompanyInfo();

  useEffect(() => {
    if (!newCompanyPublicId) return;
    getCompanyInfo({
      publicId: newCompanyPublicId,
    });
  }, []);

  const {
    mutateAsync: patchProspectAccount,
    isSuccess: isSuccessPatchProspectAccount,
    error: errorPatchProspectAccount,
  } = usePatchProspectAccount();

  const {
    mutateAsync: editProspect,
    isSuccess: isSuccessEditProspect,
    error: errorEditProspect,
  } = useEditProspect();

  const [isLoadingCalls, setIsLoadingCalls] = useState(false);

  const formik = useFormik<ProspectUpdateInputs>({
    enableReinitialize: true,
    initialValues:
      {
        firstName: selectedContact.firstName ?? '',
        lastName: selectedContact.lastName ?? '',
        linkedin: selectedContact.linkedin ?? null,
        title: newRole ?? selectedContact.title ?? null,
        company: newCompanyName ?? '',
        email: selectedContact.email ?? '',
        phone: selectedContact.phone ?? '',
        mobilePhone: selectedContact.mobilePhone ?? '',
      } || initialValues,
    validationSchema: validationSchemaUpdate,
    onSubmit: async (values) => {
      setIsLoadingCalls(true);
      const patchAccountObject: UpdateProspectAccount = {
        prospectId: selectedContact.objectId,
      };
      if (companyValue === -1) {
        patchAccountObject.accountId = selectedAccount?.objectId ?? null;
      } else if (companyValue === -2) {
        patchAccountObject.accountInsert = {
          ...createNewCompanyInfo?.company,
          name: createNewCompanyInfo?.company?.name ?? '',
          numberOfEmployees:
            createNewCompanyInfo?.company?.numberOfEmployees ?? null,
          website: createNewCompanyInfo?.company?.website ?? null,
          industry: createNewCompanyInfo?.company?.industry ?? null,
          phone: createNewCompanyInfo?.company?.phone ?? null,
          country: createNewCompanyInfo?.company?.country ?? null,
          linkedin: createNewCompanyInfo?.company?.url ?? null,
        };
      } else {
        const onlyInCrm = pickedSuggestedAccount?.onlyInCrm ?? false;
        if (onlyInCrm) {
          patchAccountObject.accountOnlyInCrm = true;
        }
        patchAccountObject.accountId = formik.values.accountId;
      }

      if (analytics) {
        analytics.track('patch-account-prospect', {
          prospectId: selectedContact.objectId,
          accountId: patchAccountObject.accountId,
          accountInsert: patchAccountObject.accountInsert,
        });
      }
      const resultPatchProspectAccount =
        await patchProspectAccount(patchAccountObject);
      if (
        !resultPatchProspectAccount.data?.isSuccess &&
        !(resultPatchProspectAccount.status === 200)
      ) {
        setIsLoadingCalls(false);
        return;
      }

      // Update the prospect if something has changed on the prospect
      if (hasProspectChanged) {
        const trimmed = trimObject(values);

        const externalValuesToAdd: {
          [key: number]: string | null;
        } = {};
        externalValuesTag.forEach((value, index) => {
          if (
            externalPropertiesTag.length > index &&
            externalPropertiesTag[index].id
          ) {
            externalValuesToAdd[externalPropertiesTag[index].id] = value;
          }
        });
        externalValuesNotTag.forEach((value) => {
          externalValuesToAdd[value.externalPropertyId] = value.value;
        });

        const insertObject: UpdateProspect = {
          ...trimmed,
          externalValues: externalValuesToAdd,
        };
        const _res = await editProspect({
          ...insertObject,
          prospectId: selectedContact.objectId,
        });
        if (analytics) {
          analytics.track('patch-prospect', {
            prospectId: selectedContact.objectId,
            accountId: patchAccountObject.accountId,
            accountInsert: patchAccountObject.accountInsert,
          });
        }
      }
      setIsLoadingCalls(false);
      onSend();
      close();
    },
  });

  //I do not pass Prospect because I have to look for both lead and prospect, so I will filter them on the memos
  const { data: externalProperties } = useGetExternalProperty(null, null, true);

  const externalPropertiesTag: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter(
        (property) =>
          property.isTag &&
          property.tableType !== 'Account' &&
          property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  const externalPropertyNotTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter(
        (property) =>
          !property.isTag &&
          property.tableType !== 'Account' &&
          property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  const { mutateAsync: verifyEmail } = useFindVerify();

  const handleChangeInformation = async (event: any) => {
    const { name, value } = event.target;
    let scrapeResult = null;
    if (name === 'email') {
      try {
        const { data } = await verifyEmail({
          email: value,
        });
        if (data?.isSuccess && data?.data.status) {
          scrapeResult = data.data.status ?? VerificationStatusType.UNKNOWN;
        } else {
          scrapeResult = VerificationStatusType.UNKNOWN;
        }
      } catch (_) {
        scrapeResult = VerificationStatusType.UNKNOWN;
      }
      formik.setFieldValue('emailStatus', scrapeResult);
    }
    formik.setFieldValue(name, value);
    handleChangeProspect();
  };

  const [externalValuesTag, setExternalValuesTag] = useState<(string | null)[]>(
    new Array(Constants.numberOfTags).fill(null),
  );

  useEffect(() => {
    if (!externalPropertiesTag || !prospect?.data?.data) return;
    if (prospect.data.data.externalValues) {
      const newExternalValues = [...externalValuesTag];
      prospect.data.data.externalValues.forEach((value) => {
        const index = externalPropertiesTag.findIndex(
          (property) => property.id === value.externalPropertyId,
        );
        if (index >= 0) {
          newExternalValues[index] = value.value;
        }
      });
      setExternalValuesTag(newExternalValues);
    }
  }, [externalPropertiesTag, prospect]);

  useEffect(() => {
    if (!externalPropertyNotTags || !prospect?.data?.data) return;
    if (prospect.data.data.externalValues) {
      const newExternalValues = [...externalValuesNotTag];
      prospect.data.data.externalValues.forEach((value) => {
        const index = externalPropertyNotTags.findIndex(
          (property) => property.id === value.externalPropertyId,
        );
        if (index >= 0 && value.value) {
          newExternalValues.push({
            externalPropertyId: value.externalPropertyId,
            value: value.value,
            isTag: false,
          });
        }
      });
      setExternalValuesNotTag(newExternalValues);
    }
  }, [externalPropertyNotTags, prospect]);

  const [mailScraped, setMailScraped] = useState<EmailScrapeResult | null>(
    null,
  );
  useEffect(() => {
    if (mailScraped) {
      formik.setFieldValue('email', mailScraped.email);
      formik.setFieldValue('emailStatus', mailScraped.verificationStatus);
    }
  }, [mailScraped]);

  const [phoneScraped, setPhoneScraped] = useState<string | null>(null);
  useEffect(() => {
    if (phoneScraped) {
      formik.setFieldValue('phone', phoneScraped);
    }
  }, [phoneScraped]);

  //Company selection
  //-3 is a non correct value, so that the following useEffect will update it and fire as expected
  const [companyValue, setCompanyValue] = useState<number>(-2);

  //CompanySelect
  const [searchCompanyText, setSearchCompanyText] = useState<string>('');
  const { data: accounts } = useAccounts({
    name: searchCompanyText,
    limit: 25,
    status: null,
    IsManager: highestRole === Role.MANAGER,
  });

  const mappedAccount = useMemo(() => {
    if (!accounts || !accounts.data) return [];
    return accounts.data?.data
      .map((acc) => {
        return acc;
      })
      .filter((acc) => {
        //I filter out the ones that I am already showing since they are similar
        return (
          companiesFound?.data?.data.findIndex(
            (company) => company.objectId === acc.objectId,
          ) === -1
        );
      });
  }, [accounts]);

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  //Here I have the acconut to insert, with the accountId
  const selectedAccount = useMemo(() => {
    const acc =
      mappedAccount.find((el) => el.objectId === selectedCompany) ??
      companiesFound?.data?.data.find((el) => el.objectId === selectedCompany);
    return acc;
  }, [selectedCompany, mappedAccount, companiesFound]);

  useEffect(() => {
    if (companyValue === -1) {
      if (selectedAccount) {
        formik.setFieldValue('company', selectedAccount.name);
        formik.setFieldValue('accountId', selectedAccount.objectId);
      } else {
        formik.setFieldValue('company', null);
        formik.setFieldValue('accountId', null);
      }
    }
  }, [companyValue, selectedAccount]);

  //Account selected between the ones suggested by the system
  const [pickedSuggestedAccount, setPickedSuggestedAccount] =
    useState<Account | null>(null);

  //When the action to perform regarding the company is changed
  useEffect(() => {
    if (companyValue === -1) {
      //Select existing

      formik.setFieldValue('company', null);
      formik.setFieldValue('accountId', null);
      //Reset the company selected before for a better experience
      setSelectedCompany(null);
    } else if (companyValue === -2) {
      //Create new
      formik.setFieldValue('company', newCompanyName);
      formik.setFieldValue('accountId', null);
    } else {
      //Selected a company from the list
      if (!companiesFound || !companiesFound?.data?.data) return;
      const acc = companiesFound.data?.data[companyValue];
      if (!acc) return;
      setPickedSuggestedAccount({
        objectId: acc?.objectId,
        name: acc?.name,
        website: acc?.website,
        industry: acc?.industry,
        numberOfEmployees: acc?.numberOfEmployees,
        linkedin: acc?.linkedin,
        linkedinNumericId: acc?.linkedinNumericId,
        externalValues: null,
        actualStatus: acc?.actualStatus ?? AccountStatus.WORKING,
        currentSolution: '',
        owned: acc.owner,
        id: -1,
        ownerId: '',
        user: '',
        userId: -1,
        prospects: null,
        isSynced: acc.isSynced,
      });
      formik.setFieldValue('company', acc?.name);
      formik.setFieldValue('accountId', acc?.objectId);
    }
  }, [companyValue]);

  const [externalValuesNotTag, setExternalValuesNotTag] = useState<
    IExternalValueInsert[]
  >([]);

  const handleSetExternalValueNotTag = (
    externalPropertyId: number,
    value: string,
  ) => {
    const newValues = [...externalValuesNotTag];
    const newExternalValue = {
      externalPropertyId,
      value: value,
      isTag: false,
    };
    const oldValue = newValues.filter(
      (val) => val.externalPropertyId === externalPropertyId,
    );
    if (oldValue.length > 0) {
      newValues.splice(newValues.indexOf(oldValue[0]), 1);

      newValues.push(newExternalValue);

      setExternalValuesNotTag(newValues);
    } else {
      newValues.push(newExternalValue);
      setExternalValuesNotTag(newValues);
    }
    handleChangeProspect();
  };

  useConditionalSnackBar([
    {
      condition: !!isSuccessPatchProspectAccount,
      message: 'Contact correctly moved to the new company!',
      severity: 'success',
    },
    {
      condition: !!isSuccessEditProspect,
      message: 'Contact correctly updated!',
      severity: 'success',
    },
    {
      condition: !!errorPatchProspectAccount,
      message:
        getError(errorPatchProspectAccount) ??
        'Error while moving the contact to the new company',
      severity: 'error',
    },
    {
      condition: !!errorEditProspect,
      message:
        getError(errorEditProspect) ?? 'Error while updating the contact',
      severity: 'error',
    },
  ]);

  const correctCompany = useMemo(() => {
    if (companiesFound?.data?.data) {
      return companiesFound.data.data.find((company) => company.correct);
    }
  }, [companiesFound]);

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const finalOption = getNewOptionsWhenSelectingWithStrings({
      currentValue: externalValuesTag[index],
      option,
      valueType: externalProperty.valueType,
    });
    const newExternalValues = [...externalValuesTag];
    newExternalValues[index] = finalOption;
    setExternalValuesTag(newExternalValues);
  };

  return (
    <InsertCompanyWrapper>
      <div
        className="background"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          close();
        }}
      />
      <div
        className="update-form-container"
        // onClick={(ev) => {
        //   ev.preventDefault();
        //   ev.stopPropagation();
        // }}
      >
        <CloseTabButton close={close} />

        <form onSubmit={formik.handleSubmit} className="insert-form">
          {/* {user?.company?.integrationType === IntegrationType.SALESFORCE && (
            <div className="prospect-info-row">
              <p className="add-new-label">Create in CRM</p>
              <div className="switch-container">
                <TopTierSwitch
                  name="createInCrm"
                  id="createInCrm"
                  checked={createInCrm}
                  onClick={(e) => handleChangeCreateInCrm()}
                />
              </div>
            </div>
          )} */}
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Update Contact:
          </Typography>
          {prospect?.data?.data && (
            <TagsBoxShowAll
              currentSituation={externalValuesTag}
              handleClickTag={handleClickTag}
              type={prospect.data.data.fromContact ? 'Prospect' : 'Lead'}
              onlyInsert={true}
              blockIsEditable={true}
            />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              {prospectInformation.map((field, index) => {
                const link = formik.values[field.name] || '';
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className="information-editable-container"
                  >
                    <Typography
                      className="information-label"
                      fontSize={14}
                      lineHeight={'24px'}
                    >
                      {field.label}:
                    </Typography>
                    <div className="editable-div">
                      <TextField
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        name={field.name}
                        autoComplete="off"
                        fullWidth
                        value={link || ''}
                        placeholder="Add information..."
                        onChange={(ev) => {
                          formik.setFieldValue(field.name, ev.target.value);
                          handleChangeProspect();
                        }}
                        error={
                          formik.touched[field.name] &&
                          Boolean(formik.errors[field.name])
                        }
                        helperText={
                          formik.touched[field.name] &&
                          formik.errors[field.name]
                        }
                      ></TextField>
                    </div>
                    {/* <span>
                      <EditIcon />
                    </span> */}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="divider" />

          <div className="additional-information-container">
            {contactInformation.map((field, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  }}
                  className="editable-div"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    {field.icon}
                    <TextField
                      name={field.name}
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      fullWidth
                      placeholder="Insert a new information..."
                      onChange={(ev) => {
                        formik.setFieldValue(field.name, ev.target.value);
                        handleChangeProspect();
                      }}
                      value={formik.values[field.name]}
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChangeInformation(ev);
                        }
                      }}
                      onBlur={(ev: any) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChangeInformation(ev);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 4,
                    }}
                  >
                    <span>
                      <EditIcon />
                    </span>
                    {field.name === 'email' &&
                      (formik.values['email'] === null ||
                        formik.values['email'] === '') && (
                        <FindEmailPreconditionButton
                          account={
                            companyValue === -1
                              ? selectedAccount
                              : companyValue >= 0
                                ? pickedSuggestedAccount
                                : null
                          }
                          setEmail={setMailScraped}
                          prospect={selectedContact}
                          hasLabel={true}
                          iconColor={colors.white}
                        />
                      )}
                    {field.name === 'phone' &&
                      (formik.values['phone'] === null ||
                        formik.values['phone'] === '') && (
                        <FindPhonePreconditionButton
                          account={
                            companyValue === -1
                              ? selectedAccount
                              : companyValue >= 0
                                ? pickedSuggestedAccount
                                : null
                          }
                          setPhone={setPhoneScraped}
                          prospectInputs={formik.values}
                          hasLabel={true}
                          iconColor={colors.white}
                        />
                      )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="additional-information-container">
            {externalPropertyNotTags.map((property, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="information-editable-container"
                  key={index}
                >
                  <Typography
                    className="information-label"
                    fontSize={12}
                    lineHeight={'16px'}
                  >
                    {property.publicName}:
                  </Typography>
                  <div
                    className={
                      property.valueType === 'Numeric' ||
                      property.valueType === 'String'
                        ? 'editable-div'
                        : ''
                    }
                  >
                    <ExternalPropertyNotTag
                      externalProperty={property}
                      type="Prospect"
                      owned={true}
                      externalValues={externalValuesNotTag}
                      handleSetValue={handleSetExternalValueNotTag}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="additional-information-container">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {correctCompany ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    className="information-label"
                    fontSize={12}
                    lineHeight={'16px'}
                  >
                    New company:
                  </Typography>{' '}
                  <Typography noWrap>{correctCompany.name}</Typography>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    className="information-label"
                    fontSize={12}
                    lineHeight={'16px'}
                  >
                    Company
                  </Typography>

                  <CronoSelect
                    MenuProps={{ style: { zIndex: 35001 } }}
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${colors.grey3}`,
                        borderRadius: 2,
                      },
                    }}
                    fullWidth
                    value={companyValue}
                    renderValue={(value: any) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value === -1
                              ? 'Select existing'
                              : value === -2
                                ? newCompanyName
                                : companiesFound?.data?.data[value]?.name}
                          </Typography>
                          {value === -2 && (
                            <div className="new-company-badge">New company</div>
                          )}
                        </div>
                      );
                    }}
                    size={'small'}
                  >
                    <CronoMenuItem
                      value={-1}
                      onClick={() => {
                        setCompanyValue(-1);
                      }}
                    >
                      Select existing
                    </CronoMenuItem>
                    <CronoMenuItem
                      value={-2}
                      onClick={() => {
                        setCompanyValue(-2);
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {newCompanyName}
                      <div
                        style={{
                          height: 20,
                          padding: '2px 6px',
                          background: colors.linkedinRepliedMessageLightBlue,
                          borderRadius: 12,
                          fontWeight: 500,
                          fontSize: 12,
                          lineHeight: '16px',
                          color: colors.intenseBlue,
                        }}
                      >
                        New company
                      </div>
                    </CronoMenuItem>
                  </CronoSelect>
                </div>
              )}
              {companyValue === -1 && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    marginTop: 7,
                  }}
                >
                  <Typography
                    className="information-label"
                    fontSize={12}
                    lineHeight={'16px'}
                  >
                    Select existing
                  </Typography>
                  <CronoAutocomplete
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    onChange={(e, value) => {
                      if (value) setSelectedCompany(value.id);
                      else setSelectedCompany(null);
                    }}
                    options={[
                      ...(companiesFound?.data?.data.map((account, index) => {
                        return {
                          id: account.objectId,
                          name: account.name,
                        };
                      }) ?? []),
                      ...mappedAccount.map((acc) => {
                        return { id: acc.objectId, name: acc.name };
                      }),
                    ]}
                    getOptionLabel={(option) => option.name || ''}
                    disablePortal={false}
                    setSearchText={(text) => setSearchCompanyText(text)}
                    textFieldParams={{
                      placeholder: 'Type company name...',
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        padding: '0',
                      },
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                        {
                          border: `1px solid ${colors.grey3}`,
                        },
                    }}
                  />
                </div>
              )}
            </div>
            {/* )} */}
          </div>
          {!correctCompany && !!companiesFound?.data?.data.length && (
            <div className="similar-companies-found-box">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  We found {companiesFound.data.data.length} similar{' '}
                  {companiesFound.data.data.length === 1
                    ? 'company'
                    : 'companies'}{' '}
                  in Crono
                </div>
                <Typography
                  style={{
                    height: '16px',
                    textTransform: 'none',
                    fontSize: 12,
                    cursor: 'pointer',
                    color: colors.primaryDark,
                    lineHeight: '16px',
                  }}
                  onClick={() => {
                    setCompanyValue(-1);
                  }}
                >
                  Show
                </Typography>
              </div>
            </div>
          )}
          <Typography
            color={colors.primary}
            fontSize={12}
            fontWeight={500}
            lineHeight={'16px'}
          >
            This contact will be removed from the previous company and attached
            to the new one
          </Typography>

          {isLoadingCalls ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <FlexDiv width="fit-content" style={{ alignSelf: 'flex-end' }}>
              <CancelButton onClick={() => close()}>Cancel</CancelButton>
              <MainButton
                onClick={() => {
                  formik.handleSubmit();
                }}
                disabled={
                  formik.values.company === null || formik.values.company === ''
                }
                style={{
                  background: !formik.values.company ? colors.grey2 : undefined,
                }}
              >
                Update
              </MainButton>
            </FlexDiv>
          )}
        </form>
      </div>
    </InsertCompanyWrapper>
  );
};

export default UpdateContact;
