import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { colors } from 'crono-fe-common/theme';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '3px',
  borderRadius: 5,
  [`& .${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.primaryLightHover,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colors.darkGreen,
  },
}));

interface IProps {
  value: number;
  linearBarSx?: any;
}

export default function LinearDeterminate({ value, linearBarSx }: IProps) {
  return (
    <BorderLinearProgress
      variant="determinate"
      value={value}
      sx={linearBarSx}
    />
  );
}
