import { Button, Typography } from '@mui/material';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import React, { useState } from 'react';
import { useTasksFilters } from './taskFIlterContext';
import { useAuth } from 'context/auth';
import { RemoveButton } from 'crono-fe-common/components/CronoButton';
import { DeleteOutline } from '@mui/icons-material';
import { TaskFilterModalWrapper } from './style';
import { TaskSortType } from 'crono-fe-common/types/enums/taskSortType';
import { colors } from 'crono-fe-common/theme';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import StrategyAutocomplete from 'components/StrategyAutocomplete';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';

interface IProps {
  close: () => void;
}

interface LocalTaskFilters {
  sortBy: TaskSortType;
  automatic?: boolean | null;
  hasAutomationError: boolean | null;
  strategyId: number | undefined;
  fromCrm: boolean;
}

const TaskFilterModal = ({ close }: IProps) => {
  const { user } = useAuth();
  const { searchTasksFilters, setSearchTasksFilters, resetTasksFilters } =
    useTasksFilters();
  const [localTasksFilters, setLocalTasksFilters] = useState<LocalTaskFilters>({
    ...searchTasksFilters,
    automatic: searchTasksFilters.hasAutomationError
      ? undefined
      : searchTasksFilters.automatic,
    strategyId: searchTasksFilters.strategyId ?? -1,
  } as LocalTaskFilters);

  const handleClear = () => {
    resetTasksFilters();
    close();
  };

  const handleApply = () => {
    setSearchTasksFilters((prev) => ({
      ...prev,
      ...localTasksFilters,
      strategyId:
        localTasksFilters.strategyId === -1
          ? undefined
          : localTasksFilters.strategyId,
    }));
    close();
  };

  const handleClose = () => {
    setLocalTasksFilters(searchTasksFilters as LocalTaskFilters);
    close();
  };

  const onStrategyAutocompleteChange = (strategyId: number) => {
    setLocalTasksFilters((prev) => ({
      ...prev,
      strategyId: strategyId !== 0 ? strategyId : -1,
    }));
  };

  return (
    <TaskFilterModalWrapper>
      <div className="background" onClick={handleClose} />

      <div className="filters-container">
        <div className="filter-body">
          <CloseTabButton close={handleClose} className="closeButton" />
          <Typography variant="h5" fontWeight={700}>
            Advanced filters:
          </Typography>

          <div className="options-container">
            <Typography fontWeight={600} lineHeight={'22px'} fontSize={14}>
              Sort by:
            </Typography>
            <div className="radio-options-container">
              {(['CreatedDate', 'ActivityDate', 'Step'] as TaskSortType[]).map(
                (value, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        'radio-option ' +
                        (localTasksFilters.sortBy === value
                          ? 'radio-option-selected'
                          : undefined)
                      }
                      onClick={() => {
                        setLocalTasksFilters((prev) => ({
                          ...prev,
                          sortBy: value,
                        }));
                      }}
                    >
                      {/*value.replace add spaces for visual label (ex. CreatedDate -> Created Date )*/}
                      {value === 'Step'
                        ? 'Strategy Step'
                        : value.replace(/([a-z])([A-Z])/g, '$1 $2')}
                    </div>
                  );
                },
              )}
            </div>
          </div>

          <div className="options-container">
            <Typography fontWeight={600} lineHeight={'22px'} fontSize={14}>
              Filter by:
            </Typography>
            <div className="radio-options-container">
              {[null, true, false].map((value, index) => {
                return (
                  <div
                    key={index}
                    className={
                      'radio-option ' +
                      (localTasksFilters.automatic === value
                        ? 'radio-option-selected'
                        : undefined)
                    }
                    onClick={() => {
                      setLocalTasksFilters((prev) => ({
                        ...prev,
                        automatic: value,
                        hasAutomationError: value === null ? null : false,
                      }));
                    }}
                  >
                    {value == null
                      ? 'All Tasks'
                      : value
                        ? 'Automatic'
                        : 'Manual'}
                  </div>
                );
              })}
              <div
                key={-1}
                className={
                  'radio-option ' +
                  (localTasksFilters.hasAutomationError === true
                    ? 'radio-option-selected'
                    : undefined)
                }
                onClick={() => {
                  setLocalTasksFilters((prev) => ({
                    ...prev,
                    automatic: undefined,
                    hasAutomationError: true,
                  }));
                }}
              >
                Tasks in Error
              </div>
            </div>
          </div>

          <div className="options-container">
            <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
              Strategy name
            </Typography>
            <StrategyAutocomplete
              defaultValue={localTasksFilters.strategyId}
              selectStrategy={onStrategyAutocompleteChange}
            />
          </div>
          {user?.integrationType !== IntegrationType.CRONO && (
            <div className="crm-switch-block">
              <div className="switch-typography-container">
                <Typography fontWeight={500} lineHeight={'16px'} fontSize={12}>
                  Remove CRM tasks
                </Typography>
                <Typography
                  fontWeight={400}
                  lineHeight={'16px'}
                  fontSize={12}
                  color={colors.grey11}
                >
                  Tasks created in the CRM will be hidden from the feed.
                </Typography>
              </div>

              <CronoSwitch
                checked={!!localTasksFilters.fromCrm}
                onChange={(ev) => {
                  setLocalTasksFilters((prev) => ({
                    ...prev,
                    fromCrm: ev.target.checked,
                  }));
                }}
              />
            </div>
          )}

          <div className="filter-footer">
            <RemoveButton
              variant="contained"
              className="button-filter"
              startIcon={<DeleteOutline />}
              onClick={() => handleClear()}
              disableElevation
            >
              Clear
            </RemoveButton>
            <Button
              color="secondary"
              variant="contained"
              className="button-filter"
              onClick={() => handleApply()}
              disableElevation
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </TaskFilterModalWrapper>
  );
};

export default TaskFilterModal;
