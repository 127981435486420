import {
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import IconSClose from 'crono-fe-common/icons/Icon-S-Close';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { colors } from 'crono-fe-common/theme';
import React, { useMemo } from 'react';
import { SuggestionPreferences } from 'crono-fe-common/types/suggestionPreferences';
import { useGetStatusesOptions } from 'utils/fe-utils';
import {
  DefaultCompanyStatus,
  DefaultCompanyStatusOptions,
  DefaultContactStatus,
  DefaultContactStatusOptions,
  StatusPropertyType,
} from 'crono-fe-common/types/model';

interface IProps {
  property: ISuggestionPreferencesComponent;
  parameters: SuggestionPreferences | null;
  entityNotSelected: boolean;
  enabled?: boolean | null;
  updateMultipleSuggestionPreferences: (
    properties: {
      name: keyof SuggestionPreferences;
      value: any;
    }[],
  ) => void;
  isLoading?: boolean;
}

export interface ISuggestionPreferencesComponent {
  entity:
    | 'emailRepliedEntity'
    | 'linkedinRepliedEntity'
    | 'followUpMeetingEntity'
    | 'startSequenceForNewProspectsEntity'
    | 'startSequenceForOldProspectsEntity';
  accountStatus:
    | 'emailRepliedAccountStatus'
    | 'linkedinRepliedAccountStatus'
    | 'followUpMeetingAccountStatus'
    | 'startSequenceForNewProspectsAccountStatus'
    | 'startSequenceForOldProspectsAccountStatus';
  prospectStatus:
    | 'emailRepliedProspectStatusNew'
    | 'linkedinRepliedProspectStatusNew'
    | 'followUpMeetingProspectStatusNew'
    | 'startSequenceForNewProspectsProspectStatusNew'
    | 'startSequenceForOldProspectsStatusNew';
  customStatus:
    | 'emailRepliedCustomStatusValues'
    | 'linkedinRepliedCustomStatusValues'
    | 'followUpMeetingCustomStatusValues'
    | 'startSequenceForNewProspectsCustomStatusValues'
    | 'startSequenceForOldProspectsCustomStatusValues';
  customStatusId:
    | 'emailRepliedCustomStatusId'
    | 'linkedinRepliedCustomStatusId'
    | 'followUpMeetingCustomStatusId'
    | 'startSequenceForNewProspectsCustomStatusId'
    | 'startSequenceForOldProspectsCustomStatusId';
}

const SuggestionMultiselectForStatus = ({
  property: task,
  parameters,
  entityNotSelected,
  enabled,
  updateMultipleSuggestionPreferences,
  isLoading = false,
}: IProps) => {
  const { isExternalPropertyStatus, statuses } = useGetStatusesOptions(
    parameters?.[task.entity] === 'Account' ? 'account' : 'prospect',
  );

  const statusToUse = isExternalPropertyStatus
    ? task.customStatus
    : parameters?.[task.entity] === 'Account'
      ? task.accountStatus
      : task.prospectStatus;
  const valuesStatus:
    | DefaultCompanyStatusOptions[]
    | DefaultContactStatusOptions[]
    | string[] = parameters?.[task.entity] ? parameters[statusToUse] || [] : [];

  const statusesToDisplay = useMemo(() => {
    if (isExternalPropertyStatus) {
      return statuses;
    }
    return statusToUse === task.accountStatus
      ? DefaultCompanyStatus
      : DefaultContactStatus;
  }, [statusToUse, statuses, isExternalPropertyStatus]);

  return (
    <div style={{ position: 'relative' }}>
      <Select
        value={valuesStatus}
        displayEmpty
        renderValue={(values) => {
          if (!values || entityNotSelected)
            return <Typography fontSize={'14px'}>Not selected</Typography>;
          return (
            <Typography
              fontSize={'14px'}
              style={{
                color:
                  !enabled && entityNotSelected ? colors.grey2 : colors.black,
              }}
            >
              {values?.length > 0
                ? `${values.length} selected`
                : 'Not selected'}
            </Typography>
          );
        }}
        inputProps={{
          placeholder: 'Select status',
        }}
        fullWidth
        size="small"
        multiple
        disabled={entityNotSelected || !enabled}
        onChange={(ev) => {
          updateMultipleSuggestionPreferences([
            {
              name: statusToUse,
              value: ev.target.value,
            },
          ]);
        }}
        MenuProps={{
          style: { zIndex: 35001 },
          PaperProps: {
            sx: {
              borderRadius: '8px',
              paddingInline: '6px',
              border: '1px solid #dadde9',
              boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
              maxHeight: 240,
              overflow: 'auto',
              '& .MuiMenuItem-root': {
                fontSize: '12px',
                lineHeight: '16px',
                height: 'fit-content',
                padding: '8px',
                cursor: 'pointer',
                width: '100%',
                borderRadius: '8px',
                '&:hover': {
                  background: colors.primaryLight,
                  color: colors.primary,
                },
              },
              '& .Mui-selected': {
                // color: colors.primary,
                backgroundColor: 'transparent !important',
              },
            },
          },
        }}
        className="select-suggestions-preferences"
        sx={{
          boxShadow: 'none',
          width: '150px',
          '.MuiOutlinedInput-input': {
            padding: '6px 8px !important',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.grey444}`,
            borderRadius: '8px',
          },
          '.MuiOutlinedInput-notchedOutline:hover': {
            border: `1px solid ${colors.grey444}`,
          },
          '.MuiInputBase-input': {
            paddingBlock: '6px',
          },
          '&:hover': {
            background: colors.grey6,
          },
        }}
      >
        {isLoading ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : (
          statusesToDisplay.map((status: StatusPropertyType, index) => (
            <MenuItem key={index} value={status.option}>
              <Checkbox
                icon={<IconUnchecked />}
                checkedIcon={<IconChecked color={colors.mainDark} />}
                checked={
                  valuesStatus.findIndex(
                    (singleValueStatus) => singleValueStatus === status.option,
                  ) > -1 || false
                }
                sx={{ padding: '0px 8px 0px 0px !important' }}
              />
              {status.label}
            </MenuItem>
          ))
        )}
      </Select>
      {valuesStatus?.length > 0 &&
        parameters?.[task.entity] !== 'Off' &&
        enabled && (
          <IconSClose
            className="remove-selection"
            onClick={(ev: any) => {
              ev.preventDefault();
              ev.stopPropagation();
              updateMultipleSuggestionPreferences([
                {
                  name: statusToUse,
                  value: [],
                },
              ]);
            }}
          />
        )}
    </div>
  );
};

export default SuggestionMultiselectForStatus;
