import { useAuth } from 'context/auth';
import { FC, useEffect } from 'react';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { FeConstants } from 'constants/FeConstants';
import SalesforceLogo from 'assets/images/Icon_Salesforce.svg';
import HubspotLogo from 'assets/images/Icon_Hubspot.svg';
import PipedriveLogo from 'assets/images/Icon_Pipedrive.svg';
import useCRMIntegrationGuard from 'hooks/guards/useCRMIntegrationGuard';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import { User } from 'crono-fe-common/types/user';

const CrmIntegrations: FC = () => {
  const { user, onboardingState, refetchOnboardingIntegration } = useAuth();

  const {
    isSuccessSF,
    isErrorSF,
    isSuccessHS,
    isErrorHS,
    isSuccessPD,
    isErrorPD,
    isSuccess,
    isLoading: isLoadingCRM,
    refetch,
  } = useCRMIntegrationGuard();

  useEffect(() => {
    if (!isSuccess && !isLoadingCRM) {
      const timeout = setTimeout(() => {
        refetch();
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (
      isSuccess &&
      onboardingState?.CrmConnected === 'ToDo' &&
      refetchOnboardingIntegration
    ) {
      refetchOnboardingIntegration();
    }
  }, [onboardingState, isSuccess, isLoadingCRM, refetchOnboardingIntegration]);

  const salesforceLogin = () => {
    if (!isSuccessSF) {
      window.location.replace(FeConstants.SALESFORCE_LOGIN_URL);
    }
  };

  const hubspotLogin = () => {
    if (!isSuccessHS) {
      window.location.replace(FeConstants.HUBSPOT_LOGIN_URL);
    }
  };

  const pipedriveLogin = () => {
    if (!isSuccessPD) {
      window.location.replace(FeConstants.PIPEDRIVE_LOGIN_URL);
    }
  };

  const renderConnectCRM = (
    user: User,
    logo: string,
    loading: boolean | undefined,
    success: boolean | undefined,
    error: boolean,
    loginFn: () => void,
  ) => {
    const integrationType = user.company?.integrationType ?? '';
    return (
      <>
        <div className="provider-box">
          <div className="icon-logo-container">
            <img
              src={logo}
              alt={integrationType}
              className={`connection-image ${integrationType}`}
            />
          </div>
          <div className={`connection-label`}>
            <Typography
              fontSize={'16px'}
              fontWeight={600}
              lineHeight={'24px'}
              color={colors.black}
            >
              {integrationType}
            </Typography>
            <Typography
              fontSize={'14px'}
              fontWeight={500}
              lineHeight={'18px'}
              color={
                !loading && success ? colors.darkGreen : colors.inactiveHover
              }
            >
              {success && !loading && 'Connected'}
              {(error || loading) && 'Not Connected'}
            </Typography>
          </div>
          <div
            className={`icons-buttons-container ${
              !loading && success ? 'connected' : 'not-connected'
            }`}
          >
            {!loading && success ? (
              <div className="connection-status">
                <Typography fontSize={'14px'} fontWeight={500}>
                  Connected
                </Typography>
                <CheckMarkIcon color={colors.callScheduled} />
              </div>
            ) : (
              <div
                className="connection-status pointer"
                onClick={() => loginFn()}
              >
                <Typography fontSize={'14px'} fontWeight={500}>
                  Connect
                </Typography>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="crm-connect-card">
      <div className="connect-card">
        <div className="providers">
          {user?.company?.integrationType === IntegrationType.SALESFORCE &&
            renderConnectCRM(
              user,
              SalesforceLogo,
              isLoadingCRM,
              isSuccessSF,
              isErrorSF,
              salesforceLogin,
            )}
          {user?.company?.integrationType === IntegrationType.HUBSPOT &&
            renderConnectCRM(
              user,
              HubspotLogo,
              isLoadingCRM,
              isSuccessHS,
              isErrorHS,
              hubspotLogin,
            )}
          {user?.company?.integrationType === IntegrationType.PIPEDRIVE &&
            renderConnectCRM(
              user,
              PipedriveLogo,
              isLoadingCRM,
              isSuccessPD,
              isErrorPD,
              pipedriveLogin,
            )}
        </div>
      </div>
    </div>
  );
};

export default CrmIntegrations;
