import React, { useEffect, useMemo, useState } from 'react';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { ManualTasksResumeWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import { Avatar, Badge, Typography } from '@mui/material';
import ManualTaskComponent from './manualTaskComponent';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import useDeleteTask from 'hooks/services/task/useDeleteTask';
import usePatchTask from 'hooks/services/task/usePatchTask';
import { useJuneAnalytics } from 'context/june';
import { useConfirmModal } from 'context/confirmModal';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import {
  getImageFromUrl,
  stringAvatarAccount,
  stringAvatarProspect,
} from 'utils/fe-utils';
import { Template } from 'crono-fe-common/types/template';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import { useConditionalSnackBar } from 'context/snackbar';
import CronoStatus from 'components/CronoStatus';
import TemplatesTooltipCard from 'pages/accountTab/emailView/TemplatesTooltipCard';
import TagsBoxShowAll from 'components/TagsBoxShowAll';

interface IProps {
  task: TaskTodo;
  onClose: () => void;
  handleOpenDateCalendar: (task: TaskTodo) => void;
  handleShowManualTaskResume: (task: TaskTodo) => void;
}

const ManualTasksResume = ({
  task: taskOpened,
  onClose,
  handleOpenDateCalendar,
  handleShowManualTaskResume,
}: IProps) => {
  const { prospect, account } = taskOpened;
  const analytics = useJuneAnalytics();

  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  const { mutate: patchTask } = usePatchTask();

  const { mutate: deleteTask, isSuccess: taskDeleted } = useDeleteTask();

  const onAdd = (templateId: number) => {
    taskOpened.templateId = templateId;
    patchTask({
      id: taskOpened.id,
      templateId: templateId,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'template',
      });
    }
    setOpenTemplateModal(false);
  };
  const handleChangeStepType = (newType: {
    type: TaskTodoType;
    subtype: TaskTodoSubtype;
  }) => {
    if (newType.type === taskOpened.type) {
      if (
        newType.type !== TaskTodoType.LINKEDIN ||
        newType.subtype === taskOpened.subtype
      )
        return;
    }
    taskOpened.type = newType.type;
    taskOpened.subtype = newType.subtype;
    taskOpened.template = null;
    taskOpened.templateId = null;
    taskOpened.automatic = false;
    patchTask({
      id: taskOpened.id,
      type: newType.type,
      subtype: newType.subtype,
      automatic: false,
      templateId: -1,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'type',
      });
    }
  };
  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteTask = () => {
    openConfirmModal({
      title: 'Are you sure you want to delete this task?',
      text: 'The task will be deleted',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        deleteTask(taskOpened.id);
        if (analytics) {
          analytics.track('patch-task', {
            type: 'delete',
          });
        }
      },
      cancelFunction: () => null,
    });
  };

  const handleChangeDate = (date: Date) => {
    taskOpened.activityDate = date;
    patchTask({
      id: taskOpened.id,
      activityDate: date,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'activityDate',
      });
    }
  };

  const handleChangeAutomatic = (automatic: boolean) => {
    taskOpened.automatic = automatic;
    patchTask({
      id: taskOpened.id,
      automatic: automatic,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'automatic',
      });
    }
  };

  const handleChangeScheduleTime = (time: Date) => {
    taskOpened.activityDate = time;
    patchTask({
      id: taskOpened.id,
      activityDate: time,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'scheduleTime',
      });
    }
  };

  const handleAllChanges = (type: string, payload: any) => {
    switch (type) {
      case 'automatic':
        handleChangeAutomatic(payload);
        break;
      case 'type':
        handleChangeStepType(payload);
        break;
      case 'delete':
        handleDeleteTask();
        break;
      case 'date':
        handleChangeDate(payload);
        break;
      case 'scheduleTime':
        handleChangeScheduleTime(payload);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (taskDeleted) {
      onClose();
    }
  }, [taskDeleted]);

  useConditionalSnackBar([
    {
      condition: !!taskDeleted,
      message: 'Task deleted successfully',
      severity: 'success',
    },
  ]);

  const templateType = useMemo(() => {
    if (taskOpened && taskOpened.type) {
      return taskOpened.type === TaskTodoType.CALL
        ? TemplateType.SCRIPT
        : taskOpened.type === TaskTodoType.EMAIL
          ? TemplateType.EMAIL
          : taskOpened.type === TaskTodoType.IN_MAIL
            ? TemplateType.IN_MAIL
            : taskOpened.subtype === TaskTodoSubtype.LinkedinInvitation
              ? TemplateType.INVITATION
              : taskOpened.subtype === TaskTodoSubtype.LinkedinVoiceNote
                ? TemplateType.SCRIPT
                : TemplateType.LINKEDIN;
    } else {
      return TemplateType.LINKEDIN;
    }
  }, [taskOpened.type]);

  return (
    <ManualTasksResumeWrapper id="manual-sequence-instance-modal">
      {/* {openTemplateModal && (
        <StepTemplateModal
          onAdd={(template: Template) => {
            onAdd(template.id);
          }}
          close={() => setOpenTemplateModal(false)}
          initialValue={taskOpened.template}
          templateType={
            taskOpened.type === TaskTodoType.EMAIL
              ? TemplateType.EMAIL
              : taskOpened.type === TaskTodoType.IN_MAIL
                ? TemplateType.IN_MAIL
                : taskOpened.subtype === TaskTodoSubtype.LinkedinInvitation
                  ? TemplateType.INVITATION
                  : taskOpened.subtype === TaskTodoSubtype.LinkedinVoiceNote
                    ? TemplateType.SCRIPT
                    : TemplateType.LINKEDIN
          }
        />
      )} */}
      <div className="container">
        <CloseTabButton close={onClose} />
        <div className="manual-task-resume-container">
          <Typography fontSize={24} fontWeight={700}>
            Task:
          </Typography>
          <div className="prospect-info-container">
            <div className="row">
              <div className="left-side-prospect-info">
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      right: 18,
                      top: 32,
                    },
                  }}
                  badgeContent={
                    <Avatar
                      src={
                        account?.website &&
                        getImageFromUrl(account.website, null)
                      }
                      className={
                        account?.website
                          ? 'account-avatar white'
                          : 'account-avatar'
                      }
                      sx={{
                        '&.MuiAvatar-root': {
                          background: colors.primaryLight,
                          color: colors.primary,
                          size: '8px',
                          fontSize: '8px',
                          height: '28px',
                          width: '28px',
                        },
                      }}
                    >
                      {stringAvatarAccount(prospect?.account?.name || '')}
                    </Avatar>
                  }
                >
                  <Avatar
                    src={
                      prospect?.account?.website &&
                      getImageFromUrl(prospect.account.website, null)
                    }
                    sx={{
                      '&.MuiAvatar-root': {
                        background: colors.primaryLight,
                        color: colors.primary,
                        marginRight: '16px',
                        size: '16px',
                        fontSize: '16px',
                        height: '48px',
                        width: '48px',
                      },
                    }}
                  >
                    {prospect && stringAvatarProspect(prospect)}
                  </Avatar>
                </Badge>
                <div className="prospect-name-title-container">
                  <Typography fontSize={14} fontWeight={500} noWrap>
                    {prospect?.name}{' '}
                    <span style={{ color: colors.primary, fontWeight: 600 }}>
                      at {prospect?.account?.name ?? account?.name}
                    </span>
                  </Typography>

                  <Typography fontSize={14} color={colors.grey11} noWrap>
                    {prospect!.title}
                  </Typography>
                </div>
              </div>

              <div className="right-side-sequence-handle">
                <CronoStatus
                  currentActualStatus={prospect.actualStatus}
                  currentExternalValues={prospect?.externalValues}
                  type="prospect"
                  disabled={true}
                />
              </div>
            </div>
            <div className="row" style={{ marginLeft: '80px' }}>
              <div className="tags-container">
                <TagsBoxShowAll
                  type={prospect.fromContact ? 'Prospect' : 'Lead'}
                  currentSituationTags={
                    prospect?.externalValues
                      ?.filter((ev) => ev.isTag && ev.value !== null)
                      .map((ev) => ({
                        externalPropertyId: ev.externalPropertyId,
                        option: ev.value!,
                      })) ?? []
                  }
                  blockIsEditable={true}
                  disableEdit={true}
                  showRemovable={false}
                />
              </div>
            </div>
          </div>
          <div className="manual-task-list">
            <ManualTaskComponent
              task={taskOpened}
              index={0}
              handleChange={handleAllChanges}
              openTemplatePicker={() => {
                setOpenTemplateModal(true);
              }}
              openDateCalendar={handleOpenDateCalendar}
              openTemplateTooltip={handleShowManualTaskResume}
              opened={true}
              closeTemplateTooltip={() => setOpenTemplateModal(false)}
              showTemplateTab={openTemplateModal}
              templateComponent={
                <TemplatesTooltipCard
                  accountId={account?.objectId ?? null}
                  prospectId={prospect?.objectId}
                  selectedTemplateId={taskOpened.template?.id ?? null}
                  setSelectedTemplate={(template: Template) => {
                    onAdd(template.id);
                  }}
                  selectedType={templateType}
                  hideShared={false}
                  hideAll={false}
                  showAnalytics={true}
                  close={() => setOpenTemplateModal(false)}
                  account={account}
                  prospect={prospect}
                  visibility="visible"
                />
              }
            />
          </div>
        </div>
      </div>
    </ManualTasksResumeWrapper>
  );
};

export default ManualTasksResume;
