import SubscriptionPlanType from '../enums/subscriptionPlanType';

export interface PlanPrices {
  id: string | null;
  paymentCycle: string | null;
  currency: string | null;
  amountTotalCycle: number;
  monthlyTotalCycle: number;
  current: boolean;
}
export interface SubscriptionProductDTO {
  id: string | null;
  subscriptionType: SubscriptionPlanType;
  description: string | null;
  prices: PlanPrices[];
}

// Addons
export interface CreditsProductsPrices {
  id: string | null;
  paymentCycle: any | null;
  currency: string | null;
  amount: number;
}

export enum ProductCreditsTypes {
  EMAIL = 'Email',
  PHONE = 'Phone',
  TEMPLATE = 'Template',
}

export interface ProductCreditsDTO {
  id: string | null;
  type: ProductCreditsTypes;
  price: CreditsProductsPrices | null;
  credits: number;
  description: string | null;
}
