import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export default function useStartDialerCampaign() {
  const queryClient = useQueryClient();

  const startDialerCampaign = (ids: number[]) => {
    const request: Request = {
      url: ENDPOINTS.task.dialerCampaign,
      config: {
        method: 'post',
        data: { ids },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, number[]>(
    startDialerCampaign,
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENDPOINTS.task.search]);
        queryClient.invalidateQueries([ENDPOINTS.task.overdue]);
        queryClient.invalidateQueries([ENDPOINTS.task.next]);
        queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
        queryClient.invalidateQueries([ENDPOINTS.task.total]);
      },
    },
  );
}
