import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ContactsInStrategyListWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 27vw;
  height: 100vh;
  background: ${colors.white};
  border-right: 1px solid ${colors.grey444};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .contacts-in-strategy-list-header {
    display: flex;
    padding: 30px 24px;
    flex-direction: column;
    .remove-text-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
  .only-owned-contacts-banner {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    background: ${colors.grey66};
    gap: 8px;
    margin-top: 8px;
    margin-inline: -12px;
    .information-icon {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
  }
  .number-of-contacts-badge {
    height: 24px;
    padding: 4px 8px;
    background: ${colors.grey4};
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
  .contacts-in-strategy-list-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .sort-labels-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid ${colors.grey444};
    }
    .contacts-list {
      flex: 1;
      overflow: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      .contact-personalized-card {
        display: flex;
        justify-content: space-between;
        padding: 24px;
        border-bottom: 1px solid ${colors.grey444};
        cursor: pointer;
        gap: 8px;
        .prospect-avatar {
          width: 24px;
          height: 24px;
          background: ${colors.primaryLight} !important;
          color: ${colors.primary} !important;
          font-size: 12px;
        }
        .linkedin-link-container {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .linkedin-link-container:hover {
          background-color: ${colors.grey4};
        }
      }
      .selected-personalized {
        border-left: 4px solid ${colors.mainDark};
        background: ${colors.grey6};
        padding-left: 20px;
      }
      .placeholder-row-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid ${colors.grey444};
        padding-block: 24px;
      }
    }
  }
`;
