import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const AddStepModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 524px;
  height: 418px;
  padding: 48px;
  background-color: ${colors.white};
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .cross {
    position: absolute;
    top: 24px;
    right: 0;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;

    .card {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 210px;
      height: 84px;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid ${colors.grey33};
      transition: background-color 300ms;

      &:hover {
        background-color: ${colors.sequenceCardHover};
      }

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
