import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './styledComponents';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  OptionsForAccordionRange,
  ValuesToOnChange,
  ValuesToOnChangeAnnualRevenue,
} from '../searchCompanyTab/searchCompanyTabFilters';
import { AutocompleteMultipleOptionWrapper } from '../style';
import ClearIcon from '@mui/icons-material/Clear';
import { LinkedinFilterValue } from 'crono-fe-common/types/crono-extension/linkedin';

interface IProps {
  currentValue: any | any[] | null;
  options: LinkedinFilterValue[];
  currentMin: LinkedinFilterValue | null;
  currentMax: LinkedinFilterValue | null;
  unitOfMeasureLabel: string;
  optionsRangeMin: any[];
  optionsRangeMax: any[];

  onChange: ({
    newLabel,
    valueToChange,
  }: ValuesToOnChangeAnnualRevenue) => void;
  placeholder: string;
  id: string;
  displayLabel: string;
  icon: JSX.Element;
  disableTopBorder?: boolean;
  expanded?: boolean;
  setExpanded?: () => void;
}

const SearchAccordionRangesSelect = ({
  currentValue,
  options,
  currentMin,
  currentMax,
  optionsRangeMin,
  optionsRangeMax,
  unitOfMeasureLabel,
  onChange,
  placeholder,
  id,
  displayLabel,
  icon,
  disableTopBorder,
  expanded,
  setExpanded,
}: IProps) => {
  const [searchText, setSearchText] = useState('');

  const resume = useMemo(() => {
    if (!currentValue || !currentMin || !currentMax) return null;
    const label =
      currentMax.id === '1001'
        ? `>${currentMin.label} ${unitOfMeasureLabel}`
        : `${currentMin.label} - ${currentMax.label} ${unitOfMeasureLabel}`;
    return (
      <AutocompleteMultipleOptionWrapper style={{ marginTop: 8 }}>
        <p className={'label'}>
          {label} {currentValue.label}
        </p>
        <div
          className={'remove-icon-container'}
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            onChange({ newLabel: null, valueToChange: 'mainValue' });
            onChange({ newLabel: null, valueToChange: 'min' });
            onChange({ newLabel: null, valueToChange: 'max' });
          }}
        >
          <ClearIcon style={{ width: 15, height: 15 }} />
        </div>
      </AutocompleteMultipleOptionWrapper>
    );
  }, [currentValue, currentMin, currentMax]);

  return (
    <Accordion expanded={expanded} disableTopBorder={disableTopBorder}>
      <AccordionSummary
        onClick={() => setExpanded && setExpanded()}
        classes={{
          expandIconWrapper: 'expand-icon-wrapper',
        }}
      >
        <div
          style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {icon}
            {displayLabel}
          </div>
          {!expanded && resume}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Autocomplete
            fullWidth
            id={id}
            className="add-new-auto-complete"
            value={currentValue ?? null}
            onChange={(e, label) => {
              if (!label) {
                onChange({ newLabel: null, valueToChange: 'mainValue' });
                return;
              }
              onChange({ newLabel: label, valueToChange: 'mainValue' });
            }}
            PaperComponent={({ children }) => (
              <div className="autocomplete-paper">{children}</div>
            )}
            renderOption={(props, option) => (
              <li {...props} className="autocomplete-option">
                {option.id}-{option.label}
              </li>
            )}
            getOptionLabel={(option) => {
              if (!option) return '';
              return `${option.id}-${option.label}`;
            }}
            options={options}
            sx={{
              width: 300,
            }}
            disablePortal
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="standard"
                placeholder={placeholder}
                value={searchText}
                onChange={(e: any) => setSearchText(e.target.value)}
              />
            )}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              width: '100%',
            }}
          >
            <Autocomplete
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder={`Min ${unitOfMeasureLabel}`}
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                />
              )}
              getOptionLabel={(option) => {
                if (!option) return '';
                return option.label;
              }}
              sx={{
                minWidth: '10px !important',
              }}
              disablePortal
              value={currentMin}
              onChange={(e, label) => {
                if (!label) {
                  onChange({ newLabel: null, valueToChange: 'min' });
                  return;
                }
                onChange({ newLabel: label, valueToChange: 'min' });
              }}
              PaperComponent={({ children }) => (
                <div className="autocomplete-paper">{children}</div>
              )}
              renderOption={(props, option) => (
                <li {...props} className="autocomplete-option">
                  {option.label}
                </li>
              )}
              options={optionsRangeMin}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
            />
            <Typography fontSize={12} lineHeight={'16px'} color={colors.grey11}>
              to
            </Typography>
            <Autocomplete
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder={`Max ${unitOfMeasureLabel}`}
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                />
              )}
              getOptionLabel={(option) => {
                if (!option) return '';
                return option.label;
              }}
              sx={{
                minWidth: '10px !important',
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              disablePortal
              value={currentMax}
              onChange={(e, label) => {
                if (!label) {
                  onChange({ newLabel: null, valueToChange: 'max' });
                  return;
                }

                onChange({ newLabel: label, valueToChange: 'max' });
              }}
              PaperComponent={({ children }) => (
                <div className="autocomplete-paper">{children}</div>
              )}
              renderOption={(props, option) => (
                <li {...props} className="autocomplete-option">
                  {option.label}
                </li>
              )}
              options={optionsRangeMax}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchAccordionRangesSelect;
