import {
  Box,
  LinearProgress,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import AddTemplateIcon from 'crono-fe-common/icons/Icon-Add-Template';
import IconFindEmail from 'crono-fe-common/icons/Icon-Find-Email';
import IconFindPhone from 'crono-fe-common/icons/Icon-Find-Phone';
import IconOpportunity from 'crono-fe-common/icons/Icon-Opportunity';
import { colors } from 'crono-fe-common/theme';
import {
  ProductCreditsDTO,
  ProductCreditsTypes,
} from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';
import { getCreditProductLabel } from 'crono-fe-common/utils';
import useCreditsCheckoutSession, {
  CreditsCheckoutSessionInput,
} from 'hooks/services/subscription/useCreditsCheckoutSession';
import moment from 'moment';
import React, { useLayoutEffect, useState } from 'react';

interface IProps {
  currentDate: Date | null;
  data: {
    left: number;
    credits: number;
    total: number;
    bucketsCredits: number | null;
    bucketsTotal: number | null;
  };
  products: ProductCreditsDTO[] | null;
  type: ProductCreditsTypes;
}

const dictionary = {
  [ProductCreditsTypes.EMAIL]: {
    icon: <IconFindEmail className="icon" color={colors.orange} />,
    title: 'Find email credits',
    description:
      'This is the number of times you can search for the hidden email of the contact.',
    colorLight: colors.orangeLight,
    color: colors.orange,
  },
  [ProductCreditsTypes.PHONE]: {
    icon: <IconFindPhone className="icon" color={colors.green} />,
    title: 'Find phone number credits',
    description:
      'This is the number of times you can search for the hidden phone number of the contact.',
    colorLight: colors.greenLightHover,
    color: colors.green,
  },
  [ProductCreditsTypes.TEMPLATE]: {
    icon: <AddTemplateIcon className="icon" color={colors.blueHover} />,
    title: 'AI credits',
    description:
      'This is the number of times you are able to generate templates and messages.',
    colorLight: colors.grey4Hover,
    color: colors.blueHover,
  },
};

const AddOnsCard = ({ currentDate, data, products, type }: IProps) => {
  const { user } = useAuth();
  const analytics = useJuneAnalytics();
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(
    products?.at(0)?.price?.id ?? null,
  );

  useLayoutEffect(() => {
    if (products && !selectedPriceId)
      setSelectedPriceId(products.at(0)?.price?.id ?? null);
  }, [products]);

  const {
    mutateAsync: callCreditsCheckout,
    error: checkoutError,
    isLoading: checkoutLoading,
  } = useCreditsCheckoutSession();

  const handleCheckoutToStripe = (
    priceId: string | null,
    quantity = 1,
  ) => {
    const creditsCheckoutInput: CreditsCheckoutSessionInput = {
      priceId,
      quantity,
    };

    callCreditsCheckout(creditsCheckoutInput)
      .then((res) => {
        const url = res.data?.data.url;
        if (url) window.open(url, '_blank');
      })
      .catch((_) => {
        console.log({ checkoutError });
      });

    if (analytics) {
      analytics.track('products-credits-addons-self-service', {
        type,
        quantity,
        priceId,
      });
    }
  };

  const typeFormat = dictionary[type];

  return (
    <div
      className="credit-container"
      style={{
        background: typeFormat.colorLight,
      }}
    >
      <div className="left-side-container">
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {typeFormat.icon}
          <Typography
            fontSize={'16px'}
            fontWeight={600}
            lineHeight={'22px'}
            color={typeFormat.color}
          >
            {typeFormat.title}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 8,
          }}
        >
          <Typography fontSize={'16px'} fontWeight={500}>
            {data.left ?? 0} credits left
          </Typography>
          <Typography
            fontSize={'14px'}
            lineHeight={'24px'}
            color={colors.grey1}
            width={320}
          >
            {typeFormat.description}
          </Typography>
        </div>
      </div>
      {data.credits !== null ? (
        <div className="right-side-container">
          <div className="used-credits">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography
                fontSize={'18px'}
                fontWeight={600}
                lineHeight={'22px'}
              >
                {data.credits}/{data.total}{' '}
                <span
                  style={{
                    fontSize: '12px',
                    color: colors.grey11,
                    fontWeight: 400,
                  }}
                >
                  per month
                </span>
              </Typography>
              <Box
                sx={{
                  width: 140,
                  color: typeFormat.colorLight,
                }}
              >
                <LinearProgress
                  variant="determinate"
                  color="inherit"
                  sx={{
                    borderRadius: 999,
                    height: 4,
                    backgroundColor: typeFormat.colorLight,
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 999,
                      backgroundColor: typeFormat.color,
                    },
                  }}
                  value={Math.round((100 / data.total) * data.credits)}
                />
              </Box>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography
                fontSize={'12px'}
                fontWeight={400}
                lineHeight={'12px'}
                color={colors.grey11}
              >
                Next update:
              </Typography>
              <Typography
                fontSize={'12px'}
                fontWeight={400}
                lineHeight={'12px'}
              >
                {moment(currentDate).format('MMM DD, YYYY')}
              </Typography>
            </div>
          </div>
          <div className="vertical-divider" />
          <div className="bucket-credits">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                <Typography fontSize={'12px'} color={typeFormat.color}>
                  Additional credits
                </Typography>
                <Typography
                  fontSize={'18px'}
                  fontWeight={600}
                  lineHeight={'22px'}
                >
                  {data.bucketsCredits !== data.bucketsTotal ? (
                    <>
                      {data.bucketsCredits}/{data.bucketsTotal}{' '}
                      <span
                        style={{
                          fontSize: '12px',
                          color: colors.grey11,
                          fontWeight: 400,
                        }}
                      >
                        consumed
                      </span>
                    </>
                  ) : (
                    'None'
                  )}
                </Typography>
              </div>
              <span
                className="additional-credits"
                style={{
                  backgroundColor: typeFormat.colorLight,
                }}
              >
                <IconOpportunity color={typeFormat.color} />
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Select
                name={type}
                onChange={(ev) => setSelectedPriceId(ev.target.value)}
                // disabled={false}
                value={selectedPriceId}
                renderValue={(value) => {
                  if (!value) return '-';
                  if (products) {
                    const credit = (products ?? []).filter(
                      (el) => el.price?.id === value,
                    )[0];
                    return (
                      <Typography
                        noWrap
                        fontSize={'12px'}
                        style={{ paddingTop: '3px' }}
                      >
                        {getCreditProductLabel(credit)}
                      </Typography>
                    );
                  }
                }}
                sx={{ minWidth: '110px', height: '36px' }}
                MenuProps={customMenuProps}
              >
                {products?.map((credit, index) => {
                  if (credit) {
                    return (
                      <MenuItem key={index} value={credit?.price?.id ?? ''}>
                        {credit ? getCreditProductLabel(credit) : '-'}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
              <Tooltip
                title="Purchased credits will not expire on monthly updates"
                enterDelay={400}
                enterNextDelay={400}
                arrow
                placement="bottom"
              >
                <MainButton
                  style={{ height: '32px', width: '66px' }}
                  onClick={() => {
                    handleCheckoutToStripe(selectedPriceId);
                  }}
                  disabled={checkoutLoading}
                >
                  Buy
                </MainButton>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        <p>Not enabled</p>
      )}
    </div>
  );
};

export default AddOnsCard;

const customMenuProps = {
  style: { zIndex: 35001 },
  PaperProps: {
    sx: {
      borderRadius: '8px',
      paddingInline: '6px',
      border: '1px solid #dadde9',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      '& .MuiMenuItem-root': {
        fontSize: '12px',
        lineHeight: '16px',
        height: 'fit-content',
        padding: '8px',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '8px',
        '&:hover': {
          background: colors.primaryLight,
          color: colors.primary,
        },
      },
      '& .Mui-selected': {
        color: colors.primary,
        backgroundColor: 'transparent !important',
      },
    },
  },
};
