import React from 'react';
import App from './app/index';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import PATH from 'routing/path';
import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router-dom';
import 'styles/global.scss';
import { ENDPOINTS } from 'config/endpoints';
import { createRoot } from 'react-dom/client';
import { Analytics } from '@june-so/analytics-next';
import { FeConstants } from 'constants/FeConstants';
import { Quill } from 'react-quill';
import { cookieDomain, cookies } from 'constants/crono-cookie';
import {
  NO_SALES_NAV_CHECK_FROM_USER,
  USER_TOKEN_KEY,
} from 'config/localStorage';

// make ts happy
declare global {
  interface Window {
    Quill: typeof Quill;
  }
}

export const clearOutLocalStorage = () => {
  localStorage.removeItem(USER_TOKEN_KEY);
  localStorage.removeItem('ajs_group_id');
  localStorage.removeItem('ajs_user_id');
  localStorage.removeItem('ajs_user_traits');
  localStorage.removeItem('ajs_group_properties');
  localStorage.removeItem('ajs_anonymous_id');
  localStorage.removeItem(NO_SALES_NAV_CHECK_FROM_USER);
};

export const history = createBrowserHistory();
export const JuneContainer: {
  june: Analytics | null;
} = { june: null };

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      const returnUrl = window.location.pathname;
      if (
        !matchPath(returnUrl, PATH.LOGIN) ||
        error.config.url.includes(ENDPOINTS.auth.me)
      ) {
        clearOutLocalStorage();
        cookies.remove(FeConstants.cronoSessionCookie, {
          domain: cookieDomain,
        });
        JuneContainer.june?.track('redirect-with-return-url', {
          returnUrl: returnUrl,
        });
        history.push(`${PATH.LOGIN}?returnUrl=${returnUrl}`, {
          error: true,
        });
      }
    }

    return Promise.reject(error);
  },
);
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App history={history} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
