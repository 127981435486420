import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const ThreeDotsIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <g id="Group">
        <path
          id="Path"
          d="M9.99918 15.4196C9.76896 15.4196 9.58234 15.233 9.58234 15.0028C9.58234 14.7726 9.76896 14.5859 9.99918 14.5859C10.2294 14.5859 10.416 14.7726 10.416 15.0028C10.416 15.233 10.2294 15.4196 9.99918 15.4196"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_2"
          d="M9.99918 10.4177C9.76896 10.4177 9.58234 10.231 9.58234 10.0008C9.58234 9.77061 9.76896 9.58398 9.99918 9.58398C10.2294 9.58398 10.416 9.77061 10.416 10.0008C10.416 10.231 10.2294 10.4177 9.99918 10.4177"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_3"
          d="M9.99918 5.41571C9.76896 5.41571 9.58234 5.22909 9.58234 4.99887C9.58234 4.76866 9.76896 4.58203 9.99918 4.58203C10.2294 4.58203 10.416 4.76866 10.416 4.99887C10.416 5.22909 10.2294 5.41571 9.99918 5.41571"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ThreeDotsIcon;
