import { AddManuallyTooltipWrapper } from './style';
import { Button, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import IconTask from 'crono-fe-common/icons/Icon-Task';
import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';

interface IProps {
  showManualCompany: () => void;
  showManualContact: () => void;
  showManualTask: () => void;
}

const AddManuallyTooltip = ({
  showManualCompany,
  showManualContact,
  showManualTask,
}: IProps) => {
  return (
    <AddManuallyTooltipWrapper>
      <Typography
        fontSize={12}
        fontWeight={500}
        lineHeight={'16px'}
        color={colors.grey11}
        style={{ paddingLeft: 8, marginBlock: 6 }}
      >
        Add manually:
      </Typography>
      <Button
        className="create-manually-button"
        color="primary"
        fullWidth
        endIcon={<IconTask color={colors.primary} />}
        onClick={showManualTask}
      >
        New Task
      </Button>
      <div className="divider" />
      <Button
        className="create-manually-button"
        color="primary"
        fullWidth
        endIcon={<IconMCompany color={colors.primary} />}
        onClick={showManualCompany}
      >
        New Company
      </Button>
      <div className="divider" />
      <Button
        className="create-manually-button"
        color="primary"
        fullWidth
        endIcon={<IconMContact color={colors.primary} />}
        onClick={showManualContact}
      >
        New Contact
      </Button>
    </AddManuallyTooltipWrapper>
  );
};

export default AddManuallyTooltip;
