import React, { useEffect, useRef, useState } from 'react';
import { ExternalPropertyWrapper } from './style';
import {
  findExternalPropertyIcon,
  findTagColors,
} from 'crono-fe-common/utils/externalProperties';
import { colors } from 'crono-fe-common/theme';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { TextField, Tooltip, Typography } from '@mui/material';
import IconTopArrow from 'crono-fe-common/icons/Icon-Top-Arrow';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import { ensureArray } from 'crono-fe-common/utils';

interface IProps {
  value: string | null;
  tooltipValue?: string | null;
  index: number;
  onClick?: () => void;
  onClickRemove?: () => void;

  showRemove?: boolean;
  customStyle?: any;
  indexOption?: number | (number | undefined)[];

  nullValueText?: React.ReactNode;
  isBeingModified?: boolean;
  searchTagValue?: string;
  setSearchTagValue?: React.Dispatch<React.SetStateAction<string>>;
  onEsc?: () => void;
  onEnter?: () => void;
  disabled?: boolean;
  //Show the plus on the left instead of the arrow on the right
  showAsPlus?: boolean;

  //In the extension we have a different tooltip
  customTooltipComponent?: any;
}

const getTagColor = (indexOption?: number | (number | undefined)[]) => {
  if (indexOption === undefined) {
    return 0;
  }
  const arr = ensureArray(indexOption);
  if (arr?.length === 1) {
    return arr[0] ?? 0;
  }
  //If they are 0 or more than 1 we use the base color
  return 0;
};

const ExternalPropertyComponentRemovable = React.forwardRef(
  (props: IProps, ref: any) => {
    const {
      value,
      tooltipValue,
      index,
      onClick,
      showRemove,
      customStyle,
      indexOption,
      nullValueText,
      onClickRemove,
      isBeingModified,
      searchTagValue,
      setSearchTagValue,
      onEsc,
      onEnter,
      disabled = false,
      showAsPlus = false,
      customTooltipComponent,
      ...rest
    } = props;

    const TooltipToUse = customTooltipComponent ?? Tooltip;

    const tagsColor = getTagColor(indexOption);
    const [light, standard, lightHover] = findTagColors(index, tagsColor);

    const [hover, setHover] = useState<boolean>(false);

    const textFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (isBeingModified && textFieldRef.current) {
        textFieldRef.current?.focus();
      }
    }, [textFieldRef.current, isBeingModified]);

    return (
      <ExternalPropertyWrapper
        ref={ref}
        {...rest}
        style={{
          ...customStyle,
          cursor: disabled ? 'default' : 'pointer',
        }}
        onMouseEnter={() => {
          if (!disabled) setHover(true);
        }}
        onMouseLeave={() => {
          if (!disabled) setHover(false);
        }}
      >
        <div
          className="tag-button"
          style={{
            background: value ? (hover ? lightHover : light) : undefined,
            border: `1px solid ${value ? 'transparent' : colors.grey3}`,
            ...customStyle,
            borderRadius: 8,
          }}
          onClick={onClick}
        >
          {value ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                  flex: 1,
                }}
              >
                {findExternalPropertyIcon(index, standard)}
                {isBeingModified ? (
                  <>
                    <TextField
                      value={searchTagValue}
                      inputRef={textFieldRef}
                      placeholder="Type..."
                      onChange={(ev) => {
                        if (setSearchTagValue)
                          setSearchTagValue(ev.target.value);
                      }}
                      autoComplete="false"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      onKeyDown={(ev) => {
                        if (ev.key === 'Esc') {
                          if (onEsc) onEsc();
                        } else if (ev.key === 'Enter') {
                          if (onEnter) onEnter();
                        }
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          fontSize: 12,
                        },
                      }}
                      sx={{
                        height: '16px',
                        width: '60px',
                      }}
                    />
                  </>
                ) : (
                  <TooltipToUse
                    arrow
                    title={tooltipValue ?? value}
                    enterDelay={400}
                    enterNextDelay={400}
                  >
                    <Typography
                      noWrap
                      flexGrow={0}
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'16px'}
                    >
                      {value}
                    </Typography>
                  </TooltipToUse>
                )}
              </div>
              {(showRemove === undefined ? true : showRemove) && (
                <CloseMIcon
                  color={colors.grey2}
                  className="remove-icon"
                  onClick={(ev: any) => {
                    if (onClickRemove) {
                      ev.stopPropagation();
                      ev.preventDefault();
                      onClickRemove();
                    } else if (onClick) {
                      ev.stopPropagation();
                      ev.preventDefault();
                      onClick();
                    }
                  }}
                />
              )}
            </>
          ) : nullValueText ? (
            isBeingModified ? (
              <>
                {showAsPlus ? (
                  <PlusSmallIcon className="plus-left-icon" />
                ) : (
                  <></>
                )}
                <TextField
                  value={searchTagValue}
                  inputRef={textFieldRef}
                  placeholder="Type..."
                  onChange={(ev) => {
                    if (setSearchTagValue) setSearchTagValue(ev.target.value);
                  }}
                  autoComplete="false"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Esc') {
                      if (onEsc) onEsc();
                    } else if (ev.key === 'Enter') {
                      if (onEnter) onEnter();
                    }
                  }}
                  inputProps={{
                    style: {
                      padding: 0,
                      fontSize: 12,
                    },
                  }}
                  sx={{
                    height: '16px',
                    width: '60px',
                  }}
                />
                {!showAsPlus ? (
                  <IconTopArrow color={colors.grey2} className="tag-arrow" />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <TooltipToUse
                enterDelay={1000}
                enterNextDelay={1000}
                title={
                  disabled ? 'You can not change the value of this tag' : ''
                }
                disableInteractive={!disabled}
                disableHoverListener={!disabled}
                disableFocusListener={!disabled}
                disableTouchListener={!disabled}
                arrow
              >
                <>{nullValueText}</>
              </TooltipToUse>
            )
          ) : (
            <>
              <PlusSmallIcon className="plus-icon" />
              {isBeingModified ? (
                <TextField
                  value={searchTagValue}
                  inputRef={textFieldRef}
                  placeholder="Type..."
                  onChange={(ev) => {
                    if (setSearchTagValue) setSearchTagValue(ev.target.value);
                  }}
                  autoComplete="false"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Esc') {
                      if (onEsc) onEsc();
                    } else if (ev.key === 'Enter') {
                      if (onEnter) onEnter();
                    }
                  }}
                  inputProps={{
                    style: {
                      padding: 0,
                      fontSize: 12,
                    },
                  }}
                  sx={{
                    height: '16px',
                    width: '60px',
                  }}
                />
              ) : (
                <TooltipToUse
                  enterDelay={1000}
                  enterNextDelay={1000}
                  title={
                    disabled ? 'You can not change the value of this tag' : ''
                  }
                  disableInteractive={!disabled}
                  disableHoverListener={!disabled}
                  disableFocusListener={!disabled}
                  disableTouchListener={!disabled}
                  arrow
                >
                  <Typography fontSize={'12px'} lineHeight={'16px'}>
                    Add Tag
                  </Typography>
                </TooltipToUse>
              )}
            </>
          )}
        </div>
      </ExternalPropertyWrapper>
    );
  },
);

export default ExternalPropertyComponentRemovable;
