import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ConnectWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;

  .divider {
    border-top: 1px solid ${colors.grey3};
    opacity: 0.5;
    width: 90%;
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }
  .logo {
    width: 145px;
    // height: 40px;
  }
  .onboarding-steps-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
  }
  .connect-crm {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding: 100px;
    gap: 40px;
  }
  .step-title-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  .crm-connect-card {
    width: 100%;
  }

  .connect-card {
    width: 100%;
    display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    background: ${colors.white};
    border-radius: 20px;
    // row-gap: 20px;
  }

  .connect-text h2 {
    font-size: 2rem;
  }

  .connect-text p {
    color: ${colors.primary};
  }

  .providers {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 90px;
  }
  .gmail-integration {
    gap: 12px;
  }
  .gmail-instructions-wrapper,
  .provider-box {
    width: 90%;
    min-height: 90px;
    // width: 700px;
    min-width: 365px;
    padding: 16px;
    display: flex;

    align-items: center;
    gap: 24px;
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
  }

  .gmail-instructions-wrapper {
    gap: 12px;
    background-color: ${colors.sequenceCardHover};
    flex-direction: column;
  }

  .provider-box:hover {
    background-color: ${colors.grey6};
  }

  .share-guide {
    cursor: pointer;
    color: ${colors.darkGreen};
    font-weight: 600;
  }
  .share-guide:hover {
    color: ${colors.darkGreenHover};
  }

  .icons-buttons-container {
    padding: 8px 16px;
    border-radius: 34px;
    display: flex;
    gap: 4px;
    justify-content: center;
  }
  .connected {
    background-color: ${colors.primaryLight};
    color: ${colors.darkGreen};
    min-width: 140px;
  }
  .not-connected {
    background-color: ${colors.primary};
    color: ${colors.white};
    min-width: 100px;
  }
  .instructions-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.grey4Hover};
    border-radius: 34px;
    cursor: pointer;
  }
  .instructions-button:hover {
    background-color: ${colors.grey4};
  }
  .arrow-icon-button {
    padding: 4px;
  }
  .arrow-icon-button > svg > path {
    stroke: ${colors.black};
  }
  .not-connected:hover {
    background-color: ${colors.darkGreen};
  }
  .connection-label-instructions,
  .connection-label {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .connection-label-instructions {
    padding-left: 12px;
  }
  .success-message {
    color: ${colors.darkGreen};
    // background: ${colors.callScheduledLight};
  }
  .not-success-message {
    color: ${colors.inactive};
    // background: ${colors.inactiveLight};
  }
  .instruction-button-wrapper,
  .connection-status {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .instruction-button-wrapper {
    gap: 2px;
  }
  .icon-logo-container {
    display: flex;
  }
  .connection-image {
    height: 56px;
    width: 56px;
    border-radius: 10px;
    padding: 8px;
  }
  // Caps in first letter to avoid calling toLowerCase
  .Hubspot {
    background-color: ${colors.hubspotOrange};
  }
  .Salesforce {
    background-color: ${colors.salesforceBlue};
  }
  .Pipedrive {
    background-color: ${colors.pipedriveColor};
  }

  .shield-lock {
    padding: 2px;
  }

  .gmail,
  .outlook,
  .aircall,
  .linkedin {
    padding: 0px;
  }
  .email {
    border-radius: 10px;
    background-color: ${colors.grey6};
  }
  .gmail-instructions-icon {
    border-radius: 10px;
    background-color: ${colors.white};
  }
  .logo-with-padding {
    // height: 80px !important;
  }
  .check-icon {
    border-radius: 999px;
    width: 32px;
    height: 32px;
    padding: 6px;
  }
  .success-icon {
    background: ${colors.callScheduledLight};
  }
  .not-success-icon {
    background: ${colors.inactiveLight};
  }
  .provider-box-google {
    background: #f8f9fd;
    margin: 30px 0px;
    padding: 15px 40px;
    height: 100px;
    border: 1px solid #c4c4c4;
  }

  .provider-box img {
    flex-grow: 1;
  }

  .provider-box h3 {
    flex-grow: 2;
    margin: 0px 40px;
    font-weight: 500;
  }

  .gmail-logo {
    margin-right: 10px;
  }

  .pointer {
    cursor: pointer !important;
  }

  .disconnect-integration {
    border-radius: 999px;
    background: ${colors.inactive};
    color: ${colors.white};
    text-transform: unset;

    &:hover {
      background: ${colors.inactiveHover};
    }
  }
  .success-icon-disconnect-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 12px;
    gap: 9px;
  }
  .copy-paste-client-id-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .instruction,
  .instructions-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .instruction-wrapper {
    display: flex;
    gap: 24px;
    padding: 12px 20px 12px 48px;
  }
  .instruction-index {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    height: 24px;
    min-width: 24px;
    background-color: ${colors.lightBlueBackground};
  }
  .horizontal-divider {
    display: flex;
    align-self: center;
    min-width: 90%;
    border-top: 1px solid ${colors.grey444};
    padding-bottom: 18px;
  }

  @media screen and (max-width: 768px) {
    .connect-form {
      padding: 20px 0px;
    }
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
`;

export const OnboardingImageContainer = styled.div`
  height: 100%;
  width: 40%;
  background-color: ${colors.primaryLight};
  display: flex;
  align-items: center;

  .onboarding-image {
    width: 100%;
  }
`;

export const SubscriptionStepWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  .divider {
    border-top: 1px solid ${colors.grey3};
    opacity: 0.5;
    width: 90%;
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }
  .logo {
    width: 145px;
  }

  .subscription-steps-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 88vh;
    gap: 40px;
    align-items: center;
    justify-content: center;
  }
  .subscription-step {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 100px 150px;
    gap: 32px;
    overflow: hidden;
  }
  .step-title-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  .step-card-container {
    width: 100%;
  }

  .step-card {
    width: 100%;
    display: flex;
    justify-content: center;
    background: ${colors.white};
    border-radius: 20px;
  }

  .form-box {
    // width: 90%;
    // width: 700px;
    min-width: 365px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .invite-user-container,
  .form-pricing {
    min-height: 90px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
  }
  .invite-user-container {
    justify-content: space-between;
  }

  // .form-pricing:hover {
  //   background-color: ${colors.grey6};
  // }
  .form-names {
    display: flex;
    gap: 24px;
  }

  .princing-plan-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .pricing-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    min-width: 56px;
    border-radius: 10px;
  }
  .FreeTrial {
    background-color: #fff5c9;
  }
  .FreeTrial > svg > path {
    stroke: ${colors.nurtureHover};
  }
  .Starter {
    background-color: ${colors.primaryLight};
  }
  .Starter > svg > path {
    stroke: ${colors.primary};
  }
  .Pro {
    background-color: ${colors.primary};
  }
  .Pro > svg > path {
    stroke: ${colors.white};
  }
  .user-month-price {
    display: flex;
    min-width: 140px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    gap: 8px;
    margin-right: 16px;
    justify-content: flex-end;
  }
  .price-tag {
    font-size: 24px;
    font-weight: 700;
  }
  .integration-select {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .pointer {
    cursor: pointer;
  }
`;

export const SubscriptionImageContainer = styled.div`
  height: 100%;
  width: 40%;
  background-color: ${colors.grey6};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .image-container-background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .subscription-image {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ExpiredPlanWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  overflow: auto;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
    width: 100%;
  }
  .logo {
    width: 145px;
  }
  .logout {
    width: 120px;
  }

  .action-card {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }
  .action-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 12px;
  }
  .action-button {
    min-width: 130px;
  }
`;

export const SubscriptionPlanWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  overflow: auto;
  padding-bottom: 24px;

  .horizontal-divider {
    display: flex;
    align-self: center;
    min-width: 90%;
    border-top: 1px solid ${colors.grey444};
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
    width: 100%;
  }
  .logo {
    width: 175px;
  }
  .logout {
    width: 120px;
  }
`;

export const SignUpWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  user-select: none;
`;
