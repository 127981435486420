import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AnalyticsTaskFilter } from 'crono-fe-common/types/analyticsFilters';
import { AnalyticsTask } from 'crono-fe-common/types/analyticsTask';

export default function useTaskAnalytics(
  analyticsFilters: AnalyticsTaskFilter,
) {
  const params: any = {};

  if (analyticsFilters.accountId) {
    params.accountId = analyticsFilters.accountId;
  }

  if (analyticsFilters.userId) {
    params.userId = analyticsFilters.userId;
  }

  if (analyticsFilters.since) {
    params.since = analyticsFilters.since;
  }

  if (analyticsFilters.to) {
    params.to = analyticsFilters.to;
  }

  if (analyticsFilters.heatmapTaskType) {
    params.heatmapTaskType = analyticsFilters.heatmapTaskType;
  }

  params.fromCrm = analyticsFilters.fromCrm;

  const request: Request = {
    url: ENDPOINTS.analytics.task,
    config: {
      method: 'post',
      data: params,
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.analytics.task, params],
    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<Response<AnalyticsTask>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
