import { useEffect, useMemo, useState } from 'react';
import { Avatar, MenuItem, Popper, Select, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FilterFormWrapper } from './style';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import useSearchProspects from 'hooks/services/prospect/useSearchProspects';
import { Prospect } from 'crono-fe-common/types/prospect';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import { stringAvatarProspect } from 'utils/fe-utils';
import useGetOpportunity from 'hooks/services/opportunity/useGetOpportunity';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { PickedTaskFilters } from '../accountView/futureTasksBox';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import LinkedinIconInMailOnlyCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass';
import { useAuth } from 'context/auth';

const customSelectStyle = {
  style: { zIndex: 35001 },
  PaperProps: {
    sx: {
      borderRadius: '8px',
      paddingInline: '6px',
      border: '1px solid #dadde9',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      '& .MuiMenuItem-root': {
        fontSize: '12px',
        lineHeight: '16px',
        height: 'fit-content',
        padding: '8px',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '8px',
        '&:hover': {
          background: colors.primaryLight,
          color: colors.primary,
        },
      },
      '& .Mui-selected': {
        color: colors.primary,
        backgroundColor: 'transparent !important',
      },
    },
  },
};

interface IProps {
  accountId: string | null;
  close?: () => void;
  handleSubmit: (filters: PickedTaskFilters) => void;
  pickedFilters: PickedTaskFilters;
}

const FilterTasks = ({
  accountId,
  close,
  handleSubmit,
  pickedFilters,
}: IProps) => {
  const { hasOpportunities } = useAuth();
  // Duplicated logic but needed for showing previous value and saving future value before apply
  const [filters, setFilters] = useState<PickedTaskFilters>(pickedFilters);

  // Prospect logic -----
  const [selectedContact, setSelectedContact] = useState<string | null>(
    pickedFilters.prospectId,
  );
  const [selectedProspect, setSelectedProspect] = useState<
    Prospect | undefined
  >(undefined);
  const [searchContactText, setSearchContactText] = useState<string>('');

  const { data: prospectsSearched } = useSearchProspects({
    limit: 5,
    offset: 0,
    name: searchContactText === '' ? null : searchContactText,
    sort: 'Name',
    status: null,
    accountId: accountId ?? null,
    onlyOwned: true,
  });

  const prospects = useMemo(() => {
    return prospectsSearched?.data?.data ?? [];
  }, [prospectsSearched]);

  useEffect(() => {
    const prospect = prospects.find((el) => el.objectId === selectedContact);
    if (prospect) {
      setSelectedProspect(prospect);
    }
  }, [selectedContact]);

  const handleRemoveProspect = () => {
    setSelectedProspect(undefined);
    setSelectedContact(null);
    setSearchContactText('');
  };

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      prospectId: selectedContact ? selectedContact : null,
    }));
  }, [selectedContact]);

  // Deal logic -----
  const { data: opportunities } = useGetOpportunity(accountId);

  const handleChangeOpportunity = (opportunityId: string) => {
    setFilters((prev) => ({
      ...prev,
      opportunityId: opportunityId ? opportunityId : null,
    }));
  };

  // Type logic -----
  const handleChangeType = (option: TaskTodoType) => {
    setFilters((prev) => ({
      ...prev,
      type: !(prev.type === option) ? option : null,
    }));
  };

  const renderTaskTodoType = (option: TaskTodoType) => {
    let icon = null;
    switch (option) {
      case TaskTodoType.LINKEDIN:
        icon = <LinkedinIcon />;
        break;
      case TaskTodoType.EMAIL:
        icon = <EmailIcon />;
        break;
      case TaskTodoType.CALL:
        icon = <CallIcon />;
        break;
      case TaskTodoType.IN_MAIL:
        icon = <LinkedinIconInMailOnlyCompass viewBox="-3 -3 32 32" />;
        break;
    }
    return (
      <div
        className={filters?.type === option ? 'option-selected' : 'option'}
        onClick={() => handleChangeType(option)}
      >
        {icon}
        <Typography fontSize={'12px'} fontWeight={500}>
          {option}
        </Typography>
      </div>
    );
  };

  const taskTypes = Object.values(TaskTodoType).map((option) => {
    return renderTaskTodoType(option);
  });

  // ----------------

  const handleApply = (filters: PickedTaskFilters) => {
    handleSubmit(filters);
    if (close) close();
  };

  const handleClearFilters = () => {
    setSelectedProspect(undefined);
    setSearchContactText('');
    setFilters({
      prospectId: null,
      type: null,
      opportunityId: null,
    });
  };

  const hasSelectedFilters = useMemo(
    () => Object.values(filters).every((el) => el === null),
    [filters],
  );

  return (
    <FilterFormWrapper>
      <div className="form-add-container">
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Contact:
          </Typography>
          {selectedProspect ? (
            <div className="information-card-manual-task">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 8,
                  width: 'calc(100% - 40px)',
                }}
              >
                <Avatar className="account-avatar">
                  {stringAvatarProspect(selectedProspect)}
                </Avatar>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'calc(100% - 40px)',
                  }}
                >
                  <Typography
                    fontWeight={500}
                    fontSize={14}
                    lineHeight={'18px'}
                    noWrap
                  >
                    {selectedProspect.name}
                  </Typography>
                  <Typography
                    fontWeight={500}
                    fontSize={12}
                    lineHeight={'16px'}
                    color={colors.grey11}
                    noWrap
                  >
                    {selectedProspect.title}
                  </Typography>
                </div>
              </div>
              <RemoveIcon
                className="manual-task-remove-icon"
                color={colors.grey11}
                onClick={handleRemoveProspect}
              />
            </div>
          ) : (
            <CronoAutocomplete
              sx={{
                '& .MuiInputBase-sizeSmall': {
                  borderRadius: '8px',
                  height: '40px',
                },
              }}
              placeholder="All contacts"
              fullWidth
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              onChange={(e, value) => {
                if (value) setSelectedContact(value.id);
                else setSelectedContact(null);
              }}
              options={prospects.map((acc) => {
                return { id: acc.objectId, name: acc.name };
              })}
              getOptionLabel={(option) => option.name || ''}
              PaperComponent={({ children }) => (
                <div className="autocomplete-paper">{children}</div>
              )}
              PopperComponent={({ children, ...props }) => (
                <Popper {...props} className="popper-style">
                  {children}
                </Popper>
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  value={option.id || undefined}
                  className="autocomplete-option"
                >
                  {option.name}
                </li>
              )}
              disablePortal
              setSearchText={(text) => setSearchContactText(text)}
            />
          )}
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Task types:
          </Typography>
          <div className="sizes-container">{taskTypes}</div>
        </div>
        {hasOpportunities && (
          <div className="form-box">
            <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
              Deal:
            </Typography>
            <Select
              value={filters?.opportunityId ?? ''}
              onChange={(ev) => {
                handleChangeOpportunity(ev.target.value as string);
              }}
              style={{
                borderRadius: '8px',
                height: '40px',
              }}
              MenuProps={customSelectStyle}
              displayEmpty
              SelectDisplayProps={{
                style: {
                  opacity: filters?.opportunityId === null ? 0.38 : 1,
                },
              }}
            >
              <MenuItem value={''}>No deal selected</MenuItem>
              {opportunities?.data?.data?.map((opportunity) => {
                return (
                  <MenuItem
                    value={opportunity.objectId ?? ''}
                    key={opportunity.id}
                  >
                    {opportunity.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
        <FlexDiv
          width="fit-content"
          height="fit-content"
          style={{ alignSelf: 'flex-end' }}
        >
          {hasSelectedFilters ? (
            <CancelButton
              style={{ minWidth: '120px' }}
              onClick={() => {
                if (close) close();
              }}
            >
              Cancel
            </CancelButton>
          ) : (
            <div className="clear-filter" onClick={handleClearFilters}>
              <TrashCanDeleteIcon color={colors.inactive} />
              <Typography fontSize={'16px'} fontWeight={500}>
                Clear
              </Typography>
            </div>
          )}
          <MainButton
            style={{ minWidth: '120px' }}
            onClick={() => handleApply(filters)}
          >
            Apply
          </MainButton>
        </FlexDiv>
      </div>
    </FilterFormWrapper>
  );
};

export default FilterTasks;
