import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useAuth } from 'context/auth';
import { useFormik } from 'formik';
import { SearchCompanyTabFiltersType } from './model';
import {
  CronoGatewayLinkedinSearchCompaniesAction,
  CronoGatewayLinkedinSearchSalesNavCompaniesAction,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { ensureArray } from 'crono-fe-common/utils';
import { useFindNewTabContext } from 'pages/home/context/findNewContext';

interface SearchCompanyTabContextType {
  dtoForSearchCompanies: CronoGatewayLinkedinSearchCompaniesAction['params']['filters'];
  dtoForSearchCompaniesSalesNav: CronoGatewayLinkedinSearchSalesNavCompaniesAction['params'];
  filtersChanged: boolean;
  setFiltersChanged: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeFormik: (fieldName: string, fieldValue: any | null) => void;
  values: SearchCompanyTabFiltersType;
  handleClearFilters: () => void;
}

export const SearchCompanyTabContext = createContext<
  SearchCompanyTabContextType | undefined
>(undefined);

SearchCompanyTabContext.displayName = 'SearchCompanyTabContext';

const SearchCompanyTabProvider: FC<{
  children: any;
}> = ({ children }) => {
  const { companiesListPersistent } = useFindNewTabContext();
  const { user } = useAuth();
  const { mutate: patchUserPreferences } = usePatchUserPreferences();
  //To disable the button if the filters are not changed
  //If we have results saved we do not allow to apply if the filters do not change
  const [filtersChanged, setFiltersChanged] = useState<boolean>(
    !companiesListPersistent,
  );

  const formik = useFormik<SearchCompanyTabFiltersType>({
    initialValues: {},
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleChangeFormik = (fieldName: string, fieldValue: any | null) => {
    setFiltersChanged(true);
    formik.setFieldValue(fieldName, fieldValue);
  };

  //Load the initial preferences, using userPreferences and company
  useEffect(() => {
    //Load userPreferences for the filters
    if (!user?.userPreferences?.findNewAccountFilters) return;
    const preferences: SearchCompanyTabFiltersType = JSON.parse(
      user.userPreferences.findNewAccountFilters,
    );
    formik.setValues({
      ...preferences,
      //Previously it was an object and then moved to be an array, this make sure that saved userPreferences will be handled correctly
      geoUrn: preferences.geoUrn ? ensureArray(preferences.geoUrn) : [],
    });
  }, []);

  const handleSaveFiltersInUserPreferences = (
    values: SearchCompanyTabFiltersType,
  ) => {
    const newUserPreferences = JSON.stringify(values);
    patchUserPreferences({
      findNewAccountFilters: newUserPreferences,
    });

    if (user) {
      if (!user.userPreferences) {
        user.userPreferences = {};
      }
      user.userPreferences.findNewAccountFilters = newUserPreferences;
    }
  };

  useEffect(() => {
    //To avoid saving the initial empty object, before the first render
    if (Object.keys(formik.values).length === 0) return;
    handleSaveFiltersInUserPreferences(formik.values);
  }, [formik.values]);

  const dtoForSearchLeads = useMemo(() => {
    return {
      ...(formik.values.geoUrn && {
        location: formik.values.geoUrn.map((g) => g.id),
      }),
      ...(formik.values.companySize && {
        companySize: formik.values.companySize.map((cs) => cs.id),
      }),
      ...(formik.values.industry && {
        industry: formik.values.industry.map((si) => si.id),
      }),
    };
  }, [formik.values]);

  const valueIsSomething = (value?: any | null): boolean => {
    return value !== undefined && value !== null;
  };

  const rangeHasToBeSent = (range?: { min?: number; max?: number } | null) => {
    if (!range) return false;
    return (
      (valueIsSomething(range.min) || valueIsSomething(range.max)) &&
      //If both are set, the min has to be less than the max
      (!valueIsSomething(range.min) ||
        !valueIsSomething(range.max) ||
        Number(range.min) <= Number(range.max))
    );
  };

  const dtoForSearchLeadsSalesNav: CronoGatewayLinkedinSearchSalesNavCompaniesAction['params'] =
    useMemo(() => {
      return {
        ...(formik.values.companySize &&
          formik.values.companySize.length > 0 && {
            headcount: formik.values.companySize,
          }),
        ...(formik.values.headquarterLocation &&
          formik.values.headquarterLocation.length > 0 && {
            headquarterLocation: formik.values.headquarterLocation,
          }),
        ...(formik.values.industry &&
          formik.values.industry.length > 0 && {
            industry: formik.values.industry,
          }),
        ...(formik.values.technologyUsed &&
          formik.values.technologyUsed.length > 0 && {
            technologyUsed: formik.values.technologyUsed,
          }),
        ...(formik.values.hiringOnLinkedin && {
          hiringOnLinkedin: formik.values.hiringOnLinkedin,
        }),
        ...(formik.values.annualRevenue &&
          valueIsSomething(formik.values.annualRevenue?.currency) &&
          valueIsSomething(formik.values.annualRevenue?.min) &&
          valueIsSomething(formik.values.annualRevenue?.max) && {
            annualRevenue: {
              currency: formik.values.annualRevenue.currency!,
              min: Number(formik.values.annualRevenue.min!.id),
              max: Number(formik.values.annualRevenue.max!.id),
            },
          }),
        ...(formik.values.headcountGrowth &&
          rangeHasToBeSent(formik.values.headcountGrowth) && {
            headcountGrowth: {
              ...(valueIsSomething(formik.values.headcountGrowth.min) && {
                min: formik.values.headcountGrowth.min,
              }),
              ...(valueIsSomething(formik.values.headcountGrowth.max) && {
                max: formik.values.headcountGrowth.max,
              }),
            },
          }),
        ...(formik.values.departmentHeadcount &&
          valueIsSomething(formik.values.departmentHeadcount.department) &&
          rangeHasToBeSent(formik.values.departmentHeadcount) && {
            departmentHeadcount: {
              department: formik.values.departmentHeadcount.department,
              ...(valueIsSomething(formik.values.departmentHeadcount.min) && {
                min: formik.values.departmentHeadcount.min,
              }),
              ...(valueIsSomething(formik.values.departmentHeadcount.max) && {
                max: formik.values.departmentHeadcount.max,
              }),
            },
          }),
        ...(formik.values.departmentHeadcountGrowth &&
          valueIsSomething(
            formik.values.departmentHeadcountGrowth.department,
          ) &&
          rangeHasToBeSent(formik.values.departmentHeadcountGrowth) && {
            departmentHeadcountGrowth: {
              department: formik.values.departmentHeadcountGrowth.department,
              ...(valueIsSomething(
                formik.values.departmentHeadcountGrowth.min,
              ) && {
                min: formik.values.departmentHeadcountGrowth.min,
              }),
              ...(valueIsSomething(
                formik.values.departmentHeadcountGrowth.max,
              ) && {
                max: formik.values.departmentHeadcountGrowth.max,
              }),
            },
          }),
      } as CronoGatewayLinkedinSearchSalesNavCompaniesAction['params'];
    }, [formik.values]);

  const handleClearFilters = () => {
    formik.resetForm();
    setFiltersChanged(true);
    handleSaveFiltersInUserPreferences({});
  };

  return (
    <SearchCompanyTabContext.Provider
      value={{
        dtoForSearchCompanies: dtoForSearchLeads,
        dtoForSearchCompaniesSalesNav: dtoForSearchLeadsSalesNav,
        filtersChanged,
        setFiltersChanged,
        handleChangeFormik,
        values: formik.values,
        handleClearFilters,
      }}
    >
      {children}
    </SearchCompanyTabContext.Provider>
  );
};

export default SearchCompanyTabProvider;

export function useSearchCompanyTab() {
  const context = useContext(SearchCompanyTabContext);
  if (context === undefined) {
    throw new Error(
      `useSearchCompanyTab must be used within a SearchCompanyTabProvider`,
    );
  }

  return context;
}
