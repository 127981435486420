import React from 'react';
import { NotificationItemWrapper } from './style';
import { Notification } from 'crono-fe-common/types/notification';
import { Badge, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import moment from 'moment';
import { getNotificationText } from './notificationDictionary';
import NotificationType from 'crono-fe-common/types/enums/notificationType';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';
import { useEmailThread } from 'context/EmailThreadTab';
import usePatchNotification from 'hooks/services/notification/usePatchNotification';

import EmailRepliedIcon from 'crono-fe-common/icons/Icon-Email-Replied';
import LinkedinAcceptedInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Accepted-Invitation';
import IconEmailOpened from 'crono-fe-common/icons/Icon-Email-Opened';
import LinkedinRepliedMessageIcon from 'crono-fe-common/icons/Icon-Linkedin-Replied-Message';
import EmailBouncedIcon from 'crono-fe-common/icons/Icon-Email-Bounced-New';
import IconEmailClickedLink from 'crono-fe-common/icons/Icon-Email-Clicked-Link';
import LinkedinIconInMailReplied from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Replied';
import { useInMailReplyTab } from 'context/inMailReplyTab';
import IconRightArrow from 'crono-fe-common/icons/Icon-Right-Arrow';

interface IProps {
  notification: Notification;
}

const NotificationItem = ({ notification }: IProps) => {
  const { openWindow } = useWindow();
  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();

  const handleOpenContact = () => {
    if (notification) {
      setSelectedAccountId(notification.account?.objectId ?? null);
      setSelectedProspectId(notification.prospect?.objectId ?? null);
      openWindow({
        windowType: 'account',
        tab: 'prospect',
        selectedProspectId: notification.prospect?.objectId ?? undefined,
      });
    }
  };

  const message: any = getNotificationText(notification, handleOpenContact);

  const { isSuccess, mutate: patchNotification } = usePatchNotification();

  const { setEmailThreadContent } = useEmailThread();

  const { setInMailReplyContent } = useInMailReplyTab();

  const handleClickNotification = () => {
    if (notification.id) {
      patchNotification({ notificationId: notification.id });
    }

    if (
      notification.type === NotificationType.EMAIL_ANSWERED ||
      notification.type === NotificationType.EMAIL_OPENED ||
      notification.type === NotificationType.EMAIL_LINK_CLICKED ||
      notification.type === NotificationType.EMAIL_BOUNCED
    ) {
      setTimeout(() => {
        setEmailThreadContent({
          account: notification.account ?? null,
          prospect: notification.prospect ?? null,
          threadId: notification.threadId ?? null,
          emailId: notification.emailId ?? null,
          owned: true,
          openReply: (_threadId: string | null, toAll = false) => {
            setEmailThreadContent(null);
            setSelectedAccountId(notification.accountId ?? null);
            openWindow({
              windowType: 'account',
              tab: 'email',
              modalVisible: 'email',
              selectedProspectId: notification.prospectId ?? undefined,
              selectedThreadId: {
                threadId: notification.threadId ?? null,
                toAll,
              },
            });
          },
        });
      }, 100);
    } else if (
      notification.type === NotificationType.LINKEDIN_ANSWERED ||
      notification.type === NotificationType.LINKEDIN_INVITATION_ACCEPTED
    ) {
      setSelectedAccountId(notification.accountId ?? null);

      setTimeout(() => {
        openWindow({
          windowType: 'account',
          tab: 'linkedin',
          modalVisible: 'linkedin',
          selectedProspectId: notification.prospectId ?? undefined,
        });
      }, 200);
    } else if (
      notification.type === NotificationType.LINKEDIN_IN_MAIL_ANSWERED
    ) {
      setTimeout(() => {
        setInMailReplyContent({
          prospect: notification.prospect ?? null,
        });
      }, 100);
    }
  };

  const renderNotificationIcon = (type: NotificationType | undefined) => {
    let icon = null;
    if (type) {
      switch (type) {
        case NotificationType.EMAIL_ANSWERED:
          icon = (
            <span className="notification-icon">
              <EmailRepliedIcon
                className="icon-size"
                color={colors.lightGreen}
              />
            </span>
          );
          break;
        case NotificationType.EMAIL_OPENED:
          icon = (
            <span className="notification-icon">
              <IconEmailOpened
                className="icon-size"
                color={colors.intenseLightBlue}
              />
            </span>
          );
          break;
        case NotificationType.EMAIL_BOUNCED:
          icon = (
            <span className="notification-icon" style={{ marginTop: '1px' }}>
              <EmailBouncedIcon
                className="icon-size"
                color={colors.inactiveHover}
              />
            </span>
          );

          break;
        case NotificationType.EMAIL_LINK_CLICKED:
          icon = (
            <span className="notification-icon">
              <IconEmailClickedLink
                className="icon-size"
                color={colors.customerHover}
              />
            </span>
          );
          break;
        case NotificationType.LINKEDIN_ANSWERED:
          icon = (
            <span className="notification-icon">
              <LinkedinRepliedMessageIcon
                className="icon-size"
                color={colors.prospectingHover}
              />
            </span>
          );
          break;
        case NotificationType.LINKEDIN_INVITATION_ACCEPTED:
          icon = (
            <span className="notification-icon">
              <LinkedinAcceptedInvitationIcon className="icon-size" />
            </span>
          );
          break;
        case NotificationType.LINKEDIN_IN_MAIL_ANSWERED:
          icon = (
            <span className="notification-icon">
              <LinkedinIconInMailReplied className="icon-size" />
            </span>
          );
          break;
        default:
          icon = (
            <span className="notification-icon">
              <EmailRepliedIcon
                className="icon-size"
                color={colors.lightGreen}
              />
            </span>
          );
      }
    }
    return icon;
  };

  return (
    <NotificationItemWrapper id={`notification-${notification.id}`}>
      <Badge
        variant="dot"
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        invisible={notification.read}
        overlap="circular"
        style={{ height: 'fit-content' }}
        sx={{
          '& .MuiBadge-badge': {
            background: colors.intenseBlue,
            border: `2px solid ${colors.white}`,
            top: '2px',
            left: '2px',
            height: '10px',
            width: '10px',
            borderRadius: '50%',
          },
        }}
      >
        {renderNotificationIcon(notification.type)}
      </Badge>
      <div className="notification-content">
        <Typography
          fontSize={'12px'}
          fontWeight={500}
          color={colors.primaryDark}
        >
          {notification.account?.name}
        </Typography>
        <Typography fontSize={'14px'} fontWeight={400} lineHeight={'18px'}>
          {message}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontSize={'11px'} fontWeight={500} color={colors.grey11}>
            {moment(notification.created).format('HH:mm・ll')}
          </Typography>
          <div className="go-to-button" onClick={handleClickNotification}>
            <Typography fontSize={'14px'} fontWeight={500}>
              Open
            </Typography>
            <IconRightArrow style={{ width: '16px', height: '16px' }} />
          </div>
        </div>
      </div>
    </NotificationItemWrapper>
  );
};

export default NotificationItem;
