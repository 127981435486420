import { ENDPOINTS } from 'config/endpoints';
import { QueryClient, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ProspectInsert } from 'crono-fe-common/types/DTO/prospectInsert';
import { Prospect } from 'crono-fe-common/types/prospect';
import { fullname } from 'utils/fe-utils';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';
import { useLimitedMutation } from '../useLimitedMutation';

export default function useCreateProspect() {
  const queryClient = useQueryClient();

  const createProspect = (prospectInput: ProspectInsert) => {
    const request: Request = {
      url: `${ENDPOINTS.prospect.main}`,
      config: {
        method: 'post',
        data: { data: prospectInput },
      },
    };
    return client(request);
  };

  return useLimitedMutation<Response<Prospect>, CronoError, ProspectInsert>(
    createProspect,
    {
      onSettled: (result, error, variables) => {
        invalidationUseCreateProspect(queryClient, variables);
      },
    },
  );
}

export const invalidationUseCreateProspect = (
  queryClient: QueryClient,
  variables?: {
    accountId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    strategyId?: number | null;
  },
) => {
  CronoAutomaticTaskExecutor.stopDelay();
  queryClient.invalidateQueries([ENDPOINTS.account.main]);
  queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
  queryClient.invalidateQueries([ENDPOINTS.user.onboarding.features]);
  if (variables) {
    queryClient.invalidateQueries([variables.accountId]);
  }
  queryClient.invalidateQueries([
    ENDPOINTS.prospect.linkedin.main,
    variables
      ? { name: fullname(variables.firstName, variables.lastName) }
      : null,
  ]);
  queryClient.invalidateQueries({
    predicate: (query) =>
      query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
  });
  queryClient.invalidateQueries([ENDPOINTS.suggestion.search]);

  queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
  queryClient.invalidateQueries([
    ENDPOINTS.sequence.detail,
    variables ? variables.strategyId : null,
  ]);
  queryClient.invalidateQueries([
    ENDPOINTS.sequence.strategy,
    variables ? variables.strategyId : null,
  ]);
  queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);
  queryClient.invalidateQueries([ENDPOINTS.task.search]);
  queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
};
