import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const EditIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        d="M16.6164 10.4333L13.5664 7.3833"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20917 19.5002H4.5V16.7911C4.5 16.5702 4.5875 16.3577 4.74417 16.2019L15.8558 5.08941C16.1817 4.76357 16.7092 4.76357 17.0342 5.08941L18.91 6.96524C19.2358 7.29107 19.2358 7.81857 18.91 8.14357L7.79833 19.2561C7.6425 19.4127 7.43 19.5002 7.20917 19.5002V19.5002Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
