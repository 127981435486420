import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import CallIconDark from 'crono-fe-common/icons/Icon-Call-Dark';
import EmailIconDark from 'crono-fe-common/icons/Icon-Email-Dark';
import LinkedinIconMessageDark from 'crono-fe-common/icons/Icon-Linkedin-Message-Dark';
import LinkedinInvitationIconDark from 'crono-fe-common/icons/Icon-Linkedin-Invitation-Dark';
import LinkedinIconInvoiceDark from 'crono-fe-common/icons/Icon-Linkedin-Invoice-Dark';
import LinkedinIconInMailCompassDark from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass-Dark';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import { colors } from 'crono-fe-common/theme';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { SequenceStep } from 'crono-fe-common/types/sequenceStep';
import { SequenceStepWrapper, StepTypeOptionsWrapper } from './style';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import {
  dispartPascalCase,
  getDateFromScheduleTime,
  parseTaskTypeToString,
} from 'utils/fe-utils';
import ReplyThreadIcon from 'crono-fe-common/icons/Icon-Reply-Thread';
import AddTemplateIconS from 'crono-fe-common/icons/Icon-Add-Template-S';
import SelectedTemplateIcon from 'crono-fe-common/icons/Icon-Selected-Template';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { Template } from 'crono-fe-common/types/template';
import { SwitchToTask } from 'crono-fe-common/types/enums/switchToConditionalStep';
import { SequenceConditionalStepPatch } from 'crono-fe-common/types/DTO/sequenceConditionalStepPatch';
import ConditionalStepDetailComponent from '../conditionalStepDetailComponent';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import ABTestingTemplates from '../abTestingTemplates';
import { useConfirmModal } from 'context/confirmModal';
import EditableTemplate from './editableTemplate';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { useAuth } from 'context/auth';
import { typeOptions } from 'pages/taskBoard/sequenceInstance/sequenceInstanceTaskComponent';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import CronoTimePicker from 'crono-fe-common/components/CronoTimePicker';
import { TemplateCardsContainerTooltip } from 'pages/accountTab/linkedinView';
import { EnableConditionalForSequence } from 'crono-fe-common/types/DTO/enableConditionalForSequence';
import { Sequence } from 'crono-fe-common/types/sequence';
import TrashDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import classNames from 'classnames';
import ConditionalFlowButton from '../conditionalFlowButton';
import DelayButton from '../delayButton';

export type ReplyThread = 'newThread' | 'existingThread';

interface IProps {
  step: SequenceStep;
  handleChange: (type: string, payload: any, index: number) => void;
  handleChangeConditionalStep: (
    type: string,
    payload: any,
    index: number,
  ) => void;
  handleChangeSequenceStepTemplate: (
    type: string,
    payload: any,
    index: number,
  ) => void;
  index: number;
  openTemplatePicker: () => void;
  openConditionalTemplatePicker: (type: SwitchToTask) => void;
  openABTestingTemplatePicker: (sequenceStepTemplateId: number | null) => void;
  //In order to assign message or request to it
  firstLinkedin: boolean;
  //To know if it is possible to add a linkedin invitation
  beforeFirstLinkedin: boolean;
  linkedinUntilAcceptedDays?: number;
  openAddStepModal: (index: number) => void;
  calculatedDate: string;
  calculatedDateConditional?: string;
  sequence: Sequence;
  patchConditionalStep: (data: SequenceConditionalStepPatch) => void;
  enableConditionalForSequence: (data: EnableConditionalForSequence) => void;

  onSuccessEditTemplate: () => void;

  // Template tooltip
  templateComponent?: JSX.Element | null;
  closeTemplateTooltip?: () => void;
  showTemplateTab?: boolean;
  loadingAdd?: boolean;
  previewOnly?: boolean;
}

const StepDetailComponent = ({
  step,
  handleChange,
  handleChangeConditionalStep,
  index,
  openTemplatePicker,
  openConditionalTemplatePicker,
  openABTestingTemplatePicker,
  firstLinkedin,
  beforeFirstLinkedin,
  linkedinUntilAcceptedDays,
  openAddStepModal,
  calculatedDate,
  calculatedDateConditional,
  sequence,
  enableConditionalForSequence,
  patchConditionalStep,
  handleChangeSequenceStepTemplate,
  onSuccessEditTemplate,
  templateComponent,
  closeTemplateTooltip,
  showTemplateTab,
  loadingAdd = false,
  previewOnly = false,
}: IProps) => {
  const steps = sequence.steps;
  const { hasSalesNav, automaticLinkedinTaskExecutionLimit } = useAuth();
  const [isReplyThreadSubject, setIsReplyThreadSubject] = useState<ReplyThread>(
    step.replyToThread ? 'existingThread' : 'newThread',
  );

  // -----------------------------------------------

  const checkPreviousSteps = (
    step: SequenceStep,
    index: number,
    steps: SequenceStep[],
  ) => {
    // first step never has previous step
    if (step.order === 1) return false;

    // Filter the previous email steps. If there are any, then there must have been previous steps in that strategy
    const previousRelatedStep = steps
      .slice(0, index)
      .filter((s) => s.type === step.type);

    return previousRelatedStep.length > 0;
  };

  const findPreviousThreadSubject = (index: number, steps: SequenceStep[]) => {
    // Only the immediate previous email step is looked to get the subject
    const previousRelatedStep = steps
      .slice(0, index)
      .filter((s) => s.type === TaskTodoType.EMAIL)
      .pop();
    if (previousRelatedStep && previousRelatedStep.template) {
      return previousRelatedStep;
    }
  };

  const [openTypeTooltip, setOpenTypeTooltip] = useState<boolean>(false);

  const { openModal: openConfirmModal } = useConfirmModal();

  const showABTestingDisclaimer = () => {
    openConfirmModal({
      title: 'You have added AB testing templates to this step',
      text: 'If you modify the step type, all statistics related to those templates will be lost and you will not be able to recover them',
      cancelText: 'Cancel',
      confirmText: 'Modify',
      confirmFunction: () => {
        setOpenTypeTooltip((prev) => !prev);
      },
      cancelFunction: () => {
        return;
      },
    });
  };

  // Auto switch disabled logic if there are no AB testing templates and no singular step template
  const enableCheckSwitch = useMemo(() => {
    return (
      (step.type === TaskTodoType.LINKEDIN &&
        step.subtype === TaskTodoSubtype.LinkedinInvitation) ||
      (step.sequenceStepTemplates && step.sequenceStepTemplates?.length) ||
      (step.template && step.type !== TaskTodoType.CALL)
    );
  }, [
    step,
    step.sequenceStepTemplates,
    step.template,
    step.type,
    step.subtype,
  ]);

  const isLinkedinInvitation =
    step.type === TaskTodoType.LINKEDIN &&
    step.subtype === TaskTodoSubtype.LinkedinInvitation;
  const isEmail = step.type === TaskTodoType.EMAIL;

  const [observerEditMode, setObserverEditMode] = useState<boolean>(false);
  const emailSubject = useMemo(
    () =>
      isReplyThreadSubject === 'newThread'
        ? step.template?.subject
        : findPreviousThreadSubject(index, steps)?.template?.subject,
    [isReplyThreadSubject, steps, step.template, index],
  );

  const emailThreadButton = useMemo(
    () => (
      <div className="flex-center email-thread">
        {isEmail && (
          <Select
            value={isReplyThreadSubject}
            startAdornment={<ReplyThreadIcon />}
            inputProps={{ IconComponent: () => null }}
            defaultValue="newThread"
            onChange={(e) => {
              setIsReplyThreadSubject(e.target.value as ReplyThread);
              handleChange(
                'replyToThread',
                e.target.value === 'existingThread',
                index,
              );
            }}
            disabled={observerEditMode}
            sx={{
              fontSize: '14px',
              minWidth: '120px',
              boxShadow: 'none',
              fontWeight: '500',
              gap: '4px',
              padding: '0px',
              '.MuiOutlinedInput-input': {
                padding: '0px',
              },
              '.MuiSelect-select': {
                padding: '0px !important',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover': {
                color: colors.darkGreen,
                '&& svg path': {
                  stroke: colors.darkGreen,
                },
                '&& fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
            MenuProps={{
              style: { zIndex: 3501 },
              PaperProps: {
                sx: {
                  borderRadius: '8px',
                  paddingInline: '6px',
                  border: '1px solid #dadde9',
                  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                  '& .MuiMenuItem-root': {
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '16px',
                    height: 'fit-content',
                    padding: '8px',
                    cursor: 'pointer',
                    width: '100%',
                    borderRadius: '8px',
                    '&:hover': {
                      background: colors.primaryLight,
                      color: colors.primary,
                    },
                  },
                  '& .Mui-selected': {
                    color: colors.primary,
                    backgroundColor: 'transparent !important',
                  },
                },
              },
            }}
          >
            <MenuItem value={'newThread'}>New email thread</MenuItem>
            <MenuItem
              value={'existingThread'}
              disabled={!checkPreviousSteps(step, index, steps)}
            >
              Previous email thread
            </MenuItem>
          </Select>
        )}
      </div>
    ),
    [steps, step, index, isReplyThreadSubject, observerEditMode],
  );

  const renderPossibleTypes = (currentTask: SequenceStep, index: number) => {
    return typeOptions
      .filter((option) => {
        if (
          currentTask.type !== TaskTodoType.IN_MAIL &&
          option.type === TaskTodoType.IN_MAIL &&
          !hasSalesNav
        ) {
          return false;
        }
        if (option.subtype === TaskTodoSubtype.LinkedinInvitation) {
          return (
            beforeFirstLinkedin ||
            (firstLinkedin &&
              currentTask.subtype !== TaskTodoSubtype.LinkedinInvitation)
          );
        } else {
          return (
            option.type !== currentTask.type ||
            (currentTask.subtype &&
              option.subtype &&
              option.subtype !== currentTask.subtype)
          );
        }
      })
      .map((option, indexOption) => {
        return (
          <div key={indexOption}>
            <Button
              className="option-button"
              color="primary"
              fullWidth
              endIcon={option.icon}
              onClick={() => {
                handleChange(
                  'type',
                  { type: option.type, subtype: option.subtype },
                  index,
                );
                setOpenTypeTooltip(false);
              }}
            >
              {option.text}
            </Button>
          </div>
        );
      });
  };

  const renderStepType = (task: SequenceStep) => {
    const type = parseTaskTypeToString(task.type, task.subtype ?? null);
    return (
      <ClickAwayListener onClickAway={() => setOpenTypeTooltip(false)}>
        <div style={{ width: '100%' }}>
          <CustomOptionsTooltip
            maxHeight={220}
            width={190}
            left={1}
            title={
              <StepTypeOptionsWrapper>
                {renderPossibleTypes(task, index)}
              </StepTypeOptionsWrapper>
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={openTypeTooltip}
            onClose={() => setOpenTypeTooltip(false)}
            borderRadius={'16px'}
            sx={{
              zIndex: '10001 !important',
            }}
          >
            <div
              className="step-type-option-container"
              onClick={() => {
                if (step.sequenceStepTemplates) {
                  showABTestingDisclaimer();
                  return;
                }
                setOpenTypeTooltip((prev) => !prev);
              }}
            >
              <div className="step-type-option">
                <Typography
                  fontSize={'12px'}
                  fontWeight={500}
                  paddingLeft={'12px'}
                >
                  {type}
                </Typography>
              </div>
              {openTypeTooltip ? <TopArrowIcon /> : <BottomArrowIcon />}
            </div>
          </CustomOptionsTooltip>
        </div>
      </ClickAwayListener>
    );
  };

  const renderActivityIcon = (step: SequenceStep) => {
    switch (step.type) {
      case TaskTodoType.CALL:
        return <CallIconDark />;
      case TaskTodoType.EMAIL:
        return <EmailIconDark />;
      case TaskTodoType.LINKEDIN:
        if (step.subtype === TaskTodoSubtype.LinkedinInvitation) {
          return <LinkedinInvitationIconDark />;
        } else if (step.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
          return <LinkedinIconInvoiceDark />;
        } else {
          return <LinkedinIconMessageDark />;
        }
      case TaskTodoType.IN_MAIL:
        return <LinkedinIconInMailCompassDark />;
    }
  };

  const stepsIncludeLinkedinWithConditional = useMemo(
    () =>
      steps.findIndex(
        (step) =>
          step.type === TaskTodoType.LINKEDIN && step.sequenceStepConditional,
      ) !== -1,
    [steps],
  );

  const stepsIncludeLinkedinNonInvitation = useMemo(
    () =>
      steps.findIndex(
        (step) =>
          step.type === TaskTodoType.LINKEDIN &&
          step.subtype !== TaskTodoSubtype.LinkedinInvitation,
      ) !== -1,
    [steps],
  );

  const templatesButton = useMemo(
    () => (
      <div
        className="flex-center step-right-side-wrapper"
        style={{
          alignSelf:
            step.sequenceStepConditional &&
            (step.template || step.sequenceStepTemplates)
              ? 'flex-start'
              : undefined,
        }}
      >
        {/* Main add template button */}
        {!previewOnly ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              paddingLeft: '20px',
              flex: 1,
            }}
          >
            <div
              className={`flex-center ${
                observerEditMode
                  ? ' disable-due-to-edit'
                  : step.template
                    ? 'selected-template'
                    : 'add-template'
              } ${step.sequenceStepTemplates ? 'ab-testing' : ''}`}
            >
              {step.sequenceStepTemplates ? (
                <>
                  <Typography fontSize={14} fontWeight={500} noWrap>
                    A/B testing
                  </Typography>
                  <span className="flex-center icon-wrapper">
                    <AddTemplateIconS
                      className={'ab-testing-step-component-button'}
                    />
                  </span>
                  <Typography fontSize={14} fontWeight={500} noWrap>
                    {step.sequenceStepTemplates.length}
                  </Typography>
                </>
              ) : step.template ? (
                <>
                  <TemplateCardsContainerTooltip
                    title={templateComponent}
                    onClose={closeTemplateTooltip}
                    open={showTemplateTab}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '4px',
                      }}
                    >
                      <span className="flex-center icon-wrapper">
                        <SelectedTemplateIcon
                          className={`${
                            observerEditMode
                              ? 'step-component-button-disabled'
                              : 'step-component-button'
                          }`}
                          color={colors.grey11}
                          onClick={openTemplatePicker}
                        />
                      </span>
                      <Typography
                        fontSize={'14px'}
                        fontWeight={500}
                        noWrap
                        onClick={openTemplatePicker}
                        maxWidth={'250px'}
                      >
                        {step.template.title}
                      </Typography>
                    </div>
                  </TemplateCardsContainerTooltip>

                  <span
                    onClick={() => {
                      handleChange('template', { id: 0 } as Template, index);
                    }}
                    style={{
                      cursor: observerEditMode ? 'default' : 'pointer',
                      display: 'flex',
                    }}
                    className="close-template-option"
                  >
                    <CloseMIcon color={colors.grey11} />
                  </span>
                </>
              ) : (
                <>
                  <TemplateCardsContainerTooltip
                    title={templateComponent}
                    onClose={closeTemplateTooltip}
                    open={showTemplateTab}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '4px',
                      }}
                    >
                      <span className="flex-center icon-wrapper">
                        <AddTemplateIconS
                          className={'step-component-button'}
                          onClick={() => {
                            openTemplatePicker();
                          }}
                        />
                      </span>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        noWrap
                        onClick={() => {
                          openTemplatePicker();
                        }}
                      >
                        Add template
                      </Typography>
                    </div>
                  </TemplateCardsContainerTooltip>
                </>
              )}
            </div>
            {observerEditMode && (
              <div className="badge-observable-mode">
                <EditPencilIconS
                  color={colors.nurtureHover}
                  className="pencil-icon"
                />
                Draft
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '18px 16px',
            }}
          >
            {step.template && (
              <>
                <div style={{ display: 'inline-flex', marginRight: '8px' }}>
                  <SelectedTemplateIcon />
                </div>

                <Typography
                  fontSize={14}
                  fontWeight={500}
                  lineHeight={'18px'}
                  color={colors.darkGreen}
                >
                  {step.template.title}
                </Typography>
              </>
            )}
          </div>
        )}
      </div>
    ),
    [
      observerEditMode,
      step.template,
      step.sequenceStepTemplates,
      step.sequenceStepConditional,
      handleChange,
      templateComponent,
      closeTemplateTooltip,
      openTemplatePicker,
      showTemplateTab,
    ],
  );

  return (
    <SequenceStepWrapper>
      <div
        className="step-row"
        style={{
          paddingTop: index === 0 ? '12px' : '',
        }}
      >
        {loadingAdd ? (
          <CircularProgress
            size={18}
            style={{
              marginInline: '21px',
            }}
          />
        ) : (
          <>
            {!previewOnly ? (
              <Tooltip placement="top" title="Add a new step" arrow>
                <div
                  className="add-step-container"
                  onClick={() => openAddStepModal(index)}
                >
                  <div className="step-plus">
                    <Typography fontSize={12} fontWeight={500}>
                      Add
                    </Typography>
                    <PlusSmallIcon color={colors.primary} />
                  </div>
                </div>
              </Tooltip>
            ) : (
              <div style={{ width: '72px' }} />
            )}
          </>
        )}

        <div
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
            justifyContent: step.sequenceStepConditional
              ? 'space-around'
              : 'center',
            flex: 1,
          }}
        >
          <DelayButton
            disabled={previewOnly}
            delay={step.delay}
            increaseDelay={() => {
              if (previewOnly) return;
              handleChange('delay', 1, index);
            }}
            decreaseDelay={() => {
              if (previewOnly) return;
              handleChange('delay', -1, index);
            }}
            dateLabel={`Ex. ${calculatedDate}`}
          />
          {step.sequenceStepConditional && calculatedDateConditional && (
            <DelayButton
              disabled={previewOnly}
              delay={step.sequenceStepConditional.daysAfterConditionNotAccepted}
              increaseDelay={() => handleChange('delay-conditional', 1, index)}
              decreaseDelay={() => handleChange('delay-conditional', -1, index)}
              dateLabel={`Ex. ${calculatedDateConditional}`}
            />
          )}
          {isLinkedinInvitation &&
            !stepsIncludeLinkedinWithConditional &&
            !previewOnly && (
              <ConditionalFlowButton
                previewOnly={previewOnly}
                onClickButton={() => {
                  enableConditionalForSequence({
                    strategyId: sequence.strategyId,
                    invitationStepId: step.id,
                  });
                }}
                disabledText={
                  stepsIncludeLinkedinNonInvitation
                    ? ''
                    : 'Conditionals are only applicable for strategies that include LinkedIn messages'
                }
              />
            )}
        </div>
      </div>

      <div className="step-row" style={previewOnly ? { gap: '40px' } : {}}>
        <div
          style={{
            height: '100%',
          }}
        >
          <div
            className="step-number-container"
            style={
              previewOnly
                ? {
                    minWidth: '40px',
                    width: 'auto',
                    margin: 0,
                  }
                : {}
            }
          >
            <span
              className={'step-number'}
              style={
                previewOnly ? { textAlign: 'center', lineHeight: '40px' } : {}
              }
            >
              {index + 1}
            </span>

            {!previewOnly && (
              <Tooltip
                title={'Delete step'}
                enterDelay={400}
                enterNextDelay={400}
                arrow
              >
                <div
                  className={'step-delete-icon-container'}
                  onClick={() => handleChange('delete', null, index)}
                >
                  <TrashDeleteIcon />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div
          className={
            step?.sequenceStepConditional
              ? 'step-content-conditional'
              : 'step-content'
          }
        >
          <div
            id={step.id.toString()}
            className="step-content-single-step"
            style={previewOnly ? { width: '95%' } : {}}
          >
            <div
              className={classNames('step-content-top', {
                'step-content-no-border-bottom':
                  step?.sequenceStepConditional ||
                  step?.template ||
                  (!step.template && step.sequenceStepTemplates),
              })}
              style={{ borderBottom: step.template ? 'none' : '' }}
            >
              <div
                className="flex-center step-left-side-wrapper"
                style={previewOnly ? { width: '60%' } : {}}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  {renderActivityIcon(step)}

                  {!previewOnly ? (
                    <div className="step-type-option-dropdown">
                      {renderStepType(step)}
                    </div>
                  ) : (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      lineHeight={'18px'}
                      color={colors.black}
                    >
                      {step.type !== TaskTodoType.LINKEDIN ? (
                        <>{step.type}</>
                      ) : (
                        <>
                          {/* linkedin types always have a subtype */}
                          {dispartPascalCase(step.subtype || 'Linkedin')}
                        </>
                      )}
                    </Typography>
                  )}
                </div>

                {previewOnly && step.automatic && (
                  <div
                    style={{
                      width: '45px',
                      height: '24px',
                      borderRadius: '12px',
                      backgroundColor: colors.lightBlue,
                    }}
                  >
                    <Typography
                      fontSize={12}
                      lineHeight={'24px'}
                      fontWeight={500}
                      color={colors.blueHover}
                      textAlign={'center'}
                    >
                      Auto
                    </Typography>
                  </div>
                )}

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {step.type !== TaskTodoType.CALL && (
                    <>
                      {!previewOnly && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {(step.type === TaskTodoType.EMAIL ||
                            (step.type === TaskTodoType.LINKEDIN &&
                              !step.subtype) ||
                            step.subtype === TaskTodoSubtype.LinkedinMessage ||
                            step.subtype ===
                              TaskTodoSubtype.LinkedinInvitation) && (
                            <Tooltip
                              arrow
                              title={
                                step.type === TaskTodoType.LINKEDIN &&
                                step.subtype ===
                                  TaskTodoSubtype.LinkedinInvitation
                                  ? `You can automate up to ${automaticLinkedinTaskExecutionLimit} LinkedIn tasks daily. Tasks that exceed the limit will be postponed to the next day`
                                  : step.template || step.sequenceStepTemplates
                                    ? step.type === TaskTodoType.LINKEDIN
                                      ? `You can automate up to ${automaticLinkedinTaskExecutionLimit} LinkedIn tasks daily. Tasks that exceed the limit will be postponed to the next day`
                                      : 'Automate this step'
                                    : 'Please select a template to automate this step'
                              }
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  fontSize={14}
                                  fontWeight={500}
                                  color={colors.grey11}
                                >
                                  Auto
                                </Typography>

                                <CronoSwitch
                                  checked={step.automatic}
                                  onChange={(ev) =>
                                    handleChange(
                                      'automatic',
                                      ev.target.checked,
                                      index,
                                    )
                                  }
                                  disabled={!enableCheckSwitch}
                                />
                              </div>
                            </Tooltip>
                          )}

                          {step.automatic && (
                            <CronoTimePicker
                              value={getDateFromScheduleTime(
                                step.scheduleTime ?? null,
                              )}
                              handleChange={(newValue) =>
                                handleChange('scheduleTime', newValue, index)
                              }
                              disabled={!enableCheckSwitch || !step.automatic}
                            />
                          )}
                        </div>
                      )}

                      {previewOnly && step.automatic && <></>}
                    </>
                  )}
                </div>
              </div>
              {!step?.sequenceStepConditional && (
                <>
                  <div className="vertical-divider" />
                  {templatesButton}
                </>
              )}
            </div>

            {((!step.template && step.sequenceStepTemplates) ||
              step.sequenceStepConditional ||
              step.template ||
              (step.type === TaskTodoType.LINKEDIN &&
                step.subtype === TaskTodoSubtype.LinkedinInvitation &&
                step.template)) && (
              <div className="step-content-bottom">
                {/*Switcher to end strategy if invitation accepted */}
                {step.type === TaskTodoType.LINKEDIN &&
                  step.subtype === TaskTodoSubtype.LinkedinInvitation &&
                  (step.template || step.sequenceStepTemplates) && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8,
                        alignSelf: 'flex-end',
                      }}
                    >
                      <Typography
                        fontSize={12}
                        lineHeight={'16px'}
                        color={colors.grey11}
                      >
                        Stop strategy when this note is replied
                      </Typography>

                      <CronoSwitch
                        disabled={previewOnly}
                        checked={
                          step.stopSequenceInstanceIfInvitationReplied ?? false
                        }
                        onChange={(ev) => {
                          handleChange(
                            'stopSequenceInstanceIfInvitationReplied',
                            ev.target.checked,
                            index,
                          );
                        }}
                      />
                    </div>
                  )}
                {/* Single template dropdown and A/B testing templates*/}
                {!step.template && step.sequenceStepTemplates ? (
                  <>
                    {step.sequenceStepTemplates?.map(
                      (sequenceStepTemplate, _index) => {
                        return (
                          <ABTestingTemplates
                            //This is a trick to force the re-render of the component when the template is changed
                            key={`${sequenceStepTemplate.templateId}${sequenceStepTemplate.template?.content.length}${_index}`}
                            sequenceStepTemplate={sequenceStepTemplate}
                            step={step}
                            index={index}
                            currentIndex={_index}
                            handleChange={handleChangeSequenceStepTemplate}
                            sequenceStepTemplates={step.sequenceStepTemplates!}
                            onSuccessEditTemplate={onSuccessEditTemplate}
                            EmailThreadButton={emailThreadButton}
                            previewOnly={previewOnly}
                          />
                        );
                      },
                    )}

                    {!previewOnly && (
                      <>
                        <div className="horizontal-divider" />

                        <TemplateCardsContainerTooltip
                          title={templateComponent}
                          onClose={closeTemplateTooltip}
                          open={showTemplateTab}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <div
                            className={
                              !previewOnly
                                ? 'ab-testing-wrapper'
                                : 'ab-testing-wrapper-preview'
                            }
                          >
                            <PlusIcon />

                            <Typography
                              fontSize={'14px'}
                              fontWeight={500}
                              onClick={() => {
                                if (previewOnly) return;

                                openABTestingTemplatePicker(
                                  step.sequenceStepTemplates?.[0].id ?? null,
                                );
                              }}
                            >
                              Add template
                            </Typography>
                          </div>
                        </TemplateCardsContainerTooltip>
                      </>
                    )}
                  </>
                ) : step?.sequenceStepConditional || step?.template ? (
                  <>
                    {step?.sequenceStepConditional && (
                      <>
                        <div className="vertical-divider" />
                        {templatesButton}
                      </>
                    )}

                    {step?.template && (
                      <>
                        <EditableTemplate
                          emailThreadButton={
                            isEmail ? emailThreadButton : undefined
                          }
                          subject={
                            isEmail ? emailSubject : step.template?.subject
                          }
                          enableSubjectMutation={
                            isReplyThreadSubject === 'newThread'
                          }
                          stepId={step.id}
                          template={step.template}
                          setObserverEditMode={(value) =>
                            setObserverEditMode(value)
                          }
                          onSuccess={onSuccessEditTemplate}
                          previewOnly={previewOnly}
                        />

                        {!previewOnly && (
                          <>
                            <div className="horizontal-divider" />
                            <div
                              onClick={() => {
                                openABTestingTemplatePicker(null);
                              }}
                              className={'ab-testing-wrapper'}
                            >
                              <PlusIcon />

                              <Typography fontSize={'14px'} fontWeight={500}>
                                Add A/B testing
                              </Typography>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </div>
            )}
          </div>
          {/* Conditional sequence */}
          {step?.sequenceStepConditional ? (
            <div className="conditional-step-wrapper">
              <ConditionalStepDetailComponent
                step={step}
                patchConditionalStep={patchConditionalStep}
                conditionalStep={step.sequenceStepConditional}
                parentStepType={step.type}
                handleChange={handleChangeConditionalStep}
                index={index}
                openConditionalTemplatePicker={openConditionalTemplatePicker}
                previousThreadSubject={findPreviousThreadSubject(index, steps)}
                onSuccessEditTemplate={onSuccessEditTemplate}
                previewOnly={previewOnly}
              />
            </div>
          ) : null}
        </div>
      </div>

      {isLinkedinInvitation && stepsIncludeLinkedinWithConditional && (
        <div className="step-row">
          <div
            className="step-content"
            style={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ConditionalFlowButton
              id={`${step.id}-conditional-button`}
              onDelete={() => {
                handleChange('delete-conditionals', null, index);
              }}
              delay={linkedinUntilAcceptedDays ?? 0}
              increaseDelay={() => handleChange('untilAccepted', 1, index)}
              decreaseDelay={() => handleChange('untilAccepted', -1, index)}
              previewOnly={previewOnly}
            />
          </div>
        </div>
      )}
    </SequenceStepWrapper>
  );
};

export default StepDetailComponent;
