import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Typography,
  IconButton,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import { InsightsAccordionWrapper } from './style';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { ReactComponent as RedirectIcon } from 'crono-fe-common/icons/Redirect.svg';
import { colors } from 'crono-fe-common/theme';
import {
  LinkedinActivity,
  LinkedinJobPost,
  LinkedinWorkExperience,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { InsightType } from 'pages/accountTab/emailView/insightsCard';
import InnerTextAccordion from './innerTextAccordion';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { Prospect } from 'crono-fe-common/types/prospect';
import { Constants } from 'crono-fe-common/constants/constants';
import { getDateDiff } from 'utils/fe-utils';
import IconFilledDownArrow from 'crono-fe-common/icons/Icon-filled-rounded-down-arrow';
import { useJuneAnalytics } from 'context/june';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';

interface IProps {
  options: any | null;
  icon: JSX.Element;
  type: InsightType;
  title?: string;
  collapsedHeight?: string;
  style?: any;
  fetchOptions?: () => Promise<void>;
  fetchOptionsLoading?: boolean;
  prospect?: Prospect | null;
  publicId?: string | null;
  growth?: string | null;
  companyPublicId?: string | null;
  selectedArray?: number[];
  setSelectedArray?: (newArray: number[]) => void;
  toggleSelectedOption?: () => void;
  isSelected?: boolean;
}

const InsightsAccordion = ({
  options,
  icon,
  type,
  title,
  collapsedHeight,
  style,
  fetchOptions,
  fetchOptionsLoading,
  prospect,
  publicId,
  growth,
  companyPublicId,
  selectedArray,
  setSelectedArray,
  isSelected,
  toggleSelectedOption,
}: IProps) => {
  const analytics = useJuneAnalytics();

  const [expanded, setExpanded] = useState<boolean>(false);

  const sortedExperiences: LinkedinWorkExperience[] = useMemo(() => {
    const exp = options?.workExperience;
    if (options?.workExperience?.length > 0 && companyPublicId) {
      const experienceInCronoRegisteredCompany: LinkedinWorkExperience[] =
        options?.workExperience?.filter(
          (experience: LinkedinWorkExperience) => {
            return experience.company.publicId === companyPublicId;
          },
        );
      const currentExperience = experienceInCronoRegisteredCompany.findIndex(
        (exp) => {
          return !exp.timePeriod?.endDate;
        },
      );
      if (currentExperience !== -1) {
        experienceInCronoRegisteredCompany.unshift(
          experienceInCronoRegisteredCompany.splice(currentExperience, 1)[0],
        );
      }

      return [
        ...experienceInCronoRegisteredCompany,
        ...exp.filter((experience: LinkedinWorkExperience) => {
          return experience.company.publicId !== companyPublicId;
        }),
      ];
    }
    return exp;
  }, [options, companyPublicId]);

  const onlyOneExperience = useMemo(() => {
    return (
      options &&
      options?.workExperience &&
      options?.workExperience?.length === 1
    );
  }, [options]);

  const insightHeaderTitleCounter = (option: any, type: InsightType) => {
    let counter;
    switch (type) {
      case InsightType.SOCIAL_PRESENCE:
        if (option && option.length) {
          counter = option?.length;
        }
        break;
      case InsightType.KEY_TOPICS:
        if (option) {
          counter = option?.length;
        }
        break;
      case InsightType.JOB_OPENINGS:
        if (option && option?.jobs && option?.jobs?.length) {
          counter = option?.jobs?.length;
        }
        break;
      case InsightType.COMPANY_NEWS:
        if (
          option &&
          option?.activities?.activities &&
          option?.activities?.activities?.length
        ) {
          counter = option?.activities?.activities?.length;
        }
        break;
      case InsightType.FUNDING_ROUNDS:
        if (option) {
          counter = option.numberOfFundingRounds;
        }
    }
    return counter ? (
      <span className={`counter ${type}-counter`}>{counter}</span>
    ) : null;
  };

  const renderCronoCheckbox = useMemo(() => {
    if (!options) return true;

    return (
      (type === InsightType.SOCIAL_PRESENCE && options.length > 0) ||
      (type === InsightType.COMPANY_NEWS &&
        (options.activities?.activities?.length ?? 0) > 0) ||
      (type === InsightType.JOB_OPENINGS && options.jobs.length > 0) ||
      (type === InsightType.KEY_TOPICS && options.length > 0) ||
      (type === InsightType.FUNDING_ROUNDS && !!options.amount) ||
      (type === InsightType.PROFILE_VIEW &&
        sortedExperiences &&
        sortedExperiences.length > 0)
    );
  }, [options, type]);

  const [toBeSelectedAfterFetch, setToBeSelectedAfterFetch] =
    useState<boolean>(false);

  useEffect(() => {
    if (setSelectedArray && options && toBeSelectedAfterFetch) {
      if (
        (type === InsightType.SOCIAL_PRESENCE && options.length > 0) ||
        (type === InsightType.JOB_OPENINGS && options.jobs.length > 0)
      ) {
        setSelectedArray(selectedArray?.length === 0 ? [0] : []);
      }
      setToBeSelectedAfterFetch(false);
    }
  }, [options]);

  return (
    <InsightsAccordionWrapper
      id="insights-accordion-wrapper"
      style={{ height: expanded ? 'fit-content' : collapsedHeight, ...style }}
    >
      <div
        className={`insights-top-header ${type} ${type}-header ${expanded ? `${type}-header-expanded` : ''}`}
      >
        <div className={`insights-icon-position-container`}>
          {renderCronoCheckbox &&
          selectedArray !== undefined &&
          setSelectedArray !== undefined ? (
            <span className={`insight-icon-wrapper ${type}-ICON`}>
              {type === InsightType.SOCIAL_PRESENCE ||
              type === InsightType.COMPANY_NEWS ||
              type === InsightType.JOB_OPENINGS ? (
                <CronoCheckbox
                  checked={selectedArray.length !== 0}
                  onClick={async () => {
                    if (!expanded && !options && fetchOptions) {
                      setToBeSelectedAfterFetch(true);
                      await fetchOptions();
                    } else {
                      setSelectedArray(selectedArray.length === 0 ? [0] : []);
                    }
                  }}
                />
              ) : (
                <CronoCheckbox
                  checked={selectedArray.includes(0)}
                  onClick={() =>
                    setSelectedArray(
                      selectedArray.includes(0)
                        ? selectedArray.filter((index) => index !== 0)
                        : [...selectedArray, 0],
                    )
                  }
                />
              )}
            </span>
          ) : renderCronoCheckbox &&
            isSelected !== undefined &&
            toggleSelectedOption !== undefined ? (
            <span className={`insight-icon-wrapper ${type}-ICON`}>
              <CronoCheckbox
                checked={isSelected}
                onClick={toggleSelectedOption}
              />
            </span>
          ) : (
            <span className={`insight-icon-wrapper ${type}-ICON`}>{icon}</span>
          )}
          {type === InsightType.PROFILE_VIEW && sortedExperiences ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                cursor: onlyOneExperience ? 'default' : 'pointer',
              }}
              onClick={async (ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                if (!onlyOneExperience) {
                  setExpanded((prev) => {
                    if (!prev) {
                      if (analytics) {
                        analytics.track('open-insights-detail', {
                          type: InsightType.PROFILE_VIEW,
                        });
                      }
                    }
                    return !prev;
                  });
                  if (!options && fetchOptions) {
                    await fetchOptions();
                  }
                }
              }}
            >
              <div className="insight-position-experience">
                <Typography fontSize={'12px'} fontWeight={500} noWrap>
                  {sortedExperiences[0]?.position}
                </Typography>
                <Typography fontSize={'12px'} color={colors.grey11} noWrap>
                  {sortedExperiences[0]?.company?.name} -{' '}
                  {sortedExperiences[0]?.timePeriod?.years
                    ? `${sortedExperiences[0]?.timePeriod?.years} ye`
                    : null}
                  {sortedExperiences[0]?.timePeriod?.months
                    ? ` ${sortedExperiences[0]?.timePeriod?.months} mo`
                    : null}{' '}
                  -{' '}
                  {!sortedExperiences[0]?.timePeriod?.endDate
                    ? 'Present'
                    : `${Constants.monthsDictionary[sortedExperiences[0]?.timePeriod?.endDate?.month]} ${sortedExperiences[0]?.timePeriod?.endDate?.year} `}
                </Typography>
              </div>
              {expanded && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    width: 'fit-content',
                  }}
                >
                  <IconButton
                    style={{
                      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                      height: 'fit-content',
                    }}
                  >
                    <BottomArrow />
                  </IconButton>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={async (ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                setExpanded((prev) => {
                  if (!prev) {
                    if (analytics) {
                      analytics.track('open-insights-detail', {
                        type: type,
                      });
                    }
                  }
                  return !prev;
                });
                if (!options && fetchOptions) {
                  await fetchOptions();
                }
              }}
            >
              <Typography
                fontSize={'13px'}
                fontWeight={400}
                noWrap
                display={'flex'}
              >
                {title} {options && insightHeaderTitleCounter(options, type)}
              </Typography>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                }}
              >
                {growth ? (
                  <span
                    className={
                      growth?.includes('-')
                        ? 'negative-growth'
                        : 'positive-growth'
                    }
                  >
                    Growth: {growth}
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transform: !growth?.includes('-')
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }}
                    >
                      <IconFilledDownArrow
                        color={
                          growth?.includes('-')
                            ? colors.inactive
                            : colors.darkGreen
                        }
                      />
                    </span>
                  </span>
                ) : null}
                <IconButton
                  onClick={async (ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setExpanded((prev) => {
                      if (!prev) {
                        if (analytics) {
                          analytics.track('open-insights-detail', {
                            type: type,
                          });
                        }
                      }
                      return !prev;
                    });
                    if (!options && fetchOptions) {
                      await fetchOptions();
                    }
                  }}
                  style={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    height: 'fit-content',
                  }}
                >
                  <BottomArrow />
                </IconButton>
              </div>
            </div>
          )}
        </div>
        {type === InsightType.PROFILE_VIEW &&
        options &&
        sortedExperiences &&
        sortedExperiences[0]?.description ? (
          <div
            style={{
              backgroundColor: colors.white,
              padding: '8px',
            }}
          >
            <InnerTextAccordion
              text={sortedExperiences[0]?.description}
              fontSize="13px"
            />
          </div>
        ) : null}
      </div>
      {/* Profile work experience - 1st is experience in saved company */}
      {type === InsightType.PROFILE_VIEW && expanded ? (
        options && sortedExperiences && sortedExperiences.length > 1 ? (
          sortedExperiences
            .slice(1)
            ?.map((experience: LinkedinWorkExperience, i: number) => {
              return (
                <div
                  className={`insights-inner-top-header ${experience.timePeriod?.endDate ? 'greyed' : ''}`}
                  key={i}
                >
                  <div className="insights-icon-position-container">
                    {renderCronoCheckbox &&
                    selectedArray !== undefined &&
                    setSelectedArray !== undefined ? (
                      <CronoCheckbox
                        checked={selectedArray.includes(i + 1)}
                        onClick={() =>
                          setSelectedArray(
                            selectedArray.includes(i + 1)
                              ? selectedArray.filter((index) => index !== i + 1)
                              : [...selectedArray, i + 1],
                          )
                        }
                      />
                    ) : (
                      <span className={`insight-icon-wrapper ${type}-ICON`}>
                        {icon}
                      </span>
                    )}
                    {type === InsightType.PROFILE_VIEW ? (
                      <div className="insight-position-experience">
                        <Typography fontSize={'12px'} fontWeight={500} noWrap>
                          {experience.position}
                        </Typography>
                        <Typography
                          fontSize={'12px'}
                          color={colors.grey11}
                          noWrap
                        >
                          {experience.company.name} -{' '}
                          {experience.timePeriod?.years
                            ? `${experience.timePeriod?.years} ye`
                            : null}
                          {experience.timePeriod?.months
                            ? ` ${experience.timePeriod?.months} mo`
                            : null}{' '}
                          -{' '}
                          {!experience.timePeriod?.endDate
                            ? 'Present'
                            : `${Constants.monthsDictionary[experience.timePeriod?.endDate.month]} ${experience.timePeriod?.endDate.year} `}
                        </Typography>
                      </div>
                    ) : (
                      type
                    )}
                  </div>
                  {type === InsightType.PROFILE_VIEW &&
                  experience.description ? (
                    <div
                      style={{
                        backgroundColor: colors.white,
                        padding: '8px',
                      }}
                    >
                      <InnerTextAccordion
                        text={experience.description}
                        fontSize="13px"
                      />
                    </div>
                  ) : null}
                </div>
              );
            })
        ) : (
          <div className="empty-state">
            <Typography fontSize={'12px'} color={colors.grey11}>
              No experience available
            </Typography>
          </div>
        )
      ) : null}
      {/* Social presence */}
      {expanded ? (
        fetchOptionsLoading && type === InsightType.SOCIAL_PRESENCE ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : type === InsightType.SOCIAL_PRESENCE ? (
          options && options.length ? (
            <div
              id="insight-social-presence-body"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 4px',
                gap: '8px',
                width: '100%',
              }}
            >
              {options
                ?.sort((a: LinkedinActivity, b: LinkedinActivity) =>
                  moment(b.calculatedDate).diff(a.calculatedDate),
                )
                .map((activity: LinkedinActivity, i: number) => {
                  return (
                    <div
                      key={activity.calculatedDate + '-' + i}
                      className="insight-social-activity-container"
                    >
                      {selectedArray !== undefined &&
                      setSelectedArray !== undefined ? (
                        <CronoCheckbox
                          checked={selectedArray.includes(i)}
                          onClick={() =>
                            setSelectedArray(
                              selectedArray.includes(i)
                                ? selectedArray.filter((index) => index !== i)
                                : [...selectedArray, i],
                            )
                          }
                          sx={{
                            height: 'fit-content',
                          }}
                        />
                      ) : null}
                      <div className={'insight-social-activity'}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ display: 'flex', gap: '4px' }}>
                            <Typography
                              className={
                                activity.type === 'comment'
                                  ? 'linkedin-comment'
                                  : 'linkedin-post'
                              }
                            >
                              {activity.type.charAt(0).toUpperCase() +
                                activity.type.slice(1)}
                            </Typography>
                            <Typography fontSize={'11px'} color={colors.grey11}>
                              -{' '}
                              {activity.type === 'comment'
                                ? 'commented'
                                : 'posted'}{' '}
                              {activity.date} ago
                            </Typography>
                          </div>
                          {activity.url && (
                            <span
                              className="redirect-icon-wrapper activity-url"
                              onClick={() =>
                                window.open(activity.url, '_blank')
                              }
                            >
                              <RedirectIcon width="16px" height="16px" />
                            </span>
                          )}
                        </div>
                        <InnerTextAccordion text={activity?.content} />
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="empty-state">
              <Typography fontSize={'12px'} color={colors.grey11}>
                No social presence available
              </Typography>
            </div>
          )
        ) : null
      ) : null}
      {/* Key topics */}
      {expanded ? (
        fetchOptionsLoading && type === InsightType.KEY_TOPICS ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : type === InsightType.KEY_TOPICS ? (
          options && options?.length ? (
            <div
              id="insight-key-topics-body"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 8px 8px 8px',
                gap: '8px',
                width: '100%',
              }}
            >
              <Typography fontSize={'12px'} color={colors.grey11}>
                This contact usually talks about:{' '}
                <span style={{ color: colors.black }}>
                  {options?.join(', ')}
                </span>
              </Typography>
            </div>
          ) : (
            <div className="empty-state" style={{ marginBottom: '8px' }}>
              <Typography fontSize={'12px'} color={colors.grey11}>
                No key topics available
              </Typography>
            </div>
          )
        ) : null
      ) : null}
      {/* Job openings */}
      {expanded ? (
        fetchOptionsLoading && type === InsightType.JOB_OPENINGS ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : type === InsightType.JOB_OPENINGS ? (
          options && options.jobs && options.jobs.length ? (
            <div
              id="insight-job-openings-body"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 4px',
                gap: '8px',
                width: '100%',
              }}
            >
              {options.jobs
                ?.sort((a: LinkedinJobPost, b: LinkedinJobPost) =>
                  moment(b.date ?? Date.now()).diff(a.date ?? Date.now()),
                )
                ?.slice(0, 5)
                ?.map((job: LinkedinJobPost, i: number) => {
                  let jobPostDate = 'No date information available';
                  if (job.date) {
                    jobPostDate = getDateDiff(job.date);
                  }
                  return (
                    <div
                      key={job.date + '-' + i}
                      className="insight-linkedin-activity"
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      {selectedArray !== undefined &&
                      setSelectedArray !== undefined ? (
                        <CronoCheckbox
                          checked={selectedArray.includes(i)}
                          onClick={() =>
                            setSelectedArray(
                              selectedArray.includes(i)
                                ? selectedArray.filter((index) => index !== i)
                                : [...selectedArray, i],
                            )
                          }
                          sx={{
                            height: 'fit-content',
                          }}
                        />
                      ) : null}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography fontSize={'11px'} color={colors.grey11}>
                            {jobPostDate ?? 'No date information available'}
                          </Typography>
                          {job.jobUrl && (
                            <span
                              className="redirect-icon-wrapper activity-url"
                              onClick={() => window.open(job.jobUrl, '_blank')}
                            >
                              <RedirectIcon width="16px" height="16px" />
                            </span>
                          )}
                        </div>
                        <Typography fontSize={'12px'} fontWeight={500}>
                          {job.title}
                        </Typography>
                        <Typography fontSize={'12px'} color={colors.grey11}>
                          {job.location}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="empty-state">
              <Typography fontSize={'12px'} color={colors.grey11}>
                No jobs openings available
              </Typography>
            </div>
          )
        ) : null
      ) : null}
      {/* Company News */}
      {expanded ? (
        fetchOptionsLoading && type === InsightType.COMPANY_NEWS ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : type === InsightType.COMPANY_NEWS ? (
          options &&
          options?.activities &&
          options?.activities?.activities &&
          options?.activities?.activities?.length ? (
            <div
              id="insight-company-news-body"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 4px',
                gap: '8px',
                width: '100%',
              }}
            >
              {options?.activities?.activities
                ?.sort((a: LinkedinActivity, b: LinkedinActivity) =>
                  moment(b.calculatedDate).diff(a.calculatedDate),
                )
                ?.slice(0, 5)
                .map((activity: LinkedinActivity, i: number) => {
                  return (
                    <div
                      key={activity.calculatedDate + '-' + i}
                      className="insight-linkedin-activity"
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      {selectedArray !== undefined &&
                      setSelectedArray !== undefined ? (
                        <CronoCheckbox
                          checked={selectedArray.includes(i)}
                          onClick={() =>
                            setSelectedArray(
                              selectedArray.includes(i)
                                ? selectedArray.filter((index) => index !== i)
                                : [...selectedArray, i],
                            )
                          }
                          sx={{
                            height: 'fit-content',
                          }}
                        />
                      ) : null}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ display: 'flex', gap: '4px' }}>
                            <Typography
                              className={
                                activity.type === 'comment'
                                  ? 'linkedin-job-comment'
                                  : 'linkedin-job-post'
                              }
                            >
                              {activity.type.charAt(0).toUpperCase() +
                                activity.type.slice(1)}
                            </Typography>
                            <Typography fontSize={'11px'} color={colors.grey11}>
                              -{' '}
                              {activity.type === 'comment'
                                ? 'commented'
                                : 'posted'}{' '}
                              {activity.date} ago
                            </Typography>
                          </div>
                          {activity.url && (
                            <span
                              className="redirect-icon-wrapper activity-url"
                              onClick={() =>
                                window.open(activity.url, '_blank')
                              }
                            >
                              <RedirectIcon width="16px" height="16px" />
                            </span>
                          )}
                        </div>
                        <InnerTextAccordion text={activity?.content} />
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="empty-state">
              <Typography fontSize={'12px'} color={colors.grey11}>
                No news available
              </Typography>
            </div>
          )
        ) : null
      ) : null}
      {/* Funding Rounds */}
      {expanded ? (
        fetchOptionsLoading && type === InsightType.FUNDING_ROUNDS ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : type === InsightType.FUNDING_ROUNDS ? (
          options && options.amount && options.announcedDate ? (
            <div
              id="insight-company-news-body"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 8px',
                gap: '8px',
                width: '100%',
                paddingBottom:
                  type === InsightType.FUNDING_ROUNDS &&
                  expanded &&
                  !options?.crunchbaseUrl &&
                  !prospect?.account?.linkedin
                    ? '8px'
                    : '0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography fontSize={'16px'} fontWeight={500}>
                      {options.amount ?? '0'}
                    </Typography>
                    <Typography
                      fontSize={'11px'}
                      color={colors.fundingRoundsCounter}
                      fontWeight={500}
                      style={{
                        backgroundColor: colors.fundingRounds,
                        borderRadius: '12px',
                        padding: '2px 4px',
                        width: 'fit-content',
                      }}
                    >
                      {options.fundingRound}
                    </Typography>
                  </div>
                </div>
                <Typography fontSize={'11px'} color={colors.grey11}>
                  Last Round: {options.announcedDate}
                </Typography>
              </div>
            </div>
          ) : (
            <div className="empty-state" style={{ marginBottom: '8px' }}>
              <Typography fontSize={'12px'} color={colors.grey11}>
                No funding rounds available
              </Typography>
            </div>
          )
        ) : null
      ) : null}
      {/* Inner footer */}
      {type === InsightType.PROFILE_VIEW ? (
        <div
          className="insights-bottom-footer"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            if (expanded || onlyOneExperience) {
              if (publicId) {
                window.open(
                  Constants.linkedinExperiencesRedirectUrl(publicId),
                  '_blank',
                );
              }
            } else {
              setExpanded((prev) => !prev);
            }
          }}
        >
          {expanded || onlyOneExperience ? (
            <div
              style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                minWidth: 'fit-content',
              }}
            >
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                color={colors.darkGreen}
              >
                Go to Experience{' '}
              </Typography>
              <span className="redirect-icon-wrapper">
                <RedirectIcon width="16px" height="16px" />
              </span>
            </div>
          ) : (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                color={colors.primaryDark}
              >
                Show more experience (
                {options?.totalExperiences ??
                  options?.workExperience.length ??
                  0}
                )
              </Typography>
            </div>
          )}
        </div>
      ) : type === InsightType.SOCIAL_PRESENCE && expanded ? (
        <div
          className="insights-bottom-footer top-border"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            if (publicId) {
              window.open(
                options?.url ?? Constants.linkedinActivityRedirectUrl(publicId),
                '_blank',
              );
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              minWidth: 'fit-content',
            }}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={500}
              color={colors.darkGreen}
            >
              Go to Activity
            </Typography>
            <span className="redirect-icon-wrapper">
              <RedirectIcon width="16px" height="16px" />
            </span>
          </div>
        </div>
      ) : type === InsightType.JOB_OPENINGS && expanded ? (
        <div
          className="insights-bottom-footer top-border"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            if (prospect?.account?.linkedin) {
              window.open(prospect?.account?.linkedin + '/jobs', '_blank');
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              minWidth: 'fit-content',
            }}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={500}
              color={colors.darkGreen}
            >
              Go to Jobs {options?.total > 0 ? `(${options?.total})` : null}
            </Typography>
            <span className="redirect-icon-wrapper">
              <RedirectIcon width="16px" height="16px" />
            </span>
          </div>
        </div>
      ) : type === InsightType.COMPANY_NEWS && expanded ? (
        <div
          className="insights-bottom-footer top-border"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            if (options.url) {
              window.open(options.url + '/posts/?feedView=all', '_blank');
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              minWidth: 'fit-content',
            }}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={500}
              color={colors.darkGreen}
            >
              Go to News
            </Typography>
            <span className="redirect-icon-wrapper">
              <RedirectIcon width="16px" height="16px" />
            </span>
          </div>
        </div>
      ) : type === InsightType.FUNDING_ROUNDS && expanded ? (
        options?.crunchbaseUrl || prospect?.account?.linkedin ? (
          <div
            className="insights-bottom-footer top-border"
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              if (options.crunchbaseUrl || prospect?.account?.linkedin) {
                window.open(
                  options.crunchbaseUrl ?? prospect?.account?.linkedin,
                  '_blank',
                );
              }
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                minWidth: 'fit-content',
              }}
            >
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                color={colors.darkGreen}
              >
                Go to Crunchbase
              </Typography>
              <span className="redirect-icon-wrapper">
                <RedirectIcon width="16px" height="16px" />
              </span>
            </div>
          </div>
        ) : null
      ) : null}
    </InsightsAccordionWrapper>
  );
};

export default InsightsAccordion;
