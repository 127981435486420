import React from 'react';
import IProps from './interface';

const IconPlay = ({ color, className, style }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <g clipPath="url(#clip0_17748_102940)">
        <path
          d="M4.96384 4.14444C4.96384 3.55434 5.61382 3.19538 6.11327 3.50965L12.2412 7.36551C12.7086 7.65963 12.7086 8.34097 12.2412 8.63509L6.11327 12.4909C5.61382 12.8052 4.96384 12.4463 4.96384 11.8562L4.96384 4.14444Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_17748_102940">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPlay;
