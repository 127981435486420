import React, { useEffect, useState } from 'react';
import { SuggestionCardWrapper } from './suggestionStyle';

import { Avatar, Badge, Typography } from '@mui/material';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { Suggestion } from 'crono-fe-common/types/suggestion';
import { consultDictionary } from './suggestionDictionary';
import { colors } from 'crono-fe-common/theme';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import moment from 'moment';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';
import { MainPrimarySmallButton } from 'crono-fe-common/components/CronoButton';
import { FeConstants } from 'constants/FeConstants';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { useConditionalSnackBar } from 'context/snackbar';
import usePatchSuggestion from 'hooks/services/suggestion/usePatchSuggestion';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { TabValue } from 'pages/home';

interface IProps {
  suggestion: Suggestion;
}

const GenerateLeadsCard = ({ suggestion }: IProps) => {
  const template = consultDictionary(suggestion.type);
  const { setSelectedAccountId } = useSelectedAccount();
  const { openWindow } = useWindow();

  const handleClickNameAccount = (accountId: string) => {
    setSelectedAccountId(accountId);
    openWindow({
      windowType: 'account',
      tab: 'account',
    });
  };

  const [badgeVisible, setBadgeVisible] = useState<boolean>(false);

  useEffect(() => {
    const currentTime = new Date();
    // Define the time limit for the last 4 hours
    const timeLimit = new Date(
      currentTime.getTime() - FeConstants.notificationTimeSpan,
    ); // 4 hours in milliseconds
    setBadgeVisible(new Date(suggestion.createdDate) > timeLimit);
  }, []);

  const {
    mutate: patchSuggestion,
    isSuccess: correctlyPatched,
    variables: actionPerformed,
    error: errorPatchSuggestion,
  } = usePatchSuggestion();

  useConditionalSnackBar([
    {
      condition: !!errorPatchSuggestion,
      message: getError(errorPatchSuggestion) ?? 'Error updating suggestion',
      severity: 'error',
    },
    {
      condition: !!correctlyPatched,
      message: actionPerformed?.skip
        ? 'Suggestion correctly skipped'
        : 'Suggestion correctly performed',
      severity: 'success',
    },
  ]);

  const analytics = useJuneAnalytics();

  const handleSkipSuggestion = () => {
    patchSuggestion({
      suggestionId: suggestion.id,
      skip: true,
    });
    if (analytics) {
      analytics.track('skip-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };

  const navigate = useNavigate();

  const handleFindNewLeads = () => {
    if (!suggestion?.account) return;
    navigate(PATH.HOME, {
      state: {
        initialTab: TabValue.FIND_NEW,
        initialTabFindNew: 'contact',
        initialCompanyFindNew: suggestion?.account ?? undefined,
      },
    });
    if (analytics) {
      analytics.track('execute-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };

  return (
    <SuggestionCardWrapper>
      <Badge
        variant="dot"
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        invisible={!badgeVisible}
        color="primary"
        overlap="circular"
        className="badge-suggestion"
      >
        <Avatar className="search-avatar">
          <SearchIcon
            className="search-avatar-icon"
            color={colors.callScheduledHover}
          />
        </Avatar>
      </Badge>
      <FlexDiv direction="column" alignItems="start">
        <div className="top-header-suggestion-container">
          <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
            {template?.title({
              accountName: (
                <b
                  className="name-link"
                  onClick={() => {
                    return handleClickNameAccount(suggestion.accountId);
                  }}
                >
                  {suggestion.account?.name ?? ''}
                </b>
              ),
            })}
          </Typography>
          <span className="skip-suggestion-x" onClick={handleSkipSuggestion}>
            <CloseMIcon color={colors.grey2} />
          </span>
        </div>
        <Typography fontSize={14} fontWeight={500}>
          {template?.description({
            accountName: (
              <b
                className="name-link"
                onClick={() => {
                  return handleClickNameAccount(suggestion.accountId);
                }}
              >
                {suggestion.account?.name ?? ''}
              </b>
            ),
          })}
        </Typography>
        <MainPrimarySmallButton
          style={{ marginTop: 12, marginBottom: 4 }}
          onClick={handleFindNewLeads}
        >
          Find new
        </MainPrimarySmallButton>
        <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
          {moment(suggestion.createdDate).format('ll')}
        </Typography>
      </FlexDiv>
    </SuggestionCardWrapper>
  );
};

export default GenerateLeadsCard;
