import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { CreateUserDTO } from 'crono-fe-common/types/DTO/createUserDTO';

export default function useCreateUserMultiCustomerSubscription() {
  const queryClient = useQueryClient();

  const createUser = (createUserBody: CreateUserDTO) => {
    const request: Request = {
      url: ENDPOINTS.subscription.multicustomer.invite.manager,
      config: {
        method: 'post',
        data: { data: createUserBody },
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    CreateUserDTO
  >(createUser, {
    onSettled: (result, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.subscription.users.main]);
      queryClient.invalidateQueries([ENDPOINTS.subscription.users.manager]);
      queryClient.invalidateQueries([ENDPOINTS.subscription.manager]);
    },
  });

  return { isSuccess, isError, mutate, ...rest };
}
