import { FC, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { CircularProgress } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useConditionalSnackBar } from 'context/snackbar';
import PATH from 'routing/path';
import useHubspotAuthCallback from 'hooks/services/hubspot/useHubspotAuthCallback';
import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';
import { SettingsTabs } from 'pages/settings';

const HubspotLoginHandler: FC = () => {
  const { user } = useAuth();

  // const analytics = useJuneAnalytics();

  const location = useLocation();
  const { isLoading, isSuccess, error, hubspotAuthCallback } =
    useHubspotAuthCallback();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    const parsedSearch = queryString.parse(location.search);
    hubspotAuthCallback(parsedSearch as any);
  }, [user]);

  useConditionalSnackBar([
    {
      condition: !!error,
      message: error?.message || 'Error while connecting with the CRM',
      severity: 'error',
    },
    {
      condition: !!isSuccess,
      message: 'Succesfully logged in in hubspot',
      severity: 'success',
    },
  ]);

  useLayoutEffect(() => {
    if (isSuccess) {
      navigate(PATH.SETTINGS, {
        state: { tab: SettingsTabs.Integrations },
      });
      //Currently sent by the backend
      // if (!user?.integrationType) {
      //   if (analytics) {
      //     analytics.track("crm-integration", {
      //       crm: "hubspot"
      //     });
      //   }
      // }
    }
  }, [isSuccess, user]);

  if (isLoading) {
    return (
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    );
  }

  return <></>;
};

export default HubspotLoginHandler;
