import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ManualTaskSequenceStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;

  .error-badge-task {
    height: 24px;
    background: ${colors.inactiveLight};
    border-radius: 12px;
    padding: 4px 8px 4px 4px;
    gap: 4px;
    display: flex;
    align-items: center;
  }

  .step-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .step-number-container {
    min-width: 50px;
    display: flex;
    justify-content: center;
    margin-right: 30px;
  }
  .step-content {
    display: flex;
    column-gap: 16px;
    border: 1px solid ${colors.primary};
    border-radius: 8px;
    padding: 8px 20px;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  }
  .divider {
    border: 1px solid ${colors.grey4Hover};
    height: 30px;
    margin: 0;
  }
  .step-type-option-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
    width: 100%;
    padding-right: 7px;
  }
  .step-type-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  .step-type-option-dropdown {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.grey4Hover};
    border-radius: 8px;
  }
  .step-type-option-dropdown:hover {
    background-color: ${colors.grey4};
  }
  .type-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }
  .task-component-button {
    cursor: pointer;
  }
  .task-component-button-disabled {
    cursor: not-allowed;
  }
  .calendar-icon > path {
    stroke: ${colors.grey11};
  }
`;
