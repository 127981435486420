import { IconButton, Typography, CircularProgress } from '@mui/material';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { isSameDay } from 'date-fns';
import moment from 'moment';
import { ScheduleWrapper } from 'pages/accountTab/emailView/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as CalendarIcon } from 'crono-fe-common/icons/Calendar.svg';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import CronoTimePicker from 'crono-fe-common/components/CronoTimePicker';
import { roundDateToMinutes } from 'crono-fe-common/utils';

interface IProps {
  close: () => void;
  isRescheduleLoading: boolean;
  handleChangeDate: (newDate: Date) => void;
  initialDate?: Date;
  taskType?: TaskTodoType;
}

const RescheduleCalendar = ({
  close,
  isRescheduleLoading,
  handleChangeDate,
  initialDate,
  taskType,
}: IProps) => {
  const [scheduleDate, setScheduleDate] = useState<Date | null>(
    initialDate ? new Date(initialDate) : new Date(),
  );
  const handleRescheduleTask = () => {
    if (scheduleDate) {
      const date = roundDateToMinutes(scheduleDate);
      handleChangeDate(date);
    }
  };
  const minTime = useMemo(() => {
    if (scheduleDate && isSameDay(scheduleDate, new Date())) {
      return new Date();
    } else {
      return undefined;
    }
  }, [scheduleDate]);

  return (
    <BlurBackgroundDivFixed style={{ zIndex: 10002 }}>
      <ScheduleWrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <IconButton style={{ alignSelf: 'flex-end' }} onClick={() => close()}>
            <CloseMIcon color={colors.grey11} />
          </IconButton>
          <Typography fontSize={24} fontWeight={700}>
            Select date and time
          </Typography>
          <FlexDiv height="fit-content" alignItems="start">
            <FlexDiv
              width="fit-content"
              height="fit-content"
              style={{ marginTop: 20, rowGap: 12 }}
              direction="column"
            >
              <FlexDiv justifyContent="start">
                <CalendarIcon />
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  style={{ marginLeft: 4 }}
                >
                  {moment(scheduleDate).format('DD/MM/YYYY')}
                </Typography>
              </FlexDiv>
              {taskType !== TaskTodoType.LINKEDIN ? (
                <FlexDiv justifyContent="start">
                  <CronoTimePicker
                    value={scheduleDate}
                    disabled={isRescheduleLoading}
                    minTime={minTime}
                    handleChange={setScheduleDate}
                    className="align-left"
                  />
                </FlexDiv>
              ) : null}
            </FlexDiv>
            <DateCalendar
              value={scheduleDate}
              onChange={(value) => {
                setScheduleDate(value as Date);
              }}
              disablePast
              disabled={isRescheduleLoading}
            />
          </FlexDiv>

          {!isRescheduleLoading ? (
            <FlexDiv width="fit-content" style={{ alignSelf: 'flex-end' }}>
              <CancelButton className="mail-button" onClick={() => close()}>
                Cancel
              </CancelButton>
              <MainButton
                className="mail-button"
                onClick={() => handleRescheduleTask()}
              >
                Reschedule
              </MainButton>
            </FlexDiv>
          ) : (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          )}
        </LocalizationProvider>
      </ScheduleWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default RescheduleCalendar;
