import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const IcebreakersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 16px;
  border-radius: 16px;
  width: 400px;
  max-height: 500px;
  overflow: hidden;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid ${colors.grey4};
  .close-button {
    cursor: pointer;
  }
  .icebreakers-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    row-gap: 12px;
    margin-bottom: 6px;
  }
  .icebreaker {
    display: flex;
    &:hover {
      background: ${colors.greyHover};
    }
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
  }
  .circle {
    border-radius: 999px;
    min-width: 16px;
    min-height: 16px;
    height: 16px;
    width: 16px;
    margin-right: 6px;
    border: 2px solid ${colors.grey3};
  }
  .error-message-icebreakers {
    background: ${colors.inactiveLight};
    border-radius: 8px;
    padding: 8px;
    color: ${colors.inactive};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;
