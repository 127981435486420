import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const EmailNotFoundStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  .edit-pencil-icon {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
  .close-icon {
    cursor: pointer;
  }
  .website-name {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 4px;
    color: ${colors.primary};
    cursor: pointer;
    &:hover {
      color: ${colors.primaryDark};
      > svg > path {
        stroke: ${colors.primaryDark};
      }
    }
  }
`;
