import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { colors } from 'crono-fe-common/theme';

import CompanyInsertCard from './companyInsertCard';
import { useJuneAnalytics } from 'context/june';
import InsertCompany from './insertCompany';
import InsertMultipleCompanies from './insertMultipleCompanies';
import {
  useEnqueueAsyncEnrichJob,
  useLinkedinSearchCompanies,
  useLinkedinSearchSalesNavCompanies,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import {
  LinkedinCompany,
  SalesNavCompany,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { rowPlaceholderFillOpacities } from 'constants/FeConstants';
import { ReactComponent as NoResults } from 'assets/images/No_results_companies_search.svg';
import { ReactComponent as NoFiltersApplied } from 'assets/images/No_filters_applied.svg';
import { ReactComponent as DisabledExtensionPlaceholder } from 'assets/images/Disabled_Extension_Placeholder.svg';
import RowResultPlaceHolder from 'crono-fe-common/icons/Row-result-placeholder';
import {
  CronoExtensionStatus,
  useGetExtensionStatus,
} from 'hooks/services/crono-extension/useGetExtensionStatus';
import { useSearchCompanyTab } from './searchCompanyTabContext';
import SearchCompanyTabFilters from './searchCompanyTabFilters';
import { useAuth } from 'context/auth';
import {
  CompanyForSearchTable,
  getCompanySearchHeadCells,
  mixCompanyWithDatabaseSaved,
} from './model';
import { Constants } from 'crono-fe-common/constants/constants';
import CustomTableHeadSearchContactTab from '../tableHeadSearchContactTab';
import Pagination from 'pages/dashboard/pagination';
import SearchCompanySelectionBottom from './searchCompanySelectionBottom';
import useAccountLinkedinMultiple from 'hooks/services/account/useAccountLinkedinMultiple';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import { useFindNewTabContext } from 'pages/home/context/findNewContext';
import { ReactComponent as ReloadIcon } from 'crono-fe-common/icons/Reload.svg';
import useSearchCompanyScrape from 'hooks/services/companyScrape/useGetCompanyScrape';
import EditColumnsModal, {
  defaultColumnsCompaniesLinkedin,
  defaultColumnsCompaniesSalesNav,
  EditColumnsType,
} from '../editColumnsModal';
import { useUpgradeModal } from 'context/upgradeModal';
import { useQueryClient } from 'react-query';
import { AsyncEnrichJob } from 'crono-fe-common/types/crono-extension/background-script';

type LoadCompaniesType =
  | {
      fromSalesNav: true;
      companies: SalesNavCompany[];
    }
  | {
      fromSalesNav: false;
      companies: LinkedinCompany[];
    };

interface IProps {
  filtersColumnWrapper: (filters: JSX.Element) => JSX.Element;
}

const CompanySearchTab = ({ filtersColumnWrapper }: IProps) => {
  const analytics = useJuneAnalytics();
  const { user, hasSalesNavWithCookie } = useAuth();
  const LIMIT_FIND_NEW = hasSalesNavWithCookie ? 25 : 10;

  const { call: searchCompanies, result: searchCompaniesResultDb } =
    useLinkedinSearchCompanies();

  const {
    companiesListPersistent,
    setCompaniesListPersistent,
    countCompaniesListPersistent,
    setCountCompaniesListPersistent,
    totalCompaniesListPersistent,
    setTotalCompaniesListPersistent,
    initialPageToLoadCompaniesListPersistent,
    setInitialPageToLoadCompaniesListPersistent,
    nextPageToLoadCompaniesListPersistent,
    setNextPageToLoadCompaniesListPersistent,
    filtersUserCompaniesListPersistent,
    setFiltersUserCompaniesListPersistent,
  } = useFindNewTabContext();

  const {
    call: searchCompaniesSalesNav,
    result: searchCompaniesSalesNavResult,
    error: searchCompaniesSalesNavError,
  } = useLinkedinSearchSalesNavCompanies();

  const queryClient = useQueryClient();
  useEffect(() => {
    if (searchCompaniesSalesNavError === 'SalesNav cookie missing or expired') {
      queryClient.invalidateQueries('getExtensionStatus');
    }
  }, [searchCompaniesSalesNavError, queryClient]);

  const count =
    (hasSalesNavWithCookie
      ? searchCompaniesSalesNavResult?.paging.count
      : searchCompaniesResultDb?.paging.count) ??
    countCompaniesListPersistent ??
    undefined;
  const total =
    (hasSalesNavWithCookie
      ? searchCompaniesSalesNavResult?.paging.total
      : searchCompaniesResultDb?.paging.total) ??
    totalCompaniesListPersistent ??
    undefined;

  const [companiesList, setCompaniesList] = useState<CompanyForSearchTable[]>(
    [],
  );

  //When we apply filters we save them here to use them correctly when asking for new pages
  const [filtersUsed, setFilterUsed] = useState<any>(null);

  useEffect(() => {
    //Load the companies from the persistent context when mounting
    if (companiesListPersistent) {
      setCompaniesList(companiesListPersistent);
    }
    if (initialPageToLoadCompaniesListPersistent !== null) {
      setInitialPage(initialPageToLoadCompaniesListPersistent);
    }
    if (nextPageToLoadCompaniesListPersistent !== null) {
      setNextPageToLoad(nextPageToLoadCompaniesListPersistent);
    }
    if (filtersUserCompaniesListPersistent !== null) {
      setFilterUsed(filtersUserCompaniesListPersistent);
    }
  }, []);

  //Save the current state in the persistent context when unmounting
  useEffect(() => {
    return () => {
      setCompaniesListPersistent(companiesList);
      setCountCompaniesListPersistent(count ?? null);
      setTotalCompaniesListPersistent(total ?? null);
      setInitialPageToLoadCompaniesListPersistent(initialPage ?? null);
      setNextPageToLoadCompaniesListPersistent(nextPageToLoad ?? null);
      setFiltersUserCompaniesListPersistent(filtersUsed ?? null);
    };
  }, [companiesList]);

  const { mutateAsync: checkAccountsOnDatabase } = useAccountLinkedinMultiple();

  const { mutateAsync: getCompanyScrape } = useSearchCompanyScrape();

  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);

  const loadCompanies = async (
    { fromSalesNav, companies }: LoadCompaniesType,
    concat = false,
  ) => {
    let companiesMapped: CompanyForSearchTable[] = [];

    if (fromSalesNav) {
      companiesMapped = companies.map((company) => {
        return {
          id: company.numericId,
          numericId: company.numericId,
          description: company.description,
          empolyees: company.employeeDisplayCount,
          name: company.companyName,
          pictureUrl: company.pictureUrl,
          website: null,
          url: '',
          urlForRedirect: Constants.linkedinSalesNavCompanyRedirectUrl(
            company.numericId ?? '',
          ),
          industry: company.industry,
          location: null,
        } as CompanyForSearchTable;
      });
    } else {
      companiesMapped = companies
        .filter((company) => Object.keys(company).length !== 0)
        .map((company) => {
          const [industry, location] = company.primarySubtitle?.split('•') ?? [
            '',
            '',
          ];
          return {
            id:
              company.publicId ??
              LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(company.url)[0],
            numericId: company.numericId,
            description: '',
            empolyees: null,
            name: company.title,
            pictureUrl: company.logoUrl,
            url: company.url,
            urlForRedirect: company.url,
            website: company.website ?? null,
            industry: industry,
            location: location,
          } as CompanyForSearchTable;
        });
    }
    try {
      const similar = await checkAccountsOnDatabase({
        searchParameters: companiesMapped.map((company) => {
          return {
            name: company.name,
            numericId: company.numericId,
            publicId: company.id,
          };
        }),
      });
      //The result is in the same order as the input
      companiesMapped = companiesMapped.map((company, index) => {
        return {
          ...company,
          similarCompanies: similar.data?.data.at(index)?.accounts ?? null,
          correct:
            similar.data?.data.at(index)?.correct ||
            //For the ones from the database we have to check manually if one is correct, since the other parameter does not cover all the cases
            similar.data?.data
              .at(index)
              ?.accounts.findIndex((account) => account.correct) !== -1,
        };
      });

      //Retrieve the additional data for the companies if they are present in the linkedin database

      const companiesFromLinkedinDatabase = await getCompanyScrape(
        companiesMapped.map((company) => ({
          numericId: company.numericId,
          publicId: fromSalesNav ? null : company.id,
        })),
      );
      companiesMapped = companiesMapped.map((company) => {
        const companyFromLinkedinDatabase =
          companiesFromLinkedinDatabase.data?.data.find(
            (companyFromLinkedinDatabase) =>
              companyFromLinkedinDatabase.numericId === company.numericId ||
              (!fromSalesNav &&
                companyFromLinkedinDatabase.publicId === company.id),
          );
        if (!companyFromLinkedinDatabase) {
          return { alreadyInDatabase: false, ...company };
        }
        //If the company already exists in the database we can obtain the additional data
        return mixCompanyWithDatabaseSaved(
          company,
          companyFromLinkedinDatabase,
        );
      });

      if (concat) {
        nonSelectable.current = nonSelectable.current.concat(
          new Array(companiesMapped?.length).fill(false),
        );
        setCompaniesList((prev) => [...prev, ...companiesMapped]);
        setIsLoadingMore(false);
      } else {
        nonSelectable.current = new Array(companiesMapped?.length).fill(false);
        setCompaniesList(companiesMapped);
      }
    } catch (e) {
      /** */
    } finally {
      setLoadingCompanies(false);
    }
  };

  const [selected, setSelected] = useState<string[]>([]);

  //When the selected changes we can compute the number of companies that can be enriched
  const enrichableCompanies = useMemo(() => {
    const toEnrich: CompanyForSearchTable[] = [];
    companiesList.forEach((companyInList) => {
      if (!companyInList.alreadyInDatabase && !companyInList.correct) {
        toEnrich.push(companyInList);
      }
    });
    return toEnrich;
  }, [companiesList]);

  const handleSelectCompany = (selectedId: string) => {
    if (selected.includes(selectedId)) {
      setSelected(selected.filter((id) => id !== selectedId));
    } else {
      setSelected([...selected, selectedId]);
    }
  };

  const handleClickGeneralCheckbox = () => {
    if (companiesList?.length && selected.length === availableToSelectCount) {
      setSelected([]);
    } else {
      setSelected(
        companiesList
          ?.filter(
            (company, index) =>
              company.id !== undefined && !nonSelectable.current.at(index),
          )
          .map((company) => {
            return company.id;
          }) as string[],
      );
    }
  };

  //This is used to know the start page that it is loaded
  const [initialPage, setInitialPage] = useState<number | undefined>(undefined);
  //This gives the next page to load, it is used in the load more results
  const [nextPageToLoad, setNextPageToLoad] = useState<number | undefined>(
    undefined,
  );

  const {
    dtoForSearchCompanies,
    dtoForSearchCompaniesSalesNav,
    filtersChanged,
    setFiltersChanged,
  } = useSearchCompanyTab();
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const maxReached = (total ?? 0) <= (nextPageToLoad ?? 0) * LIMIT_FIND_NEW;

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    handleSearch((nextPageToLoad ?? 0) + 1, true);
  };

  const handleSearch = async (value: number, concat = false) => {
    setNextPageToLoad(value);
    if (!concat) {
      setInitialPage(value);
      setSelected([]);
    }
    setLoadingCompanies(true);
    try {
      // page for linkedin is always the pagination value - 1
      if (hasSalesNavWithCookie) {
        const res = await searchCompaniesSalesNav({
          ...filtersUsed,
          start: (value - 1) * LIMIT_FIND_NEW,
          count: LIMIT_FIND_NEW,
        });
        loadCompanies(
          { fromSalesNav: true, companies: res?.results ?? [] },
          concat,
        );
        if (analytics) {
          analytics.track('search-companies-sales-nav', {});
        }
      } else {
        const res = await searchCompanies({
          keywords: '',
          filters: filtersUsed,
          page: value - 1,
        });
        loadCompanies(
          { fromSalesNav: false, companies: res?.companies ?? [] },
          concat,
        );
        if (analytics) {
          analytics.track('search-companies', {});
        }
      }
    } catch (e) {
      setLoadingCompanies(false);
    }
  };

  const applyFilters = async () => {
    if (filtersChanged) {
      setSelected([]);
      setFiltersChanged(false);
      setLoadingCompanies(true);
      try {
        if (hasSalesNavWithCookie) {
          setFilterUsed(dtoForSearchCompaniesSalesNav);
          const res = await searchCompaniesSalesNav({
            ...dtoForSearchCompaniesSalesNav,
            start: 0,
            count: LIMIT_FIND_NEW,
          });
          loadCompanies({ fromSalesNav: true, companies: res?.results ?? [] });
          if (analytics) {
            analytics.track('search-companies-sales-nav', {});
          }
        } else {
          setFilterUsed(dtoForSearchCompanies);
          // linkdin search first page to fetch leads is 0, setPage sets only pagination selected page (first is 1)
          const res = await searchCompanies({
            keywords: '',
            filters: dtoForSearchCompanies,
            page: 0,
          });
          loadCompanies({
            fromSalesNav: false,
            companies: res?.companies ?? [],
          });
          if (analytics) {
            analytics.track('search-companies', {});
          }
        }
        setInitialPage(1);
        setNextPageToLoad(1);
      } catch (e) {
        setLoadingCompanies(false);
      }
    }
  };

  const [selectedCompanyToInsert, setSelectedCompanyToInsert] =
    useState<CompanyForSearchTable | null>(null);

  const closeInsertCompany = () => {
    setSelectedCompanyToInsert(null);
  };

  function handleAddClick(company: CompanyForSearchTable) {
    setSelectedCompanyToInsert(company);
  }

  const [showAddMultiple, setShowAddMultiple] = useState<boolean>(false);

  const [selectedUserIdToAddTo, setSelectedUserIdToAddTo] = useState<
    number | null
  >(null);

  const handleClickAddMultiple = (selectedUserId: number | null) => {
    setSelectedUserIdToAddTo(selectedUserId);
    setShowAddMultiple(true);
  };

  const [availableToSelectCount, setAvailableToSelectCount] =
    useState<number>(LIMIT_FIND_NEW);

  const nonSelectable = useRef<boolean[]>(
    new Array(companiesList.length).fill(false),
  );

  const setIsCompanySelectable = (index: number, value: boolean) => {
    //The method is called "is selectable" but it actually sets the company as "not selectable"
    const actualValue = !value;
    nonSelectable.current[index] = actualValue;
    //In order to show the number of selectable companies
    if (count && companiesList) {
      setAvailableToSelectCount(
        companiesList.length -
          nonSelectable.current.filter((value) => value).length,
      );
    }
  };

  useEffect(() => {
    if (total && count) {
      setAvailableToSelectCount(
        companiesList.length -
          nonSelectable.current.filter((value) => value).length,
      );
    }
  }, [companiesList]);

  const rowPlaceholder = rowPlaceholderFillOpacities.map((el, index) => {
    return (
      <div key={index}>
        <RowResultPlaceHolder fillOpacity={el} />
      </div>
    );
  });

  const noFiltersApplied = (
    <FlexDiv style={{ position: 'relative' }}>
      <div className="no-results">
        {rowPlaceholder}
        <div className="no-results-message">
          <NoFiltersApplied />
          <Typography fontSize={16} fontWeight={600}>
            Apply at least one filter to get results
          </Typography>
          <Typography fontSize={14} color={colors.grey11} width={'410px'}>
            Add one or more of the filters and click the “Apply filters”
          </Typography>
          <Typography fontSize={14} color={colors.grey11} width={'410px'}>
            button to search for contacts or companies
          </Typography>
        </div>
      </div>
    </FlexDiv>
  );

  const { data: status } = useGetExtensionStatus();
  const isDisabledExtension = useMemo(
    () =>
      status?.status === CronoExtensionStatus.NOT_INSTALLED ||
      searchCompaniesSalesNavError === 'SalesNav cookie missing or expired',
    [searchCompaniesSalesNavError, status],
  );

  const disabledExtensionPlaceholder = (
    <FlexDiv style={{ position: 'relative' }}>
      <div className="no-results">
        {rowPlaceholder}
        <div className="no-results-message">
          <DisabledExtensionPlaceholder />
          <Typography fontSize={16} fontWeight={600}>
            Crono’s Chrome extension currently not enabled
          </Typography>
          <Typography fontSize={14} color={colors.grey11} width={'410px'}>
            Please make sure your Crono’s extension is enabled and
          </Typography>
          <Typography fontSize={14} color={colors.grey11} width={'410px'}>
            is working correctly to find companies
          </Typography>
        </div>
      </div>
    </FlexDiv>
  );

  const noCompaniesResults = (
    <FlexDiv style={{ position: 'relative' }}>
      <div className="no-results">
        {rowPlaceholder}
        <div className="no-results-message">
          <NoResults />
          <Typography fontSize={16} fontWeight={600} width={'310px'}>
            Sorry, we didn't find any results Please check the filters and try
            again
          </Typography>
        </div>
      </div>
    </FlexDiv>
  );

  const handleSetInCrono = (id: string, newCompany: AccountLinkedin | null) => {
    //The id passed is a company that is now in Crono
    const company = companiesList.find((company) => company.id === id);
    if (!company) return;
    company.correct = true;
    if (newCompany) {
      company.similarCompanies = [newCompany];
    }
    //Remove the selection if it was selected
    if (selected.includes(id)) {
      setSelected(selected.filter((value) => value !== id));
    }
    const index = companiesList.findIndex((company) => company.id === id);
    setIsCompanySelectable(index, false);
    setCompaniesList([...companiesList]);
  };

  const handleChangeSingleCompany = useCallback(
    (company: CompanyForSearchTable) => {
      setCompaniesList((prev) => {
        const index = prev.findIndex((c) => c.id === company.id);
        const updatedCompanies = [...prev];
        if (index >= 0) {
          updatedCompanies[index] = company;
        }
        return updatedCompanies;
      });
    },
    [companiesList, setCompaniesList],
  );

  const [showEditColumns, setShowEditColumns] = useState<boolean>(false);

  const headCells = useMemo(() => {
    return getCompanySearchHeadCells({
      hasSalesNav: hasSalesNavWithCookie ?? false,
      triggerEditColumnsPopup: () => setShowEditColumns((prev) => !prev),
      userPreferencesColumns:
        user?.userPreferences.findNewAccountColumns ??
        (hasSalesNavWithCookie
          ? defaultColumnsCompaniesSalesNav
          : defaultColumnsCompaniesLinkedin),
    });
  }, [
    getCompanySearchHeadCells,
    setShowEditColumns,
    hasSalesNavWithCookie,
    user?.userPreferences.findNewAccountColumns,
  ]);

  const [alreadyEnriching, setAlreadyEnriching] = useState<boolean>(false);

  const { isUpgradeModalVisible } = useUpgradeModal();

  const { call: enqueueEnrichJob } = useEnqueueAsyncEnrichJob();

  const handleEnrichMultiple = async () => {
    const toEnrich: Omit<AsyncEnrichJob, 'state' | 'id'>[] =
      enrichableCompanies.map((company) => ({
        identifiers: {
          companyNumericId: company.numericId,
          companyPublicId: LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(
            company.url,
          )[0],
          publicId: null,
          leadId: null,
          url: null,
          findCurrentRole: false,
        },
        source: 'crono-fe',
      }));
    try {
      await enqueueEnrichJob(toEnrich);
      if (analytics) {
        analytics.track('linkedin-enrich-multiple-companies', {
          count: enrichableCompanies.length,
        });
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  return (
    <div className="search-component-body">
      {!isUpgradeModalVisible && selectedCompanyToInsert && (
        <InsertCompany
          selectedCompany={selectedCompanyToInsert}
          close={closeInsertCompany}
          handleSetInCrono={handleSetInCrono}
        />
      )}
      {showAddMultiple && companiesList && (
        <InsertMultipleCompanies
          close={() => setShowAddMultiple(false)}
          companies={companiesList.filter(
            (company) => company.id && selected.includes(company.id),
          )}
          onSubmit={() => {
            setShowAddMultiple(false);
            setSelected([]);
          }}
          selectedUserIdToAddTo={selectedUserIdToAddTo}
        />
      )}
      {filtersColumnWrapper(
        <SearchCompanyTabFilters
          applyFilters={applyFilters}
          isDisabledExtension={isDisabledExtension}
        />,
      )}

      <div className="search-results-section">
        {/* <div className="search-results-header">
          {searchCompaniesResult?.companies?.length &&
          searchCompaniesResult.companies.length -
            alreadyInCrono.current.filter((value) => value).length >
            0 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', gap: '24px' }}>
                <label style={{ display: 'flex', paddingLeft: '2px' }}>
                  <Checkbox
                    indeterminate={
                      selected.length > 0 &&
                      selected.length <
                        searchCompaniesResult?.companies?.length -
                          alreadyInCrono.current.filter((value) => value).length
                    }
                    checked={
                      selected.length ===
                        searchCompaniesResult?.companies?.length -
                          alreadyInCrono.current.filter((value) => value)
                            .length &&
                      searchCompaniesResult?.companies?.length -
                        alreadyInCrono.current.filter((value) => value).length >
                        0
                    }
                    style={{ borderRadius: 24 }}
                    checkedIcon={<CheckedIcon />}
                    icon={<UncheckedIcon />}
                    indeterminateIcon={<IndeterminateIcon />}
                    onClick={handleClickGeneralCheckbox}
                    sx={{
                      padding: 'inherit',
                      margin: 'inherit',
                      '&:hover': {
                        backgroundColor: 'transparent !important',
                      },
                      '&.Mui-checked': {
                        '&:hover': {
                          opacity: 0.7,
                        },
                      },
                      marginRight: 1,
                      marginLeft: 1,
                    }}
                  />
                </label>
                {selected.length > 0 ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MainPrimarySmallButton
                      startIcon={<PlusIcon color={colors.white} />}
                      onClick={handleClickAddMultiple}
                    >
                      Add {selected.length}
                    </MainPrimarySmallButton>
                    <CancelButton
                      onClick={() => setSelected([])}
                      style={{ height: 40, fontSize: 14 }}
                    >
                      Cancel
                    </CancelButton>
                  </div>
                ) : (
                  <Typography color={colors.grey11}>
                    Select all ({availableToSelectCount})
                  </Typography>
                )}
              </div>

              <Typography color={colors.grey11}>
                {calculateResultCount(searchCompaniesResult?.paging)}
              </Typography>
            </div>
          ) : null}
        </div> */}

        <TableContainer style={{ flex: 1 }}>
          {loadingCompanies && !isLoadingMore ? (
            <FlexDiv height="100%">
              <CircularProgress />
            </FlexDiv>
          ) : (
            <>
              {isDisabledExtension ? (
                disabledExtensionPlaceholder
              ) : !searchCompaniesResultDb &&
                !searchCompaniesSalesNavResult &&
                !companiesList.length ? (
                noFiltersApplied
              ) : companiesList.length === 0 ? (
                noCompaniesResults
              ) : (
                <>
                  <Table stickyHeader>
                    <CustomTableHeadSearchContactTab
                      headCells={headCells}
                      showSelectAll={true}
                      selectedStatus={
                        selected.length === 0
                          ? 0
                          : selected.length > 0 &&
                              !!availableToSelectCount &&
                              selected.length < availableToSelectCount
                            ? 1
                            : 2
                      }
                      clickSelectAll={handleClickGeneralCheckbox}
                    />

                    <TableBody style={{ width: '100%' }}>
                      {companiesList.map((company, index) => (
                        <CompanyInsertCard
                          key={company.id ?? index}
                          company={company}
                          handleSelectCompany={handleSelectCompany}
                          handleAddClick={handleAddClick}
                          selected={selected}
                          setIsCompanySelectable={(value: boolean) =>
                            setIsCompanySelectable(index, value)
                          }
                          alreadyEnriching={alreadyEnriching}
                          setAlreadyEnriching={setAlreadyEnriching}
                          handleChangeSingleCompany={handleChangeSingleCompany}
                        />
                      ))}
                    </TableBody>
                  </Table>

                  {showEditColumns && (
                    <div className="edit-columns-dropdown">
                      <EditColumnsModal
                        editColumnsType={
                          hasSalesNavWithCookie
                            ? EditColumnsType.SalesCompanies
                            : EditColumnsType.LinkedinCompanies
                        }
                        onClose={() => setShowEditColumns(false)}
                        onApply={() => {
                          setShowEditColumns(false);
                        }}
                      />
                    </div>
                  )}
                  <div className="load-more-results-button">
                    {isLoadingMore ? (
                      <CircularProgress />
                    ) : !maxReached ? (
                      <div className="inner-button" onClick={handleLoadMore}>
                        <ReloadIcon />
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                          color={colors.primaryDark}
                        >
                          Load more results
                        </Typography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </TableContainer>
        {nextPageToLoad && initialPage && companiesList.length !== 0 ? (
          <Pagination
            total={loadingCompanies ? undefined : total}
            count={loadingCompanies ? undefined : count}
            itemOffset={(initialPage - 1) * LIMIT_FIND_NEW ?? 0}
            resultsShown={(nextPageToLoad - initialPage + 1) * LIMIT_FIND_NEW}
            setItemOffset={(offset: number) => {
              handleSearch(offset / LIMIT_FIND_NEW + 1);
            }}
            limit={LIMIT_FIND_NEW}
            selectionComponent={
              <SearchCompanySelectionBottom
                selected={selected}
                areThereToEnrich={enrichableCompanies.length > 0}
                handleEnrichAlll={handleEnrichMultiple}
                clearSelected={() => {
                  setSelected([]);
                }}
                onClickAddMultiple={handleClickAddMultiple}
              />
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default CompanySearchTab;
