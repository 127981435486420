import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const PlanWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;

  .center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    height: 100vh;
    padding-top: 80px;
    padding-bottom: 10px;
    width: 100%;
  }

  .suggestion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  .title {
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-width: 350px;
  }
  .current-plan {
    color: ${colors.intenseBlue} !important;
    background-color: ${colors.intenseBlueChipBackground} !important;
  }

  .card-navigation {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 100%;
  }
  .carousel-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    max-height: 100%;
    justify-content: space-between;
  }
  .plan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    height: 100%;
    width: 100%;
  }
  .plan {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid transparent;
    border-radius: 16px;
    padding: 16px 0px 16px 16px;
    background-color: ${colors.white};
    max-width: 400px;
    height: 100%;
    margin-top: 1px;
    cursor: pointer;
  }
  .plan-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
    padding: 24px;
    gap: 20px;
    height: 135px;
    margin-right: 16px;
  }
  .plan-selected {
    border-color: ${colors.primaryDark};
    background-color: ${colors.primaryLight};
  }
  .plan-header-selected {
    border-color: ${colors.primaryDark};
    background-color: ${colors.primary};
    color: ${colors.white} !important;
  }
  .plan-selected-option:hover {
    border-color: ${colors.primaryDark};

    .plan-hover {
      background-color: ${colors.primaryLight};
    }
  }
  .plan-selected-free-trial-option:hover {
    border-color: ${colors.nurture};
  }
  .plan-selected-free-trial {
    border-color: ${colors.nurture};
  }
  .free-trial {
    background-color: ${colors.freeTrialYellow};
    border: 1px solid transparent;
  }

  .plan-pricing {
    display: flex;
    gap: 20px;
  }
  .plan-billing {
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
    }
  }
  .plan-footer {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 8px;
  }
  .vertical-filler {
    display: flex;
    min-height: 1px;
    height: 100%;
  }
  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 350px;
  }
  .arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 35px;
    cursor: pointer;
    padding: 2px;
    background: ${colors.grey444};
  }
  .arrow-icon:hover {
    background: ${colors.blueLightHover};
  }
  .arrow-disabled {
    visibility: hidden;
  }
  .disable-plan {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .swiper {
    width: 100%;
  }
  .swiper-wrapper {
    box-sizing: border-box;
  }
  .swiper-slide {
    opacity: 0.6;
    min-width: 240px;
    display: flex !important;
    align-items: start !important;
    height: 100%;
    max-height: 655px;
  }
  /* This is to hide the navigation for not active slides. Order is relevant */
  .swiper-slide .card-navigation .navigation-container {
    opacity: 0;
  }
  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide-active .card-navigation .navigation-container {
    opacity: 1;
  }
  .swiper-button-disabled {
    opacity: 0;
  }
`;

export const ChoosePlanContainer = styled.div`
  height: 100%;
  width: 45%;
  background-color: ${colors.grey6};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserPickPlanOptionsWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.grey6};
  display: flex;
  align-items: center;
  justify-content: center;
`;
