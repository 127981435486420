import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

export const stringAvatar = (name: string) => ({
  sx: {
    height: '30px',
    width: '30px',
  },
  children: name
    .split(' ')
    .map((word) => word[0])
    .join(''),
});

export const CustomSuggestionTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'absolute',
    bottom: -17,
    right: 50,
    maxWidth: 350,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));
