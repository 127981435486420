import Logo from 'assets/images/logo_crono.png';
import { colors } from 'crono-fe-common/theme';
import { CancelButton } from 'crono-fe-common/components/CronoButton';
import Lottie from 'lottie-react';
import { useAuth } from 'context/auth';
import { Typography } from '@mui/material';
import { MigrationWrapper } from '../style';
import useGetAsset, { AssetEnum } from 'hooks/services/assets/useGetAsset';

const MigrationComponent = () => {
  const { logout } = useAuth();
  const { data: response } = useGetAsset(AssetEnum.IMPORT_ANIMATION);

  return (
    <MigrationWrapper>
      <div className="header-migration">
        <img src={Logo} alt="Crono" className="logo" />
        <CancelButton
          style={{ height: 40, border: '0px', margin: 0 }}
          onClick={logout}
        >
          Log out
        </CancelButton>
      </div>
      {!!response && (
        <Lottie
          animationData={response.data}
          autoplay={true}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          loop={true}
          style={{
            marginBottom: 60,
            height: 320,
            width: 460,
          }}
        />
      )}
      <Typography fontSize={28} lineHeight={'32px'} fontWeight={700}>
        We are importing your data...
      </Typography>
      <Typography
        fontSize={16}
        lineHeight={'24px'}
        fontWeight={500}
        width={'507px'}
        textAlign={'center'}
        color={colors.grey11}
      >
        This process might take up to several hours. You’ll receive an email
        notification once the import is completed
      </Typography>
    </MigrationWrapper>
  );
};

export default MigrationComponent;
