import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const EmailOpenedBadgeIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      {...style}
    >
      <g id="Group">
        <g id="Group_2">
          <g id="Group 427320577">
            <path
              id="Path"
              d="M0.994141 5.68701V12.9216C0.994141 13.7814 1.69059 14.4779 2.55046 14.4779H13.4439C14.3038 14.4779 15.0002 13.7814 15.0002 12.9216V5.68701"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Path_2"
              d="M2.3638 3.93848L1.6835 4.39613C0.766829 5.01243 0.766829 6.36176 1.68428 6.97884L6.25518 10.0541C7.30881 10.763 8.68615 10.763 9.73978 10.0541L14.3107 6.97884C15.2274 6.36176 15.2274 5.01321 14.3107 4.39613L13.6304 3.93848"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmailOpenedBadgeIcon;
