import { Analytics } from '@june-so/analytics-next';
import { useJune } from 'hooks/useJune';
import { createContext, FC, useContext } from 'react';
import { gatewayService } from 'crono-fe-common/hooks/crono-extension/gateway';
import { JuneContainer } from 'index';

// get from env

const JUNE_KEY = process.env.REACT_APP_JUNE_ID || '';

export const JuneContext = createContext<Analytics | null>(null);

const JuneProvider: FC<{ children: any }> = ({ children }) => {
  const analytics = useJune(JUNE_KEY);
  gatewayService.setJuneAnalytics(analytics);
  JuneContainer.june = analytics;
  return (
    <JuneContext.Provider value={analytics}>{children}</JuneContext.Provider>
  );
};

export default JuneProvider;

export function useJuneAnalytics() {
  const context = useContext(JuneContext);
  if (context === undefined) {
    throw new Error(`useJune must be used within a JuneProvider`);
  }

  return context;
}
