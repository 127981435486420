import { Button } from '@mui/material';
import {
  CancelButton,
  GreyButton,
  MainPrimaryButton,
} from 'crono-fe-common/components/CronoButton';
import React from 'react';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import { TableFooterWrapper } from 'pages/dashboard/footerStyle';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import ImportingInBackgroundBadge from '../importingInBackgroundBadge';
import { useGetAsyncQueueState } from 'hooks/services/crono-extension/useGetAsyncQueueState';
import EnrichIcon from 'crono-fe-common/icons/Icon-Enrich';
import { useGetAsyncEnrichQueueState } from 'hooks/services/crono-extension/useGetAsyncEnrichQueueState';

interface IProps {
  selected?: string[];
  areThereToEnrich?: boolean;
  handleEnrichAlll?: () => void;
  clearSelected: () => void;
  onClickAddMultiple: () => void;
}

const SearchContactSelectionBottom = ({
  selected,
  areThereToEnrich,
  handleEnrichAlll,
  clearSelected,
  onClickAddMultiple,
}: IProps) => {
  const { data: queue } = useGetAsyncQueueState();

  const isImporting = queue?.isImportingContacts;

  const handleClickAddMultiple = () => {
    onClickAddMultiple();
  };

  const { data: enrichQueue } = useGetAsyncEnrichQueueState();

  const isEnriching = enrichQueue?.isEnriching;

  return (
    <TableFooterWrapper style={{ marginLeft: 4 }}>
      {selected && selected.length > 0 && (
        <>
          <Button
            className="selected-button"
            variant="contained"
            color="secondary"
            endIcon={<CloseMIcon color={colors.grey11} />}
            disableElevation
            onClick={() => {
              if (clearSelected) {
                clearSelected();
              }
            }}
          >
            {selected.length} selected
          </Button>

          <div className="divider" />

          <MainPrimaryButton
            className="apply-button"
            onClick={handleClickAddMultiple}
            startIcon={<PlusIcon color={colors.white} />}
          >
            Add {selected.length}
          </MainPrimaryButton>

          <CancelButton
            onClick={() => {
              if (clearSelected) {
                clearSelected();
              }
            }}
            style={{
              height: 32,
              margin: 0,
            }}
          >
            Cancel
          </CancelButton>
        </>
      )}
      {isImporting && (
        <ImportingInBackgroundBadge isCompanyTab={false} small={true} />
      )}
      {areThereToEnrich && (
        <GreyButton
          style={{
            height: 32,
            padding: '4px 12px 4px 8px',
            borderRadius: 16,
            width: 'fit-content',
            color: colors.intenseBlue,
            lineHeight: '18px',
          }}
          startIcon={
            isEnriching ? (
              <span
                className="loader importing-loader"
                style={{
                  marginInline: 8,
                  fontSize: 12,
                }}
              ></span>
            ) : (
              <EnrichIcon
                color={colors.intenseBlue}
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            )
          }
          disabled={isEnriching}
          onClick={handleEnrichAlll}
        >
          Enrich all
        </GreyButton>
      )}
    </TableFooterWrapper>
  );
};

export default SearchContactSelectionBottom;
