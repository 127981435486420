import { LogLinkedin } from 'crono-fe-common/types/logLinkedin';
import { gatewayService } from 'crono-fe-common/hooks/crono-extension/gateway';
import { CronoGatewayCronoPatchLinkedinEventAction } from 'crono-fe-common/types/crono-extension/crono';

class LinkedinMessagesUtils {
  public async markLogLinkedinAsAnswered(
    logLinkedinList: LogLinkedin[],
    fullConversation?:
      | { sent: boolean; timestamp: number; message: string }[]
      | null,
  ) {
    let patched = false;

    const sortedCronoMessages = logLinkedinList
      .sort(
        (a, b) =>
          new Date(a.eventDatetime).getTime() -
          new Date(b.eventDatetime).getTime(),
      )
      .reverse();
    const sortedLinkedinMessages =
      fullConversation?.sort((a, b) => a.timestamp - b.timestamp)?.reverse() ??
      [];

    let cronoIdx = 0;
    let linkedinIdx = 0;

    while (
      cronoIdx < sortedCronoMessages.length &&
      linkedinIdx < sortedLinkedinMessages.length
    ) {
      const cronoMessage = sortedCronoMessages[cronoIdx];
      let linkedinMessage = sortedLinkedinMessages[linkedinIdx];

      if (linkedinMessage.sent) {
        // this is not a reply, check previous message
        linkedinIdx++;
        continue;
      }

      if (
        new Date(cronoMessage.eventDatetime).getTime() >
        linkedinMessage.timestamp
      ) {
        // this crono message has not been answered yet, go to previous crono message
        cronoIdx++;
        continue;
      }

      // go to the first message sent by the user
      while (
        linkedinIdx + 1 < sortedLinkedinMessages.length &&
        !sortedLinkedinMessages[linkedinIdx + 1].sent
      ) {
        linkedinIdx++;
        linkedinMessage = sortedLinkedinMessages[linkedinIdx];
      }

      // this is the reply to our crono message
      patched = true;
      try {
        await this.setLinkedinAnswered(
          cronoMessage.id,
          cronoMessage.prospectId,
          linkedinMessage.message ?? null,
          new Date(linkedinMessage.timestamp).toJSON(),
        );
      } catch (e) {
        console.error(e);
      }
      linkedinIdx++;
      cronoIdx++;

      // now we need to skip all linkedin messages until we find one that we sent
      while (
        cronoIdx < sortedCronoMessages.length &&
        linkedinIdx < sortedLinkedinMessages.length &&
        !sortedLinkedinMessages[linkedinIdx].sent
      ) {
        linkedinIdx++;
      }
    }

    return patched;
  }

  private async setLinkedinAnswered(
    linkedinId: number,
    prospectId: string,
    message: string | null,
    eventDateTime: string | null,
  ) {
    return await gatewayService.execute<CronoGatewayCronoPatchLinkedinEventAction>(
      {
        target: 'crono',
        methodName: 'patchLinkedinEventAction',
        params: {
          messageId: linkedinId,
          prospectId,
          message,
          eventDateTime,
        },
      },
    );
  }
}

const instance = new LinkedinMessagesUtils();
export default instance;
