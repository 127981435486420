import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { CompleteSubscriptionDTO } from 'crono-fe-common/types/DTO/completeSubscriptionDTO';
import { User } from 'crono-fe-common/types/user';

export default function useCompleteSubscription() {
  const completeSubscription = (completeData: CompleteSubscriptionDTO) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.complete}`,
      config: {
        method: 'post',
        data: { data: completeData },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<User>,
    Error,
    CompleteSubscriptionDTO
  >(completeSubscription, {});

  return { data, mutate, ...rest };
}
