import useTaskAnalytics from 'hooks/services/analytics/useTaskAnalytics';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import { Column, Datum } from '@ant-design/charts';
import { GraphData } from '../tabsControllers/model';
import { AnalyticsTask } from 'crono-fe-common/types/analyticsTask';
import { TaskCallLegend, colorsTaskCall } from '../legendData';
import { calcWidth, numberOfGroups, selectChartContainerClass } from '../model';
import { Typography } from '@mui/material';
import Legend from '../legend';
import { BarChartPlaceholder } from './chartsPlaceholders';

interface IProps {
  taskAnalytics: AnalyticsTask | undefined;
}

const TaskCallChart = ({ taskAnalytics }: IProps) => {
  const { data: users } = useGetUsers();

  const [maxValue, setMaxValue] = useState<number>(0);

  const dataGeneral = useMemo(() => {
    if (!users || !taskAnalytics) return [];
    const userMapping = new Map<number, string>();
    users?.data?.data?.forEach((user) => {
      userMapping.set(user.id, user.firstName + ' ' + user.lastName);
    });
    let newValue = 0;
    const newData: GraphData[] = [];
    taskAnalytics?.callDetail.ConnectedCalls?.forEach((connected) => {
      const user = userMapping.get(connected.userId);
      if (!user) return;
      if (connected.count > newValue) newValue = connected.count;
      newData.push({
        label: user,
        value: connected.count,
        type: 'Connected',
      });
    });
    taskAnalytics?.callDetail.NotAnsweredCalls?.forEach((notAnswered) => {
      const user = userMapping.get(notAnswered.userId);
      if (!user) return;
      if (notAnswered.count > newValue) newValue = notAnswered.count;
      newData.push({
        label: user,
        value: notAnswered.count,
        type: 'Not Answered',
      });
    });
    taskAnalytics?.callDetail.GateKeeperCalls?.forEach((gateKeeper) => {
      const user = userMapping.get(gateKeeper.userId);
      if (!user) return;
      if (gateKeeper.count > newValue) newValue = gateKeeper.count;
      newData.push({
        label: user,
        value: gateKeeper.count,
        type: 'Gatekeeper',
      });
    });
    taskAnalytics?.callDetail.VoiceMailCalls?.forEach((voiceMail) => {
      const user = userMapping.get(voiceMail.userId);
      if (!user) return;
      if (voiceMail.count > newValue) newValue = voiceMail.count;
      newData.push({
        label: user,
        value: voiceMail.count,
        type: 'VoiceMail',
      });
    });
    taskAnalytics?.callDetail.WrongNumberCalls?.forEach((wrongNumber) => {
      const user = userMapping.get(wrongNumber.userId);
      if (!user) return;
      if (wrongNumber.count > newValue) newValue = wrongNumber.count;
      newData.push({
        label: user,
        value: wrongNumber.count,
        type: 'Wrong Number',
      });
    });

    setMaxValue(newValue);

    return newData;
  }, [taskAnalytics, users]);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const configGeneral = useMemo(() => {
    return {
      data: dataGeneral,
      xField: 'label',
      yField: 'value',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index =
          TaskCallLegend.findIndex((legend) => legend === type) ?? 0;
        return colorsTaskCall[index];
      }) as any,
      autoFit: false,
      height: 300,
      width: calcWidth(
        dataGeneral,
        180,
        chartContainerRef.current?.offsetWidth,
      ),
      meta: {
        value: {
          min: 0,
          max: maxValue * 1.2,
        },
      },
      isGroup: true,
      renderer: 'svg' as 'svg' | 'canvas' | undefined,
      //If only one ground is present, the graph doesn't work if dodgePadding is set
      dodgePadding: numberOfGroups(dataGeneral) > 1 ? 6 : undefined,

      maxColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      minColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      legend: false as any,
      randomVal: Math.random() * 10000,
    };
  }, [dataGeneral, chartContainerRef.current?.offsetWidth, maxValue]);

  return (
    <>
      <Typography className="chart-title">Calls</Typography>
      <div
        className={selectChartContainerClass(dataGeneral)}
        ref={chartContainerRef}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 40,
            position: 'sticky',
            left: 0,
          }}
        >
          <Legend colors={colorsTaskCall} fields={TaskCallLegend} />
        </div>
        {configGeneral?.data && configGeneral.data.length ? (
          <Column {...configGeneral} key={configGeneral.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <BarChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default TaskCallChart;
