import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconMCompany = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7653 19H6.23593C5.32593 19 4.58887 18.2629 4.58887 17.3529V9.94117C4.58887 9.03117 5.32593 8.29411 6.23593 8.29411H17.7653C18.6753 8.29411 19.4124 9.03117 19.4124 9.94117V17.3529C19.4124 18.2629 18.6753 19 17.7653 19Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4378 8.29412V6.64706C15.4378 5.73706 14.7008 5 13.7908 5H10.21C9.30005 5 8.56299 5.73706 8.56299 6.64706V8.29412"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2972 14.0603H7.47242C5.87988 14.0603 4.58887 12.7693 4.58887 11.1768"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6514 14.0603H16.5349C18.1275 14.0603 19.4185 12.7693 19.4185 11.1768V11.1768"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMCompany;
