import React, { useEffect, useMemo, useState } from 'react';
import { AddContainerWrapper } from '../style';
import { colors } from 'crono-fe-common/theme';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import {
  Autocomplete,
  Avatar,
  Button,
  CircularProgress,
  ClickAwayListener,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import useAccounts from 'hooks/services/account/useAccounts';
import { useAuth } from 'context/auth';
import Role from 'crono-fe-common/types/enums/role';
import { Account } from 'crono-fe-common/types/account';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import useSearchProspects from 'hooks/services/prospect/useSearchProspects';
import { Prospect } from 'crono-fe-common/types/prospect';

import AddTemplateIcon from 'crono-fe-common/icons/Icon-Add-Template';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import { ReactComponent as TemplateAttached } from 'crono-fe-common/icons/Template-Attached.svg';

import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import StepTemplateModal from 'pages/settings/Strategy/stepTemplateModal';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { getError } from 'crono-fe-common/utils';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import { DatePicker } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useCreateTask from 'hooks/services/task/useCreateTask';
import { useConditionalSnackBar } from 'context/snackbar';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import {
  fullname,
  getImageFromUrl,
  stringAvatarAccount,
  stringAvatarProspect,
} from 'utils/fe-utils';
import { Template } from 'crono-fe-common/types/template';
import useGetOpportunity from 'hooks/services/opportunity/useGetOpportunity';
import { Opportunity } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';
import moment from 'moment';
import CalendarIcon from 'crono-fe-common/icons/Calendar-Icon';
import { StepTypeOptionsWrapper } from 'context/strategyDetail/stepDetailComponent/style';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import { useJuneAnalytics } from 'context/june';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import CronoTimePicker from 'crono-fe-common/components/CronoTimePicker';
import { TemplateCardsContainerTooltip } from 'pages/accountTab/linkedinView';
import TemplatesTooltipCard from 'pages/accountTab/emailView/TemplatesTooltipCard';

export interface TaskOption {
  type: TaskTodoType;
  subtype?: TaskTodoSubtype | null;
}
interface TypeOption {
  type: TaskTodoType;
  subtype: TaskTodoSubtype | null;
  icon: JSX.Element;
  text: string;
}

const typeOptions: TypeOption[] = [
  {
    type: TaskTodoType.CALL,
    subtype: null,
    icon: <CallIcon />,
    text: 'Change to Call',
  },
  {
    type: TaskTodoType.EMAIL,
    subtype: null,
    icon: <EmailIcon />,
    text: 'Change to Email',
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinInvitation,
    text: 'Change to Linkedin Invitation',
    icon: <LinkedinInvitationIcon />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinMessage,
    text: 'Change to Linkedin Message',
    icon: <LinkedinIconMessage />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinVoiceNote,
    text: 'Change to Linkedin Voice Note',
    icon: <LinkedinIconInvoice viewBox="0 2 20 20" />,
  },
  {
    type: TaskTodoType.IN_MAIL,
    subtype: null,
    icon: <LinkedinIconInMailCompass viewBox="0 2 20 20" />,
    text: 'Change to Linkedin InMail',
  },
];

interface IProps {
  close: () => void;
  initialCompany?: Account;
  initialContact?: Prospect;
  initialOpportunity?: Opportunity;
  initialTask?: TaskOption;
}

const AddTaskContainer = ({
  close,
  initialCompany,
  initialContact,
  initialOpportunity,
  initialTask,
}: IProps) => {
  const { highestRole, hasSalesNav, hasOpportunities } = useAuth();
  //CompanySelect
  const [searchCompanyText, setSearchCompanyText] = useState<string>(
    initialCompany?.name ?? '',
  );
  const { data: accounts } = useAccounts({
    name: searchCompanyText,
    limit: 25,
    status: null,
    IsManager: highestRole === Role.MANAGER,
  });

  const mappedAccount = useMemo(() => {
    if (!accounts || !accounts.data) return [];
    return accounts.data?.data.map((acc) => {
      return acc;
    });
  }, [accounts]);

  const [selectedCompany, setSelectedCompany] = useState<string | null>(
    initialContact?.accountId ?? initialCompany?.objectId ?? null,
  );
  //Here I have the account to insert, with the accountId
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    initialContact?.account
      ? initialContact?.account
      : initialCompany
        ? initialCompany
        : undefined,
  );

  useEffect(() => {
    const acc = mappedAccount.find((el) => el.objectId === selectedCompany);
    if (acc) setSelectedAccount(acc);
  }, [selectedCompany, mappedAccount]);

  const [selectedContact, setSelectedContact] = useState<string | null>(
    initialContact?.objectId ?? null,
  );

  const [selectedProspect, setSelectedProspect] = useState<
    Prospect | undefined
  >(initialContact);

  const [searchContactText, setSearchContactText] = useState<string>(
    initialContact?.name ??
      fullname(initialContact?.firstName, initialContact?.lastName) ??
      '',
  );

  const { data: prospectsSearched } = useSearchProspects({
    limit: 5,
    offset: 0,
    name: searchContactText === '' ? null : searchContactText,
    sort: 'Name',
    status: null,
    accountId: selectedAccount?.objectId ?? null,
    onlyOwned: true,
  });

  const prospects = useMemo(() => {
    return prospectsSearched?.data?.data ?? [];
  }, [prospectsSearched]);

  useEffect(() => {
    const prospect = prospects.find((el) => el.objectId === selectedContact);
    if (prospect) {
      setSelectedProspect(prospect);
      if (!selectedAccount) {
        setSelectedCompany(
          prospect.accountId ?? prospect?.account?.objectId ?? null,
        );
        setSelectedAccount(prospect?.account ?? undefined);
      }
    }
  }, [selectedContact]);

  const [taskOption, setTaskOption] = useState<TaskOption>(
    initialTask ?? { type: TaskTodoType.EMAIL, subtype: null },
  );
  const [template, setTemplate] = useState<Template | null>(null);

  const [showTemplateSelection, setShowTemplateSelection] =
    useState<boolean>(false);

  const onAdd = (template: Template) => {
    setTemplate(template);
    setShowTemplateSelection(false);
  };

  const handleRemoveAccount = () => {
    setSelectedAccount(undefined);
    setSelectedCompany(null);
    setSearchCompanyText('');
    setSelectedProspect(undefined);
    setSelectedContact(null);
    setSearchContactText('');
    setAccountOpportunities(false);
  };

  const handleRemoveProspect = () => {
    setSelectedProspect(undefined);
    setSelectedContact(null);
    setSearchContactText('');
  };

  const handleRemoveOpportunity = () => {
    setSelectedOpportunity(undefined);
    setSearchOpportunity('');
    setSearchOpportunityText('');
  };

  const [date, setDate] = useState<Date | null>(new Date());

  const handleChangeDate = (newDate: Date | null) => {
    if (newDate && newDate < new Date()) {
      setDate(new Date());
    } else {
      setDate(newDate);
    }
  };

  const {
    mutate: createTask,
    isSuccess: taskCreated,
    error: errorTaskCreation,
    isLoading: taskCreating,
  } = useCreateTask();

  useConditionalSnackBar([
    {
      condition: !!taskCreated,
      message: 'Task created successfully',
      severity: 'success',
    },
    {
      condition: !!errorTaskCreation,
      message: getError(errorTaskCreation) || 'Error creating task',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (taskCreated) {
      close();
    }
  }, [taskCreated]);

  const analytics = useJuneAnalytics();

  const handleScheduleTask = () => {
    if (!selectedAccount || !selectedProspect || !date) return;
    createTask({
      accountId: selectedAccount?.objectId,
      prospectId: selectedProspect?.objectId,
      type: taskOption.type,
      // TODO should we check here or in BE if an invitation was already sent?
      subtype: taskOption?.subtype ?? undefined,
      activityDate: date,
      templateId: template?.id ?? undefined,
      automatic: false,
      subject: subject || null,
      description: null,
      opportunityId: searchOpportunity || null,
    });
    if (analytics) {
      if (searchOpportunity) {
        analytics.track('create-deal-task', {
          type: taskOption.type,
          subtype: taskOption?.subtype ?? undefined,
        });
      } else {
        analytics.track('create-task', {
          type: taskOption.type,
          subtype: taskOption?.subtype ?? undefined,
        });
      }
    }
  };

  const [subject, setSubject] = useState<string>('');
  const [searchOpportunity, setSearchOpportunity] = useState<string | null>(
    initialOpportunity?.objectId ?? null,
  );
  const [selectedOpportunity, setSelectedOpportunity] = useState<
    Opportunity | undefined
  >(initialOpportunity);
  const [searchOpportunityText, setSearchOpportunityText] = useState<string>(
    selectedOpportunity?.name ?? '',
  );

  // Deal logic -------------------------
  const [accountOpportunities, setAccountOpportunities] =
    useState<boolean>(false);
  const { data: opportunities } = useGetOpportunity(
    selectedAccount?.objectId ?? null,
  );
  useEffect(() => {
    if (
      (selectedAccount &&
        selectedAccount.totalOpportunities &&
        selectedAccount.totalOpportunities > 0) ||
      (opportunities?.data?.count && opportunities.data.count > 0)
    ) {
      setAccountOpportunities(true);
    }
  }, [selectedAccount, opportunities]);

  const mappedOpportunities = useMemo(() => {
    return opportunities?.data?.data ?? [];
  }, [opportunities]);

  useEffect(() => {
    const opportunity = mappedOpportunities.find(
      (el) => el.objectId === searchOpportunity,
    );
    if (opportunity) setSelectedOpportunity(opportunity);
  }, [searchOpportunity, mappedOpportunities]);

  // Settings logic ------------------------
  const [openTypeTooltip, setOpenTypeTooltip] = useState<boolean>(false);
  const [checkValidDate, setCheckValidDate] = useState<boolean>(true);
  const [toggleTimePicker, setToggleTimePicker] = useState<boolean>(false);

  const renderPossibleTypes = (currentTask: TaskOption) => {
    return typeOptions
      .filter((option) => {
        if (
          currentTask.type !== TaskTodoType.IN_MAIL &&
          option.type === TaskTodoType.IN_MAIL &&
          !hasSalesNav
        ) {
          return false;
        }
        return (
          option.type !== currentTask.type ||
          (currentTask.subtype &&
            option.subtype &&
            option.subtype !== currentTask.subtype)
        );
      })
      .map((option) => {
        return (
          <div key={`${option.type}-${option.subtype}`}>
            <Button
              className="option-button"
              color="primary"
              fullWidth
              endIcon={option.icon}
              onClick={() => {
                setTaskOption(option);
                setOpenTypeTooltip(false);
                setTemplate(null);
              }}
            >
              {option.text}
            </Button>
          </div>
        );
      });
  };

  const renderStepType = (taskOption: TaskOption) => {
    let icon = null;
    let type = '' + taskOption.type;
    switch (taskOption.type) {
      case TaskTodoType.CALL:
        icon = (
          <CallIcon
            className={'type-icon call'}
            color={colors.callScheduledHover}
          />
        );
        type = 'Call';
        break;
      case TaskTodoType.EMAIL:
        icon = (
          <EmailIcon
            className={'type-icon email'}
            color={colors.inactiveHover}
          />
        );
        type = 'Email';
        break;
      case TaskTodoType.LINKEDIN:
        if (taskOption.subtype === TaskTodoSubtype.LinkedinInvitation) {
          icon = (
            <LinkedinInvitationIcon
              className={'linkedin-icon'}
              viewBox="-1 1 17 17"
            />
          );
          type = 'Invitation';
        } else if (taskOption.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
          icon = (
            <LinkedinIconInvoice
              color={colors.blueHover}
              className={'linkedin-icon'}
              viewBox="1 2 19 19"
            />
          );
          type = 'Voice Note';
        } else {
          icon = (
            <LinkedinIconMessage
              className={'linkedin-icon'}
              color={colors.blueHover}
            />
          );
          type = 'Message';
        }
        break;
      case TaskTodoType.IN_MAIL:
        icon = (
          <LinkedinIconInMailCompass
            className={'type-icon in-mail'}
            color={colors.purpleHover}
            viewBox="1 1 22 22"
          />
        );
        type = 'InMail';
        break;
    }
    return (
      <ClickAwayListener onClickAway={() => setOpenTypeTooltip(false)}>
        <div>
          <CustomOptionsTooltip
            maxHeight={220}
            width={250}
            left={0}
            title={
              <StepTypeOptionsWrapper>
                {renderPossibleTypes(taskOption)}
              </StepTypeOptionsWrapper>
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={openTypeTooltip}
            onClose={() => setOpenTypeTooltip(false)}
            sx={{
              zIndex: '5001 !important',
            }}
          >
            <div
              className="step-type-option-container"
              onClick={() => setOpenTypeTooltip((prev) => !prev)}
            >
              <div className="step-type-option">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className={
                    taskOption.type === TaskTodoType.LINKEDIN
                      ? 'type-icon linkedin-icon'
                      : ''
                  }
                >
                  {icon}
                </span>
                <Typography fontSize={14} fontWeight={500}>
                  {type}
                </Typography>
              </div>
              {openTypeTooltip ? <TopArrowIcon /> : <BottomArrowIcon />}
            </div>
          </CustomOptionsTooltip>
        </div>
      </ClickAwayListener>
    );
  };

  const templateType = useMemo(() => {
    if (taskOption && taskOption.type) {
      return taskOption.type === TaskTodoType.CALL
        ? TemplateType.SCRIPT
        : taskOption.type === TaskTodoType.EMAIL
          ? TemplateType.EMAIL
          : taskOption.type === TaskTodoType.IN_MAIL
            ? TemplateType.IN_MAIL
            : taskOption.subtype === TaskTodoSubtype.LinkedinInvitation
              ? TemplateType.INVITATION
              : taskOption.subtype === TaskTodoSubtype.LinkedinVoiceNote
                ? TemplateType.SCRIPT
                : TemplateType.LINKEDIN;
    } else {
      return TemplateType.LINKEDIN;
    }
  }, [taskOption.type]);

  return (
    <BlurBackgroundDivFixed>
      {/* {showTemplateSelection && (
        <StepTemplateModal
          onAdd={onAdd}
          close={() => setShowTemplateSelection(false)}
          initialValue={template}
          templateType={
            taskOption.type === TaskTodoType.EMAIL
              ? TemplateType.EMAIL
              : taskOption.type === TaskTodoType.IN_MAIL
                ? TemplateType.IN_MAIL
                : taskOption.subtype === TaskTodoSubtype.LinkedinInvitation
                  ? TemplateType.INVITATION
                  : taskOption.subtype === TaskTodoSubtype.LinkedinVoiceNote
                    ? TemplateType.SCRIPT
                    : TemplateType.LINKEDIN
          }
        />
      )} */}
      <AddContainerWrapper
        style={{
          width: 650,
          height: 487,
          justifyContent: 'flex-start',
        }}
      >
        <CloseTabButton close={close} style={{ marginRight: '18px' }} />
        <div className="add-main-container task-container">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Create new task:
          </Typography>

          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '40px',
            }}
          >
            <div
              className="form-box"
              style={{ width: 'calc(50% - 20px)', overflow: 'hidden' }}
            >
              <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
                Contact*:
              </Typography>
              {selectedProspect ? (
                <div className="information-card-manual-task">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: 8,
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Avatar className="account-avatar">
                      {stringAvatarProspect(selectedProspect)}
                    </Avatar>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'18px'}
                        noWrap
                      >
                        {selectedProspect.name}
                      </Typography>
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        lineHeight={'16px'}
                        color={colors.grey11}
                        noWrap
                      >
                        {selectedProspect.title}
                      </Typography>
                    </div>
                  </div>
                  <RemoveIcon
                    className="manual-task-remove-icon"
                    color={colors.grey11}
                    onClick={handleRemoveProspect}
                  />
                </div>
              ) : (
                <Autocomplete
                  id="contactSelect"
                  fullWidth
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  onChange={(e, value) => {
                    if (value) setSelectedContact(value.id);
                    else setSelectedContact(null);
                  }}
                  options={prospects.map((acc) => {
                    return { id: acc.objectId, name: acc.name };
                  })}
                  getOptionLabel={(option) => option.name || ''}
                  PaperComponent={({ children }) => (
                    <div className="autocomplete-paper">{children}</div>
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      value={option.id || undefined}
                      className="autocomplete-option"
                    >
                      {option.name}
                    </li>
                  )}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="Type contact name..."
                      value={searchContactText}
                      onChange={(e: any) =>
                        setSearchContactText(e.target.value)
                      }
                    />
                  )}
                />
              )}
            </div>
            <div className="form-box" style={{ width: 'calc(50% - 20px)' }}>
              <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
                Company*:
              </Typography>
              {selectedAccount ? (
                <div className="information-card-manual-task">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: 8,
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Avatar
                      className="account-avatar"
                      src={
                        selectedAccount.website &&
                        getImageFromUrl(selectedAccount.website, null)
                      }
                    >
                      {stringAvatarAccount(selectedAccount.name || '')}
                    </Avatar>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'18px'}
                        noWrap
                      >
                        {selectedAccount.name}
                      </Typography>
                    </div>
                  </div>
                  <RemoveIcon
                    className="manual-task-remove-icon"
                    color={colors.grey11}
                    onClick={handleRemoveAccount}
                  />
                </div>
              ) : (
                <Autocomplete
                  id="companySelect"
                  fullWidth
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  onChange={(e, value) => {
                    if (value) setSelectedCompany(value.id);
                    else setSelectedCompany(null);
                  }}
                  options={mappedAccount.map((acc) => {
                    return { id: acc.objectId, name: acc.name };
                  })}
                  getOptionLabel={(option) => option.name || ''}
                  PaperComponent={({ children }) => (
                    <div className="autocomplete-paper">{children}</div>
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      value={option.id || undefined}
                      className="autocomplete-option"
                    >
                      {option.name}
                    </li>
                  )}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="Type company name..."
                      value={searchCompanyText}
                      onChange={(e: any) =>
                        setSearchCompanyText(e.target.value)
                      }
                    />
                  )}
                />
              )}
            </div>
          </div>
          {accountOpportunities && hasOpportunities && (
            <div className="form-box">
              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight={'22px'}
                style={{ opacity: selectedAccount ? 1 : 0.6 }}
              >
                Deal name:
              </Typography>
              {selectedAccount && selectedOpportunity ? (
                <div className="information-card-manual-task">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: 8,
                      width: '100%',
                    }}
                  >
                    <div style={{ width: 'calc(100% - 40px)' }}>
                      <Typography
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'18px'}
                        noWrap
                      >
                        {selectedOpportunity.name}
                      </Typography>
                    </div>
                  </div>
                  <RemoveIcon
                    className="manual-task-remove-icon"
                    color={colors.grey11}
                    onClick={handleRemoveOpportunity}
                  />
                </div>
              ) : (
                <Autocomplete
                  id="dealSelect"
                  fullWidth
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  onChange={(e, value) => {
                    if (value) setSearchOpportunity(value.id);
                    else setSearchOpportunity(null);
                  }}
                  options={mappedOpportunities.map((acc) => {
                    return { id: acc.objectId, name: acc.name };
                  })}
                  getOptionLabel={(option) => option.name || ''}
                  PaperComponent={({ children }) => (
                    <div className="autocomplete-paper">{children}</div>
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      value={option.id || undefined}
                      className="autocomplete-option"
                    >
                      {option.name}
                    </li>
                  )}
                  disabled={!selectedAccount}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="Type deal name..."
                      value={searchOpportunityText}
                      onChange={(e: any) => {
                        setSearchOpportunityText(e.target.value);
                      }}
                      disabled={!selectedAccount}
                    />
                  )}
                />
              )}
            </div>
          )}
          <div className="form-box">
            <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
              Task title:
            </Typography>
            <TextField
              value={subject}
              onChange={(ev) => setSubject(ev.target.value)}
              size="small"
              variant="standard"
              fullWidth
              placeholder="Insert a title for this task..."
            />
          </div>
          <div className="form-box">
            <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
              Task settings:
            </Typography>
            <div className="step-content">
              <div className="step-content-top">
                <div className="flex-center step-left-side-wrapper">
                  {renderStepType(taskOption)}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <DatePicker
                            className="select-date-time-button"
                            minDate={new Date()}
                            value={date}
                            onChange={(newValue) => {
                              if (newValue) handleChangeDate(newValue);
                            }}
                            slotProps={{
                              popper: { style: { zIndex: 9999 } },
                              textField: {
                                variant: 'standard',
                                InputProps: { disableUnderline: true },
                              },
                            }}
                            components={{
                              OpenPickerIcon: CalendarIcon,
                            }}
                            sx={{
                              fontSize: 12,
                              '& .MuiInput-input': {
                                display: 'none',
                              },
                              '& .MuiInputAdornment-root': {
                                margin: 0,
                              },
                              '& .MuiIconButton-root': {
                                width: '42px',
                                height: '42px',
                                margin: 0,
                              },
                            }}
                          />
                          <Typography fontSize={14} fontWeight={500}>
                            {moment(date).format('DD/MM/YYYY')}
                          </Typography>
                        </div>
                        {taskOption.type !== TaskTodoType.LINKEDIN &&
                        taskOption.type !== TaskTodoType.IN_MAIL ? (
                          <CronoTimePicker
                            value={date}
                            handleChange={handleChangeDate}
                            className="align-left select-time-button"
                          />
                        ) : null}
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>

                <TemplateCardsContainerTooltip
                  title={
                    <TemplatesTooltipCard
                      accountId={selectedAccount?.objectId ?? null}
                      prospectId={selectedProspect?.objectId}
                      selectedTemplateId={template?.id ?? null}
                      setSelectedTemplate={(template: Template) =>
                        setTemplate(template)
                      }
                      selectedType={templateType}
                      hideShared={false}
                      hideAll={false}
                      showAnalytics={true}
                      close={() => setShowTemplateSelection(false)}
                      account={selectedAccount}
                      prospect={selectedProspect}
                      visibility="visible"
                    />
                  }
                  onClose={() => setShowTemplateSelection(false)}
                  open={showTemplateSelection}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  sx={{
                    zIndex: '5001 !important',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      minWidth: '80px',
                      borderLeft: `1px solid ${colors.grey444}`,
                      minHeight: '64px',
                    }}
                  >
                    {template ? (
                      <TemplateAttached
                        className="task-component-button"
                        color={colors.grey11}
                        onClick={() => {
                          setShowTemplateSelection(true);
                        }}
                      />
                    ) : (
                      <AddTemplateIcon
                        className="task-component-button"
                        onClick={() => {
                          setShowTemplateSelection(true);
                        }}
                        color={colors.grey11}
                      />
                    )}
                  </div>
                </TemplateCardsContainerTooltip>
              </div>
            </div>
          </div>
          <FlexDiv
            width="fit-content"
            height="fit-content"
            style={{ alignSelf: 'flex-end' }}
          >
            <CancelButton
              onClick={() => {
                if (close) close();
              }}
            >
              Cancel
            </CancelButton>
            {taskCreating ? (
              <CircularProgress />
            ) : (
              <MainButton
                onClick={() => {
                  handleScheduleTask();
                }}
                disabled={!selectedCompany || !selectedContact}
                style={{
                  background:
                    !selectedCompany || !selectedContact
                      ? colors.grey11
                      : undefined,
                }}
              >
                Create
              </MainButton>
            )}
          </FlexDiv>
        </div>
      </AddContainerWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default AddTaskContainer;
