import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 270px;
  overflow: auto;

  .template-tags-filters {
    border: 1px solid ${colors.grey4};
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
    margin-top: 0px !important;
  }
  .external-property {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
  }
  .external-property-container {
    padding: 12px 0px;
  }
  .external-property-name {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .external-values-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .tag-button {
    border: 1px solid red;
  }
`;
