import { ENDPOINTS } from 'config/endpoints';
import { useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AccountImport } from 'crono-fe-common/types/DTO/accountImport';
import { ImportBehavior } from 'crono-fe-common/types/enums/importType';
import { useLimitedMutation } from '../useLimitedMutation';

export interface ImportAccountsRequest {
  data: AccountImport[];
  importType: ImportBehavior | null;
  fileName: string | null;
}

export default function useImportAccounts() {
  const queryClient = useQueryClient();

  const importAccounts = (data: ImportAccountsRequest) => {
    const request: Request = {
      url: `${ENDPOINTS.account.import}`,
      config: {
        method: 'post',
        data: {
          data: data.data,
          importType: data.importType,
          fileName: data.fileName,
        },
      },
    };
    return client(request);
  };

  return useLimitedMutation<Response<null>, CronoError, ImportAccountsRequest>(
    importAccounts,
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENDPOINTS.import.main]);
        queryClient.invalidateQueries([ENDPOINTS.import.actives]);
      },
    },
  );
}
