import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SequenceStepTemplateDTO } from 'crono-fe-common/types/DTO/sequenceStepTemplateDTO';
import { SequenceStepTemplate } from 'crono-fe-common/types/DTO/sequenceStepTemplates';

// This hook has 2 different responses:
// The first response will always be an array with 2 elements: [ originalTemplate, newABTestingTemplate ]
// All the following responses after that will be an array with 1 element: [ newABTestingTemplate ]

export default function useAddSequenceStepTemplate() {
  const queryClient = useQueryClient();

  const addSequenceStepTemplate = (stepTemplate: SequenceStepTemplateDTO) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.stepTemplate}`,
      config: {
        method: 'post',
        data: { data: stepTemplate },
      },
    };
    return client(request);
  };

  return useMutation<
    Response<SequenceStepTemplate[]>,
    CronoError,
    SequenceStepTemplateDTO
  >(addSequenceStepTemplate, {
    onSettled: (result, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
    },
  });
}
