import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconPriceTag = ({
  color = colors.black,
  className,
  onClick,
  ...rest
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...rest}
    >
      <path
        d="M11.8795 12.1189L12.4753 11.523"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.28392 15.7159L7.68809 16.3118"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.68169 14.2438C7.58311 14.6522 7.67194 15.092 7.99153 15.4116L8.29053 15.7106L8.64694 16.067C9.11061 16.5307 9.86244 16.5307 10.3261 16.067C10.7107 15.6825 10.7854 15.0845 10.5049 14.6175L9.66528 13.2157C9.38578 12.7488 9.45944 12.1508 9.84403 11.7662C10.3077 11.3025 11.0595 11.3025 11.5232 11.7662L11.8796 12.1226L12.1786 12.4216C12.4971 12.7401 12.5859 13.1789 12.4895 13.5862"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1247 7.87514C16.2817 8.0321 16.2817 8.28658 16.1247 8.44354C15.9677 8.6005 15.7133 8.6005 15.5563 8.44354C15.3993 8.28658 15.3993 8.0321 15.5563 7.87514C15.7133 7.71819 15.9677 7.71819 16.1247 7.87514"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6243 21.3169L12.8805 22.2097C14.05 22.6726 15.3733 22.0997 15.8361 20.9302L18.84 13.3398"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6377 3.3335H18.3881C19.647 3.3335 20.6664 4.35291 20.6664 5.61066V10.3611C20.6664 10.9656 20.4259 11.5441 19.9991 11.972L10.8882 21.0828C9.99881 21.9722 8.5569 21.9722 7.66748 21.0828L2.91706 16.3324C2.02765 15.443 2.02765 14.0011 2.91706 13.1117L12.0279 4.00083C12.4547 3.574 13.0343 3.3335 13.6377 3.3335Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPriceTag;
