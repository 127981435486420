import { colors } from 'crono-fe-common/theme';
import styled from '@emotion/styled';

export const SubscriptionStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 90px;

  .onboarding-navigation {
    display: flex;
    align-items: center;
    gap: 8;
    justify-content: center;
  }
  .value-select {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: -6px;
  }
  .value-select-icon {
    border-radius: 4px;
    padding: 4px;
    display: flex;
  }
  .gmail {
    background-color: ${colors.grey6};
  }
  .outlook {
    background-color: ${colors.grey6};
  }
  .other {
    background-color: ${colors.grey4Hover};
  }
  .icon {
    padding: 4px;
    background-color: ${colors.grey6};
  }
  .hubspot {
    background-color: ${colors.hubspotOrange};
  }
  .salesforce {
    background-color: ${colors.salesforceBlue};
  }
  .pipedrive {
    background-color: ${colors.pipedriveColor};
  }
  .crono {
    background-color: ${colors.primary};
  }
  .call-integration-system-div {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 12px;
    padding: 8px;
    justify-content: space-between;
  }
  .user-list-container {
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: fit-content;
  }
  .user-card {
    padding: 16px 24px 16px 24px;
    gap: 48px;
    border-bottom: 1px solid ${colors.grey444};
    display: flex;
    width: 100%;
    align-items: center;
  }
  .user-card:last-child {
    border-bottom: none;
  }
  .active-badge,
  .pending-badge {
    border-radius: 4px;
    padding: 7px 16px;
    gap: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
  .active-badge {
    background: ${colors.callScheduledLight};
    color: ${colors.callScheduled};
  }
  .pending-badge {
    background: ${colors.inactiveLight};
    color: ${colors.inactive};
  }
  .admin-checkbox {
    display: flex;
  }
  .buttons-user-container {
    display: flex;
    gap: 8px;
  }
  .button-edit-user {
    background: ${colors.primaryLight};
    border-radius: 50%;
    &:hover {
      background: ${colors.primaryLightHover};
    }
    width: 32px;
    height: 32px;
    padding: 6px;
  }
  .button-delete-user {
    background: ${colors.inactiveLight};
    border-radius: 50%;
    &:hover {
      background: ${colors.inactiveLightHover};
    }
    width: 32px;
    height: 32px;
    padding: 6px;
  }
`;
