import {
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
} from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';

import InsertContact from './insertContact';
import InsertMultipleContacts from './insertMultipleContacts';
import { ReactComponent as NoResults } from 'assets/images/No_results_companies_search.svg';
import { ReactComponent as NoFiltersApplied } from 'assets/images/No_filters_applied.svg';

import ContactInsertCard from './contactInsertCard';
import {
  useEnqueueAsyncEnrichJob,
  useLinkedinSearchLeads,
  useLinkedinSearchSalesNavLeads,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import {
  LinkedinProfile,
  SalesNavProfile,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { ReactComponent as DisabledExtensionPlaceholder } from 'assets/images/Disabled_Extension_Placeholder.svg';

import { rowPlaceholderFillOpacities } from 'constants/FeConstants';
import RowResultPlaceHolder from 'crono-fe-common/icons/Row-result-placeholder';
import {
  CronoExtensionStatus,
  useGetExtensionStatus,
} from 'hooks/services/crono-extension/useGetExtensionStatus';
import SearchContactTabFilters from './searchContactTabFilters';
import { useSearchContactTab } from './searchContactTabContext';
import CustomTableHeadSearchContactTab from '../tableHeadSearchContactTab';
import { ContactForSearchTable, getContactSearchHeadCells } from './model';
import Pagination from 'pages/dashboard/pagination';
import SearchContactSelectionBottom from './searchContactSelectionBottom';
import { prospectNameFromLinkedinProfile } from 'utils/fe-utils';
import { Constants } from 'crono-fe-common/constants/constants';
import useProspectLinkedinMultiple from 'hooks/services/prospect/useProspectLinkedinMultiple';
import { ProspectLinkedin } from 'crono-fe-common/types/prospectLinkedin';
import { useFindNewTabContext } from 'pages/home/context/findNewContext';
import { ReactComponent as ReloadIcon } from 'crono-fe-common/icons/Reload.svg';
import useSearchCompanyScrape from 'hooks/services/companyScrape/useGetCompanyScrape';
import EditColumnsModal, {
  defaultColumnsContactsLinkedin,
  defaultColumnsContactsSalesNav,
  EditColumnsType,
} from '../editColumnsModal';
import { useQueryClient } from 'react-query';
import { AsyncEnrichJob } from 'crono-fe-common/types/crono-extension/background-script';

type LoadContactsType =
  | {
      fromSalesNav: true;
      leads: SalesNavProfile[];
    }
  | {
      fromSalesNav: false;
      leads: LinkedinProfile[];
    };
interface IProps {
  filtersColumnWrapper: (filters: JSX.Element) => JSX.Element;
}

const ContactSearchTab = ({ filtersColumnWrapper }: IProps) => {
  const { user, hasSalesNavWithCookie } = useAuth();
  const analytics = useJuneAnalytics();

  //They may ask us to reintroduce it in the future, commented for now
  /*
  const { data: userCredits } = useGetUserCredits();

  const [showErrorNoCreditsLeft, setShowErrorNoCreditsLeft] = useState<{
    showError: boolean;
    alreadyShown: boolean;
  }>({
    showError: false,
    alreadyShown: false,
  });

  useEffect(() => {
    if (userCredits) {
      if (
        !showErrorNoCreditsLeft.alreadyShown &&
        (userCredits.data?.data.emailLeft === 0 ||
          userCredits.data?.data.phoneLeft === 0)
      ) {
        setShowErrorNoCreditsLeft({
          showError: true,
          alreadyShown: true,
        });
        setTimeout(() => {
          setShowErrorNoCreditsLeft({
            showError: false,
            alreadyShown: true,
          });
        }, 5000);
      }
    }
  }, [userCredits]);
  */

  const LIMIT_FIND_NEW = hasSalesNavWithCookie ? 25 : 10;
  const { call: searchLeads, result: searchContactsResult } =
    useLinkedinSearchLeads();

  const {
    call: searchLeadsSalesNav,
    result: searchContactsSalesNavResult,
    error: searchContactsSalesNavError,
  } = useLinkedinSearchSalesNavLeads();

  const queryClient = useQueryClient();
  useEffect(() => {
    if (searchContactsSalesNavError === 'SalesNav cookie missing or expired') {
      queryClient.invalidateQueries('getExtensionStatus');
    }
  }, [searchContactsSalesNavError, queryClient]);

  const {
    contactsListPersistent,
    setContactsListPersistent,
    countContactsListPersistent,
    setCountContactsListPersistent,
    totalContactsListPersistent,
    setTotalContactsListPersistent,
    initialPageToLoadContactsListPersistent,
    setInitialPageToLoadContactsListPersistent,
    nextPageToLoadContactsListPersistent,
    setNextPageToLoadContactsListPersistent,
    filtersUserContactsListPersistent,
    setFiltersUserContactsListPersistent,
  } = useFindNewTabContext();

  const count =
    (hasSalesNavWithCookie
      ? searchContactsSalesNavResult?.paging.count
      : searchContactsResult?.paging.count) ??
    countContactsListPersistent ??
    undefined;
  const total =
    (hasSalesNavWithCookie
      ? searchContactsSalesNavResult?.paging.total
      : searchContactsResult?.paging.total) ??
    totalContactsListPersistent ??
    undefined;

  const [contactsList, setContactsList] = useState<ContactForSearchTable[]>([]);

  //When we apply filters we save them here to use them correctly when asking for new pages
  const [filterUsed, setFilterUsed] = useState<any>();

  useEffect(() => {
    //Load the companies from the persistent context when mounting
    if (contactsListPersistent) {
      setContactsList(contactsListPersistent);
    }
    if (nextPageToLoadContactsListPersistent !== null) {
      setNextPageToLoad(nextPageToLoadContactsListPersistent);
    }
    if (initialPageToLoadContactsListPersistent !== null) {
      setInitialPage(initialPageToLoadContactsListPersistent);
    }
    if (filtersUserContactsListPersistent) {
      setFilterUsed(filtersUserContactsListPersistent);
    }
  }, []);

  //Save the current state in the persistent context when unmounting
  useEffect(() => {
    return () => {
      setContactsListPersistent(contactsList);
      setCountContactsListPersistent(count ?? null);
      setTotalContactsListPersistent(total ?? null);
      setInitialPageToLoadContactsListPersistent(initialPage ?? null);
      setNextPageToLoadContactsListPersistent(nextPageToLoad ?? null);
      setFiltersUserContactsListPersistent(filterUsed);
    };
  }, [contactsList]);

  const [selected, setSelected] = useState<string[]>([]);

  //When the selected changes we can compute the number of contacts that can be enriched
  const enrichableContacts = useMemo(() => {
    const toEnrich: ContactForSearchTable[] = [];
    contactsList.forEach((contactInList) => {
      if (
        !contactInList.currentCompanyAlreadyInDatabase &&
        !contactInList.correct
      ) {
        toEnrich.push(contactInList);
      }
    });
    return toEnrich;
  }, [contactsList]);
  const handleSelectContact = (selectedId: string) => {
    if (selected.includes(selectedId)) {
      setSelected(selected.filter((id) => id !== selectedId));
    } else {
      setSelected([...selected, selectedId]);
    }
  };

  const handleClickGeneralCheckbox = () => {
    if (contactsList?.length && selected.length === availableToSelectCount) {
      setSelected([]);
    } else {
      setSelected(
        contactsList
          .filter(
            (contact, index) =>
              contact.id !== undefined && !nonSelectable.current.at(index),
          )
          .map((contact) => {
            return contact.id;
          }) as string[],
      );
    }
  };

  //This is used to know the start page that it is loaded
  const [initialPage, setInitialPage] = useState<number | undefined>(undefined);
  //This gives the next page to load, it is used in the load more results
  const [nextPageToLoad, setNextPageToLoad] = useState<number | undefined>(
    undefined,
  );

  const {
    dtoForSearchLeads,
    dtoForSearchLeadsSalesNav,
    getCurrentKeywordsForApplyFilters,
    filtersChanged,
    setFiltersChanged,
  } = useSearchContactTab();

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const maxReached = (total ?? 0) <= (nextPageToLoad ?? 0) * LIMIT_FIND_NEW;

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    handleSearch((nextPageToLoad ?? 0) + 1, true);
  };

  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);

  const handleSearch = async (offset: number, concat = false) => {
    setSelectedContactToInsert(null);
    setNextPageToLoad(offset);
    if (!concat) {
      setInitialPage(offset);
      setSelected([]);
    }
    setLoadingContacts(true);
    try {
      if (hasSalesNavWithCookie) {
        const leads = await searchLeadsSalesNav({
          ...filterUsed,
          start: (offset - 1) * LIMIT_FIND_NEW,
          count: LIMIT_FIND_NEW,
        });
        loadContacts(
          { fromSalesNav: true, leads: leads?.results ?? [] },
          concat,
        );
        if (analytics) {
          analytics.track('search-leads-sales-nav', {});
        }
      } else {
        // page for linkedin is always the pagination offset - 1
        const leads = await searchLeads({
          ...filterUsed,
          page: offset - 1,
        });

        loadContacts(
          { fromSalesNav: false, leads: leads?.profiles ?? [] },
          concat,
        );
        if (analytics) {
          analytics.track('search-leads', {});
        }
      }
    } catch (e) {
      console.error(e);
      setLoadingContacts(false);
    }
  };

  const applyFilters = async () => {
    if (filtersChanged) {
      //Since the currentText has to be applied as persona we add it to the personas (the state is asynchronous, so we have to duplicate it)
      const currentPersonas = getCurrentKeywordsForApplyFilters();
      setSelected([]);
      setFiltersChanged(false);
      setLoadingContacts(true);
      try {
        if (hasSalesNavWithCookie) {
          setFilterUsed(dtoForSearchLeadsSalesNav);
          const leads = await searchLeadsSalesNav({
            ...dtoForSearchLeadsSalesNav,
            start: 0,
            count: LIMIT_FIND_NEW,
          });
          loadContacts({ fromSalesNav: true, leads: leads?.results ?? [] });
          if (analytics) {
            analytics.track('search-leads-sales-nav', {});
          }
          setNextPageToLoad(1);
          setInitialPage(1);
        } else {
          setFilterUsed(dtoForSearchLeads);
          // linkedin search first page to fetch leads is 0, setPage sets only pagination selected page (first is 1)
          const leads = await searchLeads({
            ...dtoForSearchLeads,
            title: currentPersonas,
            page: 0,
          });
          loadContacts({ fromSalesNav: false, leads: leads?.profiles ?? [] });
          if (analytics) {
            analytics.track('search-leads', {});
          }
          setNextPageToLoad(1);
          setInitialPage(1);
        }
      } catch (e) {
        setLoadingContacts(false);
      }
    }
  };

  const { mutateAsync: checkProspectsOnDatabase } =
    useProspectLinkedinMultiple();

  const { mutateAsync: searchCompanyScrape } = useSearchCompanyScrape();
  const loadContacts = async (
    { fromSalesNav, leads }: LoadContactsType,
    concat = false,
  ) => {
    try {
      let contactsMapped: ContactForSearchTable[] = [];
      if (fromSalesNav) {
        const companyNumericIds: Set<string> = new Set();

        contactsMapped = leads.map((lead) => {
          const currentCompany = lead.currentPositions?.find(
            (pos) => pos.current,
          );
          if (currentCompany) {
            companyNumericIds.add(currentCompany.companyNumericId);
          }
          return {
            id: lead.leadId,
            leadId: lead.leadId,
            firstName: lead.firstName,
            lastName: lead.lastName,
            fullName: lead.fullName,
            email: null,
            emailScraped: 'no',
            emailStatus: null,
            phone: null,
            phoneScraped: 'no',
            phoneStatus: null,
            location: lead.geoRegion,
            pictureUrl: lead.pictureUrl ?? null,
            title: currentCompany?.title ?? lead.title,
            companyName: currentCompany?.companyName ?? null,
            companyNumericId: currentCompany?.companyNumericId ?? null,
            url: '',
            urlForRedirect: Constants.linkedinSalesNavLeadRedirectUrl(
              lead.leadId,
            ),
          };
        });

        //Check in the database if we already have information regarding the companies
        const companies = await searchCompanyScrape(
          Array.from(companyNumericIds).map((id) => ({
            numericId: id,
            publicId: null,
          })),
        );
        if (companies.data?.data && companies.data.data.length > 0) {
          contactsMapped = contactsMapped.map((contact) => {
            const currentCompany = contact.companyNumericId
              ? companies.data?.data.find(
                  (company) => company.numericId === contact.companyNumericId,
                )
              : null;
            if (currentCompany) {
              return {
                ...contact,
                currentCompanyAlreadyInDatabase: currentCompany,
              };
            } else {
              return contact;
            }
          });
        }
      } else {
        contactsMapped = leads
          .filter((contact) => contact.publicId !== 'headless')
          .map((lead) => {
            return {
              id: lead.publicId,
              publicId: lead.publicId,
              leadId: null,
              firstName: lead.firstName,
              lastName: lead.lastName,
              fullName: prospectNameFromLinkedinProfile(lead),
              email: null,
              emailScraped: 'no',
              emailStatus: null,
              phone: null,
              phoneScraped: 'no',
              location: lead.location ?? '',
              title: null,
              linkedinDescription: lead.title,
              pictureUrl: lead.pictureUrl,
              url: lead.url,
              urlForRedirect: lead.url,
              companyName: null,
              companyNumericId: null,
            };
          });
      }
      const similar = await checkProspectsOnDatabase({
        searchParameters: contactsMapped.map((contact) => {
          return {
            name: contact.fullName,
            leadId: contact.leadId,
            publicId: contact.id,
          };
        }),
      });
      //The result is in the same order as the input
      contactsMapped = contactsMapped.map((contact, index) => {
        const correctProspect = similar.data?.data
          .at(index)
          ?.prospects?.find((p) => p.correct);
        if (correctProspect) {
          contact.email = correctProspect.email;
          contact.phone = correctProspect.phone;
        }
        //If the prospect is correct we also extract the information regarding the current company
        //to display them in the columns
        return {
          ...contact,
          currentCompanyAlreadyInDatabase: correctProspect?.account
            ? //Put the values from the account of the prospect in a suitable format for the table
              //to match the one from the linkedin database
              {
                id: +correctProspect.account.objectId.split('_')[1],
                name: correctProspect.account.name,
                description: null,
                url: correctProspect.account.linkedin,
                website: correctProspect.account.website,
                industry: correctProspect.account.industry,
                location: correctProspect.account.country,
                numberOfEmployees: correctProspect.account.numberOfEmployees,
                pictureUrl: null,
                numericId: correctProspect.account.linkedinNumericId,
                expirationDate: new Date(),
              }
            : contact.currentCompanyAlreadyInDatabase,
          similarCompanies: similar.data?.data.at(index)?.accounts ?? null,
          similarContacts: similar.data?.data.at(index)?.prospects ?? null,
          correct:
            similar.data?.data.at(index)?.correct ||
            //For the ones from the database we have to check manually if one is correct, since the other parameter does not cover all the cases
            !!correctProspect,
        };
      });
      if (concat) {
        nonSelectable.current = nonSelectable.current.concat(
          new Array(contactsMapped.length).fill(false),
        );
        setContactsList((prev) => [...prev, ...contactsMapped]);
        setIsLoadingMore(false);
      } else {
        nonSelectable.current = new Array(contactsMapped.length).fill(false);
        setContactsList(contactsMapped);
      }
    } catch (e) {
      /** */
    } finally {
      setLoadingContacts(false);
    }
  };

  const handleChangeSingleContact = (contact: ContactForSearchTable) => {
    setContactsList((prev) => {
      const newContacts = [...prev];
      const index = contactsList.findIndex((c) => c.id === contact.id);
      if (index >= 0) {
        newContacts[index] = contact;
      }
      return newContacts;
    });
  };

  useEffect(() => {
    if (total && count) {
      setAvailableToSelectCount(
        contactsList.length -
          nonSelectable.current.filter((value) => value).length,
      );
    }
  }, [contactsList]);

  const [selectedContactToInsert, setSelectedContactToInsert] =
    useState<ContactForSearchTable | null>(null);

  const closeInsertContact = () => {
    setSelectedContactToInsert(null);
  };

  function handleAddClick(contact: ContactForSearchTable) {
    setSelectedContactToInsert(contact);
  }

  const [showAddMultiple, setShowAddMultiple] = useState<boolean>(false);

  const handleClickAddMultiple = () => {
    setShowAddMultiple(true);
  };

  const nonSelectable = useRef<boolean[]>(
    new Array(contactsList.length).fill(false),
  );

  const setIsContactSelectable = (index: number, value: boolean) => {
    //The method is called "is selectable" but it actually sets the contact as "not selectable"
    const actualValue = !value;
    nonSelectable.current[index] = actualValue;
    //In order to show the number of selectable contacts
    if (count && contactsList) {
      setAvailableToSelectCount(
        contactsList.length -
          nonSelectable.current.filter((value) => value).length,
      );
    }
  };

  const [availableToSelectCount, setAvailableToSelectCount] =
    useState<number>();

  const rowPlaceholder = rowPlaceholderFillOpacities.map((el, index) => {
    return (
      <div key={index}>
        <RowResultPlaceHolder fillOpacity={el} />
      </div>
    );
  });

  const noFiltersApplied = (
    <FlexDiv style={{ position: 'relative' }}>
      <div className="no-results">
        {rowPlaceholder}
        <div className="no-results-message">
          <NoFiltersApplied />
          <Typography fontSize={16} fontWeight={600}>
            Apply at least one filter to get results
          </Typography>
          <Typography fontSize={14} color={colors.grey11} width={'410px'}>
            Add one or more of the filters and click the “Apply filters”
          </Typography>
          <Typography fontSize={14} color={colors.grey11} width={'410px'}>
            button to search for contacts or companies
          </Typography>
        </div>
      </div>
    </FlexDiv>
  );

  const { data: status } = useGetExtensionStatus();
  const isDisabledExtension = useMemo(
    () =>
      status?.status === CronoExtensionStatus.NOT_INSTALLED ||
      searchContactsSalesNavError === 'SalesNav cookie missing or expired',
    [status, searchContactsSalesNavError],
  );

  const disabledExtensionPlaceholder = (
    <FlexDiv style={{ position: 'relative' }}>
      <div className="no-results">
        {rowPlaceholder}
        <div className="no-results-message">
          <DisabledExtensionPlaceholder />
          <Typography fontSize={16} fontWeight={600}>
            Crono’s Chrome extension currently not enabled
          </Typography>
          <Typography fontSize={14} color={colors.grey11} width={'410px'}>
            Please make sure your Crono’s extension is enabled and
          </Typography>
          <Typography fontSize={14} color={colors.grey11} width={'410px'}>
            is working correctly to find contacts
          </Typography>
        </div>
      </div>
    </FlexDiv>
  );

  const noContactsResults = (
    <FlexDiv style={{ position: 'relative' }}>
      <div className="no-results">
        {rowPlaceholder}
        <div className="no-results-message">
          <NoResults />
          <Typography fontSize={16} fontWeight={600} width={'310px'}>
            Sorry, we didn't find any results Please check the filters and try
            again
          </Typography>
        </div>
      </div>
    </FlexDiv>
  );

  const handleSetInCrono = (
    id: string,
    newContact: ProspectLinkedin | null,
  ) => {
    //The id passed is a contact that is now in Crono
    const contact = contactsList.find((contact) => contact.id === id);
    if (!contact) return;
    contact.correct = true;
    if (newContact) {
      contact.similarContacts = [newContact];
    }
    //Remove the selection if it was selected
    if (selected.includes(id)) {
      setSelected(selected.filter((value) => value !== id));
    }
    const index = contactsList.findIndex((contact) => contact.id === id);
    setIsContactSelectable(index, false);
    setContactsList([...contactsList]);
  };

  //We are currently allowing only one find email or phone per time, these states are used to do so
  // const [alreadySearchingEmail, setAlreadySearchingEmail] =
  //   useState<boolean>(false);
  // const [alreadySearchingPhone, setAlreadySearchingPhone] =
  //   useState<boolean>(false);

  //They may ask us to reintroduce this in the future
  /*
  useConditionalSnackBar([
    {
      condition: showErrorNoCreditsLeft.showError,
      message:
        'You do not have any Email or Phone finder credit left. Buy more credits to keep using these features',
      severity: 'error',
      customComponent: (
        <MainButton
          style={{
            height: '28px',
            width: '66px',
            fontSize: '14px',
            margin: '0 0 0 16px',
          }}
          onClick={() => {
            navigate(PATH.SETTINGS, {
              state: { tab: SettingsTabs.AddOns },
            });
          }}
        >
          Buy
        </MainButton>
      ),
    },
  ]);
  */

  const [alreadyEnriching, setAlreadyEnriching] = useState<boolean>(false);

  const [showEditColumns, setShowEditColumns] = useState<boolean>(false);

  const headCells = useMemo(() => {
    return getContactSearchHeadCells({
      hasSalesNav: hasSalesNavWithCookie ?? false,
      triggerEditColumnsPopup: () => setShowEditColumns((prev) => !prev),
      userPreferencesColumns:
        user?.userPreferences.findNewProspectColumns ??
        (hasSalesNavWithCookie
          ? defaultColumnsContactsSalesNav
          : defaultColumnsContactsLinkedin),
    });
  }, [
    getContactSearchHeadCells,
    setShowEditColumns,
    hasSalesNavWithCookie,
    user?.userPreferences.findNewProspectColumns,
  ]);

  const { call: enqueueEnrichJob } = useEnqueueAsyncEnrichJob();

  const handleEnrichMultiple = async () => {
    const toEnrich: Omit<AsyncEnrichJob, 'state' | 'id'>[] =
      enrichableContacts.map((contact) => ({
        identifiers: {
          companyNumericId: contact.companyNumericId ?? null,
          companyPublicId: null,
          publicId: contact.publicId ?? null,
          leadId: contact.leadId,
          url: contact.url,
          //If we are not in salesNav, we need to find the role if not already present
          findCurrentRole: !hasSalesNavWithCookie && !contact.title,
        },
        source: 'crono-fe',
      }));
    try {
      await enqueueEnrichJob(toEnrich);
      if (analytics) {
        analytics.track('linkedin-enrich-multiple-contacts', {
          count: enrichableContacts.length,
        });
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  return (
    <div className="search-component-body">
      {selectedContactToInsert && (
        <InsertContact
          selectedContact={selectedContactToInsert}
          close={closeInsertContact}
          selectedCompanyCrono={
            //If we have more than 1 companies selected we do not pass the company to the insert
            // (filterUsed.currentCompanies?.length ?? 0) > 1
            //   ? null
            //   : companyFilteredFor
            null
          }
          setContact={handleChangeSingleContact}
          handleSetInCrono={handleSetInCrono}
        />
      )}
      {showAddMultiple && contactsList && (
        <InsertMultipleContacts
          close={() => setShowAddMultiple(false)}
          contacts={contactsList?.filter(
            (contact) => contact.id && selected.includes(contact.id),
          )}
          onSubmit={() => {
            setShowAddMultiple(false);
            setSelected([]);
          }}
          //For salesNav, since we can filter for more companies, we consider the account that will be displayed inside the user, so we have to ignore the selected account
          // selectedAccountId={
          //   hasSalesNavWithCookie ? undefined : selectedAccount?.objectId
          // }
        />
      )}
      {filtersColumnWrapper(
        <SearchContactTabFilters
          applyFilters={applyFilters}
          isDisabledExtension={isDisabledExtension}
        />,
      )}

      <div className="search-results-section">
        <TableContainer style={{ flex: 1 }}>
          {/*If we are loding more the circular progress is shown in the load more button */}
          {loadingContacts && !isLoadingMore ? (
            <FlexDiv height="100%">
              <CircularProgress />
            </FlexDiv>
          ) : (
            <>
              {isDisabledExtension ? (
                disabledExtensionPlaceholder
              ) : // ): linkedinUrlError ? (
              // <FlexDiv style={{ position: 'relative' }}>
              //   <div className="no-results">
              //     {rowPlaceholder}
              //     <div
              //       className="no-results-message"
              //       style={{ position: 'absolute' }}
              //     >
              //       <NoContactsInStrategy />
              //       <Typography fontSize={16} fontWeight={600}>
              //         No results found. Invalid company's LinkedIn URL
              //       </Typography>
              //       <Typography
              //         fontSize={14}
              //         color={colors.grey11}
              //         width={'320px'}
              //       >
              //         The company's LinkedIn URL is not valid. Please change
              //         it and try again
              //       </Typography>
              //       {/**TODO: DECOMMENT THIS AND MAKE IT WORK */}
              //       {/* <MainButton onClick={handleChangeCompanyLinkedinUrl}>
              //         Change
              //       </MainButton> */}
              //     </div>
              //   </div>
              // </FlexDiv>
              !searchContactsResult &&
                !searchContactsSalesNavResult &&
                !contactsList.length ? (
                noFiltersApplied
              ) : contactsList.length === 0 ? (
                noContactsResults
              ) : (
                <>
                  <Table stickyHeader>
                    <CustomTableHeadSearchContactTab
                      headCells={headCells}
                      showSelectAll={true}
                      selectedStatus={
                        selected.length === 0
                          ? 0
                          : selected.length > 0 &&
                              !!availableToSelectCount &&
                              selected.length < availableToSelectCount
                            ? 1
                            : 2
                      }
                      clickSelectAll={handleClickGeneralCheckbox}
                    />

                    <TableBody style={{ width: '100%' }}>
                      {contactsList.map((contact, index) => {
                        if (!contact) return <></>;
                        return (
                          <ContactInsertCard
                            key={contact.id}
                            contact={contact}
                            handleSelectContact={handleSelectContact}
                            handleAddClick={handleAddClick}
                            selected={selected}
                            setIsContactSelectable={(value: boolean) =>
                              setIsContactSelectable(index, value)
                            }
                            setContact={handleChangeSingleContact}
                            //Removed for now from the UI, but may be reintroduced
                            // alreadySearchingEmail={alreadySearchingEmail}
                            // setAlreadySearchingEmail={setAlreadySearchingEmail}
                            // alreadySearchingPhone={alreadySearchingPhone}
                            // setAlreadySearchingPhone={setAlreadySearchingPhone}
                            alreadyEnriching={alreadyEnriching}
                            setAlreadyEnriching={setAlreadyEnriching}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>

                  {showEditColumns && (
                    <div className="edit-columns-dropdown">
                      <EditColumnsModal
                        editColumnsType={
                          hasSalesNavWithCookie
                            ? EditColumnsType.SalesContacts
                            : EditColumnsType.LinkedinContacts
                        }
                        onClose={() => setShowEditColumns(false)}
                        onApply={() => {
                          setShowEditColumns(false);
                        }}
                      />
                    </div>
                  )}

                  <div className="load-more-results-button">
                    {isLoadingMore ? (
                      <CircularProgress />
                    ) : !maxReached ? (
                      <div className="inner-button" onClick={handleLoadMore}>
                        <ReloadIcon />
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                          color={colors.primaryDark}
                        >
                          Load more results
                        </Typography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </TableContainer>
        {nextPageToLoad && initialPage && contactsList.length !== 0 ? (
          <Pagination
            total={loadingContacts ? undefined : total}
            count={loadingContacts ? undefined : count}
            itemOffset={(initialPage - 1) * LIMIT_FIND_NEW ?? 0}
            resultsShown={(nextPageToLoad - initialPage + 1) * LIMIT_FIND_NEW}
            setItemOffset={(offset: number) => {
              handleSearch(offset / LIMIT_FIND_NEW + 1);
            }}
            limit={LIMIT_FIND_NEW}
            selectionComponent={
              <SearchContactSelectionBottom
                selected={selected}
                areThereToEnrich={enrichableContacts.length > 0}
                handleEnrichAlll={handleEnrichMultiple}
                clearSelected={() => {
                  setSelected([]);
                }}
                onClickAddMultiple={handleClickAddMultiple}
              />
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default ContactSearchTab;
