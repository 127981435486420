import * as Yup from 'yup';

export const validationSchema = Yup.object({
  oldPassword: Yup.string()
    .min(4, 'Password must be at least 4 characters long')
    .required('Old password is required'),
  newPassword: Yup.string()
    .min(14)
    .max(31)
    .matches(/[A-Z]/)
    .matches(/[a-z]/)
    .matches(/\d/)
    .matches(/[!@#$%^&*()_+{}[\]:;"'<>,.?/~`|\\-]/)
    .required('New password is required'),
  confirmPassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match.")
    .required('Confirm password is required'),
});

export interface PasswordInputs {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};
