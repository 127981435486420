import {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { TaskSearch } from '../../../crono-fe-common/types/DTO/taskSearch';
import { Constants } from '../../../crono-fe-common/constants/constants';
import { useAuth } from '../../../context/auth';
import { TaskTodoType } from '../../../crono-fe-common/types/enums/taskTodoType';
import { TaskSortType } from '../../../crono-fe-common/types/enums/taskSortType';
import usePatchUserPreferences from '../../../hooks/services/user/usePatchUserPreferences';
import { FilterResumeWrapper } from 'pages/home/context/style';
import { Typography } from '@mui/material';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import useGetStrategyById from 'hooks/services/sequence/useGetStrategyById';

interface TaskFilterContextType {
  defaultFilters: TaskSearch;
  searchTasksFilters: TaskSearch;
  setSearchTasksFilters: React.Dispatch<React.SetStateAction<TaskSearch>>;
  resetTasksFilters: () => void;
  numberOfFilters: number;
  countableFilters: Partial<TaskSearch>;
  renderedCountableFilters: JSX.Element[];
}

interface TaskFiltersPreferences {
  filterType: TaskTodoType | null;
  filterAutomatic: boolean | null;
  filterShowCrmTasks: boolean;
  tasksOrder: TaskSortType;
  strategyId?: number;
}

export const TaskFilterContext = createContext<
  TaskFilterContextType | undefined
>(undefined);

TaskFilterContext.displayName = 'TaskFilterContext';

const TaskFilterProvider: FC<{ children: any }> = ({ children }) => {
  const defaultFilters: TaskSearch = {
    limit: Constants.defaultLimit,
    offset: 0,
    sortBy: 'CreatedDate',
    hasAutomationError: null,
    automatic: null,
  };

  const { user, enabledContextCalls } = useAuth();
  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const [searchTasksFilters, setSearchTasksFilters] =
    useState<TaskSearch>(defaultFilters);

  const { data: strategySelected } = useGetStrategyById(
    searchTasksFilters.strategyId ?? 0,
    (searchTasksFilters.strategyId ?? 0) > 0 && enabledContextCalls,
  );

  const countableFilters = {
    automatic: searchTasksFilters.automatic,
    strategyId: searchTasksFilters.strategyId,
    fromCrm: searchTasksFilters.fromCrm,
    hasAutomationError: searchTasksFilters.hasAutomationError,
  };

  const numberOfFilters = useMemo(() => {
    let count = 0;

    if (
      countableFilters.automatic !== null &&
      countableFilters.automatic !== undefined
    ) {
      count++;
    }

    if (countableFilters.strategyId) {
      count++;
    }

    if (countableFilters.fromCrm) count++;

    if (countableFilters.hasAutomationError) count++;

    return count;
  }, [countableFilters]);

  const resetTasksFilters = () => {
    setSearchTasksFilters(defaultFilters);
  };

  // Load user preferences
  useEffect(() => {
    if (!user) return;

    if (user?.userPreferences?.tasksFilters) {
      const tasksFilters: TaskFiltersPreferences = JSON.parse(
        user?.userPreferences?.tasksFilters,
      );

      setSearchTasksFilters((prev) => ({
        ...prev,
        strategyId: tasksFilters.strategyId,
        type: tasksFilters.filterType,
        automatic: tasksFilters.filterAutomatic,
        fromCrm: tasksFilters.filterShowCrmTasks,
        sortBy: tasksFilters.tasksOrder,
      }));
    }
  }, [user]);

  // Save user preferences
  useEffect(() => {
    if (user) {
      const newFilters: TaskFiltersPreferences = {
        filterType: searchTasksFilters.type ?? null,
        filterAutomatic: searchTasksFilters.automatic ?? null,
        filterShowCrmTasks: searchTasksFilters.fromCrm ?? false,
        tasksOrder: searchTasksFilters.sortBy ?? 'CreatedDate',
      };

      if (searchTasksFilters.strategyId) {
        newFilters.strategyId = searchTasksFilters.strategyId;
      }

      user.userPreferences.tasksFilters = JSON.stringify(newFilters);

      patchUserPreferences({
        tasksFilters: JSON.stringify(newFilters),
      });
    }
  }, [searchTasksFilters]);

  const renderedCountableFilters = useMemo(() => {
    const result: JSX.Element[] = [];

    Object.entries(countableFilters).map(([key, value], index) => {
      if (value === undefined || value === null) {
        return <></>;
      }

      let label = '';
      let onRemoveClick = () => {};

      switch (key) {
        case 'hasAutomationError':
          label = value ? 'Tasks in error' : '';
          onRemoveClick = () => {
            setSearchTasksFilters((prev) => ({
              ...prev,
              hasAutomationError: null,
            }));
          };
          break;
        case 'automatic':
          label = value ? 'Automatic' : 'Manual';
          onRemoveClick = () => {
            setSearchTasksFilters((prev) => ({
              ...prev,
              automatic: null,
            }));
          };
          break;
        case 'strategyId':
          label = strategySelected?.data?.data.name || 'Strategy selected';
          onRemoveClick = () => {
            setSearchTasksFilters((prev) => ({
              ...prev,
              strategyId: undefined,
            }));
          };
          break;
        case 'fromCrm':
          if (!value) return <></>;

          label = 'Remove CRM tasks';

          onRemoveClick = () => {
            setSearchTasksFilters((prev) => ({
              ...prev,
              fromCrm: false,
            }));
          };
          break;
      }

      if (label !== '') {
        result.push(
          <FilterResumeWrapper
            className="countable-filter-label"
            onClick={onRemoveClick}
          >
            <Typography noWrap>{label}</Typography>
            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>,
        );
      }
    });

    return result;
  }, [countableFilters]);

  return (
    <TaskFilterContext.Provider
      value={{
        defaultFilters,
        searchTasksFilters,
        setSearchTasksFilters,
        resetTasksFilters,
        numberOfFilters,
        countableFilters,
        renderedCountableFilters,
      }}
    >
      {children}
    </TaskFilterContext.Provider>
  );
};

export default TaskFilterProvider;

export function useTasksFilters() {
  const context = useContext(TaskFilterContext);
  if (context === undefined) {
    throw new Error(`useTaskFilters must be used within a TaskFilterContext`);
  }

  return context;
}
