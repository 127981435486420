import React, { useEffect, useMemo, useState } from 'react';
import { Typography, ClickAwayListener, Checkbox } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FilterFormWrapper } from './style';

import { PickedOpportunityFilters } from 'pages/opportunities';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import useGetPipelines from 'hooks/services/pipeline/useGetPipelines';
import useSearchOpportunity from 'hooks/services/opportunity/useSearchOpportunity';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  getRangeFromTimePeriod,
  setToMidDayOfPassedDate,
} from 'utils/fe-utils';
import { DateRangePickerTooltip } from 'components/DateRangePicker/modal';
import moment from 'moment';
import { OpportunitySearch } from 'crono-fe-common/types/DTO/opportunitySearch';
import { RangeKeyDict } from 'react-date-range';
import DateRangePicker from 'components/DateRangePicker';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import { ReactComponent as CalendarIcon } from 'crono-fe-common/icons/Calendar.svg';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { ReactComponent as IndeterminateIcon } from 'crono-fe-common/icons/IndeterminateCheck.svg';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import { ShowFilterOptionsWrapper } from 'context/strategyDetail/strategyContacts/style';
import { OpportunityStageListDTO } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import { TimePeriodCustom } from 'crono-fe-common/types/timePeriod';

interface IProps {
  close?: () => void;
  handleSubmit: (filters: PickedOpportunityFilters) => void;
  pickedFilters: PickedOpportunityFilters;
  selectedPipelineId: number | null;
}

const FilterOpportunities = ({
  close,
  handleSubmit,
  pickedFilters,
  selectedPipelineId,
}: IProps) => {
  // Duplicated logic but needed for showing previous value and saving future value before apply
  const [opportunityFilters, setOpportunityFilters] =
    useState<PickedOpportunityFilters>(pickedFilters);

  const [filterTooltipToggle, setFilterTooltipToggle] =
    useState<boolean>(false);

  // Pipeline/stage logic ----------------------------
  const { data: pipelines } = useGetPipelines();

  const selectedPipeline = useMemo(() => {
    return pipelines?.data?.data.find(
      (pipeline) => pipeline.id === selectedPipelineId,
    );
  }, [selectedPipelineId]);

  const { data: opportunityData } = useSearchOpportunity(
    {
      pipeline: selectedPipeline?.externalName ?? null,
      limit: 50,
    },
    !!selectedPipeline,
  );

  const [selectedStagesFilter, setSelectedStagesFilter] = useState<
    OpportunityStageListDTO[] | null
  >(pickedFilters.stages ?? null);

  //The stages that has to be shown in the correct order
  const stages = useMemo(() => {
    if (!opportunityData?.data?.data.opportunitiesByStages) return [];
    //I order them by stageOrder
    const entries = Object.entries(
      opportunityData?.data?.data.opportunitiesByStages,
    );
    entries.sort((a, b) => {
      return a[1].stageOrder - b[1].stageOrder;
    });
    //Remove the name, since it is already contained as property
    const stageList = entries.map((entry) => entry[1]);

    if (!selectedStagesFilter) {
      setSelectedStagesFilter(stageList);
    }

    return stageList;
  }, [opportunityData]);

  // Deal owner logic ---------------------
  const { data: users } = useGetUsers();

  const [selectedOwnerId, setSelectedOwnerId] = useState<number | null>(
    pickedFilters?.userId ?? null,
  );

  const selectedOwnerName = useMemo(() => {
    if (users?.data?.data) {
      const owner = users.data.data.find((user) => user.id === selectedOwnerId);
      const ownerName = owner ? `${owner.firstName} ${owner.lastName}` : null;

      return ownerName;
    }

    return null;
  }, [selectedOwnerId, users?.data?.data]);

  const findOwnerIdByName = (fullName: string) => {
    const [firstName, lastName] = fullName.split(' ');

    const owner = users?.data?.data.find(
      (user) => user.firstName === firstName && user.lastName === lastName,
    );
    return owner ? owner.id : null;
  };

  //Date logic ----------------------------
  const [closeDatePeriod, setCloseDatePeriod] = useState<TimePeriodCustom>(
    pickedFilters?.closePeriod ?? 'custom',
  );
  const [creationDatePeriod, setCreationDatePeriod] =
    useState<TimePeriodCustom>(pickedFilters?.createdPeriod ?? 'custom');

  const [closeDateFilters, setCloseDateFilters] = useState<Pick<
    OpportunitySearch,
    'closeDateEnd' | 'closeDateStart'
  > | null>({
    closeDateStart: pickedFilters?.closeDateStart ?? null,
    closeDateEnd: pickedFilters?.closeDateEnd ?? null,
  });

  const [creationDateFilters, setCreationDateFilters] = useState<Pick<
    OpportunitySearch,
    'createdDateEnd' | 'createdDateStart'
  > | null>({
    createdDateStart: pickedFilters?.createdDateStart ?? null,
    createdDateEnd: pickedFilters?.createdDateEnd ?? null,
  });

  const [openCloseDateTooltip, setOpenCloseDateTooltip] =
    useState<boolean>(false);

  const [openCreationDateTooltip, setOpenCreationDateTooltip] =
    useState<boolean>(false);

  useEffect(() => {
    if (closeDatePeriod === 'custom') return;
    const { start, end } = getRangeFromTimePeriod(closeDatePeriod);
    setCloseDateFilters({
      closeDateStart: start,
      closeDateEnd: end,
    });
  }, [closeDatePeriod]);

  useEffect(() => {
    if (creationDatePeriod === 'custom') return;
    const { start, end } = getRangeFromTimePeriod(creationDatePeriod);

    setCreationDateFilters({
      createdDateStart: start,
      createdDateEnd: end,
    });
  }, [creationDatePeriod]);

  useEffect(() => {
    setOpportunityFilters({
      closePeriod: closeDatePeriod === 'custom' ? null : closeDatePeriod,
      createdPeriod:
        creationDatePeriod === 'custom' ? null : creationDatePeriod,
      closeDateStart: closeDateFilters?.closeDateStart ?? null,
      closeDateEnd: closeDateFilters?.closeDateEnd ?? null,
      createdDateStart: creationDateFilters?.createdDateStart ?? null,
      createdDateEnd: creationDateFilters?.createdDateEnd ?? null,
      userId: selectedOwnerId,
      stages: selectedStagesFilter ?? null,
    });
  }, [
    closeDateFilters,
    closeDatePeriod,
    creationDatePeriod,
    creationDateFilters,
    selectedOwnerId,
    selectedStagesFilter,
  ]);

  const stagesOptions = (
    <ClickAwayListener onClickAway={() => setFilterTooltipToggle(false)}>
      <div style={{ width: '100%' }}>
        <ShowFilterOptionsWrapper>
          {stages?.map((option) => {
            return (
              <div
                key={option.stage}
                className="show-filter-option"
                style={{ padding: 0 }}
                onClick={() => {
                  if (
                    selectedStagesFilter?.find(
                      (el) => el.stage === option.stage,
                    )
                  ) {
                    setSelectedStagesFilter(
                      selectedStagesFilter.filter(
                        (el) => el.stage !== option.stage,
                      ),
                    );
                  } else {
                    setSelectedStagesFilter(
                      selectedStagesFilter
                        ? [...selectedStagesFilter, option]
                        : [option],
                    );
                  }
                }}
              >
                <label>
                  <Checkbox
                    icon={<IconUnchecked />}
                    checkedIcon={<CheckedIcon />}
                    indeterminateIcon={<IndeterminateIcon />}
                    checked={
                      !!selectedStagesFilter?.find(
                        (ss) => ss.stage === option.stage,
                      )
                    }
                  />
                </label>
                <Typography fontSize={'12px'} fontWeight={500}>
                  {option.stageName}
                </Typography>
              </div>
            );
          })}
        </ShowFilterOptionsWrapper>
      </div>
    </ClickAwayListener>
  );

  // ----
  const isAllStagesSelected = useMemo(() => {
    return (
      !stages ||
      (selectedStagesFilter && selectedStagesFilter.length === stages.length)
    );
  }, [selectedStagesFilter, stages]);

  const handleApply = (filters: PickedOpportunityFilters) => {
    if (isAllStagesSelected) {
      filters.stages = null;
    }

    handleSubmit(filters);

    if (close) close();
  };

  const handleClearFilters = () => {
    setSelectedOwnerId(null);
    setSelectedStagesFilter(stages);
    setCloseDateFilters({
      closeDateStart: undefined,
      closeDateEnd: undefined,
    });
    setCreationDateFilters({
      createdDateStart: undefined,
      createdDateEnd: undefined,
    });
    setCloseDatePeriod('custom');
    setCreationDatePeriod('custom');
  };

  const hasSelectedFilters = useMemo(
    () => Object.values(opportunityFilters).every((el) => el === null),
    [opportunityFilters],
  );

  return (
    <FilterFormWrapper>
      <div className="form-add-container">
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Owner:
          </Typography>
          <CronoAutocomplete
            value={selectedOwnerName || ''}
            placeholder={'Type the owner name...'}
            onChange={(ev: any, value: any) => {
              if (value) {
                const id = findOwnerIdByName(value);
                setSelectedOwnerId(id);
              } else {
                setSelectedOwnerId(null);
              }
            }}
            options={
              users?.data?.data.map(
                (user) => `${user.firstName} ${user.lastName}`,
              ) || []
            }
          />
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Stage:
          </Typography>
          <CustomOptionsTooltip
            open={filterTooltipToggle}
            title={stagesOptions}
            maxHeight={'256px'}
            sx={{ zIndex: '5002 !important' }}
          >
            <div
              className="show-filter-container"
              onClick={() => setFilterTooltipToggle((prev) => !prev)}
            >
              <Typography fontSize={'14px'} style={{ flex: 1 }} noWrap>
                {isAllStagesSelected
                  ? 'All stages'
                  : selectedStagesFilter
                      ?.map((option) => option.stageName)
                      .join(', ')}
              </Typography>
              {!isAllStagesSelected ? (
                <CloseMIcon
                  className="remove-text-icon"
                  color={colors.grey11}
                  onClick={() => {
                    setSelectedStagesFilter(stages);
                  }}
                />
              ) : null}
              {filterTooltipToggle ? (
                <TopArrowIcon className="tag-arrow" />
              ) : (
                <BottomArrowIcon className="tag-arrow" />
              )}
            </div>
          </CustomOptionsTooltip>
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Creation date:
          </Typography>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <PeriodSelect
              value={creationDatePeriod}
              setValue={setCreationDatePeriod}
            />
            <ClickAwayListener
              onClickAway={() => setOpenCreationDateTooltip(false)}
            >
              <div>
                <PickersRange
                  startDate={creationDateFilters?.createdDateStart ?? null}
                  endDate={creationDateFilters?.createdDateEnd ?? null}
                  close={setOpenCreationDateTooltip}
                  isOpen={openCreationDateTooltip}
                  handleSetStartDate={(value) => {
                    setCreationDateFilters((prev) => ({
                      ...prev,
                      createdDateStart: value,
                    }));
                    setCreationDatePeriod('custom');
                  }}
                  handleSetEndDate={(value) => {
                    setCreationDateFilters((prev) => ({
                      ...prev,
                      createdDateEnd: value,
                    }));
                    setCreationDatePeriod('custom');
                  }}
                />
              </div>
            </ClickAwayListener>
          </div>
        </div>
        <div className="form-box" style={{ minHeight: '90px' }}>
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Close date:
          </Typography>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <PeriodSelect
              value={closeDatePeriod}
              setValue={setCloseDatePeriod}
            />
            <ClickAwayListener
              onClickAway={() => setOpenCloseDateTooltip(false)}
            >
              <div>
                <PickersRange
                  close={setOpenCloseDateTooltip}
                  isOpen={openCloseDateTooltip}
                  startDate={closeDateFilters?.closeDateStart ?? null}
                  endDate={closeDateFilters?.closeDateEnd ?? null}
                  handleSetStartDate={(value) => {
                    setCloseDateFilters((prev) => ({
                      ...prev,
                      closeDateStart: value,
                    }));
                    setCloseDatePeriod('custom');
                  }}
                  handleSetEndDate={(value) => {
                    setCloseDateFilters((prev) => ({
                      ...prev,
                      closeDateEnd: value,
                    }));
                    setCloseDatePeriod('custom');
                  }}
                />
              </div>
            </ClickAwayListener>
          </div>
        </div>
        <FlexDiv
          width="fit-content"
          height="fit-content"
          style={{ alignSelf: 'flex-end' }}
        >
          {hasSelectedFilters ? (
            <CancelButton
              style={{ minWidth: '120px' }}
              onClick={() => {
                if (close) close();
              }}
            >
              Cancel
            </CancelButton>
          ) : (
            <div className="clear-filter" onClick={handleClearFilters}>
              <TrashCanDeleteIcon color={colors.inactive} />
              <Typography fontSize={'16px'} fontWeight={500}>
                Clear
              </Typography>
            </div>
          )}
          <MainButton
            style={{ minWidth: '120px' }}
            onClick={() => handleApply(opportunityFilters)}
          >
            Apply
          </MainButton>
        </FlexDiv>
      </div>
    </FilterFormWrapper>
  );
};

export default FilterOpportunities;

interface IPeriodSelectProps {
  value: TimePeriodCustom;
  setValue: React.Dispatch<React.SetStateAction<TimePeriodCustom>>;
}

const PeriodSelect = ({ value, setValue }: IPeriodSelectProps) => {
  return (
    <CronoSelect
      className={'select'}
      value={value || ''}
      onChange={(ev: any) => setValue(ev.target.value)}
      displayEmpty
    >
      <CronoMenuItem value="custom" className="select-option" disabled>
        Custom
      </CronoMenuItem>
      <CronoMenuItem value="today" className="select-option">
        Today
      </CronoMenuItem>
      <CronoMenuItem value="this-week" className="select-option">
        This Week
      </CronoMenuItem>
      <CronoMenuItem value="this-month" className="select-option">
        This Month
      </CronoMenuItem>
      <CronoMenuItem value="this-quarter" className="select-option">
        This Quarter
      </CronoMenuItem>
    </CronoSelect>
  );
};

interface IPickersRangeProps {
  endDate: Date | null;
  startDate: Date | null;
  isOpen: boolean;
  close: (value: React.SetStateAction<boolean>) => void;
  handleSetEndDate: (value: Date | null) => void;
  handleSetStartDate: (value: Date | null) => void;
}

interface ILocalDatePickerProps {
  value: Date | undefined;
  isEndPicker?: boolean;
}

const PickersRange: React.FC<IPickersRangeProps> = ({
  endDate,
  startDate,
  isOpen,
  close,
  handleSetEndDate,
  handleSetStartDate,
}) => {
  const LocalDatePicker: React.FC<ILocalDatePickerProps> = ({
    isEndPicker,
    value,
  }) => {
    const handleCalendarIconClick = () => {
      close((prev) => !prev);
    };

    const handlePickerChange = (ev: any) => {
      if (moment(ev).isValid()) {
        const newDate = new Date(ev ?? '');
        if (newDate?.getUTCHours() === 23) {
          newDate?.setHours(newDate.getHours() + 4);
        }

        if (isEndPicker) {
          handleSetEndDate(newDate);
        } else {
          handleSetStartDate(newDate);
        }
      }
    };

    const handleCalendarFocus = () => {
      close(true);
    };

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '12px',
          gap: '4px',
        }}
      >
        <CalendarIcon
          style={{ cursor: 'pointer' }}
          onClick={handleCalendarIconClick}
        />
        <DatePicker
          className="select-date-time-button"
          value={value}
          onChange={handlePickerChange}
          format="MMM d, yyyy"
          disableOpenPicker
          sx={{
            '& .MuiInput-input': {
              width: '96px',
            },
          }}
          slotProps={{
            textField: {
              onFocus: handleCalendarFocus,
              variant: 'standard',
              InputProps: {
                disableUnderline: true,
                placeholder: 'Select date',
              },
            },
          }}
        />
      </div>
    );
  };
  const handleCreatedDateChanges = (ranges: RangeKeyDict) => {
    let newMin: Date | undefined | null = ranges.selection.startDate;
    let newMax: Date | undefined | null = ranges.selection.endDate;
    let desiredMin = newMin;
    if (newMin) {
      desiredMin = setToMidDayOfPassedDate(newMin);
    }
    let desiredMax = newMax;
    if (newMax) {
      desiredMax = setToMidDayOfPassedDate(newMax);
    }
    newMin = desiredMin ? new Date(desiredMin) : null;
    newMax = desiredMax ? new Date(desiredMax) : null;
    handleSetStartDate(newMin);
    handleSetEndDate(newMax);
  };

  return (
    <DateRangePickerTooltip
      title={
        <DateRangePicker
          startDate={startDate ?? undefined}
          endDate={endDate ?? undefined}
          handleChangeRange={(ranges: RangeKeyDict) =>
            handleCreatedDateChanges(ranges)
          }
        />
      }
      open={isOpen}
      onClose={() => close(false)}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      sx={{
        zIndex: '5001 !important',
      }}
    >
      <div className="date-range-picker-container">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <LocalDatePicker value={startDate ?? undefined} />
          -
          <LocalDatePicker value={endDate ?? undefined} isEndPicker={true} />
        </LocalizationProvider>
      </div>
    </DateRangePickerTooltip>
  );
};
