import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';
import { CronoVoiceVisualizerWrapper } from './style';
import { ReactComponent as MicrophoneIcon } from 'crono-fe-common/icons/Icon-Microphone.svg';
import { ReactComponent as PlayIcon } from 'crono-fe-common/icons/Icon-Play.svg';
import { ReactComponent as TrashIcon } from 'crono-fe-common/icons/Trash.svg';
import { ReactComponent as PauseIcon } from 'crono-fe-common/icons/Icon-Pause.svg';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import { ReactComponent as DotIcon } from 'crono-fe-common/icons/Icon-Dot.svg';
import { colors } from 'crono-fe-common/theme';
import { blobToBase64 } from 'utils/fe-utils';
import { useConditionalSnackBar } from 'context/snackbar';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { CircularProgress } from '@mui/material';

interface IProps {
  onStartRecording: () => void;
  onClear: () => void;
  setIsAudioRecorded: (isAudioRecorded: boolean) => void;
  onSend: (recording: string, durationSeconds: number) => Promise<void>;
  isSending?: boolean;
}

const CronoVoiceVisualizer = ({
  onStartRecording,
  onClear,
  onSend,
  setIsAudioRecorded,
  isSending = false,
}: IProps) => {
  const [recordingTimeLimitError, setRecordingTimeLimitError] =
    useState<boolean>(false);

  const recorderControls = useVoiceVisualizer({
    onStartRecording,
    onClearCanvas: onClear,
  });

  const {
    // ... (Extracted controls and states, if necessary)
    isRecordingInProgress,
    recordingTime,
    duration,
    startRecording,
    stopRecording,
    recordedBlob,
    error,
    audioRef,
    togglePauseResume,
    clearCanvas,
  } = recorderControls;

  useEffect(() => {
    if (duration) {
      setIsAudioRecorded(true);
    } else {
      setIsAudioRecorded(false);
    }
  }, [duration]);

  useEffect(() => {
    let t: NodeJS.Timeout;

    if (recordingTime / 1000 > 59) {
      stopRecording();

      setRecordingTimeLimitError(true);
      t = setTimeout(() => {
        setRecordingTimeLimitError(false);
      }, 3000);
    }

    return () => {
      clearTimeout(t);
    };
  }, [recordingTime]);

  useConditionalSnackBar([
    {
      condition: recordingTimeLimitError,
      message: 'The recording stopped due to 1 min limit',
      severity: 'error',
    },
  ]);

  const formatRecordingTime = (milliseconds: number) => {
    // Convert milliseconds to seconds
    const totalSeconds = Math.floor(milliseconds / 1000);

    // Calculate minutes and seconds
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Add leading zeros if necessary
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

    // Return the formatted time
    return minutes + ':' + formattedSeconds;
  };

  //We need to set canvasWidth manually otherwise during first render it gets 0 width and no way for the wave to be seend while recording.
  //This way we set it when the state of the recorder changes, updating it manually to the correct width
  //When the audio is cleared set it back to 0 for correct visualization of the other buttons
  const [canvasWidth, setCanvasWidth] = useState<number>(0);

  useLayoutEffect(() => {
    if (!duration && !isRecordingInProgress) {
      setCanvasWidth(0);
      return;
    }
    const elements = document.getElementsByClassName('voice-visualizer');
    if (!elements || elements.length === 0) return;
    const visualizer = elements[0];
    const width = visualizer.scrollWidth;
    setCanvasWidth(width);
  }, [isRecordingInProgress, duration]);

  return (
    <CronoVoiceVisualizerWrapper>
      <div className={'controls-container'}>
        {!isRecordingInProgress && !duration && startRecording && (
          <div className={'button recorder-button'} onClick={startRecording}>
            <MicrophoneIcon />
            <p className="button-text">Start recording</p>
          </div>
        )}

        {isRecordingInProgress && (
          <div className={'dot-icon-container'}>
            <DotIcon />
          </div>
        )}

        {!!duration && (
          <button
            className={'circle-button play-pause-button'}
            onClick={togglePauseResume}
          >
            {audioRef.current?.paused ? <PlayIcon /> : <PauseIcon />}
          </button>
        )}

        {(isRecordingInProgress || !!duration) && (
          <p className={isRecordingInProgress ? 'recording-time' : ''}>
            {isRecordingInProgress
              ? 'Recording: ' + formatRecordingTime(recordingTime)
              : formatRecordingTime(duration * 1000)}
          </p>
        )}

        <VoiceVisualizer
          key={canvasWidth}
          ref={audioRef}
          controls={recorderControls}
          canvasContainerClassName={'voice-waves-container'}
          barWidth={4}
          gap={1}
          rounded={4}
          height={40}
          fullscreen={true}
          //Width has to be passed manually to the library
          width={canvasWidth}
          mainBarColor={
            isRecordingInProgress
              ? recordingTime / 1000 < 55
                ? colors.primary
                : colors.inactive
              : colors.primaryLightHover
          }
          secondaryBarColor={
            isRecordingInProgress ? colors.inactive : colors.primary
          }
          isDefaultUIShown={false}
          isControlPanelShown={false}
        />

        {(isRecordingInProgress || !!duration) && (
          <button
            className={'circle-button delete-button'}
            onClick={clearCanvas}
            disabled={isSending}
          >
            <TrashIcon />
          </button>
        )}

        {isRecordingInProgress && (
          <button
            className={'circle-button save-button'}
            onClick={stopRecording}
          >
            <CheckMarkIcon color={colors.callScheduled} />
          </button>
        )}

        {recordedBlob && duration ? (
          isSending ? (
            <CircularProgress className="bottom-button-position" />
          ) : (
            <MainButton
              style={{ margin: 0, height: 40 }}
              className="bottom-button-position send-button"
              onClick={async () => {
                const base64 = (await blobToBase64(recordedBlob)) as string;
                await onSend(base64, duration);
              }}
            >
              Send
            </MainButton>
          )
        ) : (
          <></>
        )}
      </div>
    </CronoVoiceVisualizerWrapper>
  );
};

export default CronoVoiceVisualizer;
