import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InviteNewUserFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 56px;
  gap: 48px;
  background: ${colors.white};
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 540px;
  z-index: 30;
  .form-row-invite-new {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;
