import { TemplateStep } from 'crono-fe-common/types/enums/templateStep';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { TemplateVariables } from 'crono-fe-common/types/template';
import { FeConstants } from 'constants/FeConstants';
import * as Yup from 'yup';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import { FileContent } from 'use-file-picker';
import { Constants } from 'crono-fe-common/constants/constants';

export const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  subject: Yup.string(),
  content: Yup.string().required('Content is required'),
});

export type TemplateFilter = 'Team' | 'Personal';

export interface TemplateInputs {
  title: string;
  subject: string;
  content: string;
  language: string | null;
  shared: boolean;
  type: TemplateType;
  step: TemplateStep;
  attachments: (CronoAttachment | FileContent)[];
}

export interface UpdateTemplateInputs {
  id: number;
  title: string;
  subject: string;
  language: string | null;
  content: string;
  shared: boolean;
  type: TemplateType;
}

export const initialValues: TemplateInputs = {
  title: '',
  subject: '',
  content: '',
  language: Constants.defaultLanguage,
  shared: false,
  type: TemplateType.EMAIL,
  step: TemplateStep.FIRST_TOUCH,
  attachments: [],
};

export interface IVariable {
  variable: TemplateVariables | string;
  text: string;
}

export const Variables: IVariable[] = [
  {
    variable: TemplateVariables.PROSPECT_NAME,
    text: 'Contact Name',
  },
  {
    variable: TemplateVariables.PROSPECT_SURNAME,
    text: 'Contact Surname',
  },
  {
    variable: TemplateVariables.PROSPECT_TITLE,
    text: 'Contact Title',
  },
  {
    variable: TemplateVariables.COMPANY_NAME,
    text: 'Contact Company',
  },
  {
    variable: TemplateVariables.USER_NAME,
    text: 'User Name',
  },
  {
    variable: TemplateVariables.USER_SURNAME,
    text: 'User Surname',
  },
  {
    variable: TemplateVariables.USER_COMPANY_NAME,
    text: 'User Company',
  },
];

export function isFileContent(object: any): object is FileContent {
  return 'content' in object;
}
