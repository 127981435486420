import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconRedirect = ({
  color = colors.grey3,
  className,
  onClick,
  style,
  onMouseEnter,
  onMouseLeave,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter ? onMouseEnter : undefined}
      onMouseLeave={onMouseLeave ? onMouseLeave : undefined}
      {...style}
    >
      <path
        d="M12 12L21 3V10V3H14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconRedirect;
