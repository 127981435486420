import React, { useMemo } from 'react';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  Checkbox,
  Typography,
  Tooltip,
} from '@mui/material';
import {
  ExternalProperty,
  TableType,
} from 'crono-fe-common/types/externalProperty';
import { ExternalValue } from 'crono-fe-common/types/externalValue';
import { StyledEPWrapper } from './style';
import {
  getExternalPropertyLabels,
  getExternalPropertyOptions,
} from 'crono-fe-common/utils';
import { colors } from 'crono-fe-common/theme';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';

interface IProps {
  externalValues:
    | (ExternalValue | { externalPropertyId: number; value: string })[]
    | null;
  externalProperty: ExternalProperty;
  owned: boolean;
  handleSetValue: (externalPropertyId: number, value: string) => void;
}

const InputExternalPropertyNotTag = ({
  externalValues,
  externalProperty,
  owned,
  handleSetValue,
}: IProps) => {
  const externalValue = externalValues?.find(
    (val) => val.externalPropertyId === externalProperty.id,
  );

  const component = useMemo(() => {
    switch (externalProperty.valueType) {
      case 'String':
        return (
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <TextField
              placeholder="Enter a value"
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              fullWidth
              defaultValue={externalValue?.value ?? ''}
              disabled={!owned}
              onKeyDown={(ev: any) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  ev.stopPropagation();
                  handleSetValue(externalProperty.id, ev.target.value);
                }
              }}
              onBlur={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleSetValue(externalProperty.id, ev.target.value);
              }}
            />
          </Tooltip>
        );

      case 'Text':
        return (
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <TextareaAutosize
              className="text-area"
              maxRows={4}
              disabled={!owned}
              placeholder="Enter a text"
              defaultValue={externalValue?.value || ''}
              onBlur={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleSetValue(externalProperty.id, ev.target.value);
              }}
            />
          </Tooltip>
        );

      case 'MultiSelect':
        return (
          <FormControl fullWidth>
            <Tooltip
              arrow
              title={owned ? '' : 'You are not the owner'}
              placement="bottom"
            >
              <Select
                variant="standard"
                value={externalValue?.value?.split(';') || []}
                renderValue={(values) => {
                  const labels = getExternalPropertyLabels(externalProperty);
                  return (
                    <Typography>
                      {(values as string[])
                        .filter((value) => value !== '')
                        .map((option) =>
                          labels.at(
                            externalProperty.options?.indexOf(option) ?? 0,
                          ),
                        )
                        .join(', ') || 'Select an option'}
                    </Typography>
                  );
                }}
                fullWidth
                size="small"
                multiple
                disabled={!owned}
                name={'' + externalProperty.id}
                onChange={(ev) => {
                  handleSetValue(
                    externalProperty.id,
                    (ev.target.value as string[]).join(';'),
                  );
                }}
                MenuProps={{
                  style: { zIndex: 35001 },
                  PaperProps: {
                    sx: {
                      borderRadius: '8px',
                      paddingInline: '6px',
                      border: '1px solid #dadde9',
                      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                      '& .MuiMenuItem-root': {
                        fontSize: '12px',
                        lineHeight: '16px',
                        height: 'fit-content',
                        padding: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        borderRadius: '8px',
                        '&:hover': {
                          background: colors.primaryLight,
                          color: colors.primary,
                        },
                      },
                      '& .Mui-selected': {
                        color: colors.primary,
                        backgroundColor: 'transparent !important',
                      },
                    },
                  },
                }}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '.MuiInput-input:focus': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {getExternalPropertyLabels(externalProperty).map(
                  (label, index) => (
                    <MenuItem
                      key={index}
                      value={
                        externalProperty.options
                          ? externalProperty.options[index]
                          : undefined
                      }
                    >
                      <Checkbox
                        icon={<IconUnchecked />}
                        checkedIcon={<IconChecked color={colors.mainDark} />}
                        checked={
                          (externalProperty.options &&
                            externalProperty.options[index] &&
                            externalValue?.value
                              ?.split(';')
                              .includes(externalProperty.options[index])) ||
                          false
                        }
                        sx={{ padding: '0px 8px 0px 0px !important' }}
                      />
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </Tooltip>
          </FormControl>
        );

      case 'Numeric':
        return (
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <TextField
              type="number"
              defaultValue={externalValue?.value}
              placeholder="Enter a numeric value"
              variant="standard"
              disabled={!owned}
              fullWidth
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              onKeyDown={(ev: any) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  ev.stopPropagation();
                  handleSetValue(externalProperty.id, ev.target.value);
                }
              }}
              onBlur={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleSetValue(externalProperty.id, ev.target.value);
              }}
            />
          </Tooltip>
        );

      case 'List':
        // Rendered value should be the same as the label
        const value = externalValue?.value || '';
        const labelIndex =
          getExternalPropertyOptions(externalProperty).findIndex(
            (val) => val === value,
          ) ?? -1;
        const selectedLabel =
          getExternalPropertyLabels(externalProperty)[labelIndex];

        return (
          //This is a trick to for the component to rerender correctly
          <FormControl fullWidth key={selectedLabel}>
            <Tooltip
              arrow
              title={owned ? '' : 'You are not the owner'}
              placement="bottom"
            >
              <CronoAutocomplete
                value={selectedLabel}
                fullWidth
                size="small"
                onChange={(ev, value) =>
                  handleSetValue(externalProperty.id, value as string)
                }
                options={externalProperty.options || []}
                disabled={!owned}
                placeholder="Add information..."
                slotProps={{
                  popper: {
                    sx: { zIndex: '35001 !important' },
                  },
                }}
                renderOption={(props, option) => {
                  const label = getExternalPropertyLabels(externalProperty);
                  return (
                    <li
                      {...props}
                      value={option}
                      className="autocomplete-option"
                    >
                      {label[externalProperty.options?.indexOf(option) || 0]}
                    </li>
                  );
                }}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                }}
                disablePortal={false}
                variant="standard"
              />
            </Tooltip>
          </FormControl>
        );

      case 'Boolean':
        return (
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <CronoSwitch
              checked={externalValue?.value === 'true' ? true : false}
              onChange={(ev) =>
                handleSetValue(
                  externalProperty.id,
                  ev.target.checked ? 'true' : 'false',
                )
              }
              disabled={!owned}
            />
          </Tooltip>
        );

      default:
        return <></>;
    }
  }, [externalProperty.valueType, owned, externalValue?.value, handleSetValue]);

  return (
    <StyledEPWrapper
      id={`${externalProperty.valueType}-${externalProperty.publicName}`}
    >
      <div className="form-box">
        <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
          {externalProperty.publicName}
          {externalProperty.isMandatory && '*'}:
        </Typography>
        {component}
      </div>
    </StyledEPWrapper>
  );
};

export default InputExternalPropertyNotTag;
