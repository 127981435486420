import { type } from 'os';
import { CronoCallSearchSortType } from './enums/cronoCallSearchSortType';
import { CronoEmailSearchSortType } from './enums/cronoEmailSearchSortType';
import { CronoLinkedinSearchSortType } from './enums/cronoLinkedinSearchSortType';
import { CallFeedback } from './logCall';

export interface ReportsFiltersCommon {
  since?: Date;
  to?: Date;
  limit: number;
  offset?: number;
  accountId?: string;
  userId?: number;
  callFeedback?: CallFeedback;
  isAnsweredLinkedin?: boolean;
  isAnsweredEmail?: boolean;
  isOpenedEmail?: boolean;
  isClickedEmail?: boolean;
  //
  cronoEmailSearchSortType?: CronoEmailSearchSortType;
  cronoLinkedinSearchSortType?: CronoLinkedinSearchSortType;
  cronoCallSearchSortType?: CronoCallSearchSortType;
}

// type ReportsFiltersEmail = ReportsFiltersCommon & {
//   cronoEmailSearchSortType?: CronoEmailSearchSortType;
// };
// type ReportsFiltersLinkedin = ReportsFiltersCommon & {
//   cronoLinkedinSearchSortType?: CronoLinkedinSearchSortType;
// };
// type ReportsFiltersCall = ReportsFiltersCommon & {
//   cronoCallSearchSortType?: CronoCallSearchSortType;
// };

export type ReportsFilters =
  // | ReportsFiltersCall
  // | ReportsFiltersEmail
  // | ReportsFiltersLinkedin;
  ReportsFiltersCommon;

export function getParams(reportsFilters: ReportsFilters) {
  const params: any = {};

  if (reportsFilters.since) {
    params.since = reportsFilters.since;
  }

  if (reportsFilters.to) {
    params.to = reportsFilters.to;
  }

  if (reportsFilters.limit) {
    params.limit = reportsFilters.limit;
  }

  if (reportsFilters.offset) {
    params.offset = reportsFilters.offset;
  }

  if (reportsFilters.accountId) {
    params.accountId = reportsFilters.accountId;
  }

  if (reportsFilters.userId) {
    params.userId = reportsFilters.userId;
  }

  if (reportsFilters.callFeedback) {
    params.callFeedback = reportsFilters.callFeedback;
  }

  if (reportsFilters.isAnsweredLinkedin !== undefined) {
    params.isAnsweredLinkedin = reportsFilters.isAnsweredLinkedin;
  }

  if (reportsFilters.isAnsweredEmail !== undefined) {
    params.isAnsweredEmail = reportsFilters.isAnsweredEmail;
  }

  if (reportsFilters.isOpenedEmail !== undefined) {
    params.isOpenedEmail = reportsFilters.isOpenedEmail;
  }
  if (reportsFilters.isClickedEmail !== undefined) {
    params.isClickedEmail = reportsFilters.isClickedEmail;
  }
  if (reportsFilters.cronoEmailSearchSortType) {
    params.cronoEmailSearchSortType = reportsFilters.cronoEmailSearchSortType;
  }
  if (reportsFilters.cronoLinkedinSearchSortType) {
    params.cronoLinkedinSearchSortType =
      reportsFilters.cronoLinkedinSearchSortType;
  }
  if (reportsFilters.cronoCallSearchSortType) {
    params.cronoCallSearchSortType = reportsFilters.cronoCallSearchSortType;
  }
  return params;
}
