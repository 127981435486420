import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const MicrophoneIcon = ({
  color = colors.white,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.25V11.25C7.75725 11.25 6.75 10.2428 6.75 9V4.5C6.75 3.25725 7.75725 2.25 9 2.25V2.25C10.2428 2.25 11.25 3.25725 11.25 4.5V9C11.25 10.2428 10.2428 11.25 9 11.25Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 7.5V9C13.5 11.4855 11.4855 13.5 9 13.5V13.5C6.5145 13.5 4.5 11.4855 4.5 9V7.5"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.5V15.75"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.86523 15.75H12.1352"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MicrophoneIcon;
