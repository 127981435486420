import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import Error from 'crono-fe-common/types/error';
import { IsSignUpEnabledDTO } from 'crono-fe-common/types/DTO/isSignUpEnabledDTO';

export default function useCheckSignUpEnabled(token: string) {
  const requestConfig = {
    queryKey: [ENDPOINTS.subscription.signup.isEnabled],
    queryFn: () => {
      const request: Request = {
        url: ENDPOINTS.subscription.signup.isEnabled,
        config: {
          method: 'post',
          data: { token },
        },
      };
      return client(request);
    },
  };

  return useQuery<Response<IsSignUpEnabledDTO>, Error>(requestConfig);
}
