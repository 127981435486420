import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SequenceSelectorWrapper = styled.div`
  width: 350px;
  height: 350px;
  font-size: 14px;
  padding: 12px 2px 0px 6px;
  overflow: hidden;
  .sequences-container {
    overflow: auto;
    height: calc(100% - 44px);
  }

  .remove-text-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;
