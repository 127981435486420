import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Strategy } from 'crono-fe-common/types/strategy';

export default function useGetStrategyById(id?: number, enabled = true) {
  const requestConfig = {
    queryKey: [ENDPOINTS.sequence.strategy, id],
    queryFn: () => {
      const request: Request = {
        url: `${ENDPOINTS.sequence.strategy}?StrategyId=${id}`,
        config: {
          method: 'get',
        },
      };
      return client(request);
    },
    enabled: !!id && enabled,
    staleTime: 5 * 60 * 1000,
  };

  return useQuery<Response<Strategy>, CronoError>(requestConfig);
}
