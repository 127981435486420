import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { DailyTasksNumber } from 'crono-fe-common/types/DTO/dailyTasksNumber';
import { formatDateForParameter } from 'utils/fe-utils';

export default function useGetTasksAggregate(since: Date, to: Date) {
  const queryParams = queryString.stringify({
    since: formatDateForParameter(since),
    to: formatDateForParameter(to),
  });

  const request: Request = {
    url: `${ENDPOINTS.task.aggregate}?${queryParams}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.task.aggregate, since, to],
    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<Response<DailyTasksNumber[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
