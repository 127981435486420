import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

export const SimilarContactsModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.white};
  border-radius: 16px;
  width: 600px;
  max-height: 600px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  .similar-contacts-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 16px 16px 16px;
    gap: 24px;
    overflow: hidden;
  }
  .similar-contacts-list {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  .similar-contact-card {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    height: fit-content !important;
    padding: 12px;
  }
`;

export const SimilarContactsButton = styled(Button)`
  text-transform: unset !important;
  background: ${colors.primaryLight};
  margin: 0px;
  color: ${colors.primary};
  width: 78px;
  height: 32px;
  border-radius: 16px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: start;
  padding: 4px;
  > span {
    margin-right: 4px;
    margin-left: 0px;
  }
  :hover {
    background: ${colors.primaryLightHover};
  }
`;
