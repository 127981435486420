import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const ContactsIconS = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      {...style}
    >
      <path
        id="Path"
        d="M1.33368 12.6667C1.33368 11.2 2.53368 10 4.00035 10H6.66701C8.13368 10 9.33368 11.2 9.33368 12.6667"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M7.00015 3.99945C7.93349 4.93278 7.93349 6.39945 7.00015 7.26612C6.06682 8.13278 4.60015 8.19945 3.73349 7.26612C2.86682 6.33278 2.80015 4.93278 3.66682 3.99945C4.53349 3.06612 6.06682 3.13278 7.00015 3.99945"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M10.667 9.33203H12.667C13.8003 9.33203 14.667 10.1987 14.667 11.332"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M12.867 4.46563C13.5336 5.13229 13.5336 6.19896 12.867 6.79896C12.2003 7.39896 11.1336 7.46563 10.5336 6.79896C9.93362 6.13229 9.86695 5.06563 10.5336 4.46563C11.1336 3.86563 12.2003 3.86563 12.867 4.46563"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContactsIconS;
