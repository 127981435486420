import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ThreadResume } from 'crono-fe-common/types/threadResume';

export default function useGetThreadsRecent(
  prospectId: string,
  threadId: string | null,
  enabled = true,
) {
  const queryParams = queryString.stringify({
    prospectId,
    ...(threadId ? { threadId: threadId } : {}),
  });

  const request: Request = {
    url: `${ENDPOINTS.email.thread.recent}?${queryParams}`,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.email.thread.recent, prospectId, threadId],
    queryFn: () => client(request),
    enabled: enabled,
  };

  const { data, ...rest } = useQuery<Response<ThreadResume[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
