import React from 'react';
import IProps from './interface';

const IconTriangle = ({ color, className, style }: IProps) => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <path
        d="M4.152 1.3568C4.54367 0.730128 5.45633 0.730129 5.848 1.3568L9.04375 6.47C9.46003 7.13605 8.98119 8 8.19575 8H1.80425C1.01881 8 0.539969 7.13605 0.956249 6.47L4.152 1.3568Z"
        fill={color}
      />
    </svg>
  );
};

export default IconTriangle;
