import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const BillingSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .billing-switch {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .badge {
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
    height: 20px;
    min-height: 20px;

    svg > path {
      stroke: ${colors.darkGreen};
    }
  }

  .badge-top {
    margin-left: 0;
  }

  .discount {
    color: ${colors.darkGreen};
    background-color: ${colors.primaryLightHover};
  }
`;
