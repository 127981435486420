import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconPipedrive = ({
  color = colors.white,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M6.85096 6.78595C6.85096 8.11153 7.52357 9.54152 9.00369 9.54152C10.1013 9.54152 11.2112 8.68476 11.2112 6.7665C11.2112 5.08471 10.3387 3.95565 9.04021 3.95565C7.98211 3.95565 6.85096 4.6988 6.85096 6.78595ZM9.56165 1.5C12.2155 1.5 14 3.60149 14 6.72966C14 9.80869 12.1202 11.9583 9.43281 11.9583C8.15152 11.9583 7.33081 11.4096 6.90168 11.0125C6.90473 11.1066 6.90676 11.2121 6.90676 11.3247V15.5H4.15752V4.37533C4.15752 4.2136 4.10578 4.16243 3.94549 4.16243H3V1.72621H5.30693C6.36908 1.72621 6.64096 2.26669 6.6927 2.6833C7.12386 2.20015 8.01761 1.5 9.56165 1.5Z"
        fill={color}
      />
    </svg>
  );
};

export default IconPipedrive;
