import React from 'react';
import { TypeCardWrapper } from './style';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as CircleIcon } from 'assets/images/Circle.svg';
import { ReactComponent as FilledCircleIcon } from 'assets/images/Filled_Circle.svg';

interface ITypeCardProps {
  onClick: () => void;
  active: boolean;
  image: string;
  title: string;
  subtitle: string;
  subtitleMaxWidth?: number;
}

const TypeCard: React.FC<ITypeCardProps> = ({
  active,
  onClick,
  image,
  title,
  subtitle,
  subtitleMaxWidth,
}) => {
  return (
    <TypeCardWrapper>
      <div className={`card ${active ? 'active' : ''}`} onClick={onClick}>
        <div className="card__circle-icon">
          {active ? (
            <FilledCircleIcon style={{ alignSelf: 'flex-start' }} />
          ) : (
            <CircleIcon style={{ alignSelf: 'flex-start' }} />
          )}
        </div>

        <div className={'content'}>
          <img src={image} alt={title} width={222} height={136} />

          <div className="content__info">
            <Typography
              fontSize={16}
              fontWeight={700}
              lineHeight={'24px'}
              marginBottom={'6px'}
              textAlign={'center'}
            >
              {title}
            </Typography>

            <Typography
              fontSize={14}
              fontWeight={400}
              lineHeight={'24px'}
              color={colors.grey11}
              textAlign={'center'}
              maxWidth={!subtitleMaxWidth ? '100%' : `${subtitleMaxWidth}px`}
            >
              {subtitle}
            </Typography>
          </div>
        </div>
      </div>
    </TypeCardWrapper>
  );
};

export default TypeCard;
