import React, { useEffect, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { InsertLinkedinForCompanyWrapper } from './style';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { Avatar, Checkbox, CircularProgress, Typography } from '@mui/material';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { CheckCircleRounded, RadioButtonUnchecked } from '@mui/icons-material';
import { LinkedinCompanyCompleteInfo } from 'crono-fe-common/types/crono-extension/linkedin';
import {
  useLinkedinGetCompanyInfo,
  useLinkedinSearchCompanies,
} from 'crono-fe-common/hooks/crono-extension/gateway';

interface IProps {
  close: () => void;
  searchText: string;
  setValues: (values: LinkedinCompanyCompleteInfo) => void;
}

const ResultsFromLinkedinAdd = ({ close, searchText, setValues }: IProps) => {
  const {
    call: searchCompanies,
    result: searchCompaniesResult,
    isLoading: isSearchCompaniesLoading,
  } = useLinkedinSearchCompanies();

  useEffect(() => {
    if (searchText) {
      searchCompanies({ keywords: searchText });
    }
  }, []);

  // useEffect(() => {
  //   console.log("searchCompaniesResult: ", searchCompaniesResult);
  // }, [searchCompaniesResult]);

  const [selected, setSelected] = useState<string | null>(null);

  const { call: getLinkedinCompanyInfo, result: additionalInfo } =
    useLinkedinGetCompanyInfo();

  useEffect(() => {
    if (!additionalInfo?.company) return;
    setValues(additionalInfo.company);
  }, [additionalInfo]);

  const handleConfirm = () => {
    if (!selected || !searchCompaniesResult) return;
    const selectedCompany = searchCompaniesResult.companies.find(
      (company) => company.numericId === selected,
    );
    if (!selectedCompany || !selectedCompany.url) return;
    getLinkedinCompanyInfo({
      url: selectedCompany.url,
    }).then();
  };

  return (
    <BlurBackgroundDivFixed style={{ zIndex: 15000 }}>
      <InsertLinkedinForCompanyWrapper>
        <div className="close-button">
          <CloseMIcon onClick={() => close()} color={colors.grey11} />
        </div>
        <div className="confirm-container">
          <Typography
            fontSize={24}
            fontWeight={700}
            lineHeight={'30px'}
            style={{ paddingBottom: 12 }}
          >
            Please select one:
          </Typography>
          {isSearchCompaniesLoading && (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          )}
          {searchCompaniesResult &&
            searchCompaniesResult.companies.map((company, index) => {
              return (
                <div
                  key={index}
                  className="confirm-company-card"
                  onClick={() => setSelected(company.numericId || null)}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: 8,
                    }}
                  >
                    <Avatar src={company.logoUrl} />
                    <div style={{ display: 'flex', width: 300 }}>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        lineHeight={'18px'}
                        noWrap
                      >
                        {company.title}
                      </Typography>
                    </div>
                  </div>
                  <Checkbox
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircleRounded color="secondary" />}
                    checked={selected === company.numericId}
                    sx={{ padding: 0 }}
                  />
                </div>
              );
            })}
          <FlexDiv
            width="fit-content"
            height="fit-content"
            style={{ alignSelf: 'flex-end' }}
          >
            <CancelButton onClick={() => close()}>Cancel</CancelButton>
            <MainButton
              onClick={handleConfirm}
              disabled={!selected}
              className={!selected ? 'confirm-disabled' : undefined}
            >
              Confirm
            </MainButton>
          </FlexDiv>
        </div>
      </InsertLinkedinForCompanyWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default ResultsFromLinkedinAdd;
