import { Button, Typography } from '@mui/material';
import React from 'react';
import { TooltipMoreMenuWrapper } from './style';

interface IProps {
  openDateCalendar: () => void;
  skipTask: () => void;
}

const TooltipMoreMenu = ({ openDateCalendar, skipTask }: IProps) => {
  return (
    <TooltipMoreMenuWrapper>
      <Button
        variant="text"
        color="secondary"
        className="option-button"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          openDateCalendar();
        }}
      >
        Reschedule task
      </Button>
      <Button
        variant="text"
        color="secondary"
        className="option-button"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          skipTask();
        }}
      >
        Skip
      </Button>
    </TooltipMoreMenuWrapper>
  );
};

export default TooltipMoreMenu;
