import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type StopProspectSequenceInputs = {
  sequenceInstanceId: number;
  prospectId: string;
};

export default function useStopProspectSequence() {
  const queryClient = useQueryClient();

  const stopProspectSequence = (data: StopProspectSequenceInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.prospect}`,
      config: {
        method: 'delete',
        data: data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    StopProspectSequenceInputs
  >(stopProspectSequence, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.account.main]);
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
      queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);

      queryClient.invalidateQueries([ENDPOINTS.task.total]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.prospect]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);

      queryClient.invalidateQueries([ENDPOINTS.prospect.search.strategy]);
      queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);

      queryClient.invalidateQueries([ENDPOINTS.task.total]);
    },
  });

  return { data, mutate, ...rest };
}
