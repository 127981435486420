import React from 'react';
import Xarrow, { xarrowPropsType } from 'react-xarrows';
import { colors } from 'crono-fe-common/theme';
import { Typography } from '@mui/material';
import { InvitationBadgeWrapper } from './style';
import classNames from 'classnames';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import ErrorIndicatorIcon from 'crono-fe-common/icons/Icon-Error-Indicator';

const StrategyXarrow = (props: xarrowPropsType) => {
  return (
    <Xarrow
      startAnchor={'bottom'}
      endAnchor={'top'}
      strokeWidth={1}
      path={'grid'}
      showHead={false}
      gridBreak={'30%'}
      zIndex={-2}
      {...props}
    />
  );
};

export default StrategyXarrow;

interface IInvitationBadgeProps {
  extraMargin: boolean;
}
export const InvitationAcceptedBadge = ({
  extraMargin,
}: IInvitationBadgeProps) => (
  <InvitationBadgeWrapper
    className={classNames('invitation-accepted-badge', {
      'invitation-badge-margin-large': extraMargin,
    })}
  >
    <CheckMarkIcon
      size={16}
      color={colors.darkGreen}
      color2={colors.primaryLightHover}
    />
    <Typography
      fontSize={12}
      lineHeight={'16px'}
      fontWeight={500}
      color={colors.darkGreen}
      noWrap
    >
      If invitation is accepted
    </Typography>
  </InvitationBadgeWrapper>
);

export const InvitationNotAcceptedBadge = ({
  extraMargin,
}: IInvitationBadgeProps) => (
  <InvitationBadgeWrapper
    className={classNames('invitation-not-accepted-badge', {
      'invitation-badge-margin-large': extraMargin,
    })}
  >
    <ErrorIndicatorIcon color2={colors.inactiveLightHover} />
    <Typography
      fontSize={12}
      lineHeight={'16px'}
      fontWeight={500}
      color={colors.inactive}
      noWrap
    >
      If invitation is not accepted
    </Typography>
  </InvitationBadgeWrapper>
);
