import { useAuth } from 'context/auth';
import { FC, useEffect, useMemo, useState } from 'react';
import { LoginWrapper } from './styles';
import { useFormik } from 'formik';
import { Button, Typography } from '@mui/material';
import { Input } from 'crono-fe-common/components/FormComponents';
import PATH from 'routing/path';
import Logo from '../../assets/images/logo_crono.png';
import Page from 'crono-fe-common/components/Layout/Page';
import { useNavigate, useLocation } from 'react-router-dom';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { CircularProgress } from '@mui/material';
import queryString from 'query-string';
import { useConditionalSnackBar } from 'context/snackbar';
import { useSelectedAccount } from 'pages/home/context/account';
import {
  Inputs,
  initialValues,
  validationSchema,
} from 'crono-fe-common/types/DTO/loginCredentials';
import { getError } from 'crono-fe-common/utils';
import { colors } from 'crono-fe-common/theme';

import { ReactComponent as EmailInboxIcon } from 'crono-fe-common/icons/Email-Inbox.svg';

const Login: FC = () => {
  const { user, error, login, isLoading: isLoading, logout } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state } = useLocation();
  const { setAccount, setSelectedAccountId } = useSelectedAccount();

  const formik = useFormik<Inputs>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      login(values);
      setAccount(null);
      setSelectedAccountId(null);
    },
  });

  const [showFirstLoginMessage, setShowFirstLoginMessage] =
    useState<boolean>(false);
  useEffect(() => {
    const returnUrl = queryString.parse(search).returnUrl;

    if (state?.error) {
      setAccount(null);
      setSelectedAccountId(null);
      logout();
      return;
    }
    if (user && returnUrl) {
      if (returnUrl.includes('login')) {
        navigate(PATH.HOME);
      } else {
        navigate((returnUrl as string) || PATH.HOME);
      }
      return;
    }
    if (user) {
      navigate(PATH.HOME, {});
      return;
    } else {
      setAccount(null);
      setSelectedAccountId(null);
      //If this is a new user coming from the firstLogin page, we show the message
      if (state?.firstLoginMessageToShow) {
        setShowFirstLoginMessage(true);
      } else {
        logout();
      }
    }
  }, [user, state]);

  useConditionalSnackBar([
    {
      condition: !!error && error.response?.status === 401,
      message: getError(error ?? null) ?? 'Invalid credentials',
      severity: 'error',
    },
    {
      condition:
        !!error &&
        error.response?.status !== 401 &&
        error.response?.status !== 402,
      message: 'Something went wrong, please try again',
      severity: 'error',
    },
  ]);

  // if (isLoading) {
  //   return (
  //     <FlexDiv>
  //       <CircularProgress />
  //     </FlexDiv>
  //   );
  // }

  return (
    <Page>
      {!user && (
        <LoginWrapper>
          <>
            <div className="login-card">
              <div className="login-text">
                <h2>Welcome to</h2>
                <img src={Logo} className="logo" alt="Crono Logo" />
                <h2>Prospecting made easy</h2>
                <p>Login and start prospecting now</p>
              </div>

              <form onSubmit={formik.handleSubmit} className="login-form">
                {showFirstLoginMessage && (
                  <div className="box-first-login-message">
                    <div className="box-first-login-message-icon">
                      <EmailInboxIcon />
                    </div>
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      lineHeight={'24px'}
                      color={colors.grey1}
                    >
                      Check your email for your password
                    </Typography>
                  </div>
                )}
                <Input
                  className="form-field"
                  fullWidth
                  id="email"
                  name="email"
                  label="E-mail"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{ shrink: true }}
                />
                <Input
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputLabelProps={{ shrink: true }}
                />
                <p
                  className="forgot-password-link"
                  onClick={() => {
                    navigate(PATH.FORGOT_PASSWORD);
                  }}
                >
                  Forgot password ?
                </p>

                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  LOGIN
                </Button>
              </form>
            </div>
          </>
        </LoginWrapper>
      )}
    </Page>
  );
};

export default Login;
