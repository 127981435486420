import { Request } from 'crono-fe-common/types/request';
import client from 'utils/clients/client';
import { ENDPOINTS } from '../../../config/endpoints';

export type HubspotVerifyIntegrationResult = {
  data: { isSuccess: boolean };
};

class HubspotService {
  private _alreadyRunningVerifyHubspotIntegrationCall: Promise<HubspotVerifyIntegrationResult> | null =
    null;

  public async verifyHubspotIntegration(): Promise<HubspotVerifyIntegrationResult> {
    if (this._alreadyRunningVerifyHubspotIntegrationCall) {
      return await this._alreadyRunningVerifyHubspotIntegrationCall;
    } else {
      this._alreadyRunningVerifyHubspotIntegrationCall =
        this._verifyHubspotIntegration();
      try {
        const verifyResult =
          await this._alreadyRunningVerifyHubspotIntegrationCall;
        return verifyResult;
      } catch (e) {
        return {
          data: {
            isSuccess: false,
          },
        };
      } finally {
        this._alreadyRunningVerifyHubspotIntegrationCall = null;
      }
    }
  }

  private async _verifyHubspotIntegration(): Promise<HubspotVerifyIntegrationResult> {
    const request: Request = {
      url: ENDPOINTS.hubspot.verify,
      config: {
        method: 'get',
      },
    };

    const result = await client(request);
    return result as HubspotVerifyIntegrationResult;
  }
}

// Esporto una sola istanza del servizio, in modo che tutti gli altri file che importano
// questo file importeranno sempre la stessa istanza
export default new HubspotService();
