import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ResetPasswordPageWrapper = styled('div')`
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .header {
    text-align: center;

    h2 {
      font-size: 28px;
      margin: 0;
    }

    p {
      margin-top: 5px;
      font-size: 16px;
      color: ${colors.grey11};
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 0 5px;

    .form-control {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      width: 100%;
    }

    .form-control Input {
      display: block;
      width: 100%;
      height: 10px;
    }

    button {
      margin: 30px 0 0 0;
    }
  }
`;
