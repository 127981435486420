import React, { useEffect, useMemo, useState } from 'react';
import { FindNewTableCell } from '.';
import { useAuth } from 'context/auth';
import { colors } from 'crono-fe-common/theme';
import { Tooltip, Typography } from '@mui/material';
import { ContactForSearchTable } from '../model';
import { GreyButton } from 'crono-fe-common/components/CronoButton';
import { AboutContainer } from 'pages/searchComponent/searchCompanyTab/companyInsertCard';
import {
  useBackgroundMarkEnrichJobsAsRead,
  useLinkedinGetCompanyInfo,
  useLinkedinGetProfileInfo,
  useLinkedinGetUserExperiences,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import { useGetAsyncEnrichQueueState } from 'hooks/services/crono-extension/useGetAsyncEnrichQueueState';
import EnrichIcon from 'crono-fe-common/icons/Icon-Enrich';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  contact: ContactForSearchTable;
  columnsToShow: string[] | '';
  alreadyEnriching: boolean;
  setAlreadyEnriching: React.Dispatch<React.SetStateAction<boolean>>;
  setContact: (contact: ContactForSearchTable) => void;
  setIsContactSelectable: (isSelectable: boolean) => void;
  handleShowError: (error: string) => void;
}

const CompanyInformationColumnsForContact = ({
  contact,
  columnsToShow,
  alreadyEnriching,
  setAlreadyEnriching,
  setContact,
  setIsContactSelectable,
  handleShowError,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const { hasSalesNavWithCookie } = useAuth();
  //Filter among the columns to show only the ones related to the company
  const companyColumns = useMemo(() => {
    if (!columnsToShow) return [];
    return columnsToShow.filter((column) =>
      [
        'jobTitle',
        'companyName',
        'companyIndustry',
        'companySize',
        'companyDescription',
        'companyHeadquarter',
        'companyWebsite',
      ].includes(column),
    );
  }, [columnsToShow]);
  const colSpanForEnrichCell = //If we are in salesNav scenario and we already have the company name and the job title, we have to show less columns
    hasSalesNavWithCookie &&
    (columnsToShow.includes('companyName') ||
      columnsToShow.includes('jobTitle'))
      ? companyColumns.length -
        (columnsToShow.includes('companyName') &&
        columnsToShow.includes('jobTitle')
          ? 2
          : 1)
      : companyColumns.length;

  const { call: getCompanyInfo, isLoading: isLoadingGetCompanyInfo } =
    useLinkedinGetCompanyInfo();

  const { call: getProfileInfo } = useLinkedinGetProfileInfo();

  const { call: getUserExperiences, isLoading: isLoadingGetUserExperiences } =
    useLinkedinGetUserExperiences();

  const [error, setError] = useState<string | null>(null);

  const handleEnrichClick = async () => {
    setAlreadyEnriching(true);
    if (analytics) {
      analytics.track('linkedin-enrich-single-contact', {});
    }
    try {
      //If we already have the numericId (in case of salesNav) we can directly get the info
      if (contact.companyNumericId) {
        const companyInfo = await getCompanyInfo({
          numericId: contact.companyNumericId,
        });
        if (companyInfo?.company) {
          setContact({
            ...contact,
            currentCompanyAlreadyInDatabase: {
              ...companyInfo.company,
              //Id is actually not usefull
              id: companyInfo.company.numericId
                ? +companyInfo.company.numericId
                : 1,
              expirationDate: new Date(),
            },
          });
        } else {
          throw new Error('Company not found');
        }
      } else {
        //If not we have to do an additional call to retrieve the current company of the prospect
        const profileInfo = await getProfileInfo({
          url: contact.url,
          profileId: contact.profileId,
        });
        if (profileInfo?.profile) {
          if (!profileInfo.profile.currentCompany) {
            throw new Error('Company is missing');
          }
          const currentCompany = profileInfo.profile.currentCompany;
          const companyInfo = await getCompanyInfo({
            numericId: currentCompany.numericId,
            url: currentCompany.url,
          });
          if (companyInfo?.company) {
            //Get the experiences to find the current job title
            const experiences = await getUserExperiences({
              url: contact.url,
              profileId: contact.profileId,
            });
            let currentJobTitle: string | null = '';
            if (experiences && experiences.length > 0) {
              const currentExperience = experiences.find(
                (exp) => exp.numericId === companyInfo.company?.numericId,
              );
              if (currentExperience) {
                currentJobTitle = currentExperience.role ?? null;
              }
            }

            setContact({
              ...contact,
              currentCompanyAlreadyInDatabase: {
                ...companyInfo.company,
                //Id is actually not usefull
                id: companyInfo.company.numericId
                  ? +companyInfo.company.numericId
                  : 1,
                expirationDate: new Date(),
              },
              title: currentJobTitle,
            });
          } else {
            throw new Error('Company not found');
          }
        } else {
          throw new Error('Profile not found');
        }
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setAlreadyEnriching(false);
    }
  };

  //Enrich company button
  const { data: queueEnrich } = useGetAsyncEnrichQueueState();

  const [isProspectLinkedinEnriching, setIsProspectLinkedinEnriching] =
    useState<boolean>(false);

  const [isLinkedinEnriched, setIsLinkedinEnriched] = useState<boolean>(false);

  useEffect(() => {
    const isImporting = Boolean(
      queueEnrich?.contactsToEnrich.find(
        (c) =>
          (c.identifiers.companyNumericId &&
            c.identifiers.companyNumericId === contact.companyNumericId &&
            c.identifiers.leadId &&
            c.identifiers.leadId === contact.leadId) ||
          ((c.identifiers.publicId || c.identifiers.url) &&
            c.identifiers.publicId === contact.publicId &&
            c.identifiers.url === contact.url),
      ),
    );

    setIsProspectLinkedinEnriching(isImporting);

    if (isImporting) {
      setIsLinkedinEnriched(false);
    }

    if (isImporting) {
      setIsContactSelectable(false);
    }
  }, [queueEnrich?.contactsToEnrich, contact]);

  const { call: markEnrichJobsAsRead } = useBackgroundMarkEnrichJobsAsRead();

  useEffect(() => {
    if (
      queueEnrich &&
      queueEnrich.contactsEnriched.length > 0 &&
      !isLinkedinEnriched
    ) {
      const enrichedContact = queueEnrich.contactsEnriched.find(
        (c) =>
          (c.identifiers.companyNumericId &&
            c.identifiers.companyNumericId === contact.companyNumericId &&
            c.identifiers.leadId &&
            c.identifiers.leadId === contact.leadId) ||
          ((c.identifiers.publicId || c.identifiers.url) &&
            c.identifiers.publicId === contact.publicId &&
            c.identifiers.url === contact.url),
      );
      if (!enrichedContact) return;
      setIsLinkedinEnriched(true);
      markEnrichJobsAsRead({
        jobIds: [enrichedContact.id],
      });
      if (enrichedContact.error) {
        handleShowError(
          enrichedContact.error === 'missingCookie'
            ? 'Error while enriching, check to be logged into linkedin with Crono'
            : enrichedContact.error,
        );
        setError(enrichedContact.error ?? 'Error while enriching');
        setIsProspectLinkedinEnriching(false);
        setIsContactSelectable(true);
        return;
      }
      setIsProspectLinkedinEnriching(false);

      if (enrichedContact.company) {
        setContact({
          ...contact,
          currentCompanyAlreadyInDatabase: {
            ...enrichedContact.company,
            id: enrichedContact.company.numericId
              ? +enrichedContact.company.numericId
              : 1,
            expirationDate: new Date(),
          },
          ...(enrichedContact.identifiers.findCurrentRole &&
          enrichedContact.currentRole
            ? {
                title: enrichedContact.currentRole,
              }
            : {}),
        });
      }
      setIsContactSelectable(true);
    }
  }, [queueEnrich?.contactsEnriched]);

  return (
    <>
      {/*Columns related to the company. If the company is already enriched (detectable by the currentCompanyAlreadyInDatabase attribute)
        we show each value, otherwise we have to show a single cell with the enrich button */}
      {!contact.currentCompanyAlreadyInDatabase ? (
        <>
          {/*The current company name is already populated if we are in salesNav */}
          {hasSalesNavWithCookie && (
            <>
              {/*Current company name */}
              {columnsToShow.includes('companyName') && (
                <FindNewTableCell
                  style={{
                    paddingLeft: 12,
                    width: 250,
                    minWidth: 250,
                    borderRight: `1px solid ${colors.grey444}`,
                  }}
                >
                  <span
                    style={{
                      width: 250,
                      minWidth: 250,
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <Typography
                      fontSize={14}
                      lineHeight={'24px'}
                      color={colors.grey11}
                      noWrap
                    >
                      {contact.companyName}
                    </Typography>
                  </span>
                </FindNewTableCell>
              )}
              {/*Job title for salesNav */}
              {columnsToShow.includes('jobTitle') && (
                <FindNewTableCell
                  style={{
                    paddingLeft: 12,
                    width: 250,
                    minWidth: 250,
                    borderRight: `1px solid ${colors.grey444}`,
                  }}
                >
                  <span
                    style={{
                      width: 250,
                      minWidth: 250,
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <Tooltip
                      title={contact.title}
                      arrow
                      enterDelay={800}
                      enterNextDelay={800}
                    >
                      <Typography
                        fontSize={14}
                        lineHeight={'24px'}
                        color={colors.grey11}
                        noWrap
                      >
                        {contact.title}
                      </Typography>
                    </Tooltip>
                  </span>
                </FindNewTableCell>
              )}
              {/*Location */}
              {hasSalesNavWithCookie && columnsToShow.includes('location') && (
                <FindNewTableCell
                  style={{
                    paddingLeft: 12,
                    width: 250,
                    minWidth: 250,
                    borderRight: `1px solid ${colors.grey444}`,
                  }}
                >
                  <span
                    style={{
                      width: 250,
                      minWidth: 250,
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <Typography
                      fontSize={14}
                      lineHeight={'24px'}
                      color={colors.grey11}
                      noWrap
                    >
                      {contact.location}
                    </Typography>
                  </span>
                </FindNewTableCell>
              )}
            </>
          )}
          {colSpanForEnrichCell > 0 && (
            <FindNewTableCell
              colSpan={colSpanForEnrichCell}
              style={{
                paddingTop: 8.5,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              {isProspectLinkedinEnriching ||
              isLoadingGetCompanyInfo ||
              isLoadingGetUserExperiences ? (
                <div
                  className="importing-loader-container"
                  style={{ width: 78, height: 32 }}
                >
                  <span className="loader importing-loader"></span>
                </div>
              ) : error ? (
                <Typography
                  fontSize={12}
                  lineHeight={'18px'}
                  fontWeight={500}
                  color={colors.inactive}
                  maxWidth={700}
                  noWrap
                >
                  {error}
                </Typography>
              ) : (
                <GreyButton
                  startIcon={
                    <EnrichIcon
                      color={colors.intenseBlue}
                      style={{
                        width: 24,
                        height: 24,
                      }}
                    />
                  }
                  style={{
                    height: 32,
                    borderRadius: 16,
                    padding: '4px 12px 4px 8px',
                    width: 130,
                    position: 'sticky',
                    //This has to match the width of the first cell or bring grater than that
                    left: 300,
                    color: colors.intenseBlue,
                  }}
                  disabled={alreadyEnriching}
                  onClick={handleEnrichClick}
                >
                  Enrich
                </GreyButton>
              )}
            </FindNewTableCell>
          )}
        </>
      ) : (
        <>
          {/*Current company name */}
          {columnsToShow.includes('companyName') && (
            <FindNewTableCell
              style={{
                paddingLeft: 12,
                width: 250,
                minWidth: 250,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <span
                style={{
                  width: 250,
                  minWidth: 250,
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  noWrap
                >
                  {contact.currentCompanyAlreadyInDatabase.name}
                </Typography>
              </span>
            </FindNewTableCell>
          )}

          {/*Job title  */}
          {columnsToShow.includes('jobTitle') && (
            <FindNewTableCell
              style={{
                paddingLeft: 12,
                width: 250,
                minWidth: 250,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <span
                style={{
                  width: 250,
                  minWidth: 250,
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Tooltip
                  title={contact.title}
                  arrow
                  enterDelay={800}
                  enterNextDelay={800}
                >
                  <Typography
                    fontSize={14}
                    lineHeight={'24px'}
                    color={colors.grey11}
                    noWrap
                  >
                    {contact.title}
                  </Typography>
                </Tooltip>
              </span>
            </FindNewTableCell>
          )}
          {/*Location */}
          {hasSalesNavWithCookie && columnsToShow.includes('location') && (
            <FindNewTableCell
              style={{
                paddingLeft: 12,
                width: 250,
                minWidth: 250,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <span
                style={{
                  width: 250,
                  minWidth: 250,
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  noWrap
                >
                  {contact.location}
                </Typography>
              </span>
            </FindNewTableCell>
          )}
          {/*Current company industry */}
          {columnsToShow.includes('companyIndustry') && (
            <FindNewTableCell
              style={{
                paddingLeft: 12,
                width: 250,
                minWidth: 250,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <span
                style={{
                  width: 250,
                  minWidth: 250,
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  noWrap
                >
                  {contact.currentCompanyAlreadyInDatabase.industry}
                </Typography>
              </span>
            </FindNewTableCell>
          )}
          {/*Current company headquarter */}
          {columnsToShow.includes('companyHeadquarter') && (
            <FindNewTableCell
              style={{
                paddingLeft: 12,
                width: 250,
                minWidth: 250,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <span
                style={{
                  width: 250,
                  minWidth: 250,
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  noWrap
                >
                  {contact.currentCompanyAlreadyInDatabase.location}
                </Typography>
              </span>
            </FindNewTableCell>
          )}
          {/*Current company website */}
          {columnsToShow.includes('companyWebsite') && (
            <FindNewTableCell
              style={{
                paddingLeft: 12,
                width: 250,
                minWidth: 250,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <span
                style={{
                  width: 250,
                  minWidth: 250,
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  noWrap
                >
                  {contact.currentCompanyAlreadyInDatabase.website}
                </Typography>
              </span>
            </FindNewTableCell>
          )}
          {/*Current company size */}
          {columnsToShow.includes('companySize') && (
            <FindNewTableCell
              style={{
                paddingLeft: 12,
                width: 250,
                minWidth: 250,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <span
                style={{
                  width: 250,
                  minWidth: 250,
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  noWrap
                >
                  {contact.currentCompanyAlreadyInDatabase.numberOfEmployees}
                </Typography>
              </span>
            </FindNewTableCell>
          )}
          {/*Current company description */}
          {columnsToShow.includes('companyDescription') && (
            <FindNewTableCell
              style={{
                paddingLeft: 12,
                width: 250,
                minWidth: 250,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <AboutContainer
                about={
                  contact.currentCompanyAlreadyInDatabase.description ?? ''
                }
              />
            </FindNewTableCell>
          )}
        </>
      )}
    </>
  );
};

export default CompanyInformationColumnsForContact;
