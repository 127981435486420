import { Tooltip, Typography } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC, useMemo } from 'react';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { Template as _Template } from 'crono-fe-common/types/template';
import { TemplateWrapper } from './styles';
import { TemplatesSelectionProps } from './types';
import { useAuth } from 'context/auth';
import parse from 'html-react-parser';
import TextAccordion from 'crono-fe-common/components/TextAccordion';
import { replaceAllTemplateVariables } from 'utils/fe-utils';
import PrivateLockIcon from 'crono-fe-common/icons/Icon-Private-Lock';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as NotOwnedIcon } from 'crono-fe-common/icons/Not-owned-by-the-user.svg';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';

const Template: FC<TemplatesSelectionProps> = ({
  template,
  showAnalytics,
  handleSelectTemplate,
  showTags,
  selected,
  prospect,
  account,
  usedForProspect,
}) => {
  const { user } = useAuth();
  const { data: users } = useGetUsers();

  function renderTemplateAnalytics(template: _Template) {
    const total = template.useCount;
    const replyRate =
      template.answerCount !== 0 && total !== 0
        ? Math.round((template.answerCount / total) * 100)
        : 0;
    const openRate =
      template.openCount !== 0 && total !== 0
        ? Math.round((template.openCount / total) * 100)
        : 0;
    const showOpenRate = template.type === TemplateType.EMAIL;
    return (
      <FlexDiv
        className="template-analytics-container"
        width={showOpenRate ? '180px' : '120px'}
      >
        <Tooltip arrow title="Total use" placement="bottom">
          <div className="template-analytics-item usage-item">{total}</div>
        </Tooltip>
        <div className="vertical-divider" />
        {showOpenRate && (
          <>
            <Tooltip arrow title="Open rate" placement="bottom">
              <div className="template-analytics-item open-item">
                {openRate}%
              </div>
            </Tooltip>
            <div className="vertical-divider" />
          </>
        )}
        <Tooltip
          arrow
          title={`${
            template.type === TemplateType.INVITATION
              ? 'Accepted rate'
              : 'Reply rate'
          }`}
          placement="bottom"
        >
          <div className="template-analytics-item reply-item">{replyRate}%</div>
        </Tooltip>
      </FlexDiv>
    );
  }

  const { data: templateVariables } = useGetTemplateVariables();

  const subjectReplaced = useMemo(() => {
    if (!template.subject) return '';
    return parse(
      replaceAllTemplateVariables(
        template.subject,
        prospect ?? null,
        account ?? null,
        user ?? null,
        templateVariables?.data?.data ?? null,
      ),
    );
  }, [template, prospect, account, user, templateVariables]);

  const templateOwner = useMemo(() => {
    return users?.data?.data?.find((u) => u.id === template.userId);
  }, [users, template]);

  return (
    <TemplateWrapper
      id="template-card-wrapper"
      key={template.id}
      onClick={() => handleSelectTemplate(template)}
      selected={selected}
    >
      <FlexDiv
        id="template-card"
        justifyContent="space-between"
        alignItems="start"
        direction="column"
        height="fit-content"
        style={{
          rowGap: template.type === TemplateType.LINKEDIN ? 0 : 12,
          opacity: usedForProspect ? '0.4' : '1',
        }}
      >
        {showTags && (
          <FlexDiv
            justifyContent="space-between"
            width="100%"
            style={{ gap: '4px', alignItems: 'center' }}
          >
            {template.sequenceStepTemplateId && (
              <Typography
                fontWeight={500}
                fontSize={'12px'}
                color={colors.purpleHover}
                className="ab-tag"
              >
                A/B
              </Typography>
            )}

            {!template.shared && (
              <div style={{ display: 'flex' }}>
                <PrivateLockIcon />
              </div>
            )}
            {template.userId !== user?.id && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '2px',
                }}
              >
                <Tooltip
                  arrow
                  title={
                    template.userId
                      ? `The owner is ${templateOwner?.firstName} ${templateOwner?.lastName}`
                      : 'No owner'
                  }
                  placement="top"
                >
                  <NotOwnedIcon className="not-owned-icon" />
                </Tooltip>
              </div>
            )}
            <Tooltip
              arrow
              title={
                usedForProspect ? 'Previously used template' : template.title
              }
              enterDelay={400}
            >
              <Typography fontWeight={600} fontSize={14} noWrap width={'100%'}>
                {template.title}
              </Typography>
            </Tooltip>

            {showAnalytics && renderTemplateAnalytics(template)}
          </FlexDiv>
        )}
        <FlexDiv
          justifyContent="start"
          alignItems="start"
          direction="column"
          style={{ overflow: 'hidden', gap: '0px' }}
        >
          {template.type === TemplateType.EMAIL && (
            <Tooltip
              arrow
              title={subjectReplaced}
              placement="bottom"
              style={{
                width: 'fit-content',
                maxWidth: '100%',
                overflow: 'hidden',
              }}
              enterDelay={800}
              enterNextDelay={800}
            >
              <Typography
                fontWeight={500}
                fontSize={14}
                noWrap
                className="template-title-display"
              >
                Subject: {subjectReplaced}
              </Typography>
            </Tooltip>
          )}
          <TextAccordion
            text={parse(
              replaceAllTemplateVariables(
                template.content,
                prospect ?? null,
                account ?? null,
                user ?? null,
                templateVariables?.data?.data ?? null,
              ),
            )}
            style={{
              fontSize: '14px',
              color: colors.grey11,
              paddingBottom: template.type === TemplateType.LINKEDIN ? 12 : 0,
            }}
          />
        </FlexDiv>
        <FlexDiv justifyContent="space-between" width="100%">
          {showTags ? (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 6,
              }}
            >
              <TagsBoxShowAll
                type="AccountAndProspect"
                blockIsEditable={true}
                disableEdit={true}
                showRemovable={false}
                currentSituationTags={
                  template?.templateTags?.map((tag) => {
                    return {
                      externalPropertyId: tag.externalPropertyId,
                      option: tag.value,
                    };
                  }) ?? []
                }
              />
            </div>
          ) : (
            <Tooltip
              arrow
              title={
                usedForProspect ? 'Previously used template' : template.title
              }
              enterDelay={400}
            >
              <Typography
                fontWeight={600}
                fontSize={14}
                noWrap
                className="template-title-display"
              >
                {template.title}
              </Typography>
            </Tooltip>
          )}
        </FlexDiv>
      </FlexDiv>
    </TemplateWrapper>
  );
};

export default Template;
