import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';
interface SequenceStepWrapperProps {
  openedStep?: boolean;
}

export const SequenceStepWrapper = styled.div<SequenceStepWrapperProps>`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  margin-top: ${(props) => (props.openedStep ? '32px' : '24px')};
  margin-bottom: ${(props) => (props.openedStep ? '32px' : '24px')};

  .step-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  .delay-jump-step-container {
    height: 100%;
    width: 100%;
    margin-left: 62px;
    display: flex;
    gap: 16px;
    align-items: center;
    overflow: hidden;
  }
  .step-number-container {
    min-width: 50px;
    display: flex;
    justify-content: center;
    margin-right: 12px;
  }
  .step-number,
  .step-plus {
    background: ${colors.primaryLight};
    color: ${colors.primary};
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  .step-plus {
    border: 1px solid ${colors.primary};
  }
  .step-completed {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
  }
  .step-number {
    width: 32px;
    height: 32px;
  }
  .step-number-selected {
    background: ${colors.primary};
    color: ${colors.white};
  }

  .error-badge-task {
    height: 24px;
    background: ${colors.inactiveLight};
    border-radius: 12px;
    padding: 4px 8px 4px 4px;
    gap: 4px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .step-content,
  .step-content-conditional {
    width: calc(100% - 62px);

    .step-content-left {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 480px;
      padding: 8px 8px 8px 20px;
      justify-content: space-between;
    }
    .step-content-right {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;
      overflow: hidden;
      justify-content: space-between;
      padding: 8px 16px 8px 16px;
    }
  }
  .vertical-divider {
    border-left: 1px solid ${colors.grey4};
    height: 100%;
    margin: 0px;
  }

  .step-content-top {
    display: flex;
    border: 1px solid ${colors.grey4};
    background: ${colors.grey6};
    border-radius: 8px;
    align-items: center;
    height: 60px;
    width: 100%;
  }
  .step-content-bottom {
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey4};
    align-items: center;
    min-height: 40px;
    width: 100%;
    border-radius: 0 0 8px 8px;
  }
  .step-content-no-border-bottom {
    border-bottom: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .step-content-no-border-top {
    border-top: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .step-content-single-step {
    border-radius: 8px;
    height: fit-content;
  }
  .step-content-single-step:hover {
    box-shadow: 0px 0px 8px 0px ${colors.grey4Hover};
    border-collapse: collapse;
  }
  .step-content-conditional {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    & > * {
      width: calc(50% - 8px) !important;
    }

    .step-content-single-step {
      border-radius: 10px;
      border: 2px solid ${colors.primaryLightHover};

      .step-content-top,
      .step-content-bottom {
        border: 1px solid ${colors.darkGreen};
      }

      .step-content-bottom {
        border-top: none;
      }
    }

    .step-row-conditional {
      .step-content-single-step {
        border: 2px solid ${colors.backgroundCloseRed};

        .step-content-top,
        .step-content-bottom {
          border: 1px solid ${colors.inactive};
        }

        .step-content-bottom {
          border-top: none;
        }
      }
    }
  }
  .step-completed-info {
    border-radius: 8px;
    height: 100%;
    background: ${colors.grey55};
  }
  .step-content-completed {
    display: flex;
    column-gap: 12px;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .step-content-left-side {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 480px;
    padding: 12px 20px;
    justify-content: space-between;
  }
  .step-content-right-side {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
    overflow: hidden;
    padding: 12px 16px 12px 4px;
    justify-content: space-between;
  }
  .active-step {
    border-color: ${colors.primary};
  }

  .conditional-step-wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .dashed-corner {
    position: absolute;
    top: -17px;
    left: 13px;
  }
  .conditional-step {
    width: calc(100% - 40px);
  }
  .steps-line {
    border-left: 1px dashed ${colors.grey33};
    height: calc(100% + 20px);
  }
  .conditional-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 16px;
    padding: 4px;
    border: 1px solid ${colors.grey33};
    background-color: ${colors.white};

    position: absolute;
    left: 0px;
    top: 17px;
  }

  .divider {
    border: 1px solid ${colors.grey4Hover};
    height: 30px;
    margin: 0;
  }
  .step-type-option-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
    width: 100%;
    padding-right: 7px;
  }
  .step-type-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  .step-type-option-dropdown {
    width: 192px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.grey4Hover};
    border-radius: 8px;
  }
  .step-type-option-dropdown:hover {
    background-color: ${colors.grey4};
  }
  .completed-type-icon,
  .type-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }
  .skipped-background-icon {
    background: ${colors.grey444};
  }
  .completed-background-icon {
    background: ${colors.primaryLightHover};
  }
  .error-background-icon {
    background: ${colors.inactiveLightHover};
  }
  .type-icon svg > path#cross_outside_border {
    stroke: ${colors.white};
  }
  .in-mail-icon {
    padding: 5px;
  }
  .sequence-icon {
    padding: 4px !important;
  }
  .jump-button {
    font-size: 14px;
    background: ${colors.primaryLight};
    color: ${colors.primary};
    height: fit-content;
    border-radius: 999px;
    text-transform: unset !important;
    padding-inline: 16px;
    min-width: fit-content;
    position: absolute;
    bottom: calc(100% + 6px);
  }
  .jump-button:hover {
    background: ${colors.primary};
    color: ${colors.white};
  }

  .container-sequence-instance-task {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 8px;
  }

  .task-component-button-white,
  .task-component-button {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 6px;
    flex-shrink: 0;
  }
  .task-component-button-white {
    background: ${colors.white};
    border: 1px solid ${colors.grey444};

    &:hover {
      background: ${colors.grey6};
    }
  }
  .task-component-button-disabled {
    cursor: not-allowed;
  }
  .calendar-icon {
    border: 1px solid ${colors.grey444};
  }
  .remove-icon {
    background: ${colors.inactiveLight};
  }
  .subject-row {
    display: flex;
    width: 100%;
    padding: 0px 16px;
    background: ${colors.grey55}99;
    align-items: flex-end;
  }
  .open-template-details-button {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.grey33};
  }
  .no-template-to-open-placeholder {
    width: 32px;
  }

  .template-section {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    overflow: hidden;
  }

  .add-template {
    cursor: pointer;
    width: 100%;
    color: ${colors.grey11};
  }
  .add-template:hover {
    color: ${colors.black};
  }
  .add-template:hover > span > svg > path {
    stroke: ${colors.black};
  }
  .selected-template {
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    color: ${colors.darkGreen};
  }
  .selected-template > span > svg > path {
    fill: ${colors.darkGreen};
  }
  .selected-template > #remove-template-icon > svg {
    width: 0px !important;
    min-width: 0px !important;
  }
  .selected-template:hover > #remove-template-icon > svg {
    width: 24px !important;
    min-width: 24px !important;
  }
  .selected-template:hover {
    color: ${colors.darkGreenHover};
  }
  .selected-template:hover > span > svg > path {
    fill: ${colors.darkGreenHover};
  }

  .selected-template > span.close-template-option > svg > path {
    stroke: transparent;
  }
  .selected-template:hover > span.close-template-option > svg > path {
    stroke: ${colors.grey2};
  }
  .disable-due-to-edit {
    cursor: not-allowed !important;
    color: ${colors.grey11} !important;
    flex: 1;
    overflow: hidden;
    :hover {
      color: ${colors.grey11} !important;
    }
  }
  .disable-due-to-edit > span.close-template-option > svg > path {
    stroke: transparent;
  }
  .step-component-button {
    cursor: pointer;
  }

  .step-component-button-disabled {
    cursor: not-allowed;
  }
  .personalized-badge {
    background: ${colors.grey4};
    height: 24px;
    /* width: 98px; */
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
  }
  //we apply this only when template is edited to avoid overflow of cancel/save position absolute buttons
  #editable-template-conditional-left-leaf,
  #editable-template-conditional-right-leaf {
    .toolbar {
      max-width: 80%;
    }
  }
`;
