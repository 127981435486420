export interface ISize {
  min: number;
  max?: number | null;
  toString: string;
};

export const sizes: ISize[] = [
  {
    min: 1,
    max: 10,
    toString: '1-10',
  },
  {
    min: 11,
    max: 50,
    toString: '11-50',
  },
  {
    min: 51,
    max: 200,
    toString: '51-200',
  },
  {
    min: 201,

    max: 500,
    toString: '201-500',
  },
  {
    min: 501,
    max: 1000,
    toString: '501-1000',
  },
  {
    min: 1001,
    max: 5000,
    toString: '1001-5000',
  },
  {
    min: 5001,
    max: 10000,
    toString: '5001-10000',
  },
  {
    min: 10001,
    toString: '10001+',
  },
];
