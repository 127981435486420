import React from 'react';
import { colors } from 'crono-fe-common/theme';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { BillingPlan } from 'pages/signup/model';
import { Options } from 'pages/signup';
import FeatureListItem from './featureListItem';
import PlanShortCard from './planShortCard';

interface IProps {
  billingPlan: BillingPlan;
  setSelectedPlan: React.Dispatch<React.SetStateAction<number>>;
  options: Options[];
  selectedPlan: number;
  close: () => void;
}

export const PlanOptions = ({
  billingPlan,
  setSelectedPlan,
  options,
  selectedPlan,
  close,
}: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        paddingInline: '20px',
      }}
    >
      {options.map((option, index) => {
        const price = option?.prices.filter(
          (price) => price.paymentCycle === billingPlan,
        )[0];
        const type = option?.subscriptionType;
        const selected = selectedPlan === index;
        return (
          <div
            id={`plan-options-carousel-${index}`}
            className="card-navigation"
            key={index}
            onClick={() => {
              setSelectedPlan(index);
              close();
            }}
            style={{
              minWidth: '360px',
            }}
          >
            <div
              className={`plan ${
                type === SubscriptionPlanType.FREE_TRIAL
                  ? 'plan-selected-free-trial-option'
                  : 'plan-selected-option'
              }`}
              style={{
                borderColor: selected ? colors.primaryDark : undefined,
              }}
            >
              <PlanShortCard
                type={type}
                billingPlan={billingPlan}
                key={index}
                price={price}
                selected={selected}
                className={'plan-hover'}
              />
              <div className="plan-footer">
                {option?.features.map((feature, i) => {
                  const firstFeature =
                    i === 0 &&
                    type !== SubscriptionPlanType.FREE_TRIAL &&
                    type !== SubscriptionPlanType.STARTER;

                  return (
                    <FeatureListItem
                      key={i}
                      feature={feature}
                      firstFeature={firstFeature}
                      type={type}
                    />
                  );
                })}
              </div>
            </div>
            <div className="vertical-filler" />
          </div>
        );
      })}
    </div>
  );
};
