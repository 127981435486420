import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconIntegration = ({ color = colors.black, className }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.4452 12.778L19.7782 10.445C21.4962 8.727 21.4962 5.941 19.7782 4.222V4.222C18.0602 2.504 15.2742 2.504 13.5552 4.222L11.2222 6.555"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.89014 15.11L15.1101 8.89"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.55509 11.222L4.22209 13.555C2.50409 15.273 2.50409 18.059 4.22209 19.778V19.778C5.94009 21.496 8.72609 21.496 10.4451 19.778L12.7781 17.445"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconIntegration;
