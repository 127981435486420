import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ConditionalSequenceStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  width: 100%;

  .step-row-conditional {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .step-number-container {
    min-width: 60px;
    display: flex;
    justify-content: center;
    margin-right: 12px;
  }
  .step-number {
    background: ${colors.primaryLight};
    color: ${colors.primary};
    font-size: 14px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 32px;
    height: 32px;
  }
  .step-plus {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 16px;
    padding: 1px 3px 1px 6px;
    gap: 2px;
    justify-content: center;
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
    background: ${colors.primaryLight};
    &:hover {
      background: ${colors.primary};
      color: ${colors.white};
    }
  }
  .step-plus:hover > svg > path {
    stroke: ${colors.white};
  }
  .delay-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
  .delay-picker {
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    display: flex;
    padding: 4px 12px;
    column-gap: 16px;
    align-items: center;
    width: 150px;
    justify-content: space-between;
    height: 32px;
  }
  .delay-picker-button {
    font-size: 18px;
  }
  .step-date {
    display: flex;
    align-items: center;
  }
  .step-content {
    width: calc(100% - 72px);
  }
  .step-content-single-step {
    width: 100%;
  }
  .step-content-single-step:hover {
    box-shadow: 0px 0px 8px 0px ${colors.grey4Hover};
    border-collapse: collapse;
  }
  .conditional-step-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    position: relative;
  }
  .conditional-step {
    width: calc(100% - 40px);
  }
  .steps-line {
    border-left: 1px dashed ${colors.grey33};
    // position: absolute;
    // left: 30px;
    height: calc(100% + 20px);
    // z-index: -1;
  }
  .conditional-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 16px;
    padding: 4px;
    border: 1px solid ${colors.grey33};

    position: absolute;
    left: 0px;
    top: 17px;
  }
  .step-content-top {
    display: flex;
    border-radius: 8px;
    align-items: center;
    height: 60px;
    width: 100%;
  }
  .step-content-bottom {
    display: flex;
    border-top: 1px solid ${colors.grey4};
    border-radius: 8px;
    align-items: center;
    min-height: 40px;
    width: 100%;
  }
  .divider {
    border: 1px solid ${colors.grey4};
    height: 26px;
  }

  .step-component-button {
    cursor: pointer;
    color: ${colors.grey11};
  }

  .step-component-button-disabled {
    cursor: not-allowed;
  }
  .select-paper {
    background: ${colors.white};
    -webkit-box-shadow: 1px 12px 20px -12px #000000;
    -moz-box-shadow: 1px 12px 20px -12px #000000;
    -o-box-shadow: 1px 12px 20px -12px #000000;
    box-shadow: 1px 12px 20px -12px #000000;
    padding-inline: 6px;
  }
  .select-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .select-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .pointer {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  .vertical-divider {
    border-left: 1px solid ${colors.grey4};
    height: 100%;
    margin: 0px;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .step-left-side-wrapper {
    padding: 16px;
    justify-content: flex-start;
    min-width: 430px;
  }
  .email-thread {
    padding-right: 8px;
  }
  .add-template {
    cursor: pointer;
    width: 100%;
    color: ${colors.grey11};
  }
  .add-template:hover {
    color: ${colors.black};
  }
  .add-template:hover > span > svg > path {
    stroke: ${colors.black};
  }
  .selected-template {
    max-width: 100%;
    cursor: pointer;
    color: ${colors.darkGreen};
  }
  .selected-template:hover {
    color: ${colors.darkGreenHover};
  }
  .selected-template:hover > span > svg > path {
    fill: ${colors.darkGreenHover};
  }

  .selected-template > span.close-template-option > svg > path {
    stroke: transparent;
  }
  .selected-template:hover > span.close-template-option > svg > path {
    stroke: ${colors.grey2};
  }

  .step-component-template-disable {
    cursor: not-allowed;
    color: ${colors.grey11};
  }
  .step-component-template-disable > span > svg {
    cursor: not-allowed;
  }
  .step-component-template-disable > span.close-template-option > svg > path {
    stroke: transparent;
  }

  .close-template-option-conditional:hover {
    border-radius: 15px;
    background-color: ${colors.grey3};
  }
  .remove-icon {
    height: 32px;
    width: 32px;
    padding: 3px;
  }

  .icon-wrapper {
    padding: 4px;
    min-width: 32px;
  }
  .edit-icon {
    background-color: ${colors.primaryLight};
  }
  .edit-icon:hover {
    background-color: ${colors.primaryLightHover};
  }

  .remove-icon {
    background-color: ${colors.inactiveLight};
  }
  .remove-icon:hover {
    background-color: ${colors.inactiveLightHover};
  }
  .conditional-button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px 4px 4px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    cursor: pointer;
  }
  .conditional-button:hover {
    background-color: ${colors.sequenceCardHover};
  }

  .step-type-option-dropdown {
    width: fit-content;
  }
`;
