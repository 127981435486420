import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC } from 'react';
import { LinkedinExtensionModalWrapper, ModalWrapper } from './styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  CancelButton,
  CronoButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import { Typography } from '@mui/material';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import React from 'react';

interface InstallLinkedinExtensionModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const InstallLinkedinExtensionModal: FC<InstallLinkedinExtensionModalProps> = ({
  open,
  setOpen,
}) => {
  function handleClose() {
    setOpen(false);
  }

  const openInstallLink = () => {
    const url = process.env.REACT_APP_CRONO_CHROME_EXTENSION_URL;
    if (url) {
      window.open(url);
    }
  };

  return (
    <LinkedinExtensionModalWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-box">
        <CloseTabButton close={handleClose} />
        <div className="inner-content-container">
          <Typography fontSize={24} lineHeight={'30px'} fontWeight={700}>
            LinkedIn Integration
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={'24px'}
            fontWeight={400}
            color={colors.grey11}
          >
            Click to install Crono-Linkedin extension.
            <br />
            Use it with Crono and Linkedin open in the same browser
          </Typography>
          <FlexDiv justifyContent="end">
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <MainButton onClick={openInstallLink}>Install</MainButton>
          </FlexDiv>
        </div>
      </div>
    </LinkedinExtensionModalWrapper>
  );
};

export default InstallLinkedinExtensionModal;
