import React from 'react';
import { UpgradeDeniedModalWrapper } from './style';
import { ReactComponent as AccessBlockedIllustration } from 'assets/images/Illustration_Access_Blocked.svg';
import { Typography } from '@mui/material';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { colors } from 'crono-fe-common/theme';

export interface IProps {
  onClose: () => void;
  manager: string;
}

const UpgradeDeniedModal = ({ onClose, manager }: IProps) => {
  return (
    <UpgradeDeniedModalWrapper onClick={(e) => e.stopPropagation()}>
      <AccessBlockedIllustration />
      <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
        It looks like you are not the Admin of this Subscription
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={400}
        lineHeight={'24px'}
        color={colors.grey11}
      >
        Please ask <span className={'text-bold'}>{manager}</span> to upgrade
        your plan to enjoy more features from Crono
      </Typography>
      <MainButton className={'close-button'} onClick={onClose}>
        Close
      </MainButton>
    </UpgradeDeniedModalWrapper>
  );
};

export default UpgradeDeniedModal;
