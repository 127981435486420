import React, { useEffect, useState } from 'react';
import { Suggestion } from 'crono-fe-common/types/suggestion';
import { SuggestionCardWrapper } from './suggestionStyle';
import { Avatar, Badge, Button, TextField, Typography } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import moment from 'moment';
import { colors } from 'crono-fe-common/theme';
import { consultDictionary } from './suggestionDictionary';
import { stringAvatar } from './utils';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import usePatchSuggestion from 'hooks/services/suggestion/usePatchSuggestion';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import SearchLinkedin from './preconditionLinkedinCard/SearchLinkedin';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { FeConstants } from 'constants/FeConstants';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { getImageFromUrl } from 'utils/fe-utils';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  suggestion: Suggestion;
}

const PreconditionLinkedinCard = ({ suggestion }: IProps) => {
  const template = consultDictionary(suggestion.type);

  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();
  const { openWindow } = useWindow();

  const handleClickNameProspect = (prospectId: string, accountId: string) => {
    setSelectedAccountId(accountId);
    setSelectedProspectId(prospectId);

    openWindow({
      windowType: 'account',
      tab: 'prospect',
      selectedProspectId: prospectId,
    });
  };
  const {
    isSuccess: isEditProspectSuccess,
    mutate: updateProspect,
    error: updateProspectError,
  } = useEditProspect();

  const {
    mutate: patchSuggestion,
    isSuccess: correctlyPatched,
    variables: actionPerformed,
    error: errorPatchSuggestion,
  } = usePatchSuggestion();

  const analytics = useJuneAnalytics();

  useConditionalSnackBar([
    {
      condition: !!errorPatchSuggestion,
      message: getError(errorPatchSuggestion) ?? 'Error updating suggestion',
      severity: 'error',
    },
    {
      condition:
        !!updateProspectError && updateProspectError.response?.status === 403,
      message: 'You are not the owner of this contact',
      severity: 'error',
    },
    {
      condition:
        !!updateProspectError && updateProspectError.response?.status !== 403,
      message:
        'Update contact error: ' + getError(updateProspectError) ??
        'Unknown error',
      severity: 'error',
    },
    {
      condition: !!correctlyPatched,
      message: actionPerformed?.skip
        ? 'Suggestion correctly skipped'
        : 'Suggestion correctly performed',
      severity: 'success',
    },
  ]);

  useEffect(() => {
    if (isEditProspectSuccess) {
      setInformation(null);

      patchSuggestion({
        suggestionId: suggestion.id,
        skip: false,
      });
      if (analytics) {
        analytics.track('execute-suggestion', {
          suggestionType: suggestion.type,
          suggestionId: suggestion.id,
        });
      }
    }
  }, [isEditProspectSuccess]);

  const [information, setInformation] = useState<string | null>(null);

  const handleSaveInformation = () => {
    if (!information) return;
    updateProspect({
      prospectId: suggestion.prospectId,
      linkedin: information,
    });
  };
  const [open, setOpen] = useState<boolean>(false);

  //This function is fired when the add linkedin button is clicked in the modal
  const callOnSuccess = () => {
    setInformation(null);

    patchSuggestion({
      suggestionId: suggestion.id,
      skip: false,
    });
    if (analytics) {
      analytics.track('execute-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };
  const [badgeVisible, setBadgeVisible] = useState<boolean>(false);

  useEffect(() => {
    const currentTime = new Date();
    // Define the time limit for the last 4 hours
    const timeLimit = new Date(
      currentTime.getTime() - FeConstants.notificationTimeSpan,
    ); // 4 hours in milliseconds
    setBadgeVisible(new Date(suggestion.createdDate) > timeLimit);
  }, []);

  const handleSkipSuggestion = () => {
    patchSuggestion({
      suggestionId: suggestion.id,
      skip: true,
    });
    if (analytics) {
      analytics.track('skip-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };

  return (
    <SuggestionCardWrapper>
      <Badge
        variant="dot"
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        invisible={!badgeVisible}
        color="primary"
        overlap="circular"
        className="badge-suggestion"
      >
        <Avatar
          className="suggestion-avatar"
          key={`avatar-key-${suggestion.objectId}`}
          alt={suggestion.type}
          src={
            suggestion.account?.website &&
            getImageFromUrl(suggestion.account?.website, null)
          }
          {...stringAvatar(suggestion.account?.name ?? '')}
        />
      </Badge>
      <FlexDiv direction="column" alignItems="start" width="100%">
        <div className="top-header-suggestion-container">
          <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
            {template?.title({
              prospectName: (
                <b
                  onClick={() =>
                    handleClickNameProspect(
                      suggestion.prospectId,
                      suggestion.accountId,
                    )
                  }
                  className="name-link"
                >
                  {suggestion.prospect?.name}
                </b>
              ),
              type: suggestion.preconditionType,
            })}
          </Typography>
          <span className="skip-suggestion-x" onClick={handleSkipSuggestion}>
            <CloseMIcon color={colors.grey2} />
          </span>
        </div>
        <Typography fontSize={14} fontWeight={500}>
          {template?.description({ type: suggestion.preconditionType })}
        </Typography>
        <TextField
          size="small"
          value={information}
          onChange={(e) => setInformation(e.target.value)}
          variant="standard"
          placeholder="Add information..."
          className="text-field-suggestion"
          InputProps={{ disableUnderline: true }}
        />
        <Button
          endIcon={<SearchIcon color={colors.primary} />}
          className="find-information-button"
          onClick={() => setOpen(true)}
        >
          Find linkedin
        </Button>
        {suggestion.account && suggestion.prospect && open && (
          <div className="search-linkedin-container">
            <SearchLinkedin
              account={suggestion.account}
              prospect={suggestion.prospect}
              setOpenModal={setOpen}
              callOnSuccess={callOnSuccess}
            />
          </div>
        )}
        <FlexDiv justifyContent="end" onClick={handleSaveInformation}>
          <MainPrimaryButton className="save-button">Save</MainPrimaryButton>
        </FlexDiv>
        <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
          {moment(suggestion.createdDate).format('ll')}
        </Typography>
      </FlexDiv>
    </SuggestionCardWrapper>
  );
};

export default PreconditionLinkedinCard;
