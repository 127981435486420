import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ITemplateVariables } from 'crono-fe-common/types/templateVariables';

export default function useGetTemplateVariables() {
  const request: Request = createGetTemplateVariablesRequest();

  const requestConfig = {
    queryKey: [ENDPOINTS.templateVariables.main],
    queryFn: () => client(request),
    staleTime: 10 * 60 * 1000,
  };

  const { data, ...rest } = useQuery<Response<ITemplateVariables>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}

export const createGetTemplateVariablesRequest = (): Request => {
  return {
    url: ENDPOINTS.templateVariables.main,
    config: {
      method: 'get',
    },
  };
};
