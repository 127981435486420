import React, { useEffect, useMemo, useState } from 'react';
import { TaskInvitationPendingWrapper } from './style';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { colors } from 'crono-fe-common/theme';
import { CircularProgress, IconButton, Typography } from '@mui/material';

import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { useJuneAnalytics } from 'context/june';
import usePatchTask from 'hooks/services/task/usePatchTask';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { useConfirmModal } from 'context/confirmModal';
import { ReactComponent as InvitationPendingIcon } from 'crono-fe-common/icons/Ill_Invitation pending.svg';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CalendarIcon from 'crono-fe-common/icons/Calendar-Icon';
import moment from 'moment';
import useLastTaskInvitation from 'hooks/services/event/useLastTaskInvitation';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import { useAuth } from 'context/auth';

interface IProps {
  task: TaskTodo;
  close: () => void;
  callOnSuccess?: (
    keepTemplatesOpened?: boolean,
    executeTheSame?: boolean,
  ) => void;
  keepOpenTemplateTab?: boolean;
}

const TaskInvitationPending = ({
  task,
  close,
  callOnSuccess,
  keepOpenTemplateTab,
}: IProps) => {
  const { hasSalesNav } = useAuth();
  const analytics = useJuneAnalytics();

  const { data: lastTask } = useLastTaskInvitation(task?.prospectId);

  const {
    mutate: patchTask,
    isSuccess: taskPatched,
    variables: variablesTaskPatch,
    isLoading: patchingTask,
  } = usePatchTask();

  useEffect(() => {
    if (taskPatched) {
      if (callOnSuccess) {
        callOnSuccess(
          keepOpenTemplateTab,
          variablesTaskPatch?.type !== undefined,
        );
      }
      close();
    }
  }, [taskPatched]);

  const handleChangeTaskDate = (id: number, newDate: Date) => {
    patchTask({
      id: id,
      activityDate: newDate,
    });
    if (analytics) {
      analytics.track('patch-task', {
        type: 'reschedule',
      });
    }
  };

  const handleReschedule = () => {
    if (!selectedDate) return;
    handleChangeTaskDate(task.id, selectedDate);
  };

  const { openModal: openConfirmModal } = useConfirmModal();

  const handleSkipTask = (id: number) => {
    openConfirmModal({
      title: 'Are you sure you want to skip this task?',
      text: 'The task will be skipped',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        patchTask({
          id: id,
          skipped: true,
        });

        if (analytics) {
          analytics.track('patch-task', {
            type: 'skip',
          });
        }
      },
      cancelFunction: () => null,
    });
  };

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  useEffect(() => {
    const newDate = new Date();
    setSelectedDate(moment(newDate).businessAdd(5).toDate());
  }, []);

  const handleChangeType = (type: TaskTodoType) => {
    //If the type is the same, we don't do anything
    if (type === task.type) return;
    //Clear the template
    patchTask({
      id: task.id,
      type: type,
      templateId: undefined,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'type',
      });
    }
  };

  const pendingSince = useMemo(() => {
    if (lastTask?.data?.data?.eventDatetime) {
      return moment(lastTask?.data?.data?.eventDatetime).format('ll');
    }
    return null;
  }, [lastTask]);

  return (
    <>
      <TaskInvitationPendingWrapper>
        {/* <CloseTabButton close={close} /> */}
        <div className="task-invitation-pending-container">
          <InvitationPendingIcon />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={600}
              lineHeight={'24px'}
              align="center"
            >
              You cannot send a LinkedIn Message as your connection request is
              still pending
              {pendingSince && (
                <>
                  {' '}
                  <span>since</span>{' '}
                  <span style={{ color: colors.intenseBlue }}>
                    {pendingSince}
                  </span>
                </>
              )}
            </Typography>
            <Typography fontSize={14} lineHeight={'24px'} color={colors.grey11}>
              Either Skip or Reschedule this task to continue
            </Typography>
          </div>
          {/* <div style={{ display: "flex", columnGap: 12, height: "auto" }}>
            <Avatar>{stringAvatarProspect(prospect)}</Avatar>
            <div style={{ width: "calc(100% - 40px)" }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: 12,
                  width: "100%",
                  justifyContent: "space-between"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginRight: 12,
                    overflow: "hidden",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      marginRight: 12,
                      overflow: "hidden"
                    }}
                  >
                    <Typography fontSize={14} fontWeight={500} noWrap>
                      {prospect?.name}{" "}
                      <span style={{ color: colors.primary, fontWeight: 600 }}>
                        at {account?.name}
                      </span>
                    </Typography>

                    <Typography fontSize={14} color={colors.grey11} noWrap>
                      {prospect!.title}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="divider" /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={selectedDate}
              slotProps={{
                textField: {
                  variant: 'standard',
                  InputProps: { disableUnderline: true },
                },
              }}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              sx={{
                '.MuiInput-root': {
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: '16px',
                },
                width: 160,
              }}
              onChange={(newValue) => {
                if (newValue) setSelectedDate(newValue);
              }}
            />
          </LocalizationProvider>
          <div className="actions-task-invitation-pending">
            {patchingTask ? (
              <CircularProgress />
            ) : (
              <MainButton style={{ margin: 0 }} onClick={handleReschedule}>
                Reschedule
              </MainButton>
            )}
            <CancelButton
              style={{ margin: 0 }}
              onClick={() => handleSkipTask(task.id)}
            >
              Skip the task
            </CancelButton>
          </div>
        </div>
        <div className="footer-buttons">
          <Typography
            fontSize={14}
            lineHeight={'18px'}
            fontWeight={500}
            marginRight={'16px'}
          >
            You can also turn this task into
          </Typography>
          <IconButton
            className="action-button-invitation-pending button-pending-call"
            onClick={() => handleChangeType(TaskTodoType.CALL)}
          >
            <CallIcon color={colors.callScheduled} />
          </IconButton>
          <IconButton
            className="action-button-invitation-pending button-pending-email"
            onClick={() => handleChangeType(TaskTodoType.EMAIL)}
          >
            <EmailIcon color={colors.inactive} />
          </IconButton>
          {hasSalesNav && (
            <IconButton
              className="action-button-invitation-pending button-pending-inmail"
              onClick={() => handleChangeType(TaskTodoType.IN_MAIL)}
            >
              <LinkedinIconInMailCompass color={colors.purpleHover} />
            </IconButton>
          )}
        </div>
      </TaskInvitationPendingWrapper>
    </>
  );
};

export default TaskInvitationPending;
