import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC, useEffect } from 'react';
// import { Account } from "crono-fe-common/types/account";
import { EmailThreadModalWrapper } from '../../pages/accountTab/emailView/styles';

import { Prospect } from 'crono-fe-common/types/prospect';

import { Typography } from '@mui/material';
import moment from 'moment';
import { useAuth } from 'context/auth';
import { User } from 'crono-fe-common/types/user';
import { Task } from 'crono-fe-common/types/task';
import { formatHtmlContent } from 'utils/fe-utils';
interface EmailThreadModalProps {
  prospect: Prospect | null;
  activity?: Task;
}

const EmailLogModal: FC<EmailThreadModalProps> = ({ prospect, activity }) => {
  const { user } = useAuth();

  const renderMessage = (activity: Task, user: User, prospect: Prospect) => {
    return (
      <FlexDiv
        className="message-container"
        justifyContent="center"
        direction="column"
      >
        <FlexDiv className="message-header" justifyContent="space-between">
          <div className="message-sender">
            <Typography fontSize={14} fontWeight={500}>
              from: {activity.emailLog?.from}
            </Typography>

            <Typography fontSize={12} fontWeight={400}>
              to: {prospect?.email}
            </Typography>
          </div>
          <Typography className="message-date" fontWeight={500} fontSize={12}>
            {activity?.createdDate &&
              moment(activity.createdDate).format('LLL')}
          </Typography>
        </FlexDiv>
        <FlexDiv
          //If we have to show the description we need to consider the preline to show new lines, if not we show the contentHtml
          className={`message-body ${
            activity.emailLog?.contentHtml ? '' : 'no-thread'
          }`}
          justifyContent="space-between"
          direction="column"
        >
          <div
            style={{ width: '100%' }}
            dangerouslySetInnerHTML={{
              __html:
                formatHtmlContent(activity.emailLog?.contentHtml ?? '') ||
                activity.description,
            }}
          />
        </FlexDiv>
      </FlexDiv>
    );
  };

  return (
    <EmailThreadModalWrapper>
      <FlexDiv
        direction="column"
        className="modal-header"
        justifyContent="start"
        alignItems="start"
      >
        <FlexDiv justifyContent="space-between" alignItems="center">
          <h2>{activity?.subject || 'No subject'}</h2>
        </FlexDiv>
      </FlexDiv>
      <div className="modal-body">
        {(activity?.emailLog?.contentHtml || activity?.description) &&
          user &&
          prospect &&
          renderMessage(activity, user, prospect)}
      </div>
    </EmailThreadModalWrapper>
  );
};

export default EmailLogModal;
