import React from 'react';
import IProps from './interface';

const IconCircle = ({ color, className, style }: IProps) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <rect width="8" height="8" rx="4" fill={color} />
    </svg>
  );
};

export default IconCircle;
