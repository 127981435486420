import React from 'react';
import { ExternalPropertyTemplateVariablesSelectorWrapper } from './style';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { Checkbox, Typography } from '@mui/material';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { colors } from 'crono-fe-common/theme';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import usePatchExternalProperty from 'hooks/services/externalProperty/usePatchExternalProperty';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { isPossibleForVariable } from 'utils/fe-utils';

interface IProps {
  externalProperties: ExternalProperty[];
  setShowOtherVariables: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExternalPropertyTemplateVariablesSelector = ({
  externalProperties,
  setShowOtherVariables,
}: IProps) => {
  const { mutate: patchExternalProperty, error: erroPatchExternalProperty } =
    usePatchExternalProperty();

  const handleClick = (
    externalProperty: ExternalProperty,
    isTemplateVariable: boolean,
  ) => {
    patchExternalProperty({
      externalPropertyId: externalProperty.id,
      tableType: externalProperty.tableType,
      type: externalProperty.valueType,
      isTemplateVariable: isTemplateVariable,
    });
  };

  useConditionalSnackBar([
    {
      condition: !!erroPatchExternalProperty,
      message:
        getError(erroPatchExternalProperty) ??
        'Error while updating external property',
      severity: 'error',
    },
  ]);

  return (
    <ExternalPropertyTemplateVariablesSelectorWrapper
      onClick={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
      }}
    >
      <CloseTabButton close={() => setShowOtherVariables(false)} />
      <div className="container-other-variables">
        <Typography fontSize={24} lineHeight={'30px'} fontWeight={700}>
          Other variables
        </Typography>
        <div className="other-variables-list">
          {externalProperties
            .filter((ep) => isPossibleForVariable(ep))
            .map((externalProperty) => {
              return (
                <div key={externalProperty.id} className="other-variable-item">
                  <Checkbox
                    icon={<IconUnchecked />}
                    checkedIcon={<CheckedIcon />}
                    checked={externalProperty.isTemplateVariable}
                    onClick={(ev) => {
                      handleClick(
                        externalProperty,
                        !externalProperty.isTemplateVariable,
                      );
                      ev.stopPropagation();
                      ev.preventDefault();
                    }}
                    sx={{
                      padding: 'inherit',
                      margin: 'inherit',
                      '&:hover': {
                        backgroundColor: colors.grey4,
                      },
                      '&.Mui-checked': {
                        '&:hover': {
                          opacity: 0.7,
                        },
                      },
                      marginRight: 1,
                      marginLeft: 1,
                    }}
                  />
                  <Typography fontSize={14} lineHeight={'24px'}>
                    {externalProperty.publicName ??
                      externalProperty.externalName ??
                      ''}
                  </Typography>
                </div>
              );
            })}
        </div>
        <MainButton
          style={{ margin: 0 }}
          onClick={() => setShowOtherVariables(false)}
        >
          Save
        </MainButton>
      </div>
    </ExternalPropertyTemplateVariablesSelectorWrapper>
  );
};

export default ExternalPropertyTemplateVariablesSelector;
