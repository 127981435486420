import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Migration } from 'crono-fe-common/types/migration';

export default function useGetActiveMigration(enabled: boolean) {
  const request: Request = {
    url: `${ENDPOINTS.migration.active}`,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.migration.active],
    queryFn: () => client(request),
    enabled: enabled,
  };

  const { data, ...rest } = useQuery<Response<Migration | null>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
