import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const RemoveIcon = ({
  color = colors.black,
  className,
  onClick,
  ...rest
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...rest}
    >
      <path
        d="M6.50098 7.99173H17.499"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7135 7.9917V16.633C16.7135 17.5011 16.0104 18.2042 15.1424 18.2042H8.85777C7.98971 18.2042 7.28662 17.5011 7.28662 16.633V7.9917"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3565 5.43862H9.64307"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4289 11.134V15.0619"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.571 11.134V15.0619"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemoveIcon;
