import styled from 'styled-components';
import { colors } from 'crono-fe-common/theme';

export const TypeCardWrapper = styled.div`
  .card {
    position: relative;
    border-radius: 24px;
    padding: 32px 24px 24px;
    gap: 0;
    border: 1px solid ${colors.grey33};
    width: 270px;
    height: 100%;
    cursor: pointer;

    &__circle-icon {
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .content {
      display: flex;
      flex-direction: column;

      &__info {
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      & > img {
        width: 222px;
        margin-bottom: 16px;
      }
    }

    &:hover {
      background-color: ${colors.sequenceCardHover};
    }
  }

  .card.active {
    border: 1px solid ${colors.darkGreen};
    background: ${colors.primaryLight};
  }
`;
