import { ENDPOINTS } from 'config/endpoints';
import { useInfiniteQuery, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Notification } from 'crono-fe-common/types/notification';
import NotificationType from 'crono-fe-common/types/enums/notificationType';
import { Constants } from 'crono-fe-common/constants/constants';

export default function useLiveFeed({
  Offset,
  Limit = 25,
  AccountId,
  Types,
  Read,
}: {
  Offset: number;
  Limit: number | null;
  AccountId?: string | null;
  Types?: NotificationType[] | null;
  Read: boolean | null;
}) {
  const getQueryParams = (offset: number) => {
    let queryParams = '';
    if (offset) {
      queryParams += `Offset=${offset}&`;
    }
    if (Limit) {
      queryParams += `Limit=${Limit}`;
    }
    if (AccountId) {
      queryParams += `&AccountId=${AccountId}`;
    }
    if (Types) {
      queryParams += Types.map((type) => `&Types=${type}`).join('');
    }
    if (Read !== null) {
      queryParams += `&Read=${Read}`;
    }
    return queryParams;
  };
  const getRequest = (Offset: number) => {
    const queryParams = getQueryParams(Offset);
    const request: Request = {
      url: `${ENDPOINTS.notifications.feed}?${queryParams}`,
      config: {
        method: 'get',
      },
    };
    return request;
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.notifications.feed],
    queryFn: ({ pageParam }: any) => client(getRequest(pageParam)),
    getNextPageParam: (
      lastPage: Response<Notification[]>,
      pages: Response<Notification[]>[],
    ) => {
      if (!lastPage.data.data) {
        return undefined;
      }
      let howManyLoaded = 0;
      pages.forEach((page) => {
        howManyLoaded += page.data.data.length;
      });
      const checkIfHasNextPage =
        lastPage.data.data.length === 0 ||
        lastPage.data.data.length < (Limit ?? Constants.defaultLimit);
      if (checkIfHasNextPage) {
        return undefined;
      }
      return howManyLoaded;
    },
    // refetchInterval: 5 * 60 * 1000,
  };

  const { data, ...rest } = useInfiniteQuery<
    Response<Notification[]>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
