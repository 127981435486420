import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Prospect } from 'crono-fe-common/types/prospect';

export default function useGetProspect(
  prospectId?: string | null,
  withExternalProperties = true,
  withSequenceInstance = true,
  withAccount = true,
) {
  const request = createGetProspectRequest(
    prospectId,
    withExternalProperties,
    withSequenceInstance,
    withAccount,
  );

  const requestConfig = {
    queryKey: [ENDPOINTS.prospect.main, prospectId],
    queryFn: () => {
      return client(request);
    },
    staleTime: 60 * 1000,
    enabled: !!prospectId,
  };

  const { data, ...rest } = useQuery<Response<Prospect | null>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };

  // return useQuery<Response<Prospect>, CronoError>(requestConfig);
}

export function createGetProspectRequest(
  prospectId?: string | null,
  withExternalProperties = true,
  withSequenceInstance = true,
  withAccount = true,
): Request {
  const queryParams = new URLSearchParams({
    withExternalProperties: withExternalProperties ? 'true' : 'false',
    withSequenceInstance: withSequenceInstance ? 'true' : 'false',
    withAccount: withAccount ? 'true' : 'false',
  }).toString();
  return {
    url: `${ENDPOINTS.prospect.main}/${prospectId}?${queryParams}`,
    config: {
      method: 'get',
    },
  };
}
