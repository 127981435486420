import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const NotesBoxWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  padding-left: 12px;
  .note-list {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    height: calc(100% - 65px);
    overflow: auto;
  }
  .note-card {
    border-left: 2px solid ${colors.grey4};
    padding-left: 6px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    padding-right: 12px;
  }
  .icon-container {
    cursor: pointer;
    height: 20px;
    width: 20px;

    svg {
      stroke: ${colors.grey11};
    }
  }
  .remove-icon-container:hover {
    svg {
      path {
        stroke: ${colors.error};
      }
    }
  }
  //to make it visually the same to trash icon
  .edit-icon-container {
    display: flex;
  }
  .edit-icon-container:hover {
    svg {
      path {
        stroke: ${colors.primary};
      }
    }
  }

  .new-note-button {
    background: ${colors.grey4};
    color: ${colors.black};
    height: 32px;
    border-radius: 20px;
    text-decoration: none;
    text-transform: none;
    display: flex;
    gap: 8px;
    padding: 12px;

    &:hover {
      background: ${colors.blueLightHover};
    }
  }
  .new-note-buttons {
    display: flex;
    column-gap: 6px;
    width: fit-content;

    button {
      height: 42px;
      width: 42px;
    }
  }
  .confirm-note-icon {
    background: ${colors.callScheduledLight};
    &:hover {
      background: ${colors.primaryLightHover};
    }
  }
  .cancel-create-note-icon {
    background: ${colors.inactiveLight};
    &:hover {
      background: ${colors.inactiveLightHover};
    }
  }
  .cancel-edit-note-icon {
    border: 1px solid ${colors.grey444};
  }
  .create-note-card {
    padding-right: 6px;
    height: calc(100% - 52px);
  }
  .create-note-textarea {
    width: 100%;
    height: 100% !important;
    overflow: auto !important;
    border: 1px solid transparent;
    resize: none;
    font-family: inherit;
    font-size: 14px;
    &:focus {
      outline: none;
    }
  }

  .count-number {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.grey55};
    color: ${colors.black};
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 8px;
  }
  .notes-opportunity-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notes-opportunity-badge {
    background-color: ${colors.intenseBlue};
    color: ${colors.white};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 4px;
    height: 15px;
  }
`;
