import useSearchSuggestions from 'hooks/services/suggestion/useSearchSuggestions';
import React, { useEffect, useMemo, useState } from 'react';
import { FeConstants } from 'constants/FeConstants';
import { SuggestionsWrapper } from './style';
import { CircularProgress, Typography } from '@mui/material';
import { SuggestionType } from 'crono-fe-common/types/enums/suggestionType';
import FollowUpMeetingCard from './suggestionCards/followUpMeetingCard';
import { Suggestion } from 'crono-fe-common/types/suggestion';
import GenerateAccountsCard from './suggestionCards/generateAccountsCard';
import GenerateLeadsCard from './suggestionCards/generateLeadsCard';
import PreconditionEmailCard from './suggestionCards/preconditionEmailCard';
import StartSequenceForProspectCard from './suggestionCards/startSequenceForProspectCard';
import StopSequenceForReplyCard from './suggestionCards/stopSequenceForReplyCard';
import SuggestedAccountStatusCard from './suggestionCards/suggestedAccountStatusCard';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import PreconditionCallCard from './suggestionCards/preconditionCallCard';
import PreconditionLinkedinCard from './suggestionCards/preconditionLinkedinCard';
import { Constants } from 'crono-fe-common/constants/constants';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as NoSuggestionsAvailable } from '../../../assets/images/No_suggestions_available.svg';
import useGetSuggestionPreferences from 'hooks/services/suggestion/useGetSuggestionPreferences';
import { SuggestedAccountStatusEnabled } from 'crono-fe-common/types/suggestionPreferences';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { SettingsTabs } from 'pages/settings';
import SuggestUpdateCompanyCard from './suggestionCards/suggestUpdateCompanyCard';

interface IProps {
  accountId?: string | null;
}

const SuggestionsBox = ({ accountId }: IProps) => {
  //Suggestions related
  const { data: suggestions, isLoading: loadingSuggestions } =
    useSearchSuggestions({
      limit: Constants.defaultLimit,
      offset: 0,
      accountId,
    });

  const navigate = useNavigate();

  const {
    data: suggestionPreferences,
    isLoading: loadingSuggestionPreferences,
  } = useGetSuggestionPreferences();

  const hasEnabledAnySuggestion = useMemo(() => {
    let check = false;
    if (suggestionPreferences?.data?.data) {
      const {
        generateLeadsEnabled,
        generateAccountsEnabled,
        preconditionEnabled,
        followUpMeetingEnabled,
        startSequenceForOldProspectsEnabled,
        startSequenceForNewProspectsEnabled,
        emailMultipleReadEnabled,
        linkedinRepliedEnabled,
        stopSequencesForReplyEnabled,
        stopSequencesForChangeStatusEnabled,
        emailRepliedEnabled,
        generateLeadsIfNoEngagementEnabled,
        clearDataEnabled,
        followUpOpportunityEnabled,
        suggestAccountStatusEnabled,
      } = suggestionPreferences.data.data;

      check =
        !!generateLeadsEnabled ||
        !!generateAccountsEnabled ||
        !!preconditionEnabled ||
        !!followUpMeetingEnabled ||
        !!startSequenceForOldProspectsEnabled ||
        !!startSequenceForNewProspectsEnabled ||
        !!emailMultipleReadEnabled ||
        !!linkedinRepliedEnabled ||
        !!stopSequencesForReplyEnabled ||
        !!stopSequencesForChangeStatusEnabled ||
        !!emailRepliedEnabled ||
        !!generateLeadsIfNoEngagementEnabled ||
        !!clearDataEnabled ||
        !!followUpOpportunityEnabled ||
        suggestAccountStatusEnabled === SuggestedAccountStatusEnabled.Disabled;
    }
    return check;
  }, [suggestionPreferences]);

  // useEffect(() => {
  //   console.log("suggestions", suggestions);
  // }, [suggestions]);
  const renderSuggestion = (suggestion: Suggestion) => {
    switch (suggestion.type) {
      case SuggestionType.FollowUpMeeting:
      case SuggestionType.FollowUpOpportunity:
      case SuggestionType.EmailMultipleRead:
      case SuggestionType.EmailReplied:
      case SuggestionType.LinkedinReplied:
      case SuggestionType.SuggestNewRole:
        return <FollowUpMeetingCard suggestion={suggestion} />;
      case SuggestionType.GenerateAccounts:
        return <GenerateAccountsCard suggestion={suggestion} />;
      case SuggestionType.GenerateLeads:
        return <GenerateLeadsCard suggestion={suggestion} />;
      case SuggestionType.Precondition:
      case SuggestionType.SuggestClearData:
        switch (suggestion.preconditionType) {
          case TaskTodoType.EMAIL:
            return <PreconditionEmailCard suggestion={suggestion} />;
          case TaskTodoType.CALL:
            return <PreconditionCallCard suggestion={suggestion} />;
          case TaskTodoType.LINKEDIN:
            return <PreconditionLinkedinCard suggestion={suggestion} />;
          default:
            return null;
        }
      case SuggestionType.StartSequenceForNewProspects:
      case SuggestionType.StartSequenceForOldProspects:
        return <StartSequenceForProspectCard suggestion={suggestion} />;
      case SuggestionType.StopSequencesForReply:
      case SuggestionType.StopSequencesForChangeStatus:
        return <StopSequenceForReplyCard suggestion={suggestion} />;
      //No longer used
      // case SuggestionType.SuggestedAccountStatus:
      //   return <SuggestedAccountStatusCard suggestion={suggestion} />;
      case SuggestionType.SuggestNewCompany:
        return <SuggestUpdateCompanyCard suggestion={suggestion} />;
      default:
        break;
    }
  };

  const [newSuggestions, setNewSuggestions] = useState<number | null>(null);

  //Calculate the number of new suggestions
  useEffect(() => {
    const currentTime = new Date();
    // Define the time limit for the last 4 hours
    const timeLimit = new Date(
      currentTime.getTime() - FeConstants.notificationTimeSpan,
    ); // 4 hours in milliseconds
    setNewSuggestions(
      suggestions?.data?.data.filter(
        (suggestion) => new Date(suggestion.createdDate) > timeLimit,
      ).length || null,
    );
  }, [suggestions]);

  const hasSuggestions = () => {
    return (suggestions?.data?.data.length ?? 0) > 0;
  };

  const handleSetSuggestions = () => {
    navigate(PATH.SETTINGS, {
      state: { tab: SettingsTabs.SuggestionPreferences },
    });
  };

  return (
    <SuggestionsWrapper>
      <div style={{ paddingLeft: '12px', display: 'flex', gap: '8px' }}>
        <Typography fontSize={'14px'} fontWeight={600} lineHeight={'24px'}>
          Suggestions
        </Typography>
        {newSuggestions ? (
          <span className="number-of">{newSuggestions}</span>
        ) : null}
      </div>
      {loadingSuggestionPreferences || loadingSuggestions ? (
        <FlexDiv>
          <CircularProgress />
        </FlexDiv>
      ) : !hasEnabledAnySuggestion ? (
        <FlexDiv
          direction="column"
          position="relative"
          justifyContent="start"
          style={{ overflow: 'hidden' }}
        >
          <FlexDiv
            direction="column"
            position="absolute"
            className="no-suggestions-available-container"
          >
            <NoSuggestionsAvailable />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'24px'}
                textAlign={'center'}
              >
                Create your own suggestions!
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}
                maxWidth={'290px'}
                textAlign={'center'}
                color={colors.grey11}
              >
                Customize suggestion preferences to get useful notifications on
                what truly matters to you and optimize your work
              </Typography>
            </div>
            <MainButton onClick={handleSetSuggestions}>Suggestions</MainButton>
          </FlexDiv>
        </FlexDiv>
      ) : hasSuggestions() ? (
        <div className="suggestions-container">
          {suggestions?.data?.data.map((suggestion, index) => {
            return (
              <div key={suggestion.id} style={{ margin: '8px 0px 6px 0px' }}>
                <div className="suggestion-card">
                  <div className="inner-suggestion-card">
                    {renderSuggestion(suggestion)}
                  </div>
                </div>
                <div className="divider" />
              </div>
            );
          })}
        </div>
      ) : (
        <FlexDiv
          direction="column"
          position="relative"
          justifyContent="start"
          style={{ overflow: 'hidden' }}
        >
          <FlexDiv
            direction="column"
            position="absolute"
            className="no-suggestions-available-container"
          >
            <NoSuggestionsAvailable />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'24px'}
                textAlign={'center'}
              >
                No suggestions available
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}
                maxWidth={'290px'}
                textAlign={'center'}
                color={colors.grey11}
              >
                Make sure to costumize your suggestion preferences to leverage
                data and receive useful tips to optimize your work
              </Typography>
            </div>
          </FlexDiv>
        </FlexDiv>
      )}
    </SuggestionsWrapper>
  );
};

export default SuggestionsBox;
