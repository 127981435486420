import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const StrategyWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  .strategy-settings-container {
    background: ${colors.white};
    border-radius: 16px;
    padding: 18px 0px 18px 18px;
    padding-bottom: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .strategy-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    overflow: auto;
    height: 100%;
    padding-right: 13px;
  }

  .strategy-card-wrapper {
    width: 100%;
    border: 1px solid ${colors.grey4};
    border-radius: 16px;
  }

  .selected-strategy-wrapper {
    border-left: 3px solid ${colors.black};
  }

  .strategy-card {
    width: 100%;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 50px;
    cursor: pointer;
  }
  .strategy-card:hover {
    background-color: ${colors.sequenceCardHover};
  }

  .strategy-left-container {
    display: flex;
    justify-content: space-between;
    min-width: 50%;
    // gap: 50px;
    overflow: hidden;
    padding: 12px 0px 12px 16px;
  }
  .strategy-header-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    width: 35%;
    overflow: hidden;
    // padding-right: 14px;
  }
  .left-bottom-tags-wrapper {
    display: flex;
    flex: 1;
    gap: 8px;
  }
  .extra-tags-tooltip {
    min-width: 26px;
    height: 26px;
    width: fit-content;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: ${colors.grey4};
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .extra-tags-tooltip:hover {
    background-color: ${colors.grey4};
  }
  .strategy-right-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 16px 19px 16px 0px;
  }
  .strategy-tags-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    height: 26px;
    gap: 8px;
    // min-width: min-content;
    max-width: max-content;
  }

  .strategy-row {
    display: flex;
    justify-content: space-between;
  }
  .analitycs-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
  .analityc-box {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100px;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
  }
  .analityc-box:hover {
    background: ${colors.grey4};
  }
  .strategy-options {
    border: 1px solid transparent;
    border-radius: 4px;
    height: 30px;
    width: 30px;
    padding: 4px;
    cursor: pointer;
  }
  .strategy-options:hover {
    background-color: ${colors.blueLightHover};
  }

  .divider {
    height: 60px;
    border-left: 1px solid ${colors.grey444};
  }
  .horizontal-divider {
    display: flex;
    align-self: center;
    min-width: 16px;
    border-top: 1px solid ${colors.grey444};
  }
  .informations-box-container {
    display: flex;
  }
  .information-box {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin: 0px 5px;
    min-width: 120px;
    p {
      margin-left: 2px;
    }
  }
  .edit-button {
    background: ${colors.callScheduledLight};
  }
  .cancel-button {
    background: ${colors.inactiveLight};
  }
  .remove-text-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .tag-filter-container {
    height: 100%;
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 14px;
    width: 90px;
    cursor: pointer;
  }
  .tag-filter-container:hover {
    border: 1px solid ${colors.black};
  }
  .tags-selected {
    background: ${colors.grey4};
    width: auto;
  }

  .strategy-steps {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
  .strategy-total-steps:hover {
    color: ${colors.primaryDark};
  }
  .strategy-progress-icons {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .type-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 7px;
    cursor: pointer;
  }
  .extra-steps {
    font-size: 12px;
    font-weight: 500;
    background-color: ${colors.grey4};
  }
  .extra-steps:hover {
    background-color: ${colors.grey3};
  }
  .call {
    background-color: ${colors.primaryLight};
  }
  .call:hover {
    background-color: ${colors.primaryLightHover};
  }
  .email {
    background-color: ${colors.inactiveLight};
  }
  .in-mail {
    background-color: ${colors.purpleLight};
    padding: 6px;
  }
  .email:hover {
    background-color: ${colors.inactiveLightHover};
  }
  .linkedin-icon {
    background-color: ${colors.blueLight};
  }
  .linkedin-icon:hover {
    background-color: ${colors.blueLightHover};
    svg {
      background-color: ${colors.blueLightHover};
    }
  }
  .text-field {
    width: 180px;
    .MuiInput-underline:after {
      border-bottom: 2px solid ${colors.grey11};
    }
  }
`;

export const StrategyTagsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
  padding: 8px;
  flex-wrap: wrap;

  .strategy-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 280px;
  }
`;

export const StepsWrapper = styled.div`
  flex: 1;
  height: 45px;
  display: flex;
  align-items: center;

  .horizontal-divider {
    display: flex;
    align-self: center;
    min-width: 16px;
    border-top: 1px solid ${colors.grey444};
  }
  .type-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 7px;
    cursor: pointer;
  }
  .call {
    background-color: ${colors.primaryLight};
  }
  .call:hover {
    background-color: ${colors.primaryLightHover};
  }
  .email {
    background-color: ${colors.inactiveLight};
  }
  .email:hover {
    background-color: ${colors.inactiveLightHover};
  }
  .in-mail {
    background-color: ${colors.purpleLight};
  }
  .linkedin-icon {
    background-color: ${colors.blueLight};
  }
  .linkedin-icon:hover {
    background-color: ${colors.blueLightHover};
    svg {
      background-color: ${colors.blueLightHover};
    }
  }
`;
