import { SequenceStep } from 'crono-fe-common/types/sequenceStep';
import { StrategyStepsIconsWrapper } from './styles';
import CallIconDark from 'crono-fe-common/icons/Icon-Call-Dark';
import EmailIconDark from 'crono-fe-common/icons/Icon-Email-Dark';
import LinkedinInvitationIconDark from 'crono-fe-common/icons/Icon-Linkedin-Invitation-Dark';
import LinkedinIconInvoiceDark from 'crono-fe-common/icons/Icon-Linkedin-Invoice-Dark';
import LinkedinIconMessageDark from 'crono-fe-common/icons/Icon-Linkedin-Message-Dark';
import LinkedinIconInMailCompassDark from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass-Dark';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import React, { useCallback } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as LinkedinActivationIcon } from 'assets/images/linkedin-activation-icon.svg';
import { ReactComponent as TemplateIcon } from 'assets/images/template-icon-tip.svg';
import { ReactComponent as AutomaticIcon } from 'assets/images/automatic-icon-tip.svg';

interface IProps {
  steps: SequenceStep[];
  activeConditionLinkedinInvitation: boolean;
}

const StrategyStepsIcons: React.FC<IProps> = ({
  steps,
  activeConditionLinkedinInvitation,
}: IProps) => {
  const renderActivityIcon = useCallback((step: SequenceStep) => {
    switch (step.type) {
      case TaskTodoType.CALL:
        return <CallIconDark />;
      case TaskTodoType.EMAIL:
        return <EmailIconDark />;
      case TaskTodoType.LINKEDIN:
        if (step.subtype === TaskTodoSubtype.LinkedinInvitation) {
          return <LinkedinInvitationIconDark />;
        } else if (step.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
          return <LinkedinIconInvoiceDark />;
        } else {
          return <LinkedinIconMessageDark />;
        }
      case TaskTodoType.IN_MAIL:
        return <LinkedinIconInMailCompassDark />;
    }
  }, []);

  return (
    <StrategyStepsIconsWrapper>
      {steps.map((step, index) => {
        const { id, templateId, automatic, subtype } = step;

        if (index > 7 && steps.length > 8) return;

        if (index === 7 && steps.length > 8) {
          return (
            <div key={id} className="steps-left">
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'32px'}
                color={colors.black}
                textAlign={'center'}
              >
                +{steps.length - 7}
              </Typography>
            </div>
          );
        }

        return (
          <React.Fragment key={id}>
            <div className="step-icon">
              {subtype === TaskTodoSubtype.LinkedinInvitation &&
              activeConditionLinkedinInvitation ? (
                <div className="linkedin-invite-icon-activated-container">
                  <div className="linkedin-invite-icon-activated">
                    <div
                      style={{
                        flexShrink: 0,
                        width: '32px',
                        height: '32px',
                      }}
                    >
                      <LinkedinInvitationIconDark />;
                    </div>
                  </div>
                </div>
              ) : (
                <>{renderActivityIcon(step)}</>
              )}

              {!!templateId && !automatic && (
                <TemplateIcon className="step-icon-tip step-icon-tip--template" />
              )}

              {automatic && (
                <AutomaticIcon className="step-icon-tip step-icon-tip--automation" />
              )}
            </div>

            {subtype === TaskTodoSubtype.LinkedinInvitation &&
              activeConditionLinkedinInvitation && (
                <>
                  <div className="step-divider step-divider--gold" />

                  <Tooltip
                    arrow
                    title={
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '32px',
                        }}
                      >
                        Active Condition for Linkedin Activation
                      </Typography>
                    }
                    placement="top"
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        width: '264px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                      },
                    }}
                  >
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <LinkedinActivationIcon
                        style={{ width: '20px', height: '20px' }}
                      />
                    </div>
                  </Tooltip>
                </>
              )}

            {index !== steps.length - 1 && index < 7 && (
              <div className="step-divider" />
            )}
          </React.Fragment>
        );
      })}
    </StrategyStepsIconsWrapper>
  );
};

export default StrategyStepsIcons;
