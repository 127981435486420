import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useAuth } from 'context/auth';
import { Account } from 'crono-fe-common/types/account';
import { useFormik } from 'formik';
import { SearchContactTabFiltersType } from './model';
import {
  CronoGatewayLinkedinSearchLeadsAction,
  CronoGatewayLinkedinSearchSalesNavLeadsAction,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { ensureArray } from 'crono-fe-common/utils';
import { useLinkedinGetCompanyInfo } from 'crono-fe-common/hooks/crono-extension/gateway';
import useEditAccount from 'hooks/services/account/useEditAccount';
import InsertLinkedinForCompany from './insertLinkedinForCompany';
import { useFindNewTabContext } from 'pages/home/context/findNewContext';

interface SearchContactTabContextType {
  dtoForSearchLeads: CronoGatewayLinkedinSearchLeadsAction['params'];
  dtoForSearchLeadsSalesNav: CronoGatewayLinkedinSearchSalesNavLeadsAction['params'];
  getCurrentKeywordsForApplyFilters: () => string[];
  filtersChanged: boolean;
  setFiltersChanged: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeFormik: (fieldName: string, fieldValue: any | null) => void;
  values: SearchContactTabFiltersType;
  handleAddPersona: () => void;
  removePersona: (index: number) => void;
  personaText: string;
  setPersonaText: React.Dispatch<React.SetStateAction<string>>;
  handleClearFilters: () => void;
}

export const SearchContactTabContext = createContext<
  SearchContactTabContextType | undefined
>(undefined);

SearchContactTabContext.displayName = 'SearchContactTabContext';

const SearchContactTabProvider: FC<{
  initialCompany?: Account;
  children: any;
}> = ({ initialCompany, children }) => {
  const { contactsListPersistent } = useFindNewTabContext();
  const { user } = useAuth();
  const { mutate: patchUserPreferences } = usePatchUserPreferences();
  //To disable the button if the filters are not changed
  //If we have results saved we do not allow to apply if the filters do not change
  const [filtersChanged, setFiltersChanged] = useState<boolean>(
    !contactsListPersistent || !!initialCompany,
  );

  const formik = useFormik<SearchContactTabFiltersType>({
    initialValues: {},
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleChangeFormik = (fieldName: string, fieldValue: any | null) => {
    setFiltersChanged(true);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const { call: linkedinGetCompanyInfo } = useLinkedinGetCompanyInfo();

  const { mutate: editAccount } = useEditAccount();

  //Load the initial preferences, using userPreferences and company
  useEffect(() => {
    const setPreferences = async () => {
      //Load userPreferences for the filters
      if (!user?.userPreferences?.findNewProspectFilters) return;
      const preferences: SearchContactTabFiltersType = JSON.parse(
        user.userPreferences.findNewProspectFilters,
      );

      let numericIdOfInitialCompany = initialCompany?.linkedinNumericId;
      if (initialCompany) {
        if (!numericIdOfInitialCompany) {
          if (initialCompany?.linkedin) {
            const company = await linkedinGetCompanyInfo({
              url: initialCompany.linkedin,
            });
            if (company?.company?.numericId) {
              numericIdOfInitialCompany = company.company.numericId;
              editAccount({
                accountId: initialCompany.objectId,
                linkedinNumericId: numericIdOfInitialCompany,
              });
            }
          }
        }
        if (!numericIdOfInitialCompany) {
          console.error(
            'The company does not have a numericId, the filters will not be applied',
          );
          setShowInsertLinkedinForCompany(true);
        }
      }
      //If there is an initial company correctly loaded we do not set the other precerences
      formik.setValues(
        !!numericIdOfInitialCompany && initialCompany
          ? {
              currentCompanies: [
                {
                  objectId: initialCompany.objectId,
                  label: initialCompany.name,
                  id: numericIdOfInitialCompany,
                },
              ],
            }
          : {
              ...preferences,
              //Previously it was an object and then moved to be an array, this make sure that saved userPreferences will be handled correctly
              geoUrn: preferences.geoUrn ? ensureArray(preferences.geoUrn) : [],
            },
      );
    };
    setPreferences();
  }, []);

  const handleSaveFiltersInUserPreferences = (
    values: SearchContactTabFiltersType,
  ) => {
    const newUserPreferences = JSON.stringify(values);
    patchUserPreferences({
      findNewProspectFilters: newUserPreferences,
    });

    if (user) {
      if (!user.userPreferences) {
        user.userPreferences = {};
      }
      user.userPreferences.findNewProspectFilters = newUserPreferences;
    }
  };

  useEffect(() => {
    //To avoid saving the initial empty object, before the first render
    if (Object.keys(formik.values).length === 0) return;
    handleSaveFiltersInUserPreferences(formik.values);
  }, [formik.values]);

  const [personaText, setPersonaText] = useState<string>('');

  const handleAddPersona = () => {
    if (personaText !== '') {
      if ((formik.values.title as string[])?.includes(personaText)) {
        setPersonaText('');
        return;
      }
      setFiltersChanged(true);
      formik.setFieldValue('title', [
        ...(formik.values.title ?? []),
        personaText,
      ]);
      setPersonaText('');
    }
  };

  const removePersona = (index: number) => {
    setFiltersChanged(true);
    formik.setFieldValue(
      'title',
      formik.values.title?.filter((_, i) => i !== index),
    );
  };

  const dtoForSearchLeads: CronoGatewayLinkedinSearchLeadsAction['params'] =
    useMemo(() => {
      return {
        ...(formik.values.geoUrn && {
          geoUrn: formik.values.geoUrn.map((g) => g.id),
        }),
        // ...(formik.values.industry && {
        industry: formik?.values?.industry?.map((li) => li.id) ?? [],
        // }),
        ...(formik.values.currentCompanies && {
          currentCompanies: formik.values.currentCompanies.map((cc) => {
            return {
              name: cc.label.trim(),
              numericId: cc.id,
            };
          }),
        }),
        title: formik.values.title,
        keywords: '',
      };
    }, [formik.values]);

  const dtoForSearchLeadsSalesNav: CronoGatewayLinkedinSearchSalesNavLeadsAction['params'] =
    useMemo(() => {
      return {
        ...(formik.values.currentCompanies && {
          currentCompanies: formik.values.currentCompanies.map((cc) => {
            return {
              name: cc.label.trim(),
              numericId: cc.id,
            };
          }),
        }),
        ...(formik.values.currentCompanyHeadcount && {
          currentCompanyHeadcount: formik.values.currentCompanyHeadcount,
        }),
        ...(formik.values.companyHeadquarters && {
          companyHeadquarters: formik.values.companyHeadquarters,
        }),
        ...(formik.values.industry && {
          industry: formik.values.industry,
        }),
        ...(formik.values.function && {
          function: formik.values.function,
        }),
        ...(formik.values.persona && {
          persona: formik.values.persona,
        }),
        ...(formik.values.jobTitle && {
          jobTitle: formik.values.jobTitle,
        }),
        ...(formik.values.seniority && {
          seniority: formik.values.seniority,
        }),
        ...(formik.values.yearsInCurrentPosition && {
          yearsInCurrentPosition: formik.values.yearsInCurrentPosition,
        }),
        ...(formik.values.geography && {
          geography: formik.values.geography,
        }),
        ...(formik.values.categoryInterest && {
          categoryInterest: formik.values.categoryInterest,
        }),
        ...(formik.values.accountHasBuyerIntent && {
          accountHasBuyerIntent: formik.values.accountHasBuyerIntent,
        }),
        title: formik.values.title,
      } as CronoGatewayLinkedinSearchSalesNavLeadsAction['params'];
    }, [formik.values]);

  const getCurrentKeywordsForApplyFilters = () => {
    //Since the currentText has to be applied as persona we add it to the personas (the state is asynchronous, so we have to duplicate it)
    const currentPersonas = [...(formik.values.title ?? [])];
    if (personaText !== '') {
      currentPersonas.push(personaText);
      handleAddPersona();
    }
    return currentPersonas;
  };
  const handleClearFilters = () => {
    formik.resetForm();
    setFiltersChanged(true);
    handleSaveFiltersInUserPreferences({} as SearchContactTabFiltersType);
  };
  const [showInsertLinkedinForCompany, setShowInsertLinkedinForCompany] =
    useState<boolean>(false);
  useEffect(() => {
    console.log('Formik.values', formik.values);
  }, [formik.values]);

  const handleOnSuccessInsertLinkedinForCompany = (account: Account) => {
    if (account.linkedinNumericId) {
      formik.setValues({
        currentCompanies: [
          {
            objectId: account.objectId,
            label: account.name,
            id: account.linkedinNumericId,
          },
        ],
      });
    }
    setShowInsertLinkedinForCompany(false);
  };

  return (
    <SearchContactTabContext.Provider
      value={{
        dtoForSearchLeads,
        dtoForSearchLeadsSalesNav,
        getCurrentKeywordsForApplyFilters,
        filtersChanged,
        setFiltersChanged,
        handleChangeFormik,
        values: formik.values,
        handleAddPersona,
        removePersona,
        personaText,
        setPersonaText,
        handleClearFilters,
      }}
    >
      {showInsertLinkedinForCompany && initialCompany && (
        <InsertLinkedinForCompany
          close={() => setShowInsertLinkedinForCompany(false)}
          onSuccess={handleOnSuccessInsertLinkedinForCompany}
          company={initialCompany}
        />
      )}
      {children}
    </SearchContactTabContext.Provider>
  );
};

export default SearchContactTabProvider;

export function useSearchContactTab() {
  const context = useContext(SearchContactTabContext);
  if (context === undefined) {
    throw new Error(
      `useSearchContactTab must be used within a SearchContactTabProvider`,
    );
  }

  return context;
}
