import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export interface IPatchTemplateVariables {
  productName: string;
  productDescription: string;
  productReferences: string[];
  productBenefits: string[];
  productIndustries: string[];
}

export default function usePatchTemplateVariables() {
  const queryClient = useQueryClient();
  const patchTemplateVariables = (
    templateVariables: IPatchTemplateVariables,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.templateVariables.main}`,
      config: {
        method: 'patch',
        data: {
          data: templateVariables,
        },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    IPatchTemplateVariables
  >(patchTemplateVariables, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.templateVariables.main]);
    },
  });

  return { data, mutate, ...rest };
}
