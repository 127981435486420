import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { BillingSwitchWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import { Options, Plan } from '../../../index';
import IconArrowSTopRight from 'crono-fe-common/icons/Icon-Arrow-S-Top-Right';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { BillingPlan } from '../../../model';
import {
  mapPlanToSubscriptionType,
  mapSubscriptionToPlanType,
} from '../../../../../utils/fe-utils';

type BadgePlacement = 'top' | 'inside';

interface IProps {
  selectedPlan: Plan;
  options: Options[];
  billingPlan: BillingPlan;
  setBillingPlan: React.Dispatch<React.SetStateAction<BillingPlan>>;
  badgePlacement?: BadgePlacement;
}

const BillingSwitch = ({
  selectedPlan,
  options,
  billingPlan,
  setBillingPlan,
  badgePlacement = 'inside',
}: IProps) => {
  const calculateSavePercentage = (options: Options[], selectedPlan: Plan) => {
    const plan = options.find(
      (p) => p.subscriptionType === mapPlanToSubscriptionType(selectedPlan),
    );
    if (!plan) return 0;

    const priceWithYearlyPaymentCycle = plan.prices.filter(
      (price) => price.paymentCycle === 'yearly',
    )[0];
    const priceWithMonthlyPaymentCycle = plan.prices.filter(
      (price) => price.paymentCycle === 'monthly',
    )[0];

    return (
      100 -
      (priceWithYearlyPaymentCycle?.monthlyTotalCycle * 100) /
        priceWithMonthlyPaymentCycle?.monthlyTotalCycle
    ).toFixed(0);
  };

  const badgeJSX = useMemo(() => {
    const savePercent = calculateSavePercentage(options, selectedPlan);
    return selectedPlan !== Plan.FREE_TRIAL ? (
      <span
        className={`badge discount ${badgePlacement === 'top' ? 'badge-top' : ''}`}
      >
        Save {savePercent}% <IconArrowSTopRight />
      </span>
    ) : (
      <></>
    );
  }, [selectedPlan, options, badgePlacement]);

  return (
    <BillingSwitchWrapper>
      {badgePlacement === 'top' ? badgeJSX : null}
      <div className={'billing-switch'}>
        <Typography
          fontSize={'16px'}
          color={billingPlan === 'yearly' ? colors.black : colors.grey11}
        >
          Pay annually
        </Typography>
        {badgePlacement === 'inside' ? badgeJSX : null}
        <CronoSwitch
          sameSwitchColor={true}
          onChange={() => {
            setBillingPlan((prev) =>
              prev === 'monthly' ? 'yearly' : 'monthly',
            );
          }}
          checked={billingPlan === 'monthly'}
        />
        <Typography
          fontSize={'16px'}
          color={billingPlan === 'monthly' ? colors.black : colors.grey11}
        >
          Pay monthly
        </Typography>
      </div>
    </BillingSwitchWrapper>
  );
};

export default BillingSwitch;
