import { FileContent, useFilePicker } from 'use-file-picker';
import { FeConstants } from 'constants/FeConstants';
import { FileError } from 'use-file-picker/dist/interfaces';
import { useEffect, useState } from 'react';
import { parseFileError } from '../utils/fe-utils';

export function useAttachmentsFilePicker(): [
  () => void,
  {
    filesContent: FileContent[];
    errors: FileError[];
    errorMessage: string | null;
  },
] {
  const [openFileSelector, { filesContent, errors }] = useFilePicker({
    readAs: 'DataURL',
    multiple: true,
    limitFilesConfig: { max: FeConstants.maxFilesPerTransaction },
    accept: [
      '.json',
      '.pdf',
      '.jpg',
      '.png',
      '.jpeg',
      '.webp',
      '.gif',
      '.doc',
      '.docx',
      '.xls',
      '.xlsx',
      '.ppt',
      '.pptx',
      '.txt',
      '.csv',
    ],
    maxFileSize: FeConstants.uploadFileLimit,
  });

  const [errorUploadFile, setErrorUploadFile] = useState<string | null>(null);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (errors.length > 0) {
      const firstError = errors[0];
      const message = parseFileError(firstError);

      setErrorUploadFile(message);
      t = setTimeout(() => {
        setErrorUploadFile(null);
      }, 5000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [errors]);

  return [
    openFileSelector,
    { filesContent, errors, errorMessage: errorUploadFile },
  ];
}
