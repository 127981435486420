import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';

export default function useApplyChangesToSequenceInstances() {
  const queryClient = useQueryClient();

  const applyChanges = (strategyId: number) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.apply}`,
      config: {
        method: 'patch',
        data: { strategyId },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, number>(applyChanges, {
    onSettled: () => {
      CronoAutomaticTaskExecutor.stopDelay();
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
      queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);
      queryClient.invalidateQueries([ENDPOINTS.applySequenceChanges.allActive]);
    },
  });
}
