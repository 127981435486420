import { useMemo } from 'react';
import { DonutData } from '../tabsControllers/model';
import {
  colorsSuggestionsDonut,
  suggestionsDonutTitle,
  suggestionsDonutTooltipTitle,
} from '../legendData';
import { Grid, Typography } from '@mui/material';
import { AnalyticsSuggestion } from 'crono-fe-common/types/analyticsSuggestion';
import DonutChart from './donutChart';

interface IProps {
  suggestionAnalytics: AnalyticsSuggestion | undefined;
}

const SuggestionDataHealthChart = ({
  suggestionAnalytics: taskAnalytics,
}: IProps) => {
  const dataGeneral = useMemo(() => {
    if (!taskAnalytics) return [];
    const newData: DonutData[][] = [[], [], [], []];
    let index = 0;
    taskAnalytics?.dataMissing.email?.forEach((email) => {
      newData[index].push({
        label: email.label,
        value: email.count,
      });
    });
    index++;
    taskAnalytics?.dataMissing.phone?.forEach((phone) => {
      newData[index].push({
        label: phone.label,
        value: phone.count,
      });
    });
    index++;
    taskAnalytics?.dataMissing.linkedin?.forEach((linkedin) => {
      newData[index].push({
        label: linkedin.label,
        value: linkedin.count,
      });
    });
    index++;
    taskAnalytics?.dataMissing.title?.forEach((title) => {
      newData[index].push({
        label: title.label,
        value: title.count,
      });
    });
    return newData;
  }, [taskAnalytics?.dataMissing]);

  return (
    <>
      <Typography className="chart-title">Data Health</Typography>
      <div
        className="chart-container"
        style={{ height: 250, overflowX: 'hidden' }}
      >
        <Grid container className="donuts-container">
          {dataGeneral.map((data, index) => {
            return (
              <Grid
                item
                xs={3}
                key={index}
                style={{
                  height: 200,
                  width: 200,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DonutChart
                  data={data}
                  title={
                    data[0].value + data[1].value === 0
                      ? '0%'
                      : (
                          (data[0].value / (data[0].value + data[1].value)) *
                          100
                        ).toFixed(1) + '%'
                  }
                  subtitle={data[0].value}
                  colorsChart={colorsSuggestionsDonut[index]}
                  tooltipTitle={suggestionsDonutTooltipTitle[index]}
                  key={index}
                />
                <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
                  {suggestionsDonutTitle[index]}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        {/* <Column {...configGeneral} key={configGeneral.randomVal} /> */}
      </div>
    </>
  );
};

export default SuggestionDataHealthChart;
