import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const AutoClockSIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <g id="Frame 427321094" clipPath="url(#clip0_6395_161653)">
        <circle
          id="Ellipse 919"
          cx="6"
          cy="6"
          r="5"
          stroke={color}
          strokeWidth="0.9"
          strokeLinejoin="round"
        />
        <path
          id="Vector 150"
          d="M6 4V6.33333L7.94444 7.5"
          stroke={color}
          strokeWidth="0.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6395_161653">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AutoClockSIcon;
