import { FC, createContext, useContext, useMemo, useState } from 'react';
import { LinkedinContextType, LinkedinContent } from './types';
import { CloseButton } from 'crono-fe-common/components/CronoButton';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import LinkedinModal from './LinkedinModal';
import { useAuth } from 'context/auth';

export const LinkedinContext = createContext<LinkedinContextType | undefined>(
  undefined,
);

// Provider of the sideTab
const LinkedinProvider: FC<{ children: any }> = ({ children }) => {
  const [linkedinContent, setLinkedinContent] =
    useState<LinkedinContent | null>(null);
  const { user } = useAuth();
  //If there is no user I prevent the window from opening
  const open = useMemo(() => {
    if (!user) return undefined;
    return !!linkedinContent;
  }, [user, linkedinContent]);
  // Removed the windowContent && cause it's implemented by the transitions. Be care of what is passed in the windowContent.windowType,
  // because that's what make everything works
  return (
    <LinkedinContext.Provider
      value={{ setLinkedinContent, linkedinContent: linkedinContent }}
    >
      {open && linkedinContent && (
        <div className="email-thread-container">
          <CloseButton size="large" onClick={() => setLinkedinContent(null)}>
            <CloseMIcon color={colors.grey11} />
          </CloseButton>
          <LinkedinModal
            prospect={linkedinContent.prospect}
            activity={linkedinContent.activity}
          />
        </div>
      )}
      {children}
    </LinkedinContext.Provider>
  );
};

export function useLinkedinContext() {
  const context = useContext(LinkedinContext);
  if (context === undefined) {
    throw new Error(
      `useLinkedinContext must be used within a LinkedinProvider`,
    );
  }

  return context;
}

export default LinkedinProvider;
