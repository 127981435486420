import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const GenerateWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1200;
`;

export const GenerateFormWrapper = styled.div`
  .generate-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 32px;
    margin-bottom: 18px;
  }
  .form-row {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }
  .choices-container {
    display: flex;
    flex-wrap: wrap;
  }
  .choice-button,
  .choice-button-selected {
    border: 1px solid ${colors.grey4};
    text-transform: unset;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
  }
  .choice-button-selected {
    background: ${colors.primaryLight};
  }
`;
