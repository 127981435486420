import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export default function usePortalSession() {
  const callCheckout = () => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.stripePortal}`,
      config: {
        method: 'POST',
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<{ url: string }>,
    Error,
    object
  >(callCheckout, {});

  return { data, mutate, ...rest };
}
