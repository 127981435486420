import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconCheckmarkCalendar = ({
  color = colors.black,
  className,
  onClick,
  ...rest
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...rest}
    >
      <path
        d="M15.111 20.0002H6.66662C5.19389 20.0002 4 18.8063 4 17.3336V6.66711C4 5.19438 5.19389 4.00049 6.66662 4.00049H17.3331C18.8058 4.00049 19.9997 5.19438 19.9997 6.66711V11.1115"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9997 8.44492H4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 14.3331V6.66662C20 5.19389 18.8061 4 17.3334 4H6.6669C5.19416 4 4.00028 5.19389 4.00028 6.66662V11.111"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3V5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3V5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12.5L11.8757 15.5L10 13.7003"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.88901 19.9997H17.3334C18.8061 19.9997 20 18.8058 20 17.3331V6.66662C20 5.19389 18.8061 4 17.3334 4H6.6669C5.19416 4 4.00028 5.19389 4.00028 6.66662V11.111"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCheckmarkCalendar;
