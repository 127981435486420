import { Analytics } from '@june-so/analytics-next';
import { ENDPOINTS } from 'config/endpoints';
import { gatewayService } from 'crono-fe-common/hooks/crono-extension/gateway';
import { SuggestionInsert } from 'crono-fe-common/types/DTO/suggestionInsert';
import { UpdateProspect } from 'crono-fe-common/types/DTO/updateProspect';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { CronoGatewayLinkedinGetJobChangesAction } from 'crono-fe-common/types/crono-extension/linkedin';
import { CronoLogType } from 'crono-fe-common/types/cronoLogType';
import { SuggestionType } from 'crono-fe-common/types/enums/suggestionType';
import { LogLinkedin } from 'crono-fe-common/types/logLinkedin';
import { ProspectLinkedin } from 'crono-fe-common/types/prospectLinkedin';
import { Request } from 'crono-fe-common/types/request';
import LinkedinMessagesUtils from 'crono-fe-common/utils/LinkedinMessagesUtils';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import { invalidateLogLinkedinQueries } from 'hooks/services/event/useLogLinkedin';
import { createGetProspectLinkedinRequest } from 'hooks/services/prospect/useProspectLinkedin';
import lscache from 'lscache';
import { QueryClient } from 'react-query';
import CronoExtensionRequests from 'services/CronoExtensionRequests';
import client from 'utils/clients/client';

class CronoServiceClass {
  private isRunning = false;
  private analytics: Analytics | null = null;
  private queryClient: QueryClient | null = null;

  setQueryClient(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  public async checkLinkedinExtensionsUpdates(analytics: Analytics | null) {
    if (analytics) {
      this.analytics = analytics;
    }
    if (this.isRunning) {
      return;
    }

    try {
      this.isRunning = true;
      await this.checkInvitations();
      await this.checkMessages();
      await this.checkSalesNavMessages();
      await this.checkJobChanges();
    } catch (e) {
      console.error(e);
    } finally {
      this.isRunning = false;
    }
  }

  private async checkInvitations() {
    const { toCheck, lastCheck: _lastCheck } =
      await this.getProspectsNewLinkedinEventCheck(
        CronoLogType.CheckLinkedinNewConnections,
      );
    if (!toCheck) {
      return;
    }

    const lastCheck = _lastCheck && new Date(_lastCheck);
    const recentConnections =
      await CronoExtensionRequests.getRecentConnections();
    const newConnections = recentConnections?.filter((c) => {
      if (!lastCheck) {
        return true;
      }

      const delta = c.connectionTimestamp - lastCheck.getTime();
      const oneDayAgo = -(1000 * 60 * 60 * 24);
      return delta > oneDayAgo;
    });

    if (newConnections && newConnections.length > 0) {
      await this.setProspectsNewConnection(
        newConnections.map((c) => c.publicId),
      );
    }
  }

  private async checkMessages() {
    const { toCheck, lastCheck: _lastCheck } =
      await this.getProspectsNewLinkedinEventCheck(
        CronoLogType.CheckLinkedinNewMessages,
      );
    if (!toCheck) {
      return;
    }

    const lastCheck = _lastCheck && new Date(_lastCheck);

    const linkedinConversations =
      await CronoExtensionRequests.getLinkedinAllConversations();
    const newConversations = linkedinConversations
      ?.filter((c) => !!c.participantPublicId)
      ?.filter((c) => {
        if (!lastCheck || !c.lastActivity) {
          return true;
        }

        const delta = c.lastActivity - lastCheck.getTime();
        const oneDayAgo = -(1000 * 60 * 60 * 24);
        return delta > oneDayAgo;
      });
    if (!newConversations || newConversations.length <= 0) {
      return;
    }

    const publicIds = newConversations.map((c) => c.participantPublicId!);
    const cronoLogLinkedin = await this.areLinkedinMessagesPending(
      'Message',
      publicIds,
    );
    const cronoMessagesByProspectId = groupBy(cronoLogLinkedin, 'prospectId');

    let shouldInvalidateCache = false;
    for (const prospectId in cronoMessagesByProspectId) {
      const cronoMessages = cronoMessagesByProspectId[prospectId];
      const prospect = cronoMessages[0]!.prospect;
      const prospectPublicId = LinkedinUrlUtils.getIdFromLinkedinProfileUrl(
        prospect?.linkedin,
      );
      if (!prospectPublicId) {
        await this.analytics?.track('crono-service-error', {
          message: 'prospectPublicId not found',
        });
        continue;
      }

      const updatedConversation = newConversations.find(
        (c) => c.participantPublicId === prospectPublicId,
      );
      if (!updatedConversation) {
        await this.analytics?.track('crono-service-error', {
          message: 'updatedConversation not found',
        });
        continue;
      }

      if (!prospect?.owned) {
        continue;
      }

      let fullConversation:
        | {
            sent: boolean;
            timestamp: number;
            message: string;
          }[]
        | null;
      if (cronoMessages.length === 1 && !updatedConversation.isSent) {
        // we can skip downloading full conversation
        fullConversation = [
          {
            sent: false,
            timestamp: updatedConversation.lastActivity!,
            message: updatedConversation.lastMessage!,
          },
        ];
      } else {
        // we need to download full conversation
        const shouldSkipDownload = this.isConversationRecentlyAnalyzed(
          prospectId,
          updatedConversation.lastActivity!,
        );
        if (shouldSkipDownload) {
          console.log(
            `[CRONO-SERVICE] Conversation with ${prospectId} recently analyzed, will check it later`,
          );
          continue;
        }
        this.markConversationAsRecentlyAnalyzed(
          prospectId,
          updatedConversation.lastActivity!,
        );

        fullConversation = await CronoExtensionRequests.getLinkedinConversation(
          updatedConversation.participantProfileId!,
        );
        await delay();
      }

      if (fullConversation) {
        const patched = await LinkedinMessagesUtils.markLogLinkedinAsAnswered(
          cronoMessages,
          fullConversation,
        );
        shouldInvalidateCache = shouldInvalidateCache || patched;
      }
    }

    if (shouldInvalidateCache && this.queryClient) {
      await invalidateLogLinkedinQueries(this.queryClient);
    }
    lscache.flushExpired();
  }

  private async checkSalesNavMessages() {
    const { toCheck, lastCheck: _lastCheck } =
      await this.getProspectsNewLinkedinEventCheck(
        CronoLogType.CheckLinkedinNewInMails,
      );
    if (!toCheck) {
      return;
    }

    const hasSalesNav = await CronoExtensionRequests.hasSalesNav();
    if (!hasSalesNav) {
      return;
    }

    const lastCheck = _lastCheck && new Date(_lastCheck);

    const salesNavConversations =
      await CronoExtensionRequests.getSalesNavAllConversations();
    const newConversations = salesNavConversations
      ?.filter((c) => !!c.participantPublicId)
      ?.filter((c) => {
        if (!lastCheck || !c.lastActivity) {
          return true;
        }

        const delta = c.lastActivity - lastCheck.getTime();
        const oneDayAgo = -(1000 * 60 * 60 * 24);
        return delta > oneDayAgo;
      });
    if (!newConversations || newConversations.length <= 0) {
      return;
    }

    const publicIds = newConversations.map((c) => c.participantPublicId!);
    const cronoLogLinkedin = await this.areLinkedinMessagesPending(
      'InMail',
      publicIds,
    );
    const cronoMessagesByProspectId = groupBy(cronoLogLinkedin, 'prospectId');

    let shouldInvalidateCache = false;
    for (const prospectId in cronoMessagesByProspectId) {
      const cronoMessages = cronoMessagesByProspectId[prospectId];
      const prospect = cronoMessages[0]!.prospect;
      const prospectPublicId = LinkedinUrlUtils.getIdFromLinkedinProfileUrl(
        prospect?.linkedin,
      );
      if (!prospectPublicId) {
        await this.analytics?.track('crono-service-error', {
          message: 'prospectPublicId not found',
        });
        continue;
      }

      const updatedConversation = newConversations.find(
        (c) => c.participantPublicId === prospectPublicId,
      );
      if (!updatedConversation) {
        await this.analytics?.track('crono-service-error', {
          message: 'updatedConversation not found',
        });
        continue;
      }

      if (!prospect?.owned) {
        continue;
      }

      let fullConversation:
        | {
            sent: boolean;
            timestamp: number;
            message: string;
          }[]
        | null;
      if (cronoMessages.length === 1 && !updatedConversation.isSent) {
        // we can skip downloading full conversation
        fullConversation = [
          {
            sent: false,
            timestamp: updatedConversation.lastActivity!,
            message: updatedConversation.lastMessage!,
          },
        ];
      } else {
        // we need to download full conversation
        const shouldSkipDownload = this.isConversationRecentlyAnalyzed(
          prospectId,
          updatedConversation.lastActivity!,
        );
        if (shouldSkipDownload) {
          console.log(
            `[CRONO-SERVICE] Conversation with ${prospectId} recently analyzed, will check it later`,
          );
          continue;
        }
        this.markConversationAsRecentlyAnalyzed(
          prospectId,
          updatedConversation.lastActivity!,
        );

        fullConversation = await CronoExtensionRequests.getSalesNavConversation(
          updatedConversation.conversationId!,
          updatedConversation.participantProfileId!,
        );
        await delay();
      }

      if (fullConversation) {
        const patched = await LinkedinMessagesUtils.markLogLinkedinAsAnswered(
          cronoMessages,
          fullConversation,
        );
        shouldInvalidateCache = shouldInvalidateCache || patched;
      }
    }

    if (shouldInvalidateCache && this.queryClient) {
      await invalidateLogLinkedinQueries(this.queryClient);
    }
    lscache.flushExpired();
  }

  private async checkJobChanges() {
    const toCheckResult = await this.getLinkedinCelebrationsCheck();
    if (!toCheckResult?.toCheck) {
      return;
    }

    const jobChanges = await this.getLinkedinJobChanges();
    if (!jobChanges) {
      return;
    }
    for (const jobChange of jobChanges) {
      const prospects = await this.getProspectLinkedin(
        jobChange.name,
        jobChange.publicId,
      );
      const prospect = prospects.prospects.find((p) => p.correct);
      if (!prospect || !prospect.owner) {
        continue;
      }

      let dataChanged = false;
      if (jobChange.type === 'newCompany') {
        dataChanged = prospect.account.name !== jobChange.company;
      } else if (jobChange.type === 'newRole') {
        dataChanged =
          prospect.account.name !== jobChange.company ||
          prospect.title !== jobChange.position;
      }
      if (!dataChanged) {
        continue;
      }

      const linkedinStatus = await CronoExtensionRequests.getLinkedinStatus({
        publicId: jobChange.publicId,
      });
      if (!linkedinStatus) {
        continue;
      }

      const isCurrentCompany =
        linkedinStatus.company?.title === jobChange.company;
      if (!isCurrentCompany) {
        continue;
      }

      const suggestion: SuggestionInsert = {
        prospectId: prospect.objectId,
        accountId: prospect.accountId,
        type:
          jobChange.type === 'newCompany'
            ? SuggestionType.SuggestNewCompany
            : SuggestionType.SuggestNewRole,
        newRole: jobChange.position ?? linkedinStatus.title,
        newCompanyPublicId: linkedinStatus.company?.publicId ?? '',
        newCompanyName: jobChange.company,
      };

      await this.createSuggestion(suggestion);

      if (
        jobChange.type === 'newRole' &&
        prospect.title !== suggestion.newRole
      ) {
        await this.updateProspect({
          prospectId: prospect.objectId,
          title: suggestion.newRole,
        });
      }
    }
  }

  private async getProspectsNewLinkedinEventCheck(type: CronoLogType) {
    const url = `${ENDPOINTS.prospect.newLinkedinEventCheck}?type=${type}`;
    const request: Request = {
      url: url,
      config: {
        method: 'get',
      },
    };
    const response = await client(request);
    return response?.data?.data as {
      toCheck: boolean;
      lastCheck: string | null;
    };
  }

  private async setProspectsNewConnection(newConnectionsPublicIds: string[]) {
    const request: Request = {
      url: ENDPOINTS.prospect.newConnectionSet,
      config: {
        method: 'post',
        data: { publicIds: newConnectionsPublicIds },
      },
    };
    await client(request);
  }

  private async areLinkedinMessagesPending(
    type: 'Message' | 'InMail',
    publicIds: string[],
  ) {
    const request: Request = {
      url: ENDPOINTS.events.check.linkedinPublicIds,
      config: {
        method: 'post',
        data: { publicIds, type },
      },
    };
    const response = await client(request);
    return response.data.data as LogLinkedin[];
  }

  private async getProspectLinkedin(name: string, publicId: string) {
    const request = createGetProspectLinkedinRequest({ name, publicId });
    const response = await client(request);
    return response?.data?.data as {
      prospects: ProspectLinkedin[];
      accounts: AccountLinkedin[];
    };
  }

  private async getLinkedinCelebrationsCheck() {
    const request: Request = {
      url: ENDPOINTS.events.check.linkedinCelebrations,
      config: {
        method: 'get',
      },
    };

    const response = await client(request);
    if (!response?.data?.data || response?.data?.data.length <= 0) {
      return null;
    }

    return response.data.data as { lastCheck: string; toCheck: boolean };
  }

  private async updateProspect(updateProspect: UpdateProspect) {
    const request: Request = {
      url: `${ENDPOINTS.prospect.main}`,
      config: {
        method: 'patch',
        data: updateProspect,
      },
    };

    return await client(request);
  }

  private isConversationRecentlyAnalyzed(
    prospectId: string,
    lastMessageTime: number,
  ) {
    return lscache.get(prospectId) === lastMessageTime;
  }

  private markConversationAsRecentlyAnalyzed(
    prospectId: string,
    lastMessageTime: number,
  ) {
    lscache.set(prospectId, lastMessageTime, 3 * 60);
  }

  private async getLinkedinJobChanges() {
    const response =
      await gatewayService.execute<CronoGatewayLinkedinGetJobChangesAction>({
        target: 'linkedin',
        methodName: 'getJobChanges',
        params: {},
        identifiers: null,
      });

    if (typeof response === 'string' || !response.jobChanges) {
      return null;
    }
    return response.jobChanges;
  }

  private async createSuggestion(suggestion: SuggestionInsert) {
    const request: Request = {
      url: ENDPOINTS.suggestion.main,
      config: {
        method: 'post',
        data: { data: suggestion },
      },
    };

    await client(request);
  }
}

export const CronoService = new CronoServiceClass();

function delay() {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000 + Math.random() * 1000);
  });
}

function groupBy<T, K = keyof T>(collection: T[], iteratee: K) {
  return collection.reduce(
    (acc, item) => {
      // @ts-ignore
      const key = item[iteratee];
      // @ts-ignore
      acc[key] = (acc[key] || []).concat(item);
      return acc;
    },
    {} as Record<string, T[]>,
  );
}
