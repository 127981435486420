import {
  CircularProgress,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Box,
} from '@mui/material';
import { useAuth } from 'context/auth';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { OnBoardingWrapper } from './style';
import usePatchUserOnboardingIntegrations from 'hooks/services/user/usePatchUserOnboardingIntegrations';
import IntegrationStep from './integrationStep';
import { IntegrationsOnboarding } from 'crono-fe-common/types/DTO/integrationsOnboarding';
import {
  ConnectWrapper,
  OnboardingImageContainer,
} from 'pages/connect/views/main/styles';
import Logo from 'assets/images/logo_crono.png';
import { colors } from 'crono-fe-common/theme';
import { getExternalImage } from 'utils/fe-utils';
import { ImageResources } from 'crono-fe-common/types/enums/imageResources';

export type OnBoardingStep = keyof Omit<
  IntegrationsOnboarding,
  'emailConnectedOnboarding' | 'linkedinConnectedOnboarding'
>;

const stepText = {
  adminCrmConnected: {
    title: 'Set Up Salesforce Workspace',
    subtitle:
      'Create Crono’s Webhooks into your Salesforce workspace to habilitate users’ CRM integrations',
  },
  crmConnected: {
    title: 'Connect with your CRM',
    subtitle: 'Integrate your usual CRM to transfer all prospects in seconds',
  },
  emailConnected: {
    title: 'Connect email',
    subtitle: 'Integrate your email tool to communicate with your prospects',
  },
  linkedinConnected: {
    title: 'Install Chrome extension',
    subtitle: 'Integrate your LinkedIn to communicate with your prospects',
  },
};

const OnBoarding = () => {
  const { onboardingState, user } = useAuth();

  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate(PATH.HOME);
    }
  }, [user]);

  //Current step showed (the user can go back and forward)
  const [currentStep, setCurrentStep] = useState<number | null>(null);

  //Usefull to keep the steps when the onboarding is done and we want to user to press finish.
  //IF WE WANT TO REDIRECT HIM, THIS IS USELESS
  const lastSteps = useRef<
    { step: OnBoardingStep; completed: boolean }[] | null
  >(null);

  //Steps available for this user and their state (completed or not)
  const steps: { step: OnBoardingStep; completed: boolean }[] = useMemo(() => {
    if (onboardingState === null) {
      //If lastSteps is null it means that the user has completed the onboarding (this is why we entered the
      //previous if) but he does not come from the onboarding (so for example he may have typed in the search bar
      //the url of the onboarding), so we have to redirect him to the home page
      if (lastSteps.current === null) {
        navigate(PATH.HOME);
        return [];
      }
      //If the onboardingState is null, it means that the user has finished the onboarding
      //In lastSteps I have the previous steps, so I have to set the last value to completed=true
      lastSteps.current = lastSteps.current!.map((step, index) => {
        if (index === lastSteps.current!.length - 1) {
          return {
            ...step,
            completed: true,
          };
        }
        return step;
      });
      return lastSteps.current ?? [];
    }
    if (onboardingState === undefined) return [];
    const newSteps: { step: OnBoardingStep; completed: boolean }[] = [];
    let lastDone = 0;
    let lastReached = true;
    if (onboardingState.SalesforceAdmin !== 'NotRelevant') {
      newSteps.push({
        step: 'adminCrmConnected',
        completed: onboardingState.SalesforceAdmin === 'Completed',
      });
      if (onboardingState.SalesforceAdmin === 'Completed' && lastReached) {
        lastDone = lastDone + 1;
      } else {
        lastReached = false;
      }
    }
    if (onboardingState.CrmConnected !== 'NotRelevant') {
      newSteps.push({
        step: 'crmConnected',
        completed: onboardingState.CrmConnected === 'Completed',
      });
      if (onboardingState.CrmConnected === 'Completed' && lastReached) {
        lastDone = lastDone + 1;
      } else {
        lastReached = false;
      }
    }
    if (onboardingState.EmailConnected !== 'NotRelevant') {
      newSteps.push({
        step: 'emailConnected',
        completed: onboardingState.EmailConnected === 'Completed',
      });
      if (onboardingState.EmailConnected === 'Completed' && lastReached) {
        lastDone = lastDone + 1;
      } else {
        lastReached = false;
      }
    }
    if (onboardingState.LinkedinConnected !== 'NotRelevant') {
      newSteps.push({
        step: 'linkedinConnected',
        completed: onboardingState.LinkedinConnected === 'Completed',
      });
    }
    //Initially I place the user in the next integration to do
    //If the currentStep is already initialized I do not reload it
    //If we want to send the user to the next step to do when we detect changes, remove the if statement
    if (currentStep === null) setCurrentStep(lastDone);
    //I save the steps to be able to show it when the onboarding is done and the user is not automatically redirected
    //IF WE WANT TO REDIRECT THE USER, JUST REMOVE THIS AND THE PREVIOUS IF
    lastSteps.current = newSteps;
    return newSteps;
  }, [onboardingState]);

  const { mutate: patchUserOnboarding } = usePatchUserOnboardingIntegrations();
  //The completed parameter will be removed, once a step of the import is done
  const handlePatchUserOnboarding = (
    step: OnBoardingStep,
    completed: boolean,
    clearSettings?: boolean,
  ) => {
    let stepName: keyof IntegrationsOnboarding = step;
    //From the onboarding we patch the onboarding version of the features, not the connect one
    if (stepName === 'emailConnected') {
      stepName = 'emailConnectedOnboarding';
    } else if (stepName === 'linkedinConnected') {
      stepName = 'linkedinConnectedOnboarding';
    }
    patchUserOnboarding({
      [stepName]: !completed,
      clearSettings,
    });
  };
  //Function called when the nextButton is pressed.
  //If the currentStep is the first not completed I call the API to complete it
  const handleGoNextStep = () => {
    if (currentStep === null) return;
    //If the step is not completed I complete it
    //If the user has not completed the action it means that he wants to skip this, so we have to patch it
    //If he has already completed the integration, the completed will be true, so we don't have to patch it
    if (!steps[currentStep].completed) {
      handlePatchUserOnboarding(steps[currentStep].step, false);
    }
    // patch takes a moment to update on the background, but for now it is not something that should block the forward navigation
    setCurrentStep((prev) => {
      if (prev === null) return null;
      //Finish pressed
      if (prev === steps.length - 1) {
        navigate(PATH.HOME);
        return prev;
      }
      return prev + 1;
    });
  };
  const handleBack = () => {
    setCurrentStep((prev) => {
      if (prev === null) return null;
      if (prev > 0) return prev - 1;
      return prev;
    });
  };
  //THIS WILL NOT EXIST IN PRODUCTION, IS JUST FOR TESTING RIGHT NOW
  //The user has no possibility to go back to the onboarding once it is done
  // const handleDisconnect = (step: OnBoardingStep) => {
  // handlePatchUserOnboarding(step, true, true);
  // };

  //If we are checking add a loading
  if (onboardingState === undefined) return <CircularProgress />;
  //If null it means that the onboarding is done
  if (onboardingState === null) {
    navigate(PATH.HOME);
    return <></>;
  }

  return (
    <OnBoardingWrapper>
      <ConnectWrapper>
        <div className="onboarding-steps-container">
          <div className="logo-container">
            <img src={Logo} className="logo" alt="Crono Logo" />
          </div>
          <div className="divider" />
          <div className="connect-crm">
            <div className="step-title-container">
              <Typography
                fontSize={'28px'}
                fontWeight={700}
                lineHeight={'32px'}
              >
                {stepText[steps[currentStep ?? 0].step].title}
              </Typography>
              <Typography
                fontSize={'16px'}
                fontWeight={500}
                lineHeight={'24px'}
                color={colors.grey11}
                textAlign={'center'}
                maxWidth={'406px'}
              >
                {stepText[steps[currentStep ?? 0].step].subtitle}
              </Typography>
            </div>
            {/* Steps progress */}
            {currentStep !== null && (
              <Box
                sx={{
                  width: '280px',
                  display: steps?.length === 1 ? 'contents' : '',
                }}
              >
                <Stepper activeStep={currentStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    return (
                      <Step
                        style={{ padding: '0px' }}
                        key={label.step}
                        {...stepProps}
                      >
                        <StepLabel
                          sx={{
                            padding: '0px',
                            '& .MuiStepLabel-iconContainer': {
                              padding: '0px',
                            },
                            '& .MuiStepIcon-text': {
                              fill: currentStep !== index ? colors.grey2 : '',
                            },
                            '& .MuiStepIcon-root': {
                              height: '30px',
                              width: '30px',
                              fill: currentStep !== index ? colors.white : '',
                              border: '1px solid',
                              borderRadius: '999px',
                            },
                            '& .Mui-active, .Mui-completed': {
                              border: 'none !important',
                            },
                            '& .Mui-completed': {
                              fill: colors.primary,
                            },
                          }}
                          {...labelProps}
                        ></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            )}
            <div style={{ width: '100%' }}>
              {steps?.length > 0 && (
                <IntegrationStep
                  step={steps[currentStep ?? 0].step}
                  completed={steps[currentStep ?? 0].completed}
                  firstStep={currentStep === 0}
                  handleGoNextStep={handleGoNextStep}
                  handleBack={handleBack}
                  onboardingState={onboardingState}

                  // handleDisconnect={handleDisconnect}
                />
              )}
            </div>
          </div>
        </div>
      </ConnectWrapper>
      <OnboardingImageContainer>
        <img
          src={getExternalImage(ImageResources.ONBOARDING)}
          alt="onboarding"
          className="onboarding-image"
        />
      </OnboardingImageContainer>
    </OnBoardingWrapper>
  );
};

export default OnBoarding;
