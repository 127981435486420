import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinIconInvoiceDark = ({
  color = colors.white,
  color2 = colors.blueHover,
  className,
  onClick,
  style,
  viewBox = '0 0 32 32',
}: IProps) => {
  return (
    <svg
      width="32"
      height="32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={className}
      onClick={onClick}
      {...style}
    >
      <rect width="32" height="32" rx="16" fill={color2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1286 12.833L13.0859 12.833L13.0859 20H15.286L15.286 16.5279C15.286 15.3646 15.7177 14.6249 16.7567 14.6249C17.3507 14.6249 17.6587 15.0565 17.7608 15.8422C18.2108 15.2114 18.9486 14.8 19.7823 14.8C19.8278 14.8 19.8729 14.8012 19.9177 14.8036C19.7028 13.4586 19.0185 12.7395 17.3111 12.7395C16.1775 12.7395 15.4229 13.2653 15.1286 13.8505V12.833ZM11.5135 12.7395L11.5135 19.9065H9.3135L9.3135 12.7395H11.5135ZM11.7211 10.9396C11.7922 10.7695 11.8287 10.5869 11.8287 10.4025C11.8287 10.0302 11.6796 9.67375 11.4145 9.41085C11.1488 9.14795 10.7892 9 10.4141 9C10.0396 8.99984 9.68029 9.14749 9.41415 9.41085C9.1485 9.67375 9 10.0302 9 10.4025C9.00032 10.5869 9.03702 10.7694 9.10799 10.9395C9.17896 11.1097 9.28282 11.2642 9.4136 11.3942C9.67997 11.6572 10.0392 11.8047 10.4135 11.805C10.7885 11.8059 11.1485 11.6579 11.4145 11.3936C11.5458 11.2641 11.65 11.1098 11.7211 10.9396Z"
        fill={color}
      />
      <path
        d="M22.5621 19.3965C22.5621 20.9323 21.3169 22.1775 19.7811 22.1775V22.1775C18.2453 22.1775 17 20.9323 17 19.3965"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8008 19.4207C18.8008 19.9625 19.2405 20.4023 19.7823 20.4023C20.3241 20.4023 20.7639 19.9625 20.7639 19.4207V17.3331C20.7639 16.7913 20.3241 16.3516 19.7823 16.3516C19.2405 16.3516 18.8008 16.7913 18.8008 17.3331V19.4207Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7823 22.2969V23.1148"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinIconInvoiceDark;
