import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const NotificationBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: 100%;
  overflow: auto;
  .close-button {
    cursor: pointer;
  }
  .notification-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
  }
  .notification-rows-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    overflow: hidden scroll;
  }
  .divider {
    border-top: 1px solid #dddddd;
    opacity: 0.5;
    margin: 0;
  }
  .number-of {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    height: 24px;
    width: 24px;
    background: ${colors.grey55};
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    min-width: 40px;
    height: 40px;
    gap: 4px;
    padding-inline: 8px;
    cursor: pointer;
    margin-right: 16px;
  }
  .filter-button:hover {
    background-color: ${colors.blueLightHover};
  }
  .show-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #c5c5c5;
    border-radius: 8px;
    padding: 12px;
    height: 40px;
    background-color: ${colors.grey4};
  }
  .return-to-top-button {
    position: sticky;
    bottom: 0px;
    left: 100%;
    background: ${colors.white};
    :hover {
      background: ${colors.grey6};
    }
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    border: 1px solid ${colors.grey444};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
`;
