import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconStarsInsights = ({
  color = colors.grey11,
  className,
  onClick,
  style,
  viewBox = '0 0 18 18',
}: IProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24805 12.595C3.90559 12.595 5.2493 13.9387 5.2493 15.5962C5.2493 13.9387 6.593 12.595 8.25055 12.595C6.593 12.595 5.2493 11.2513 5.2493 9.59375C5.2493 11.2513 3.90559 12.595 2.24805 12.595Z"
        stroke={color}
        strokeWidth="1.09"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 10.345C11.4075 10.345 12.7512 11.6887 12.7512 13.3462C12.7512 11.6887 14.095 10.345 15.7525 10.345C14.095 10.345 12.7512 9.00129 12.7512 7.34375C12.7512 9.00129 11.4075 10.345 9.75 10.345V10.345Z"
        stroke={color}
        strokeWidth="1.09"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24805 5.09207C6.90559 5.09207 8.2493 6.43578 8.2493 8.09332C8.2493 6.43578 9.593 5.09207 11.2505 5.09207C9.593 5.09207 8.2493 3.74836 8.2493 2.09082C8.2493 3.74836 6.90559 5.09207 5.24805 5.09207Z"
        stroke={color}
        strokeWidth="1.09"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconStarsInsights;
