import { ENDPOINTS } from 'config/endpoints';
import { useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { TaskInsert } from 'crono-fe-common/types/DTO/taskInsert';
import { useLimitedMutation } from '../useLimitedMutation';

export default function useCreateTask() {
  const queryClient = useQueryClient();
  const createTask = (taskInsert: TaskInsert) => {
    const request: Request = {
      url: `${ENDPOINTS.task.main}`,
      config: {
        method: 'post',
        data: { data: taskInsert },
      },
    };
    return client(request);
  };

  return useLimitedMutation<Response<null>, CronoError, TaskInsert>(
    createTask,
    {
      onSettled: (result, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.task.main]);
        queryClient.invalidateQueries([ENDPOINTS.task.next]);
        queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
        queryClient.invalidateQueries([ENDPOINTS.task.total]);
        queryClient.invalidateQueries([ENDPOINTS.task.search]);
        queryClient.invalidateQueries([ENDPOINTS.task.overdue]);
      },
    },
  );
}
