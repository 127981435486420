import {
  CircularProgress,
  IconButton,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import {
  CancelButton,
  MainButton,
  TabSelectionButton,
} from 'crono-fe-common/components/CronoButton';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import {
  ExternalProperty,
  TableType,
} from 'crono-fe-common/types/externalProperty';
import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import { AddTagWrapper } from './style';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import useGetExternalPropertyCRM from 'hooks/services/externalProperty/useGetExternalPropertyCRM';
import useCreateExternalProperty from 'hooks/services/externalProperty/useCreateExternalProperty';
import { getError, getExternalPropertyLabels } from 'crono-fe-common/utils';
import { useConditionalSnackBar } from 'context/snackbar';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { Constants } from 'crono-fe-common/constants/constants';
import ExternalPropertyEditable from 'crono-fe-common/components/ExternalPropertyEditable';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  initialTab: TableType;
  close: () => void;
}

const CreateTag = ({ initialTab, close }: IProps) => {
  const [tab, setTab] = useState<TableType | null>(initialTab);

  const { user } = useAuth();

  const [onlyCrono, setOnlyCrono] = useState<boolean>(true);

  const [tagsToShow, setTagsToShow] = useState<string[]>([]);

  const { data: allTags } = useGetExternalProperty(null, true);

  const accountTags = useMemo(() => {
    return allTags?.data?.data.filter((tag) => tag.tableType === 'Account');
  }, [allTags?.data?.data]);
  const prospectTags = useMemo(() => {
    return allTags?.data?.data.filter((tag) => tag.tableType !== 'Account');
  }, [allTags?.data?.data]);

  const [propertyName, setPropertyName] = useState<string>('');

  const [allowMultiSelection, setAllowMultiSelection] =
    useState<boolean>(false);

  const handleRemoveOption = (index: number) => {
    const newTags = [...tagsToShow];
    newTags.splice(index, 1);
    setTagsToShow(newTags);
  };

  const [newValue, setNewValue] = useState<string>('');

  const handleAdd = (newValue: string) => {
    if (checkDuplicate(newValue)) return;
    if (newValue === '') return;
    setTagsToShow([...tagsToShow, newValue]);
    setNewValue('');
  };

  const [selectedPropertyCRM, setSelectedPropertyCRM] =
    useState<ExternalProperty | null>(null);

  const { data: externalPropertiesCRM } = useGetExternalPropertyCRM(
    tab,
    'List',
    false,
    user?.company?.integrationType !== IntegrationType.CRONO && !onlyCrono,
  );

  const handleSelectExternalPropertyCRM = (externalName: string) => {
    const property =
      externalPropertiesCRM?.data?.data.find(
        (p) => p.externalName === externalName,
      ) || null;
    setSelectedPropertyCRM(property);
    if (property) {
      setTagsToShow(getExternalPropertyLabels(property) || []);
    } else {
      setTagsToShow([]);
    }
  };

  //When onlyCrono changes I reset the selections
  useEffect(() => {
    setTagsToShow([]);
    setSelectedPropertyCRM(null);
    setPropertyName('');
    setNewValue('');
  }, [onlyCrono]);

  useEffect(() => {
    if (!onlyCrono) {
      setTagsToShow([]);
      setSelectedPropertyCRM(null);
    }
  }, [tab]);

  const {
    mutate: createExternalProperty,
    isSuccess: externalPropertyCreated,
    error: externalPropertyCreationError,
    isLoading: creatingExternalProperty,
  } = useCreateExternalProperty();

  useConditionalSnackBar([
    {
      condition: !!externalPropertyCreationError,
      message: getError(externalPropertyCreationError) || 'Error creating tag',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (externalPropertyCreated) {
      close();
    }
  }, [externalPropertyCreated]);

  const analytics = useJuneAnalytics();

  const handleCreateProperty = () => {
    if (!tab) return;
    if (onlyCrono) {
      createExternalProperty({
        externalName: propertyName,
        publicName: propertyName,
        tableType: tab,
        top: false,
        valueType: allowMultiSelection && onlyCrono ? 'MultiSelect' : 'List',
        isEditable: true,
        isInsert: true,
        isTag: true,
        labels: tagsToShow,
        options: tagsToShow,
        isSync: false,
      });
    } else {
      createExternalProperty({
        externalName: selectedPropertyCRM?.externalName,
        publicName: selectedPropertyCRM?.publicName,
        tableType: tab,
        top: false,
        valueType: selectedPropertyCRM?.valueType ?? 'List',
        isEditable: selectedPropertyCRM?.isEditable,
        isInsert: selectedPropertyCRM?.isEditable,
        isTag: true,
        labels: selectedPropertyCRM?.labels,
        options: selectedPropertyCRM?.options,
        isSync: true,
      });
    }
    if (
      (allowMultiSelection && onlyCrono) ||
      selectedPropertyCRM?.valueType === 'MultiSelect'
    ) {
      if (analytics) {
        analytics.track('tag-multiselect-created', {});
      }
    }
  };

  const checkDuplicate = (newValue: string) => {
    return tagsToShow.find((tag) => tag === newValue);
  };

  const handleChangePropertyOption = (newValue: string, index: number) => {
    if (checkDuplicate(newValue)) return;
    const newTags = [...tagsToShow];
    newTags[index] = newValue;
    setTagsToShow(newTags);
  };

  return (
    <BlurBackgroundDivFixed>
      <AddTagWrapper>
        <CloseTabButton close={close} />
        <div className="add-tag-container">
          <Typography fontSize={24} fontWeight={700}>
            Create property:
          </Typography>
          {user?.company?.integrationType !== IntegrationType.CRONO && (
            <div>
              <TabSelectionButton
                selected={onlyCrono}
                onClick={() => setOnlyCrono(true)}
              >
                Only in Crono
              </TabSelectionButton>
              <TabSelectionButton
                selected={!onlyCrono}
                style={{ position: 'relative', right: 6 }}
                onClick={() => setOnlyCrono(false)}
              >
                Linked with CRM
              </TabSelectionButton>
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
              Property related to:
            </Typography>
            <div className="tabs-tag-container">
              <div className="tab-option">
                <Radio
                  color="secondary"
                  checked={tab === 'Account'}
                  onClick={() => setTab('Account')}
                  disabled={accountTags?.length === Constants.numberOfTags}
                />
                <IconMCompany />
                <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
                  Company
                </Typography>
              </div>
              <div className="tab-option">
                <Radio
                  color="secondary"
                  checked={tab === 'Prospect'}
                  onClick={() => setTab('Prospect')}
                  disabled={prospectTags?.length === Constants.numberOfTags}
                />
                <IconMContact />
                <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
                  Contact
                </Typography>
              </div>
              {user?.company?.integrationType === IntegrationType.SALESFORCE &&
                !onlyCrono && (
                  <div className="tab-option">
                    <Radio
                      color="secondary"
                      checked={tab === 'Lead'}
                      onClick={() => setTab('Lead')}
                      disabled={prospectTags?.length === Constants.numberOfTags}
                    />
                    <IconMContact />
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'16px'}
                    >
                      Lead
                    </Typography>
                  </div>
                )}
            </div>
          </div>
          {onlyCrono ? (
            <>
              <div>
                <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
                  Property name:
                </Typography>
                <TextField
                  variant="standard"
                  fullWidth
                  placeholder="Type property name..."
                  value={propertyName}
                  onChange={(e) => setPropertyName(e.target.value)}
                />
              </div>
              <div>
                <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
                  Property values:
                </Typography>
                <div className="insert-value-text-field">
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Type value name..."
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAdd(newValue);
                      }
                    }}
                  />
                  <div
                    className="manage-value-buttons-container"
                    style={{
                      display:
                        newValue !== '' && newValue !== null ? 'flex' : 'none',
                    }}
                  >
                    <IconButton
                      className="manage-value-button"
                      style={{ background: colors.inactiveLight }}
                      onClick={() => setNewValue('')}
                    >
                      <CloseMIcon color={colors.inactive} />
                    </IconButton>
                    <IconButton
                      className="manage-value-button"
                      style={{ background: colors.callScheduledLight }}
                      onClick={() => handleAdd(newValue)}
                    >
                      <CheckMarkIcon color={colors.callScheduled} />
                    </IconButton>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ display: 'flex', flexDirection: 'column', rowGap: 6 }}
              >
                <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
                  Property name:
                </Typography>
                <Select
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: `1px solid ${colors.grey3}`,
                      borderRadius: 2,
                    },
                  }}
                  variant="standard"
                  placeholder="Select property"
                  MenuProps={{
                    style: {
                      zIndex: 35001,
                      maxHeight: '50%',
                      borderRadius: 16,
                    },
                  }}
                  fullWidth
                  size="small"
                  onChange={(ev) => {
                    handleSelectExternalPropertyCRM(ev.target.value as string);
                  }}
                  value={selectedPropertyCRM?.id || 0}
                  renderValue={(value) =>
                    selectedPropertyCRM?.publicName || 'Select property'
                  }
                >
                  {externalPropertiesCRM?.data?.data.map((externalProperty) => (
                    <MenuItem
                      key={externalProperty.id}
                      value={externalProperty.externalName}
                      style={{ borderBottom: `1px solid ${colors.grey44}` }}
                    >
                      {externalProperty.publicName}
                    </MenuItem>
                  ))}
                </Select>
                <Typography
                  fontSize={11}
                  fontWeight={400}
                  lineHeight={'14px'}
                  color={colors.grey11}
                >
                  *If you change the name in Crono, it will remain unchanged in
                  your CRM{' '}
                </Typography>
              </div>
            </>
          )}
          <div className="options-container">
            {tagsToShow
              .filter((option) => option !== null && option !== '')
              .map((option, index) => (
                <ExternalPropertyEditable
                  key={`Option-Tag-${index}-${option}`}
                  value={option}
                  index={
                    (tab === 'Account'
                      ? accountTags?.length
                      : prospectTags?.length) || 0
                  }
                  indexOption={index}
                  onClick={() => {
                    handleRemoveOption(index);
                  }}
                  handleSetValue={(value) => {
                    if (value !== option)
                      handleChangePropertyOption(value, index);
                  }}
                />
              ))}
          </div>
          {onlyCrono && (
            <div className="switcher-multiselection">
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'14px'}
                color={colors.grey11}
              >
                Allow multi-selection
              </Typography>
              <CronoSwitch
                checked={allowMultiSelection}
                onChange={() => setAllowMultiSelection(!allowMultiSelection)}
              />
            </div>
          )}
          <FlexDiv width="fit-content" style={{ alignSelf: 'flex-end' }}>
            <CancelButton onClick={() => close()}>Cancel</CancelButton>
            {creatingExternalProperty ? (
              <CircularProgress />
            ) : (
              <MainButton
                disabled={
                  (onlyCrono && !propertyName) ||
                  (!onlyCrono && !selectedPropertyCRM)
                }
                style={{
                  background:
                    (onlyCrono && !propertyName) ||
                    (!onlyCrono && !selectedPropertyCRM)
                      ? colors.grey11
                      : undefined,
                }}
                onClick={handleCreateProperty}
              >
                Create
              </MainButton>
            )}
          </FlexDiv>
        </div>
      </AddTagWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default CreateTag;
