import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { UpdatePasswordInputs } from 'crono-fe-common/types/updatePassword';

export default function useUpdatePassword() {
  const updatePassword = ({
    email,
    password,
    newPassword,
  }: UpdatePasswordInputs) => {
    const request: Request = {
      url: ENDPOINTS.auth.password,
      config: {
        method: 'put',
        data: { email, password, newPassword },
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    UpdatePasswordInputs
  >(updatePassword, {});

  return { isSuccess, isError, mutate, ...rest };
}
