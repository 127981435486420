import React, { useEffect, useState } from 'react';
import { PaginationWrapper } from './style';
import { TextField } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

import IconLeftArrow from 'crono-fe-common/icons/Icon-Left-Arrow';
import IconRightArrow from 'crono-fe-common/icons/Icon-Right-Arrow';

interface IProps {
  count: number | undefined;
  total: number | undefined;
  itemOffset: number;
  setItemOffset:
    | React.Dispatch<React.SetStateAction<number>>
    | ((offset: number) => void);
  limit: number;
  resultsShown?: number;

  //Component to render in the left side of the pagination
  selectionComponent?: React.ReactNode;

  style?: any;
  selectorStyle?: any;
}

const Pagination = ({
  count,
  total,
  itemOffset,
  setItemOffset,
  limit,
  resultsShown,
  selectionComponent,
  style,
  selectorStyle,
}: IProps) => {
  const currentNumberOfResults = resultsShown ?? limit;
  const [pageCount, setPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number | null>(1);
  const handlePageClick = (increment: number) => {
    if (!currentPage) return;
    let newPage = currentPage + increment;

    if (increment === 1) {
      const pagesLoaded = Math.ceil(currentNumberOfResults / limit);
      newPage = currentPage + pagesLoaded;
    }
    if (newPage <= pageCount && newPage > 0 && count !== null) {
      setCurrentPage(newPage);
      const newOffset = (newPage - 1) * limit;
      setItemOffset(newOffset);
    }
  };

  //When the offset is changed update the currentPage (when the parent update it, typically when the table is sorted)
  useEffect(() => {
    setCurrentPage(Math.ceil(itemOffset / limit) + 1);
  }, [itemOffset]);

  const handleManualInput = (value: string) => {
    if (value === '') {
      setCurrentPage(null);
      return;
    }

    let numberValue = +value;
    if (numberValue === 0) return;
    if (numberValue > pageCount || numberValue <= 0) {
      numberValue = pageCount;
    }
    setCurrentPage(numberValue);
    const newOffset = (numberValue - 1) * limit;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    // Calculate the total number of pages
    if (total !== undefined) {
      const newPageCount = Math.ceil(total / limit);
      setPageCount(newPageCount);
    }
  }, [total]);

  return (
    <PaginationWrapper id="pagination-wrapper" style={style}>
      <span>{selectionComponent ?? ''}</span>
      <span className="selector" style={selectorStyle}>
        <span
          style={{
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '18px',
            color: colors.grey11,
          }}
        >
          {total !== undefined
            ? (total === 0 ? 0 : itemOffset + 1) +
              ' - ' +
              (total === 0
                ? 0
                : Math.min(
                    itemOffset + currentNumberOfResults,
                    total || 999999,
                  )) +
              '  of  ' +
              total
            : 'Loading...'}
        </span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <IconLeftArrow
            color={currentPage === 1 ? colors.grey444 : colors.grey11}
            onClick={() => handlePageClick(-1)}
            className="arrow-pagination"
          />
          <TextField
            type="number"
            InputProps={{
              sx: {
                height: 30,
                width: 50,
                marginInline: 1,
                borderRadius: 2,
                '&:hover': {
                  background: colors.grey6,
                },
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: `1px solid ${colors.grey5}`,
                },
                '&.Mui-focused fieldset': {
                  border: `1px solid ${colors.grey5}`,
                },
              },
            }}
            inputProps={{
              style: {
                textAlign: 'center',
                border: 'none',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '18px',
              },
            }}
            value={currentPage}
            onChange={(ev) => {
              handleManualInput(ev.target.value);
            }}
          />
          <IconRightArrow
            color={currentPage === pageCount ? colors.grey444 : colors.grey11}
            onClick={() => handlePageClick(1)}
            className="arrow-pagination"
          />
        </span>
      </span>
    </PaginationWrapper>
  );
};

export default Pagination;
