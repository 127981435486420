import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TaskHomeWrapper = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  //To compensate the padding of the children
  width: calc(100% + 16px);
  margin-inline: -8px;

  .section {
    padding-inline: 8px;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  .inner-section {
    background: ${colors.white};
    border-radius: 16px;
    padding-top: 16px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .inner-section-half {
    background: ${colors.white};
    border-radius: 16px;
    padding: 12px 12px 12px 0px;
    height: 100%;
    overflow: hidden;
    padding-top: 24px;
  }
  .live-feed {
  }

  .show-filters-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 24px 16px 24px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
  }
  .show-filters-single-box {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
  }

  .suggestion {
  }
  .selected-tasks-tabs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .selected-tasks {
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 12px;
    gap: 4px;
    background-color: ${colors.grey6};
    border-radius: 8px;
    cursor: pointer;
  }
  .selected-tasks-actions {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
  }
  .vertical-divider {
    border-left: 1px solid ${colors.grey444};
    min-height: 100%;
    margin: 0px;
  }
  .action-button-container {
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 6px;
    gap: 6px;
    cursor: pointer;
    color: ${colors.grey11};
  }
  .action-button-container:hover {
    color: ${colors.black};
  }
  .action-button-container > svg > path {
    stroke: ${colors.grey11};
  }
  .action-button-container:hover > svg > path {
    stroke: ${colors.black};
  }
  .executable-tasks-list {
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 8px 24px 0px 24px;
  }
  .automatic-limit-exceeded-banner {
    background: ${colors.prospectingLight};
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 14px 16px;
    .icon-info-exceeded {
      background: ${colors.prospecting};
      height: 32px;
      width: 32px !important;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
  .tasks-list {
    max-height: calc(100% - 136px);
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-right: 20px;
  }
  .tab,
  .tab-not-selected {
    border-radius: 6px !important;
    text-transform: unset !important;
    min-height: 20px !important;
    min-width: 40px !important;
    padding: 2px;
  }
  .tab {
    background: ${colors.primary};
    color: ${colors.white};
  }
  .tab-not-selected {
    color: ${colors.grey1} !important;
    opacity: 1;
    filter: grayscale(0);
  }
  .tab:hover {
    background: ${colors.primaryDark};
  }
  .tab-not-selected:hover {
    background-color: ${colors.grey6};
  }
  .tab > svg > path {
    stroke: ${colors.white};
  }
  .tab > svg#linkedin-inmail-compass-icon > path#path1_fill {
    fill: ${colors.white};
    stroke: transparent;
  }
  .tab > svg#linkedin-inmail-compass-icon > path#path2_fill {
    fill: ${colors.white};
    stroke: transparent;
  }
  .tab > svg#linkedin-icon > path {
    fill: ${colors.white};
    stroke: transparent;
  }
  .tab-not-selected > svg > path {
    stroke: ${colors.grey1};
  }
  .tab-not-selected > svg#linkedin-icon > path {
    fill: ${colors.grey1};
    stroke: transparent;
  }
  .tab-not-selected > svg#linkedin-inmail-compass-icon > path#path1_fill {
    fill: ${colors.grey1};
    stroke: transparent;
  }
  .tab-not-selected > svg#linkedin-inmail-compass-icon > path#path2_fill {
    fill: ${colors.grey1};
    stroke: transparent;
  }
  .right-icons-button-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .tabs-group {
    display: flex;
    gap: 2px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    padding: 3px;
    height: fit-content;
    min-width: 145px;
    justify-content: space-between;
  }
  .button-filters {
    height: 32px;
    border-radius: 8px;
    border: 1px solid ${colors.grey444};
    gap: 2px;
    display: flex;
    align-items: center;
    padding: 4px;
    width: fit-content !important;
  }
  .button-filters > svg > path {
    fill: ${colors.black};
  }
  .switch-container {
    display: flex;
    align-items: center;
  }

  .task-card-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    border-radius: 8px;
    border: 1px solid ${colors.grey44};
  }
  .task-card-wrapper:hover {
    background-color: ${colors.greyHover};
  }
  .automatic-task:hover {
    background-color: ${colors.white};
  }
  .greater-than-today:hover {
    background-color: transparent;
    cursor: auto;
  }
  .task-card-error {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 16px;
    background: ${colors.inactiveExtraLight};
    color: ${colors.inactiveHover};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .task-card {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
  }
  .task-card:hover .sequence-type-container-title {
    color: ${colors.darkGreen};
  }
  .task-card:has(.prospect-name:hover):hover,
  .task-card:has(.account-avatar:hover):hover,
  .task-card:has(.sequence-type-container-subtitle:hover):hover,
  .task-card:has(.task-card-buttons-section:hover):hover {
    background-color: ${colors.white};
  }

  /* hover on prospect name sets name task type to black */
  .task-card:has(.prospect-name:hover) .sequence-type-container-title {
    color: ${colors.black};
  }

  .task-card:has(.account-avatar:hover) .sequence-type-container-title {
    color: ${colors.black};
  }

  .task-card:has(.sequence-type-container-subtitle:hover)
    .sequence-type-container-title {
    color: ${colors.black};
  }

  .task-card:has(.task-card-buttons-section:hover)
    .sequence-type-container-title {
    color: ${colors.black};
  }

  .sequence-type {
    display: flex;
    gap: 4px;
  }
  .icon-task-info {
    display: flex;
    gap: 14px;
    align-items: center;
  }
  .card-left-side {
    min-width: 60%;
    width: 60%;
    overflow: hidden;
    display: flex;
  }
  .prospect-info-tags-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 6px;
    width: 100%;
  }
  .prospect-avatar-name-title {
    display: flex;
    gap: 12px;
  }
  .prospect-name-container {
    width: 65%;
  }

  .task-executing {
    background: ${colors.grey55};
    border-left: 4px solid ${colors.black};
  }
  .inner-task-card {
    padding: 8px;
    height: fit-content;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
    width: calc(100% - 110px);
  }
  .prospect-name {
    display: inline;
  }
  .prospect-name:hover {
    color: ${colors.darkGreen};
    cursor: pointer;
  }
  .task-card-buttons-section {
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: space-around;
    border-left: 1px solid ${colors.grey44};
    height: 100%;
    padding-inline: 8px;
  }
  .close-task-executing-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .inner-task-card > svg {
    width: 28px;
    height: 28px;
    margin: 20px 12px;
  }
  .calendar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 12px;
  }
  .day {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 2px;
    border-radius: 8px;
    padding: 5px 4px;
    cursor: pointer;
    color: ${colors.grey1};
    width: 52px;
    height: 52px;
    font-weight: 500;
  }
  .past-day {
    color: ${colors.grey11};
  }
  .selected {
    color: ${colors.white} !important;
    background-color: ${colors.primary};
    border: none !important;
  }
  .not-selected:hover {
    background-color: ${colors.grey6};
  }

  .day-number {
    display: contents;
    align-self: center;
    min-width: 36px;
    min-height: 36px;
    padding: 0px;
  }
  .today {
    border: 1px solid ${colors.grey44};
    color: ${colors.black};
  }
  .number-of-tasks {
    position: absolute;
    top: -5px;
    right: -5px;
    min-width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.blueLightHover};
    color: ${colors.black};
    border-radius: 12px;
    font-size: 12px;
    text-align: center;
    padding: 3px 4px;
    font-weight: 500;
  }
  .picked {
    color: ${colors.white};
    background: ${colors.black};
  }
  .type-icon {
    width: 30px;
    height: 30px;
    border-radius: 16px;
    padding: 7px;
    // cursor: pointer;
  }
  .call {
    background-color: ${colors.primaryLight};
  }
  .email {
    background-color: ${colors.inactiveLight};
  }
  .in-mail {
    background-color: ${colors.purpleLight};
    padding: 5px;
  }
  .linkedin-icon {
    background-color: ${colors.blueLight};
  }
  .type-icon:hover {
    // background: ${colors.grey4};
  }
  .subtype-icon {
    background: ${colors.black} !important;
  }
  .pointer {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .divider {
    border-top: 1px solid #dddddd;
    opacity: 0.5;
    margin-top: 12px;
  }

  .progress {
    display: flex;
    flex-direction: column;
    width: 38%;
  }
  .scheduled-label,
  .auto-label,
  .in-charge-label,
  .completed-label {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    padding: 2px 4px;
    gap: 10px;
    border-radius: 8px;
  }
  .scheduled-label {
    color: ${colors.openOpportunityHover};
    background: ${colors.openOpportunityLight};
  }
  .auto-label {
    color: ${colors.blueHover};
    background: ${colors.grey6};
  }
  .in-charge-label {
    color: ${colors.blueHover};
    background: ${colors.blueLight};
  }
  .completed-label {
    color: ${colors.customerHover};
    background: ${colors.customerLight};
  }
  .aircall-logo-start-campaign {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
  .daily-task-sequence-container {
    width: 10%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 8px;
  }
  .daily-task-pause-sequence-button {
    background: ${colors.grey55};
    width: fit-content;
    height: fit-content;
  }
  .tasks-tabs-container {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 16px 24px 8px 32px;
  }
  .left-task-tabs {
    display: flex;
    gap: 4px;
  }
  .task-tab-button {
    cursor: pointer;
    padding: 0px 6px;
  }
  .counter-task {
    display: inline-flex;
    margin-left: 5px;
    border-radius: 16px;
    padding: 0px 6px;
    height: 20px;
    min-width: 20px;
    align-items: center;
    justify-content: center;
  }
  .today-task {
    color: ${colors.black};
    background-color: ${colors.grey4};
  }
  .overdue-task {
    color: ${colors.inactive};
    background-color: ${colors.inactiveLight};
  }

  .account-avatar {
    height: 32px;
    width: 32px;
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    font-size: 14px !important;
    border: 1px solid ${colors.grey4Hover};
  }
  .white {
    background: ${colors.white} !important;
  }
  .arrow-icon {
    border-radius: 12px;
  }
  .arrow-icon:hover {
    background: ${colors.blueLightHover};
    cursor: pointer;
  }
  .sequence-type-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .sequence-type-container-title {
    color: ${colors.black};
  }
  .sequence-type-container-subtitle {
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${colors.grey11};
  }
  .sequence-type-container-subtitle > svg > path {
    stroke: ${colors.grey11};
  }
  .sequence-type-container-title:hover,
  .sequence-type-container-subtitle:hover {
    color: ${colors.darkGreen};
    cursor: pointer;
  }
  .sequence-type-container-subtitle:hover > svg > path {
    stroke: ${colors.darkGreen};
  }
  .lists-redirect {
    cursor: pointer;
    color: ${colors.darkGreen};
    font-weight: 600;
  }
  .lists-redirect:hover {
    color: ${colors.darkGreenHover};
  }

  .countable-filters-container {
    padding: 16px 24px 8px 32px;
    display: flex;
    gap: 8px;

    .countable-filter-label {
      max-width: 200px;
      height: 32px;
    }
  }

  .automation-error-banner {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
    background: ${colors.inactiveLight};
    border-radius: 8px;
    padding: 16px;
    margin: 0 34px 0 24px;

    .icon-warning {
      min-height: 34px;
      min-width: 34px;
      border-radius: 50%;
      background: ${colors.inactive};
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-warning-error {
        width: 24px;
        height: 24px;
      }
    }

    .close-btn {
      cursor: pointer;
      margin-left: auto;
    }
  }
`;
