import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconVariables = ({ color = colors.black, className, style }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M16.5703 20C17.8323 20 18.8563 18.977 18.8563 17.714V13.143L19.9993 12L18.8563 10.857V6.286C18.8563 5.023 17.8333 4 16.5703 4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.429 4C6.166 4 5.143 5.023 5.143 6.286V10.857L4 12L5.143 13.143V17.714C5.143 18.977 6.166 20 7.429 20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="1.5" fill={color} />
    </svg>
  );
};

export default IconVariables;
