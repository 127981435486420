import styled from '@emotion/styled';

export const TagIconWrapper = styled.div`
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  display: flex;
  align-items: center;
  margin-right: 6px;
`;
