import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { SuggestionSearch } from 'crono-fe-common/types/DTO/suggestionSearch';
import { Suggestion } from 'crono-fe-common/types/suggestion';

export default function useSearchSuggestions(
  searchParameters: SuggestionSearch,
) {
  const request: Request = {
    url: `${ENDPOINTS.suggestion.search}`,
    config: {
      method: 'post',
      data: { data: searchParameters },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.suggestion.search, searchParameters],

    queryFn: () => client(request),
  };
  return useQuery<ResponsePaginated<Suggestion[]>, CronoError>(requestConfig);
}
