import React from 'react';
import { StageColumnWrapper } from './style';
import { OpportunityStageListDTO } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';
import { StageColors } from 'constants/FeConstants';
import { Tooltip, Typography } from '@mui/material';
import { useAuth } from 'context/auth';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import OpportunityBox from './opportunityBox';
import IconBottomArrow from 'crono-fe-common/icons/Icon-Bottom-Arrow';
import { colors } from 'crono-fe-common/theme';
import { numericFormatter } from 'react-number-format';
import { ReactComponent as InformationIcon } from 'crono-fe-common/icons/Icon-S-Information.svg';

interface IProps {
  stage: OpportunityStageListDTO;
  colorsStage: StageColors;
  increaseStageLimit: (stage: string | null) => void;
}

const StateColumn = ({ stage, colorsStage, increaseStageLimit }: IProps) => {
  const { user } = useAuth();

  return (
    <StageColumnWrapper>
      <div
        className="stage-header-box"
        style={{ background: colorsStage.light }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontSize={14} lineHeight={'18px'} fontWeight={500} noWrap>
            {stage.stageName}
          </Typography>
          <Typography
            fontSize={14}
            lineHeight={'22px'}
            fontWeight={600}
            color={colorsStage.dark}
          >
            {stage.totalOpportunities}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <Tooltip
              arrow
              placement="top"
              title={`Total weighted on ${stage.probability}% deal probability`}
            >
              <span style={{ paddingTop: '4px' }}>
                <InformationIcon className="info-icon" />
              </span>
            </Tooltip>
            <Typography fontSize={'12px'} lineHeight={'24px'}>
              Weighted:{' '}
              {numericFormatter((stage.totalAmountWeighted ?? 0).toString(), {
                prefix: `${user?.otherSettings?.currency ?? '$'} `,
                decimalSeparator: ',',
                thousandSeparator: '.',
              })}
            </Typography>
          </div>
          <Typography fontSize={16} lineHeight={'24px'} fontWeight={600}>
            {numericFormatter((stage.totalAmount ?? 0).toString(), {
              prefix: `${user?.otherSettings?.currency ?? '$'} `,
              decimalSeparator: ',',
              thousandSeparator: '.',
            })}
          </Typography>
        </div>
      </div>
      <Droppable droppableId={stage.stage ?? stage.stageName ?? ''}>
        {(provided) => (
          <div
            className="stage-opportunities-list"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {stage.opportunities?.map((opportunity, index) => {
              return (
                <Draggable
                  key={opportunity.id}
                  draggableId={opportunity.objectId ?? opportunity.id + ''}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className="opportunity-box"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        cursor: 'grab',
                      }}
                    >
                      <OpportunityBox opportunity={opportunity} />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            {stage.loadMore && (
              <div className="load-more-button">
                <Typography
                  fontSize={14}
                  lineHeight={'18px'}
                  fontWeight={500}
                  onClick={() => increaseStageLimit(stage.stage)}
                >
                  View more
                </Typography>
                <IconBottomArrow color={colors.mainDark} />
              </div>
            )}
          </div>
        )}
      </Droppable>
    </StageColumnWrapper>
  );
};

export default StateColumn;
