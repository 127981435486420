import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const EmailRepliedIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <g id="Icon/Replied">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Path"
              d="M14.8529 7.26258V3.19531"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Path_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.2197 1.55615H2.63946C1.73372 1.55615 1 2.28987 1 3.19561V3.19561C1 3.73938 1.26985 4.24728 1.71986 4.55218L6.08714 7.51235C7.19995 8.26645 8.66087 8.26645 9.77369 7.51235L14.1401 4.553C14.5893 4.2481 14.8592 3.73938 14.8592 3.19643V3.19561C14.8592 2.28987 14.1255 1.55615 13.2197 1.55615V1.55615Z"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Path_3"
              d="M7.52197 12.1541H2.63049C1.72965 12.1541 1 11.4244 1 10.5236V3.19531"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="Group 427320578">
              <path
                id="Path_4"
                d="M11.4844 11.6592L10 13.1436L11.4844 14.6281"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Path_5"
                d="M12.9763 10.1953H13.7185C14.5387 10.1953 15.203 10.8596 15.203 11.6798V11.6798C15.203 12.4999 14.5387 13.1642 13.7185 13.1642H13.3549H13.7185H10"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmailRepliedIcon;
