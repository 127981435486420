import { ReactComponent as PlusSmallIcon } from 'crono-fe-common/icons/Icon-Plus-Small.svg';
import React, { useState } from 'react';
import { ExternalPropertyWrapper } from './style';
import {
  findExternalPropertyIcon,
  findTagColors,
} from 'crono-fe-common/utils/externalProperties';
import { colors } from 'crono-fe-common/theme';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { TextField, Typography } from '@mui/material';
import IconBottomArrow from 'crono-fe-common/icons/Icon-Bottom-Arrow';

interface IProps {
  value: string | null;
  index: number;
  onClick?: () => void;
  showRemove?: boolean;
  customStyle?: any;
  indexOption?: number;

  nullValueText?: React.ReactNode;
  handleSetValue: (value: string) => void;
}

const ExternalPropertyEditable = React.forwardRef((props: IProps, ref: any) => {
  const {
    value,
    index,
    onClick,
    showRemove,
    customStyle,
    indexOption,
    nullValueText,
    handleSetValue,
    ...rest
  } = props;
  const [light, standard, lightHover] = findTagColors(index, indexOption ?? 0);

  const [hover, setHover] = useState<boolean>(false);

  return (
    <ExternalPropertyWrapper
      ref={ref}
      {...rest}
      style={customStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className="tag-button"
        style={{
          background: value ? (hover ? lightHover : light) : undefined,
          border: value ? 'none' : `1px solid ${colors.grey3}`,
          ...customStyle,
          borderRadius: nullValueText ? 8 : 4,
        }}
        onClick={showRemove ? onClick : undefined}
      >
        {value ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 8,
                overflow: 'hidden',
                flex: 1,
              }}
            >
              {findExternalPropertyIcon(index, standard)}
              <TextField
                defaultValue={value}
                variant="standard"
                fullWidth
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  fontSize: '12px !important',
                  lineHeight: '16px !important',
                  fontWeight: 500,
                  height: '16px !important',
                }}
                inputProps={{
                  style: {
                    fontSize: 12,
                    lineHeight: '16px !important',
                    fontWeight: 500,
                    height: 16,
                    padding: 0,
                  },
                }}
                // sx={{
                //   "&.MuiInputBase-input": {
                //     fontSize: "12px !important",
                //     lineHeight: "16px !important",
                //     fontWeight: 500,
                //     height: "16px !important"
                //   },
                //   "&.MuiInputBase-root": {
                //     fontSize: "12px !important",
                //     lineHeight: "16px !important",
                //     fontWeight: 500,
                //     height: "16px !important"
                //   },

                //   "&.MuiInput-input": {
                //     fontSize: "12px !important",
                //     lineHeight: "16px !important",
                //     fontWeight: 500,
                //     height: "16px !important"
                //   },

                //   "&.MuiInput-root": {
                //     fontSize: "12px !important",
                //     lineHeight: "16px !important",
                //     fontWeight: 500,
                //     height: "16px !important"
                //   }
                // }}
                onBlur={(e) => handleSetValue(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleSetValue(e.target.value);
                  }
                }}
              />
              {/* <Typography noWrap flexGrow={0}>
                {value}
              </Typography> */}
            </div>
            {(showRemove === undefined ? true : showRemove) && (
              <CloseMIcon
                color={colors.grey2}
                className="remove-icon"
                onClick={onClick}
              />
            )}
          </>
        ) : (
          <>{nullValueText}</> || (
            <>
              <PlusSmallIcon /> Add Tag
            </>
          )
        )}
      </div>
    </ExternalPropertyWrapper>
  );
});

export default ExternalPropertyEditable;
