import React, { useState } from 'react';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { SequenceStepWrapper } from '../sequenceInstanceTaskComponent/style';
import { Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { colors } from 'crono-fe-common/theme';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';

import { printCallFeedback } from 'utils/fe-utils';
import { htmlToText } from 'crono-fe-common/utils';
import { CronoErrorCode } from 'crono-fe-common/types/enums/cronoErrorCode';
import CronoError from 'crono-fe-common/types/error';
import LinkedinIconInMailOnlyCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import SelectedTemplateIcon from 'crono-fe-common/icons/Icon-Selected-Template';
import AddTemplateIconS from 'crono-fe-common/icons/Icon-Add-Template-S';
import IconTopArrow from 'crono-fe-common/icons/Icon-Top-Arrow';
import IconBottomArrow from 'crono-fe-common/icons/Icon-Bottom-Arrow';
import TemplateType from 'crono-fe-common/types/enums/templateType';

const renderActivity = (task: TaskTodo) => {
  const data: { title?: string; subtitle?: string } = {};
  switch (task.type) {
    case TaskTodoType.CALL:
      data.title = task.callLog?.callFeedback
        ? printCallFeedback(task.callLog?.callFeedback)
        : '';
      break;
    case TaskTodoType.EMAIL:
      data.title = task.emailLog?.subject || task?.subject || 'No subject';
      data.subtitle =
        htmlToText(task.emailLog?.content.replaceAll('\n', ' ') ?? '') ||
        task?.description ||
        'No content';
      break;
    case TaskTodoType.LINKEDIN:
      data.title =
        task.linkedinLog?.content || task?.subject || 'No subject found';
      break;
    case TaskTodoType.IN_MAIL:
      data.title =
        task.linkedinLog?.subject || task?.subject || 'No subject found';
      data.subtitle =
        task.linkedinLog?.content || task?.description || 'No content';
      break;
  }
  return data;
};

const renderActivityIcon = (
  task: TaskTodo,
  backgroundIconClassName: string,
  backgroundIconColor: string,
  iconColor: string,
) => {
  switch (task.type) {
    case TaskTodoType.CALL:
      return (
        <CallIcon
          className={`completed-type-icon ${backgroundIconClassName}`}
          color={iconColor}
        />
      );
    case TaskTodoType.EMAIL:
      return (
        <EmailIcon
          className={`completed-type-icon ${backgroundIconClassName}`}
          color={iconColor}
        />
      );
    case TaskTodoType.LINKEDIN:
      if (task.subtype === TaskTodoSubtype.LinkedinInvitation) {
        return (
          <LinkedinInvitationIcon
            className={`completed-type-icon ${backgroundIconClassName}`}
            fill={backgroundIconColor}
            color={iconColor}
          />
        );
      } else if (task.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
        return (
          <LinkedinIconInvoice
            className={`completed-type-icon ${backgroundIconClassName}`}
            color={iconColor}
            viewBox="2 2 19 19"
          />
        );
      } else {
        return (
          <LinkedinIconMessage
            className={`completed-type-icon ${backgroundIconClassName}`}
            color={iconColor}
            viewBox="-2 -1 19 19"
          />
        );
      }
    case TaskTodoType.IN_MAIL:
      return (
        <LinkedinIconInMailOnlyCompass
          className={`completed-type-icon ${backgroundIconClassName}`}
          color={iconColor}
        />
      );
  }
};

const renderType = (task: TaskTodo) => {
  let type = '';
  switch (task.type) {
    case TaskTodoType.CALL:
      type = 'Call';
      break;
    case TaskTodoType.EMAIL:
      type = 'Email';
      break;
    case TaskTodoType.LINKEDIN:
      switch (task.subtype) {
        case TaskTodoSubtype.LinkedinInvitation:
          type = 'Invitation';
          break;
        case TaskTodoSubtype.LinkedinVoiceNote:
          type = 'Voice Note';
          break;
        case TaskTodoSubtype.LinkedinMessage:
          type = 'LinkedIn Message';
          break;
        default:
          type = 'LinkedIn Message';
          break;
      }
      break;
    case TaskTodoType.IN_MAIL:
      type = 'InMail';
      break;
  }
  return type;
};

interface IProps {
  task: TaskTodo;
  index: number;
  //Only if true show subject
  // subject?: boolean;
}

const SequenceInstanceTaskCompletedComponent = ({
  task,
  index,
  // subject
}: IProps) => {
  const taskError = task.taskError || task.automationError;
  // const { title, subtitle } = renderActivity(task);
  const errorStyle = !task.skipped && taskError;
  const [openedDetails, setOpenedDetails] = useState(false);
  const backgroundColor = task.skipped
    ? colors.grey6
    : errorStyle
      ? colors.inactiveLight
      : colors.greyGreenLight;
  const backgroundColorDark = task.skipped
    ? colors.grey444
    : errorStyle
      ? colors.inactiveLightHover
      : colors.primaryLightHover;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          height: '100%',
          borderLeft: `1px solid ${colors.grey444}`,
          position: 'relative',
          left: 25,
          zIndex: -1,
        }}
        id="dashed-line"
      />
      <SequenceStepWrapper key={index} id="sequence-step-wrapper">
        <div className="step-row">
          <div className="step-number-container">
            <Typography
              className="step-completed"
              style={{
                backgroundColor,
              }}
            >
              {errorStyle ? (
                <CloseMIcon color={colors.inactive} />
              ) : task.skipped ? (
                <CloseMIcon color={colors.grey11} />
              ) : (
                <CheckMarkIcon color={colors.grey11} />
              )}
            </Typography>
          </div>
          <div style={{ width: 'calc(100% - 62px)', height: '100%' }}>
            <div
              className={'step-completed-info'}
              style={{
                backgroundColor,
              }}
            >
              <div className="step-content-completed" id={task.id.toString()}>
                <div
                  style={{
                    borderRight: `1px solid ${backgroundColorDark}`,
                  }}
                  className="step-content-left-side"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 'fit-content',
                      gap: '16px',
                    }}
                  >
                    {renderActivityIcon(
                      task,
                      task.skipped
                        ? 'skipped-background-icon'
                        : errorStyle
                          ? 'error-background-icon'
                          : 'completed-background-icon',
                      backgroundColorDark,
                      errorStyle ? colors.inactiveHover : colors.grey11,
                    )}
                    <Typography
                      fontSize={'14px'}
                      fontWeight={500}
                      color={errorStyle ? colors.inactiveHover : colors.grey11}
                      minWidth={80}
                    >
                      {renderType(task)}
                    </Typography>
                    {/* {!task.skipped ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "center",
                          height: "fit-content",
                          color: errorStyle
                            ? colors.inactiveHover
                            : colors.grey11
                        }}
                      >
                        {title && (
                          <Typography
                            fontSize={"12px"}
                            fontWeight={600}
                            noWrap
                            width={180}
                          >
                            {title}
                          </Typography>
                        )}
                        {subtitle && (
                          <Typography fontSize={"12px"} noWrap width={180}>
                            {subtitle}
                          </Typography>
                        )}
                      </div>
                    ) : null} */}
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    {task.automatic && (
                      <Typography
                        fontSize={'12px'}
                        fontWeight={500}
                        color={
                          errorStyle ? colors.inactiveHover : colors.grey11
                        }
                        style={{
                          backgroundColor: backgroundColorDark,
                          borderRadius: '16px',
                          paddingInline: '8px',
                          width: 'fit-content',
                        }}
                      >
                        Auto
                      </Typography>
                    )}
                    <Typography
                      fontSize={'12px'}
                      fontWeight={500}
                      color={errorStyle ? colors.inactiveHover : colors.grey11}
                    >
                      {moment(task.completeDate).format('ll')}
                    </Typography>
                  </div>
                </div>
                <div className="step-content-right-side">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '16px',
                      justifyContent: 'space-between',
                      flex: 1,
                      overflow: 'hidden',
                    }}
                  >
                    {task.type !== TaskTodoType.CALL ? (
                      task.template ? (
                        <Tooltip
                          arrow
                          placement="top"
                          title={task.template.title}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 8,
                              flex: 1,
                              overflow: 'hidden',
                            }}
                          >
                            <span className="flex-center icon-wrapper">
                              <SelectedTemplateIcon
                                className={'step-component-button-disabled'}
                                color={colors.grey11}
                              />
                            </span>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              noWrap
                              flex={1}
                              overflow={'hidden'}
                              color={colors.grey11}
                            >
                              {task.template.title}
                            </Typography>
                          </div>
                        </Tooltip>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                          }}
                        >
                          <span className="flex-center icon-wrapper">
                            <AddTemplateIconS
                              className={'step-component-button'}
                            />
                          </span>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            noWrap
                            color={colors.grey11}
                          >
                            No template
                          </Typography>
                        </div>
                      )
                    ) : (
                      <div />
                    )}

                    {errorStyle ? (
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color={colors.inactiveHover}
                      >
                        Error
                      </Typography>
                    ) : task.skipped ? (
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color={colors.grey1}
                      >
                        Skipped
                      </Typography>
                    ) : (
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color={colors.grey1}
                      >
                        Succesfully sent
                      </Typography>
                    )}
                  </div>
                  {/*Button to show the template if present */}
                  {task.template ? (
                    <div
                      className="open-template-details-button"
                      onClick={() => setOpenedDetails((prev) => !prev)}
                    >
                      {openedDetails ? <IconTopArrow /> : <IconBottomArrow />}
                    </div>
                  ) : (
                    <div className="no-template-to-open-placeholder" />
                  )}
                </div>
              </div>
              {openedDetails && task.template && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    padding: '12px 20px',
                    borderTop: `1px solid ${colors.grey33}`,
                  }}
                >
                  {(task.template.type === TemplateType.EMAIL ||
                    task.template.type === TemplateType.IN_MAIL) && (
                    <Typography
                      fontSize={14}
                      lineHeight={'18px'}
                      fontWeight={500}
                      noWrap
                    >
                      <span style={{ color: colors.grey11 }}>Subject:</span>{' '}
                      {task.skipped
                        ? task.template.subject
                        : task.emailLog?.subject ?? ''}
                    </Typography>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: task.skipped
                        ? task.template.content
                        : task.emailLog?.contentHtml ?? '',
                    }}
                    style={{ maxHeight: 200, overflow: 'auto' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </SequenceStepWrapper>
    </div>
  );
};

export default SequenceInstanceTaskCompletedComponent;
