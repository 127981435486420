import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import SequenceOverviewChart from '../charts/sequenceOverviewChart';
import SequenceDetailChart from '../charts/sequenceDetailChart';
import SequenceConversionChart from '../charts/sequenceConversionChart';

interface IProps {
  filterParams: AnalyticsFilters;
}

const AnalyticSequenceTabController = ({ filterParams }: IProps) => {
  return (
    <>
      <SequenceOverviewChart filterParams={filterParams} />

      <SequenceDetailChart filterParams={filterParams} />

      <SequenceConversionChart filterParams={filterParams} />
    </>
  );
};

export default AnalyticSequenceTabController;
