import { ENDPOINTS } from 'config/endpoints';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { useMutation } from 'react-query';
import { CustomEmailProviderSettingsDTO } from 'crono-fe-common/types/DTO/customEmailProviderSettingsDTO';
import CronoError from 'crono-fe-common/types/error';
import { Response } from 'crono-fe-common/types/response';

const useConnectCustomEmailProviderIntegration = () => {
  const connectCustomEmailProvider = (
    customEmailProviderData: CustomEmailProviderSettingsDTO,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.customEmailProvider.connect}`,
      config: {
        method: 'post',
        data: { data: customEmailProviderData },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    CustomEmailProviderSettingsDTO
  >(connectCustomEmailProvider, {});

  return { data, mutate, ...rest };
};

export default useConnectCustomEmailProviderIntegration;
