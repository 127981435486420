import { CircularProgress, IconButton, Typography } from '@mui/material';
import {
  CancelButton,
  MainButton,
  MainPrimaryButton,
  MainPrimarySmallButton,
} from 'crono-fe-common/components/CronoButton';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import Role from 'crono-fe-common/types/enums/role';
import EditUserForm from 'pages/settings/Subscription/editUserForm';
import InviteNewUserForm, {
  IUserSubscriptionPage,
} from 'pages/settings/Subscription/inviteNewUserForm';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SubscriptionStepContainer } from './styles';
import { colors } from 'crono-fe-common/theme';
import InvitePlaceholder from 'assets/images/Invite_placeholder.png';
import { useAuth } from 'context/auth';
import ContactsIconS from 'crono-fe-common/icons/Icon-Contacts-S';

interface IProps {
  users: (IUserSubscriptionPage & { id: number; userRoles: Role[] })[];
  setUsers: React.Dispatch<
    React.SetStateAction<
      (IUserSubscriptionPage & {
        id: number;
        userRoles: Role[];
      })[]
    >
  >;
  handleBack: () => void;
  handleFinishSubscription: () => void;
  completingSubscription: boolean;
}

const InviteStep = ({
  users,
  setUsers,
  handleBack,
  handleFinishSubscription,
  completingSubscription,
}: IProps) => {
  const { user } = useAuth();

  const maxLicenses = useMemo(() => {
    if (user?.company?.numberOfLicenses) {
      return user?.company?.numberOfLicenses;
    }
    return null;
  }, [user]);

  const nextId = useRef(1);

  const [userToEdit, setUserToEdit] = useState<
    (IUserSubscriptionPage & { id: number; userRoles: Role[] }) | null
  >(null);
  const [addNewUser, setAddNewUser] = useState<boolean>(false);
  const handleAddNewUser = (user: IUserSubscriptionPage) => {
    setUsers((prev) => [
      ...prev,
      {
        ...user,
        id: nextId.current++,
        userRoles:
          user.permission === Role.BASIC
            ? [Role.BASIC]
            : [Role.MANAGER, Role.BASIC],
      },
    ]);
    setAddNewUser(false);
  };
  const handleEditUser = (
    user: IUserSubscriptionPage & { id: number; userRoles: Role[] },
  ) => {
    setUsers((prev) => {
      const index = prev.findIndex((el) => el.id === user.id);
      const newUsers = [...prev];
      newUsers[index] = user;
      return newUsers;
    });
    setUserToEdit(null);
  };

  const handleDeleteUser = (id: number) => {
    setUsers((prev) => {
      return prev.filter((el) => el.id !== id);
    });
  };

  const refContainer = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refContainer.current) {
      refContainer.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <SubscriptionStepContainer
      style={{
        gap: '32px',
        height: '100%',
        overflow: 'hidden',
        justifyContent: 'flex-start',
      }}
      ref={refContainer}
    >
      {addNewUser && (
        <InviteNewUserForm
          close={() => setAddNewUser(false)}
          onAdd={handleAddNewUser}
        />
      )}
      {userToEdit && (
        <EditUserForm
          close={() => setUserToEdit(null)}
          user={userToEdit}
          onEdit={handleEditUser}
          showEmail={true}
        />
      )}
      <div
        className="step-card-container"
        style={{ flex: 1, overflow: 'hidden', display: 'flex' }}
      >
        <div className="form-box" style={{ flex: 1, overflow: 'auto' }}>
          {users.length > 0 && (
            <div className="user-list-container">
              {users.map((user, index) => {
                return (
                  <div key={index} className="user-card">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          overflow: 'hidden',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          fontSize={16}
                          lineHeight={'24px'}
                          fontWeight={500}
                          noWrap
                        >
                          {user.firstName} {user.lastName}
                        </Typography>
                        <Typography
                          fontSize={12}
                          lineHeight={'16px'}
                          fontWeight={500}
                          color={colors.prospectingHover}
                        >
                          <span style={{ paddingInline: 8, fontWeight: 700 }}>
                            ·
                          </span>
                          {user.userRoles.includes(Role.MANAGER)
                            ? 'Manager'
                            : 'User'}
                        </Typography>
                      </div>
                      <Typography
                        fontSize={14}
                        lineHeight={'18px'}
                        fontWeight={500}
                        color={colors.grey11}
                        noWrap
                      >
                        {user.email}
                      </Typography>
                    </div>
                    {/* {user.active ? (
                    <div className="active-badge">Active</div>
                  ) : (
                    <div className="pending-badge">Pending</div>
                  )} */}
                    <div className="buttons-user-container">
                      <IconButton
                        className="button-edit-user"
                        onClick={() => setUserToEdit(user)}
                      >
                        <EditIcon color={colors.primaryDark} />
                      </IconButton>
                      <IconButton
                        className="button-delete-user"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        <RemoveIcon color={colors.inactiveHover} />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="invite-user-container">
            <div
              style={{
                display: 'flex',
                flex: 1,
                overflow: 'hidden',
                height: 46,
                maxWidth: 200,
                minWidth: 46,
              }}
            >
              <img src={InvitePlaceholder} alt="user invite placeholder" />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
            >
              {maxLicenses !== null &&
                (users.length > 0 ? (
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <ContactsIconS
                      color={colors.mainDark}
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography
                      fontSize={16}
                      lineHeight={'24px'}
                      fontWeight={600}
                    >
                      {users.length + 1}
                      <span style={{ color: colors.grey11 }}>
                        /{maxLicenses}
                      </span>
                    </Typography>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <ContactsIconS
                      color={colors.grey11}
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography
                      fontSize={'16px'}
                      fontWeight={500}
                      lineHeight={'24px'}
                      color={colors.grey11}
                    >
                      License limit:
                    </Typography>
                    <Typography
                      fontSize={16}
                      lineHeight={'24px'}
                      fontWeight={600}
                    >
                      {maxLicenses}
                    </Typography>
                  </div>
                ))}
              <MainPrimarySmallButton
                onClick={() => setAddNewUser(true)}
                startIcon={<PlusSmallIcon color={colors.white} />}
                style={{ height: 37, fontSize: 14, lineHeight: '18px' }}
                disabled={
                  maxLicenses !== null && users.length + 1 >= maxLicenses
                }
              >
                Invite user
              </MainPrimarySmallButton>
            </div>
          </div>
        </div>
      </div>

      <div className="onboarding-navigation">
        <CancelButton style={{ width: '120px' }} onClick={handleBack}>
          Back
        </CancelButton>
        {completingSubscription ? (
          <CircularProgress />
        ) : (
          <MainButton
            style={{ width: '120px' }}
            onClick={handleFinishSubscription}
          >
            Finish
          </MainButton>
        )}
      </div>
    </SubscriptionStepContainer>
  );
};

export default InviteStep;
