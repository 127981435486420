import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const DuplicateSquareIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <rect
        id="Rectangle"
        x="6.99805"
        y="6.99609"
        width="14.0058"
        height="14.0058"
        rx="3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path"
        d="M14.0009 12V16.0017"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M12 14.0002H16.0017"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M6.998 17.0019H5.99759C4.34004 17.0019 2.99634 15.6582 2.99634 14.0007V5.99734C2.99634 4.3398 4.34004 2.99609 5.99759 2.99609H14.0009C15.6585 2.99609 17.0022 4.3398 17.0022 5.99734V6.99776"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 5"
      />
    </svg>
  );
};

export default DuplicateSquareIcon;
