import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SequenceInstanceWrapper = styled.div`
  .background-component {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: ${colors.black}66;
    z-index: 5001;
  }
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    min-width: 930px;
    height: 90%;
    background: ${colors.white};
    border-radius: 16px;
    padding: 16px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    margin-top: -8px;
  }
  .prospect-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .prospect-name-title-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow: hidden;
    flex: 1;
    margin-top: -8px;
  }
  .sequence-instance-container {
    padding-inline: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow: auto;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .right-side-sequence-handle {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 4;
    column-gap: 4;
  }
  .account-avatar {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    font-size: 20px !important;
    border: 1px solid ${colors.grey4Hover};
  }
  .white {
    background: ${colors.white} !important;
  }
  .close-button {
    align-self: flex-end;
    cursor: pointer;
  }

  .stop-strategy-button {
    background: ${colors.orangeLight};
    display: flex;
    gap: 4px;
    align-items: center;
    height: 32px;
    border-radius: 16px;
    padding: 9px 8px 9px 12px;
    cursor: pointer;
    .rectangle-icon {
      width: 12px;
      height: 12px;
    }
  }
  .stop-strategy-button:hover {
    background: ${colors.orangeLightHover};
  }
  .remove-from-strategy-button {
    height: 30px;
    width: 30px;
    min-width: 30px;
    padding: 4px;
    background: ${colors.inactive};
    :hover {
      background: ${colors.inactiveHover};
    }
  }
  .sequence-steps-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-right: 6px;
    justify-content: flex-start;
    overflow: hidden auto;
    /* row-gap: 24px; */
  }
  //To reduce the height of the first dashed line so that it starts from the middle of the number
  .sequence-steps-container
    #completed-steps-div:first-child
    div:first-child
    #dashed-line:first-child {
    height: 50% !important;
    top: 25%;
  }
  //.sequence-steps-container
  //  #todo-steps-div:first-child
  //  div:first-child
  //  #dashed-line:first-child {
  //  height: calc((100% - 20px) / 2) !important;
  //  top: calc(20px + 25%);
  //}
  .completed-steps,
  .todo-steps {
    display: flex;
    flex-direction: column;
  }
  .tasks-line {
    border-left: 2px dashed ${colors.primary};
    width: 10px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    flex-grow: 1;
    left: 29px;
    z-index: -1;
  }
  .start-triangle {
    position: absolute;
    left: -7px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-top: 6px solid ${colors.primary};
  }
  .end-triangle {
    position: absolute;
    left: -7px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 6px solid ${colors.primary};
  }
  .restart-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 16px;
    padding: 6px;
    cursor: pointer;
    border: 1px solid ${colors.grey33};
    background-color: ${colors.grey6};
  }
`;
