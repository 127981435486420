import { Prospect } from 'crono-fe-common/types/prospect';
import * as Yup from 'yup';

export interface ContactInformation {
  linkedin: string;
  email: string;
  phone: string;
  mobilePhone: string;
}

export const getInformation = (key: keyof Prospect) => {
  if (key === 'linkedin') return 'linkedin';
  if (key === 'email') return 'email';
  if (key === 'mobilePhone') return 'mobilePhone';
  return 'phone';
};

export const validationSchema = Yup.object({
  phone: Yup.number(),
  mobilePhone: Yup.number(),
});
