import React, { useState } from 'react';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { StrategyPreviewModalWrapper } from './style';
import { useSequenceContext } from 'context/strategyDetail/context/sequence';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import StrategyDetailModal from 'context/strategyDetail/strategyDetailModal';
import useDuplicateStrategyLibrary, {
  IDuplicateStrategyLibrary,
} from 'hooks/services/sequence/useDublicateStrategyLibrary';
import { Constants } from 'crono-fe-common/constants/constants';
import { useConditionalSnackBar } from 'context/snackbar';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useShortStrategyOverviewContext } from 'context/strategyDetail';
import { StrategyOverviewTab } from 'context/strategyDetail/types';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  onClose: () => void;
  onGoBack: () => void;
}

const StrategyPreview: React.FC<IProps> = ({ onClose, onGoBack }) => {
  const analytics = useJuneAnalytics();
  const [dublicationSuccess, setDublicationSuccess] = useState<boolean>(false);

  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const { sequence } = useSequenceContext();
  const {
    mutate: duplicateStrategy,
    isLoading,
    error: dublicationError,
  } = useDuplicateStrategyLibrary();

  const handleDuplicate = async () => {
    if (!sequence) return;
    if (isLoading) return;

    const dataToSend: IDuplicateStrategyLibrary = {
      strategyId: sequence.strategyId,
      language: selectedLanguage,
    };

    duplicateStrategy(dataToSend, {
      onSuccess: (resp: any) => {
        setDublicationSuccess(true);
        setTimeout(() => {
          setEditSequenceId(resp.data);
          onClose();
        }, 1000);
      },
    });
    if (analytics) {
      analytics.track('strategy-from-library', {});
      if (selectedLanguage !== Constants.defaultLanguage) {
        analytics.track('strategy-translated', {
          language: selectedLanguage,
        });
      }
    }
  };

  const { setStrategyOverviewContent } = useShortStrategyOverviewContext();

  const setEditSequenceId = (sequenceId: number | null) => {
    setStrategyOverviewContent({
      strategyId: sequenceId,
      initialTab: StrategyOverviewTab.Detail,
    });
  };

  useConditionalSnackBar([
    {
      condition: dublicationSuccess,
      message: `The strategy ${sequence.name} was correctly duplicated`,
      severity: 'success',
    },
    {
      condition: !!dublicationError,
      message: 'Dublication faield',
      severity: 'error',
    },
  ]);

  return (
    <BlurBackgroundDivFixed onClick={onClose}>
      <StrategyPreviewModalWrapper>
        <div className="strategy-preview" onClick={(e) => e.stopPropagation()}>
          <div className="strategy-preview__top">
            <div className="strategy-preview__cross">
              <CloseTabButton
                style={{ paddingRight: '24px' }}
                close={() => {
                  onClose();
                }}
              />
            </div>

            <div className="strategy-preview__title">
              <Typography
                fontSize={24}
                fontWeight={700}
                lineHeight={'30px'}
                color={colors.black}
              >
                {sequence.name}
              </Typography>
            </div>

            <div className="strategy-preview__preview">
              <StrategyDetailModal previewOnly={true} />
            </div>
          </div>

          <div className="strategy-preview__footer">
            <CancelButton width="120px" fontWeight="400" onClick={onGoBack}>
              Back
            </CancelButton>

            <div className="strategy-preview__footer-right">
              <div className="strategy-preview__language-preview">
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  lineHeight={'16px'}
                  color={colors.black}
                >
                  Strategy language:
                </Typography>

                <FormControl sx={{ minWidth: 40, mt: 1, mb: 1 }} size="small">
                  <Select
                    labelId="select-small"
                    id="select-small"
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                    sx={{
                      fontSize: '14px',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${colors.grey4}`,
                        borderRadius: 2,
                      },
                      padding: '2px',
                      zIndex: 100000,
                      backgroundColor: colors.white,
                    }}
                    MenuProps={{
                      style: { zIndex: 100000 },
                      PaperProps: {
                        sx: {
                          borderRadius: '8px',
                          paddingInline: '6px',
                          border: '1px solid #dadde9',
                          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                          '& .MuiMenuItem-root': {
                            fontSize: '12px',
                            lineHeight: '16px',
                            height: 'fit-content',
                            padding: '8px',
                            cursor: 'pointer',
                            width: '100%',
                            borderRadius: '8px',
                            '&:hover': {
                              background: colors.primaryLight,
                              color: colors.primary,
                            },
                          },
                          '& .Mui-selected': {
                            color: colors.primary,
                            backgroundColor: 'transparent !important',
                          },
                        },
                      },
                    }}
                  >
                    {Constants.templateLanguagesInput.map((language) => (
                      <MenuItem value={language} key={language}>
                        {Constants.languages[language]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {!isLoading ? (
                <MainButton
                  width="188px"
                  fontWeight="300"
                  onClick={handleDuplicate}
                >
                  Duplicate Strategy
                </MainButton>
              ) : (
                <FlexDiv justifyContent="center" width="200px">
                  <CircularProgress />
                </FlexDiv>
              )}
            </div>
          </div>
        </div>
      </StrategyPreviewModalWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default StrategyPreview;
