import styled from '@emotion/styled';
import Page from 'crono-fe-common/components/Layout/Page';
import { colors } from 'crono-fe-common/theme';

export const LoginWrapper = styled(Page)`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .login-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    padding: 40px 40px;
    border-radius: 20px;
    width: 40%;
  }

  .login-text h2 {
    font-weight: 500;
  }

  .login-text {
    text-align: center;
    margin-bottom: 40px;
  }

  .login-form {
    padding: 20px;
  }

  .forgot-password-link {
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: end;
    margin: 5px 0 20px 0;

    color: ${colors.primary};
    &:hover {
      color: ${colors.darkGreenHover};
    }
  }

  .form-field {
    margin-bottom: 20px;
  }

  .logo {
    max-width: 75%;
  }

  .box-first-login-message {
    display: flex;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    padding: 16px;
    background: ${colors.primaryLight};
    gap: 10px;
    margin-bottom: 20px;
  }
  .box-first-login-message-icon {
    background: ${colors.primaryLightHover};
    border-radius: 10px;
    padding: 12px;
    height: 56px;
    width: 56px;
  }

  @media screen and (max-width: 768px) {
    .login-form {
      padding: 20px 0px;
    }
  }
`;
