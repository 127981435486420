import React, { useEffect, useRef, useState } from 'react';
import { ExternalPropertyWrapper, TagCheckmarkIconWrapper } from './style';
import {
  findExternalPropertyIcon,
  findTagColors,
} from 'crono-fe-common/utils/externalProperties';
import { colors } from 'crono-fe-common/theme';
import MinusSmallIcon from 'crono-fe-common/icons/Icon-Minus-Small';
import { TextField, Typography } from '@mui/material';
//import { TagIconWrapper } from "crono-fe-common/utils/externalProperties/style";
import IconXSCheckmark from 'crono-fe-common/icons/Icon-XS-CheckMark';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import { TagIconWrapper } from 'crono-fe-common/utils/externalProperties/style';

interface IProps {
  value: string | null;
  index: number;
  onClick?: () => void;
  isEditable?: boolean;
  indexOption?: number;
  fullWidth?: boolean;
  selected?: boolean;
  nullTextValue?: string | null;

  onlyPlus?: boolean;
  style?: any;
  customStyleTag?: any;
  //Show hover effect
  disabled?: boolean;

  //TODO: TENTATIVE
  isBeingModified?: boolean;
  searchTagValue?: string;
  setSearchTagValue?: React.Dispatch<React.SetStateAction<string>>;
  onEsc?: () => void;
  onEnter?: () => void;

  //If the tag is not selected: white background, if selected: colored background without checkmark
  useEmptySelection?: boolean;
}

const ExternalPropertyComponent = React.forwardRef(
  (props: IProps, ref: any) => {
    const {
      value,
      index,
      onClick,
      isEditable,
      indexOption,
      fullWidth,
      selected,
      onlyPlus,
      style,
      customStyleTag,
      useEmptySelection = false,
      nullTextValue,
      disabled,
      isBeingModified,
      searchTagValue,
      setSearchTagValue,
      onEsc,
      onEnter,
      ...rest
    } = props;
    const [light, standard, lightHover] = findTagColors(
      index,
      indexOption ?? 0,
    );

    const [hover, setHover] = useState<boolean>(false);

    const textFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (isBeingModified && textFieldRef.current) {
        textFieldRef.current?.focus();
      }
    }, [textFieldRef.current, isBeingModified]);

    const handleOnClick = () => {
      if (onClick) onClick();
    };
    return (
      <ExternalPropertyWrapper
        ref={ref}
        {...rest}
        style={{
          width: fullWidth ? '100%' : undefined,
          cursor: disabled ? 'default' : 'pointer',
          ...style,
        }}
        onMouseEnter={() => {
          if (!disabled) {
            setHover(true);
          }
        }}
        onMouseLeave={() => {
          if (!disabled) {
            setHover(false);
          }
        }}
      >
        {isEditable === undefined || isEditable ? (
          value !== null ? (
            <div
              //If we are displayinh in emptySelection mode and it is not selected use the empty style
              className={`tag-button ${useEmptySelection && !selected ? 'empty-selection' : ''}`}
              style={{
                background: value ? (hover ? lightHover : light) : undefined,
                width: fullWidth ? '100%' : undefined,
                boxSizing: 'border-box',
                ...customStyleTag,
              }}
              onClick={handleOnClick}
            >
              {selected && !useEmptySelection ? (
                <TagCheckmarkIconWrapper>
                  <IconXSCheckmark
                    className="checkmark-icon"
                    color={colors.black}
                  />
                </TagCheckmarkIconWrapper>
              ) : (
                findExternalPropertyIcon(index, standard)
              )}
              {isBeingModified ? (
                <>
                  <TextField
                    value={searchTagValue}
                    inputRef={textFieldRef}
                    onChange={(ev) => {
                      if (setSearchTagValue) setSearchTagValue(ev.target.value);
                    }}
                    autoComplete="false"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onKeyDown={(ev) => {
                      if (ev.key === 'Esc') {
                        if (onEsc) onEsc();
                      } else if (ev.key === 'Enter') {
                        if (onEnter) onEnter();
                      }
                    }}
                    inputProps={{
                      style: {
                        padding: 0,
                        fontSize: 12,
                      },
                    }}
                    sx={{
                      height: '16px',
                    }}
                  />
                </>
              ) : (
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  lineHeight={'16px'}
                  noWrap
                >
                  {value}
                </Typography>
              )}
            </div>
          ) : onlyPlus ? (
            <div
              className="tag-add-button only-plus-add-button"
              onClick={handleOnClick}
            >
              <TagIconWrapper style={{ minWidth: 12 }}>
                <PlusSmallIcon />
              </TagIconWrapper>
              {isBeingModified && (
                <>
                  <TextField
                    value={searchTagValue}
                    inputRef={textFieldRef}
                    onChange={(ev) => {
                      if (setSearchTagValue) setSearchTagValue(ev.target.value);
                    }}
                    autoComplete="false"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onKeyDown={(ev) => {
                      if (ev.key === 'Esc') {
                        if (onEsc) onEsc();
                      } else if (ev.key === 'Enter') {
                        if (onEnter) onEnter();
                      }
                    }}
                    inputProps={{
                      style: {
                        padding: 0,
                        fontSize: 12,
                      },
                    }}
                    sx={{
                      height: '16px',
                      width: '110px',
                    }}
                  />
                </>
              )}
            </div>
          ) : (
            <div
              className="tag-add-button"
              onClick={handleOnClick}
              style={{
                width: fullWidth ? '100%' : undefined,
                boxSizing: 'border-box',
              }}
            >
              <TagIconWrapper style={{ minWidth: 12 }}>
                <PlusSmallIcon />
              </TagIconWrapper>
              {isBeingModified ? (
                <>
                  <TextField
                    value={searchTagValue}
                    inputRef={textFieldRef}
                    onChange={(ev) => {
                      if (setSearchTagValue) setSearchTagValue(ev.target.value);
                    }}
                    autoComplete="false"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onKeyDown={(ev) => {
                      if (ev.key === 'Esc') {
                        if (onEsc) onEsc();
                      } else if (ev.key === 'Enter') {
                        if (onEnter) onEnter();
                      }
                    }}
                    inputProps={{
                      style: {
                        padding: 0,
                        fontSize: 12,
                      },
                    }}
                    sx={{
                      height: '16px',
                      width: '110px',
                    }}
                  />
                </>
              ) : (
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  lineHeight={'16px'}
                  noWrap
                >
                  {nullTextValue ?? 'Add Tag'}
                </Typography>
              )}
            </div>
          )
        ) : (
          //Is not editable
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MinusSmallIcon />
          </div>
        )}
      </ExternalPropertyWrapper>
    );
  },
);

export default ExternalPropertyComponent;
