import React, { useEffect } from 'react';
import { RedButton } from 'crono-fe-common/components/CronoButton';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import { colors } from 'crono-fe-common/theme';
import useDeleteSequenceProspectMultiple from 'hooks/services/sequence/useDeleteProspectSequenceMultiple';
import { useConfirmModal } from 'context/confirmModal';
import { CircularProgress } from '@mui/material';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  selected: number[];
  clearSelected: () => void;
}

const StrategyContactsModalSelectionComponent = ({
  selected,
  clearSelected,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const {
    mutate: deleteSequenceProspectMultiple,
    isLoading: isLoadingDeleteSequenceProspectMultiple,
    error: errorDeleteSequenceProspectMultiple,
    isSuccess: isSuccessDeleteSequenceProspectMultiple,
  } = useDeleteSequenceProspectMultiple();

  const { openModal } = useConfirmModal();

  const handleDelete = () => {
    openModal({
      title: `Are you sure you want to remove ${selected.length === 1 ? 'contant' : 'contacts'} from the strategy?`,
      text: "You're about to remove all data of these contacts related to this strategy. This includes all activity data and completed tasks.",
      confirmFunction: () => {
        deleteSequenceProspectMultiple({
          sequenceInstanceIds: selected,
        });
        if (analytics) {
          analytics.track('remove-multiple-prospect-from-strategy', {
            count: selected.length,
          });
        }
      },
      cancelFunction: () => {},
      cancelText: 'Cancel',
      confirmText: 'Remove',
    });
  };

  useEffect(() => {
    if (isSuccessDeleteSequenceProspectMultiple) {
      clearSelected();
    }
  }, [isSuccessDeleteSequenceProspectMultiple]);

  useConditionalSnackBar([
    {
      condition: !!errorDeleteSequenceProspectMultiple,
      message:
        getError(errorDeleteSequenceProspectMultiple) ?? 'Error while deleting',
      severity: 'error',
    },
    {
      condition: !!isSuccessDeleteSequenceProspectMultiple,
      message: 'Contacts removed from strategy',
      severity: 'success',
    },
  ]);

  return selected.length > 0 ? (
    <div style={{ paddingLeft: 8 }}>
      {isLoadingDeleteSequenceProspectMultiple ? (
        <CircularProgress />
      ) : (
        <RedButton
          startIcon={<TrashCanDeleteIcon color={colors.white} />}
          style={{
            height: 32,
          }}
          onClick={handleDelete}
        >
          Remove from strategy
        </RedButton>
      )}
    </div>
  ) : null;
};

export default StrategyContactsModalSelectionComponent;
