import { Opportunity } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';
import React from 'react';
import { OpportunityBoxWrapper } from './style';
import { Avatar, Tooltip, Typography } from '@mui/material';
import IconNotOwned from 'crono-fe-common/icons/Icon-Not-Owned';
import { colors } from 'crono-fe-common/theme';
import { getImageFromUrl, stringAvatarAccount } from 'utils/fe-utils';
import ClockIconTwelveFifteen from 'crono-fe-common/icons/Icon-Clock-12-15';
import moment from 'moment';
import { useAuth } from 'context/auth';
import { numericFormatter } from 'react-number-format';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';

interface IProps {
  opportunity: Opportunity;
}

const OpportunityBox = ({ opportunity }: IProps) => {
  const { user } = useAuth();

  const { openWindow } = useWindow();
  const { setSelectedAccountId } = useSelectedAccount();
  const handleClickDeal = () => {
    setSelectedAccountId(
      opportunity.account?.objectId ?? opportunity.accountId,
    );
    openWindow({
      modalVisible: 'deals',
      tab: 'deals',
      windowType: 'account',
      selectedOpportunityId: opportunity.objectId,
      forceReload: Math.random() * 10000,
    });
  };

  return (
    <OpportunityBoxWrapper onClick={handleClickDeal}>
      <div className="opportunity-box-row">
        <Tooltip title={opportunity.name} enterDelay={800} enterNextDelay={800}>
          <Typography fontSize={14} lineHeight={'18px'} noWrap>
            {opportunity.name}
          </Typography>
        </Tooltip>
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          {!opportunity.owned && (
            <Tooltip
              title={
                opportunity.user
                  ? 'Owned by ' + opportunity.user
                  : 'You are not the owner of this opportunity'
              }
              enterDelay={400}
              enterNextDelay={400}
            >
              <div style={{ width: 16, height: 16 }}>
                <IconNotOwned color={colors.grey11} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="opportunity-box-row">
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Avatar
            src={
              opportunity.account?.website &&
              getImageFromUrl(opportunity.account.website, null)
            }
            className={
              opportunity.account?.website
                ? 'account-avatar white'
                : 'account-avatar'
            }
          >
            {stringAvatarAccount(opportunity.account?.name || '')}
          </Avatar>
          <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <ClockIconTwelveFifteen
              color={colors.grey11}
              className="icon-clock"
            />
            <Typography
              fontSize={12}
              lineHeight={'16px'}
              color={colors.grey11}
              noWrap
            >
              {moment(opportunity.createdDate).format('MMM DD, YYYY')}
            </Typography>
          </div>
        </div>
        <Typography fontSize={14} lineHeight={'22px'} fontWeight={600}>
          {numericFormatter((opportunity.amount ?? 0).toString(), {
            prefix: `${user?.otherSettings?.currency ?? '$'} `,
            decimalSeparator: ',',
            thousandSeparator: '.',
          })}
        </Typography>
      </div>
    </OpportunityBoxWrapper>
  );
};

export default OpportunityBox;
