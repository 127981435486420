import {
  Avatar,
  Checkbox,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAuth } from 'context/auth';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { colors } from 'crono-fe-common/theme';
import { Prospect } from 'crono-fe-common/types/prospect';
import React, { useEffect, useState } from 'react';
import {
  getImageFromUrl,
  stringAvatarProspect,
  prospectName,
} from 'utils/fe-utils';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import useSyncProspects from 'hooks/services/prospect/useSyncProspects';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import useLookSync from 'hooks/services/sync/useLookSync';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';

interface IProspectExternalRow {
  prospect: Prospect;
  handleSelectedExternal: (prospectId: string, fromContact: boolean) => void;
  selected: boolean;
  disableCheckbox?: boolean;
  onAdd: () => void;
  syncing?: boolean;
  onErrorImporting: () => void;
  onSuccessImporting: () => void;
}

const ProspectExternalRow = ({
  prospect,
  selected,
  handleSelectedExternal,
  disableCheckbox,
  onAdd,
  syncing = false,
  onErrorImporting,
  onSuccessImporting,
}: IProspectExternalRow) => {
  const { user } = useAuth();
  const [backgroundColorSelected, setBackgroundColorSelected] =
    useState<string>(colors.greyLight);

  const {
    mutate: syncProspects,
    isLoading: syncingProspect,
    error: errorSyncingProspect,
    isSuccess: successSyncingProspect,
    data: dataSyncingProspect,
  } = useSyncProspects();

  const { mutateAsync: lookSync } = useLookSync();

  const startLookSync = async (id: number) => {
    const t = setInterval(async () => {
      const res = await lookSync(id);
      if (res.data?.data.status === ImportStatusType.CompletedWithErrors) {
        clearInterval(t);
        onErrorImporting();
      } else if (res.data?.data.status === ImportStatusType.Completed) {
        onSuccessImporting();
        clearInterval(t);
      }
    }, 5000);
  };

  useEffect(() => {
    if (successSyncingProspect) {
      onAdd();
      if (dataSyncingProspect.data?.data) {
        startLookSync(dataSyncingProspect.data.data);
      }
    }
  }, [successSyncingProspect]);

  const handleAddProspect = () => {
    const isLead = !prospect.fromContact;
    syncProspects({
      data: [prospect.objectId],
      isLead,
    });
  };

  useConditionalSnackBar([
    {
      condition: !!errorSyncingProspect,
      message:
        getError(errorSyncingProspect) ?? 'Error while adding contact from CRM',
      severity: 'error',
    },
  ]);

  return (
    <div
      style={{
        background: backgroundColorSelected,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${colors.grey4}`,
      }}
      onMouseEnter={(ev) => {
        setBackgroundColorSelected(colors.grey4);
        ev.stopPropagation();
        ev.preventDefault();
      }}
      onMouseLeave={(ev) => {
        setBackgroundColorSelected(colors.greyLight);
        ev.stopPropagation();
        ev.preventDefault();
      }}
    >
      <div
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 8,
          background: backgroundColorSelected,
          width: 316,
          minWidth: 316,
          padding: '15px 8px 15px 4px',
          borderRight: `1px solid ${colors.grey4}`,
        }}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <label>
            <Checkbox
              checked={selected && !syncing}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleSelectedExternal(prospect.objectId, prospect.fromContact);
              }}
              icon={
                <IconUnchecked
                  color={disableCheckbox ? colors.grey4 : colors.grey3}
                />
              }
              checkedIcon={<CheckedIcon />}
              color="secondary"
              disabled={disableCheckbox || syncing}
              sx={{
                padding: 'inherit',
                margin: 'inherit',
                '&:hover': {
                  backgroundColor: colors.grey4,
                },
                '&.Mui-checked': {
                  '&:hover': {
                    opacity: 0.7,
                  },
                },
                marginRight: 1,
                marginLeft: 1,
                // "&.MuiCheckbox-root": {
                //   color: colors.grey33 + " !important",
                // },
              }}
            />
          </label>
          <Avatar
            key={prospect.objectId}
            src={
              prospect?.account?.website &&
              getImageFromUrl(prospect.account.website, null)
            }
            className="prospect-avatar-row"
          >
            {stringAvatarProspect(prospect)}
          </Avatar>
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '210px',
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip
                arrow
                title={prospectName(prospect)}
                enterDelay={800}
                enterNextDelay={800}
              >
                <span
                  style={{
                    display: 'inline-block',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    margin: 0,
                    fontSize: '1rem',
                    fontWeight: 500,
                  }}
                >
                  {prospectName(prospect)}
                </span>
              </Tooltip>
              {user?.company?.integrationType === IntegrationType.SALESFORCE &&
                !prospect.fromContact && (
                  <span className="lead-badge">Lead</span>
                )}
            </span>
            <Tooltip
              arrow
              title={prospect.title}
              enterDelay={800}
              enterNextDelay={800}
            >
              <span
                style={{
                  width: 210,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  margin: 0,
                }}
              >
                {prospect.title}
              </span>
            </Tooltip>
          </span>
        </span>
      </div>
      <div
        style={{
          flex: 1,
          padding: '12px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          fontSize={12}
          fontWeight={500}
          lineHeight={'16px'}
          color={colors.grey11}
          noWrap
        >
          {prospect.account?.name}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
          {!syncing && (
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight={'16px'}
              color={colors.grey11}
            >
              Contact exists in {user?.company?.integrationType}
            </Typography>
          )}
          {syncingProspect ? (
            <CircularProgress />
          ) : !syncing ? (
            <MainPrimaryButton
              style={{
                margin: 0,
                height: 40,
                fontSize: 12,
                lineHeight: '18px',
                fontWeight: 500,
              }}
              startIcon={<PlusSmallIcon color={colors.white} />}
              onClick={handleAddProspect}
            >
              Add to Crono
            </MainPrimaryButton>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
                color={colors.primaryDark}
              >
                Syncing
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProspectExternalRow;
