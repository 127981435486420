import { Column } from '@ant-design/charts';
import { Autocomplete, TextField, Typography } from '@mui/material';
import useSequenceDetailsAnalytics from 'hooks/services/analytics/useSequenceDetailsAnalytics';
import useSearchStrategies from 'hooks/services/sequence/useSearchStrategies';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import { Strategy } from 'crono-fe-common/types/strategy';
import { FeConstants } from 'constants/FeConstants';
import { SequencesOverviewLegend, colorsChart } from '../legendData';
import { calcWidth, numberOfGroups, selectChartContainerClass } from '../model';
import Legend from '../legend';
import useGetSequence from 'hooks/services/sequence/useGetSequence';
import { GraphData } from '../tabsControllers/model';
import { useAuth } from 'context/auth';
import { Constants } from 'crono-fe-common/constants/constants';
import { colors } from 'crono-fe-common/theme';
import styled from '@emotion/styled';
import { BarChartPlaceholder } from './chartsPlaceholders';

interface IProps {
  filterParams: AnalyticsFilters;
}

const SequenceDetailChart = ({ filterParams }: IProps) => {
  const { user } = useAuth();

  const [selectedStrategyDetail, setSelectedStrategyDetail] = useState<
    number | null
  >(null);
  const [searchTextDetail, setSearchTextDetail] = useState<string>('');
  const { data: strategies } = useSearchStrategies({
    limit: Constants.defaultLimit,
    offset: 0,
    name: searchTextDetail,
  });

  const { data: firstUserStrategy } = useSearchStrategies({
    limit: 1,
    offset: 0,
    name: '',
    userId: user?.id,
  });
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if (
      selectedStrategyDetail === null &&
      firstUserStrategy?.data?.data &&
      firstUserStrategy?.data?.data.length > 0
    ) {
      setSelectedStrategyDetail(firstUserStrategy?.data?.data[0].id);
      setSearchTextDetail(firstUserStrategy?.data?.data[0].name);
    }
  }, [firstUserStrategy]);

  //If the first strategy of the user does not appear in the list of strategies, it is added
  useEffect(() => {
    if (
      strategies?.data?.data &&
      firstUserStrategy?.data?.data &&
      firstUserStrategy.data.data.length > 0 &&
      selectedStrategyDetail === firstUserStrategy?.data?.data[0].id &&
      !strategies?.data?.data.find(
        (strategy: Strategy) =>
          strategy.id === firstUserStrategy?.data?.data[0].id,
      )
    ) {
      strategies?.data?.data.unshift(firstUserStrategy?.data?.data[0]);
    }
  }, [strategies, firstUserStrategy, selectedStrategyDetail]);

  const { data: sequenceDetailAnalytics } = useSequenceDetailsAnalytics(
    {
      strategyId: selectedStrategyDetail ?? 0,
      groupBy: 'Day',
      ...filterParams,
    },
    selectedStrategyDetail !== null,
  );

  // useEffect(() => {
  //   console.log(sequenceDetailAnalytics);
  // }, [sequenceDetailAnalytics]);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const { data: sequenceSelected } = useGetSequence(selectedStrategyDetail);

  const [maxValue, setMaxValue] = useState<number>(0);

  const dataDetails = useMemo(() => {
    const newData: GraphData[] = [];

    if (
      !sequenceSelected ||
      sequenceSelected?.data?.data.strategyId !== selectedStrategyDetail
    )
      return [];

    const stepActivity = sequenceSelected.data.data.steps.map((step) => {
      return step.type;
    });
    let newValue = 0;
    sequenceDetailAnalytics?.data?.data.detail.AnalyticsSequenceCount?.forEach(
      (count) => {
        if (count.count === 0) return;
        if (newValue < count.count) {
          newValue = count.count;
        }
        newData.push({
          label: 'Step ' + count.label + ': ' + stepActivity[+count.label - 1],
          value: count.count,
          type: 'Usage',
        });
      },
    );
    sequenceDetailAnalytics?.data?.data.detail.AnalyticsSequenceActive?.forEach(
      (active) => {
        if (active.count === 0) return;
        if (newValue < active.count) {
          newValue = active.count;
        }
        newData.push({
          label:
            'Step ' + active.label + ': ' + stepActivity[+active.label - 1],
          value: active.count,
          type: 'Active',
        });
      },
    );
    sequenceDetailAnalytics?.data?.data.detail.AnalyticsSequenceStopped?.forEach(
      (stopped) => {
        if (stopped.count === 0) return;
        if (newValue < stopped.count) {
          newValue = stopped.count;
        }
        newData.push({
          label:
            'Step ' + stopped.label + ': ' + stepActivity[+stopped.label - 1],
          value: stopped.count,
          type: 'Stopped',
        });
      },
    );
    sequenceDetailAnalytics?.data?.data.detail.AnalyticsSequenceAnswer?.forEach(
      (answer) => {
        if (answer.count === 0) return;
        if (newValue < answer.count) {
          newValue = answer.count;
        }
        newData.push({
          label:
            'Step ' + answer.label + ': ' + stepActivity[+answer.label - 1],
          value: answer.count,
          type: 'Answered',
        });
      },
    );
    sequenceDetailAnalytics?.data?.data.detail.AnalyticsSequenceCompleted?.forEach(
      (completed) => {
        if (completed.count === 0) return;
        if (newValue < completed.count) {
          newValue = completed.count;
        }
        newData.push({
          label:
            'Step ' +
            completed.label +
            ': ' +
            stepActivity[+completed.label - 1],
          value: completed.count,
          type: 'Completed',
        });
      },
    );
    setMaxValue(newValue);
    return newData;
  }, [sequenceDetailAnalytics, sequenceSelected]);
  const configDetail = useMemo(() => {
    return {
      data: dataDetails,
      xField: 'label',
      yField: 'value',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index =
          SequencesOverviewLegend.findIndex((legend) => legend === type) ?? 0;
        return colorsChart[index];
      }) as any,
      autoFit: false,
      height: 300,
      meta: {
        value: {
          min: 0,
          max: maxValue * 1.2,
        },
      },
      width: calcWidth(
        dataDetails,
        180,
        chartContainerRef.current?.offsetWidth,
      ),
      isGroup: true,
      columnStyle: {},

      renderer: 'svg' as 'svg' | 'canvas' | undefined,
      dodgePadding: numberOfGroups(dataDetails) > 1 ? 6 : undefined,
      maxColumnWidth: numberOfGroups(dataDetails) > 1 ? 15 : 200,
      minColumnWidth: numberOfGroups(dataDetails) > 1 ? 15 : 200,
      randomVal: Math.random() * 10000,
      legend: false as any,
    };
  }, [
    dataDetails,
    sequenceSelected,
    sequenceDetailAnalytics,
    chartContainerRef.current?.offsetWidth,
    maxValue,
  ]);

  const autocompleteValue = useMemo(() => {
    const val = strategies?.data?.data.find(
      (item) => item.id === selectedStrategyDetail,
    );
    if (val) {
      return val;
    }
  }, [strategies, selectedStrategyDetail]);

  return (
    <>
      <Typography className="chart-title">Strategy Detail</Typography>
      <div
        className={selectChartContainerClass(dataDetails)}
        style={{ height: 400 }}
        ref={chartContainerRef}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 12,
            position: 'sticky',
            left: 0,
            zIndex: 10,
          }}
        >
          <Autocomplete
            className="input-account detail-picker"
            onChange={(e, value) => {
              setFirstLoad(false);
              setSelectedStrategyDetail((value as Strategy)?.id ?? '');
            }}
            options={[...(strategies?.data?.data || []), '']}
            getOptionLabel={(account) => (account as Strategy)?.name ?? ''}
            style={{ fontWeight: 700 }}
            openOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Strategy"
                size="small"
                onChange={(e) => {
                  setFirstLoad(false);
                  setSearchTextDetail(e.target.value);
                }}
              />
            )}
            //This way I can show the default value at the beginning, the first strategy of the user. After something is changed I go back to no value
            //for a better user experience
            {...(firstLoad && { value: autocompleteValue ?? '' })}
            freeSolo
            disablePortal
            disableClearable
            ListboxComponent={styled('ul')`
              overflow-x: hidden;
              width: 100%;
              box-sizing: border-box;
              .MuiAutocomplete-option.Mui-focused {
                color: ${colors.primary} !important;
                background-color: ${colors.primaryLight} !important;
              }
              .MuiAutocomplete-option[aria-selected='true'] {
                color: ${colors.primary} !important;
                background-color: ${colors.white} !important;
              }
              li {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                padding: 8px;
                cursor: pointer;
                width: 99%;
                border-radius: 8px;
                z-index: 1000;
              }
            `}
            PaperComponent={({ children }) => (
              <div className="autocomplete-paper">{children}</div>
            )}
            renderOption={(props, option) => {
              if (option === '') return;
              return (
                <li {...props} className="autocomplete-option">
                  {(option as Strategy)?.name}
                </li>
              );
            }}
          />
          <Legend colors={colorsChart} fields={SequencesOverviewLegend} />
        </div>

        {configDetail?.data && configDetail.data.length ? (
          <Column {...configDetail} key={configDetail.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <BarChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default SequenceDetailChart;
