import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { RewriteSequenceInstanceInput } from 'crono-fe-common/types/DTO/rewriteSequenceInstanceInput';

export default function useRewriteSequenceInstance() {
  const queryClient = useQueryClient();

  const rewriteMessage = (rewriteInput: RewriteSequenceInstanceInput) => {
    const request: Request = {
      url: `${ENDPOINTS.assistant.rewriteSequenceInstance}`,
      config: {
        method: 'post',
        data: { data: rewriteInput },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    RewriteSequenceInstanceInput
  >(rewriteMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(ENDPOINTS.sequence.prospect);
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
    },
  });

  return { data, mutate, ...rest };
}
