import styled from '@emotion/styled';
import { colors } from '../../theme';

interface IProps {
  sameSwitchColor?: boolean;
}

export const CronoSwitchWrapper = styled.div<IProps>`
  .switch-thumb {
    width: 16px;
    height: 16px;
    margin-left: 3px;
    margin-top: 3px;
    color: ${(props) =>
      props.sameSwitchColor ? colors.primary : colors.grey11};
    box-shadow: none !important;
  }

  .switch-track {
    height: 16px;
    border-radius: 16px;
    background: ${(props) =>
      props.sameSwitchColor ? colors.primary : colors.grey11};
    opacity: 0.2 !important;
  }
`;
