import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const RowResultPlaceHolder = ({
  color = colors.black,
  className,
  onClick,
  fillOpacity,
  style,
}: IProps) => {
  return (
    <svg
      width={style?.width ? style.width : '100%'}
      height="68"
      viewBox="0 0 826 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <rect
        width="826"
        height="68"
        rx="16"
        fill="white"
        fillOpacity={fillOpacity}
      />
      <rect x="714" y="14" width="98" height="40" rx="20" fill="#F5F7F9" />
      <rect x="656" y="14" width="40" height="40" rx="20" fill="#F5F7F9" />
      <rect x="21" y="14" width="40" height="40" rx="20" fill="#F5F7F9" />
      <rect x="81" y="23" width="180" height="8" rx="4" fill="#F5F7F9" />
      <rect x="81" y="37" width="102.857" height="8" rx="4" fill="#F5F7F9" />
    </svg>
  );
};

export default RowResultPlaceHolder;
