import React, { useEffect, useState } from 'react';
import { ContactsInStrategyListWrapper } from './style';
import {
  Avatar,
  TableCell,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { Order } from 'crono-fe-common/types/userPreferences';
import Pagination from 'pages/dashboard/pagination';
import useSearchProspectStrategy from 'hooks/services/prospect/useSearchProspectStrategy';
import { Constants } from 'crono-fe-common/constants/constants';
import {
  fullname,
  getImageFromUrl,
  stringAvatarProspect,
  transformToValidUrl,
} from 'utils/fe-utils';
import IconNotOwned from 'crono-fe-common/icons/Icon-Not-Owned';
import moment from 'moment';
import { ReactComponent as PersonalizedSequenceIcon } from 'crono-fe-common/icons/PersonalizedSequenceInstance.svg';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';

import RowPlaceholderContactsStrategy from 'assets/images/ContactsStrategyPlaceholderWithPadding.svg';
import { CONTACTS_IN_STRATEGY_ORDER } from 'config/localStorage';
import IconInformationS from 'crono-fe-common/icons/Icon-Information-Small';
import { useSafeStrategyOverviewContext } from '../index';

type ContactsStrategyOrder = 'name' | 'date';

interface IProps {
  sequenceId: number;
}

const ContactsInStrategyList = ({ sequenceId }: IProps) => {
  const [search, setSearch] = useState<string>('');
  const [orderBy, setOrderBy] = useState<ContactsStrategyOrder | null>('name');
  const [order, setOrder] = useState<Order>('asc');
  const onRequestSort = (property: ContactsStrategyOrder) => {
    //If the property is the same, I change the order: if it is asc, I change to desc, but if it is desc, i set the orderBy to null
    const isSameProperty = orderBy === property;
    const isAsc = isSameProperty && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    const newProperty = isSameProperty && !isAsc ? null : property;
    setOrderBy(newProperty);
    if (newProperty) {
      localStorage.setItem(
        CONTACTS_IN_STRATEGY_ORDER,
        `${isAsc ? 'desc' : 'asc'}-${property}`,
      );
    } else {
      //Back to default
      localStorage.setItem(CONTACTS_IN_STRATEGY_ORDER, 'asc-name');
    }
  };

  useEffect(() => {
    const order = localStorage.getItem(CONTACTS_IN_STRATEGY_ORDER);
    if (order) {
      const [orderValue, orderByValue] = order.split('-');
      setOrder(orderValue as Order);
      setOrderBy(orderByValue as ContactsStrategyOrder);
    }
  }, []);

  const [itemOffset, setItemOffset] = useState<number>(0);

  const { data: searchProspectStrategy } = useSearchProspectStrategy({
    limit: Constants.defaultLimit,
    offset: itemOffset,
    name: search,
    strategyId: sequenceId,
    sortType:
      orderBy === 'name'
        ? order === 'asc'
          ? 'Name'
          : 'NameDesc'
        : orderBy === 'date'
          ? order === 'asc'
            ? 'AddedToSequence'
            : 'AddedToSequenceDesc'
          : null,
  });

  const {
    insightsContext: { prospect, setProspect },
  } = useSafeStrategyOverviewContext();

  const selectedProspect = prospect;
  const setSelectedProspect = setProspect;

  //Set the first one initially
  useEffect(() => {
    if (!!searchProspectStrategy?.data?.data.length && !selectedProspect) {
      setSelectedProspect(searchProspectStrategy?.data?.data[0]);
    }
  }, [searchProspectStrategy?.data?.data, selectedProspect]);

  useEffect(() => {
    setItemOffset(0);
  }, [search, orderBy, order]);

  return (
    <ContactsInStrategyListWrapper>
      <div className="contacts-in-strategy-list-header">
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Typography fontSize={24} lineHeight={'30px'} fontWeight={700}>
            Contacts in strategy
          </Typography>
          <div className="number-of-contacts-badge">
            {searchProspectStrategy?.data?.total}
          </div>
        </div>
        <Typography fontSize={14} lineHeight={'24px'} color={colors.grey11}>
          Select the contact to personalize the strategy
        </Typography>
        <div className="only-owned-contacts-banner">
          <IconInformationS
            color={colors.mainDark}
            className="information-icon"
          />
          <Typography fontSize={14} lineHeight="18px" fontWeight={500}>
            Only owned contacts will appear in this list
          </Typography>
        </div>
        <span
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            marginTop: 16,
          }}
        >
          <SearchIcon color={colors.grey11} />
          <TextField
            variant="standard"
            placeholder="Search..."
            autoComplete="off"
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            fullWidth
            InputProps={{
              endAdornment:
                search === '' ? null : (
                  <CloseMIcon
                    className="remove-text-icon"
                    color={colors.grey11}
                    onClick={() => {
                      setSearch('');
                    }}
                  />
                ),
            }}
          />
        </span>
      </div>
      <div className="contacts-in-strategy-list-body">
        <div className="sort-labels-container">
          <TableCell
            sortDirection={orderBy === 'name' ? order : false}
            padding={'normal'}
            sx={{
              top: -2,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              borderBottom: 'none',
              paddingLeft: '24px',
            }}
          >
            <TableSortLabel
              active={true}
              direction={orderBy === 'name' ? order : 'asc'}
              onClick={() => onRequestSort('name')}
              sx={{
                '&.MuiTableSortLabel-root': {
                  color:
                    orderBy === 'name'
                      ? `${colors.darkGreen} !important`
                      : `${colors.grey11} !important`,
                  '&:hover': {
                    color:
                      orderBy === 'name'
                        ? `${colors.darkGreenHover} !important`
                        : `${colors.black} !important`,
                    '.MuiTableSortLabel-icon': {
                      color:
                        orderBy === 'name'
                          ? `${colors.darkGreenHover} !important`
                          : `${colors.black} !important`,
                    },
                  },
                  '.MuiTableSortLabel-icon': {
                    color:
                      orderBy === 'name'
                        ? `${colors.darkGreen} !important`
                        : `${colors.grey4Hover} !important`,
                  },
                },
              }}
            >
              <span style={{ fontWeight: 600 }}>Contact Name</span>
            </TableSortLabel>
          </TableCell>
          <TableCell
            sortDirection={orderBy === 'date' ? order : false}
            padding={'normal'}
            sx={{
              top: -2,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              borderBottom: 'none',
            }}
          >
            <TableSortLabel
              active={true}
              direction={orderBy === 'date' ? order : 'asc'}
              onClick={() => onRequestSort('date')}
              sx={{
                '&.MuiTableSortLabel-root': {
                  color:
                    orderBy === 'date'
                      ? `${colors.darkGreen} !important`
                      : `${colors.grey11} !important`,
                  '&:hover': {
                    color:
                      orderBy === 'date'
                        ? `${colors.darkGreenHover} !important`
                        : `${colors.black} !important`,
                    '.MuiTableSortLabel-icon': {
                      color:
                        orderBy === 'date'
                          ? `${colors.darkGreenHover} !important`
                          : `${colors.black} !important`,
                    },
                  },
                  '.MuiTableSortLabel-icon': {
                    color:
                      orderBy === 'date'
                        ? `${colors.darkGreen} !important`
                        : `${colors.grey4Hover} !important`,
                  },
                },
              }}
            >
              <span style={{ fontWeight: 600 }}>Added date</span>
            </TableSortLabel>
          </TableCell>
        </div>
        <div className="contacts-list">
          {searchProspectStrategy?.data?.data.length === 0 && (
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundImage: `url(${RowPlaceholderContactsStrategy})`,
                }}
              />
            </>
          )}
          {searchProspectStrategy?.data?.data.map((prospect) => {
            return (
              <div
                key={prospect.objectId}
                className={`contact-personalized-card ${
                  selectedProspect?.objectId === prospect.objectId
                    ? 'selected-personalized'
                    : ''
                }`}
                onClick={() => {
                  setSelectedProspect(prospect);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    flex: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Avatar
                    key={prospect.objectId}
                    src={
                      prospect?.account?.website &&
                      getImageFromUrl(prospect.account.website, null)
                    }
                    className="prospect-avatar"
                  >
                    {stringAvatarProspect(prospect)}
                  </Avatar>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: 2,
                        overflow: 'hidden',
                        flex: 1,
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignContent: 'center',
                        }}
                      >
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                          paddingTop={'2px'}
                          noWrap
                        >
                          {fullname(prospect.firstName, prospect.lastName)}
                        </Typography>
                        {prospect.linkedin && (
                          <span className="linkedin-link-container">
                            <LinkedinIcon
                              color={colors.blueHover}
                              onClick={() => {
                                if (!prospect.linkedin) return;
                                const url = transformToValidUrl(
                                  prospect.linkedin,
                                );
                                if (url) {
                                  window.open(url, '_blank');
                                }
                              }}
                            />
                          </span>
                        )}
                      </div>
                      {!prospect.owned && (
                        <Tooltip
                          title={prospect.user}
                          enterDelay={400}
                          enterNextDelay={400}
                          arrow
                        >
                          <div
                            style={{
                              display: 'flex',
                            }}
                          >
                            <IconNotOwned color={colors.mainDark} />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <Typography
                      fontSize={12}
                      lineHeight={'16px'}
                      color={colors.grey11}
                      noWrap
                    >
                      {prospect.title}
                    </Typography>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  {/*Change this to isPersonalized */}
                  {prospect.isSequencePersonalized && (
                    <Tooltip
                      title="Strategy is personalized"
                      enterDelay={400}
                      enterNextDelay={400}
                      arrow
                    >
                      <PersonalizedSequenceIcon />
                    </Tooltip>
                  )}
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    color={colors.mainDark}
                    noWrap
                  >
                    {moment(prospect.addedToSequenceDate).format('DD/MM/YYYY')}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
        <Pagination
          total={searchProspectStrategy?.data?.total}
          count={searchProspectStrategy?.data?.count}
          itemOffset={itemOffset}
          setItemOffset={setItemOffset}
          limit={Constants.defaultLimit}
          selectorStyle={{
            width: '100%',
            marginLeft: '24px',
          }}
        />
      </div>
    </ContactsInStrategyListWrapper>
  );
};

export default ContactsInStrategyList;
