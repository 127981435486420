import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SuggestionPatchType } from 'crono-fe-common/types/DTO/suggestionPatchType';

export default function usePatchSuggestion() {
  const queryClient = useQueryClient();

  const patchSuggestion = (SuggestionPatch: SuggestionPatchType) => {
    const request: Request = {
      url: ENDPOINTS.suggestion.main,
      config: {
        method: 'patch',
        data: SuggestionPatch,
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    SuggestionPatchType
  >(patchSuggestion, {
    onSettled: (result, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.suggestion.search]);
    },
  });

  return { isSuccess, isError, mutate, ...rest };
}
