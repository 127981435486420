import { Request } from 'crono-fe-common/types/request';
import { ENDPOINTS } from 'config/endpoints';
import client from 'utils/clients/client';
import { useQuery } from 'react-query';
import { Response } from 'crono-fe-common/types/response';
import CronoError from 'crono-fe-common/types/error';
import { LogLinkedin } from 'crono-fe-common/types/logLinkedin';

export default function useEventsCheckLinkedinPublicIds(publicIds?: string[]) {
  const request: Request = {
    url: ENDPOINTS.events.check.linkedinPublicIds,
    config: {
      method: 'post',
      data: { publicIds },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.events.check.linkedinPublicIds],
    queryFn: () => client(request),
    enabled: !!publicIds,
  };

  const { data, ...rest } = useQuery<Response<LogLinkedin[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
