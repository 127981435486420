import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { Strategy } from 'crono-fe-common/types/strategy';

interface ISearchStrategyLibrary {
  limit: number;
  offset: number;
  name?: string | null;
}

export default function useSearchStrategyLibrary(
  searchData: ISearchStrategyLibrary,
  enabled = true,
) {
  const request: Request = {
    url: ENDPOINTS.sequence.librarySearch,
    config: {
      method: 'post',
      data: { data: searchData },
    },
  };

  const queryString = JSON.stringify(searchData);

  const requestConfig = {
    queryKey: [ENDPOINTS.sequence.librarySearch, queryString],
    queryFn: () => client(request),
    enabled,
    staleTime: 30 * 60 * 1000,
  };

  return useQuery<ResponsePaginated<Strategy[]>, CronoError>(requestConfig);
}
