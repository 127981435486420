import { Request } from 'crono-fe-common/types/request';
import client from 'utils/clients/client';
import { ENDPOINTS } from '../../../config/endpoints';
import { AircallSettings } from 'crono-fe-common/types/aircallSettings';

export type AircallVerifyIntegrationResult = {
  data: { isSuccess: boolean; data: AircallSettings | null };
};

class AircallService {
  private _alreadyRunningVerifyAircallIntegrationCall: Promise<AircallVerifyIntegrationResult> | null =
    null;

  public async verifyAircallIntegration(): Promise<AircallVerifyIntegrationResult> {
    if (this._alreadyRunningVerifyAircallIntegrationCall) {
      return await this._alreadyRunningVerifyAircallIntegrationCall;
    } else {
      this._alreadyRunningVerifyAircallIntegrationCall =
        this._verifyAircallIntegration();
      try {
        const verifyResult =
          await this._alreadyRunningVerifyAircallIntegrationCall;
        return verifyResult;
      } catch (e) {
        return {
          data: {
            isSuccess: false,
            data: null,
          },
        };
      } finally {
        this._alreadyRunningVerifyAircallIntegrationCall = null;
      }
    }
  }

  private async _verifyAircallIntegration(): Promise<AircallVerifyIntegrationResult> {
    const request: Request = {
      url: ENDPOINTS.aircall.verify,
      config: {
        method: 'get',
      },
    };

    const result = await client(request);
    return result as AircallVerifyIntegrationResult;
  }
}

// Esporto una sola istanza del servizio, in modo che tutti gli altri file che importano
// questo file importeranno sempre la stessa istanza
export default new AircallService();
