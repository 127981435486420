import { CircularProgress } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useWindow } from 'context/sideTab';
import useGetAccount from 'hooks/services/account/useGetAccount';
import { useSelectedAccount } from 'pages/home/context/account';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PATH from 'routing/path';

const CompanyInterceptor = () => {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId') ?? undefined;
  const { openWindow } = useWindow();
  const { setSelectedAccountId } = useSelectedAccount();

  const { isSuccess, error } = useGetAccount(accountId);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && accountId) {
      setSelectedAccountId(accountId);
      openWindow({
        tab: 'account',
        windowType: 'account',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error || isSuccess) {
      navigate(PATH.HOME);
    }
  }, [error, isSuccess]);
  return (
    <>
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    </>
  );
};

export default CompanyInterceptor;
