import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const EnrichFooterModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${colors.white};
  width: 400px;
  .enrich-footer-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 8px;
    gap: 8px;
    .enrich-footer-row-half {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .enrich-footer-row-selected {
    background: ${colors.primaryLight};
  }
`;
