import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const CloseMIcon = ({
  color = colors.black,
  className,
  onClick,
  strokeWidth = '1.5',
  size = 24,
  style,
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      {...style}
    >
      <path
        d="M16.9492 17.2922L7.04972 7.39274"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M7.05029 17.292L16.9498 7.3925"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseMIcon;
