import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const NoSalesNavigatorLicenseInmailViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  .new-type-option-container {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .call-type {
    background: ${colors.callScheduledLight};
    :hover {
      background: ${colors.callScheduledLightHover};
    }
  }
  .email-type {
    background: ${colors.inactiveLight};
    :hover {
      background: ${colors.inactiveLightHover};
    }
  }
  .linkedin-type {
    background: ${colors.blueLight};
    :hover {
      background: ${colors.blueLightHover};
    }
  }
`;
