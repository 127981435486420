import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconSClose = ({
  color = colors.grey11,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        d="M11.2988 11.5283L4.69917 4.92866"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.69922 11.5278L11.2989 4.92817"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconSClose;
