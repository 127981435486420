import { Request } from 'crono-fe-common/types/request';
import client from 'utils/clients/client';
import { ENDPOINTS } from '../../../config/endpoints';
import { OutlookSettings } from 'crono-fe-common/types/outlookSettings';

export type OutlookVerifyIntegrationResult = {
  data: { isSuccess: boolean; data: OutlookSettings | null };
};

class OutlookService {
  private _alreadyRunningVerifyOutlookIntegrationCall: Promise<OutlookVerifyIntegrationResult> | null =
    null;

  public async verifyOutlookIntegration(): Promise<OutlookVerifyIntegrationResult> {
    if (this._alreadyRunningVerifyOutlookIntegrationCall) {
      return await this._alreadyRunningVerifyOutlookIntegrationCall;
    } else {
      this._alreadyRunningVerifyOutlookIntegrationCall =
        this._verifyOutlookIntegration();
      try {
        const verifyResult =
          await this._alreadyRunningVerifyOutlookIntegrationCall;
        return verifyResult;
      } catch (e) {
        return {
          data: {
            isSuccess: false,
            data: null,
          },
        };
      } finally {
        this._alreadyRunningVerifyOutlookIntegrationCall = null;
      }
    }
  }

  private async _verifyOutlookIntegration(): Promise<OutlookVerifyIntegrationResult> {
    const request: Request = {
      url: ENDPOINTS.outlook.verify,
      config: {
        method: 'get',
      },
    };

    const result = await client(request);
    return result as OutlookVerifyIntegrationResult;
  }
}

// Esporto una sola istanza del servizio, in modo che tutti gli altri file che importano
// questo file importeranno sempre la stessa istanza
export default new OutlookService();
