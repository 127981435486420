import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InsertMultipleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.white};
  width: 600px;
  max-height: 95%;
  border-radius: 16px;
  padding: 24px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .close-button {
    align-self: flex-end;
    cursor: pointer;
    z-index: 1001;
    height: 24px;
    width: 24px;
  }

  .body-container {
    display: flex;
    flex-direction: column;
    padding: 0px 48px 24px 48px;
    row-gap: 24px;
    height: calc(100% - 10px);
    overflow: hidden scroll;
  }
  .companies-container {
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow: auto;
  }
  .contact-card-multiple {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    width: 100%;
    border-radius: 8px;
    position: relative;
    border-radius: 8px;
  }
  .contact-card-multiple > div > span {
    opacity: 0;
  }
  .contact-card-multiple:hover > div > span {
    opacity: 1;
  }
  .contact-card-multiple:hover {
    background: ${colors.inactiveLight}44;
  }
  .contact-card-multiple:hover > div > div {
    opacity: 0;
  }
  .remove-icon-multiple {
    width: 34px;
    height: 34px;
  }
  .divider {
    border-top: 1px solid ${colors.grey44};
    margin-inline: 12px;
  }
  .divider-multiple-contacts {
    border-top: 1px solid ${colors.grey44};
  }
  .other-fields-button {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: ${colors.grey11};
    gap: 8px;
    cursor: pointer;
    width: fit-content;
  }
  .cancel-opacity-icon {
    position: absolute;
    top: 0px;
    height: 40px;
    width: 40px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.inactiveLight};
    border-radius: 999px;
    cursor: pointer;
    z-index: 20;
  }
  .company-indication-prospect-card {
    color: ${colors.primary};
  }
  .contact-avatar-container {
    position: relative;
    width: 56px;
  }
  .company-small-logo {
    position: absolute;
    width: 32px;
    height: 32px;
    z-index: 10;
    bottom: 0;
    right: 0;
    border: 1px solid ${colors.white};
  }
  .information-editable-container {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    min-height: 26px !important;
    height: 30px !important;
  }
  //modifier that go on top of the main class to add extra styles
  .information-editable-container--big {
    height: 64px !important;
    margin: 0;
  }
  .green-sequence-button-disabled,
  .green-sequence-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 999px;
    padding: 2px 6px 2px 2px;
    cursor: pointer;
  }
  .green-sequence-button {
    background-color: ${colors.primaryLight};

    &--selected {
      background-color: ${colors.grey6};
      -webkit-text-fill-color: inherit !important;
    }
  }
  .green-sequence-button-disabled {
    filter: grayscale(1);
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 999px;
    background: ${colors.primary};
    transition: background-color 200ms;

    &--hovered {
      background: ${colors.darkGreen};
      transition: background-color 200ms;
    }
  }
  .icon-container > svg > path {
    fill: ${colors.white};
  }
  .select-strategy {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 8px;
  }
  .select-owner-button {
    height: 40px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    text-transform: unset;
    padding: 4px 8px 4px 12px;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
  }
  .select-owner-icon {
    width: 24px;
    height: 24px;
  }
  .remove-icon-owner {
    height: 16px;
    width: 16px;
    min-width: 16px;
  }

  .find-info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px 12px 20px;
    border-radius: 8px;
    border: 1px solid ${colors.grey444};
    .find-info-icon {
      width: 24px;
      height: 24px;
    }
    .find-info-box-inner {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .find-info-box-text-container {
      display: flex;
      flex-direction: column;
    }
  }
  .fsz-12 {
    font-size: 12px;
  }
  .select-radius {
    border-radius: 8px;
  }
  .select-padding {
    padding: 13px;
  }
  .select-wrapper {
    width: 100%;
  }
  .section-wrapper {
    display: flex;
    flex-direction: column;

    &__title {
      margin-bottom: 8px;
    }

    &__others {
      margin: 8px 0px;
    }
  }
  .popup__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
  }
  .selected-strategy {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__title {
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.black};
    }

    &__steps {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.grey11};
    }

    &__icon-container {
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: ${colors.white};
      border: 1px solid ${colors.grey444};
      cursor: pointer;

      transition-property: border, background-color;
      transition-duration: 200ms;

      &:hover {
        background-color: ${colors.grey444};
        border: 0;
      }
    }
  }
`;
export const SelectionUserTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;

  .sequence-action-tooltip-button {
    width: 160px;
    text-transform: unset;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    border-radius: 8px;
    color: ${colors.black};
    &:hover {
      color: ${colors.primary};
    }
  }
  .selected-user {
    color: ${colors.primary};
    background: ${colors.primaryLight};
  }
`;
