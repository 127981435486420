import React from 'react';
import { EnrichFooterModalWrapper } from './style';
import IconFindEmail from 'crono-fe-common/icons/Icon-Find-Email';
import { Typography } from '@mui/material';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';
import { colors } from 'crono-fe-common/theme';
import RemainingTokensBadge from 'pages/searchComponent/searchContactTab/insertMultipleContacts/remainingTokensBadge';
import IconFindPhone from 'crono-fe-common/icons/Icon-Find-Phone';

interface IProps {
  enrichEmail: boolean;
  emailRequiredLength: number;
  enrichPhone: boolean;
  phoneRequiredLength: number;
  setEnrichEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setEnrichPhone: React.Dispatch<React.SetStateAction<boolean>>;
}

const EnrichFooterModal = ({
  enrichEmail,
  emailRequiredLength,
  enrichPhone,
  phoneRequiredLength,
  setEnrichEmail,
  setEnrichPhone,
}: IProps) => {
  return (
    <EnrichFooterModalWrapper>
      <div
        className={`enrich-footer-row ${enrichEmail ? 'enrich-footer-row-selected' : ''}`}
      >
        <div className="enrich-footer-row-half">
          <IconFindEmail color={colors.mainDark} />
          <Typography fontSize={12} lineHeight={'16px'} fontWeight={500}>
            Email addresses
          </Typography>
        </div>
        <div className="enrich-footer-row-half">
          <RemainingTokensBadge
            type="email"
            requiredLength={emailRequiredLength}
            showIcon={false}
          />
          <CronoCheckbox
            checked={enrichEmail}
            onChange={() => setEnrichEmail(!enrichEmail)}
          />
        </div>
      </div>
      <div
        className={`enrich-footer-row ${enrichPhone ? 'enrich-footer-row-selected' : ''}`}
      >
        <div className="enrich-footer-row-half">
          <IconFindPhone
            color={colors.mainDark}
            style={{ width: 24, height: 24, minWidth: 24 }}
          />
          <Typography fontSize={12} lineHeight={'16px'} fontWeight={500}>
            Phone numbers
          </Typography>
        </div>
        <div className="enrich-footer-row-half">
          <RemainingTokensBadge
            type="phone"
            requiredLength={phoneRequiredLength}
            showIcon={false}
          />
          <CronoCheckbox
            checked={enrichPhone}
            onChange={() => setEnrichPhone(!enrichPhone)}
          />
        </div>
      </div>
    </EnrichFooterModalWrapper>
  );
};

export default EnrichFooterModal;
