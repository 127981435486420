import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ModalFormWrapper } from 'crono-fe-common/components/Modal/styles';
import { useConditionalSnackBar } from 'context/snackbar';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import {
  initialValues,
  GenerateTemplateProps,
  GenerateTemplateInput,
  validationSchema,
} from './model';
import { trimObject } from 'crono-fe-common/utils/object';
import { useJuneAnalytics } from 'context/june';
import { useAuth } from 'context/auth';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { TemplateStep } from 'crono-fe-common/types/enums/templateStep';
import { GenerateTemplateStyle } from 'crono-fe-common/types/enums/generateTemplateStyle';
import { GenerateTemplateLength } from 'crono-fe-common/types/enums/generateTemplateLength';
import useGenerateTemplate from 'hooks/services/assistant/useGenerateTemplate';
import {
  CancelButton,
  CronoButton,
  MainButton,
  TabSelectionButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { getError } from 'crono-fe-common/utils';
import { GenerateTemplateGoal } from 'crono-fe-common/types/enums/generateTemplateGoal';
import { FeConstants } from 'constants/FeConstants';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { UserPreferences } from 'crono-fe-common/types/userPreferences';
import InfoIcon from '@mui/icons-material/Info';
import { colors } from 'crono-fe-common/theme';
import { GenerateFormWrapper } from './style';
import { ReactComponent as CheckMarkSmall } from 'crono-fe-common/icons/CheckMarkSmall.svg';
import { Constants } from 'crono-fe-common/constants/constants';

const GenerateTemplateForm: FC<GenerateTemplateProps> = ({
  setOpen,
  step,
  setStep,
  language,
  setLanguage,
  templateType,
  setTemplateType,
  templateFormik,
}) => {
  const { user } = useAuth();
  const analytics = useJuneAnalytics();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    mutate: generateTemplate,
    data: generateTemplateData,
    error: generateTemplateError,
    isLoading: isLoadingGenerateTemplate,
  } = useGenerateTemplate();

  const { mutate: editPreferences } = usePatchUserPreferences();

  const formik = useFormik<GenerateTemplateInput>({
    initialValues: {
      ...initialValues,
      language: language,
      templateType: templateType,
      type: step,
      what: user?.userPreferences?.productDescription ?? '',
      title: user?.userPreferences?.productBuyerPersona ?? null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const trimmedValues = trimObject(values);

      const userPreferencesToEdit: UserPreferences = {};

      let edit = false;

      if (
        trimmedValues.what !== null &&
        trimmedValues.what !== '' &&
        trimmedValues.what !== user?.userPreferences?.productDescription
      ) {
        userPreferencesToEdit.productDescription = trimmedValues.what;
        edit = true;
      }

      if (
        trimmedValues.title !== null &&
        trimmedValues.title !== '' &&
        trimmedValues.title !== user?.userPreferences?.productBuyerPersona
      ) {
        userPreferencesToEdit.productBuyerPersona = trimmedValues.title;
        edit = true;
      }

      if (edit) {
        editPreferences(userPreferencesToEdit);
        if (user?.userPreferences) {
          user.userPreferences = {
            ...user.userPreferences,
            ...userPreferencesToEdit,
          };
        }
      }

      generateTemplate({
        goal: trimmedValues.goal,
        title: trimmedValues.title,
        length: trimmedValues.length,
        style: trimmedValues.style,
        type: trimmedValues.type,
        what: trimmedValues.what,
        language: trimmedValues.language,
        templateType: trimmedValues.templateType,
      });

      if (analytics) {
        analytics.track('generate-template', {
          templateType: trimmedValues.templateType,
          type: trimmedValues.type,
          goal: trimmedValues.goal,
          length: trimmedValues.length,
          style: trimmedValues.style,
        });
      }
    },
  });

  useEffect(() => {
    if (generateTemplateData) {
      // console.log(generateTemplateData.data?.data);
      templateFormik.setFieldValue(
        'subject',
        generateTemplateData?.data?.data?.subject || '',
      );
      templateFormik.setFieldValue(
        'content',
        generateTemplateData?.data?.data?.content?.replace('\n', '<br/>') || '',
      );
      setOpen(false);
      setLanguage(formik.values.language);
      setTemplateType(formik.values.templateType);
    }
  }, [generateTemplateData, setOpen, templateFormik]);

  useConditionalSnackBar([
    {
      condition: !!errorMessage,
      message: errorMessage || '',
      severity: 'error',
    },
    {
      condition: !!generateTemplateError,
      message:
        getError(generateTemplateError) || 'Error while generate tempalte',
      severity: 'error',
    },
  ]);

  const handleChangeType = (type: TemplateType) => {
    formik.setFieldValue('templateType', type);
    setTemplateType(type);
  };

  const handleChangeLanguage = (language: string) => {
    formik.setFieldValue('language', language);
    setLanguage(language);
  };

  const handleChangeStep = (step: TemplateStep) => {
    formik.setFieldValue('type', step);
    setStep(step);
  };

  const handleChangeLength = (length: string) => {
    formik.setFieldValue('length', length);
  };

  return (
    <GenerateFormWrapper>
      <form onSubmit={formik.handleSubmit} className="generate-form-container">
        <div className="form-row">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Template type*
          </Typography>
          <div>
            <TabSelectionButton
              selected={formik.values.templateType === TemplateType.EMAIL}
              className="tab-template-button"
              onClick={() => handleChangeType(TemplateType.EMAIL)}
            >
              Email
            </TabSelectionButton>
            <TabSelectionButton
              selected={formik.values.templateType === TemplateType.LINKEDIN}
              className="tab-template-button"
              style={{ position: 'relative', right: 10 }}
              onClick={() => handleChangeType(TemplateType.LINKEDIN)}
            >
              Message
            </TabSelectionButton>
            <TabSelectionButton
              selected={formik.values.templateType === TemplateType.INVITATION}
              className="tab-template-button"
              style={{ position: 'relative', right: 20 }}
              onClick={() => handleChangeType(TemplateType.INVITATION)}
            >
              Invitation
            </TabSelectionButton>
            <TabSelectionButton
              selected={formik.values.templateType === TemplateType.IN_MAIL}
              className="tab-template-button"
              style={{ position: 'relative', right: 30 }}
              onClick={() => handleChangeType(TemplateType.IN_MAIL)}
            >
              InMail
            </TabSelectionButton>
          </div>
        </div>
        <div className="form-row">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Language*
          </Typography>
          <FormControl size="small">
            <Select
              labelId="select-small"
              id="select-small"
              name="language"
              value={formik.values.language ?? undefined}
              onChange={(ev) => handleChangeLanguage(ev.target.value)}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colors.grey4}`,
                  borderRadius: 2,
                },
                padding: '2px',
              }}
              MenuProps={{
                style: { zIndex: 6001 },
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    paddingInline: '6px',
                    border: '1px solid #dadde9',
                    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      lineHeight: '16px',
                      height: 'fit-content',
                      padding: '8px',
                      cursor: 'pointer',
                      width: '100%',
                      borderRadius: '8px',
                      '&:hover': {
                        background: colors.primaryLight,
                        color: colors.primary,
                      },
                    },
                    '& .Mui-selected': {
                      color: colors.primary,
                      backgroundColor: 'transparent !important',
                    },
                  },
                },
              }}
            >
              {Constants.templateLanguagesInput.map((language) => (
                <MenuItem value={language} key={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="form-row">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Describe your product*
          </Typography>
          <TextField
            fullWidth
            style={{ position: 'relative' }}
            id="what"
            name="what"
            placeholder="Crono is a software that..."
            value={formik.values.what}
            onChange={formik.handleChange}
            error={formik.touched.what && Boolean(formik.errors.what)}
            helperText={formik.touched.what && formik.errors.what}
            variant="standard"
          />

          <Typography
            fontSize={11}
            lineHeight={'14px'}
            fontWeight={500}
            color={colors.grey11}
          >
            {formik.values.what.length}/200
          </Typography>
          {/* <Tooltip title="Write a short sentence to describe your product. Example: Crono is a software that...">
              <InfoIcon
                style={{
                  marginLeft: "5px",
                  color: colors.primary,
                  cursor: "pointer",
                  marginTop: "30px",
                }}
              />
            </Tooltip> */}
        </div>
        <div className="form-row hint">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            What role are you targetting?
          </Typography>
          <TextField
            fullWidth
            style={{ position: 'relative' }}
            id="title"
            name="title"
            placeholder="Which role is this message for (optional)..."
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            variant="standard"
          />
          {/* <Tooltip title="Which role is this message for?">
              <InfoIcon
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  color: colors.primary,
                  cursor: "pointer",
                }}
              />
            </Tooltip> */}
        </div>
        <div className="form-row hint">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Purpose*
          </Typography>
          <FormControl size="small">
            <Select
              labelId="select-small"
              id="goal"
              name="goal"
              value={formik.values.goal}
              onChange={formik.handleChange}
              error={formik.touched.goal && Boolean(formik.errors.goal)}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colors.grey4}`,
                  borderRadius: 2,
                },
                padding: '2px',
              }}
              MenuProps={{
                style: { zIndex: 6001 },
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    paddingInline: '6px',
                    border: '1px solid #dadde9',
                    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      lineHeight: '16px',
                      height: 'fit-content',
                      padding: '8px',
                      cursor: 'pointer',
                      width: '100%',
                      borderRadius: '8px',
                      '&:hover': {
                        background: colors.primaryLight,
                        color: colors.primary,
                      },
                    },
                    '& .Mui-selected': {
                      color: colors.primary,
                      backgroundColor: 'transparent !important',
                    },
                  },
                },
              }}
            >
              <MenuItem value={GenerateTemplateGoal.MEETING}>
                Schedule a meeting
              </MenuItem>
              <MenuItem value={GenerateTemplateGoal.INTEREST}>
                Ask for interest
              </MenuItem>
              <MenuItem value={GenerateTemplateGoal.NURTURE}>
                Nurture the contact
              </MenuItem>
            </Select>
          </FormControl>
          {/* <Tooltip title="What is the purpose of this message?">
              <InfoIcon
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  color: colors.primary,
                  cursor: "pointer",
                }}
              />
            </Tooltip> */}
        </div>
        <div className="form-row hint">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Step*
          </Typography>
          <FormControl size="small">
            <Select
              id="type"
              name="type"
              value={formik.values.type}
              onChange={(ev) =>
                handleChangeStep(ev.target.value as TemplateStep)
              }
              error={formik.touched.type && Boolean(formik.errors.type)}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colors.grey4}`,
                  borderRadius: 2,
                },
                padding: '2px',
              }}
              MenuProps={{
                style: { zIndex: 6001 },
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    paddingInline: '6px',
                    border: '1px solid #dadde9',
                    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      lineHeight: '16px',
                      height: 'fit-content',
                      padding: '8px',
                      cursor: 'pointer',
                      width: '100%',
                      borderRadius: '8px',
                      '&:hover': {
                        background: colors.primaryLight,
                        color: colors.primary,
                      },
                    },
                    '& .Mui-selected': {
                      color: colors.primary,
                      backgroundColor: 'transparent !important',
                    },
                  },
                },
              }}
            >
              <MenuItem value={TemplateStep.FIRST_TOUCH}>
                First Message
              </MenuItem>
              <MenuItem value={TemplateStep.SECOND_TOUCH}>
                Second Message
              </MenuItem>
              <MenuItem value={TemplateStep.FOLLOW_UP}>Follow Up</MenuItem>
              <MenuItem value={TemplateStep.LAST_TOUCH}>Last Message</MenuItem>
            </Select>
          </FormControl>
          {/* <Tooltip title="Which step is this message?">
              <InfoIcon
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  color: colors.primary,
                  cursor: "pointer",
                }}
              />
            </Tooltip> */}
        </div>
        <div className="form-row hint">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Tone of voice*
          </Typography>
          <Grid container className="choices-container">
            <Grid item xs={4} style={{ paddingRight: 6 }}>
              <Button
                className={
                  formik.values.style === GenerateTemplateStyle.FRIENDLY
                    ? 'choice-button-selected'
                    : 'choice-button'
                }
                onClick={() =>
                  formik.setFieldValue('style', GenerateTemplateStyle.FRIENDLY)
                }
                color="secondary"
                variant="outlined"
                endIcon={
                  formik.values.style === GenerateTemplateStyle.FRIENDLY ? (
                    <CheckMarkSmall />
                  ) : undefined
                }
              >
                Friendly
              </Button>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 3, paddingRight: 3 }}>
              <Button
                className={
                  formik.values.style === GenerateTemplateStyle.DIRECT
                    ? 'choice-button-selected'
                    : 'choice-button'
                }
                onClick={() =>
                  formik.setFieldValue('style', GenerateTemplateStyle.DIRECT)
                }
                color="secondary"
                variant="outlined"
                endIcon={
                  formik.values.style === GenerateTemplateStyle.DIRECT ? (
                    <CheckMarkSmall />
                  ) : undefined
                }
              >
                Direct
              </Button>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 6 }}>
              <Button
                className={
                  formik.values.style === GenerateTemplateStyle.PROFESSIONAL
                    ? 'choice-button-selected'
                    : 'choice-button'
                }
                onClick={() =>
                  formik.setFieldValue(
                    'style',
                    GenerateTemplateStyle.PROFESSIONAL,
                  )
                }
                color="secondary"
                variant="outlined"
                endIcon={
                  formik.values.style === GenerateTemplateStyle.PROFESSIONAL ? (
                    <CheckMarkSmall />
                  ) : undefined
                }
              >
                Professional
              </Button>
            </Grid>
            {/* <Tooltip title="What tone of voice would you like to use?">
              <InfoIcon
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  color: colors.primary,
                  cursor: "pointer",
                }}
              />
            </Tooltip> */}
          </Grid>
        </div>
        <div className="form-row hint">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Message length*
          </Typography>
          <Grid container className="choices-container">
            <Grid item xs={4} style={{ paddingRight: 6 }}>
              <Button
                color="secondary"
                variant="outlined"
                className={
                  formik.values.length === GenerateTemplateLength.SHORT
                    ? 'choice-button-selected'
                    : 'choice-button'
                }
                endIcon={
                  formik.values.length === GenerateTemplateLength.SHORT ? (
                    <CheckMarkSmall />
                  ) : undefined
                }
                onClick={() => handleChangeLength(GenerateTemplateLength.SHORT)}
              >
                Short (50-70 w)
              </Button>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 3, paddingRight: 3 }}>
              <Button
                color="secondary"
                variant="outlined"
                className={
                  formik.values.length === GenerateTemplateLength.MEDIUM
                    ? 'choice-button-selected'
                    : 'choice-button'
                }
                endIcon={
                  formik.values.length === GenerateTemplateLength.MEDIUM ? (
                    <CheckMarkSmall />
                  ) : undefined
                }
                onClick={() =>
                  handleChangeLength(GenerateTemplateLength.MEDIUM)
                }
              >
                Medium (90-110 w)
              </Button>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 6 }}>
              <Button
                color="secondary"
                variant="outlined"
                className={
                  formik.values.length === GenerateTemplateLength.LONG
                    ? 'choice-button-selected'
                    : 'choice-button'
                }
                endIcon={
                  formik.values.length === GenerateTemplateLength.LONG ? (
                    <CheckMarkSmall />
                  ) : undefined
                }
                onClick={() => handleChangeLength(GenerateTemplateLength.LONG)}
              >
                Long (160-180 w)
              </Button>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {isLoadingGenerateTemplate ? (
            <CircularProgress />
          ) : (
            <>
              <CancelButton onClick={() => setOpen(false)}>Cancel</CancelButton>
              <MainButton type="submit">Generate</MainButton>
            </>
          )}
        </div>
      </form>
    </GenerateFormWrapper>
  );
};

export default GenerateTemplateForm;
