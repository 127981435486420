import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const FeatureListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;

  .free-trial-feature,
  .includes-previous-feature-icon,
  .feature-checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primaryLight};
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 12px;
  }

  &.includes-previous-feature {
    color: ${colors.darkGreenHover};
    background-color: ${colors.primaryLight};
    border-radius: 8px;
    p {
      font-weight: 500;
    }

    .includes-previous-feature-icon {
      background-color: ${colors.primary};
      svg > path {
        stroke: ${colors.white};
      }
    }
  }

  .free-trial-feature {
    color: ${colors.darkYellow};
    background-color: ${colors.freeTrialYellow};
    border-radius: 8px;
    p {
      font-weight: 500;
    }
    svg > path {
      stroke: ${colors.darkYellow};
    }
  }
`;
