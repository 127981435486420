import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const MigrationWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  // background: ${colors.primaryLight};
  background: #e4f6f6;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  .header-migration {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    padding-top: 24px;
  }
  .logo {
    width: 145px;
    height: 44px;
  }
`;
