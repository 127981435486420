import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const PrivateLockIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <g id="Private">
        <path
          id="Path"
          d="M5.75537 6.66602V4.91135C5.75537 3.67135 6.7607 2.66602 8.0007 2.66602C9.2407 2.66602 10.246 3.67135 10.246 4.91135V6.66602"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6667 13.3327H5.33333C4.59733 13.3327 4 12.7353 4 11.9993V7.99935C4 7.26335 4.59733 6.66602 5.33333 6.66602H10.6667C11.4027 6.66602 12 7.26335 12 7.99935V11.9993C12 12.7353 11.4027 13.3327 10.6667 13.3327Z"
          fill={color}
          stroke={color}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default PrivateLockIcon;
