import {
  Button,
  ClickAwayListener,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { AutomatedStepsWrapper, AutomatedTasksBadgeWrapper } from './style';

import { colors } from 'crono-fe-common/theme';

import LinearDeterminate from 'crono-fe-common/components/LinearProgress';

import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';

import { ReactComponent as AutomationsTypesIcon } from 'assets/images/Automations-types.svg';
import { ReactComponent as ExecuteLinkedinTaskIcon } from 'assets/images/Executing-linkedin-tasks.svg';
import { ReactComponent as StopExecutionIcon } from 'assets/images/Stop-rounded-icon.svg';
import { ReactComponent as ImportingContactsIcon } from 'assets/images/Importing-contacts.svg';
import { ReactComponent as ImportingCompaniesIcon } from 'assets/images/Importing-companies.svg';
import { LinkedinAutomaticTasksQuantity } from 'crono-fe-common/types/DTO/linkedinAutomaticTasksQuantity';
import useSearchTasks from 'hooks/services/task/useSearchTasks';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import usePatchMultipleLinkedinTasks from 'hooks/services/task/usePatchMultipleLinkedinTasks';
import { useJuneAnalytics } from 'context/june';
import {
  CronoExtensionStatus,
  useGetExtensionStatus,
} from 'hooks/services/crono-extension/useGetExtensionStatus';
import IconSWarning from 'crono-fe-common/icons/Icon-S-Warning';
import { CancelButton } from 'crono-fe-common/components/CronoButton';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import { useGetAsyncQueueState } from 'hooks/services/crono-extension/useGetAsyncQueueState';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useBackgroundClearAsyncJobs } from 'crono-fe-common/hooks/crono-extension/gateway';
import { useConditionalSnackBar } from 'context/snackbar';
import { FeConstants } from 'constants/FeConstants';
import {
  AsyncJobImportCompany,
  AsyncJobImportProspect,
} from 'crono-fe-common/types/crono-extension/background-script';
import useGetNextAutomaticTaskDate from 'hooks/services/task/useGetNextAutomaticTaskDate';
import ClockIconTwelveFifteen from 'crono-fe-common/icons/Icon-Clock-12-15';
import moment from 'moment';

export const AutomatedTasksTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & {
    left?: number;
    top?: number;
    minWidth?: number | string;
    width?: number | string;
    height?: number | string;
    maxHeight?: number | string;
    overflow?: string;
    padding?: number | string;
    borderRadius?: number | string;
  }) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(
  ({
    left,
    top,
    width,
    minWidth,
    height,
    maxHeight,
    overflow,
    padding,
    borderRadius,
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      position: 'relative',
      top: top ? top : -8,
      left: left ? left : -8,
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      height: height ? height : undefined,
      maxHeight: maxHeight ? maxHeight : 100,
      width: width ? width : 500,
      minWidth: minWidth ? minWidth : undefined,
      border: `1px solid ${colors.grey4}`,
      borderRadius: borderRadius ? borderRadius : 8,
      padding: padding ? padding : 0,
      WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      display: 'flex',
      overflow: overflow ? overflow : 'auto',
    },
  }),
);

interface IProps {
  linkedinAutomaticTasks?: LinkedinAutomaticTasksQuantity;
}
const AutomatedTasks = ({ linkedinAutomaticTasks }: IProps) => {
  const analytics = useJuneAnalytics();
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [taskIds, setTaskIds] = useState<number[] | null>(null);

  const { data: queue } = useGetAsyncQueueState();

  const { call: clearQueueRequest } = useBackgroundClearAsyncJobs();
  const [showClearQueueMessage, setShowClearQueueMessage] = useState(false);

  useEffect(() => {
    if (showClearQueueMessage) {
      setShowClearQueueMessage(false);
    }
  }, [showClearQueueMessage]);

  const clearQueue = async (isCompany: boolean, showMessage: boolean) => {
    await clearQueueRequest({
      type: isCompany ? 'importCompany' : 'importProspect',
    });

    if (showMessage) {
      setShowClearQueueMessage(true);
    }
  };

  // ------ Importing companies ------
  const [previouslyImportedCompanies, setPreviouslyImportedCompanies] =
    useState<Set<Omit<AsyncJobImportCompany, 'state' | 'id'>>>(new Set());
  const [importedCompaniesSnackBar, setImportedCompaniesSnackBar] = useState({
    isVisible: false,
    counter: 0,
  });

  useEffect(() => {
    if (importedCompaniesSnackBar.isVisible) {
      setImportedCompaniesSnackBar({ isVisible: false, counter: 0 });
    }
  }, [importedCompaniesSnackBar]);

  const calculateProgressCompanies = useMemo(() => {
    if (
      !queue ||
      (!queue.importedCompaniesCount && !queue.companiesToImportCount)
    ) {
      return null;
    }

    if (queue.companiesToImportCount === 0) {
      const counter = queue?.importedCompanies?.filter(
        (j) => !previouslyImportedCompanies.has(j) && !j.error,
      ).length;
      if (counter > 0) {
        setImportedCompaniesSnackBar({
          isVisible: true,
          counter: counter,
        });
      }

      const newSetOfImportedCompanies = new Set(queue.importedCompanies);
      setPreviouslyImportedCompanies((prev) =>
        // @ts-ignore
        prev.union(newSetOfImportedCompanies),
      );

      return 100;
    }

    return Math.round(
      (queue.importedCompaniesCount * 100) /
        (queue.companiesToImportCount + queue.importedCompaniesCount),
    );
  }, [queue]);

  // ------ Importing contacts ------
  const [previouslyImportedContacts, setPreviouslyImportedContacts] = useState<
    Set<Omit<AsyncJobImportProspect, 'state' | 'id'>>
  >(new Set());
  const [importedContactsSnackBar, setImportedContactsSnackBar] = useState({
    isVisible: false,
    counter: 0,
  });

  useEffect(() => {
    if (importedContactsSnackBar.isVisible) {
      setImportedContactsSnackBar({ isVisible: false, counter: 0 });
    }
  }, [importedContactsSnackBar]);

  const calculateProgressContacts = useMemo(() => {
    if (
      !queue ||
      (!queue.importedContactsCount && !queue.contactsToImportCount)
    ) {
      return null;
    }

    if (queue.contactsToImportCount === 0) {
      const counter = queue?.importedContacts?.filter(
        (j) => !previouslyImportedContacts.has(j) && !j.error,
      ).length;
      if (counter > 0) {
        setImportedContactsSnackBar({
          isVisible: true,
          counter: counter,
        });
      }
      const newSetOfImportedContacts = new Set(queue.importedContacts);
      setPreviouslyImportedContacts((prev) =>
        // @ts-ignore
        prev.union(newSetOfImportedContacts),
      );

      return 100;
    }

    return Math.round(
      (queue.importedContactsCount * 100) /
        (queue.contactsToImportCount + queue.importedContactsCount),
    );
  }, [queue]);

  // ------ Automated linkedin tasks ------

  const { data: automatedLinkedinTasks } = useSearchTasks({
    type: TaskTodoType.LINKEDIN,
    automatic: true,
    completed: false,
    limit: 50,
    offset: 0,
  });

  const { data: extensionStatus } = useGetExtensionStatus();

  const isWorkingExtension = useMemo(
    () =>
      extensionStatus
        ? extensionStatus.status ===
          CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE
        : true,
    [extensionStatus],
  );

  const nextAutoTaskDate = useGetNextAutomaticTaskDate();

  useEffect(() => {
    if (automatedLinkedinTasks?.data?.data?.length) {
      const ids = automatedLinkedinTasks?.data?.data?.map((task) => task.id);
      setTaskIds(ids);
    }
  }, [automatedLinkedinTasks]);

  const toggleTooltipProgress = () => {
    setIsTooltipVisible((prev) => !prev);
  };

  const calculateProgressAutomaticTasks = useMemo(() => {
    const completed = linkedinAutomaticTasks?.completed ?? 0;
    const inPipeline = linkedinAutomaticTasks?.inPipeline ?? 0;

    if (completed + inPipeline === 0) {
      return null;
    }

    return Math.round((completed / (completed + inPipeline)) * 100) ?? 100;
  }, [linkedinAutomaticTasks]);

  const { mutate: patchMultipleLinkedinTasks } =
    usePatchMultipleLinkedinTasks();

  const handlePauseExecutingTasks = () => {
    patchMultipleLinkedinTasks({
      ids: [],
      automatic: false,
      allForToday: true,
    });
    if (analytics) {
      analytics.track('stop-auto-linkedin-task', {});
    }
  };

  const numberOfAutomations = useMemo(() => {
    let n = 0;
    if (linkedinAutomaticTasks?.inPipeline) n++;
    if (queue?.isImportingContacts) n++;
    if (queue?.isImportingCompanies) n++;
    return n;
  }, [
    queue?.isImportingContacts,
    queue?.isImportingCompanies,
    linkedinAutomaticTasks,
  ]);

  const overallProgress = useMemo(() => {
    let n = 0;
    let sum = 0;

    if (calculateProgressCompanies !== null) {
      n++;
      sum += calculateProgressCompanies;
    }

    if (calculateProgressContacts !== null) {
      n++;
      sum += calculateProgressContacts;
    }
    if (calculateProgressAutomaticTasks !== null) {
      n++;
      sum += calculateProgressAutomaticTasks;
    }

    return Math.round(sum / n);
  }, [
    calculateProgressCompanies,
    calculateProgressContacts,
    calculateProgressAutomaticTasks,
  ]);

  useConditionalSnackBar([
    {
      condition: showClearQueueMessage,
      message: `Background import stopped successfully`,
      severity: 'success',
    },
    {
      condition: importedContactsSnackBar.isVisible,
      message: `You successfully inserted ${importedContactsSnackBar.counter} ${
        importedContactsSnackBar.counter === 1 ? 'contact' : 'contacts'
      } `,
      severity: 'success',
    },
    {
      condition: importedCompaniesSnackBar.isVisible,
      message: `You successfully inserted ${
        importedCompaniesSnackBar.counter
      } ${importedCompaniesSnackBar.counter === 1 ? 'company' : 'companies'} `,
      severity: 'success',
    },
  ]);

  const renderErrorRow = (text: string, button: React.ReactNode) => (
    <div style={{ width: '100%' }}>
      <div className="automated-task-wrapper">
        <div className="automated-task-header-wrapper">
          <span className="execute-icon-wrapper-error">
            <IconSWarning color={colors.inactive} />
          </span>
          <div className="automated-task-header">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={600}
                lineHeight={'20px'}
              >
                {text}
              </Typography>
            </div>
            <div className="execute-right-side-wrapper">{button}</div>
          </div>
        </div>
      </div>
    </div>
  );

  //The memoization of the import companies and contacts is done to avoid re-rendering of the tooltip
  const memoizedImportCompanies = useMemo(() => {
    if (!queue?.importedCompanies) {
      return <></>;
    }
    return (
      <RenderRow
        icon={<ImportingCompaniesIcon />}
        title="New companies from Find New"
        subtitle={`${queue?.companiesToImportCount} companies in queue`}
        progress={calculateProgressCompanies}
        isFinished={queue?.companiesToImportCount === 0}
        onStopClick={() => clearQueue(true, true)}
        isWorkingExtension={isWorkingExtension}
      />
    );
  }, [queue?.importedCompaniesCount, queue?.companiesToImportCount]);

  const memoizedImportContacts = useMemo(() => {
    if (!queue?.importedContacts) {
      return <></>;
    }
    return (
      <RenderRow
        icon={<ImportingContactsIcon />}
        title="New contacts from Find New"
        subtitle={`${queue?.contactsToImportCount} contacts in queue`}
        progress={calculateProgressContacts}
        isFinished={queue?.contactsToImportCount === 0}
        onStopClick={() => clearQueue(false, true)}
        isWorkingExtension={isWorkingExtension}
      />
    );
  }, [queue?.importedContactsCount, queue?.contactsToImportCount]);

  const automatedSteps = (
    <ClickAwayListener
      onClickAway={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setIsTooltipVisible(false);
      }}
    >
      <AutomatedStepsWrapper>
        <div className="header-container">
          <div className="header">
            <Typography fontSize={16} fontWeight={600}>
              {numberOfAutomations}{' '}
              {numberOfAutomations === 1 ? 'Automation' : 'Automations'} in
              progress
            </Typography>
            <Typography fontSize={12} color={colors.grey11}>
              Crono performs automated actions on the background
            </Typography>
          </div>
          <div>
            <AutomationsTypesIcon />
          </div>
        </div>

        <div className="horizontal-divider" />

        {!isWorkingExtension &&
          (extensionStatus?.status === CronoExtensionStatus.NOT_INSTALLED
            ? renderErrorRow(
                "Please install or enable Crono's Chrome extension to continue",
                <CancelButton
                  className="extension-action-button"
                  onClick={() => {
                    window.open(
                      process.env.REACT_APP_CRONO_CHROME_EXTENSION_URL,
                      '_blank',
                    );
                  }}
                >
                  Enable
                </CancelButton>,
              )
            : renderErrorRow(
                'Please sign in to your LinkedIn account to resume',
                <CancelButton
                  className="extension-action-button"
                  sx={{
                    '.MuiButton-startIcon': {
                      marginRight: '6px',
                      marginLeft: 0,
                    },
                  }}
                  startIcon={
                    <LinkedinIcon
                      color={colors.grey1}
                      className="linkedin-log-in-icon"
                    />
                  }
                  onClick={() => {
                    window.open('https://www.linkedin.com/', '_blank');
                  }}
                >
                  Sign in
                </CancelButton>,
              ))}

        {!!linkedinAutomaticTasks?.inPipeline && (
          <RenderRow
            icon={<ExecuteLinkedinTaskIcon />}
            title="Sending LinkedIn tasks"
            subtitle={`Sent ${linkedinAutomaticTasks?.completed}/${
              (linkedinAutomaticTasks?.inPipeline ?? 0) +
              (linkedinAutomaticTasks?.completed ?? 0)
            } tasks today`}
            progress={calculateProgressAutomaticTasks}
            isFinished={!linkedinAutomaticTasks?.inPipeline}
            onStopClick={() => handlePauseExecutingTasks()}
            isWorkingExtension={isWorkingExtension}
            nextAutoTaskDate={nextAutoTaskDate}
          />
        )}

        {queue?.isImportingCompanies && memoizedImportCompanies}

        {queue?.isImportingContacts && memoizedImportContacts}
      </AutomatedStepsWrapper>
    </ClickAwayListener>
  );

  if (
    !linkedinAutomaticTasks?.inPipeline &&
    !queue?.isImportingCompanies &&
    !queue?.isImportingContacts
  ) {
    return <></>;
  }

  return (
    <AutomatedTasksBadgeWrapper>
      <div>
        <AutomatedTasksTooltip
          title={automatedSteps}
          className="automated-tasks-tooltip"
          minWidth={450}
          height={'100%'}
          maxHeight={620}
          padding={'0 0 20px 0'}
          open={isTooltipVisible}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            style: {
              zIndex: 4,
            },
          }}
        >
          <Button
            className={
              isWorkingExtension
                ? 'automation-tasks-button'
                : 'automation-tasks-error'
            }
            onClick={toggleTooltipProgress}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8px',
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight={'22px'}
                noWrap
              >
                Automations
              </Typography>

              <LinearDeterminate
                value={overallProgress}
                linearBarSx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: isWorkingExtension
                      ? colors.intenseBlue
                      : colors.inactive,
                  },
                  '&.MuiLinearProgress-colorPrimary': {
                    backgroundColor: isWorkingExtension
                      ? colors.greyButtonHover
                      : colors.inactiveLightHover,
                  },
                }}
              />
            </div>

            {isWorkingExtension ? (
              <FlexDiv style={{ width: '40px' }}>
                <span
                  className="loader"
                  style={{ transform: 'scale3d(0.3, 0.3, 1)' }}
                ></span>
              </FlexDiv>
            ) : (
              <div className="error-automation-icon">
                <IconSWarning
                  color={colors.inactive}
                  className="icon-warning-error"
                />
              </div>
            )}
          </Button>
        </AutomatedTasksTooltip>
      </div>
    </AutomatedTasksBadgeWrapper>
  );
};

export default AutomatedTasks;

interface IRenderRow {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  progress: number | null;
  isFinished: boolean;
  onStopClick?: () => void;
  isWorkingExtension: boolean;
  nextAutoTaskDate?: Date | null;
}

const RenderRow = ({
  icon,
  title,
  subtitle,
  progress,
  isFinished,
  onStopClick,
  isWorkingExtension = false,
  nextAutoTaskDate,
}: IRenderRow) => {
  if (!isWorkingExtension) {
    return <></>;
  }
  return (
    <div style={{ width: '100%' }}>
      <div className="automated-task-wrapper">
        <div className="automated-task-header-wrapper">
          <span className="execute-icon-wrapper">{icon}</span>
          <div className="automated-task-header">
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
            >
              <Typography
                fontSize={'14px'}
                fontWeight={600}
                color={isFinished ? colors.grey11 : undefined}
              >
                {title}
              </Typography>
              <div
                style={{ display: 'flex', gap: '12px', alignItems: 'center' }}
              >
                <Typography fontSize={'12px'} color={colors.grey11}>
                  {subtitle}
                </Typography>
                {nextAutoTaskDate ? (
                  <div className="next-auto-task">
                    <ClockIconTwelveFifteen
                      color={colors.intenseBlue}
                      style={{ height: '14px', width: '14px' }}
                    />
                    <span className="next-auto-task-text">
                      {moment(nextAutoTaskDate).diff(moment(), 'minutes') >= 1
                        ? `Next task in ${moment(nextAutoTaskDate).diff(
                            moment(),
                            'minutes',
                          )} min`
                        : 'Next task executing'}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="execute-right-side-wrapper">
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                color={colors.darkGreen}
              >
                {progress ?? 0}%
              </Typography>
              {isFinished ? (
                <IconButton
                  className="completed-checkmark"
                  style={{ cursor: 'auto' }}
                >
                  <CheckMarkIcon color={colors.white} />
                </IconButton>
              ) : (
                <div style={{ paddingRight: '8px' }}>
                  <Tooltip
                    arrow
                    title={
                      'Automation will be stopped and you will not be able to resume it'
                    }
                  >
                    <IconButton
                      style={{ cursor: 'pointer', padding: 0 }}
                      onClick={onStopClick}
                    >
                      <StopExecutionIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
        <LinearDeterminate value={progress ?? 0} />
      </div>
    </div>
  );
};
