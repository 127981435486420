import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { EnableConditionalForSequence } from 'crono-fe-common/types/DTO/enableConditionalForSequence';

export default function useEnableConditionalForSequence() {
  const queryClient = useQueryClient();

  const enableConditionalForSequence = (
    identifiers: EnableConditionalForSequence,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.conditional.invitation}`,
      config: {
        method: 'patch',
        data: { data: identifiers },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, EnableConditionalForSequence>(
    enableConditionalForSequence,
    {
      onSettled: (result, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
      },
    },
  );
}
