import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { visuallyHidden } from '@mui/utils';
import { HeadCell } from './types';
import { Order } from 'crono-fe-common/types/userPreferences';
import { colors } from 'crono-fe-common/theme';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import Tooltip from '@mui/material/Tooltip';
import { Checkbox } from '@mui/material';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { ReactComponent as IndeterminateIcon } from 'crono-fe-common/icons/IndeterminateCheck.svg';
import EditPencilIcon from 'crono-fe-common/icons/Icon-Edit-pencil';
import { useState } from 'react';
import EditColumnsDropdown from './editColumnsDropdown';

interface EnhancedTableProps {
  onRequestSort: (data: any) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  onlyOwned: boolean;
  showSelectAll?: boolean;
  // 0 - None, 1 - Some, 2 - All
  selectedStatus?: number;
  clickSelectAll?: () => void;
  disableCheckbox?: boolean;
  toggleEditDropdown: () => void;
}

export default function CustomTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    onlyOwned,
    showSelectAll,
    selectedStatus,
    clickSelectAll,
    disableCheckbox,
    toggleEditDropdown,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells
          //If the user is showing only it's owned I don't show the owner column
          .filter((headCell) => !onlyOwned || headCell.id !== 'owner')
          .map((headCell, index) => {
            const firstColumnWithCheckbox =
              showSelectAll && selectedStatus !== undefined && index == 0;

            return (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.isSticky ? 'first-column' : 'column'}
                padding={'normal'}
                sx={{
                  left: headCell.stickyOffset ?? undefined,
                  width: headCell.width,
                  minWidth: headCell.minWidth,
                  paddingInline: headCell.padding ?? undefined,
                  top: -2,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  borderRight: headCell.border ? '1px solid #ebebeb' : 'unset',
                  paddingLeft: firstColumnWithCheckbox ? '4px' : undefined,
                }}
              >
                <Tooltip title={headCell.tooltip ?? ''} placement="top" arrow>
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={true}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => onRequestSort(headCell.id)}
                      sx={{
                        '&.MuiTableSortLabel-root': {
                          color:
                            orderBy === headCell.id
                              ? `${colors.darkGreen} !important`
                              : `${colors.grey11} !important`,
                          '&:hover': {
                            color:
                              orderBy === headCell.id
                                ? `${colors.darkGreenHover} !important`
                                : `${colors.black} !important`,
                            '.MuiTableSortLabel-icon': {
                              color:
                                orderBy === headCell.id
                                  ? `${colors.darkGreenHover} !important`
                                  : `${colors.black} !important`,
                            },
                          },
                          '.MuiTableSortLabel-icon': {
                            color:
                              orderBy === headCell.id
                                ? `${colors.darkGreen} !important`
                                : `${colors.grey4Hover} !important`,
                          },
                        },
                      }}
                    >
                      {firstColumnWithCheckbox && (
                        <label>
                          <Checkbox
                            icon={
                              <IconUnchecked
                                color={
                                  disableCheckbox ? colors.grey4 : colors.grey3
                                }
                              />
                            }
                            checkedIcon={<CheckedIcon />}
                            indeterminateIcon={<IndeterminateIcon />}
                            indeterminate={selectedStatus === 1}
                            checked={selectedStatus === 2}
                            disabled={disableCheckbox}
                            onClick={(ev) => {
                              if (clickSelectAll) clickSelectAll();
                              ev.preventDefault();
                              ev.stopPropagation();
                            }}
                            sx={{
                              padding: 'inherit',
                              margin: 'inherit',
                              '&:hover': {
                                backgroundColor: colors.grey4,
                              },
                              '&.Mui-checked': {
                                '&:hover': {
                                  opacity: 0.7,
                                },
                              },
                              marginRight: 1,
                              marginLeft: 1,
                              // "&.MuiCheckbox-root": {
                              //   color: colors.grey33 + " !important",
                              // },
                            }}
                          />
                        </label>
                      )}
                      <span style={{ fontWeight: 600 }}>{headCell.label}</span>
                    </TableSortLabel>
                  ) : (
                    <span style={{ fontWeight: 600 }}>{headCell.label}</span>
                  )}
                </Tooltip>
              </TableCell>
            );
          })}

        <div
          onClick={toggleEditDropdown}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: `${colors.white}`,
            position: 'absolute',
            zIndex: '10',
            top: '93px',
            right: '14px',
            width: '30px',
            height: '20px',
            cursor: 'pointer',
          }}
        >
          <EditPencilIcon width="18px" height="18px" color={colors.green} />
        </div>
      </TableRow>
    </TableHead>
  );
}
