import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const GeneralIcon = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 18.584C7.569 17.059 9.692 16.121 12.023 16.121C14.331 16.121 16.431 17.04 18 18.537C16.431 20.062 14.308 21 11.977 21C9.669 21 7.569 20.081 6 18.584Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.975 16.025C3.365 14.811 3 13.452 3 12C3 7.026 7.026 3 12 3C16.974 3 21 7.026 21 12C21 13.45 20.637 14.81 20.023 16.023"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1213 7.87868C15.2929 9.05025 15.2929 10.9497 14.1213 12.1213C12.9497 13.2929 11.0502 13.2929 9.87868 12.1213C8.70711 10.9497 8.70711 9.05025 9.87868 7.87868C11.0502 6.70711 12.9497 6.70711 14.1213 7.87868"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GeneralIcon;
