import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const CallModalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: fit-content;
  max-height: calc(100% - 130px);
  overflow-y: auto;
  padding: 16px 18px 0px 18px;

  .vertical-divider {
    border: 1px solid ${colors.grey4};
    height: 40px;
  }

  .right-side-call-log {
    display: flex;
    justify-content: space-between;
    margin-right: 2px;
    align-items: center;
    width: 100%;
  }

  .modal-body {
    width: 100%;
    padding: 0;
    margin: 0;
    display: inline-flex;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
  }

  .message-channel-container {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    height: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .left-side-actions {
    padding: 10px;
    flex: 2;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  .quick-actions {
    gap: 8px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }

  .quick-actions-empty {
    gap: 8px;
    height: 92px;
  }

  .quick-actions > h2 {
    font-weight: 500;
    color: ${colors.primary};
    margin: 0;
    margin-bottom: 1rem;
  }

  .dialer {
    gap: 20px;
  }

  .add-note-text-area {
    width: 100%;
    background-color: ${colors.grey6};
    color: ${colors.dialerBlue};
    border-radius: 1rem;
    outline: none;
  }

  .call-input {
    color: ${colors.white};
    font-size: 1.2em;
    padding: 15px;
    width: auto;
    border-radius: 5px;
  }

  .call-phone-button {
    background: ${colors.callScheduled};

    padding: 10px 40px;
    text-transform: unset !important;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
  .phone-icon {
    width: 20px;
    height: 20px;
  }
  .call-log-icon-round {
    border: 1px solid ${colors.grey444};
    border-radius: 999px;
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  .redirect-icon {
    display: flex;
    align-items: center;
  }
  .redirect-icon > button {
    border-radius: 999px;
    min-width: auto;
  }
  .redirect-icon:hover > button {
    background: ${colors.grey6};
  }
  .editable-div > div > button {
    opacity: 0;
  }
  .editable-div:hover > div > button {
    opacity: 1;
  }

  @media (max-width: 900px) {
    .modal-body {
      display: flex;
      flex-direction: column;
    }
  }

  .badge {
    min-width: 9rem;
    width: fit-content;
    padding: 8px 10px;
    cursor: auto;
  }
  .status-not-selected {
    background: #eaf1fb;
    color: #7a8395;
  }
  .status-selected {
    color: white;
  }
  .status-not-selected-checkbox {
    color: #7a8395;
  }

  .wrongNumber {
    background: #fd6778;
  }
  .notAnswered {
    background: #e2ad13;
  }
  .voicemail {
    background: #3b58db;
  }
  .gatekeeper {
    background: #1a9d6e;
  }
  .connected {
    background: #8846dc;
  }
`;

export const DialerWrapper = styled.div`
  border: 2px solid ${colors.iconBlack};
  border-radius: 0.5rem;
  background-color: ${colors.dialerBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
`;

export const DialerButton = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${colors.dialerBlue};
  border-radius: 50%;
  color: ${colors.white};
  text-align: center;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  vertical-align: middle;
`;

type QuickActionButtonProps = {
  selected: boolean;
};

export const QuickActionButton = styled.div<QuickActionButtonProps>`
  background-color: ${(props) =>
    props.selected ? colors.primary : colors.dialerBackground};
  border: 1px solid ${colors.primary};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: ${(props) => (props.selected ? colors.white : colors.dialerBlue)};
  font-weight: 600;
  width: 10.5rem;
  text-align: center;
  cursor: pointer;
`;
