import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

const EmailInput = styled(TextField)`
  .MuiOutlinedInput-input,
  .MuiAutocomplete-inputRoot {
    background: white !important;
    padding: 0px !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0px solid white !important;
  }

  & .Mui-focused .MuiInputLabel-root {
    color: ${colors.primary} !important;
  }
`;

export default EmailInput;
