import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const NotSupportedView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  .logo {
    width: 135px;
    height: 40px;
    margin: 16px 0px;
  }
  .no-mobile-support {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: ${colors.grey6};
    height: 100vh;
    width: 100%;
  }

  .no-mobile-footer {
    background-color: ${colors.grey6};
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0px;
  }
  .divider {
    border: 1px solid ${colors.grey4};
  }
`;
