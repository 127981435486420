import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InvitationBadgeWrapper = styled.div`
  height: 24px;
  border-radius: 12px;
  padding: 4px 8px 4px 4px;
  gap: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;

  //to make the line go through the middle of the badge
  &.invitation-badge-margin-large {
    margin-bottom: 12px;
  }

  &.invitation-not-accepted-badge {
    background: ${colors.inactiveLight};
  }
  &.invitation-accepted-badge {
    background: ${colors.primaryLight};
  }
`;
