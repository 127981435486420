import React from 'react';
import { Typography } from '@mui/material';
import { PlanShortCardWrapper } from './style';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { colors } from 'crono-fe-common/theme';
import IconArrowSTopRight from 'crono-fe-common/icons/Icon-Arrow-S-Top-Right';
import { PlanPrices } from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';
import classNames from 'classnames';

interface IProps {
  type: SubscriptionPlanType;
  price: PlanPrices;
  billingPlan: string;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
}

const PlanShortCard = ({
  type,
  price,
  billingPlan,
  onClick,
  selected,
  className,
}: IProps) => {
  return (
    <PlanShortCardWrapper
      onClick={onClick}
      className={classNames(className ?? '', {
        clickable: !!onClick,
        selected: !!selected,
        'free-trial': type === SubscriptionPlanType.FREE_TRIAL,
      })}
    >
      <Typography
        fontSize={'18px'}
        fontWeight={700}
        lineHeight={'24px'}
        color={
          type === SubscriptionPlanType.FREE_TRIAL ? colors.black : undefined
        }
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {type === SubscriptionPlanType.FREE_TRIAL ? '14 days trial' : type}
        {type !== SubscriptionPlanType.FREE_TRIAL &&
        type !== SubscriptionPlanType.STARTER ? (
          <span className="badge highlight">
            {type === SubscriptionPlanType.ULTRA
              ? 'More than 5 users'
              : 'Popular'}
            {type === SubscriptionPlanType.PRO ? (
              <IconArrowSTopRight color={colors.intenseBlue} />
            ) : null}
          </span>
        ) : null}
      </Typography>
      <div className="plan-pricing">
        <Typography
          fontSize={'40px'}
          fontWeight={600}
          lineHeight={'40px'}
          color={
            type === SubscriptionPlanType.FREE_TRIAL ? colors.black : undefined
          }
        >
          {type === SubscriptionPlanType.FREE_TRIAL
            ? 'Free'
            : `€${price?.monthlyTotalCycle}`}
        </Typography>
        {type !== SubscriptionPlanType.FREE_TRIAL && (
          <div className="plan-billing">
            <Typography fontWeight={500} lineHeight={'24px'}>
              User / month
            </Typography>
            <Typography
              fontWeight={400}
              lineHeight={'24px'}
              color={selected ? colors.white : colors.grey11}
            >
              billed {billingPlan === 'yearly' ? 'annually' : 'monthly'}
            </Typography>
          </div>
        )}
      </div>
    </PlanShortCardWrapper>
  );
};

export default PlanShortCard;
