import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Bar, Tooltip } from '@ant-design/charts';
import { Typography } from '@mui/material';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import Legend from '../legend';
import { calcWidth, numberOfGroups, selectChartContainerClass } from '../model';
import { GraphData } from '../tabsControllers/model';
import { BarChartPlaceholder } from './chartsPlaceholders';
import {
  AnalyticsDeals,
  AnalyticsDealsData,
} from 'crono-fe-common/types/analyticsDeals';
import { AnalyticDealsSwitch } from '../tabsControllers/analyticDealsTabController';
import { TabSelectionButton } from 'crono-fe-common/components/CronoButton';
import { fullname, getColorsStages } from 'utils/fe-utils';
import { useAuth } from 'context/auth';

interface IProps {
  dealsAnalytics: AnalyticsDealsData | undefined;
  stagesKeys: string[];
  stagesNames: string[];
}

const DealsOverviewBarChart = ({
  dealsAnalytics,
  stagesKeys,
  stagesNames,
}: IProps) => {
  const { user } = useAuth();
  const { data: users } = useGetUsers();
  const [switchValue, setSwitchValue] = useState<AnalyticDealsSwitch>(
    AnalyticDealsSwitch.Values,
  );
  const [workingData, setWorkingData] = useState<AnalyticsDeals | null>(null);

  useEffect(() => {
    if (switchValue === AnalyticDealsSwitch.Quantity) {
      setWorkingData(dealsAnalytics?.quantity || null);
    }

    if (switchValue === AnalyticDealsSwitch.Values) {
      setWorkingData(dealsAnalytics?.values || null);
    }
  }, [switchValue]);

  useEffect(() => {
    setWorkingData(dealsAnalytics?.values || null);
  }, [dealsAnalytics]);

  const dataGeneral: GraphData[] = useMemo(() => {
    if (!users || !workingData) return [];
    const userMapping = new Map<number, string>();
    const newData: GraphData[] = [];
    let maxValue = 0;

    users?.data?.data?.forEach((user) => {
      userMapping.set(user.id, fullname(user.firstName, user.lastName));
    });

    const userTotalDeals = new Map<string, number>();

    stagesKeys.forEach((stage) => {
      if (!workingData[stage]) return;

      workingData[stage].forEach((item) => {
        let user: string | undefined;

        if (item.userId && item.count > 0) {
          user = userMapping.get(item.userId);
          if (user) {
            const currentTotal = userTotalDeals.get(user) ?? 0;
            userTotalDeals.set(user, currentTotal + item.count);
          }
        }
      });
    });

    userTotalDeals.forEach((totalDeals, user) => {
      if (totalDeals > maxValue) maxValue = totalDeals;

      stagesKeys.forEach((stage) => {
        if (!workingData[stage]) return;

        workingData[stage].forEach((item) => {
          let userLabel: string | undefined;

          if (item.userId && item.count > 0) {
            userLabel = userMapping.get(item.userId);
            if (userLabel === user) {
              newData.push({
                label: user,
                value: item.count,
                type: item.label,
              });
            }
          }
        });
      });
    });

    return newData;
  }, [workingData, stagesKeys, users]);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const legendColors: string[] = useMemo(() => {
    const length: number = stagesNames.length;

    return Array.from(
      { length },
      (_, i) => getColorsStages(i + 1, length).dark,
    );
  }, [stagesNames]);

  const configGeneral = useMemo(() => {
    const totalValues = dataGeneral.reduce(
      (acc, cur) => {
        acc[cur.label] = (acc[cur.label] || 0) + cur.value;
        return acc;
      },
      {} as Record<string, number>,
    );

    const maxTotalValue = Math.max(...Object.values(totalValues)) * 1.1;

    return {
      data: dataGeneral,
      xField: 'value',
      yField: 'label',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index = stagesNames.findIndex((legend) => legend === type) ?? 0;

        return getColorsStages(index + 1, stagesNames.length).dark;
      }) as any,
      autoFit: false,
      height: 300,
      width:
        calcWidth(dataGeneral, 180, chartContainerRef.current?.offsetWidth) +
        20,
      meta: {
        value: {
          min: 0,
          max: maxTotalValue,
        },
      },
      isStack: true,
      renderer: 'svg' as 'svg' | 'canvas' | undefined,
      dodgePadding: numberOfGroups(dataGeneral) > 1 ? 6 : undefined,

      maxBarWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      minBarWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      legend: false as any,
      randomVal: Math.random() * 10000,
      tooltip: {
        formatter:
          switchValue === AnalyticDealsSwitch.Values
            ? (datum: Record<string, any>) => {
                const value = datum.value;
                const formattedValue =
                  value % 1 === 0
                    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return {
                  name: datum.type,
                  value: `${user?.otherSettings?.currency || '$'}${formattedValue}`,
                };
              }
            : undefined,
      } as Tooltip,
    };
  }, [dataGeneral, chartContainerRef.current?.offsetWidth]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography className="chart-title">Overall Deal View</Typography>

        <div>
          <TabSelectionButton
            selected={switchValue === AnalyticDealsSwitch.Quantity}
            onClick={() => setSwitchValue(AnalyticDealsSwitch.Quantity)}
            disableElevation
            style={{
              minWidth: '127px',
            }}
          >
            Quantity
          </TabSelectionButton>

          <TabSelectionButton
            selected={switchValue === AnalyticDealsSwitch.Values}
            onClick={() => setSwitchValue(AnalyticDealsSwitch.Values)}
            disableElevation
            style={{
              minWidth: '127px',
            }}
          >
            Values
          </TabSelectionButton>
        </div>
      </div>

      <div
        //For this graph we do not want to apply the special styles that we use when there is only 1 user
        className={selectChartContainerClass(dataGeneral, true)}
        ref={chartContainerRef}
        id={
          numberOfGroups(dataGeneral) === 1
            ? 'deals-overview-bar-chart'
            : 'deals-overview-bar-chart-multiple-users'
        }
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 40,
            position: 'sticky',
            left: 0,
          }}
        >
          <Legend
            colors={legendColors}
            fields={stagesNames}
            toManyFields={true}
          />
        </div>

        {configGeneral?.data && configGeneral.data.length ? (
          <Bar {...configGeneral} key={configGeneral.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <BarChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default DealsOverviewBarChart;
