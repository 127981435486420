import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { ProspectSearchForExport } from 'crono-fe-common/types/DTO/prospectSearchForExport';
import { ProspectForExport } from 'crono-fe-common/types/prospectForExport';

export default function useSearchProspectsForExport() {
  const searchProspectsForExport = (
    searchParameters: ProspectSearchForExport,
  ) => {
    const { selected, ...rest } = searchParameters;

    const request: Request = {
      url: ENDPOINTS.prospect.search.export,
      config: {
        method: 'post',
        data: { data: rest },
      },
    };

    return client(request);
  };

  return useMutation<
    ResponsePaginated<ProspectForExport[]>,
    CronoError,
    ProspectSearchForExport
  >(searchProspectsForExport, {});
}
