import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Pipeline } from 'crono-fe-common/types/pipeline';

export default function useGetPipelines(enabled = true) {
  const request: Request = {
    url: `${ENDPOINTS.pipeline.main}`,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.pipeline.main],
    queryFn: () => client(request),
    enabled,
    staleTime: 60 * 60 * 1000, // 60 minutes
  };

  const { data, ...rest } = useQuery<Response<Pipeline[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
