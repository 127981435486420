import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ExtensionStatusBannerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 16px 24px;
  margin-bottom: 8px;
  border: 1px solid ${colors.inactive};
  background: ${colors.inactiveLight};
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .warning-icon {
    width: 32px;
    height: 32px;
  }
`;
