import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import { USER_TOKEN_KEY } from 'config/localStorage';
import { gatewayService } from 'crono-fe-common/hooks/crono-extension/gateway';
import { _CronoGatewayBackgoundPullNotifications } from 'crono-fe-common/types/crono-extension/background-script';
import {
  CronoNotificationEmitEvents,
  NotificationEvent,
} from 'crono-fe-common/types/notification-event';
import EventEmitter from 'eventemitter3';
import { invalidationUseCreateAccount } from 'hooks/services/account/useCreateAccount';
import { invalidationUseCreateProspect } from 'hooks/services/prospect/useCreateProspect';
import { QueryClient } from 'react-query';
import PATH from 'routing/path';

class CronoNotification {
  private connection: HubConnection | null = null;
  public readonly notificationEmitter =
    new EventEmitter<CronoNotificationEmitEvents>();

  private running = false;

  private queryClient: QueryClient | null = null;

  public start = (queryClient: QueryClient) => {
    if (!this.running) {
      this.running = true;
      this.startListeningFromServer();
      this.startListeningFromExtension();
      this.queryClient = queryClient;
    }
  };

  private startListeningFromServer = () => {
    const authToken = localStorage.getItem(USER_TOKEN_KEY);
    //Websocket connection with the server to get push notifications
    this.connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BE_URL}${PATH.CONNECTION_HUB}`, {
        accessTokenFactory: () => authToken ?? '',
      })
      .build();
    if (!this.connection) return;
    this.connection.on('ReceiveNotification', (message: string) => {
      this.handleEmitNotification(message);
    });
    this.connection.onclose((error) => {
      console.log('Error', error);
    });
    if (this.connection.state === HubConnectionState.Disconnected)
      this.connection.start();
  };

  private startListeningFromExtension = async () => {
    // https://developer.chrome.com/docs/extensions/develop/concepts/messaging#external-webpage
    // "Extensions can also receive and respond to messages from other web pages, but can't send messages to web pages."
    // for this reason, we need to use long pooling
    while (this.running) {
      try {
        const response =
          await gatewayService.execute<_CronoGatewayBackgoundPullNotifications>(
            {
              target: 'background-script',
              methodName: 'pullCronoNotifications',
              params: {},
            },
          );
        if (this.running) {
          for (const notification of response.notifications) {
            this.handleEmitNotification(notification);
          }
        }
        await delay(100);
      } catch (e) {
        await delay(6_000);
      }
    }
  };

  public stop = () => {
    this.running = false;
    if (
      this.connection &&
      this.connection.state === HubConnectionState.Connected
    ) {
      this.connection.stop();
    }
  };

  //Send the notification through the emitter
  private handleEmitNotification = (item: string | NotificationEvent) => {
    const notification: NotificationEvent =
      typeof item === 'string' ? JSON.parse(item) : item;
    switch (notification.type) {
      case 'LiveFeed':
        if (notification.actionType === 'Refetch') {
          this.notificationEmitter.emit('LiveFeedRefetch');
        }
        break;
      case 'ImportProspect':
        invalidationUseCreateProspect(this.queryClient!);
        break;
      case 'ImportAccount':
        invalidationUseCreateAccount(this.queryClient!);
        break;
      default:
        break;
    }
  };
}

const notificationInstance = new CronoNotification();

export default notificationInstance;

async function delay(millis: number) {
  await new Promise((resolve) => {
    setTimeout(resolve, millis);
  });
}
