import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Event } from 'crono-fe-common/types/event';

export default function useEvents(
  accountId: string,
  prospectId?: string | null,
) {
  const queryParams = queryString.stringify({
    accountId,
    prospectId,
    limit: 15,
  });

  const request: Request = {
    url: `${ENDPOINTS.events.event}?${queryParams}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.events.event, accountId, prospectId],
    queryFn: () => client(request),
    enabled: !!accountId || !!prospectId,
  };

  const { data, ...rest } = useQuery<Response<Event[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
