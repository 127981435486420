import { Button, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { OpportunitiesWrapper } from './style';

import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import { colors } from 'crono-fe-common/theme';
import AddOpportunityContainer from './addOpportunity/container';
import useGetPipelines from 'hooks/services/pipeline/useGetPipelines';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { TuneRounded } from '@mui/icons-material';
import useSearchOpportunity from 'hooks/services/opportunity/useSearchOpportunity';
import StageColumn from './stageColumn';
import { getColorsStages, getRangeFromTimePeriod } from 'utils/fe-utils';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { StageLimit } from 'crono-fe-common/types/DTO/opportunitySearch';
import { FeConstants } from 'constants/FeConstants';
import usePatchOpportunity from 'hooks/services/opportunity/usePatchOpportunity';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { OpportunityStageListDTO } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { FilterModalContainer } from 'pages/accountTab/filter/style';
import FilterOpportunities from 'pages/accountTab/filter/filterOpportunities';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import usePatchUserPreferences from '../../hooks/services/user/usePatchUserPreferences';
import { useAuth } from '../../context/auth';
import { FlexDiv } from '../../crono-fe-common/components/Layout/FlexDiv';
import { TimePeriod } from '../../crono-fe-common/types/timePeriod';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { SettingsTabs } from 'pages/settings';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import { useJuneAnalytics } from 'context/june';
import Role from 'crono-fe-common/types/enums/role';

interface OpportunityFiltersPreferences {
  userId?: number | null;
  // We save external names of stages
  stages?: string[];
  closeDateStart?: Date | null;
  closeDateEnd?: Date | null;
  closePeriod?: TimePeriod;
  createdDateStart?: Date | null;
  createdDateEnd?: Date | null;
  createdPeriod?: TimePeriod;
  pipelineId?: number;
}

export type PickedOpportunityFilters = {
  userId?: number | null;
  stages?: OpportunityStageListDTO[] | null;
  closeDateStart?: Date | null;
  closeDateEnd?: Date | null;
  closePeriod?: TimePeriod | null;
  createdDateStart?: Date | null;
  createdDateEnd?: Date | null;
  createdPeriod?: TimePeriod | null;
};

const Opportunities = () => {
  const analytics = useJuneAnalytics();

  const navigate = useNavigate();
  // All states ---
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

  const [selectedPipelineId, setSelectedPipelineId] = useState<number | null>(
    null,
  );

  const [filters, setFilters] = useState<PickedOpportunityFilters | null>(null);

  const appliedFilters = useMemo(() => {
    let count = 0;
    if (!filters) return 0;
    if (filters.userId) count++;
    if (filters.stages) count++;
    if (filters.closeDateStart || filters.closeDateEnd || filters.closePeriod)
      count++;
    if (
      filters.createdDateStart ||
      filters.createdDateEnd ||
      filters.createdPeriod
    )
      count++;

    return count;
  }, [filters]);
  //The stages that has to be shown in the correct order
  const [stages, setStages] = useState<OpportunityStageListDTO[]>([]);
  const [savedStagesFromUserPreferences, setSavedStagesFromUserPreferences] =
    useState<string[] | undefined | null>(undefined);
  const [stageLimits, setStageLimits] = useState<StageLimit[] | null>(null);

  const [search, setSearch] = useState<string>('');

  const [showErrorOpportunityNotYours, setShowErrorOpportunityNotYours] =
    useState<boolean>(false);

  // Hooks && Use memo ----
  const { user, highestRole } = useAuth();
  const stateOfSwitch = !user || user.id === filters?.userId;

  const { mutate: patchUserPreferences } = usePatchUserPreferences();
  const { data: pipelines } = useGetPipelines();

  const selectedPipeline = useMemo(() => {
    //Reset eventual limits
    setStageLimits(null);
    return pipelines?.data?.data.find(
      (pipeline) => pipeline.id === selectedPipelineId,
    );
  }, [selectedPipelineId, pipelines]);

  const totalNumberOfStages = useMemo(() => {
    //Return the max stageOrder in the array of stages
    return selectedPipeline?.stages.length ?? 0;
  }, [selectedPipeline]);

  const { data: opportunityData } = useSearchOpportunity(
    {
      ...filters,
      pipeline: selectedPipeline?.externalName ?? null,
      limit: 50,
      name: search,
      stageLimits: stageLimits ?? [],
      stages: filters?.stages?.map((stage) => stage.stage) as string[],
    },
    !!selectedPipeline,
  );

  const {
    mutate: patchOpportunity,
    error: errorPatchingOpportunity,
    isSuccess: opportunityPatched,
  } = usePatchOpportunity();

  useConditionalSnackBar([
    {
      condition: !!opportunityPatched,
      message: 'Deal moved successfully',
      severity: 'success',
    },
    {
      condition: !!errorPatchingOpportunity,
      message: getError(errorPatchingOpportunity) ?? 'Error moving opportunity',
      severity: 'error',
    },
    {
      condition: !!showErrorOpportunityNotYours,
      message: 'You are not the owner of this opportunity',
      severity: 'error',
    },
  ]);

  // Use effects ----

  const tryToSetFirstPipelineId = () => {
    //When loaded set the initial pipeline to the first one
    if (pipelines?.data?.data.length) {
      setSelectedPipelineId(pipelines?.data?.data[0].id);
    }
  };
  // Load user preferences
  useEffect(() => {
    if (!user) return;

    if (user.userPreferences?.opportunityFilters) {
      const opportunityFilters: OpportunityFiltersPreferences = JSON.parse(
        user.userPreferences?.opportunityFilters,
      );

      if (opportunityFilters.pipelineId) {
        setSelectedPipelineId(opportunityFilters.pipelineId);
      } else {
        tryToSetFirstPipelineId();
      }
      setFilters((prev) => {
        const newFilters: PickedOpportunityFilters = { ...prev };

        if (opportunityFilters.userId !== undefined) {
          newFilters.userId = opportunityFilters.userId;
        } else {
          newFilters.userId = user.id;
        }

        //If there is a period I recalculate the dates in order to be consistent with the period
        if (opportunityFilters.closePeriod) {
          const { start, end } = getRangeFromTimePeriod(
            opportunityFilters.closePeriod,
          );
          newFilters.closeDateStart = start;
          newFilters.closeDateEnd = end;
        } else {
          newFilters.closeDateStart = opportunityFilters.closeDateStart
            ? new Date(opportunityFilters.closeDateStart)
            : null;
          newFilters.closeDateEnd = opportunityFilters.closeDateEnd
            ? new Date(opportunityFilters.closeDateEnd)
            : null;
        }
        newFilters.closePeriod = opportunityFilters.closePeriod;

        //Same for creation
        if (opportunityFilters.createdPeriod) {
          const { start, end } = getRangeFromTimePeriod(
            opportunityFilters.createdPeriod,
          );
          newFilters.createdDateStart = start;
          newFilters.createdDateEnd = end;
        } else {
          newFilters.createdDateStart = opportunityFilters.createdDateStart
            ? new Date(opportunityFilters.createdDateStart)
            : null;
          newFilters.createdDateEnd = opportunityFilters.createdDateEnd
            ? new Date(opportunityFilters.createdDateEnd)
            : null;
        }
        newFilters.createdPeriod = opportunityFilters.createdPeriod;

        return newFilters;
      });

      if (opportunityFilters.stages) {
        setSavedStagesFromUserPreferences(opportunityFilters.stages);
      }
    } else {
      tryToSetFirstPipelineId();
    }
  }, [user, pipelines]);

  // Save user preferences
  useEffect(() => {
    //To be sure that the filters in the userPreferences are saved only after the filters are set
    if (filters && user) {
      const opportunityFilters = user?.userPreferences
        ?.opportunityFilters as string;
      const oldFilters: OpportunityFiltersPreferences = JSON.parse(
        opportunityFilters !== '' ? opportunityFilters : '{}',
      );

      const newFilters: OpportunityFiltersPreferences = {
        ...oldFilters,
      };

      if (filters.userId !== undefined) {
        newFilters.userId = filters.userId;
      }

      if (selectedPipelineId) {
        newFilters.pipelineId = selectedPipelineId;

        if (
          oldFilters?.pipelineId &&
          selectedPipelineId !== oldFilters.pipelineId
        ) {
          newFilters.stages = undefined;
        }
      }

      newFilters.createdDateStart = filters?.createdDateStart ?? undefined;
      newFilters.createdDateEnd = filters?.createdDateEnd ?? undefined;
      newFilters.createdPeriod = filters.createdPeriod ?? undefined;

      newFilters.closeDateStart = filters?.closeDateStart ?? undefined;
      newFilters.closeDateEnd = filters?.closeDateEnd ?? undefined;
      newFilters.closePeriod = filters.closePeriod ?? undefined;

      if (savedStagesFromUserPreferences !== undefined) {
        newFilters.stages = savedStagesFromUserPreferences ?? undefined;
      }

      user.userPreferences.opportunityFilters = JSON.stringify(newFilters);
      patchUserPreferences({
        opportunityFilters: JSON.stringify(newFilters),
      });
    }
  }, [
    //Like this so this get rendered only when new filters are set. Be carefull to always create a new object when setting (something that should be done in general given React's rules)
    filters,
  ]);

  useEffect(() => {
    if (!opportunityData?.data?.data.opportunitiesByStages) {
      setStages([]);
      return;
    }
    //I order them by stageOrder
    const entries = Object.entries(
      opportunityData?.data?.data.opportunitiesByStages,
    );
    entries.sort((a, b) => {
      return a[1].stageOrder - b[1].stageOrder;
    });
    //Remove the name, since it is already contained as property
    const stagesList: OpportunityStageListDTO[] = entries.map(
      (entry) => entry[1],
    );

    // undefined is a default value => don't do nothing
    if (savedStagesFromUserPreferences?.length !== undefined) {
      if (savedStagesFromUserPreferences === null) {
        setFilters((prev) => ({
          ...prev,
          stages: null,
        }));
      } else {
        const filteredStagesList = stagesList.filter(
          (stageDTO) =>
            stageDTO.stage &&
            savedStagesFromUserPreferences.includes(stageDTO.stage),
        );

        setFilters((prev) => ({
          ...prev,
          stages: filteredStagesList,
        }));
      }
    }

    setStages(stagesList);
  }, [opportunityData, savedStagesFromUserPreferences]);

  // Functions ---
  // const handleChangeYear = (value: number) => {
  //   if(year){
  //     const newYear = year + value;
  //     if (newYear <= new Date().getFullYear()) {
  //       setYear(newYear);
  //     }
  //   }
  // };

  const changeFilters = (filters: PickedOpportunityFilters) => {
    setFilters(filters);

    if (!filters.stages) {
      // we don't have selected stages and it means that we want to show all
      setSavedStagesFromUserPreferences(null);
    } else {
      const stagesToSave = filters.stages
        .map((stage) => stage.stage)
        .filter((s): s is string => typeof s === 'string');

      setSavedStagesFromUserPreferences(stagesToSave);
    }
  };

  //Increase the limit of the stage passed
  const increaseStageLimit = (stage: string | null) => {
    let newStageLimits = stageLimits ?? [];
    //Initialize the limits if not present
    if (!stageLimits) {
      newStageLimits = stages.map((stage) => {
        return {
          stage: stage.stage,
          limit: FeConstants.opportunityStageLimit,
        };
      });
    }
    newStageLimits = newStageLimits.map((stageLimit) => {
      if (stageLimit.stage === stage) {
        return {
          stage: stage,
          limit: stageLimit.limit + FeConstants.opportunityStageLimit,
        };
      } else {
        return stageLimit;
      }
    });
    setStageLimits(newStageLimits);
  };

  const handleDragEnd = (result: DropResult) => {
    //If dropped outside the lists
    if (!result.destination) return;
    const destinationId = result.destination.droppableId;
    const sourceId = result.source.droppableId;
    //If dropped in the same list
    if (destinationId === sourceId) return;
    //Get the opportunity
    const opportunityId = result.draggableId;
    const movedOpportunity = stages
      .find((stage) => stage.stage === sourceId)
      ?.opportunities?.find(
        (opportunity) => opportunity.objectId === opportunityId,
      );
    //If the moved opportunity is not yours return and show the error in the snackbar
    if (!movedOpportunity?.owned) {
      setShowErrorOpportunityNotYours(true);
      setTimeout(() => {
        setShowErrorOpportunityNotYours(false);
      }, 3000);
      return;
    }
    if (
      !movedOpportunity ||
      (!movedOpportunity.accountId && !movedOpportunity.account?.objectId)
    )
      return;
    //Perform the patch
    patchOpportunity({
      accountId:
        movedOpportunity.accountId ?? movedOpportunity.account?.objectId ?? '',
      opportunityId: movedOpportunity.objectId,
      stage: destinationId,
    });
    if (analytics) {
      analytics.track('patch-deal', {
        field: 'stage',
      });
    }
    //Update locally
    const newStages = [...stages];
    const sourceStage = newStages.find((stage) => stage.stage === sourceId);

    if (!sourceStage) return;
    const destinationStage = newStages.find(
      (stage) => stage.stage === destinationId,
    );
    if (!destinationStage) return;
    //Remove from source
    sourceStage.opportunities =
      sourceStage.opportunities?.filter(
        (opportunity) => opportunity.objectId !== opportunityId,
      ) ?? null;
    //Add to destination
    destinationStage.opportunities?.push(movedOpportunity);
    //Order per createdDate to reflect what will come from the BE
    destinationStage.opportunities =
      destinationStage.opportunities?.sort((a, b) =>
        a.createdDate < b.createdDate ? 1 : -1,
      ) ?? null;
    setStages(newStages);
  };

  return (
    <OpportunitiesWrapper>
      {showModal && (
        <AddOpportunityContainer close={() => setShowModal(false)} />
      )}
      {filtersVisible && (
        <BlurBackgroundDivFixed>
          <FilterModalContainer
            style={{ padding: '18px 0', maxHeight: '90%', height: 'auto' }}
          >
            <CloseTabButton
              close={() => setFiltersVisible(false)}
              style={{ marginRight: '18px' }}
            />
            <div className="add-main-container">
              <div className="modal-header">
                <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
                  Filter deals:
                </Typography>
              </div>

              <FilterOpportunities
                selectedPipelineId={selectedPipelineId}
                close={() => setFiltersVisible(false)}
                handleSubmit={changeFilters}
                pickedFilters={filters ?? {}}
              />
            </div>
          </FilterModalContainer>
        </BlurBackgroundDivFixed>
      )}
      <div className="filters-deals-container">
        <div className="filters-deals-part" style={{ gap: 40 }}>
          <CronoSelect
            style={{ height: 40, minWidth: '200px' }}
            value={selectedPipelineId}
            sx={{ fontSize: '12px' }}
            onChange={(event) => {
              setSelectedPipelineId(event.target.value as number);
              // Reset stages
              setFilters((prev) => ({
                ...prev,
                stages: null,
              }));
              setSavedStagesFromUserPreferences(undefined);
            }}
          >
            {pipelines?.data?.data
              .map((pipeline) => {
                return (
                  <CronoMenuItem value={pipeline.id} key={pipeline.id}>
                    {pipeline.publicName}
                  </CronoMenuItem>
                );
              })
              .concat(
                highestRole === Role.MANAGER ? (
                  <>
                    <div
                      style={{
                        borderTop: `1px solid ${colors.grey4}`,
                        width: '100%',
                      }}
                    />
                    <CronoMenuItem
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: colors.grey11,
                        // stroke: colors.grey11
                      }}
                      value={-1}
                      key={'other'}
                      onClick={() =>
                        navigate(PATH.SETTINGS, {
                          state: { tab: SettingsTabs.PipelinesManagement },
                        })
                      }
                    >
                      Manage pipeline
                      <EditPencilIconS color={colors.grey11} />
                    </CronoMenuItem>
                  </>
                ) : (
                  []
                ),
              )}
          </CronoSelect>

          <div style={{ display: 'flex', gap: '8px', marginLeft: '8px' }}>
            <SearchIcon color={colors.grey11} />
            <TextField
              className="text-field"
              variant="standard"
              placeholder="Search..."
              autoComplete="off"
              value={search || ''}
              InputProps={{
                endAdornment:
                  (search || '') === '' ? null : (
                    <CloseMIcon
                      className="remove-text-icon"
                      color={colors.grey11}
                      onClick={() => setSearch('')}
                    />
                  ),
              }}
              sx={{
                width: '100%',
                input: {
                  fontWeight: '400',
                  padding: '4px 0px',
                  '&::placeholder': {
                    opacity: 1,
                    color: colors.grey11,
                  },
                },
              }}
              onChange={(ev) => setSearch(ev.target.value)}
            />
          </div>
        </div>
        <div className="filters-deals-part" style={{ gap: 12 }}>
          {/*<YearSelectorWrapper>*/}
          {/*  <IconLeftArrow*/}
          {/*    className="year-arrow-icon"*/}
          {/*    onClick={() => handleChangeYear(-1)}*/}
          {/*    color={colors.grey11}*/}
          {/*  />*/}
          {/*  <Typography*/}
          {/*    fontSize={12}*/}
          {/*    lineHeight="16px"*/}
          {/*    fontWeight={500}*/}
          {/*    width={"38px"}*/}
          {/*    textAlign={"center"}*/}
          {/*  >*/}
          {/*    {year}*/}
          {/*  </Typography>*/}
          {/*  <IconRightArrow*/}
          {/*    className={`year-arrow-icon ${*/}
          {/*      year === new Date().getFullYear() ? "year-disabled" : ""*/}
          {/*    }`}*/}
          {/*    color={*/}
          {/*      year === new Date().getFullYear() ? colors.grey3 : colors.grey11*/}
          {/*    }*/}
          {/*    onClick={() => handleChangeYear(1)}*/}
          {/*  />*/}
          {/*</YearSelectorWrapper>*/}
          <FlexDiv gap={'0px'} width={'auto'}>
            <Typography
              fontWeight={500}
              lineHeight={'16px'}
              fontSize={12}
              color={colors.grey11}
            >
              My deals
            </Typography>
            <CronoSwitch
              checked={stateOfSwitch}
              onChange={(ev) => {
                setFilters((prev) => ({
                  ...prev,
                  userId: ev.target.checked ? user?.id : null,
                }));
              }}
            />
          </FlexDiv>
          <Button
            variant="outlined"
            className="filter-button"
            endIcon={<TuneRounded />}
            color="secondary"
            onClick={() => setFiltersVisible(true)}
          >
            {appliedFilters ? (
              <span className="number-of">{appliedFilters}</span>
            ) : (
              'Filters'
            )}
          </Button>
          <MainPrimaryButton
            startIcon={
              <PlusIcon
                style={{ width: '16px', height: '16px' }}
                color={colors.white}
              />
            }
            style={{
              height: '40px',
              margin: 0,
              fontSize: 14,
              lineHeight: '18px',
              fontWeight: 500,
            }}
            onClick={() => setShowModal(true)}
          >
            New deal
          </MainPrimaryButton>
        </div>
      </div>
      <div className="columns-deals-container">
        <div className="inner-div-scrollable">
          <DragDropContext onDragEnd={handleDragEnd}>
            {stages.map((stage) => {
              const colorsStage = getColorsStages(
                stage.stageOrder,
                totalNumberOfStages,
              );
              return (
                <StageColumn
                  key={stage.stage}
                  stage={stage}
                  colorsStage={colorsStage}
                  increaseStageLimit={increaseStageLimit}
                />
              );
            })}
          </DragDropContext>
        </div>
      </div>
    </OpportunitiesWrapper>
  );
};

export default Opportunities;
