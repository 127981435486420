import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const AddedTemplateSIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <g id="Icon/Add template">
        <g id="Group">
          <g id="Group 427320481">
            <path
              id="Path"
              d="M1.5 5.74805V3.63694C1.5 3.14582 1.9475 2.74805 2.5 2.74805H8C8.5525 2.74805 9 3.14582 9 3.63694V9.85916C9 10.3503 8.5525 10.748 8 10.748H5.5"
              stroke={color}
              strokeWidth="0.8"
              strokeLinejoin="round"
            />
            <path
              id="Path_2"
              d="M9 7.74805L9 9.85916C9 10.3503 8.5525 10.748 8 10.748L2.5 10.748C1.9475 10.748 1.5 10.3503 1.5 9.85916L1.5 3.63694C1.5 3.14582 1.9475 2.74805 2.5 2.74805L5 2.74805"
              stroke={color}
              strokeWidth="0.8"
              strokeLinejoin="round"
            />
            <path
              id="Path_3"
              d="M3 2.74805V2.13694C3 1.64582 3.4475 1.24805 4 1.24805H9.5C10.0525 1.24805 10.5 1.64582 10.5 2.13694V8.35916C10.5 8.85027 10.0525 9.24805 9.5 9.24805H9"
              stroke={color}
              strokeWidth="0.8"
              strokeLinejoin="round"
            />
            <path
              id="Path_4"
              d="M7.50187 4.49815H3"
              stroke={color}
              strokeWidth="0.8"
              strokeLinejoin="round"
            />
            <path
              id="Path_5"
              d="M7.5 6.49805H3"
              stroke={color}
              strokeWidth="0.8"
              strokeLinejoin="round"
            />
            <path
              id="Path_6"
              d="M6 8.49805H3"
              stroke={color}
              strokeWidth="0.8"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddedTemplateSIcon;
