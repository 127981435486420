import React, { useMemo, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useAuth } from 'context/auth';

import { SubscriptionPlanWrapper } from 'pages/connect/views/main/styles';
import { FeConstants, planFeatures } from 'constants/FeConstants';
import Logo from 'assets/images/logo_crono.png';
import { colors } from 'crono-fe-common/theme';
import useGetSubscriptionProducts from 'hooks/services/subscription/useGetSubscriptionProducts';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import { ReactComponent as CronoHandLogo } from '../../assets/images/Crono-hand-logo.svg';
import useCheckoutSession from 'hooks/services/subscription/useCheckoutSession';
import { SubscriptionPlansBodyWrapper } from './style';
import useGetSubscription from 'hooks/services/subscription/useGetSubscription';
import { useJuneAnalytics } from 'context/june';

import { sortPlans } from 'utils/fe-utils';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { CancelButton } from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import Subscription from 'pages/settings/Subscription/Subscription';
import FeatureListItem from '../signup/planSteps/planType/featureListItem';
import BillingSwitch from '../signup/planSteps/planType/billingSwitch';
import { Plan } from '../signup';
import { cookieDomain, cookies } from 'constants/crono-cookie';

type BillingPlan = 'monthly' | 'yearly';

const SubscriptionPlans = () => {
  const { logout } = useAuth();

  return (
    <SubscriptionPlanWrapper>
      <div className="logo-container">
        <img src={Logo} className="logo" alt="Crono Logo" />
        <CancelButton
          className="logout"
          color="secondary"
          onClick={() => logout()}
        >
          Log out
        </CancelButton>
      </div>

      <div className="horizontal-divider" />
      <Subscription />
    </SubscriptionPlanWrapper>
  );
};

export interface IProps {
  style?: any;
  title: any;
  subtitle: any;
  initialQuantity?: number;
  containerHeight?: string;
}

export const SubscriptionPlansCard = ({
  style,
  title,
  subtitle,
  containerHeight = '270px',
}: IProps) => {
  const { user: userMe } = useAuth();

  const analytics = useJuneAnalytics();

  const { data: subscriptionDataDb, isLoading: loadingSubscriptionDataDb } =
    useGetSubscription();

  const subscriptionData = useMemo(() => {
    if (subscriptionDataDb?.data?.data) {
      return subscriptionDataDb.data.data;
    }
    return null;
  }, [subscriptionDataDb]);

  const activeLicenses = useMemo(() => {
    return subscriptionData?.users?.length ?? 0;
  }, [subscriptionData]);

  const [billingPlan, setBillingPlan] = useState<BillingPlan>('yearly');
  const { data: subscriptionProducts, isLoading: loadingSubscriptionProducts } =
    useGetSubscriptionProducts();
  const options = useMemo(() => {
    const plans = (subscriptionProducts?.data?.data ?? [])
      ?.filter(
        (plan) => plan.subscriptionType !== SubscriptionPlanType.FREE_TRIAL,
      )
      .map((plan) => {
        return {
          ...plan,
          features:
            planFeatures[plan.subscriptionType as keyof typeof planFeatures],
        };
      });
    return sortPlans(plans);
  }, [subscriptionProducts]);

  const { mutateAsync: callCheckout, isLoading: callingCheckout } =
    useCheckoutSession();

  const handleCheckout = (priceId: string, quantity: number) => {
    callCheckout({ priceId, quantity }).then((res) => {
      const url = res.data?.data.url;
      if (url) {
        if (analytics) {
          const plan = options.find((plan) => {
            const price = plan?.prices.find((price) => price?.id === priceId);
            return price;
          });
          //Set the thank-you cookie to show the thank you page after the payment
          cookies.set(FeConstants.cronoThankYouCookie, '1', {
            domain: cookieDomain,
            path: '/',
            //Expires in 20 minutes
            expires: new Date(Date.now() + 20 * 60 * 1000),
            sameSite: 'none',
            secure: true,
          });

          analytics.track('user-checkout-created', {
            priceId,
            billingPlan,
            quantity: quantity,
            plan: plan?.subscriptionType,
          });
        }
        window.open(url, '_self');
      }
    });
  };

  const handleHasToBeDisabled = (
    currentPlan: SubscriptionPlanType | null | undefined,
    plan: SubscriptionPlanType,
  ) => {
    if (!currentPlan) return false;
    if (
      currentPlan === SubscriptionPlanType.FREE_TRIAL ||
      currentPlan === SubscriptionPlanType.STARTER
    )
      return false;
    if (currentPlan === SubscriptionPlanType.PRO) {
      if (plan === SubscriptionPlanType.STARTER) return true;
    }
    if (currentPlan === SubscriptionPlanType.ULTRA) {
      if (plan === SubscriptionPlanType.STARTER) return true;
      if (plan === SubscriptionPlanType.PRO) return true;
    }
    return false;
  };

  const [expanded, setExpanded] = useState<boolean>(false);

  if (!userMe) return null;

  return (
    <SubscriptionPlansBodyWrapper style={style}>
      {loadingSubscriptionProducts ? (
        <FlexDiv>
          <CircularProgress />
        </FlexDiv>
      ) : (
        <>
          <div className="suggestion-container">
            <div className="title">
              <Typography
                fontSize={'24px'}
                fontWeight={700}
                lineHeight={'30px'}
              >
                {title}
              </Typography>
              <Typography
                fontSize={'14px'}
                lineHeight={'24px'}
                color={colors.grey11}
              >
                {subtitle}
              </Typography>
            </div>
            <BillingSwitch
              options={options}
              selectedPlan={Plan.STARTER}
              billingPlan={billingPlan}
              setBillingPlan={setBillingPlan}
            />
          </div>
          <div
            className={`suggestion-container ${expanded ? '' : 'blur'}`}
            style={{
              overflow: 'hidden',
              height: expanded ? 'fit-content' : containerHeight,
            }}
          >
            {options.map((plan) => {
              const isUltra =
                plan.subscriptionType === SubscriptionPlanType.ULTRA;
              const price = plan?.prices.filter(
                (price) => price.paymentCycle === billingPlan,
              )[0]?.monthlyTotalCycle;
              const priceId = plan?.prices.filter(
                (price) => price.paymentCycle === billingPlan,
              )[0]?.id;
              const badge =
                plan.subscriptionType === SubscriptionPlanType.PRO ? (
                  <span className="badge popular-plan">Popular</span>
                ) : plan.subscriptionType === SubscriptionPlanType.ULTRA ? (
                  <span className="badge ultra-plan">more than 5 users</span>
                ) : null;
              const currentPlan =
                userMe?.subscriptionType === plan.subscriptionType;
              const disableDueToDowngrade = handleHasToBeDisabled(
                userMe?.subscriptionType,
                plan.subscriptionType,
              );
              return (
                <div
                  className={`plan ${currentPlan ? 'selected' : ''} ${
                    disableDueToDowngrade ? 'disable-plan' : ''
                  }`}
                  key={plan?.id}
                >
                  <div className={`plan-header ${plan.subscriptionType}`}>
                    <Typography
                      fontSize={'18px'}
                      fontWeight={700}
                      lineHeight={'24px'}
                    >
                      {plan.subscriptionType}
                      {currentPlan ? (
                        <span className="badge current-plan">Current plan</span>
                      ) : (
                        badge
                      )}
                    </Typography>
                    <div className="plan-pricing">
                      <Typography
                        fontSize={'40px'}
                        fontWeight={600}
                        lineHeight={'40px'}
                      >
                        €{price}
                      </Typography>
                      <div className="plan-billing">
                        <Typography
                          fontSize={'14px'}
                          fontWeight={400}
                          lineHeight={'24px'}
                          color={
                            plan.subscriptionType ===
                            SubscriptionPlanType.STARTER
                              ? colors.grey1
                              : colors.primaryLight
                          }
                        >
                          User / month
                        </Typography>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={500}
                          lineHeight={'24px'}
                          color={
                            plan.subscriptionType ===
                            SubscriptionPlanType.STARTER
                              ? colors.black
                              : colors.white
                          }
                        >
                          billed{' '}
                          {billingPlan === 'yearly' ? 'annually' : 'monthly'}
                        </Typography>
                      </div>
                    </div>
                    {isUltra ? (
                      <div className="ultra-logo-container">
                        <CronoHandLogo />
                      </div>
                    ) : null}

                    <div
                      className={`upgrade-button ${
                        plan.subscriptionType
                      }-upgrade-button ${
                        callingCheckout ? 'disable-due-to-upgrade' : ''
                      }`}
                      onClick={() => {
                        if (
                          priceId &&
                          !disableDueToDowngrade &&
                          !callingCheckout
                        )
                          handleCheckout(priceId, activeLicenses);
                      }}
                    >
                      <Typography
                        fontSize={'16px'}
                        fontWeight={500}
                        lineHeight={'24px'}
                      >
                        {disableDueToDowngrade
                          ? 'Downgrade'
                          : currentPlan
                            ? 'Renew'
                            : 'Upgrade'}
                      </Typography>
                    </div>
                  </div>
                  <div className="plan-footer">
                    {plan.features.map((feature, i) => {
                      const firstFeature =
                        i === 0 &&
                        plan.subscriptionType !== SubscriptionPlanType.STARTER;

                      return (
                        <FeatureListItem
                          type={plan.subscriptionType}
                          feature={feature}
                          firstFeature={firstFeature}
                          key={i}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="compare-features"
            onClick={() => setExpanded((prev) => !prev)}
          >
            <Typography fontSize={'14px'} fontWeight={500}>
              Compare features
            </Typography>
            <BottomArrow
              style={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
        </>
      )}
    </SubscriptionPlansBodyWrapper>
  );
};

export default SubscriptionPlans;
