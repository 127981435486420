import { Account } from './account';
import { Prospect } from './prospect';

export interface LogLinkedin {
  id: number;
  taskTodoId: number | null;
  suggestionId: number | null;
  accountId: string;
  prospectId: string;
  content: string;
  subject: string | null;
  linkedinType: LinkedinType;
  eventDatetime: Date;
  isAnswered: boolean;
  templateId: number | null;
  prospect?: Prospect | null;
  account?: Account;
  owner?: string;
  templateTitle?: string | null;
}

export enum LinkedinType {
  Message = 'Message',
  Invitation = 'Invitation',
  Conversation = 'Conversation',
  Reply = 'Reply',
  InMail = 'InMail',
  VoiceNote = 'VoiceNote',
}
