import { CheckCircleRounded, RadioButtonUnchecked } from '@mui/icons-material';
import {
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { FeConstants } from 'constants/FeConstants';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import {
  CallFeedback,
  getCallFeedbackString,
} from 'crono-fe-common/types/logCall';
import { CallLogModalWrapper } from './style';

interface IProps {
  close: () => void;
  selectedCallFeedback: CallFeedback | null;
  handleChangeNote: (note: string) => void;
  handleChangeFeedback: (feedback: CallFeedback) => void;
  note: string;
  isLoggingCall: boolean;
  handleLogClick: () => void;
}

const CallLogModal = ({
  close,
  selectedCallFeedback,
  handleChangeNote,
  handleChangeFeedback,
  note,
  isLoggingCall,
  handleLogClick,
}: IProps) => {
  return (
    <CallLogModalWrapper>
      <div className="call-log-container">
        <FlexDiv
          direction="column"
          alignItems="start"
          height="fit-content"
          style={{ gap: 20 }}
        >
          <Typography fontSize={'24px'} fontWeight={700}>
            Call Feedback
          </Typography>
          <FlexDiv
            justifyContent="start"
            alignItems="center"
            className="quick-actions"
          >
            {FeConstants.callFeedback.map((cf) => {
              return (
                <div
                  className={
                    'status badge ' +
                    (cf.type !== selectedCallFeedback
                      ? 'status-not-selected-'
                      : 'status-') +
                    getCallFeedbackString(cf.type)
                  }
                  style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 8,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                  key={cf.id}
                  onClick={() => handleChangeFeedback(cf.type)}
                >
                  {cf.title}
                  <Checkbox
                    icon={
                      <RadioButtonUnchecked
                        className={
                          'status-not-selected-checkbox-' +
                          getCallFeedbackString(cf.type)
                        }
                      />
                    }
                    checkedIcon={
                      <CheckCircleRounded
                        sx={{ color: 'white', cursor: 'initial' }}
                      />
                    }
                    checked={selectedCallFeedback === cf.type}
                    sx={{ padding: 0 }}
                  />
                </div>
              );
            })}
          </FlexDiv>
        </FlexDiv>

        <TextField
          multiline
          onChange={(e) => handleChangeNote(e.target.value)}
          value={note}
          rows={5}
          placeholder="Add call description (optional)..."
          className="add-note-text-area"
          sx={{
            '& fieldset': { border: 'none' },
          }}
        />

        <FlexDiv
          width="fit-content"
          alignItems="center"
          style={{ alignSelf: 'flex-end' }}
        >
          <CancelButton
            onClick={close}
            style={{
              width: '120px',
              height: '48px',
            }}
            type="button"
          >
            Cancel
          </CancelButton>

          {isLoggingCall ? (
            <CircularProgress />
          ) : (
            <MainButton
              onClick={handleLogClick}
              style={{
                width: '120px',
                height: '48px',
              }}
            >
              Log Call
            </MainButton>
          )}
        </FlexDiv>
      </div>
    </CallLogModalWrapper>
  );
};

export default CallLogModal;
