import { AddToStrategyExternalPropertyTooltip } from 'pages/settings/Strategy/strategyCreation';
import React, { useMemo, useState } from 'react';

import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { ClickAwayListener, Tooltip, Typography } from '@mui/material';
import AddTagTooltip from '../tagTooltip';
import ExternalPropertyComponentRemovable from 'crono-fe-common/components/ExternalPropertyRemovable';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import {
  ensureArray,
  getExternalPropertyLabels,
  getNewOptionsWhenSelecting,
  getNewOptionsWhenSelectingWithStrings,
  getOptionsMultiselect,
  setOptionsMultiselect,
} from 'crono-fe-common/utils';
import { MultipleTagWrapper } from './style';
import { maxWidth, width } from '@mui/system';

export interface TagToShow {
  externalPropertyId: number;
  option: string;
}
interface IProps {
  indexOfTag: number;
  handleClickTag?: (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => void;
  strategyTagValue?: TagToShow | null;
  isEditable: boolean;
  option: string | null;
  tag: ExternalProperty;
  indexGeneralArray: number;
  showRemovable?: boolean;
  showAsPlus?: boolean;
  showMandatoryStar?: boolean;
  whiteBackground?: boolean;
  //If this is true for multiselect, if there is more than 1 option, it will show the first 2 and a plus
  show2AndPlus?: boolean;

  customStyle?: any;
}

export const isString = (value: string | string[] | null): value is string =>
  typeof value === 'string';

const TagsBoxSingleTagShowAll = ({
  indexOfTag,
  handleClickTag,
  isEditable,
  strategyTagValue,
  option,
  tag,
  indexGeneralArray,
  showRemovable = true,
  showAsPlus = false,
  showMandatoryStar = false,
  whiteBackground = false,
  show2AndPlus = false,
  customStyle,
}: IProps) => {
  const isMultiple = tag.valueType === 'MultiSelect';
  const showMore = show2AndPlus && isMultiple;
  const parsedOptions = useMemo(() => {
    if (isMultiple) {
      return option?.split(',') ?? null;
    }
    return option;
  }, [option, isMultiple]);

  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const open = useMemo(() => {
    return openTooltip && isEditable;
  }, [openTooltip, isEditable]);

  const [searchTagValue, setSearchTagValue] = useState<string>('');

  const tagToShow: ExternalProperty & { indexMatch: number[] } = useMemo(() => {
    //If there is no search just display all the tags
    if (!searchTagValue)
      return {
        ...tag,
        indexMatch: Array.from(
          { length: tag.options?.length ?? 0 },
          (_, index) => index,
        ),
      };

    return {
      ...tag,
      indexMatch: tag.labels
        ?.map((label, index) => {
          const bool = label
            .toLowerCase()
            .includes(searchTagValue.toLowerCase());
          if (bool) return index;
          return null;
        })
        .filter((val) => val !== null) as number[],
    };
  }, [searchTagValue, tag]);

  const getLabel = (option: string) => {
    return getExternalPropertyLabels(tag)[
      tag.options?.findIndex((property) => property === option) ?? 0
    ];
  };

  const firstTagForShowMore = useMemo(() => {
    if (!showMore) return null;
    //Considering the order of the options in the tagToShow, show the first 1
    const firstOption = tagToShow.options?.find((option) =>
      ensureArray(parsedOptions).includes(option),
    );
    const isThereSecondOption = parsedOptions?.[1] !== undefined;
    return (
      <ExternalPropertyComponentRemovable
        index={indexOfTag}
        disabled={!isEditable}
        value={firstOption ? getLabel(firstOption) : null}
        tooltipValue={firstOption ? getLabel(firstOption) : null}
        indexOption={
          firstOption ? tagToShow?.options?.indexOf(firstOption) : undefined
        }
        onClick={() => {
          setOpenTooltip(true);
        }}
        onClickRemove={() => {
          if (handleClickTag) {
            handleClickTag(
              firstOption === undefined ? null : firstOption,
              tagToShow,
              indexOfTag,
            );
          }
        }}
        customStyle={{
          ...(whiteBackground
            ? {
                backgroundColor: whiteBackground ? 'white' : undefined,
                borderRadius: whiteBackground ? 8 : undefined,
              }
            : {}),
          ...(!isThereSecondOption
            ? {
                maxWidth: 250,
              }
            : { maxWidth: 140 }),
          ...customStyle,
        }}
        showRemove={showRemovable && isEditable}
        nullValueText={
          showAsPlus ? null : (
            <span className="null-value-text">
              <Typography
                fontSize={'12px'}
                lineHeight={'20px'}
                style={{ flex: 1 }}
                noWrap
              >
                {tagToShow.publicName ??
                  tagToShow.externalName ??
                  'Property ' + (indexOfTag + 1)}
                {showMandatoryStar && tagToShow.isMandatory && '*'}
              </Typography>
              {open ? (
                <TopArrowIcon className="tag-arrow" />
              ) : (
                <BottomArrowIcon className="tag-arrow" />
              )}
            </span>
          )
        }
        isBeingModified={open}
        searchTagValue={searchTagValue}
        setSearchTagValue={setSearchTagValue}
        onEsc={() => {
          setSearchTagValue('');
          setOpenTooltip(false);
        }}
        onEnter={() => {
          if (tagToShow.indexMatch.length <= 0) return;
          const option = tagToShow.options![tagToShow.indexMatch[0]];
          if (handleClickTag) {
            handleClickTag(option, tagToShow, indexOfTag);
            setSearchTagValue('');
            setOpenTooltip(false);
          }
        }}
      />
    );
  }, [showMore, parsedOptions, open, searchTagValue, tagToShow]);

  const secondTagForShowMore = useMemo(() => {
    if (!showMore) return null;
    //Considering the order of the options in the tagToShow, show the second one
    const firstOption = tagToShow.options?.find((option) =>
      ensureArray(parsedOptions).includes(option),
    );
    const secondOption = tagToShow.options?.find(
      (option) =>
        option !== firstOption && ensureArray(parsedOptions).includes(option),
    );
    if (secondOption === undefined) return null;
    return (
      <ExternalPropertyComponentRemovable
        index={indexOfTag}
        disabled={!isEditable}
        value={getLabel(secondOption)}
        tooltipValue={getLabel(secondOption)}
        indexOption={tagToShow?.options?.indexOf(secondOption)}
        onClick={() => {
          setOpenTooltip(true);
        }}
        onClickRemove={() => {
          if (handleClickTag) {
            handleClickTag(secondOption, tagToShow, indexOfTag);
          }
        }}
        customStyle={{
          ...(whiteBackground
            ? {
                backgroundColor: whiteBackground ? 'white' : undefined,
                borderRadius: whiteBackground ? 8 : undefined,
              }
            : {}),
          maxWidth: 140,
        }}
        showRemove={showRemovable && isEditable}
      />
    );
  }, [showMore, parsedOptions, tagToShow]);

  const additionalTags = useMemo(() => {
    if (!showMore || !parsedOptions) return null;
    if (isString(parsedOptions) || parsedOptions.length <= 2) return null;
    const firstOption = tagToShow.options?.find((option) =>
      ensureArray(parsedOptions).includes(option),
    );
    const secondOption = tagToShow.options?.find(
      (option) =>
        option !== firstOption && ensureArray(parsedOptions).includes(option),
    );
    const tagToShowOptions =
      tagToShow.options
        ?.filter(
          (option) =>
            option !== firstOption &&
            option !== secondOption &&
            ensureArray(parsedOptions).includes(option),
        )
        .map((option) => getLabel(option))
        .join(', ') ?? '';
    return (
      <Tooltip
        title={<div>{tagToShowOptions}</div>}
        arrow
        enterDelay={400}
        enterNextDelay={400}
      >
        <div
          className="remaining-tags-button"
          onClick={() => {
            setOpenTooltip(true);
          }}
        >
          +{parsedOptions.length - 2}
        </div>
      </Tooltip>
    );
  }, [showMore, parsedOptions]);

  return showMore && !isString(parsedOptions) ? (
    <ClickAwayListener
      onClickAway={() => {
        setSearchTagValue('');
        setOpenTooltip(false);
      }}
    >
      <div
        style={{
          display: 'flex',
          width: 'fit-content',
        }}
      >
        <AddToStrategyExternalPropertyTooltip
          title={
            <AddTagTooltip
              toShow={tagToShow}
              handleClick={(option, externalProperty, index) => {
                setSearchTagValue('');
                if (handleClickTag) {
                  handleClickTag(option, externalProperty, indexGeneralArray);
                }
                setOpenTooltip(false);
              }}
              indexExternalProperty={indexOfTag}
              currentOption={parsedOptions}
              useEmptySelection={true}
            />
          }
          open={open}
          onClose={() => setOpenTooltip(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          sx={{
            zIndex: '5001 !important',
          }}
        >
          <MultipleTagWrapper>
            {firstTagForShowMore && firstTagForShowMore}
            {secondTagForShowMore && secondTagForShowMore}
            {additionalTags && additionalTags}
          </MultipleTagWrapper>
        </AddToStrategyExternalPropertyTooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <ClickAwayListener
      onClickAway={() => {
        setSearchTagValue('');
        setOpenTooltip(false);
      }}
    >
      <div>
        <AddToStrategyExternalPropertyTooltip
          title={
            <AddTagTooltip
              toShow={tagToShow}
              handleClick={(option, externalProperty, index) => {
                setSearchTagValue('');
                if (handleClickTag) {
                  handleClickTag(option, externalProperty, indexGeneralArray);
                }
                setOpenTooltip(false);
              }}
              indexExternalProperty={indexOfTag}
              currentOption={parsedOptions}
              useEmptySelection={isMultiple}
            />
          }
          open={open}
          onClose={() => setOpenTooltip(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          sx={{
            zIndex: '5001 !important',
          }}
        >
          <ExternalPropertyComponentRemovable
            index={indexOfTag}
            disabled={!isEditable}
            value={
              parsedOptions
                ? isString(parsedOptions)
                  ? getLabel(parsedOptions)
                  : parsedOptions.length === 1
                    ? getLabel(parsedOptions[0])
                    : `${parsedOptions.length} ${tag.publicName}`
                : null
            }
            tooltipValue={
              parsedOptions
                ? isString(parsedOptions)
                  ? getLabel(parsedOptions)
                  : parsedOptions.length === 1
                    ? getLabel(parsedOptions[0])
                    : parsedOptions.map((option) => getLabel(option)).join(', ')
                : null
            }
            indexOption={ensureArray(parsedOptions).map((singleOption) =>
              tagToShow?.options?.indexOf(singleOption ?? ''),
            )}
            onClick={() => {
              setOpenTooltip(true);
            }}
            onClickRemove={() => {
              if (handleClickTag) {
                handleClickTag(null, tagToShow, indexOfTag);
              }
            }}
            customStyle={{
              ...(whiteBackground
                ? {
                    backgroundColor: whiteBackground ? 'white' : undefined,
                    borderRadius: whiteBackground ? 8 : undefined,
                  }
                : {}),
              ...customStyle,
            }}
            showRemove={showRemovable && isEditable}
            nullValueText={
              showAsPlus ? null : (
                <span className="null-value-text">
                  <Typography
                    fontSize={'12px'}
                    lineHeight={'20px'}
                    style={{ flex: 1 }}
                    noWrap
                  >
                    {tagToShow.publicName ??
                      tagToShow.externalName ??
                      'Property ' + (indexOfTag + 1)}
                    {showMandatoryStar && tagToShow.isMandatory && '*'}
                  </Typography>
                  {open ? (
                    <TopArrowIcon className="tag-arrow" />
                  ) : (
                    <BottomArrowIcon className="tag-arrow" />
                  )}
                </span>
              )
            }
            isBeingModified={open}
            searchTagValue={searchTagValue}
            setSearchTagValue={setSearchTagValue}
            onEsc={() => {
              setSearchTagValue('');
              setOpenTooltip(false);
            }}
            onEnter={() => {
              if (tagToShow.indexMatch.length <= 0) return;
              const option = tagToShow.options![tagToShow.indexMatch[0]];
              if (handleClickTag) {
                handleClickTag(option, tagToShow, indexOfTag);
                setSearchTagValue('');
                setOpenTooltip(false);
              }
            }}
          />
        </AddToStrategyExternalPropertyTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default TagsBoxSingleTagShowAll;
