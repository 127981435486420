import useGetUsers from 'hooks/services/subscription/useGetUsers';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Column } from '@ant-design/charts';
import {
  SuggestionOverallLegend,
  colorsSuggestionOverall,
} from '../legendData';
import { calcWidth, numberOfGroups, selectChartContainerClass } from '../model';
import { Typography } from '@mui/material';
import Legend from '../legend';
import { AnalyticsSuggestion } from 'crono-fe-common/types/analyticsSuggestion';
import { GraphData } from '../tabsControllers/model';
import { BarChartPlaceholder } from './chartsPlaceholders';

interface IProps {
  suggestionAnalytics: AnalyticsSuggestion | undefined;
}

const SuggestionOverviewChart = ({
  suggestionAnalytics: taskAnalytics,
}: IProps) => {
  const { data: users } = useGetUsers();

  const [maxValue, setMaxValue] = useState<number>(0);

  const dataGeneral = useMemo(() => {
    if (!users || !taskAnalytics) return [];
    const userMapping = new Map<number, string>();
    users?.data?.data?.forEach((user) => {
      userMapping.set(user.id, user.firstName + ' ' + user.lastName);
    });

    let newValue = 0;

    const newData: GraphData[] = [];
    taskAnalytics?.overview.AnalyticsPendingSuggestions?.forEach((pending) => {
      const user = userMapping.get(pending.userId);
      if (!user) return;
      if (pending.count > newValue) newValue = pending.count;
      newData.push({
        label: user,
        value: pending.count,
        type: 'Pending',
      });
    });
    taskAnalytics?.overview.AnalyticsCompletedSuggestions?.forEach(
      (completed) => {
        const user = userMapping.get(completed.userId);
        if (!user) return;
        if (completed.count > newValue) newValue = completed.count;
        newData.push({
          label: user,
          value: completed.count,
          type: 'Completed',
        });
      },
    );
    taskAnalytics?.overview.AnalyticsDeletedSuggestions?.forEach((deleted) => {
      const user = userMapping.get(deleted.userId);
      if (!user) return;
      if (deleted.count > newValue) newValue = deleted.count;
      newData.push({
        label: user,
        value: deleted.count,
        type: 'Deleted',
      });
    });

    setMaxValue(newValue);

    return newData;
  }, [taskAnalytics, users]);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const configGeneral = useMemo(() => {
    return {
      data: dataGeneral,
      xField: 'label',
      yField: 'value',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index =
          SuggestionOverallLegend.findIndex((legend) => legend === type) ?? 0;
        return colorsSuggestionOverall[index];
      }) as any,
      autoFit: false,
      height: 300,
      width: calcWidth(
        dataGeneral,
        180,
        chartContainerRef.current?.offsetWidth,
      ),
      meta: {
        value: {
          min: 0,
          max: maxValue * 1.2,
        },
      },
      isGroup: true,
      columnStyle: {},
      renderer: 'svg' as 'svg' | 'canvas' | undefined,
      //If only one ground is present, the graph doesn't work if dodgePadding is set
      dodgePadding: numberOfGroups(dataGeneral) > 1 ? 6 : undefined,

      maxColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      minColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,

      legend: false as any,
      randomVal: Math.random() * 10000,
    };
  }, [dataGeneral, chartContainerRef.current?.offsetWidth, maxValue]);

  return (
    <>
      <Typography className="chart-title">Overall Activity</Typography>
      <div
        className={selectChartContainerClass(dataGeneral)}
        ref={chartContainerRef}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 40,
            position: 'sticky',
            left: 0,
          }}
        >
          <Legend
            colors={colorsSuggestionOverall}
            fields={SuggestionOverallLegend}
          />
        </div>
        {configGeneral?.data && configGeneral.data.length ? (
          <Column {...configGeneral} key={configGeneral.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <BarChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default SuggestionOverviewChart;
