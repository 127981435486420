import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export interface FilesInput {
  files: {
    name: string;
    content: string;
  }[];
}

export default function useUploadFiles() {
  const uploadFiles = (templateImages: FilesInput) => {
    const request: Request = {
      url: `${ENDPOINTS.file.main}`,
      config: {
        method: 'post',
        data: templateImages,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<string[]>,
    CronoError,
    FilesInput
  >(uploadFiles, {
    onSettled: () => {},
  });

  return { data, mutate, ...rest };
}
