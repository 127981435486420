import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const OpportunityBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
  border: 1px solid ${colors.grey444};
  border-radius: 8px;
  background: ${colors.white};
  :hover {
    background: ${colors.sequenceCardHover};
  }
  .opportunity-box-row {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
  }
  .three-dots-icon {
    cursor: pointer;
    width: 16px !important;
    height: 16px !important;
  }

  .account-avatar {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    font-size: 14px !important;
    border: 1px solid ${colors.grey4Hover};
    width: 24px !important;
    height: 24px !important;
  }
  .white {
    background: ${colors.white} !important;
  }
  .icon-clock {
    width: 12px !important;
    height: 12px !important;
    margin-top: 2px;
  }
`;
