import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import TaskOverviewChart from '../charts/taskOverviewChart';
import useTaskAnalytics from 'hooks/services/analytics/useTaskAnalytics';
import TaskEmailChart from '../charts/taskEmailChart';
import TaskLinkedinChart from '../charts/taskLinkedinChart';
import TaskCallChart from '../charts/taskCallChart';
import TaskActivityHeatmapDetail from '../charts/taskActivityHeatmapDetail';
import TaskActivityHeatmapOverview from '../charts/taskActivityHeatmapOverview';
import { Grid } from '@mui/material';
import { useCallback, useState } from 'react';

interface IProps {
  filterParams: AnalyticsFilters;
}

const AnalyticTaskTabController = ({ filterParams }: IProps) => {
  const [removeFromCrm, setRemoveFromCrm] = useState<boolean>(false);

  const { data: taskAnaytics } = useTaskAnalytics({
    ...filterParams,
    fromCrm: !removeFromCrm,
  });

  const handleSwitchRemoveFromCrm = useCallback(
    () => setRemoveFromCrm(!removeFromCrm),
    [removeFromCrm],
  );

  return (
    <>
      <TaskOverviewChart
        taskAnalytics={taskAnaytics?.data?.data}
        switchRemoveFromCrmValue={removeFromCrm}
        onSwitchRemoveFromCrm={handleSwitchRemoveFromCrm}
      />
      <TaskEmailChart taskAnalytics={taskAnaytics?.data?.data} />
      <TaskLinkedinChart taskAnalytics={taskAnaytics?.data?.data} />
      <TaskCallChart taskAnalytics={taskAnaytics?.data?.data} />
      <Grid container>
        <Grid item xs={6} style={{ paddingRight: 6 }}>
          <TaskActivityHeatmapOverview
            taskAnalytics={taskAnaytics?.data?.data}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 6 }}>
          <TaskActivityHeatmapDetail
            filterParams={{ ...filterParams, fromCrm: removeFromCrm }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AnalyticTaskTabController;
