import React from 'react';
import { SettingsTooltipWrapper } from './style';
import { Button, Typography } from '@mui/material';
import { ReactComponent as SettingsIcon } from 'crono-fe-common/icons/General-Settings.svg';
import { ReactComponent as LogoutIcon } from 'crono-fe-common/icons/Log-Out.svg';
import { ReactComponent as SupportIcon } from 'crono-fe-common/icons/Support-question-mark-icon.svg';

import PATH from 'routing/path';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/auth';
import LinearDeterminate from 'crono-fe-common/components/LinearProgress';
import { ReactComponent as OnboardingFeaturesIcon } from 'crono-fe-common/icons/Cursor, Select, Hand, Password.svg';
import { CompletedOnboardingFeatures } from 'context/onboardingFeatures/types';
import { colors } from 'crono-fe-common/theme';
import { FeConstants } from 'constants/FeConstants';

interface IProps {
  onboardingFeatures: CompletedOnboardingFeatures | null;
  openOnboardingView: () => void;
}
const OpenSettingsTooltip = ({
  onboardingFeatures,
  openOnboardingView,
}: IProps) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <SettingsTooltipWrapper>
      {onboardingFeatures && (
        <Button
          className="settings-button finish-onboarding"
          color="secondary"
          fullWidth
          endIcon={<OnboardingFeaturesIcon />}
          onClick={openOnboardingView}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontSize={'12px'} fontWeight={500}>
              Finish onboarding
            </Typography>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                color={colors.primaryDark}
              >
                {onboardingFeatures?.percentage ?? 0}%
              </Typography>
              <LinearDeterminate
                value={onboardingFeatures?.percentage ?? 0}
                linearBarSx={{
                  width: '60px',
                }}
              />
            </div>
          </div>
        </Button>
      )}
      <Button
        className="settings-button"
        color="secondary"
        fullWidth
        endIcon={<SettingsIcon />}
        onClick={() => navigate(PATH.SETTINGS)}
      >
        Account settings
      </Button>
      <div className="divider" />
      <Button
        className="settings-button"
        color="secondary"
        fullWidth
        endIcon={<SupportIcon />}
        onClick={() => window.open(FeConstants.helpLinkDefault, '_blank')}
      >
        Support
      </Button>
      <div className="divider" />
      <Button
        className="settings-button"
        color="secondary"
        fullWidth
        endIcon={<LogoutIcon />}
        onClick={() => logout()}
      >
        Log out
      </Button>
    </SettingsTooltipWrapper>
  );
};

export default OpenSettingsTooltip;
