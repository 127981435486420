import React from 'react';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  Checkbox,
  Typography,
  Tooltip,
} from '@mui/material';
import useEditAccount from 'hooks/services/account/useEditAccount';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import {
  ExternalProperty,
  TableType,
} from 'crono-fe-common/types/externalProperty';
import { ExternalValue } from 'crono-fe-common/types/externalValue';
import { NotTagPropertyWrapper } from './style';
import {
  getError,
  getExternalPropertyLabels,
  getExternalPropertyOptions,
} from 'crono-fe-common/utils';
import { useConditionalSnackBar } from 'context/snackbar';
import { colors } from 'crono-fe-common/theme';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import usePatchOpportunity from 'hooks/services/opportunity/usePatchOpportunity';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  type: TableType;
  externalValues:
    | (ExternalValue | { externalPropertyId: number; value: string })[]
    | null;
  externalProperty: ExternalProperty;
  objectId?: string;
  owned: boolean;
  handleSetValue?: (externalPropertyId: number, value: string) => void;
  accountId?: string;
}

const ExternalPropertyNotTag = ({
  type,
  externalValues,
  externalProperty,
  objectId,
  owned,
  handleSetValue,
  accountId,
}: IProps) => {
  const analytics = useJuneAnalytics();

  const externalValue = externalValues?.find(
    (val) => val.externalPropertyId === externalProperty.id,
  );
  // todo manage update message
  const { mutate: updateProspect, error: errorUpdateProspect } =
    useEditProspect();

  const { mutate: updateAccount, error: errorUpdateAccount } = useEditAccount();

  const { mutate: patchOpportunity, error: errorPatchOpportunity } =
    usePatchOpportunity();

  useConditionalSnackBar([
    {
      condition: !!errorUpdateAccount,
      message: getError(errorUpdateAccount) ?? 'Error update company',
      severity: 'error',
    },
    {
      condition: !!errorUpdateProspect,
      message: getError(errorUpdateProspect) ?? 'Error update contact',
      severity: 'error',
    },
    {
      condition: !!errorPatchOpportunity,
      message: getError(errorPatchOpportunity) ?? 'Error update deal',
      severity: 'error',
    },
  ]);

  const handleChange = (externalPropertyId: number, value: string) => {
    if (type === 'Account' && objectId) {
      //I update the account with the new information
      updateAccount({
        accountId: objectId,
        externalValues: {
          [externalPropertyId]: value,
        },
      });
    } else if (type === 'Prospect' && objectId) {
      //I update the prospect with the new information
      updateProspect({
        prospectId: objectId,
        externalValues: {
          [externalPropertyId]: value,
        },
      });
    } else if (type === 'Opportunity' && objectId && accountId) {
      patchOpportunity({
        accountId: accountId,
        opportunityId: objectId,
        externalValues: {
          [externalPropertyId]: value,
        },
      });
      if (analytics) {
        analytics.track('patch-deal', {
          field: 'externalValues',
          property: externalPropertyId,
        });
      }
    } else if (handleSetValue) {
      //Set the value using the function passed
      handleSetValue(externalPropertyId, value);
    }
  };

  switch (externalProperty.valueType) {
    case 'String':
      return (
        <NotTagPropertyWrapper>
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <TextField
              defaultValue={externalValue?.value ?? ''}
              className="text-field field-input"
              variant="standard"
              placeholder="Enter a value"
              autoComplete="off"
              fullWidth
              disabled={!owned}
              InputProps={{
                disableUnderline: true,
              }}
              onKeyDown={(ev: any) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  ev.stopPropagation();
                  handleChange(externalProperty.id, ev.target.value);
                }
              }}
              onBlur={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleChange(externalProperty.id, ev.target.value);
              }}
            />
          </Tooltip>
        </NotTagPropertyWrapper>
      );
    case 'Text':
      return (
        <NotTagPropertyWrapper>
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <TextareaAutosize
              className="text-area"
              maxRows={4}
              disabled={!owned}
              placeholder="Enter a text"
              defaultValue={externalValue?.value || ''}
              onBlur={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleChange(externalProperty.id, ev.target.value);
              }}
            />
          </Tooltip>
        </NotTagPropertyWrapper>
      );
    case 'MultiSelect':
      return (
        <NotTagPropertyWrapper id="multiselect" style={{ width: '100%' }}>
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <Select
              className="field-input"
              value={externalValue?.value?.split(';') || []}
              renderValue={(values) => {
                const labels = getExternalPropertyLabels(externalProperty);
                return (
                  <Typography noWrap>
                    {(values as string[])
                      .filter((value) => value !== '')
                      .map((option) =>
                        labels.at(
                          externalProperty.options?.indexOf(option) ?? 0,
                        ),
                      )
                      .join(', ') || 'Select an option'}
                  </Typography>
                );
              }}
              fullWidth
              size="small"
              multiple
              disabled={!owned}
              name={'' + externalProperty.id}
              onChange={(ev) => {
                handleChange(
                  externalProperty.id,
                  (ev.target.value as string[]).join(';'),
                );
              }}
              MenuProps={{
                style: { zIndex: 35001 },
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    paddingInline: '6px',
                    border: '1px solid #dadde9',
                    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      lineHeight: '16px',
                      height: 'fit-content',
                      padding: '8px',
                      cursor: 'pointer',
                      width: '100%',
                      borderRadius: '8px',
                      '&:hover': {
                        background: colors.primaryLight,
                        color: colors.primary,
                      },
                    },
                    '& .Mui-selected': {
                      color: colors.primary,
                      backgroundColor: 'transparent !important',
                    },
                  },
                },
              }}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                minWidth: '180px !important',
              }}
            >
              {getExternalPropertyLabels(externalProperty).map(
                (label, index) => (
                  <MenuItem
                    key={index}
                    value={
                      externalProperty.options
                        ? externalProperty.options[index]
                        : undefined
                    }
                  >
                    <Checkbox
                      icon={<IconUnchecked />}
                      checkedIcon={<IconChecked color={colors.mainDark} />}
                      checked={
                        (externalProperty.options &&
                          externalProperty.options[index] &&
                          externalValue?.value
                            ?.split(';')
                            .includes(externalProperty.options[index])) ||
                        false
                      }
                      sx={{ padding: '0px 8px 0px 0px !important' }}
                    />
                    {label}
                  </MenuItem>
                ),
              )}
            </Select>
          </Tooltip>
        </NotTagPropertyWrapper>
      );
    case 'Numeric':
      return (
        <NotTagPropertyWrapper>
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <TextField
              type="number"
              defaultValue={externalValue?.value}
              className="text-field field-input"
              placeholder="Enter a numeric value"
              variant="standard"
              disabled={!owned}
              fullWidth
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
              }}
              onKeyDown={(ev: any) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  ev.stopPropagation();
                  handleChange(externalProperty.id, ev.target.value);
                }
              }}
              onBlur={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleChange(externalProperty.id, ev.target.value);
              }}
            />
          </Tooltip>
        </NotTagPropertyWrapper>
      );
    case 'List':
      // Rendered value should be the same as the label
      const value = externalValue?.value || '';
      const labelIndex =
        getExternalPropertyOptions(externalProperty).findIndex(
          (val) => val === value,
        ) ?? -1;
      const selectedLabel =
        getExternalPropertyLabels(externalProperty)[labelIndex];

      return (
        //This is a trick to for the component to rerender correctly
        <NotTagPropertyWrapper key={selectedLabel}>
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <CronoAutocomplete
              className="field-input no-padding"
              value={selectedLabel}
              fullWidth
              size="small"
              onChange={(ev, value) =>
                handleChange(externalProperty.id, value as string)
              }
              options={externalProperty.options || []}
              disabled={!owned}
              placeholder="Add information..."
              slotProps={{
                popper: {
                  sx: { zIndex: '35001 !important' },
                },
              }}
              renderOption={(props, option) => {
                const label = getExternalPropertyLabels(externalProperty);
                return (
                  <li {...props} value={option} className="autocomplete-option">
                    {label[externalProperty.options?.indexOf(option) || 0]}
                  </li>
                );
              }}
              sx={{
                boxShadow: 'none',
                '& .MuiInputBase-sizeSmall': {
                  borderRadius: '8px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  padding: '0',
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              }}
              disablePortal={false}
            />
          </Tooltip>
        </NotTagPropertyWrapper>
      );
    case 'Boolean':
      return (
        <NotTagPropertyWrapper>
          <Tooltip
            arrow
            title={owned ? '' : 'You are not the owner'}
            placement="bottom"
          >
            <CronoSwitch
              checked={externalValue?.value === 'true' ? true : false}
              onChange={(ev) =>
                handleChange(
                  externalProperty.id,
                  ev.target.checked ? 'true' : 'false',
                )
              }
              disabled={!owned}
            />
          </Tooltip>
        </NotTagPropertyWrapper>
      );
    default:
      return <></>;
  }
};

export default ExternalPropertyNotTag;
