import styled from 'styled-components';
import { colors } from 'crono-fe-common/theme';
import Switch from '@mui/material/Switch';

export const SettingsWrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px;
  width: 100%;
  border-radius: 16px;
  overflow: auto;
  flex: 1;

  .general-wrapper .MuiBox-root {
    padding: 0;
  }

  .MuiGrid-item > div > .MuiBox-root {
    padding: 0;
  }
  .tabs-container {
    padding: 20px 20px 0px 20px;
    height: 100%;
    width: 300px;
  }
  .tab-content-container {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
  }

  .settings-card {
    align-items: start;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: 100%;
    width: 100%;
  }

  .settings-tab {
    text-transform: unset;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    min-height: unset !important;
    border-radius: 16px;
    padding: 15px 20px;
    text-align: start;
  }
  .settings-tab > svg {
    min-width: 24px;
  }

  @media (min-width: 600px) {
    .tabs-container {
      border-right: 3px solid ${colors.background};
    }
  }

  .settings-grid {
    height: 100%;
  }
`;

export const GeneralWrapper = styled.div`
  display: flex;
  flex-flow: wrap;

  .mail-button-container {
    margin-left: 20px;
    margin-bottom: 40px;
  }

  .mail-button {
    width: 120px;
    position: absolute;
    bottom: 8px;
    left: 8px;
  }

  .tox {
    border-bottom: 0px solid red !important;
    overflow: hidden;
  }

  .form-control {
    flex-basis: 100%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
  }

  .form-control label {
    display: block;
    margin-bottom: 10px;
  }

  .form-control Input {
    display: block;
    width: 100%;
    height: 10px;
  }

  .mailer-button-container {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .template-button-container {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 15px;
  }

  .template-button {
    width: 100px;
    height: 40px;
    font-size: 12px;
    margin: 10px;
    padding: 10px;
  }

  .select {
    min-width: 120px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .select-small-width {
    min-width: 80px;
  }

  .status-select {
    height: 40px;
    width: 200px;
    background-color: ${colors.lightGrey};
    border-radius: 10px;
    text-align: center;
  }

  .variable-button {
    width: 80px;
    height: 40px;
    font-size: 12px;
    margin-top: 5px;
    margin-right: 20px;
  }

  .success-message {
    color: ${colors.primary};
  }

  .account-info-row {
    min-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    gap: 30px;
  }

  .account-info-row p {
    font-weight: 600;
    width: 180px;
  }

  .add-new-label {
    width: 150px;
    display: inline-block;
    font-weight: bold;
  }

  .add-new-field {
    background-color: ${colors.lightGrey};
    display: inline;
    align-items: center;
    width: 200px;
    margin-bottom: 10px;
  }

  .not-success-message {
    color: ${colors.inactiveRed};
  }

  .user-roles {
    font-size: 14px;
    font-weight: 400 !important;
    color: ${colors.primary};
    margin-left: 10px;
  }

  .connect-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    padding-left: 60px;
    border-radius: 20px;
  }

  .password-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    row-gap: 12px;
  }

  .user-form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .mail-form {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    max-width: 800px;
  }

  .connect-text {
    text-align: center;
  }

  .connect-text h2 {
    font-size: 2rem;
  }

  .connect-text p {
    color: ${colors.primary};
  }

  .providers {
    margin: 20px 0px;
  }

  .provider-box {
    background: #f8f9fd;
    margin: 30px 0px;
    padding: 15px 40px;
    height: 100px;
    border: 1px solid #c4c4c4;
    cursor: pointer;
  }

  .provider-box img {
    flex-grow: 1;
  }

  .provider-box h3 {
    flex-grow: 2;
    margin: 0px 40px;
    font-weight: 500;
  }

  .linkedin-title {
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 20px;
  }

  .linkedin-title-container {
    width: 400px;
    margin-bottom: 10px;
  }

  .titles-list-container {
    min-height: 150px;
  }

  .max-titles-div {
    height: 75px;
  }

  .remove-button {
    background-color: ${colors.lightGrey};
  }

  @media screen and (max-width: 768px) {
    .connect-form {
      padding: 20px 0px;
    }
  }

  .settings-hint {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    font-style: italic;
    color: ${colors.mediumGrey};
  }

  .addon-label {
    font-weight: 600;
    font-size: 14px;
  }

  .credits-label {
    font-weight: 500;
    font-size: 13px;
    text-align: right;
  }

  .add-ons-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .credit-container {
    display: flex;
    border-radius: 16px;
    width: 110%;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
  }
  .left-side-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
  }
  .right-side-container {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 16px;
    background-color: ${colors.white};
  }
  .used-credits {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 8px;
  }
  .bucket-credits {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding-left: 24px;
  }
  .additional-credits {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
  }
  .icon {
    width: 32px;
    height: 32px;
    min-height: 32px;
    min-width: 32px;
  }
  .vertical-divider {
    border-left: 1px solid ${colors.grey4};
    height: 100%;
    margin: 0px;
  }
  .tab-suggestion-preferences,
  .tab-suggestion-preferences-not-selected {
    min-height: 50px;
    text-transform: unset !important;
    color: black;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px 8px 0px 0px;
    color: ${colors.primary};
  }
  .tab-suggestion-preferences-not-selected {
    opacity: 0.7;
    filter: grayscale(1);
  }
  .tab-suggestion-preferences-not-selected:hover {
    opacity: 1;
  }
  .user-settings-card-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .suggestion-info-grey-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    padding: 24px;
    column-gap: 12px;
    background: ${colors.grey6};
  }
  .suggestion-tab-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 12px;
    width: 100%;
  }
  .pipeline-stage-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .default-pipeline {
    border: 1px solid ${colors.greyFieldBorder};
    border-radius: 12px;
    padding: 16px 14px;
  }
  .resync-crm-pipelines {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px 12px;
    border-radius: 32px;
    min-width: 180px;
    min-height: 48px;
    border: 1px solid ${colors.grey444};
    background: ${colors.grey4};
    cursor: pointer;
    &:hover {
      background: ${colors.grey4Hover};
    }
  }
  .stages-container {
    display: flex;
    flex-direction: column;
    padding: 2px 10px 2px 4px;
  }
  .stage-row {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 12px 10px;
    gap: 4px;
    border-radius: 8px;
    margin-block: 4px;
  }
  .action-buttons-container {
    display: flex;
    column-gap: 6px;
    width: fit-content;
  }
  .action-buttons {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
  .edit-stage {
    background: ${colors.callScheduledLight};
  }
  .edit-stage:hover {
    background: ${colors.callScheduledLightHover};
  }
  .delete-stage {
    background: ${colors.inactiveLight};
  }
  .delete-stage:hover {
    background: ${colors.inactiveLightHover};
  }
`;

export const ActiveSwitch = styled(Switch).attrs(() => ({
  classes: {
    root: 'root',
    switchBase: 'switchBase',
    thumb: 'thumb',
    track: 'track',
    checked: 'checked',
    focusVisible: 'focusVisible',
  },
  disableRipple: true,
  focusVisibleClassName: 'focusVisible',
}))`
  &.root {
    width: 52px;
    height: 26px;
    padding: 0;
    margin: 8px;
  }

  .switchBase {
    padding: 1px;

    &.checked {
      transform: translateX(26px);
      color: ${colors.primary};
      & + .track {
        background-color: #f8f9fd;
        opacity: 1;
        border: 2px solid ${colors.primary};
      }
    }

    &.focusVisible &.thumb {
      color: #f8f9fd;
      border: 6px solid #fff;
    }
  }

  .thumb {
    width: 24px;
    height: 24px;
  }

  & .track {
    border-radius: 13px;
    border: 2px solid ${colors.lightGrey};
    background-color: white;
    opacity: 1;
  }

  .checked {
  }
  .focusVisible {
  }
`;

export const tooltipStyles = {
  tooltip: {
    color: colors.black,
    backgroundColor: colors.lightGrey,
    border: `2px solid ${colors.primary}`,
    borderRadius: '5px',
    padding: '10px 25px',
    fontSize: '0.9em',
  },
  arrow: {
    '&:before': {
      backgroundColor: colors.primary,
    },
  },
};
