import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const AddOnsIcon = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M13.0005 8.99826H17.0022"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99805 8.72311L7.91743 9.55146L9.7682 7.88477"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9995 20.0016H7.99867C5.23609 20.0016 2.99658 17.7621 2.99658 14.9996V6.99622C2.99658 4.23365 5.23609 1.99414 7.99867 1.99414H16.002C17.3286 1.99414 18.6009 2.52114 19.539 3.45922C20.4771 4.39729 21.0041 5.66959 21.0041 6.99622V9.99747"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99805 13.7251L7.91743 14.5534L9.7682 12.8867"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.001 13.9004V19.6032C14.002 20.3764 15.5684 21.0035 17.5019 21.0035C19.4355 21.0035 21.0019 20.3764 21.0029 19.6032V13.9004"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.002 16.752C14.002 17.5252 15.5694 18.1523 17.5029 18.1523C19.4364 18.1523 21.0039 17.5252 21.0039 16.752"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.002 13.9004C14.002 14.6736 15.5694 15.3008 17.5029 15.3008C19.4365 15.3008 21.0029 14.6736 21.0029 13.9004C21.0029 13.1262 19.4345 12.5 17.5019 12.5C15.5694 12.5 14.002 13.1272 14.001 13.9004"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddOnsIcon;
