import { LinkedinFilterValue } from 'crono-fe-common/types/crono-extension/linkedin';
import {
  HeadCellSearchTab,
  IGetSearchHeadCellsProps,
} from '../searchContactTab/model';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { CompanyScrape } from 'crono-fe-common/types/companyScrape';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import EditPencilIcon from 'crono-fe-common/icons/Icon-Edit-pencil';

export interface SearchCompanyTabFiltersType {
  keywords?: string | null;
  annualRevenue?: {
    min: LinkedinFilterValue | null;
    max: LinkedinFilterValue | null;
    currency: LinkedinFilterValue | null;
  } | null;
  headcount?: LinkedinFilterValue[] | null;
  headcountGrowth?: { min?: number; max?: number } | null;
  headquarterLocation?: LinkedinFilterValue[] | null;
  hiringOnLinkedin?: boolean | null;
  departmentHeadcount?: {
    min?: number; // percentage
    max?: number;
    department: LinkedinFilterValue;
  } | null;
  departmentHeadcountGrowth?: {
    min?: number; // percentage
    max?: number;
    department: LinkedinFilterValue;
  } | null;
  technologyUsed?: LinkedinFilterValue[] | null;

  //Non salesNav filters
  geoUrn?: LinkedinFilterValue[] | null;
  companySize?: LinkedinFilterValue[] | null;
  industry?: LinkedinFilterValue[] | null;
}

export const getCompanySearchHeadCells = ({
  hasSalesNav,
  triggerEditColumnsPopup,
  userPreferencesColumns,
}: IGetSearchHeadCellsProps) => {
  const columns = userPreferencesColumns.split(',');
  const defaultCells: HeadCellSearchTab[] = [
    {
      id: 'name',
      label: 'Company Name',
      sortable: false,
      isSticky: true,
      minWidth: 300,
      width: 300,
      stickyOffsetLeft: 0,
      borderRight: true,
    },
    {
      id: 'companyIndustry',
      label: 'Industry',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: 250,
      padding: 2,
    },
    {
      id: 'companyHeadquarter',
      label: 'Headquarter',
      sortable: false,
      isSticky: false,
      minWidth: 200,
      width: 200,
    },
    {
      id: 'companyWebsite',
      label: 'Website',
      sortable: false,
      isSticky: false,
      minWidth: 200,
      width: 200,
    },
    {
      id: 'companySize',
      label: 'Employees',
      sortable: false,
      isSticky: false,
      minWidth: 200,
      width: 200,
    },
    {
      id: 'companyDescription',
      label: 'About',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: '100%',
      padding: 2,
    },
    {
      id: 'linkedinLink',
      label: 'Link',
      sortable: false,
      isSticky: false,
      minWidth: 400,
      width: '40%',
    },
    //Empty for add button
    {
      id: 'addButton',
      label: (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 6,
          }}
          className="edit-columns-btn"
          onClick={triggerEditColumnsPopup}
        >
          <Typography
            fontSize={'inherit'}
            lineHeight={'16px'}
            fontWeight={500}
            color={colors.green}
          >
            Edit Columns
          </Typography>

          <EditPencilIcon width="16px" height="16px" color={colors.green} />
        </span>
      ),
      sortable: false,
      isSticky: true,
      stickyOffsetRight: 0,
      minWidth: 106,
      width: 106,
      borderLeft: true,
    },
  ];
  const toRet = [
    defaultCells[0],
    ...defaultCells.filter((cell) => columns.includes(cell.id)),
    defaultCells[defaultCells.length - 1],
  ];
  return toRet;
};

export type CompanyForSearchTable = {
  id: string;
  numericId: string | null;
  pictureUrl: string | null;
  empolyees: number | string | null;
  name: string;
  description: string;
  url: string;
  urlForRedirect: string;
  website: string | null;
  industry: string | null;
  location: string | null;
  phone?: string | null;

  //Property to identify the possible similar companies that we retrieved from the database
  similarCompanies?: AccountLinkedin[] | null;
  correct?: boolean;

  //Used to know if the search in the linkedin database produced results or not to allow then to save the additional values once imported
  alreadyInDatabase?: boolean;
};

export const mixCompanyWithDatabaseSaved = (
  company: CompanyForSearchTable,
  companyFromLinkedinDatabase: CompanyScrape,
): CompanyForSearchTable => {
  return {
    id: company.id,
    name: companyFromLinkedinDatabase.name ?? company.name,
    description: companyFromLinkedinDatabase.description ?? company.description,
    url: companyFromLinkedinDatabase.url ?? company.url,
    website: companyFromLinkedinDatabase.website ?? company.website,
    industry: companyFromLinkedinDatabase.industry ?? company.industry,
    location: companyFromLinkedinDatabase.location ?? company.location,
    empolyees:
      companyFromLinkedinDatabase.numberOfEmployees ?? company.empolyees,
    pictureUrl: companyFromLinkedinDatabase.pictureUrl ?? company.pictureUrl,
    numericId: companyFromLinkedinDatabase.numericId ?? company.numericId,
    similarCompanies: company.similarCompanies,
    //These 2 may be inverted in the future if we want to send directly to linkedin if already in database
    urlForRedirect: company.urlForRedirect ?? company.url,
    correct: company.correct,

    alreadyInDatabase: true,
  };
};
