import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export default function useDuplicateStrategy() {
  const queryClient = useQueryClient();

  const duplicateStrategy = (strategyId: number) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.duplicate}`,
      config: {
        method: 'post',
        data: { strategyId },
      },
    };
    return client(request);
  };

  return useMutation<Response<number>, CronoError, number>(duplicateStrategy, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
    },
  });
}
