import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { ReportsFilters } from 'crono-fe-common/types/reportsFilters';
import { LogLinkedin } from 'crono-fe-common/types/logLinkedin';

export default function useDownloadLinkedinsReport() {
  const getLinkedinsReport = (reportsFilters: ReportsFilters) => {
    const request: Request = {
      url: ENDPOINTS.events.linkedinReports,
      config: {
        method: 'post',
        data: reportsFilters,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    ResponsePaginated<LogLinkedin[]>,
    CronoError,
    ReportsFilters
  >(getLinkedinsReport, {});

  return { data, mutate, ...rest };
}
