import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const CalendarIcon = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <g id="Group">
        <path
          id="Path"
          d="M12.111 21.0002H7.66662C6.19389 21.0002 5 19.8063 5 18.3336V7.66711C5 6.19437 6.19389 5.00049 7.66662 5.00049H18.3331C19.8058 5.00049 20.9997 6.19437 20.9997 7.66711V12.1115"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_2"
          d="M20.9997 9.44468H5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_3"
          d="M9.88706 20.9997H18.3314C19.8042 20.9997 20.998 19.8058 20.998 18.3331V7.66662C20.998 6.19389 19.8042 5 18.3314 5H7.66494C6.19221 5 4.99832 6.19389 4.99832 7.66662V12.111"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="Group 427320242">
          <path
            id="Path_4"
            d="M12.9807 17.4009C12.9692 17.4009 12.9582 17.4055 12.9502 17.4137C12.9421 17.4219 12.9378 17.433 12.938 17.4444C12.938 17.4621 12.9487 17.4781 12.9651 17.4848C12.9815 17.4914 13.0003 17.4876 13.0127 17.4749C13.025 17.4623 13.0286 17.4434 13.0215 17.4272C13.0145 17.4109 12.9984 17.4005 12.9807 17.4009"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_5"
            d="M17.3693 17.4009C17.3579 17.4009 17.3469 17.4055 17.3388 17.4137C17.3308 17.4219 17.3264 17.433 17.3267 17.4444C17.3267 17.4621 17.3374 17.4781 17.3538 17.4848C17.3702 17.4914 17.389 17.4876 17.4013 17.4749C17.4137 17.4623 17.4172 17.4434 17.4102 17.4272C17.4032 17.4109 17.387 17.4005 17.3693 17.4009"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_6"
            d="M8.59289 17.4004C8.58111 17.4002 8.56974 17.4048 8.56149 17.4132C8.55324 17.4217 8.54884 17.4331 8.54934 17.4449C8.54934 17.4626 8.56005 17.4785 8.57644 17.4852C8.59282 17.4919 8.61162 17.488 8.62401 17.4754C8.63639 17.4627 8.63989 17.4438 8.63287 17.4276C8.62585 17.4114 8.6097 17.401 8.592 17.4013"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Group 427320243">
          <path
            id="Path_7"
            d="M12.9807 13.4009C12.9692 13.4009 12.9582 13.4055 12.9502 13.4137C12.9421 13.4219 12.9378 13.433 12.938 13.4444C12.938 13.4621 12.9487 13.4781 12.9651 13.4848C12.9815 13.4914 13.0003 13.4876 13.0127 13.4749C13.025 13.4623 13.0286 13.4434 13.0215 13.4272C13.0145 13.4109 12.9984 13.4005 12.9807 13.4009"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_8"
            d="M17.3693 13.4009C17.3579 13.4009 17.3469 13.4055 17.3388 13.4137C17.3308 13.4219 17.3264 13.433 17.3267 13.4444C17.3267 13.4621 17.3374 13.4781 17.3538 13.4848C17.3702 13.4914 17.389 13.4876 17.4013 13.4749C17.4137 13.4623 17.4172 13.4434 17.4102 13.4272C17.4032 13.4109 17.387 13.4005 17.3693 13.4009"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_9"
            d="M8.59289 13.4004C8.58111 13.4002 8.56974 13.4048 8.56149 13.4132C8.55324 13.4217 8.54884 13.4331 8.54934 13.4449C8.54934 13.4626 8.56005 13.4785 8.57644 13.4852C8.59282 13.4919 8.61162 13.488 8.62401 13.4754C8.63639 13.4627 8.63989 13.4438 8.63287 13.4276C8.62585 13.4114 8.6097 13.401 8.592 13.4013"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default CalendarIcon;
