import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const CalendarRescheduleIcon = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        id="Path"
        d="M12.111 21.0002H7.66662C6.19389 21.0002 5 19.8063 5 18.3336V7.66711C5 6.19437 6.19389 5.00049 7.66662 5.00049H18.3331C19.8058 5.00049 20.9997 6.19437 20.9997 7.66711V12.1115"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M19.7383 16.7949H20.9978V15.5371"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M16.9287 19.5156H15.6719V20.7734"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M20.9985 19.1033C20.8658 19.5044 20.6422 19.8694 20.3452 20.1699C19.2344 21.2804 17.4338 21.2804 16.323 20.1699C16.13 19.9778 15.9659 19.7586 15.8359 19.5193"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M20.8313 16.794C20.4025 16.0064 19.6274 15.4671 18.7398 15.3388C17.8523 15.2106 16.9562 15.5084 16.322 16.1425C16.0244 16.443 15.7999 16.8079 15.666 17.2091"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_6"
        d="M20.9997 9.44468H5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        id="Path_7"
        d="M14.5538 12.9009C14.5298 12.9009 14.5103 12.9204 14.5103 12.9444C14.5103 12.9685 14.5298 12.988 14.5538 12.988C14.5779 12.988 14.5974 12.9685 14.5974 12.9444C14.5974 12.9204 14.5779 12.9009 14.5538 12.9009"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_8"
        d="M11.4416 12.9009C11.4301 12.9009 11.4191 12.9055 11.4111 12.9137C11.4031 12.9219 11.3987 12.933 11.3989 12.9444C11.3989 12.9621 11.4096 12.9781 11.426 12.9848C11.4424 12.9914 11.4612 12.9876 11.4736 12.9749C11.486 12.9623 11.4895 12.9434 11.4825 12.9272C11.4754 12.9109 11.4593 12.9005 11.4416 12.9009"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_9"
        d="M8.33117 12.9009C8.31939 12.9007 8.30802 12.9053 8.29977 12.9137C8.29152 12.9221 8.28712 12.9336 8.28762 12.9454C8.28762 12.9631 8.29833 12.979 8.31472 12.9857C8.3311 12.9924 8.3499 12.9885 8.36229 12.9758C8.37467 12.9632 8.37817 12.9443 8.37115 12.9281C8.36413 12.9118 8.34798 12.9015 8.33028 12.9018"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_10"
        d="M11.4416 16.5674C11.4301 16.5674 11.4191 16.572 11.4111 16.5802C11.4031 16.5884 11.3987 16.5995 11.3989 16.6109C11.3989 16.6286 11.4096 16.6446 11.426 16.6513C11.4424 16.658 11.4612 16.6541 11.4736 16.6414C11.486 16.6288 11.4895 16.6099 11.4825 16.5937C11.4754 16.5774 11.4593 16.567 11.4416 16.5674"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_11"
        d="M8.33117 16.5674C8.31939 16.5672 8.30802 16.5718 8.29977 16.5802C8.29152 16.5886 8.28712 16.6001 8.28762 16.6119C8.28762 16.6296 8.29833 16.6455 8.31472 16.6522C8.3311 16.6589 8.3499 16.655 8.36229 16.6424C8.37467 16.6297 8.37817 16.6108 8.37115 16.5946C8.36413 16.5783 8.34798 16.568 8.33028 16.5683"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarRescheduleIcon;
