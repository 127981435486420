import { findLatesActivity } from 'pages/dashboard/utilities';
import React from 'react';
import { Prospect } from 'crono-fe-common/types/prospect';

interface IProps {
  prospect: Prospect;
}

const LastActivityProspect = ({ prospect }: IProps) => {
  return (
    <>
      {prospect.lastActivityDate ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/*findLatesActivity(prospect.tasks || [])*/}
          {new Date(prospect.lastActivityDate).toLocaleDateString()}
        </div>
      ) : (
        <div>-</div>
      )}
    </>
  );
};

export default LastActivityProspect;
