import { ClickAwayListener, Typography } from '@mui/material';
import { EditColumnsModalWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import {
  CancelSmallButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'context/auth';
import { UserPreferences } from 'crono-fe-common/types/userPreferences';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useJuneAnalytics } from 'context/june';
import { useConfirmModal } from 'context/confirmModal';

export enum EditColumnsType {
  SalesContacts,
  SalesCompanies,
  LinkedinContacts,
  LinkedinCompanies,
}

export const defaultColumnsContactsLinkedin =
  'companyName,location,linkedinLink';
export const defaultColumnsContactsSalesNav =
  'companyName,jobTitle,location,linkedinLink';

export const defaultColumnsCompaniesLinkedin =
  'companyName,companyHeadquarter,linkedinLink';
export const defaultColumnsCompaniesSalesNav =
  'companyName,jobTitle,companyHeadquarter,linkedinLink';

//each tab can be visible in multiple places
interface IColumnType {
  columnTitle: string;
  columnId: string;
  columnsTypes: EditColumnsType[];
}

const editColumns: IColumnType[] = [
  {
    columnTitle: 'Company name',
    columnId: 'companyName',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.LinkedinContacts,
    ],
  },
  {
    columnTitle: 'Job title',
    columnId: 'jobTitle',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.LinkedinContacts,
    ],
  },
  {
    columnTitle: 'Location',
    columnId: 'location',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.LinkedinContacts,
    ],
  },
  {
    columnTitle: 'Company industry',
    columnId: 'companyIndustry',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.SalesCompanies,
      EditColumnsType.LinkedinContacts,
      EditColumnsType.LinkedinCompanies,
    ],
  },
  {
    columnTitle: 'Headquarter location',
    columnId: 'companyHeadquarter',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.SalesCompanies,
      EditColumnsType.LinkedinContacts,
      EditColumnsType.LinkedinCompanies,
    ],
  },
  {
    columnTitle: 'Company website',
    columnId: 'companyWebsite',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.SalesCompanies,
      EditColumnsType.LinkedinContacts,
      EditColumnsType.LinkedinCompanies,
    ],
  },
  {
    columnTitle: 'Company size',
    columnId: 'companySize',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.SalesCompanies,
      EditColumnsType.LinkedinContacts,
      EditColumnsType.LinkedinCompanies,
    ],
  },
  {
    columnTitle: 'Company description',
    columnId: 'companyDescription',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.SalesCompanies,
      EditColumnsType.LinkedinContacts,
      EditColumnsType.LinkedinCompanies,
    ],
  },
  {
    columnTitle: 'LinkedIn Link',
    columnId: 'linkedinLink',
    columnsTypes: [
      EditColumnsType.SalesContacts,
      EditColumnsType.SalesCompanies,
      EditColumnsType.LinkedinContacts,
      EditColumnsType.LinkedinCompanies,
    ],
  },
];

interface IProps {
  editColumnsType: EditColumnsType;
  onClose: () => void;
  onApply: () => void;
}

const EditColumnsModal = ({ editColumnsType, onClose, onApply }: IProps) => {
  const { user } = useAuth();
  const filteredColumns = useMemo(() => {
    return editColumns.filter((column) =>
      column.columnsTypes.includes(editColumnsType),
    );
  }, [editColumnsType]);

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  useEffect(() => {
    if (!user) return;
    switch (editColumnsType) {
      case EditColumnsType.SalesContacts:
      case EditColumnsType.LinkedinContacts:
        setSelectedColumns(
          (
            user.userPreferences?.findNewProspectColumns ??
            (editColumnsType === EditColumnsType.LinkedinContacts
              ? defaultColumnsContactsLinkedin
              : defaultColumnsContactsSalesNav)
          ).split(',') ?? [],
        );
        break;
      case EditColumnsType.SalesCompanies:
      case EditColumnsType.LinkedinCompanies:
        setSelectedColumns(
          (
            user.userPreferences?.findNewAccountColumns ??
            (editColumnsType === EditColumnsType.LinkedinCompanies
              ? defaultColumnsCompaniesLinkedin
              : defaultColumnsCompaniesSalesNav)
          ).split(',') ?? [],
        );
        break;
    }
  }, [user]);

  const [hasChanges, setHasChanges] = useState(false);

  const handleChangeColumn = (columnId: string) => {
    setHasChanges(true);
    setSelectedColumns((prev) =>
      prev.includes(columnId)
        ? prev.filter((column) => column !== columnId)
        : [...prev, columnId],
    );
  };

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const updateUserPreferences = (
    columns: string[],
    type: keyof Pick<
      UserPreferences,
      'findNewAccountColumns' | 'findNewProspectColumns'
    >,
  ) => {
    if (!user) return;
    const value = columns.join(',');
    if (!user.userPreferences) {
      user.userPreferences = {} as UserPreferences;
    }
    user.userPreferences[type] = value;
    patchUserPreferences({
      [type]: value,
    });
  };

  const analytics = useJuneAnalytics();

  const handleApply = () => {
    switch (editColumnsType) {
      case EditColumnsType.SalesContacts:
      case EditColumnsType.LinkedinContacts:
        updateUserPreferences(selectedColumns, 'findNewProspectColumns');

        if (analytics) {
          analytics.track('edit-columns-find-new-contacts', {});
        }
        break;
      case EditColumnsType.SalesCompanies:
      case EditColumnsType.LinkedinCompanies:
        updateUserPreferences(selectedColumns, 'findNewAccountColumns');

        if (analytics) {
          analytics.track('edit-columns-find-new-companies', {});
        }
        break;
    }
    onApply();
  };

  const { openModal } = useConfirmModal();

  const handleOpenConfirm = () => {
    if (!hasChanges) {
      onClose();
      return;
    }
    openModal({
      title: 'Do you want to save your changes?',
      confirmText: 'Save',
      cancelText: 'Discard',
      type: 'danger',
      confirmFunction: () => {
        handleApply();
        onClose();
      },
      cancelFunction: onClose,
    });
  };

  return (
    <ClickAwayListener onClickAway={handleOpenConfirm}>
      <EditColumnsModalWrapper>
        <div className="edit-columns-top">
          <Typography
            fontSize={'12px'}
            lineHeight={'16px'}
            fontWeight={500}
            color={colors.grey11}
            marginBottom={'8px'}
            marginLeft={'8px'}
          >
            Shown columns:
          </Typography>

          <div className="edit-columns-list">
            {filteredColumns.map(({ columnTitle, columnId }) => (
              <div className="edit-columns-item" key={columnId}>
                <div className="edit-columns-check">
                  <CronoCheckbox
                    checked={selectedColumns.includes(columnId)}
                    onChange={() => handleChangeColumn(columnId)}
                  />
                </div>

                <Typography
                  fontSize={'12px'}
                  lineHeight={'16px'}
                  fontWeight={500}
                  color={colors.black}
                >
                  {columnTitle}
                </Typography>
              </div>
            ))}
          </div>
        </div>

        <div className="edit-columns-buttons">
          <CancelSmallButton onClick={onClose} fullWidth>
            <Typography
              fontSize={'14px'}
              lineHeight={'18px'}
              fontWeight={500}
              color={colors.black}
            >
              Cancel
            </Typography>
          </CancelSmallButton>

          <MainButton
            height={'32px'}
            margin={'0px'}
            fullWidth
            onClick={handleApply}
          >
            <Typography
              fontSize={'14px'}
              lineHeight={'18px'}
              fontWeight={500}
              color={colors.white}
            >
              Apply
            </Typography>
          </MainButton>
        </div>
      </EditColumnsModalWrapper>
    </ClickAwayListener>
  );
};

export default EditColumnsModal;
