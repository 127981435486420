import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SequencePatch } from 'crono-fe-common/types/DTO/sequencePatch';

export default function usePatchSequence() {
  const queryClient = useQueryClient();

  const patchSequence = (sequencePatch: SequencePatch) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.main}`,
      config: {
        method: 'patch',
        data: { data: sequencePatch },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, CronoError, SequencePatch>(patchSequence, {
    onSettled: (result, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([
        ENDPOINTS.sequence.main,
        variables.strategyId,
      ]);
      queryClient.invalidateQueries([
        ENDPOINTS.sequence.strategy,
        variables.strategyId,
      ]);
      queryClient.invalidateQueries([
        ENDPOINTS.sequence.detail,
        variables.strategyId,
      ]);
    },
  });
}
