import {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';

function useSafeDispatch(
  dispatch: Dispatch<unknown> | SetStateAction<unknown>,
): any {
  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return useCallback(
    (...arg: any) =>
      mounted.current && typeof dispatch === 'function'
        ? dispatch(...arg)
        : void 0,
    [dispatch],
  );
}

export default useSafeDispatch;
