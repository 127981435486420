import React, { useEffect, useRef, useState } from 'react';
import { Typography, IconButton } from '@mui/material';
import { TextAccordionWrapper } from './style';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { colors } from 'crono-fe-common/theme';

interface IProps {
  text: any | null;
  style?: any | null;
  expanded?: boolean;
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  hideArrow?: boolean;
  children?: any;
}

const TextAccordion = ({
  text,
  style,
  expanded: expandedParent,
  setExpanded: setExpandedParent,
  hideArrow = false,
  children,
}: IProps) => {
  const [expanded, setExpanded] = useState(expandedParent ?? false);

  useEffect(() => {
    if (expandedParent !== undefined) {
      setExpanded(expandedParent);
    }
  }, [expandedParent]);

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
    if (setExpandedParent) {
      setExpandedParent((prev) => !prev);
    }
  };

  const refText = useRef<HTMLDivElement | null>(null);

  const [overflowing, setOverflowing] = useState<boolean>(false);

  useEffect(() => {
    if (
      (refText.current?.clientWidth ?? 0) <
        (refText.current?.scrollWidth ?? 0) ||
      (refText.current?.scrollHeight ?? 0) > 30
    ) {
      setOverflowing(true);
    }
  }, [refText.current]);

  return (
    <TextAccordionWrapper style={style}>
      {!children ? (
        <Typography
          variant="body1"
          noWrap={!expanded}
          className="accordion-text"
          ref={refText} //HTMLDivElement is subtype of HTMLSpanElement
          height={expanded ? 'fit-content' : '30px'}
          color={style ? style.color : colors.grey11}
          fontSize={style ? style.fontSize : ''}
        >
          {text}
        </Typography>
      ) : (
        <div
          ref={refText}
          className="accordion-text"
          style={{ maxHeight: expanded ? 'fit-content' : '30px' }}
        >
          {children}
        </div>
      )}
      {overflowing && !hideArrow && (
        <IconButton
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            toggleExpand();
          }}
          style={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            height: 'fit-content',
          }}
        >
          <BottomArrow />
        </IconButton>
      )}
    </TextAccordionWrapper>
  );
};

export default TextAccordion;
