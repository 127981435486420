import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const CronoTimePickerWrapper = styled.div`
  .select-date-time-button {
    cursor: pointer;
    display: flex;
    text-transform: unset;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${colors.grey11};
    padding-inline: 4px;
    min-width: 100px;
  }

  .align-left {
    padding-inline: 0;

    .MuiInputAdornment-root {
      margin-left: 0;
      margin-right: 9px;
    }
  }
`;
