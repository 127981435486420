import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import VerificationStatusType from 'crono-fe-common/types/enums/verificationStatusType';
import React from 'react';

interface IProps {
  emailValid?: boolean;
  emailStatus: VerificationStatusType | null;
}

const ValidityBadgeEmailTask = ({ emailValid, emailStatus }: IProps) => {
  return (
    <ValidityBadgeEmailTaskWrapper>
      {!emailValid || emailStatus === VerificationStatusType.INVALID ? (
        <div className="email-badge invalid-email-badge">Invalid</div>
      ) : emailStatus === VerificationStatusType.VALID ? (
        <div className="email-badge valid-email-badge">Valid</div>
      ) : emailStatus === VerificationStatusType.ACCEPT_ALL ? (
        <div className="email-badge catch-all-email-badge">Catch all</div>
      ) : emailStatus === VerificationStatusType.UNKNOWN ? (
        <div className="email-badge catch-all-email-badge">Unknown</div>
      ) : (
        <></>
      )}
    </ValidityBadgeEmailTaskWrapper>
  );
};

export const ValidityDot = ({ emailStatus }: IProps) => {
  return (
    <ValidityBadgeEmailTaskWrapper>
      {emailStatus === VerificationStatusType.INVALID ? (
        <Tooltip title="Invalid" arrow enterDelay={200} enterNextDelay={200}>
          <div className="email-dot invalid-email-dot" />
        </Tooltip>
      ) : emailStatus === VerificationStatusType.VALID ? (
        <Tooltip title="Valid" arrow enterDelay={200} enterNextDelay={200}>
          <div className="email-dot valid-email-dot" />
        </Tooltip>
      ) : emailStatus === VerificationStatusType.ACCEPT_ALL ? (
        <Tooltip title="Accept all" arrow enterDelay={200} enterNextDelay={200}>
          <div className="email-dot catch-all-email-dot" />
        </Tooltip>
      ) : emailStatus === VerificationStatusType.UNKNOWN ? (
        <Tooltip title="Unknown" arrow enterDelay={200} enterNextDelay={200}>
          <div className="email-dot catch-all-email-dot" />
        </Tooltip>
      ) : (
        <></>
      )}
    </ValidityBadgeEmailTaskWrapper>
  );
};

const ValidityBadgeEmailTaskWrapper = styled.div`
  .email-badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    border-radius: 16px;
    padding: 4px 8px;
    font-weight: 500;
    width: fit-content;
  }
  .email-dot {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    min-width: 6px;
    margin-right: 6px;
  }
  .invalid-email-badge {
    color: ${colors.inactiveHover};
    background-color: ${colors.inactiveLight};
  }
  .valid-email-badge {
    color: ${colors.callScheduledHover};
    background-color: ${colors.callScheduledLight};
  }
  .catch-all-email-badge {
    color: ${colors.nurtureHover};
    background-color: ${colors.nurtureLight};
    width: 73px;
  }
  .invalid-email-dot {
    background-color: ${colors.inactiveHover};
  }
  .valid-email-dot {
    background-color: ${colors.callScheduledHover};
  }
  .catch-all-email-dot {
    background-color: ${colors.nurtureHover};
  }
`;

export default ValidityBadgeEmailTask;
