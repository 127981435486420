import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconIndeterminate = ({
  color = colors.grey3,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect
        x="0.75"
        y="0.75"
        width="14.5"
        height="14.5"
        rx="3.25"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="4.5" y="7" width="7" height="2" rx="1" fill="white" />
    </svg>
  );
};

export default IconIndeterminate;
