import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconOpportunity = ({ color = colors.black, className }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <ellipse
        id="Oval"
        cx="8.99859"
        cy="9.99915"
        rx="6.0025"
        ry="2.50104"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path"
        d="M15.0011 13.5006C15.0011 14.8819 12.3137 16.0017 8.99859 16.0017C5.6835 16.0017 2.99609 14.8819 2.99609 13.5006"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M21.0064 9.99918C21.0064 11.3805 18.319 12.5002 15.0039 12.5002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M15.0011 9.99918V17.5023C15.0011 18.8836 12.3137 20.0033 8.99859 20.0033C5.6835 20.0033 2.99609 18.8836 2.99609 17.5023V9.99918"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M21.0064 6.49771V14.0008C21.0064 15.3821 18.319 16.5019 15.0039 16.5019"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M9.50021 7.50814C9.20322 7.2532 9.02266 6.88846 9 6.49772C9 5.11714 11.6911 3.99667 15.0025 3.99667C18.3139 3.99667 21.005 5.11714 21.005 6.49772C21.005 7.87829 18.3139 8.99876 15.0025 8.99876C14.8324 8.99876 14.6624 8.99876 14.5023 8.98875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconOpportunity;
