import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Opportunity } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';

export default function useGetOpportunity(
  accountId: string | null,
  limit = 20,
  owned = false,
  selectedOpportunityId: string | null = null,
) {
  const queryParams = new URLSearchParams({
    accountId: accountId ?? '',
    limit: '' + limit,
    owned: owned ? 'true' : 'false',
    ...(selectedOpportunityId
      ? { selectedOpportunityId: selectedOpportunityId ?? '' }
      : {}),
  });

  const request: Request = {
    url: `${ENDPOINTS.opportunity.main}?${queryParams}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [
      ENDPOINTS.opportunity.main,
      accountId,
      limit,
      owned,
      selectedOpportunityId,
    ],
    queryFn: () => client(request),
    enabled: !!accountId,
  };

  const { data, ...rest } = useQuery<
    Response<Opportunity[]> & {
      data: {
        count: number;
      };
    },
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
