import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const NotSupportedView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;

  .logo {
    width: 135px;
    height: 40px;
    margin: 16px 0px;
  }
  .no-resolution-support {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: ${colors.grey6};
    height: 100vh;
    width: 100%;
  }
`;
