import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export default function useDeleteTemplate() {
  const queryClient = useQueryClient();

  const deleteTemplate = (TemplateId: number) => {
    const queryParams = queryString.stringify({ TemplateId });

    const request: Request = {
      url: `${ENDPOINTS.template.main}?${queryParams}`,
      config: {
        method: 'delete',
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    number
  >(deleteTemplate, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.template.main]);
      queryClient.invalidateQueries([ENDPOINTS.template.search]);
    },
  });

  return { data, mutate, ...rest };
}
