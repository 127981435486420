import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  TooltipProps,
  styled,
  tooltipClasses,
  ClickAwayListener,
} from '@mui/material';
import CallIconDark from 'crono-fe-common/icons/Icon-Call-Dark';
import EmailIconDark from 'crono-fe-common/icons/Icon-Email-Dark';
import LinkedinIconMessageDark from 'crono-fe-common/icons/Icon-Linkedin-Message-Dark';
import LinkedinInvitationIconDark from 'crono-fe-common/icons/Icon-Linkedin-Invitation-Dark';
import LinkedinIconInvoiceDark from 'crono-fe-common/icons/Icon-Linkedin-Invoice-Dark';
import LinkedinIconInMailCompassDark from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass-Dark';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { SequenceStepWrapper } from './style';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import moment from 'moment';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { dateCompareUTC } from 'pages/taskBoard/calendar';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import RescheduleIcon from 'crono-fe-common/icons/Icon-Change-Date';
import SelectedTemplateIcon from 'crono-fe-common/icons/Icon-Selected-Template';
import AddTemplateIconS from 'crono-fe-common/icons/Icon-Add-Template-S';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import { ReplyThread } from 'context/strategyDetail/stepDetailComponent';
import ReplyThreadIcon from 'crono-fe-common/icons/Icon-Reply-Thread';
import EditableTemplate from 'context/strategyDetail/stepDetailComponent/editableTemplate';
import {
  getTextOfAutomationError,
  parseTaskTypeToString,
} from 'utils/fe-utils';
import ErrorIndicatorIcon from 'crono-fe-common/icons/Icon-Error-Indicator';
import ConditionalStepDetailComponent from 'context/strategyDetail/conditionalStepDetailComponent';
import { SwitchToTask } from 'crono-fe-common/types/enums/switchToConditionalStep';
import usePatchPersonalizedTemplate from 'hooks/services/personalizedTemplate/usePatchPersonalizedTemplate';
import useCreatePersonalizedTemplate from 'hooks/services/personalizedTemplate/useCreatePersonalizedTemplate';
import { useConditionalSnackBar } from 'context/snackbar';
import CronoTimePicker from 'crono-fe-common/components/CronoTimePicker';
import { useJuneAnalytics } from 'context/june';
import { TemplateCardsContainerTooltip } from 'pages/accountTab/linkedinView';
import DelayButton from 'context/strategyDetail/delayButton';
import classNames from 'classnames';
import ConditionalFlowButton from 'context/strategyDetail/conditionalFlowButton';
import ThreeDotsIcon from 'crono-fe-common/icons/Icon-Three-dots';
import CloseSIcon from 'crono-fe-common/icons/Icon-Close-S';
import { SequenceOptionsWrapper } from 'pages/settings/Strategy/sequenceOptions/style';

export interface TypeOption {
  type: TaskTodoType;
  subtype: TaskTodoSubtype | null;
  icon: JSX.Element;
  text: string;
}

export const typeOptions: TypeOption[] = [
  {
    type: TaskTodoType.CALL,
    subtype: null,
    icon: <CallIcon />,
    text: 'Call',
  },
  {
    type: TaskTodoType.EMAIL,
    subtype: null,
    icon: <EmailIcon />,
    text: 'Email',
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinInvitation,
    text: 'Linkedin Invitation',
    icon: <LinkedinInvitationIcon />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinMessage,
    text: 'Linkedin Message',
    icon: <LinkedinIconMessage />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinVoiceNote,
    text: 'Linkedin Voice Note',
    icon: <LinkedinIconInvoice viewBox="0 2 20 20" />,
  },
  {
    type: TaskTodoType.IN_MAIL,
    subtype: null,
    icon: <LinkedinIconInMailCompass viewBox="0 2 20 20" />,
    text: 'Linkedin InMail',
  },
];

const CustomTooltipActionTask = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    margin: '0px 16px 0px 0px',
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    display: 'flex',
    width: 175,
  },
}));

interface IProps {
  task: TaskTodo;
  handleChange: (type: string, payload: any, index: number) => void;
  index: number;
  handleJumpToStep: (index: number) => void;
  openTemplatePicker: (index: number) => void;
  handleTemplatePicker: (type: SwitchToTask) => void;
  openDateCalendar: (task: TaskTodo) => void;
  firstTask?: boolean;
  opened?: boolean;
  linkedinUntilAcceptedDays?: number;
  handleRemoveTemplate: (index: number) => void;
  tasks: TaskTodo[];
  setSequenceStepTemplateId: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  // Template tooltip
  templateComponent?: JSX.Element | null;
  closeTemplateTooltip?: () => void;
  showTemplateTab?: boolean;
  taskConditionalActivityDate?: string;
  previewOnly?: boolean;
  allowToPersonalize?: boolean;
}

const SequenceInstanceTaskComponent = ({
  task,
  handleChange,
  index,
  handleJumpToStep,
  openTemplatePicker,
  handleTemplatePicker,
  openDateCalendar,
  firstTask,
  opened,
  linkedinUntilAcceptedDays,
  handleRemoveTemplate,
  tasks,
  setSequenceStepTemplateId,
  templateComponent,
  closeTemplateTooltip,
  showTemplateTab,
  taskConditionalActivityDate,
  previewOnly = false,
  allowToPersonalize,
}: IProps) => {
  const [type, setType] = useState<TaskTodoType | null>(null);

  const tasksIncludeLinkedinWithConditional = useMemo(
    () =>
      tasks.findIndex(
        (t) => t.type === TaskTodoType.LINKEDIN && t.sequenceStepConditional,
      ) !== -1,
    [tasks],
  );

  const isLinkedinInvitation =
    task.type === TaskTodoType.LINKEDIN &&
    task.subtype === TaskTodoSubtype.LinkedinInvitation;

  // Added task took the next type task, this forces to update to Email type (default)
  useEffect(() => {
    if (task.type) {
      setType(task.type);
    }
  }, [task]);

  const todaysTasks = dateCompareUTC(new Date(task.activityDate), new Date());

  const renderStepType = (task: TaskTodo) => {
    const type = parseTaskTypeToString(task.type, task.subtype ?? null);

    return (
      <Typography fontSize={'12px'} fontWeight={500} paddingLeft={'12px'}>
        {type}
      </Typography>
    );
  };

  const renderActivityIcon = (task: TaskTodo) => {
    switch (task.type) {
      case TaskTodoType.CALL:
        return <CallIconDark />;
      case TaskTodoType.EMAIL:
        return <EmailIconDark />;
      case TaskTodoType.LINKEDIN:
        if (task.subtype === TaskTodoSubtype.LinkedinInvitation) {
          return <LinkedinInvitationIconDark />;
        } else if (task.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
          return <LinkedinIconInvoiceDark />;
        } else {
          return <LinkedinIconMessageDark />;
        }
      case TaskTodoType.IN_MAIL:
        return <LinkedinIconInMailCompassDark />;
    }
  };

  const checkPreviousSteps = (
    step: TaskTodo,
    index: number,
    steps: TaskTodo[],
  ) => {
    // first step never has previous step
    if (step.order === 1) return false;

    // Filter the previous email steps. If there are any, then there must have been previous steps in that strategy
    const previousRelatedStep = steps
      .slice(0, index)
      .filter((s) => s.type === step.type);

    return previousRelatedStep.length > 0;
  };

  const findPreviousThreadSubject = (index: number, steps: TaskTodo[]) => {
    // Only the immediate previous email step is looked to get the subject
    const previousRelatedStep = steps
      .slice(0, index)
      .filter((s) => s.type === TaskTodoType.EMAIL)
      .pop();
    if (previousRelatedStep && previousRelatedStep.template) {
      return previousRelatedStep;
    }
  };

  const [observerEditMode, setObserverEditMode] = useState<boolean>(false);

  const isEmail = task.type === TaskTodoType.EMAIL;
  const [isReplyThreadSubject, setIsReplyThreadSubject] = useState<ReplyThread>(
    task.replyToThread ? 'existingThread' : 'newThread',
  );
  const emailSubject = useMemo(
    () =>
      isReplyThreadSubject === 'newThread'
        ? task.template?.subject ?? 'No subject'
        : findPreviousThreadSubject(index, tasks)?.template?.subject ??
          'No subject',
    [isReplyThreadSubject, tasks, task.template, index],
  );

  const emailThreadButton = useMemo(
    () => (
      <div className="flex-center email-thread">
        {isEmail && (
          <Select
            value={isReplyThreadSubject}
            startAdornment={<ReplyThreadIcon />}
            inputProps={{ IconComponent: () => null }}
            defaultValue="newThread"
            onChange={(e) => {
              setIsReplyThreadSubject(e.target.value as ReplyThread);
              handleChange(
                'replyToThread',
                e.target.value === 'existingThread',
                index,
              );
            }}
            disabled={observerEditMode}
            sx={{
              fontSize: '14px',
              minWidth: '120px',
              boxShadow: 'none',
              fontWeight: '500',
              gap: '4px',
              padding: '0px',
              '.MuiOutlinedInput-input': {
                padding: '0px',
              },
              '.MuiSelect-select': {
                padding: '0px !important',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover': {
                color: colors.darkGreen,
                '&& svg path': {
                  stroke: colors.darkGreen,
                },
                '&& fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
            MenuProps={{
              style: { zIndex: 10011 },
              PaperProps: {
                sx: {
                  borderRadius: '8px',
                  paddingInline: '6px',
                  border: '1px solid #dadde9',
                  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                  '& .MuiMenuItem-root': {
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '16px',
                    height: 'fit-content',
                    padding: '8px',
                    cursor: 'pointer',
                    width: '100%',
                    borderRadius: '8px',
                    '&:hover': {
                      background: colors.primaryLight,
                      color: colors.primary,
                    },
                  },
                  '& .Mui-selected': {
                    color: colors.primary,
                    backgroundColor: 'transparent !important',
                  },
                },
              },
            }}
          >
            <MenuItem value={'newThread'}>New email thread</MenuItem>
            <MenuItem
              value={'existingThread'}
              disabled={!checkPreviousSteps(task, index, tasks)}
            >
              Previous email thread
            </MenuItem>
          </Select>
        )}
      </div>
    ),
    [tasks, task, index, isReplyThreadSubject, observerEditMode],
  );

  const {
    mutate: patchPersonalizedTemplate,
    isSuccess: personalizedTemplatePatched,
  } = usePatchPersonalizedTemplate();

  const {
    mutate: createPersonalizedtemplate,
    isSuccess: personalizedTemplateCreated,
  } = useCreatePersonalizedTemplate();

  const analytics = useJuneAnalytics();

  const handleSaveEditableTemplate = (subject: string, content: string) => {
    if (!task.template) return;
    const isTemplatePersonalized = task.template.isPersonalized;
    //It a template is already present update it
    if (isTemplatePersonalized && task.template.personalizedTemplateId) {
      patchPersonalizedTemplate({
        id: task.template?.personalizedTemplateId,
        content: content,
        subject: subject,
      });
      if (analytics) {
        analytics.track('patch-personalized-template', {});
      }
    } else {
      //Otherwise we create a new personalized one
      createPersonalizedtemplate({
        taskTodoId: task.id,
        content: content,
        subject: subject,
        templateId: task.template.id,
      });
      if (analytics) {
        analytics.track('create-personalized-template', {});
      }
    }
  };

  const handleSaveEditableTemplateConditional = (
    subject: string,
    content: string,
  ) => {
    if (!task.sequenceStepConditional?.template) return;
    const isTemplatePersonalized =
      task.sequenceStepConditional.template.isPersonalized;
    //It a template is already present update it
    if (
      isTemplatePersonalized &&
      task.sequenceStepConditional.template.personalizedTemplateId
    ) {
      patchPersonalizedTemplate({
        id: task.sequenceStepConditional.template.personalizedTemplateId,
        content: content,
        subject: subject,
      });
    } else {
      //Otherwise we create a new personalized one
      createPersonalizedtemplate({
        taskTodoId: task.id,
        content: content,
        subject: subject,
        templateId: task.sequenceStepConditional.template.id,
        sequenceStepConditionalId: task.sequenceStepConditionalId ?? undefined,
      });
    }
  };

  useConditionalSnackBar([
    {
      condition: !!personalizedTemplateCreated || !!personalizedTemplatePatched,
      message: 'Your personalization has been saved correctly',
      severity: 'success',
    },
  ]);

  const renderTemplateManagementUI = () => (
    <div
      className="step-content-right"
      style={{
        alignSelf:
          task.sequenceStepConditional && task.template
            ? 'flex-start'
            : undefined,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          overflow: 'hidden',
        }}
      >
        {task.type !== TaskTodoType.CALL ? (
          task.template ? (
            <>
              {task.sequenceStepTemplateId && (
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  lineHeight={'18px'}
                  color={colors.grey11}
                >
                  A/B testing:
                </Typography>
              )}
              <TemplateCardsContainerTooltip
                title={templateComponent}
                onClose={closeTemplateTooltip}
                open={showTemplateTab}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <div
                  className={`template-section ${
                    observerEditMode
                      ? 'disable-due-to-edit'
                      : 'selected-template'
                  }`}
                  onClick={() => {
                    if (task.sequenceStepTemplateId) {
                      setSequenceStepTemplateId(task.sequenceStepTemplateId);
                    }
                    if (task.type !== TaskTodoType.CALL)
                      openTemplatePicker(index);
                  }}
                >
                  <span className="flex-center icon-wrapper">
                    <SelectedTemplateIcon
                      className={`${
                        observerEditMode
                          ? 'step-component-button-disabled'
                          : 'step-component-button'
                      }`}
                      color={colors.grey11}
                    />
                  </span>
                  <Typography fontSize={14} fontWeight={500} noWrap>
                    {task.template.title}
                  </Typography>
                  <span
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                    }}
                    className="close-template-option"
                    onClick={(ev: any) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      handleRemoveTemplate(index);
                    }}
                    id="remove-template-icon"
                  >
                    <CloseMIcon color={colors.grey11} />
                  </span>
                </div>
              </TemplateCardsContainerTooltip>
            </>
          ) : (
            <TemplateCardsContainerTooltip
              title={templateComponent}
              onClose={closeTemplateTooltip}
              open={showTemplateTab}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <div
                className="template-section add-template"
                onClick={() => {
                  if (task.type !== TaskTodoType.CALL)
                    openTemplatePicker(index);
                }}
              >
                <span className="flex-center icon-wrapper">
                  <AddTemplateIconS className={'step-component-button'} />
                </span>
                <Typography fontSize={'14px'} fontWeight={500} noWrap>
                  Add template
                </Typography>
              </div>
            </TemplateCardsContainerTooltip>
          )
        ) : (
          <div />
        )}
        {task.template?.isPersonalized && (
          <div className="personalized-badge">
            <EditPencilIconS color={colors.intenseBlue} />
            <Typography
              fontSize={'12px'}
              lineHeight={'16px'}
              fontWeight={500}
              color={colors.intenseBlue}
            >
              Personalized
            </Typography>
          </div>
        )}
      </div>
    </div>
  );

  const [openTooltipAction, setOpenTooltipAction] = useState<boolean>(false);

  const handleSkipTask = () => {
    handleChange('skip', null, index);
    setOpenTooltipAction(false);
  };

  //If the invitation is accepted and we still have some conditions aroud (due to old logic of strategies) we apply an additional margin at the top
  //of the delay buttons to make it look as the new one
  const firstSequenceStepConditional = useMemo(() => {
    const invitationTask = tasks.find(
      (t) =>
        t.type === TaskTodoType.LINKEDIN &&
        t.subtype === TaskTodoSubtype.LinkedinInvitation,
    );
    if (invitationTask?.completed || invitationTask?.skipped) {
      return tasks.findIndex((t) => t.sequenceStepConditional) === index;
    }
    return false;
  }, [tasks, index]);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      id={'' + (index + 1)}
    >
      {/*This is not a component because when using :first-child to apply the style to the first one, styles to components are applied to everyone, not only to the first one */}
      <div
        style={{
          height: '100%',
          borderLeft: `1px dashed ${colors.primaryDark}`,
          position: 'absolute',
          left: 25,
          zIndex: -1,
        }}
        id="dashed-line"
      />
      <SequenceStepWrapper openedStep={opened}>
        <div className="step-row">
          {!firstTask && (
            <Tooltip
              title="Previous tasks will be skipped"
              PopperProps={{
                disablePortal: true,
                style: { zIndex: '999999 !important' },
              }}
              enterDelay={400}
              leaveDelay={400}
              placement="top"
              arrow
            >
              <Button
                className="jump-button"
                style={
                  task.sequenceStepConditional
                    ? undefined
                    : {
                        transform: 'translateY(50%)',
                        bottom: '50%',
                      }
                }
                onClick={() => {
                  handleJumpToStep(index);
                }}
              >
                Jump to this step
              </Button>
            </Tooltip>
          )}
          <div
            className="delay-jump-step-container"
            style={{
              justifyContent: task.sequenceStepConditional
                ? 'space-around'
                : 'center',
              marginTop: firstSequenceStepConditional ? 42 : 0,
            }}
          >
            <DelayButton
              delay={task.delay ?? 0}
              decreaseDelay={() => {
                handleChange('delay', -1, index);
              }}
              increaseDelay={() => {
                handleChange('delay', 1, index);
              }}
              dateLabel={moment(task.activityDate).format('ll')}
              delayLabel={todaysTasks ? 'Today:' : 'Wait:'}
              minusDisabled={todaysTasks}
            />
            {task.sequenceStepConditional ? (
              <DelayButton
                delay={
                  task.sequenceStepConditional.daysAfterConditionNotAccepted
                }
                tooltipTitle={
                  'To change this delay please change it for the whole strategy'
                }
                disabled={true}
                increaseDelay={() => {}}
                decreaseDelay={() => {}}
                dateLabel={taskConditionalActivityDate}
              />
            ) : task.automationError ? (
              <div className="error-badge-task">
                <ErrorIndicatorIcon color2={colors.inactiveLightHover} />
                <Typography
                  fontSize={12}
                  lineHeight={'16px'}
                  fontWeight={500}
                  color={colors.inactive}
                  noWrap
                >
                  {getTextOfAutomationError(task.automationError)}
                </Typography>
              </div>
            ) : null}
          </div>
        </div>
        <div className="step-row">
          <div className="step-number-container">
            <Typography
              className={
                opened ? 'step-number step-number-selected' : 'step-number'
              }
            >
              {index + 1}
            </Typography>
          </div>
          <div
            className={classNames({
              'step-content-conditional': task?.sequenceStepConditional,
              'step-content': !task?.sequenceStepConditional,
              'active-step': opened,
            })}
          >
            <div className="step-content-single-step" id={task.id.toString()}>
              <div
                className={classNames('step-content-top', {
                  'step-content-no-border-bottom':
                    task?.sequenceStepConditional || task?.template,
                })}
                style={{ borderBottom: task.template ? 'none' : '' }}
              >
                <div className="step-content-left">
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    {renderActivityIcon(task)}
                    {type && renderStepType(task)}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {task.type === TaskTodoType.EMAIL ||
                    (task.type === TaskTodoType.LINKEDIN &&
                      task.subtype !== TaskTodoSubtype.LinkedinVoiceNote) ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 16,
                        }}
                      >
                        <Tooltip
                          arrow
                          title={
                            task.type === TaskTodoType.LINKEDIN &&
                            task.subtype === TaskTodoSubtype.LinkedinInvitation
                              ? 'Automate this step'
                              : task.templateId === null
                                ? 'Please select a template to automate this step'
                                : 'Automate this step'
                          }
                          PopperProps={{
                            disablePortal: true,
                            style: { zIndex: '999999 !important' },
                          }}
                          enterDelay={400}
                          enterNextDelay={400}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              fontSize={12}
                              fontWeight={500}
                              color={colors.grey11}
                            >
                              Auto
                            </Typography>
                            <CronoSwitch
                              checked={task.automatic}
                              disabled={
                                !(
                                  task.type === TaskTodoType.LINKEDIN &&
                                  task.subtype ===
                                    TaskTodoSubtype.LinkedinInvitation
                                ) && !task.template
                              }
                              onChange={(ev) => {
                                handleChange(
                                  'automatic',
                                  ev.target.checked,
                                  index,
                                );
                              }}
                            />
                          </div>
                        </Tooltip>
                        {task.automatic && (
                          <CronoTimePicker
                            value={new Date(task.activityDate)}
                            handleChange={(newValue) =>
                              handleChange('scheduleTime', newValue, index)
                            }
                            disabled={
                              !(
                                task.type === TaskTodoType.LINKEDIN &&
                                task.subtype ===
                                  TaskTodoSubtype.LinkedinInvitation
                              ) && !task.template
                            }
                            className={'select-time-button'}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
                {!task.sequenceStepConditional && (
                  <>
                    <div className="vertical-divider" />
                    {renderTemplateManagementUI()}
                  </>
                )}
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenTooltipAction(false);
                  }}
                >
                  <div className="container-sequence-instance-task">
                    <CustomTooltipActionTask
                      title={
                        <SequenceOptionsWrapper>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Button
                              className="option-button"
                              endIcon={<CloseSIcon color={colors.mainDark} />}
                              onClick={handleSkipTask}
                            >
                              Skip task
                            </Button>
                            <Button
                              className="option-button"
                              endIcon={
                                <RescheduleIcon
                                  className="option-button-icon-16"
                                  color={colors.mainDark}
                                />
                              }
                              onClick={() => {
                                setOpenTooltipAction(false);
                                openDateCalendar(task);
                              }}
                            >
                              Reschedule task
                            </Button>
                          </div>
                        </SequenceOptionsWrapper>
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      open={openTooltipAction}
                      onClose={() => setOpenTooltipAction(false)}
                    >
                      <div>
                        <IconButton className="task-component-button-white ">
                          <ThreeDotsIcon
                            onClick={() =>
                              setOpenTooltipAction((prev) => !prev)
                            }
                            color={colors.mainDark}
                          />
                        </IconButton>
                      </div>
                    </CustomTooltipActionTask>
                  </div>
                </ClickAwayListener>
              </div>

              {task?.sequenceStepConditional || task?.template ? (
                <div className="step-content-bottom">
                  {task.sequenceStepConditional && renderTemplateManagementUI()}

                  {task.template ? (
                    <EditableTemplate
                      id={
                        task.sequenceStepConditional
                          ? 'editable-template-conditional-left-leaf'
                          : undefined
                      }
                      applyIdOnlyOnEditMode={true}
                      allowToPersonalize={allowToPersonalize}
                      previewOnly={previewOnly}
                      key={`${task.template.id}-${task.template.isPersonalized}-${emailSubject}`}
                      emailThreadButton={
                        isEmail ? emailThreadButton : undefined
                      }
                      subject={isEmail ? emailSubject : task.template.subject}
                      enableSubjectMutation={
                        isReplyThreadSubject === 'newThread'
                      }
                      stepId={task.id}
                      template={task.template}
                      setObserverEditMode={(value) =>
                        setObserverEditMode(value)
                      }
                      onSuccess={() => {}}
                      onSaveReturnTemplate={handleSaveEditableTemplate}
                      prospectId={task.prospectId}
                      accountId={task.accountId}
                      isSequenceInstance={true}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
            {/* Conditional step */}
            {task?.sequenceStepConditional ? (
              <div className="conditional-step-wrapper">
                <ConditionalStepDetailComponent
                  allowToPersonalize={allowToPersonalize}
                  conditionalStep={task.sequenceStepConditional}
                  parentStepType={task.type}
                  handleChange={null}
                  index={index}
                  openConditionalTemplatePicker={handleTemplatePicker}
                  previousThreadSubject={findPreviousThreadSubject(
                    index,
                    tasks,
                  )}
                  onSaveReturnTemplate={handleSaveEditableTemplateConditional}
                  onSuccessEditTemplate={() => {}}
                  disableEditsButTemplate={true}
                  prospectId={task.prospectId}
                  accountId={task.accountId}
                  isSequenceInstance={true}
                  previewOnly={previewOnly}
                />
              </div>
            ) : null}
          </div>
        </div>
        {isLinkedinInvitation && tasksIncludeLinkedinWithConditional && (
          <div className="step-row">
            <div
              className="step-content"
              style={{
                marginLeft: 'auto',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ConditionalFlowButton
                id={`${task.id}-conditional-button`}
                delay={linkedinUntilAcceptedDays ?? 0}
                increaseDelay={() => {}}
                decreaseDelay={() => {}}
                disabledText={
                  'To change this conditional settings change it for the whole strategy'
                }
              />
            </div>
          </div>
        )}
      </SequenceStepWrapper>
    </div>
  );
};

export default SequenceInstanceTaskComponent;
