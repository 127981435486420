import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ConditionalFlowButtonWrapper = styled.div`
  width: fit-content;

  .conditional-button-disabled,
  .conditional-button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0px 12px;
    border: 1px solid ${colors.darkYellow};
    border-radius: 8px;
    height: 42px;
    background: ${colors.nurtureLight};
  }

  .conditional-button-initial:hover {
    cursor: pointer;
    background: ${colors.nurtureLightHover};
  }

  .conditional-button-activated {
    cursor: initial;
  }

  .conditional-button-disabled {
    cursor: initial;
    background: ${colors.grey6};
    border: none;
  }
`;
