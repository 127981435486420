import { Select } from '@mui/material';
import { CronoMenuItem } from 'crono-fe-common/components/Select';
import { colors } from 'crono-fe-common/theme';
import { SuggestionPreferences } from 'crono-fe-common/types/suggestionPreferences';
import React from 'react';
import { useGetStatusesOptions } from 'utils/fe-utils';
import { ITaskSuggestion } from '../suggestionPreferencesTabs/suggestionPreferencesTasksTab';
import { ISuggestionPreferencesComponent } from './SuggestionMultiselectStatus';

interface IProps {
  suggestion: ISuggestionPreferencesComponent;
  parameters: SuggestionPreferences | null;
  enabled?: boolean | null;
  updateMultipleSuggestionPreferences: (
    properties: {
      name: keyof SuggestionPreferences;
      value: any;
    }[],
  ) => void;
}

const SuggestionSelectEntity = ({
  suggestion,
  parameters,
  enabled = true,
  updateMultipleSuggestionPreferences,
}: IProps) => {
  const {
    isExternalPropertyStatus: isExternalPropertyStatusAccount,
    externalPropertyDefaultStatus: externalPropertyDefaultStatusAccount,
    externalPropertyStatusId: externalPropertyStatusIdAccount,
  } = useGetStatusesOptions('account');
  const {
    isExternalPropertyStatus: isExternalPropertyStatusProspect,
    externalPropertyDefaultStatus: externalPropertyDefaultStatusProspect,
    externalPropertyStatusId: externalPropertyStatusIdProspect,
  } = useGetStatusesOptions('prospect');

  const handleChangeEntity = (
    ev: any,
    task: ISuggestionPreferencesComponent,
  ) => {
    const fieldsToChange: { name: keyof SuggestionPreferences; value: any }[] =
      [{ name: task.entity, value: ev.target.value }];
    //If accout or prospect is selected and the status is empty, I set the default value
    if (ev.target.value === 'Account') {
      //If account is externalPropertyStatus we patch the customStatus and set accountStatus to null
      if (isExternalPropertyStatusAccount) {
        fieldsToChange.push({
          name: task.customStatusId,
          value: externalPropertyStatusIdAccount,
        });
        fieldsToChange.push({
          name: task.accountStatus,
          value: [],
        });
        fieldsToChange.push({
          name: task.customStatus,
          value: [externalPropertyDefaultStatusAccount],
        });
      } else {
        fieldsToChange.push({
          name: task.customStatusId,
          value: null,
        });
        if ((parameters?.[task.accountStatus]?.length ?? 0) === 0) {
          //If it is not externalPropertyStatus and the value is empty, I set the default value
          fieldsToChange.push({
            name: task.accountStatus,
            value: [externalPropertyDefaultStatusAccount],
          });
        }
      }
    } else if (ev.target.value === 'Prospect') {
      //If prospect is externalPropertyStatus we patch the customStatus and set prospectStatus to null
      if (isExternalPropertyStatusProspect) {
        fieldsToChange.push({
          name: task.customStatusId,
          value: externalPropertyStatusIdProspect,
        });
        fieldsToChange.push({
          name: task.prospectStatus,
          value: [],
        });
        fieldsToChange.push({
          name: task.customStatus,
          value: [externalPropertyDefaultStatusProspect],
        });
      } else {
        fieldsToChange.push({
          name: task.customStatusId,
          value: null,
        });
        if ((parameters?.[task.prospectStatus]?.length ?? 0) === 0) {
          //If it is not externalPropertyStatus and the value is empty, I set the default value
          fieldsToChange.push({
            name: task.prospectStatus,
            value: [externalPropertyDefaultStatusProspect],
          });
        }
      }
    }
    updateMultipleSuggestionPreferences(fieldsToChange);
  };

  return (
    <>
      {parameters?.[suggestion.entity] !== undefined && (
        <Select
          className="select-suggestions-preferences"
          value={parameters?.[suggestion.entity] ?? 'Off'}
          onChange={(ev) => {
            handleChangeEntity(ev, suggestion);
          }}
          disabled={!enabled}
          sx={{
            fontSize: '14px',
            boxShadow: 'none',
            color: 'inherit',
            '.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${colors.grey4}`,
              borderRadius: 2,
            },
            '.MuiOutlinedInput-input': {
              padding: '4px 4px 4px 6px',
            },
            padding: '2px',
          }}
          MenuProps={{
            style: { zIndex: 3501 },
            PaperProps: {
              sx: {
                borderRadius: '8px',
                paddingInline: '6px',
                border: '1px solid #dadde9',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                '& .MuiMenuItem-root': {
                  fontSize: '12px',
                  lineHeight: '16px',
                  height: 'fit-content',
                  padding: '8px',
                  cursor: 'pointer',
                  width: '100%',
                  borderRadius: '8px',
                  '&:hover': {
                    background: colors.primaryLight,
                    color: colors.primary,
                  },
                },
                '& .Mui-selected': {
                  color: colors.primary,
                  backgroundColor: 'transparent !important',
                },
              },
            },
          }}
        >
          <CronoMenuItem value="Off">Off</CronoMenuItem>
          <CronoMenuItem value="Prospect">Contact</CronoMenuItem>
          <CronoMenuItem value="Account">Company</CronoMenuItem>
        </Select>
      )}
    </>
  );
};

export default SuggestionSelectEntity;
