import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  IndexFirstLinkedin,
  IndexFirstLinkedinAfterInvitation,
} from 'utils/fe-utils';
import SequenceInstanceTaskCompletedComponent from './sequenceInstanceTaskCompletedComponent';
import SequenceInstanceTaskComponent from './sequenceInstanceTaskComponent';
import { useConditionalSnackBar } from 'context/snackbar';
import { useConfirmModal } from 'context/confirmModal';
import { getError } from 'crono-fe-common/utils';
import { SequenceInstance } from 'crono-fe-common/types/sequenceInstance';
import useJumpToSequenceInstanceStep from 'hooks/services/sequence/useJumpToSequenceInstanceStep';
import { FeConstants } from 'constants/FeConstants';
import { useJuneAnalytics } from 'context/june';
import useAddSequenceInstanceStep from 'hooks/services/task/useAddSequenceInstanceStep';
import { colors } from 'crono-fe-common/theme';
import useDeleteTask from 'hooks/services/task/useDeleteTask';
import usePatchTask from 'hooks/services/task/usePatchTask';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { Template } from 'crono-fe-common/types/template';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { SwitchToTask } from 'crono-fe-common/types/enums/switchToConditionalStep';
import TemplatesTooltipCard from 'pages/accountTab/emailView/TemplatesTooltipCard';
import StrategyXarrow, {
  InvitationAcceptedBadge,
  InvitationNotAcceptedBadge,
} from 'context/strategyDetail/strategyXarrow';
import moment from 'moment/moment';

interface IProps {
  sequenceInstance: SequenceInstance;
  sequenceInstanceId: number;
  prospectId: string | undefined;
  onClose: () => void;
  handleOpenDateCalendar: (task: TaskTodo) => void;
  taskOrder?: number;
  allowToPersonalize?: boolean;
}

const SequenceInstanceContent = ({
  sequenceInstance,
  sequenceInstanceId,
  prospectId,
  onClose,
  handleOpenDateCalendar,
  taskOrder,
  allowToPersonalize,
}: IProps) => {
  const analytics = useJuneAnalytics();

  const { mutate: patchTask, error: errorPatchTask } = usePatchTask();

  const { mutate: deleteTask, error: errorDeleteTask } = useDeleteTask();

  const [stepTemplateIndex, setStepTemplateIndex] = useState<number | null>(
    null,
  );

  //To get the correct type of template to open if we are picking for a conditionalStep
  const [conditionalStepTemplateType, setConditionalStepTemplate] =
    useState<SwitchToTask | null>(null);

  const onAdd = (
    templateId: number,
    conditionalStepTemplateType: SwitchToTask | null,
  ) => {
    if (stepTemplateIndex === null) return;
    //If I'm changing a conditionalStep and not the original one
    if (conditionalStepTemplateType) {
      return;
    }
    patchTask({
      id: sequenceInstance.tasks[stepTemplateIndex].id,
      templateId: templateId,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'template',
      });
    }
    setStepTemplateIndex(null);
    setSequenceStepTemplateId(null);
    setConditionalStepTemplate(null);
  };

  const {
    mutate: jumpToStep,
    isSuccess: successJump,
    error: errorJumpToSequenceInstanceStep,
  } = useJumpToSequenceInstanceStep();

  const handleJumpToStep = (index: number) => {
    if (!prospectId) return;
    if (
      sequenceInstance.id !== 0 &&
      sequenceInstance.tasks[index].order !== null
    ) {
      jumpToStep({
        prospectId: prospectId,
        sequenceInstanceId: sequenceInstance.id,
        step: sequenceInstance.tasks[index].order!,
      });
      if (analytics) {
        analytics.track('jump-to-step', {});
      }
    }
  };
  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteTask = (index: number) => {
    openConfirmModal({
      title: 'Are you sure you want to delete this task?',
      text: 'The task will be deleted',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        if (sequenceInstance) {
          deleteTask(sequenceInstance.tasks[index].id);
          if (analytics) {
            analytics.track('patch-task', {
              type: 'delete',
            });
          }
        }
      },
      cancelFunction: () => null,
    });
  };

  const handleChangeDelay = (increment: number, index: number) => {
    //Copy the array of steps
    const tasks = [...(sequenceInstance.tasks || [])];
    //Update the step
    if (tasks[index].delay === null) return;
    if (
      tasks[index].delay! + increment >= FeConstants.MIN_DELAY &&
      tasks[index].delay! + increment <= FeConstants.MAX_DELAY
    )
      tasks[index].delay = tasks[index].delay! + increment;
    // tasks[index].untilAccepted = false;

    //Update the sequenceInstance
    //setSequence({ ...sequenceInstance, tasks });
    if (sequenceInstance) {
      patchTask({
        id: tasks[index].id,
        delay: tasks[index].delay!,
      });

      if (analytics) {
        analytics.track('patch-task', {
          type: 'delay',
        });
      }
    }
  };

  const handleChangeAutomatic = (automatic: boolean, index: number) => {
    //Copy the array of steps
    const tasks = [...(sequenceInstance.tasks || [])];
    //Update the step
    tasks[index].automatic = automatic;
    //Update the sequenceInstance
    //setSequence({ ...sequenceInstance, tasks });
    if (sequenceInstance) {
      patchTask({
        id: tasks[index].id,
        automatic: tasks[index].automatic,
      });

      if (analytics) {
        analytics.track('patch-task', {
          type: 'automatic',
        });
      }
    }
  };

  const handleChangeStepType = (
    newType: { type: TaskTodoType; subtype: TaskTodoSubtype },
    index: number,
  ) => {
    //If the type is the same, we don't do anything
    if (newType.type === sequenceInstance.tasks[index].type) {
      if (
        newType.type !== TaskTodoType.LINKEDIN ||
        newType.subtype === sequenceInstance.tasks[index].subtype
      )
        return;
    }
    //Copy the array of tasks
    const tasks = [...(sequenceInstance.tasks || [])];

    //Update the step
    tasks[index].type = newType.type;
    tasks[index].subtype = newType.subtype;

    //I make sure that the new step is not automatic
    tasks[index].automatic = false;
    //Clear the template
    tasks[index].template = null;
    tasks[index].templateId = null;
    //setSequence({ ...sequenceInstance, tasks });
    if (sequenceInstance) {
      patchTask({
        id: tasks[index].id,
        type: tasks[index].type,
        subtype: tasks[index].subtype,
        automatic: tasks[index].automatic,
        templateId: tasks[index].templateId ?? undefined,
      });

      if (analytics) {
        analytics.track('patch-task', {
          type: 'type',
        });
      }
    }
  };

  const handleChangeTime = (time: Date, index: number) => {
    //Copy the array of tasks
    const tasks = [...(sequenceInstance.tasks || [])];

    tasks[index].activityDate = time;
    if (sequenceInstance) {
      patchTask({
        id: tasks[index].id,
        activityDate: time,
      });

      if (analytics) {
        analytics.track('patch-task', {
          type: 'schedule-time',
        });
      }
    }
  };

  const handleChangeReplyToThread = (replyToThread: boolean, index: number) => {
    if (sequenceInstance) {
      patchTask({
        id: sequenceInstance.tasks[index].id,
        replyToThread: replyToThread,
      });

      if (analytics) {
        analytics.track('patch-task', {
          type: 'replyToThread',
        });
      }
    }
  };

  const handleSkipTask = (index: number) => {
    openConfirmModal({
      title: 'Are you sure you want to skip this task?',
      text: 'The task will be skipped',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        patchTask({
          id: sequenceInstance.tasks[index].id,
          skipped: true,
        });
      },
      cancelFunction: () => null,
    });
  };

  const handleAllChanges = (type: string, payload: any, index: number) => {
    switch (type) {
      case 'delay':
        handleChangeDelay(payload, index);
        break;
      case 'automatic':
        handleChangeAutomatic(payload, index);
        break;
      case 'type':
        handleChangeStepType(payload, index);
        break;
      case 'delete':
        handleDeleteTask(index);
        break;
      case 'scheduleTime':
        handleChangeTime(payload, index);
        break;
      case 'replyToThread':
        handleChangeReplyToThread(payload, index);
        break;
      case 'skip':
        handleSkipTask(index);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (successJump) {
      onClose();
    }
  }, [successJump]);

  const {
    mutate: addSequenceInstanceStep,
    isSuccess: stepAdded,
    error: errorAddingStep,
  } = useAddSequenceInstanceStep();

  const handleAddStep = (index: number) => {
    addSequenceInstanceStep({
      sequenceInstanceId: sequenceInstanceId,
      step: index + 1,
      data: {
        type: TaskTodoType.EMAIL,
        automatic: false,
        delay: 1,
        templateId: null,
      },
    });
    if (analytics) {
      analytics.track('patch_sequence_instance', {
        patch: 'add_task',
        sequenceInstanceId: sequenceInstanceId,
      });
    }
  };

  const indexFirstLinkedin = useMemo(() => {
    return IndexFirstLinkedin(sequenceInstance.tasks ?? []);
  }, [sequenceInstance.tasks]);

  const handleTemplatePicker = (type: SwitchToTask) => {
    setConditionalStepTemplate(type);
  };

  const [sequenceStepTemplateId, setSequenceStepTemplateId] = useState<
    number | null
  >(null);

  const currentTemplateType =
    stepTemplateIndex === null
      ? TemplateType.EMAIL
      : conditionalStepTemplateType === SwitchToTask.TO_EMAIL
        ? TemplateType.EMAIL
        : conditionalStepTemplateType === SwitchToTask.IN_MAIL
          ? TemplateType.IN_MAIL
          : sequenceInstance.tasks[stepTemplateIndex].type ===
              TaskTodoType.EMAIL
            ? TemplateType.EMAIL
            : sequenceInstance.tasks[stepTemplateIndex].type ===
                TaskTodoType.IN_MAIL
              ? TemplateType.IN_MAIL
              : sequenceInstance.tasks[stepTemplateIndex].subtype ===
                  TaskTodoSubtype.LinkedinInvitation
                ? TemplateType.INVITATION
                : sequenceInstance.tasks[stepTemplateIndex].subtype ===
                    TaskTodoSubtype.LinkedinVoiceNote
                  ? TemplateType.SCRIPT
                  : TemplateType.LINKEDIN;

  const memoizedTemplateComponent = useMemo(() => {
    if (stepTemplateIndex !== null) {
      return (
        <TemplatesTooltipCard
          selectedTemplateId={
            conditionalStepTemplateType
              ? sequenceInstance.tasks[stepTemplateIndex]
                  .sequenceStepConditional?.template?.id ?? null
              : sequenceInstance.tasks[stepTemplateIndex].template?.id ?? null
          }
          setSelectedTemplate={(template: Template) =>
            onAdd(template.id, conditionalStepTemplateType)
          }
          hideShared={false}
          hideAll={false}
          selectedType={currentTemplateType}
          showAnalytics={true}
          close={() => {
            setStepTemplateIndex(null);
            setConditionalStepTemplate(null);
            setSequenceStepTemplateId(null);
          }}
          sequenceStepTemplateId={sequenceStepTemplateId}
          visibility={'visible'}
        />
      );
    } else {
      return null;
    }
  }, [stepTemplateIndex]);

  //Since the arrows are declared at this level they do not notice if the child component changes, so we need to make it trigger
  //The ping from child is called everytime the child component changes

  const [keyArrow, setKeyArrow] = useState<number>(0);

  const elementRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      setKeyArrow(Math.random() * 100000);
    });
    resizeObserver.observe(node);
  }, []);
  const [completedSteps, todoSteps] = (() => {
    const completedSteps: any[] = [];
    const todoSteps: any[] = [];
    const tasks = sequenceInstance.tasks ?? [];
    const indexFirstLinkedinAfterInvitation =
      IndexFirstLinkedinAfterInvitation(tasks);

    if (tasks.length > 0) {
      const firstStepWithConditionalIndex = tasks.findIndex(
        (t) => t.sequenceStepConditional && !t.completed,
      );
      const tasksContainsConditionals = firstStepWithConditionalIndex !== -1;
      let conditionalTaskActivityDate: moment.Moment | null = null;
      if (tasks[firstStepWithConditionalIndex]) {
        conditionalTaskActivityDate = moment(
          tasks[firstStepWithConditionalIndex].activityDate,
        ).businessAdd(-1 * (tasks[firstStepWithConditionalIndex].delay ?? 0));
      }

      sequenceInstance.tasks.forEach((task, index, tasks) => {
        if (task.completed) {
          completedSteps.push(
            <SequenceInstanceTaskCompletedComponent
              key={`${index}-${sequenceInstanceId}`}
              task={task}
              index={index}
            />,
          );
        } else {
          const prevTask = index !== 0 ? tasks[index - 1] : null;
          const enableBadges = index === indexFirstLinkedinAfterInvitation;
          const conditionalAfterInvitation =
            prevTask?.subtype === TaskTodoSubtype.LinkedinInvitation &&
            !!task.sequenceStepConditional;

          if (conditionalTaskActivityDate) {
            if (task.sequenceStepConditional) {
              conditionalTaskActivityDate =
                conditionalTaskActivityDate.businessAdd(
                  task.sequenceStepConditional.daysAfterConditionNotAccepted,
                );
            } else {
              if (index > firstStepWithConditionalIndex) {
                conditionalTaskActivityDate =
                  conditionalTaskActivityDate.businessAdd(task.delay ?? 0);
              }
            }
          }

          todoSteps.push(
            <>
              <SequenceInstanceTaskComponent
                key={`${index}-${sequenceInstanceId}`}
                allowToPersonalize={allowToPersonalize}
                task={task}
                linkedinUntilAcceptedDays={
                  tasks[indexFirstLinkedinAfterInvitation]
                    ?.sequenceStepConditional?.linkedinUntilAcceptedDays
                }
                index={index}
                handleChange={handleAllChanges}
                handleJumpToStep={handleJumpToStep}
                openTemplatePicker={(index: number) => {
                  setStepTemplateIndex(index);
                  setConditionalStepTemplate(null);
                }}
                handleTemplatePicker={(type: SwitchToTask) => {
                  handleTemplatePicker(type);
                  setStepTemplateIndex(index);
                }}
                openDateCalendar={handleOpenDateCalendar}
                firstTask={index === sequenceInstance.actualStep}
                opened={taskOrder ? task.order === taskOrder : false}
                handleRemoveTemplate={(index: number) => {
                  if (sequenceInstance.tasks[index].id) {
                    const step = sequenceInstance.tasks[index];
                    const newAutomatic =
                      step.type !== TaskTodoType.LINKEDIN ||
                      step.subtype !== TaskTodoSubtype.LinkedinInvitation;
                    patchTask({
                      id: sequenceInstance.tasks[index].id,
                      templateId: -1,
                      ...(step.automatic ? { automatic: !newAutomatic } : {}),
                    });
                    if (analytics) {
                      analytics.track('patch-task', {
                        type: 'template',
                      });
                    }
                  }
                }}
                tasks={tasks}
                setSequenceStepTemplateId={setSequenceStepTemplateId}
                templateComponent={memoizedTemplateComponent}
                showTemplateTab={stepTemplateIndex === index}
                closeTemplateTooltip={() => {
                  setStepTemplateIndex(null);
                  setConditionalStepTemplate(null);
                  setSequenceStepTemplateId(null);
                }}
                taskConditionalActivityDate={conditionalTaskActivityDate?.format(
                  'll',
                )}
              />
              {indexFirstLinkedin !== null &&
                index > indexFirstLinkedin &&
                tasksContainsConditionals &&
                prevTask && (
                  <>
                    <StrategyXarrow
                      key={`${index}-1-${keyArrow}`}
                      gridBreak={conditionalAfterInvitation ? '50%' : '100%-80'}
                      start={prevTask.id.toString()}
                      end={task.id.toString()}
                      color={
                        !task.sequenceStepConditional &&
                        !prevTask.sequenceStepConditional
                          ? colors.grey33
                          : colors.darkGreen
                      }
                      labels={
                        enableBadges
                          ? {
                              middle: (
                                <InvitationAcceptedBadge
                                  extraMargin={!conditionalAfterInvitation}
                                />
                              ),
                            }
                          : undefined
                      }
                      zIndex={-1}
                    />
                    {prevTask.sequenceStepConditional ||
                    task.sequenceStepConditional ? (
                      <StrategyXarrow
                        key={`${index}-2-${keyArrow}`}
                        gridBreak={
                          conditionalAfterInvitation ? '50%' : '100%-80'
                        }
                        start={
                          prevTask.sequenceStepConditional &&
                          prevTask.active &&
                          !prevTask.completed
                            ? `${prevTask.sequenceStepConditional.id}-conditional`
                            : prevTask.id.toString()
                        }
                        end={
                          task.sequenceStepConditional
                            ? `${task.sequenceStepConditional.id.toString()}-conditional`
                            : task.id.toString()
                        }
                        color={colors.inactive}
                        labels={
                          enableBadges
                            ? {
                                middle: (
                                  <InvitationNotAcceptedBadge
                                    extraMargin={!conditionalAfterInvitation}
                                  />
                                ),
                              }
                            : undefined
                        }
                      />
                    ) : null}
                  </>
                )}
            </>,
          );
        }
      });
    }

    return [completedSteps, todoSteps];
  })();

  useConditionalSnackBar([
    {
      condition: !!stepAdded,
      message: 'Step added correctly',
      severity: 'success',
    },
    {
      condition: !!errorAddingStep,
      message: getError(errorAddingStep) ?? 'Error adding the step',
      severity: 'error',
    },
    {
      condition: !!successJump,
      message: 'Jump to the step correctly performed',
      severity: 'success',
    },
    {
      condition: !!errorJumpToSequenceInstanceStep,
      message:
        getError(errorJumpToSequenceInstanceStep) ??
        'Error jumping to the step',
      severity: 'error',
    },
    {
      condition: !!errorPatchTask,
      message: getError(errorPatchTask) ?? 'Error patching the task',
      severity: 'error',
    },
    {
      condition: !!errorDeleteTask,
      message: getError(errorDeleteTask) ?? 'Error deleting the task',
      severity: 'error',
    },
  ]);

  const todoStepsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!completedSteps || !todoStepsRef.current) return;
    if (taskOrder) {
      const children: any[] = [].slice.call(todoStepsRef.current.children);
      const selectedChild = children.find(
        (child) => child.id === '' + taskOrder,
      );
      if (selectedChild) {
        selectedChild.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    } else {
      //Go to the first one to do
      const firstTodo = todoStepsRef.current.children[0];
      if (firstTodo) {
        firstTodo.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [completedSteps.length, taskOrder]);

  return (
    <div className="sequence-steps-container">
      {completedSteps.length > 0 && (
        <div className="completed-steps" id="completed-steps-div">
          {completedSteps}
        </div>
      )}
      <div ref={elementRef}>
        <div className="todo-steps" ref={todoStepsRef} id="todo-steps-div">
          {todoSteps}
        </div>
      </div>
    </div>
  );
};

export default SequenceInstanceContent;
