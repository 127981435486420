import { TASK_AUTOMATIC_ERROR_BANNER_KEY } from 'config/localStorage';
import useGetTasksAutomationErrorsMetaData from 'hooks/services/task/useGetTasksAutomationErrorsMetaData';
import React, { useEffect, useState } from 'react';

interface ITaskAutomaticErrorBannerData {
  errorDateTime?: string;
  isClosed: boolean;
}

export const useGetAutomationsErrorBanner = () => {
  const { data: automationErrorsMetaData } =
    useGetTasksAutomationErrorsMetaData();
  const [automationErrorBanner, setAutomationErrorBanner] = useState({
    isVisible: false,
    errorsCount: 0,
  });

  const getTaskAutomaticErrorBannerData = () => {
    const dateTimeString = localStorage.getItem(
      TASK_AUTOMATIC_ERROR_BANNER_KEY,
    );
    if (dateTimeString) {
      return JSON.parse(dateTimeString) as ITaskAutomaticErrorBannerData;
    }

    return null;
  };

  const saveTaskAutomaticErrorBannerData = (
    automaticErrorData: ITaskAutomaticErrorBannerData,
  ) => {
    const newValue = JSON.stringify(automaticErrorData);
    return localStorage.setItem(TASK_AUTOMATIC_ERROR_BANNER_KEY, newValue);
  };

  useEffect(() => {
    if (automationErrorsMetaData && automationErrorsMetaData.quantity > 0) {
      const automaticErrorBannerData = getTaskAutomaticErrorBannerData();

      if (
        automaticErrorBannerData &&
        automaticErrorBannerData.errorDateTime ===
          automationErrorsMetaData.lastFailedAttempt
      ) {
        setAutomationErrorBanner({
          isVisible: !automaticErrorBannerData.isClosed,
          errorsCount: automationErrorsMetaData.quantity,
        });
      } else {
        setAutomationErrorBanner({
          isVisible: true,
          errorsCount: automationErrorsMetaData.quantity,
        });

        saveTaskAutomaticErrorBannerData({
          isClosed: false,
          errorDateTime: automationErrorsMetaData.lastFailedAttempt,
        });
      }
    } else if (
      automationErrorsMetaData &&
      automationErrorsMetaData.quantity === 0
    ) {
      setAutomationErrorBanner({
        isVisible: false,
        errorsCount: 0,
      });
    }
  }, [automationErrorsMetaData]);

  const handleAutomationErrorBannerClose = () => {
    setAutomationErrorBanner({
      isVisible: false,
      errorsCount: 0,
    });

    const automaticErrorBannerData = getTaskAutomaticErrorBannerData();

    if (automaticErrorBannerData) {
      saveTaskAutomaticErrorBannerData({
        ...automaticErrorBannerData,
        isClosed: true,
      });
    }
  };

  return { automationErrorBanner, handleAutomationErrorBannerClose };
};
