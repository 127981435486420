import React, { useEffect, useMemo, useState } from 'react';
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import EditorToolbar, {
  EditorToolbarProps,
  redoChange,
  undoChange,
} from './CronoEditorToolbar';
import { EditorWrapper } from './styles';
import { FeConstants } from 'constants/FeConstants';
import useUploadFiles from 'hooks/services/templates/useUploadFiles';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';
import { useImageFilePicker } from 'hooks/useImageFilePicker';
import { FileContent } from 'use-file-picker';

export type CustomButtonType =
  | 'attachments'
  | 'templates'
  | 'insights'
  | 'variables'
  | 'rewrite'
  | 'image-upload';

interface IProps extends ReactQuillProps, EditorToolbarProps {
  editorRef: React.MutableRefObject<ReactQuill | null>;
  onFocus?: () => void;
  imageSupport?: boolean;
  templateComponent?: JSX.Element | null;
  closeTemplateTooltip?: () => void;
  showTemplateTab?: boolean;
  onAttachmentDrop?: (files: FileContent[]) => void;
}

const CronoEditor = ({
  editorRef,
  children,
  toolbarId = 'toolbar',
  imageSupport = false,
  templateComponent,
  closeTemplateTooltip,
  showTemplateTab,
  customButtons,
  onAttachmentDrop,
  hideStylingButtons,
  ...props
}: IProps) => {
  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
      handlers: {
        undo: undoChange,
        redo: redoChange,
      },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
  };

  const [imageSupportIsDisabledError, setImageSupportIsDisabledError] =
    useState<boolean>(false);

  const {
    mutate: uploadFiles,
    data: uploadFilesResponse,
    error: uploadFilesError,
  } = useUploadFiles();

  useConditionalSnackBar([
    {
      condition: !!uploadFilesError,
      message: getError(uploadFilesError) ?? 'Image upload has failed',
      severity: 'error',
    },
    {
      condition: imageSupportIsDisabledError,
      message: 'You can upload image only for an email',
      severity: 'error',
    },
  ]);

  const insertImagePlaceholders = (count: number) => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      const range = editor.getSelection(true);
      const index = range.index + range.length;

      for (let i = 0; i < count; i++) {
        editor.insertEmbed(index + i, 'placeholder', {});
      }
    }
  };

  const deleteImagePlaceholders = () => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      const contents = editor.getContents();
      let index = 0;
      const placeholders: number[] = [];

      contents.ops?.forEach((op) => {
        if (
          op.insert &&
          typeof op.insert === 'object' &&
          op.insert.placeholder
        ) {
          placeholders.push(index);
        }
        index += op.insert
          ? typeof op.insert === 'object'
            ? 1
            : op.insert.length
          : 0;
      });

      placeholders.reverse().forEach((pos) => {
        editor.deleteText(pos, 1);
      });
    }
  };

  const analytics = useJuneAnalytics();

  const uploadImagesIfSupported = (files: File[]) => {
    if (files.length > 0) {
      if (imageSupport) {
        if (analytics) {
          analytics.track('image-attached', {});
        }
        insertImagePlaceholders(files.length);
        const images: any[] = [];
        let filesRead = 0;

        files.forEach((file: any) => {
          const reader = new FileReader();
          reader.onload = () => {
            images.push({
              name: file.name,
              content: reader.result as string,
            });
            filesRead++;

            // Check if all files have been read
            if (filesRead === files.length && editorRef.current) {
              uploadFiles({
                files: images,
              });
            }
          };
          reader.readAsDataURL(file);
        });
      } else {
        // we handle them as attachments in some cases inside TemplateManagement
        if (onAttachmentDrop) {
          handleAttachments(files);
        } else {
          setImageSupportIsDisabledError(true);
          setTimeout(() => setImageSupportIsDisabledError(false), 3000);
        }
      }
    }
  };

  const handleAttachments = (files: File[]) => {
    if (files.length > 0 && onAttachmentDrop) {
      const fileContents: FileContent[] = [];
      let filesRead = 0;

      files.forEach((file: any) => {
        const reader = new FileReader();
        reader.onload = () => {
          // it was not working with ...file
          fileContents.push({
            name: file.name,
            size: file.size,
            type: file.type,
            slice: file.slice,
            text: file.text,
            stream: file.stream,
            arrayBuffer: file.arrayBuffer,
            lastModified: file.lastModified,
            content: reader.result as string,
          });
          filesRead++;

          // Check if all files have been read
          if (filesRead === files.length && editorRef.current) {
            onAttachmentDrop(fileContents);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const { dataTransfer } = event;

    if (dataTransfer && dataTransfer.files && dataTransfer.files.length > 0) {
      const images = Array.from(dataTransfer.files).filter((file: any) =>
        file.type.startsWith('image/'),
      ) as File[];

      uploadImagesIfSupported(images);

      const otherFiles = Array.from(dataTransfer.files).filter(
        (file: any) => !file.type.startsWith('image/'),
      ) as File[];

      handleAttachments(otherFiles);
    }
  };

  useEffect(() => {
    if (!editorRef.current) return;
    const editor = editorRef.current.getEditor();

    const handlePaste = (event: any) => {
      const clipboardData = event.clipboardData;
      const items = clipboardData.items;
      const files = [];

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const file = items[i].getAsFile();
          files.push(file);
        }
      }

      // We do it here not to break text pasting
      if (files.length > 0) {
        event.preventDefault();
        event.stopPropagation();
      }
      uploadImagesIfSupported(files);
    };

    editor.root.addEventListener('paste', handlePaste);

    return () => {
      editor.root.removeEventListener('paste', handlePaste);
    };
  }, [editorRef.current]);

  useEffect(() => {
    const responseData = uploadFilesResponse?.data?.data;
    if (responseData?.length && responseData.length > 0) {
      if (editorRef.current) {
        const editor = editorRef.current.getEditor();
        const range = editor.getSelection(true);
        const index = range.index + range.length;

        deleteImagePlaceholders();
        responseData.forEach((imageName: string) => {
          const urlRepresentation = new URLSearchParams({ imageName });
          const encodedImageName = urlRepresentation.toString().split('=')[1];
          editor.insertEmbed(
            index,
            'image',
            `${process.env.REACT_APP_S3_FILES_URL}/${encodedImageName}`,
          );
        });
      }
    }
  }, [uploadFilesResponse]);

  useEffect(() => {
    deleteImagePlaceholders();
  }, [uploadFilesError]);

  // this removes the image resize overlay (border) when clicking outside the image or scrolling editor content
  useEffect(() => {
    const quillEditorContext = editorRef.current;
    if (!quillEditorContext) return;
    const quill = quillEditorContext.getEditor();
    const imageResize = quill.getModule('imageResize');
    const quillRoot = quill.root;
    const hideOverlayOnClick = async (event: any) => {
      if (!quillRoot.contains(event.target)) {
        imageResize.hide();
      }
    };
    const hideOverlay = async (event: any) => {
      imageResize.hide();
    };

    const wrapper = document.getElementById('editor-wrapper');
    const editor = document.getElementsByClassName('ql-editor')[0];

    wrapper?.addEventListener('click', hideOverlayOnClick);
    editor?.addEventListener('scroll', hideOverlay);

    return () => {
      wrapper?.removeEventListener('click', hideOverlayOnClick);
      editor?.removeEventListener('scroll', hideOverlay);
    };
  }, [editorRef.current]);

  const [openFileSelector, { filesContent, errorMessage }] =
    useImageFilePicker();
  useEffect(() => {
    if (filesContent.length > 0) {
      insertImagePlaceholders(filesContent.length);
      uploadFiles({ files: filesContent });
    }
  }, [filesContent]);

  const quillFormats = useMemo(() => {
    if (imageSupport) {
      return [
        ...FeConstants.editorToolbarFormats,
        ...FeConstants.editorToolbarAdvanceFormattingFormats,
        'image',
      ];
    } else if (!hideStylingButtons) {
      return [
        ...FeConstants.editorToolbarFormats,
        ...FeConstants.editorToolbarAdvanceFormattingFormats,
      ];
    } else {
      return FeConstants.editorToolbarFormats;
    }
  }, [imageSupport, hideStylingButtons]);

  return (
    <EditorWrapper id={'editor-wrapper'}>
      <EditorToolbar
        {...props}
        toolbarId={toolbarId}
        editorRef={editorRef}
        closeTemplateTooltip={closeTemplateTooltip}
        showTemplateTab={showTemplateTab}
        templateComponent={templateComponent}
        hideStylingButtons={hideStylingButtons}
        customButtons={
          imageSupport && customButtons
            ? [
                ...customButtons,
                {
                  type: 'image-upload',
                  onClick: openFileSelector,
                },
              ]
            : customButtons
        }
      />
      <div
        data-text-editor="editor-wrapper"
        className="quill-editor"
        onDrop={handleDrop}
      >
        <ReactQuill
          {...props}
          ref={(editor) => {
            if (editor) {
              editorRef.current = editor;
            }
          }}
          id="editor"
          theme={props.readOnly ? 'bubble' : 'snow'}
          modules={modules}
          formats={quillFormats}
          bounds={`[data-text-editor="editor-wrapper"]`}
        />
      </div>
      {children}
    </EditorWrapper>
  );
};

export default CronoEditor;
