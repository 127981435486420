import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';
import useCreateAccount from 'hooks/services/account/useCreateAccount';
import React, { useEffect, useMemo, useState } from 'react';
import { LinkedinCompany } from 'crono-fe-common/types/crono-extension/linkedin';
import {
  convertPeopleStringToNumber,
  getError,
  getNewOptionsWhenSelectingWithStrings,
  mapAccountToAccountLinkedinAndCorrect,
} from 'crono-fe-common/utils';
import {
  AccountInputs,
  IExternalValueInsert,
  initialValues,
  validationSchema,
} from './model';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useFormik } from 'formik';
import { trimObject } from 'crono-fe-common/utils/object';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import {
  Avatar,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CancelButton,
  MainButton,
  TabSelectionButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useConditionalSnackBar } from 'context/snackbar';
import useAccountExists from 'hooks/services/account/useAccountExists';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { InsertCompanyWrapper } from './style';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import { ReactComponent as WebsiteIcon } from 'crono-fe-common/icons/website.svg';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import { ReactComponent as IndustryIcon } from 'crono-fe-common/icons/Industry.svg';
import { ReactComponent as MoneyIcon } from 'crono-fe-common/icons/Money.svg';
import { ReactComponent as ToolsIcon } from 'crono-fe-common/icons/Tools.svg';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import ExternalPropertyNotTag from 'pages/accountTab/externalPropertyNotTag';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import {
  useLinkedinGetCompanyActivities,
  useLinkedinGetCompanyInfo,
  useLinkedinGetCompanyInfoFromNumericId,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import { Constants } from 'crono-fe-common/constants/constants';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import { colors } from 'crono-fe-common/theme';
import { CompanyForSearchTable } from '../model';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';

interface IProps {
  selectedCompany: CompanyForSearchTable;
  close: () => void;
  handleSetInCrono: (id: string, newCompany: AccountLinkedin | null) => void;
}

interface IInformation {
  name: keyof AccountInputs;
  label?: string;
  icon: JSX.Element;
}

const contactInformation: IInformation[] = [
  {
    name: 'phone',
    label: 'Phone',
    icon: <CallIcon className="info-icon" />,
  },
  {
    name: 'website',
    label: 'Website',
    icon: <WebsiteIcon className="info-icon" />,
  },
  {
    name: 'linkedin',
    label: 'LinkedIn',
    icon: <LinkedinIcon className="info-icon" />,
  },
];

const accountInformation: IInformation[] = [
  {
    name: 'country',
    label: 'Country',
    icon: <IndustryIcon className="account-info-icon" />,
  },
  {
    name: 'industry',
    label: 'Industry',
    icon: <IndustryIcon className="account-info-icon" />,
  },
  {
    name: 'annualRevenue',
    label: 'Revenue',
    icon: <MoneyIcon className="account-info-icon" />,
  },
  {
    name: 'numberOfEmployees',
    label: 'Employees',
    icon: <MoneyIcon className="account-info-icon" />,
  },
  {
    name: 'currentSolution',
    label: 'Solution',
    icon: <ToolsIcon className="account-info-icon" />,
  },
];

const InsertCompany = ({
  selectedCompany,
  close,
  handleSetInCrono,
}: IProps) => {
  const {
    isSuccess,
    isLoading,
    mutateAsync: createAccount,
    error,
  } = useCreateAccount();
  const [editingComplete, setEditingComplete] = useState(false);
  const { user } = useAuth();
  const analytics = useJuneAnalytics();
  const [createInCrm, setCreateInCrm] = useState(true);

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const [errorMandatoryFields, setErrorMandatoryFields] = useState<
    string | null
  >(null);

  //To clear the error after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorMandatoryFields) {
      timeout = setTimeout(() => {
        setErrorMandatoryFields(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [errorMandatoryFields]);

  const formik = useFormik<AccountInputs>({
    enableReinitialize: true,
    initialValues:
      {
        name: selectedCompany.name,
        linkedin: selectedCompany.url,
        annualRevenue: '',
        numberOfEmployees: convertPeopleStringToNumber(
          selectedCompany.empolyees,
        ),
        topTier: false,
        industry: selectedCompany.industry ?? '',
        website: '',
        phone: '',
        currentSolution: '',
        country: selectedCompany.location ?? '',
        linkedinNumericId: selectedCompany.numericId ?? undefined,
      } || initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (checkAccountExistsError()) return false;
      const trimmed = trimObject(values);

      const externalValuesToAdd: {
        [key: number]: string | null;
      } = {};
      externalValuesTag.forEach((value, index) => {
        if (value && externalPropertiesTag[index].id) {
          externalValuesToAdd[externalPropertiesTag[index].id] = value;
        }
      });
      externalValuesNotTag.forEach((value) => {
        if (value && value.value !== '') {
          externalValuesToAdd[value.externalPropertyId] = value.value;
        }
      });

      //Check if the mandatory fields are filled
      const mandatoryToFill: string[] = [];
      externalProperties?.data?.data.forEach((property) => {
        if (property.isMandatory && !externalValuesToAdd[property.id]) {
          mandatoryToFill.push(
            property.publicName ?? property.externalName ?? 'Unnamed property',
          );
        }
      });
      if (mandatoryToFill.length > 0) {
        setErrorMandatoryFields(mandatoryToFill.join(', '));
        return;
      }
      if (analytics) {
        analytics.track('create-account', {});
      }
      const res = await createAccount({
        ...trimmed,
        createInCrm,
        externalValues: externalValuesToAdd,
      });
      if (res.data?.data) {
        const newCompany = mapAccountToAccountLinkedinAndCorrect(
          res.data?.data ?? null,
        );
        handleSetInCrono(selectedCompany.id, newCompany);
        close();
      }
    },
  });

  const { data: externalProperties } = useGetExternalProperty(
    'Account',
    null,
    true,
  );

  const externalPropertiesTag: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter(
        (property) => property.isTag && property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  const externalPropertyNotTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter(
        (property) => !property.isTag && property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  //Initialize the externalValuesTag that has default value
  useEffect(() => {
    if (!externalPropertiesTag) return;
    const newExternalProperties: string[] = new Array(
      Constants.numberOfTags,
    ).fill(null);
    externalPropertiesTag.forEach((property, index) => {
      if (property.defaultValue) {
        newExternalProperties[index] = property.defaultValue;
      }
    });
    setExternalValuesTag(newExternalProperties);
  }, [externalPropertiesTag]);

  //Initialize the externalValuesNotTag that has default value
  useEffect(() => {
    if (!externalPropertyNotTags) return;
    const newExternalProperties: IExternalValueInsert[] = [];
    externalPropertyNotTags.forEach((property) => {
      if (property.defaultValue) {
        newExternalProperties.push({
          externalPropertyId: property.id,
          value: property.defaultValue,
          isTag: false,
        });
      }
    });
    setExternalValuesNotTag(newExternalProperties);
  }, [externalPropertyNotTags]);

  useEffect(() => {
    if (user?.company?.integrationType === IntegrationType.SALESFORCE) {
      setCreateInCrm(true);
    }
  }, [user]);

  const { data: accounts, isLoading: accountExistsloading } = useAccountExists(
    formik.values.name,
    editingComplete,
  );

  const {
    result: linkedinCompanyInfo,
    call: getLinkedinCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useLinkedinGetCompanyInfo();

  useEffect(() => {
    if (selectedCompany.url || selectedCompany.numericId) {
      getLinkedinCompanyInfo({
        url: selectedCompany.url,
        numericId: selectedCompany.numericId,
      });
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (linkedinCompanyInfo?.company) {
      formik.setFieldValue('linkedin', linkedinCompanyInfo.company.url || '');
      formik.setFieldValue(
        'numberOfEmployees',
        linkedinCompanyInfo.company.numberOfEmployees
          ? '' + linkedinCompanyInfo.company.numberOfEmployees
          : '',
      );
      formik.setFieldValue(
        'website',
        linkedinCompanyInfo.company.website || '',
      );
      formik.setFieldValue('phone', linkedinCompanyInfo.company.phone || '');
      formik.setFieldValue(
        'country',
        linkedinCompanyInfo.company.country || '',
      );
    }
  }, [linkedinCompanyInfo]);

  useConditionalSnackBar([
    {
      condition: !!error,
      message: 'Insert account error: ' + getError(error) ?? 'Unknown error',
      severity: 'error',
    },
    {
      condition: !!isSuccess,
      message: 'Account created succesfully',
      severity: 'success',
    },
    {
      condition: !!errorMandatoryFields,
      message: `The empty fields ${errorMandatoryFields} are mandatory. Add a value to continue`,
      severity: 'error',
    },
  ]);

  const checkAccountExistsError = () => {
    if (accountExistsloading) {
      return false;
    }
    if (accounts?.data?.data && accounts.data.data.length > 0) {
      return true;
    }
    return false;
  };

  const handleChangeCreateInCrm = () => {
    const newCrm = !createInCrm;
    setCreateInCrm(newCrm);
    patchUserPreferences({
      createInCrm: newCrm,
    });
    if (user) {
      if (user.userPreferences) {
        user.userPreferences.createInCrm = newCrm;
      } else {
        user.userPreferences = {
          createInCrm: newCrm,
        };
      }
    }
  };

  // const getAccountExistsError = () => {
  //   let errorMessage = "";
  //   if (accountExistsloading) {
  //     return errorMessage;
  //   }
  //   if (accounts?.data?.data && accounts.data.data.length > 0) {
  //     const account = accounts.data.data[0];
  //     errorMessage = `Account with name ${account.name} already exists`;
  //     if (user && account.userId !== user.id) {
  //       errorMessage += ", but is not owned by you";
  //     }
  //   }
  //   return errorMessage;
  // };

  const handleChangeInformation = (event: any) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const [externalValuesTag, setExternalValuesTag] = useState<(string | null)[]>(
    new Array(Constants.numberOfTags).fill(null),
  );

  const [externalValuesNotTag, setExternalValuesNotTag] = useState<
    IExternalValueInsert[]
  >([]);

  const handleSetExternalValueNotTag = (
    externalPropertyId: number,
    value: string,
  ) => {
    const newValues = [...externalValuesNotTag];
    const newExternalValue = {
      externalPropertyId,
      value: value,
      isTag: false,
    };
    const oldValue = newValues.filter(
      (val) => val.externalPropertyId === externalPropertyId,
    );
    if (oldValue.length > 0) {
      newValues.splice(newValues.indexOf(oldValue[0]), 1);

      newValues.push(newExternalValue);

      setExternalValuesNotTag(newValues);
    } else {
      newValues.push(newExternalValue);
      setExternalValuesNotTag(newValues);
    }
  };

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const finalOption = getNewOptionsWhenSelectingWithStrings({
      currentValue: externalValuesTag[index],
      option,
      valueType: externalProperty.valueType,
    });
    const newExternalValues = [...externalValuesTag];
    newExternalValues[index] = finalOption;
    setExternalValuesTag(newExternalValues);
  };

  return (
    <BlurBackgroundDivFixed>
      <InsertCompanyWrapper>
        <CloseTabButton close={close} style={{ paddingRight: '16px' }} />
        {isLoadingCompanyInfo && (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        )}
        {!isLoadingCompanyInfo && (
          <form onSubmit={formik.handleSubmit} className="insert-form">
            <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
              Insert Company:
            </Typography>
            {user?.company?.integrationType === IntegrationType.SALESFORCE && (
              <div>
                <TabSelectionButton
                  selected={createInCrm}
                  size="large"
                  sx={{ position: 'relative', margin: '6px', marginLeft: 0 }}
                  onClick={() => handleChangeCreateInCrm()}
                  disableElevation
                  type="button"
                >
                  Create in Crm
                </TabSelectionButton>
                <TabSelectionButton
                  selected={!createInCrm}
                  sx={{ position: 'relative', right: 20, margin: '6px' }}
                  size="large"
                  onClick={() => handleChangeCreateInCrm()}
                  disableElevation
                  type="button"
                >
                  Only in crono
                </TabSelectionButton>
              </div>
            )}
            <div className="form-fields-container">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '12px',
                }}
              >
                <Typography className="information-label" fontSize={'14px'}>
                  Company name:
                </Typography>
                <Tooltip
                  arrow
                  title={selectedCompany.name}
                  enterDelay={800}
                  enterNextDelay={800}
                >
                  <Typography fontSize={'14px'} noWrap>
                    {selectedCompany.name}
                  </Typography>
                </Tooltip>
              </div>
              <Avatar
                src={selectedCompany.pictureUrl ?? undefined}
                className="company-logo"
              />
            </div>

            <TagsBoxShowAll
              currentSituation={externalValuesTag}
              handleClickTag={handleClickTag}
              type="Account"
              onlyInsert={true}
              showMandatoryAsStar={true}
            />
            <div className="divider" />
            <div className="additional-information-container">
              {contactInformation.map((field, index) => {
                return (
                  <div className="contact-info-fields-container" key={index}>
                    {field.icon}
                    <div
                      className="editable-div"
                      style={{
                        overflow: 'hidden',
                        width: '100%',
                      }}
                    >
                      <TextField
                        name={field.name}
                        variant="standard"
                        autoComplete="off"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        fullWidth
                        placeholder="Insert a new information..."
                        onChange={(ev) =>
                          formik.setFieldValue(field.name, ev.target.value)
                        }
                        value={formik.values[field.name]}
                        onKeyDown={(ev) => {
                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                            ev.stopPropagation();
                            handleChangeInformation(ev);
                          }
                        }}
                        onBlur={(ev: any) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChangeInformation(ev);
                        }}
                      />
                      <div className="icons-wrapper">
                        <span className="edit-icon">
                          <EditIcon color={colors.grey2} />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="divider" />

            <div className="additional-information-container">
              {accountInformation.map((field, index) => {
                const link = formik.values[field.name] || '';
                return (
                  <div className="information-editable-container" key={index}>
                    <Typography fontSize={'14px'} className="information-label">
                      {field.label}:
                    </Typography>
                    <div className="editable-div">
                      <TextField
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        name={field.name}
                        autoComplete="off"
                        fullWidth
                        value={link || ''}
                        placeholder="Add information..."
                        onChange={(ev) =>
                          formik.setFieldValue(field.name, ev.target.value)
                        }
                        error={
                          formik.touched[field.name] &&
                          Boolean(formik.errors[field.name])
                        }
                        helperText={
                          formik.touched[field.name] &&
                          formik.errors[field.name]
                        }
                      ></TextField>
                    </div>
                  </div>
                );
              })}
              {externalPropertyNotTags.map((property, index) => {
                return (
                  <div
                    key={'' + index + property?.valueType + property?.id}
                    className="information-editable-container"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: property.valueType === 'Text' ? '9px 0px' : '',
                    }}
                  >
                    <div className="external-properties-tag">
                      <Typography
                        fontSize={'14px'}
                        className="information-label"
                      >
                        {property.publicName}
                        {property.isMandatory && '*'}:
                      </Typography>
                    </div>
                    <div
                      className={
                        property.valueType === 'Numeric' ||
                        property.valueType === 'String'
                          ? 'editable-div'
                          : ''
                      }
                      style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ExternalPropertyNotTag
                        externalProperty={property}
                        type="Account"
                        owned={true}
                        externalValues={externalValuesNotTag}
                        handleSetValue={handleSetExternalValueNotTag}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {isLoading ? (
              <FlexDiv>
                <CircularProgress />
              </FlexDiv>
            ) : (
              <FlexDiv width="fit-content" style={{ alignSelf: 'flex-end' }}>
                <CancelButton
                  onClick={close}
                  style={{
                    width: '120px',
                    height: '48px',
                  }}
                  type="button"
                >
                  Cancel
                </CancelButton>
                <MainButton
                  type="submit"
                  disabled={checkAccountExistsError()}
                  style={{
                    width: '120px',
                    height: '48px',
                  }}
                >
                  Insert
                </MainButton>
              </FlexDiv>
            )}
          </form>
        )}
      </InsertCompanyWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default InsertCompany;
