import { CallModalWrapper } from './styles';
import React, { useEffect, useMemo, useState } from 'react';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { getCallFeedbackString } from 'crono-fe-common/types/logCall';
import { FeConstants } from 'constants/FeConstants';
import { Prospect } from 'crono-fe-common/types/prospect';
import { ProspectCardWrapper } from '../contactView/style';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import { ReactComponent as RedirectIcon } from 'crono-fe-common/icons/Redirect.svg';
import { CheckCircleRounded, RadioButtonUnchecked } from '@mui/icons-material';
import { Task } from 'crono-fe-common/types/task';
import moment from 'moment';
import CallLogIcon from 'crono-fe-common/icons/Icon-Call-Log';
import { htmlToText } from 'crono-fe-common/utils';
import useGetProspect from 'hooks/services/prospect/useGetProspect';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import { colors } from 'crono-fe-common/theme';

interface IProps {
  activity?: Task | null;
  selectedProspectId?: string | null;
  visibility: 'none' | 'visible';
}

const CallLogView = ({ activity, selectedProspectId, visibility }: IProps) => {
  const { data: prospectFromDb } = useGetProspect(selectedProspectId);
  const prospect = useMemo(() => {
    return prospectFromDb?.data?.data ?? null;
  }, [prospectFromDb]);

  const [callDuration, setCallDuration] = useState<string>('');
  const [eventTime, setEventTime] = useState<Date>(new Date());

  useEffect(() => {
    if (activity?.callLog?.externalDuration) {
      setCallDuration(
        moment.utc(activity?.callLog?.externalDuration * 1000).format('mm:ss'),
      );
    }
    if (activity?.callLog?.eventDatetime) {
      setEventTime(activity.callLog.eventDatetime);
    }
  }, [activity?.callLog]);

  if (!prospect) return <></>;
  return (
    <CallModalWrapper
      id="call-log-view-component"
      style={{
        display: visibility === 'visible' ? undefined : 'none',
      }}
    >
      <FlexDiv direction="column" className="modal-body" height="fit-content">
        {/*Prospect information */}
        <FlexDiv direction="column" width="100%">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  marginRight: 12,
                  overflow: 'hidden',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    noWrap
                    lineHeight={'20px'}
                  >
                    {prospect?.name}
                  </Typography>
                  <Tooltip
                    title={
                      !prospect?.linkedin ? 'No Linkedin profile URL found' : ''
                    }
                    placement="top"
                    arrow
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        style={{ width: 20, height: 20, padding: 2 }}
                        disabled={!prospect?.linkedin}
                        onClick={() => {
                          if (prospect?.linkedin)
                            window.open(prospect?.linkedin, '_blank');
                        }}
                      >
                        <LinkedinIcon
                          color={
                            !prospect?.linkedin ? colors.grey3 : colors.blue
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>

                <Typography variant="body1" noWrap>
                  {prospect?.title}
                </Typography>
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              height: 'fit-content',
            }}
          >
            <Typography fontSize={16} fontWeight={600} lineHeight={'24px'}>
              Call Overview
            </Typography>
          </div>
          <ProspectCardWrapper
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="right-side-call-log">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  id="phone-number"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    width: '100%',
                    paddingRight: activity?.callLog?.externalDirectLink
                      ? ''
                      : '40px',
                  }}
                >
                  <CallIcon
                    className="info-icon"
                    style={{ marginRight: '8px' }}
                  />
                  <Typography>{prospect?.phone}</Typography>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  fontSize={12}
                  lineHeight={'16px'}
                  fontWeight={500}
                  color={colors.grey11}
                >
                  Performed time:
                </Typography>
                <Typography fontSize={14} lineHeight={'24px'}>
                  {moment(eventTime).format('ll ・ HH:mm')}
                </Typography>
              </div>
              {activity?.callLog?.externalDirectLink && (
                <div style={{ width: 'fit-content', display: 'flex', gap: 12 }}>
                  <div className="vertical-divider" />

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '75px',
                      }}
                    >
                      <CallLogIcon className="call-log-icon-round" />
                      {callDuration}
                    </span>
                  </div>
                  <div className="redirect-icon">
                    <Button
                      disabled={!activity?.callLog?.externalDirectLink}
                      onClick={() => {
                        if (activity?.callLog?.externalDirectLink) {
                          window.open(
                            activity?.callLog?.externalDirectLink,
                            '_blank',
                          );
                        }
                      }}
                    >
                      <RedirectIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>

            {/* </FlexDiv> */}
          </ProspectCardWrapper>
        </FlexDiv>

        <FlexDiv style={{ gap: 20 }} direction="column">
          <FlexDiv
            direction="column"
            alignItems="start"
            height="fit-content"
            style={{ gap: 20 }}
          >
            <Typography fontSize={16} fontWeight={600}>
              Call Feedback
            </Typography>
            {activity?.callLog && (
              <FlexDiv
                justifyContent="start"
                alignItems="center"
                className="quick-actions"
              >
                {FeConstants.callFeedback.map((cf) => {
                  return (
                    <div
                      className={
                        'status badge ' +
                        (activity?.callLog?.callFeedback === cf.type
                          ? getCallFeedbackString(cf.type)
                          : 'status-not-selected')
                      }
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 8,
                        fontSize: 12,
                      }}
                      key={cf.id}
                    >
                      {cf.title}
                      <Checkbox
                        icon={
                          <RadioButtonUnchecked className="status-not-selected-checkbox" />
                        }
                        checkedIcon={
                          <CheckCircleRounded
                            sx={{ color: 'white', cursor: 'initial' }}
                          />
                        }
                        checked={activity?.callLog?.callFeedback === cf.type}
                        sx={{ padding: 0 }}
                      />
                    </div>
                  );
                })}
              </FlexDiv>
            )}
          </FlexDiv>

          <TextField
            disabled
            multiline
            value={
              (activity?.description && htmlToText(activity?.description)) ||
              'No description available'
            }
            rows={5}
            className="add-note-text-area"
            sx={{
              '& fieldset': { border: 'none' },
              '& .Mui-disabled': {
                WebkitTextFillColor: '#000',
              },
            }}
          />
        </FlexDiv>
      </FlexDiv>
    </CallModalWrapper>
  );
};

export default CallLogView;
