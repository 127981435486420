import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Prospect } from 'crono-fe-common/types/prospect';

interface ISearchProspectsExternal {
  name: string;
  linkedinPublicId?: string | null;
  getAccountInCrm?: boolean;
}

export default function useSearchProspectsExternal() {
  const searchProspectExternal = (updateProspect: ISearchProspectsExternal) => {
    const request: Request = {
      url: `${ENDPOINTS.prospect.search.external}`,
      config: {
        method: 'post',
        data: { data: updateProspect },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<Prospect[]>,
    CronoError,
    ISearchProspectsExternal
  >(searchProspectExternal, {});

  return { data, mutate, ...rest };
}
