import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const PlanShortCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${colors.grey444};
  border-radius: 16px;
  padding: 24px;
  gap: 20px;
  height: 135px;
  min-width: 258.5px;
  margin-right: 16px;

  &.free-trial {
    background-color: ${colors.freeTrialYellow};
    border: 1px solid transparent;
  }

  &.clickable:not(.selected) {
    cursor: default;
    &:hover {
      border-color: ${colors.primaryDark};
      background-color: ${colors.primaryLight};
    }
  }

  &.selected:not(.free-trial) {
    border-color: ${colors.primaryDark};
    background-color: ${colors.primary};
    color: ${colors.white} !important;
  }

  .plan-pricing {
    display: flex;
    gap: 20px;
  }

  .plan-billing {
    display: flex;
    flex-direction: column;
    p {
      font-size: 14px;
    }
  }
  .badge {
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
    height: 20px;
    min-height: 20px;
  }

  .highlight {
    color: ${colors.intenseBlue} !important;
    background-color: ${colors.intenseBlueChipBackground} !important;
  }

  //@media (max-width: 1300px) {
  //  .plan-billing {
  //    p {
  //      font-size: 14px;
  //    }
  //  }
  //}
`;
