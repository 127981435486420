import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const CronoVoiceVisualizerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  //class that is default on voice-visualizer library
  .voice-visualizer {
    flex: 1;
  }
  .voice-waves-container {
    overflow-y: hidden;
    height: 40px !important;
    display: flex;
    align-items: center;

    canvas {
      min-width: 100% !important;
      width: 100% !important;
      height: 40px !important;
    }
  }

  .controls-container {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    gap: 8px;
    padding: 10px 0 10px 10px;
    position: relative;

    .recording-time {
      min-width: 120px;
    }

    .button {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 11px 16px;
      background: #010e27;
      border-radius: 24px;
      cursor: pointer;

      &-text {
        font-weight: 500;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        color: white;
      }
    }

    .recorder-button {
      justify-content: space-between;

      &:hover {
        background: ${colors.grey1};
      }

      svg {
        height: 18px;
      }
    }

    .bottom-button-position {
      position: absolute;
      bottom: -42px;
      left: 11px;
    }

    .send-button {
      border: none;
      padding: 11px 26px;
    }

    .dot-icon-container {
      padding: 0 10px;
    }

    .circle-button {
      all: unset;
      width: calc(24px * 1.5);
      height: calc(24px * 1.5);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      border-radius: 50%;

      svg {
        height: calc(10px * 2);
      }
    }

    .play-pause-button {
      background: ${colors.primary};
    }

    .delete-button {
      background: ${colors.inactiveLight};
    }

    .save-button {
      background: ${colors.greenLight};
    }
  }
`;
