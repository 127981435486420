import React, { useMemo } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { ExportCompaniesModalWrapper } from './style';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { colors } from 'crono-fe-common/theme';
import { AccountSearchColumns } from 'crono-fe-common/types/DTO/accountSearchForExport';
import { ReactComponent as ExclamationMark } from 'crono-fe-common/icons/Error-Indicator-S.svg';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import IconSWarning from 'crono-fe-common/icons/Icon-S-Warning';
import { ProspectSearchColumns } from 'crono-fe-common/types/DTO/prospectSearchForExport';
import { Constants } from 'crono-fe-common/constants/constants';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';

export interface IColumnsToSelect {
  label: string;
  value: string;
}

export type SearchColumns = AccountSearchColumns | ProspectSearchColumns;
export type SearchColumnsKey = keyof SearchColumns;

export interface BasePropsExportDataModal {
  onClose: () => void;
  total: number;
  exportInProgress: boolean;
}

interface IProps extends BasePropsExportDataModal {
  onSubmit: (columns: SearchColumns) => void;
  optionsToSelect: IColumnsToSelect[];
  exportDataName: string;
  columnsSelected: SearchColumns;
  setColumnsSelected: React.Dispatch<React.SetStateAction<SearchColumns>>;
  isCompany?: boolean;
}

export const ExportDataModal = ({
  onClose,
  total,
  optionsToSelect,
  exportDataName,
  columnsSelected,
  setColumnsSelected,
  onSubmit,
  exportInProgress,
  isCompany = false,
}: IProps) => {
  const maxLimitReached = total > Constants.defaultExportDataLimit;

  const noColumnSelected = useMemo(() => {
    return !Object.values(columnsSelected).some((value) => value);
  }, [columnsSelected]);

  return (
    <BlurBackgroundDivFixed onClick={onClose}>
      <ExportCompaniesModalWrapper
        onClick={(e) => e.stopPropagation()}
        style={{ height: isCompany ? 446 : 518 }}
      >
        <div className="cross">
          <CloseTabButton style={{ paddingRight: '24px' }} close={onClose} />
        </div>

        <Typography
          fontSize={24}
          fontWeight={700}
          lineHeight={'30px'}
          color={colors.black}
        >
          Select the fields you want to export:
        </Typography>

        <div className="options">
          {optionsToSelect.map((option, index) => {
            const isSelected =
              columnsSelected[option.value as SearchColumnsKey];
            return (
              <div
                className="option"
                key={index}
                onClick={() => {
                  setColumnsSelected((prev) => ({
                    ...prev,
                    [option.value]: !isSelected,
                  }));
                }}
              >
                <CronoCheckbox checked={isSelected} />
                <Typography
                  fontSize={12}
                  lineHeight={'16px'}
                  color={colors.black}
                  fontWeight={500}
                >
                  {option.label}
                </Typography>
              </div>
            );
          })}
        </div>

        <div
          className="export-amount-container"
          style={{
            marginBottom: -16,
            background: maxLimitReached
              ? colors.inactiveLight
              : colors.lightGreenBlue,
          }}
        >
          {maxLimitReached ? (
            <IconSWarning color={colors.inactive} />
          ) : (
            <ExclamationMark className={'info-icon'} />
          )}

          <Typography
            fontSize={12}
            lineHeight={'16px'}
            fontWeight={500}
            color={maxLimitReached ? colors.inactive : colors.grey1}
          >
            {maxLimitReached
              ? `Max ${Constants.defaultExportDataLimit} ${exportDataName} will be exported`
              : `${total} ${exportDataName} will be exported in CSV format`}
          </Typography>
        </div>

        <div className="buttons">
          <CancelButton width="120px" fontWeight="400" onClick={onClose}>
            Cancel
          </CancelButton>

          {exportInProgress ? (
            <CircularProgress />
          ) : (
            <MainButton
              fontWeight="400"
              disabled={noColumnSelected}
              onClick={() => {
                onSubmit(columnsSelected);
              }}
            >
              Start export
            </MainButton>
          )}
        </div>
      </ExportCompaniesModalWrapper>
    </BlurBackgroundDivFixed>
  );
};
