import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import IconIndeterminate from 'crono-fe-common/icons/Icon-Indeterminate';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';

const CronoCheckbox = ({ sx, disabled, ...props }: CheckboxProps) => {
  return (
    <Checkbox
      icon={<IconUnchecked />}
      checkedIcon={<IconChecked color={colors.mainDark} />}
      indeterminateIcon={<IconIndeterminate color={colors.mainDark} />}
      {...props}
      sx={{
        padding: 0,
        margin: 0,
        '&:hover': {
          backgroundColor: colors.grey4,
        },
        '&.Mui-checked': {
          '&:hover': {
            opacity: 0.7,
          },
        },
        marginRight: 1,
        marginLeft: 1,
        opacity: disabled ? 0.5 : 1,
        svg: {
          rect: {
            stroke: colors.grey11,
          },
        },
        ...sx,
      }}
      disabled={disabled}
    />
  );
};

export default CronoCheckbox;
