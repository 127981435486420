import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'context/auth';
import { colors } from 'crono-fe-common/theme';
import { Typography } from '@mui/material';
import { GreyButton } from 'crono-fe-common/components/CronoButton';
import { AboutContainer } from 'pages/searchComponent/searchCompanyTab/companyInsertCard';
import {
  useBackgroundMarkEnrichJobsAsRead,
  useLinkedinGetCompanyInfo,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import { useGetAsyncEnrichQueueState } from 'hooks/services/crono-extension/useGetAsyncEnrichQueueState';
import { CompanyForSearchTable } from '../model';
import { FindNewTableCell } from 'pages/searchComponent/searchContactTab/contactInsertCard';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import EnrichIcon from 'crono-fe-common/icons/Icon-Enrich';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  company: CompanyForSearchTable;
  columnsToShow: string[] | '';
  alreadyEnriching: boolean;
  setAlreadyEnriching: React.Dispatch<React.SetStateAction<boolean>>;
  setCompany: (company: CompanyForSearchTable) => void;
  setIsCompanySelectable: (isSelectable: boolean) => void;
  handleShowError: (error: string) => void;
}

const CompanyInformationColumns = ({
  company,
  columnsToShow,
  alreadyEnriching,
  setAlreadyEnriching,
  setCompany,
  setIsCompanySelectable,
  handleShowError,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const { hasSalesNavWithCookie } = useAuth();
  //Filter among the columns to show only the ones related to the company information to enrich
  const companyColumns = useMemo(() => {
    if (!columnsToShow) return [];
    //Depending if the user has salesNav or not the columns that needs to be enriched and the ones that we already have change
    return columnsToShow.filter((column) =>
      (hasSalesNavWithCookie
        ? ['companyHeadquarter', 'companyWebsite']
        : ['companyWebsite', 'companySize', 'companyDescription']
      ).includes(column),
    );
  }, [columnsToShow, hasSalesNavWithCookie]);
  const colSpanForEnrichCell = companyColumns.length;

  const { call: getCompanyInfo, isLoading: isLoadingGetCompanyInfo } =
    useLinkedinGetCompanyInfo();

  const [error, setError] = useState<string | null>(null);

  const handleEnrichClick = async () => {
    setAlreadyEnriching(true);
    if (analytics) {
      analytics.track('linkedin-enrich-single-company', {});
    }
    try {
      const companyInfo = await getCompanyInfo({
        numericId: company.numericId,
        url: company.url,
      });
      if (companyInfo?.company) {
        setCompany({
          ...company,
          alreadyInDatabase: true,
          description: companyInfo.company.description ?? '',
          empolyees: companyInfo.company.numberOfEmployees,
          location: companyInfo.company.location,
          numericId: companyInfo.company.numericId,
          website: companyInfo.company.website,
          pictureUrl: companyInfo.company.imageUrl ?? '',
        });
      } else {
        throw new Error('Company not found');
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setAlreadyEnriching(false);
    }
  };

  //Enrich company button
  const { data: queueEnrich } = useGetAsyncEnrichQueueState();

  const [isAccountLinkedinEnriching, setIsAccountLinkedinEnriching] =
    useState<boolean>(false);

  const [isLinkedinEnriched, setIsLinkedinEnriched] = useState<boolean>(false);

  useEffect(() => {
    const isImporting = Boolean(
      queueEnrich?.contactsToEnrich.find(
        (c) =>
          c.identifiers.publicId === null &&
          c.identifiers.leadId === null &&
          ((c.identifiers.companyNumericId &&
            company.numericId &&
            c.identifiers.companyNumericId === company.numericId) ||
            (c.identifiers.companyPublicId &&
              company.url &&
              c.identifiers.companyPublicId ===
                LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(company.url)[0])),
      ),
    );

    setIsAccountLinkedinEnriching(isImporting);

    if (isImporting) {
      setIsLinkedinEnriched(false);
    }

    if (isImporting) {
      setIsCompanySelectable(false);
    }
  }, [queueEnrich?.contactsToEnrich, company]);

  const { call: markEnrichJobsAsRead } = useBackgroundMarkEnrichJobsAsRead();

  useEffect(() => {
    if (
      queueEnrich &&
      queueEnrich.contactsEnriched.length > 0 &&
      !isLinkedinEnriched
    ) {
      const enrichedEntity = queueEnrich.contactsEnriched.find(
        (c) =>
          c.identifiers.publicId === null &&
          c.identifiers.leadId === null &&
          ((c.identifiers.companyNumericId &&
            company.numericId &&
            c.identifiers.companyNumericId === company.numericId) ||
            (c.identifiers.companyPublicId &&
              company.url &&
              c.identifiers.companyPublicId ===
                LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(company.url)[0])),
      );

      if (!enrichedEntity) return;
      setIsLinkedinEnriched(true);
      markEnrichJobsAsRead({
        jobIds: [enrichedEntity.id],
      });
      if (enrichedEntity.error) {
        handleShowError(
          enrichedEntity.error === 'missingCookie'
            ? 'Error while enriching, check to be logged into linkedin with Crono'
            : enrichedEntity.error,
        );
        setError(enrichedEntity.error ?? 'Error while enriching');
        setIsAccountLinkedinEnriching(false);
        setIsCompanySelectable(true);
        return;
      }
      setIsAccountLinkedinEnriching(false);
      if (enrichedEntity.company) {
        setCompany({
          ...company,
          alreadyInDatabase: true,
          description: enrichedEntity.company.description ?? '',
          empolyees: '' + enrichedEntity.company.numberOfEmployees,
          location: enrichedEntity.company.location ?? null,
          numericId: enrichedEntity.company.numericId ?? null,
          website: enrichedEntity.company.website ?? null,
        });
      }
      setIsCompanySelectable(true);
    }
  }, [queueEnrich?.contactsEnriched]);

  const EnrichButtonRowComponent = useMemo(() => {
    return !company.alreadyInDatabase && colSpanForEnrichCell > 0 ? (
      <FindNewTableCell
        colSpan={colSpanForEnrichCell}
        style={{
          paddingTop: 8.5,
          borderRight: `1px solid ${colors.grey444}`,
        }}
      >
        {isAccountLinkedinEnriching || isLoadingGetCompanyInfo ? (
          <div
            className="importing-loader-container"
            style={{ width: 78, height: 32 }}
          >
            <span className="loader importing-loader"></span>
          </div>
        ) : error ? (
          <Typography
            fontSize={12}
            lineHeight={'18px'}
            fontWeight={500}
            color={colors.inactive}
            maxWidth={700}
            noWrap
          >
            {error}
          </Typography>
        ) : (
          <GreyButton
            startIcon={
              <EnrichIcon
                color={colors.intenseBlue}
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            }
            style={{
              height: 32,
              borderRadius: 16,
              padding: '4px 12px 4px 8px',
              width: 130,
              position: 'sticky',
              //This has to match the width of the first cell or bring grater than that
              left: 350,
              color: colors.intenseBlue,
            }}
            disabled={alreadyEnriching}
            onClick={handleEnrichClick}
          >
            Enrich
          </GreyButton>
        )}
      </FindNewTableCell>
    ) : null;
  }, [
    isAccountLinkedinEnriching,
    error,
    alreadyEnriching,
    company.alreadyInDatabase,
    columnsToShow.length,
    colSpanForEnrichCell,
  ]);

  return (
    <>
      {hasSalesNavWithCookie && EnrichButtonRowComponent}
      {/*Headquarter */}
      {columnsToShow.includes('companyHeadquarter') &&
        (company.alreadyInDatabase || !hasSalesNavWithCookie) && (
          <FindNewTableCell
            style={{
              paddingLeft: 12,
              width: 150,
              minWidth: 150,
              borderRight: `1px solid ${colors.grey444}`,
            }}
          >
            <span
              style={{
                width: 150,
                minWidth: 150,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <Typography
                fontSize={14}
                lineHeight={'24px'}
                color={colors.grey11}
                noWrap
              >
                {company.location}
              </Typography>
            </span>
          </FindNewTableCell>
        )}
      {/*Website */}
      {columnsToShow.includes('companyWebsite') &&
        company.alreadyInDatabase && (
          <FindNewTableCell
            style={{
              paddingLeft: 12,
              width: 150,
              minWidth: 150,
              borderRight: `1px solid ${colors.grey444}`,
            }}
          >
            <span
              style={{
                width: 150,
                minWidth: 150,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <Typography
                fontSize={14}
                lineHeight={'24px'}
                color={colors.grey11}
                noWrap
              >
                {company.website}
              </Typography>
            </span>
          </FindNewTableCell>
        )}
      {/*Employees */}
      {columnsToShow.includes('companySize') &&
        (company.alreadyInDatabase || hasSalesNavWithCookie) && (
          <FindNewTableCell
            style={{
              paddingLeft: 12,
              width: 150,
              minWidth: 150,
              borderRight: `1px solid ${colors.grey444}`,
            }}
          >
            <span
              style={{
                width: 150,
                minWidth: 150,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <Typography
                fontSize={14}
                lineHeight={'24px'}
                color={colors.grey11}
                noWrap
              >
                {company.empolyees}
              </Typography>
            </span>
          </FindNewTableCell>
        )}
      {/*About */}
      {columnsToShow.includes('companyDescription') &&
        (company.alreadyInDatabase || hasSalesNavWithCookie) && (
          <FindNewTableCell
            style={{
              paddingLeft: 12,
              width: 250,
              minWidth: 250,
              borderRight: `1px solid ${colors.grey444}`,
            }}
          >
            <AboutContainer about={company.description} />
          </FindNewTableCell>
        )}
      {!hasSalesNavWithCookie && EnrichButtonRowComponent}
    </>
  );
};

export default CompanyInformationColumns;
