import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const ErrorIndicatorIcon = ({
  color = colors.inactive,
  color2 = colors.inactiveLight,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...style}
      className={className}
      onClick={onClick}
    >
      <rect width="16" height="16" rx="8" fill={color2} />
      <path
        d="M8.00021 7.3979V4.32962"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99921 11.0081C7.95641 11.0081 7.91539 11.0252 7.88532 11.0557C7.85524 11.0861 7.83861 11.1274 7.83915 11.1702C7.83968 11.2587 7.91072 11.3306 7.99921 11.3322C8.08872 11.3322 8.16128 11.2597 8.16128 11.1702C8.16128 11.0807 8.08872 11.0081 7.99921 11.0081"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ErrorIndicatorIcon;
