import { ENDPOINTS } from 'config/endpoints';
import { useAsync } from 'hooks/useAsync';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';

const useGoogleAuthCallback = () => {
  const { error, isLoading, isError, isSuccess, run } = useAsync<boolean>();

  const googleAuthCallback = (code: string) => {
    const request: Request = {
      url: ENDPOINTS.google.authCallback,
      config: {
        method: 'post',
        data: { code },
      },
    };

    run(
      client(request).then(() => {
        return true;
      }),
    );
  };

  return { googleAuthCallback, error, isLoading, isError, isSuccess };
};

export default useGoogleAuthCallback;
