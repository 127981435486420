import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import { Template } from 'crono-fe-common/types/template';
import { FileContent } from 'use-file-picker';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  recipient: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  cc: Yup.array().of(Yup.string().email('Enter valid emails')),
  ccn: Yup.array().of(Yup.string().email('Enter valid emails')),
  message: Yup.string()
    .required('Impossible to send an empty mail')
    .test((value) => true),
});

export interface MailInputs {
  recipient?: string;
  cc?: string[];
  ccn?: string[];
  subject?: string;
  message: string;
  template?: Template | null;
  threadId?: string;
  files?: (FileContent | CronoAttachment)[];
  taskId?: number | null;
}

export const initialValues = {
  recipient: '',
  cc: [],
  ccn: [],
  message: '',
  subject: '',
};
