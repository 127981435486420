import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InMailWrapper } from './styles';
import {
  Avatar,
  CircularProgress,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import ActivitiesBoxInsideActivityView from '../activitiesInsideActivityView';
import { colors } from 'crono-fe-common/theme';
import useGetProspect from 'hooks/services/prospect/useGetProspect';
import EmailInput from 'crono-fe-common/components/FormComponents/EmailInput';
import { useFormik } from 'formik';
import { InMailFormik, initialValues, validationSchema } from './types';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import {
  getMissingTemplateVariables,
  prospectName,
  replaceAllTemplateVariables,
  stringAvatarProspect,
  transformToValidUrl,
} from 'utils/fe-utils';
import { useConfirmModal } from 'context/confirmModal';
import {
  useLinkedinGetStatus,
  useLinkedinSendInMailMessage,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import { useSelectedAccount } from 'pages/home/context/account';
import AddTemplateIconS from 'crono-fe-common/icons/Icon-Add-Template-S';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { Template } from 'crono-fe-common/types/template';
import { LinkedinConnectionStatus } from 'crono-fe-common/types/crono-extension/linkedin';
import { useAuth } from 'context/auth';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { useHideTaskConstruction } from '../hideTaskConstructionContext';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import NoSalesNavigatorLicenseInmailView from './noSalesNavigatorLicenseInmailView';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import { useConditionalSnackBar } from 'context/snackbar';
import {
  LinkedinType,
  LogLinkedinInsert,
} from 'crono-fe-common/types/logLinkedinInsert';
import useLogLinkedin from 'hooks/services/event/useLogLinkedin';
import { useJuneAnalytics } from 'context/june';
import { getError, htmlToText } from 'crono-fe-common/utils';
import { CursorPosition, TemplateCardsContainerTooltip } from '../linkedinView';
import { ReactComponent as AttachmentIcon } from 'crono-fe-common/icons/Attachment.svg';
import { FileContent } from 'use-file-picker';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import useGetTemplateAttachmentsMutate from 'hooks/services/templates/useGetTemplateAttachmentsMutate';
import { isFileContent } from 'pages/settings/Template/model';
import { CronoAttachmentWithContent } from 'crono-fe-common/types/cronoAttachmentWithContent';
import useGetTemplateAttachmentsFileMutate from 'hooks/services/templates/useGetTemplateAttachmentsFileMutate';
import { useAttachmentsFilePicker } from 'hooks/useAttachmentsFilePicker';
import IconStarsInsights from 'crono-fe-common/icons/Icon-Stars-Insights';
import InsightsCard, {
  RewriteSubmittedContext,
} from '../emailView/insightsCard';
import TemplatesTooltipCard from '../emailView/TemplatesTooltipCard';
import { ReactComponent as IconOpenAI } from 'assets/images/Icon_OpenAI.svg';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';

export interface IProps {
  task?: TaskTodo | null;
  selectedProspectId?: string | null;
  callOnSuccess?: (keepTemplatesOpened?: boolean) => void;
  suggestionId?: number | null;
  handleCloseModal: () => void;
  defaultOpenTemplateTab?: boolean;
  visibility: 'none' | 'visible';
  close: () => void;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
}

const InMailView = ({
  selectedProspectId,
  setTabValue,
  task,
  visibility,
  callOnSuccess,
  suggestionId,
  handleCloseModal,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const { setHideProgressTask } = useHideTaskConstruction();
  const { account } = useSelectedAccount();
  const { user, hasSalesNav } = useAuth();
  //Prospect on which the inmail communication is performed
  const { data: prospectFromDb } = useGetProspect(selectedProspectId);
  const prospect = useMemo(() => {
    if (
      prospectFromDb?.data?.data &&
      account?.objectId === prospectFromDb.data.data.accountId
    ) {
      return prospectFromDb.data.data;
    }
    return null;
  }, [prospectFromDb, account?.objectId]);

  useEffect(() => {
    if (prospect) {
      setHideProgressTask(false);
    }
    //The dependency of task is to allow the screen to be shut down even in case of two tasks directed to the same prospect and of the same type (the prospect would be already loaded
    //and the useEffect would not be fired)
  }, [prospect, task]);

  const {
    call: sendInMailMessage,
    result: sendInMailResult,
    error: sendInMailError,
  } = useLinkedinSendInMailMessage();

  useEffect(() => {
    if (sendInMailResult && sendInMailResult.rawError) {
      analytics?.track('send-linkedin-inmail-error', {
        error: sendInMailResult.rawError,
      });
    }
  }, [sendInMailResult, analytics]);

  //Attachments
  const [openFileSelector, { filesContent, errorMessage: errorUploadFile }] =
    useAttachmentsFilePicker();

  const [errorOnLoadAttachments, setErrorOnLoadAttachments] = useState<
    string | null
  >(null);

  const [files, setFiles] = useState<(FileContent | CronoAttachment)[]>([]);

  const setAttachments = (files: FileContent[]) => {
    if (files && files.length > 0) {
      setFiles((prev) => prev.concat(files));
    }
  };

  useEffect(() => {
    setAttachments(filesContent);
  }, [filesContent]);

  const handleAttachments = (files: File[]) => {
    if (files.length > 0) {
      const fileContents: FileContent[] = [];
      let filesRead = 0;

      files.forEach((file: any) => {
        const reader = new FileReader();
        reader.onload = () => {
          // it was not working with ...file
          fileContents.push({
            name: file.name,
            size: file.size,
            type: file.type,
            slice: file.slice,
            text: file.text,
            stream: file.stream,
            arrayBuffer: file.arrayBuffer,
            lastModified: file.lastModified,
            content: reader.result as string,
          });
          filesRead++;

          // Check if all files have been read
          if (filesRead === files.length) {
            setAttachments(fileContents);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const { dataTransfer } = event;

    if (dataTransfer && dataTransfer.files && dataTransfer.files.length > 0) {
      const files = Array.from(dataTransfer.files) as File[];
      handleAttachments(files);
    }
  };

  const removeAttachment = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const [sending, setSending] = useState<boolean>(false);

  const { mutateAsync: getTemplates } = useGetTemplateAttachmentsFileMutate();

  const [rewriteSubmittedContext, setRewriteSubmittedContext] =
    useState<RewriteSubmittedContext | null>(null);

  const formik = useFormik<InMailFormik>({
    initialValues: {
      ...initialValues,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSending(true);

      if (rewriteSubmittedContext && analytics) {
        analytics.track('sent-with-rewrite', {
          ...rewriteSubmittedContext,
          actualMessage: values.message,
        });
        setRewriteSubmittedContext(null);
      }

      let templates: CronoAttachmentWithContent[] = [];
      if (files.length > 0) {
        const attachmentsToLoad = files.filter(
          (file) => !isFileContent(file),
        ) as CronoAttachment[];
        //If we have templates to load we load them, otherwise we skip to save the await time
        if (attachmentsToLoad.length > 0) {
          const templatesResponse = await getTemplates(
            attachmentsToLoad.map((attachment) => attachment.id),
          );
          if (!templatesResponse.data?.isSuccess) {
            setErrorOnLoadAttachments('Error loading attachments');
            setSending(false);
            setTimeout(() => {
              setErrorOnLoadAttachments(null);
            }, 3000);
            return;
          }
          templates = templatesResponse.data?.data ?? [];
        }
      }
      sendInMailMessage({
        body: values.message,
        subject: values.subject,
        url: prospect?.linkedin,
        publicId:
          LinkedinUrlUtils.getIdFromLinkedinProfileUrl(prospect?.linkedin) ??
          undefined,
        attachments: files
          .filter((file) => isFileContent(file))
          .map((file) => ({
            urlData: (file as any as FileContent).content,
            fileName: file.name,
          }))
          .concat(
            templates
              .filter((template) => template.fileContent)
              .map((template) => ({
                urlData: template.fileContent!,
                fileName: template.name,
              })) ?? [],
          ),
      });
    },
  });

  const {
    mutate: createLogLinkedin,
    error: errorLoggingLinkedin,
    isSuccess: linkedinLogSuccessfully,
  } = useLogLinkedin();

  useEffect(() => {
    if (!sendInMailResult) return;
    if (sendInMailResult?.messageSent) {
      if (account && prospect) {
        const logObject: LogLinkedinInsert = {
          accountId: account?.objectId ?? prospect?.accountId,
          prospectId: prospect?.objectId,
          taskTodoId: task?.id ?? null,
          content: formik.values.message,
          subject: formik.values.subject,
          linkedinType: LinkedinType.InMail,
          templateId: selectedTemplate?.id ?? null,
          suggestionId: suggestionId ?? null,
        };
        createLogLinkedin(logObject);

        if (task?.id) {
          if (analytics) {
            analytics.track('sequence-task', {});
          }
        }
      }
      if (analytics) {
        analytics.track('send-linkedin-inmail', {});
      }
    } else if (sendInMailResult.messageSent === false) {
      setSending(false);
    }
  }, [sendInMailResult]);

  useEffect(() => {
    if (errorLoggingLinkedin) {
      setSending(false);
    }
  }, [errorLoggingLinkedin]);

  useEffect(() => {
    if (linkedinLogSuccessfully) {
      if (callOnSuccess) {
        callOnSuccess(showTemplateTab);
      }
      setSending(false);
      handleCloseModal();
    }
  }, [linkedinLogSuccessfully]);

  const { openModal: openConfirmModal } = useConfirmModal();

  //Check if any of the variables from template is missing, show confirm modal in case
  const handleClickSubmit = () => {
    const missingVariables: string[] = getMissingTemplateVariables(
      formik.values.message ?? '',
    );
    if (missingVariables.length > 0) {
      openConfirmModal({
        title: 'Variable value missing',
        text: `${
          missingVariables.length === 1 ? 'Variable' : 'Variables'
        } ${missingVariables.map((variable) => variable).join(', ')} ${
          missingVariables.length === 1 ? 'is' : 'are'
        } missing from the message, are you sure you want to continue?`,
        confirmText: `Send anyway`,
        cancelText: 'Go back',
        cancelFunction: () => null,
        confirmFunction: () => {
          formik.handleSubmit();
        },
      });
    } else {
      formik.handleSubmit();
    }
  };

  const {
    call: getLinkedinStatus,
    result: linkedinStatus,
    error: linkedinStatusError,
    isLoading: isLinkedinStatusLoading,
    resetData: resetLinkedinStatus,
  } = useLinkedinGetStatus();

  //ENTRY POINT: this is where all the calls start from. When the prospect is loaded I get his status if he has linkedin, otherwise I terminate
  //the loading process (since nothing can be done, other parts of the code will take care of this)
  useEffect(() => {
    if (!prospect) return;
    //New prospect, so I reset status and conversation (to delete the conversation with the previous prospect, we had this problem in the past)
    if (prospect?.linkedin && prospect?.linkedin !== '') {
      getLinkedinStatus({
        url: prospect.linkedin,
        user: prospectName(prospect),
        company: account?.name ?? null,
      }).then();
    } else {
      resetLinkedinStatus();
    }
  }, [prospect]);

  //This memo is used to always know the state of the current prospect. The profileId is used to allow all the components/useEffects to refresh
  //when a new prospect is analyzed, even if they have the same status
  const prospectLinkedinStatus: {
    profileId: string | null;
    status: LinkedinConnectionStatus;
  } | null = useMemo(() => {
    if (!(prospect?.linkedin && prospect?.linkedin !== '') || !linkedinStatus)
      return null;
    return {
      profileId: linkedinStatus?.profileId ?? null,
      status: linkedinStatus?.status ?? 'Unknown',
    };
  }, [/*prospect,*/ linkedinStatus]);
  const [showTemplateTab, setShowTemplateTab] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<Template | null>(null);

  const { mutateAsync: loadAttachments } = useGetTemplateAttachmentsMutate();
  const { data: templateVariables } = useGetTemplateVariables();

  useEffect(() => {
    //To delete the template during the loading, since it may be releated to the previous contact
    if (!prospectLinkedinStatus || !selectedTemplate) {
      formik.setFieldValue('message', '');
      setFiles([]);
      return;
    }

    let subject = htmlToText(selectedTemplate?.subject || '');
    subject = replaceAllTemplateVariables(
      subject ?? '',
      prospect ?? null,
      account ?? null,
      user ?? null,
      templateVariables?.data?.data ?? null,
    );
    formik.setFieldValue('subject', subject);

    let content = htmlToText(selectedTemplate?.content || '');
    content = replaceAllTemplateVariables(
      content ?? '',
      prospect ?? null,
      account ?? null,
      user ?? null,
      templateVariables?.data?.data ?? null,
    );
    formik.setFieldValue('message', content);
    loadAttachments(selectedTemplate.id).then((response) => {
      let newFiles = files;
      newFiles = files.filter((file) => isFileContent(file));
      setFiles(newFiles.concat(response.data?.data ?? []));
    });
  }, [selectedTemplate, prospectLinkedinStatus]);

  //Task template
  useEffect(() => {
    if (!prospectLinkedinStatus) return;
    if (task && task.template?.type === TemplateType.IN_MAIL) {
      setSelectedTemplate(task.template);
    } else {
      setSelectedTemplate(null);
    }
  }, [prospectLinkedinStatus, task]);

  // Insights logic --------------
  const [showInsightTab, setShowInsightTab] = useState<boolean>(false);

  useConditionalSnackBar([
    {
      condition: !!sendInMailResult?.error,
      message: sendInMailResult?.error ?? 'Error sending inMail message',
      severity: 'error',
    },
    {
      condition: !!errorLoggingLinkedin,
      message: getError(errorLoggingLinkedin) ?? 'Error logging inMail message',
      severity: 'error',
    },
    {
      condition: !!sendInMailResult?.messageSent,
      message: 'In mail message sent successfully',
      severity: 'success',
    },
    {
      condition: !!sendInMailError,
      message: sendInMailError ?? 'Error sending inMail message',
      severity: 'error',
    },
    {
      condition: !!errorUploadFile,
      message: errorUploadFile ?? 'Error uploading file',
      severity: 'error',
    },
    {
      condition: !!errorOnLoadAttachments,
      message:
        errorOnLoadAttachments ?? 'Error on loading template attachments',
      severity: 'error',
    },
  ]);

  const [rewriteEnabled, setRewriteEnabled] = useState<boolean>(false);

  const toggleInsightsTab = () => {
    setShowInsightTab((prev) => {
      if (!prev) {
        if (analytics) {
          analytics.track('open-insights', {});
        }
      }

      // we set to initial state (false) on insights close to keep all logic persistant
      setRewriteEnabled(false);

      return !prev;
    });
  };

  const onRewriteWithAIClick = () => {
    setShowInsightTab((prevShowInsightTab) => {
      setRewriteEnabled((prev) => !(prevShowInsightTab && prev));

      return !(prevShowInsightTab && rewriteEnabled);
    });
  };

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [selectedText, setSelectedText] = useState<string | undefined>(
    undefined,
  );
  const [selection, setSelection] = useState({ start: 0, end: 0 });

  const handleSelect = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;

      if (start === end) {
        setSelection({ start: 0, end: textArea.value.length });
        setSelectedText(textArea.value);
      } else {
        setSelection({ start, end });
        setSelectedText(textArea.value.substring(start, end));
      }
    }
  };

  //HACK to trigger handleSelect function when needed
  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea && showInsightTab) {
      textArea.setSelectionRange(0, 0);
      textArea.focus({ preventScroll: true });
    }
  }, [showInsightTab, selectedTemplate]);

  const showSelection = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.focus();
      textArea.setSelectionRange(selection.start, selection.end);
    }
  };

  const replaceSelectedText = (text: string) => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.focus();
      const textBeforeSelection = formik.values.message.slice(
        0,
        selection.start,
      );
      const textAfterSelection = formik.values.message.slice(selection.end);

      const newText = textBeforeSelection + text + textAfterSelection;

      formik.setFieldValue('message', newText);
    }
  };
  return (
    <>
      {/*Insight shown on the left side of the tab */}
      {showInsightTab && (
        <InsightsCard
          close={() => {
            setRewriteEnabled(false);
            setShowInsightTab(false);
          }}
          prospect={prospect}
          account={account}
          rewriteEnabled={rewriteEnabled}
          wholeMessage={formik.values.message}
          partToRewrite={selectedText}
          showSelection={showSelection}
          replaceSelectedText={replaceSelectedText}
          setRewriteSubmittedContext={setRewriteSubmittedContext}
        />
      )}
      {/*Templates shown on the left side of the tab */}
      {/* {showTemplateTab && (
        <TemplatesCard
          accountId={account?.objectId}
          prospectId={prospect?.objectId}
          selectedTemplateId={selectedTemplate?.id ?? null}
          setSelectedTemplate={(template: Template) =>
            setSelectedTemplate(template)
          }
          hideShared={false}
          hideAll={false}
          selectedType={TemplateType.IN_MAIL}
          showAnalytics={true}
          close={() => setShowTemplateTab(false)}
          account={account}
          prospect={prospect}
          visibility={visibility}
          sequenceStepTemplateId={task?.sequenceStepTemplateId}
        />
      )} */}
      <InMailWrapper
        style={{
          position: 'relative',
          display: visibility === 'visible' ? undefined : 'none',
          //   height: showTextareaContainer() ? undefined : "100%",
        }}
      >
        <div
          style={{
            display: 'flex',
            paddingBottom: 8,
            width: '100%',
            height: 'fit-content',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginRight: 12,
              overflow: 'hidden',
              maxWidth: '70%',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Avatar key={prospect?.objectId} className="prospect-avatar-row">
              {stringAvatarProspect(prospect)}
            </Avatar>

            <div
              style={{
                marginRight: 12,
                overflow: 'hidden',
                flex: 1,
                width: 'fit-content',
                minWidth: 0,
                maxWidth: 'fit-content',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography
                  variant="body1"
                  fontSize={14}
                  lineHeight={'18px'}
                  fontWeight={600}
                  noWrap
                >
                  {prospect?.name}
                </Typography>

                <Tooltip
                  title={
                    !prospect?.linkedin ? 'No Linkedin profile URL found' : ''
                  }
                  placement="top"
                  arrow
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      style={{ width: 20, height: 20, padding: 2 }}
                      disabled={!prospect?.linkedin}
                      onClick={() => {
                        if (!prospect?.linkedin) return;
                        const url = transformToValidUrl(prospect?.linkedin);
                        if (url) window.open(url, '_blank');
                      }}
                    >
                      <LinkedinIcon
                        color={!prospect?.linkedin ? colors.grey3 : colors.blue}
                      />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>

              <Typography
                variant="body1"
                fontSize={12}
                color={colors.grey11}
                lineHeight={'16px'}
                noWrap
              >
                {prospect?.title}
              </Typography>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <ActivitiesBoxInsideActivityView
              setTabValue={setTabValue}
              selectedProspectId={
                prospect?.objectId ??
                task?.prospectId ??
                task?.prospect.objectId ??
                null
              }
            />
          </div>
        </div>
        {hasSalesNav === null ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : hasSalesNav ? (
          <form
            onSubmit={formik.handleSubmit}
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <div className="subject-selector-container bottom-line">
              <EmailInput
                fullWidth
                id="subject"
                name="subject"
                placeholder="Subject*:"
                value={formik.values.subject}
                onChange={(ev) => {
                  formik.handleChange(ev);
                }}
                helperText={formik.touched.subject && formik.errors.subject}
                required={false}
                autoComplete={'off'}
              />
            </div>
            {/*Hidden in voiceNote to give the best experience to the user. They are removed also by the useEffect, this is a security check */}
            {files && files.length > 0 ? (
              <div className="file-preview">
                {files.map((file, index) => (
                  <div className={`file`} key={index}>
                    <CloseMIcon
                      className="delete-attachments-icon"
                      onClick={() => {
                        removeAttachment(index);
                      }}
                    />
                    {file.name}
                  </div>
                ))}
              </div>
            ) : null}
            <div className="textarea-container">
              <div className="header-textarea">
                <div
                  className="template-button-container"
                  onClick={() => openFileSelector()}
                  style={{ padding: '8px' }}
                >
                  <AttachmentIcon style={{ width: '18px', height: '18px' }} />
                </div>
                <TemplateCardsContainerTooltip
                  title={
                    <TemplatesTooltipCard
                      accountId={account?.objectId}
                      prospectId={prospect?.objectId}
                      selectedTemplateId={selectedTemplate?.id ?? null}
                      setSelectedTemplate={(template: Template) =>
                        setSelectedTemplate(template)
                      }
                      hideShared={false}
                      hideAll={false}
                      selectedType={TemplateType.IN_MAIL}
                      showAnalytics={true}
                      close={() => setShowTemplateTab(false)}
                      account={account}
                      prospect={prospect}
                      visibility={visibility}
                      sequenceStepTemplateId={task?.sequenceStepTemplateId}
                    />
                  }
                  onClose={() => setShowTemplateTab(false)}
                  open={showTemplateTab}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <div
                    className="template-button-container templates-button"
                    onClick={() => {
                      setShowTemplateTab((prev) => !prev);
                    }}
                  >
                    <span className="flex-center icon-wrapper">
                      <AddTemplateIconS
                        className={'template-button-icon'}
                        color={colors.black}
                      />
                    </span>
                    <Typography fontSize={'12px'}>Templates</Typography>
                    {task?.template?.sequenceStepTemplateId ? (
                      <Typography
                        fontWeight={500}
                        fontSize={'12px'}
                        color={colors.purpleHover}
                        className="ab-tag"
                      >
                        A/B
                      </Typography>
                    ) : null}
                  </div>
                </TemplateCardsContainerTooltip>
                <div
                  className="template-button-container insights-button"
                  onClick={toggleInsightsTab}
                >
                  <span className="flex-center icon-wrapper">
                    <IconStarsInsights
                      className={'template-button-icon'}
                      color={colors.black}
                      viewBox="0 0 16 16"
                    />
                  </span>
                  <Typography fontSize={'12px'}>Insights</Typography>
                </div>
              </div>

              <TextareaAutosize
                id="textarea"
                ref={textAreaRef}
                minRows={4}
                className="textarea"
                value={formik.values.message}
                placeholder="Insert a message..."
                onSelect={handleSelect}
                onChange={(ev) => {
                  formik.setFieldValue('message', ev.target.value);
                }}
                onDrop={handleDrop}
              />
              {prospect?.linkedin &&
                (sending || isLinkedinStatusLoading ? (
                  <div className="send-invitation-button">
                    <CircularProgress />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      width: '100%',
                      justifyContent: 'space-between',
                      columnGap: 8,
                      padding: 8,
                      marginTop: 4,
                    }}
                  >
                    <MainButton
                      className="send-invitation-button"
                      disabled={
                        !formik.values.message || !formik.values.subject
                      }
                      onClick={handleClickSubmit}
                    >
                      Send
                    </MainButton>
                    {user && (
                      <button
                        type={'button'}
                        className={'rewrite-with-ai-button'}
                        onClick={onRewriteWithAIClick}
                      >
                        <IconOpenAI />
                        <p>Rewrite with AI</p>
                      </button>
                    )}
                  </div>
                ))}
            </div>
          </form>
        ) : task?.id ? (
          <NoSalesNavigatorLicenseInmailView
            taskId={task?.id}
            callOnSuccess={callOnSuccess}
          />
        ) : null}
      </InMailWrapper>
    </>
  );
};

export default InMailView;
