import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const StatusSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 50%;
  width: 100%;
  .statuses-container {
    display: flex;
    width: 100%;
    gap: 100px;
    height: 100%;
    align-items: flex-start;
  }
`;
