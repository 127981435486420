import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ExternalPropertyWrapper = styled.div`
  cursor: pointer;
  .tag-button {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3px 8px;
    width: fit-content;
    max-width: 180px;
    min-height: 28px;
  }
  .tag-button > svg {
    margin-right: 4px;
  }
  .remove-icon {
    margin-right: -4px !important;
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
  }
  .plus-icon {
    // margin-right: 6px !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
  }
  .tag-arrow {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: -4px !important;
  }
  .null-value-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
  }
`;
