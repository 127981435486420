import { Account } from './account';
import CallIntegrationType from './enums/callIntegrationType';
import { Prospect } from './prospect';

export interface LogCall {
  taskTodoId: number | null;
  suggestionId: number | null;
  accountId: string;
  prospectId: string;
  telephone: string;
  callFeedback: CallFeedback;
  note: string;
  account?: Account;
  prospect?: Prospect;
  eventDatetime?: Date;
  owner?: string;
  stopSequence?: boolean | null;
  externalLog?: string | null;
  externalDirectLink?: string | null;
  externalRegistrationLink?: string | null;
  externalDuration?: number | null;
  integrationType?: CallIntegrationType;
  templateTitle?: string | null;
}

export enum CallFeedback {
  CONNECTED = 'Connected',
  NOT_ANSWERED = 'NotAnswered',
  VOICEMAIL = 'Voicemail',
  GATE_KEEPER = 'GateKeeper',
  WRONG_NUMBER = 'WrongNumber',
}

export const getCallFeedbackString = (callFeedback: CallFeedback) => {
  switch (callFeedback) {
    case CallFeedback.CONNECTED:
      return 'connected';
    case CallFeedback.NOT_ANSWERED:
      return 'notAnswered';
    case CallFeedback.VOICEMAIL:
      return 'voicemail';
    case CallFeedback.GATE_KEEPER:
      return 'gatekeeper';
    case CallFeedback.WRONG_NUMBER:
      return 'wrongNumber';
    default:
      return '';
  }
};

export enum QuickAction {
  CALL_BACK = 'CallBack',
  STOP_PROSPECT = 'StopProspect',
  NURTURE = 'Nurture',
  INACTIVE = 'Inactive',
  CALL_SCHEDULED = 'CallScheduled',
}
