import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SuggestionsWrapper = styled.div`
  height: 100%;
  .suggestions-container {
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .suggestion-card {
    border-radius: 16px;
    margin: 0px 12px 4px 12px;
    padding: 8px;
  }
  .action-suggestion-button {
    width: 100px;
    padding-block: 4px;
    height: 32px;
    margin: 6px 6px 6px 0px !important;
  }

  .number-of {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    height: 24px;
    width: 24px;
    background: ${colors.grey55};
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divider {
    border-top: 1px solid #dddddd;
    opacity: 0.5;
    margin: 0px !important;
  }
  .no-suggestions-available-container {
    padding-bottom: 50px;
  }
`;
