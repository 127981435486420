import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';
import { ProspectScrape } from 'crono-fe-common/types/prospectScrape';
import { ProspectPhoneScrapeResult } from 'crono-fe-common/types/prospectPhoneScrapeResult';

export default function useGetScrapeFindBulkActive(enabled = true) {
  const request: Request = {
    url: ENDPOINTS.scrape.scrapeBulk.active,
    config: {
      method: 'get',
    },
  };
  const requestConfig = {
    queryKey: [ENDPOINTS.scrape.scrapeBulk.active],
    queryFn: () => client(request),
    enabled,
    staleTime: 60 * 60 * 1000, // 60 minutes
  };

  const { data, ...rest } = useQuery<
    Response<
      {
        scrapeBulk: {
          id: string;
          findEmail: boolean;
          findPhone: boolean;
          status: ImportStatusType;
          completed: number;
          total: number;
          emailFound: number;
          phoneFound: number;
        };
        prospectScrapes: ProspectScrape[];
        prospectScrapesPhone: ProspectPhoneScrapeResult[];
      }[]
    >,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
