import React from 'react';
import { AccountTabTabWrapper, AccountTabTabWrapperNoHover } from './style';

interface IProps {
  selected: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
  label: any;
  endIcon?: any;
  style?: any;
  showHoverOnLabelsOnly?: boolean;
}

const AccountTabTab = ({
  selected,
  onClick,
  icon,
  label,
  endIcon,
  style,
  showHoverOnLabelsOnly = false,
}: IProps) => {
  return showHoverOnLabelsOnly ? (
    <AccountTabTabWrapperNoHover
      onClick={onClick}
      className={selected ? 'selected' : ''}
      style={style}
    >
      {icon}
      {label}
      {endIcon}
    </AccountTabTabWrapperNoHover>
  ) : (
    <AccountTabTabWrapper
      onClick={onClick}
      className={selected ? 'selected' : ''}
      style={style}
    >
      {icon}
      {label}
      {endIcon}
    </AccountTabTabWrapper>
  );
};

export default AccountTabTab;
