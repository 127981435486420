import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SequenceStepPost } from 'crono-fe-common/types/DTO/sequenceStepPost';
import { SequenceStep } from 'crono-fe-common/types/sequenceStep';

export default function useAddSequenceStep() {
  const queryClient = useQueryClient();

  const postSequenceStep = (sequenceStepPost: SequenceStepPost) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.step}`,
      config: {
        method: 'post',
        data: { data: sequenceStepPost },
      },
    };
    return client(request);
  };

  return useMutation<Response<SequenceStep>, CronoError, SequenceStepPost>(
    postSequenceStep,
    {
      onSettled: (result, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.account.main]);
        queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
        queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey
              .toString()
              .startsWith(ENDPOINTS.prospect.search.main),
        });
        queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
        queryClient.invalidateQueries([ENDPOINTS.task.search]);
        queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
        queryClient.invalidateQueries([
          ENDPOINTS.sequence.detail,
          variables.strategyId,
        ]);
        queryClient.invalidateQueries([
          ENDPOINTS.sequence.strategy,
          variables.strategyId,
        ]);
      },
    },
  );
}
