import { ENDPOINTS } from 'config/endpoints';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { UpdateProspect } from 'crono-fe-common/types/DTO/updateProspect';

export function createEditProspectRequest(updateProspect: UpdateProspect) {
  const request: Request = {
    url: `${ENDPOINTS.prospect.main}`,
    config: {
      method: 'patch',
      data: updateProspect,
    },
  };
  return request;
}

export default function useEditProspect() {
  const queryClient = useQueryClient();

  const editProspect = (updateProspect: UpdateProspect) => {
    const request = createEditProspectRequest(updateProspect);
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    UpdateProspect
  >(editProspect, {
    onSettled: () => {
      invalidateEditProspectQueries(queryClient);
    },
  });

  return { data, mutate, ...rest };
}

export async function invalidateEditProspectQueries(queryClient: QueryClient) {
  await Promise.all([
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey.toString().startsWith(ENDPOINTS.account.main) ||
        query.queryKey.toString().startsWith(ENDPOINTS.prospect.main) ||
        query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
    }),
    queryClient.invalidateQueries([ENDPOINTS.prospect.main]),
    queryClient.invalidateQueries([ENDPOINTS.account.search.main]),
    queryClient.invalidateQueries([ENDPOINTS.task.search]),
  ]);
}
