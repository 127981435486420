import * as Yup from 'yup';

export const validationSchema = Yup.object({
  subject: Yup.string().required('Subject is required'),
  message: Yup.string()
    .required('Impossible to send an empty inmail')
    .test((value) => true),
});

export interface InMailFormik {
  subject: string;
  message: string;
}

export const initialValues = {
  message: '',
  subject: '',
};
