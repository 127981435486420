import ExternalPropertyComponent from 'crono-fe-common/components/ExternalProperty';
import React from 'react';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { ensureArray, getExternalPropertyLabels } from 'crono-fe-common/utils';
import { AddTagTooltipWrapper } from './style';
import { colors } from 'crono-fe-common/theme';

interface IProps {
  toShow: ExternalProperty & { indexMatch: number[] };
  handleClick: (
    option: string,
    externalProperty: ExternalProperty,
    index: number,
  ) => void;
  indexExternalProperty: number;
  currentOption?: string | string[] | null;
  //If we want the dropdown to have all the possible options empty if not selected
  useEmptySelection?: boolean;
}

const AddTagTooltip = ({
  toShow,
  handleClick,
  indexExternalProperty,
  currentOption,
  useEmptySelection = false,
}: IProps) => {
  return (
    <AddTagTooltipWrapper
      id="add-tag-tooltip"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        overflow: 'auto',
        maxHeight: '270px',
        paddingRight: '12px',
      }}
    >
      {getExternalPropertyLabels(toShow).map((label, index2) => {
        if (toShow.indexMatch.includes(index2)) {
          return (
            <ExternalPropertyComponent
              index={indexExternalProperty ?? 0}
              value={label}
              indexOption={index2}
              key={'' + index2}
              onClick={() => {
                if (toShow.options)
                  handleClick(
                    toShow.options[index2],
                    toShow,
                    indexExternalProperty,
                  );
              }}
              selected={
                ensureArray(currentOption).findIndex(
                  (option) => option === toShow.options?.[index2],
                ) > -1
              }
              useEmptySelection={useEmptySelection}
              fullWidth={useEmptySelection}
            />
          );
        }
      })}
    </AddTagTooltipWrapper>
  );
};

export default AddTagTooltip;
