import { useEffect } from 'react';
import useVerifyAircallIntegration from 'hooks/services/aircall/useVerifyAircallIntegration';
import { useAuth } from 'context/auth';
import CallIntegrationType from 'crono-fe-common/types/enums/callIntegrationType';

const useCallIntegrationGuard = () => {
  const { user } = useAuth();
  const {
    isLoading: isLoadingAircall,
    isError: isErrorAircall,
    isSuccess: isSuccessAircall,
    verifyAircallIntegration,
    error: errorAircall,
    data: dataAircall,
  } = useVerifyAircallIntegration();

  const verifyIntegration = () => {
    if (
      user &&
      user.company?.callIntegrationType === CallIntegrationType.AIRCALL
    ) {
      verifyAircallIntegration();
    }
  };

  useEffect(() => {
    verifyIntegration();
  }, [user]);

  return {
    isLoading: isLoadingAircall,
    isError: isErrorAircall,
    isSuccess: isSuccessAircall,
    error: errorAircall,
    data: dataAircall,
    verifyIntegration: verifyIntegration,
  };
};

export default useCallIntegrationGuard;
