import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .logo {
    width: 110px;
    height: auto;
    cursor: pointer;
  }

  .vertical-divider {
    border-left: 1px solid ${colors.grey4};
    margin-left: 16px;
    height: 40px;
  }
  .onboarding-features-button {
    height: 48px;
    width: 200px;
    border-radius: 34px;
    background-color: ${colors.primaryLight};
    display: flex;
    text-transform: unset;
    color: ${colors.black};
    margin-right: 16px;
  }
  .onboarding-features-button:hover {
    background-color: ${colors.featureOnboardingHover};
  }
  .onboarding-features-button-circular {
    /* size in CircularDeterminate must be the same here */
    height: 47px;
    width: 47px;
    border-radius: 34px;
    background-color: ${colors.primaryLight};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .layout-grid {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .tabs-item {
    padding: 0px !important;
  }
  .button,
  .icon-button {
    border-radius: 9999px;
    margin: 6px;
    font-size: 16px;
    text-transform: unset !important;
    height: 48px;
    width: 48px;
  }
  .profile-icon {
    border-radius: 9999px;
    font-size: 16px;
    text-transform: unset !important;
    height: 40px;
    width: 40px;
  }
  .name {
  }
  .icon-button {
    background: ${colors.grey4};
    color: ${colors.black};
  }
  .icon-button:hover {
    background: ${colors.greyButtonHover};
  }
  .icon {
    font-size: 34px;
  }
  .plus-icon {
    height: 34px;
    width: 34px;
  }
  .tab-section {
    padding: 0px 20px 0px 20px;
    background: ${colors.white} !important;
  }
  .tab,
  .tab-not-selected {
    text-transform: unset !important;
    color: black;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px;
    color: ${colors.primaryDark};
  }
  .tab-not-selected > span > svg > path,
  .tab-not-selected > svg > path,
  .tab-not-selected > svg > ellipse {
    opacity: 0.7;
    filter: grayscale(1);
  }

  .tab-not-selected > .tab-button-label {
    opacity: 0.7;
    filter: grayscale(1);
  }
  .tab-not-selected:hover > svg > path,
  .tab-not-selected:hover > svg > ellipse,
  .tab-not-selected:hover > span > svg > path,
  .tab-not-selected:hover > span > svg > ellipse,
  .tab-not-selected:hover > .tab-button-label {
    opacity: 1;
  }
  .tabs-container {
    display: flex;
    overflow: auto;
  }
  .table {
    height: calc(100% - 77px);
    width: 100%;
    padding: 14px;
    position: relative;
    background: ${colors.grey6};
    display: flex;
    flex-direction: column;
  }
  .top-section {
    padding: 5px 20px 0px 20px;
    background: ${colors.white} !important;
    position: relative;
  }
  .name-icon {
    display: flex;
    border-radius: 999px;
    padding-left: 18px;
    align-items: center;
    height: fit-content;
    column-gap: 8px;
    cursor: pointer;
    padding: 4px 6px 4px 10px;
  }
  .name-icon:hover {
    background: ${colors.grey6};
  }
  .company-name-container {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  .company-name {
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${colors.grey11};
  }
  .trial-badge {
    font-size: 12px;
    font-weight: 500;
    color: ${colors.darkGreen};
    background-color: ${colors.primaryLight};
    padding: 4px 8px;
    border-radius: 4px;
  }

  .analytics-tabs-container {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  .Mui-selected {
    background: ${colors.white};
    color: ${colors.primaryDark} !important;
    &:hover {
      background: ${colors.white} !important;
    }
  }
  .analytic-tab {
    text-transform: unset;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px;
    &:hover {
      background: ${colors.primaryLightHover};
    }
    padding: 12px !important;
  }
  .back-button {
    text-transform: unset;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px;
    padding: 12px 12px 12px 0px;
    color: ${colors.grey11};
  }

  .new-badge {
    position: relative;
    overflow: visible;

    &:after {
      position: absolute;
      content: 'New';
      top: 0;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      border-radius: 4px;
      padding: 0 5px;
      background: ${colors.orangeLight};
      color: ${colors.orangeDark};
      font-size: 10px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1135px) {
    .tab,
    .tab-not-selected {
      min-width: 54px;
      width: 54px;
    }
    .tab-button-label {
      display: none;
    }
  }
`;
