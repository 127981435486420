import styled from '@emotion/styled';

export const TagsBoxShowAllWrapper = styled.div``;

export const TagsBoxWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const AddTagTooltipWrapper = styled.div`
  .external-property {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .external-property-name {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .external-values-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
  }
  .external-property-container {
    padding: 8px 0px;
  }
`;
