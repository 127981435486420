import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { TemplateInputs } from 'crono-fe-common/types/DTO/settingsTemplate';
import { Template } from 'crono-fe-common/types/template';

export default function useCreateTemplate() {
  const queryClient = useQueryClient();

  const createTemplate = (templateInput: TemplateInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.template.main}`,
      config: {
        method: 'post',
        data: { data: templateInput },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<Template>,
    CronoError,
    TemplateInputs
  >(createTemplate, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.template.main]);
      queryClient.invalidateQueries([ENDPOINTS.template.search]);
      queryClient.invalidateQueries([ENDPOINTS.user.onboarding.features]);
    },
  });

  return { data, mutate, ...rest };
}
