import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

interface SyncProspectsDTO {
  data: string[];
  isLead?: boolean;
}

export default function useSyncProspects() {
  const queryClient = useQueryClient();

  const syncProspect = (data: SyncProspectsDTO) => {
    const request: Request = {
      url: `${ENDPOINTS.prospect.sync}`,
      config: {
        method: 'post',
        data: data,
      },
    };
    return client(request);
  };

  return useMutation<Response<number>, CronoError, SyncProspectsDTO>(
    syncProspect,
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey
              .toString()
              .startsWith(ENDPOINTS.prospect.search.main),
        });
        queryClient.invalidateQueries([ENDPOINTS.account.main]);
        queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
      },
    },
  );
}
