import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { colors } from 'crono-fe-common/theme';

const CircularDeterminateProgress = styled(CircularProgress)(({ theme }) => ({
  borderRadius: 5,
  [`& .${circularProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${circularProgressClasses.circle}`]: {
    borderRadius: 5,
    backgroundColor: colors.darkGreen,
  },
}));

interface IProps {
  value: number;
}

export default function CircularDeterminate({ value }: IProps) {
  return (
    <Stack spacing={1} direction="row">
      <CircularDeterminateProgress
        thickness={3}
        size="47px"
        variant="determinate"
        value={value}
      />
    </Stack>
  );
}
