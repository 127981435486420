import {
  CancelButton,
  MainButton,
  MainPrimaryButton,
} from 'crono-fe-common/components/CronoButton';
import { useConditionalSnackBar } from 'context/snackbar';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { FC, useEffect, useRef } from 'react';
import { GeneralWrapper } from '../styles';
import { EmailSettingsInput } from './model';
import useEmailSaveSettings from 'hooks/services/email/useSaveSettings';
import { useAuth } from 'context/auth';
import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CronoEditor from 'components/CronoEditor/CronoEditor';
import ReactQuill from 'react-quill';
import {
  ExistingSignatureViewWrapper,
  SignatureCreateViewWrapper,
  SignatureGeneralFormWrapper,
} from './styles';
import CreateByCopyPasteImage from 'assets/images/Create_By_Copy_Paste.png';
import CreateWithCronoEditorImage from 'assets/images/Create_With_Crono_Editor.png';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import { createHtmlBlot } from 'components/CronoEditor/CronoEditorToolbar';
import { FeConstants } from 'constants/FeConstants';
import { formatHtmlContent } from 'utils/fe-utils';
import IconRegenerate from 'crono-fe-common/icons/Icon-Regenerate';
import usePatchGoogleSignature from 'hooks/services/google/usePatchGoogleSignature';
import TypeCard from './typeCard';

type CreateMode = 'createFromScratch' | 'createByCopyPaste';

const SignatureSettings: FC = () => {
  const { user } = useAuth();
  const createSignatureEditorRef = useRef<ReactQuill | null>(null);
  const editEditorRef = useRef<ReactQuill | null>(null);

  const { isSuccess, isError, mutate: saveSettings } = useEmailSaveSettings();

  useEffect(() => {
    if (isSuccess && user) {
      user.emailSettings = formik.values;
      setIsEdited(false);
    }
  }, [isSuccess]);

  const inititalEditViewState = {
    isEdited: false,
    isEditView: false,
  };
  const [editView, setEditView] = useState(inititalEditViewState);

  const initialCreateViewState = {
    isCreateView: false,
    createViewStep: 0,
    createMode: null,
  };
  const [createView, setCreateView] = useState<{
    isCreateView: boolean;
    createViewStep: number;
    createMode: CreateMode | null;
  }>(initialCreateViewState);

  const htmlShowAndEditSignatureRef = React.createRef<HTMLDivElement>();
  const htmlCopyPasteSignatureRef = React.createRef<HTMLDivElement>();

  const formik = useFormik<EmailSettingsInput>({
    initialValues: {
      integrationType: EmailIntegrationType.GMAIL,
      signature: user?.emailSettings?.signature || '',
    },
    onSubmit: (values) => {
      values = { ...values, signature: formatHtmlContent(values.signature) };
      saveSettings(values);
      setCreateView(initialCreateViewState);
    },
  });

  const setIsEdited = (newState: boolean) => {
    setEditView((prev) => ({ ...prev, isEdited: newState }));
  };

  const setIsEditMode = (newState: boolean) => {
    setEditView((prev) => ({ ...prev, isEditView: newState }));
  };

  const handleCopyPasteSignatureInput = () => {
    if (htmlCopyPasteSignatureRef.current?.innerHTML) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  };

  const handleEditDivInput = () => {
    if (
      htmlShowAndEditSignatureRef.current?.innerHTML !=
      user?.emailSettings?.signature
    ) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  };

  useEffect(() => {
    if (htmlShowAndEditSignatureRef.current && user?.emailSettings) {
      htmlShowAndEditSignatureRef.current.innerHTML =
        user.emailSettings.signature || '';
    }
  }, [user?.emailSettings?.signature]);

  const cancelCreateSignatureProcess = () => {
    if (user?.emailSettings?.signature) {
      formik.setFieldValue('signature', user.emailSettings.signature);
    }

    setCreateView(initialCreateViewState);
  };

  const moveToSecondStepOfCreate = () => {
    setCreateView((prev) => ({ ...prev, createViewStep: 1 }));
  };

  const moveToFirstStepOfCreate = () => {
    setCreateView((prev) => ({ ...prev, createViewStep: 0 }));
  };

  const setCreateMode = (mode: CreateMode) => {
    setCreateView((prev) => ({ ...prev, createMode: mode }));
  };

  const enableCreateView = () => {
    setCreateView((prev) => ({ ...prev, isCreateView: true }));
  };

  const stepsJSX = useMemo(
    () => (
      <Box
        sx={{
          width: '155px',
        }}
      >
        <Stepper activeStep={createView.createViewStep}>
          {[1, 2].map((step, index) => {
            return (
              <Step style={{ padding: '0px' }} key={index}>
                <StepLabel
                  sx={{
                    padding: '0px',
                    '& .MuiStepLabel-iconContainer': {
                      padding: '0px',
                    },
                    '& .MuiStepIcon-text': {
                      fill:
                        createView.createViewStep !== index ? colors.grey2 : '',
                    },
                    '& .MuiStepIcon-root': {
                      height: '30px',
                      width: '30px',
                      fill:
                        createView.createViewStep !== index ? colors.white : '',
                      border: '1px solid',
                      borderRadius: '999px',
                    },
                    '& .Mui-active, .Mui-completed': {
                      border: 'none !important',
                    },
                    '& .Mui-completed': {
                      fill: colors.primary,
                    },
                  }}
                />
              </Step>
            );
          })}
        </Stepper>
      </Box>
    ),
    [createView.createViewStep],
  );

  const isCreateFromScratch = createView.createMode === 'createFromScratch';

  const {
    mutateAsync: patchGoogleSignature,
    isLoading: isLoadingPatchGoogleSignature,
    error: errorPatchGoogleSignature,
    isSuccess: isSuccessPatchGoogleSignature,
  } = usePatchGoogleSignature();

  const handleReloadSignature = async () => {
    const emailSettings = await patchGoogleSignature();
    if (emailSettings.data?.data) {
      formik.setFieldValue('signature', emailSettings.data.data.signature);
      if (user) {
        user.emailSettings = emailSettings.data.data;
      }
    }
  };

  useConditionalSnackBar([
    {
      condition: !!isError,
      message: 'Error during signature save',
      severity: 'error',
    },
    {
      condition: !!isSuccess,
      message: 'Succesfully saved signature',
      severity: 'success',
    },
    {
      condition: !!isSuccessPatchGoogleSignature,
      message: 'Signature successfully reloaded',
      severity: 'success',
    },
    {
      condition: !!errorPatchGoogleSignature,
      message: 'Error during signature reload',
      severity: 'error',
    },
  ]);

  return (
    <GeneralWrapper style={{ height: '100%' }}>
      <SignatureGeneralFormWrapper
        onSubmit={formik.handleSubmit}
        className="mail-form"
        style={{
          height: '100%',
          margin: createView.isCreateView ? 'auto' : 'inherit',
        }}
      >
        {createView.isCreateView && (
          <SignatureCreateViewWrapper>
            {stepsJSX}
            {createView.createViewStep === 0 && (
              <>
                <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
                  How would you like to create a signature?
                </Typography>
                <div className={'cards-container'}>
                  <TypeCard
                    active={isCreateFromScratch}
                    onClick={() => setCreateMode('createFromScratch')}
                    image={CreateWithCronoEditorImage}
                    title={'Create from scratch'}
                    subtitle={'The data will be added like contacts'}
                  />
                  <TypeCard
                    active={createView.createMode === 'createByCopyPaste'}
                    onClick={() => setCreateMode('createByCopyPaste')}
                    image={CreateByCopyPasteImage}
                    title={'Create by copy & paste'}
                    subtitle={
                      'Just copy & paste your ready-made layout of signature'
                    }
                  />
                </div>
                <div className={'buttons-container'}>
                  <CancelButton onClick={cancelCreateSignatureProcess}>
                    Cancel
                  </CancelButton>
                  <MainButton
                    disabled={!createView.createMode}
                    onClick={moveToSecondStepOfCreate}
                  >
                    Next
                  </MainButton>
                </div>
              </>
            )}

            {createView.createViewStep === 1 && (
              <>
                <div>
                  <div style={{ marginBottom: '32px' }}>
                    <Typography
                      fontSize={24}
                      fontWeight={700}
                      lineHeight={'30px'}
                    >
                      {isCreateFromScratch
                        ? 'Create from scratch your signature'
                        : 'Copy & paste your signature'}
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      lineHeight={'24px'}
                      color={colors.grey11}
                      maxWidth={'486px'}
                      marginX={'auto'}
                    >
                      {isCreateFromScratch
                        ? 'You can use Crono editor to create a signature'
                        : 'If you already use signature in your emails you can just paste the signature layout here'}
                    </Typography>
                  </div>
                  {isCreateFromScratch ? (
                    <CronoEditor
                      editorRef={createSignatureEditorRef}
                      placeholder={'Type signature...'}
                      imageSupport={true}
                      value={formik.values.signature}
                      onChange={(text) => {
                        formik.setFieldValue('signature', text);
                        setIsEdited(true);
                      }}
                    />
                  ) : (
                    <div className={'html-div-container'}>
                      <div
                        ref={htmlCopyPasteSignatureRef}
                        onInput={handleCopyPasteSignatureInput}
                        contentEditable={true}
                        className="html-div dark-scrollbar"
                      />
                    </div>
                  )}
                </div>

                <div className={'buttons-container'}>
                  <CancelButton onClick={moveToFirstStepOfCreate}>
                    Back
                  </CancelButton>
                  <MainButton
                    disabled={!editView.isEdited}
                    onClick={() => {
                      if (
                        createView.createMode === 'createByCopyPaste' &&
                        htmlCopyPasteSignatureRef.current?.innerHTML
                      ) {
                        const newValue = createHtmlBlot(
                          htmlCopyPasteSignatureRef.current.innerHTML,
                        );
                        formik.setFieldValue('signature', newValue);
                        formik.handleSubmit();
                      } else if (
                        isCreateFromScratch &&
                        createSignatureEditorRef.current
                      ) {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    Save
                  </MainButton>
                </div>
              </>
            )}
          </SignatureCreateViewWrapper>
        )}

        {/*We use 'display: none | inherit' instead of conditional rendering to save htmlShowAndEditSignatureRef.current.innerHtml during rerender */}
        <ExistingSignatureViewWrapper
          style={{
            display:
              !createView.isCreateView && !!user?.emailSettings?.signature
                ? 'inherit'
                : 'none',
          }}
        >
          <div className={'header'}>
            <div className={'text-container'}>
              <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
                Signature settings
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                lineHeight={'24px'}
                color={colors.grey11}
              >
                When you add a signature, it will appear in the draft of every
                email. You can always delete or change it.
              </Typography>
            </div>

            <MainPrimaryButton
              style={{ width: '130px' }}
              onClick={() => {
                formik.setFieldValue('signature', '');
                enableCreateView();
              }}
            >
              Create new
            </MainPrimaryButton>
          </div>

          {editView.isEditView &&
            !user?.emailSettings?.signature?.includes(
              FeConstants.htmlBlotTagName,
            ) && (
              <Grid container>
                <Grid item xs={12} className="editor-container">
                  <CronoEditor
                    editorRef={editEditorRef}
                    imageSupport={true}
                    value={formik.values.signature}
                    onChange={(text) => {
                      if (text.replace(/<p>|<\/p>|<br>/g, '').trim()) {
                        formik.setFieldValue('signature', text);
                      } else {
                        formik.setFieldValue('signature', '');
                      }
                      setIsEdited(true);
                    }}
                  />
                  <div className={'control-buttons'}>
                    <CancelButton
                      onClick={() => {
                        setEditView(inititalEditViewState);
                      }}
                    >
                      Cancel
                    </CancelButton>
                    <MainButton
                      disabled={!editView.isEdited}
                      onClick={() => {
                        setIsEditMode(false);
                        formik.handleSubmit();
                      }}
                    >
                      Save
                    </MainButton>
                  </div>
                </Grid>
              </Grid>
            )}

          <div
            className={'html-div-container'}
            style={{
              display:
                !editView.isEditView ||
                user?.emailSettings?.signature?.includes(
                  FeConstants.htmlBlotTagName,
                )
                  ? 'inherit'
                  : 'none',
            }}
          >
            <div
              ref={htmlShowAndEditSignatureRef}
              defaultValue={user?.emailSettings?.signature || ''}
              contentEditable={editView.isEditView ? true : false}
              onInput={handleEditDivInput}
              className="html-div dark-scrollbar"
              placeholder={'Type signature...'}
            />
            {editView.isEditView ? (
              <div className={'control-buttons'}>
                <CancelButton
                  onClick={() => {
                    if (
                      user?.emailSettings?.signature &&
                      htmlShowAndEditSignatureRef.current
                    ) {
                      htmlShowAndEditSignatureRef.current.innerHTML =
                        user.emailSettings.signature;
                    }
                    setEditView(inititalEditViewState);
                  }}
                >
                  Cancel
                </CancelButton>
                <MainButton
                  disabled={!editView.isEdited}
                  onClick={() => {
                    if (htmlShowAndEditSignatureRef.current) {
                      formik.setFieldValue(
                        'signature',
                        htmlShowAndEditSignatureRef.current?.innerHTML,
                      );
                    }
                    setIsEditMode(false);
                    formik.handleSubmit();
                  }}
                >
                  Save
                </MainButton>
              </div>
            ) : (
              <div className="left-control-buttons">
                {user?.company?.emailIntegrationType ===
                  EmailIntegrationType.GMAIL &&
                  (isLoadingPatchGoogleSignature ? (
                    <CircularProgress size={40} />
                  ) : (
                    <Tooltip
                      enterDelay={400}
                      enterNextDelay={400}
                      arrow
                      title={`Reload the signature from ${user.company?.emailIntegrationType}`}
                    >
                      <IconButton
                        className="reload-signature-button"
                        onClick={handleReloadSignature}
                      >
                        <IconRegenerate color={colors.white} />
                      </IconButton>
                    </Tooltip>
                  ))}

                <MainButton
                  className={'edit-button'}
                  onClick={() => setIsEditMode(true)}
                  startIcon={<EditPencilIconS color={colors.white} />}
                >
                  Edit
                </MainButton>
              </div>
            )}
          </div>
        </ExistingSignatureViewWrapper>
        {!createView.isCreateView && !user?.emailSettings?.signature && (
          <>
            <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
              Signature settings
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              lineHeight={'24px'}
              color={colors.grey11}
            >
              When you add a signature, it will appear in the draft of every
              email. You can always delete or change it.
            </Typography>
            <MainPrimaryButton
              style={{ width: '120px' }}
              onClick={() => {
                formik.setFieldValue('signature', '');
                enableCreateView();
              }}
            >
              Create
            </MainPrimaryButton>
          </>
        )}
      </SignatureGeneralFormWrapper>
    </GeneralWrapper>
  );
};

export default SignatureSettings;
