import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';

interface IProps {
  name: string;
  publicId?: string;
  numericId?: string;
  searchInCrm: boolean;
  enabled?: boolean;
}

export default function useAccountLinkedin({
  name,
  publicId,
  numericId,
  searchInCrm,
  enabled = true,
}: IProps) {
  // get key value object from searchParameters
  const queryString = new URLSearchParams({
    name: name.trim(),
    ...(publicId && { publicId: publicId.trim() }),
    ...(numericId && { numericId: numericId.trim() }),

    searchInCrm: searchInCrm.toString(),
  }).toString();

  const request: Request = {
    url: ENDPOINTS.account.linkedin.main + '?' + queryString,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.account.linkedin.main, queryString],
    queryFn: () => client(request),
    enabled: enabled === undefined ? true : enabled,
  };

  const { data, ...rest } = useQuery<Response<AccountLinkedin[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
