import { CircularProgress, IconButton, Typography } from '@mui/material';
import { useConfirmModal } from 'context/confirmModal';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import usePatchTask from 'hooks/services/task/usePatchTask';
import useSearchTasks from 'hooks/services/task/useSearchTasks';
import { useSelectedAccount } from 'pages/home/context/account';
import RescheduleCalendar from 'pages/taskBoard/rescheduleCalendar';
import SuggestionsBox from 'pages/taskBoard/suggestionsBox';
import TaskCard from 'pages/taskBoard/taskCard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { getError } from 'crono-fe-common/utils';
import { Constants } from 'crono-fe-common/constants/constants';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { rowPlaceholderFillOpacities } from 'constants/FeConstants';
import { ReactComponent as NoTasks } from '../../../../assets/images/No_tasks_placeholder.svg';
import { ReactComponent as NoTasksTooltip } from '../../../../assets/images/No_tasks_tooltip_placeholder.svg';
import TaskPlaceholderEmail from '../../../../assets/images/Placeholder_task_email.png';
import TaskPlaceholderLinkedin from '../../../../assets/images/Placeholder_task_linkedin.png';
import TaskPlaceholderCall from '../../../../assets/images/Placeholder_task_call.png';
import { colors } from 'crono-fe-common/theme';
import { TuneRounded } from '@mui/icons-material';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { FilterModalContainer } from 'pages/accountTab/filter/style';
import FilterTasks from 'pages/accountTab/filter/filterTasks';
import AddTaskContainer from 'pages/addComponents/addTaskContainer';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import IconRightArrow from 'crono-fe-common/icons/Icon-Right-Arrow';
import { createPortal } from 'react-dom';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import { useUpgradeModal } from 'context/upgradeModal';

export type PickedTaskFilters = {
  prospectId: string | null;
  opportunityId: string | null;
  type: TaskTodoType | null;
};

interface IProps {
  accountId?: string | null;
  prospectId?: string | null;
  opportunityId?: string | null;
  setTabValue?: (value: number) => void;
  setNTasks?: React.Dispatch<React.SetStateAction<number | null>>;
  dealTabTooltip?: boolean;

  // Modal needs to be set like this since the creation modal is opened from inside a tooltip
  openTaskCreation?: () => void;
}

const FutureTasksBox = ({
  accountId,
  prospectId,
  opportunityId,
  setTabValue,
  setNTasks,
  dealTabTooltip,
  openTaskCreation,
}: IProps) => {
  const { account } = useSelectedAccount();
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

  // Filters logic ----
  const [selectedProspect, setSelectedProspect] = useState<
    string | null | undefined
  >(prospectId);
  const [selectedType, setSelectedType] = useState<TaskTodoType | null>(null);
  const [selectedOpportunityId, setSelectedOpportunityId] = useState<
    string | null
  >(opportunityId ?? null);

  const [appliedFilters, setAppliedFilters] = useState<number>(0);

  // Missing typing check
  const changeFilters = (filters: PickedTaskFilters) => {
    setSelectedProspect(filters.prospectId);
    setSelectedType(filters.type);
    setSelectedOpportunityId(filters.opportunityId);
    setAppliedFilters(
      Object.values(filters).filter((value) => value !== null).length,
    );
  };
  // ------------------

  const [toDate, setToDate] = React.useState<Date>(new Date());
  const [sinceDate, setSinceDate] = useState<Date>(new Date());
  const [addTaskManually, setAddTaskManually] = useState<boolean>(false);

  const analytics = useJuneAnalytics();

  useEffect(() => {
    setToDate(new Date(toDate.setFullYear(toDate.getFullYear() + 5)));
    const sinceDate = new Date();
    sinceDate.setHours(0, 0, 0, 0);
    sinceDate.setMonth(sinceDate.getMonth() - 1);
    setSinceDate(sinceDate);
  }, []);

  const { data: tasks, isLoading: loadingSearchTasks } = useSearchTasks(
    {
      limit: Constants.defaultLimit,
      offset: 0,
      accountId: accountId,
      since: sinceDate,
      to: toDate,
      prospectId: selectedProspect,
      type: selectedType,
      opportunityId: selectedOpportunityId,
    },
    true,
  );

  const tasksThisWeek = useMemo(() => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    //Today is saturday or sunday
    if (today.getDay() === 6 || today.getDay() === 0) return [];
    const dayOfWeek = today.getDay(); // Sunday is 0, Monday is 1, and so on

    // Calculate the number of days to add to the current date to reach Friday
    const daysToAdd = (5 - dayOfWeek + 7) % 7;

    // Create a new date object and add the calculated days to get the Friday of the current week
    const friday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + daysToAdd,
    );
    friday.setHours(23, 59, 59, 999);
    return tasks?.data?.data.filter((task) => {
      return (
        new Date(task.activityDate) <= friday &&
        new Date(task.activityDate) >= today
      );
    });
  }, [tasks]);

  // const overdueTasks = useMemo(() => {
  //   const today = new Date();
  //   today.setUTCHours(0, 0, 0, 0);
  //   return tasks?.data?.data.filter((task) => {
  //     return new Date(task.activityDate) < today;
  //   });
  // }, [tasks]);
  const tasksNext = useMemo(() => {
    const today = new Date();
    //Today is saturday or sunday
    if (today.getDay() === 6 || today.getDay() === 0) return tasks?.data?.data;
    const dayOfWeek = today.getDay(); // Sunday is 0, Monday is 1, and so on

    // Calculate the number of days to add to the current date to reach Friday
    const daysToAdd = (5 - dayOfWeek + 7) % 7;

    // Create a new date object and add the calculated days to get the Friday of the current week
    const friday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + daysToAdd,
    );
    friday.setHours(23, 59, 59, 999);
    return tasks?.data?.data.filter((task) => {
      return new Date(task.activityDate) > friday;
    });
  }, [tasks]);

  const {
    mutate: patchTask,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
    error: errorPatchTask,
  } = usePatchTask();

  const handleChangeTaskDate = (id: number, newDate: Date) => {
    patchTask({
      id: id,
      activityDate: newDate,
    });
    if (analytics) {
      analytics.track('patch-task', {
        type: 'reschedule',
      });
    }
  };

  const handleOpenDateCalendar = (task: TaskTodo) => {
    //handleChangeTaskDate(index);
    setShowRescheduleTask({ index: task.id, initialDate: task.activityDate });
  };

  const handleChangeDate = (newDate: Date) => {
    handleChangeTaskDate(showRescheduleTask!.index, newDate);
  };

  const [showRescheduleTask, setShowRescheduleTask] = useState<{
    index: number;
    initialDate: Date;
  } | null>(null);

  useEffect(() => {
    if (patchSuccess) setShowRescheduleTask(null);
  }, [patchSuccess]);

  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteFutureTask = (id: number) => {
    openConfirmModal({
      title: 'Are you sure you want to skip this task?',
      text: 'The task will be skipped',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmFunction: () => {
        patchTask({
          id: id,
          skipped: true,
        });
        if (analytics) {
          analytics.track('patch-task', {
            type: 'skip',
          });
        }
      },
      cancelFunction: () => null,
    });
  };
  useConditionalSnackBar([
    {
      condition: !!errorPatchTask,
      message: getError(errorPatchTask) ?? 'Error updating task',
      severity: 'error',
    },
    {
      condition: !!patchSuccess,
      message: 'Task updated successfully',
      severity: 'success',
    },
  ]);

  const hasTasks = useCallback(() => {
    return (
      // (overdueTasks !== undefined && overdueTasks?.length > 0) ||
      (tasksThisWeek !== undefined && tasksThisWeek?.length > 0) ||
      (tasksNext !== undefined && tasksNext?.length > 0)
    );
  }, [
    // overdueTasks,
    tasksThisWeek,
    tasksNext,
  ]);

  const tasksCount = useMemo(() => {
    return (tasksThisWeek?.length ?? 0) + (tasksNext?.length ?? 0);
  }, [tasksThisWeek, tasksNext]);

  useEffect(() => {
    if (setNTasks) {
      setNTasks(tasksCount);
    }
  }, [tasksCount]);

  const rowPlaceholder = rowPlaceholderFillOpacities.map((el, index) => {
    return (
      <div key={index} style={{ width: '100%' }}>
        <img
          src={TaskPlaceholderEmail}
          alt="Empty contact placeholder"
          style={{ opacity: (el - 0.15) / (index + 1), width: '100%' }}
        />
        <img
          src={TaskPlaceholderLinkedin}
          alt="Empty contact placeholder"
          style={{ opacity: (el - 0.25) / (index + 1), width: '100%' }}
        />
        <img
          src={TaskPlaceholderCall}
          alt="Empty contact placeholder"
          style={{ opacity: (el - 0.35) / (index + 1), width: '100%' }}
        />
      </div>
    );
  });

  const { isUpgradeModalVisible } = useUpgradeModal();

  if (!account) return <></>;

  return (
    <>
      {!opportunityId && filtersVisible && (
        <BlurBackgroundDivFixed>
          <FilterModalContainer style={{ maxHeight: '90%', height: 'auto' }}>
            <div className="add-main-container">
              <div className="modal-header">
                <CloseTabButton close={() => setFiltersVisible(false)} />
                <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
                  Filter tasks:
                </Typography>
              </div>
              <FilterTasks
                accountId={account.objectId}
                close={() => setFiltersVisible(false)}
                handleSubmit={changeFilters}
                pickedFilters={{
                  prospectId: selectedProspect ?? null,
                  opportunityId: selectedOpportunityId,
                  type: selectedType,
                }}
              />
            </div>
          </FilterModalContainer>
        </BlurBackgroundDivFixed>
      )}
      {/* Modal for Workload view only */}
      {!isUpgradeModalVisible &&
        addTaskManually &&
        createPortal(
          <AddTaskContainer
            close={() => setAddTaskManually(false)}
            initialCompany={account}
          />,
          document.body,
        )}
      {!opportunityId && showRescheduleTask && (
        <RescheduleCalendar
          isRescheduleLoading={patchLoading}
          close={() => setShowRescheduleTask(null)}
          handleChangeDate={handleChangeDate}
          initialDate={showRescheduleTask.initialDate}
        />
      )}
      <div style={{ height: !opportunityId ? '100%' : 'calc(100% - 18px)' }}>
        <div className={!opportunityId ? 'box-header' : 'box-header-tooltip'}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography fontSize={16} fontWeight={600} lineHeight={'24px'}>
              {!opportunityId ? 'Future Tasks' : 'Deal tasks'}
            </Typography>
            {opportunityId ? (
              <IconButton
                className="add-task-button"
                size="small"
                onClick={() => openTaskCreation && openTaskCreation()}
              >
                <PlusSmallIcon
                  color={colors.black}
                  style={{ width: '20px', height: '20px' }}
                />
              </IconButton>
            ) : null}
          </div>
          {opportunityId ? (
            <div
              className="go-to-button"
              onClick={() => setTabValue && setTabValue(2)}
            >
              <Typography fontSize={'14px'} fontWeight={500}>
                Go to tasks
              </Typography>
              <IconRightArrow />
            </div>
          ) : null}
          {!opportunityId ? (
            <div
              style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <IconButton
                className="icon-button"
                size="large"
                onClick={() => setAddTaskManually(true)}
              >
                <PlusIcon color={colors.white} />
              </IconButton>
              <div
                className="filter-button"
                onClick={() => setFiltersVisible(true)}
              >
                <TuneRounded />
                {appliedFilters ? (
                  <span className="number-of">{appliedFilters}</span>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>

        <div
          className={
            !opportunityId
              ? 'tasks-list-container'
              : 'tasks-list-container-tooltip'
          }
        >
          <div className={!opportunityId ? 'tasks-list' : 'tasks-list-tooltip'}>
            {loadingSearchTasks ? (
              <FlexDiv>
                <CircularProgress />
              </FlexDiv>
            ) : hasTasks() ? (
              <>
                {/* {overdueTasks !== undefined && overdueTasks?.length > 0 && (
                      <>
                        <Typography fontSize={14} fontWeight={500}>
                          Overdue tasks
                        </Typography>
                        {overdueTasks?.map((task, index) => {
                          return (
                            <TaskCard
                              key={index}
                              task={task}
                              overdue={true}
                              companyTab={true}
                              handleOpenDateCalendar={handleOpenDateCalendar}
                              handleSkipTask={handleDeleteFutureTask}
                            />
                          );
                        })}
                      </>
                    )} */}
                {tasksThisWeek !== undefined && tasksThisWeek?.length > 0 && (
                  <>
                    {!opportunityId ? (
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color={colors.grey11}
                        paddingLeft={'12px'}
                      >
                        This week
                      </Typography>
                    ) : null}
                    {tasksThisWeek?.map((task, index) => {
                      return (
                        <TaskCard
                          key={index}
                          task={task}
                          companyTab={true}
                          handleOpenDateCalendar={handleOpenDateCalendar}
                          handleSkipTask={handleDeleteFutureTask}
                          dealTabTooltip={dealTabTooltip}
                        />
                      );
                    })}
                  </>
                )}
                {tasksNext !== undefined && tasksNext?.length > 0 && (
                  <>
                    {!opportunityId ? (
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color={colors.grey11}
                        paddingLeft={'12px'}
                      >
                        Next
                      </Typography>
                    ) : null}
                    {tasksNext?.map((task, index) => {
                      return (
                        <TaskCard
                          key={index}
                          task={task}
                          companyTab={true}
                          handleOpenDateCalendar={handleOpenDateCalendar}
                          handleSkipTask={handleDeleteFutureTask}
                          dealTabTooltip={dealTabTooltip}
                        />
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <FlexDiv
                direction="column"
                position="relative"
                justifyContent="start"
                style={{ overflow: 'hidden' }}
              >
                {rowPlaceholder}
                <FlexDiv direction="column" position="absolute" gap="20px">
                  <NoTasksTooltip />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    {!opportunityId ? (
                      <Typography
                        fontSize={'16px'}
                        fontWeight={600}
                        lineHeight={'24px'}
                        textAlign={'center'}
                      >
                        No upcoming tasks available
                      </Typography>
                    ) : (
                      <Typography
                        fontSize={'14px'}
                        fontWeight={500}
                        lineHeight={'24px'}
                        textAlign={'center'}
                      >
                        No upcoming tasks for this deal
                      </Typography>
                    )}

                    {!opportunityId && (
                      <Typography
                        fontSize={'14px'}
                        fontWeight={400}
                        lineHeight={'24px'}
                        maxWidth={'260px'}
                        textAlign={'center'}
                        color={colors.grey11}
                      >
                        Make sure to enrol contacts into strategies or create
                        manual tasks to see all upcoming tasks here
                      </Typography>
                    )}
                  </div>
                </FlexDiv>
              </FlexDiv>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FutureTasksBox;
