import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC, useEffect } from 'react';
import {
  CircularProgress,
  ClickAwayListener,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';

import { Box } from '@mui/system';

import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { colors } from 'crono-fe-common/theme';

enum ImportType {
  Accounts = 'Accounts',
  Contacts = 'Contacts',
  Leads = 'Leads',
}

export type ImportTypeMessage = {
  type: ImportType;
  description: string;
};

const steps = [
  'What data would you like to import ?',
  'Upload your file',
  'Map all required fields to import data correctly',
  'Enrich data from your file',
];

interface IProps {
  activeStep: number;
  setActiveStep: any;
  children: any;
  disableNext: boolean;
  resetSelectedType: () => void;
  handleBack: (step: number) => void;
  mapAndImport: () => void;
  isImporting: boolean;
  importType: ImportType | null;
  setDisableNext: (val: boolean) => void;
  importError: string | null;
}

const StepsProgress: FC<IProps> = ({
  activeStep,
  setActiveStep,
  children,
  disableNext,
  resetSelectedType,
  handleBack,
  mapAndImport,
  isImporting,
  importType,
  setDisableNext,
  importError,
}: IProps) => {
  // useEffect(() => {
  //   console.log({ activeStep });
  //   return () => {
  //     console.log("unmounts");
  //     // setActiveStep(0);
  //   };
  // });

  // Optional logic, should we need to implement it in the future
  // https://mui.com/material-ui/react-stepper/
  // const [skipped, setSkipped] = useState(new Set<number>());
  // const isStepOptional = (step: number) => {
  //   return step === 1;
  // };
  // const isStepSkipped = (step: number) => {
  //   return skipped.has(step);
  // };
  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const handleNext = () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }
    if (importError) {
      setDisableNext(true);
    }

    const isLastStep: boolean =
      (importType === ImportType.Accounts && activeStep === steps.length - 2) ||
      activeStep === steps.length - 1;

    if (isLastStep) {
      mapAndImport();
    } else {
      // This is to reset disableNext and take uploaded files as condition for enabling
      if (activeStep === 0) {
        resetSelectedType();
      }

      setActiveStep((prevActiveStep: number) => prevActiveStep + 1);

      // setSkipped(newSkipped);
    }
  };

  const handleBackStep = () => {
    handleBack(activeStep - 1);

    setDisableNext(false);
  };

  const handleEditImportType = () => {
    // Always goes to step 1
    handleBack(0);
    setDisableNext(false);
  };

  const returnLabel = (importType: ImportType) => {
    switch (importType) {
      case ImportType.Accounts:
        return 'companies';
      case ImportType.Contacts:
        return 'contacts';
      case ImportType.Leads:
        return 'leads';
      default:
        return '';
    }
  };
  return (
    // <ClickAwayListener onClickAway={() => handleBack(-1)}>
    <FlexDiv
      alignItems="center"
      justifyContent="center"
      style={{ rowGap: 40 }}
      direction="column"
      // width={"500px"}
    >
      <Box sx={{ width: '280px' }}>
        <Stepper activeStep={activeStep}>
          {steps
            .slice(
              0,
              importType === ImportType.Accounts || importType === null ? 3 : 4,
            )
            .map((label, index) => {
              const stepProps: { completed?: boolean } = {};

              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              // if (isStepOptional(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption">Optional</Typography>
              //   );
              // }
              // if (isStepSkipped(index)) {
              //   stepProps.completed = false;
              // }
              return (
                <Step style={{ padding: '0px' }} key={label} {...stepProps}>
                  <StepLabel
                    sx={{
                      padding: '0px',
                      '& .MuiStepLabel-iconContainer': {
                        padding: '0px',
                      },
                      '& .MuiStepIcon-text': {
                        fill: activeStep !== index ? colors.grey2 : '',
                      },
                      '& .MuiStepIcon-root': {
                        height: '30px',
                        width: '30px',
                        fill: activeStep !== index ? colors.white : '',
                        border: '1px solid',
                        borderRadius: '999px',
                      },
                      '& .Mui-active, .Mui-completed': {
                        border: 'none !important',
                      },
                      '& .Mui-completed': {
                        fill: colors.primary,
                      },
                    }}
                    {...labelProps}
                  ></StepLabel>
                </Step>
              );
            })}
        </Stepper>
      </Box>

      <Box className="step-title-container">
        <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
          {steps[activeStep]}
        </Typography>

        {activeStep > 0 && importType && (
          <Typography
            fontSize={16}
            fontWeight={500}
            lineHeight={'24px'}
            color={colors.grey11}
          >
            {activeStep === 3 ? (
              <>Crono can find your missing contact data</>
            ) : (
              <>
                You are importing {returnLabel(importType)}.{' '}
                <span
                  style={{ color: colors.primaryDark, cursor: 'pointer' }}
                  onClick={handleEditImportType}
                >
                  Edit
                </span>
              </>
            )}
          </Typography>
        )}
      </Box>

      {children}

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <CancelButton
          style={{ width: '120px' }}
          // disabled={activeStep === 0}
          onClick={handleBackStep}
        >
          {activeStep === 0 ? 'Cancel' : 'Back'}
        </CancelButton>
        <Box sx={{ flex: '1 1 auto' }} />
        {/* {isStepOptional(activeStep) && (
                <Button
                  color="inherit"
                  onClick={handleSkip}
                  // sx={{ mr: 1 }}
                >
                  Skip
                </Button>
              )} */}
        {isImporting ? (
          <FlexDiv
            direction="column"
            style={{ paddingTop: '9px', width: '120px' }}
          >
            <CircularProgress />
          </FlexDiv>
        ) : (
          <MainButton
            style={{
              minWidth: '120px',
              backgroundColor: disableNext ? colors.grey11 : colors.black,
            }}
            disabled={disableNext}
            onClick={handleNext}
          >
            {activeStep ===
            steps.length - (importType === ImportType.Accounts ? 2 : 1)
              ? 'Import file'
              : 'Next'}
          </MainButton>
        )}
      </Box>
    </FlexDiv>
    // </ClickAwayListener>
  );
};

export default StepsProgress;
