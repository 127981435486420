import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './styledComponents';
import {
  Autocomplete,
  Checkbox,
  TextField,
  Tooltip,
  createFilterOptions,
} from '@mui/material';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { AutocompleteMultipleOptionWrapper } from '../style';
import ClearIcon from '@mui/icons-material/Clear';
import IconInformationS from 'crono-fe-common/icons/Icon-Information-Small';
import { colors } from 'crono-fe-common/theme';
import {
  CronoGatewayLinkedinGetFilterValuesAction,
  LinkedinFilterValue,
  LinkedinFilterValueFreeSolo,
  LinkedinFilterValueFreeSoloWithImage,
  LinkedinFilterValueWithImg,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { useGetLinkedinFilterValues } from 'crono-fe-common/hooks/crono-extension/gateway';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import EmptyCompanyImageLinkedin from 'crono-fe-common/icons/EmptyImageLinkedin.svg';

// Helper type to omit 'keyword' field
type OmitKeyword<T> = T extends { keyword: any } ? Omit<T, 'keyword'> : T;

// New type without 'keyword' field
export type FilterValuesActionParams = OmitKeyword<
  CronoGatewayLinkedinGetFilterValuesAction['params']
>;

interface IProps {
  currentValue: LinkedinFilterValueFreeSolo[] | null;
  onChange: (e: any, label: any[]) => void;
  placeholder: string;
  id: string;
  displayLabel: string;
  handleSelectSingle: (value: any) => void;
  icon: JSX.Element;
  disableTopBorder?: boolean;
  tooltipText?: string;
  expanded?: boolean;
  setExpanded?: () => void;
  orderForIds?: boolean;
  filterType?: FilterValuesActionParams;
  options?: LinkedinFilterValue[];
  freeSolo?: boolean;
  searchTextParent?: string;
  setSearchTextParent?: (value: string) => void;
}

const SearchAccordionMultipleAutocomplete = ({
  currentValue,
  onChange,
  placeholder,
  id,
  displayLabel,
  handleSelectSingle,
  icon,
  disableTopBorder,
  tooltipText,
  expanded,
  setExpanded,
  orderForIds = false,
  filterType,
  options: fixedOptions,
  freeSolo = false,
  searchTextParent,
  setSearchTextParent,
}: IProps) => {
  //Value of the search input
  const [searchText, setSearchText] = useState<string>(searchTextParent ?? '');

  //Value used to filter based on the name searched. This has to be used since we don't want to filter every time the search value changes,
  //but only when on enter or after 1 second of inactivity
  const [searchFilterValue, setSearchFilterValue] = useState<string>(
    searchTextParent ?? '',
  );
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchFilterValue(searchText);
    }, 800);
    return () => clearTimeout(timeout);
  }, [searchText]);

  const {
    call: getFilterValues,
    result,
    isLoading: loadingFilterValues,
  } = useGetLinkedinFilterValues();

  const options = (fixedOptions || result?.values) as
    | LinkedinFilterValueWithImg[]
    | undefined;

  useEffect(() => {
    //If I passed some fixed options we do not look inside the filters
    if (fixedOptions) return;
    if (!expanded || !filterType) return;
    getFilterValues({
      ...filterType,
      //Currently linkedin gives error 400 if empty string is passed
      keyword: searchFilterValue === '' ? null : searchFilterValue,
    });
  }, [searchFilterValue, expanded]);

  const counter = currentValue?.length || 0;

  const resume = (
    <FlexDiv direction={'column'} gap={'8px'} style={{ padding: '8px 0' }}>
      {currentValue
        ?.sort((a, b) => {
          if (
            orderForIds &&
            a.id !== undefined &&
            b.id !== undefined &&
            a.id !== null &&
            b.id !== null
          ) {
            return a.id.localeCompare(b.id);
          } else return a.label.localeCompare(b.label);
        })
        ?.map((data) => {
          return (
            <AutocompleteMultipleOptionWrapper key={data.id}>
              <p className={'label'}>{data.label}</p>
              <div
                className={'remove-icon-container'}
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  handleSelectSingle(data);
                }}
              >
                <ClearIcon style={{ width: 15, height: 15 }} />
              </div>
            </AutocompleteMultipleOptionWrapper>
          );
        })}
    </FlexDiv>
  );

  const defaultFilterOptions =
    createFilterOptions<LinkedinFilterValueFreeSoloWithImage>();

  const handleChangeValue = (
    e: any,
    value: (string | LinkedinFilterValueFreeSolo)[],
  ) => {
    let stringFound = false;
    const formattedValues = value.map((val) => {
      if (typeof val === 'string') {
        stringFound = true;
        return {
          label: val,
          id: null,
        };
      }
      return val;
    });
    onChange(e, formattedValues);
    //Force the searchText to remain the same and not to update only if we have not cleared it
    //But if we found a string we have to clear the searchText
    if (stringFound) {
      setSearchText('');
      setSearchTextParent && setSearchTextParent('');
    } else if (formattedValues.length > 0) {
      setSearchText(searchText);
      setSearchTextParent && setSearchTextParent(searchText);
    }
  };

  return (
    <Accordion expanded={expanded} disableTopBorder={disableTopBorder}>
      <AccordionSummary
        onClick={() => {
          if (setExpanded) {
            setExpanded();
          }
        }}
        classes={{
          expandIconWrapper: 'expand-icon-wrapper',
        }}
      >
        <div
          style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        >
          <div className="autocomplete-summary">
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {icon}
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                {displayLabel}
                {!!tooltipText && (
                  <Tooltip
                    title={tooltipText}
                    enterDelay={400}
                    enterNextDelay={400}
                    arrow
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: 'fit-content',
                        height: 'fit-content',
                        cursor: 'pointer',
                      }}
                    >
                      <IconInformationS color={colors.grey11} />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            {!!counter && <div className="autocomplete-counter">{counter}</div>}
          </div>
          {!expanded && counter > 0 && resume}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Autocomplete
          id={id}
          className="add-new-auto-complete"
          value={(currentValue || []) as LinkedinFilterValueFreeSoloWithImage[]}
          disableCloseOnSelect
          onChange={(e, value) => {
            handleChangeValue(e, value);
          }}
          freeSolo={freeSolo}
          PaperComponent={useCallback(
            (paperProps: any) => (
              <div className="autocomplete-paper">{paperProps.children}</div>
            ),
            [],
          )}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderOption={(props, option) => {
            const checked =
              (currentValue?.findIndex((val) => val.id === option.id) ?? -1) >
              -1;
            return (
              <li
                {...props}
                className={`autocomplete-selected-item ${
                  filterType?.filterName === 'COMPANY' ||
                  filterType?.filterName === 'COMPANY_WITH_LIST'
                    ? 'autocomplete-selected-item-with-image'
                    : ''
                } ${checked ? 'autocomplete-selected-item-checked' : ''}`}
              >
                {(filterType?.filterName === 'COMPANY' ||
                  filterType?.filterName === 'COMPANY_WITH_LIST') && (
                  <label
                    style={{
                      backgroundImage: `url(${option.imageUrl ?? EmptyCompanyImageLinkedin})`,
                    }}
                    id="autocomplete-select-image"
                    className="autocomplete-select-item-image"
                  ></label>
                )}
                <label id="autocomplete-select-checkbox">
                  <Checkbox
                    icon={<IconUnchecked />}
                    checkedIcon={<CheckedIcon />}
                    checked={checked}
                  />
                </label>
                {option.label}
              </li>
            );
          }}
          disablePortal
          multiple
          noOptionsText={
            searchFilterValue && options && !options.length
              ? 'No results found'
              : loadingFilterValues
                ? 'Loading options...'
                : 'Type to search...'
          }
          options={options ?? []}
          filterOptions={(options, params) => {
            return defaultFilterOptions(options, params).slice(0, 25);
          }}
          sx={{
            width: 300,
          }}
          clearOnBlur={false}
          clearOnEscape={false}
          inputValue={searchText}
          onInputChange={(e, value) => {
            setSearchText(value);
            setSearchTextParent && setSearchTextParent(value);
          }}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="standard"
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {searchText && (
                      <CloseMIcon
                        onClick={() => {
                          setSearchText('');
                          setSearchTextParent && setSearchTextParent('');
                        }}
                        style={{ width: 18, height: 18, cursor: 'pointer' }}
                        color={colors.grey11}
                      />
                    )}
                  </>
                ),
              }}
            />
          )}
          renderTags={(value) => <></>}
        />
        {expanded && counter > 0 && resume}
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchAccordionMultipleAutocomplete;
