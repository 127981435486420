import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const FilterResumeWrapper = styled.div`
  padding: 12px;
  background: ${colors.grey55};
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 40px;
  gap: 8px;
  cursor: pointer;
  max-width: 150px;

  &:hover {
    background: ${colors.grey4Hover};
  }

  .remove-filter-icon {
    min-width: 24px;
    min-height: 24px;
  }

  .email-phone-status-icon {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    min-width: 6px;

    &--green {
      background-color: ${colors.callScheduled}
    }
    
    &--yellow {
      background-color: ${colors.nurture}
    }
    
    &--red {
      background-color: ${colors.inactiveHover}
    }
    
    &--grey {
      background-color: ${colors.grey2}
    }
  }
`;

export const SocialFilterWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 12px;
  max-width: 150px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${colors.green};
  background: ${colors.white};
  gap: 8px;
  cursor: pointer;

  &:hover {
    background: ${colors.grey6};
  }
`;

export const RemainingFilterResumeWrapper = styled.div`
  padding: 12px;
  background: ${colors.white} !important;
  border: 1px solid ${colors.grey44};
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 40px;
  gap: 8px;
  cursor: pointer;
`;
