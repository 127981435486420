import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinIconInMailDark = ({
  color = colors.white,
  color2 = colors.purpleLight2,
  className,
  onClick,
  style,
  viewBox = '0 0 32 32',
}: IProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox={viewBox}
      onClick={onClick}
      className={className}
      {...style}
    >
      <rect width="32" height="32" rx="16" fill={color2} />
      <g clipPath="url(#clip0_16989_347987)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4795 8.75C11.3266 8.75 8.75 11.3266 8.75 14.4795C8.75 17.6055 11.2828 20.165 14.399 20.2084L14.399 18.7082C12.1111 18.6648 10.25 16.7771 10.25 14.4795C10.25 12.155 12.155 10.25 14.4795 10.25C16.804 10.25 18.709 12.155 18.709 14.4795C18.709 14.7739 18.6784 15.0616 18.6203 15.3396L20.1442 15.3396C20.1869 15.0589 20.209 14.7717 20.209 14.4795C20.209 11.3266 17.6324 8.75 14.4795 8.75Z"
          fill={color}
        />
        <path
          d="M16 17.9922V21.6668C16 22.3112 16.5223 22.8335 17.1667 22.8335H21.8333C22.4777 22.8335 23 22.3112 23 21.6668V17.9926"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8102 20.0014L22.5625 18.8134C22.8351 18.6291 22.9981 18.3214 22.9981 17.9924V17.9924C22.9981 17.4441 22.554 17 22.006 17H16.996C16.448 17 16.0039 17.4441 16.0039 17.9921V17.9921C16.0039 18.3211 16.1669 18.6287 16.4395 18.8134L18.1918 20.0014C18.9824 20.5373 20.0196 20.5373 20.8102 20.0014V20.0014Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8192 13.0558C15.896 13.0321 15.9679 13.104 15.9442 13.1808L15.3085 15.2424C15.2987 15.274 15.274 15.2987 15.2424 15.3085L13.1808 15.9442C13.104 15.9679 13.0321 15.896 13.0558 15.8192L13.6915 13.7576C13.7013 13.726 13.726 13.7013 13.7576 13.6915L15.8192 13.0558Z"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16989_347987">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedinIconInMailDark;
