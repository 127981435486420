import { colors } from 'crono-fe-common/theme';
import styled from '@emotion/styled';

export const ChooseLicenseWrapper = styled.div`
  display: flex;
  width: 55%;
  flex-direction: column;
  // flex-wrap: wrap;
  align-items: center;
  overflow: auto;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }
  .logo {
    width: 100px;
  }
  .subscription-license-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    gap: 40px;
    align-items: center;
  }
  .subscription-license {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 90px;
    overflow: hidden;
  }
  .license-title-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
  .license-card-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-box {
    width: 430px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .form-pricing {
    min-height: 90px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
  }
  .invite-user-container {
    justify-content: space-between;
  }
  .princing-plan-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .free-trial {
    min-height: 40px;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid ${colors.grey444};
    border-radius: 16px;
    background-color: ${colors.primaryLight};
  }
  .free-trial-button {
    display: flex;
    gap: 5px;
    cursor: pointer;
    color: ${colors.darkGreen};
    padding: 2px 0px 2px 8px;
    border-radius: 6px;
  }
  .free-trial-button > svg > path {
    stroke: ${colors.darkGreen};
  }
  .free-trial-button:hover {
    color: ${colors.white};
    background-color: ${colors.ultraPlanDarkGreen};
  }
  .free-trial-button:hover svg > path {
    stroke: ${colors.white};
  }
  .pricing-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    min-width: 56px;
    border-radius: 10px;
  }
  .Starter {
    background-color: ${colors.primaryLight};
  }
  .Starter > svg > path {
    stroke: ${colors.primary};
  }
  .Pro {
    background-color: ${colors.primary};
  }
  .Pro > svg > path,
  .Ultra > svg > path {
    stroke: ${colors.white};
  }
  .Ultra {
    background-color: ${colors.ultraPlanDarkGreen};
  }
  .FreeTrial {
    background-color: ${colors.freeTrialYellow};
  }
  .FreeTrial > svg > path {
    stroke: ${colors.darkYellow};
  }
  .user-month-price {
    display: flex;
    min-width: fit-content;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    gap: 8px;
    margin-right: 16px;
  }
  .price-tag {
    font-size: 24px;
    font-weight: 700;
  }
  .login-link {
    color: ${colors.darkGreen};
    font-weight: 600;
    cursor: pointer;
  }
  .login-link:hover {
    color: ${colors.darkGreenHover};
  }
`;

export const LicenseFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;

  .amount-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .number-picker {
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    display: flex;
    width: fit-content;
    padding: 2px;
    align-items: center;
    width: 120px;
    justify-content: space-around;
  }
  .number-picker-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .onboarding-navigation {
    display: flex;
    align-items: center;
    gap: 8;
    justify-content: center;
  }
`;
