import React, { useMemo, useState } from 'react';

import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';
import {
  AsyncJobImportCompany,
  AsyncJobImportProspect,
} from 'crono-fe-common/types/crono-extension/background-script';
import { useGetAsyncQueueState } from 'hooks/services/crono-extension/useGetAsyncQueueState';
import { ClickAwayListener, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import { useBackgroundClearAsyncJobs } from 'crono-fe-common/hooks/crono-extension/gateway';
import { useConditionalSnackBar } from 'context/snackbar';

interface IProps {
  isCompanyTab: boolean;
  small?: boolean;
}

const ImportingInBackgroundBadge = ({
  isCompanyTab,
  small = false,
}: IProps) => {
  const { data: queue } = useGetAsyncQueueState();

  const isImporting = isCompanyTab
    ? queue?.isImportingCompanies
    : queue?.isImportingContacts;

  const toImportCount = isCompanyTab
    ? queue?.companiesToImportCount || 0
    : queue?.contactsToImportCount || 0;

  const importedCount = isCompanyTab
    ? queue?.importedCompaniesCount || 0
    : queue?.importedContactsCount || 0;

  const [isImportingTooltipOpen, setIsImportingTooltipOpen] =
    useState<boolean>(false);

  const rowsToDisplay = useMemo(() => {
    if (!queue) {
      return [];
    }

    return isCompanyTab ? queue?.companiesToImport : queue?.contactsToImport;
  }, [
    isCompanyTab,
    isImportingTooltipOpen,
    queue?.companiesToImport,
    queue?.contactsToImport,
  ]);

  const renderRows = (
    title: string,
    subtitle: string | null,
    url: string,
    isInCrono: boolean,
  ) => (
    <div className="importing-tooltip-row">
      <div className="col-1">
        <Typography
          noWrap
          fontSize={14}
          fontWeight={500}
          lineHeight={'22px'}
          color={colors.grey1}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            noWrap
            fontSize={12}
            lineHeight={'16px'}
            color={colors.grey11}
          >
            {subtitle}
          </Typography>
        )}
      </div>
      <div className="flex-center">
        <div className="linkedin-icon">
          <LinkedinIcon
            color={colors.blue}
            onClick={() => {
              window.open(url, '_blank');
            }}
          />
        </div>
      </div>
      <div className="flex-center">
        {isInCrono ? (
          <Typography
            fontSize={14}
            fontWeight={500}
            lineHeight={'18px'}
            color={colors.grey11}
          >
            In Crono
          </Typography>
        ) : (
          <span className="loader importing-loader"></span>
        )}
      </div>
    </div>
  );

  const { call: clearQueueRequest, result: clearQueueResponse } =
    useBackgroundClearAsyncJobs();

  const clearQueue = async () => {
    await clearQueueRequest({
      type: isCompanyTab ? 'importCompany' : 'importProspect',
    });

    if (isImportingTooltipOpen) {
      setIsImportingTooltipOpen(false);
    }
  };

  useConditionalSnackBar([
    {
      condition: clearQueueResponse !== null,
      message: `Background import stopped successfully`,
      severity: 'success',
    },
  ]);

  return (
    <>
      {isImporting && (
        <div className="importing-container">
          {isCompanyTab ? <IconMCompany /> : <IconMContact />}
          <Typography fontSize={14} fontWeight={500} lineHeight={'18px'}>
            Adding:
          </Typography>
          <div
            className="importing-badge"
            style={{
              height: small ? 32 : 40,
            }}
            onClick={() => setIsImportingTooltipOpen((prev) => !prev)}
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight={'18px'}
              noWrap
            >
              Adding {toImportCount}...
            </Typography>
            <div className="importing-loader-container">
              <span className="loader importing-loader"></span>
            </div>
          </div>
        </div>
      )}
      {isImporting && isImportingTooltipOpen && (
        <ClickAwayListener onClickAway={() => setIsImportingTooltipOpen(false)}>
          <div className="importing-tooltip">
            <div className="importing-tooltip-header">
              <div>
                <Typography fontSize={16} fontWeight={500} lineHeight={'24px'}>
                  Importing new {isCompanyTab ? 'companies' : 'contacts'}
                </Typography>
                <Typography
                  fontSize={12}
                  lineHeight={'16px'}
                  color={colors.grey1}
                >
                  <strong>{`${toImportCount} `}</strong>
                  {isCompanyTab
                    ? toImportCount === 1
                      ? 'company'
                      : 'companies'
                    : toImportCount === 1
                      ? 'contact'
                      : 'contacts'}{' '}
                  in progress
                </Typography>
              </div>

              {/*We can't stop one because we can't stop runningQueue job*/}
              {toImportCount > 1 ? (
                <div className="stop-button" onClick={clearQueue}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={'18px'}
                    color={colors.grey1}
                  >
                    Stop
                  </Typography>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="importing-tooltip-body">
              {rowsToDisplay.map((job) => {
                if (isCompanyTab) {
                  const data = job as AsyncJobImportCompany;

                  return renderRows(
                    data.account.name,
                    null,
                    data.account.linkedin || '',
                    !!queue?.importedCompanies.find(
                      (j) =>
                        (data.account.linkedinNumericId &&
                          j.account.linkedinNumericId &&
                          (j as AsyncJobImportCompany).account
                            .linkedinNumericId ===
                            data.account.linkedinNumericId) ||
                        (data.account.linkedinPublicId &&
                          j.account.linkedinPublicId &&
                          (j as AsyncJobImportCompany).account
                            .linkedinPublicId ===
                            data.account.linkedinPublicId),
                    ),
                  );
                } else {
                  const data = job as AsyncJobImportProspect;

                  return renderRows(
                    `${data.prospect.firstName} ${data.prospect.lastName}`,
                    data.prospect.title || '',
                    data.prospect.linkedin || '',
                    !!queue?.importedContacts.find(
                      (j) =>
                        (data.prospect.linkedinLeadId &&
                          j.prospect.linkedinLeadId &&
                          (j as AsyncJobImportProspect).prospect
                            .linkedinLeadId === data.prospect.linkedinLeadId) ||
                        (data.prospect.linkedinPublicId &&
                          j.prospect.linkedinPublicId &&
                          (j as AsyncJobImportProspect).prospect
                            .linkedinPublicId ===
                            data.prospect.linkedinPublicId),
                    ),
                  );
                }
              })}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default ImportingInBackgroundBadge;
