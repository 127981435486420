enum SequenceStatusType {
  ACTIVE = 'Active',
  FINISHED = 'Finished',
  CONVERTED = 'Converted',
  STOPPED = 'Stopped',
}

export enum SequenceStatusTypeDTO {
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  ANSWERED = 'Answered',
  STOPPED = 'Stopped',
}

export const showSequenceStatusDTO = (
  status: SequenceStatusTypeDTO | SequenceStatusType,
) => {
  switch (status) {
    case SequenceStatusTypeDTO.ACTIVE:
      return 'Active';
    case SequenceStatusType.FINISHED:
    case SequenceStatusTypeDTO.COMPLETED:
      return 'Finished';
    case SequenceStatusType.CONVERTED:
    case SequenceStatusTypeDTO.ANSWERED:
      return 'Replied';
    case SequenceStatusTypeDTO.STOPPED:
      return 'Stopped';
  }
};

export default SequenceStatusType;
