import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ExternalCallLog } from 'crono-fe-common/types/externalCallLog';

export default function useGetCallExternal() {
  const getCallExternal = (number: string) => {
    const params = new URLSearchParams({ number });
    const request: Request = {
      url: `${ENDPOINTS.call.external}?${params.toString()}`,
      config: {
        method: 'get',
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<ExternalCallLog>,
    CronoError,
    string
  >(getCallExternal, {});

  return { data, mutate, ...rest };
}
