//import AuthProvider, { useAuth } from "context/auth";
import { isBrowser } from 'react-device-detect';

import AppRoutes from 'routing/AppRoutes';
import AppRouter from 'routing/AppRouter';
import { ErrorBoundary } from 'react-error-boundary';
import CssBaseline from '@mui/material/CssBaseline';
import FullpageErrorFallBack from './errorPage';
import PATH from 'routing/path';
import { ThemeProvider } from '@mui/material';
import SnackbarProvider from 'context/snackbar';
import { QueryClient, QueryClientProvider } from 'react-query';
import ConfirmModalProvider from 'context/confirmModal';
import { ReactQueryDevtools } from 'react-query/devtools';
import { FC } from 'react';
import type { BrowserHistory } from 'history';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AccountProvider from 'pages/home/context/account';
import JuneProvider from 'context/june';
import SideTabProvider from 'context/sideTab';
import AuthProvider from 'context/auth';
import FiltersProvider from 'pages/home/context/filters';
import EmailThreadProvider from 'context/EmailThreadTab';
import ExecuteMultipleTasksProvider from 'pages/accountTab/startMultipleTasksContext';
import { theme } from 'crono-fe-common/theme';
import LinkedinProvider from 'context/linkedinTab';

import { CustomerlyProvider } from 'react-live-chat-customerly';
import OnboardingFeaturesProvider from 'context/onboardingFeatures';
import HideTaskConstructionProvider from 'pages/accountTab/hideTaskConstructionContext';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';
import InMailReplyProvider from 'context/inMailReplyTab';
import MobileNotSupported from 'pages/mobileNotSupported';
import ResolutionNotSupported from 'pages/resolutionNotSupported';
import useScreenSize from 'hooks/useScreenSize';
import { isLocal } from '../utils/fe-utils';
import { clearOutLocalStorage } from 'index';
import SequenceProvider from 'context/strategyDetail/context/sequence';
import PollerProvider from 'context/poller';
import UpgradeModalProvider, { useUpgradeModal } from 'context/upgradeModal';
import UpgradeSubscriptionModal from '../pages/subscription/upgradeSubscription';

const queryClient = new QueryClient();

CronoAutomaticTaskExecutor.setQueryClient(queryClient);

const App: FC<{ history: BrowserHistory }> = ({ history }) => {
  const onResetErrorBoundary = () => {
    //refresh all state and redirect to login
    clearOutLocalStorage();
    history.push(PATH.LOGIN, {
      error: true,
    });
  };

  const { supportedResolution } = useScreenSize();

  return (
    <JuneProvider>
      <ErrorBoundary
        FallbackComponent={FullpageErrorFallBack}
        onReset={onResetErrorBoundary}
      >
        <CssBaseline />
        <ThemeProvider theme={theme}>
          {isBrowser || isLocal() ? (
            <UpgradeModalProvider>
              <QueryClientProvider client={queryClient}>
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
                >
                  <SnackbarProvider>
                    <ConfirmModalProvider>
                      {supportedResolution || isLocal() ? (
                        <div className="App">
                          <AppRouter history={history}>
                            <AuthProvider>
                              <CustomerlyProvider
                                appId={
                                  process.env.REACT_APP_CUSTOMERLY_APP_ID ?? ''
                                }
                              >
                                <FiltersProvider>
                                  <AccountProvider>
                                    <EmailThreadProvider>
                                      <LinkedinProvider>
                                        <InMailReplyProvider>
                                          <HideTaskConstructionProvider>
                                            <SideTabProvider>
                                              <ExecuteMultipleTasksProvider>
                                                <OnboardingFeaturesProvider>
                                                  <SequenceProvider>
                                                    <PollerProvider>
                                                      <BodyComponent />
                                                    </PollerProvider>
                                                  </SequenceProvider>
                                                </OnboardingFeaturesProvider>
                                              </ExecuteMultipleTasksProvider>
                                            </SideTabProvider>
                                          </HideTaskConstructionProvider>
                                        </InMailReplyProvider>
                                      </LinkedinProvider>
                                    </EmailThreadProvider>
                                  </AccountProvider>
                                </FiltersProvider>
                              </CustomerlyProvider>
                            </AuthProvider>
                          </AppRouter>
                        </div>
                      ) : (
                        <ResolutionNotSupported />
                      )}
                      <ReactQueryDevtools position="bottom-right" />
                    </ConfirmModalProvider>
                  </SnackbarProvider>
                </GoogleOAuthProvider>
              </QueryClientProvider>
            </UpgradeModalProvider>
          ) : (
            <MobileNotSupported />
          )}
        </ThemeProvider>
      </ErrorBoundary>
    </JuneProvider>
  );
};

const BodyComponent = () => {
  const { isUpgradeModalVisible } = useUpgradeModal();

  return (
    <>
      {isUpgradeModalVisible && <UpgradeSubscriptionModal />}
      <AppRoutes />
    </>
  );
};

export default App;
