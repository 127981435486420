import { useAuth } from 'context/auth';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { GeneralWrapper } from '../styles';
import { useConditionalSnackBar } from 'context/snackbar';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { Tab, Tabs, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { getError } from 'crono-fe-common/utils';
import { SuggestionPreferences } from 'crono-fe-common/types/suggestionPreferences';
import useGetSuggestionPreferences from 'hooks/services/suggestion/useGetSuggestionPreferences';
import usePatchSuggestionPreferences from 'hooks/services/suggestion/usePatchSuggestionPreferences';
import { ReactComponent as SuggestionPreferencesTaskIcon } from 'crono-fe-common/icons/Icon-Tasks-Suggestion-Preferences.svg';
import { ReactComponent as SuggestionPreferencesStrategiesIcon } from 'crono-fe-common/icons/Icon-Strategies-Suggestion-Preferences.svg';
import { ReactComponent as SuggestionPreferencesNewDataIcon } from 'crono-fe-common/icons/Icon-New-Data-Suggestion-Preferences.svg';
import { ReactComponent as SuggestionPreferencesDataQualityIcon } from 'crono-fe-common/icons/Icon-Data-Quality-Suggestion-Preferences.svg';
import { ReactComponent as IllustrationTasks } from 'crono-fe-common/icons/Illustration_task.svg';
import { ReactComponent as IllustrationStrategies } from 'crono-fe-common/icons/Illustration_strategies.svg';

import { ReactComponent as IllustrationNewData } from 'crono-fe-common/icons/Illustration_new_data.svg';
import { ReactComponent as IllustrationDataQuality } from 'crono-fe-common/icons/Illustration_data_quality.svg';
import SuggestionPreferencesTasksTab from './suggestionPreferencesTabs/suggestionPreferencesTasksTab';
import SuggestionPreferencesNewData from './suggestionPreferencesTabs/suggestionPreferencesNewData';
import SuggestionPreferencesDataQuality from './suggestionPreferencesTabs/suggestionPreferencesDataQuality';
import SuggestionPreferencesStrategies from './suggestionPreferencesTabs/suggestionPreferencesStrategies';

interface IValue {
  name: keyof SuggestionPreferences;
  description: string;
  label: string;
}

enum TabValue {
  TASKS,
  STRATEGIES,
  NEW_DATA,
  DATA_QUALITY,
}

const SuggestionsPreferences: FC = () => {
  const { user } = useAuth();

  const [inputError, setInputError] = useState<string | null>(null);

  const { data: suggestionPreferences } = useGetSuggestionPreferences();

  const parameters = useMemo(() => {
    return suggestionPreferences?.data?.data ?? null;
  }, [suggestionPreferences]);

  useEffect(() => {
    setInputError(null);
  }, [user]);

  const {
    mutate: patchSuggestionPreferences,
    error: errorPatchSuggestionPreferences,
    isLoading: patchingSuggestionPreferences,
    isSuccess: patchSuggestionPreferencesSuccess,
  } = usePatchSuggestionPreferences();

  const showFirstSnackbar = useRef<boolean>(true);

  useEffect(() => {
    if (patchSuggestionPreferencesSuccess) {
      if (showFirstSnackbar.current) {
        showFirstSnackbar.current = false;
      }
    }
  }, [patchSuggestionPreferencesSuccess]);

  useConditionalSnackBar([
    {
      condition: !!errorPatchSuggestionPreferences,
      message:
        getError(errorPatchSuggestionPreferences) ??
        'Error while update suggestion preferences',
      severity: 'error',
    },
    {
      condition: !!inputError,
      message: inputError!,
      severity: 'error',
    },
    {
      condition:
        !!patchSuggestionPreferencesSuccess && showFirstSnackbar.current,
      message: 'Suggestion preferences will be updated automatically',
      severity: 'success',
    },
  ]);

  const updateSuggestionPreferences = (
    name: keyof SuggestionPreferences,
    value: any,
  ) => {
    patchSuggestionPreferences({ [name]: value });
  };

  const updateMultipleSuggestionPreferences = (
    properties: { name: keyof SuggestionPreferences; value: any }[],
  ) => {
    const obj: any = {};
    properties.forEach((property) => {
      obj[property.name] = property.value;
    });
    patchSuggestionPreferences(obj);
  };

  const [tabValue, setTabValue] = useState<number>(0);

  const renderTitleInfoBox = (tabValue: TabValue) => {
    switch (tabValue) {
      case TabValue.TASKS:
        return 'Follow up tasks';
      case TabValue.STRATEGIES:
        return 'Start & Stop strategy';
      case TabValue.NEW_DATA:
        return 'Find new data';
      case TabValue.DATA_QUALITY:
        return 'Data quality';
      default:
        return '';
    }
  };

  const renderSubtitleInfoBox = (tabValue: TabValue) => {
    switch (tabValue) {
      case TabValue.TASKS:
        return 'Set up your preferred parameters to receive suggestions for your follow up tasks.\nSuggest to:';
      case TabValue.STRATEGIES:
        return 'Set up your preferred parameters to receive suggestions to enrol or stop contacts from strategies.\nSuggest to:';
      case TabValue.NEW_DATA:
        return 'Set up your preferred parameters to receive suggestions to find new data.\nSuggest to:';
      case TabValue.DATA_QUALITY:
        return 'Set up your preferred parameters to receive suggestions to monitor and improve your data quality.\nSuggest to:';
      default:
        return '';
    }
  };

  const renderIconInfoBox = (tabValue: TabValue) => {
    switch (tabValue) {
      case TabValue.TASKS:
        return <IllustrationTasks />;
      case TabValue.STRATEGIES:
        return <IllustrationStrategies />;
      case TabValue.NEW_DATA:
        return <IllustrationNewData />;
      case TabValue.DATA_QUALITY:
        return <IllustrationDataQuality />;
      default:
        return <></>;
    }
  };

  return (
    <GeneralWrapper>
      <FlexDiv direction="column" alignItems="start" style={{ rowGap: 24 }}>
        <div className="user-settings-card-title">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Suggestions
          </Typography>
        </div>
        <div>
          <Tabs
            value={tabValue}
            onChange={(_, newValue: TabValue) => {
              setTabValue(newValue);
            }}
            className="tab-suggestion-preferences"
            // TabIndicatorProps={{
            //   style: { display: "none" }
            // }}
          >
            <Tab
              icon={<SuggestionPreferencesTaskIcon />}
              iconPosition="start"
              label="Tasks"
              color="primary"
              value={TabValue.TASKS}
              className={
                tabValue === TabValue.TASKS
                  ? 'tab-suggestion-preferences'
                  : 'tab-suggestion-preferences-not-selected'
              }
            />
            <Tab
              icon={<SuggestionPreferencesStrategiesIcon />}
              iconPosition="start"
              label="Strategies"
              color="primary"
              value={TabValue.STRATEGIES}
              className={
                tabValue === TabValue.STRATEGIES
                  ? 'tab-suggestion-preferences'
                  : 'tab-suggestion-preferences-not-selected'
              }
            />
            <Tab
              icon={<SuggestionPreferencesNewDataIcon />}
              iconPosition="start"
              label="New data"
              color="primary"
              value={TabValue.NEW_DATA}
              className={
                tabValue === TabValue.NEW_DATA
                  ? 'tab-suggestion-preferences'
                  : 'tab-suggestion-preferences-not-selected'
              }
            />
            <Tab
              icon={<SuggestionPreferencesDataQualityIcon />}
              iconPosition="start"
              label="Data quality"
              color="primary"
              value={TabValue.DATA_QUALITY}
              className={
                tabValue === TabValue.DATA_QUALITY
                  ? 'tab-suggestion-preferences'
                  : 'tab-suggestion-preferences-not-selected'
              }
            />
          </Tabs>
        </div>
        <div className="user-settings-card-content">
          <div className="suggestion-info-grey-box">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                gap: 4,
              }}
            >
              <Typography fontSize={20} fontWeight={700} lineHeight={'30px'}>
                {renderTitleInfoBox(tabValue)}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                lineHeight={'24px'}
                color={colors.grey11}
                style={{ whiteSpace: 'pre-line' }}
              >
                {renderSubtitleInfoBox(tabValue)}
              </Typography>
            </div>
            <div>{renderIconInfoBox(tabValue)}</div>
          </div>
          <div className="suggestion-tab-container">
            {tabValue === TabValue.TASKS ? (
              <SuggestionPreferencesTasksTab
                updateMultipleSuggestionPreferences={
                  updateMultipleSuggestionPreferences
                }
                parameters={parameters}
                isLoading={patchingSuggestionPreferences}
              />
            ) : tabValue === TabValue.STRATEGIES ? (
              <SuggestionPreferencesStrategies
                updateMultipleSuggestionPreferences={
                  updateMultipleSuggestionPreferences
                }
                parameters={parameters}
                isLoading={patchingSuggestionPreferences}
              />
            ) : tabValue === TabValue.NEW_DATA ? (
              <SuggestionPreferencesNewData
                updateSuggestionPreferences={updateSuggestionPreferences}
                parameters={parameters}
              />
            ) : tabValue === TabValue.DATA_QUALITY ? (
              <SuggestionPreferencesDataQuality
                updateSuggestionPreferences={updateSuggestionPreferences}
                parameters={parameters}
              />
            ) : (
              <></>
            )}
          </div>
          {/* {values.map((preference) => {
            return (
              <div className="account-info-row">
                <p>{preference.label}</p>
                <div>
                  {preference.name === "suggestAccountStatusEnabled" ? (
                    parameters &&
                    parameters.has(preference.name) && (
                      <Select
                        onChange={(e) => {
                          const value = e.target
                            .value as any as SuggestedAccountStatusEnabled;
                          updateSuggestionPreferences(preference.name, value);
                        }}
                        value={
                          parameters.get(
                            preference.name
                          ) as SuggestedAccountStatusEnabled
                        }
                      >
                        <MenuItem
                          value={SuggestedAccountStatusEnabled.Disabled}
                        >
                          Disabled
                        </MenuItem>
                        <MenuItem value={SuggestedAccountStatusEnabled.Enabled}>
                          Enabled
                        </MenuItem>
                        <MenuItem
                          value={SuggestedAccountStatusEnabled.Automatic}
                        >
                          Automatic
                        </MenuItem>
                      </Select>
                    )
                  ) : (
                    <ActiveSwitch
                      checked={parameters.get(preference.name) ?? false}
                      onChange={(e: any) => {
                        updateSuggestionPreferences(
                          preference.name,
                          e.target.checked as boolean
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })} */}
        </div>
        {/* {user?.otherSettings?.hasLateSync && (
          <>
            <div className="user-settings-card-title">
              <h3>Synchronization</h3>
            </div>
            <div className="user-settings-card-content">
              <div className="account-info-row">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Tooltip
                    arrow
                    title="Status after a company is sync with CRM"
                  >
                    <InfoIcon
                      style={{
                        marginRight: "5px",
                        color: colors.primary,
                        cursor: "pointer"
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )} */}
      </FlexDiv>
    </GeneralWrapper>
  );
};

export default SuggestionsPreferences;
