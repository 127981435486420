import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import assetsClient from 'utils/clients/assetsClient';

export enum AssetEnum {
  IMPORT_ANIMATION = 'import_animation',
}

export default function useGetAsset(asset: AssetEnum) {
  const url = asset.toString();

  const request: Request = {
    url,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [url],
    queryFn: () => assetsClient(request),
    staleTime: 60 * 60 * 1000,
  };

  const { data, ...rest } = useQuery<Response<any>, CronoError>(requestConfig);

  return { data, ...rest };
}
