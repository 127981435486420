import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import CronoAutomaticTaskExecutor from 'services/CronoAutomaticTaskExecutor';
import { DeleteSequenceProspectMultipleDTO } from 'crono-fe-common/types/DTO/deleteSequenceProspectMultipleDTO';

export default function useDeleteSequenceProspectMultiple() {
  const queryClient = useQueryClient();

  const deleteSequenceProspectMultiple = (
    data: DeleteSequenceProspectMultipleDTO,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.prospectDeleteMultiple}`,
      config: {
        method: 'delete',
        data: data,
      },
    };
    return client(request);
  };

  return useMutation<
    Response<null>,
    CronoError,
    DeleteSequenceProspectMultipleDTO
  >(deleteSequenceProspectMultiple, {
    onSettled: (result, error, variables) => {
      CronoAutomaticTaskExecutor.stopDelay();
      queryClient.invalidateQueries([ENDPOINTS.account.main]);
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
      queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);
    },
  });
}
