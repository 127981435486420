import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const AccountTabsContainer = styled.ul`
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 24px;
  position: relative;
  display: flex;
  width: 100%;
  z-index: 5;
  position: relative;
  height: 44px;
  bottom: -1px;

  .tabs-divider {
    height: 24px;
    width: 1px;
    min-width: 1px;
    background: ${colors.grey444};
    margin-top: 8px;
    margin-inline: 2px;
    z-index: 2;
  }
  li.selected {
    background: ${colors.white};
    color: #333;
    z-index: 2;
    border: 1px solid ${colors.grey444};
    border-bottom-color: ${colors.white};
  }
  li:before,
  li:after {
    border: 1px solid ${colors.grey444};
    position: absolute;
    bottom: -1px;
    width: 12px;
    height: 12px;
    content: '';
  }

  li:before {
    left: -12px;
    border-bottom-right-radius: 12px;
    border-width: 0px 0px 0px 0px;
    box-shadow: 0px 0px 0 ${colors.grey6};
  }

  li:after {
    right: -12px;
    border-bottom-left-radius: 12px;
    border-width: 0px 0px 0px 0px;
    box-shadow: 0px 0px 0 ${colors.grey6};
  }
  li.selected:before {
    box-shadow: 2px 2px 0 2px ${colors.white};
    border-width: 0px 1px 1px 0px;
  }
  li.selected:after {
    box-shadow: -2px 2px 0 2px ${colors.white};
    border-width: 0px 0px 1px 1px;
  }
  .selected {
    color: ${colors.darkGreen} !important;
    .inner-number-tab {
      background: #47c6c0;
      color: ${colors.white} !important;
    }
    &:hover {
      background: ${colors.white} !important;
      .inner-number-tab {
        background: #47c6c0;
      }
    }
  }
`;

export const AccountTabTabWrapper = styled.li`
  border-bottom: 1px solid ${colors.grey444};
  background: ${colors.grey6};
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin: 0px -3px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  cursor: pointer;

  .inner-number-tab {
    border-radius: 12px;
    min-width: 24px;
    padding: 4px 8px 2px 8px;
    gap: 10px;
    background: ${colors.grey444};
    margin-left: 6px;
    color: ${colors.grey11};
  }
  :hover {
    background: ${colors.grey444};
    .inner-number-tab {
      background: ${colors.grey33};
      color: ${colors.black};
    }
  }
`;

export const AccountTabTabWrapperNoHover = styled.li`
  border-bottom: 1px solid ${colors.grey444};
  background: ${colors.grey6};
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin: 0px -3px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  cursor: pointer;

  .inner-number-tab {
    border-radius: 12px;
    padding: 4px 8px 2px 8px;
    gap: 10px;
    background: ${colors.grey444};
    margin-left: 6px;
    color: ${colors.grey11};
  }
  :hover {
    color: ${colors.primaryDark};
    svg > path {
      stroke: ${colors.primaryDark};
    }
  }
`;
