import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { ActivityType } from 'crono-fe-common/types/enums/activityType';
import { PreviousActivitiesDTO } from 'crono-fe-common/types/DTO/activitiesDTO';

export interface ActivitiesFilters {
  limit: number;
  page: number;
  accountId: string | null;
  prospectId: string | null;
  opportunityId: string | null;
  types: ActivityType[] | null;
}

export default function useSearchActivities(
  searchParameters: ActivitiesFilters,
) {
  const queryString = JSON.stringify(searchParameters);
  const request: Request = {
    url: `${ENDPOINTS.events.activities.search}`,
    config: {
      method: 'post',
      data: { data: searchParameters },
    },
  };

  const requestConfig = {
    queryKey: [
      ENDPOINTS.events.activities.search,
      searchParameters.prospectId,
      queryString,
    ],
    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<
    ResponsePaginated<PreviousActivitiesDTO[]>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
