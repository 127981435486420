import {
  Button,
  ClickAwayListener,
  TextField,
  Typography,
} from '@mui/material';
import {
  CancelButton,
  GreyButton,
  MainPrimaryButton,
} from 'crono-fe-common/components/CronoButton';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import {
  SequenceActionTooltipWrapper,
  TableFooterWrapper,
} from 'pages/dashboard/footerStyle';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import ImportingInBackgroundBadge from '../importingInBackgroundBadge';
import { useGetAsyncQueueState } from 'hooks/services/crono-extension/useGetAsyncQueueState';
import useGetUsers, {
  UserSubscription,
} from 'hooks/services/subscription/useGetUsers';
import { fullname } from 'utils/fe-utils';
import OwnerIcon from 'crono-fe-common/icons/Icon-Owner';
import { TooltipFooter } from 'pages/dashboard/contactTable/contactTableFooter/prospectSelectionBottom';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { useAuth } from 'context/auth';
import EnrichIcon from 'crono-fe-common/icons/Icon-Enrich';
import { useGetAsyncEnrichQueueState } from 'hooks/services/crono-extension/useGetAsyncEnrichQueueState';

interface IProps {
  selected?: string[];
  areThereToEnrich?: boolean;
  handleEnrichAlll?: () => void;
  clearSelected: () => void;
  onClickAddMultiple: (selectedUserId: number | null) => void;
}

const SearchCompanySelectionBottom = ({
  selected,
  areThereToEnrich,
  handleEnrichAlll,
  clearSelected,
  onClickAddMultiple,
}: IProps) => {
  const { user } = useAuth();
  const { data: queue } = useGetAsyncQueueState();

  const isImporting = queue?.isImportingCompanies;

  const { data: usersData } = useGetUsers();
  const users = useMemo(() => usersData?.data?.data ?? [], [usersData]);

  const [searchUserName, setSearchUserName] = useState<string | null>(null);

  const filteredUsers = useMemo(() => {
    if (searchUserName) {
      return users?.filter((user) =>
        fullname(user.firstName, user.lastName)
          .toLowerCase()
          .includes(searchUserName.toLowerCase()),
      );
    }
    return users;
  }, [searchUserName]);

  const [openTooltipOwner, setOpenTooltipOwner] = useState<boolean>(false);

  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (openTooltipOwner && textFieldRef.current) {
      textFieldRef.current?.focus();
    }
  }, [textFieldRef.current, openTooltipOwner]);

  const handleTooltipOwnerClose = () => {
    setOpenTooltipOwner(false);
  };
  const handleTooltipOwnerOpen = () => {
    setOpenTooltipOwner(true);
  };
  const [selectedUser, setSelectedUser] = useState<UserSubscription | null>(
    null,
  );

  useEffect(() => {
    //When we make this appear we set the initial user as us
    if ((selected?.length ?? 0) > 0 && !selectedUser && user) {
      setSelectedUser(users?.find((u) => u.id === user.id) ?? null);
    }
  }, [user, users, selected?.length]);

  const handleSelectUser = (userId: number) => {
    setSelectedUser(users?.find((user) => user.id === userId) ?? null);
    handleTooltipOwnerClose();
    setSearchUserName('');
  };

  const onEnterOwner = () => {
    if (filteredUsers?.length >= 1) {
      setSelectedUser(filteredUsers[0]);
      handleTooltipOwnerClose();
      setSearchUserName('');
    }
  };

  const handleClickAddMultiple = () => {
    onClickAddMultiple(selectedUser?.id ?? null);
  };

  const clearFooter = () => {
    setSelectedUser(null);
    setSearchUserName('');
  };

  useEffect(() => {
    if (selected?.length === 0) {
      clearFooter();
    }
  }, [selected]);

  const { data: enrichQueue } = useGetAsyncEnrichQueueState();
  const isEnriching = enrichQueue?.isEnriching;
  return (
    <TableFooterWrapper style={{ marginLeft: 4 }}>
      {selected && selected.length > 0 && (
        <>
          <Button
            className="selected-button"
            variant="contained"
            color="secondary"
            endIcon={<CloseMIcon color={colors.grey11} />}
            disableElevation
            onClick={() => {
              if (clearSelected) {
                clearSelected();
              }
            }}
          >
            {selected.length} selected
          </Button>

          <ClickAwayListener onClickAway={handleTooltipOwnerClose}>
            <div>
              <TooltipFooter
                title={
                  <SequenceActionTooltipWrapper>
                    {filteredUsers?.map((user) => {
                      return (
                        <Button
                          key={user.id}
                          className={`sequence-action-tooltip-button ${
                            user.id === selectedUser?.id ? 'selected-user' : ''
                          }`}
                          onClick={() => handleSelectUser(user.id)}
                        >
                          {fullname(user.firstName, user.lastName)}
                        </Button>
                      );
                    })}
                  </SequenceActionTooltipWrapper>
                }
                onClose={handleTooltipOwnerClose}
                open={openTooltipOwner}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Button
                  startIcon={
                    <OwnerIcon
                      className="sequence-action-icon"
                      color={colors.mainDark}
                    />
                  }
                  className="sequence-action-button"
                  color="secondary"
                  onClick={() => {
                    if (openTooltipOwner) {
                      handleTooltipOwnerClose();
                    } else {
                      handleTooltipOwnerOpen();
                    }
                  }}
                  endIcon={
                    openTooltipOwner ? <TopArrowIcon /> : <BottomArrowIcon />
                  }
                  style={{
                    maxWidth: 200,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {openTooltipOwner ? (
                    <TextField
                      value={searchUserName}
                      inputRef={textFieldRef}
                      placeholder="Type..."
                      onChange={(ev) => {
                        if (setSearchUserName)
                          setSearchUserName(ev.target.value);
                      }}
                      autoComplete="false"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          onEnterOwner();
                        }
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          fontSize: 12,
                        },
                      }}
                      sx={{
                        height: '16px',
                        width: '60px',
                      }}
                    />
                  ) : selectedUser ? (
                    <>
                      <Typography
                        fontSize={12}
                        lineHeight={'16px'}
                        fontWeight={400}
                        noWrap
                      >
                        {fullname(
                          selectedUser.firstName,
                          selectedUser.lastName,
                        )}
                      </Typography>
                    </>
                  ) : (
                    'Owner'
                  )}
                </Button>
              </TooltipFooter>
            </div>
          </ClickAwayListener>

          <div className="divider" />

          <MainPrimaryButton
            className="apply-button"
            onClick={handleClickAddMultiple}
            startIcon={<PlusIcon color={colors.white} />}
          >
            Add {selected.length}
          </MainPrimaryButton>
          <CancelButton
            onClick={() => {
              if (clearSelected) {
                clearSelected();
              }
            }}
            style={{
              height: 32,
              margin: 0,
            }}
          >
            Cancel
          </CancelButton>
        </>
      )}
      {isImporting && (
        <ImportingInBackgroundBadge isCompanyTab={true} small={true} />
      )}
      {areThereToEnrich && (
        <GreyButton
          style={{
            height: 32,
            padding: '4px 12px 4px 8px',
            borderRadius: 16,
            width: 'fit-content',
            color: colors.intenseBlue,
            lineHeight: '18px',
          }}
          startIcon={
            isEnriching ? (
              <span
                className="loader importing-loader"
                style={{
                  marginInline: 8,
                  fontSize: 12,
                }}
              ></span>
            ) : (
              <EnrichIcon
                color={colors.intenseBlue}
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            )
          }
          disabled={isEnriching}
          onClick={handleEnrichAlll}
        >
          Enrich all
        </GreyButton>
      )}
    </TableFooterWrapper>
  );
};

export default SearchCompanySelectionBottom;
