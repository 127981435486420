import * as Yup from 'yup';

export interface LinkedinInputs {
  message: string;
}

export const linkedinInitialValues = {
  recipient: '',
  message: '',
};
export const linkedinInvitationValidationSchema = Yup.object({
  message: Yup.string().nullable(),
});
