import {
  Typography,
  Tooltip,
  ClickAwayListener,
  Avatar,
  MenuItem,
  TextareaAutosize,
  Select,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { getError } from 'crono-fe-common/utils';
import { ReactComponent as NotOwnedIcon } from 'crono-fe-common/icons/Not-owned-by-the-user.svg';
import { colors } from 'crono-fe-common/theme';
import { OpportunityCardWrapper } from './style';
import { useAuth } from 'context/auth';
import { useSelectedAccount } from 'pages/home/context/account';
import { useConditionalSnackBar } from 'context/snackbar';
import { useJuneAnalytics } from 'context/june';
import { Opportunity } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';
import usePatchOpportunity from 'hooks/services/opportunity/usePatchOpportunity';
import useDisableOpportunity from 'hooks/services/opportunity/useDisableOpportunity';
import { AccountTabWrapper } from '../style';

import { ReactComponent as ProspectActivityIcon } from 'crono-fe-common/icons/Icon-Activity-Clock.svg';
import PreviousActivitiesBox from '../accountView/previousActivitiesBox';
import IconTask from 'crono-fe-common/icons/Icon-Task';
import FutureTasksBox from '../accountView/futureTasksBox';
import {
  generateOpportunityCrmLink,
  getColorsStages,
  getImageFromUrl,
  stringAvatarAccount,
} from 'utils/fe-utils';
import { StatusTooltip } from 'pages/dashboard/companyTable';
import PipelineStages from './pipelineStage';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import AddTaskContainer from 'pages/addComponents/addTaskContainer';
import { Stage } from 'crono-fe-common/types/stage';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { NotTagPropertyWrapper } from '../externalPropertyNotTag/style';
import moment from 'moment';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import IconXSCheckmark from 'crono-fe-common/icons/Icon-XS-CheckMark';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import useGetPipelines from 'hooks/services/pipeline/useGetPipelines';
import { createPortal } from 'react-dom';
import { NumericFormat, numericFormatter } from 'react-number-format';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import ExternalPropertyNotTag from '../externalPropertyNotTag';
import { ReactComponent as RedirectIcon } from 'crono-fe-common/icons/Redirect.svg';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import IconCRMLink from 'crono-fe-common/icons/Icon-CRM-Link';
import { CustomTooltip } from '../activitiesInsideActivityView';
import NotesBox from '../notesBox';
import { useUpgradeModal } from 'context/upgradeModal';

type OpportunityEditableField = 'closeDate' | 'description' | 'userId' | 'name';

const NumberTextField = (props: any) => {
  return (
    <TextField
      variant="standard"
      InputProps={{ disableUnderline: true }}
      sx={{
        '& .MuiInputBase-input': {
          fontWeight: 700,
          width: 'fit-content',
          minWidth: 'fit-content',
          textAlign: 'right',
        },
        '& .MuiInputBase-root': {
          width: 'fit-content',
          minWidth: 'fit-content',
        },
      }}
      type="number"
      {...props}
    />
  );
};

interface IProps {
  opportunity: Opportunity;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  stages: Stage[];
}

const OpportunityCard = ({ opportunity, setTabValue, stages }: IProps) => {
  const { user } = useAuth();
  const { account } = useSelectedAccount();

  const analytics = useJuneAnalytics();

  const { mutate: deleteOpportunity, error: errorDeleteOpportunity } =
    useDisableOpportunity();

  const [expand, setExpand] = useState<boolean>(false);
  const [createTaskModal, setCreateTaskModal] = useState<boolean>(false);
  const [nTask, setNTasks] = useState<number | null>(null);

  // Change values logic --------------------------------
  const {
    mutate: patchOpportunity,
    error: patchOpportunityError,
    isSuccess: isSuccessPatchOpportunity,
  } = usePatchOpportunity();

  // keeps track of successful edited fields
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const [editedOpportunity, setEditedOpportunity] =
    useState<OpportunityEditableField | null>(null);
  const [editedDescription, setEditedDescription] = useState<string | null>(
    opportunity.description ?? null,
  );
  const [editedName, setEditedName] = useState<string | null>(
    opportunity.name ?? null,
  );

  const [editedAmount, setEditedAmount] = useState<number | null>(
    opportunity.amount ?? 0,
  );

  // Change fields logic --------------------------------
  const handleChangeFields = (type: OpportunityEditableField, event: any) => {
    setShowBadge(false);
    setEditedOpportunity(null);
    let name: string;
    let value: any;
    switch (type) {
      case 'closeDate':
        name = 'closeDate';
        value = event;
        break;
      case 'description':
        name = 'description';
        value = editedDescription;
        break;
      case 'userId':
        name = 'userId';
        value = event.target.value;
        break;
      case 'name':
        name = 'name';
        value = event.target.value;
        break;
      default:
        return;
    }
    //We do allow people to change the owner of an opportunity
    if (!opportunity.owned && type !== 'userId') {
      setInformativeMessage("You can't edit this deal, it's not owned by you");
      setTimeout(() => {
        setInformativeMessage(null);
      }, 2000);
      return;
    }

    // * onBlur retriggers the request if enter was pressed previously, even for the same value
    // This is to avoid invoking updateProspect by the on blur event, if it was triggered before by on keydown
    if ((opportunity[name as keyof Opportunity] ?? '') !== value) {
      patchOpportunity({
        accountId: opportunity.accountId ?? account?.objectId ?? '',
        opportunityId: opportunity.objectId ?? null,
        [name]: value,
      });
      if (analytics) {
        analytics.track('patch-deal', {
          field: name,
        });
      }
      setEditedOpportunity(type);
    }
  };

  const renderOpportunityField = (type: OpportunityEditableField) => {
    switch (type) {
      case 'closeDate':
        return (
          <NotTagPropertyWrapper>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="select-date-time-button"
                value={null}
                onChange={(ev: any) => handleChangeFields(type, ev)}
                slots={{
                  openPickerIcon: () => (
                    <>
                      {opportunity.closeDate ? (
                        <Typography fontSize={'14px'}>
                          {moment(opportunity.closeDate).format('ll')}
                        </Typography>
                      ) : (
                        <Typography fontSize={'14px'}>
                          No close date selected
                        </Typography>
                      )}
                    </>
                  ),
                }}
                disabled={!opportunity.owned}
                slotProps={{
                  popper: { style: { zIndex: 9999 } },
                  textField: {
                    variant: 'standard',
                    InputProps: { disableUnderline: true },
                  },
                }}
                sx={{
                  '& .MuiInput-input': {
                    display: 'none',
                  },
                  '& .MuiInputAdornment-root': {
                    margin: 0,
                  },
                  '& .MuiIconButton-root': {
                    marginTop: '20px',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: colors.black,
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </NotTagPropertyWrapper>
        );
      case 'description':
        return (
          <NotTagPropertyWrapper>
            <TextareaAutosize
              className="text-area no-min-height"
              style={{
                // paddingTop: "12px",
                minHeight: '20px !important',
              }}
              minRows={1}
              maxRows={3}
              value={editedDescription || ''}
              disabled={!opportunity.owned}
              placeholder="Enter a text"
              onChange={(ev: any) => setEditedDescription(ev.target.value)}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  ev.stopPropagation();
                  handleChangeFields(type, ev);
                }
              }}
              onBlur={(ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleChangeFields(type, ev);
              }}
            />
          </NotTagPropertyWrapper>
        );
      case 'userId':
        return (
          <NotTagPropertyWrapper>
            <Select
              className="field-input"
              value={opportunity.userId || 0}
              fullWidth
              size="small"
              onChange={(ev: any) => handleChangeFields(type, ev)}
              MenuProps={{
                style: { zIndex: 35001 },
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    paddingInline: '6px',
                    border: '1px solid #dadde9',
                    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      lineHeight: '16px',
                      height: 'fit-content',
                      padding: '8px',
                      cursor: 'pointer',
                      width: '100%',
                      borderRadius: '8px',
                      '&:hover': {
                        background: colors.primaryLight,
                        color: colors.primary,
                      },
                    },
                    '& .Mui-selected': {
                      color: colors.primary,
                      backgroundColor: 'transparent !important',
                    },
                  },
                },
              }}
              sx={{
                boxShadow: 'none',
                border: '1px solid #dadde9',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              }}
            >
              {users?.data?.data.map((user) => {
                return (
                  <MenuItem value={user.id} key={user.id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                );
              })}
            </Select>
          </NotTagPropertyWrapper>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSuccessPatchOpportunity) {
      setShowBadge(isSuccessPatchOpportunity);
      timeout = setTimeout(() => {
        setEditedOpportunity(null);
        setShowBadge(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccessPatchOpportunity]);

  const [informativeMessage, setInformativeMessage] = useState<string | null>(
    null,
  );

  useConditionalSnackBar([
    {
      condition: !!informativeMessage,
      message: informativeMessage || 'Action not allowed',
      severity: 'info',
    },
    {
      condition: !!patchOpportunityError,
      message: getError(patchOpportunityError) ?? 'Error updating deal',
      severity: 'error',
    },
    {
      condition: !!errorDeleteOpportunity,
      message: getError(errorDeleteOpportunity) ?? 'Error deleting deal',
      severity: 'error',
    },
  ]);

  // Tooltip logic --------------------------------
  const opportunityActivities = (
    <AccountTabWrapper
      padding={'0px 1px'}
      borderRadius={'12px'}
      overflow={'auto'}
      boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.10)'}
    >
      <div style={{ overflow: 'hidden', height: '100%', paddingBlock: '8px' }}>
        <PreviousActivitiesBox
          setTabValue={setTabValue}
          opportunityId={opportunity.objectId}
        />
      </div>
    </AccountTabWrapper>
  );

  const opportunityTasks = (
    <AccountTabWrapper
      padding={'0px 1px'}
      borderRadius={'12px'}
      overflow={'auto'}
      boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.10)'}
    >
      <div style={{ overflow: 'hidden', height: '100%', paddingBlock: '8px' }}>
        <FutureTasksBox
          setTabValue={setTabValue}
          opportunityId={opportunity.objectId}
          setNTasks={setNTasks}
          dealTabTooltip
          openTaskCreation={() => setCreateTaskModal(true)}
        />
      </div>
    </AccountTabWrapper>
  );

  const handleDisableOpportunity = () => {
    if (!opportunity.account?.objectId && !opportunity.accountId) return;
    deleteOpportunity({
      AccountId: opportunity.account?.objectId ?? opportunity.accountId ?? '',
      OpportunityId: opportunity.objectId,
    });
  };

  // Stages logic --------------------------------
  const [stagesTooltipOpen, setStagesTooltipOpen] = useState<boolean>(false);
  const handleTooltipClose = () => {
    if (stagesTooltipOpen) {
      setStagesTooltipOpen(false);
    }
  };

  const totalNumberOfStages = useMemo(() => {
    //Return the max stageOrder in the array of stages
    return stages.reduce((prev, curr) => {
      return Math.max(prev, curr.displayOrder);
    }, 0);
  }, [stages]);

  const currentStage = useMemo(() => {
    const stage = stages
      .filter((stage) => stage.externalName === opportunity.stage)
      .map((stage) => {
        const colorsStage = getColorsStages(
          stage.displayOrder,
          totalNumberOfStages,
        );
        return (
          <div
            key={stage.id}
            className="status"
            style={{
              backgroundColor: colorsStage.dark,
              cursor: opportunity.owned ? 'pointer' : 'default',
              padding: '4px 16px',
            }}
            onClick={() => {
              if (opportunity.owned) {
                setStagesTooltipOpen(true);
              }
            }}
          >
            <Typography
              noWrap
              fontSize={'12px'}
              fontWeight={500}
              color={colors.white}
            >
              {stage.publicName}
            </Typography>
          </div>
        );
      });
    return stage[0] ?? <></>;
  }, [opportunity, stages, totalNumberOfStages]);

  const handleChangeStage = (stage: string | null) => {
    if (!stage) return;
    patchOpportunity({
      accountId: opportunity.accountId ?? account?.objectId ?? '',
      opportunityId: opportunity.objectId ?? null,
      stage: stage,
    });
    if (analytics) {
      analytics.track('patch-deal', {
        field: 'stage',
      });
    }
    handleTooltipClose();
  };

  const handleChangeDealAmount = (amount: number) => {
    if (opportunity.amount === amount) return;
    patchOpportunity({
      accountId: opportunity.accountId ?? account?.objectId ?? '',
      opportunityId: opportunity.objectId ?? null,
      amount: amount,
    });
    if (analytics) {
      analytics.track('patch-deal', {
        field: 'amount',
      });
    }
  };
  // Pipeline logic ------------------------------
  const { data: pipelines } = useGetPipelines();

  const [selectedPipelineId, setSelectedPipelineId] = useState<number | null>(
    null,
  );

  // Deal owner logic ----------------------------
  const { data: users } = useGetUsers();

  // EP logic -----------------------------------
  const { data: externalProperties } = useGetExternalProperty(
    'Opportunity',
    false,
    false,
  );

  const { isUpgradeModalVisible } = useUpgradeModal();

  if (!user) return <></>;

  return (
    <OpportunityCardWrapper>
      {!isUpgradeModalVisible &&
        createTaskModal &&
        createPortal(
          <AddTaskContainer
            close={() => setCreateTaskModal(false)}
            initialCompany={account ?? undefined}
            initialOpportunity={opportunity}
          />,
          document.body,
        )}
      <div className="deal-header-container">
        <Avatar
          key={opportunity.objectId}
          src={account?.website && getImageFromUrl(account.website, null)}
          className={
            account?.website ? 'account-avatar white' : 'account-avatar'
          }
        >
          {stringAvatarAccount(account?.name || '')}
        </Avatar>
        <div className="opportunity-info-container">
          <div className="top-container-name-title-status">
            <div className="left-side">
              <div className="left-name-lead">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip
                    arrow
                    title={opportunity.name}
                    enterDelay={800}
                    enterNextDelay={800}
                  >
                    <div className="editable-div">
                      <TextField
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: '14px',
                            fontWeight: '600',
                            maxWidth: '150px',
                          },
                        }}
                        disabled={!opportunity.owned}
                        name="name"
                        autoComplete="off"
                        fullWidth
                        value={editedName || ''}
                        onChange={(ev: any) => setEditedName(ev.target.value)}
                        defaultValue={opportunity.name}
                        key={opportunity.name}
                        placeholder="Insert a name..."
                        onKeyDown={(ev) => {
                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                            ev.stopPropagation();
                            handleChangeFields('name', ev);
                          }
                        }}
                        onBlur={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChangeFields('name', ev);
                        }}
                      />
                      {showBadge && editedOpportunity === 'name' && (
                        <p className="edited-field-badge">
                          Saved
                          <IconXSCheckmark color={colors.primaryDark} />
                        </p>
                      )}
                    </div>
                  </Tooltip>
                  {!opportunity.owned && (
                    <Tooltip
                      arrow
                      title={`The owner is ${opportunity.user}`}
                      placement="top"
                    >
                      <NotOwnedIcon
                        style={{
                          width: 16,
                          height: 16,
                          minWidth: 16,
                          minHeight: 16,
                          marginLeft: 8,
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="pipeline-stage">
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <StatusTooltip
                      title={
                        <PipelineStages
                          opportunity={opportunity}
                          stages={stages}
                          totalNumberOfStages={totalNumberOfStages}
                          changeStage={handleChangeStage}
                        />
                      }
                      open={stagesTooltipOpen}
                      onClose={() => setStagesTooltipOpen(false)}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      {currentStage}
                    </StatusTooltip>
                  </div>
                </ClickAwayListener>
              </div>
            </div>
            <div className="right-side-deal-header">
              {opportunity.owned ? (
                <NumericFormat
                  value={editedAmount}
                  prefix={`${user?.otherSettings?.currency ?? '$'} `}
                  onValueChange={(values) => {
                    setEditedAmount(parseFloat(values.value));
                  }}
                  decimalSeparator=","
                  thousandSeparator="."
                  placeholder={`${user?.otherSettings?.currency ?? '$'} 0.00`}
                  allowNegative={false}
                  decimalScale={2}
                  autoComplete="off"
                  customInput={NumberTextField}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                      ev.stopPropagation();
                      handleChangeDealAmount(editedAmount ?? 0);
                    }
                  }}
                  onBlur={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    handleChangeDealAmount(editedAmount ?? 0);
                  }}
                />
              ) : (
                <Typography
                  fontSize={'18px'}
                  fontWeight={700}
                  lineHeight={'24px'}
                  noWrap
                >
                  {numericFormatter((opportunity.amount ?? 0).toString(), {
                    prefix: `${user?.otherSettings?.currency ?? '$'} `,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                  })}
                </Typography>
              )}

              <CustomTooltip placement="bottom" title={opportunityActivities}>
                <span className="deal-action-icon-container">
                  <ProspectActivityIcon />
                </span>
              </CustomTooltip>
              <CustomTooltip placement="bottom" title={opportunityTasks}>
                <span className="deal-action-icon-container">
                  {nTask ? (
                    <div className={`number-of-tasks`}>{nTask}</div>
                  ) : null}
                  <IconTask />
                </span>
              </CustomTooltip>
              {user?.integrationType === IntegrationType.CRONO ? (
                <IconButton
                  className="delete-deal-button"
                  onClick={handleDisableOpportunity}
                >
                  <TrashCanDeleteIcon color={colors.inactive} />
                </IconButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="divider" />
      <div className="deal-fields-notes-container">
        <div className="inner-expand inner-expand-left">
          <div className="information-editable-container">
            <div className="external-properties-tag">
              <Typography
                fontSize={'14px'}
                className="information-label-opportunity"
              >
                Close date:
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {renderOpportunityField('closeDate')}
              {showBadge && editedOpportunity === 'closeDate' && (
                <p className="edited-field-badge">
                  Saved
                  <IconXSCheckmark color={colors.primaryDark} />
                </p>
              )}
            </div>
          </div>
          <div
            className="information-editable-container"
            style={{ position: 'relative' }}
          >
            <div className="external-properties-tag">
              <Typography
                fontSize={'14px'}
                className="information-label-opportunity"
              >
                Deal owner:
              </Typography>
            </div>
            <div style={{ width: '100%' }}>
              {renderOpportunityField('userId')}
            </div>
            {showBadge && editedOpportunity === 'userId' && (
              <p className="edited-field-badge absolute-right">
                Saved
                <IconXSCheckmark color={colors.primaryDark} />
              </p>
            )}
          </div>
          <div
            className="information-editable-container"
            style={{ position: 'relative' }}
          >
            <div className="external-properties-tag">
              <Typography
                fontSize={'14px'}
                className="information-label-opportunity"
              >
                Description:
              </Typography>
            </div>
            <div style={{ width: '100%' }}>
              {renderOpportunityField('description')}
            </div>
            {showBadge && editedOpportunity === 'description' && (
              <p className="edited-field-badge absolute-right">
                Saved
                <IconXSCheckmark color={colors.primaryDark} />
              </p>
            )}
          </div>
          {user.company?.integrationType !== IntegrationType.CRONO && (
            <div
              className="information-editable-container"
              style={{ position: 'relative' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '24px',
                  }}
                >
                  <IconCRMLink color={colors.grey11} />
                </div>
                <Typography
                  fontSize={'14px'}
                  className="information-label-opportunity"
                >
                  CRM:
                </Typography>
              </div>
              <div className="editable-div" style={{ height: 31 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <TextField
                    name="crm"
                    variant="standard"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    defaultValue={
                      generateOpportunityCrmLink(opportunity, user) ?? ''
                    }
                    disabled
                  ></TextField>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 4,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      window.open(
                        generateOpportunityCrmLink(opportunity, user) ?? '',
                        '_blank',
                      );
                    }}
                    style={{ padding: 0 }}
                  >
                    <RedirectIcon style={{ height: 24, width: 24 }} />
                  </IconButton>
                </div>
              </div>
            </div>
          )}
          {expand && (
            <>
              <div className="information-editable-container">
                <div className="external-properties-tag">
                  <Typography
                    fontSize={'14px'}
                    className="information-label-opportunity"
                  >
                    Pipeline:
                  </Typography>
                </div>
                <div style={{ width: '100%' }}>
                  {pipelines?.data?.data.length === 1 ? (
                    <Typography fontSize={'14px'}>
                      {pipelines?.data?.data[0].publicName}
                    </Typography>
                  ) : (
                    <CronoSelect
                      style={{ height: 40, width: '100%' }}
                      value={selectedPipelineId}
                      onChange={(event) => {
                        setSelectedPipelineId(event.target.value as number);
                      }}
                    >
                      {pipelines?.data?.data.map((pipeline) => {
                        return (
                          <CronoMenuItem value={pipeline.id} key={pipeline.id}>
                            {pipeline.publicName}
                          </CronoMenuItem>
                        );
                      })}
                    </CronoSelect>
                  )}
                </div>
              </div>
              {(externalProperties?.data?.data || [])
                .filter((externalProperty) => !externalProperty.isTag)
                .map((externalProperty, index) => (
                  <div
                    key={externalProperty.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '4px',
                    }}
                    className="information-editable-container"
                  >
                    <div className="external-properties-tag">
                      <Tooltip arrow title={externalProperty.publicName}>
                        <Typography
                          className="information-label"
                          fontSize={'14px'}
                        >
                          {externalProperty.publicName}:
                        </Typography>
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ExternalPropertyNotTag
                        type="Opportunity"
                        externalValues={opportunity.externalValues}
                        externalProperty={externalProperty}
                        objectId={opportunity.objectId ?? undefined}
                        owned={opportunity.owned}
                        key={index}
                        accountId={opportunity.accountId ?? account?.objectId}
                      />
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
        <div
          className="inner-expand inner-expand-right"
          style={{
            maxHeight: expand ? '290px' : '185px',
          }}
        >
          <NotesBox opportunityId={opportunity.objectId} />
        </div>
      </div>
      <div className="divider" />
      <div
        className="show-more-fields"
        onClick={() => setExpand((prev) => !prev)}
      >
        <Typography fontSize={'12px'} fontWeight={500}>
          Show {!expand ? 'more' : 'less'}
        </Typography>
        {expand ? <TopArrowIcon /> : <BottomArrowIcon />}
      </div>
    </OpportunityCardWrapper>
  );
};

export default OpportunityCard;
