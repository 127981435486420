import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect } from 'react';
import { CircularProgress, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import Role from 'crono-fe-common/types/enums/role';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import { InviteNewUserFormWrapper } from '../inviteNewUserForm/style';
import { validationSchemaEditMember } from './model';
import { User } from 'crono-fe-common/types/user';
import usePatchUser from 'hooks/services/subscription/usePatchUser';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { IUserSubscriptionPage } from '../inviteNewUserForm';

interface IProps {
  user: (IUserSubscriptionPage & { id: number; userRoles: Role[] }) | User;
  close: () => void;
  onEdit?: (
    user: IUserSubscriptionPage & {
      id: number;
      userRoles: Role[];
    },
  ) => void;
  showEmail?: boolean;
}

const EditUserForm = ({ close, user, onEdit, showEmail = false }: IProps) => {
  const {
    mutate: patchUser,
    isSuccess: userPatched,
    error: errorPatchingUser,
    isLoading: patchingUser,
  } = usePatchUser();

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      permission: user.userRoles.includes(Role.MANAGER)
        ? Role.MANAGER
        : Role.BASIC,
      email: user.email,
    },
    validationSchema: validationSchemaEditMember,
    onSubmit: (values) => {
      //Check if the value changed, if so pass it, if not pass undefined
      const manager =
        (values.permission === Role.MANAGER &&
          user.userRoles.includes(Role.MANAGER)) ||
        (values.permission === Role.BASIC &&
          !user.userRoles.includes(Role.MANAGER))
          ? undefined
          : values.permission === Role.MANAGER;
      //If there is an onEdit function I use that instead of the patchUser API
      if (onEdit) {
        if (values.firstName && values.lastName) {
          onEdit({
            firstName: values.firstName!,
            lastName: values.lastName!,
            id: user.id,
            userRoles:
              values.permission === Role.BASIC
                ? [Role.BASIC]
                : [Role.BASIC, Role.MANAGER],
            email: values.email,
            permission: values.permission,
          });
        }
      } else {
        patchUser({
          manager: manager,
          firstName:
            values.firstName !== user.firstName
              ? values.firstName ?? undefined
              : undefined,
          lastName:
            values.lastName !== user.lastName
              ? values.lastName ?? undefined
              : undefined,
          userId: user.id,
        });
      }
    },
  });

  useEffect(() => {
    if (userPatched) {
      close();
    }
  }, [userPatched]);

  useConditionalSnackBar([
    {
      condition: !!errorPatchingUser,
      message: getError(errorPatchingUser) ?? 'Error updating user',
      severity: 'error',
    },
    {
      condition: !!userPatched,
      message: 'User updated successfully!',
      severity: 'success',
    },
  ]);

  return (
    <BlurBackgroundDivFixed>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 10,
        }}
        onClick={() => close()}
      />
      <InviteNewUserFormWrapper>
        <Typography fontWeight={700} fontSize={24} lineHeight={'30px'}>
          Edit member info:
        </Typography>
        <div className="form-row-invite-new">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            First name:
          </Typography>
          <TextField
            fullWidth
            placeholder="James..."
            variant="standard"
            value={formik.values.firstName}
            onChange={(ev) => {
              formik.setFieldValue('firstName', ev.target.value);
            }}
            helperText={formik.touched.firstName && formik.errors.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          />
        </div>
        <div className="form-row-invite-new">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Last name:
          </Typography>
          <TextField
            fullWidth
            placeholder="Smith..."
            variant="standard"
            value={formik.values.lastName}
            onChange={(ev) => {
              formik.setFieldValue('lastName', ev.target.value);
            }}
            helperText={formik.touched.lastName && formik.errors.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          />
        </div>
        {showEmail && (
          <div className="form-row-invite-new">
            <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
              Email:
            </Typography>
            <TextField
              fullWidth
              placeholder="Smith..."
              variant="standard"
              value={formik.values.email}
              onChange={(ev) => {
                formik.setFieldValue('email', ev.target.value);
              }}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </div>
        )}
        <div className="form-row-invite-new" style={{ gap: 8 }}>
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Select user permissions:
          </Typography>
          <CronoSelect
            value={formik.values.permission}
            onChange={(ev) =>
              formik.setFieldValue('permission', ev.target.value)
            }
            style={{ height: 40 }}
            MenuProps={{
              // disablePortal: true
              style: {
                zIndex: 10000,
              },
            }}
          >
            <CronoMenuItem value={Role.BASIC}>User</CronoMenuItem>
            <CronoMenuItem value={Role.MANAGER}>Manager</CronoMenuItem>
          </CronoSelect>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              width: '100%',
              gap: 2,
            }}
          >
            <Typography
              flexWrap={'wrap'}
              fontSize={12}
              lineHeight={'16px'}
              color={colors.grey11}
            >
              *
            </Typography>
            <Typography
              flexWrap={'wrap'}
              fontSize={12}
              lineHeight={'16px'}
              color={colors.grey11}
            >
              {formik.values.permission === Role.BASIC
                ? 'The user has the same rights as the manager, except for creating new templates and modifying workspace data.'
                : 'The manager has full rights: managing templates & modifying workspace data.'}
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
          }}
        >
          <CancelButton onClick={close} style={{ margin: 0 }}>
            Cancel
          </CancelButton>
          {patchingUser ? (
            <CircularProgress />
          ) : (
            <MainButton
              onClick={() => formik.handleSubmit()}
              style={{ margin: 0 }}
            >
              Save
            </MainButton>
          )}
        </div>
      </InviteNewUserFormWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default EditUserForm;
