import useTaskAnalytics from 'hooks/services/analytics/useTaskAnalytics';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Column } from '@ant-design/charts';
import { GraphData } from '../tabsControllers/model';
import { AnalyticsTask } from 'crono-fe-common/types/analyticsTask';
import { MailLegend, colorsMail } from '../legendData';
import { calcWidth, numberOfGroups, selectChartContainerClass } from '../model';
import { Typography } from '@mui/material';
import Legend from '../legend';
import { BarChartPlaceholder } from './chartsPlaceholders';

interface IProps {
  taskAnalytics: AnalyticsTask | undefined;
}

const TaskEmailChart = ({ taskAnalytics }: IProps) => {
  const { data: users } = useGetUsers();

  const [maxValue, setMaxValue] = useState<number>(0);

  const dataGeneral = useMemo(() => {
    if (!users || !taskAnalytics) return [];
    const userMapping = new Map<number, string>();
    users?.data?.data?.forEach((user) => {
      userMapping.set(user.id, user.firstName + ' ' + user.lastName);
    });
    let newValue = 0;
    const newData: GraphData[] = [];
    taskAnalytics?.emailDetail.TotalCount?.forEach((total) => {
      const user = userMapping.get(total.userId);
      if (!user) return;
      if (total.count > newValue) newValue = total.count;
      newData.push({
        label: user,
        value: total.count,
        type: 'Sent',
      });
    });
    taskAnalytics?.emailDetail.OpenCount?.forEach((open) => {
      const user = userMapping.get(open.userId);
      if (!user) return;
      if (open.count > newValue) newValue = open.count;
      newData.push({
        label: user,
        value: open.count,
        type: 'Opened',
      });
    });
    taskAnalytics?.emailDetail.BouncedCount?.forEach((bounced) => {
      const user = userMapping.get(bounced.userId);
      if (!user) return;
      if (bounced.count > newValue) newValue = bounced.count;
      newData.push({
        label: user,
        value: bounced.count,
        type: 'Bounced',
      });
    });
    taskAnalytics?.emailDetail.ClickCount?.forEach((click) => {
      const user = userMapping.get(click.userId);
      if (!user) return;
      if (click.count > newValue) newValue = click.count;
      newData.push({
        label: user,
        value: click.count,
        type: 'Link clicked',
      });
    });
    taskAnalytics?.emailDetail.ReplyCount?.forEach((reply) => {
      const user = userMapping.get(reply.userId);
      if (!user) return;
      if (reply.count > newValue) newValue = reply.count;
      newData.push({
        label: user,
        value: reply.count,
        type: 'Replied',
      });
    });
    setMaxValue(newValue);
    return newData;
  }, [taskAnalytics, users]);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const configGeneral = useMemo(() => {
    return {
      data: dataGeneral,
      xField: 'label',
      yField: 'value',
      seriesField: 'type',
      color: (({ type }: { type: string }) => {
        const index = MailLegend.findIndex((legend) => legend === type) ?? 0;
        return colorsMail[index];
      }) as any,
      autoFit: false,
      height: 300,
      meta: {
        value: {
          min: 0,
          max: maxValue * 1.2,
        },
      },
      width: calcWidth(
        dataGeneral,
        180,
        chartContainerRef.current?.offsetWidth,
      ),
      isGroup: true,
      columnStyle: {},
      renderer: 'svg' as 'svg' | 'canvas' | undefined,
      //If only one ground is present, the graph doesn't work if dodgePadding is set
      dodgePadding: numberOfGroups(dataGeneral) > 1 ? 6 : undefined,

      maxColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      minColumnWidth: numberOfGroups(dataGeneral) > 1 ? 15 : 150,
      legend: false as any,
      randomVal: Math.random() * 10000,
    };
  }, [dataGeneral, chartContainerRef.current?.offsetWidth, maxValue]);

  return (
    <>
      <Typography className="chart-title">Email Conversion Rate</Typography>
      <div
        className={selectChartContainerClass(dataGeneral)}
        ref={chartContainerRef}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 40,
            position: 'sticky',
            left: 0,
          }}
        >
          <Legend colors={colorsMail} fields={MailLegend} />
        </div>
        {configGeneral?.data && configGeneral.data.length ? (
          <Column {...configGeneral} key={configGeneral.randomVal} />
        ) : (
          <div style={{ minWidth: '100%', marginTop: '25px' }}>
            <BarChartPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default TaskEmailChart;
