import { SearchLinkedinWrapper } from './styles';
import { FC, useEffect, useState } from 'react';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { CircularProgress, Typography } from '@mui/material';
import Profile from './Profile';

import {
  CloseButton,
  MainPrimaryButton,
} from 'crono-fe-common/components/CronoButton';
import { useJuneAnalytics } from 'context/june';
import { ActionPreconditionProps } from './types';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import { useLinkedinSearchLeads } from 'crono-fe-common/hooks/crono-extension/gateway';
import {
  CronoGatewayLinkedinSearchLeadsAction,
  LinkedinProfile,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { openLinkedinAccount } from 'utils/fe-utils';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';

const SearchLinkedin: FC<ActionPreconditionProps> = ({
  account,
  setOpenModal: setOpen,
  prospect,
  callOnSuccess,
}) => {
  const [leads, setLeads] = useState<LinkedinProfile[]>([]);
  const analytics = useJuneAnalytics();

  const {
    call: searchLeads,
    result: searchLeadsResult,
    isLoading: isSearchLeadLoading,
  } = useLinkedinSearchLeads();

  useEffect(() => {
    if (searchLeadsResult?.profiles) {
      setLeads(searchLeadsResult.profiles);
    }
  }, [searchLeadsResult]);

  useEffect(() => {
    if (!searchLeadsResult) {
      const companyPublicId = LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(
        account.linkedin,
      )[0];

      if (analytics) {
        analytics.track('search-linkedin', {});
      }
      const searchLeadsParams: CronoGatewayLinkedinSearchLeadsAction['params'] =
        {
          companyName: account?.name,
          userFirstName: prospect?.firstName,
          userLastName: prospect?.lastName,
          title: null,
        };
      if (companyPublicId) {
        searchLeadsParams.companyPublicId = companyPublicId;
      }
      searchLeads(searchLeadsParams);
    }
  }, [searchLeadsResult, account]);
  return (
    <SearchLinkedinWrapper>
      <FlexDiv
        direction="column"
        alignItems="end"
        style={{ rowGap: 20, overflow: 'hidden', flex: 1 }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            fontSize={20}
            fontWeight={700}
            style={{ alignSelf: 'flex-start' }}
          >
            Search linkedin profile
          </Typography>
          <CloseButton size="large" onClick={() => setOpen(false)}>
            <CloseMIcon color={colors.grey11} />
          </CloseButton>
        </div>

        <div className="modal-body">
          {isSearchLeadLoading ? (
            <FlexDiv className="loading">
              <CircularProgress />
            </FlexDiv>
          ) : (
            <FlexDiv
              className="modal-content"
              direction="column"
              justifyContent="center"
            >
              {searchLeadsResult && prospect && leads.length > 0 ? (
                leads
                  .filter(
                    (l) =>
                      l.firstName !== '' &&
                      l.lastName !== '' &&
                      l.profileId !== 'UNKNOWN',
                  )
                  .map((lead) => (
                    <Profile
                      key={lead.profileId}
                      profile={lead}
                      prospect={prospect}
                      setOpen={setOpen}
                      callOnSuccess={callOnSuccess}
                    />
                  ))
              ) : (
                <FlexDiv
                  className="modal-content"
                  direction="column"
                  justifyContent="center"
                >
                  <h3>We didn't find LinkedIn profile</h3>
                  {(!account?.linkedin || account.linkedin === '') && (
                    <FlexDiv>
                      <p>Find LinkedIn Company url to improve Search</p>
                    </FlexDiv>
                  )}
                </FlexDiv>
              )}
            </FlexDiv>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <MainPrimaryButton
            className="modal-button"
            onClick={() => openLinkedinAccount(account)}
          >
            Go To LinkedIn
          </MainPrimaryButton>
        </div>
      </FlexDiv>
    </SearchLinkedinWrapper>
  );
};

export default SearchLinkedin;
