import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconMContact = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
      onClick={onClick}
    >
      <path
        d="M14.1655 5.45949C15.3615 6.65547 15.3615 8.59454 14.1655 9.79052C12.9695 10.9865 11.0305 10.9865 9.83449 9.79052C8.6385 8.59454 8.6385 6.65547 9.83449 5.45949C11.0305 4.2635 12.9695 4.2635 14.1655 5.45949"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17.6875V18.5625C5 19.0455 5.392 19.4375 5.875 19.4375H18.125C18.608 19.4375 19 19.0455 19 18.5625V17.6875C19 15.0397 15.542 13.3195 12 13.3195C8.458 13.3195 5 15.0397 5 17.6875Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMContact;
