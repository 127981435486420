import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const NotificationItemWrapper = styled.div`
  margin: 0px 12px 4px 12px;
  padding: 12px;
  display: flex;
  column-gap: 16px;
  border-radius: 8px;
  overflow: hidden;

  .notification-icon {
    width: 32px;
    height: 32px;
  }
  .notification-content {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
  }

  .icon-size {
    width: 24px;
    height: 24px;
  }

  .go-to-button {
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${colors.darkGreen};
  }
  .go-to-button > svg > path {
    stroke: ${colors.darkGreen};
  }
  .go-to-button:hover {
    color: ${colors.darkGreenHover};
    cursor: pointer;
  }
  .go-to-button:hover > svg > path {
    stroke: ${colors.darkGreenHover};
  }

  .prospect-name {
    cursor: pointer;
  }
  .prospect-name:hover {
    color: ${colors.darkGreen};
  }
`;
