import { Badge, TableCell, Tooltip, Typography } from '@mui/material';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import { useLinkedinGetCompanyInfo } from 'crono-fe-common/hooks/crono-extension/gateway';
import useFindScrape from 'hooks/services/scrape/useFindScrape';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { extractDomainFromWebsite } from 'utils/fe-utils';
import styled from '@emotion/styled';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import IconCopy from 'crono-fe-common/icons/Icon-Copy';
import { ValidityDot } from 'pages/accountTab/emailView/validityBadgeEmailTask';
import useGetUserCredits from 'hooks/services/user/useGetUserCredits';
import { Prospect } from 'crono-fe-common/types/prospect';
import { Account } from 'crono-fe-common/types/account';
import useEditAccount from 'hooks/services/account/useEditAccount';
import { AccountInfoInputs } from 'crono-fe-common/types/DTO/accountInfoInputs';
import { getError } from 'crono-fe-common/utils';
import useScrapeWebsite from 'hooks/services/scrape/useScrapeWebsite';
import IconWebsite from 'crono-fe-common/icons/Icon-Website';
import { EmailNotFoundWrapper } from './style';
import DomainEditor, { TooltipEmailNotFound } from './domainEditor';
import { createPortal } from 'react-dom';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import useEditProspect from 'hooks/services/prospect/useEditProspect';

interface IProps {
  prospect: Prospect;
  account?: Account | null;
  disabled?: boolean;
  enrichingEmailInProgress: boolean;
  alreadySearching: boolean;
  setAlreadySearching: React.Dispatch<React.SetStateAction<boolean>>;
}

const FindEmailProspectRow = ({
  account,
  prospect,
  disabled,
  enrichingEmailInProgress,
  alreadySearching,
  setAlreadySearching,
}: IProps) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [scrapeFailed, setScrapeFailed] = useState<boolean>(false);

  //================================================================================================
  const analytics = useJuneAnalytics();
  const [searchEmailError, setSearchEmailError] = useState<string | null>(null);
  const [searchEmailHint, setSearchEmailHint] = useState<string | null>(null);
  const [nProvider, setNProvider] = useState<number>(0);

  const {
    isLoading: isProspectScrapeLoading,
    data: prospectScrapeData,
    error: prospectScrapeError,
    mutateAsync: findEmail,
  } = useFindScrape();

  const { data: userCredits } = useGetUserCredits();

  useEffect(() => {
    if (prospectScrapeData) {
      if (prospectScrapeData.data?.data) {
        setIsSearching(false);
      }
    }
  }, [prospectScrapeData]);

  const { result: linkedinCompanyInfo, call: getLinkedinCompanyInfo } =
    useLinkedinGetCompanyInfo();

  const { mutate: updateAccount, error: errorUpdateAccount } = useEditAccount();

  useEffect(() => {
    const handle = async () => {
      if (linkedinCompanyInfo && account) {
        if (linkedinCompanyInfo?.company?.website) {
          const website = await scrapeWebsiteAsync({
            website: linkedinCompanyInfo?.company?.website ?? '',
          });
          if (website.data?.data?.redirectUrl) {
            const domain = extractDomainFromWebsite(
              website.data?.data?.redirectUrl,
            );
            validateInputAndFindEmail(domain);
          }
          const patchAccount: AccountInfoInputs = {
            accountId: account.objectId,
          };

          patchAccount.website = website.data?.data?.redirectUrl;

          if (
            !account.numberOfEmployees &&
            linkedinCompanyInfo.company.numberOfEmployees
          ) {
            patchAccount.nOfEmployees =
              linkedinCompanyInfo.company.numberOfEmployees;
          }

          if (!account.industry && linkedinCompanyInfo.company.industry) {
            patchAccount.industry = linkedinCompanyInfo.company.industry;
          }

          if (!account.country && linkedinCompanyInfo.company.country) {
            patchAccount.country = linkedinCompanyInfo.company.country;
          }

          updateAccount(patchAccount);
        } else {
          setSearchEmailHint('Add website to company info to find email');
          setIsSearching(false);
          setScrapeFailed(true);
          setTimeout(() => {
            setSearchEmailHint(null);
          }, 2000);
        }
      }
    };
    handle();
  }, [linkedinCompanyInfo]);

  useEffect(() => {
    if (prospectScrapeError) {
      setScrapeFailed(true);
      setIsSearching(false);
      if (prospectScrapeError.response?.status === 409) {
        setSearchEmailError(
          `The found email ${prospectScrapeData?.data?.data.email ?? ''} is already in use in the CRM`,
        );
      } else if (prospectScrapeError?.response?.status === 404) {
        setSearchEmailError('Email not found');
      } else if (prospectScrapeError?.response?.status === 403) {
        setSearchEmailError('Scrape not enabled or credit exhausted');
      } else {
        setSearchEmailError('Error while finding email');
      }
    }
  }, [prospectScrapeError]);

  useConditionalSnackBar([
    {
      condition: !!searchEmailError,
      message: searchEmailError || '',
      severity: 'error',
    },
    {
      condition: !!searchEmailHint,
      message: searchEmailHint || '',
      severity: 'info',
    },
    {
      condition: !!errorUpdateAccount,
      message: getError(errorUpdateAccount) ?? 'Error update company',
      severity: 'error',
    },
  ]);

  const { mutateAsync: scrapeWebsiteAsync, isLoading: isLoadingScrapeWebsite } =
    useScrapeWebsite();

  const handleFindEmail = async () => {
    setSearchEmailError(null);
    setSearchEmailHint(null);
    setIsSearching(true);
    const website = await scrapeWebsiteAsync({
      website: account?.website ?? '',
    });
    if (account && website.data?.data?.redirectUrl) {
      const domain = extractDomainFromWebsite(website.data?.data?.redirectUrl);
      validateInputAndFindEmail(domain);
      if (website.data.data.redirectUrl !== account.website) {
        updateAccount({
          accountId: account.objectId,
          website: website.data.data.redirectUrl,
        });
      }
    } else if (account && (account.linkedin || account.linkedinNumericId)) {
      getLinkedinCompanyInfo({
        url: account.linkedin,
        numericId: account.linkedinNumericId,
      }).then();
    } else {
      setIsSearching(false);
      setScrapeFailed(true);
      setSearchEmailHint('Add website to company info to find email');
    }
  };

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (searchEmailHint) {
      t = setTimeout(() => {
        setSearchEmailHint(null);
      }, 2000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [searchEmailHint]);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (searchEmailError) {
      t = setTimeout(() => {
        setSearchEmailError(null);
      }, 2000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [searchEmailError]);

  const validateInputAndFindEmail = (domain: string) => {
    if (!domain || domain.length === 0) {
      setScrapeFailed(true);
      setSearchEmailError('Fill company domain');
      return;
    }
    if (
      !prospect.firstName ||
      !prospect.lastName ||
      prospect.firstName === '' ||
      prospect.lastName === ''
    ) {
      setScrapeFailed(true);
      setSearchEmailError('Fill contact name');
      return;
    }

    if (analytics) {
      analytics.track('search-email', {});
    }
    findEmail({
      firstName: prospect.firstName,
      lastName: prospect.lastName,
      domain,
      linkedinUrl: prospect.linkedin,
      prospectId: prospect.objectId,
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isProspectScrapeLoading) {
      timer = setInterval(() => {
        setNProvider((prev) => {
          if (prev >= 10) return 10;
          return prev + 1;
        });
      }, 2500);
    } else {
      setNProvider(1);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isProspectScrapeLoading]);

  const areThere0Credits = useMemo(() => {
    return userCredits?.data?.data.emailLeft === 0;
  }, [userCredits]);

  //================================================================================================

  //Update the other rows that one is already looking for the email
  useEffect(() => {
    setAlreadySearching(isSearching);
  }, [isSearching]);

  //Show the domain editor
  const [openTooltipWebsiteUsed, setOpenTooltipWebsiteUsed] = useState(false);

  const [showDomainEditor, setShowDomainEditor] = useState(false);

  const { mutate: editProspect } = useEditProspect();

  const handleConfirmWebsite = () => {
    editProspect({
      prospectId: prospect.objectId,
      clearLastEmailNotFoundDate: true,
    });
  };

  return (
    <>
      {showDomainEditor &&
        createPortal(
          <DomainEditor
            accountId={account?.objectId ?? null}
            initialWebsite={
              prospect.account?.website ?? account?.website ?? null
            }
            close={() => setShowDomainEditor(false)}
            onSave={() => setScrapeFailed(false)}
          />,
          document.body,
        )}
      <TableCell
        style={{
          width: 150,
          minWidth: 150,
          paddingBlock: 12,
          borderRight: `1px solid ${colors.grey444}`,
        }}
      >
        <span
          style={{
            width: 150,
            minWidth: 150,
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            minHeight: 24,
          }}
        >
          {prospect.email ? (
            <>
              <ValidityDot emailStatus={prospect.emailStatus} />
              <Tooltip
                title={prospect.email ?? null}
                arrow
                enterDelay={400}
                enterNextDelay={400}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  noWrap
                >
                  {prospect.email}
                </Typography>
              </Tooltip>
              <IconCopy
                onClick={() => {
                  navigator.clipboard.writeText(prospect.email || '');
                }}
                className="icon-copy"
              />
            </>
          ) : enrichingEmailInProgress ? (
            <span
              className="loader"
              style={{ fontSize: 3, marginLeft: 12 }}
            ></span>
          ) : prospect.lastEmailNotFoundDate || scrapeFailed ? (
            <Tooltip
              arrow
              enterDelay={400}
              enterNextDelay={400}
              open={openTooltipWebsiteUsed}
              onClose={() => setOpenTooltipWebsiteUsed(false)}
              title={
                <TooltipEmailNotFound>
                  <span
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    Crono used this company <br />
                    website for email search:
                  </span>
                  <span
                    style={{
                      color: colors.primary,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                      width: '100%',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      setShowDomainEditor(true);
                      setOpenTooltipWebsiteUsed(false);
                    }}
                  >
                    <Typography
                      fontSize={12}
                      lineHeight={'16px'}
                      fontWeight={600}
                      style={{
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                      }}
                    >
                      {prospect.account?.website || 'Website is missing'}
                    </Typography>
                    <EditPencilIconS
                      color={colors.primary}
                      className="pencil-icon"
                    />
                  </span>
                  {prospect.account?.website && (
                    <span
                      className="confirm-button"
                      onClick={handleConfirmWebsite}
                    >
                      Confirm
                    </span>
                  )}
                </TooltipEmailNotFound>
              }
            >
              <EmailNotFoundWrapper
                onMouseEnter={() => setOpenTooltipWebsiteUsed(true)}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'24px'}
                  color={
                    openTooltipWebsiteUsed ? colors.mainDark : colors.grey11
                  }
                >
                  Email not found
                </Typography>
                <Badge
                  variant="dot"
                  overlap="circular"
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                  sx={{
                    '& .MuiBadge-badge': {
                      right: 6,
                      top: 6,
                      background: colors.inactive,
                      height: '10px',
                      width: '10px',
                      minWidth: '10px',
                      minHeight: '10px',
                      padding: '1px',
                      border: `1px solid ${colors.white}`,
                      borderRadius: '50%',
                    },
                  }}
                >
                  <div>
                    <IconWebsite
                      color={
                        openTooltipWebsiteUsed ? colors.mainDark : colors.grey11
                      }
                    />
                  </div>
                </Badge>
              </EmailNotFoundWrapper>
            </Tooltip>
          ) : isSearching || isLoadingScrapeWebsite ? (
            <>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                color={colors.intenseBlue}
              >
                {nProvider} provider...
              </Typography>
              <span
                className="loader"
                style={{ fontSize: 3, marginLeft: 12 }}
              ></span>
            </>
          ) : (
            <Tooltip
              arrow
              enterDelay={400}
              enterNextDelay={400}
              title={
                areThere0Credits ? 'You have 0 find email credits left' : null
              }
            >
              <FindEmailContactSearchWrapper
                onClick={() => {
                  // if (disabled) return;
                  if (alreadySearching || disabled || areThere0Credits) return;
                  handleFindEmail();
                }}
                className="find-button"
                style={{
                  cursor:
                    alreadySearching || disabled || areThere0Credits
                      ? 'default'
                      : 'pointer',
                  opacity: alreadySearching ? 0.5 : 1,
                }}
              >
                <Typography
                  fontSize={'14px'}
                  lineHeight={'24px'}
                  color={areThere0Credits ? colors.grey11 : colors.primaryDark}
                >
                  Find email
                </Typography>
                <SearchIcon
                  color={areThere0Credits ? colors.grey11 : colors.primaryDark}
                />
              </FindEmailContactSearchWrapper>
            </Tooltip>
          )}
        </span>
      </TableCell>
    </>
  );
};

export default FindEmailProspectRow;

const FindEmailContactSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;
