import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import queryString from 'query-string';
import { CronoAttachmentWithContent } from 'crono-fe-common/types/cronoAttachmentWithContent';

export default function useGetTemplateAttachmentsFileMutate() {
  const getTemplateAttachments = (AttachmentIds: number[]) => {
    const request = createGetTemplateAttachmentsFileRequest(AttachmentIds);
    return client(request);
  };

  const { data, ...rest } = useMutation<
    Response<CronoAttachmentWithContent[]>,
    CronoError,
    number[]
  >(getTemplateAttachments);

  return { data, ...rest };
}

export function createGetTemplateAttachmentsFileRequest(
  AttachmentIds: number[],
): Request {
  const queryParams = queryString.stringify({ AttachmentIds });
  return {
    url: `${ENDPOINTS.template.attachment.file}?${queryParams}`,
    config: {
      method: 'get',
    },
  };
}
