import { FC, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { CircularProgress } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useConditionalSnackBar } from 'context/snackbar';
import PATH from 'routing/path';
import useAircallAuthCallback from 'hooks/services/aircall/useAircallAuthCallback';
import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';
import { SettingsTabs } from 'pages/settings';
import { getError } from 'crono-fe-common/utils';

const AircallLoginHandler: FC = () => {
  const { user } = useAuth();

  const analytics = useJuneAnalytics();

  const location = useLocation();
  const { isLoading, isSuccess, error, aircallAuthCallback } =
    useAircallAuthCallback();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    const parsedSearch = queryString.parse(location.search);
    aircallAuthCallback(parsedSearch as any);
  }, [user]);

  useConditionalSnackBar([
    {
      condition: !!error,
      message:
        getError(error ?? null) ?? 'Error while make Aircall integration',
      severity: 'error',
    },
    {
      condition: !!isSuccess,
      message: 'Succesfully logged in in aircall',
      severity: 'success',
    },
  ]);

  useLayoutEffect(() => {
    if (isSuccess) {
      navigate(PATH.SETTINGS, {
        state: { tab: SettingsTabs.Integrations },
      });
      if (analytics) {
        analytics.track('call-integration', {
          type: 'aircall',
        });
      }
    }
  }, [isSuccess, user]);

  if (isLoading) {
    return (
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    );
  }

  return <></>;
};

export default AircallLoginHandler;
