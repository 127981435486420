import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinIcon = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      id="linkedin-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4574 6.1814H8.6858V7.2914C9.0068 6.653 9.83 6.0794 11.0666 6.0794C13.4372 6.0794 14 7.3502 14 9.6818V14H11.6V10.2128C11.6 8.885 11.279 8.1362 10.4618 8.1362C9.3284 8.1362 8.8574 8.9432 8.8574 10.2122V14H6.4574V6.1814ZM2.342 13.898H4.742V6.0794H2.342V13.898ZM5.0858 3.53C5.08589 3.73117 5.04599 3.93034 4.96843 4.11595C4.89087 4.30156 4.77719 4.46991 4.634 4.6112C4.34384 4.89957 3.95109 5.06099 3.542 5.06C3.13363 5.05973 2.74179 4.89872 2.4512 4.6118C2.30853 4.47003 2.19523 4.30149 2.11781 4.11586C2.04038 3.93022 2.00035 3.73113 2 3.53C2 3.1238 2.162 2.735 2.4518 2.4482C2.74213 2.1609 3.13414 1.99982 3.5426 2C3.9518 2 4.3442 2.1614 4.634 2.4482C4.9232 2.735 5.0858 3.1238 5.0858 3.53Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkedinIcon;
