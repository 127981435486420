import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const OpportunitiesWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-bottom: -6px;
  .filters-deals-container {
    background: ${colors.white};
    border-radius: 16px;
    padding: 12px;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .filters-deals-part {
    display: flex;
    align-items: center;
  }
  .remove-text-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 35001;
  }
  .filter-button {
    border-radius: 8px;
    padding: 0 12px;
    height: 40px;
    text-transform: none;
    border: 1px solid ${colors.blueLightHover};
    font-size: 1rem;
  }
  .filter-button {
    cursor: pointer;
  }

  .columns-deals-container {
    width: 100%;
    overflow: auto;
    flex: 1;
    padding-bottom: 6px;
  }
  .inner-div-scrollable {
    width: fit-content;
    display: flex;
    height: 100%;
    gap: 8px;
  }
  .number-of {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    height: 24px;
    width: 24px;
    background: ${colors.grey55};
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-field {
    width: 180px;
    .MuiInput-underline:after {
      border-bottom: 2px solid ${colors.grey11};
    }
  }
`;

// export const YearSelectorWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   user-select: none;
//   .year-arrow-icon {
//     cursor: pointer;
//   }
//   .year-disabled {
//     cursor: default;
//   }
// `;
