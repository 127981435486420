import useTemplateAnalytics from 'hooks/services/analytics/useTemplateAnalytics';
import { useEffect } from 'react';
import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import TemplateEmailChart from '../charts/templateEmailChart';
import TemplateLinkedinChart from '../charts/templateLinkedinChart';

interface IProps {
  filterParams: AnalyticsFilters;
}

const AnalyticTemplateTabController = ({ filterParams }: IProps) => {
  const { data: analyticsTemplate } = useTemplateAnalytics(filterParams);
  return (
    <>
      <TemplateEmailChart templateAnalytics={analyticsTemplate?.data?.data} />
      <TemplateLinkedinChart
        templateAnalytics={analyticsTemplate?.data?.data}
      />
    </>
  );
};

export default AnalyticTemplateTabController;
