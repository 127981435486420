import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { UserCredits } from 'crono-fe-common/types/userCredits';

export default function useGetUserCredits() {
  const request: Request = {
    url: ENDPOINTS.user.credits,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.user.credits],
    queryFn: () => client(request),
    //Only 30 seconds since we don't have yet invalidation from the extension side
    staleTime: 30 * 1000,
  };

  const { data, ...rest } = useQuery<Response<UserCredits>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
