export interface StatusPropertyType {
  id: number;
  option: string;
  label: string;
}

export const allDefaultContactStatusOptions = [
  'New',
  'Qualified',
  'Interested',
  'NotInterested',
  'BadFit',
  'OptedOut',
] as const;

export type DefaultContactStatusOptions =
  (typeof allDefaultContactStatusOptions)[number];

export const DefaultContactStatus: StatusPropertyType[] = [
  {
    id: 1,
    label: 'New',
    option: 'New',
  },
  {
    id: 2,
    label: 'Qualified',
    option: 'Qualified',
  },
  {
    id: 3,
    label: 'Interested',
    option: 'Interested',
  },
  {
    id: 4,
    label: 'Not Interested',
    option: 'NotInterested',
  },
  {
    id: 5,
    label: 'Bad fit',
    option: 'BadFit',
  },
  {
    id: 6,
    label: 'Opted-out',
    option: 'OptedOut',
  },
];

export const DefaultDefaultContactStatus: StatusPropertyType =
  DefaultContactStatus[0];

export const allDefaultCompanyStatusOptions = [
  'Inactive',
  'Nurture',
  'Working',
  'CallScheduled',
  'OpenOpportunity',
  'Customer',
] as const;
export type DefaultCompanyStatusOptions =
  (typeof allDefaultCompanyStatusOptions)[number];

export const DefaultCompanyStatus: StatusPropertyType[] = [
  {
    id: 1,
    label: 'Not in target',
    option: 'Inactive',
  },
  {
    id: 2,
    label: 'Nurture',
    option: 'Nurture',
  },
  {
    id: 3,
    label: 'Prospecting',
    option: 'Working',
  },
  {
    id: 4,
    label: 'Meeting',
    option: 'CallScheduled',
  },
  {
    id: 5,
    label: 'Opportunity',
    option: 'OpenOpportunity',
  },
  {
    id: 6,
    label: 'Customer',
    option: 'Customer',
  },
];

export const DefaultDefaultCompanyStatus: StatusPropertyType =
  DefaultCompanyStatus[2];

export const getLabelFromUnknownOption = (
  option: DefaultContactStatusOptions | DefaultCompanyStatusOptions,
) => {
  if (
    allDefaultContactStatusOptions.includes(
      option as DefaultContactStatusOptions,
    )
  ) {
    return DefaultContactStatus.find((status) => status.option === option)
      ?.label;
  } else if (
    allDefaultCompanyStatusOptions.includes(
      option as DefaultCompanyStatusOptions,
    )
  ) {
    return DefaultCompanyStatus.find((status) => status.option === option)
      ?.label;
  }
  return '';
};
