export const ENDPOINTS = {
  account: {
    main: 'Account',
    search: {
      main: 'Account/search',
      export: 'Account/search/export',
      external: 'Account/search/external',
    },
    exists: 'Account/exists',
    disable: 'Account/disable',
    import: 'Account/import',
    multiple: 'Account/Multiple',
    disableMultiple: 'Account/disable/Multiple',
    linkedin: {
      main: 'Account/Linkedin',
      multiple: 'Account/Linkedin/Multiple',
    },
    sync: 'Account/Sync',
  },
  aircall: {
    authCallback: 'Aircall/callback_oauth',
    verify: 'Aircall/Verify',
    disconnect: 'Aircall/Disconnect',
  },
  analytics: {
    main: 'Analytics',
    sequence: {
      main: 'Analytics/Sequence',
      detail: 'Analytics/Sequence/Detail',
    },
    task: 'Analytics/Task',
    template: 'Analytics/Template',
    suggestion: 'Analytics/Suggestion',
    metrics: 'Analytics/Metrics',
    opportunity: 'Analytics/Opportunity',
  },
  applyStatusMigration: {
    main: 'ApplyStatusMigration',
  },
  applySequenceChanges: {
    active: 'ApplySequenceChanges/Active',
    allActive: 'ApplySequenceChanges/Active/All',
  },
  assistant: {
    main: 'Assistant',
    template: 'Assistant/Template',
    rewrite: 'Assistant/Rewrite',
    rewriteSequenceInstance: 'Assistant/SequenceInstance/Rewrite',
    revertRewrittenSequenceInstance: 'Assistant/SequenceInstance/RevertRewrite',
    icebreaker: 'Assistant/Icebreaker',
  },
  auth: {
    login: 'Auth/Login',
    logout: 'Auth/Logout',
    me: 'Auth/Me',
    refresh: 'Auth/Refresh',
    forbidden: 'Auth/forbidden',
    password: 'Auth/Password',
    sendResetPasswordEmail: 'Auth/SendResetPasswordEmail',
    resetPassword: 'Auth/ResetPassword',
  },
  call: {
    external: 'Call/External',
  },
  companyScrape: {
    main: 'CompanyScrape',
    search: 'CompanyScrape/search',
  },
  email: {
    saveSettings: 'Email/save_settings',
    send: 'Email/send',
    sendPreview: 'Email/send/preview',
    schedule: 'Email/schedule',
    scheduled: 'Email/scheduled',
    thread: {
      main: 'Email/Thread',
      recent: 'Email/Thread/Recent',
    },
    // scheduleProspect: "Email/schedule/prospect",
  },
  events: {
    main: 'Event',
    call: 'Event/Call',
    email: 'Event/Email',
    linkedin: 'Event/Linkedin',
    event: 'Event/Event',
    task: {
      main: 'Event/Task',
      email: 'Event/Task/Email',
      last: 'Event/Task/Last',
      invitation: 'Event/Task/Last/Invitation',
      conversation: 'Event/Task/Last/Conversation',
    },
    callReports: 'Event/Task/Report/Call',
    emailReports: 'Event/Task/Report/Email',
    linkedinReports: 'Event/Task/Report/Linkedin',
    check: {
      linkedin: 'Event/Check/Linkedin',
      linkedinCelebrations: 'Event/Check/Linkedin/Celebrations',
      linkedinPublicIds: 'Event/Check/Linkedin/PublicIds',
    },
    activities: {
      search: 'Event/Activities/Search',
    },
  },
  externalProperty: {
    main: 'ExternalProperty',
    option: 'ExternalProperty/option',
    usage: 'ExternalProperty/usage',
    crm: 'ExternalProperty/crm',
    status: {
      main: 'ExternalProperty/status',
      migrate: 'ExternalProperty/status/migrate',
    },
    sync: 'ExternalProperty/Sync',
  },
  google: {
    authCallback: 'Google/callback_oauth',
    verify: 'Google/Verify',
    disconnect: 'Google/Disconnect',
    signature: 'Google/Signature',
  },
  customEmailProvider: {
    connect: 'CustomEmailProvider/connect',
    verify: 'CustomEmailProvider/Verify',
    disconnect: 'CustomEmailProvider/Disconnect',
    test: {
      smtp: 'CustomEmailProvider/Test/Smtp',
      imap: 'CustomEmailProvider/Test/Imap',
    },
  },
  hubspot: {
    authCallback: 'Hubspot/callback_oauth',
    verify: 'Hubspot/Verify',
  },
  import: {
    main: 'Import',
    actives: 'Import/Actives',
  },
  linkedinOauth: {
    authCallback: 'Linkedin/callback_oauth',
    verify: 'Linkedin/Verify',
  },
  migration: {
    active: 'Migration/Active',
  },
  notes: {
    main: 'Note',
  },
  notifications: {
    main: 'Notification',
    feed: 'Notification/Feed',
    patchAll: 'Notification/All',
  },
  outlook: {
    authCallback: 'Outlook/callback_oauth',
    verify: 'Outlook/Verify',
    disconnect: 'Outlook/Disconnect',
  },
  opportunity: {
    main: 'Opportunity',
    search: 'Opportunity/search',
    disable: 'Opportunity/disable',
  },
  personalizedTemplate: {
    main: 'PersonalizedTemplate',
  },
  pipeline: {
    main: 'Pipeline',
    sync: 'Pipeline/Sync',
    stage: 'Pipeline/Stage',
  },
  prospect: {
    main: 'Prospect',
    multiple: 'Prospect/Multiple',
    search: {
      main: 'Prospect/search',
      export: 'Prospect/search/export',
      external: 'Prospect/search/external',
      strategy: 'Prospect/search/strategy',
    },
    exists: 'Prospect/exists',
    linkedin: {
      main: 'Prospect/Linkedin',
      multiple: 'Prospect/Linkedin/Multiple',
    },
    disable: 'Prospect/disable',
    disableMultiple: 'Prospect/disable/Multiple',
    invitationSent: 'Prospect/invitationSent',
    newConnectionSet: 'Prospect/newConnection/set',
    newLinkedinEventCheck: 'Prospect/newLinkedinEvent/check',
    import: 'Prospect/Import',
    importLeads: 'Prospect/Import/Leads',
    account: 'Prospect/Account',
    sync: 'Prospect/Sync',
  },
  salesforce: {
    saveToken: 'Salesforce/save_token',
    authCallback: 'Salesforce/callback_oauth',
    verify: 'Salesforce/Verify',
    image: 'services/images/photo', // Salesforce Url
  },
  sync: {
    main: 'Sync',
  },
  pipedrive: {
    authCallback: 'Pipedrive/callback_oauth',
    verify: 'Pipedrive/Verify',
  },
  sequence: {
    main: 'Sequence',
    prospect: 'Sequence/Prospect',
    prospectDelete: 'Sequence/Prospect/Delete',
    prospectDeleteMultiple: 'Sequence/Prospect/Delete/Multiple',
    prospectResumeMultiple: 'Sequence/Prospect/Resume/Multiple',
    account: 'Sequence/Account',
    prospectMultiple: 'Sequence/Prospect/Multiple',
    step: 'Sequence/Step',
    conditional: {
      main: 'Sequence/Step/Conditional',
      invitation: 'Sequence/Step/Conditional/Invitation',
    },
    search: 'Sequence/Search',
    strategy: 'Sequence/Strategy',
    apply: 'Sequence/Apply',
    duplicate: 'Sequence/duplicate',
    stop: 'Sequence/Stop',
    detail: 'Sequence/Strategy/Detail',
    stepTemplate: 'Sequence/Step/Template',
    restart: 'Sequence/Prospect/Restart',
    librarySearch: 'Sequence/Search/Library',
    libraryOne: 'Sequence/Library',
    libraryDuplicate: 'Sequence/Duplicate/Library',
  },
  scrape: {
    main: 'Scrape',
    verify: 'Scrape/Verify',
    find: 'Scrape/Find',
    verifyScrape: 'Scrape/find/verify',
    phone: 'Scrape/find/Phone',
    website: 'scrape/website',
    scrapeBulk: {
      main: 'scrape/find/bulk',
      active: 'scrape/find/bulk/active',
    },
  },
  status: {
    bob: 'api/Status/Bob',
  },
  task: {
    main: 'Task',
    search: {
      main: 'Task/search',
      linkedin: {
        next: 'Task/search/linkedin/next',
      },
    },
    aggregate: 'Task/aggregate',
    multiple: 'Task/Multiple',
    next: 'Task/search/next',
    sequence: 'Task/Sequence',
    overdue: 'Task/search/overdue',
    total: 'Task/total',
    multipleLinkedin: 'Task/Multiple/Linkedin',
    multipleInmail: 'Task/Multiple/Inmail',
    linkedin: {
      quantity: 'Task/Linkedin/Quantity',
      automatic: {
        last: 'Task/linkedin/automatic/last',
        check: 'Task/linkedin/automatic/check',
      },
    },
    dialerCampaign: 'Task/DialerCampaign',
    automationErrors: 'Task/WithAutomationError',
  },
  suggestion: {
    main: 'Suggestion',
    preferences: 'Suggestion/Preferences',
    search: 'Suggestion/search',
  },
  template: {
    main: 'Template',
    search: 'Template/search',
    attachment: {
      main: 'Template/Attachment',
      file: 'Template/Attachment/File',
    },
    usage: 'Template/usage',
  },
  templateVariables: {
    main: 'TemplateVariables',
    snippet: 'TemplateVariables/snippet',
  },
  file: {
    main: 'File',
  },
  subscription: {
    main: 'Subscription',
    users: {
      main: 'Subscription/Users',
      manager: 'Subscription/Users/Manager',
    },
    signup: {
      enable: 'Subscription/Signup/Enable',
      isEnabled: 'Subscription/Signup/isEnabled',
    },
    manager: 'Subscription/Manager',
    otherSettings: 'Subscription/OtherSettings/Manager',
    stripePortal: 'Subscription/create-portal-session',
    stripeCheckout: 'Subscription/create-checkout-session',
    products: {
      main: 'Subscription/Products',
      credits: 'Subscription/Products/Credits',
    },
    complete: 'Subscription/complete',
    startFreeTrial: 'Subscription/StartFreeTrial',
    site: {
      checkout: 'Subscription/Site/create-checkout-session',
    },
    credits: {
      checkout: 'Subscription/Credits/create-checkout-session',
    },
    migration: 'Subscription/Migration/Manager',
    multicustomer: {
      invite: {
        manager: 'Subscription/MultiCustomer/Invite/Manager',
      },
    },
  },
  user: {
    main: 'User',
    credits: 'User/credits',
    preferences: 'User/Preferences',
    onboarding: {
      integrations: 'User/onboarding/integrations',
      features: 'User/onboarding/features',
    },
    manager: 'User/Manager',
  },
};
