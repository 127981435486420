import { FC } from 'react';
import LinkedinLogo from 'assets/images/Icon_Linkedin_extension.svg';
import {
  useGetExtensionStatus,
  CronoExtensionStatus,
} from 'hooks/services/crono-extension/useGetExtensionStatus';

import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';

const LinkedinIntegration: FC = () => {
  const { data: extensionStatus } = useGetExtensionStatus();

  const openInstallLink = () => {
    const url = process.env.REACT_APP_CRONO_CHROME_EXTENSION_URL;
    if (url) {
      window.open(url);
    }
  };
  const connectLinkedinExtension = () => {
    if (
      extensionStatus?.status &&
      extensionStatus.status === CronoExtensionStatus.NOT_INSTALLED
    ) {
      openInstallLink();
    }
  };

  return (
    <>
      <div className="crm-connect-card">
        <div className="connect-card">
          <div className="providers">
            <div className="provider-box">
              <div className="icon-logo-container">
                <img
                  src={LinkedinLogo}
                  alt={'Linkedin'}
                  className={`connection-image linkedin`}
                />
              </div>
              <div className={`connection-label`}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'24px'}
                  color={colors.black}
                >
                  Chrome extension
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  lineHeight={'18px'}
                  color={
                    extensionStatus?.status ===
                    CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE
                      ? colors.darkGreen
                      : colors.inactiveHover
                  }
                >
                  {extensionStatus?.status ===
                    CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE &&
                    'Connected'}
                  {extensionStatus?.status ===
                    CronoExtensionStatus.INSTALLED_WITHOUT_LINKEDIN_COOKIE &&
                    'LinkedIn not open'}
                  {extensionStatus?.status ===
                    CronoExtensionStatus.NOT_CHROME_BASED_BROWSER &&
                    'Use Chrome to integrate'}
                  {extensionStatus?.status ===
                    CronoExtensionStatus.NOT_INSTALLED && 'Install LinkedIn'}
                </Typography>
              </div>
              <div
                className={`icons-buttons-container ${
                  extensionStatus?.status ===
                  CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE
                    ? 'connected'
                    : 'not-connected'
                }`}
              >
                {extensionStatus?.status ===
                CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE ? (
                  <div className="connection-status">
                    <Typography fontSize={'14px'} fontWeight={500}>
                      Installed
                    </Typography>
                    <CheckMarkIcon color={colors.callScheduled} />
                  </div>
                ) : (
                  <div
                    className="connection-status pointer"
                    onClick={() => connectLinkedinExtension()}
                  >
                    <Typography fontSize={'14px'} fontWeight={500}>
                      Install
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkedinIntegration;
