import { IconButton, CircularProgress, Typography } from '@mui/material';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { BlurBackgroundDivAbsolute } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import CalendarIcon from 'crono-fe-common/icons/Calendar-Icon';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { isSameDay } from 'date-fns';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { ScheduleWrapper } from '../styles';
import { colors } from 'crono-fe-common/theme';
import CronoTimePicker from 'crono-fe-common/components/CronoTimePicker';

interface IProps {
  handleSchedule: (dateTime?: Date | null) => void;
  close: () => void;
  isScheduleLoading: boolean;
}

const RescheduleEmail = ({
  handleSchedule,
  close,
  isScheduleLoading,
}: IProps) => {
  const [scheduleDate, setScheduleDate] = useState<Date | null>(new Date());
  const [scheduleTime, setScheduleTime] = useState<Date | null>(
    new Date(Date.now() + 5 * 60 * 1000),
  );
  const handleClickSubmit = () => {
    if (!scheduleDate || !scheduleTime) return;
    const scheduleDateTime = new Date(
      scheduleDate.getFullYear(),
      scheduleDate.getMonth(),
      scheduleDate.getDate(),
      scheduleTime.getHours(),
      scheduleTime.getMinutes(),
      scheduleTime.getSeconds(),
    );
    handleSchedule(scheduleDateTime);
  };
  const minTime = useMemo(() => {
    if (scheduleDate && isSameDay(scheduleDate, new Date())) {
      return new Date();
    } else {
      return undefined;
    }
  }, [scheduleDate]);
  return (
    <BlurBackgroundDivAbsolute>
      <ScheduleWrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <IconButton style={{ alignSelf: 'flex-end' }} onClick={close}>
            <CloseMIcon color={colors.grey11} />
          </IconButton>
          <Typography fontSize={24} fontWeight={700}>
            Select date and time
          </Typography>
          <FlexDiv height="fit-content" alignItems="start">
            <FlexDiv
              width="fit-content"
              height="fit-content"
              style={{ marginTop: 20, rowGap: 12 }}
              direction="column"
            >
              <FlexDiv justifyContent="start">
                <CalendarIcon />
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  style={{ marginLeft: 4 }}
                >
                  {moment(scheduleDate).format('DD/MM/YYYY')}
                </Typography>
              </FlexDiv>
              <FlexDiv justifyContent="start">
                <CronoTimePicker
                  disabled={isScheduleLoading}
                  value={scheduleTime}
                  handleChange={setScheduleTime}
                  className={'align-left'}
                  minTime={minTime}
                />
              </FlexDiv>
            </FlexDiv>
            <DateCalendar
              value={scheduleDate}
              onChange={(value) => setScheduleDate(value as Date)}
              disablePast
              disabled={isScheduleLoading}
            />
          </FlexDiv>

          {!isScheduleLoading ? (
            <FlexDiv width="fit-content" style={{ alignSelf: 'flex-end' }}>
              <CancelButton
                // variant="contained"
                // color="primary"
                className="mail-button"
                onClick={close}
              >
                Cancel
              </CancelButton>
              <MainButton
                // variant="contained"
                // color="primary"
                className="mail-button"
                onClick={handleClickSubmit}
                disabled={!scheduleDate || !scheduleTime}
              >
                Schedule
              </MainButton>
            </FlexDiv>
          ) : (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          )}
        </LocalizationProvider>
      </ScheduleWrapper>
    </BlurBackgroundDivAbsolute>
  );
};

export default RescheduleEmail;
