import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ScheduledEmail } from 'crono-fe-common/types/DTO/scheduledEmail';

export default function useGetScheduledEmail() {
  const getScheduledEmail = (ScheduledEmailId: number) => {
    const queryParams = queryString.stringify({ ScheduledEmailId });
    const request: Request = {
      url: `${ENDPOINTS.email.scheduled}?${queryParams}`,
      config: {
        method: 'GET',
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<ScheduledEmail>,
    CronoError,
    number
  >(getScheduledEmail, {});

  return { data, mutate, ...rest };
}
