import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { StrategySearch } from 'crono-fe-common/types/DTO/strategySearch';
import { Strategy } from 'crono-fe-common/types/strategy';

export default function useSearchStrategies(
  searchParameters: StrategySearch,
  enabled = true,
) {
  const request: Request = {
    url: ENDPOINTS.sequence.search,
    config: {
      method: 'post',
      data: { data: searchParameters },
    },
  };
  const queryString = JSON.stringify(searchParameters);
  const requestConfig = {
    queryKey: [ENDPOINTS.sequence.search, queryString],

    queryFn: () => client(request),
    enabled,
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
  };

  return useQuery<ResponsePaginated<Strategy[]>, CronoError>(requestConfig);
}
