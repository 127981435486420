import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const MobilePhoneIcon = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        id="path_1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46835 4.84013C7.93144 4.84013 7.49687 5.2747 7.49687 5.81162V18.1877C7.49687 18.7247 7.93144 19.1592 8.46835 19.1592H15.5404C16.0773 19.1592 16.5119 18.7247 16.5119 18.1877V5.81162C16.5119 5.2747 16.0773 4.84013 15.5404 4.84013H8.46835ZM5.90381 5.81162C5.90381 4.39488 7.05161 3.24707 8.46835 3.24707H15.5404C16.9572 3.24707 18.105 4.39488 18.105 5.81162V18.1877C18.105 19.6045 16.9572 20.7523 15.5404 20.7523H8.46835C7.05161 20.7523 5.90381 19.6045 5.90381 18.1877V5.81162Z"
        fill={color}
      />
      <path
        id="path_2"
        d="M15.5403 19.9556C16.5171 19.9556 17.3083 19.1644 17.3083 18.1876V18H6.7002V18.1876C6.7002 19.1644 7.49138 19.9556 8.46821 19.9556H15.5403Z"
        fill={color}
      />
      <path
        id="path_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.92348C6 4.86117 6.86866 4 7.94021 4H16.0598C17.1313 4 18 4.86117 18 5.92348V17.0765C18 18.1388 17.1313 19 16.0598 19H7.9402C6.86866 19 6 18.1388 6 17.0765V5.92348ZM7.94021 5.55172C7.73311 5.55172 7.56522 5.71817 7.56522 5.92348V17.0765C7.56522 17.2818 7.73311 17.4483 7.9402 17.4483H16.0598C16.2669 17.4483 16.4348 17.2818 16.4348 17.0765V5.92348C16.4348 5.71816 16.2669 5.55172 16.0598 5.55172H7.94021Z"
        fill={color}
      />
      <path
        id="path_4"
        d="M11.4639 19.0093H12.5257"
        stroke="white"
        strokeWidth="0.955834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobilePhoneIcon;
