import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinIconMessage = ({
  color = colors.black,
  className,
  onClick,
  style,
  viewBox = '0 0 16 16',
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08595 4.83295H7.12865V5.85045C7.4229 5.26525 8.1775 4.73945 9.31105 4.73945C11.2928 4.73945 11.8963 5.7083 11.9872 7.5C11.5 7.5 10 7.5 9.69749 7.5C9.55542 6.93211 9.25784 6.62485 8.75665 6.62485C7.7177 6.62485 7.28595 7.3646 7.28595 8.52785V12H5.08595V4.83295ZM1.3135 11.9065H3.5135V4.73945H1.3135V11.9065ZM3.82865 2.4025C3.82873 2.5869 3.79216 2.76948 3.72106 2.93962C3.64997 3.10977 3.54576 3.26408 3.4145 3.3936C3.14852 3.65794 2.7885 3.80591 2.4135 3.805C2.03916 3.80475 1.67997 3.65716 1.4136 3.39415C1.28282 3.26419 1.17896 3.1097 1.10799 2.93954C1.03702 2.76937 1.00032 2.58687 1 2.4025C1 2.03015 1.1485 1.67375 1.41415 1.41085C1.68029 1.14749 2.03963 0.999835 2.41405 1C2.78915 1 3.14885 1.14795 3.4145 1.41085C3.6796 1.67375 3.82865 2.03015 3.82865 2.4025Z"
        fill={color}
      />
      <path
        id="Path"
        d="M8.83398 9.89746V13.2223C8.83398 13.8053 9.30655 14.2779 9.88959 14.2779H14.112C14.6951 14.2779 15.1676 13.8053 15.1676 13.2223V9.89781"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1867 11.7157L14.7722 10.6408C15.0188 10.474 15.1663 10.1957 15.1663 9.89797V9.89797C15.1663 9.40184 14.7644 9 14.2687 9H9.73551C9.23973 9 8.83789 9.40184 8.83789 9.89762V9.89762C8.83789 10.1953 8.98532 10.4736 9.23199 10.6408L10.8175 11.7157C11.5329 12.2006 12.4713 12.2006 13.1867 11.7157V11.7157Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinIconMessage;
