import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import {
  AnalyticsWrapper,
  ShowFilterOptionsWrapper,
  StrategyContactsWrapper,
  TableHeaderStepsWrapper,
} from './style';
import { useJuneAnalytics } from 'context/june';
import {
  MainButton,
  RedButton,
  RemoveButton,
} from 'crono-fe-common/components/CronoButton';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import EmailRepliedIcon from 'crono-fe-common/icons/Icon-Email-Replied';
import LinkedinAcceptedInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Accepted-Invitation';
import IconEmailOpened from 'crono-fe-common/icons/Icon-Email-Opened';
import LinkedinRepliedMessageIcon from 'crono-fe-common/icons/Icon-Linkedin-Replied-Message';
import EmailBouncedIcon from 'crono-fe-common/icons/Icon-Email-Bounced-New';
import IconEmailClickedLink from 'crono-fe-common/icons/Icon-Email-Clicked-Link';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import ContactsIconS from 'crono-fe-common/icons/Icon-Contacts-S';
import { Sequence } from 'crono-fe-common/types/sequence';
import {
  Avatar,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import useGetStrategyDetail from 'hooks/services/sequence/useGetStrategyDetail';
import {
  SequenceDetailFilterType,
  SequenceFilterStatusType,
  ByType,
  OrderType,
} from 'crono-fe-common/types/DTO/strategyDetailSearch';
import {
  StrategyDetail,
  TaskSequenceInstance,
  SequenceInstanceEmailStatus,
  SequenceInstanceCallStatus,
  SequenceInstanceLinkedinStatus,
  SequenceInstanceDetail,
} from 'crono-fe-common/types/DTO/strategyDetail';
import {
  getImageFromUrl,
  prospectName,
  stringAvatarProspect,
} from 'utils/fe-utils';
import { Prospect } from 'crono-fe-common/types/prospect';
import moment from 'moment';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import CloseSIcon from 'crono-fe-common/icons/Icon-Close-S';
import { ReactComponent as NoContactsInStrategy } from 'assets/images/No_results_strategy_contacts.svg';
import { ReactComponent as NoContactsFound } from 'assets/images/No_contacts_found.svg';
import { ReactComponent as NotOwnedIcon } from 'crono-fe-common/icons/Not-owned-by-the-user.svg';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import { ReactComponent as IndeterminateIcon } from 'crono-fe-common/icons/IndeterminateCheck.svg';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import Pagination from 'pages/dashboard/pagination';
import { Constants } from 'crono-fe-common/constants/constants';
import Role from 'crono-fe-common/types/enums/role';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import LinkedinIconInMailReplied from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Replied';
import { LinkedinType } from 'crono-fe-common/types/logLinkedin';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import { useAuth } from 'context/auth';
import { useSequenceContext } from 'context/strategyDetail/context/sequence';
import { BlurStrategyModal } from '../style';
import { showSequenceStatusDTO } from 'crono-fe-common/types/enums/prospectSequenceStatus';
import StrategyContactsModalSelectionComponent from './strategyContactsModalSelectionComponent';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';
import useGetSequence from 'hooks/services/sequence/useGetSequence';

const analyticsOptions: {
  fieldName: keyof Sequence;
  text: string;
  numberColor: string;
  percentage?: boolean;
  filterStatus: SequenceFilterStatusType;
}[] = [
  {
    fieldName: 'useCount',
    text: 'Total',
    numberColor: colors.black,
    percentage: false,
    filterStatus: SequenceFilterStatusType.ALL,
  },
  {
    fieldName: 'activeCount',
    text: 'Active',
    numberColor: colors.callScheduled,
    percentage: true,
    filterStatus: SequenceFilterStatusType.ACTIVE,
  },
  {
    fieldName: 'stoppedCount',
    text: 'Stopped',
    numberColor: colors.nurture,
    percentage: true,
    filterStatus: SequenceFilterStatusType.STOPPED,
  },
  {
    fieldName: 'completedCount',
    text: 'Finished',
    numberColor: colors.blue,
    percentage: true,
    filterStatus: SequenceFilterStatusType.FINISHED,
  },
  {
    fieldName: 'answerCount',
    text: 'Replied',
    numberColor: colors.purple,
    percentage: true,
    filterStatus: SequenceFilterStatusType.CONVERTED,
  },
];

const sortByOptions: {
  text: string;
  sort: ByType;
}[] = [
  {
    text: 'Contacts',
    sort: ByType.CONTACTS,
  },
  {
    text: 'Progress',
    sort: ByType.PROGRESS,
  },
  {
    text: 'Last activity',
    sort: ByType.LAST_ACTIVITY,
  },
  {
    text: 'Last 10 days interaction',
    sort: ByType.LAST_INTERACTION,
  },
];

const showFilterOptions: {
  text: string;
  filter: SequenceDetailFilterType;
}[] = [
  {
    text: 'All',
    filter: SequenceDetailFilterType.ALL,
  },
  {
    text: 'Delivered emails',
    filter: SequenceDetailFilterType.DELIVERED_EMAILS,
  },
  {
    text: 'Opened emails',
    filter: SequenceDetailFilterType.OPENED_EMAILS,
  },
  {
    text: 'Replied emails',
    filter: SequenceDetailFilterType.REPLIED_EMAILS,
  },
  {
    text: 'Bounced emails',
    filter: SequenceDetailFilterType.BOUNCED_EMAILS,
  },
  {
    text: 'Clicked email links',
    filter: SequenceDetailFilterType.CLICKED_EMAILS_LINK,
  },
  {
    text: 'Replied LinkedIn messages',
    filter: SequenceDetailFilterType.REPLIED_LINKEDIN_MESSAGE,
  },
  {
    text: 'Accepted LinkedIn invitation',
    filter: SequenceDetailFilterType.ACCEPTED_LINKEDIN_INVITATION,
  },
];
interface IProps {
  sequenceId: number;
  initialFilter: SequenceFilterStatusType;
  handleClickOnBackground: () => void;
}

const StrategyContactsModal = ({
  sequenceId,
  initialFilter,
  handleClickOnBackground,
}: IProps) => {
  const { user } = useAuth();
  const { openWindow } = useWindow();

  const analytics = useJuneAnalytics();

  const [sortsBy, setSortsBy] = useState<ByType>(ByType.LAST_ACTIVITY);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.DESC);
  const [selectedShowFilters, setSelectedShowFilters] = useState<
    SequenceDetailFilterType[]
  >([SequenceDetailFilterType.ALL]);
  const [filterStatus, setFilterStatus] =
    useState<SequenceFilterStatusType>(initialFilter);
  const [offset, setOffset] = useState<number>(0);
  const [sharedSequences, setSharedSequences] = useState<boolean>(false);
  const [sortByStep, setSortByStep] = useState<number | null>(null);

  const resetFilters = () => {
    setSortsBy(ByType.LAST_ACTIVITY);
    setOrderType(OrderType.DESC);
    setSelectedShowFilters([SequenceDetailFilterType.ALL]);
    setFilterStatus(SequenceFilterStatusType.ALL);
    setOffset(0);
    setSharedSequences(false);
    setSortByStep(null);
  };

  const resetOnFilterStatusChange = () => {
    setSortsBy(ByType.LAST_ACTIVITY);
    setOrderType(OrderType.DESC);
    setSelectedShowFilters([SequenceDetailFilterType.ALL]);
    setOffset(0);
    setSharedSequences(false);
    setSortByStep(null);
  };

  useEffect(() => {
    if (user && !user?.userRoles.includes(Role.MANAGER)) {
      setSharedSequences(true);
    }
  }, [user]);

  const [selected, setSelected] = useState<number[]>([]);

  // const { sequence: originalSequence } = useSequenceContext();

  const { data: sequence } = useGetSequence(sequenceId);
  const originalSequence = sequence?.data?.data;

  const { data: strategyDetail, isFetching: fetchingStrategyDetail } =
    useGetStrategyDetail({
      strategyId: sequenceId,
      sequencesStatus: filterStatus,
      sortsBy: [
        {
          by: sortsBy,
          order: orderType,
        },
      ],
      show: {
        onStep: sortByStep,
        filters: selectedShowFilters,
      },
      limit: Constants.defaultLimit,
      offset: offset,
      onlyMyContacts: sharedSequences,
    });

  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();

  const handleOpenProspectDetail = (accountId: string, prospectId: string) => {
    setSelectedAccountId(accountId);
    setSelectedProspectId(prospectId);
    openWindow({
      windowType: 'account',
      tab: 'prospect',
      selectedProspectId: prospectId ?? undefined,
    });
  };

  const [filterTooltipToggle, setFilterTooltipToggle] =
    useState<boolean>(false);

  const renderSequenceSteps = (detail: StrategyDetail) => {
    const positions: number[] = [];
    for (let i = 1; i < detail.maxTotalStep + 1; i++) {
      positions.push(i);
    }

    const steps = positions.map((step) => {
      let contacts = 0;
      if (
        detail.sequenceSteps &&
        detail.sequenceSteps.find((n) => n.number === step)
      ) {
        contacts =
          detail.sequenceSteps.find((n) => n.number === step)
            ?.prospectsOnStep ?? 0;
      }

      const hasUsers = contacts > 0;
      const selected = step === sortByStep;
      return (
        <TableHeaderStepsWrapper
          hasUsers={hasUsers}
          key={step}
          selected={selected}
        >
          <Tooltip placement="top" arrow title="Click to filter by this step">
            <Typography
              fontSize={14}
              fontWeight={500}
              color={selected ? colors.darkGreen : colors.grey1}
              onClick={() => {
                setSortByStep((prev) => {
                  return prev === step ? null : step;
                });
                setOffset(0);
              }}
            >
              Step {step}
            </Typography>
          </Tooltip>
          <Tooltip
            placement="top"
            arrow
            title="Contacts with this as last step"
          >
            <div className="users-on-step">
              <ContactsIconS
                color={hasUsers ? colors.prospectingHover : colors.grey1}
              />
              <Typography
                fontSize={12}
                fontWeight={500}
                color={hasUsers ? colors.prospectingHover : colors.grey1}
              >
                {contacts}
              </Typography>
            </div>
          </Tooltip>
        </TableHeaderStepsWrapper>
      );
    });
    return steps;
  };

  /**
   * Email can have upto 3 icons at once
   * Email clicks, email replies, email opened have counters
   * Common to all -> skipped
   * InMail and VoiceNote are similar to Message
   */
  const renderStepStatus = (
    task: TaskSequenceInstance,
    resultType?: 'linkClicked' | 'replied' | 'opened',
  ) => {
    let icon = null;
    let status = '';
    let bgColor = '';

    if (task.skipped) {
      icon = <CloseSIcon color={colors.grey2} />;
      status = 'Skipped';
      bgColor = colors.grey6;
    } else {
      bgColor = colors.grey4;
      switch (task.type) {
        case TaskTodoType.EMAIL:
          if (task.email) {
            switch (task.email.result) {
              case SequenceInstanceEmailStatus.UNKNOWN:
                icon = (
                  <span className="notification-icon">
                    <EmailIcon
                      className="icon-size"
                      color={colors.greyIconCompletedTask}
                    />
                  </span>
                );
                break;
              case SequenceInstanceEmailStatus.BOUNCED:
                icon = (
                  <span className="notification-icon">
                    <EmailBouncedIcon
                      className="icon-size"
                      color={colors.inactiveHover}
                    />
                  </span>
                );
                status = 'Bounced';
                bgColor = colors.inactiveLight;
                break;
              case SequenceInstanceEmailStatus.NOT_OPENED:
                icon = (
                  <span className="notification-icon">
                    <IconEmailOpened
                      className="icon-size"
                      color={colors.greyIconCompletedTask}
                    />
                  </span>
                );
                status = 'Not Opened';
                break;
              // OPENED : opened must be > 0, replied can be optional, linkClicked can be optional
              // REPLIED: opened must be > 0, replied must be > 0, linkClicked can be optional
              case SequenceInstanceEmailStatus.OPENED:
              case SequenceInstanceEmailStatus.REPLIED:
                switch (resultType) {
                  case 'linkClicked':
                    icon = (
                      <span className="notification-icon">
                        <IconEmailClickedLink
                          className="icon-size"
                          color={colors.customerHover}
                          fill={colors.customerLight}
                        />
                      </span>
                    );
                    status = task.email.linkClicked + '';
                    bgColor = colors.customerLight;
                    break;
                  case 'replied':
                    icon = (
                      <span className="notification-icon">
                        <EmailRepliedIcon
                          className="icon-size"
                          color={colors.lightGreen}
                        />
                      </span>
                    );
                    status = task.email.replied + '';
                    bgColor = colors.repliedEmailLightGreen;
                    break;
                  case 'opened':
                    icon = (
                      <span className="notification-icon">
                        <IconEmailOpened
                          className="icon-size"
                          color={colors.intenseLightBlue}
                        />
                      </span>
                    );
                    status = task.email.opened + '';
                    bgColor = colors.primaryLight;
                    break;
                }
                break;
            }
          }
          break;
        case TaskTodoType.CALL:
          if (task.call) {
            // Default for GATEKEEPER, NOT_ANSWERED, WRONG_NUMBER and VOICEMAIL
            icon = (
              <span className="notification-icon">
                <CallIcon
                  className="icon-size"
                  color={colors.greyIconCompletedTask}
                />
              </span>
            );
            switch (task.call.result) {
              case SequenceInstanceCallStatus.UNKNOWN:
                status = '';
                break;
              case SequenceInstanceCallStatus.GATEKEEPER:
                status = 'Gatekeeper';
                break;
              case SequenceInstanceCallStatus.NOT_ANSWERED:
                status = 'Not Answered';
                break;
              case SequenceInstanceCallStatus.WRONG_NUMBER:
                status = 'Wrong Number';
                break;
              case SequenceInstanceCallStatus.VOICEMAIL:
                status = SequenceInstanceCallStatus.VOICEMAIL;
                break;
              case SequenceInstanceCallStatus.CONNECTED:
                icon = (
                  <span className="notification-icon">
                    <CallIcon className="icon-size" color={colors.lightGreen} />
                  </span>
                );
                status = SequenceInstanceCallStatus.CONNECTED;
                bgColor = colors.primaryLight;
                break;
            }
          }
          break;
        case TaskTodoType.LINKEDIN:
          if (task.linkedin) {
            // Default for NO_REPLY, PENDING, DECLINED and UNKNOWN
            icon = (
              <span className="notification-icon">
                <LinkedinRepliedMessageIcon
                  className="icon-size"
                  color={colors.greyIconCompletedTask}
                />
              </span>
            );
            if (task.linkedin.type === LinkedinType.Invitation) {
              icon = (
                <span className="notification-icon">
                  <LinkedinInvitationIcon
                    className="icon-size"
                    color={colors.greyIconCompletedTask}
                  />
                </span>
              );
            }
            switch (task.linkedin.result) {
              case SequenceInstanceLinkedinStatus.NO_REPLY:
                status = 'No reply';
                break;
              case SequenceInstanceLinkedinStatus.PENDING:
                status = 'Pending';
                break;
              case SequenceInstanceLinkedinStatus.DECLINED:
                // not possible to parse this from BE
                status = 'Declined';
                break;
              case SequenceInstanceLinkedinStatus.UNKNOWN:
                status = 'Unknown';
                break;
              case SequenceInstanceLinkedinStatus.ACCEPTED:
                icon = (
                  <span className="notification-icon">
                    <LinkedinAcceptedInvitationIcon className="icon-size" />
                  </span>
                );
                status = 'Accepted';
                bgColor = colors.nurtureLight;
                break;
              case SequenceInstanceLinkedinStatus.CONNECTED:
                icon = (
                  <span className="notification-icon">
                    <LinkedinAcceptedInvitationIcon
                      className="icon-size"
                      color={colors.greyIconCompletedTask}
                    />
                  </span>
                );
                status = 'Connected';
                break;
              case SequenceInstanceLinkedinStatus.REPLIED:
                icon = (
                  <span className="notification-icon">
                    <LinkedinRepliedMessageIcon
                      className="icon-size"
                      color={colors.prospectingHover}
                    />
                  </span>
                );
                status = 'Replied';
                bgColor = colors.linkedinRepliedMessageLightBlue;
                break;
            }
          }
          break;
        case TaskTodoType.IN_MAIL:
          // Default
          let color = colors.grey2;
          if (task.linkedin) {
            switch (task.linkedin.result) {
              case SequenceInstanceLinkedinStatus.NO_REPLY:
                status = 'No reply';
                bgColor = colors.grey6;
                break;
              case SequenceInstanceLinkedinStatus.REPLIED:
                status = 'Replied';
                bgColor = colors.purpleLight;
                color = colors.purpleHover;
                break;
            }
            icon = (
              <span className="notification-icon">
                <LinkedinIconInMailReplied
                  viewBox="-1 -1 16 16"
                  className="icon-size"
                  color={color}
                  color2={bgColor}
                />
              </span>
            );
          }
          break;
        default:
          break;
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <span className="type-icon" style={{ backgroundColor: bgColor }}>
          {icon}
        </span>
        <Typography fontSize={12} fontWeight={400}>
          {status}
        </Typography>
      </div>
    );
  };

  const renderTasks = (
    instance: SequenceInstanceDetail,
    rowIndex: number,
    rowLength: number,
    maxSteps: number,
    sortByStep?: number | null,
  ) => {
    const emptyTasksPlaceholder = (
      <div className="table-row-step-info empty-placeholder"></div>
    );

    const actualTasks = instance.tasks.map(
      (task: TaskSequenceInstance, index) => {
        const stepSortSelected = task.number === sortByStep;

        return (
          <div
            className={`table-row-step-info ${
              stepSortSelected ? 'filtered-by-step' : ''
            }`}
            key={task.id}
            style={{
              opacity: sortByStep ? (stepSortSelected ? '1' : '0.6') : '1',
              borderTop:
                stepSortSelected && rowIndex === 0
                  ? `1px solid ${colors.primary}`
                  : '',
              borderBottom:
                stepSortSelected && rowIndex === rowLength - 1
                  ? `1px solid ${colors.primary}`
                  : '',
            }}
          >
            <Typography fontSize={12} fontWeight={400} color={colors.grey1}>
              {moment(task.activityDate).format('ll')}
            </Typography>
            <div style={{ display: 'flex', gap: '4px' }}>
              {task.type === TaskTodoType.EMAIL &&
              (task.email?.result === SequenceInstanceEmailStatus.OPENED ||
                task.email?.result === SequenceInstanceEmailStatus.REPLIED) ? (
                <>
                  {task.email.opened > 0
                    ? renderStepStatus(task, 'opened')
                    : null}
                  {task.email.linkClicked > 0
                    ? renderStepStatus(task, 'linkClicked')
                    : null}
                  {task.email.replied > 0
                    ? renderStepStatus(task, 'replied')
                    : null}
                </>
              ) : (
                <>{renderStepStatus(task)}</>
              )}
            </div>
          </div>
        );
      },
    );
    // This is done since there is no grid for table content (empty)
    const minimumTasksLength =
      maxSteps - actualTasks.length > -1 ? maxSteps - actualTasks.length : 0;
    const placeholderTasks = new Array(minimumTasksLength);
    placeholderTasks.fill(emptyTasksPlaceholder, 0, placeholderTasks.length);

    return [...actualTasks, placeholderTasks];
  };

  const renderSequenceInstances = (detail: StrategyDetail) => {
    if (detail?.sequenceInstances) {
      const rowLength = detail.sequenceInstances.length;
      const instances = detail.sequenceInstances.map((instance, index) => {
        return (
          <div
            className="table-row"
            key={instance.id}
            style={{
              width: '100%',
              minWidth: 'max-content',
              opacity: instance.prospect.isActive ? '1' : '0.6',
            }}
          >
            <div
              className="table-row-prospect-info"
              onClick={() => {
                if (
                  instance?.account?.objectId &&
                  instance?.prospect?.objectId
                ) {
                  handleOpenProspectDetail(
                    instance?.account?.objectId,
                    instance.prospect.objectId,
                  );
                }
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                  overflow: 'hidden',
                }}
              >
                <Tooltip
                  arrow
                  enterDelay={400}
                  enterNextDelay={400}
                  title={
                    !instance.prospect.owned ? (
                      <span style={{ display: 'flex', textAlign: 'center' }}>
                        Strategy changes can be <br />
                        made only to owned contacts
                      </span>
                    ) : null
                  }
                >
                  <div>
                    <CronoCheckbox
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelected((prev) => {
                          if (instance.id) {
                            if (prev.includes(instance.id)) {
                              return prev.filter((id) => id !== instance.id);
                            } else {
                              return [...prev, instance.id];
                            }
                          }
                          return prev;
                        });
                      }}
                      style={{
                        marginRight: '16px',
                      }}
                      checked={
                        instance.prospect.objectId
                          ? selected.includes(instance.id)
                          : false
                      }
                      disabled={!instance.prospect.owned}
                    />
                  </div>
                </Tooltip>
                <Avatar
                  key={instance.prospect.objectId}
                  src={
                    instance.account?.website &&
                    getImageFromUrl(instance.account.website, null)
                  }
                  className="prospect-avatar-row"
                >
                  {stringAvatarProspect(instance.prospect as Prospect)}
                </Avatar>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Tooltip
                      title={prospectName(instance.prospect as Prospect)}
                      placement="top"
                      arrow
                      enterDelay={800}
                      enterNextDelay={800}
                    >
                      <Typography fontSize={14} fontWeight={500} noWrap>
                        {prospectName(instance.prospect as Prospect)}
                      </Typography>
                    </Tooltip>
                    {!instance.prospect.isActive && (
                      <Typography
                        fontSize={10}
                        fontWeight={500}
                        color={colors.inactiveHover}
                      >
                        Deleted
                      </Typography>
                    )}
                  </div>
                  <Tooltip
                    title={instance.prospect?.title}
                    placement="top"
                    arrow
                    enterDelay={800}
                    enterNextDelay={800}
                  >
                    <Typography fontSize={12} fontWeight={400} noWrap>
                      {instance.prospect?.title}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                {!instance.prospect.owned && (
                  <div style={{ display: 'flex' }}>
                    <Tooltip
                      arrow
                      title={`The owner is ${instance.prospect.user}`}
                      placement="top"
                    >
                      <NotOwnedIcon className="not-owned-icon" />
                    </Tooltip>
                  </div>
                )}
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  className={
                    'prospect-status ' + instance.status.toLocaleLowerCase()
                  }
                  noWrap
                >
                  {showSequenceStatusDTO(instance.status)}
                </Typography>
              </div>
            </div>
            <div className="row-container">
              {renderTasks(
                instance,
                index,
                rowLength,
                detail.maxTotalStep,
                sortByStep,
              )}
            </div>
          </div>
        );
      });

      return instances;
    }
  };

  // ALL is exclusive, the rest can all be cumulative)
  const handleShowFilters = (filter: SequenceDetailFilterType) => {
    setSelectedShowFilters((prev) => {
      // Selected ALL filter, if it was not previously selected
      if (
        filter === SequenceDetailFilterType.ALL &&
        !prev.includes(SequenceDetailFilterType.ALL)
      ) {
        return [SequenceDetailFilterType.ALL];
      }
      // This sets ALL as default filter
      if (
        filter !== SequenceDetailFilterType.ALL &&
        prev.length === 1 &&
        prev[0] === filter
      ) {
        return [SequenceDetailFilterType.ALL];
      }

      // If ALL already existed in the filters, remove it and add the new filter
      if (prev.includes(SequenceDetailFilterType.ALL)) {
        return [filter];
      } else {
        // If ALL was not existed in the filters,
        if (prev.includes(filter)) {
          // remove the previous filter if it was already selected
          return [...prev].filter((op) => op !== filter);
        } else {
          // add the filter
          return [...prev, filter];
        }
      }
    });
    setOffset(0);
  };

  const handleAnalyticsFilter = (filterStatus: SequenceFilterStatusType) => {
    setFilterStatus(filterStatus);
    resetOnFilterStatusChange();
    if (analytics) {
      analytics.track('strategy-analytics', {
        selectedFilter: filterStatus,
      });
    }
  };

  const showFilter = (
    <ShowFilterOptionsWrapper>
      {showFilterOptions.map((option) => {
        return (
          <div
            key={option.filter}
            className="show-filter-option"
            onClick={() => handleShowFilters(option.filter)}
          >
            <label>
              <Checkbox
                icon={<IconUnchecked />}
                checkedIcon={<CheckedIcon />}
                indeterminateIcon={<IndeterminateIcon />}
                checked={selectedShowFilters.includes(option.filter)}
                sx={{
                  padding: 'inherit',
                  margin: 'inherit',
                  '&:hover': {
                    backgroundColor: colors.grey4,
                  },
                  '&.Mui-checked': {
                    '&:hover': {
                      opacity: 0.7,
                    },
                  },
                  marginRight: 1,
                }}
              />
            </label>
            <Typography fontSize={12} fontWeight={500}>
              {option.text}
            </Typography>
          </div>
        );
      })}
    </ShowFilterOptionsWrapper>
  );

  const selectableContacts = useMemo(() => {
    if (strategyDetail?.data?.data) {
      return (
        strategyDetail?.data?.data.sequenceInstances?.filter(
          (instance) => instance.prospect.owned,
        ) ?? []
      );
    } else {
      return [];
    }
  }, [strategyDetail?.data?.data]);

  const renderTableHeader = (detail: StrategyDetail) => {
    return (
      <div className="table-header">
        <div
          className="filter-steps-container"
          style={{
            width: '100%',
            minWidth: 'max-content',
            height: '100%',
          }}
        >
          <div className="table-header-filter">
            <CronoCheckbox
              checked={
                selectableContacts.length > 0 &&
                selected.length === selectableContacts.length
              }
              onClick={() => {
                if (selected.length === selectableContacts.length) {
                  setSelected([]);
                } else {
                  setSelected(
                    selectableContacts.map((instance) => instance.id),
                  );
                }
              }}
              indeterminate={
                selected.length > 0 &&
                selected.length < (selectableContacts.length ?? 0)
              }
            />
            {/* Sort by options filter */}
            <Typography fontSize={14}>Sort by</Typography>
            <FormControl
              sx={{ minWidth: 120, maxWidth: 120, mt: 1, mb: 1 }}
              size="small"
            >
              <Select
                labelId="select-medium"
                id="select-medium"
                value={sortsBy}
                onChange={(e) => {
                  if ((e.target.value as ByType) === ByType.CONTACTS) {
                    setOrderType(OrderType.ASC);
                  } else {
                    setOrderType(OrderType.DESC);
                  }
                  setSortsBy(e.target.value as ByType);
                  setOffset(0);
                }}
                sx={{
                  boxShadow: 'none',
                  fontSize: '12px',
                  height: '32px',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${colors.grey4}`,
                    borderRadius: 2,
                  },
                  padding: '2px',
                }}
                MenuProps={{
                  disablePortal: true,
                  style: { zIndex: 3501, left: 25, top: 5 },
                  PaperProps: {
                    sx: {
                      left: '50px',
                      fontSize: '14px',
                      borderRadius: '8px',
                      paddingInline: '6px',
                      border: '1px solid #dadde9',
                      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                      '& .MuiMenuItem-root': {
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '16px',
                        height: 'fit-content',
                        padding: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        borderRadius: '8px',
                        '&:hover': {
                          background: colors.primaryLight,
                          color: colors.primary,
                        },
                      },
                      '& .Mui-selected': {
                        color: colors.primary,
                        backgroundColor: 'transparent !important',
                      },
                    },
                  },
                }}
              >
                {sortByOptions.map((option) => {
                  return (
                    <MenuItem key={option.sort} value={option.sort}>
                      {option.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* Show options filter */}
            <Typography fontSize={14}>Show</Typography>
            <ClickAwayListener
              onClickAway={() => setFilterTooltipToggle(false)}
            >
              <FormControl size="small">
                <CustomOptionsTooltip
                  open={filterTooltipToggle}
                  title={showFilter}
                  left={61}
                  maxHeight={'255px'}
                  width={'100%'}
                >
                  {selectedShowFilters?.length === 1 ? (
                    <div
                      className="show-filter-container"
                      style={{
                        backgroundColor:
                          selectedShowFilters[0] ===
                          SequenceDetailFilterType.ALL
                            ? colors.white
                            : '',
                      }}
                      onClick={() =>
                        setFilterTooltipToggle(!filterTooltipToggle)
                      }
                    >
                      <Typography fontSize={'12px'} style={{ flex: 1 }} noWrap>
                        {
                          showFilterOptions.filter(
                            (option) =>
                              option.filter === selectedShowFilters[0],
                          )[0].text
                        }
                      </Typography>

                      {filterTooltipToggle ? (
                        <TopArrowIcon className="tag-arrow" />
                      ) : (
                        <BottomArrowIcon className="tag-arrow" />
                      )}
                    </div>
                  ) : (
                    <div
                      className="show-filter-container"
                      onClick={() =>
                        setFilterTooltipToggle(!filterTooltipToggle)
                      }
                    >
                      <Typography fontSize={'12px'} style={{ flex: 1 }} noWrap>
                        {selectedShowFilters.length} selected
                      </Typography>
                      <CloseMIcon
                        className="remove-text-icon"
                        color={colors.grey11}
                        onClick={() => {
                          setSelectedShowFilters([
                            SequenceDetailFilterType.ALL,
                          ]);
                          setOffset(0);
                        }}
                      />
                    </div>
                  )}
                </CustomOptionsTooltip>
              </FormControl>
            </ClickAwayListener>
          </div>
          <div className="table-header-steps">
            {renderSequenceSteps(detail)}
          </div>
        </div>
      </div>
    );
  };

  const contacts = useMemo(() => {
    if (strategyDetail?.data?.data) {
      return renderSequenceInstances(strategyDetail?.data?.data);
    } else {
      return [];
    }
  }, [strategyDetail?.data?.data, sortByStep, selected]);

  useEffect(() => {
    setSelected([]);
  }, [strategyDetail?.data?.data]);

  const noAssignedContacts = (
    <div className="no-results" style={{ height: 'calc(100% - 100px)' }}>
      <NoContactsInStrategy />
      <div className="no-contacts-in-strategy">
        <Typography fontSize={16} fontWeight={600}>
          There are no added contacts in the strategy
        </Typography>
        <Typography fontSize={14} color={colors.grey11}>
          Place contacts in a strategy to see information about the activity of
          each contact
        </Typography>
      </div>
    </div>
  );

  const noContactsFound = (
    <div className="no-results">
      <NoContactsFound />
      <div className="no-contacts-found">
        <Typography fontSize={16} fontWeight={600}>
          Sorry, we didn't find any results.
        </Typography>
        <Typography fontSize={16} fontWeight={600}>
          Please check filters.
        </Typography>
      </div>
      {/* render only other than ALL filter was applied or only my contacts switch is ON */}
      {/* {filterStatus === SequenceFilterStatusType.ALL || sharedSequences ? ( */}
      <MainButton
        startIcon={<TrashCanDeleteIcon color={colors.white} />}
        onClick={resetFilters}
      >
        Clear Filters
      </MainButton>
      {/* ) : null} */}
    </div>
  );

  return (
    <>
      <BlurStrategyModal onClick={handleClickOnBackground} />
      <StrategyContactsWrapper>
        {/* Analytics container */}
        <div className="strategy-container">
          {originalSequence && (
            <div className="strategy-right-container">
              <div className="analitycs-div">
                {analyticsOptions.map((analytic, index) => {
                  if (originalSequence) {
                    return (
                      <React.Fragment key={analytic.fieldName}>
                        <AnalyticsWrapper
                          selected={filterStatus === analytic.filterStatus}
                          key={index}
                          onClick={() =>
                            handleAnalyticsFilter(analytic.filterStatus)
                          }
                          style={{
                            backgroundColor:
                              filterStatus === analytic.filterStatus
                                ? colors.primaryLight
                                : colors.white,
                          }}
                        >
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            lineHeight={'18px'}
                            color={
                              filterStatus === analytic.filterStatus
                                ? colors.darkGreen
                                : colors.grey11
                            }
                            noWrap
                          >
                            {analytic.text}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            lineHeight={'24px'}
                            color={
                              filterStatus === analytic.filterStatus
                                ? colors.darkGreen
                                : analytic.numberColor
                            }
                            noWrap
                          >
                            {(originalSequence[analytic.fieldName] as string) ??
                              0}{' '}
                            {analytic.percentage &&
                              '(' +
                                Math.round(
                                  ((originalSequence[
                                    analytic.fieldName
                                  ] as number) /
                                    ((originalSequence.useCount as number) ||
                                      1)) *
                                    100,
                                ) +
                                '%)'}
                          </Typography>
                        </AnalyticsWrapper>

                        {index < analyticsOptions.length - 1 ? (
                          <div className="divider" />
                        ) : null}
                      </React.Fragment>
                    );
                  }
                })}
              </div>
              {user?.userRoles.includes(Role.MANAGER) &&
                originalSequence.shared && (
                  <div className="shared-switch">
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      color={colors.grey11}
                      paddingLeft={'32px'}
                    >
                      Only my contacts
                    </Typography>
                    <CronoSwitch
                      disabled={
                        !strategyDetail?.data?.total && !sharedSequences
                      }
                      checked={sharedSequences}
                      onChange={() => {
                        if (!user?.userRoles.includes(Role.MANAGER)) {
                          setSharedSequences(true);
                        } else {
                          setSharedSequences(!sharedSequences);
                        }
                        setOffset(0);
                      }}
                    />
                  </div>
                )}
            </div>
          )}
        </div>

        {/*
        Detail table
        No result view changes depending if request is only ALL filter or more
      */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {fetchingStrategyDetail ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : originalSequence?.useCount && originalSequence?.useCount > 0 ? (
            strategyDetail?.data?.data && (
              <div className="table-container">
                {renderTableHeader(strategyDetail.data.data)}
                <div
                  className="table-content"
                  style={{
                    width: '100%',
                    minWidth: 'max-content',
                  }}
                >
                  {strategyDetail?.data?.data && contacts?.length ? (
                    contacts
                  ) : (
                    <div
                      style={{
                        height: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        position: 'absolute',
                      }}
                    >
                      {noContactsFound}
                    </div>
                  )}
                </div>
              </div>
            )
          ) : (
            noAssignedContacts
          )}

          {strategyDetail?.data?.total ? (
            <Pagination
              count={strategyDetail?.data?.count}
              total={strategyDetail?.data?.total}
              itemOffset={offset}
              setItemOffset={setOffset}
              limit={Constants.defaultLimit}
              selectionComponent={
                <StrategyContactsModalSelectionComponent
                  selected={selected}
                  clearSelected={() => {
                    setSelected([]);
                  }}
                />
              }
              style={{
                height: 48,
              }}
            />
          ) : null}
        </div>
      </StrategyContactsWrapper>
    </>
  );
};

export default StrategyContactsModal;
