import React, { useCallback, useEffect, useState } from 'react';
import { SearchComponentWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import CompanySearchTab from './searchCompanyTab';
import ContactSearchTab from './searchContactTab';
import { Account } from 'crono-fe-common/types/account';
import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';
import SearchContactTabProvider from './searchContactTab/searchContactTabContext';
import SearchCompanyTabProvider from './searchCompanyTab/searchCompanyTabContext';
import { AccountTabsContainer } from 'pages/accountTab/tab/style';
import AccountTabTab from 'pages/accountTab/tab';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CollapseIcon } from 'crono-fe-common/icons/Collapse.svg';

interface IProps {
  initialTab?: 'company' | 'contact';
  initialCompany?: Account;
}

const SearchComponent = ({ initialTab, initialCompany }: IProps) => {
  const [isCompany, setIsCompany] = useState<boolean>(
    initialTab === undefined ? false : initialTab === 'company' ? true : false,
  );
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      //To clear the state when we leave this page, so that the next times we are not going to be redirected here
      navigate(location.pathname, { state: undefined });
    };
  }, []);

  const [collapseFilters, setCollapseFilters] = useState<boolean>(false);

  //Get the filters as parameters and wraps them inside the column with the selector at the beginning
  const FiltersColumnWrapper = useCallback(
    (filters: JSX.Element) => {
      return collapseFilters ? (
        <div
          className="search-component-open-filters"
          onClick={() => setCollapseFilters((prev) => !prev)}
        >
          <CollapseIcon />
        </div>
      ) : (
        <div className="search-component-filters-column">
          <AccountTabsContainer
            style={{ paddingInline: 3 }}
            className={isCompany ? '' : 'remove-before-modifiers-first'}
          >
            <AccountTabTab
              selected={!isCompany}
              onClick={() => setIsCompany(false)}
              icon={
                <IconMContact
                  color={!isCompany ? colors.darkGreen : colors.black}
                />
              }
              label="Contacts"
              style={{
                borderBottom: `1px solid transparent`,
                marginBottom: isCompany ? 1 : 0,
              }}
              showHoverOnLabelsOnly={true}
            />
            <div
              className="tabs-divider"
              style={{
                visibility: 'hidden',
              }}
            />
            <AccountTabTab
              selected={isCompany}
              onClick={() => setIsCompany(true)}
              icon={
                <IconMCompany
                  color={isCompany ? colors.darkGreen : colors.black}
                />
              }
              label="Companies"
              style={{
                borderBottom: `1px solid transparent`,
                marginBottom: !isCompany ? 1 : 0,
              }}
              showHoverOnLabelsOnly={true}
            />
            <div
              className="tabs-divider"
              style={{
                visibility: 'hidden',
              }}
            />
            <AccountTabTab
              selected={false}
              onClick={() => setCollapseFilters((prev) => !prev)}
              icon={<CollapseIcon />}
              label=""
              style={{
                borderBottom: `1px solid transparent`,
                marginBottom: 1,
              }}
              showHoverOnLabelsOnly={true}
            />
          </AccountTabsContainer>
          <div
            className="search-component-filters-container"
            style={{
              borderTopLeftRadius: isCompany ? '16px' : '0px',
              // borderTopRightRadius: !isCompany ? '16px' : '0px',
              borderTopRightRadius: 16,
            }}
          >
            {filters}
          </div>
        </div>
      );
    },
    [collapseFilters, isCompany],
  );

  return (
    <SearchComponentWrapper>
      {isCompany ? (
        <SearchCompanyTabProvider>
          <CompanySearchTab filtersColumnWrapper={FiltersColumnWrapper} />
        </SearchCompanyTabProvider>
      ) : (
        <SearchContactTabProvider initialCompany={initialCompany}>
          <ContactSearchTab filtersColumnWrapper={FiltersColumnWrapper} />
        </SearchContactTabProvider>
      )}
    </SearchComponentWrapper>
  );
};

export default SearchComponent;
