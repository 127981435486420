import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { ProspectSearchStrategyResponse } from 'crono-fe-common/types/prospectSearchStrategy';
import { ProspectSearchStrategy } from 'crono-fe-common/types/DTO/prospectSearchStrategy';

export default function useSearchProspectStrategy(
  searchParameters: ProspectSearchStrategy,
) {
  const request: Request = {
    url: `${ENDPOINTS.prospect.search.strategy}`,
    config: {
      method: 'post',
      data: { data: searchParameters },
    },
  };
  const stringSearchParams = JSON.stringify(searchParameters);
  const requestConfig = {
    queryKey: [ENDPOINTS.prospect.search.strategy, stringSearchParams],

    queryFn: () => client(request),
    staleTime: 5 * 60 * 1000,
  };

  return useQuery<
    ResponsePaginated<ProspectSearchStrategyResponse[]>,
    CronoError
  >(requestConfig);
}
