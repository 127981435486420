import React, { useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import IconVariables from 'crono-fe-common/icons/Icon-Variables';
import IconBackArrow from 'crono-fe-common/icons/Icon-Back-Arrow';
import IconAttachments from 'crono-fe-common/icons/Icon-Attachments';
import AddTemplateIconS from 'crono-fe-common/icons/Icon-Add-Template-S';
import { EditorToolbarWrapper } from './styles';
import { FeConstants } from 'constants/FeConstants';
import { CustomButtonType } from './CronoEditor';
import { colors } from 'crono-fe-common/theme';
import ImageResize from 'quill-image-resize-module-react';
import { ClickAwayListener } from '@mui/material';
import VariablesSelector from 'pages/settings/Template/variablesSelector';
import { TemplateVariables } from 'crono-fe-common/types/template';
import IconStarsInsights from 'crono-fe-common/icons/Icon-Stars-Insights';
import { TemplateCardsContainerTooltip } from 'pages/accountTab/linkedinView';
import IconImage from 'assets/images/Icon_Image.svg';
import { ReactComponent as IconImageComponent } from 'assets/images/Icon_Image.svg';
import { ReactComponent as IconOpenAI } from 'assets/images/Icon_OpenAI.svg';

window.Quill = Quill;
// Add sizes to whitelist and register them
const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);

const FontStyle = Quill.import('attributors/style/font');
// we delete it to be backward-compatible with tiny-mc editor
delete FontStyle.whitelist;
Quill.register(FontStyle, true);

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = FeConstants.editorSupportedFontSizes;
Quill.register(SizeStyle, true);

const BlockEmbed = Quill.import('blots/block/embed');

class PlaceholderBlot extends BlockEmbed {
  static create(value: any): HTMLElement {
    const node = super.create() as HTMLElement;
    node.setAttribute('contenteditable', 'false');
    node.innerHTML = `<div class='placeholder'><img src='${IconImage}'/></div>`;
    return node;
  }

  static value(node: HTMLElement): any {
    return node.innerHTML;
  }
}

PlaceholderBlot.blotName = 'placeholder';
PlaceholderBlot.tagName = 'picture';
Quill.register(PlaceholderBlot);

class HtmlBlot extends BlockEmbed {
  static create(value: any) {
    const node = super.create();
    node.setAttribute('contenteditable', 'false');
    node.innerHTML = value;
    return node;
  }

  static value(node: any) {
    return node.innerHTML;
  }
}

HtmlBlot.blotName = 'html-blot';
HtmlBlot.tagName = FeConstants.htmlBlotTagName;
Quill.register(HtmlBlot);

export const createHtmlBlot = (value: string) => {
  return `<${FeConstants.htmlBlotTagName}>${value}</${FeConstants.htmlBlotTagName}>`;
};

// Undo and redo functions for Custom Toolbar
export function undoChange() {
  // @ts-ignore
  this.quill.history.undo();
}
export function redoChange() {
  // @ts-ignore
  this.quill.history.redo();
}

Quill.register('modules/imageResize', ImageResize);

export interface CustomButton {
  type: CustomButtonType;
  onClick: () => void;
  showLabel?: boolean;
}

export interface EditorToolbarProps {
  editorRef: React.MutableRefObject<ReactQuill | null>;
  customButtons?: CustomButton[];
  hideStylingButtons?: boolean;
  customButtonsToRight?: boolean;
  showVariablesPicker?: boolean;
  setShowVariablesPicker?: React.Dispatch<React.SetStateAction<boolean>>;
  toolbarId?: string;
  parseVariable?: (variable: string) => string;
  templateComponent?: JSX.Element | null;
  closeTemplateTooltip?: () => void;
  showTemplateTab?: boolean;
}

export const EditorToolbar: React.FC<EditorToolbarProps> = ({
  customButtons = [],
  hideStylingButtons,
  showVariablesPicker,
  setShowVariablesPicker,
  editorRef,
  toolbarId,
  customButtonsToRight = true,
  parseVariable,
  templateComponent,
  closeTemplateTooltip,
  showTemplateTab,
}) => {
  const closeVariablesPicker = () => {
    if (setShowVariablesPicker) {
      setShowVariablesPicker(false);
    }
  };

  const addVariablesContent = (variable: TemplateVariables | string) => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      const range = editor.getSelection(true);
      if (parseVariable) {
        variable = parseVariable(variable);
      }
      if (range) {
        if (range.length > 0) {
          editor.deleteText(range.index, range.length, 'user');
        }
        editor.insertText(range.index, variable, 'user');
        editor.setSelection(range.index + variable.length, 0, 'user');
      } else {
        editor.insertText(0, variable, 'user');
      }
    }
  };

  const getToolbarButtonJSX = (
    customButton: CustomButton,
    icon: JSX.Element,
    label?: string,
    highlightedButton?: boolean,
  ) => (
    <TemplateCardsContainerTooltip
      key={customButton.type}
      title={
        customButton.type === 'templates' && templateComponent
          ? templateComponent
          : null
      }
      onClose={closeTemplateTooltip}
      open={showTemplateTab}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <button
        id={`ql-${customButton.type}`}
        className={`ql-${customButton.type} ${highlightedButton ? 'highlighted-button-container' : ''}`}
        onClick={customButton.onClick}
      >
        {icon}
        {label}
        {customButton.type === 'variables' &&
          !!closeVariablesPicker &&
          !!addVariablesContent &&
          showVariablesPicker && (
            <ClickAwayListener onClickAway={closeVariablesPicker}>
              <div className="variables-picker">
                <VariablesSelector addVariables={addVariablesContent} />
              </div>
            </ClickAwayListener>
          )}
      </button>
    </TemplateCardsContainerTooltip>
  );

  const customButtonsLeftGroup = useMemo(() => {
    const attachmentsButton = customButtons.find(
      (button) => button.type === 'attachments' && !button.showLabel,
    );
    const imageUploadButton = customButtons.find(
      (button) => button.type === 'image-upload',
    );

    return (
      <>
        {attachmentsButton &&
          getToolbarButtonJSX(attachmentsButton, <IconAttachments />)}
        {imageUploadButton &&
          getToolbarButtonJSX(imageUploadButton, <IconImageComponent />)}
      </>
    );
  }, [
    customButtons,
    showVariablesPicker,
    closeVariablesPicker,
    addVariablesContent,
  ]);

  const customButtonsGroup = useMemo(() => {
    return customButtons
      .filter((button) => button.type !== 'attachments' || button.showLabel)
      .filter((button) => button.type !== 'image-upload')
      .map((customButton) => {
        let label = '';
        let icon = <></>;
        const highlightedButton =
          customButton.type === 'templates' || customButton.type === 'insights';

        switch (customButton.type) {
          case 'attachments':
            label = customButton.showLabel ? 'Attachments' : '';
            icon = <IconAttachments />;
            break;
          case 'templates':
            label = customButton.showLabel ? 'Templates' : '';
            icon = (
              <span className="flex-center icon-wrapper">
                <AddTemplateIconS
                  color={colors.black}
                  className={'highlighted-button-icon'}
                />
              </span>
            );
            break;
          case 'insights':
            label = customButton.showLabel ? 'Insights' : '';
            icon = (
              <span className="flex-center icon-wrapper">
                <IconStarsInsights
                  color={colors.black}
                  viewBox="0 0 16 16"
                  className={'highlighted-button-icon'}
                />
              </span>
            );
            break;
          case 'variables':
            label = customButton.showLabel ? 'Variables' : '';
            icon = <IconVariables />;
            break;
          case 'rewrite':
            label = customButton.showLabel ? 'Rewrite with AI' : '';
            icon = <IconOpenAI />;
            break;
        }

        return getToolbarButtonJSX(
          customButton,
          icon,
          label,
          highlightedButton,
        );
      });
  }, [
    customButtons,
    showVariablesPicker,
    closeVariablesPicker,
    addVariablesContent,
  ]);

  return (
    <EditorToolbarWrapper id={toolbarId ?? 'toolbar'}>
      <div className="toolbar">
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flex: '1',
            alignItems: 'center',
            rowGap: '8px',
          }}
        >
          <button className="ql-undo">
            <IconBackArrow />
          </button>
          <button className="ql-redo">
            <IconBackArrow style={{ transform: 'rotateY(180deg)' }} />
          </button>
          {!hideStylingButtons && (
            <>
              <select className="ql-size" defaultValue="12pt">
                {FeConstants.editorSupportedFontSizes.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select className="ql-font">
                <option selected key={-1}>
                  Normal
                </option>
                {FeConstants.editorSupportedFonts.map((font) => (
                  <option key={font} value={font}>
                    {font}
                  </option>
                ))}
              </select>
              <span className="buttons-group">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
              </span>
              <span className="buttons-group">
                <select className="ql-align" />
                <select className="ql-color" />
                <select className="ql-background" />
              </span>
              <span className="buttons-group">
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
                <button className="ql-link" />
              </span>
            </>
          )}
          {customButtonsLeftGroup}
          {!customButtonsToRight && customButtonsGroup}
          <div
            style={{ minWidth: '1px', display: 'flex', flex: 1 }}
            id="spacer"
          />
          <div
            style={{
              display: 'flex',
              minWidth: 'fit-content',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {customButtonsToRight && customButtonsGroup}
          </div>
        </div>
      </div>
    </EditorToolbarWrapper>
  );
};

export default EditorToolbar;
