import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AddOpportunityFormWrapper } from './style';
import {
  Autocomplete,
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useAuth } from 'context/auth';
import { useConditionalSnackBar } from 'context/snackbar';
import {
  OpportunityInsert,
  OpportunityInsertCodeDTO,
} from 'crono-fe-common/types/DTO/opportunityInsert';
import { getImageFromUrl, stringAvatarAccount } from 'utils/fe-utils';
import useAccounts from 'hooks/services/account/useAccounts';
import Role from 'crono-fe-common/types/enums/role';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as CalendarIcon } from 'crono-fe-common/icons/Calendar.svg';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { ReactComponent as TopArrow } from 'crono-fe-common/icons/Top-Arrow.svg';

import useGetPipelines from 'hooks/services/pipeline/useGetPipelines';
import useSearchOpportunity from 'hooks/services/opportunity/useSearchOpportunity';
import { NumericFormat } from 'react-number-format';
import { Account } from 'crono-fe-common/types/account';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { TagCreation } from 'crono-fe-common/types/DTO/accountInsert';
import InputExternalPropertyNotTag from 'pages/accountTab/externalPropertyNotTag/inputExternalPropertyNotTag';

const NumberTextField = (props: any) => {
  return (
    <TextField
      variant="standard"
      sx={{
        '& .MuiInput-underline:before': {
          borderBottomColor: colors.grey444,
        },
      }}
      type="number"
      {...props}
    />
  );
};

const customSelectStyle = {
  style: { zIndex: 35001 },
  PaperProps: {
    sx: {
      borderRadius: '8px',
      paddingInline: '6px',
      border: '1px solid #dadde9',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      '& .MuiMenuItem-root': {
        fontSize: '12px',
        lineHeight: '16px',
        height: 'fit-content',
        padding: '8px',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '8px',
        '&:hover': {
          background: colors.primaryLight,
          color: colors.primary,
        },
      },
      '& .Mui-selected': {
        color: colors.primary,
        backgroundColor: 'transparent !important',
      },
    },
  },
};

interface IProps {
  close?: () => void;
  handleSubmit: (values: any) => void;
  createLoading?: boolean;
  initialCompany?: Account | null;
}

const AddOpportunityForm = ({
  close,
  handleSubmit,
  createLoading,
  initialCompany,
}: IProps) => {
  const { user, highestRole } = useAuth();
  const [initialAccount, setInitialAccount] = useState<Account | null>(
    initialCompany ?? null,
  );

  const [errorMandatoryFields, setErrorMandatoryFields] = useState<
    string | null
  >(null);

  //To clear the error after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorMandatoryFields) {
      timeout = setTimeout(() => {
        setErrorMandatoryFields(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [errorMandatoryFields]);

  // CompanySelect -------------------------------
  const [searchCompanyText, setSearchCompanyText] = useState<string>('');
  const { data: accounts } = useAccounts({
    name: searchCompanyText,
    limit: 25,
    status: null,
    IsManager: highestRole === Role.MANAGER,
  });

  const mappedAccount = useMemo(() => {
    if (!accounts || !accounts.data) return [];
    return accounts.data?.data.map((acc) => {
      return acc;
    });
  }, [accounts]);

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  //Here I have the acconut to insert, with the accountId
  const selectedAccount = useMemo(() => {
    if (initialAccount) {
      return initialAccount;
    } else {
      const acc = mappedAccount.find((el) => el.objectId === selectedCompany);
      return acc;
    }
  }, [selectedCompany, mappedAccount, initialAccount]);

  const handleRemoveAccount = () => {
    setSelectedCompany(null);
    setSearchCompanyText('');
    setInitialAccount(null);
    setDealName('');
  };

  // Deal owner and name logic ----------------------------
  const { data: users } = useGetUsers();

  const [dealName, setDealName] = useState<string | null>(null);

  useEffect(() => {
    if (selectedAccount) {
      setDealName(selectedAccount.name + ' - New Deal');
    }
  }, [selectedAccount]);

  const handleDealNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setDealName(ev.target.value);
  };

  // Pipeline/stage logic ----------------------------
  const { data: pipelines } = useGetPipelines();

  const [selectedPipelineId, setSelectedPipelineId] = useState<number | null>(
    null,
  );

  const selectedPipeline = useMemo(() => {
    return pipelines?.data?.data.find(
      (pipeline) => pipeline.id === selectedPipelineId,
    );
  }, [selectedPipelineId]);

  useEffect(() => {
    //When loaded set the initial pipeline to the first one
    if (pipelines?.data?.data.length && selectedPipelineId === null) {
      setSelectedPipelineId(pipelines?.data?.data[0].id);
    }
  }, [pipelines]);

  const { data: opportunityData } = useSearchOpportunity(
    {
      pipeline: selectedPipeline?.externalName ?? null,
      limit: 50,
      // year: new Date().getFullYear(),
    },
    !!selectedPipeline,
  );

  const formik = useFormik<OpportunityInsertCodeDTO>({
    initialValues: {
      accountId: null,
      amount: null,
      name: dealName,
      stage: null,
      pipeline: null,
      description: null,
      closeDate: null,
      userId: user?.id ?? null,
      externalValues: [],
    },
    onSubmit: (values) => {
      const externalValuesToAdd: { [key: number]: string } = {};
      formik.values.externalValues?.forEach((val) => {
        externalValuesToAdd[val.externalPropertyId] = val.value;
      });
      //Check if the mandatory fields are filled
      const mandatoryToFill: string[] = [];
      externalProperties?.data?.data.forEach((property) => {
        if (property.isMandatory && !externalValuesToAdd[property.id]) {
          mandatoryToFill.push(
            property.publicName ?? property.externalName ?? 'Unnamed property',
          );
        }
      });
      if (mandatoryToFill.length > 0) {
        setErrorMandatoryFields(mandatoryToFill.join(', '));
        return;
      }
      const opportunityInsert: OpportunityInsert = {
        accountId: selectedAccount?.objectId ?? null,
        amount: values.amount,
        name: dealName,
        stage: values.stage,
        pipeline: values.pipeline ?? selectedPipeline?.externalName ?? null,
        description: values.description,
        closeDate: values.closeDate,
        userId: values.userId,
        externalValues: externalValuesToAdd,
      };

      handleSubmit(opportunityInsert);
    },
  });

  //The stages that has to be shown in the correct order
  const stages = useMemo(() => {
    if (!opportunityData?.data?.data.opportunitiesByStages) return [];
    //I order them by stageOrder
    const entries = Object.entries(
      opportunityData?.data?.data.opportunitiesByStages,
    );
    entries.sort((a, b) => {
      return a[1].stageOrder - b[1].stageOrder;
    });
    if (entries.length === 0) {
      formik.setFieldValue('stage', null);
      return [];
    }
    //Set the first one as default in the formik
    formik.setFieldValue('stage', entries[0][1].stage);

    //Remove the name, since it is already contained as property
    return entries.map((entry) => entry[1]);
  }, [opportunityData]);

  //  --------------------------------------------

  //To clear the error after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorMandatoryFields) {
      timeout = setTimeout(() => {
        setErrorMandatoryFields(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [errorMandatoryFields]);

  useConditionalSnackBar([
    {
      condition: !!errorMandatoryFields,
      message: `These mandatory fields are missing a value: ${errorMandatoryFields}`,
      severity: 'error',
    },
  ]);

  // EP logic ----------------------------
  const { data: externalProperties } = useGetExternalProperty(
    'Opportunity',
    null,
    true,
  );
  const [moreFields, setMoreFields] = useState<boolean>(false);
  const externalPropertyNotTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter((property) => !property.isTag) ??
      []
    );
  }, [externalProperties]);

  //Initialize the externalValues that has default value
  useEffect(() => {
    if (!externalPropertyNotTags) return;
    const newExternalProperties: TagCreation[] = [];
    externalPropertyNotTags.forEach((property) => {
      if (property.defaultValue) {
        newExternalProperties.push({
          externalPropertyId: property.id,
          value: property.defaultValue,
          isTag: property.isTag,
        });
      }
    });
    formik.setFieldValue('externalValues', newExternalProperties);
  }, [externalPropertyNotTags]);

  const handleSetExternalValueNotTag = useCallback(
    (externalPropertyId: number, value: string) => {
      const newValues = [...formik.values.externalValues];
      const newExternalValue = {
        externalPropertyId,
        value: value,
        isTag: false,
      };
      const oldValue = newValues.filter(
        (val) => val.externalPropertyId === externalPropertyId,
      );
      if (oldValue.length > 0) {
        newValues.splice(newValues.indexOf(oldValue[0]), 1);

        newValues.push(newExternalValue);

        formik.setFieldValue('externalValues', newValues);
      } else {
        newValues.push(newExternalValue);
        formik.setFieldValue('externalValues', newValues);
      }
    },
    [formik.values],
  );

  return (
    <AddOpportunityFormWrapper>
      <form
        className="form-add-container"
        onSubmit={formik.handleSubmit}
        onKeyDown={(ev) => {
          if (ev.code === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
          }
        }}
      >
        {/* Show select only if pipeline is more than 1 */}
        {user?.userRoles.includes(Role.MANAGER) && users?.data?.data && (
          <>
            <div className="form-box">
              <Typography
                fontSize={'14px'}
                fontWeight={600}
                lineHeight={'22px'}
              >
                Pipeline:
              </Typography>
              {pipelines?.data?.data && pipelines?.data?.data?.length === 1 ? (
                <div className="default-pipeline">
                  <Typography fontSize={'14px'}>
                    {pipelines?.data?.data[0].publicName}
                  </Typography>
                </div>
              ) : (
                <Select
                  value={selectedPipelineId ?? -1}
                  onChange={(ev) => {
                    if (ev.target.value !== -1) {
                      setSelectedPipelineId(ev.target.value as number);
                    }
                  }}
                  className="select"
                  MenuProps={customSelectStyle}
                >
                  <MenuItem value={-1} disabled style={{ display: 'none' }}>
                    Select pipeline
                  </MenuItem>
                  {pipelines?.data?.data.map((pipeline) => {
                    return (
                      <MenuItem value={pipeline.id} key={pipeline.id}>
                        {pipeline.publicName}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </div>

            <div className="form-box">
              <Typography
                fontSize={'14px'}
                fontWeight={600}
                lineHeight={'22px'}
              >
                Deal owner:
              </Typography>
              <Select
                placeholder="Owner name"
                name=""
                value={formik.values.userId || 0}
                onChange={(ev) => {
                  const value = ev.target.value as number;
                  formik.setFieldValue('userId', value);
                }}
                className="select"
                MenuProps={customSelectStyle}
              >
                <MenuItem value={0} disabled style={{ display: 'none' }}>
                  Owner name
                </MenuItem>
                {users?.data?.data.map((user) => {
                  return (
                    <MenuItem value={user.id} key={user.id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </>
        )}

        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Deal company*
          </Typography>
          <div style={{ display: 'flex' }}>
            {selectedAccount ? (
              <div className="information-card-manual-task">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 8,
                    width: '100%',
                  }}
                >
                  <Avatar
                    src={
                      selectedAccount.website &&
                      getImageFromUrl(selectedAccount.website, null)
                    }
                    className="account-avatar"
                  >
                    {stringAvatarAccount(selectedAccount.name || '')}
                  </Avatar>
                  <div style={{ width: 'calc(100% - 40px)' }}>
                    <Typography
                      fontWeight={500}
                      fontSize={'14px'}
                      lineHeight={'18px'}
                      noWrap
                    >
                      {selectedAccount.name}
                    </Typography>
                  </div>
                </div>
                <RemoveIcon
                  className="manual-task-remove-icon"
                  color={colors.grey11}
                  onClick={handleRemoveAccount}
                />
              </div>
            ) : (
              <Autocomplete
                id="companySelect"
                fullWidth
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                onChange={(e, value) => {
                  if (value) setSelectedCompany(value.id);
                  else setSelectedCompany(null);
                }}
                options={mappedAccount.map((acc) => {
                  return { id: acc.objectId, name: acc.name };
                })}
                getOptionLabel={(option) => option.name || ''}
                PaperComponent={({ children }) => (
                  <div className="autocomplete-paper">{children}</div>
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={option.id}
                    value={option.id || undefined}
                    className="autocomplete-option"
                  >
                    {option.name}
                  </li>
                )}
                disablePortal
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    fullWidth
                    placeholder="Type company name..."
                    value={searchCompanyText}
                    onChange={(e: any) => setSearchCompanyText(e.target.value)}
                  />
                )}
              />
            )}
          </div>
        </div>

        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Deal name*
          </Typography>
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder="Type name..."
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              name="name"
              value={dealName}
              onChange={handleDealNameChange}
              fullWidth
            />
          </div>
        </div>

        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Deal amount
          </Typography>
          <div style={{ display: 'flex' }}>
            <NumericFormat
              value={formik.values.amount}
              prefix={`${user?.otherSettings?.currency ?? '$'} `}
              onValueChange={(values) => {
                formik.setFieldValue('amount', parseFloat(values.value));
              }}
              decimalSeparator=","
              thousandSeparator="."
              placeholder={`${user?.otherSettings?.currency ?? '$'} 0.00`}
              allowNegative={false}
              decimalScale={2}
              fullWidth
              autoComplete="off"
              customInput={NumberTextField}
            />
          </div>
        </div>

        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Deal stage:
          </Typography>
          <Select
            value={formik.values.stage || ''}
            onChange={(ev) => {
              formik.setFieldValue('stage', ev.target.value);
            }}
            className="select"
            MenuProps={customSelectStyle}
            displayEmpty
          >
            <MenuItem value={''} disabled style={{ display: 'none' }}>
              Select stage
            </MenuItem>
            {stages?.map((stage) => {
              return (
                <MenuItem value={stage.stage as string} key={stage.stageName}>
                  {stage.stageName}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Close date:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className="select-date-time-button"
              value={formik.values.closeDate || null}
              onChange={(newValue) =>
                formik.setFieldValue('closeDate', newValue)
              }
              slots={{
                openPickerIcon: () => <CalendarIcon />,
              }}
              slotProps={{
                popper: { style: { zIndex: 9999 } },
                textField: {
                  variant: 'standard',
                  InputProps: { disableUnderline: true },
                  placeholder: 'Select date',
                },
              }}
              format="MMM d, yyyy"
              sx={{
                width: 130,
                fontSize: 12,
                '.MuiInputBase-root': {
                  flexDirection: 'row-reverse',
                  width: 160,
                },
                '& .MuiInputAdornment-root': {
                  margin: 0,
                },
                '.MuiInputBase-input': {
                  marginLeft: '10px',
                  marginTop: '2px',
                },
              }}
            />
          </LocalizationProvider>
        </div>

        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Description
          </Typography>
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder="Add information..."
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              fullWidth
            />
          </div>
        </div>
        {moreFields && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {externalPropertyNotTags.map((property) => {
              return (
                <InputExternalPropertyNotTag
                  key={property.id}
                  externalProperty={property}
                  owned={true}
                  externalValues={formik.values.externalValues}
                  handleSetValue={handleSetExternalValueNotTag}
                />
              );
            })}
          </div>
        )}

        {externalPropertyNotTags.length > 0 && (
          <Button
            variant="text"
            color="secondary"
            className="more-fields-button"
            endIcon={!moreFields ? <BottomArrow /> : <TopArrow />}
            onClick={() => setMoreFields((prev) => !prev)}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            {!moreFields
              ? `More fields (${externalPropertyNotTags.length})`
              : 'Less fields'}
          </Button>
        )}

        <FlexDiv
          width="fit-content"
          height="fit-content"
          style={{ alignSelf: 'flex-end' }}
        >
          <CancelButton
            style={{ width: '120px' }}
            onClick={() => {
              if (close) close();
            }}
          >
            Cancel
          </CancelButton>
          {createLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <MainButton
              type="submit"
              style={{ width: '120px' }}
              disabled={
                dealName === null ||
                dealName === '' ||
                formik.values.userId === null ||
                formik.values.stage === null
              }
            >
              Create
            </MainButton>
          )}
        </FlexDiv>
      </form>
    </AddOpportunityFormWrapper>
  );
};

export default AddOpportunityForm;
