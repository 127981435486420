import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import Error from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export interface IPostSnippetVariable {
  label: string;
  content: string;
}

export default function usePostSnippetVariable() {
  const queryClient = useQueryClient();

  const postSnippetVariable = (snippetVariable: IPostSnippetVariable) => {
    const request: Request = {
      url: `${ENDPOINTS.templateVariables.snippet}`,
      config: {
        method: 'post',
        data: {
          data: snippetVariable,
        },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    IPostSnippetVariable
  >(postSnippetVariable, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.templateVariables.main]);
    },
  });

  return { data, mutate, ...rest };
}
