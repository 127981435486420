import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const AddContainerWrapper = styled.div<{ replyToThread?: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  max-height: 90%;
  background: ${colors.white};
  // overflow: auto;
  padding: 18px 0px 18px 18px;
  border-radius: 16px;

  overflow: hidden;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .close-button {
    cursor: pointer;
  }
  .add-main-container {
    display: flex;
    flex-direction: column;
    padding: 0px 38px 0px 20px;
    row-gap: 20px;
    // height: calc(100% - 250px);
    overflow: auto;
    // padding-top: 8px;
    // padding-inline: 16px;
  }
  .task-container {
    gap: 32px;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .search-linkedin-icon {
    cursor: pointer;
  }
  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding-inline: 6px;
    font-family: 'Poppins', sans-serif;
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    // background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .manual-task-settings {
    display: flex;
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
  }
  .step-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .step-content-top {
    display: flex;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    align-items: center;
    // height: 60px;
    width: 100%;
    border-bottom-left-radius: ${({ replyToThread }) =>
      replyToThread ? '0px' : ''};
    border-bottom-right-radius: ${({ replyToThread }) =>
      replyToThread ? '0px' : ''};
    // display: flex;
    // align-items: center;
    justify-content: space-between;
  }
  .step-content-bottom {
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    align-items: center;
    min-height: 40px;
    width: 100%;
    // padding: 8px;
    border-top: none;
    border-top-left-radius: ${({ replyToThread }) =>
      replyToThread ? '0px' : ''};
    border-top-right-radius: ${({ replyToThread }) =>
      replyToThread ? '0px' : ''};
  }

  .flex-center {
    display: flex;
    align-items: center;
  }
  .step-left-side-wrapper {
    padding: 16px;
    justify-content: space-between;
    min-width: 380px;
  }

  .type-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 7px;
    // cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .call {
    background-color: ${colors.primaryLight};
  }
  .selected-type-icon:hover {
    opacity: 0.7;
  }
  .email {
    background-color: ${colors.inactiveLight};
  }
  .in-mail {
    background-color: ${colors.purpleLight};
    padding: 5px;
  }
  .linkedin-icon {
    background-color: ${colors.blueLight};
  }
  .check-badge-icon {
    width: 15px;
    height: 15px;
  }
  .divider {
    border: 1px solid ${colors.grey4};
  }
  .task-component-button {
    cursor: pointer;
  }
  .task-component-button-disabled {
    cursor: not-allowed;
  }
  .information-card-manual-task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .account-avatar {
    width: 30px;
    height: 30px;
  }
  .manual-task-remove-icon {
    cursor: pointer;
    justify-self: flex-end;
  }
  .select-date-time-button {
    cursor: pointer;
    display: flex;
    text-transform: unset;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${colors.grey11};
    padding-inline: 4px;
  }
  .select-time-button {
    margin-left: 8px;
  }
  .step-type-option-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
  }
  .step-type-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
`;
