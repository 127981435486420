import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const EmailIconDark = ({
  color = colors.white,
  color2 = colors.inactive,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <rect width="32" height="32" rx="16" fill={color2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6217 16.5025L22.1261 14.1265C22.6713 13.7579 22.9972 13.1427 22.9972 12.4848V12.4848C22.9972 11.3882 22.109 10.5 21.0132 10.5H10.9937C9.89793 10.5 9.00977 11.3882 9.00977 12.484V12.484C9.00977 13.1419 9.33563 13.7571 9.88082 14.1265L13.3853 16.5025C14.9664 17.5742 17.0406 17.5742 18.6217 16.5025V16.5025Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.4844V19.8331C9 21.1218 10.0445 22.1663 11.3332 22.1663H20.6659C21.9546 22.1663 22.9991 21.1218 22.9991 19.8331V12.4852"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIconDark;
