import styled from '@emotion/styled';

export const IntegrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 90px;

  .onboarding-navigation {
    display: flex;
    align-items: center;
    gap: 8;
    justify-content: center;
  }
  .salesforce-admin {
    padding: 0px;
  }
`;
