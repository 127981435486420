import { Typography, Tooltip, Button, ClickAwayListener } from '@mui/material';
import AddTemplateIcon from 'crono-fe-common/icons/Icon-Add-Template';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';

import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';

import { colors } from 'crono-fe-common/theme';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { ManualTaskSequenceStepWrapper } from './style';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import moment from 'moment';
import { ReactComponent as ChangeDateIcon } from 'crono-fe-common/icons/Change-Date.svg';

import { ReactComponent as TemplateAttached } from 'crono-fe-common/icons/Template-Attached.svg';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import React, { useState } from 'react';
import { typeOptions } from 'pages/taskBoard/sequenceInstance/sequenceInstanceTaskComponent';
import LinkedinIconInMailOnlyCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import { StepTypeOptionsWrapper } from 'context/strategyDetail/stepDetailComponent/style';
import { useAuth } from 'context/auth';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import CronoTimePicker from 'crono-fe-common/components/CronoTimePicker';
import { TemplateCardsContainerTooltip } from 'pages/accountTab/linkedinView';
import ErrorIndicatorIcon from 'crono-fe-common/icons/Icon-Error-Indicator';
import { getTextOfAutomationError } from 'utils/fe-utils';

interface IProps {
  task: TaskTodo;
  handleChange: (type: string, payload: any, index: number) => void;
  index: number;
  openTemplatePicker: () => void;
  openDateCalendar: (task: TaskTodo) => void;
  opened?: boolean;
  // Template tooltip
  templateComponent?: JSX.Element | null;
  closeTemplateTooltip?: () => void;
  showTemplateTab?: boolean;
  openTemplateTooltip: (task: TaskTodo) => void;
}

export const MAX_DELAY = 10;

const ManualTaskComponent = ({
  task,
  handleChange,
  index,
  openTemplatePicker,
  openDateCalendar,
  opened,
  templateComponent,
  closeTemplateTooltip,
  showTemplateTab,
  openTemplateTooltip,
}: IProps) => {
  const { hasSalesNav } = useAuth();
  const fontColor = opened ? colors.primary : colors.grey11;
  const [openTypeTooltip, setOpenTypeTooltip] = useState<boolean>(false);
  const renderPossibleTypes = (currentTask: TaskTodo, index: number) => {
    return typeOptions
      .filter((option) => {
        if (
          currentTask.type !== TaskTodoType.IN_MAIL &&
          option.type === TaskTodoType.IN_MAIL &&
          !hasSalesNav
        ) {
          return false;
        }
        return (
          option.type !== currentTask.type ||
          (currentTask.subtype &&
            option.subtype &&
            option.subtype !== currentTask.subtype)
        );
      })
      .map((option) => {
        return (
          <div key={`${option.type}-${option.subtype}`}>
            <Button
              className="option-button"
              color="primary"
              fullWidth
              endIcon={option.icon}
              onClick={() => {
                handleChange(
                  'type',
                  { type: option.type, subtype: option.subtype },
                  index,
                );
                setOpenTypeTooltip(false);
              }}
            >
              {option.text}
            </Button>
          </div>
        );
      });
  };
  const renderStepType = (task: TaskTodo) => {
    let icon = null;
    let type = '' + task.type;
    switch (task.type) {
      case TaskTodoType.CALL:
        icon = <CallIcon className={'type-icon'} />;
        break;
      case TaskTodoType.EMAIL:
        icon = <EmailIcon className={'type-icon'} />;
        break;
      case TaskTodoType.LINKEDIN:
        if (task.subtype === TaskTodoSubtype.LinkedinInvitation) {
          icon = (
            <LinkedinInvitationIcon
              className={'type-icon'}
              color={colors.black}
            />
          );
          type = 'Invitation';
        } else if (task.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
          icon = (
            <LinkedinIconInvoice className={'type-icon'} viewBox="2 2 19 19" />
          );
          type = 'Voice Note';
        } else {
          icon = (
            <LinkedinIconMessage
              className={'type-icon'}
              viewBox="-2 -1 19 19"
            />
          );
          type = 'Message';
        }
        break;
      case TaskTodoType.IN_MAIL:
        icon = (
          <LinkedinIconInMailOnlyCompass
            className={'type-icon'}
            color={colors.mainDark}
          />
        );
        type = 'InMail';
        break;
    }

    return (
      <ClickAwayListener onClickAway={() => setOpenTypeTooltip(false)}>
        <div style={{ width: '100%' }}>
          <CustomOptionsTooltip
            maxHeight={220}
            width={190}
            left={1}
            title={
              <StepTypeOptionsWrapper>
                {renderPossibleTypes(task, index)}
              </StepTypeOptionsWrapper>
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={openTypeTooltip}
            onClose={() => setOpenTypeTooltip(false)}
            borderRadius={'16px'}
            sx={
              {
                // zIndex: '10001 !important',
              }
            }
          >
            <div
              className="step-type-option-container"
              onClick={() => setOpenTypeTooltip((prev) => !prev)}
            >
              <div className="step-type-option">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {icon}
                </span>
                <Typography fontSize={'12px'} fontWeight={500}>
                  {type}
                </Typography>
              </div>
              {openTypeTooltip ? <TopArrowIcon /> : <BottomArrowIcon />}
            </div>
          </CustomOptionsTooltip>
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <ManualTaskSequenceStepWrapper>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        {task.subject && (
          <Typography fontSize={12} lineHeight={'16px'} fontWeight={500} noWrap>
            Task title:{' '}
            <span style={{ color: colors.grey11 }}>{task.subject}</span>
          </Typography>
        )}
        {task.automationError && (
          <div className="error-badge-task">
            <ErrorIndicatorIcon color2={colors.inactiveLightHover} />
            <Typography
              fontSize={12}
              lineHeight={'16px'}
              fontWeight={500}
              color={colors.inactive}
              noWrap
            >
              {getTextOfAutomationError(task.automationError)}
            </Typography>
          </div>
        )}
      </div>
      <div className="step-row">
        <div className="step-content">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <Typography
                fontSize={'12px'}
                fontWeight={500}
                color={fontColor}
                minWidth={'90px'}
                width={'90px'}
              >
                {moment(task.activityDate).format('ll')}
              </Typography>
              <div className="divider" />
            </div>

            {task.type === TaskTodoType.EMAIL ||
            (task.type === TaskTodoType.LINKEDIN &&
              task.subtype !== TaskTodoSubtype.LinkedinVoiceNote) ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tooltip
                  arrow
                  title={
                    task.type === TaskTodoType.LINKEDIN &&
                    task.subtype === TaskTodoSubtype.LinkedinInvitation
                      ? 'Automate this step'
                      : task.templateId === null
                        ? 'Please select a template to automate this step'
                        : 'Automate this step'
                  }
                  PopperProps={{
                    disablePortal: true,
                    style: { zIndex: '999999 !important' },
                  }}
                  enterDelay={400}
                  enterNextDelay={400}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color={colors.grey11}
                    >
                      Auto
                    </Typography>
                    <CronoSwitch
                      checked={task.automatic}
                      disabled={
                        !(
                          task.type === TaskTodoType.LINKEDIN &&
                          task.subtype === TaskTodoSubtype.LinkedinInvitation
                        ) && !task.templateId
                      }
                      onChange={(ev) =>
                        handleChange('automatic', ev.target.checked, index)
                      }
                    />
                    {task.automatic && (
                      <CronoTimePicker
                        value={new Date(task.activityDate)}
                        handleChange={(newValue) =>
                          handleChange('scheduleTime', newValue, index)
                        }
                        disabled={
                          !(
                            task.type === TaskTodoType.LINKEDIN &&
                            task.subtype === TaskTodoSubtype.LinkedinInvitation
                          ) && !task.templateId
                        }
                      />
                    )}
                  </div>
                </Tooltip>
              </div>
            ) : null}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
            <div className="step-type-option-dropdown">
              {renderStepType(task)}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: 140,
              }}
            >
              <div
                className="divider"
                style={{ marginBlock: -8, height: 72 }}
              />
              <TemplateCardsContainerTooltip
                title={templateComponent}
                onClose={closeTemplateTooltip}
                open={showTemplateTab}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                {task?.templateId ? (
                  <TemplateAttached
                    className="task-component-button"
                    color={colors.grey11}
                    onClick={() => {
                      openTemplatePicker();
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AddTemplateIcon
                      className="task-component-button"
                      onClick={() => {
                        openTemplatePicker();
                      }}
                      color={colors.grey11}
                    />
                  </div>
                )}
              </TemplateCardsContainerTooltip>
              <ChangeDateIcon
                className="task-component-button calendar-icon"
                onClick={() => openDateCalendar(task)}
              />
              <RemoveIcon
                color={colors.inactive}
                className="task-component-button"
                onClick={() => handleChange('delete', null, index)}
              />
            </div>
          </div>
        </div>
      </div>
    </ManualTaskSequenceStepWrapper>
  );
};

export default ManualTaskComponent;
