import { FeConstants } from 'constants/FeConstants';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  priceId: Yup.string().required(),
  quantity: Yup.number().required(),
  email: Yup.string()
    .matches(FeConstants.emailRegex, 'Email is not valid')
    .required('Please use a business email to create an account'),
});

export type BillingPlan = 'monthly' | 'yearly';

export interface SingupFormValues {
  priceId: string;
  quantity: number;
  email: string;
  plan: SubscriptionPlanType;
  billed: BillingPlan;
  price: number;
}
