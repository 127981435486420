import { SuggestionPreferences } from 'crono-fe-common/types/suggestionPreferences';
import React from 'react';
import { SuggestionPreferencesWrapper } from './style';
import {
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import IconSClose from 'crono-fe-common/icons/Icon-S-Close';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import SuggestionMultiselectForStatus from '../suggesitonPreferencesComponents/SuggestionMultiselectStatus';
import SuggestionSelectEntity from '../suggesitonPreferencesComponents/SuggestionSelectEntity';
import { useGetStatusesOptions } from 'utils/fe-utils';

interface IProps {
  updateMultipleSuggestionPreferences: (
    properties: {
      name: keyof SuggestionPreferences;
      value: any;
    }[],
  ) => void;
  parameters: SuggestionPreferences | null;
  isLoading: boolean;
}

export interface IStrategiesSuggestion {
  enabled:
    | 'startSequenceForNewProspectsEnabled'
    | 'startSequenceForOldProspectsEnabled';
  days: 'startSequenceForNewProspectsDays' | 'startSequenceForOldProspectsDays';
  entity:
    | 'startSequenceForNewProspectsEntity'
    | 'startSequenceForOldProspectsEntity';
  accountStatus:
    | 'startSequenceForOldProspectsAccountStatus'
    | 'startSequenceForNewProspectsAccountStatus';
  prospectStatus:
    | 'startSequenceForOldProspectsStatusNew'
    | 'startSequenceForNewProspectsProspectStatusNew';
  customStatus:
    | 'startSequenceForOldProspectsCustomStatusValues'
    | 'startSequenceForNewProspectsCustomStatusValues';
  customStatusId:
    | 'startSequenceForOldProspectsCustomStatusId'
    | 'startSequenceForNewProspectsCustomStatusId';
  firstTextSegment: string;
}

const SuggestionPreferencesStrategies = ({
  updateMultipleSuggestionPreferences,
  parameters,
  isLoading,
}: IProps) => {
  const strategiesSuggestion: IStrategiesSuggestion[] = [
    {
      enabled: 'startSequenceForNewProspectsEnabled',
      entity: 'startSequenceForNewProspectsEntity',
      days: 'startSequenceForNewProspectsDays',
      accountStatus: 'startSequenceForNewProspectsAccountStatus',
      prospectStatus: 'startSequenceForNewProspectsProspectStatusNew',
      customStatus: 'startSequenceForNewProspectsCustomStatusValues',
      customStatusId: 'startSequenceForNewProspectsCustomStatusId',
      firstTextSegment: 'New contacts created by',
    },
    {
      enabled: 'startSequenceForOldProspectsEnabled',
      entity: 'startSequenceForOldProspectsEntity',
      days: 'startSequenceForOldProspectsDays',
      accountStatus: 'startSequenceForOldProspectsAccountStatus',
      prospectStatus: 'startSequenceForOldProspectsStatusNew',
      customStatus: 'startSequenceForOldProspectsCustomStatusValues',
      customStatusId: 'startSequenceForOldProspectsCustomStatusId',
      firstTextSegment: 'Contacts with last activity date older than',
    },
  ];

  const {
    statuses,
    isExternalPropertyStatus: isExternalPropertyStatusProspect,
    externalPropertyDefaultStatus: externalPropertyDefaultStatusProspect,
    externalPropertyStatusId: externalPropertyStatusIdProspect,
  } = useGetStatusesOptions('account');

  const propertyForCompanyChangedStatus = isExternalPropertyStatusProspect
    ? 'stopSequencesForChangeStatusCustomStatusValues'
    : 'stopSequencesForChangeStatusAccountStatus';

  const handleSwitchOnChangeStatus = () => {
    const fieldsToChange: { name: keyof SuggestionPreferences; value: any }[] =
      [
        {
          name: 'stopSequencesForChangeStatusEnabled',
          value: !parameters?.stopSequencesForChangeStatusEnabled as boolean,
        },
      ];
    //If there are no options and we are switching to true we set a default one. If we are using externalPropertyStatus we also reset the default one
    if (
      !parameters?.stopSequencesForChangeStatusEnabled &&
      (parameters?.[propertyForCompanyChangedStatus]?.length ?? 0) === 0
    ) {
      fieldsToChange.push({
        name: propertyForCompanyChangedStatus,
        value: [externalPropertyDefaultStatusProspect],
      });
      if (isExternalPropertyStatusProspect) {
        fieldsToChange.push({
          name: 'suggestNewRoleEnabledCustomStatusId',
          value: externalPropertyStatusIdProspect,
        });
        fieldsToChange.push({
          name: 'suggestNewRoleEnabledProspectStatusNew',
          value: [],
        });
      }
    }
    updateMultipleSuggestionPreferences(fieldsToChange);
  };

  return (
    <>
      <Typography
        fontSize={16}
        fontWeight={500}
        lineHeight={'24px'}
        color={colors.grey11}
      >
        Start Strategy IF:
      </Typography>
      {strategiesSuggestion.map((suggestion, index) => {
        return (
          <SuggestionPreferencesWrapper key={index}>
            <CronoSwitch
              checked={!!parameters?.[suggestion.enabled]}
              onChange={() => {
                updateMultipleSuggestionPreferences([
                  {
                    name: suggestion.enabled,
                    value: !parameters?.[suggestion.enabled] as boolean,
                  },
                ]);
              }}
            />
            <div
              className="container-text-suggestion"
              style={{
                color: !parameters?.[suggestion.enabled]
                  ? colors.grey2
                  : colors.black,
              }}
            >
              <div className="container-text-line" style={{ marginTop: 4 }}>
                {suggestion.firstTextSegment}{' '}
                <TextField
                  defaultValue={parameters?.[suggestion.days]}
                  type="number"
                  className="day-picker-preferences"
                  disabled={!parameters?.[suggestion.enabled]}
                  onChange={(ev) => {
                    if (
                      ev.target.value &&
                      !Number.isNaN(Number(ev.target.value))
                    ) {
                      updateMultipleSuggestionPreferences([
                        {
                          name: suggestion.days,
                          value: ev.target.value,
                        },
                      ]);
                    }
                  }}
                  InputProps={{
                    sx: {
                      height: 30,
                      width: 50,
                      marginInline: 1,
                      borderRadius: 2,
                      color: 'inherit',
                      '&:hover': {
                        background: colors.grey6,
                      },
                      '& fieldset': {
                        border: `1px solid ${colors.grey444}`,
                      },
                      '&:hover fieldset': {
                        border: `1px solid ${colors.grey5}`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `1px solid ${colors.grey5}`,
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      border: 'none',
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '18px',
                    },
                  }}
                />
                days
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 8,
                    color:
                      (parameters?.[suggestion.entity] ?? 'Off') === 'Off' ||
                      !parameters?.[suggestion.enabled]
                        ? colors.grey2
                        : colors.black,
                  }}
                >
                  AND
                  <SuggestionSelectEntity
                    parameters={parameters}
                    updateMultipleSuggestionPreferences={
                      updateMultipleSuggestionPreferences
                    }
                    enabled={parameters?.[suggestion.enabled]}
                    suggestion={suggestion}
                  />
                  status is
                  <SuggestionMultiselectForStatus
                    entityNotSelected={
                      (parameters?.[suggestion.entity] ?? 'Off') === 'Off'
                    }
                    enabled={parameters?.[suggestion.enabled]}
                    parameters={parameters}
                    updateMultipleSuggestionPreferences={
                      updateMultipleSuggestionPreferences
                    }
                    property={suggestion}
                  />
                </span>
              </div>
            </div>
          </SuggestionPreferencesWrapper>
        );
      })}
      <Typography
        fontSize={16}
        fontWeight={500}
        lineHeight={'24px'}
        color={colors.grey11}
        marginTop={'24px'}
      >
        Stop Strategy IF:
      </Typography>
      <SuggestionPreferencesWrapper>
        <CronoSwitch
          checked={!!parameters?.stopSequencesForChangeStatusEnabled}
          onChange={handleSwitchOnChangeStatus}
        />
        <div
          className="container-text-suggestion"
          style={{
            color: !parameters?.stopSequencesForChangeStatusEnabled
              ? colors.grey2
              : colors.black,
            paddingTop: 2,
          }}
        >
          <div className="container-text-line">
            Contacts’s company changed status to
            <div style={{ position: 'relative' }}>
              <Select
                value={parameters?.[propertyForCompanyChangedStatus] ?? []}
                displayEmpty
                renderValue={(values) => {
                  if (!values)
                    return (
                      <Typography fontSize={'14px'}>Not selected</Typography>
                    );
                  return (
                    <Typography
                      fontSize={'14px'}
                      style={{
                        color: !parameters?.stopSequencesForChangeStatusEnabled
                          ? colors.grey2
                          : colors.black,
                      }}
                    >
                      {values?.length > 0
                        ? `${values.length} selected`
                        : 'Not selected'}
                    </Typography>
                  );
                }}
                inputProps={{
                  placeholder: 'Select status',
                }}
                fullWidth
                size="small"
                multiple
                disabled={!parameters?.stopSequencesForChangeStatusEnabled}
                onChange={(ev) => {
                  updateMultipleSuggestionPreferences([
                    {
                      name: propertyForCompanyChangedStatus,
                      value: ev.target.value,
                    },
                  ]);
                }}
                MenuProps={{
                  style: { zIndex: 35001 },
                  PaperProps: {
                    sx: {
                      borderRadius: '8px',
                      paddingInline: '6px',
                      border: '1px solid #dadde9',
                      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                      '& .MuiMenuItem-root': {
                        fontSize: '12px',
                        lineHeight: '16px',
                        height: 'fit-content',
                        padding: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        borderRadius: '8px',
                        '&:hover': {
                          background: colors.primaryLight,
                          color: colors.primary,
                        },
                      },
                      '& .Mui-selected': {
                        // color: colors.primary,
                        backgroundColor: 'transparent !important',
                      },
                    },
                  },
                }}
                className="select-suggestions-preferences"
                sx={{
                  fontSize: '14px',
                  boxShadow: 'none',
                  width: '150px',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${colors.grey444}`,
                    borderRadius: '8px',
                  },
                  '.MuiOutlinedInput-notchedOutline:hover': {
                    border: `1px solid ${colors.grey444}`,
                  },
                  '.MuiInputBase-input': {
                    paddingBlock: '6px',
                  },
                  '&:hover': {
                    background: colors.grey6,
                  },
                }}
              >
                {isLoading ? (
                  <FlexDiv>
                    <CircularProgress />
                  </FlexDiv>
                ) : (
                  statuses.map((status, index) => (
                    <MenuItem key={index} value={status.option}>
                      <Checkbox
                        icon={<IconUnchecked />}
                        checkedIcon={<IconChecked color={colors.mainDark} />}
                        checked={
                          (
                            parameters?.[propertyForCompanyChangedStatus] ?? []
                          ).findIndex((option) => option === status.option) >
                            -1 || false
                        }
                        sx={{ padding: '0px 8px 0px 0px !important' }}
                      />
                      {status.label}
                    </MenuItem>
                  ))
                )}
              </Select>
              {(parameters?.[propertyForCompanyChangedStatus] ?? []).length >
                0 &&
                parameters?.stopSequencesForChangeStatusEnabled && (
                  <IconSClose
                    className="remove-selection"
                    onClick={(ev: any) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      updateMultipleSuggestionPreferences([
                        {
                          name: propertyForCompanyChangedStatus,
                          value: [],
                        },
                      ]);
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </SuggestionPreferencesWrapper>
      <SuggestionPreferencesWrapper>
        <CronoSwitch
          checked={!!parameters?.stopSequencesForReplyEnabled}
          onChange={() => {
            updateMultipleSuggestionPreferences([
              {
                name: 'stopSequencesForReplyEnabled',
                value: !parameters?.stopSequencesForReplyEnabled as boolean,
              },
            ]);
          }}
        />
        <div
          className="container-text-suggestion"
          style={{
            color: !parameters?.stopSequencesForReplyEnabled
              ? colors.grey2
              : colors.black,
            paddingTop: 2,
          }}
        >
          <div className="container-text-line" style={{ marginTop: 6 }}>
            Other contacts from the same company has stopped a strategy.
          </div>
        </div>
      </SuggestionPreferencesWrapper>
    </>
  );
};

export default SuggestionPreferencesStrategies;
