import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  .connect-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: ${colors.white};
    border-radius: 20px;
    row-gap: 20px;
  }

  .providers {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .provider-box-no-border,
  .provider-box {
    width: 90%;
    min-height: 90px;
    max-width: 600px;
    min-width: 390px;
    padding: 16px;
    display: flex;

    align-items: center;
    column-gap: 24px;
    border: 1px solid ${colors.grey444};
    border-radius: 16px;

    &--gmail {
      display: flex;
      flex-direction: column;
      padding: 7px;
    }
  }

  .email-provider {
    &-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      border: 1px solid ${colors.blueLightHover};
      border-radius: 16px;
      padding: 14px 15px;

      &__select {
        display: flex;
        justify-content: space-between;
      }
    }

    display: flex;
    align-items: center;
    height: 58px;
    padding: 2px 0;

    &__left {
      display: flex;
      flex-direction: column;
      width: 468px;
    }

    &__buttons {
      align-self: flex-end;
      display: flex;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;

      &:first-child {
        margin-right: 6px;
      }

      &--close {
        border: 1px solid ${colors.grey33};

        &:hover {
          background-color: ${colors.grey6};
        }
      }

      &--check {
        background-color: ${colors.primaryLight};

        &:hover {
          background-color: ${colors.primaryLightHover};
        }
      }
    }

    &__logo {
      &-container {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        margin-right: 24px;
        background-color: ${colors.grey4Hover};
        border-radius: 10px;
      }

      width: 37px;
      height: 37px;
    }

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__select {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 32px;
      margin-left: 8px;
      border-radius: 34px;
      background-color: ${colors.primary};
      color: ${colors.white};
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .icon-none {
    background-color: ${colors.grey4Hover};
  }

  .gmail-provider-top {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px;
    padding: 8px 8px 16px;
  }

  .gmail-provider-bottom {
    width: 100%;
    /* flex-wrap: wrap;
    align-items: center; */
  }

  .gmail-instruction-wrapper {
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    background-color: ${colors.sequenceCardHover};
  }

  .gmail-provider-info {
    display: flex;
  }

  .connection-label-instructions--gmail {
    padding: 0 4px 0 8px;
  }

  .shield-lock-icon {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;
  }

  .gmail-instruction-button {
    align-self: center;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 121px;
    height: 32px;
    border: 1px solid ${colors.grey4Hover};
    border-radius: 34px;
    cursor: pointer;
  }

  .hotizontal-divider-gmail {
    align-self: center;
    min-width: 100%;
    border-top: 1px solid ${colors.grey444};
    padding-bottom: 18px;
  }

  .instruction-wrapper-gmail {
    display: flex;
    padding: 12px 20px 12px 0px;
  }

  .instruction-text {
    width: 100%;
    padding-left: 8px;
  }

  .instructions-arrow-icon-button {
    flex-shrink: 0;
    padding: 0px;
    width: 20px;
    height: 20px;
  }

  .provider-box-no-border {
    border: none;
    padding-inline: 8px;
    width: 100%;
  }
  .icon-logo-container {
    display: flex;
  }
  .icons-buttons-container {
    padding: 4px 12px;
    border-radius: 34px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }
  .connected-email-buttons-wrapper {
    display: flex;
    gap: 8px;
    height: 40px;
    align-items: center;
  }
  .email-icon-button {
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }
  .disconnect-integration {
    border-radius: 999px;
    background: ${colors.inactive};
    color: ${colors.white};
    text-transform: unset;

    &:hover {
      background: ${colors.inactiveHover};
    }
  }
  .connected {
    background-color: ${colors.primaryLight};
    color: ${colors.darkGreen};
    // min-width: 140px;
  }
  .not-connected {
    background-color: ${colors.primary};
    color: ${colors.white};
    min-width: 100px;
  }
  .not-connected:hover {
    background-color: ${colors.primaryDark};
  }
  .connection-status {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .connection-label {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .connection-image {
    height: 56px;
    width: 56px;
    border-radius: 10px;
    padding: 8px;
  }
  .gmail,
  .outlook,
  .aircall,
  .linkedin {
    padding: 0px;
  }
  .linkedin,
  .email {
    border-radius: 10px;
    background-color: ${colors.grey6};
  }
  // Caps in first letter to avoid calling toLowerCase
  .Hubspot {
    background-color: ${colors.hubspotOrange};
  }
  .Salesforce {
    background-color: ${colors.salesforceBlue};
  }
  .Pipedrive {
    background-color: ${colors.pipedriveColor};
  }

  .check-icon {
    border-radius: 999px;
    width: 26px;
    height: 26px;
    padding: 3px;
  }
  .success-icon {
    background: ${colors.callScheduled};
  }
  .success-icon:hover {
    background: ${colors.callScheduledHover};
  }
  .not-success-icon {
    background: ${colors.inactive};
  }
  .not-success-icon:hover {
    background: ${colors.inactiveHover};
  }

  .pointer {
    cursor: pointer !important;
  }

  .integration-select {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .value-select {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: -6px;
  }
  .value-select-icon {
    border-radius: 4px;
    padding: 4px;
    display: flex;
  }
  .icon {
    padding: 4px;
    background-color: ${colors.grey6};
  }
  .hubspot {
    background-color: ${colors.hubspotOrange};
  }
  .salesforce {
    background-color: ${colors.salesforceBlue};
  }
  .pipedrive {
    background-color: ${colors.pipedriveColor};
  }

  .sales-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    background-color: ${colors.grey6};
    padding: 12px;
  }

  .sales-nav-daily-limit {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.grey33};
    border-radius: 8px;
    background-color: ${colors.white};
    padding: 12px;
    min-width: 215px;
  }

  .integrate-crm {
    color: ${colors.darkGreen};
    font-weight: 500;
    cursor: pointer;
  }
  .integrate-crm:hover {
    color: ${colors.darkGreenHover};
  }

  @media screen and (max-width: 768px) {
    .connect-form {
      padding: 20px 0px;
    }
  }
`;

export const CustomEmailProviderSettingsModalWrapper = styled.div`
  max-height: 80%;
  display: flex;
  overflow: hidden;
  width: 810px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.white};
  border-radius: 20px;
  padding: 24px;
  flex-direction: column;
  .inner-content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 0px 16px 16px 16px;
    overflow: hidden;
  }
  .inner-content-container-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-right: 4px;
    flex: 1;
    overflow: auto;
  }
  .inner-content-container-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  .inner-content-container-line-item {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .inner-content-container-line-item-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
  .inner-content-container-block {
    display: flex;
    width: 100%;
    gap: 16px;
    flex-direction: column;
  }
  .inner-content-container-block-title {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .inner-content-container-option-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 16px;
    gap: 24px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
  }
  .inner-content-footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    width: 100%;
  }
  .connect-email-provider-success {
    background: ${colors.primaryLight};
    border-radius: 16px;
    display: flex;
    gap: 10px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${colors.primaryDark};
    padding: 4px 8px;
  }
  .connect-email-provider-error-with-icon,
  .connect-email-provider-error {
    background: ${colors.inactiveLight};
    border-radius: 16px;
    display: flex;
    gap: 10px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${colors.inactive};
  }
  .connect-email-provider-error-with-icon {
    padding: 4px 8px 4px 4px;
  }
  .connect-email-provider-error {
    padding: 4px 8px;
  }
`;
