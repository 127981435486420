import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import RemainingCreditsHeaderIcon from './remainingEmailCredits';
import VerificationStatusType from 'crono-fe-common/types/enums/verificationStatusType';
import {
  LinkedinFilterValue,
  LinkedinFilterValueFreeSolo,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { AccountLinkedin } from 'crono-fe-common/types/accountLinkedin';
import { ProspectLinkedin } from 'crono-fe-common/types/prospectLinkedin';
import { CompanyScrape } from 'crono-fe-common/types/companyScrape';
import EditPencilIcon from 'crono-fe-common/icons/Icon-Edit-pencil';
import { useAuth } from 'context/auth';

export interface CurrentCompanySearchContact {
  id: string;
  label: string;
  objectId: string;
}

export interface SearchContactTabFiltersType {
  keywords?: string | null;
  currentCompanies?: CurrentCompanySearchContact[] | null;
  currentCompanyHeadcount?: LinkedinFilterValue[] | null;
  companyHeadquarters?: LinkedinFilterValue[] | null; // see FeConstants::linkedinGeoUrns
  function?: LinkedinFilterValue[] | null;
  persona?: LinkedinFilterValue[] | null;
  jobTitle?: LinkedinFilterValueFreeSolo[] | null;
  seniority?: LinkedinFilterValue[] | null;
  yearsInCurrentPosition?: LinkedinFilterValue[] | null;
  industry?: LinkedinFilterValue[] | null; // see FeConstants:linkedinIndustries
  geography?: LinkedinFilterValue[] | null;
  accountHasBuyerIntent?: boolean | null;
  categoryInterest?: LinkedinFilterValue[] | null;

  //Non salesNav filters
  title?: string[] | null;
  geoUrn?: LinkedinFilterValue[] | null;
  companyPublicId?: string | null;
  companyName?: string | null;
}

export interface HeadCellSearchTab {
  id: string;
  label: any;
  sortable: boolean;
  isSticky: boolean;
  stickyOffsetLeft?: number;
  stickyOffsetRight?: number;

  width: number | string;
  minWidth: number | string;
  padding?: number;
  borderLeft?: boolean;
  borderRight?: boolean;
  tooltip?: string;
}

export interface IGetSearchHeadCellsProps {
  hasSalesNav: boolean;
  triggerEditColumnsPopup: () => void;
  userPreferencesColumns: string;
}

export const getContactSearchHeadCells = ({
  hasSalesNav,
  triggerEditColumnsPopup,
  userPreferencesColumns,
}: IGetSearchHeadCellsProps) => {
  const columns = userPreferencesColumns.split(',');
  const defaultCells: HeadCellSearchTab[] = [
    {
      id: 'name',
      label: 'Contact Name',
      sortable: false,
      isSticky: true,
      minWidth: 260,
      width: 260,
      stickyOffsetLeft: 0,
      borderRight: true,
    },
    ...(!hasSalesNav
      ? [
          {
            id: 'location',
            label: 'Location',
            sortable: false,
            isSticky: false,
            minWidth: 250,
            width: '25%',
            padding: 2,
          },
        ]
      : []),
    {
      id: 'companyName',
      label: 'Company',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: '15%',
    },
    {
      id: 'jobTitle',
      label: 'Job title',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: '15%',
    },
    ...(hasSalesNav
      ? [
          {
            id: 'location',
            label: 'Location',
            sortable: false,
            isSticky: false,
            minWidth: 250,
            width: '25%',
            padding: 2,
          },
        ]
      : []),
    {
      id: 'companyIndustry',
      label: 'Industry',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: '15%',
    },
    {
      id: 'companyHeadquarter',
      label: 'Headquarter',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: '15%',
    },
    {
      id: 'companyWebsite',
      label: 'Website',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: '15%',
    },
    {
      id: 'companySize',
      label: 'Size',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: '15%',
    },
    {
      id: 'companyDescription',
      label: 'Description',
      sortable: false,
      isSticky: false,
      minWidth: 250,
      width: '15%',
    },
    // {
    //   id: 'CompanyName',
    //   label: (
    //     <span
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         gap: 4,
    //       }}
    //     >
    //       <Typography
    //         fontSize={'inherit'}
    //         lineHeight={'16px'}
    //         fontWeight={500}
    //         color={colors.grey11}
    //       >
    //         Email
    //       </Typography>
    //       <RemainingCreditsHeaderIcon type="email" />
    //     </span>
    //   ),
    //   sortable: false,
    //   isSticky: false,
    //   minWidth: 160,
    //   width: 160,
    // },
    // {
    //   id: 'status',
    //   label: (
    //     <span
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         gap: 4,
    //       }}
    //     >
    //       <Typography
    //         fontSize={'inherit'}
    //         lineHeight={'16px'}
    //         fontWeight={500}
    //         color={colors.grey11}
    //       >
    //         Phone
    //       </Typography>
    //       <RemainingCreditsHeaderIcon type="phone" />
    //     </span>
    //   ),
    //   sortable: false,
    //   isSticky: false,
    //   minWidth: 160,
    //   width: 160,
    // },

    {
      id: 'linkedinLink',
      label: 'Link',
      sortable: false,
      isSticky: false,
      minWidth: 200,
      width: '60%',
    },
    //Empty for add button
    {
      id: 'addButton',
      label: (
        <>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
            }}
            className="edit-columns-btn"
            onClick={triggerEditColumnsPopup}
          >
            <Typography
              fontSize={'inherit'}
              lineHeight={'16px'}
              fontWeight={500}
              color={colors.green}
            >
              Edit Columns
            </Typography>

            <EditPencilIcon width="16px" height="16px" color={colors.green} />
          </span>
        </>
      ),
      sortable: false,
      isSticky: true,
      stickyOffsetRight: 0,
      minWidth: 106,
      width: 106,
      borderLeft: true,
    },
  ];
  const toRet = [
    defaultCells[0],
    ...defaultCells.filter((cell) => columns.includes(cell.id)),
    defaultCells[defaultCells.length - 1],
  ];
  return toRet;
};

export type InformationScrapedResult = 'no' | 'found' | 'not-found';

export type ContactForSearchTable = {
  id: string; //currently publicId for linkedin, leadId for salesNav
  leadId: string | null;
  publicId?: string | null;
  profileId?: string | null;
  pictureUrl: string | null;
  firstName: string;
  lastName: string;
  fullName: string;
  //Actual current title in the current company
  title: string | null;
  //This is the description that the person can customize in linkedin. This is retrieved from normal linkedin and used at the beginning, since we don't have the current title
  linkedinDescription?: string | null;
  location: string;
  companyName: string | null;
  companyNumericId: string | null;
  email: string | null;
  emailScraped: InformationScrapedResult;
  emailStatus: VerificationStatusType | null;
  phone: string | null;
  phoneScraped: InformationScrapedResult;
  url: string;
  urlForRedirect: string;

  //Property to identify the possible similar contacts and companies that we retrieved from the database
  similarCompanies?: AccountLinkedin[] | null;
  similarContacts?: ProspectLinkedin[] | null;
  correct?: boolean;

  //Current company already present in the database that can be used to retrieve information (email, phone) faster, without calling linkedin
  currentCompanyAlreadyInDatabase?: CompanyScrape | null;
};
