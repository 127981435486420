import { ConditionalFlowButtonWrapper } from './style';
import classNames from 'classnames';
import { Tooltip, Typography } from '@mui/material';
import ConditionalForkIcon from 'crono-fe-common/icons/Condition-fork-icon';
import { colors } from 'crono-fe-common/theme';
import TrashDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import React from 'react';
import DelayButton from '../delayButton';
import { makeStyles } from '@mui/styles';

interface IConditionalFlowButton {
  id?: string;
  onClickButton?: () => void;
  onDelete?: () => void;
  disabledText?: string;
  delay?: number;
  decreaseDelay?: () => void;
  increaseDelay?: () => void;
  delayTooltipTitle?: string;
  previewOnly?: boolean;
}

const ConditionalFlowButton = ({
  id,
  onClickButton,
  onDelete,
  delay,
  decreaseDelay,
  increaseDelay,
  disabledText,
  delayTooltipTitle,
  previewOnly = false,
}: IConditionalFlowButton) => {
  const activated =
    delay !== undefined &&
    decreaseDelay !== undefined &&
    increaseDelay !== undefined;

  return (
    <ConditionalFlowButtonWrapper id={id}>
      <div
        className={classNames('conditional-button', {
          'conditional-button-disabled': !!disabledText,
          'conditional-button-activated': activated,
          'conditional-button-initial': !activated && !disabledText,
        })}
      >
        <Tooltip title={disabledText} placement="top" arrow>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
            onClick={() => {
              if (previewOnly || disabledText) return;

              if (onClickButton) onClickButton();
            }}
          >
            <ConditionalForkIcon
              color={disabledText ? colors.grey2 : colors.darkYellow}
            />
            <Typography
              color={disabledText ? colors.grey2 : colors.darkYellow}
              fontSize={'12px'}
              fontWeight={500}
            >
              {!previewOnly ? (
                <>Add condition{activated ? ':' : ''}</>
              ) : (
                <>Condition{activated ? ':' : ''}</>
              )}
            </Typography>

            {activated && (
              <>
                <Typography
                  color={colors.black}
                  fontSize={'12px'}
                  fontWeight={500}
                >
                  LinkedIn invitation accepted within
                </Typography>

                <DelayButton
                  delay={delay}
                  decreaseDelay={decreaseDelay}
                  increaseDelay={increaseDelay}
                  lightTheme={true}
                  tooltipTitle={delayTooltipTitle}
                  disabled={!!disabledText || previewOnly}
                />
              </>
            )}
          </div>
        </Tooltip>

        {onDelete && !previewOnly ? (
          <span
            onClick={onDelete}
            style={{ display: 'flex', cursor: 'pointer' }}
          >
            <TrashDeleteIcon />
          </span>
        ) : null}
      </div>
    </ConditionalFlowButtonWrapper>
  );
};

export default ConditionalFlowButton;
