import moment from 'moment';
import React from 'react';

import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { colors } from 'crono-fe-common/theme';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { TaskCardWrapper } from './style';
import {
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import TooltipMoreMenu from './tooltipMoreMenu';
import { prospectName } from 'utils/fe-utils';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import { ReactComponent as ErrorIndicator } from 'crono-fe-common/icons/Error-Indicator-S.svg';
import ThreeDotsIcon from 'crono-fe-common/icons/Icon-Three-dots';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import { useAuth } from 'context/auth';

interface IProps {
  task: TaskTodo;
  handleShowSequenceInstance?: (task: TaskTodo) => void;
  handleSkipTask?: (id: number) => void;
  handleOpenDateCalendar?: (task: TaskTodo) => void;
  //Called by the company tab, so do not show the company name but the date instead
  companyTab?: boolean;

  showSelected?: boolean;
  handleSelect?: (id: number) => void;

  openManualResume?: () => void;

  overdue?: boolean | null;
  dealTabTooltip?: boolean;
}

export const MoreMenuTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & {
    maxHeight?: number | string;
    maxWidth?: number | string;
    width?: number | string;
    height?: number | string;
  }) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(({ maxHeight, maxWidth, width, height }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.white,
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    maxWidth: maxWidth ? maxWidth : 350,
    maxHeight: maxHeight ? maxHeight : undefined,
    width: width ? width : undefined,
    height: height ? height : undefined,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    zIndex: 10,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

const TaskCard = ({
  task,
  handleShowSequenceInstance,
  handleOpenDateCalendar,
  handleSkipTask,
  companyTab,
  showSelected,
  handleSelect,
  openManualResume,
  overdue,
  dealTabTooltip,
}: IProps) => {
  const { hasOpportunities } = useAuth();
  const openDateCalendar = () => {
    if (handleOpenDateCalendar) {
      handleOpenDateCalendar(task);
    }
  };
  const skipTask = () => {
    if (handleSkipTask) handleSkipTask(task.id);
  };

  const renderTaskTypeIcon = (task: TaskTodo) => {
    let icon = null;
    switch (task.type) {
      case TaskTodoType.CALL:
        icon = (
          <CallIcon
            className={'type-icon call'}
            color={colors.callScheduledHover}
          />
        );
        break;
      case TaskTodoType.EMAIL:
        icon = (
          <EmailIcon
            className={'type-icon email'}
            color={colors.inactiveHover}
          />
        );
        break;
      case TaskTodoType.LINKEDIN:
        if (task.subtype === TaskTodoSubtype.LinkedinInvitation) {
          icon = <LinkedinInvitationIcon className={'linkedin-icon'} />;
        } else if (task.subtype === TaskTodoSubtype.LinkedinVoiceNote) {
          icon = (
            <LinkedinIconInvoice
              className={'linkedin-icon'}
              viewBox="2 3 17 17"
              color={colors.blueHover}
            />
          );
        } else {
          icon = (
            <LinkedinIconMessage
              className={'linkedin-icon'}
              color={colors.blueHover}
            />
          );
        }
        break;
      case TaskTodoType.IN_MAIL:
        icon = (
          <LinkedinIconInMailCompass
            className={'type-icon in-mail'}
            color={colors.purpleHover}
            viewBox="2 2 20 20"
          />
        );
        break;
      default:
        icon = (
          <EmailIcon
            className={'type-icon email'}
            color={colors.inactiveHover}
          />
        );
        break;
    }
    return (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className={
          task.type === TaskTodoType.LINKEDIN ? 'type-icon linkedin-icon' : ''
        }
      >
        <Tooltip
          arrow
          title={
            task.automatic &&
            'Task is automatic, it can not be performed manually'
          }
          placement="top"
        >
          {icon}
        </Tooltip>
      </span>
    );
  };

  const checkTaskType = (task: TaskTodo) => {
    let type = task.type as string;
    if (task.type === TaskTodoType.LINKEDIN) {
      if (task.subtype === TaskTodoSubtype.LinkedinInvitation) {
        type = 'Invitation';
      } else {
        type = 'Message';
      }
    }
    return type;
  };

  return (
    <TaskCardWrapper
      hasDeal={!!task.opportunityId && !dealTabTooltip}
      style={{
        border: dealTabTooltip ? 'none' : undefined,
        borderBottom:
          task.opportunityId && dealTabTooltip
            ? `1px solid ${colors.grey4}`
            : undefined,
        borderRadius: dealTabTooltip ? '0px' : '8px',
      }}
    >
      <div
        className="inner-task-card"
        onClick={() => {
          if (handleShowSequenceInstance && task.sequenceInstanceId)
            handleShowSequenceInstance(task);
          else if (openManualResume) openManualResume();
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {renderTaskTypeIcon(task)}
          <div className="sequence-type-container">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '8px',
              }}
            >
              <Typography
                color={overdue === true ? colors.inactiveHover : colors.black}
                fontSize={'11px'}
                fontWeight={400}
                minWidth={'70px'}
              >
                {(companyTab === undefined ? false : companyTab) ? (
                  moment(task.activityDate).format('ll')
                ) : (
                  <Typography noWrap>{task.account.name}</Typography>
                )}
              </Typography>
              <div style={{ display: 'flex', gap: '4px' }}>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  noWrap
                  maxWidth={'160px'}
                >
                  {prospectName(task.prospect)}
                </Typography>
                {task.automatic && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span className="auto-label">Auto</span>
                  </div>
                )}
                {task.automationError && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      arrow
                      title={
                        'An unexpected error occurred during the automatic submission'
                      }
                    >
                      <ErrorIndicator />
                    </Tooltip>
                  </div>
                )}
                {!dealTabTooltip && (
                  <MoreMenuTooltip
                    width={130}
                    height={70}
                    title={
                      <TooltipMoreMenu
                        skipTask={skipTask}
                        openDateCalendar={openDateCalendar}
                      />
                    }
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                    }}
                    PopperProps={{
                      disablePortal: true,
                    }}
                  >
                    <div style={{ width: '16px', height: '16px' }}>
                      <ThreeDotsIcon
                        style={{ width: '16px', height: '16px' }}
                        color={colors.grey1}
                      />
                    </div>
                  </MoreMenuTooltip>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {task.sequenceInstanceId ? (
                <Typography
                  fontSize={'12px'}
                  fontWeight={500}
                  color={colors.black}
                  noWrap
                  maxWidth={'120px'}
                >
                  {`${checkTaskType(task)}: ${task.order}/${task.total}`}
                </Typography>
              ) : (
                <Tooltip
                  arrow
                  title={task.subject ?? task.type}
                  enterDelay={300}
                  enterNextDelay={300}
                  placement="top"
                >
                  <Typography
                    fontSize={'12px'}
                    fontWeight={500}
                    color={colors.black}
                    noWrap
                    maxWidth={task.sequenceInstanceId ? '120px' : '250px'}
                  >
                    {`${task.subject ?? task.type}`}
                  </Typography>
                </Tooltip>
              )}

              <Typography
                fontSize={12}
                fontWeight={500}
                noWrap
                color={colors.grey11}
              >
                {task.sequenceInstanceId !== null
                  ? task.sequenceInstance?.name
                  : 'Task'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      {task.opportunityId && !dealTabTooltip && hasOpportunities ? (
        <Typography
          fontSize={'10px'}
          fontWeight={500}
          className="task-opportunity-badge"
        >
          Deal
        </Typography>
      ) : null}
    </TaskCardWrapper>
  );
};

export default TaskCard;
