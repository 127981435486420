import React, { useState } from 'react';
import { AccountTabWrapper } from '../style';
import ActivitiesBox from '../accountView/activitiesBox';
import { ReactComponent as ProspectActivityIcon } from 'crono-fe-common/icons/Icon-Activity-Clock.svg';
import {
  tooltipClasses,
  ClickAwayListener,
  TooltipProps,
  Tooltip,
} from '@mui/material';

import styled from 'styled-components';
import { colors } from 'crono-fe-common/theme';

export const CustomTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & {
    minHeight?: number | string;
    width?: number | string;
    height?: number | string;
    maxHeight?: number | string;
  }) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(({ minHeight, height, maxHeight }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    margin: '0px 16px 0px 0px',
    width: 330,
    height: height ? height : 250,
    maxHeight: maxHeight ? maxHeight : 250,
    minHeight: minHeight ? minHeight : undefined,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    display: 'flex',
    maxWidth: 350,
  },
}));

interface IProps {
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  selectedProspectId: string | null;
}

const ActivitiesBoxInsideActivityView = ({
  setTabValue,
  selectedProspectId,
}: IProps) => {
  const [statusTooltipOpen, setStatusTooltipOpen] = useState<boolean>(false);
  const activities = (
    <ClickAwayListener onClickAway={() => setStatusTooltipOpen(false)}>
      <AccountTabWrapper
        padding={'0px 1px'}
        borderRadius={'12px'}
        overflow={'auto'}
        boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.10)'}
      >
        <div style={{ overflow: 'auto', height: '100%', paddingBlock: '8px' }}>
          <ActivitiesBox
            setTabValue={setTabValue}
            prospectId={selectedProspectId}
            closeTooltip={() => setStatusTooltipOpen(false)}
          />
        </div>
      </AccountTabWrapper>
    </ClickAwayListener>
  );
  return (
    <CustomTooltip
      placement="bottom"
      title={activities}
      open={statusTooltipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <ActivityIconWrapper
        onClick={() => setStatusTooltipOpen((prev) => !prev)}
      >
        Activities
        <ProspectActivityIcon />
      </ActivityIconWrapper>
    </CustomTooltip>
  );
};

export default ActivitiesBoxInsideActivityView;

const ActivityIconWrapper = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1px solid ${colors.grey444};
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
  cursor: pointer;
  background-color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  line-height: '18px';
  gap: 8px;
  :hover {
    background-color: ${colors.blueLightHover};
  }
`;
