import { ENDPOINTS } from 'config/endpoints';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SendResetPasswordEmailDTO } from 'crono-fe-common/types/DTO/sendResetPasswordEmailDTO';

export default function useSendResetPasswordEmail() {
  const sendResetPasswordEmail = ({ userEmail }: SendResetPasswordEmailDTO) => {
    const request: Request = {
      url: ENDPOINTS.auth.sendResetPasswordEmail,
      config: {
        method: 'post',
        data: { userEmail },
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    SendResetPasswordEmailDTO
  >(sendResetPasswordEmail, {});

  return { isSuccess, isError, mutate, ...rest };
}
