import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const NotTagPropertyWrapper = styled.div`
  flex: 1;
  .field-input {
    width: 100%;
    border-radius: 8px;
    padding: 4px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-input {
      padding: 0px;
    }
    & .MuiSelect-select {
      padding: 0px 26px 0px 4px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & .MuiAutocomplete-inputRoot {
      padding: 0px;
    }
  }
  .no-padding {
    padding: 0px;
  }
  .field-input:hover {
    background: ${colors.grey6};
  }
  .text-area {
    display: flex;
    width: 100%;
    min-height: 30px !important;
    max-height: 90px !important;
    padding: 5px;
    border: none;
    resize: none;
    border-radius: 8px;
    font-family: inherit;
    font-size: 14px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #a2a2a2;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
    }
  }
  .text-area:hover {
    background: ${colors.grey6};
  }
  .no-min-height {
    min-height: unset !important;
  }
  .text-field {
    width: 100%;
  }
  .editable-div {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 26px !important;
    height: 30px !important;
    padding-left: 4px;
  }
  .editable-div > span > svg {
    opacity: 0;
  }
  .editable-div:hover > span > svg {
    opacity: 1;
  }
  .editable-div > div > span {
    opacity: 0;
  }
  .editable-div > div > div > input:focus ~ .editable-div {
    background: ${colors.inactive};
  }
  .editable-div:hover {
    background: ${colors.grey6};
  }
  .editable-div:hover > div > span {
    opacity: 1;
  }
`;

export const StyledEPWrapper = styled.div`
  .text-area {
    display: flex;
    width: 100%;
    min-height: 30px !important;
    max-height: 90px !important;
    padding: 5px;
    border: none;
    resize: none;
    border-radius: 8px;
    font-family: inherit;
    font-size: 14px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #a2a2a2;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
    }
  }
  .text-area:hover {
    background: ${colors.grey6};
  }
`;
