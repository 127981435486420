import { extensionId } from 'config/cronoExtension';
import { useQuery } from 'react-query';

export enum CronoExtensionStatus {
  NOT_CHROME_BASED_BROWSER = 'notChromeBasedBrowser',
  NOT_INSTALLED = 'notInstalled',
  // this is a bit hacky: we use the same name as authError response that can come from extension
  INSTALLED_WITHOUT_LINKEDIN_COOKIE = 'authError',
  INSTALLED_WITH_LINKEDIN_COOKIE = 'installedWithLinkedinCookie',
}

export function useGetExtensionStatus() {
  return useQuery({
    queryKey: 'getExtensionStatus',
    queryFn: async () => {
      const promise = new Promise<{
        status: CronoExtensionStatus;
        hasSalesNavCookie?: boolean;
      }>((resolve) => {
        if (!window.chrome) {
          resolve({ status: CronoExtensionStatus.NOT_CHROME_BASED_BROWSER });
          return;
        }

        try {
          chrome.runtime.sendMessage(
            extensionId,
            {
              methodName: 'verifyLogin',
            },
            (callResult: any) => {
              if (callResult?.status !== 'callSuccess') {
                resolve({ status: CronoExtensionStatus.NOT_INSTALLED });
              }
              resolve(
                callResult?.result?.hasLinkedinCookie
                  ? {
                      status:
                        CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE,
                      hasSalesNavCookie: callResult?.result?.hasSalesNavCookie,
                    }
                  : {
                      status:
                        CronoExtensionStatus.INSTALLED_WITHOUT_LINKEDIN_COOKIE,
                      hasSalesNavCookie: callResult?.result?.hasSalesNavCookie,
                    },
              );
            },
          );
        } catch (e) {
          resolve({ status: CronoExtensionStatus.NOT_INSTALLED });
        }
      });
      return await promise;
    },
  });
}
