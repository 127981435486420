import React from 'react';
import IProps from './interface';

const IconVideo = ({ color, className }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.875 12.4722H2.75C1.78322 12.4722 1 11.689 1 10.7222V5.27777C1 4.31099 1.78322 3.52777 2.75 3.52777H8.875C9.84178 3.52777 10.625 4.31099 10.625 5.27777V10.7222C10.625 11.689 9.84178 12.4722 8.875 12.4722Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 8.85314L13.5767 11.2285C14.1491 11.6897 15 11.2821 15 10.5471V5.4527C15 4.7177 14.1491 4.31014 13.5767 4.77136L10.625 7.1467"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconVideo;
