import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export default function useGetTasksAutomationErrorsMetaData() {
  const request: Request = {
    url: ENDPOINTS.task.automationErrors,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.task.automationErrors],
    queryFn: () => client(request),
    refetchInterval: 60_000,
  };

  const { data: response, ...rest } = useQuery<
    Response<{ quantity: number; lastFailedAttempt?: string }>,
    CronoError
  >(requestConfig);

  return { data: response?.data?.data, ...rest };
}
