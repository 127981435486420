import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { ReactComponent as LinkedinInvalidUrlIcon } from '../../../assets/images/Linkedin_Invalid_url.svg';
import { Typography } from '@mui/material';

const LinkedinInvalidUrl = ({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}) => {
  return (
    <FlexDiv direction="column" justifyContent="center" alignItems="center">
      <FlexDiv
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap="20px"
      >
        <LinkedinInvalidUrlIcon />
        <Typography
          fontSize="16px"
          fontWeight={600}
          textAlign="center"
          lineHeight="24px"
        >
          The Linkedin URL of this contact is invalid.
          <br />
          Please change it and try again.
        </Typography>

        <p style={{ textAlign: 'center' }}>
          This contact's Linkedin URL is
          <br />
          not valid and caused an error
        </p>

        <MainButton style={{ width: '130px' }} onClick={handleCloseModal}>
          Change
        </MainButton>
      </FlexDiv>
    </FlexDiv>
  );
};
export default LinkedinInvalidUrl;
