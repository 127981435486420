import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { CreateStrategyModalWrapper } from './style';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import IlustrationCreateFromScratch from 'assets/images/IllustrationCreateScratch.png';
import IlustrationCreateFromTemplate from 'assets/images/IllustrationDublicateLibrary.png';
import classNames from 'classnames';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import TypeCard from 'pages/settings/Signature/typeCard';
import { colors } from 'crono-fe-common/theme';

export enum EStrategyCreationType {
  Scratch,
  Library,
}

interface IProps {
  onClose: () => void;
  onNext: (strategy: EStrategyCreationType) => void;
}

export const CreateStrategyModal: React.FC<IProps> = ({ onClose, onNext }) => {
  const [selectedStrategyCreation, setSelectedStrategyCreation] =
    useState<EStrategyCreationType | null>(null);

  const handleChooseStrategyCreation = useCallback(
    (strategy: EStrategyCreationType) => {
      if (selectedStrategyCreation === strategy) return;
      setSelectedStrategyCreation(strategy);
    },
    [selectedStrategyCreation],
  );

  return (
    <BlurBackgroundDivFixed onClick={onClose}>
      <CreateStrategyModalWrapper>
        <div onClick={(e) => e.stopPropagation()} className="create-strategy">
          <div className="create-strategy__cross">
            <CloseTabButton
              style={{ paddingRight: '24px' }}
              close={() => {
                onClose();
              }}
            />
          </div>

          <div className="create-strategy__title">
            <Typography
              fontSize={24}
              fontWeight={700}
              lineHeight={'30px'}
              color={colors.black}
            >
              How would you like to create the strategy?
            </Typography>
          </div>

          <div className="create-strategy__cards">
            <div className="create-strategy__card">
              <TypeCard
                active={
                  selectedStrategyCreation === EStrategyCreationType.Scratch
                }
                onClick={() =>
                  handleChooseStrategyCreation(EStrategyCreationType.Scratch)
                }
                image={IlustrationCreateFromScratch}
                title={'Create from scratch'}
                subtitle={'Build your strategy step by step'}
                subtitleMaxWidth={170}
              />
            </div>

            <div className="create-strategy__card">
              <TypeCard
                active={
                  selectedStrategyCreation === EStrategyCreationType.Library
                }
                onClick={() =>
                  handleChooseStrategyCreation(EStrategyCreationType.Library)
                }
                image={IlustrationCreateFromTemplate}
                title={'Choose from library'}
                subtitle={'Copy an already existing strategy'}
              />
            </div>
          </div>

          <div
            onClick={() => {
              if (selectedStrategyCreation === null) return;
              onNext(selectedStrategyCreation);
            }}
            className={classNames('create-strategy__next', {
              'create-strategy__next--disabled':
                selectedStrategyCreation === null,
            })}
          >
            Next
          </div>
        </div>
      </CreateStrategyModalWrapper>
    </BlurBackgroundDivFixed>
  );
};
