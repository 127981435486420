import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ExportCompaniesModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 476px;
  padding: 48px;
  background-color: ${colors.white};
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .cross {
    position: absolute;
    top: 24px;
    right: 0;
  }

  .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px 12px;
    width: 100%;
    margin-left: 8px;

    .option {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .checkbox {
        padding: 0px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .export-amount-container {
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 0 10px 0 16px;
    height: 42px;
    gap: 12px;
    align-items: center;
    text-align: left;

    .info-icon {
      border: 1px solid ${colors.grey1};
      border-radius: 50%;
      path {
        stroke: ${colors.grey1};
      }
      rect {
        fill: transparent;
      }
    }
  }

  .buttons {
    display: flex;
    align-self: flex-end;
    margin-left: 4px;
  }
`;
