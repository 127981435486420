import { ENDPOINTS } from 'config/endpoints';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { LogLinkedinInsert } from 'crono-fe-common/types/logLinkedinInsert';

export default function useLogLinkedin() {
  const queryClient = useQueryClient();

  const insertLinkedin = (logLinkedin: LogLinkedinInsert) => {
    const request: Request = {
      url: `${ENDPOINTS.events.linkedin}`,
      config: {
        method: 'post',
        data: { data: logLinkedin },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    LogLinkedinInsert
  >(insertLinkedin, {
    onSettled: (_r, _e, variables) =>
      invalidateLogLinkedinQueries(queryClient, variables),
  });

  return { data, mutate, ...rest };
}

export function createLogLinkedinRequest(
  logLinkedin: LogLinkedinInsert,
): Request {
  return {
    url: `${ENDPOINTS.events.linkedin}`,
    config: {
      method: 'post',
      data: { data: logLinkedin },
    },
  };
}

export function invalidateLogLinkedinQueries(
  queryClient: QueryClient,
  variables?: LogLinkedinInsert,
) {
  return Promise.all([
    queryClient.invalidateQueries([ENDPOINTS.events.task.main]),
    queryClient.invalidateQueries([ENDPOINTS.task.search]),
    queryClient.invalidateQueries([ENDPOINTS.task.aggregate]),
    queryClient.invalidateQueries([ENDPOINTS.task.total]),
    //WARNING: this is commented since the next task is called from the context of multiple execution manually, to improve synchronization
    // queryClient.invalidateQueries([ENDPOINTS.task.next]),
    queryClient.invalidateQueries([ENDPOINTS.task.overdue]),
    queryClient.invalidateQueries([ENDPOINTS.user.onboarding.features]),
    queryClient.invalidateQueries([ENDPOINTS.task.multipleLinkedin]),
    queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]),
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey.toString().startsWith(ENDPOINTS.sequence.main),
    }),
    queryClient.invalidateQueries([ENDPOINTS.sequence.search]),
    queryClient.invalidateQueries([ENDPOINTS.sequence.detail]),
    queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]),
    queryClient.invalidateQueries(
      variables
        ? [ENDPOINTS.account.main, variables.accountId]
        : [ENDPOINTS.account.main],
    ),
    queryClient.invalidateQueries([ENDPOINTS.account.search.main]),
  ]);
}
