import { ENDPOINTS } from 'config/endpoints';
import { useQuery, UseQueryOptions } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Sequence } from 'crono-fe-common/types/sequence';

interface IGetLibraryStrategy {
  strategyId: string;
}

export default function useGetLibraryStrategy(
  { strategyId }: IGetLibraryStrategy,
  enabled = true,
) {
  const request: Request = {
    url: `${ENDPOINTS.sequence.libraryOne}?StrategyId=${strategyId}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.sequence.libraryOne, strategyId],
    queryFn: () => client(request),
    enabled,
    staleTime: 30 * 60 * 1000,
  };

  return useQuery<Response<Sequence>, CronoError>(requestConfig);
}
