import { FC } from 'react';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import GenerateTemplateForm from './GenerateTemplateForm';
import { GenerateWrapper } from './style';
import { Typography } from '@mui/material';
import { TemplateStep } from 'crono-fe-common/types/enums/templateStep';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';

interface GenerateTemplateModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  step: TemplateStep;
  setStep: React.Dispatch<React.SetStateAction<TemplateStep>>;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  templateType: TemplateType;
  setTemplateType: React.Dispatch<React.SetStateAction<TemplateType>>;
  templateFormik: any;
}

const GenerateTemplateModal: FC<GenerateTemplateModalProps> = ({
  setOpen,
  step,
  setStep,
  language,
  setLanguage,
  templateType,
  setTemplateType,
  templateFormik,
}) => {
  return (
    <GenerateWrapper>
      <div className="main-container">
        <CloseTabButton
          style={{ paddingRight: '18px' }}
          close={() => setOpen(false)}
        />
        <div className="template-management-container">
          <Typography
            fontSize={24}
            fontWeight={700}
            style={{ marginBottom: '30px' }}
          >
            Generate template
          </Typography>
          <div>
            <GenerateTemplateForm
              setOpen={setOpen}
              step={step}
              setStep={setStep}
              language={language}
              setLanguage={setLanguage}
              templateType={templateType}
              setTemplateType={setTemplateType}
              templateFormik={templateFormik}
            />
          </div>
        </div>
      </div>
    </GenerateWrapper>
  );
};

export default GenerateTemplateModal;
