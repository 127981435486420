import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SequenceOptionsWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 8px;

  .option-button-delete,
  .option-button,
  .option-button-stop,
  .option-button-resume {
    text-transform: unset !important;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 8px;
    padding: 8px;
    color: ${colors.black};
  }
  .option-button:hover {
    color: ${colors.primary};
    background-color: ${colors.primaryLight};
  }
  .option-button:hover > span > svg > path,
  .option-button:hover > span > svg > circle,
  .option-button:hover > span > svg > rect {
    stroke: ${colors.primary};
  }

  .option-button-delete {
    color: ${colors.inactiveHover};
  }
  .option-button-delete:hover {
    background-color: ${colors.inactiveLight};
  }
  .option-button-stop {
    color: ${colors.orangeDark};
    .stop-strategy-rectangle-icon {
      width: 24px;
      height: 24px;
      background: ${colors.orangeLight};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .stop-strategy-rectangle {
      }
    }
  }
  .option-button-stop.disabled {
    filter: grayscale(1);
  }
  .option-button-stop:hover {
    background-color: ${colors.orangeLight};
  }
  .option-button-resume {
    color: ${colors.callScheduledHover};
    .resume-strategy-triangle-icon {
      width: 24px;
      height: 24px;
      background: ${colors.callScheduledLight};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .resume-strategy-triangle {
        width: 16px;
        height: 16px;
      }
    }
  }
  .option-button-resume.disabled {
    filter: grayscale(1);
  }
  .option-button-resume:hover {
    background-color: ${colors.callScheduledLight};
  }
  .divider {
    border-top: 1px solid ${colors.grey4};
  }
  .option-button-icon-16 {
    width: 16px;
    height: 16px;
  }
`;
