import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import {
  ExternalProperty,
  ExternalPropertyValueType,
  TableType,
} from 'crono-fe-common/types/externalProperty';

export default function useGetExternalPropertyCRM(
  TableType: TableType | null,
  ValueType: ExternalPropertyValueType | null,
  isStatus: boolean | null = null,
  enabled = true,
) {
  const params: any = {};

  if (TableType) {
    params['TableType'] = TableType;
  }
  if (ValueType) {
    params['Type'] = ValueType;
  }
  if (isStatus) {
    params['isStatus'] = isStatus;
  }
  const queryString = new URLSearchParams(params).toString();

  const request: Request = {
    url: ENDPOINTS.externalProperty.crm + '?' + queryString,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.externalProperty.crm, queryString],

    queryFn: () => client(request),
    enabled,
  };

  const { data, ...rest } = useQuery<
    ResponsePaginated<ExternalProperty[]>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
