import React, { createContext, FC, useContext, useState } from 'react';
import { UpgradeModalContextType } from './types';
import { useJuneAnalytics } from '../june';

export const UpgradeModalContext = createContext<
  UpgradeModalContextType | undefined
>(undefined);
UpgradeModalContext.displayName = 'UpgradeModalContext';

const UpgradeModalProvider: FC<{ children: any }> = ({ children }) => {
  const [isUpgradeModalVisible, setIsUpgradeModalVisible] =
    useState<boolean>(false);
  const analytics = useJuneAnalytics();

  return (
    <UpgradeModalContext.Provider
      value={{
        openModal: () => setIsUpgradeModalVisible(true),
        closeModal: () => {
          if (analytics) {
            analytics.track('subscription-popup-closed-clicked', {});
          }
          setIsUpgradeModalVisible(false);
        },
        isUpgradeModalVisible,
      }}
    >
      {children}
    </UpgradeModalContext.Provider>
  );
};

export function useUpgradeModal() {
  const context = useContext(UpgradeModalContext);
  if (context === undefined) {
    throw new Error(
      `useUpgradeModal must be used within a UpgradeModalProvider`,
    );
  }

  return context;
}

export default UpgradeModalProvider;
