import { useCallback, useMemo } from 'react';
import { SequenceOptionsWrapper } from './style';
import { Button, CircularProgress, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import AddContactsIcon from 'crono-fe-common/icons/Icon-Add-Contacts';
import DuplicateSquareIcon from 'crono-fe-common/icons/Icon-Duplicate-Square';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import EditPencilIcon from 'crono-fe-common/icons/Icon-Edit-pencil';
import IconRectangle from 'crono-fe-common/icons/Icon-Rectangle';
import IconPlay from 'crono-fe-common/icons/Icon-Play';
import useResumeProspectSequenceMultiple from 'hooks/services/sequence/useResumeProspectSequenceMultiple';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';
import useGetSequence from 'hooks/services/sequence/useGetSequence';

interface IProps {
  addContacts?: () => void;
  stopStrategy?: () => void;
  duplicateStrategy?: () => void;
  modifyStrategy?: () => void;
  deleteStrategy?: () => void;
  enableResume: boolean;
  sequenceId: number;
}

const SequenceOptions = ({
  addContacts,
  stopStrategy,
  duplicateStrategy,
  modifyStrategy,
  deleteStrategy,
  enableResume,
  sequenceId,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const {
    mutate: resumeProspectSequenceMultiple,
    isLoading: isLoadingResumeProspectSequenceMultiple,
    error: errorResumeProspectSequenceMultiple,
    isSuccess: isSuccessResumeProspectSequenceMultiple,
  } = useResumeProspectSequenceMultiple();

  const { data: sequence, isLoading: isLoadingSequence } =
    useGetSequence(sequenceId);

  const [resumableCount, stoppableCount] = useMemo(() => {
    if (!sequence?.data?.data) {
      return [null, null];
    }
    return [
      sequence.data.data.resumableCount,
      sequence.data.data.stoppableCount,
    ];
  }, [sequence]);

  const handleResumeProspectSequence = useCallback(() => {
    resumeProspectSequenceMultiple({
      strategyId: sequenceId,
    });
    if (analytics) {
      analytics.track('resume-multiple-strategies', {
        count: resumableCount,
      });
    }
  }, [resumeProspectSequenceMultiple, sequenceId]);

  useConditionalSnackBar([
    {
      condition: isSuccessResumeProspectSequenceMultiple,
      message: 'Strategies resumed successfully',
      severity: 'success',
    },
    {
      condition: !!errorResumeProspectSequenceMultiple,
      message:
        getError(errorResumeProspectSequenceMultiple) ??
        'Error while resuming strategies',
      severity: 'error',
    },
  ]);

  const isStopDisabled = !stoppableCount || stoppableCount === 0;

  const isResumeDisabled = !resumableCount || resumableCount === 0;

  return (
    <SequenceOptionsWrapper>
      {addContacts && (
        <Button
          className="option-button"
          color="primary"
          fullWidth
          endIcon={<AddContactsIcon />}
          onClick={addContacts}
        >
          Add contacts
        </Button>
      )}
      {isLoadingSequence ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {stopStrategy && (
            <Button
              className={`option-button-stop ${isStopDisabled ? 'disabled' : ''}`}
              fullWidth
              endIcon={
                <div className={`stop-strategy-rectangle-icon`}>
                  <IconRectangle
                    color={colors.orangeDark}
                    className="stop-strategy-rectangle"
                  />
                </div>
              }
              onClick={(ev) => {
                if (isStopDisabled) {
                  ev.stopPropagation();
                  ev.preventDefault();
                  return;
                }
                stopStrategy();
              }}
              disabled={isStopDisabled}
            >
              {isStopDisabled
                ? 'No contacts to stop'
                : `Stop all (${stoppableCount} contacts)`}
            </Button>
          )}
          {enableResume && (
            <Button
              className={`option-button-resume ${isResumeDisabled ? 'disabled' : ''}`}
              color="primary"
              fullWidth
              endIcon={
                isLoadingResumeProspectSequenceMultiple ? (
                  <CircularProgress size={24} />
                ) : (
                  <div className={`resume-strategy-triangle-icon`}>
                    <IconPlay
                      color={colors.callScheduledHover}
                      className="resume-strategy-triangle"
                    />
                  </div>
                )
              }
              onClick={(ev) => {
                if (isResumeDisabled) {
                  ev.stopPropagation();
                  ev.preventDefault();
                  return;
                }
                handleResumeProspectSequence();
              }}
              disabled={
                isResumeDisabled || isLoadingResumeProspectSequenceMultiple
              }
            >
              {isResumeDisabled
                ? 'No contacts to resume'
                : `Resume all (${resumableCount} contacts)`}
            </Button>
          )}
        </>
      )}

      <div className="divider" />
      {duplicateStrategy && (
        <Button
          className="option-button"
          color="primary"
          fullWidth
          endIcon={<DuplicateSquareIcon />}
          onClick={duplicateStrategy}
        >
          Duplicate
        </Button>
      )}
      {modifyStrategy && (
        <Button
          className="option-button"
          color="primary"
          fullWidth
          endIcon={<EditPencilIcon />}
          onClick={modifyStrategy}
        >
          Edit
        </Button>
      )}
      {deleteStrategy && (
        <>
          <div className="divider" />
          <Button
            className="option-button-delete"
            color="primary"
            fullWidth
            endIcon={<TrashCanDeleteIcon color={colors.inactiveHover} />}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              deleteStrategy();
            }}
          >
            Delete
          </Button>
        </>
      )}
    </SequenceOptionsWrapper>
  );
};

export default SequenceOptions;
