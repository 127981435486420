import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect, useMemo } from 'react';
import { InviteNewUserFormWrapper } from './style';
import {
  CircularProgress,
  ClickAwayListener,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import Role from 'crono-fe-common/types/enums/role';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { validationSchemaInviteNew } from './model';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import useCreateUser from 'hooks/services/subscription/useCreateUser';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useJuneAnalytics } from 'context/june';
import useGetSubscription from 'hooks/services/subscription/useGetSubscription';
import useCreateUserMultiCustomerSubscription from 'hooks/services/subscription/useCreateUserMultiCustomerSubscription';

interface IProps {
  close: () => void;
  onAdd?: (user: IUserSubscriptionPage) => void;
}

export interface IUserSubscriptionPage {
  firstName: string;
  lastName: string;
  email: string;
  permission: Role;
}

const InviteNewUserForm = ({ close, onAdd }: IProps) => {
  const { data: subscription } = useGetSubscription();

  const isMultiCustomer = useMemo(() => {
    return subscription?.data?.data.multiCustomer ?? false;
  }, [subscription]);

  const {
    mutate: createUser,
    isLoading: creatingUser,
    isSuccess: userCreated,
    error: errorCreatingUser,
    variables: createUserVariables,
  } = useCreateUser();

  const {
    mutate: createUserMultiCustomer,
    isLoading: creatingUserMultiCustomer,
    isSuccess: userCreatedMultiCustomer,
    error: errorCreatingUserMultiCustomer,
    variables: createUserMultiCustomerVariables,
  } = useCreateUserMultiCustomerSubscription();

  const isLoading = creatingUser || creatingUserMultiCustomer;
  const isSuccess = userCreated || userCreatedMultiCustomer;
  const error = errorCreatingUser || errorCreatingUserMultiCustomer;
  const variables = createUserVariables || createUserMultiCustomerVariables;

  const analytics = useJuneAnalytics();

  const formik = useFormik<IUserSubscriptionPage>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      permission: Role.BASIC,
    },
    validationSchema: validationSchemaInviteNew,
    onSubmit: (values) => {
      //If there is an onAdd function I use that instead of calling the createUser API
      if (onAdd) {
        onAdd(values);
      } else {
        const createUserBody = {
          userRoles:
            values.permission === Role.BASIC
              ? [Role.BASIC]
              : [Role.MANAGER, Role.BASIC],
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        };
        if (!isMultiCustomer) {
          createUser(createUserBody);
        } else {
          createUserMultiCustomer(createUserBody);
        }
        if (analytics) {
          analytics.track('Invite new user', createUserBody);
        }
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  useConditionalSnackBar([
    {
      condition: !!isSuccess,
      message: variables?.email
        ? `Invitation successfully sent to ${variables?.email}`
        : 'Invitation sent successfully',
      severity: 'success',
    },
    {
      condition: !!error,
      message: getError(error) ?? 'Error creating user',
      severity: 'error',
    },
  ]);

  return (
    <BlurBackgroundDivFixed>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 10,
        }}
        onClick={() => close()}
      />
      <InviteNewUserFormWrapper>
        <Typography fontWeight={700} fontSize={24} lineHeight={'30px'}>
          Invite new user:
        </Typography>
        <div className="form-row-invite-new">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            First name:
          </Typography>
          <TextField
            fullWidth
            placeholder="James..."
            variant="standard"
            value={formik.values.firstName}
            onChange={(ev) => {
              formik.setFieldValue('firstName', ev.target.value);
            }}
            helperText={formik.touched.firstName && formik.errors.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          />
        </div>
        <div className="form-row-invite-new">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Last name:
          </Typography>
          <TextField
            fullWidth
            placeholder="Smith..."
            variant="standard"
            value={formik.values.lastName}
            onChange={(ev) => {
              formik.setFieldValue('lastName', ev.target.value);
            }}
            helperText={formik.touched.lastName && formik.errors.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          />
        </div>
        <div className="form-row-invite-new">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Email:
          </Typography>
          <TextField
            fullWidth
            placeholder="name@example.com..."
            variant="standard"
            value={formik.values.email}
            onChange={(ev) => {
              formik.setFieldValue('email', ev.target.value);
            }}
            helperText={formik.touched.email && formik.errors.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
        </div>
        <div className="form-row-invite-new" style={{ gap: 8 }}>
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Select user permissions:
          </Typography>
          <CronoSelect
            value={formik.values.permission}
            onChange={(ev) =>
              formik.setFieldValue('permission', ev.target.value)
            }
            style={{ height: 40 }}
            MenuProps={{
              // disablePortal: true
              style: {
                zIndex: 10000,
              },
            }}
          >
            <CronoMenuItem value={Role.BASIC}>User</CronoMenuItem>
            <CronoMenuItem value={Role.MANAGER}>Manager</CronoMenuItem>
          </CronoSelect>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              width: '100%',
              gap: 2,
            }}
          >
            <Typography
              flexWrap={'wrap'}
              fontSize={12}
              lineHeight={'16px'}
              color={colors.grey11}
            >
              *
            </Typography>
            <Typography
              flexWrap={'wrap'}
              fontSize={12}
              lineHeight={'16px'}
              color={colors.grey11}
            >
              {formik.values.permission === Role.BASIC
                ? 'The member will be invited in user role. The user has the same rights as the manager, except for creating new templates and modifying workspace data.'
                : 'The member will be invited in manager role. The manager has full rights: managing templates & modifying workspace data.'}
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
          }}
        >
          <CancelButton onClick={close} style={{ margin: 0 }}>
            Cancel
          </CancelButton>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <MainButton
              onClick={() => formik.handleSubmit()}
              style={{ margin: 0 }}
            >
              Invite
            </MainButton>
          )}
        </div>
      </InviteNewUserFormWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default InviteNewUserForm;
