import React, { useEffect } from 'react';
import { DisconnectedBannerWrapper } from './style';
import { Typography } from '@mui/material';
import { useAuth } from 'context/auth';
import useEmailIntegrationGuard from 'hooks/guards/useEmailIntegrationGuard';
import IconSWarning from 'crono-fe-common/icons/Icon-S-Warning';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { SettingsTabs } from 'pages/settings';
import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';

const DisconnectedBanner = () => {
  const { isError: emailNotIntegrated } = useEmailIntegrationGuard();

  const { user } = useAuth();

  const navigate = useNavigate();

  const handleClick = () => {
    //Go to settings in the connect tab using the state
    navigate(PATH.SETTINGS, { state: { tab: SettingsTabs.Integrations } });
  };

  return emailNotIntegrated && user?.company?.emailIntegrationType ? (
    <DisconnectedBannerWrapper>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 18 }}>
        <IconSWarning className="warning-icon" />

        <Typography fontSize={14} lineHeight={'22px'} fontWeight={600}>
          {user?.company?.emailIntegrationType === EmailIntegrationType.GMAIL
            ? 'Gmail'
            : user?.company?.emailIntegrationType ===
                EmailIntegrationType.OUTLOOK
              ? 'Outlook'
              : user?.company?.emailIntegrationType ===
                  EmailIntegrationType.CUSTOM
                ? 'Custom email provider '
                : 'The email provider'}{' '}
          is disconnected. Go to Account Settings to connect your email back
        </Typography>
      </div>
      <MainButton style={{ margin: 0, height: 40 }} onClick={handleClick}>
        Settings
      </MainButton>
    </DisconnectedBannerWrapper>
  ) : null;
};

export default DisconnectedBanner;
