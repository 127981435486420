import { CircularProgress, Typography } from '@mui/material';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import React, { useEffect, useMemo, useState } from 'react';
import { colors } from 'crono-fe-common/theme';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { SyncStatusWrapper } from './style';
import { useAuth } from 'context/auth';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import useGetExternalPropertyCRM from 'hooks/services/externalProperty/useGetExternalPropertyCRM';
import useCreateExternalProperty from 'hooks/services/externalProperty/useCreateExternalProperty';
import { getError, getExternalPropertyLabels } from 'crono-fe-common/utils';
import { useConditionalSnackBar } from 'context/snackbar';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import IconSWarning from 'crono-fe-common/icons/Icon-S-Warning';
import { StatusComponentOptions } from 'crono-fe-common/types/StatusOptions';
import useMigrateExternalPropertyStatus from 'hooks/services/externalProperty/useMigrateExternalPropertyStatus';

interface IProps {
  close: () => void;
  type: StatusComponentOptions;
}

const LinkStatusToExternalProperty = ({ close, type }: IProps) => {
  const { user } = useAuth();

  const [tagsToShow, setTagsToShow] = useState<string[]>([]);

  const { data: allTags } = useGetExternalProperty(
    type === 'account' ? 'Account' : 'Prospect',
    true,
    true,
  );

  const [selectedPropertyCRM, setSelectedPropertyCRM] =
    useState<ExternalProperty | null>(null);

  const { data: externalPropertiesCRM } = useGetExternalPropertyCRM(
    type === 'account' ? 'Account' : 'Prospect',
    'List',
    true,
  );

  const alreadyUsedAsTag = useMemo(() => {
    if (!allTags) return false;
    return allTags?.data?.data.find(
      (tag) => tag.externalName === selectedPropertyCRM?.externalName,
    );
  }, [selectedPropertyCRM, allTags]);

  const handleSelectExternalPropertyCRM = (externalName: string) => {
    const property =
      externalPropertiesCRM?.data?.data.find(
        (p) => p.externalName === externalName,
      ) || null;
    setSelectedPropertyCRM(property);
    if (property) {
      setTagsToShow(getExternalPropertyLabels(property) || []);
    } else {
      setTagsToShow([]);
    }
  };

  const {
    mutate: migrateExternalProperty,
    isSuccess: externalPropertyMigrated,
    error: externalPropertyMigrationError,
    isLoading: migratingExternalProperty,
  } = useMigrateExternalPropertyStatus();

  useConditionalSnackBar([
    {
      condition: !!externalPropertyMigrationError,
      message: getError(externalPropertyMigrationError) || 'Error creating tag',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (externalPropertyMigrated) {
      close();
    }
  }, [externalPropertyMigrated]);

  const handleLinkProperty = () => {
    migrateExternalProperty({
      tableType: type === 'account' ? 'Account' : 'Prospect',
      externalName: selectedPropertyCRM?.externalName,
    });
  };

  return (
    <BlurBackgroundDivFixed onClick={close}>
      <SyncStatusWrapper
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
      >
        <CloseTabButton close={close} />
        <div className="add-tag-container">
          <Typography fontSize={24} fontWeight={700}>
            Select the CRM property you would like to use as{' '}
            {type === 'account' ? 'company' : 'contact'} status
          </Typography>
          <Typography fontSize={14} lineHeight={'24px'} color={colors.grey11}>
            By linking statuses in Crono with a CRM property all the statuses of
            existing {type === 'account' ? 'companies' : 'contacts'} in Crono
            will be automatically synchronized
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 6 }}>
            <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
              Select CRM property*
            </Typography>
            <CronoSelect
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colors.grey3}`,
                  borderRadius: 2,
                },
              }}
              variant="standard"
              placeholder="Select property"
              MenuProps={{
                style: {
                  zIndex: 35001,
                  maxHeight: '50%',
                  borderRadius: 16,
                },
              }}
              fullWidth
              size="small"
              onChange={(ev) => {
                handleSelectExternalPropertyCRM(ev.target.value as string);
              }}
              value={selectedPropertyCRM?.id || 0}
              renderValue={(value) =>
                selectedPropertyCRM?.publicName || 'Select property'
              }
            >
              {externalPropertiesCRM?.data?.data.map((externalProperty) => (
                <CronoMenuItem
                  value={externalProperty.externalName}
                  key={externalProperty.id}
                >
                  {externalProperty.publicName}
                </CronoMenuItem>
              ))}
            </CronoSelect>
            {alreadyUsedAsTag && (
              <Typography
                fontSize={12}
                lineHeight={'16px'}
                fontWeight={500}
                color={colors.inactive}
              >
                Already in use as Tag. Save to use it for Status instead
              </Typography>
            )}
          </div>
          <div
            className="alert-property-already-used-as-tag"
            style={{ marginTop: -16 }}
          >
            <div className="warning-icon-already-used">
              <IconSWarning color={colors.white} />
            </div>
            <Typography
              fontSize={12}
              lineHeight={'16px'}
              fontWeight={500}
              color={colors.inactive}
            >
              Please be aware that if you save this change, all current{' '}
              {type === 'account' ? 'company ' : 'contact '}
              statuses will be lost
            </Typography>
          </div>
          <FlexDiv width="fit-content" style={{ alignSelf: 'center' }}>
            <CancelButton onClick={() => close()}>Cancel</CancelButton>
            {migratingExternalProperty ? (
              <CircularProgress />
            ) : (
              <MainButton
                disabled={!selectedPropertyCRM}
                style={{
                  background: !selectedPropertyCRM ? colors.grey11 : undefined,
                }}
                onClick={handleLinkProperty}
              >
                Save
              </MainButton>
            )}
          </FlexDiv>
        </div>
      </SyncStatusWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default LinkStatusToExternalProperty;
