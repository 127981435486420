import React, { useMemo, useState } from 'react';
import useSearchStrategies from 'hooks/services/sequence/useSearchStrategies';
import { FeConstants } from 'constants/FeConstants';
import SequenceCard from 'pages/dashboard/contactTable/sequenceCard';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import { Strategy } from 'crono-fe-common/types/strategy';

interface IProps {
  selectStrategy: (strategyId: number) => void;
  negativeOptionLabel?: string;
  defaultValue?: number;
  // For debugging purposes
  open?: boolean;
}

const StrategyAutocomplete = ({
  selectStrategy,
  negativeOptionLabel,
  defaultValue,
  open,
}: IProps) => {
  const [searchStrategyText, setSearchStrategyText] = useState<string>('');

  const { data: strategiesResponse, isLoading: isStrategiesLoading } =
    useSearchStrategies({
      limit: FeConstants.strategyLimit,
      offset: 0,
      name: searchStrategyText,
    });

  // we provide that in order to avoid the flickering of the autocomplete options on strategies loading
  const [previousStrategies, setPreviousStrategies] = useState<Strategy[]>([]);

  const strategies = useMemo((): Strategy[] => {
    const newStrategies = strategiesResponse?.data?.data;

    if (isStrategiesLoading || !newStrategies) {
      return previousStrategies;
    } else {
      setPreviousStrategies(newStrategies);
      return newStrategies;
    }
  }, [strategiesResponse, isStrategiesLoading]);

  const negativeOption: Strategy = {
    id: -1,
    subscriptionId: -1,
    userId: -1,
    name: negativeOptionLabel ?? '',
    numberOfSteps: 0,
    firstStep: '',
    emailPercent: -1,
    callPercent: -1,
    linkedinPercent: -1,
    shared: false,
    active: false,
    strategyTags: null,
  };

  return (
    <CronoAutocomplete
      defaultValue={
        defaultValue === -1
          ? negativeOption
          : defaultValue
            ? strategies?.find((s) => s.id === defaultValue)
            : undefined
      }
      placeholder="Select a strategy..."
      fullWidth
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(ev, value) => {
        if (value) {
          selectStrategy(value.id);
        } else {
          selectStrategy(0);
          setSearchStrategyText('');
        }
      }}
      options={
        negativeOptionLabel
          ? [negativeOption, ...(strategies ?? [])]
          : strategies ?? []
      }
      getOptionLabel={(s) => s.name}
      renderOption={(props, strategyOption) => (
        <li value={strategyOption.id} key={strategyOption.id} {...props}>
          <SequenceCard sequence={strategyOption} />
        </li>
      )}
      setSearchText={(text) => {
        setSearchStrategyText(text);
      }}
      onBlur={() => {
        setSearchStrategyText('');
      }}
      disablePortal
      open={open}
    />
  );
};

export default StrategyAutocomplete;
