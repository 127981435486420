import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export default function useDeleteMultipleSequences() {
  const queryClient = useQueryClient();

  const deleteStrategy = (prospectIds: string[]) => {
    const request: Request = {
      url: `${ENDPOINTS.sequence.prospectMultiple}`,

      config: {
        method: 'delete',
        data: {
          prospectIds,
        },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<{ ok: number; ko: number }>,
    CronoError,
    string[]
  >(deleteStrategy, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.account.main]);
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.main]);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);

      queryClient.invalidateQueries([ENDPOINTS.task.total]);
    },
  });

  return { data, mutate, ...rest };
}
