import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { TextField, IconButton, Tooltip, Checkbox } from '@mui/material';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import IconDrag from 'crono-fe-common/icons/Icon-Drag';
import EditPencilIcon from 'crono-fe-common/icons/Icon-Edit-pencil';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import { colors } from 'crono-fe-common/theme';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StatusColors } from 'crono-fe-common/constants/constants';
import { DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';
import { StatusComponentOptions } from 'crono-fe-common/types/StatusOptions';
import { FeConstants } from 'constants/FeConstants';
import { StatusPropertyType } from 'crono-fe-common/types/model';

interface IProps {
  handleChangeName: (
    name: string | null,
    index: number,
    onAccept: () => void,
    onRefuse: () => void,
  ) => void;
  type: StatusComponentOptions;
  status: StatusPropertyType;
  colorsStatus: StatusColors;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  statusFocused: number | null;
  setStatusFocused: React.Dispatch<React.SetStateAction<number | null>>;
  index: number;
  handleDeleteStatus: (index: number) => Promise<void>;
  defaultValue: string | null;
  handleChangeDefaultStatus: (option: string) => void;
  syncedWithCRM: boolean;
  disableDelete?: boolean;
}

const StatusSettingsCard = ({
  handleChangeName,
  type,
  status,
  colorsStatus,
  provided,
  snapshot,
  statusFocused,
  setStatusFocused,
  index,
  handleDeleteStatus,
  defaultValue,
  handleChangeDefaultStatus,
  syncedWithCRM,
  disableDelete = false,
}: IProps) => {
  const [statusName, setStatusName] = useState<string | null>(status.label);

  const refTextfield = useRef<HTMLDivElement | null>(null);

  const onAccept = (reset = false) => {
    //To set back to the initial label
    if (reset) {
      setStatusName(status.label);
    }
    //Get to the input field and blur it
    (refTextfield.current?.firstChild?.firstChild as any).blur();
  };
  const onRefuse = () => {
    //Get to the input field and focus it
    (refTextfield.current?.firstChild?.firstChild as any).focus();
  };
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...provided.draggableProps.style,
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      {!syncedWithCRM && (
        <Tooltip
          enterDelay={800}
          enterNextDelay={800}
          title={
            <span
              style={{
                width: 216,
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              The marked status will be assigned automatically to new{' '}
              {type === 'account' ? 'companies' : 'contacts'}
            </span>
          }
          arrow
        >
          <Checkbox
            key={status.option + defaultValue}
            checkedIcon={<RadioButtonChecked />}
            icon={<RadioButtonUnchecked />}
            color="secondary"
            checked={status.option === defaultValue}
            onChange={() => {
              handleChangeDefaultStatus(status.option);
            }}
          />
        </Tooltip>
      )}
      <div
        className="stage-row"
        style={{
          backgroundColor: colorsStatus.dark,
          color: colors.white,
          justifyContent: 'space-between',
          filter: snapshot.isDragging ? 'brightness(0.95)' : 'none',
          opacity:
            statusFocused === null ? 1 : statusFocused === status.id ? 1 : 0.6,
          flex: 1,
        }}
      >
        <TextField
          ref={refTextfield}
          placeholder="Add a status name"
          value={statusName}
          onChange={(ev) => setStatusName(ev.target.value)}
          fullWidth
          variant="standard"
          disabled={syncedWithCRM}
          sx={{
            '& .MuiInput-input': {
              padding: 0,
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: colors.white,
            },
          }}
          onFocus={() => setStatusFocused(status.id)}
          onBlur={() => {
            handleChangeName(statusName, index, onAccept, onRefuse);
          }}
          InputProps={{
            style: {
              fontSize: '12px',
              fontWeight: 500,
              color: colors.white,
            },
            disableUnderline: true,
            classes: {
              input: 'title',
            },
          }}
        />
        {!syncedWithCRM && (
          <IconButton
            className="action-buttons"
            style={{
              padding: '2px',
              cursor: 'grab',
            }}
            onClick={() => {
              setStatusFocused(null);
            }}
          >
            <IconDrag color={colors.white} />
          </IconButton>
        )}
      </div>
      {!syncedWithCRM && (
        <div className="action-buttons-container">
          {statusFocused === status.id ? (
            <>
              <IconButton
                className="action-buttons delete-stage"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setStatusFocused(null);
                  setStatusName(status.label);
                  //We don't blur here because this would lead to the onBlur of the textfield to be fired
                }}
              >
                <CloseMIcon strokeWidth="2" color={colors.inactive} />
              </IconButton>
              <IconButton
                className="action-buttons edit-stage"
                onClick={() => {
                  handleChangeName(statusName, index, onAccept, onRefuse);
                }}
              >
                <CheckMarkIcon strokeWidth="2" color={colors.callScheduled} />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                className="action-buttons"
                onClick={() => {
                  setStatusFocused(status.id);
                  (refTextfield.current?.firstChild?.firstChild as any).focus();
                }}
              >
                <EditPencilIcon color={colors.grey11} />
              </IconButton>
              {/*The tooltip is shown only if we reached the minimum amount of statuses */}
              <Tooltip
                arrow
                enterDelay={400}
                enterNextDelay={400}
                title={
                  disableDelete ? (
                    <span
                      style={{
                        width: 260,
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      You can’t remove this status as the minimum number of
                      statuses is {FeConstants.MINIMUM_STATUSES}
                    </span>
                  ) : null
                }
              >
                <div>
                  <IconButton
                    className="action-buttons"
                    style={{ padding: '2px', opacity: disableDelete ? 0.5 : 1 }}
                    onClick={() => handleDeleteStatus(index)}
                    disabled={disableDelete}
                  >
                    <TrashCanDeleteIcon color={colors.grey11} />
                  </IconButton>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default StatusSettingsCard;
