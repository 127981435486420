import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const EmailIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2496 8.47868L13.2536 6.44201C13.7209 6.12601 14.0003 5.59868 14.0003 5.03468V5.03468C14.0003 4.09468 13.2389 3.33334 12.2996 3.33334H3.71092C2.77159 3.33334 2.01025 4.09468 2.01025 5.03401V5.03401C2.01025 5.59801 2.28959 6.12534 2.75692 6.44201L5.76092 8.47868C7.11625 9.39734 8.89425 9.39734 10.2496 8.47868V8.47868Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 5.03403V11.3334C2 12.438 2.89533 13.3334 4 13.3334H12C13.1047 13.3334 14 12.438 14 11.3334V5.03469"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
