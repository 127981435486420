import React, { SetStateAction } from 'react';
import { GenerateTemplateGoal } from 'crono-fe-common/types/enums/generateTemplateGoal';
import { GenerateTemplateLength } from 'crono-fe-common/types/enums/generateTemplateLength';
import { GenerateTemplateStyle } from 'crono-fe-common/types/enums/generateTemplateStyle';
import { TemplateStep } from 'crono-fe-common/types/enums/templateStep';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  templateType: Yup.string().required('Required'),
  what: Yup.string()
    .required('Required')
    .max(200, 'Must be 200 characters or less'),
  title: Yup.string().nullable().max(100, 'Must be 100 characters or less'),
  type: Yup.string().required('Required'),
  style: Yup.string().required('Required'),
  goal: Yup.string().required('Required'),
  length: Yup.string().required('Required'),
  language: Yup.string(),
});

export const initialValues = {
  templateType: TemplateType.EMAIL,
  what: '',
  title: null,
  type: TemplateStep.FIRST_TOUCH,
  style: GenerateTemplateStyle.FRIENDLY,
  goal: GenerateTemplateGoal.MEETING,
  length: GenerateTemplateLength.MEDIUM,
  language: 'en',
};

export interface GenerateTemplateInput {
  templateType: TemplateType;
  what: string;
  title: string | null;
  type: TemplateStep;
  style: GenerateTemplateStyle;
  goal: GenerateTemplateGoal;
  length: GenerateTemplateLength;
  language: string;
}

export interface GenerateTemplateProps {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  step: TemplateStep;
  setStep: React.Dispatch<React.SetStateAction<TemplateStep>>;
  language: string;
  setLanguage: React.Dispatch<SetStateAction<string>>;
  templateType: TemplateType;
  setTemplateType: React.Dispatch<SetStateAction<TemplateType>>;
  templateFormik: any;
}
