import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './styledComponents';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  OptionsForAccordionRange,
  ValuesToOnChange,
} from '../searchCompanyTab/searchCompanyTabFilters';
import { AutocompleteMultipleOptionWrapper } from '../style';
import ClearIcon from '@mui/icons-material/Clear';
import { LinkedinFilterValue } from 'crono-fe-common/types/crono-extension/linkedin';

interface IProps {
  showMainSelect: boolean;
  currentValue?: LinkedinFilterValue | null;
  options?: any[];
  currentMin: any | null;
  currentMax: any | null;
  unitOfMeasureLabel: string;

  onChange: ({ newLabel, valueToChange }: ValuesToOnChange) => void;
  placeholder?: string;
  id: string;
  displayLabel: string;
  icon: JSX.Element;
  disableTopBorder?: boolean;
  expanded?: boolean;
  setExpanded?: () => void;
}

const SearchAccordionRangesInput = ({
  showMainSelect,
  currentValue,
  options,
  currentMin,
  currentMax,
  unitOfMeasureLabel,
  onChange,
  placeholder,
  id,
  displayLabel,
  icon,
  disableTopBorder,
  expanded,
  setExpanded,
}: IProps) => {
  const [searchText, setSearchText] = useState('');
  const showErrorMessage =
    currentMin !== null &&
    currentMax !== null &&
    Number(currentMin) > Number(currentMax);

  const resume = useMemo(() => {
    if ((showMainSelect && !currentValue) || (!currentMin && !currentMax))
      return null;
    const label = !currentMin
      ? `< ${currentMax} ${unitOfMeasureLabel}`
      : !currentMax
        ? `> ${currentMin} ${unitOfMeasureLabel}`
        : `${currentMin} - ${currentMax} ${unitOfMeasureLabel}`;
    return (
      <AutocompleteMultipleOptionWrapper style={{ marginTop: 8 }}>
        <Typography noWrap className={'label'}>
          {label} {showMainSelect && currentValue?.label}
        </Typography>
        <div
          className={'remove-icon-container'}
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            onChange({ newLabel: null, valueToChange: 'mainValue' });
            onChange({ newLabel: null, valueToChange: 'min' });
            onChange({ newLabel: null, valueToChange: 'max' });
          }}
        >
          <ClearIcon style={{ width: 15, height: 15 }} />
        </div>
      </AutocompleteMultipleOptionWrapper>
    );
  }, [currentValue, currentMin, currentMax]);

  return (
    <Accordion expanded={expanded} disableTopBorder={disableTopBorder}>
      <AccordionSummary
        onClick={() => {
          setExpanded && setExpanded();
        }}
        classes={{
          expandIconWrapper: 'expand-icon-wrapper',
        }}
      >
        <div
          style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {icon}
            {displayLabel}
          </div>
          {!expanded && resume}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          {showMainSelect && options && (
            <Autocomplete
              fullWidth
              id={id}
              className="add-new-auto-complete"
              value={currentValue}
              onChange={(e, label) => {
                if (!label) {
                  onChange({ newLabel: null, valueToChange: 'mainValue' });
                  return;
                }
                onChange({ newLabel: label, valueToChange: 'mainValue' });
              }}
              PaperComponent={({ children }) => (
                <div className="autocomplete-paper">{children}</div>
              )}
              renderOption={(props, option) => (
                <li {...props} className="autocomplete-option">
                  {option.label}
                </li>
              )}
              getOptionLabel={(option) => {
                if (!option) return '';
                return option.label;
              }}
              options={options}
              sx={{
                width: 300,
              }}
              disablePortal
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="standard"
                  placeholder={placeholder}
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                />
              )}
            />
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              width: '100%',
            }}
          >
            <TextField
              fullWidth
              type="number"
              size="small"
              placeholder={`Min ${unitOfMeasureLabel}`}
              value={currentMin ?? ''}
              onChange={(e) =>
                onChange({ newLabel: e.target.value, valueToChange: 'min' })
              }
            />
            <Typography fontSize={12} lineHeight={'16px'} color={colors.grey11}>
              to
            </Typography>
            <TextField
              fullWidth
              type="number"
              size="small"
              placeholder={`Max ${unitOfMeasureLabel}`}
              value={currentMax ?? ''}
              onChange={(e) =>
                onChange({ newLabel: e.target.value, valueToChange: 'max' })
              }
            />
          </div>
          {showErrorMessage && (
            <Typography
              color={colors.inactive}
              fontSize={11}
              lineHeight={'14px'}
              fontWeight={400}
            >
              Max value must be grater than min
            </Typography>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchAccordionRangesInput;
