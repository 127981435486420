import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Sequence } from 'crono-fe-common/types/sequence';

interface SequenceContextType {
  sequence: Sequence;
  setSequence: React.Dispatch<React.SetStateAction<Sequence>>;
  isNewSequenceView: boolean;
}

export const SequenceContext = createContext<SequenceContextType | undefined>(
  undefined,
);

const SequenceProvider: FC<{ children: any }> = ({ children }) => {
  //Bad hack to prevent the sequence to be updated every time the originalSequence changes
  const [sequence, setSequence] = useState<Sequence>({ id: -1 } as Sequence);
  const isNewSequenceView =
    sequence?.steps?.length === undefined || sequence.steps.length === 0;

  return (
    <SequenceContext.Provider
      value={{ sequence, setSequence, isNewSequenceView }}
    >
      {children}
    </SequenceContext.Provider>
  );
};

export default SequenceProvider;

export function useSequenceContext() {
  const context = useContext(SequenceContext);
  if (context === undefined) {
    throw new Error(
      `useSequenceContext must be used within a SequenceContextProvided`,
    );
  }

  return context;
}
