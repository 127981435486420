import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { TaskSearch } from 'crono-fe-common/types/DTO/taskSearch';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';

export default function useSearchTasks(
  searchParameters: TaskSearch,
  withOpportunities?: boolean,
  enabled = true,
) {
  const searchParametersString = JSON.stringify({
    ...searchParameters,
    withOpportunities,
  });
  const request = createSearchTasksRequest(searchParameters, withOpportunities);

  const requestConfig = {
    queryKey: [ENDPOINTS.task.search, searchParametersString],

    queryFn: () => client(request),
    enabled,
  };

  return useQuery<ResponsePaginated<TaskTodo[]>, CronoError>(requestConfig);
}

export function createSearchTasksRequest(
  searchParameters: TaskSearch,
  withOpportunities?: boolean,
): Request {
  return {
    url: ENDPOINTS.task.search.main,
    config: {
      method: 'post',
      data: { data: searchParameters, withOpportunities },
    },
  };
}
