import styled from '@emotion/styled';
import { Badge, Tooltip } from '@mui/material';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import MobilePhoneIcon from 'crono-fe-common/icons/Icon-Mobile-Phone';
import { colors } from 'crono-fe-common/theme';
import VerificationStatusType from 'crono-fe-common/types/enums/verificationStatusType';
import { Prospect } from 'crono-fe-common/types/prospect';
import { checkIfValidEmail, checkIfValidPhone } from 'crono-fe-common/utils';
import {
  checkIfValidLinkedinCompanyUrl,
  checkIfValidLinkedinProfileUrl,
} from 'utils/fe-utils';

export interface IInformation {
  name: keyof Prospect;
  icon: JSX.Element;
}

export const callInformation: IInformation = {
  name: 'phone',
  icon: <CallIcon className="info-icon phone-info-icon" />,
};
export const mobilePhoneInformation: IInformation = {
  name: 'mobilePhone',
  icon: <MobilePhoneIcon className="info-icon mobile-phone-info-icon" />,
};
export const emailInformation: IInformation = {
  name: 'email',
  icon: <EmailIcon className="info-icon email-info-icon" />,
};
export const linkedinInformation: IInformation = {
  name: 'linkedin',
  icon: <LinkedinIcon className="info-icon linkedin-info-icon" />,
};

export const contactInformation: IInformation[] = [
  callInformation,
  mobilePhoneInformation,
  emailInformation,
  linkedinInformation,
];

export const contactInformationIconWithStatus = (
  option: IInformation,
  status: 'invalid' | 'valid' | 'warning' | 'none',
  message: string,
  renderOnClickHandler: ((name: IInformation['name']) => () => void) | null,
) => {
  return (
    <ContactInformationIconWrapper
      onClick={() => {
        if (renderOnClickHandler) {
          renderOnClickHandler(option.name)();
        }
      }}
      style={{ cursor: renderOnClickHandler ? 'pointer' : 'default' }}
    >
      <Tooltip enterDelay={400} enterNextDelay={400} title={message} arrow>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              right: 8,
              top: 5,
              background:
                status === 'warning'
                  ? colors.customerYellow
                  : status === 'invalid'
                    ? colors.inactive
                    : colors.callScheduled,
            },
          }}
          id="email-badge"
          variant="dot"
          invisible={status === 'none'}
        >
          {option.icon}
        </Badge>
      </Tooltip>
    </ContactInformationIconWrapper>
  );
};

export const renderContactInformationIcon = (
  contactInformation: IInformation,
  prospect: {
    email?: string | null;
    emailStatus?: VerificationStatusType | null;
    linkedin?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
  },
  renderOnClickHandler: ((name: IInformation['name']) => () => void) | null,
) => {
  switch (contactInformation.name) {
    case 'email':
      if (!prospect.email) {
        return contactInformationIconWithStatus(
          contactInformation,
          'none',
          '',
          renderOnClickHandler,
        );
      }
      if (!checkIfValidEmail(prospect.email)) {
        return contactInformationIconWithStatus(
          contactInformation,
          'invalid',
          'Invalid email',
          renderOnClickHandler,
        );
      } else {
        if (prospect.emailStatus) {
          switch (prospect.emailStatus) {
            case VerificationStatusType.VALID:
              return contactInformationIconWithStatus(
                contactInformation,
                'valid',
                'Valid email address',
                renderOnClickHandler,
              );
            case VerificationStatusType.INVALID:
              return contactInformationIconWithStatus(
                contactInformation,
                'invalid',
                'Invalid email address',
                renderOnClickHandler,
              );
            case VerificationStatusType.ACCEPT_ALL:
              return contactInformationIconWithStatus(
                contactInformation,
                'warning',
                'Catch all email address',
                renderOnClickHandler,
              );
            case VerificationStatusType.UNKNOWN:
              return contactInformationIconWithStatus(
                contactInformation,
                'warning',
                'Unknown',
                renderOnClickHandler,
              );
          }
        } else {
          return contactInformationIconWithStatus(
            contactInformation,
            'none',
            '',
            renderOnClickHandler,
          );
        }
      }
      break;
    case 'linkedin':
      if (prospect.linkedin) {
        // We need to check both because Crono is Company-based and if we want to create a contact we need to create a company for it
        if (
          checkIfValidLinkedinCompanyUrl(prospect.linkedin) ||
          checkIfValidLinkedinProfileUrl(prospect.linkedin)
        ) {
          return contactInformationIconWithStatus(
            contactInformation,
            'valid',
            'Valid linkedin url',
            renderOnClickHandler,
          );
        } else {
          return contactInformationIconWithStatus(
            contactInformation,
            'invalid',
            'Invalid linkedin url',
            renderOnClickHandler,
          );
        }
      }
      break;
    case 'phone':
      if (!prospect.phone) {
        return contactInformationIconWithStatus(
          contactInformation,
          'none',
          '',
          renderOnClickHandler,
        );
      }
      if (checkIfValidPhone(prospect.phone ?? '')) {
        return contactInformationIconWithStatus(
          contactInformation,
          'valid',
          'Valid phone number',
          renderOnClickHandler,
        );
      } else {
        return contactInformationIconWithStatus(
          contactInformation,
          'invalid',
          'Invalid phone number',
          renderOnClickHandler,
        );
      }
    case 'mobilePhone':
      if (!prospect.mobilePhone) {
        return contactInformationIconWithStatus(
          contactInformation,
          'none',
          '',
          renderOnClickHandler,
        );
      }
      if (checkIfValidPhone(prospect.mobilePhone ?? '')) {
        return contactInformationIconWithStatus(
          contactInformation,
          'valid',
          'Valid mobile phone number',
          renderOnClickHandler,
        );
      } else {
        return contactInformationIconWithStatus(
          contactInformation,
          'invalid',
          'Invalid mobile phone number',
          renderOnClickHandler,
        );
      }
    default:
      return contactInformation.icon;
  }

  return contactInformation.icon;
};

const ContactInformationIconWrapper = styled.div`
  cursor: pointer;
  .info-icon {
    min-width: 32px;
    min-height: 32px;
    padding: 4px;
    border-radius: 999px;
    border: 2px solid ${colors.grey4};
    margin-right: 8px;
  }
`;
