import React from 'react';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { Task } from 'crono-fe-common/types/task';

export interface WindowContextType {
  windowContent: WindowContent | null;
  openWindow: React.Dispatch<React.SetStateAction<WindowContent | null>>;
  closeWindow: () => void;
}

export type WindowType = 'account' | 'default';

export type WindowTab =
  | 'account'
  | 'prospect'
  | 'tasks'
  | 'email'
  | 'linkedin'
  | 'call'
  | 'callLog'
  | 'inmail'
  | 'deals';

export function parseTaskType(type: TaskTodoType): WindowTab {
  switch (type) {
    case TaskTodoType.EMAIL:
      return 'email';
    case TaskTodoType.LINKEDIN:
      return 'linkedin';
    case TaskTodoType.CALL:
      return 'call';
    case TaskTodoType.IN_MAIL:
      return 'inmail';
    default:
      return 'account';
  }
}

export function parseTab(tab: WindowTab | undefined) {
  switch (tab) {
    case 'account':
      return 0;
    case 'prospect':
      return 1;
    case 'tasks':
      return 2;
    case 'email':
      return 3;
    case 'linkedin':
      return 4;
    case 'call':
      return 5;
    case 'callLog':
      return 6;
    case 'deals':
      return 7;
    case 'inmail':
      return 8;
    default:
      return 0;
  }
}

export interface WindowContent {
  windowType?: WindowType;
  tab?: WindowTab;
  selectedProspectId?: string;
  modalVisible?: WindowTab;
  task?: TaskTodo | null;
  forceReload?: number;
  selectedThreadId?: {
    threadId: string | null;
    toAll: boolean;
  } | null;
  //This function is passed from the suggestions, to be called when the suggestion is accepted
  callOnSuccess?: () => void;
  //Id passed from the suggestion
  suggestionId?: number | null;
  //To keep the template tab opened between tasks
  defaultOpenTemplateTab?: boolean;
  activity?: Task | null;
  selectedOpportunityId?: string | null;
  initialPhoneSelected?: PhoneOption;
}

export type PhoneOption = 'phone' | 'mobilePhone';

export interface ConditionalWindow extends WindowContent {
  condition: boolean;
}
