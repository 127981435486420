import {
  CronoGatewayLinkedinGetAllConversationsAction,
  CronoGatewayLinkedinGetConversationAction,
  CronoGatewayLinkedinGetInvitationsLimitAction,
  CronoGatewayLinkedinGetRecentConnections,
  CronoGatewayLinkedinGetSalesNavAllConversationsAction,
  CronoGatewayLinkedinGetSalesNavConversationAction,
  CronoGatewayLinkedinGetStatusAction,
  CronoGatewayLinkedinHasSalesNavAction,
  CronoGatewayLinkedinSendInvitationAction,
  CronoGatewayLinkedinSendMessageAction,
  CronoGatewayLinkedinSendMessageWithAttachmentAction,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { gatewayService } from 'crono-fe-common/hooks/crono-extension/gateway';
import { CronoGatewayParamsProspect } from 'crono-fe-common/types/crono-extension';

class CronoExtensionRequests {
  public async getLinkedinStatus(
    params: CronoGatewayParamsProspect<CronoGatewayLinkedinGetStatusAction>,
  ) {
    try {
      return await gatewayService.execute<CronoGatewayLinkedinGetStatusAction>({
        target: 'linkedin',
        methodName: 'getStatus',
        params,
        identifiers: 'prospect',
      });
    } catch (e) {
      return null;
    }
  }

  public async sendLinkedinMessage(
    params: CronoGatewayParamsProspect<CronoGatewayLinkedinSendMessageAction>,
  ) {
    try {
      const response =
        await gatewayService.execute<CronoGatewayLinkedinSendMessageAction>({
          target: 'linkedin',
          methodName: 'sendMessage',
          params,
          identifiers: 'prospect',
        });

      return response.messageSent;
    } catch (e) {
      return false;
    }
  }

  public async sendLinkedinMessageWithAttachments(
    params: CronoGatewayParamsProspect<CronoGatewayLinkedinSendMessageWithAttachmentAction>,
  ) {
    try {
      const response =
        await gatewayService.execute<CronoGatewayLinkedinSendMessageWithAttachmentAction>(
          {
            target: 'linkedin',
            methodName: 'sendMessageWithAttachment',
            params,
            identifiers: 'prospect',
          },
        );

      return response.messageSent;
    } catch (e) {
      return false;
    }
  }

  public async sendLinkedinInvitation(
    params: CronoGatewayParamsProspect<CronoGatewayLinkedinSendInvitationAction>,
  ) {
    try {
      return await gatewayService.execute<CronoGatewayLinkedinSendInvitationAction>(
        {
          target: 'linkedin',
          methodName: 'sendInvitation',
          params,
          identifiers: 'prospect',
        },
      );
    } catch (e) {
      return null;
    }
  }

  public async getRecentConnections(
    params: CronoGatewayParamsProspect<CronoGatewayLinkedinGetRecentConnections> = {},
  ) {
    try {
      return await gatewayService.execute<CronoGatewayLinkedinGetRecentConnections>(
        {
          target: 'linkedin',
          methodName: 'getRecentConnections',
          params,
          identifiers: null,
        },
      );
    } catch (e) {
      return null;
    }
  }

  public async hasSalesNav() {
    try {
      return await gatewayService.execute<CronoGatewayLinkedinHasSalesNavAction>(
        {
          target: 'linkedin',
          methodName: 'hasSalesNav',
          params: undefined,
          identifiers: null,
        },
      );
    } catch (e) {
      return false;
    }
  }

  public async getLinkedinAllConversations() {
    try {
      const response =
        await gatewayService.execute<CronoGatewayLinkedinGetAllConversationsAction>(
          {
            target: 'linkedin',
            methodName: 'getAllConversations',
            params: undefined,
            identifiers: null,
          },
        );

      return response.conversations;
    } catch (e) {
      return null;
    }
  }

  public async getLinkedinConversation(profileId: string) {
    try {
      const response =
        await gatewayService.execute<CronoGatewayLinkedinGetConversationAction>(
          {
            target: 'linkedin',
            methodName: 'getConversation',
            params: { profileId },
            identifiers: 'prospect',
          },
        );
      return response.conversation ?? null;
    } catch (e) {
      return null;
    }
  }

  public async getSalesNavAllConversations() {
    try {
      const response =
        await gatewayService.execute<CronoGatewayLinkedinGetSalesNavAllConversationsAction>(
          {
            target: 'linkedin',
            methodName: 'getSalesNavAllConversations',
            params: undefined,
            identifiers: null,
          },
        );

      return response.conversations;
    } catch (e) {
      return null;
    }
  }

  public async getSalesNavConversation(thread: string, profileId: string) {
    try {
      const response =
        await gatewayService.execute<CronoGatewayLinkedinGetSalesNavConversationAction>(
          {
            target: 'linkedin',
            methodName: 'getSalesNavConversation',
            params: {
              thread: thread,
              profileId: profileId,
            },
            identifiers: 'prospect',
          },
        );

      return response;
    } catch (e) {
      return null;
    }
  }

  public async getLinkedinInvitationsLimit() {
    try {
      const response =
        await gatewayService.execute<CronoGatewayLinkedinGetInvitationsLimitAction>(
          {
            target: 'linkedin',
            methodName: 'getInvitationsLimit',
            params: undefined,
            identifiers: null,
          },
        );

      return response;
    } catch (e) {
      return null;
    }
  }
}

const instance = new CronoExtensionRequests();
export default instance;
