import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconXSClose = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        d="M16.9492 17.2922L7.04972 7.39274"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M7.05029 17.292L16.9498 7.3925"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconXSClose;
