export enum SequenceFilterStatusType {
  ALL = 'All',
  ACTIVE = 'Active',
  STOPPED = 'Stopped',
  FINISHED = 'Finished',
  CONVERTED = 'Converted',
}

export enum ByType {
  CONTACTS = 'Contacts',
  PROGRESS = 'Progress',
  LAST_ACTIVITY = 'LastActivity',
  LAST_INTERACTION = 'LastInteraction',
}

export enum OrderType {
  ASC = 'Asc',
  DESC = 'Desc',
}

export enum SequenceDetailFilterType {
  ALL = 'All',
  DELIVERED_EMAILS = 'DeliveredEmails',
  OPENED_EMAILS = 'OpenedEmails',
  REPLIED_EMAILS = 'RepliedEmails',
  BOUNCED_EMAILS = 'BouncedEmails',
  CLICKED_EMAILS_LINK = 'ClickedEmailsLink',
  REPLIED_LINKEDIN_MESSAGE = 'RepliedLinkedinMessage',
  ACCEPTED_LINKEDIN_INVITATION = 'AcceptedLinkedinInvitation',
}

interface Sort {
  by: ByType;
  order: OrderType;
}

interface Show {
  onStep?: number | null;
  filters?: SequenceDetailFilterType[];
}

export interface StrategyDetailSearch {
  strategyId: number;
  sequencesStatus: SequenceFilterStatusType;
  sortsBy: Sort[] | null;
  show: Show;
  limit?: number | null;
  offset?: number | null;
  onlyMyContacts: boolean;
}
