import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const StrategyStepsIconsWrapper = styled.div`
  display: flex;
  align-items: center;

  .step-icon {
    position: relative;
    display: inline-flex;

    &-tip {
      position: absolute;
      top: -6px;
      right: -6px;
      width: 20px;
      height: 20px;
    }
  }

  .steps-left {
    width: 32px;
    height: 32px;
    background-color: ${colors.grey4};
    border-radius: 50%;
  }

  .step-divider {
    border-top: 1px solid ${colors.grey33};
    width: 12px;

    &--gold {
      border-color: ${colors.darkYellow};
    }
  }

  .linkedin-invite-icon-activated {
    &-container {
      width: 32px;
      height: 32px;
      border: 1px solid ${colors.darkYellow};
      border-radius: 50%;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid ${colors.white};
    overflow: hidden;
  }
`;
