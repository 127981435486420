import React, { useEffect, useMemo, useState } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DigitalClock,
  LocalizationProvider,
  TimePicker,
  TimePickerProps,
} from '@mui/x-date-pickers';
import moment from 'moment/moment';
import ClockIconTwelveFifteen from 'crono-fe-common/icons/Icon-Clock-12-15';
import { CronoTimePickerWrapper } from './style';
import { roundDateToMinutes } from 'crono-fe-common/utils';

interface IProps extends TimePickerProps<Date> {
  handleChange: (payload: Date | null) => void;
}

const CronoTimePicker = ({
  handleChange,
  className,
  minTime,
  value,
  ...props
}: IProps) => {
  const [isTimePickerOpen, setIsTimePickerOpen] = useState<boolean>(false);

  const toggleTimePicker = () => setIsTimePickerOpen((prev) => !prev);
  const closeTimePicker = () => setIsTimePickerOpen(false);
  const [checkValidDate, setCheckValidDate] = useState<boolean>(true);

  useEffect(() => {
    setCheckValidDate(!value || !minTime || value >= minTime);
  }, [value, minTime]);

  const checkTimeRestrictions = (newTime: Date | null) => {
    if (newTime) {
      setCheckValidDate(moment(newTime).isValid());
      if (minTime) {
        setCheckValidDate(newTime >= minTime);
      }
    }
  };

  const roundedValue = useMemo(() => {
    if (value) {
      return roundDateToMinutes(value);
    }

    return value;
  }, [value]);

  return (
    <CronoTimePickerWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClickAwayListener onClickAway={closeTimePicker}>
          <Tooltip
            placement="top"
            title={'Invalid date, please select a valid date'}
            arrow
            PopperProps={{ sx: { zIndex: '99999 !important' } }}
            enterDelay={400}
            enterNextDelay={400}
            disableHoverListener={checkValidDate}
          >
            <div>
              <TimePicker
                {...props}
                value={roundedValue}
                className={`select-date-time-button ${className}`}
                ampm={false}
                onChange={(newValue) => {
                  handleChange(newValue);
                  checkTimeRestrictions(newValue);
                  closeTimePicker();
                }}
                open={isTimePickerOpen}
                closeOnSelect
                slots={{
                  openPickerIcon: () => (
                    <ClockIconTwelveFifteen onClick={toggleTimePicker} />
                  ),
                  layout: () => (
                    <DigitalClock
                      ampm={false}
                      value={roundedValue}
                      onChange={(newValue: Date | null) => {
                        handleChange(newValue);
                        checkTimeRestrictions(newValue);
                        closeTimePicker();
                      }}
                      sx={{
                        height: '240px',
                        width: '160px',
                        borderRadius: '8px',
                        paddingInline: '6px',
                        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                        '& .MuiMenuItem-root': {
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '16px',
                          height: 'fit-content',
                          padding: '8px',
                          cursor: 'pointer',
                          width: '100%',
                          borderRadius: '8px',
                          '&:hover': {
                            background: `${colors.primaryLight} !important`,
                            color: colors.primary,
                          },
                        },
                        '& .Mui-selected': {
                          color: `${colors.primaryDark} !important`,
                          background: 'transparent !important',
                        },
                      }}
                    />
                  ),
                }}
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: '99999 !important',
                    },
                  },
                  textField: {
                    InputProps: { size: 'small' },
                  },
                  openPickerIcon: {
                    style: {},
                  },
                }}
                sx={{
                  width: 100,
                  fontSize: 12,
                  boxShadow: 'none',
                  padding: '0px',
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    width: '100%',
                    minWidth: '105px',
                    color: !checkValidDate
                      ? colors.inactiveHover
                      : colors.black,
                  },
                  '.MuiOutlinedInput-input': {
                    padding: '0px',
                    width: '80px',
                    fontSize: '14px',
                    fontWeight: 500,
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '.MuiIconButton-root': {
                    position: 'relative',
                    right: '6px',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                  '.MuiIconButton-root > svg > path, .MuiIconButton-root > svg > circle':
                    {
                      stroke: !checkValidDate
                        ? colors.inactiveHover
                        : colors.black,
                    },
                  '.MuiIconButton-root:hover > svg > path, .MuiIconButton-root:hover > svg > circle':
                    {
                      stroke: colors.darkGreen,
                    },
                }}
              />
            </div>
          </Tooltip>
        </ClickAwayListener>
      </LocalizationProvider>
    </CronoTimePickerWrapper>
  );
};

export default CronoTimePicker;
