import React, { useState } from 'react';
import { Typography, IconButton } from '@mui/material';
import { TextAccordionWrapper } from './style';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { colors } from 'crono-fe-common/theme';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import { OnboardingOptions } from '../finishOnboarding';

interface IProps {
  option: OnboardingOptions;
  index?: number;
  style?: any | null;
}

const OnboardingAccordion = ({ option, style }: IProps) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  return (
    <TextAccordionWrapper style={style}>
      <div
        className="onboarding-step-wrapper"
        style={{ height: expanded ? 'fit-content' : '72px' }}
      >
        <div className="onboarding-step-header-wrapper">
          <span style={{ width: '40px', height: '40px' }}>{option.icon}</span>
          <div className="onboarding-step-header">
            <Typography
              fontSize={'14px'}
              fontWeight={600}
              className={option.completed ? 'completed-step' : ''}
            >
              {option.title}
            </Typography>
            {!option.completed ? (
              <div
                style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
              >
                <Typography fontSize={'14px'} color={colors.grey11}>
                  {option.estimatedTime}
                </Typography>
                <IconButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setExpanded((prev) => !prev);
                  }}
                  style={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    height: 'fit-content',
                  }}
                >
                  <BottomArrow />
                </IconButton>
              </div>
            ) : (
              <IconButton
                className="completed-checkmark"
                style={{ cursor: 'auto' }}
                // should remain closed if completed, left for debug/development
                // onClick={(ev) => {
                //   ev.stopPropagation();
                //   ev.preventDefault();
                //   setExpanded((prev) =>
                //     prev !== option.id ? option.id : null
                //   );
                // }}
              >
                <CheckMarkIcon color={colors.white} />
              </IconButton>
            )}
          </div>
        </div>
        <div
          className="onboarding-component-container"
          style={{ marginLeft: option.id === 1 ? 0 : undefined }}
        >
          {option.component}
        </div>
      </div>
    </TextAccordionWrapper>
  );
};

export default OnboardingAccordion;
