class LinkedinUrlUtils {
  private readonly linkedinProfileRegex =
    /^(?:https?:\/\/)?(?:[a-z]+\.)?linkedin\.com\/(?:pub|in|profile)\/([^/?#]+)\/?.*/;
  private readonly linkedinCompanyRegex =
    /^(?:https?:\/\/)?(?:[a-z]+\.)?linkedin\.com\/(company|school|showcase)\/([^/?#]+)\/?.*/;
  private readonly salesNavLeadUrl =
    /^(?:https?:\/\/)?(?:[a-z]+\.)?linkedin\.com\/sales\/lead\/([^,?#]+)\/?.*/;
  private readonly salesNavCompanyUrl =
    /^(?:https?:\/\/)?(?:[a-z]+\.)?linkedin\.com\/sales\/(company|school|showcase)\/([^,?#]+)\/?.*/;

  public isLinkedinProfileUrl(url: string | null | undefined): boolean {
    if (!url) {
      return false;
    }
    return (
      url.includes('linkedin.com/') &&
      url.includes('/in/') &&
      !url.includes('/details/') &&
      !url.includes('/recent-activity/')
    );
  }

  public getIdFromLinkedinProfileUrl(
    _url: string | null | undefined,
  ): string | null {
    const url = _url?.trim();
    if (!url || url.length === 0) {
      return null;
    }
    const match = this.linkedinProfileRegex.exec(url);
    if (!match || match.length !== 2) {
      return null;
    }
    return hackTryDecode(match[1]);
  }

  public getLeadIdFromSalesNavLeadUrl(url: string | null | undefined) {
    if (!url) {
      return null;
    }
    const match = this.salesNavLeadUrl.exec(url);

    if (!match || match.length !== 2) {
      return null;
    }
    return match[1];
  }

  public getNumericIdFromSalesNavCompanyUrl(url: string | null | undefined) {
    if (!url) {
      return null;
    }
    const match = this.salesNavCompanyUrl.exec(url);
    if (!match || match.length !== 3) {
      return [null, null];
    }
    const [, type, company] = match;
    if (!company) {
      return [null, null];
    }
    return [hackTryDecode(company.replace(/\//g, '')), type];
  }

  public getIdFromLinkedinCompanyUrl(
    url: string | null | undefined,
  ): [string, string] | [null, null] {
    if (!url || url.length === 0) {
      return [null, null];
    }

    const match = this.linkedinCompanyRegex.exec(url);
    if (!match || match.length !== 3) {
      return [null, null];
    }
    const [, type, company] = match;
    if (!company) {
      return [null, null];
    }
    return [hackTryDecode(company.replace(/\//g, '')), type];
  }

  public isAnySalesNavUrl(url: string) {
    return url.includes('linkedin.com/sales');
  }

  public isSalesNavProfileUrl(url: string | null | undefined): boolean {
    if (!url) {
      return false;
    }
    return url.includes('linkedin.com/sales/lead/');
  }

  public isCompanyUrl(url: string | null | undefined): boolean {
    return (
      !!url &&
      !url.includes('/admin/') &&
      !url.includes('/setup/new/') &&
      this.linkedinCompanyRegex.test(url)
    );
  }

  public isNumeric(id: string | null) {
    return !!id && /^\d+$/.test(id);
  }

  public isSalesNavCompanyUrl(url: string): boolean {
    return url.includes('linkedin.com/sales/company/');
  }
  public isSalesNavLeadsListUrl(url: string): boolean {
    const regex = /www\.linkedin\.com\/sales\/lists\/people\/[0-9]+/;
    return regex.test(url);
  }
  public isSalesNavLeadsSearchUrl(url: string, withResults = false): boolean {
    const regex = /www\.linkedin\.com\/sales\/search\/people/;
    const [urlWithoutQueryString, queryString] = url.split('?');
    return (
      regex.test(urlWithoutQueryString) &&
      (!withResults ||
        queryString?.indexOf('query') >= 0 ||
        queryString?.indexOf('savedSearchId') >= 0)
    );
  }
  public isSalesNavCompaniesListUrl(url: string): boolean {
    const [urlWithoutQueryString] = url.split('?');
    const regex = /www\.linkedin\.com\/sales\/accounts\/dashboard/;
    return regex.test(urlWithoutQueryString);
  }
  public isSalesNavCompaniesSearchUrl(url: string): boolean {
    const regex = /www\.linkedin\.com\/sales\/search\/company/;
    return regex.test(url.split('?')[0]);
  }
  public isLinkedinCompaniesSearchUrl(url: string): boolean {
    const regex = /www\.linkedin\.com\/search\/results\/companies/;
    return regex.test(url.split('?')[0]);
  }
  public isLinkedinLeadsSearchUrl(url: string): boolean {
    const regex = /www\.linkedin\.com\/search\/results\/people/;
    return regex.test(url.split('?')[0]);
  }
  public isLinkedinPostsSearchUrl(url: string): boolean {
    const regex = /www\.linkedin\.com\/search\/results\/content/;
    return regex.test(url.split('?')[0]);
  }
  public isLinkedinMessagingThreadUrl(url: string): boolean {
    const regex = /www\.linkedin\.com\/messaging\/thread\/.*/;
    return regex.test(url.split('?')[0]);
  }

  public isSalesNavInboxPage(url: string) {
    return url.indexOf('www.linkedin.com/sales/inbox') >= 0;
  }

  public getLinkedinCompanyRegex() {
    return this.linkedinCompanyRegex;
  }

  public isLeadId(id: string) {
    return id.length >= 39 && id.startsWith('ACwAA');
  }
}

export default new LinkedinUrlUtils();

function hackTryDecode(value: string) {
  // this hacky function should not be needed: we need to take care of decoding the url where we know
  // it is encoded. This is, for instance, from window.location.href.
  // However, our db contains encoded urls. Until we fix this issue, we need this hack
  try {
    return decodeURIComponent(value);
  } catch (e) {
    return value;
  }
}
