export enum CronoLinkedinSearchSortType {
  DATE = 'Date',
  DATEDESC = 'DateDesc',
  COMPANYNAME = 'CompanyName',
  COMPANYNAMEDESC = 'CompanyNameDesc',
  CONTACTNAME = 'ContactName',
  CONTACTNAMEDESC = 'ContactNameDesc',
  REPLIED = 'Replied',
  REPLIEDDESC = 'RepliedDesc',
  TYPE = 'Type',
  TYPEDESC = 'TypeDesc',
  OWNERFULLNAME = 'OwnerFullName',
  OWNERFULLNAMEDESC = 'OwnerFullNameDesc',
}
