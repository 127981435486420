import { RadioButtonUnchecked, CheckCircleRounded } from '@mui/icons-material';
import { Typography, Checkbox, Tooltip } from '@mui/material';
import { useState } from 'react';
import { getColorsStatus } from 'crono-fe-common/utils';
import {
  SingleStatusOptionComponentWrapper,
  StatusTooltipPickerWrapper,
} from './style';
import { colors } from 'crono-fe-common/theme';
import { StatusColors } from 'crono-fe-common/constants/constants';
import { StatusPropertyType } from 'crono-fe-common/types/model';

interface IPropsStatusTooltipPicker {
  statuses: StatusPropertyType[];
  onClick?: () => void;

  currentStatus?: string;
  setStatus?: (status: string) => void;
}

const StatusTooltipPicker = ({
  statuses,
  onClick,
  currentStatus,
  setStatus,
}: IPropsStatusTooltipPicker) => {
  const changeStatus = (status: string) => {
    if (setStatus) {
      setStatus(status);
    }
  };
  return (
    <StatusTooltipPickerWrapper>
      <Typography fontSize={12} lineHeight={'16px'} fontWeight={500}>
        Change Status
      </Typography>
      <div className="status-list-container">
        {statuses.map((status, index) => {
          const statusColor = getColorsStatus(index, statuses.length);
          const selected = status.option === currentStatus;
          return (
            status && (
              <SingleStatusOptionComponent
                selected={selected}
                statusColor={statusColor}
                status={status}
                changeStatus={changeStatus}
                key={index}
              />
            )
          );
        })}
      </div>
    </StatusTooltipPickerWrapper>
  );
};

interface IProps {
  selected: boolean;
  statusColor: StatusColors;
  status: StatusPropertyType;
  changeStatus: (status: string) => void;
}

export const SingleStatusOptionComponent = ({
  selected,
  statusColor,
  status,
  changeStatus,
}: IProps) => {
  const [hover, setHover] = useState(false);

  return (
    <SingleStatusOptionComponentWrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={
          !selected
            ? 'status-option-picker-not-selected'
            : 'status-option-picker'
        }
        style={{
          background: selected
            ? statusColor.dark
            : hover
              ? statusColor.lightHover
              : statusColor.light,
          color: selected ? colors.white : statusColor.dark,
        }}
        onClick={() => changeStatus(status.option)}
      >
        <Tooltip
          title={status.label}
          arrow
          enterDelay={800}
          enterNextDelay={800}
        >
          <Typography fontSize={12} lineHeight={'16px'} fontWeight={500} noWrap>
            {status.label}
          </Typography>
        </Tooltip>
        <Checkbox
          icon={
            <RadioButtonUnchecked
              style={{
                color: selected ? colors.white : statusColor.dark,
              }}
            />
          }
          checkedIcon={<CheckCircleRounded sx={{ color: 'white' }} />}
          checked={selected}
          sx={{ padding: 0 }}
        />
      </div>
    </SingleStatusOptionComponentWrapper>
  );
};

export default StatusTooltipPicker;
