import { useAuth } from 'context/auth';
import CrmHubspotIcon from 'crono-fe-common/icons/Icon-CRM-Hubspot';
import CrmPipedriveIcon from 'crono-fe-common/icons/Icon-CRM-Pipedrive';
import CrmSalesforceIcon from 'crono-fe-common/icons/Icon-CRM-Salesforce';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import React from 'react';

interface IProps {
  color?: string;
}

const CrmIcon = ({ color }: IProps) => {
  const { user } = useAuth();
  return user?.company?.integrationType === IntegrationType.HUBSPOT ? (
    <CrmHubspotIcon color={color} />
  ) : user?.company?.integrationType === IntegrationType.PIPEDRIVE ? (
    <CrmPipedriveIcon color={color} />
  ) : user?.company?.integrationType === IntegrationType.SALESFORCE ? (
    <CrmSalesforceIcon color={color} />
  ) : null;
};

export default CrmIcon;
