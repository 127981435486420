import styled from '@emotion/styled';

export const TextAccordionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .accordion-text {
    width: 100%;
    display: flex;
    padding: 8px;
    overflow: hidden;
    flex-direction: column;
    & > p {
      margin: 0px;
    }
    & > p:empty {
      margin: 8px;
    }
  }
`;
