import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const StrategyCreationWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${colors.black}66;
  z-index: 1000;

  .strategy-creation-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 540px;
    max-height: 90%;
    background: ${colors.white};
    border-radius: 16px;
    padding: 24px 0px 14px 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .close-button {
    align-self: flex-end;
    cursor: pointer;
  }
  .strategy-creation-block-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    overflow: auto;
    padding-right: 24px;
  }
  .strategy-creation-block {
    width: 100%;
  }
  .tags {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .number-picker {
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    display: flex;
    width: fit-content;
    padding: 4px 12px;
    column-gap: 16px;
    align-items: center;
    width: 120px;
    justify-content: space-between;
  }
  .number-picker-button {
    font-size: 18px;
  }
  .channels-container {
    display: flex;
    column-gap: 24px;
    margin-top: 12px;
  }
  .channel-container {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .selected-Linkedin {
    color: ${colors.blue};
  }
  .selected-Email {
    color: ${colors.inactive};
  }
  .selected-Call {
    color: ${colors.callScheduled};
  }
  .channel-checkbox {
    padding: 0px;
    width: 16px;
    height: 16px;
  }
  .ranking-container {
    border-radius: 8px;
    border: 1px solid ${colors.grey4};
    padding: 2px;
    row-gap: 8px;
    display: flex;
    width: 100%;
  }
  .ranking-numbers {
    margin: 18px 6px 0px 12px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  .number {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.grey3};
  }
  .draggable-item {
    top: auto !important;
    left: auto !important;
    margin: 6px;
    border-radius: 8px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .draggable-Call {
    background: ${colors.callScheduledLight};
    color: ${colors.callScheduled};
  }
  .draggable-Email {
    background: ${colors.inactiveLight};
    color: ${colors.inactive};
  }
  .draggable-Linkedin {
    background: ${colors.blueLight};
    color: ${colors.blue};
  }
  .tag-container {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
  }
  .switch-container {
    display: flex;
    align-items: center;
  }
`;
