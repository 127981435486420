import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import useDealsAnalytics from 'hooks/services/analytics/useDealsAnalytics';
import { useCallback, useMemo, useState } from 'react';
import DealsOverviewChart from '../charts/dealsOverviewChart';
import { Pipeline } from 'crono-fe-common/types/pipeline';
import DealsStackedBarChart from '../charts/dealsStackedBarChart';
import { Stage } from 'crono-fe-common/types/stage';

interface IProps {
  filterParams: AnalyticsFilters;
  pipeline: Pipeline | null;
}

export enum AnalyticDealsSwitch {
  Quantity,
  Values,
}

const AnalyticDealsTabController = ({ filterParams, pipeline }: IProps) => {
  //bellow used hook will be removed, conditionaly calling the hook wasnt working
  //so now inside the hook the config enabled run only if the pipeline name is provided

  // const { data: analyticsDeals } = pipeline && pipeline.externalName
  //   ? useDealsAnalytics({
  //       ...filterParams,
  //       pipelineName: pipeline.externalName
  //     },
  //     switchValue
  //   )
  //   : { data: null };

  const { data: analyticsDeals } = useDealsAnalytics({
    ...filterParams,
    pipelineName: pipeline?.externalName || null,
  });

  const sortedPipelineStages: Stage[] = useMemo(() => {
    if (!pipeline) return [];

    return pipeline.stages.sort((a, b) => a.displayOrder - b.displayOrder);
  }, [pipeline]);

  const takeStagesKeys = useMemo(() => {
    return sortedPipelineStages.map((stage) => stage.externalName || '');
  }, [sortedPipelineStages]);

  const takeStagesNames = useMemo(() => {
    return sortedPipelineStages.map((stage) => stage.publicName || '');
  }, [sortedPipelineStages]);

  return (
    <>
      {pipeline && (
        <>
          <DealsOverviewChart
            dealsAnalytics={analyticsDeals?.data?.data}
            stagesKeys={takeStagesKeys}
            stagesNames={takeStagesNames}
          />

          <DealsStackedBarChart
            dealsAnalytics={analyticsDeals?.data?.data}
            stagesKeys={takeStagesKeys}
            stagesNames={takeStagesNames}
          />
        </>
      )}
    </>
  );
};

export default AnalyticDealsTabController;
