import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect } from 'react';
import { AddContainerWrapper } from '../../addOpportunity/style';
import { Typography } from '@mui/material';
import AddStageForm from '../formComponent';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import useCreateStage, {
  StageInput,
} from 'hooks/services/stage/useCreateStage';
import { useJuneAnalytics } from 'context/june';
// import { Stage } from "crono-fe-common/types/stage";

interface IProps {
  close: () => void;
  pipelineId: number;
  existingStages?: string[];
  setLocalStages: (value: any) => void;
}

const AddStageContainer = ({
  close,
  pipelineId,
  existingStages,
  setLocalStages,
}: IProps) => {
  const {
    mutateAsync: createStageAsync,
    isSuccess,
    error,
    isLoading: createLoading,
  } = useCreateStage();

  const analytics = useJuneAnalytics();

  const handleSubmit = async (newStage: StageInput) => {
    const result = await createStageAsync(newStage);
    if (result) {
      // console.log({ result });
      if (analytics) {
        analytics.track('create-pipeline-stage', {});
      }

      // setLocalStages((prev: Stage[]) => [...prev, result]);
    }
  };

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) || 'Error while creating stage',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <BlurBackgroundDivFixed>
      <AddContainerWrapper>
        <CloseTabButton close={close} style={{ marginRight: '18px' }} />
        <div className="add-main-container" style={{ gap: '32px' }}>
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Create new stage
          </Typography>
          <AddStageForm
            close={close}
            handleSubmit={handleSubmit}
            createLoading={createLoading}
            pipelineId={pipelineId}
            existingStages={existingStages}
          />
        </div>
      </AddContainerWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default AddStageContainer;
