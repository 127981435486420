import {
  DeleteOutline,
  RadioButtonUnchecked,
  CheckCircleRounded,
} from '@mui/icons-material';
import {
  Typography,
  Button,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import {
  getColorsStatus,
  getExternalPropertyLabels,
} from 'crono-fe-common/utils';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { useFilters } from 'pages/home/context/filters';
import ExternalPropertyFilter from 'crono-fe-common/types/externalPropertyFilter';
import { useAuth } from 'context/auth';
import { ISize, sizes } from './sizes';
import {
  EmailStatuses,
  emailStatuses,
  phoneStatuses,
  ProspectSearch,
} from 'crono-fe-common/types/DTO/prospectSearch';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { RemoveButton } from 'crono-fe-common/components/CronoButton';
import useSearchStrategies from 'hooks/services/sequence/useSearchStrategies';
import { FeConstants } from 'constants/FeConstants';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { DateRangePickerTooltip } from 'components/DateRangePicker/modal';
import moment from 'moment';
import DateRangePicker from 'components/DateRangePicker';
import { RangeKeyDict } from 'react-date-range';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { ReactComponent as TopArrow } from 'crono-fe-common/icons/Top-Arrow.svg';
import { colors } from 'crono-fe-common/theme';
import useGetImports from 'hooks/services/import/useGetImports';
import { Import } from 'crono-fe-common/types/import';
import FilterExternalProperty from './filterExternalProperty';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';
import {
  SequenceStatusTypeDTO,
  showSequenceStatusDTO,
} from 'crono-fe-common/types/enums/prospectSequenceStatus';
import MobilePhoneIcon from 'crono-fe-common/icons/Icon-Mobile-Phone';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import StrategyAutocomplete from 'components/StrategyAutocomplete';
import { useGetStatusesOptions } from 'utils/fe-utils';
import { SingleStatusOptionComponent } from 'components/CronoStatus/statusTooltipPicker';
import { setToMidDayOfPassedDate } from 'utils/fe-utils';
import {
  DefaultCompanyStatusOptions,
  DefaultContactStatusOptions,
} from 'crono-fe-common/types/model';
import { linkedinIndustries } from 'crono-fe-common/constants/constants';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import MinusSmallIcon from 'crono-fe-common/icons/Icon-Minus-Small';
import { DashboardTabType } from '../index';
import classNames from 'classnames';
import { useJuneAnalytics } from 'context/june';

export type externalInformationAcceptedProspect = Pick<
  ProspectSearch,
  'hasPhone' | 'hasEmail' | 'hasLinkedin' | 'hasMobilePhone'
>;

type ExternalInformation = {
  [k in keyof externalInformationAcceptedProspect]: boolean;
};
const externalInformation: ExternalInformation = {
  hasLinkedin: false,
  hasEmail: false,
  hasPhone: false,
  hasMobilePhone: false,
};

interface IProps {
  close: () => void;
  tabState: DashboardTabType;
}
const sequenceStatusOptions: SequenceStatusTypeDTO[] = Object.values(
  SequenceStatusTypeDTO,
);
const ProspectFilters = ({ close, tabState }: IProps) => {
  const {
    searchProspectParameters: initialFiltersProspect,
    searchLeadParameters: initialFiltersLead,
    setSearchProspectParameters,
    setSearchLeadParameters,
    resetProspectFilters: resetFilters, // handle both lead and contact(prospect)
    updateUserPreferences,
  } = useFilters();

  const { user } = useAuth();
  const { data: users } = useGetUsers();

  // State containing the filters applied in this section
  const [filters, setFilters] = useState<ProspectSearch>(
    tabState === 'Lead' ? initialFiltersLead : initialFiltersProspect,
  );
  const { data: externalProperties } = useGetExternalProperty(
    tabState === 'Lead' ? 'Lead' : 'Prospect',
    true,
    false,
  );
  const { data: accountExternalProperties } = useGetExternalProperty(
    'Account',
    true,
  );
  // When a status is pressed I update the filters
  const handleStatusChange = (status: DefaultContactStatusOptions) => {
    if (filters.actualStatus?.includes(status)) {
      const newStatus = filters.actualStatus?.filter(
        (filter) => filter !== status,
      );
      setFilters({
        ...filters,
        actualStatus: newStatus.length === 0 ? null : newStatus,
      });
    } else {
      setFilters({
        ...filters,
        actualStatus: [...(filters.actualStatus ?? []), status],
      });
    }
  };
  const handleAccountStatusChange = (status: DefaultCompanyStatusOptions) => {
    if (filters.accountStatus?.includes(status)) {
      const newStatus = filters.accountStatus?.filter(
        (filter) => filter !== status,
      );
      setFilters({
        ...filters,
        accountStatus: newStatus.length === 0 ? null : newStatus,
      });
    } else {
      setFilters({
        ...filters,
        accountStatus: [...(filters.accountStatus ?? []), status],
      });
    }
  };

  //External property status prospect
  const {
    externalPropertyStatusId: externalPropertyStatusIdProspect,
    isExternalPropertyStatus: isExternalPropertyStatusProspect,
    statuses: statusesProspect,
  } = useGetStatusesOptions('prospect');

  //External property status account
  const {
    externalPropertyStatusId: externalPropertyStatusIdAccount,
    isExternalPropertyStatus: isExternalPropertyStatusAccount,
    statuses: statusesAccount,
  } = useGetStatusesOptions('account');

  // When a property is pressed I update the filters
  const handleChangeExternalProperty = (
    externalPropertyId: number,
    option: string,
  ) => {
    // Check on first pace which id is being passed for which external prop (prospect, account)
    // In case of status the actions to perform are the same, so I consider also this case in these controls
    if (
      (externalProperties?.data?.data?.findIndex(
        (ep) => ep.id === externalPropertyId,
      ) ?? -1) >= 0 ||
      (isExternalPropertyStatusProspect &&
        externalPropertyStatusIdProspect &&
        externalPropertyId === externalPropertyStatusIdProspect)
    ) {
      // If the option is not selected, I add it to the filters
      if (
        !filters.externalProperties
          ?.filter((ep) => ep.externalPropertyId === externalPropertyId)
          .at(0)
          ?.values?.includes(option)
      ) {
        const externalProperty =
          filters.externalProperties?.filter(
            (ep) => ep.externalPropertyId === externalPropertyId,
          )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
        externalProperty.values = [...(externalProperty.values ?? []), option];
        const newExternalProperties: ExternalPropertyFilter[] = [
          ...(filters.externalProperties?.filter(
            (ep) => ep.externalPropertyId !== externalPropertyId,
          ) || []),
          externalProperty,
        ];

        setFilters({
          ...filters,
          externalProperties: newExternalProperties,
        });
      } else {
        //Remove the filter
        const externalProperty: ExternalPropertyFilter =
          filters.externalProperties?.filter(
            (ep) => ep.externalPropertyId === externalPropertyId,
          )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
        externalProperty.values = externalProperty.values?.filter(
          (value) => value !== option,
        );

        // If the externalProperty has no value I have to remove it from the filters (not adding it)
        const newExternalProperties: ExternalPropertyFilter[] = [
          ...(filters.externalProperties?.filter(
            (ep) => ep.externalPropertyId !== externalPropertyId,
          ) || []),
        ];
        if (externalProperty.values && externalProperty.values?.length > 0) {
          newExternalProperties.push(externalProperty);
        }

        setFilters({
          ...filters,
          externalProperties: newExternalProperties,
        });
      }
    } else if (
      (accountExternalProperties?.data?.data?.findIndex(
        (ep) => ep.id === externalPropertyId,
      ) ?? -1) >= 0 ||
      (isExternalPropertyStatusAccount &&
        externalPropertyStatusIdAccount &&
        externalPropertyId === externalPropertyStatusIdAccount)
    ) {
      if (
        !filters.accountExternalProperties
          ?.filter((ep) => ep.externalPropertyId === externalPropertyId)
          .at(0)
          ?.values?.includes(option)
      ) {
        const accountExternalProperty =
          filters.accountExternalProperties?.filter(
            (ep) => ep.externalPropertyId === externalPropertyId,
          )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
        accountExternalProperty.values = [
          ...(accountExternalProperty.values ?? []),
          option,
        ];
        const newAccountExternalProperties: ExternalPropertyFilter[] = [
          ...(filters.accountExternalProperties?.filter(
            (ep) => ep.externalPropertyId !== externalPropertyId,
          ) || []),
          accountExternalProperty,
        ];
        setFilters({
          ...filters,
          accountExternalProperties: newAccountExternalProperties,
        });
      } else {
        //Remove the filter

        const accountExternalProperty: ExternalPropertyFilter =
          filters.accountExternalProperties?.filter(
            (ep) => ep.externalPropertyId === externalPropertyId,
          )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
        accountExternalProperty.values = accountExternalProperty.values?.filter(
          (value) => value !== option,
        );

        // If the externalProperty has no value I have to remove it from the filters (not adding it)
        const newAccountExternalProperties: ExternalPropertyFilter[] = [
          ...(filters.accountExternalProperties?.filter(
            (ep) => ep.externalPropertyId !== externalPropertyId,
          ) || []),
        ];
        if (
          accountExternalProperty.values &&
          accountExternalProperty.values?.length > 0
        ) {
          newAccountExternalProperties.push(accountExternalProperty);
        }

        setFilters({
          ...filters,
          accountExternalProperties: newAccountExternalProperties,
        });
      }
    }
  };

  const handleChangeSize = (size: ISize) => {
    if (
      filters.numberOfEmployeesMin === size.min &&
      filters.numberOfEmployeesMax === size.max
    ) {
      setFilters({
        ...filters,
        numberOfEmployeesMin: null,
        numberOfEmployeesMax: null,
      });
    } else {
      setFilters({
        ...filters,
        numberOfEmployeesMin: size.min,
        numberOfEmployeesMax: size.max,
      });
    }
  };

  const renderExternalInformation = (
    option: keyof externalInformationAcceptedProspect,
    index: number,
  ) => {
    let label = '';
    let icon = null;
    switch (option) {
      case 'hasLinkedin':
        label = 'Linkedin';
        icon = <LinkedinIcon />;
        break;
      case 'hasEmail':
        label = 'Email';
        icon = <EmailIcon />;
        break;
      case 'hasPhone':
        label = 'Phone';
        icon = <CallIcon />;
        break;
      case 'hasMobilePhone':
        label = 'Mobile Phone';
        icon = <MobilePhoneIcon />;
        break;
      default:
        break;
    }

    return (
      <div
        key={index}
        className={
          filters[option] === true
            ? 'option-selected'
            : filters[option] === false
              ? 'option-negative-selected'
              : 'option'
        }
        onClick={() => handleChangeExistingInformation(option)}
        style={{ userSelect: 'none' }}
      >
        {icon}
        <Typography fontSize={'12px'} fontWeight={500}>
          {label}
        </Typography>

        {filters[option] === true ? (
          <PlusSmallIcon color={colors.green} />
        ) : filters[option] === false ? (
          <MinusSmallIcon color={colors.inactiveHover} />
        ) : null}
      </div>
    );
  };

  const externalInformationOptions = useMemo(
    () =>
      Object.keys(externalInformation).map((option, index) => {
        return renderExternalInformation(
          option as keyof externalInformationAcceptedProspect,
          index,
        );
      }),
    [filters],
  );

  const handleChangeExistingInformation = (
    option: keyof externalInformationAcceptedProspect,
  ) => {
    if (filters[option] === true) {
      setFilters({
        ...filters,
        [option]: false,
      });
    } else if (filters[option] === false) {
      setFilters({
        ...filters,
        [option]: null,
      });
    } else {
      setFilters({
        ...filters,
        [option]: true,
      });
    }
  };
  const analytics = useJuneAnalytics();
  const handleApply = (filters: ProspectSearch) => {
    if (tabState === 'Lead') {
      setSearchLeadParameters(filters);
    } else {
      setSearchProspectParameters(filters);
    }
    if (analytics) {
      analytics.track('filter-contacts-main-list', {
        filters: filters,
        leadsTable: tabState === 'Lead',
      });
    }
    //I update the userPreferences not saving the name
    updateUserPreferences(tabState, filters);
    close();
  };

  const handleChangeFilterStatus = (sequenceStatus: SequenceStatusTypeDTO) => {
    setFilters({ ...filters, sequenceStatus });
  };

  const handleChangeUserId = (userId: number) => {
    setFilters({
      ...filters,
      userId: userId === 0 ? null : userId,
    });
  };

  const handleClear = () => {
    resetFilters(true, tabState);
    close();
  };

  const handleSelectStrategy = (strategyId: number) => {
    handleChangeSequenceSelected(strategyId);
  };

  const handleChangeSequenceSelected = (sequenceSelected: number) => {
    if (sequenceSelected === 0) {
      setFilters({
        ...filters,
        inSequence: null,
        strategyId: null,
        sequenceStatus: null,
      });
    } else if (sequenceSelected === -1) {
      // handleChangeInSequence("Not in strategy")
      setFilters({
        ...filters,
        inSequence: false,
        strategyId: null,
        sequenceStatus: null,
      });
    } else {
      setFilters({
        ...filters,
        inSequence: true,
        strategyId: sequenceSelected,
        //If there are no status in this moment I set the active as default
        sequenceStatus: filters.sequenceStatus ?? SequenceStatusTypeDTO.ACTIVE,
      });
    }
  };
  const { data: strategies } = useSearchStrategies({
    limit: FeConstants.strategyLimit,
    offset: 0,
  });

  const [moreFiltersOpened, setMoreFiltersOpened] = useState<boolean>(false);

  const handleCreatedDateChanges = (ranges: RangeKeyDict) => {
    const newMin = ranges.selection.startDate;
    const newMax = ranges.selection.endDate;
    let desiredMin = newMin;
    if (newMin) {
      desiredMin = setToMidDayOfPassedDate(newMin);
    }
    let desiredMax = newMax;
    if (newMax) {
      desiredMax = setToMidDayOfPassedDate(newMax);
    }
    setFilters({
      ...filters,
      createdDateMin: desiredMin?.toJSON(),
      createdDateMax: desiredMax?.toJSON(),
    });
  };
  const handleLastActivityDateChanges = (ranges: RangeKeyDict) => {
    const newMin = ranges.selection.startDate;
    const newMax = ranges.selection.endDate;
    let desiredMin = newMin;
    if (newMin) {
      desiredMin = setToMidDayOfPassedDate(newMin);
    }
    let desiredMax = newMax;
    if (newMax) {
      desiredMax = setToMidDayOfPassedDate(newMax);
    }
    setFilters({
      ...filters,
      lastActivityDateMin: desiredMin?.toJSON(),
      lastActivityDateMax: desiredMax?.toJSON(),
    });
  };

  const [openCreatedDateTooltip, setOpenCreatedDateTooltip] =
    useState<boolean>(false);
  const [openLastActivityDateTooltip, setOpenLastActivityDateTooltip] =
    useState<boolean>(false);

  const handleClearCreateDate = () => {
    setFilters({
      ...filters,
      createdDateMin: undefined,
      createdDateMax: undefined,
    });
  };
  const handleClearLastActivityDate = () => {
    setFilters({
      ...filters,
      lastActivityDateMin: undefined,
      lastActivityDateMax: undefined,
    });
  };

  const { data: importFiles } = useGetImports(
    10,
    tabState === 'Lead' ? 'Lead' : 'Prospect',
    ImportStatusType.Completed,
  );

  const handleChangeImport = (id: number) => {
    setFilters({
      ...filters,
      importId: id === -1 ? undefined : id,
    });
  };

  const calculateFilterStrategyValue = useMemo(() => {
    if (
      filters.strategyId !== null &&
      filters.strategyId !== undefined &&
      filters.inSequence === true
    ) {
      return filters.strategyId;
    }
    if (filters.inSequence === false) {
      return -1;
    }
    return 0;
  }, [filters.strategyId, filters.inSequence]);

  const handleChangeStatusProspect = (status: string) => {
    if (isExternalPropertyStatusProspect && externalPropertyStatusIdProspect) {
      handleChangeExternalProperty(externalPropertyStatusIdProspect, status);
    } else {
      handleStatusChange(status as DefaultContactStatusOptions);
    }
  };

  const isProspectStatusSelected = (status: string) => {
    if (isExternalPropertyStatusProspect && externalPropertyStatusIdProspect) {
      return (
        filters.externalProperties
          ?.filter(
            (ep) => ep.externalPropertyId === externalPropertyStatusIdProspect,
          )?.[0]
          ?.values?.includes(status) ?? false
      );
    } else {
      return (
        filters.actualStatus?.includes(status as DefaultContactStatusOptions) ??
        false
      );
    }
  };

  const handleChangeStatusAccount = (status: string) => {
    if (isExternalPropertyStatusAccount && externalPropertyStatusIdAccount) {
      handleChangeExternalProperty(externalPropertyStatusIdAccount, status);
    } else {
      handleAccountStatusChange(status as DefaultCompanyStatusOptions);
    }
  };

  const isAccountStatusSelected = (status: string) => {
    if (isExternalPropertyStatusAccount && externalPropertyStatusIdAccount) {
      return (
        filters.accountExternalProperties
          ?.filter(
            (ep) => ep.externalPropertyId === externalPropertyStatusIdAccount,
          )?.[0]
          ?.values?.includes(status) ?? false
      );
    } else {
      return (
        filters.accountStatus?.includes(
          status as DefaultCompanyStatusOptions,
        ) ?? false
      );
    }
  };

  return (
    <div className="filters-container">
      <div className="filter-header">
        <Typography variant="h5" fontWeight={700}>
          Filters
        </Typography>
        <div>
          <RemoveButton
            variant="contained"
            className="button-filter"
            startIcon={<DeleteOutline />}
            onClick={() => {
              handleClear();
            }}
            disableElevation
          >
            Clear
          </RemoveButton>
          <Button
            color="secondary"
            variant="contained"
            className="button-filter"
            onClick={() => {
              handleApply(filters);
            }}
            disableElevation
          >
            Apply
          </Button>
        </div>
      </div>
      <div className="filter-body">
        <div className="filter-section">
          {strategies?.data?.data && (
            <>
              <div className="filter-section">
                <Typography fontSize={14} fontWeight={600}>
                  Strategy
                </Typography>
                <StrategyAutocomplete
                  defaultValue={calculateFilterStrategyValue}
                  selectStrategy={handleSelectStrategy}
                  negativeOptionLabel="Not in strategy"
                />
              </div>
              {calculateFilterStrategyValue > 0 && (
                <div className="filter-section">
                  <Typography fontSize={14} fontWeight={600}>
                    Strategy Status
                  </Typography>
                  <Select
                    defaultValue={SequenceStatusTypeDTO.ACTIVE}
                    value={
                      filters.sequenceStatus ?? SequenceStatusTypeDTO.ACTIVE
                    }
                    onChange={(ev) => {
                      handleChangeFilterStatus(
                        ev.target.value as SequenceStatusTypeDTO,
                      );
                    }}
                    className="select"
                    MenuProps={{
                      style: { zIndex: 35001 },
                      PaperProps: {
                        sx: {
                          borderRadius: '8px',
                          paddingInline: '6px',
                          border: '1px solid #dadde9',
                          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                          '& .MuiMenuItem-root': {
                            fontSize: '12px',
                            lineHeight: '16px',
                            height: 'fit-content',
                            padding: '8px',
                            cursor: 'pointer',
                            width: '100%',
                            borderRadius: '8px',
                            '&:hover': {
                              background: colors.primaryLight,
                              color: colors.primary,
                            },
                          },
                          '& .Mui-selected': {
                            color: colors.primary,
                            backgroundColor: 'transparent !important',
                          },
                        },
                      },
                    }}
                  >
                    {sequenceStatusOptions.map((value) => {
                      return (
                        <MenuItem value={value} key={value}>
                          {showSequenceStatusDTO(value)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )}
            </>
          )}
          {users?.data?.data && (
            <div className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                Contacts Owner
              </Typography>
              <Select
                value={filters.userId || 0}
                onChange={(ev) => {
                  const value = ev.target.value as number;
                  handleChangeUserId(value);
                }}
                className="select"
                MenuProps={{
                  style: { zIndex: 35001 },
                  PaperProps: {
                    sx: {
                      borderRadius: '8px',
                      paddingInline: '6px',
                      border: '1px solid #dadde9',
                      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                      '& .MuiMenuItem-root': {
                        fontSize: '12px',
                        lineHeight: '16px',
                        height: 'fit-content',
                        padding: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        borderRadius: '8px',
                        '&:hover': {
                          background: colors.primaryLight,
                          color: colors.primary,
                        },
                      },
                      '& .Mui-selected': {
                        color: colors.primary,
                        backgroundColor: 'transparent !important',
                      },
                    },
                  },
                }}
              >
                <MenuItem value={0}>All</MenuItem>
                {users?.data?.data.map((user) => {
                  return (
                    <MenuItem value={user.id} key={user.id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          )}
          <div className="filter-section">
            <Typography fontSize={14} fontWeight={600}>
              Title
            </Typography>
            <TextField
              className="text-field"
              size="small"
              value={filters.title || ''}
              onChange={(ev) => {
                setFilters({ ...filters, title: ev.target.value });
              }}
            />
          </div>
          <div className="filter-section">
            <Typography fontSize={14} fontWeight={600}>
              Country
            </Typography>
            <TextField
              className="text-field"
              placeholder="Type a country..."
              size="small"
              value={filters.country || ''}
              onChange={(ev) => {
                setFilters({ ...filters, country: ev.target.value });
              }}
            />
          </div>
          <div className="filter-section">
            <Typography fontSize={14} fontWeight={600}>
              Industry
            </Typography>
            <CronoAutocomplete
              className="text-field"
              freeSolo
              value={filters.industry || ''}
              onChange={(ev, value) => {
                setFilters({ ...filters, industry: value || '' });
              }}
              //To allow the user to see the industry filter even without pressing apply
              onInput={(ev: any) => {
                setFilters({ ...filters, industry: ev.target.value });
              }}
              options={linkedinIndustries.map((el) => el.label)}
              textFieldParams={{
                placeholder: 'Type an industry...',
              }}
            />
          </div>
        </div>
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Status
          </Typography>
          <div className="status-container">
            {statusesProspect.map((status, index) => {
              const statusColors = getColorsStatus(
                index,
                statusesProspect.length,
              );
              return (
                <SingleStatusOptionComponent
                  key={index}
                  statusColor={statusColors}
                  status={status}
                  changeStatus={handleChangeStatusProspect}
                  selected={isProspectStatusSelected(status.option)}
                />
              );
            })}
          </div>
        </div>
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Company status
          </Typography>
          <div className="status-container">
            {statusesAccount.map((status, index) => {
              const statusColors = getColorsStatus(
                index,
                statusesAccount.length,
              );
              return (
                <SingleStatusOptionComponent
                  key={index}
                  statusColor={statusColors}
                  status={status}
                  changeStatus={handleChangeStatusAccount}
                  selected={isAccountStatusSelected(status.option)}
                />
              );
            })}
          </div>
        </div>
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Size (Employees amount)
          </Typography>
          <div className="sizes-container">
            {sizes.map((size, index) => {
              return (
                <div
                  key={index}
                  className={
                    'size ' +
                    (filters.numberOfEmployeesMin === size.min
                      ? 'size-selected'
                      : '')
                  }
                  onClick={() => handleChangeSize(size)}
                >
                  {size.toString}
                  {filters.numberOfEmployeesMin === size.min && (
                    <CloseMIcon className="remove-size-icon" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Existing information
          </Typography>
          <div className="sizes-container">{externalInformationOptions}</div>
        </div>

        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Email status
          </Typography>

          <div className="email-phone-status-container">
            {emailStatuses.map((status) => (
              <div
                key={status}
                className={classNames('email-phone-status', {
                  'email-phone-status--selected':
                    filters.verificationStatuses?.includes(status),
                })}
                onClick={() => {
                  let statusesToUpdate: EmailStatuses[];

                  if (!filters?.verificationStatuses) {
                    statusesToUpdate = [status];
                  } else if (filters.verificationStatuses.includes(status)) {
                    statusesToUpdate = [
                      ...filters.verificationStatuses.filter(
                        (st) => st !== status,
                      ),
                    ];
                  } else {
                    statusesToUpdate = [
                      ...filters.verificationStatuses,
                      status,
                    ];
                  }

                  setFilters((state) => ({
                    ...state,
                    verificationStatuses: statusesToUpdate.length
                      ? statusesToUpdate
                      : null,
                  }));
                }}
              >
                <div
                  className={classNames('email-phone-status__icon', {
                    'email-phone-status__icon--green': status === 'Valid',
                    'email-phone-status__icon--yellow': status === 'AcceptAll',
                    'email-phone-status__icon--red': status === 'Invalid',
                    'email-phone-status__icon--grey': status === 'Unknown',
                  })}
                />

                <Typography
                  fontSize={'12px'}
                  fontWeight={500}
                  lineHeight={'16px'}
                >
                  {status === 'AcceptAll' ? 'Accept all' : status}
                </Typography>
              </div>
            ))}
          </div>
        </div>

        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Phone number status
          </Typography>

          <div className="email-phone-status-container">
            {phoneStatuses.map((status) => (
              <div
                key={status}
                className={classNames('email-phone-status', {
                  'email-phone-status--selected':
                    (status === 'Valid' && filters.isPhoneValid === true) ||
                    (status === 'Invalid' && filters.isPhoneValid === false),
                })}
                onClick={() => {
                  let statusToUpdate: boolean | null = null;

                  if (status === 'Valid') {
                    if (filters.isPhoneValid === true) {
                      statusToUpdate = null;
                    } else {
                      statusToUpdate = true;
                    }
                  }

                  if (status === 'Invalid') {
                    if (filters.isPhoneValid === false) {
                      statusToUpdate = null;
                    } else {
                      statusToUpdate = false;
                    }
                  }

                  setFilters((state) => ({
                    ...state,
                    isPhoneValid: statusToUpdate,
                  }));
                }}
              >
                <div
                  className={classNames('email-phone-status__icon', {
                    'email-phone-status__icon--green': status === 'Valid',
                    'email-phone-status__icon--red': status === 'Invalid',
                  })}
                />

                <Typography
                  fontSize={'12px'}
                  fontWeight={500}
                  lineHeight={'16px'}
                >
                  {status}
                </Typography>
              </div>
            ))}
          </div>
        </div>

        {externalProperties?.data?.data.map((externalProperty, index) => {
          return (
            <div key={externalProperty.id} className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                {externalProperty.publicName}
              </Typography>
              <div className="external-properties-container">
                {getExternalPropertyLabels(externalProperty).map(
                  (option, index2) => {
                    const isSelected = externalProperty.options
                      ? filters.externalProperties
                          ?.filter(
                            (ep) =>
                              ep.externalPropertyId === externalProperty.id,
                          )
                          .at(0)
                          ?.values?.includes(externalProperty.options[index2])
                      : false;
                    return (
                      <FilterExternalProperty
                        option={option}
                        isSelected={isSelected ?? false}
                        externalProperty={externalProperty}
                        index={index}
                        index2={index2}
                        handleChangeExternalProperty={
                          handleChangeExternalProperty
                        }
                        key={index + '-' + index2}
                      />
                    );
                  },
                )}
              </div>
            </div>
          );
        })}
        <Button
          variant="text"
          color="secondary"
          className="more-filters-button"
          endIcon={!moreFiltersOpened ? <BottomArrow /> : <TopArrow />}
          onClick={() => setMoreFiltersOpened((prev) => !prev)}
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          {!moreFiltersOpened ? 'More filters' : 'Hide filters'}
        </Button>
        {moreFiltersOpened && (
          <>
            {accountExternalProperties?.data?.data.map(
              (externalProperty, index) => {
                return (
                  <div key={externalProperty.id} className="filter-section">
                    <Typography fontSize={14} fontWeight={600}>
                      {externalProperty.publicName} {'(company)'}
                    </Typography>
                    <div className="external-properties-container">
                      {getExternalPropertyLabels(externalProperty).map(
                        (option, index2) => {
                          const isSelected = externalProperty.options
                            ? filters.accountExternalProperties
                                ?.filter(
                                  (ep) =>
                                    ep.externalPropertyId ===
                                    externalProperty.id,
                                )
                                .at(0)
                                ?.values?.includes(
                                  externalProperty.options[index2],
                                )
                            : false;
                          return (
                            <FilterExternalProperty
                              option={option}
                              isSelected={isSelected ?? false}
                              externalProperty={externalProperty}
                              index={index}
                              index2={index2}
                              handleChangeExternalProperty={
                                handleChangeExternalProperty
                              }
                              key={index + '-' + index2}
                            />
                          );
                        },
                      )}
                    </div>
                  </div>
                );
              },
            )}
            <div className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                Imported from
              </Typography>
              <Select
                value={filters.importId ?? -1}
                onChange={(ev) => {
                  const value = ev.target.value as number;
                  handleChangeImport(value);
                }}
                className="select"
                MenuProps={{
                  style: { zIndex: 35001 },
                  PaperProps: {
                    sx: {
                      borderRadius: '8px',
                      paddingInline: '6px',
                      border: '1px solid #dadde9',
                      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                      '& .MuiMenuItem-root': {
                        fontSize: '12px',
                        lineHeight: '16px',
                        height: 'fit-content',
                        padding: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        borderRadius: '8px',
                        '&:hover': {
                          background: colors.primaryLight,
                          color: colors.primary,
                        },
                      },
                      '& .Mui-selected': {
                        color: colors.primary,
                        backgroundColor: 'transparent !important',
                      },
                    },
                  },
                }}
                renderValue={(value) => {
                  if (value === -1) {
                    return 'Select a file';
                  }
                  const importFile = importFiles?.data?.data.find(
                    (file: Import) => file.id === value,
                  );
                  return importFile?.fileName ?? 'Import';
                }}
              >
                <MenuItem value={-1}>X</MenuItem>
                {importFiles?.data?.data.map((file: Import) => (
                  <MenuItem value={file.id} key={file.id}>
                    {file.fileName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                Created Date
              </Typography>
              <ClickAwayListener
                onClickAway={() => setOpenCreatedDateTooltip(false)}
              >
                <div>
                  <DateRangePickerTooltip
                    title={
                      <DateRangePicker
                        startDate={
                          filters.createdDateMin
                            ? new Date(filters.createdDateMin)
                            : undefined
                        }
                        endDate={
                          filters.createdDateMax
                            ? new Date(filters.createdDateMax)
                            : undefined
                        }
                        handleChangeRange={handleCreatedDateChanges}
                      />
                    }
                    open={openCreatedDateTooltip}
                    onClose={() => setOpenCreatedDateTooltip(false)}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div className="date-range-picker-container">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            filters.createdDateMin
                              ? new Date(filters.createdDateMin)
                              : null
                          }
                          onChange={(ev) => {
                            if (moment(ev).isValid()) {
                              const newDate = new Date(ev ?? '');
                              if (newDate?.getUTCHours() === 22) {
                                newDate?.setHours(newDate.getHours() + 4);
                              }
                              setFilters({
                                ...filters,
                                createdDateMin: newDate.toJSON() ?? undefined,
                              });
                            }
                          }}
                          disableOpenPicker
                          className="date-picker-range"
                          slotProps={{
                            textField: {
                              onFocus: () => setOpenCreatedDateTooltip(true),
                              variant: 'standard',
                            },
                          }}
                        />
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            filters.createdDateMax
                              ? new Date(filters.createdDateMax)
                              : null
                          }
                          onChange={(ev) => {
                            if (moment(ev).isValid()) {
                              const newDate = new Date(ev ?? '');
                              if (newDate?.getUTCHours() === 22) {
                                newDate?.setHours(newDate.getHours() + 4);
                              }
                              setFilters({
                                ...filters,
                                createdDateMax: newDate.toJSON() ?? undefined,
                              });
                            }
                          }}
                          disableOpenPicker
                          className="date-picker-range"
                          slotProps={{
                            textField: {
                              onFocus: () => setOpenCreatedDateTooltip(true),
                              variant: 'standard',
                            },
                          }}
                        />
                        {(filters.createdDateMin || filters.createdDateMin) && (
                          <IconButton
                            style={{
                              width: 'fit-content',
                              height: 'fit-content',
                            }}
                            onClick={handleClearCreateDate}
                          >
                            <CloseMIcon color={colors.inactive} />
                          </IconButton>
                        )}
                      </LocalizationProvider>
                    </div>
                  </DateRangePickerTooltip>
                </div>
              </ClickAwayListener>
            </div>
            <div className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                Last activity Date
              </Typography>
              <ClickAwayListener
                onClickAway={() => setOpenLastActivityDateTooltip(false)}
              >
                <div>
                  <DateRangePickerTooltip
                    title={
                      <DateRangePicker
                        startDate={
                          filters.lastActivityDateMin
                            ? new Date(filters.lastActivityDateMin)
                            : undefined
                        }
                        endDate={
                          filters.lastActivityDateMax
                            ? new Date(filters.lastActivityDateMax)
                            : undefined
                        }
                        handleChangeRange={handleLastActivityDateChanges}
                      />
                    }
                    open={openLastActivityDateTooltip}
                    onClose={() => setOpenLastActivityDateTooltip(false)}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div className="date-range-picker-container">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            filters.lastActivityDateMin
                              ? new Date(filters.lastActivityDateMin)
                              : null
                          }
                          onChange={(ev) => {
                            if (moment(ev).isValid()) {
                              const newDate = new Date(ev ?? '');
                              if (newDate?.getUTCHours() === 22) {
                                newDate?.setHours(newDate.getHours() + 4);
                              }
                              setFilters({
                                ...filters,
                                lastActivityDateMin:
                                  newDate.toJSON() ?? undefined,
                              });
                            }
                          }}
                          disableOpenPicker
                          className="date-picker-range"
                          slotProps={{
                            textField: {
                              onFocus: () =>
                                setOpenLastActivityDateTooltip(true),
                              variant: 'standard',
                            },
                          }}
                        />
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            filters.lastActivityDateMax
                              ? new Date(filters.lastActivityDateMax)
                              : null
                          }
                          onChange={(ev) => {
                            if (moment(ev).isValid()) {
                              const newDate = new Date(ev ?? '');
                              if (newDate?.getUTCHours() === 22) {
                                newDate?.setHours(newDate.getHours() + 4);
                              }
                              setFilters({
                                ...filters,
                                lastActivityDateMax:
                                  newDate.toJSON() ?? undefined,
                              });
                            }
                          }}
                          disableOpenPicker
                          className="date-picker-range"
                          slotProps={{
                            textField: {
                              onFocus: () =>
                                setOpenLastActivityDateTooltip(true),
                              variant: 'standard',
                            },
                          }}
                        />
                        {(filters.lastActivityDateMin ||
                          filters.lastActivityDateMin) && (
                          <IconButton
                            style={{
                              width: 'fit-content',
                              height: 'fit-content',
                            }}
                            onClick={handleClearLastActivityDate}
                          >
                            <CloseMIcon color={colors.inactive} />
                          </IconButton>
                        )}
                      </LocalizationProvider>
                    </div>
                  </DateRangePickerTooltip>
                </div>
              </ClickAwayListener>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProspectFilters;
