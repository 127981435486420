import { Avatar, Badge, Typography } from '@mui/material';
import {
  MainPrimaryButton,
  CancelButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Suggestion } from 'crono-fe-common/types/suggestion';
import { consultDictionary } from './suggestionDictionary';
import { SuggestionCardWrapper } from './suggestionStyle';
import { stringAvatar } from './utils';
import { colors } from 'crono-fe-common/theme';
import useEditAccount from 'hooks/services/account/useEditAccount';
import usePatchSuggestion from 'hooks/services/suggestion/usePatchSuggestion';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';
import { FeConstants } from 'constants/FeConstants';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { getImageFromUrl } from 'utils/fe-utils';
import UpdateContact from './updateContact';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  suggestion: Suggestion;
}

const SuggestUpdateCompanyCard = ({ suggestion }: IProps) => {
  const template = consultDictionary(suggestion.type);

  const handleOpenForm = () => {
    setShowUpdateCompany(true);
  };

  const analytics = useJuneAnalytics();

  const handleAcceptSuggestion = () => {
    patchSuggestion({
      suggestionId: suggestion.id,
      skip: false,
    });
    if (analytics) {
      analytics.track('execute-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };

  const {
    mutate: patchSuggestion,
    isSuccess: correctlyPatched,
    variables: actionPerformed,
    error: errorPatchSuggestion,
  } = usePatchSuggestion();

  useConditionalSnackBar([
    {
      condition: !!errorPatchSuggestion,
      message: getError(errorPatchSuggestion) ?? 'Error updating suggestion',
      severity: 'error',
    },
    {
      condition: !!correctlyPatched,
      message: actionPerformed?.skip
        ? 'Suggestion correctly skipped'
        : 'Suggestion correctly performed',
      severity: 'success',
    },
  ]);

  const handleRejectSuggestion = () => {
    patchSuggestion({
      suggestionId: suggestion.id,
      skip: true,
    });
    if (analytics) {
      analytics.track('skip-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };

  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();
  const { openWindow } = useWindow();

  const [badgeVisible, setBadgeVisible] = useState<boolean>(false);
  const handleClickNameProspect = (prospectId: string, accountId: string) => {
    setSelectedAccountId(accountId);
    setSelectedProspectId(prospectId);

    openWindow({
      windowType: 'account',
      tab: 'prospect',
      selectedProspectId: prospectId,
    });
  };
  useEffect(() => {
    const currentTime = new Date();
    // Define the time limit for the last 4 hours
    const timeLimit = new Date(
      currentTime.getTime() - FeConstants.notificationTimeSpan,
    ); // 4 hours in milliseconds
    setBadgeVisible(new Date(suggestion.createdDate) > timeLimit);
  }, []);

  const [showUpdateCompany, setShowUpdateCompany] = useState<boolean>(false);

  return (
    <>
      <SuggestionCardWrapper>
        {showUpdateCompany && suggestion.prospect && (
          <UpdateContact
            selectedContact={suggestion.prospect}
            close={() => {
              setShowUpdateCompany(false);
            }}
            newCompanyName={suggestion.newCompanyName ?? null}
            newCompanyPublicId={suggestion.newCompanyPublicId ?? null}
            newRole={suggestion.newRole ?? null}
            onSend={handleAcceptSuggestion}
          />
        )}
        <Badge
          variant="dot"
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          invisible={!badgeVisible}
          color="primary"
          overlap="circular"
          className="badge-suggestion"
        >
          <Avatar
            className="suggestion-avatar"
            key={`avatar-key-${suggestion.objectId}`}
            alt={suggestion.type}
            src={
              suggestion.account?.website &&
              getImageFromUrl(suggestion.account?.website, null)
            }
            {...stringAvatar(suggestion.account?.name ?? '')}
          />
        </Badge>
        <FlexDiv direction="column" alignItems="start">
          <div className="top-header-suggestion-container">
            <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
              {template?.title({})}
            </Typography>
            <span
              className="skip-suggestion-x"
              onClick={handleRejectSuggestion}
            >
              <CloseMIcon color={colors.grey2} />
            </span>
          </div>
          <Typography fontSize={14} fontWeight={500}>
            {template?.description({
              prospectName: (
                <b
                  onClick={() =>
                    handleClickNameProspect(
                      suggestion.prospectId,
                      suggestion.accountId,
                    )
                  }
                  className="name-link"
                >
                  {suggestion.prospect?.name}
                </b>
              ),
            })}
          </Typography>
          <FlexDiv width="100%" justifyContent="start">
            <MainPrimaryButton
              className="action-suggestion-button"
              onClick={handleOpenForm}
            >
              Update
            </MainPrimaryButton>
          </FlexDiv>
          <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
            {moment(suggestion.createdDate).format('ll')}
          </Typography>
        </FlexDiv>
      </SuggestionCardWrapper>
    </>
  );
};

export default SuggestUpdateCompanyCard;
