import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Sync } from 'crono-fe-common/types/sync';

export default function useLookSync() {
  const lookSyncById = (id: number) => {
    const request: Request = {
      url: `${ENDPOINTS.sync.main}/${id}`,
      config: {
        method: 'get',
      },
    };
    return client(request);
  };

  return useMutation<Response<Sync>, CronoError, number>(lookSyncById, {});
}
