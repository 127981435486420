import { Alert, Snackbar } from '@mui/material';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import {
  ConditionalSnackbar,
  SnackbarContent,
  SnackbarContextType,
} from './types';
import { colors } from 'crono-fe-common/theme';

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);
SnackbarContext.displayName = 'SnackbarContext';

const SnackbarProvider: FC<{ children: any }> = ({ children }) => {
  const [snackbarContent, setSnackbarContent] =
    useState<SnackbarContent | null>(null);

  return (
    <SnackbarContext.Provider value={{ pushSnackbar: setSnackbarContent }}>
      {snackbarContent && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={() => setSnackbarContent(null)}
          sx={{
            zIndex: 9999,
            minWidth: snackbarContent.customComponent ? '900px' : undefined,
          }}
        >
          <Alert
            onClose={() => setSnackbarContent(null)}
            severity={snackbarContent.severity}
            sx={{
              width: '100%',
              borderRadius: '8px',
              fontWeight: '500',
              fontSize: '14px',
              backgroundColor:
                snackbarContent.severity === 'success' ? '#EEF7FF' : '',
              color: snackbarContent.severity === 'success' ? colors.black : '',
              alignItems: 'center',
              '& .MuiAlert-icon': {
                color:
                  snackbarContent.severity === 'success' ? colors.black : '',
              },
              ...(snackbarContent.customComponent
                ? {
                    '& .MuiAlert-action': {
                      paddingBlock: 0,
                    },
                  }
                : {}),
            }}
          >
            {snackbarContent.message}

            {snackbarContent.customComponent}
          </Alert>
        </Snackbar>
      )}
      {children}
    </SnackbarContext.Provider>
  );
};

export function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error(`useSnackBar must be used within a SnackbarProvider`);
  }

  return context;
}

export function useConditionalSnackBar(
  conditionalSnackbar: ConditionalSnackbar[],
) {
  try {
    const { pushSnackbar } = useSnackbar();
    //This is done to save the previous state, since the conditions that we use to show the snackbars are not resetting, so they would always be true
    const [savedConditions, setSavedConditions] = useState<boolean[]>(
      new Array(conditionalSnackbar.length).fill(false),
    );
    const conditions = conditionalSnackbar.map(
      (snackbar) => snackbar.condition,
    );

    //Todo, maybe change the snackbars to be an array, to show more of the one above the other
    useEffect(() => {
      conditionalSnackbar.forEach((snackbar, index) => {
        const { condition, ...rest } = snackbar;
        //If the saved one is true I don't have to show the snackbar again

        //This works because when an API is called, the isSuccess (or the error) is set to null while loading, so we update the savedConditions
        //and then we can listen to the changes again, showing the error or the success message again if needed
        condition && !savedConditions[index] && pushSnackbar(rest);
      });
      setSavedConditions(conditions);
    }, [...conditions]);
  } catch (e) {
    throw new Error(
      `useConditionalSnackBar must be used within a SnackbarProvider`,
    );
  }
}

export default SnackbarProvider;
