import React, { useMemo, useState } from 'react';
import { Button, TextField, Tooltip, Typography } from '@mui/material';
import { useAuth } from 'context/auth';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../searchAccordion/styledComponents';
import SearchAccordionMultipleAutocomplete from '../searchAccordion/searchAccordionMultipleAutocomplete';
import { ReactComponent as LocationIcon } from 'crono-fe-common/icons/Icon-Geography.svg';
import { ReactComponent as IndustryIcon } from 'crono-fe-common/icons/Icon-Industry.svg';
import { ReactComponent as KeywordIcon } from 'crono-fe-common/icons/Icon-Keyword.svg';
import { ReactComponent as CompanySIcon } from 'crono-fe-common/icons/Company-S.svg';
import { ReactComponent as RemoveSEmpty } from 'crono-fe-common/icons/Remove-S-empty.svg';
import { useSearchContactTab } from './searchContactTabContext';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { colors } from 'crono-fe-common/theme';
import ContactsIconS from 'crono-fe-common/icons/Icon-Contacts-S';
import { ReactComponent as LocationPinIcon } from 'crono-fe-common/icons/Location.svg';
import { ReactComponent as FireIcon } from 'crono-fe-common/icons/Fire.svg';
import { ReactComponent as FunctionIcon } from 'crono-fe-common/icons/Function.svg';
import { ReactComponent as SeniorityLevelIcon } from 'crono-fe-common/icons/level.svg';
import { ReactComponent as YearsInCompanyIcon } from 'crono-fe-common/icons/Years-in-company.svg';
import { ReactComponent as CategoryInterestIcon } from 'crono-fe-common/icons/Category-interest.svg';
import { ReactComponent as JobTitleIcon } from 'crono-fe-common/icons/Job-title.svg';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import IconInformationS from 'crono-fe-common/icons/Icon-Information-Small';
import ClearIcon from '@mui/icons-material/Clear';
import {
  LinkedinFilterValue,
  LinkedinFilterValueFreeSolo,
} from 'crono-fe-common/types/crono-extension/linkedin';
import {
  LinkedinSeniorityMap,
  LinkedinYearsInCurrentPositionMap,
  SalesNavCompanyHeadcountMap,
} from 'crono-fe-common/types/crono-extension/linkedin-constants-handly-written';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';

interface IProps {
  applyFilters: () => void;
  isDisabledExtension: boolean;
}

const SearchContactTabFilters = ({
  applyFilters,
  isDisabledExtension,
}: IProps) => {
  const { hasSalesNavWithCookie } = useAuth();

  const {
    handleChangeFormik,
    setFiltersChanged,
    values,
    handleAddPersona,
    removePersona,
    filtersChanged,
    personaText,
    setPersonaText,
    handleClearFilters,
  } = useSearchContactTab();

  const disableApplyDueToNoFilters =
    (hasSalesNavWithCookie &&
      Object.values(values).filter((v) => v).length === 0) ??
    false;

  const [expanded, setExpanded] = useState<
    keyof typeof values | 'initialCompany' | null
  >(null);

  const personasResume = useMemo(() => {
    if (!values.title || values.title.length === 0) return null;
    return (
      <div className="personas-container">
        {values.title?.map((persona: string, index: number) => {
          return (
            <div
              className="persona-card"
              key={index}
              style={{
                background:
                  index % 3 === 0
                    ? colors.callScheduledLight
                    : index % 3 === 1
                      ? colors.openOpportunityLight
                      : colors.blueLight,
              }}
            >
              <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
                {persona}
              </Typography>
              <ClearIcon
                onClick={(ev: any) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  removePersona(index);
                }}
                style={{ width: 15, height: 15 }}
              />
            </div>
          );
        })}
      </div>
    );
  }, [values.title]);

  return (
    <div className="search-filters-section">
      <div className="left-side-container">
        <div className="filter-box first-filter">
          <Typography fontSize={16} fontWeight={600} lineHeight={'24px'}>
            Filters
          </Typography>
          <Button
            className="clear-filters-button"
            endIcon={<RemoveSEmpty />}
            onClick={handleClearFilters}
          >
            Clear
          </Button>
        </div>
        <div className="filters-accordions-container">
          {hasSalesNavWithCookie ? (
            <>
              <Typography
                fontSize={14}
                fontWeight={500}
                lineHeight={'18px'}
                color={colors.grey11}
                padding={'12px 24px 12px 24px'}
              >
                Company information
              </Typography>
              <SearchAccordionMultipleAutocomplete
                currentValue={values.currentCompanies ?? []}
                displayLabel="Current company"
                icon={<CompanySIcon />}
                id="initialCompany"
                placeholder="Type company name..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('currentCompanies', selectedOptions);
                }}
                handleSelectSingle={(currentCompany: LinkedinFilterValue) => {
                  if (!values.currentCompanies) return;
                  handleChangeFormik(
                    'currentCompanies',
                    values.currentCompanies.filter(
                      (i) => i.id !== currentCompany.id,
                    ),
                  );
                }}
                expanded={expanded === 'currentCompanies'}
                setExpanded={() => {
                  setExpanded(
                    expanded === 'currentCompanies' ? null : 'currentCompanies',
                  );
                }}
                filterType={{
                  type: 'salesNav',
                  filterName: 'COMPANY_WITH_LIST',
                }}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.currentCompanyHeadcount ?? []}
                displayLabel="Company size"
                icon={<ContactsIconS />}
                id="companyHeadcountAutocomplete"
                placeholder="Type size range..."
                options={SalesNavCompanyHeadcountMap}
                onChange={(e, selectedOptions) => {
                  handleChangeFormik(
                    'currentCompanyHeadcount',
                    selectedOptions,
                  );
                }}
                handleSelectSingle={(headcount: LinkedinFilterValue) => {
                  if (!values.currentCompanyHeadcount) return;
                  handleChangeFormik(
                    'currentCompanyHeadcount',
                    values.currentCompanyHeadcount.filter(
                      (i) => i !== headcount,
                    ),
                  );
                }}
                expanded={expanded === 'currentCompanyHeadcount'}
                setExpanded={() => {
                  setExpanded(
                    expanded === 'currentCompanyHeadcount'
                      ? null
                      : 'currentCompanyHeadcount',
                  );
                }}
                orderForIds={true}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.companyHeadquarters ?? []}
                displayLabel="Headquarters location"
                icon={<LocationPinIcon />}
                id="companyHeadquartersLocation"
                placeholder="Type location..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('companyHeadquarters', selectedOptions);
                }}
                handleSelectSingle={(headcount: LinkedinFilterValue) => {
                  if (!values.companyHeadquarters) return;
                  handleChangeFormik(
                    'companyHeadquarters',
                    values.companyHeadquarters.filter(
                      (i) => i.id !== headcount.id,
                    ),
                  );
                }}
                expanded={expanded === 'companyHeadquarters'}
                setExpanded={() => {
                  setExpanded(
                    expanded === 'companyHeadquarters'
                      ? null
                      : 'companyHeadquarters',
                  );
                }}
                filterType={{
                  type: 'salesNav',
                  filterName: 'BING_GEO',
                }}
              />
              <div className="single-line-find-new-filter">
                <div className="inner-text-section">
                  <FireIcon />
                  <div className="inner-text-with-tooltip">
                    Buyer intent
                    <Tooltip
                      title="Leads who work at accounts that have expressed high or moderate interest in your company in the past 30 days. Buyer intent is determined by InMail and ad engagement, company page views, and profile views."
                      enterDelay={400}
                      enterNextDelay={400}
                      arrow
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: 'fit-content',
                          height: 'fit-content',
                          cursor: 'pointer',
                        }}
                      >
                        <IconInformationS color={colors.grey11} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <CronoSwitch
                  checked={values.accountHasBuyerIntent ?? false}
                  onChange={(e) => {
                    handleChangeFormik(
                      'accountHasBuyerIntent',
                      e.target.checked,
                    );
                  }}
                />
              </div>
              <SearchAccordionMultipleAutocomplete
                currentValue={values.industry ?? []}
                displayLabel="Industry"
                id="industryAutocomplete"
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('industry', selectedOptions);
                }}
                placeholder="Type keyword..."
                handleSelectSingle={(industry: LinkedinFilterValue) => {
                  if (!values.industry) return;
                  handleChangeFormik(
                    'industry',
                    values.industry.filter((i) => i !== industry),
                  );
                }}
                icon={<IndustryIcon />}
                expanded={expanded === 'industry'}
                setExpanded={() => {
                  setExpanded(expanded === 'industry' ? null : 'industry');
                }}
                filterType={{
                  type: 'salesNav',
                  filterName: 'INDUSTRY',
                }}
              />
              <Typography
                fontSize={14}
                fontWeight={500}
                lineHeight={'18px'}
                color={colors.grey11}
                padding={'12px 24px 12px 24px'}
              >
                Contact information
              </Typography>
              <SearchAccordionMultipleAutocomplete
                currentValue={values.function ?? []}
                displayLabel="Function"
                icon={<FunctionIcon />}
                id="contactFunction"
                placeholder="Type function..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('function', selectedOptions);
                }}
                handleSelectSingle={(f: LinkedinFilterValue) => {
                  if (!values.function) return;
                  handleChangeFormik(
                    'function',
                    values.function.filter((i) => i.id !== f.id),
                  );
                }}
                disableTopBorder={true}
                expanded={expanded === 'function'}
                setExpanded={() => {
                  setExpanded(expanded === 'function' ? null : 'function');
                }}
                filterType={{
                  type: 'salesNav',
                  filterName: 'FUNCTION',
                }}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.jobTitle ?? []}
                displayLabel="Current job title"
                icon={<JobTitleIcon />}
                id="contactJobTitle"
                placeholder="Type job title..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('jobTitle', selectedOptions);
                }}
                handleSelectSingle={(f: LinkedinFilterValueFreeSolo) => {
                  if (!values.jobTitle) return;
                  handleChangeFormik(
                    'jobTitle',
                    values.jobTitle.filter((i) => {
                      if (f.id === null || f.id === undefined) {
                        return i.label !== f.label;
                      }
                      return i.id !== f.id;
                    }),
                  );
                }}
                freeSolo={true}
                expanded={expanded === 'jobTitle'}
                setExpanded={() => {
                  setExpanded(expanded === 'jobTitle' ? null : 'jobTitle');
                }}
                filterType={{
                  type: 'salesNav',
                  filterName: 'TITLE',
                }}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.seniority ?? []}
                displayLabel="Seniority level"
                icon={<SeniorityLevelIcon />}
                id="contactSeniority"
                placeholder="Type seniority level..."
                options={LinkedinSeniorityMap}
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('seniority', selectedOptions);
                }}
                handleSelectSingle={(f: LinkedinFilterValue) => {
                  if (!values.seniority) return;
                  handleChangeFormik(
                    'seniority',
                    values.seniority.filter((i) => i.id !== f.id),
                  );
                }}
                expanded={expanded === 'seniority'}
                setExpanded={() => {
                  setExpanded(expanded === 'seniority' ? null : 'seniority');
                }}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.yearsInCurrentPosition ?? []}
                displayLabel="Years in current company"
                icon={<YearsInCompanyIcon />}
                id="contactYearsInCurrentCompany"
                placeholder="Type amount of years..."
                options={LinkedinYearsInCurrentPositionMap}
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('yearsInCurrentPosition', selectedOptions);
                }}
                handleSelectSingle={(f: LinkedinFilterValue) => {
                  if (!values.yearsInCurrentPosition) return;
                  handleChangeFormik(
                    'yearsInCurrentPosition',
                    values.yearsInCurrentPosition.filter((i) => i.id !== f.id),
                  );
                }}
                expanded={expanded === 'yearsInCurrentPosition'}
                setExpanded={() => {
                  setExpanded(
                    expanded === 'yearsInCurrentPosition'
                      ? null
                      : 'yearsInCurrentPosition',
                  );
                }}
                orderForIds={true}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.geography ?? []}
                displayLabel="Location"
                icon={<LocationIcon />}
                id="contactLocation"
                placeholder="Type location..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('geography', selectedOptions);
                }}
                handleSelectSingle={(f: LinkedinFilterValue) => {
                  if (!values.geography) return;
                  handleChangeFormik(
                    'geography',
                    values.geography.filter((i) => i.id !== f.id),
                  );
                }}
                expanded={expanded === 'geography'}
                setExpanded={() => {
                  setExpanded(expanded === 'geography' ? null : 'geography');
                }}
                filterType={{
                  type: 'salesNav',
                  filterName: 'BING_GEO',
                }}
              />

              <SearchAccordionMultipleAutocomplete
                currentValue={values.persona ?? []}
                displayLabel="Persona"
                icon={
                  <IconMContact
                    style={{
                      height: 16,
                      width: 16,
                    }}
                  />
                }
                id="contactPersona"
                placeholder="Type persona..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('persona', selectedOptions);
                }}
                handleSelectSingle={(p: LinkedinFilterValue) => {
                  if (!values.persona) return;
                  handleChangeFormik(
                    'persona',
                    values.persona.filter((i) => i.id !== p.id),
                  );
                }}
                expanded={expanded === 'persona'}
                setExpanded={() => {
                  setExpanded(expanded === 'persona' ? null : 'persona');
                }}
                filterType={{
                  type: 'salesNav',
                  filterName: 'PERSONA',
                }}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.categoryInterest ?? []}
                displayLabel="Category interest"
                icon={<CategoryInterestIcon />}
                id="contactCategoryInterest"
                placeholder="Type product category..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('categoryInterest', selectedOptions);
                }}
                handleSelectSingle={(f: LinkedinFilterValue) => {
                  if (!values.categoryInterest) return;
                  handleChangeFormik(
                    'categoryInterest',
                    values.categoryInterest.filter((i) => i.id !== f.id),
                  );
                }}
                tooltipText="Leads who have potential interest in your product category based on group membership, profile data, and other signals."
                expanded={expanded === 'categoryInterest'}
                setExpanded={() => {
                  setExpanded(
                    expanded === 'categoryInterest' ? null : 'categoryInterest',
                  );
                }}
                filterType={{
                  type: 'salesNav',
                  filterName: 'PRODUCT_CATEGORY_INTEREST',
                }}
              />
            </>
          ) : (
            <>
              <SearchAccordionMultipleAutocomplete
                currentValue={values.currentCompanies ?? []}
                displayLabel="Current company"
                icon={<CompanySIcon />}
                id="initialCompany"
                placeholder="Type company name..."
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('currentCompanies', selectedOptions);
                }}
                handleSelectSingle={(currentCompany: LinkedinFilterValue) => {
                  if (!values.currentCompanies) return;
                  handleChangeFormik(
                    'currentCompanies',
                    values.currentCompanies.filter(
                      (i) => i.id !== currentCompany.id,
                    ),
                  );
                }}
                expanded={expanded === 'currentCompanies'}
                setExpanded={() => {
                  setExpanded(
                    expanded === 'currentCompanies' ? null : 'currentCompanies',
                  );
                }}
                filterType={{
                  type: 'linkedin',
                  filterName: 'COMPANY',
                }}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.geoUrn ?? null}
                displayLabel="Location"
                onChange={(e, label) => {
                  handleChangeFormik('geoUrn', label);
                }}
                handleSelectSingle={(geoUrn: LinkedinFilterValue) => {
                  if (!values.geoUrn) return;
                  handleChangeFormik(
                    'geoUrn',
                    values.geoUrn.filter((i) => i !== geoUrn),
                  );
                }}
                id="geoUrnSelect"
                placeholder="Type city/country name..."
                icon={<LocationIcon />}
                expanded={expanded === 'geoUrn'}
                setExpanded={() => {
                  setExpanded(expanded === 'geoUrn' ? null : 'geoUrn');
                }}
                filterType={{
                  type: 'linkedin',
                  filterName: 'GEO',
                }}
              />
              <SearchAccordionMultipleAutocomplete
                currentValue={values.industry ?? []}
                displayLabel="Industry"
                id="industryAutocomplete"
                onChange={(e, selectedOptions) => {
                  handleChangeFormik('industry', selectedOptions);
                }}
                placeholder="Type keyword..."
                handleSelectSingle={(industry: LinkedinFilterValue) => {
                  if (!values.industry) return;
                  handleChangeFormik(
                    'industry',
                    values.industry.filter((i) => i !== industry),
                  );
                }}
                icon={<IndustryIcon />}
                expanded={expanded === 'industry'}
                setExpanded={() => {
                  setExpanded(expanded === 'industry' ? null : 'industry');
                }}
                filterType={{
                  type: 'linkedin',
                  filterName: 'INDUSTRY',
                }}
              />
            </>
          )}
          {/*This is common to both the filter */}
          <Accordion
            defaultExpanded={(values.title?.length ?? 0) > 0}
            expanded={expanded === 'title'}
          >
            <AccordionSummary
              onClick={() => setExpanded(expanded === 'title' ? null : 'title')}
              classes={{
                expandIconWrapper: 'expand-icon-wrapper',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: 8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <KeywordIcon />
                  Keywords
                </div>
                {expanded !== 'title' && personasResume}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                variant="standard"
                fullWidth
                placeholder="Insert a word..."
                value={personaText}
                onChange={(e: any) => {
                  setPersonaText(e.target.value);
                  setFiltersChanged(true);
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleAddPersona();
                  }
                }}
              />
              {expanded === 'title' && personasResume}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="apply-filter-container">
        <MainButton
          fullWidth
          onClick={applyFilters}
          className={!filtersChanged ? 'apply-filters-disabled' : undefined}
          disabled={
            !filtersChanged || isDisabledExtension || disableApplyDueToNoFilters
          }
        >
          Apply filter
        </MainButton>
      </div>
    </div>
  );
};

export default SearchContactTabFilters;
