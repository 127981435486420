import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InMailModalWrapper = styled.div`
  padding-top: 12px;

  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow: hidden;
  flex: 1;

  p {
    margin: 0;
  }

  .participants {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .linkedin-icon {
      cursor: pointer;
    }

    .label {
      font-weight: normal;
    }

    .receiver {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;

      display: flex;
      gap: 4px;
    }

    .sender {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: ${colors.grey11};
    }
  }

  .subject {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;

    .label {
      font-weight: normal;
      color: ${colors.grey11};
    }
  }

  .conversation {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;
    overflow-y: auto;
    word-break: break-word;

    .message-container {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 16px;
      padding: 4px 0px 16px 8px;
      border-bottom: 1px solid ${colors.grey444};

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;

        width: 2px;
        height: calc(100% - 20px);
        background-color: ${colors.grey4Hover};
      }

      .message-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .author {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
        }

        .date {
          font-size: 12px;
          line-height: 16px;
          color: ${colors.grey11};
        }
      }
    }

    .reply {
      width: calc(100% - 18px);
    }
  }
`;
