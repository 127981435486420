import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import IconRevenue from 'crono-fe-common/icons/Icon-Revenue';
import { colors } from 'crono-fe-common/theme';
import useGetUserCredits from 'hooks/services/user/useGetUserCredits';
import moment from 'moment';
import { SettingsTabs } from 'pages/settings';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { RemainingTokensBadgeWrapper } from './style';

interface IProps {
  type: 'email' | 'phone';
  requiredLength: number;
  showIcon?: boolean;
}

const RemainingTokensBadge = ({
  type,
  requiredLength,
  showIcon = true,
}: IProps) => {
  const { data: creditsData } = useGetUserCredits();
  const credits = creditsData?.data?.data;
  const creditsLeft =
    (type === 'email' ? credits?.emailLeft : credits?.phoneLeft) ?? 0;

  const navigate = useNavigate();

  const handleBuyMoreCredits = () => {
    navigate(PATH.SETTINGS, {
      state: {
        initialSettingsTab: SettingsTabs.AddOns,
      },
    });
  };
  return (
    <RemainingTokensBadgeWrapper onClick={handleBuyMoreCredits}>
      <Tooltip
        enterDelay={400}
        enterNextDelay={400}
        arrow
        placement="top"
        title={
          <span
            style={{
              whiteSpace: 'pre-line',
              display: 'flex',
              textAlign: 'center',
            }}
          >
            {creditsLeft} Find {type} credits left.
            {'\n'}
            Next update:{' '}
            {moment(credits?.creditsRenewalDate).format('MMM DD, YYYY')}
          </span>
        }
      >
        <div
          className={
            creditsLeft < requiredLength
              ? 'remaining-tokens-unsufficient'
              : 'remaining-tokens'
          }
        >
          <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
            {creditsLeft} credits left
          </Typography>
          {creditsLeft < requiredLength && (
            <div className="buy-more-button">
              {showIcon && <IconRevenue color={colors.white} />}
              <Typography
                fontSize={11}
                lineHeight={'14px'}
                fontWeight={500}
                color={colors.white}
              >
                Buy
              </Typography>
            </div>
          )}
        </div>
      </Tooltip>
    </RemainingTokensBadgeWrapper>
  );
};

export default RemainingTokensBadge;
