import { useAuth } from 'context/auth';
import { FC, useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ChartsGridWrapper } from './styles';
import useCallsReport from 'hooks/services/event/useCallsReport';
import CallsTable from './tables/CallsTable';
import { FeConstants } from 'constants/FeConstants';
import EmailsTable from './tables/EmailsTable';
import LinkedinsTable from './tables/LinkedinsTable';
import useLinkedinsReport from 'hooks/services/event/useLinkedinsReport';
import useEmailsReport from 'hooks/services/event/useEmailsReport';
import { Account } from 'crono-fe-common/types/account';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { User } from 'crono-fe-common/types/user';
import { Prospect } from 'crono-fe-common/types/prospect';
import { CallFeedback } from 'crono-fe-common/types/logCall';
import { useJuneAnalytics } from 'context/june';
import useSearchAccounts from 'hooks/services/account/useSearchAccounts';
import MessageReportModal from './modal/MessageReportModal';
import { CronoEmailSearchSortType } from 'crono-fe-common/types/enums/cronoEmailSearchSortType';
import { CronoLinkedinSearchSortType } from 'crono-fe-common/types/enums/cronoLinkedinSearchSortType';
import { CronoCallSearchSortType } from 'crono-fe-common/types/enums/cronoCallSearchSortType';
import {
  newDateWithInitialTime,
  newDateWithEndTime,
  fullname,
} from 'utils/fe-utils';
import { colors } from 'crono-fe-common/theme';
import { UserSubscription } from 'hooks/services/subscription/useGetUsers';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import NoInformationRows from '../../assets/images/Empty-chart-rows.png';
import { ReactComponent as EmailIcon } from '../../assets/images/No_email_chart_icon.svg';
import { ReactComponent as CallIcon } from '../../assets/images/No_call_chart_icon.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/images/No_linkedin_chart_icon.svg';

export type ReportMessage = {
  account: Account;
  prospect: Prospect;
  type: 'linkedin' | 'email';
  content: string;
  subject: string | null;
  sentTime: string;
};

const ReportsTables: FC = () => {
  const { useAuthGuard, user } = useAuth();
  useAuthGuard();
  const [since, setSince] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [limit, setLimit] = useState<number>(FeConstants.defaultReportLimit);
  const [userId, setUserId] = useState<number | null>(user?.id ?? null);
  const [callOffset, setCallOffset] = useState<number>(0);
  const [emailOffset, setEmailOffset] = useState<number>(0);
  const [linkedinOffset, setLinkedinOffset] = useState<number>(0);
  const [period, setPeriod] = useState<string | null>('');
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [searchUserText, setSearchUserText] = useState<string>('');
  const [openReportMessageModal, setOpenReportMessageModal] =
    useState<boolean>(false);
  const [selectedReportMessage, setSelectedReportMessage] =
    useState<ReportMessage | null>(null);
  const [isAnsweredEmail, setIsAnsweredEmail] = useState<boolean | null>(null);
  const [isOpenedEmail, setIsOpenedEmail] = useState<boolean | null>(null);
  const [isClickedEmail, setIsClickedEmail] = useState<boolean | null>(null);

  const [isAnsweredLinkedin, setIsAnsweredLinkedin] = useState<boolean | null>(
    null,
  );
  const [callFeedback, setCallFeedback] = useState<CallFeedback | null>(null);

  const { data: users } = useGetUsers();

  const analytics = useJuneAnalytics();

  const { data: accounts } = useSearchAccounts({
    name: searchText,
    limit: 10,
    offset: 0,
    status: null,
    sort: 'Name',
    userId: null,
  });

  const [sortModelCall, setSortModelCall] =
    useState<CronoCallSearchSortType | null>(null);

  const { data: callsData, isLoading: loadingCall } = useCallsReport(
    {
      ...(userId && { userId }),
      ...(since && { since }),
      ...(to && { to }),
      ...{ limit: limit, offset: callOffset },
      ...(selectedAccount && { accountId: selectedAccount }),
      ...(callFeedback && { callFeedback: callFeedback }),
      ...(sortModelCall && { cronoCallSearchSortType: sortModelCall }),
    },
    true,
  );

  useEffect(() => {
    setCallOffset(0);
  }, [userId, since, to, selectedAccount, callFeedback, sortModelCall]);

  const [sortModelLinkedin, setSortModelLinkedin] =
    useState<CronoLinkedinSearchSortType | null>(null);

  const { data: linkedinsData, isLoading: loadingLinkedins } =
    useLinkedinsReport(
      {
        ...(userId && { userId }),
        ...(since && { since }),
        ...(to && { to }),
        ...{ limit: limit, offset: linkedinOffset },
        ...(selectedAccount && { accountId: selectedAccount }),
        ...(isAnsweredLinkedin && { isAnsweredLinkedin }),
        ...(sortModelLinkedin && {
          cronoLinkedinSearchSortType: sortModelLinkedin,
        }),
      },
      !!user?.otherSettings?.hasLinkedin,
    );

  useEffect(() => {
    setLinkedinOffset(0);
  }, [
    userId,
    since,
    to,
    selectedAccount,
    isAnsweredLinkedin,
    sortModelLinkedin,
  ]);

  const [sortModelEmail, setSortModelEmail] =
    useState<CronoEmailSearchSortType | null>(null);

  const { data: emailsData, isLoading: loadingEmail } = useEmailsReport(
    {
      ...(userId && { userId }),
      ...(since && { since }),
      ...(to && { to }),
      ...{ limit: limit, offset: emailOffset },
      ...(selectedAccount && { accountId: selectedAccount }),
      ...(isAnsweredEmail !== null && { isAnsweredEmail }),
      ...(isOpenedEmail !== null && { isOpenedEmail }),
      ...(isClickedEmail !== null && { isClickedEmail }),
      ...(sortModelEmail !== null && {
        cronoEmailSearchSortType: sortModelEmail,
      }),
    },
    !!user?.otherSettings?.hasEmail,
  );

  useEffect(() => {
    setEmailOffset(0);
  }, [
    userId,
    since,
    to,
    selectedAccount,
    isAnsweredEmail,
    isOpenedEmail,
    isClickedEmail,
    sortModelEmail,
  ]);

  function updateSince(value: Date | null) {
    setSince(value);
    setPeriod('');
  }

  function updateTo(value: Date | null) {
    setTo(value);
    setPeriod('');
  }

  useEffect(() => {
    if (emailsData && emailsData.data && analytics) {
      analytics.track('view-reports', {
        type: 'email',
      });
    }
  }, [emailsData]);

  function choosePeriodSelectClassName(value: string | null): string {
    if (!value || value === '') {
      return 'input-period-placeholder';
    }
    return 'input-period';
  }

  function updatePeriod(value: string | null) {
    setPeriod(value);
    if (value === '') {
      setSince(null);
      setTo(null);
    } else if (value === 'today') {
      // setSince to yesterday
      setSince(newDateWithInitialTime());
      setTo(null);
    } else if (value === 'this-week') {
      // find last monday
      const lastMonday = newDateWithInitialTime();
      lastMonday.setDate(lastMonday.getDate() - lastMonday.getDay() + 1);
      setSince(lastMonday);
      setTo(new Date());
    } else if (value === 'this-month') {
      // setSince to last month
      // set date to the first of this month
      const lastMonth = newDateWithInitialTime();
      lastMonth.setDate(1);
      setSince(lastMonth);
      setTo(new Date());
    } else if (value === 'this-quarter') {
      // setSince to last month
      // set date to the begin of this quarter
      const lastQuarter = newDateWithInitialTime();
      lastQuarter.setMonth(Math.floor(lastQuarter.getMonth() / 3) * 3);
      lastQuarter.setDate(1);
      setSince(lastQuarter);
      setTo(new Date());
    } else if (value === 'last-week') {
      // setSince to last week
      // set date to the first of last week
      const lastWeekMonday = newDateWithInitialTime();
      lastWeekMonday.setDate(
        lastWeekMonday.getDate() - lastWeekMonday.getDay() + 1,
      );
      lastWeekMonday.setDate(lastWeekMonday.getDate() - 7);
      setSince(lastWeekMonday);
      const lastWeekSunday = newDateWithEndTime();
      lastWeekSunday.setDate(
        lastWeekSunday.getDate() - lastWeekSunday.getDay(),
      );
      setTo(lastWeekSunday);
    } else if (value === 'last-month') {
      // setSince to last month
      // set date to the first of last month
      const lastMonth = newDateWithInitialTime();
      lastMonth.setDate(1);
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      setSince(lastMonth);
      const lastMonthEnd = newDateWithEndTime();
      lastMonthEnd.setDate(0);
      setTo(lastMonthEnd);
    } else if (value === 'last-quarter') {
      // setSince to last month
      // set date to the begin of last quarter
      const lastQuarter = new Date();
      lastQuarter.setMonth(Math.floor(lastQuarter.getMonth() / 3) * 3 - 3);
      lastQuarter.setDate(1);
      setSince(lastQuarter);
      const lastQuarterEnd = newDateWithEndTime();
      lastQuarterEnd.setMonth(Math.floor(lastQuarterEnd.getMonth() / 3) * 3);
      lastQuarterEnd.setDate(0);
      setTo(lastQuarterEnd);
    }
  }

  const handleSelectReportMessage = (reportMessage: ReportMessage) => {
    setSelectedReportMessage(reportMessage);
    setOpenReportMessageModal(true);
  };

  return (
    <ChartsGridWrapper>
      {/* <div
        style={{ alignSelf: "flex-start", cursor: "pointer" }}
        onClick={() => navigate(PATH.HOME)}
      >
        <CloseMIcon color={colors.grey11} />
      </div> */}
      {selectedReportMessage && (
        <MessageReportModal
          open={openReportMessageModal}
          setOpen={setOpenReportMessageModal}
          account={selectedReportMessage.account}
          prospect={selectedReportMessage.prospect}
          reportMessage={selectedReportMessage}
        />
      )}

      <div className="filter-container">
        {/* <h2 className="title">Reports</h2> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={since}
            format="dd/MM/yyyy"
            onChange={(value) => updateSince(value)}
            slots={(params: any) => (
              <TextField
                className="input-date"
                name="scheduleDate"
                fullWidth
                {...params}
              />
            )}
          />
          <DatePicker
            value={to}
            format="dd/MM/yyyy"
            onChange={(value) => updateTo(value)}
            slots={(params: any) => (
              <TextField
                className="input-date"
                name="scheduleDate"
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>

        <Select
          value={period}
          className={choosePeriodSelectClassName(period)}
          placeholder={'Select period'}
          onChange={(e) => updatePeriod(e.target.value)}
          displayEmpty={true}
          MenuProps={{
            // style: { zIndex: 3501 },
            PaperProps: {
              sx: {
                borderRadius: '8px',
                paddingInline: '6px',
                border: '1px solid #dadde9',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                '& .MuiMenuItem-root': {
                  fontSize: '12px',
                  lineHeight: '16px',
                  height: 'fit-content',
                  padding: '8px',
                  cursor: 'pointer',
                  width: '100%',
                  borderRadius: '8px',
                  '&:hover': {
                    background: colors.primaryLight,
                    color: colors.primary,
                  },
                },
                '& .Mui-selected': {
                  color: colors.primary,
                  backgroundColor: 'transparent !important',
                },
              },
            },
          }}
        >
          <MenuItem value="">Period</MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="this-week">This Week</MenuItem>
          <MenuItem value="this-month">This Month</MenuItem>
          <MenuItem value="this-quarter">This Quarter</MenuItem>
          <MenuItem value="last-week">Last Week</MenuItem>
          <MenuItem value="last-month">Last Month</MenuItem>
          <MenuItem value="last-quarter">Last Quarter</MenuItem>
        </Select>

        <Autocomplete
          className="input-account"
          onChange={(e, value) => {
            setSelectedAccount((value as Account)?.objectId ?? '');
          }}
          options={[...(accounts?.data?.data || []), '']}
          getOptionLabel={(account) => (account as Account).name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              className="input-account-field"
              variant="outlined"
              placeholder="Company"
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
          PaperComponent={({ children }) => (
            <div className="autocomplete-paper">{children}</div>
          )}
          renderOption={(props, option) => (
            <li {...props} className="autocomplete-option">
              {(option as Account).name}
            </li>
          )}
          fullWidth
          disablePortal
        />

        {user && (
          <Autocomplete
            className="input-user"
            value={users?.data?.data.find((u) => u.id === userId) || ''}
            onChange={(e, value) => {
              setUserId((value as User)?.id ?? '');
            }}
            options={[
              ...(users?.data?.data.filter((u) =>
                fullname(u.firstName, u.lastName)
                  .toLowerCase()
                  .includes(searchUserText.toLowerCase()),
              ) || []),
              '',
            ]}
            getOptionLabel={(user) =>
              fullname((user as User).firstName, (user as User).lastName) || ''
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className="input-user-field"
                variant="outlined"
                placeholder="User"
                onChange={(e) => setSearchUserText(e.target.value)}
              />
            )}
            PaperComponent={({ children }) => (
              <div className="autocomplete-paper">{children}</div>
            )}
            renderOption={(props, option) => (
              <li {...props} className="autocomplete-option">
                {fullname(
                  (option as UserSubscription).firstName,
                  (option as UserSubscription).lastName,
                ) || ''}
              </li>
            )}
            fullWidth
            disablePortal
          />
        )}
      </div>
      <div className="charts-grid">
        <div className="chart-container">
          {user?.otherSettings?.hasEmail && loadingEmail ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <EmailsTable
              data={emailsData?.data?.data ?? []}
              total={emailsData?.data?.total ?? 0}
              limit={limit}
              offset={emailOffset}
              setOffset={setEmailOffset}
              handleSelectReportMessage={handleSelectReportMessage}
              setIsAnsweredEmail={setIsAnsweredEmail}
              isAnsweredEmail={isAnsweredEmail}
              setIsOpenedEmail={setIsOpenedEmail}
              isOpenedEmail={isOpenedEmail}
              isClickedEmail={isClickedEmail}
              setIsClickedEmail={setIsClickedEmail}
              userId={userId}
              since={since}
              to={to}
              selectedAccount={selectedAccount}
              sortModelEmail={sortModelEmail}
              setSortModelEmail={setSortModelEmail}
            />
          )}
        </div>

        <div className="chart-container">
          {user?.otherSettings?.hasLinkedin && loadingLinkedins ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <LinkedinsTable
              data={linkedinsData?.data?.data ?? []}
              total={linkedinsData?.data?.total ?? 0}
              limit={limit}
              offset={linkedinOffset}
              setOffset={setLinkedinOffset}
              handleSelectReportMessage={handleSelectReportMessage}
              setIsAnsweredLinkedin={setIsAnsweredLinkedin}
              isAnsweredLinkedin={isAnsweredLinkedin}
              userId={userId}
              since={since}
              to={to}
              selectedAccount={selectedAccount}
              setSortModelLinkedin={setSortModelLinkedin}
            />
          )}
        </div>

        <div className="chart-container">
          {loadingCall ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <CallsTable
              data={callsData?.data?.data ?? []}
              total={callsData?.data?.total ?? 0}
              limit={limit}
              offset={callOffset}
              setOffset={setCallOffset}
              setCallFeedback={setCallFeedback}
              callFeedback={callFeedback}
              userId={userId}
              since={since}
              to={to}
              selectedAccount={selectedAccount}
              setSortModelCall={setSortModelCall}
            />
          )}
        </div>
      </div>
    </ChartsGridWrapper>
  );
};

export const renderNoChartResults = (type: 'call' | 'email' | 'linkedin') => {
  let icon = null;
  switch (type) {
    case 'call':
      icon = <CallIcon />;
      break;
    case 'email':
      icon = <EmailIcon />;
      break;
    case 'linkedin':
      icon = <LinkedinIcon />;
      break;
  }
  return (
    <FlexDiv
      direction="column"
      position="relative"
      justifyContent="start"
      style={{
        overflow: 'hidden',
      }}
    >
      <div style={{ width: '100%' }}>
        <img
          src={NoInformationRows}
          alt="Empty task placeholder"
          style={{ width: '100%', filter: 'blur(0.5px)' }}
        />
      </div>
      <FlexDiv direction="column" position="absolute" gap="12px">
        {icon}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={500}
            textAlign={'center'}
            lineHeight={'18px'}
            color={colors.grey1}
          >
            No rows
          </Typography>
        </div>
      </FlexDiv>
    </FlexDiv>
  );
};

const Reports: FC = () => {
  const { user } = useAuth();

  return <>{user && <ReportsTables />}</>;
};

export default Reports;
