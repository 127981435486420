import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';
import GeneralPlaceholder from 'assets/images/General_Placeholder.svg';

export const CreateStrategyViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 72px);
  gap: 24px;
  margin-left: auto;
  height: 100%;
  padding-right: 24px;
  position: relative;

  .dashed-line {
    border-left: 1px dashed ${colors.grey33};
    position: absolute;
    left: -42px;
    height: calc(100% + 20px);
    z-index: -1;

    &__number-container {
      position: absolute;
      top: 70px;
    }

    &__triangle {
      position: absolute;
      left: -6.5px;
      top: 10px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-top: 6px solid ${colors.grey33};
    }
  }

  .instructions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
    height: 100%;
    width: 100%;
    gap: 8px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: url(${GeneralPlaceholder});
      background-repeat: repeat-y;
      background-size: 100%;
      opacity: 0.6;
      z-index: -1;
    }

    .background-blur-panel {
      margin-top: auto;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) -19.1%,
        rgba(255, 255, 255, 0.8) 58.7%
      );
      height: 30%;
      width: 100%;
    }
  }
`;

export const AddStepNumberCountWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  align-items: center;
  background: ${colors.white};
  color: ${colors.grey2};
  border: 1px solid ${colors.grey33};
  border-radius: 50%;
  transform: translateX(-50%);

  .number-count {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
`;

export const AddStepPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
  min-height: 160px;
  width: 100%;
  gap: 8px;
  background: ${colors.primaryLight};
  border: 1px dashed ${colors.darkGreen};
  cursor: pointer;
  border-radius: 16px;
  transition: background 0.2s;

  &:hover {
    background: ${colors.primaryLightHover};

    .plus-icon {
      background: ${colors.darkGreen};
    }
  }

  .plus-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: ${colors.primary};
  }
`;
