import React from 'react';
import IProps from './interface';

const IconCustomEmailProvider = ({ className, onClick }: IProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_16013_420603)">
        <g clipPath="url(#clip1_16013_420603)">
          <path
            d="M15.8314 7.58889C15.8324 7.45047 15.7609 7.32165 15.6429 7.24927H15.6408L15.6334 7.24515L9.49958 3.61426C9.47309 3.59636 9.44561 3.58 9.41724 3.56527C9.18041 3.44309 8.89908 3.44309 8.66224 3.56527C8.63388 3.58001 8.6064 3.59636 8.57991 3.61426L2.4461 7.24515L2.43869 7.24927C2.25123 7.36585 2.19375 7.61233 2.31033 7.7998C2.34468 7.85504 2.39199 7.90106 2.44816 7.93387L8.58199 11.5648C8.60856 11.5825 8.63605 11.5989 8.66432 11.6138C8.90115 11.7359 9.18248 11.7359 9.41932 11.6138C9.4476 11.5989 9.47507 11.5825 9.50166 11.5648L15.6355 7.93387C15.758 7.86243 15.8328 7.73074 15.8314 7.58889Z"
            fill="#10635F"
          />
          <g filter="url(#filter0_d_16013_420603)">
            <path
              d="M15.0088 12.4794L15.0088 3.12201C15.0185 2.70001 14.6845 2.34996 14.2625 2.33984H3.81687C3.39487 2.34996 3.06085 2.70001 3.07052 3.12201L3.07052 12.4794H15.0088Z"
              fill="white"
            />
          </g>
          <path
            d="M15.6425 7.9112L15.6347 7.91532L9.50088 11.3651C9.47412 11.3815 9.44696 11.3972 9.41855 11.4112C9.31436 11.4608 9.20122 11.4888 9.08592 11.4935L8.75083 11.2976C8.72251 11.2834 8.69502 11.2675 8.66849 11.2502L2.45234 7.70249H2.44945L2.24609 7.58887V14.5724C2.24926 15.0383 2.62943 15.4135 3.09536 15.4105H14.995C15.002 15.4105 15.0082 15.4072 15.0156 15.4072C15.114 15.4009 15.211 15.3807 15.3037 15.3471C15.3438 15.3302 15.3825 15.3101 15.4194 15.287C15.447 15.2714 15.4943 15.2372 15.4943 15.2372C15.7052 15.0812 15.83 14.8347 15.8311 14.5724V7.58887C15.8309 7.72244 15.7589 7.8456 15.6425 7.9112Z"
            fill="#1EBAB2"
          />
          <path
            opacity="0.1"
            d="M15.5034 7.56034V7.98847L9.08961 12.4044L2.44943 7.70442C2.44943 7.70215 2.44758 7.7003 2.44531 7.7003L2.61821 7.32568L2.63056 7.3298L2.67584 7.35862C2.67584 7.35862 8.91669 10.9195 8.93317 10.9278L9.17193 11.0677C9.19251 11.0595 9.21309 11.0513 9.23779 11.043C9.25015 11.0348 15.4334 7.55621 15.4334 7.55621L15.5034 7.56034Z"
            fill="#10635F"
          />
          <path
            d="M15.6425 7.9112L15.6347 7.91573L9.50087 11.3655C9.47412 11.382 9.44695 11.3976 9.41854 11.4116C9.18033 11.528 8.90175 11.528 8.66354 11.4116C8.63533 11.3976 8.60786 11.3822 8.5812 11.3655L2.4474 7.91573L2.43999 7.9112C2.32157 7.84699 2.24732 7.72357 2.24609 7.58887V14.5724C2.24904 15.0382 2.62907 15.4135 3.09491 15.4105C3.09491 15.4105 3.09493 15.4105 3.09495 15.4105H14.9822C15.4481 15.4135 15.8281 15.0382 15.8311 14.5724C15.8311 14.5724 15.8311 14.5724 15.8311 14.5724V7.58887C15.8309 7.72244 15.7589 7.8456 15.6425 7.9112Z"
            fill="#0A9B94"
          />
          <path
            opacity="0.1"
            d="M9.59145 11.3145L9.49965 11.3659C9.47305 11.3828 9.44556 11.3984 9.41731 11.4124C9.31618 11.4621 9.2062 11.4912 9.09375 11.4981L11.4275 14.2579L15.4985 15.2389C15.61 15.1546 15.6987 15.0438 15.7566 14.9165L9.59145 11.3145Z"
            fill="black"
          />
          <path
            opacity="0.05"
            d="M10.0072 11.0806L9.49965 11.3658C9.47305 11.3828 9.44556 11.3983 9.41731 11.4124C9.31618 11.462 9.2062 11.4911 9.09375 11.498L10.1871 14.5126L15.4997 15.2376C15.709 15.0804 15.8322 14.834 15.8323 14.5723V14.4822L10.0072 11.0806Z"
            fill="black"
          />
          <path
            d="M3.10647 15.4105H14.981C15.1637 15.4114 15.3419 15.3537 15.4894 15.2459L8.75041 11.2984C8.7221 11.2842 8.6946 11.2684 8.66808 11.2511L2.45192 7.70331H2.44904L2.24609 7.58887V14.5485C2.24563 15.0241 2.63083 15.4101 3.10647 15.4105V15.4105Z"
            fill="#1EBAB2"
          />
          <path
            d="M9.5114 8.67912H8.53599C7.43096 8.67912 6.53516 7.78332 6.53516 6.67829V6.17808C6.53516 5.07305 7.43096 4.17725 8.53599 4.17725H9.0362C9.56685 4.17725 10.0758 4.38805 10.451 4.76328C10.8262 5.13851 11.037 5.64743 11.037 6.17808V6.80334C11.037 7.21773 10.7011 7.55365 10.2867 7.55365H10.2617C9.84732 7.55365 9.5114 7.21773 9.5114 6.80334V6.37191"
            stroke="#0A9B94"
            strokeWidth="0.514286"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.10632 5.54232L8.18135 5.46729C8.28681 5.36219 8.42963 5.30319 8.57852 5.30322H8.95042C9.26121 5.30322 9.51316 5.55517 9.51316 5.86596V6.99143C9.51316 7.30222 9.26121 7.55416 8.95042 7.55416H8.52825C8.20204 7.55402 7.93764 7.28962 7.9375 6.96341V6.96341C7.93764 6.63721 8.20204 6.37281 8.52825 6.37267H9.51316"
            stroke="#0A9B94"
            strokeWidth="0.514286"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_16013_420603"
          x="0.927455"
          y="0.0684152"
          width="16.2232"
          height="14.4254"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.128571" />
          <feGaussianBlur stdDeviation="1.07143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_16013_420603"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_16013_420603"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_16013_420603">
          <rect
            width="17.1429"
            height="17.1429"
            fill="white"
            transform="translate(0.429688 0.428711)"
          />
        </clipPath>
        <clipPath id="clip1_16013_420603">
          <rect
            width="16.7143"
            height="16.2857"
            fill="white"
            transform="translate(0.644531 0.428711)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCustomEmailProvider;
