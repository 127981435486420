import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const EditPencilIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
  width = '20px',
  height = '20px',
}: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        id="Path"
        d="M14.6167 8.43281L11.5667 5.38281"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20917 17.5009H2.5V14.7917C2.5 14.5709 2.5875 14.3584 2.74417 14.2026L13.8558 3.09008C14.1817 2.76424 14.7092 2.76424 15.0342 3.09008L16.91 4.96591C17.2358 5.29174 17.2358 5.81924 16.91 6.14424L5.79833 17.2567C5.6425 17.4134 5.43 17.5009 5.20917 17.5009V17.5009Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditPencilIcon;
