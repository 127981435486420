import { CircularProgress } from '@mui/material';
import { useAuth } from 'context/auth';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { SettingsTabs } from 'pages/settings';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';

const SubscriptionPageInterceptor = () => {
  const { user, isSubscriptionManager } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    //Only subscription manager can access the subscription page
    if (!isSubscriptionManager) {
      navigate(PATH.HOME);
      return;
    }
    navigate(PATH.SETTINGS, { state: { tab: SettingsTabs.Subscriptions } });
  }, [user, isSubscriptionManager]);
  return (
    <>
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    </>
  );
};

export default SubscriptionPageInterceptor;
