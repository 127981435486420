import { ENDPOINTS } from 'config/endpoints';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { useMutation } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import { Response } from 'crono-fe-common/types/response';
import { CustomEmailProviderSettingsImapDTO } from 'crono-fe-common/types/DTO/customEmailProviderSettingsDTO';

const useTestImapCustomEmailProviderIntegration = () => {
  const testImapCustomEmailProviderIntegration = (
    customEmailProviderData: CustomEmailProviderSettingsImapDTO,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.customEmailProvider.test.imap}`,
      config: {
        method: 'post',
        data: { data: customEmailProviderData },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    CustomEmailProviderSettingsImapDTO
  >(testImapCustomEmailProviderIntegration, {});

  return { data, mutate, ...rest };
};

export default useTestImapCustomEmailProviderIntegration;
