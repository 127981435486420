import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { useUpgradeModal } from 'context/upgradeModal';
import CronoError from 'crono-fe-common/types/error';
import { isCronoError } from 'utils/fe-utils';

export function useLimitedMutation<
  TData,
  TError,
  TVariables,
  TContext = unknown,
>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { openModal } = useUpgradeModal();

  const handlePossibleNotAllowedError = (error: CronoError) => {
    if (
      error.response?.data?.errors &&
      error.response?.data?.errors.length >= 1 &&
      error.response.data.errors[0]?.code === 'NotAllowedWithCurrentPlan'
    ) {
      openModal();
    }
  };

  const combinedOptions = {
    ...options,
    onError: (
      error: TError,
      variables: TVariables,
      context: TContext | undefined,
    ) => {
      if (isCronoError(error)) {
        handlePossibleNotAllowedError(error);
      }
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  };

  return useMutation(mutationFn, combinedOptions);
}
