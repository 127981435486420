import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ProspectCardWrapper = styled.div`
  // margin-bottom: 12px;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid ${colors.grey4};

  display: flex;
  flex-direction: column;
  .status {
    font-size: 10px;
    min-width: 94px;
    max-width: 110px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    cursor: default !important;
  }
  .status-notcontacted {
    background: ${colors.nurture};
  }
  .status-firstcontact {
    background: ${colors.blue};
  }
  .status-warm {
    background: ${colors.callScheduled};
  }
  .status-cold {
    background: ${colors.inactive};
  }
  .status-notinterested {
    background: ${colors.purple};
  }

  .lead-badge-container {
    display: flex;
    justify-content: center;
    width: 50px;
  }
  .lead-badge {
    font-size: 10px;
    width: 40px;
    min-width: 40px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    cursor: default !important;
    color: ${colors.black};
    border: 1px solid ${colors.grey4Hover};
    border-radius: 50px;
  }
  .resume-sequence-handling-container,
  .add-sequence-handling-container,
  .pause-sequence-handling-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 12px;
    border-radius: 24px;
    cursor: pointer;
    padding: 4px 8px 4px 4px;
    //to align with statuses
    margin-top: -4px;
  }
  .add-sequence-handling-container:hover {
    background-color: ${colors.primaryLight};
  }
  .add-sequence-handling-container:hover > p {
    color: ${colors.darkGreen};
  }
  .resume-sequence-handling-container:hover {
    background-color: ${colors.callScheduledLight};
  }
  .resume-sequence-handling-container:hover > div > p {
    color: ${colors.callScheduledHover} !important;
  }
  .pause-sequence-handling-container:hover {
    background-color: ${colors.orangeLight};
  }
  .pause-sequence-handling-container:hover > div > p {
    color: ${colors.orangeDark} !important;
  }

  .label {
    color: ${colors.grey11};
  }
  .sequence-button-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  .add-to-strategy:hover > p {
    color: ${colors.primaryDark};
  }
  .sequence-label {
    max-width: 170px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .trash-icon {
    width: 34px;
    height: 34px;
    padding: 4px;
  }
  .delete-icon {
    background: ${colors.inactiveLight};
    &:hover {
      background: ${colors.inactiveLightHover};
    }
  }

  .expand-arrow-icon {
    cursor: pointer;
    min-width: 34px;
    min-height: 34px;
    padding: 3px;
    border: 1px solid ${colors.grey4};
    border-radius: 999px;
    &:hover {
      background: ${colors.grey6};
    }
  }

  .expand-arrow-icon:hover > svg > path {
    stroke: ${colors.grey1};
  }

  .inner-expand {
    // margin-top: 12px;
    padding: 16px 12px 12px 12px;
  }
  .inner-expand-left {
    width: 60%;
    border-right: 1px solid ${colors.grey4};
  }
  .inner-expand-right {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    padding-top: 18px;
  }
  .search-linkedin-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: ${colors.black}55;
    z-index: 1000;
  }
  .prospect-avatar-row {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    margin-right: 12px;
    size: 16px !important;
    font-size: 16px !important;
  }
  .information-editable-container {
    display: flex;
    align-items: center;
    margin: 3px 0px;
  }
  .information-label {
    width: 100%;
    color: ${colors.grey11};
    margin-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .external-properties-tag {
    max-width: 50%;
  }
  .field-input {
    width: 100%;
    border-radius: 8px;
    padding: 4px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-input {
      padding: 0px;
    }
    & .MuiSelect-select {
      padding: 0px 26px 0px 4px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .field-input:hover {
    background: ${colors.grey6};
  }

  .prospect-info-container {
    display: flex;
    width: calc(100% - 50px);
    flex-direction: column;
    gap: 14px;
  }

  .top-container-name-title-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .left {
    width: 60%;
  }

  .right {
    width: 40%;
  }

  .left-side {
    display: flex;
    overflow: hidden;
    justify-content: start;
  }

  .left-name-lead {
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: fit-content;
  }

  .prospect-title {
    width: 150px;
    max-width: 150px;
  }

  .right-status-not-owned-icon {
    display: flex;
    margin-left: 12px;
  }

  .right-side-sequence-handling {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: fit-content;
  }

  .bottom-container-tags-action-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .left-tags-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
  }

  .right-icons-container {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  .disabled-add-task-container,
  .add-task-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 34px;
    border-radius: 32px;
    border: 1px solid ${colors.grey4};
    width: 120px;
    cursor: pointer;
    &:hover {
      background: ${colors.grey6};
    }
  }
  .add-task-container:hover > p {
    color: ${colors.grey1};
  }

  .disabled-add-task-container {
    opacity: 0.6;
    cursor: default !important;
  }

  .edited-field-badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    font-size: 0.8rem;
    border-radius: 16px;
    color: ${colors.primaryDark};
    background-color: ${colors.backgroundSaveGreen};
    padding: 4px 6px;
  }
  .invalid-email-badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
    border-radius: 16px;
    color: ${colors.inactive};
    background-color: ${colors.inactiveLight};
    padding: 2px 6px;
  }

  .phone-mobile-field {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }
  .field-disabled {
    opacity: 0.7;
  }

  .pencil-edit-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 200ms;

    &:hover {
      background-color: ${colors.greyHoverNote};
    }
  }

  .pencil-edit-icon {
    flex-shrink: 0;
    stroke: ${colors.grey2};
    transition: stroke 200ms;

    &--hovered {
      stroke: ${colors.black};
    }
  }
`;

export const AddTasksOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;

  .option-button {
    text-transform: unset !important;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 8px;
    padding: 8px;
    color: ${colors.black};
  }
  .option-button:hover {
    color: ${colors.primary};
    background-color: ${colors.primaryLight};
  }
  .option-button:hover > span > svg > path,
  .option-button:hover > span > svg > circle,
  .option-button:hover > span > svg > rect {
    stroke: ${colors.primary};
  }

  // LinkedinMessageIcon
  .option-button:hover > span > svg > path#Vector {
    stroke: transparent;
    fill: ${colors.primary};
  }

  // LinkedinInvitationIcon

  .option-button > span > svg > path#linkedin_invitation_letter {
    stroke: transparent;
    fill: ${colors.black};
  }
  .option-button > span > svg > path#vertical_stroke,
  .option-button > span > svg > path#horizontal_stroke {
    stroke: ${colors.black};
  }
  .option-button > span > svg > path#cross_outside_border {
    stroke: transparent;
  }

  .option-button:hover > span > svg > path#linkedin_invitation_letter {
    stroke: transparent;
    fill: ${colors.primary};
  }
  .option-button:hover > span > svg > path#vertical_stroke,
  .option-button:hover > span > svg > path#horizontal_stroke {
    stroke: ${colors.primary};
  }
  .option-button:hover > span > svg > path#cross_outside_border {
    stroke: transparent;
  }
`;
