import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export type NotificationPatchInput = {
  notificationId: number;
};

export default function usePatchNotification() {
  const queryClient = useQueryClient();

  const patchNotification = (input: NotificationPatchInput) => {
    const request: Request = {
      url: `${ENDPOINTS.notifications.main}`,
      config: {
        method: 'patch',
        data: input,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    NotificationPatchInput
  >(patchNotification, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.notifications.main]);
      queryClient.invalidateQueries([ENDPOINTS.notifications.feed]);
    },
  });

  return { data, mutate, ...rest };
}
