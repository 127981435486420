import {
  Avatar,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import { MainPrimarySmallButton } from 'crono-fe-common/components/CronoButton';
import { colors } from 'crono-fe-common/theme';

import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import SimilarContactsModal from './similarContactsModal';
import { ProspectLinkedin } from 'crono-fe-common/types/prospectLinkedin';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import { useConditionalSnackBar } from 'context/snackbar';
import {
  getError,
  mapProspectToProspectLinkedinAndCorrect,
} from 'crono-fe-common/utils';
import { useGetAsyncQueueState } from 'hooks/services/crono-extension/useGetAsyncQueueState';
import IconCheckedCrono from 'crono-fe-common/icons/Icon-Checked-Crono';
import IconRedirect from 'crono-fe-common/icons/Icon-Redirect';
import { ContactForSearchTable, InformationScrapedResult } from '../model';
import FindEmailContactSearch from './findEmailContactSearch';
import VerificationStatusType from 'crono-fe-common/types/enums/verificationStatusType';
import FindPhoneContactSearch from './findPhoneContactSearch';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';
import { SimilarContactsButton } from './style';
import {
  useBackgroundMarkJobsAsRead,
  useLinkedinGetPublicIdFromLeadId,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import { Constants } from 'crono-fe-common/constants/constants';
import { useAuth } from 'context/auth';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';
import { Prospect } from 'crono-fe-common/types/prospect';
import styled from '@emotion/styled';
import {
  defaultColumnsContactsLinkedin,
  defaultColumnsContactsSalesNav,
} from 'pages/searchComponent/editColumnsModal';
import CompanyInformationColumnsForContact from './companyInformationColumnsForContact';
import { useGetAsyncEnrichQueueState } from 'hooks/services/crono-extension/useGetAsyncEnrichQueueState';

interface IProps {
  contact: ContactForSearchTable;
  selected: string[];
  handleSelectContact: (profileId: string) => void;
  handleAddClick: (contact: ContactForSearchTable) => void;
  setIsContactSelectable: (value: boolean) => void;
  setContact: (contact: ContactForSearchTable) => void;
  //Remove from the UI for the moment, but may be needed in the future
  // alreadySearchingEmail: boolean;
  // setAlreadySearchingEmail: React.Dispatch<React.SetStateAction<boolean>>;
  // alreadySearchingPhone: boolean;
  // setAlreadySearchingPhone: React.Dispatch<React.SetStateAction<boolean>>;
  alreadyEnriching: boolean;
  setAlreadyEnriching: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FindNewTableCell = styled(TableCell)`
  padding: 12px 12px 0px 12px;
  height: 50px;
  max-height: 50px;
  vertical-align: text-top;
`;

const ContactInsertCard = ({
  contact,
  selected,
  handleSelectContact,
  handleAddClick,
  setIsContactSelectable,
  setContact,
  // alreadySearchingEmail,
  // setAlreadySearchingEmail,
  // alreadySearchingPhone,
  // setAlreadySearchingPhone,
  alreadyEnriching,
  setAlreadyEnriching,
}: IProps) => {
  const { user, hasSalesNavWithCookie } = useAuth();

  //The object to use to identify the contact to the linkedin APIs, using the leadId in case of salesNav, the publicId otherwise
  // const identifierObject = useMemo(() => {
  //   if (hasSalesNavWithCookie) {
  //     return {
  //       leadId: contact.leadId ?? undefined,
  //     };
  //   } else {
  //     return {
  //       publicId:
  //         LinkedinUrlUtils.getIdFromLinkedinProfileUrl(contact.url) ??
  //         undefined,
  //     };
  //   }
  // }, [hasSalesNavWithCookie]);

  const columnsToShow = useMemo(() => {
    if (!user) return '';
    const columns = (
      user.userPreferences.findNewProspectColumns ??
      (hasSalesNavWithCookie
        ? defaultColumnsContactsSalesNav
        : defaultColumnsContactsLinkedin)
    ).split(',');
    return columns;
  }, [user?.userPreferences.findNewProspectColumns, hasSalesNavWithCookie]);

  const {
    mutateAsync: editProspect,
    isSuccess: prospectEdited,
    error: errorEditingProspect,
  } = useEditProspect();

  const [prospectInCrono, setProspectInCrono] = useState<boolean>(false);
  const [linkedinMember, setLinkedinMember] = useState<boolean>(false);

  const [similarContacts, setSimilarContacts] = useState<
    ProspectLinkedin[] | null
  >(null);

  useEffect(() => {
    if (contact.correct) {
      setProspectInCrono(true);
      setIsContactSelectable(false);
      setSimilarContacts(null);
      return;
    }
    if (similarContacts !== null) return;
    //Load only the first time looking at what similarContacts is equal to, if null we still have to lead the first time
    if (contact.similarContacts && contact.similarContacts.length > 0) {
      setIsContactSelectable(false);
      setSimilarContacts(contact.similarContacts);
    } else if (
      contact.url?.includes(
        'https://www.linkedin.com/search/results/people/headless',
      )
    ) {
      setProspectInCrono(false);
      setIsContactSelectable(false);
      setLinkedinMember(true);
    } else {
      setProspectInCrono(false);
      setIsContactSelectable(true);
      setSimilarContacts(null);
    }
  }, [contact, contact.correct]);

  const [showLinkSimilarContact, setShowLinkSimilarContact] = useState(false);

  const {
    call: getPublicIdFromLeadId,
    isLoading: isLoadingGetPublicIdFromLeadId,
  } = useLinkedinGetPublicIdFromLeadId();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleShowError = (message: string) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  const handleClickLink = async (prospect: ProspectLinkedin) => {
    const leadId = contact.leadId;
    let newUrl = contact.url;
    //If we are in a salesNav situation, we don't have the real url, so we have to parse the leadId to publicId and then to url
    if (hasSalesNavWithCookie && leadId) {
      const publicId = await getPublicIdFromLeadId({ leadId: leadId });
      if (!publicId?.publicId) {
        handleShowError(
          'Error while retrieving the information for the contact, please try again later',
        );
        return;
      }
      newUrl = `${Constants.linkedinBaseUrl}/in/${publicId.publicId}`;
    }
    const res = await editProspect({
      prospectId: prospect.objectId,
      linkedin: newUrl,
      ...(contact.email && {
        email: contact.email,
      }),
      ...(contact.emailStatus && {
        emailStatus: contact.emailStatus,
      }),
      ...(contact.phone && { phone: contact.phone }),
      ...(leadId && { linkedinLeadId: leadId }),
      sync: true,
    });
    if (res.data.isSuccess) {
      setContact({
        ...contact,
        correct: true,
        similarContacts: [{ ...prospect, correct: true }],
      });
    }
  };

  useEffect(() => {
    if (prospectEdited) {
      setProspectInCrono(true);
      setIsContactSelectable(false);
      setShowLinkSimilarContact(false);
    }
  }, [prospectEdited]);

  useConditionalSnackBar([
    {
      condition: !!errorEditingProspect,
      message: getError(errorEditingProspect) ?? 'Error while editing contact',
      severity: 'error',
    },
    {
      condition: prospectEdited,
      message: 'Contact linked successfully',
      severity: 'success',
    },
    {
      condition: !!errorMessage,
      message: errorMessage ?? 'Error while processing the operation',
      severity: 'error',
    },
  ]);

  const [isProspectImporting, setIsProspectImporting] =
    useState<boolean>(false);

  const { data: queue } = useGetAsyncQueueState();

  //This state is used to know if the user has already tried to import this company in order to avoid multiple execution of the effect
  const [isImportedChecked, setIsImportedChecked] = useState<boolean>(false);

  useEffect(() => {
    const isImporting = Boolean(
      queue?.contactsToImport.find(
        (c) =>
          c.prospect.linkedinLeadId === contact.id ||
          c.prospect.linkedinPublicId === contact.id ||
          c.prospect.linkedinProfileId === contact.url,
      ),
    );

    setIsProspectImporting(isImporting);

    //If we try to import we reset also the state to check if we already tried to import to get the new result
    if (isImporting) {
      setIsImportedChecked(false);
    }

    if (isImporting) {
      setIsContactSelectable(false);
    }
  }, [queue?.contactsToImport]);

  const { call: markJobsAsRead } = useBackgroundMarkJobsAsRead();

  useEffect(() => {
    if (queue && queue.importedContacts.length > 0 && !isImportedChecked) {
      const importedContact = queue.importedContacts.find(
        (c) =>
          c.prospect.linkedinLeadId === contact.id ||
          c.prospect.linkedinPublicId === contact.id ||
          c.prospect.linkedinProfileId === contact.id,
      );
      if (!importedContact) return;
      setIsImportedChecked(true);
      markJobsAsRead({
        jobIds: [importedContact.id],
      });
      if (importedContact.error) {
        handleShowError(
          importedContact.error === 'missingCookie'
            ? 'Error while importing, check to be logged into linkedin with Crono'
            : importedContact.error,
        );
        setIsProspectImporting(false);
        setIsContactSelectable(true);
        return;
      }
      setIsProspectImporting(false);
      const newContact = mapProspectToProspectLinkedinAndCorrect(
        importedContact.prospectCreated ?? null,
      );
      if (newContact) {
        const contactToSet = {
          ...contact,
          correct: true,
          ...(importedContact.prospectCreated
            ? { similarContacts: [newContact] }
            : {}),
        };
        if (importedContact.findEmail) {
          if (importedContact.foundEmail) {
            contactToSet.email = importedContact.prospect.email ?? null;
            contactToSet.emailStatus =
              importedContact.prospect.emailStatus ?? null;
            contactToSet.emailScraped = 'found';
          } else {
            contactToSet.emailScraped = 'not-found';
          }
        }
        if (importedContact.findPhone) {
          if (importedContact.foundPhone) {
            contactToSet.phone = importedContact.prospect.phone ?? null;
            contactToSet.phoneScraped = 'found';
          } else {
            contactToSet.phoneScraped = 'not-found';
          }
        }
        setContact(contactToSet);
      }
      setIsContactSelectable(false);
      setProspectInCrono(true);
    }
  }, [queue?.importedContacts]);

  // const handleSetEmailFromFind = (
  //   email: string | null,
  //   emailStatus: VerificationStatusType | null,
  //   emailFound: boolean,
  // ) => {
  //   const newContact: ContactForSearchTable = {
  //     ...contact,
  //     email,
  //     emailScraped: emailFound ? 'found' : 'not-found',
  //     emailStatus: emailStatus,
  //   };
  //   setContact(newContact);
  // };

  // const handleSetPhoneFromFind = (
  //   phone: string | null,
  //   phoneScraped: InformationScrapedResult | null,
  // ) => {
  //   const newContact: ContactForSearchTable = {
  //     ...contact,
  //     phone,
  //     phoneScraped: phoneScraped ?? 'no',
  //   };
  //   setContact(newContact);
  // };

  const handleRemoveSingleSimilarContact = (index: number) => {
    if (!similarContacts) return;
    const newSimilar = similarContacts.filter((_, i) => i !== index);
    //If there are no more similar we can now select this contact and we close the modal
    if (newSimilar.length === 0) {
      setShowLinkSimilarContact(false);
      setIsContactSelectable(true);
    }
    setSimilarContacts(newSimilar);
  };
  const disableRow = isProspectImporting || prospectInCrono;
  const [isHover, setIsHover] = useState(false);
  const showBackgroundHover = isHover && !disableRow;

  const { openWindow } = useWindow();
  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();

  const handleOpenContactInCrono = () => {
    const findCorrectProspect = contact.similarContacts?.find((c) => c.correct);
    if (!findCorrectProspect) return;
    setSelectedProspectId(findCorrectProspect.objectId);
    setSelectedAccountId(findCorrectProspect.accountId);
    openWindow({
      windowType: 'account',
      tab: 'prospect',
      selectedProspectId: findCorrectProspect.objectId,
    });
  };

  return (
    <>
      {showLinkSimilarContact && (
        <SimilarContactsModal
          close={() => setShowLinkSimilarContact(false)}
          similarProspects={similarContacts ?? []}
          removeSimilarContact={handleRemoveSingleSimilarContact}
          handleClickLink={handleClickLink}
          isLoading={isLoadingGetPublicIdFromLeadId}
        />
      )}
      <TableRow
        style={{
          opacity: disableRow ? 0.55 : 1,
          background: showBackgroundHover ? colors.grey6 : colors.white,
        }}
        onMouseEnter={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          setIsHover(true);
        }}
        onMouseLeave={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          setIsHover(false);
        }}
      >
        {/*Avatar and name */}
        <FindNewTableCell
          style={{
            position: 'sticky',
            left: 0,
            minWidth: 260,
            width: 260,
            overflow: 'hidden',
            padding: '8px 12px',
            background: showBackgroundHover ? colors.grey6 : colors.white,
            borderRight: `1.5px solid ${colors.grey33}`,
            //To hide the spinning loader when scrolling
            zIndex: 1,
          }}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 16,
              minWidth: 260,
              width: 260,
              overflow: 'hidden',
            }}
          >
            <Tooltip
              arrow
              enterDelay={400}
              enterNextDelay={400}
              title={
                (similarContacts?.length ?? 0) > 0
                  ? 'Similar contact found, click on "Show Similar" to check the existing contact'
                  : null
              }
            >
              <div style={{ height: 'fit-content', width: 'fit-content' }}>
                <Checkbox
                  checked={
                    prospectInCrono ||
                    (contact.id ? selected.includes(contact.id) : false)
                  }
                  style={{ borderRadius: 24, padding: 0 }}
                  checkedIcon={
                    prospectInCrono ? <IconCheckedCrono /> : <CheckedIcon />
                  }
                  icon={
                    <IconUnchecked
                      color={
                        linkedinMember ||
                        isProspectImporting ||
                        (similarContacts?.length ?? 0) > 0
                          ? colors.grey6
                          : colors.grey2
                      }
                    />
                  }
                  onClick={() => {
                    if (contact.id && !prospectInCrono && !linkedinMember)
                      handleSelectContact(contact.id);
                  }}
                  disabled={
                    prospectInCrono ||
                    linkedinMember ||
                    isProspectImporting ||
                    (similarContacts?.length ?? 0) > 0
                  }
                />
              </div>
            </Tooltip>
            <Avatar
              src={contact.pictureUrl ?? ''}
              className="contact-logo"
              sx={{
                width: '24px !important',
                height: '24px !important',
              }}
              onClick={() => {
                if (contact.id && !prospectInCrono && !linkedinMember)
                  handleSelectContact(contact.id);
              }}
            />
            <div style={{ width: '100%', overflow: 'hidden' }}>
              <Tooltip
                title={contact.fullName}
                arrow
                enterDelay={800}
                enterNextDelay={800}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  lineHeight={'18px'}
                  className="contact-name"
                  noWrap
                  onClick={() => {
                    if (contact.id && !prospectInCrono && !linkedinMember)
                      handleSelectContact(contact.id);
                  }}
                >
                  {contact.fullName}
                </Typography>
              </Tooltip>
              {!hasSalesNavWithCookie && (
                <Tooltip
                  title={contact.linkedinDescription}
                  arrow
                  enterDelay={800}
                  enterNextDelay={800}
                >
                  <Typography
                    noWrap
                    fontSize={12}
                    fontWeight={500}
                    lineHeight={'16px'}
                    color={colors.grey11}
                  >
                    {contact.linkedinDescription}
                  </Typography>
                </Tooltip>
              )}
            </div>
          </span>
        </FindNewTableCell>

        {/*Location */}
        {!hasSalesNavWithCookie && columnsToShow.includes('location') && (
          <FindNewTableCell
            style={{
              paddingLeft: 12,
              width: 250,
              minWidth: 250,
              borderRight: `1px solid ${colors.grey444}`,
            }}
          >
            <span
              style={{
                width: 250,
                minWidth: 250,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <Typography
                fontSize={14}
                lineHeight={'24px'}
                color={colors.grey11}
                noWrap
              >
                {contact.location}
              </Typography>
            </span>
          </FindNewTableCell>
        )}

        <CompanyInformationColumnsForContact
          contact={contact}
          columnsToShow={columnsToShow}
          alreadyEnriching={alreadyEnriching}
          setAlreadyEnriching={setAlreadyEnriching}
          setContact={setContact}
          handleShowError={handleShowError}
          setIsContactSelectable={setIsContactSelectable}
        />
        {/*//Remove from the UI for the moment, but may be needed in the future */}
        {/*Email */}
        {/* <FindEmailContactSearch
          contact={contact}
          setEmail={handleSetEmailFromFind}
          identifierObject={identifierObject}
          disabled={disableRow}
          alreadySearching={alreadySearchingEmail}
          setAlreadySearching={setAlreadySearchingEmail}
        /> */}

        {/*Phone */}
        {/* <FindPhoneContactSearch
          contact={contact}
          setPhone={handleSetPhoneFromFind}
          identifierObject={identifierObject}
          disabled={disableRow}
          alreadySearching={alreadySearchingPhone}
          setAlreadySearching={setAlreadySearchingPhone}
        /> */}

        {/*Link */}
        {columnsToShow.includes('linkedinLink') && (
          <FindNewTableCell
            style={{
              paddingLeft: 12,
              minWidth: 250,
              width: 250,
            }}
          >
            <span
              style={{
                minWidth: 250,
                width: 250,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                gap: 4,
              }}
            >
              <Typography
                fontSize={14}
                lineHeight={'24px'}
                color={colors.grey11}
                noWrap
              >
                {contact.urlForRedirect}
              </Typography>

              <IconRedirect
                onClick={() => {
                  window.open(contact.urlForRedirect, '_blank');
                }}
                color={colors.grey2}
                className="redirect-icon-contact-row"
              />
            </span>
          </FindNewTableCell>
        )}
        {/*Add button */}
        <FindNewTableCell
          style={{
            width: 106,
            minWidth: 106,
            position: 'sticky',
            right: 0,
            background: showBackgroundHover ? colors.grey6 : colors.white,
            padding: '8px 12px',
            borderLeft: `1.5px solid ${colors.grey33}`,
          }}
        >
          {prospectInCrono ? (
            <span
              className="message"
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 14,
                lineHeight: '18px',
                fontWeight: 500,
                color: colors.grey11,
                alignItems: 'center',
                gap: 4,
                cursor: 'pointer',
              }}
              onClick={handleOpenContactInCrono}
            >
              In Crono{' '}
              <IconRedirect
                color={colors.grey11}
                style={{ width: 20, height: 20 }}
              />
            </span>
          ) : linkedinMember && !prospectInCrono ? (
            <div></div>
          ) : similarContacts && similarContacts?.length > 0 ? (
            //Button to open the similar option to allow the user to remove those
            <SimilarContactsButton
              startIcon={<IconMContact color={colors.primaryDark} />}
              onClick={() => setShowLinkSimilarContact(true)}
              style={{ width: 88 }}
              fullWidth
            >
              Show Similar
            </SimilarContactsButton>
          ) : isProspectImporting ? (
            <div
              className="importing-loader-container"
              style={{ width: 78, height: 32 }}
            >
              <span className="loader importing-loader"></span>
            </div>
          ) : (
            <MainPrimarySmallButton
              startIcon={<PlusIcon color={colors.white} />}
              onClick={() => handleAddClick(contact)}
              style={{ minWidth: 78, height: 32, margin: 0 }}
              fullWidth
            >
              Add
            </MainPrimarySmallButton>
          )}
        </FindNewTableCell>
      </TableRow>
    </>
  );
};

export default React.memo(ContactInsertCard);
