import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CompletedOnboardingFeatures,
  OnboardingFeaturesContextType,
} from './types';
import { useAuth } from 'context/auth';
import useGetUserOnboardingFeatures from 'hooks/services/user/useGetUserOnboardingFeatures';
import useGetUserOnboardingIntegrations from 'hooks/services/user/useGetUserOnboardingIntegrations';
import { FeaturesOnboarding } from 'crono-fe-common/types/DTO/featuresOnboarding';

export const OnboardingFeaturesContext = createContext<
  OnboardingFeaturesContextType | undefined
>(undefined);
OnboardingFeaturesContext.displayName = 'UserContext';

const OnboardingFeaturesProvider: FC<{ children: any }> = ({ children }) => {
  const { user, enabledContextCalls } = useAuth();

  //To prevent the call from being fired multiple times. When the onboardingState === null after checking with the user this is set to false
  const [onboardingCallEnabled, setOnboardingCallEnabled] =
    useState<boolean>(true);

  useEffect(() => {
    //After logout the user is set to null, after this I can call the onboarding API or the Migration
    if (user === null) {
      setOnboardingCallEnabled(true);
    }
  }, [user]);

  const { data: userOnboardingFeatures } = useGetUserOnboardingFeatures(
    enabledContextCalls && onboardingCallEnabled,
  );
  const { data: userOnboardingIntegrations } = useGetUserOnboardingIntegrations(
    enabledContextCalls && onboardingCallEnabled,
  );
  //Calculate the current state of the onBoarding:
  //- undefined: calculating;
  //- null: onBoarding done or user missing;
  //- else: onboarding to finish
  const onboardingFeatures: CompletedOnboardingFeatures | null | undefined =
    useMemo(() => {
      //I make sure that both the API have been called
      if (
        user === undefined ||
        userOnboardingFeatures === undefined ||
        userOnboardingIntegrations === undefined
      )
        return undefined;
      if (!user) return null;
      let state: any =
        userOnboardingFeatures?.data?.data ?? user.featuresOnboarding ?? null;
      if (
        state &&
        state.createContactOrCompany &&
        state.createStrategy &&
        state.createTemplate &&
        state.executeTask &&
        state.integrations
      ) {
        //Everything is completed, no need to show the onboarding button
        state = null;
      } else if (state) {
        //For the integrations, false if not done, true if done, null if not to do (not activated on the subscription)
        //Email
        if (user.otherSettings?.hasEmail === true) {
          state.emailIntegrated =
            userOnboardingIntegrations?.data?.data?.emailConnected;
        } else {
          state.emailIntegrated = null;
        }
        //Linkedin
        if (user.otherSettings?.hasLinkedin === true) {
          state.linkedinIntegrated =
            userOnboardingIntegrations?.data?.data?.linkedinConnected;
        } else {
          state.linkedinIntegrated = null;
        }

        //Calculate percentages
        let total = 0;
        if (state.integrations) {
          total += 20;
        } else {
          //Ensured by the BE that if there are neither email nor linkedin, integrations is true, so we won't join this if and so no need to check for it
          if (state.emailIntegrated === null) {
            if (state.linkedinIntegrated) total += 20;
          } else if (state.linkedinIntegrated === null) {
            if (state.emailIntegrated) total += 20;
          } else {
            //Both present
            if (state.emailIntegrated) total += 10;
            if (state.linkedinIntegrated) total += 10;
          }
        }
        if (state.createContactOrCompany) total += 20;
        if (state.createStrategy) total += 20;
        if (state.createTemplate) total += 20;
        if (state.executeTask) total += 20;
        state.percentage = total;
      }
      //Features onboarding done
      if (state === null || state.percentage === 100) {
        setOnboardingCallEnabled(false);
      }
      return state;
    }, [user, userOnboardingFeatures, userOnboardingIntegrations]);

  const value = {
    onboardingFeatures,
  };

  return (
    <OnboardingFeaturesContext.Provider value={value}>
      {children}
    </OnboardingFeaturesContext.Provider>
  );
};

export default OnboardingFeaturesProvider;

export function OnboardingFeatures() {
  const context = useContext(OnboardingFeaturesContext);
  if (context === undefined) {
    throw new Error(
      `OnboardingFeatures must be used within a OnboardingFeaturesProvider`,
    );
  }

  return context;
}
