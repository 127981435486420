import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SequenceStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  width: 100%;
  margin-block: 24px;

  .step-row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .step-number-container,
  .add-step-container {
    min-width: 60px;
    display: flex;
    justify-content: center;
    margin-right: 12px;
  }
  .step-number-container {
    height: 40px;
    gap: 4px;
    align-items: center;
    background: ${colors.grey6};
    color: ${colors.grey1};
    border: 1px solid ${colors.grey33};
    border-radius: 40px;

    .step-number {
      font-size: 14px;
      font-weight: 600;
      min-width: 14px;
      text-align: end;
    }
  }
  .step-delete-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ${colors.white};
    border: 1px solid ${colors.grey444};
    cursor: pointer;

    path {
      stroke: ${colors.inactiveHover};
    }

    &:hover {
      background: ${colors.inactive};

      path {
        stroke: ${colors.white};
      }
    }
  }
  .step-plus {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 16px;
    padding: 1px 3px 1px 6px;
    gap: 2px;
    justify-content: center;
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
    background: ${colors.primaryLight};
    &:hover {
      background: ${colors.primary};
      color: ${colors.white};
    }
  }
  .step-plus:hover > svg > path {
    stroke: ${colors.white};
  }
  .step-content,
  .step-content-conditional {
    width: calc(100% - 72px);
  }
  .step-content-no-border-bottom {
    border-bottom: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .step-content-no-border-top {
    border-top: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .step-content-conditional {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    & > * {
      width: calc(50% - 8px) !important;
    }

    .step-content-single-step {
      border-radius: 10px;
      border: 2px solid ${colors.primaryLightHover};

      .step-content-top,
      .step-content-bottom {
        border: 1px solid ${colors.darkGreen};
      }

      .step-content-bottom {
        border-top: none;
      }
    }

    .step-row-conditional {
      .step-content-single-step {
        border: 2px solid ${colors.backgroundCloseRed};

        .step-content-top,
        .step-content-bottom {
          border: 1px solid ${colors.inactive};
        }

        .step-content-bottom {
          border-top: none;
        }
      }
    }
  }
  .step-content-single-step {
    border-radius: 8px;
    width: 100%;
    height: fit-content;
  }
  .step-content-single-step:hover {
    box-shadow: 0px 0px 8px 0px ${colors.grey4Hover};
    border-collapse: collapse;
  }
  .conditional-step-wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .dashed-corner {
    position: absolute;
    top: -17px;
    left: 13px;
  }
  .conditional-step {
    width: calc(100% - 40px);
  }
  .steps-line {
    border-left: 1px dashed ${colors.grey33};
    height: calc(100% + 20px);
  }
  .conditional-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 16px;
    padding: 4px;
    border: 1px solid ${colors.grey33};
    background-color: ${colors.white};

    position: absolute;
    left: 0px;
    top: 17px;
  }
  .step-content-top {
    display: flex;
    border: 1px solid ${colors.grey4};
    background: ${colors.grey6};
    border-radius: 8px;
    align-items: center;
    height: 60px;
    width: 100%;
  }
  .step-content-bottom {
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.grey4};
    align-items: center;
    min-height: 40px;
    width: 100%;
    border-radius: 0 0 8px 8px;
    padding: 8px;
  }
  .ab-testing {
    cursor: auto !important;
  }
  .ab-testing:hover {
    color: ${colors.grey11} !important;
    cursor: auto !important;
  }
  .ab-testing:hover > span > svg > path {
    stroke: ${colors.grey11} !important;
    cursor: auto !important;
  }
  .ab-testing-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 6px;
    color: ${colors.grey11};
    cursor: pointer;
  }

  .ab-testing-wrapper:hover {
    color: ${colors.mainDark};
  }

  .ab-testing-wrapper > svg > path {
    stroke: ${colors.grey11};
  }

  .ab-testing-wrapper:hover > svg > path {
    stroke: ${colors.mainDark};
  }

  .divider {
    border: 1px solid ${colors.grey4};
    height: 26px;
  }

  .horizontal-divider {
    border-top: 1px solid ${colors.grey4};
    width: 100%;
  }

  .ab-testing-step-component-button,
  .step-component-button {
    color: ${colors.grey11};
  }
  .step-component-button {
    cursor: pointer;
  }

  .step-component-button-disabled {
    cursor: not-allowed;
  }
  .select-paper {
    background: ${colors.white};
    -webkit-box-shadow: 1px 12px 20px -12px #000000;
    -moz-box-shadow: 1px 12px 20px -12px #000000;
    -o-box-shadow: 1px 12px 20px -12px #000000;
    box-shadow: 1px 12px 20px -12px #000000;
    padding-inline: 6px;
  }
  .select-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .select-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }

  .type-icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .call {
    background-color: ${colors.primaryLight};
  }
  .email {
    background-color: ${colors.inactiveLight};
  }
  .in-mail {
    padding: 5px;
    background-color: ${colors.purpleLight};
  }
  .linkedin-icon {
    background-color: ${colors.blueLight};
  }
  .subtype-icon {
    background: ${colors.black} !important;
  }
  .pointer {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  .vertical-divider {
    border-left: 1px solid ${colors.grey4};
    height: 100%;
    margin: 0px;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .step-left-side-wrapper {
    padding: 16px;
    justify-content: flex-start;
    width: 50%;
    gap: 8px;
  }
  .step-right-side-wrapper {
    overflow: hidden;
    justify-content: space-between;
    flex: 1;
    gap: 8px;
  }
  .email-thread {
    padding-right: 8px;
  }
  .add-template {
    cursor: pointer;
    max-width: 100%;
    color: ${colors.grey11};
    flex: 1;
    overflow: hidden;
  }
  .add-template:hover {
    color: ${colors.black};
  }
  .add-template:hover > div > span > svg > path {
    stroke: ${colors.black};
  }
  .selected-template {
    max-width: 100%;
    cursor: pointer;
    color: ${colors.darkGreen};
    flex: 1;
    overflow: hidden;
  }
  .selected-template > div > span > svg > path {
    fill: ${colors.darkGreen};
  }
  .selected-template:hover {
    color: ${colors.darkGreenHover};
  }
  .selected-template:hover > div > span > svg > path {
    fill: ${colors.darkGreenHover};
  }

  .selected-template > span.close-template-option > svg > path {
    stroke: transparent;
  }
  .selected-template:hover > span.close-template-option > svg > path {
    stroke: ${colors.grey2};
  }
  .close-template-option:hover > svg > path {
    stroke: ${colors.grey1} !important;
  }
  .disable-due-to-edit {
    cursor: not-allowed !important;
    color: ${colors.grey11} !important;
    flex: 1;
    overflow: hidden;
    :hover {
      color: ${colors.grey11} !important;
    }
  }
  .disable-due-to-edit > span.close-template-option > svg > path {
    stroke: transparent;
  }
  .badge-observable-mode {
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: ${colors.nurtureLight};
    color: ${colors.nurtureHover};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    .pencil-icon {
      width: 16px;
      height: 16px;
    }
  }

  .icon-wrapper {
    padding: 4px;
  }
  .edit-icon {
    background-color: ${colors.primaryLight};
  }
  .edit-icon:hover {
    background-color: ${colors.primaryLightHover};
  }

  .remove-icon {
    background-color: ${colors.inactiveLight};
  }
  .remove-icon:hover {
    background-color: ${colors.inactiveLightHover};
  }

  .step-type-option-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
    width: 100%;
    padding-right: 7px;
  }
  .step-type-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  .step-type-option-dropdown {
    width: 192px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.grey4Hover};
    background: ${colors.white};
    border-radius: 8px;
  }
  .step-type-option-dropdown:hover {
    background-color: ${colors.grey4};
  }
`;

export const StepTypeOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;

  .option-button {
    text-transform: unset !important;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 8px;
    padding: 8px;
    color: ${colors.black};
  }
  .option-button:hover {
    color: ${colors.primary};
    background-color: ${colors.primaryLight};
  }
  .option-button:hover > span > svg > path,
  .option-button:hover > span > svg > circle,
  .option-button:hover > span > svg > rect {
    stroke: ${colors.primary};
  }

  // Linkdedin Compass Only
  .option-button:hover > span > svg > #path1_fill,
  .option-button:hover > span > svg > #path2_fill {
    fill: ${colors.primary};
    stroke: transparent;
  }

  // LinkedinIcon
  .option-button:hover > span > #linkedin-icon > path {
    fill: ${colors.primary};
    stroke: transparent;
  }

  // LinkedinMessageIcon
  .option-button:hover > span > svg > path#Vector {
    stroke: transparent;
    fill: ${colors.primary};
  }

  // LinkedinInvitationIcon

  .option-button > span > svg > path#linkedin_invitation_letter {
    stroke: transparent;
    fill: ${colors.black};
  }
  .option-button > span > svg > path#vertical_stroke,
  .option-button > span > svg > path#horizontal_stroke {
    stroke: ${colors.black};
  }
  .option-button > span > svg > path#cross_outside_border {
    stroke: transparent;
  }

  .option-button:hover > span > svg > path#linkedin_invitation_letter {
    stroke: transparent;
    fill: ${colors.primary};
  }
  .option-button:hover > span > svg > path#vertical_stroke,
  .option-button:hover > span > svg > path#horizontal_stroke {
    stroke: ${colors.primary};
  }
  .option-button:hover > span > svg > path#cross_outside_border {
    stroke: transparent;
  }
`;
