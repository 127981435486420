import React from 'react';
import IProps from './interface';

const IconCheckedCrono = ({ className, onClick }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect width="16" height="16" rx="4" fill="#1EBAB2" />
      <g clipPath="url(#clip0_2608_14730)">
        <path
          d="M4.45646 9.15202L8.4593 3.14777C8.55856 2.99887 8.79065 3.06914 8.79065 3.24809V7.17239H11.1522C11.2993 7.17239 11.3849 7.33867 11.2994 7.45838L7.31004 13.0434C7.20776 13.1866 6.98199 13.1143 6.98199 12.9383V9.43322H4.60695C4.46249 9.43322 4.37633 9.27222 4.45646 9.15202Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2608_14730">
          <rect
            width="10.6667"
            height="10.6667"
            fill="white"
            transform="translate(2.66699 2.6665)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCheckedCrono;
