import useGetAccount from 'hooks/services/account/useGetAccount';
import { createContext, FC, useContext, useState, useEffect } from 'react';
import { Account } from 'crono-fe-common/types/account';
import { AccountContextType } from './types';
import { FeConstants } from 'constants/FeConstants';

export const AccountContext = createContext<AccountContextType | undefined>(
  undefined,
);

AccountContext.displayName = 'AccountContext';

//TODO: CHECK EVERYWHERE THIS ACCOUNT IS USED AND USE GETPROSPECT INSTEAD OF ACCOUNT.PROSPECT

const AccountProvider: FC<{ children: any }> = ({ children }) => {
  const [account, setAccount] = useState<Account | null>(null);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedAccounts, setCheckedAccounts] = useState<string[]>([]);
  const [unCheckedAccounts, setUnCheckedAccounts] = useState<string[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null,
  );
  const [selectedProspectId, setSelectedProspectId] = useState<string | null>(
    null,
  );

  const [limit] = useState<number>(FeConstants.contactPageLimit);
  const [offset, setOffset] = useState<number>(0);
  const [onlyOwned, setOnlyOwned] = useState<boolean>(false);

  const { data: accountData } = useGetAccount(
    selectedAccountId ?? undefined,
    limit,
    offset,
    selectedProspectId,
    onlyOwned,
  );
  useEffect(() => {
    setOffset(0);
    setOnlyOwned(false);
  }, [selectedAccountId]);

  useEffect(() => {
    if (accountData && accountData.data.data) {
      setAccount(accountData.data.data);
    }
  }, [accountData]);

  return (
    <AccountContext.Provider
      value={{
        account,
        setAccount,
        selectedAccountId,
        setSelectedAccountId,
        selectedProspectId,
        setSelectedProspectId,
        checkedAll,
        setCheckedAll,
        checkedAccounts,
        setCheckedAccounts,
        unCheckedAccounts,
        setUnCheckedAccounts,
        offset,
        setOffset,
        onlyOwned,
        setOnlyOwned,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;

export function useSelectedAccount() {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error(`useAccount must be used within a AccountProvider`);
  }

  return context;
}
