import * as Yup from 'yup';

export interface CustomEmailProviderSettingsForm {
  senderName?: string | null;
  email: string | null;
  smtpUser: string | null;
  smtpPassword?: string | null;
  smtpHost: string | null;
  smtpPort: number | null;
  imapUser: string | null;
  imapPassword?: string | null;
  imapHost: string | null;
  imapPort: number | null;
}

export const customEmailProviderSettingsFormSchema = Yup.object().shape({
  senderName: Yup.string().nullable(),
  email: Yup.string().email().required('Email is required'),
  smtpUser: Yup.string().required('SMTP user is required'),
  smtpPassword: Yup.string().required('SMTP password is required'),
  smtpHost: Yup.string().required('SMTP host is required'),
  smtpPort: Yup.number()
    .typeError('The port must be a number')
    .required('SMTP port is required'),
  imapUser: Yup.string().required('IMAP user is required'),
  imapPassword: Yup.string().required('IMAP password is required'),
  imapHost: Yup.string().required('IMAP host is required'),
  imapPort: Yup.number()
    .typeError('The port must be a number')
    .required('IMAP port is required'),
});
