import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const CallLogModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${colors.black}66;
  z-index: 1000;

  .call-log-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 850px;
    max-height: 90%;
    background: ${colors.white};
    border-radius: 16px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 16px;
  }
  .quick-actions {
    gap: 8px;
    flex-wrap: wrap;
    display: flex;
  }
  .quick-actions-empty {
    gap: 8px;
    height: 92px;
  }
  .quick-actions > h2 {
    font-weight: 500;
    color: ${colors.primary};
    margin: 0;
    margin-bottom: 1rem;
  }
  .add-note-text-area {
    width: 100%;
    background-color: ${colors.grey6};
    color: ${colors.dialerBlue};
    border-radius: 1rem;
    outline: none;
  }
  .badge {
    min-width: 9rem;
    width: fit-content;
    padding: 8px 10px;
  }
  .status-not-selected-wrongNumber {
    background: #ffe2e2;
    color: #fd6778;
  }
  .status-wrongNumber {
    background: #fd6778;
  }
  .status-not-selected-checkbox-wrongNumber {
    color: #fd6778;
  }
  .status-not-selected-wrongNumber:hover {
    background: #fdd0d0;
  }
  .status-not-selected-notAnswered {
    background: #fef3d2;
    color: #e2ad13;
  }
  .status-notAnswered {
    background: #f9bb06;
  }
  .status-not-selected-checkbox-notAnswered {
    color: #e2ad13;
  }
  .status-not-selected-notAnswered:hover {
    background: #f8e6b1;
  }
  .status-not-selected-voicemail {
    background: #eaf1fb;
    color: #3b58db;
  }
  .status-voicemail {
    background: #3b58db;
  }
  .status-not-selected-checkbox-voicemail {
    color: #3b58db;
  }
  .status-not-selected-voicemail:hover {
    background: #d5e0f0;
  }
  .status-not-selected-gatekeeper {
    background: #e9f8f8;
    color: #1a9d6e;
  }

  .status-gatekeeper {
    background: #24ba84;
  }
  .status-not-selected-checkbox-gatekeeper {
    color: #1a9d6e;
  }
  .status-not-selected-gatekeeper:hover {
    background: #ceeded;
  }

  .status-not-selected-connected {
    background: #f2eaff;
    color: #8846dc;
  }
  .status-connected {
    background: #a35aff;
  }
  .status-not-selected-checkbox-connected {
    color: #8846dc;
  }
  .status-not-selected-connected:hover {
    background: #e4d8f6;
  }
`;
