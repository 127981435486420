import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const StatusSettingsIcon = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M12.5 10L7.00042 10C4.79112 10.0001 3.00021 11.7912 3.00047 14.0005V14.0005C3.00072 16.2094 4.7915 18 7.00044 18H17C19.2091 18 21 16.2091 21 14V14C21 11.7909 19.2091 10 17 10V10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0585 8.27674L16.7246 5.94287"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8602 15.2142H9.78711V13.1411C9.78711 12.9722 9.85406 12.8095 9.97395 12.6903L18.4766 4.187C18.7259 3.93767 19.1296 3.93767 19.3783 4.187L20.8137 5.62239C21.063 5.87172 21.063 6.27536 20.8137 6.52405L12.311 15.0274C12.1918 15.1472 12.0292 15.2142 11.8602 15.2142Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StatusSettingsIcon;
