import { SuggestionPreferences } from 'crono-fe-common/types/suggestionPreferences';
import React, { useMemo } from 'react';
import { SuggestionPreferencesWrapper } from './style';
import {
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import IconSClose from 'crono-fe-common/icons/Icon-S-Close';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import SuggestionMultiselectForStatus from '../suggesitonPreferencesComponents/SuggestionMultiselectStatus';
import { useGetStatusesOptions } from 'utils/fe-utils';
import SuggestionSelectEntity from '../suggesitonPreferencesComponents/SuggestionSelectEntity';
import {
  DefaultCompanyStatusOptions,
  DefaultContactStatusOptions,
} from 'crono-fe-common/types/model';

interface IProps {
  updateMultipleSuggestionPreferences: (
    properties: {
      name: keyof SuggestionPreferences;
      value: any;
    }[],
  ) => void;
  parameters: SuggestionPreferences | null;
  isLoading: boolean;
}
export interface ITaskSuggestion {
  enabled:
    | 'emailRepliedEnabled'
    | 'linkedinRepliedEnabled'
    | 'followUpMeetingEnabled'
    | 'startSequenceForNewProspectsEnabled';
  days: 'emailRepliedDays' | 'linkedinRepliedDays' | 'followUpMeetingDays';
  entity:
    | 'emailRepliedEntity'
    | 'linkedinRepliedEntity'
    | 'followUpMeetingEntity'
    | 'startSequenceForNewProspectsEntity';
  accountStatus:
    | 'emailRepliedAccountStatus'
    | 'linkedinRepliedAccountStatus'
    | 'followUpMeetingAccountStatus'
    | 'startSequenceForNewProspectsAccountStatus';
  prospectStatus:
    | 'emailRepliedProspectStatusNew'
    | 'linkedinRepliedProspectStatusNew'
    | 'followUpMeetingProspectStatusNew'
    | 'startSequenceForNewProspectsProspectStatusNew';
  customStatus:
    | 'emailRepliedCustomStatusValues'
    | 'linkedinRepliedCustomStatusValues'
    | 'followUpMeetingCustomStatusValues'
    | 'startSequenceForNewProspectsCustomStatusValues';
  customStatusId:
    | 'emailRepliedCustomStatusId'
    | 'linkedinRepliedCustomStatusId'
    | 'followUpMeetingCustomStatusId'
    | 'startSequenceForNewProspectsCustomStatusId';

  activity:
    | 'emailRepliedActivity'
    | 'linkedinRepliedActivity'
    | 'followUpMeetingActivity';
  firstTextSegment: string;
}
const PreferencesTasksInitial: ITaskSuggestion[] = [
  {
    enabled: 'emailRepliedEnabled',
    days: 'emailRepliedDays',
    entity: 'emailRepliedEntity',
    accountStatus: 'emailRepliedAccountStatus',
    prospectStatus: 'emailRepliedProspectStatusNew',
    customStatus: 'emailRepliedCustomStatusValues',
    customStatusId: 'emailRepliedCustomStatusId',
    activity: 'emailRepliedActivity',
    firstTextSegment: 'Email reply received ',
  },
  {
    enabled: 'linkedinRepliedEnabled',
    days: 'linkedinRepliedDays',
    entity: 'linkedinRepliedEntity',
    accountStatus: 'linkedinRepliedAccountStatus',
    prospectStatus: 'linkedinRepliedProspectStatusNew',
    customStatus: 'linkedinRepliedCustomStatusValues',
    customStatusId: 'linkedinRepliedCustomStatusId',
    activity: 'linkedinRepliedActivity',
    firstTextSegment: 'LinkedIn message received ',
  },
];

const SuggestionPreferencesTasksTab = ({
  updateMultipleSuggestionPreferences,
  parameters,
  isLoading,
}: IProps) => {
  const { user } = useAuth();

  const PreferencesTasks: ITaskSuggestion[] = useMemo(() => {
    if (user?.company?.integrationType === IntegrationType.CRONO) {
      return PreferencesTasksInitial;
    } else {
      return [
        ...PreferencesTasksInitial,
        {
          enabled: 'followUpMeetingEnabled',
          days: 'followUpMeetingDays',
          entity: 'followUpMeetingEntity',
          accountStatus: 'followUpMeetingAccountStatus',
          prospectStatus: 'followUpMeetingProspectStatusNew',
          customStatus: 'followUpMeetingCustomStatusValues',
          customStatusId: 'followUpMeetingCustomStatusId',
          activity: 'followUpMeetingActivity',
          firstTextSegment: 'Meeting concluded ',
        },
      ];
    }
  }, [user]);

  const activityOptions = useMemo(() => {
    if (user?.company?.integrationType === IntegrationType.CRONO) {
      return [
        { value: 'Off', label: 'Off' },
        { value: 'Task', label: 'Task' },
      ];
    } else {
      return [
        { value: 'Off', label: 'Off' },
        { value: 'Task', label: 'Task' },
        { value: 'Event', label: 'Event' },
        { value: 'Opportunity', label: 'Opportunity' },
      ];
    }
  }, [user]);

  const {
    statuses,
    isExternalPropertyStatus: isExternalPropertyStatusProspect,
    externalPropertyDefaultStatus: externalPropertyDefaultStatusContact,
    externalPropertyStatusId: externalPropertyStatusIdProspect,
  } = useGetStatusesOptions('prospect');

  const propertyForTitle = isExternalPropertyStatusProspect
    ? 'suggestNewRoleEnabledCustomStatusValues'
    : 'suggestNewRoleEnabledProspectStatusNew';

  const handleSwitchSuggestTitle = () => {
    const fieldsToChange: { name: keyof SuggestionPreferences; value: any }[] =
      [
        {
          name: 'suggestNewRoleEnabled',
          value: !parameters?.suggestNewRoleEnabled as boolean,
        },
      ];
    //If there are no options and we are switching to true we set a default one. If we are using externalPropertyStatus we also reset the default one
    if (
      !parameters?.suggestNewRoleEnabled &&
      (parameters?.[propertyForTitle]?.length ?? 0) === 0
    ) {
      fieldsToChange.push({
        name: propertyForTitle,
        value: [externalPropertyDefaultStatusContact],
      });
      if (isExternalPropertyStatusProspect) {
        fieldsToChange.push({
          name: 'suggestNewRoleEnabledCustomStatusId',
          value: externalPropertyStatusIdProspect,
        });
        fieldsToChange.push({
          name: 'suggestNewRoleEnabledProspectStatusNew',
          value: [],
        });
      }
    }
    updateMultipleSuggestionPreferences(fieldsToChange);
  };

  return (
    <>
      {PreferencesTasks.map((task, index) => {
        //If the entity is not selected, so Off
        const entityNotSelected =
          (parameters?.[task.entity] ?? 'Off') === 'Off';
        //If the selected entity is Account
        const statusToUse =
          parameters?.[task.entity] === 'Account'
            ? task.accountStatus
            : task.prospectStatus;
        const valuesStatus:
          | DefaultCompanyStatusOptions[]
          | DefaultContactStatusOptions[] = parameters?.[task.entity]
          ? parameters[statusToUse] || []
          : [];
        const enabled = parameters?.[task.enabled];
        return (
          <SuggestionPreferencesWrapper key={index}>
            <CronoSwitch
              checked={!!enabled}
              onChange={(_) => {
                updateMultipleSuggestionPreferences([
                  {
                    name: task.enabled,
                    value: !enabled as boolean,
                  },
                ]);
              }}
            />
            <div
              className="container-text-suggestion"
              style={{
                color: !enabled ? colors.grey2 : colors.black,
              }}
            >
              <div className="container-text-line">
                {task.firstTextSegment}
                {/*To allow the use of the default value I show the textField only when the parameter is received */}
                {(parameters?.[task.days] ?? -1) >= 0 && (
                  <TextField
                    defaultValue={parameters?.[task.days]}
                    disabled={!enabled}
                    type="number"
                    className="day-picker-preferences"
                    onChange={(ev) => {
                      if (
                        ev.target.value &&
                        !Number.isNaN(Number(ev.target.value))
                      ) {
                        updateMultipleSuggestionPreferences([
                          { name: task.days, value: ev.target.value },
                        ]);
                      }
                    }}
                    InputProps={{
                      sx: {
                        height: 30,
                        width: 50,
                        marginInline: 1,
                        borderRadius: 2,
                        color: 'inherit',
                        '&:hover': {
                          background: colors.grey6,
                        },
                        '& fieldset': {
                          border: `1px solid ${colors.grey444}`,
                        },
                        '&:hover fieldset': {
                          border: `1px solid ${colors.grey5}`,
                        },
                        '&.Mui-focused fieldset': {
                          border: `1px solid ${colors.grey5}`,
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        border: 'none',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '18px',
                      },
                    }}
                  />
                )}
                days ago{' '}
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 8,
                    color:
                      entityNotSelected || !enabled
                        ? colors.grey2
                        : colors.black,
                  }}
                >
                  AND{' '}
                  <SuggestionSelectEntity
                    enabled={enabled}
                    suggestion={task}
                    parameters={parameters}
                    updateMultipleSuggestionPreferences={
                      updateMultipleSuggestionPreferences
                    }
                  />
                  status is
                  <SuggestionMultiselectForStatus
                    enabled={enabled}
                    property={task}
                    parameters={parameters}
                    updateMultipleSuggestionPreferences={
                      updateMultipleSuggestionPreferences
                    }
                    entityNotSelected={entityNotSelected}
                    isLoading={isLoading}
                  />
                </span>
              </div>
              <div
                className="container-text-line"
                style={{
                  color:
                    (parameters?.[task.activity] ?? 'Off') === 'Off' || !enabled
                      ? colors.grey2
                      : colors.black,
                }}
              >
                AND No
                {parameters?.[task.activity] !== undefined && (
                  <Select
                    className="select-suggestions-preferences"
                    value={parameters?.[task.activity] ?? 'Off'}
                    onChange={(ev) => {
                      updateMultipleSuggestionPreferences([
                        {
                          name: task.activity,
                          value: ev.target.value,
                        },
                      ]);
                    }}
                    sx={{
                      fontSize: '14px',
                      boxShadow: 'none',
                      color: 'inherit',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${colors.grey4}`,
                        borderRadius: 2,
                      },
                      '.MuiOutlinedInput-input': {
                        padding: '4px 4px 4px 6px',
                      },
                      padding: '2px',
                    }}
                    disabled={!enabled}
                    MenuProps={{
                      style: { zIndex: 3501 },
                      PaperProps: {
                        sx: {
                          borderRadius: '8px',
                          paddingInline: '6px',
                          border: '1px solid #dadde9',
                          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                          '& .MuiMenuItem-root': {
                            fontSize: '12px',
                            lineHeight: '16px',
                            height: 'fit-content',
                            padding: '8px',
                            cursor: 'pointer',
                            width: '100%',
                            borderRadius: '8px',
                            '&:hover': {
                              background: colors.primaryLight,
                              color: colors.primary,
                            },
                          },
                          '& .Mui-selected': {
                            color: colors.primary,
                            backgroundColor: 'transparent !important',
                          },
                        },
                      },
                    }}
                  >
                    {activityOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                logged.
              </div>
            </div>
          </SuggestionPreferencesWrapper>
        );
      })}
      <SuggestionPreferencesWrapper>
        <CronoSwitch
          checked={parameters?.suggestNewRoleEnabled ?? false}
          onChange={handleSwitchSuggestTitle}
        />
        <div
          className="container-text-suggestion"
          style={{
            color: !parameters?.suggestNewRoleEnabled
              ? colors.grey2
              : colors.black,
          }}
        >
          <div className="container-text-line">
            Contact changed position in the company AND contact status is{' '}
            <div style={{ position: 'relative' }}>
              <Select
                value={parameters?.[propertyForTitle] ?? []}
                displayEmpty
                renderValue={(values) => {
                  if (!values)
                    return (
                      <Typography fontSize={'14px'}>Not selected</Typography>
                    );
                  return (
                    <Typography
                      fontSize={'14px'}
                      style={{
                        color: !parameters?.suggestNewRoleEnabled
                          ? colors.grey2
                          : colors.black,
                      }}
                    >
                      {values?.length > 0
                        ? `${values.length} selected`
                        : 'Not selected'}
                    </Typography>
                  );
                }}
                inputProps={{
                  placeholder: 'Select status',
                }}
                fullWidth
                size="small"
                multiple
                disabled={!parameters?.suggestNewRoleEnabled}
                onChange={(ev) => {
                  updateMultipleSuggestionPreferences([
                    {
                      name: propertyForTitle,
                      value: ev.target.value,
                    },
                  ]);
                }}
                MenuProps={{
                  style: { zIndex: 35001 },
                  PaperProps: {
                    sx: {
                      borderRadius: '8px',
                      paddingInline: '6px',
                      border: '1px solid #dadde9',
                      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                      '& .MuiMenuItem-root': {
                        fontSize: '12px',
                        lineHeight: '16px',
                        height: 'fit-content',
                        padding: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        borderRadius: '8px',
                        '&:hover': {
                          background: colors.primaryLight,
                          color: colors.primary,
                        },
                      },
                      '& .Mui-selected': {
                        // color: colors.primary,
                        backgroundColor: 'transparent !important',
                      },
                    },
                  },
                }}
                className="select-suggestions-preferences"
                sx={{
                  boxShadow: 'none',
                  width: '150px',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${colors.grey444}`,
                    borderRadius: '8px',
                  },
                  '.MuiOutlinedInput-notchedOutline:hover': {
                    border: `1px solid ${colors.grey444}`,
                  },
                  '.MuiInputBase-input': {
                    paddingBlock: '6px',
                  },
                  '&:hover': {
                    background: colors.grey6,
                  },
                }}
              >
                {isLoading ? (
                  <FlexDiv>
                    <CircularProgress />
                  </FlexDiv>
                ) : (
                  statuses.map((status, index) => (
                    <MenuItem key={index} value={status.option}>
                      <Checkbox
                        icon={<IconUnchecked />}
                        checkedIcon={<IconChecked color={colors.mainDark} />}
                        checked={
                          (parameters?.[propertyForTitle] ?? []).findIndex(
                            (option) => option === status.option,
                          ) > -1 || false
                        }
                        sx={{ padding: '0px 8px 0px 0px !important' }}
                      />
                      {status.label}
                    </MenuItem>
                  ))
                )}
              </Select>
              {(parameters?.[propertyForTitle] ?? []).length > 0 &&
                parameters?.suggestNewRoleEnabled && (
                  <IconSClose
                    className="remove-selection"
                    onClick={(ev: any) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      updateMultipleSuggestionPreferences([
                        {
                          name: propertyForTitle,
                          value: [],
                        },
                      ]);
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </SuggestionPreferencesWrapper>
    </>
  );
};

export default SuggestionPreferencesTasksTab;
