import { ReactComponent as ChartBars } from '../../../assets/images/Bars.svg';
import { ReactComponent as Lines } from '../../../assets/images/Lines.svg';
import { ReactComponent as Heatmap } from '../../../assets/images/Heatmap.svg';

import ChartHorizontalLines from '../../../assets/images/Horizontal-lines-frame.png';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { Typography } from '@mui/material';

export const BarChartPlaceholder = () => {
  return (
    <FlexDiv id="no-results-placeholder-container">
      <img
        src={ChartHorizontalLines}
        alt="Horizontal chart lines"
        style={{ opacity: 0.7 }}
      />
      <FlexDiv id="information-placeholder" position="absolute">
        <ChartBars />
      </FlexDiv>
      <FlexDiv id="information-placeholder" position="absolute">
        <Typography
          fontSize={'16px'}
          fontWeight={600}
          className="backdrop-blur-style"
        >
          No available data
        </Typography>
      </FlexDiv>
    </FlexDiv>
  );
};

export const LineChartPlaceholder = () => {
  return (
    <FlexDiv id="no-results-placeholder-container">
      <img
        src={ChartHorizontalLines}
        alt="Horizontal chart lines"
        style={{ opacity: 0.7 }}
      />
      <FlexDiv id="information-placeholder" position="absolute">
        <Lines />
      </FlexDiv>
      <FlexDiv id="information-placeholder" position="absolute">
        <Typography
          fontSize={'16px'}
          fontWeight={600}
          className="backdrop-blur-style"
        >
          No available data
        </Typography>
      </FlexDiv>
    </FlexDiv>
  );
};

export const HeatmapPlaceholder = () => {
  return (
    <FlexDiv id="no-results-placeholder-container">
      <Heatmap />
      <FlexDiv id="information-placeholder" position="absolute">
        <Typography
          fontSize={'16px'}
          fontWeight={600}
          className="backdrop-blur-style"
        >
          No available data
        </Typography>
      </FlexDiv>
    </FlexDiv>
  );
};
