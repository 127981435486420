import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  overflow-y: hidden;
  border: 1px solid #ebebeb;
  border-radius: 16px 16px 16px 16px;
  background: ${colors.white};

  .empty-state-placeholder {
    min-height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px 10px;
    gap: 10px;
  }
  .switch-button,
  .switch-button-not-selected {
    border-radius: 8px !important;
    padding: 12px 20px;
    text-transform: unset !important;
    font-size: 14px;
    margin: 6px;
  }
  .switch-button {
    z-index: 10;
  }
  .switch-button-not-selected {
    color: ${colors.primaryDark};
    background: ${colors.primaryLight};
    z-index: 9;
    &:hover {
      background: ${colors.primaryLightHover};
    }
  }
  .buttons-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .filters-space {
    flex: 1;
    display: flex;
    overflow: hidden;
    gap: 6px;
    margin-left: 8px;
  }
  .text-field-search {
    padding: 0px 10px;
    width: 200px;
    .text-field {
      width: 180px;
      .MuiInput-underline:after {
        border-bottom: 2px solid ${colors.grey11};
      }
    }
  }
  .text-field {
    width: 100%;
  }
  .filter-button,
  .filter-icon-button {
    border-radius: 8px;
    height: 40px;
    text-transform: none;
    border: 1px solid ${colors.blueLightHover};
    font-size: 1rem;
  }
  .filter-button {
    min-width: 100px;
    cursor: pointer;
  }

  .dashboard-tabs-container {
    display: flex;
    background: ${colors.primaryLight};
    padding: 0;
    width: fit-content;
    border-radius: 8px;

    &:hover {
      background: ${colors.primaryLightHover};
    }
  }

  .switch-my-contacts-dashboard {
    display: flex;
    align-items: center;
  }

  .table-container {
    height: calc(100% - 132px);
    width: 100%;
    .lead-badge {
      font-size: 10px;
      min-width: 40px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
      cursor: default !important;
      color: ${colors.black};
      border: 1px solid ${colors.grey4Hover};
      border-radius: 50px;
    }
    .error-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      max-width: 470px;
    }
    .find-new {
      cursor: pointer;
      color: ${colors.darkGreen};
      font-weight: 600;
    }
    .find-new:hover {
      color: ${colors.darkGreenHover};
    }
    .icon-copy {
      min-width: 24px;
      width: 24px;
      cursor: pointer;
    }
  }

  .column,
  .first-column {
    background: white;
    border-right: 0px !important;
    color: ${colors.grey11} !important;
  }
  .first-column {
    z-index: 11;
    color: ${colors.grey11} !important;
  }
  .slider {
    width: 32px;
    height: 3px;
    cursor: initial;
    margin-left: 3px;
  }
  .slider > .MuiSlider-thumb {
    display: none;
  }
  .button-suggestion {
    background: ${colors.grey4};
    color: ${colors.black};
    border-radius: 9999px;
    font-size: 14px;
    padding: 4px;
    line-height: 1.5;
    &:hover {
      background: ${colors.grey4Hover};
    }
  }
  .account-avatar {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    font-size: 14px !important;
    border: 1px solid ${colors.grey4Hover};
  }
  .white {
    background: ${colors.white} !important;
  }
  .prospect-avatar {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    margin-left: 5px;
    margin-right: 10px;
    size: 20px !important;
    font-size: 14px !important;
  }
  .prospect-avatar-row {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    margin-left: 10px;
    margin-top: 5px;
    margin-right: 10px;
    size: 24px !important;
    font-size: 14px !important;
    border: 1px solid ${colors.grey444};
  }
  .icon-contact-column-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${colors.grey11};
  }

  .icon-contact-column-container:hover {
    color: ${colors.black};
  }
  .icon-contact-column-container:hover svg {
    stroke: ${colors.black};
  }
  .icon-contact-column-container:hover path {
    stroke: ${colors.black};
  }

  .icon-contact-column {
    margin-right: 6px;
  }
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
    margin: 0 6px;
    border-radius: 9999px;
    background: black;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    display: flex;
  }
  .status-inactive {
    background: ${colors.inactive};
    &:hover {
      background: ${colors.inactiveHover};
    }
  }
  .status-nurture {
    background: ${colors.nurture};
    &:hover {
      background: ${colors.nurtureHover};
    }
  }
  .status-working {
    background: ${colors.prospecting};
    &:hover {
      background: ${colors.prospectingHover};
    }
  }
  .status-callscheduled {
    background: ${colors.callScheduled};
    &:hover {
      background: ${colors.callScheduledHover};
    }
  }
  .status-openopportunity {
    background: ${colors.openOpportunity};
    &:hover {
      background: ${colors.openOpportunityHover};
    }
  }
  .status-customer {
    background: ${colors.customer};
    &:hover {
      background: ${colors.customerHover};
    }
  }
  .status-notcontacted {
    background: ${colors.nurture};
  }
  .status {
    // width: 120px;
    font-size: 12px;
  }
  .status-firstcontact {
    background: ${colors.blue};
  }
  .status-warm {
    background: ${colors.callScheduled};
  }
  .status-cold {
    background: ${colors.inactive};
  }
  .status-notinterested {
    background: ${colors.purple};
  }
  .activity-icon {
    margin-right: 4px;
    min-height: 16px;
    min-width: 16px;
  }

  .activity-icon:hover > path {
    // fill: ${colors.black};
  }

  .remove-text-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .activities-container {
    cursor: pointer;
    color: ${colors.grey11};
    // display: flex;
    // max-width: 180px;
  }

  // text
  .activities-container:hover {
    color: ${colors.black};
  }
  .activities-container:hover svg {
    stroke: ${colors.black};
  }
  .activities-container:hover path {
    stroke: ${colors.black};
  }
  .activities-container:hover #linkedin-icon > path {
    fill: ${colors.black};
  }
  #linkedin-icon > path {
    stroke: none;
  }

  .search-in-crm-row {
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    position: sticky;
    left: 0;
  }
  .search-in-crm-row-clickable {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
  }
  //Sibling class
  .search-in-crm-row-clickable-disabled {
    filter: grayscale(0.5);
    opacity: 0.5;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .search-in-crm-row-no-results {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 130px;
    gap: 20px;
    position: sticky;
    left: 0;
  }
  .search-in-crm-results {
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: sticky;
    left: 0;
    background: ${colors.greyLight};
  }

  .add-manually-button {
    border-radius: 50%;
    margin: 6px 8px;
    font-size: 16px;
    text-transform: unset !important;
    height: 40px;
    width: 40px;
    padding: 8px;
    background: ${colors.black};
    color: ${colors.white};
  }
  .export-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid ${colors.grey33};
    margin-right: 24px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 4px 0px;
  width: 100%;
  border-top: 1px solid #ebebeb;
  border-radius: 0px 0px 16px 16px;
  height: 40px;

  .selector {
    width: 260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
    font-size: 12px;
  }

  .accounts-icon {
    cursor: pointer;
    margin-right: 5px;
  }

  .update-container {
    margin-left: 15px;
  }

  .update-button {
    margin-left: 15px;
  }

  .input-label {
    font-size: 12px;
    font-weight: 500;
  }

  .update-label {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }

  .selected-label {
    font-size: 12px;
    font-weight: 500;
    width: 80px;
  }

  .update-label-tooltip {
    margin-bottom: 10px;
  }

  .update-select-container {
    width: 80px;
    margin-left: 20px;
  }

  .update-select {
    width: 100px;
    height: 30px;
    margin: 0px 10px;
    font-size: 11px;
  }

  .pagination-container {
    width: 700px;
  }

  .pagination > ul {
    width: 700px;
  }

  .pagination {
    padding-left: 0;
    border-radius: 4px;
    margin-left: 20px;
  }
  .pagination > li {
    display: inline;
  }
  .pagination > li > a,
  .pagination > li > span {
    font-size: 12px;
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    background-color: transparent;
    border-radius: 1rem;
    margin-left: -1px;
    cursor: pointer;
    font-size: large;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    z-index: 2;
    color: #ffffff;
    border-color: #ddd;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3;
    color: #222222;
    border-color: #2c689c;
    cursor: default;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777777;
    border-color: #ddd;
    cursor: not-allowed;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 20px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .arrow-pagination {
    cursor: pointer;
  }
`;
