import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from 'context/auth';
import useGoogleAuthCallback from 'hooks/services/google/useGoogleAuthCallback';
import useDisconnectGoogleIntegration from 'hooks/services/google/useDisconnectGoogleIntegration';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { FeConstants } from 'constants/FeConstants';
import { ConnectWrapper } from './styles';
import { ConnectWrapper as GmailConnectWrapper } from 'pages/connect/views/main/styles';
import SalesforceLogo from 'assets/images/Icon_Salesforce.svg';
import GmailLogo from 'assets/images/gmail_logo.svg';
import HubspotLogo from 'assets/images/Icon_Hubspot.svg';
import LinkedinLogo from 'assets/images/Icon_Linkedin_extension.svg';
import PipedriveLogo from 'assets/images/Icon_Pipedrive.svg';
import OutlookLogo from 'assets/images/outlook.svg';
import AircallLogo from 'assets/images/Icon_aircall_extension.svg';
import ShieldLock from 'assets/images/Shield-lock-icon.svg';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import {
  useGetExtensionStatus,
  CronoExtensionStatus,
} from 'hooks/services/crono-extension/useGetExtensionStatus';
import InstallLinkedinExtensionModal from 'crono-fe-common/components/Modal/InstallLinkedinExtensionModal';
import useCRMIntegrationGuard from 'hooks/guards/useCRMIntegrationGuard';
import useEmailIntegrationGuard from 'hooks/guards/useEmailIntegrationGuard';
import useCallIntegrationGuard from 'hooks/guards/useCallIntegrationGuard';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import {
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';
import useDisconnectOutlookIntegration from 'hooks/services/outlook/useDisconnectGoogleIntegration';
import CallIntegrationType from 'crono-fe-common/types/enums/callIntegrationType';
import Role from 'crono-fe-common/types/enums/role';
import useGetActiveMigration from 'hooks/services/migration/useGetActiveMigration';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import IconHubspot from 'crono-fe-common/icons/Icon-Hubspot';
import IconSalesforce from 'crono-fe-common/icons/Icon-Salesforce';
import IconPipedrive from 'crono-fe-common/icons/Icon-Pipedrive';
import {
  crmIntegrationsMenuItems,
  customSelectPaperProps,
  emailIntegrationsMenuItems,
} from 'pages/subscription/integrationStep/integrationsStep';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import useStartMigration from 'hooks/services/subscription/useStartMigration';
import { getError } from 'crono-fe-common/utils';
import { useConfirmModal } from 'context/confirmModal';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { Constants } from 'crono-fe-common/constants/constants';
import IconCustomEmailProvider from 'crono-fe-common/icons/Icon-Custom-Email-Provider';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import CustomEmailProviderSettingsModal from './customEmailProviderSettingsModal';
import useDisconnectCustomEmailProviderIntegration from 'hooks/services/customEmailIntegration/useDisconnectCustomEmailProviderIntegration';
import TextAccordion from 'crono-fe-common/components/TextAccordion';
import { gmailInstructions } from 'pages/onboarding/pages/email';
import useEditOtherSettings, {
  OtherSettingsInputs,
} from 'hooks/services/subscription/useEditOtherSettings';
import IconCrono from 'crono-fe-common/icons/Icon-Crono';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import classNames from 'classnames';
import { IntegrationMenuItem } from 'pages/subscription/integrationStep/integrationsStep';

const Integrations: FC = () => {
  const navigate = useNavigate();
  const [showCrmOptions, setShowCrmOptions] = useState<boolean>(false);

  const [scopeNotAcceptedError, setScopeNotAcceptedError] =
    useState<boolean>(false);
  const [openInstallLinkedinExtension, setOpenInstallLinkedinExtension] =
    useState<boolean>(false);

  const {
    user,
    refreshUser,
    isSubscriptionManager,
    highestRole,
    hasSalesNav,
    automaticLinkedinTaskExecutionLimit,
  } = useAuth();
  const [checkSalesNav, setCheckSalesNav] = useState<boolean>(
    user?.userPreferences?.hasSalesNav ?? false,
  );

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const [sliderLimitValue, setSliderLimitValue] = useState<number>(
    automaticLinkedinTaskExecutionLimit,
  );

  const analytics = useJuneAnalytics();

  const {
    isSuccessSF,
    isErrorSF,
    isSuccessHS,
    isErrorHS,
    isSuccessPD,
    isErrorPD,
    isSuccess,
    isLoading: isLoadingCRM,
  } = useCRMIntegrationGuard();

  const { data: extensionStatus } = useGetExtensionStatus();

  const { data: activeMigration } = useGetActiveMigration(
    user?.integrationType === IntegrationType.CRONO,
  );

  const {
    // isSuccessGoogle: googleIntegrated,
    // isErrorGoogle: googleNotIntegrated,
    // errorGoogle: googleError,
    // verifyGoogleIntegration,
    // isSuccessOutlook: outlookIntegrated,
    // isErrorOutlook: outlookNotIntegrated,
    // isLoadingOutlook,
    // errorOutlook: outlookError,
    // verifyOutlookIntegration,
    isSuccess: emailIntegrated,
    isError: emailNotIntegrated,
    error: emailError,
    isLoading: isLoadingEmail,
    verifyIntegration,
  } = useEmailIntegrationGuard();

  const { isSuccess: googleDisconnected, disconnectGoogleIntegration } =
    useDisconnectGoogleIntegration();

  const { isSuccess: outlookDisconnected, disconnectOutlookIntegration } =
    useDisconnectOutlookIntegration();

  const {
    disconnectCustomEmailProviderIntegration,
    error: errorDisconnectingCustomEmailProviderIntegration,
    isSuccess: customEmailProviderDisconnected,
  } = useDisconnectCustomEmailProviderIntegration();
  const {
    isSuccess: callIntegrated,
    isError: callNotIntegrated,
    isLoading: isLoadingCall,
  } = useCallIntegrationGuard();

  useEffect(() => {
    if (googleDisconnected) {
      verifyIntegration();
    }
  }, [googleDisconnected]);

  useEffect(() => {
    if (outlookDisconnected) {
      verifyIntegration();
    }
  }, [outlookDisconnected]);

  useEffect(() => {
    if (customEmailProviderDisconnected) {
      verifyIntegration();
    }
  }, [customEmailProviderDisconnected]);

  useEffect(() => {
    if (emailError?.response?.status === 401) {
      navigate(PATH.LOGIN);
    }
  }, [emailError]);

  const {
    isLoading: isLoadingGoogleAuthCallback,
    isSuccess: isSuccessGoogleAuthCallback,
    error: errorGoogleAuthCallback,
    googleAuthCallback,
  } = useGoogleAuthCallback();

  useEffect(() => {
    if (isSuccessGoogleAuthCallback) {
      verifyIntegration();
      if (analytics) {
        analytics.track('mail-integration', {
          crm: 'google',
        });
      }
      refreshUser();
    }
  }, [isSuccessGoogleAuthCallback]);

  const salesforceLogin = () => {
    if (!isSuccessSF) {
      window.location.replace(FeConstants.SALESFORCE_LOGIN_URL);
    }
  };

  const hubspotLogin = () => {
    if (!isSuccessHS) {
      window.location.replace(FeConstants.HUBSPOT_LOGIN_URL);
    }
  };

  const pipedriveLogin = () => {
    if (!isSuccessPD) {
      window.location.replace(FeConstants.PIPEDRIVE_LOGIN_URL);
    }
  };

  const outlookLogin = () => {
    window.location.replace(FeConstants.OUTLOOK_LOGIN_URL);
  };

  const linkedinLogin = () => {
    if (
      extensionStatus?.status &&
      extensionStatus.status === CronoExtensionStatus.NOT_INSTALLED
    ) {
      setOpenInstallLinkedinExtension(true);
    }
  };

  const googleLogin = useGoogleLogin({
    scope: FeConstants.gmailScopes,
    onSuccess: async (tokenResponse) => {
      const acceptedScopes = tokenResponse.scope
        .split(' ')
        .filter((scope) => scope !== '');
      if (
        acceptedScopes.includes('email') &&
        acceptedScopes.includes('https://www.googleapis.com/auth/gmail.send') &&
        acceptedScopes.includes(
          'https://www.googleapis.com/auth/gmail.readonly',
        )
      ) {
        googleAuthCallback(tokenResponse.code);
      } else {
        setScopeNotAcceptedError(true);
        setTimeout(() => {
          setScopeNotAcceptedError(false);
        }, 3000);
      }
    },

    flow: 'auth-code',
  });

  const aircallLogin = () => {
    window.location.replace(FeConstants.AIRCALL_LOGIN_URL);
  };

  const goHome = () => {
    if (isSuccess || !user) {
      navigate(`${PATH.HOME}`);
    }
  };

  const renderConnectCRM = (
    integrationType: IntegrationType,
    logo: string,
    loading: boolean | undefined,
    success: boolean | undefined,
    error: boolean,
    loginFn: () => void,
  ) => {
    return (
      <div className="provider-box">
        <div className="icon-logo-container">
          <img
            src={logo}
            alt={integrationType}
            className={`connection-image ${integrationType}`}
          />
        </div>
        <div className={`connection-label`}>
          <Typography
            fontSize={'16px'}
            fontWeight={600}
            lineHeight={'24px'}
            color={colors.black}
          >
            {integrationType}
          </Typography>
          <Typography
            fontSize={'14px'}
            fontWeight={500}
            lineHeight={'18px'}
            color={
              !loading && success ? colors.darkGreen : colors.inactiveHover
            }
          >
            {success && !loading && 'Connected'}
            {(error || loading) && 'Not Connected'}
          </Typography>
        </div>
        <div
          className={`icons-buttons-container ${
            !loading && success ? 'connected' : 'not-connected'
          }`}
        >
          {!loading && success ? (
            <div className="connection-status">
              <Typography fontSize={'14px'} fontWeight={500}>
                Connected
              </Typography>
              <CheckMarkIcon color={colors.callScheduled} />
            </div>
          ) : (
            <div
              className="connection-status pointer"
              onClick={() => loginFn()}
            >
              <Typography fontSize={'14px'} fontWeight={500}>
                Connect
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  };

  const saleforceIntegration = user && (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {(user.company?.integrationType === IntegrationType.SALESFORCE ||
        activeMigration?.data?.data?.type === IntegrationType.SALESFORCE) &&
        renderConnectCRM(
          IntegrationType.SALESFORCE,
          SalesforceLogo,
          isLoadingCRM,
          isSuccessSF,
          isErrorSF,
          salesforceLogin,
        )}
      {user.userRoles?.includes(Role.MANAGER) &&
        user.company?.integrationType === IntegrationType.SALESFORCE && (
          <div style={{ display: 'flex', maxWidth: '530px' }}>
            <Typography paddingRight="4px">*</Typography>
            <Typography>
              Crono integrates with Salesforce through Web Hooks. If you are a
              Salesforce Admin, you can check the Web Hooks status{' '}
              <a
                style={{ textDecoration: 'none', color: colors.primaryDark }}
                href={`${process.env.REACT_APP_BE_URL}/api/webhookcreator/?token=${user?.token.token}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </Typography>
          </div>
        )}
    </div>
  );

  const hubspotIntegration =
    user &&
    (user.company?.integrationType === IntegrationType.HUBSPOT ||
      activeMigration?.data?.data?.type === IntegrationType.HUBSPOT) &&
    renderConnectCRM(
      IntegrationType.HUBSPOT,
      HubspotLogo,
      isLoadingCRM,
      isSuccessHS,
      isErrorHS,
      hubspotLogin,
    );

  const pipedriveIntegration =
    user &&
    (user?.company?.integrationType === IntegrationType.PIPEDRIVE ||
      activeMigration?.data?.data?.type === IntegrationType.PIPEDRIVE) &&
    renderConnectCRM(
      IntegrationType.PIPEDRIVE,
      PipedriveLogo,
      isLoadingCRM,
      isSuccessPD,
      isErrorPD,
      pipedriveLogin,
    );

  const handlePatchAccountLimit = () => {
    patchUserPreferences({
      automaticTaskLimit: sliderLimitValue,
    });
    if (user) {
      user.userPreferences.automaticTaskLimit = sliderLimitValue;
    }
  };

  useEffect(() => {
    patchUserPreferences({
      hasSalesNav: checkSalesNav,
    });
    if (user) {
      user.userPreferences.hasSalesNav = checkSalesNav;
    }
  }, [checkSalesNav]);

  const [
    showErrorNormalUserTriesToEnableSalesNav,
    setShowErrorNormalUserTriesToEnableSalesNav,
  ] = useState<boolean>(false);

  const handleShowErrorNormalUserTriesToEnableSalesNav = () => {
    setShowErrorNormalUserTriesToEnableSalesNav(true);
    setTimeout(() => {
      setShowErrorNormalUserTriesToEnableSalesNav(false);
    }, 3000);
  };

  const [expanded, setExpanded] = useState<boolean>(false);
  const [copyText, setCopyText] = useState<boolean>(false);

  const handleCopyClipboard = useCallback((text: string) => {
    navigator.clipboard.writeText(text);
    setCopyText(true);
    setTimeout(() => setCopyText(false), 3000);
  }, []);

  // TODO possible refactor for email integrations
  const gmailIntegration = (
    <div className="provider-box provider-box--gmail">
      <div className="gmail-provider-top">
        <div className={`icon-logo-container email`}>
          <img
            src={GmailLogo}
            alt={'gmail'}
            className={`connection-image gmail`}
          />
        </div>
        <div className={`connection-label`}>
          <Typography
            fontSize={'16px'}
            fontWeight={600}
            lineHeight={'24px'}
            color={colors.black}
          >
            Gmail
          </Typography>
          <Typography
            fontSize={'14px'}
            fontWeight={500}
            lineHeight={'18px'}
            color={
              (isLoadingGoogleAuthCallback && !emailIntegrated) ||
              isSuccessGoogleAuthCallback ||
              (emailIntegrated && !isSuccessGoogleAuthCallback)
                ? colors.darkGreen
                : colors.inactiveHover
            }
          >
            {emailIntegrated && !isSuccessGoogleAuthCallback && 'Connected'}
            {(emailNotIntegrated || googleDisconnected) &&
              !isSuccessGoogleAuthCallback &&
              'Login required'}
            {isLoadingGoogleAuthCallback && !emailIntegrated && 'Connecting'}
            {isSuccessGoogleAuthCallback && 'Integration complete!'}
            {errorGoogleAuthCallback && 'Error during integration'}
          </Typography>
        </div>
        {/* Connect */}
        {((emailNotIntegrated && !isSuccessGoogleAuthCallback) ||
          (googleDisconnected && !isSuccessGoogleAuthCallback)) && (
          <div
            className={'icons-buttons-container pointer not-connected'}
            onClick={() => googleLogin()}
          >
            <div className="connection-status">
              <Typography fontSize={'14px'} fontWeight={500}>
                Connect
              </Typography>
            </div>
          </div>
        )}
        {/* Connected / Disconnect */}
        {(emailIntegrated || isSuccessGoogleAuthCallback) &&
          !(googleDisconnected && !isSuccessGoogleAuthCallback) && (
            <div className="connected-email-buttons-wrapper">
              <Tooltip title="Disconnect" arrow placement="top">
                <div
                  className="email-icon-button pointer"
                  onClick={() => {
                    disconnectGoogleIntegration();
                  }}
                >
                  <CloseMIcon
                    className="check-icon not-success-icon"
                    color={colors.white}
                  />
                </div>
              </Tooltip>
              <div className={'icons-buttons-container connected'}>
                <div>
                  <Typography fontSize={'14px'} fontWeight={500}>
                    Connected
                  </Typography>
                </div>
                <CheckMarkIcon color={colors.callScheduled} />
              </div>
            </div>
          )}
      </div>

      {((emailNotIntegrated && !isSuccessGoogleAuthCallback) ||
        (googleDisconnected && !isSuccessGoogleAuthCallback)) &&
        highestRole === Role.MANAGER && (
          <GmailConnectWrapper className="gmail-provider-bottom">
            <div
              className="gmail-instruction-wrapper"
              style={{
                height: expanded ? 'fit-content' : undefined,
                alignItems: expanded ? 'flex-start' : undefined,
              }}
            >
              <div className="gmail-provider-info">
                <div className={`icon-logo-container`}>
                  <img
                    src={ShieldLock}
                    alt={'Instructions'}
                    className={`shield-lock-icon`}
                  />
                </div>

                <div className={`connection-label-instructions--gmail`}>
                  <Typography
                    fontSize={'14px'}
                    fontWeight={400}
                    lineHeight={'24px'}
                    color={colors.black}
                  >
                    Google Workspace guide for the Admin
                  </Typography>

                  <Typography
                    fontSize={'12px'}
                    fontWeight={400}
                    lineHeight={'16px'}
                    color={colors.grey11}
                  >
                    Crono needs to be marked as trusted app in your Google
                    Workspace. If you are not the{' '}
                    <span style={{ fontWeight: 600 }}>Admin</span> of the
                    workspace, please{' '}
                    <span
                      className="share-guide"
                      onClick={() =>
                        handleCopyClipboard(FeConstants.gmailGuideText)
                      }
                    >
                      share this guide
                    </span>{' '}
                    with the <span style={{ fontWeight: 600 }}>Admin</span>
                  </Typography>
                </div>

                {/* Instructions button */}
                <div
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setExpanded((prev) => !prev);
                  }}
                  className={'gmail-instruction-button'}
                >
                  <Typography
                    fontSize={'14px'}
                    fontWeight={500}
                    lineHeight={'18px'}
                    color={colors.grey1}
                    letterSpacing={'0px'}
                  >
                    Instructions
                  </Typography>

                  <IconButton
                    className="instructions-arrow-icon-button"
                    style={{
                      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                  >
                    <BottomArrow />
                  </IconButton>
                </div>
              </div>

              <TextAccordion
                text={null}
                expanded={expanded}
                setExpanded={setExpanded}
                hideArrow
                style={{
                  display: !expanded ? 'none' : undefined,
                  paddingLeft: '0px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '16px',
                  }}
                >
                  <div className="horizontal-divider-gmail" />

                  <div className="instructions-container">
                    {gmailInstructions(handleCopyClipboard).map(
                      ({ id, instruction }) => (
                        <div className="instruction-wrapper-gmail" key={id}>
                          <Typography
                            className="instruction-index"
                            fontWeight={600}
                            color={colors.prospectingHover}
                          >
                            {id}
                          </Typography>

                          <div className="instruction-text">{instruction}</div>
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </TextAccordion>
            </div>
          </GmailConnectWrapper>
        )}
    </div>
  );

  const outlookIntegration = (
    <div className="provider-box">
      <div className={`icon-logo-container email`}>
        <img
          src={OutlookLogo}
          alt={'outlook'}
          className={`connection-image outlook`}
        />
      </div>
      <div className={`connection-label`}>
        <Typography
          fontSize={'16px'}
          fontWeight={600}
          lineHeight={'24px'}
          color={colors.black}
        >
          Outlook
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={500}
          lineHeight={'18px'}
          color={
            !isLoadingEmail && emailIntegrated
              ? colors.darkGreen
              : colors.inactiveHover
          }
        >
          {emailIntegrated && !isLoadingEmail && 'Connected'}
          {emailNotIntegrated && !isLoadingEmail && 'Login required'}
        </Typography>
      </div>
      {/* Connect */}
      {!isLoadingEmail && emailNotIntegrated ? (
        <div
          className={'icons-buttons-container pointer not-connected'}
          onClick={() => outlookLogin()}
        >
          <div className="connection-status">
            <Typography fontSize={'14px'} fontWeight={500}>
              Connect
            </Typography>
          </div>
        </div>
      ) : (
        <div className="connected-email-buttons-wrapper">
          <Tooltip title="Disconnect" arrow placement="top">
            <div
              className="email-icon-button pointer"
              onClick={() => {
                disconnectOutlookIntegration();
              }}
            >
              <CloseMIcon
                className="check-icon not-success-icon"
                color={colors.white}
              />
            </div>
          </Tooltip>
          <div className={'icons-buttons-container connected'}>
            <div>
              <Typography fontSize={'14px'} fontWeight={500}>
                Connected
              </Typography>
            </div>
            <CheckMarkIcon color={colors.callScheduled} />
          </div>
        </div>
      )}
    </div>
  );

  const [showCustomEmailSettingsModal, setShowCustomEmailSettingsModal] =
    useState(false);

  const handleOpenCustomEmailSettingsModal = () => {
    setShowCustomEmailSettingsModal(true);
  };

  const customIntegration = (
    <div className="provider-box">
      <div className={`icon-logo-container email`}>
        <IconCustomEmailProvider className={`connection-image custom`} />
      </div>
      <div className={`connection-label`}>
        <Typography
          fontSize={'16px'}
          fontWeight={600}
          lineHeight={'24px'}
          color={colors.black}
        >
          Other email provider (SMTP/IMAP)
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={500}
          lineHeight={'18px'}
          color={
            !isLoadingEmail && emailIntegrated
              ? colors.darkGreen
              : colors.inactiveHover
          }
        >
          {emailIntegrated && !isLoadingEmail && 'Connected'}
          {emailNotIntegrated && !isLoadingEmail && 'Login required'}
        </Typography>
      </div>
      {/* Connect */}
      {!isLoadingEmail && emailNotIntegrated ? (
        <div
          className={'icons-buttons-container pointer not-connected'}
          onClick={handleOpenCustomEmailSettingsModal}
        >
          <div className="connection-status">
            <Typography fontSize={'14px'} fontWeight={500}>
              Connect
            </Typography>
          </div>
        </div>
      ) : (
        <div className="connected-email-buttons-wrapper">
          <Tooltip title="Disconnect" arrow placement="top">
            <div
              className="email-icon-button pointer"
              onClick={() => {
                disconnectCustomEmailProviderIntegration();
              }}
            >
              <CloseMIcon
                className="check-icon not-success-icon"
                color={colors.white}
              />
            </div>
          </Tooltip>
          <Tooltip title="Edit settings" arrow placement="top">
            <div
              className="email-icon-button pointer"
              onClick={handleOpenCustomEmailSettingsModal}
            >
              <EditIcon
                className="check-icon success-icon"
                color={colors.white}
              />
            </div>
          </Tooltip>
          <div className={'icons-buttons-container connected'}>
            <div>
              <Typography fontSize={'14px'} fontWeight={500}>
                Connected
              </Typography>
            </div>
            <CheckMarkIcon color={colors.callScheduled} />
          </div>
        </div>
      )}
    </div>
  );

  const linkedinChromeExtension = (
    <div
      className="provider-box"
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 8px 8px 8px',
      }}
    >
      <div
        className="provider-box-no-border"
        style={{
          display: 'flex',
        }}
      >
        <div className="icon-logo-container">
          <img
            src={LinkedinLogo}
            alt={'Linkedin'}
            className={`connection-image linkedin`}
          />
        </div>
        <div className={`connection-label`}>
          <Typography
            fontSize={'16px'}
            fontWeight={600}
            lineHeight={'24px'}
            color={colors.black}
          >
            Chrome extension
          </Typography>
          <Typography
            fontSize={'14px'}
            fontWeight={500}
            lineHeight={'18px'}
            color={
              extensionStatus?.status ===
              CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE
                ? colors.darkGreen
                : colors.inactiveHover
            }
          >
            {extensionStatus?.status ===
              CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE &&
              'Installed'}
            {extensionStatus?.status ===
              CronoExtensionStatus.INSTALLED_WITHOUT_LINKEDIN_COOKIE &&
              'LinkedIn not open'}
            {extensionStatus?.status ===
              CronoExtensionStatus.NOT_CHROME_BASED_BROWSER &&
              'Use Chrome to integrate'}
            {extensionStatus?.status === CronoExtensionStatus.NOT_INSTALLED &&
              'Install LinkedIn'}
          </Typography>
        </div>
        <div
          className={`icons-buttons-container ${
            extensionStatus?.status ===
            CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE
              ? 'connected'
              : 'not-connected'
          }`}
        >
          {extensionStatus?.status ===
          CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE ? (
            <div className="connection-status">
              <Typography fontSize={'14px'} fontWeight={500}>
                Installed
              </Typography>
              <CheckMarkIcon color={colors.callScheduled} />
            </div>
          ) : (
            <div
              className="connection-status pointer"
              onClick={() => linkedinLogin()}
            >
              <Typography fontSize={'14px'} fontWeight={500}>
                Install
              </Typography>
            </div>
          )}
        </div>
      </div>
      {extensionStatus?.status ===
      CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE ? (
        <div
          className="sales-nav"
          style={{
            filter:
              hasSalesNav || user?.userPreferences?.hasSalesNav
                ? 'grayscale(0)'
                : 'grayscale(1)',
          }}
        >
          <div style={{ display: 'flex', gap: '12px', width: '100%' }}>
            <label
              style={{ height: 'fit-content' }}
              onClick={(ev) => {
                if (!hasSalesNav) {
                  ev.stopPropagation();
                  ev.preventDefault();
                  handleShowErrorNormalUserTriesToEnableSalesNav();
                }
              }}
            >
              <Checkbox
                checked={checkSalesNav}
                disabled={!hasSalesNav}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  setCheckSalesNav((prev) => !prev);
                }}
                icon={
                  <IconUnchecked
                    color={!hasSalesNav ? colors.grey4 : colors.grey3}
                  />
                }
                checkedIcon={<CheckedIcon />}
                color="secondary"
                sx={{
                  backgroundColor: colors.white,
                  padding: 'inherit',
                  margin: 'inherit',
                  '&:hover': {
                    backgroundColor: colors.grey4,
                  },
                  '&.Mui-checked': {
                    '&:hover': {
                      opacity: 0.7,
                    },
                  },
                  marginRight: 1,
                  marginLeft: 1,
                }}
              />
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
              }}
            >
              <Typography fontSize={'14px'}>
                I’m a SalesNavigator user
              </Typography>
              <Typography fontSize={'12px'} color={colors.grey11} width="250px">
                Using sales navigator, you can automate up to{' '}
                {Constants.maxAutomaticDailySalesNav} LinkedIn tasks daily
              </Typography>
            </div>
          </div>
          <div
            className="sales-nav-daily-limit"
            style={{
              backgroundColor:
                !checkSalesNav &&
                (!hasSalesNav || !user?.userPreferences?.hasSalesNav)
                  ? colors.grey6
                  : undefined,
            }}
          >
            <Typography fontSize={'12px'}>
              Daily limit for task automation:
            </Typography>
            <Tooltip
              title={
                !checkSalesNav
                  ? 'You need a Sales navigator license to increase this limit'
                  : null
              }
              enterDelay={400}
              enterNextDelay={400}
              arrow
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                  width: '100%',
                }}
              >
                <Typography fontSize={'12px'}>{sliderLimitValue}</Typography>
                <Slider
                  disabled={!checkSalesNav}
                  style={{ minWidth: '120px' }}
                  sx={{
                    '& .MuiSlider-valueLabel': {
                      background: 'unset',
                      padding: '12px',
                      borderRadius: '4px',
                      backgroundColor: colors.black,
                      display: 'flex',
                      width: '210px',
                      whiteSpace: 'unset',
                      height: '50px',
                      textAlign: 'center',
                    },
                  }}
                  size="small"
                  aria-label="Restricted values"
                  defaultValue={sliderLimitValue}
                  step={10}
                  min={50}
                  max={Constants.maxAutomaticDailySalesNav}
                  value={sliderLimitValue}
                  onChange={(ev, value) => setSliderLimitValue(value as number)}
                  onChangeCommitted={handlePatchAccountLimit}
                />
                <Typography fontSize={'12px'}>
                  {Constants.maxAutomaticDailySalesNav}
                </Typography>
              </div>
            </Tooltip>
          </div>
        </div>
      ) : null}
    </div>
  );

  const aircallIntegration = (
    <div className="provider-box">
      <div className="icon-logo-container">
        <img
          src={AircallLogo}
          alt={'Aircall'}
          className={`connection-image aircall`}
        />
      </div>
      <div className={`connection-label`}>
        <Typography
          fontSize={'16px'}
          fontWeight={600}
          lineHeight={'24px'}
          color={colors.black}
        >
          Aircall
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={500}
          lineHeight={'18px'}
          color={
            !isLoadingCall && callIntegrated
              ? colors.darkGreen
              : colors.inactiveHover
          }
        >
          {callIntegrated && !isLoadingCall && 'Connected'}
          {callNotIntegrated && !isLoadingCall && 'Not Connected'}
        </Typography>
      </div>
      <div
        className={`icons-buttons-container ${
          !isLoadingCall && callIntegrated ? 'connected' : 'not-connected'
        }`}
      >
        {!isLoadingCall && callIntegrated ? (
          <div className="connection-status">
            <Typography fontSize={'14px'} fontWeight={500}>
              Connected
            </Typography>
            <CheckMarkIcon color={colors.callScheduled} />
          </div>
        ) : (
          <div
            className="connection-status pointer"
            onClick={() => aircallLogin()}
          >
            <Typography fontSize={'14px'} fontWeight={500}>
              Connect
            </Typography>
          </div>
        )}
      </div>
    </div>
  );

  const showIntegrateCRM = useMemo(() => {
    return (
      !activeMigration?.data?.data &&
      user?.integrationType === IntegrationType.CRONO &&
      (user.subscriptionType === SubscriptionPlanType.PRO ||
        user?.subscriptionType === SubscriptionPlanType.ULTRA)
    );
  }, [user]);

  const [selectedCRM, setSelectedCRM] = useState<IntegrationType>(
    IntegrationType.HUBSPOT,
  );

  const renderIntegrationMenuItem = (item: IntegrationMenuItem) => {
    return (
      <MenuItem value={item.name}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={`menu-item-icon ${item.name}`}
        >
          {item.src}
        </div>

        <Typography fontSize={12} lineHeight={'16px'} fontWeight={500}>
          {item.name}
        </Typography>
      </MenuItem>
    );
  };

  const renderValueCRMSelect = (
    value: IntegrationType | EmailIntegrationType | null | 'None',
  ) => {
    const item = [
      ...crmIntegrationsMenuItems,
      ...emailIntegrationsMenuItems,
    ].find((el) => el.name === value);
    return item ? (
      <div className={`value-select`}>
        <div
          className={classNames(
            `value-select-icon ${item.name.toLocaleLowerCase()}`,
            {
              'icon-none': item.name === 'None',
            },
          )}
        >
          {item.src}
        </div>
        <Typography fontSize={12} lineHeight={'16px'} fontWeight={500}>
          {item.label}
        </Typography>
      </div>
    ) : (
      <div />
    );
  };

  const {
    mutate: startMigration,
    isLoading: startingMigration,
    error: errorStartingMigration,
    isSuccess: migrationStartedSuccessfully,
  } = useStartMigration();

  const { openModal } = useConfirmModal();

  const handleStartMigration = () => {
    openModal({
      title: 'Would you like to start migration right now?',
      text: 'Migration may take several hours and all users will be unlogined during import period. We synchronize all data with your CRM. After integration, all data from the CRM will be available to you in Crono. ',
      cancelText: 'Cancel',
      confirmText: 'Start',
      cancelFunction: () => {},
      confirmFunction: () => confirmStartMigration(),
    });
  };

  const confirmStartMigration = () => {
    if (!isSubscriptionManager) return;
    startMigration(selectedCRM);
    if (analytics) {
      analytics.track('crm-integration-migration', {
        crm: selectedCRM,
      });
    }
  };

  //If successfully started the migration call the auth me to get the correct data for the user
  useEffect(() => {
    if (migrationStartedSuccessfully) {
      refreshUser();
    }
  }, [migrationStartedSuccessfully]);

  useConditionalSnackBar([
    {
      condition: !!copyText,
      message: 'Copied to clipboard',
      severity: 'success',
    },
    {
      condition: !!scopeNotAcceptedError,
      message: 'Accept all options to proceed with the integration',
      severity: 'info',
    },
    {
      condition: !!errorStartingMigration,
      message:
        getError(errorStartingMigration) ??
        'Error while starting the migration',
      severity: 'error',
    },
    {
      condition: !!migrationStartedSuccessfully,
      message: 'Migration started',
      severity: 'success',
    },
    {
      condition: showErrorNormalUserTriesToEnableSalesNav,
      message:
        'You need a Sales navigator license to increase LinkedIn daily automations limit',
      severity: 'error',
    },
    {
      condition: !!customEmailProviderDisconnected,
      message: 'Email provider disconnected',
      severity: 'success',
    },
    {
      condition: !!errorDisconnectingCustomEmailProviderIntegration,
      message:
        getError(errorDisconnectingCustomEmailProviderIntegration ?? null) ??
        'Error disconnecting email provider',
      severity: 'error',
    },
  ]);

  const checkEmailIntegration = useCallback(
    (emailIntegration: EmailIntegrationType) => {
      return (
        user?.otherSettings?.hasEmail &&
        user.company?.emailIntegrationType === emailIntegration
      );
    },
    [user],
  );

  const isIntegrationAvailable = useMemo(() => {
    if (!user || !user.otherSettings) return false;

    return !user.otherSettings.hasEmail && highestRole === 'Manager';
  }, [user, highestRole]);

  const { mutateAsync } = useEditOtherSettings();

  const [selectedEmail, setSelectedEmail] = useState<
    EmailIntegrationType | 'None'
  >('None');

  const [providerSelectInProgress, setProviderSelectInProgress] =
    useState<boolean>(false);

  const updateEmailIntegration = async (
    emailIntegrationType: EmailIntegrationType,
  ) => {
    const otherSettingsInputs: OtherSettingsInputs = {
      hasEmail: true,
      emailIntegrationType,
    };

    try {
      await mutateAsync(otherSettingsInputs);
      refreshUser();
    } catch (error) {
      /* empty */
    }
  };

  return (
    <ConnectWrapper>
      {showCustomEmailSettingsModal && (
        <CustomEmailProviderSettingsModal
          close={() => setShowCustomEmailSettingsModal(false)}
          onSuccess={() => {
            verifyIntegration();
            setShowCustomEmailSettingsModal(false);
          }}
        />
      )}
      <InstallLinkedinExtensionModal
        open={openInstallLinkedinExtension}
        setOpen={setOpenInstallLinkedinExtension}
      />
      <div className="connect-card">
        <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
          Integrations settings
        </Typography>
        <Typography fontSize={16} lineHeight={'24px'} color={colors.grey11}>
          Connect your tools to create a seamless prospecting experience
        </Typography>
      </div>
      <div className="providers">
        {/* CRMS */}
        {saleforceIntegration}
        {hubspotIntegration}
        {pipedriveIntegration}

        {/* Emails */}
        {checkEmailIntegration(EmailIntegrationType.GMAIL) && gmailIntegration}
        {checkEmailIntegration(EmailIntegrationType.OUTLOOK) &&
          outlookIntegration}
        {checkEmailIntegration(EmailIntegrationType.CUSTOM) &&
          customIntegration}

        {isIntegrationAvailable && (
          <div className="email-provider-wrapper">
            {providerSelectInProgress ? (
              <div className="email-provider-wrapper__select">
                <div className="email-provider__left">
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'16px'}
                    fontWeight={500}
                    color={colors.grey11}
                    marginBottom={'2px'}
                  >
                    Email provider
                  </Typography>

                  <Select
                    value={selectedEmail ?? -1}
                    onChange={(ev) => {
                      setSelectedEmail(ev.target.value as EmailIntegrationType);
                    }}
                    className="select"
                    MenuProps={{
                      style: { zIndex: 35001 },
                      PaperProps: customSelectPaperProps,
                    }}
                    sx={{
                      height: '40px',
                    }}
                    renderValue={(
                      value: EmailIntegrationType | -1 | 'None',
                    ) => {
                      if (value === -1) {
                        return (
                          <Typography color={colors.grey11}>
                            Choose one
                          </Typography>
                        );
                      }

                      return renderValueCRMSelect(value);
                    }}
                  >
                    {emailIntegrationsMenuItems.map((el) =>
                      renderIntegrationMenuItem(el),
                    )}
                  </Select>
                </div>

                <div className="email-provider__buttons">
                  <div
                    onClick={() => setProviderSelectInProgress(false)}
                    className="email-provider__button email-provider__button--close"
                  >
                    <CloseMIcon />
                  </div>

                  <div
                    onClick={() => {
                      if (selectedEmail === 'None') return;
                      updateEmailIntegration(selectedEmail);
                    }}
                    className="email-provider__button email-provider__button--check"
                  >
                    <CheckMarkIcon color={colors.darkGreen} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="email-provider">
                <div className="email-provider__logo-container">
                  <IconCrono className="email-provider__logo" />
                </div>

                <div className="email-provider__info">
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    lineHeight={'24px'}
                    color={colors.black}
                    marginBottom={'2px'}
                  >
                    Email provider is not selected
                  </Typography>

                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={'18px'}
                    color={colors.grey11}
                  >
                    To enable the email integration, select your provider
                  </Typography>
                </div>

                <div
                  onClick={() =>
                    setProviderSelectInProgress(!providerSelectInProgress)
                  }
                  className="email-provider__select"
                >
                  Select
                </div>
              </div>
            )}
          </div>
        )}

        {/* Other integrations/extensions */}
        {user?.otherSettings?.hasLinkedin && linkedinChromeExtension}
        {user &&
          user.userRoles?.includes(Role.MANAGER) &&
          user.company?.callIntegrationType === CallIntegrationType.AIRCALL &&
          aircallIntegration}
      </div>
      {showIntegrateCRM && isSubscriptionManager && (
        <div style={{ maxWidth: 545, marginTop: 20 }}>
          <div className="integration-select">
            <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
              Would you like to integrate your CRM?
            </Typography>
            <Typography>
              <Typography
                fontSize={16}
                lineHeight={'24px'}
                color={colors.grey11}
              >
                If you want to sync with CRM you should choose the one you use.
              </Typography>
              {!showCrmOptions && (
                <Typography
                  fontSize={16}
                  lineHeight={'24px'}
                  color={colors.grey11}
                >
                  To enable the integration with your CRM{' '}
                  <span
                    className="integrate-crm"
                    onClick={() => setShowCrmOptions(true)}
                  >
                    Click here
                  </span>
                </Typography>
              )}
            </Typography>
            {showCrmOptions && (
              <>
                <Typography fontSize={'14px'} fontWeight={600}>
                  CRM integration
                </Typography>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
                >
                  <Select
                    value={selectedCRM}
                    onChange={(ev) => {
                      setSelectedCRM(ev.target.value as IntegrationType);
                    }}
                    className="select"
                    sx={{
                      height: '40px',
                      width: '100%',
                    }}
                    MenuProps={{
                      style: { zIndex: 35001 },
                      PaperProps: customSelectPaperProps,
                    }}
                    renderValue={(value) => {
                      return renderValueCRMSelect(value);
                    }}
                    // disabled={migrationStartedSuccessfully}
                  >
                    {crmIntegrationsMenuItems
                      .filter((el) => {
                        if (
                          user?.subscriptionType !== SubscriptionPlanType.ULTRA
                        ) {
                          return el.name !== IntegrationType.SALESFORCE;
                        }
                        return true;
                      })
                      .map((el) => {
                        return renderIntegrationMenuItem(el);
                      })}
                  </Select>
                  {/*I show this only if the integration has not been done already */}
                  {!migrationStartedSuccessfully &&
                    (startingMigration ? (
                      <CircularProgress />
                    ) : (
                      <Tooltip
                        title={
                          !isSubscriptionManager
                            ? 'Only subscription manager can start the migration'
                            : null
                        }
                        enterDelay={800}
                        enterNextDelay={800}
                        arrow
                      >
                        <MainPrimaryButton
                          style={{
                            margin: 0,
                            height: 40,
                            width: 120,
                            fontSize: 14,
                            fontWeight: 500,
                            lineHeight: '18px',
                          }}
                          disabled={!isSubscriptionManager}
                          onClick={handleStartMigration}
                        >
                          Integrate
                        </MainPrimaryButton>
                      </Tooltip>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </ConnectWrapper>
  );
};

export default Integrations;
