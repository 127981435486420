import NotificationType from 'crono-fe-common/types/enums/notificationType';
import { Notification } from 'crono-fe-common/types/notification';

export const getNotificationText = (
  notification: Notification,
  callback: any,
) => {
  const notificationProspect = (
    <b className="prospect-name" onClick={callback}>
      {notification.prospect?.name}
    </b>
  );
  switch (notification.type) {
    case NotificationType.EMAIL_ANSWERED:
      return <span>{notificationProspect} replied to your email</span>;
    case NotificationType.LINKEDIN_ANSWERED:
      return (
        <span>{notificationProspect} replied to your LinkedIn message</span>
      );
    case NotificationType.LINKEDIN_INVITATION_ACCEPTED:
      return (
        <span>{notificationProspect} accepted your LinkedIn invitation</span>
      );
    case NotificationType.EMAIL_BOUNCED:
      return <span>Email sent to {notificationProspect} was bounced</span>;
    case NotificationType.EMAIL_LINK_CLICKED:
      return (
        <span>
          {notificationProspect} clicked on your email link{' '}
          {notification.data
            ? `${notification.data} ${
                notification.data === '1' ? 'time' : 'times'
              }`
            : ''}
        </span>
      );
    case NotificationType.EMAIL_OPENED:
      return (
        <span>
          {notificationProspect} opened your email{' '}
          {notification.data
            ? `${notification.data} ${
                notification.data === '1' ? 'time' : 'times'
              }`
            : ''}
        </span>
      );
    case NotificationType.LINKEDIN_IN_MAIL_ANSWERED:
      return <span>{notificationProspect} replied to your inMail</span>;
    default:
      return '';
  }
};
