import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ManualTasksResumeWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${colors.black}66;
  z-index: 2;

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-height: 90%;
    background: ${colors.white};
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    overflow: auto;
    position: relative;
  }
  .manual-task-resume-container {
    padding-inline: 12px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    overflow: auto;
  }
  .prospect-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .prospect-name-title-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: 16px;
  }
  .left-side-prospect-info {
    display: flex;
    margin-right: 12;
    overflow: hidden;
    max-width: 50%;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right-side-sequence-handle {
    display: flex;
    align-items: center;
  }
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 4;
    column-gap: 4;
  }
  .account-avatar {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    font-size: 20px !important;
    border: 1px solid ${colors.grey4Hover};
  }
  .white {
    background: ${colors.white} !important;
  }
  .close-button {
    align-self: flex-end;
    cursor: pointer;
  }

  .manual-task-list {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-right: 2px;
    justify-content: flex-start;
    overflow: hidden auto;
    row-gap: 12px;
  }
`;
