import { Input } from 'crono-fe-common/components/FormComponents';
import { useAuth } from 'context/auth';
import { FC } from 'react';
import { GeneralWrapper } from '../styles';
import { TextField, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { fullname } from 'utils/fe-utils';

const General: FC = () => {
  const { user } = useAuth();

  return (
    <GeneralWrapper>
      <div className="user-form">
        <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
          Personal info
        </Typography>
        <div className="form-control">
          <Typography
            color={colors.primary}
            fontSize={12}
            fontWeight={500}
            lineHeight={'16px'}
          >
            Full Name:
          </Typography>
          <TextField
            style={{ width: '250px' }}
            variant="standard"
            value={fullname(user?.firstName, user?.lastName)}
            disabled
            sx={{
              '& .Mui-disabled:before': {
                borderBottomStyle: 'solid !important',
              },
            }}
          />
        </div>

        <div className="form-control">
          <Typography
            color={colors.primary}
            fontSize={12}
            fontWeight={500}
            lineHeight={'16px'}
          >
            Email:
          </Typography>
          <TextField
            style={{ width: '250px' }}
            variant="standard"
            value={user?.email}
            disabled
            sx={{
              '& .Mui-disabled:before': {
                borderBottomStyle: 'solid !important',
              },
            }}
          />
        </div>
        <Typography
          fontSize={24}
          fontWeight={700}
          lineHeight={'30px'}
          paddingTop={'24px'}
        >
          Company info
        </Typography>
        <div className="form-control">
          <Typography
            color={colors.primary}
            fontSize={12}
            fontWeight={500}
            lineHeight={'16px'}
          >
            Company Name:
          </Typography>
          <TextField
            style={{ width: '250px' }}
            variant="standard"
            value={user?.company?.name}
            disabled
            sx={{
              '& .Mui-disabled:before': {
                borderBottomStyle: 'solid !important',
              },
            }}
          />
        </div>
      </div>
    </GeneralWrapper>
  );
};

export default General;
