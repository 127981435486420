import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

type AnalyticsWrapperProp = {
  hasDeal?: boolean;
};

export const TaskCardWrapper = styled.div<AnalyticsWrapperProp>`
  border-radius: 8px;
  border: 1px solid ${colors.grey4};
  border-bottom: ${(props) =>
    props.hasDeal ? `2px solid ${colors.intenseBlue};` : undefined};
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;

  .inner-task-card {
    padding: 8px 12px 12px 12px;
    width: 100%;
  }

  .type-icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    border-radius: 999px;
    border: 1px solid ${colors.grey4};
    padding: 4px;

    cursor: pointer;
  }
  .subtype-icon {
    background: ${colors.black};
  }
  .action-half {
    min-width: 114px;
    display: flex;
    flex-direction: column;
    margin-top: -24px;
  }
  .more-menu-button {
    width: fit-content;
    height: fit-content;
    // align-self: flex-start;
    padding: 0px;
    // margin-right: -8px;
  }

  .progress {
    // cursor: pointer;
    display: flex;
    flex-direction: column;
  }
  .sequence-type-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
  }
  .auto-label {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: ${colors.blueHover};
    background: ${colors.grey6};
    padding: 2px 4px;
    gap: 10px;
    border-radius: 8px;
  }

  .type-icon {
    width: 30px;
    height: 30px;
    border-radius: 16px;
    padding: 7px;
    // cursor: pointer;
  }
  .call {
    background-color: ${colors.primaryLight};
  }
  .email {
    background-color: ${colors.inactiveLight};
  }
  .in-mail {
    background-color: ${colors.purpleLight};
    padding: 5px;
  }
  .linkedin-icon {
    background-color: ${colors.blueLight};
  }
  .type-icon:hover {
    // background: ${colors.grey4};
  }
  .subtype-icon {
    background: ${colors.black} !important;
  }
  .pointer {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  .task-opportunity-badge {
    position: absolute;
    bottom: -1px;
    right: -1px;
    background-color: ${colors.intenseBlue};
    color: ${colors.white};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    height: 15px;
  }
`;

export const TooltipMoreMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  .option-button {
    border-radius: 8px;
    cursor: pointer;
    text-transform: unset !important;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px !important;
    &:hover {
      background: ${colors.primaryLight};
      color: ${colors.primary};
    }
  }
`;
