import React, { SetStateAction } from 'react';
import { checkIfValidLinkedinCompanyUrl } from 'utils/fe-utils';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  numberOfEmployees: Yup.string(),
  annualRevenue: Yup.number().positive(),
  website: Yup.string(),
  topTier: Yup.boolean(),
  linkedin: Yup.string().test((val) => {
    if (!val) return true;
    return checkIfValidLinkedinCompanyUrl(val);
  }),
  industry: Yup.string(),
});

export interface IExternalValueInsert {
  externalPropertyId: number;
  value: string;
  isTag: boolean;
}

export interface AccountInputs {
  name: string;
  numberOfEmployees: number | null;
  annualRevenue: string;
  website: string;
  topTier: boolean;
  linkedin: string;
  industry: string;
  phone: string;
  currentSolution: string;
  country: string;
  linkedinNumericId?: string;
}

export const initialValues = {
  name: '',
  numberOfEmployees: '',
  annualRevenue: '',
  website: '',
  topTier: false,
  linkedin: '',
  industry: '',
  phone: '',
};

export interface InsertAccountProps {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  addAccountInitialValues?: AccountInputs;
  publicId?: string;
  handleBack?: () => void;
}
