import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import {
  getParams,
  ReportsFilters,
} from 'crono-fe-common/types/reportsFilters';
import { LogLinkedin } from 'crono-fe-common/types/logLinkedin';

export default function useLinkedinsReport(
  reportsFilters: ReportsFilters,
  enabled: boolean,
) {
  // todo verify if correct using useQuery for post request and add filters in hook
  const params = getParams(reportsFilters);

  const request: Request = {
    url: ENDPOINTS.events.linkedinReports,
    config: {
      method: 'post',
      data: params,
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.events.linkedinReports, params],
    queryFn: () => client(request),
    enabled: enabled,
  };

  const { data, ...rest } = useQuery<
    ResponsePaginated<LogLinkedin[]>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
