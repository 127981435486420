import { Typography } from '@mui/material';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { colors } from 'crono-fe-common/theme';
import React, { useState } from 'react';

interface IProps {
  onClick: () => void;
}

const SearchNewContactsComponentTable = ({ onClick }: IProps) => {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <SearchIcon
        className="icon-contact-column"
        color={hover ? colors.darkGreenHover : colors.darkGreen}
      />
      <Typography
        variant="body1"
        fontSize={14}
        fontWeight={500}
        color={hover ? colors.darkGreenHover : colors.darkGreen}
      >
        Find new
      </Typography>
    </div>
  );
};

export default SearchNewContactsComponentTable;
