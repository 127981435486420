import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SubscriptionProductDTO } from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';

export default function useGetSubscriptionProducts() {
  const request: Request = {
    url: ENDPOINTS.subscription.products.main,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.subscription.products.main],
    queryFn: () => client(request),
    staleTime: 24 * 60 * 60 * 1000,
  };

  const { data, ...rest } = useQuery<
    Response<SubscriptionProductDTO[]>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
