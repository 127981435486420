import React from 'react';
import { Task } from 'crono-fe-common/types/task';
import { Grid, Typography } from '@mui/material';
import { renderActivityIcon } from './utilities';
import { Prospect } from 'crono-fe-common/types/prospect';
import { Account } from 'crono-fe-common/types/account';

interface IProps {
  data: Prospect | Account;
  justifyContent?: string;
  className?: string;
  color?: string;
}

interface ISubtype {
  element: any;
  count: number;
}

const ActivitiesFromTotals = ({
  data,
  justifyContent,
  className,
  color,
}: IProps) => {
  const activities: ISubtype[] = [
    {
      element: renderActivityIcon('call', color, className),
      count: data.totalCall ?? 0,
    },
    {
      element: renderActivityIcon('email', color, className),
      count: data.totalEmail ?? 0,
    },
    {
      element: renderActivityIcon('linkedin', color, className),
      count: data.totalLinkedin ?? 0,
    },
  ];
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={justifyContent || 'center'}
      className={className ?? ''}
    >
      {activities.map((activity, index) => {
        return (
          <Grid key={index} item xs={4} alignItems={'center'} display={'flex'}>
            {activity.element}
            <Typography variant="body1" fontWeight={500}>
              {activity.count}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ActivitiesFromTotals;
