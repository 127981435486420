import {
  TableRow,
  TableCell,
  AvatarGroup,
  Checkbox,
  Avatar,
  Tooltip,
  Typography,
  Badge,
} from '@mui/material';
import TagsBoxSingleTag from 'components/TagsBoxShowAll/tagsBoxSingleTag';
import { useWindow } from 'context/sideTab';
import { WindowTab } from 'context/sideTab/types';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { Account } from 'crono-fe-common/types/account';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import useEditAccount from 'hooks/services/account/useEditAccount';
import ActivitiesFromTotals from 'pages/dashboard/activitiesFromTotals';
import { useSelectedAccount } from 'pages/home/context/account';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getImageFromUrl,
  stringAvatarAccount,
  stringAvatarProspect,
} from 'utils/fe-utils';
import SearchNewContactsComponentTable from '../searchNewContactsComponentTable';
import { colors } from 'crono-fe-common/theme';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import IconNotOwned from 'crono-fe-common/icons/Icon-Not-Owned';
import { ReactComponent as ContactsIcon } from 'crono-fe-common/icons/Contacts.svg';
import { ReactComponent as LightBulbIcon } from '../../../../assets/images/Light-bulb.svg';
import CronoStatus from 'components/CronoStatus';
import { ExternalValue } from 'crono-fe-common/types/externalValue';
import {
  getOptionsMultiselect,
  setOptionsMultiselect,
} from 'crono-fe-common/utils';
import { User } from 'crono-fe-common/types/user';
import { useAuth } from 'context/auth';

interface IProps {
  account: Account;
  externalProperties: ExternalProperty[] | undefined;
  onlyOwned: boolean;
  selected: boolean;
  handleSelected: (accountId: string) => void;
  handleOpenFindNew: (accountId: string) => void;
  disableCheckbox?: boolean;
}

const AccountRow = ({
  account,
  externalProperties,
  onlyOwned,
  selected,
  handleSelected,
  handleOpenFindNew,
  disableCheckbox,
}: IProps) => {
  // I get the function to open the window when an account is selected
  const { openWindow } = useWindow();
  const { user } = useAuth();

  const { selectedAccountId, setSelectedAccountId } = useSelectedAccount();

  const { mutate: patchAccount } = useEditAccount();

  // Called when an account is selected
  const handleClick = (objectId: string, tab?: WindowTab) => {
    setSelectedAccountId(objectId);
    openWindow({
      windowType: 'account',
      tab: tab ?? 'account',
      forceReload: Math.random() * 1000000,
    });
  };

  const [backgroundColorSelected, setBackgroundColorSelected] =
    useState<string>(colors.white);

  useEffect(() => {
    if (selectedAccountId === account.objectId) {
      setBackgroundColorSelected(`${colors.grey55}`);
    } else {
      setBackgroundColorSelected(colors.white);
    }
  }, [selectedAccountId]);

  const handleClickSearchNewContacts = () => {
    handleOpenFindNew(account.objectId);
  };

  const handlePatchAccount = (
    option: string | null,
    externalPropertyId: number,
  ) => {
    if (!account) return;

    patchAccount({
      accountId: account.objectId,
      externalValues: {
        [externalPropertyId]: option,
      },
    });
  };

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
  ) => {
    const externalPropertyId = externalProperty.id;

    const externalValue = account.externalValues?.find(
      (externalValue) =>
        externalValue.externalPropertyId === externalProperty.id,
    );
    if (option === null) {
      handlePatchAccount(null, externalPropertyId);
      return;
    }
    if (externalProperty?.valueType === 'List') {
      handlePatchAccount(
        option === externalValue?.value ? null : option,
        externalPropertyId,
      );
    } else if (externalProperty?.valueType === 'MultiSelect') {
      const options = getOptionsMultiselect(externalValue?.value ?? null);
      if (options.includes(option ?? '')) {
        options.splice(options.indexOf(option ?? ''), 1);
      } else {
        options.push(option ?? '');
      }
      handlePatchAccount(
        options.length ? setOptionsMultiselect(options) : null,
        externalPropertyId,
      );
    }
  };

  const showTableHead = useCallback(
    (columnId: string) => {
      if (!user) return false;
      if (!user.userPreferences.listAccountColumns) return true;
      if (user.userPreferences.listAccountColumns.includes(columnId))
        return true;
    },
    [user],
  );

  return (
    <TableRow
      style={{ background: backgroundColorSelected }}
      onMouseEnter={() => {
        if (selectedAccountId !== account.objectId) {
          setBackgroundColorSelected(colors.tableHover);
        }
      }}
      onMouseLeave={() => {
        if (selectedAccountId !== account.objectId) {
          setBackgroundColorSelected(colors.white);
        }
      }}
    >
      <TableCell
        onClick={() => handleClick(account.objectId)}
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 8,
          width: '12%',
          minWidth: 270,
          background: backgroundColorSelected,
          borderRight: '1px solid #ebebeb',
          cursor: 'pointer',
          paddingBlock: 8,
          paddingRight: 0,
          paddingLeft: 4,
          borderLeft:
            selectedAccountId === account.objectId
              ? `4px solid ${colors.black}`
              : 'none',
        }}
        className="table-row-hover"
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <label>
            <Checkbox
              checked={selected}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleSelected(account.objectId);
              }}
              disabled={disableCheckbox}
              icon={
                <IconUnchecked
                  color={disableCheckbox ? colors.grey4 : colors.grey3}
                />
              }
              checkedIcon={<IconChecked color={colors.mainDark} />}
              color="secondary"
              sx={{
                padding: 'inherit',
                margin: 'inherit',
                '&:hover': {
                  backgroundColor: colors.grey4,
                },
                '&.Mui-checked': {
                  '&:hover': {
                    opacity: 0.7,
                  },
                },
                marginRight: 1,
                marginLeft: 1,
                // "&.MuiCheckbox-root": {
                //   color: colors.grey33 + " !important",
                // },
              }}
            />
          </label>
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                right: 6,
                top: 6,
                background: colors.nurture,
                height: '16px',
                width: '16px',
                minWidth: '16px',
                minHeight: '16px',
                padding: '1px',
                border: `1px solid ${colors.white}`,
              },
            }}
            badgeContent={
              !account.suggestions?.length ? null : (
                <LightBulbIcon
                  onClick={(ev: any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    handleClick(account.objectId, 'tasks');
                  }}
                />
              )
            }
          >
            <Avatar
              key={account.objectId}
              src={account.website && getImageFromUrl(account.website, null)}
              className={
                account.website ? 'account-avatar white' : 'account-avatar'
              }
            >
              {stringAvatarAccount(account.name || '')}
            </Avatar>
          </Badge>
          <Tooltip
            arrow
            title={account.name}
            enterDelay={800}
            enterNextDelay={800}
          >
            <span
              style={{
                width: !account.owned ? 180 : 210,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginLeft: 8,
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              {account.name}
            </span>
          </Tooltip>
          {!account.owned && (
            <Tooltip
              arrow
              title={`The owner is ${account.user}`}
              placement="top"
            >
              <span
                style={{
                  width: 20,
                  marginRight: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconNotOwned color={colors.black} />
              </span>
            </Tooltip>
          )}
        </span>
      </TableCell>

      {/*Status */}
      {showTableHead('status') && (
        <TableCell
          style={{
            paddingBlock: 8,
          }}
        >
          <CronoStatus
            type="account"
            currentActualStatus={account.actualStatus}
            objectToUpdate={account.objectId}
            currentExternalValues={account.externalValues}
            fixedWidth={true}
            disabled={!account.owned}
          />
        </TableCell>
      )}

      {/*Owner */}
      {!onlyOwned && showTableHead('owner') && (
        <TableCell
          style={{
            paddingBlock: 8,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                width: 110,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {account.user}
            </span>
          </span>
        </TableCell>
      )}

      {/*ExternalProperties */}
      {externalProperties?.map((externalProperty, index) => {
        // I get the externalValue of the account that matches the name of the externalProperty
        const externalValue = account.externalValues?.find(
          (externalValue) =>
            externalValue.externalPropertyId === externalProperty.id,
        );

        if (showTableHead(String(externalProperty.id))) {
          return (
            <TableCell
              key={index}
              style={{
                paddingBlock: 8,
                borderRight: `1px solid ${colors.grey444}`,
              }}
            >
              <TagsBoxSingleTag
                indexOfTag={index}
                isEditable={externalProperty.isEditable}
                option={externalValue?.value ?? null}
                tag={externalProperty}
                handleClickTag={(
                  option: string | null,
                  _externalProperty: ExternalProperty,
                  _index: number,
                ) => handleClickTag(option, externalProperty)}
                indexGeneralArray={index}
                showRemovable={true}
                showAsPlus={true}
                show2AndPlus={true}
              />
            </TableCell>
          );
        }

        return null;
      })}

      {/*Progress */}
      {/* <TableCell
          style={{
            paddingBlock: 8,
          borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          {(() => {
            const percentage = percentageValue(account.actualStatus);
            return (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  position: "relative"
                }}
              >
                <Typography variant="body2" fontWeight="500">
                  {percentage + "%"}
                </Typography>
                <Slider
                  value={percentage}
                  className="slider"
                  sx={{
                    position: "absolute",
                    left: 35,
                    "& .MuiSlider-track": {
                      color: colors.mainDark
                    },
                    "& .MuiSlider-rail": {
                      color: colors.grey44
                    }
                  }}
                />
              </div>
            );
          })()}
        </TableCell> */}

      {/*All contacts */}
      {showTableHead('allContacts') && (
        <TableCell
          style={{
            paddingBlock: 8,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          {account.prospects?.length === 0 ? (
            <SearchNewContactsComponentTable
              onClick={handleClickSearchNewContacts}
            />
          ) : (
            <div
              className="icon-contact-column-container"
              onClick={() => {
                handleClick(account.objectId, 'prospect');
              }}
            >
              <ContactsIcon className="icon-contact-column" />
              <Typography variant="body1" fontSize={16}>
                {account.prospects?.length}
              </Typography>
            </div>
          )}
        </TableCell>
      )}

      {/*In sequence */}
      {showTableHead('inStrategy') && (
        <TableCell
          style={{
            paddingBlock: 8,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <AvatarGroup
            max={3}
            style={{
              justifyContent: 'flex-end',
            }}
            sx={{
              '& .MuiAvatar-root': {
                background: '' + colors.primaryLight + ' !important',
                color: '' + colors.primary + ' !important',
                fontSize: '14px !important',
                size: '20px !important',
              },
            }}
          >
            {account.prospects
              ?.filter((prospect) => prospect.sequenceInstance?.active)
              ?.map((prospect, index) => {
                return (
                  <Avatar
                    key={index}
                    //src={require("./defaultImage.jpg")}
                    className="prospect-avatar"
                  >
                    {stringAvatarProspect(prospect)}
                  </Avatar>
                );
              })}
          </AvatarGroup>
        </TableCell>
      )}

      {/*Last activity */}
      {showTableHead('lastActivity') && (
        <TableCell
          style={{
            paddingBlock: 8,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          {account.lastActivityDate ? (
            <span style={{ display: 'flex' }}>
              {/*findLatesActivity(account.tasks || [])*/}
              {new Date(account.lastActivityDate).toLocaleDateString()}
            </span>
          ) : (
            '-'
          )}
        </TableCell>
      )}

      {/*Created Date */}
      {showTableHead('createdDate') && (
        <TableCell
          style={{
            paddingBlock: 8,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                width: 110,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {account.createdDate &&
                new Date(account.createdDate).toLocaleDateString()}
            </span>
          </span>
        </TableCell>
      )}

      {/*Activities */}
      {showTableHead('activities') && (
        <TableCell
          onClick={() => handleClick(account.objectId)}
          style={{
            cursor: 'pointer',
            paddingBlock: 8,
            borderRight: `1px solid ${colors.grey444}`,
          }}
        >
          <ActivitiesFromTotals
            data={account}
            className="activities-container"
            color={colors.grey11}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default AccountRow;
