import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const EmailBouncedIcon = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <g id="Icon/Bounced">
        <g id="Group">
          <g id="Group_2">
            <g id="Group 427320581">
              <path
                id="Path"
                d="M14.998 3.14111V7.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Path_2"
                d="M1 3.14111V10.1154C1 11.4042 2.04456 12.4488 3.33333 12.4488H6.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Path_3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.91267 7.20984L1.84389 4.53973C1.32511 4.26595 1 3.72773 1 3.14128V3.11561C1 2.25617 1.69611 1.56006 2.55556 1.56006H13.4188C14.2922 1.56006 15 2.26784 15 3.14128V3.15684C15 3.73395 14.6803 4.26439 14.1693 4.5335L9.08733 7.21061C8.40678 7.56761 7.59322 7.56761 6.91267 7.20984Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Group 427320579">
              <g id="Group 427320580">
                <path
                  id="Path_4"
                  d="M11.9837 11.7494V10.5801"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Path_5"
                  d="M11.9834 13.0395C11.9514 13.0395 11.9254 13.0655 11.9261 13.0975C11.9261 13.1295 11.9521 13.1555 11.9841 13.1555C12.0161 13.1555 12.0414 13.1295 12.0414 13.0975C12.0414 13.0655 12.0154 13.0395 11.9834 13.0395"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
        <circle
          id="Ellipse 916"
          cx="12"
          cy="12"
          r="3"
          stroke={color}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default EmailBouncedIcon;
