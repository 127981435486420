import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { SuggestionPreferences } from 'crono-fe-common/types/suggestionPreferences';

export default function useGetSuggestionPreferences() {
  const request: Request = {
    url: `${ENDPOINTS.suggestion.preferences}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.suggestion.preferences],
    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<
    Response<SuggestionPreferences>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
