import { CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ReactComponent as CompanyIcon } from 'crono-fe-common/icons/Company-M.svg';
import { ReactComponent as ContactIcon } from 'crono-fe-common/icons/Contact-M.svg';
import { TagsSettingsWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import {
  MainButton,
  MainPrimaryButton,
} from 'crono-fe-common/components/CronoButton';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import IconMLead from 'crono-fe-common/icons/Icon-M-Lead';
import ExternalPropertyComponent from 'crono-fe-common/components/ExternalProperty';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import {
  ExternalProperty,
  TableType,
} from 'crono-fe-common/types/externalProperty';
import ModifyTag from './modifyTag';
import CreateTag from './createTag';
import IconIntegration from 'crono-fe-common/icons/Icon-Integration';
import { getError, getExternalPropertyLabels } from 'crono-fe-common/utils';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import { useConfirmModal } from 'context/confirmModal';
import useDeleteExternalProperty from 'hooks/services/externalProperty/useDeleteExternalProperty';
import { useConditionalSnackBar } from 'context/snackbar';
import { Constants } from 'crono-fe-common/constants/constants';
import ResyncButton from 'crono-fe-common/components/resyncButton';
import { useAuth } from 'context/auth';
import usePatchExternalProperty from 'hooks/services/externalProperty/usePatchExternalProperty';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { ReactComponent as NoTags } from 'assets/images/No_tags.svg';
import useGetSubscription from 'hooks/services/subscription/useGetSubscription';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';

const TagsSettings = () => {
  const { data: allTags } = useGetExternalProperty(null, true);
  const { user } = useAuth();
  const { data: subscription } = useGetSubscription();

  const { accountTags, prospectTags, leadTags } = useMemo(() => {
    const response = {
      accountTags: [] as ExternalProperty[],
      prospectTags: [] as ExternalProperty[],
      leadTags: [] as ExternalProperty[],
    };

    allTags?.data?.data.forEach((tag) => {
      switch (tag.tableType) {
        case 'Account':
          response.accountTags.push(tag);
          break;
        case 'Lead':
          response.leadTags.push(tag);
          break;
        default:
          response.prospectTags.push(tag);
          break;
      }
    });

    return response;
  }, [allTags?.data?.data]);

  const [tagToChangeId, setTagToChangeId] = useState<number | null>(null);

  const changeTag: {
    tag: ExternalProperty;
    index: number;
  } | null = useMemo(() => {
    const tagFoundIndex = allTags?.data?.data.findIndex(
      (tag) => tag.id === tagToChangeId,
    );
    if (
      tagFoundIndex !== undefined &&
      tagFoundIndex > -1 &&
      allTags?.data?.data[tagFoundIndex]
    ) {
      return {
        tag: allTags?.data?.data[tagFoundIndex],
        index: tagFoundIndex,
      };
    }
    return null;
  }, [allTags?.data?.data, tagToChangeId]);

  const [addTag, setAddTag] = useState<TableType | null>(null);

  const { openModal: openConfirmModal } = useConfirmModal();

  const {
    mutate: deleteExternalProperty,
    isSuccess: externalPropertyDeleted,
    error: errorDeletingExternalProperty,
  } = useDeleteExternalProperty();

  const handleRemoveTag = (tag: ExternalProperty) => {
    openConfirmModal({
      title: 'Are you sure you want to delete this property?',
      text: tag.isSync
        ? 'You will need a new synchronization with the CRM if you want to add this property again'
        : '',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      confirmFunction: () => {
        deleteExternalProperty({ externalPropertyId: tag.id });
      },
      cancelFunction: () => null,
    });
  };
  const {
    mutate: patchExternalProperty,
    isLoading: patchingExternalProperty,
    variables: patchingVariables,
    error: errorPatchingExternalProperty,
    isSuccess: externalPropertyPatched,
  } = usePatchExternalProperty();
  const handleResyncTag = (tag: ExternalProperty) => {
    patchExternalProperty({
      externalPropertyId: tag.id,
      tableType: tag.tableType,
      type: tag.valueType,
      sync: true,
    });
  };

  useConditionalSnackBar([
    {
      condition: !!externalPropertyDeleted,
      message: 'Tag deleted successfully',
      severity: 'success',
    },
    {
      condition: !!errorDeletingExternalProperty,
      message: getError(errorDeletingExternalProperty) ?? 'Error deleting tag',
      severity: 'error',
    },
    {
      condition: !!externalPropertyPatched,
      message: 'Tags are now synchronized between Crono and your CRM',
      severity: 'success',
    },
    {
      condition: !!errorPatchingExternalProperty,
      message: getError(errorPatchingExternalProperty) ?? 'Error updating tag',
      severity: 'error',
    },
  ]);

  const renderTagsHeader = (tags: ExternalProperty[], type: TableType) => (
    <div className="company-contact-row">
      <div className="company-contact-title">
        {type === 'Account' ? (
          <CompanyIcon className="company-contact-icon" />
        ) : type === 'Lead' ? (
          <IconMLead className="company-contact-icon" />
        ) : (
          <ContactIcon className="company-contact-icon" />
        )}{' '}
        <Typography
          fontWeight={500}
          fontSize={16}
          lineHeight={'24px'}
          color={colors.grey1}
        >
          {type === 'Account'
            ? 'Company'
            : type === 'Lead'
              ? 'Lead'
              : 'Contact'}{' '}
          properties
        </Typography>{' '}
        <Typography fontSize={16} fontWeight={500} color={colors.grey1}>
          <span style={{ color: colors.primaryDark }}>{tags?.length}/</span>3
        </Typography>
      </div>
      {(tags?.length ?? 3) < Constants.numberOfTags && (
        <MainPrimaryButton
          startIcon={<PlusIcon color={colors.white} />}
          onClick={() => setAddTag(type)}
        >
          Create new
        </MainPrimaryButton>
      )}
    </div>
  );
  const accountTagsHeaderJSX = renderTagsHeader(accountTags, 'Account');
  const prospectTagsHeaderJSX = renderTagsHeader(prospectTags, 'Prospect');
  const leadTagsHeaderJSX = renderTagsHeader(leadTags, 'Lead');

  const renderTags = (tags: ExternalProperty[]) => (
    <div className="tags-container">
      {tags.map((tag, indexTag) => (
        <div className="tag-card" key={`Tag-${indexTag}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 8,
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={600}
              lineHeight={'24px'}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {tag.publicName}{' '}
              {tag.isSync && (
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 8,
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: '16px',
                    color: colors.grey11,
                  }}
                >
                  <IconIntegration color={colors.grey11} />
                  Linked with CRM
                </span>
              )}
            </Typography>
            <div className="options-container">
              {getExternalPropertyLabels(tag).map((label, index) => (
                <ExternalPropertyComponent
                  key={`Label-Tag-${indexTag}-${index}`}
                  value={label}
                  indexOption={index}
                  index={indexTag}
                  disabled={true}
                />
              ))}
              {!tag.isSync && (
                <ExternalPropertyComponent
                  value={null}
                  index={indexTag}
                  onClick={() => {
                    setTagToChangeId(tag.id);
                  }}
                  disabled={true}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 8,
            }}
          >
            {tag.isSync &&
              user?.company &&
              (patchingExternalProperty &&
              patchingVariables?.externalPropertyId === tag.id ? (
                <CircularProgress />
              ) : (
                <IconButton
                  className="resync-crm-button"
                  disabled={
                    patchingExternalProperty &&
                    patchingVariables?.externalPropertyId !== tag.id
                  }
                  style={{
                    opacity:
                      patchingExternalProperty &&
                      patchingVariables?.externalPropertyId !== tag.id
                        ? 0.5
                        : 1,
                  }}
                >
                  <ResyncButton
                    CRM={user?.company?.integrationType}
                    onClick={() => handleResyncTag(tag)}
                  />
                </IconButton>
              ))}
            <IconButton
              className="edit-button"
              onClick={() => {
                setTagToChangeId(tag.id);
              }}
            >
              <EditIcon color={colors.callScheduled} />
            </IconButton>
            <IconButton
              className="remove-button"
              onClick={() => {
                handleRemoveTag(tag);
              }}
            >
              <RemoveIcon color={colors.inactive} />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
  const prospectTagsJSX = useMemo(
    () => renderTags(prospectTags),
    [prospectTags],
  );
  const leadTagsJSX = useMemo(() => renderTags(leadTags), [leadTags]);
  const accountTagsJSX = useMemo(() => renderTags(accountTags), [accountTags]);

  return (
    <TagsSettingsWrapper>
      {changeTag && (
        <ModifyTag
          tag={changeTag.tag}
          close={() => setTagToChangeId(null)}
          indexTag={changeTag.index}
        />
      )}
      {addTag && (
        <CreateTag initialTab={addTag} close={() => setAddTag(null)} />
      )}

      <Typography fontSize={24} fontWeight={700}>
        Tag management
      </Typography>
      {accountTags.length || prospectTags.length || leadTags.length ? (
        <div className="tags-settings-container">
          {accountTagsHeaderJSX}
          {accountTagsJSX}
          {prospectTagsHeaderJSX}
          {prospectTagsJSX}
          {subscription?.data?.data?.company?.integrationType ===
            IntegrationType.SALESFORCE && (
            <>
              {leadTagsHeaderJSX}
              {leadTagsJSX}
            </>
          )}
        </div>
      ) : (
        <FlexDiv direction="column" gap="20px" style={{ paddingTop: '100px' }}>
          <NoTags />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography
              fontSize={'16px'}
              fontWeight={600}
              lineHeight={'24px'}
              textAlign={'center'}
              maxWidth={'300px'}
            >
              You still don’t have any Tag created. Create a new tag for either
              contacts or companies to see it here
            </Typography>
          </div>
          <MainButton
            startIcon={<PlusIcon color={colors.white} />}
            onClick={() => setAddTag('Account')}
          >
            Create new
          </MainButton>
        </FlexDiv>
      )}
    </TagsSettingsWrapper>
  );
};

export default TagsSettings;
