import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const SignatureIcon = ({
  color = colors.black,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M16 8L3 21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87414 10.1144L12.5531 4.44638C14.4871 2.51638 17.6181 2.51838 19.5501 4.44938V4.44938C21.4841 6.38338 21.4831 9.52038 19.5471 11.4524L13.8721 17.1164C13.3101 17.6774 12.5471 17.9934 11.7531 17.9934H5.99414V12.2374C5.99414 11.4404 6.31114 10.6764 6.87414 10.1144V10.1144Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignatureIcon;
