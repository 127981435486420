import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const OwnerIcon = ({
  color = colors.primaryDark,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.7628 18.91L17.4128 18.031C17.2358 17.585 16.9608 17.185 16.6088 16.859L16.5448 16.8C15.9908 16.286 15.2628 16 14.5068 16H9.49284C8.73684 16 8.00884 16.286 7.45384 16.799L7.38984 16.859C7.03784 17.185 6.76384 17.585 6.58584 18.031L6.23584 18.91"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4749 7.44701C15.8417 8.81385 15.8417 11.0299 14.4749 12.3968C13.108 13.7636 10.892 13.7636 9.52513 12.3968C8.15829 11.0299 8.15829 8.81385 9.52513 7.44701C10.892 6.08017 13.108 6.08017 14.4749 7.44701"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3639 5.63604C21.8787 9.15076 21.8787 14.8492 18.3639 18.3639C14.8492 21.8787 9.15074 21.8787 5.63604 18.3639C2.12132 14.8492 2.12132 9.15074 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.3639 5.63604"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OwnerIcon;
