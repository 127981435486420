import React, { useEffect, useState } from 'react';
import { Suggestion } from 'crono-fe-common/types/suggestion';
import { SuggestionCardWrapper } from './suggestionStyle';
import {
  Avatar,
  Badge,
  Button,
  ClickAwayListener,
  Typography,
} from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import moment from 'moment';
import { CustomSuggestionTooltip, stringAvatar } from './utils';
import { consultDictionary } from './suggestionDictionary';
import { colors } from 'crono-fe-common/theme';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import SequenceSelector from 'pages/dashboard/contactTable/sequenceSelector';
import usePatchSuggestion from 'hooks/services/suggestion/usePatchSuggestion';
import { useConditionalSnackBar } from 'context/snackbar';
import { useWindow } from 'context/sideTab';
import { useSelectedAccount } from 'pages/home/context/account';
import { FeConstants } from 'constants/FeConstants';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { getError } from 'crono-fe-common/utils';
import { getImageFromUrl } from 'utils/fe-utils';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  suggestion: Suggestion;
}

const StartSequenceForProspectCard = ({ suggestion }: IProps) => {
  const template = consultDictionary(suggestion.type);
  const { prospect } = suggestion;

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const analytics = useJuneAnalytics();

  const {
    mutate: patchSuggestion,
    isSuccess: correctlyPatched,
    variables: actionPerformed,
    error: errorPatchSuggestion,
  } = usePatchSuggestion();

  useConditionalSnackBar([
    {
      condition: !!errorPatchSuggestion,
      message: getError(errorPatchSuggestion) ?? 'Error updating suggestion',
      severity: 'error',
    },
    {
      condition: !!correctlyPatched,
      message: actionPerformed?.skip
        ? 'Suggestion correctly skipped'
        : 'Suggestion correctly performed',
      severity: 'success',
    },
  ]);

  const handleRejectSuggestion = () => {
    patchSuggestion({
      suggestionId: suggestion.id,
      skip: true,
    });
    if (analytics) {
      analytics.track('skip-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };

  const handleActionPerformed = () => {
    patchSuggestion({
      suggestionId: suggestion.id,
      skip: false,
    });
    if (analytics) {
      analytics.track('execute-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
    setOpen(false);
  };

  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();
  const { openWindow } = useWindow();

  const handleClickNameProspect = (prospectId: string, accountId: string) => {
    setSelectedAccountId(accountId);
    setSelectedProspectId(prospectId);
    openWindow({
      windowType: 'account',
      tab: 'prospect',
      selectedProspectId: prospectId,
    });
  };
  const handleClickNameAccount = (accountId: string) => {
    setSelectedAccountId(accountId);
    openWindow({
      windowType: 'account',
      tab: 'account',
    });
  };
  const [badgeVisible, setBadgeVisible] = useState<boolean>(false);

  useEffect(() => {
    const currentTime = new Date();
    // Define the time limit for the last 4 hours
    const timeLimit = new Date(
      currentTime.getTime() - FeConstants.notificationTimeSpan,
    ); // 4 hours in milliseconds
    setBadgeVisible(new Date(suggestion.createdDate) > timeLimit);
  }, []);
  if (!prospect) return null;

  return (
    <SuggestionCardWrapper>
      <Badge
        variant="dot"
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        invisible={!badgeVisible}
        color="primary"
        overlap="circular"
        className="badge-suggestion"
      >
        <Avatar
          className="suggestion-avatar"
          key={`avatar-key-${suggestion.objectId}`}
          alt={suggestion.type}
          src={
            suggestion.account?.website &&
            getImageFromUrl(suggestion.account?.website, null)
          }
          {...stringAvatar(suggestion.account?.name ?? '')}
        />
      </Badge>
      <FlexDiv direction="column" alignItems="start">
        <div className="top-header-suggestion-container">
          <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
            {template?.title({
              accountName: (
                <b
                  className="name-link"
                  onClick={() => {
                    return handleClickNameAccount(suggestion.accountId);
                  }}
                >
                  {suggestion.account?.name ?? ''}
                </b>
              ),
              prospectName: (
                <b
                  onClick={() =>
                    handleClickNameProspect(
                      suggestion.prospectId,
                      suggestion.accountId,
                    )
                  }
                  className="name-link"
                >
                  {suggestion.prospect?.name}
                </b>
              ),
              lastActivityDate:
                (suggestion.prospect?.lastActivityDate &&
                  'since ' +
                    moment(suggestion.prospect?.lastActivityDate).format(
                      'LL',
                    )) ||
                'for a while',
            })}
          </Typography>
          <span className="skip-suggestion-x" onClick={handleRejectSuggestion}>
            <CloseMIcon color={colors.grey2} />
          </span>
        </div>
        <Typography fontSize={14} fontWeight={500}>
          {template?.description(
            <b
              onClick={() =>
                handleClickNameProspect(
                  suggestion.prospectId,
                  suggestion.accountId,
                )
              }
              className="name-link"
            >
              {suggestion.prospect?.name}
            </b>,
          )}
        </Typography>
        <FlexDiv width="100%" justifyContent="start">
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <CustomSuggestionTooltip
                title={
                  <SequenceSelector
                    prospectId={prospect.objectId}
                    accountId={prospect.accountId}
                    setOpenTooltip={setOpen}
                    callOnSucces={handleActionPerformed}
                  />
                }
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <MainPrimaryButton
                  className="action-suggestion-button"
                  onClick={handleTooltipOpen}
                >
                  Yes
                </MainPrimaryButton>
              </CustomSuggestionTooltip>
            </div>
          </ClickAwayListener>
        </FlexDiv>
        <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
          {moment(suggestion.createdDate).format('ll')}
        </Typography>
      </FlexDiv>
    </SuggestionCardWrapper>
  );
};

export default StartSequenceForProspectCard;
