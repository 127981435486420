import { Typography } from '@mui/material';
import { useSelectedAccount } from 'pages/home/context/account';
import React, { useRef } from 'react';
import ProspectCard from './prospectCard';
import Pagination from 'pages/dashboard/pagination';
import {
  FeConstants,
  rowPlaceholderFillOpacities,
} from 'constants/FeConstants';
import { colors } from 'crono-fe-common/theme';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import { ReactComponent as NoContactsForCompany } from '../../../assets/images/No_contacts_for_company.svg';
import ContactRowPlaceholder from '../../../assets/images/Contact_row_placeholder.png';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { TabValue } from 'pages/home';

interface IProps {
  selectedProspectId?: string;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  close: () => void;
}

const ContactsView = ({ selectedProspectId, setTabValue, close }: IProps) => {
  const { account, offset, setOffset, onlyOwned, setOnlyOwned } =
    useSelectedAccount();

  const listRef = useRef<HTMLDivElement | null>(null);
  const { prospects } = account ?? { prospects: [] };

  const navigate = useNavigate();

  const handleOpenSearch = () => {
    navigate(PATH.HOME, {
      state: {
        initialTab: TabValue.FIND_NEW,
        initialTabFindNew: 'contact',
        initialCompanyFindNew: account ?? undefined,
      },
    });
    close();
  };

  const rowPlaceholder = rowPlaceholderFillOpacities
    .slice(0, 4)
    .map((el, index) => {
      return (
        <div key={index} style={{ width: '100%' }}>
          <img
            src={ContactRowPlaceholder}
            alt="Empty contact placeholder"
            style={{ opacity: el - 0.2, width: '100%' }}
          />
        </div>
      );
    });

  if (!account) return <></>;

  return (
    <>
      <div
        style={{
          padding: '16px 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontSize={'1.2em'} fontWeight={600} lineHeight={'24px'}>
          Contact(s)
        </Typography>

        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <div className="switch-container">
            <Typography
              fontWeight={500}
              lineHeight={'16px'}
              fontSize={12}
              color={colors.grey11}
            >
              My contacts
            </Typography>
            <CronoSwitch
              checked={onlyOwned}
              onChange={(ev) => {
                setOnlyOwned(ev.target.checked);
              }}
            />
          </div>
          {/*The button is none if the current setup of search gives > 0 results, so in case of a company with contacts but no one is mine I won't show this */}
          {prospects &&
          prospects.length &&
          account?.prospects &&
          account.prospects.length ? (
            <MainButton
              startIcon={<SearchIcon color={colors.white} />}
              onClick={handleOpenSearch}
              style={{ height: '40px', margin: '0px 6px 0px 0px' }}
            >
              Find new
            </MainButton>
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: '100%',
          padding: '0px 24px',
          background: colors.white,
        }}
      >
        <div className="contacts-container" ref={listRef}>
          {prospects && prospects.length ? (
            prospects.map((prospect) => (
              <ProspectCard
                prospect={prospect}
                key={prospect.objectId}
                selectedToExpand={selectedProspectId ?? ''}
                setTabValue={setTabValue}
              />
            ))
          ) : (
            <FlexDiv
              direction="column"
              position="relative"
              justifyContent="start"
            >
              {rowPlaceholder}
              <FlexDiv className="blur-backdrop-effect">
                <div className="img-wrapper">
                  <NoContactsForCompany />
                </div>
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'24px'}
                  textAlign={'center'}
                >
                  There are no contacts for this company
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  lineHeight={'24px'}
                  width={'310px'}
                  textAlign={'center'}
                >
                  Find at least one contact for this company to see it here
                </Typography>
                <MainButton
                  startIcon={<SearchIcon color={colors.white} />}
                  onClick={handleOpenSearch}
                >
                  Find new
                </MainButton>
              </FlexDiv>
            </FlexDiv>
          )}
        </div>
        {(account?.totalProspects ?? 0) > FeConstants.contactPageLimit && (
          <Pagination
            count={account.prospects?.length ?? 0}
            itemOffset={offset}
            limit={FeConstants.contactPageLimit}
            setItemOffset={setOffset}
            total={account.totalProspects ?? 0}
          />
        )}
      </div>
    </>
  );
};

export default ContactsView;
