import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { LinkedinAutomaticTasksQuantity } from 'crono-fe-common/types/DTO/linkedinAutomaticTasksQuantity';

export default function useGetLinkedinAutomaticTasks() {
  const request: Request = {
    url: `${ENDPOINTS.task.linkedin.quantity}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.task.linkedin.quantity],
    queryFn: () => client(request),
    staleTime: 5 * 60 * 1000,
  };

  const { data, ...rest } = useQuery<
    Response<LinkedinAutomaticTasksQuantity>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
