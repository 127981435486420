import { CircularProgress, Typography } from '@mui/material';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import React from 'react';

import { ReactComponent as AddContact } from '../../../assets/images/Add_contact_plus_sign.svg';
import { ReactComponent as NoPreviousMessage } from '../../../assets/images/No_previous_message_icon.svg';
import { ReactComponent as PendingLinkedinInvitation } from '../../../assets/images/Pending_linkedin_invitation.svg';
import { ReactComponent as BrokenContactLinkedinUrl } from '../../../assets/images/Broken_linkedin_url_contact.svg';
import { ReactComponent as IllustrationConnected } from 'crono-fe-common/icons/illustration_connected.svg';
import { ReactComponent as IllustrationNotConnected } from 'crono-fe-common/icons/illustration_not connected.svg';
import { ReactComponent as InformativeIcon } from 'crono-fe-common/icons/InformativeIndicator.svg';
import { ReactComponent as IllustrationInvitationPending } from 'assets/images/InvitationPendingBigIllustration.svg';

import { colors } from 'crono-fe-common/theme';

type FeedbackType =
  | 'linkedinUrlInvalid'
  | 'linkedinNotLogged'
  | 'connectionRequestPendingFeedback'
  | 'alreadyConnectedFeedback'
  | 'noPreviousMessageFeedback'
  | 'brokenLinkedinFeedback'
  | 'notLinkedinConnectedFeedback'
  | 'cantPerformTaskFeedback'
  | 'completePendingInvitationFeedback';

interface IProps {
  type: FeedbackType;
  loadingMainButton?: boolean;
  actionMainButton?: () => void;
}

//TODO: Some components need a check or more thought if we want to refactor them here
// you can test the old components by commenting the break statement and uncommenting the return
export const RenderFeedback = ({
  type,
  loadingMainButton,
  actionMainButton,
}: IProps) => {
  let icon = null;
  let title = null;
  let subtitle = null;
  let actionLogicHandler = null;
  let style = {};
  let footerComponent = null;
  let titleStyles = {};
  let subtitleStyles = {};
  switch (type) {
    case 'connectionRequestPendingFeedback':
      style = { paddingBottom: '80px', gap: '24px' };
      icon = <PendingLinkedinInvitation />;
      titleStyles = {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        maxWidth: '400px',
        textAlign: 'center',
      };
      title =
        'You cannot send a LinkedIn Message as your connection request is still pending';
      break;
    // return connectionRequestPendingFeedback;
    case 'alreadyConnectedFeedback':
      icon = <IllustrationConnected />;
      titleStyles = {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
      };
      subtitleStyles = titleStyles;
      title = 'You already connected with this contact.';
      subtitle = 'This task is completed. Perform the next task.';
      actionLogicHandler = (
        <>
          {loadingMainButton ? (
            <CircularProgress />
          ) : (
            <MainButton
              style={{ marginTop: 32, width: 120 }}
              onClick={actionMainButton}
            >
              Next
            </MainButton>
          )}
        </>
      );
      break;
    // return alreadyConnectedFeedback;
    case 'noPreviousMessageFeedback':
      style = { gap: '24px' };
      icon = <NoPreviousMessage />;
      titleStyles = {
        fontSize: '16px',
        fontWeight: 600,
        maxWidth: '220px',
        textAlign: 'center',
        lineHeight: '24px',
      };
      title = 'No previous messages. Send your first message';
      break;
    // return noPreviousMessageFeedback;
    case 'brokenLinkedinFeedback':
      style = { gap: '20px' };
      icon = <BrokenContactLinkedinUrl />;
      titleStyles = {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        maxWidth: '350px',
        textAlign: 'center',
      };
      subtitleStyles = {
        fontSize: '14px',
        lineHeight: '18px',
        maxWidth: '220px',
        textAlign: 'center',
        color: colors.grey11,
      };
      title =
        'The LinkedIn URL of this contact is invalid. Please change it and try again';
      subtitle =
        'This contact’s LinkedIn URL is not valid and caused an error.';
      actionLogicHandler = (
        <MainButton style={{ width: '140px' }} onClick={actionMainButton}>
          Change
        </MainButton>
      );
      break;
    // return brokenLinkedinFeedback;
    case 'notLinkedinConnectedFeedback':
      style = { gap: '24px' };
      icon = <AddContact />;
      titleStyles = {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        maxWidth: '300px',
        textAlign: 'center',
      };
      title = 'You are not connected on LinkedIn! Add message (optional)';
      break;
    // return notLinkedinConnectedFeedback;
    case 'cantPerformTaskFeedback':
      icon = <IllustrationNotConnected />;
      titleStyles = {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        maxWidth: '320px',
        textAlign: 'center',
      };
      title =
        "You can't perform this task as you are not connected on LinkedIn";
      actionLogicHandler = (
        <MainButton
          style={{ marginTop: 32, width: 120 }}
          onClick={actionMainButton}
        >
          Connect
        </MainButton>
      );
      footerComponent = (
        <div className="informative-message-linkedin-view">
          <InformativeIcon style={{ minWidth: 32 }} />
          <Typography fontSize={14} lineHeight={'18px'}>
            The LinkedIn message task will be automatically rescheduled in 5
            days.
          </Typography>
        </div>
      );
      break;
    // return cantPerformTaskFeedback;
    case 'completePendingInvitationFeedback':
      icon = <IllustrationConnected />;
      titleStyles = {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        textAlign: 'center',
      };
      subtitleStyles = titleStyles;
      title = 'You have a pending invitation with this contact.';
      subtitle = 'Click Next to mark this task as completed';
      actionLogicHandler = (
        <>
          {loadingMainButton ? (
            <CircularProgress />
          ) : (
            <MainButton
              style={{ marginTop: 32, width: 120 }}
              onClick={actionMainButton}
            >
              Next
            </MainButton>
          )}
        </>
      );
      break;
    // return completePendingInvitationFeedback;
  }

  return (
    <FlexDiv direction="column" {...style}>
      {icon}
      {title && <Typography {...titleStyles}>{title}</Typography>}
      {subtitle && <Typography {...subtitleStyles}>{subtitle}</Typography>}
      {actionLogicHandler}
      {footerComponent}
    </FlexDiv>
  );
};

// Refactored components (left for now for testing), eventually will delete
// const connectionRequestPendingFeedback = (
//   <FlexDiv direction="column" gap="24px" style={{ paddingBottom: "80px" }}>
//     <PendingLinkedinInvitation />
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "8px"
//       }}
//     >
//       <Typography
//         fontSize={"16px"}
//         fontWeight={600}
//         maxWidth={"400px"}
//         textAlign={"center"}
//         lineHeight={"24px"}
//       >
//         You cannot send a LinkedIn Message as your connection request is still
//         pending
//       </Typography>
//     </div>
//   </FlexDiv>
// );

// const alreadyConnectedFeedback = (
//   <div className="all-screen-linkedin-view">
//     <IllustrationConnected />
//     <Typography fontSize={16} lineHeight={"24px"} fontWeight={600}>
//       You already connected with this contact.
//     </Typography>
//     <Typography fontSize={16} lineHeight={"24px"} fontWeight={600}>
//       This task is completed. Perform the next task.
//     </Typography>
//     {patchingTask ? (
//       <CircularProgress />
//     ) : (
//       <MainButton
//         style={{ marginTop: 32, width: 120 }}
//         onClick={handleGoNextTask}
//       >
//         Next
//       </MainButton>
//     )}
//   </div>
// );

// const noPreviousMessageFeedback = (
//   <FlexDiv direction="column" gap="24px">
//     <NoPreviousMessage />
//     {/* div here */}
//     <Typography
//       fontSize={"16px"}
//       fontWeight={600}
//       maxWidth={"200px"}
//       textAlign={"center"}
//       lineHeight={"24px"}
//     >
//       No previous messages. Send your first message
//     </Typography>
//   </FlexDiv>
// );

// const brokenLinkedinFeedback = (
//   <FlexDiv direction="column" gap="20px">
//     <BrokenContactLinkedinUrl />
//     <Typography
//       fontSize={16}
//       fontWeight={600}
//       maxWidth={"350px"}
//       textAlign={"center"}
//     >
//       The LinkedIn URL of this contact is invalid. Please change it and try
//       again
//     </Typography>
//     <Typography
//       fontSize={14}
//       color={colors.grey11}
//       maxWidth={"220px"}
//       textAlign={"center"}
//     >
//       This contact’s LinkedIn URL is not valid and caused an error.
//     </Typography>
//     <MainButton
//       style={{ width: "140px" }}
//       onClick={() => {
//         setSelectedProspectId(selectedProspectId ?? null);
//         openWindow({
//           windowType: "account",
//           selectedProspectId: selectedProspectId ?? undefined,
//           tab: "prospect"
//         });
//       }}
//     >
//       Change
//     </MainButton>
//   </FlexDiv>
// );

// const notLinkedinConnectedFeedback = (
//   <FlexDiv direction="column" gap="24px">
//     <AddContact />
//     <Typography
//       fontSize={"16px"}
//       fontWeight={600}
//       maxWidth={"300px"}
//       textAlign={"center"}
//       lineHeight={"24px"}
//     >
//       You are not connected on LinkedIn! Add message (optional)
//     </Typography>
//   </FlexDiv>
// );

// const cantPerformTaskFeedback = (
//   <div className="all-screen-linkedin-view">
//     <IllustrationNotConnected />
//     <Typography fontSize={16} lineHeight={"24px"} fontWeight={600}>
//       You can't perform this task as you are
//     </Typography>
//     <Typography fontSize={16} lineHeight={"24px"} fontWeight={600}>
//       not connected on LinkedIn
//     </Typography>
//     <MainButton
//       style={{ marginTop: 32, width: 120 }}
//       onClick={() => setShowConnectWindow(true)}
//     >
//       Connect
//     </MainButton>
//     <div className="informative-message-linkedin-view">
//       <InformativeIcon style={{ minWidth: 32 }} />
//       <Typography fontSize={14} lineHeight={"18px"}>
//         The LinkedIn message task will be automatically rescheduled in 5 days.
//       </Typography>
//     </div>
//   </div>
// );

// const completePendingInvitationFeedback = (
//     <div className="all-screen-linkedin-view">
//       <IllustrationInvitationPending />
//       <Typography fontSize={16} lineHeight={"24px"} fontWeight={600}>
//         You have a pending invitation with this contact.
//       </Typography>
//       <Typography fontSize={16} lineHeight={"24px"} fontWeight={600}>
//         Click Next to mark this task as completed
//       </Typography>
//       {patchingTask ? (
//         <CircularProgress />
//       ) : (
//         <MainButton
//           style={{ marginTop: 32, width: 120 }}
//           onClick={handleGoNextTask}
//         >
//           Next
//         </MainButton>
//       )}
//     </div>
//   );
