import React, { FC, useEffect, useState } from 'react';
import { CheckYourEmailWrapper } from './styles';
import CheckYourEmailImage from 'assets/images/Check-your-email.svg';

import { MainButton } from 'crono-fe-common/components/CronoButton';
import { CircularProgress, Typography } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { colors } from 'crono-fe-common/theme';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';

interface IProps {
  onClick: () => void;
  isResending: boolean;
  resetEmail: string | null;
  goBack?: () => void;
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`;
};

const CheckYourEmail = ({
  onClick,
  isResending,
  resetEmail,
  goBack,
}: IProps) => {
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prev) => (prev ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const onClickResend = async () => {
    await onClick();
    setSeconds(60);
  };

  return (
    <CheckYourEmailWrapper>
      <img src={CheckYourEmailImage} alt="Check your email" />

      <div className="header">
        <h2>Check your email</h2>
        {resetEmail && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'center',
            }}
          >
            <Typography
              fontSize={'16px !important'}
              fontWeight={400}
              lineHeight={'16px'}
              color={`${colors.black} !important`}
            >
              {resetEmail}
            </Typography>
            {goBack && (
              <span className="go-back" onClick={goBack}>
                <EditPencilIconS color={colors.primaryDark} />
              </span>
            )}
          </div>
        )}
        <p style={{ width: '480px' }}>
          Make sure the email you used matches your Crono’s user email to
          receive a reset link. Please check your inbox to continue.
        </p>
        <p>If you can’t find the email please send a new one in:</p>
        <p>{formatTime(seconds)}</p>
      </div>

      {isResending ? (
        <FlexDiv justifyContent="center">
          <CircularProgress />
        </FlexDiv>
      ) : (
        <MainButton onClick={onClickResend} disabled={seconds !== 0}>
          Resend email
        </MainButton>
      )}
    </CheckYourEmailWrapper>
  );
};

export default CheckYourEmail;
