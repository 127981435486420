import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ApplySequenceChanges } from 'crono-fe-common/types/DTO/applySequenceChanges';

export default function useGetApplySequenceChanges(enabled = true) {
  const request: Request = {
    url: ENDPOINTS.applySequenceChanges.allActive,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.applySequenceChanges.allActive],
    queryFn: () => client(request),
    refetchInterval: 5 * 1000,
    enabled,
  };

  const { data, ...rest } = useQuery<
    Response<ApplySequenceChanges[]>,
    CronoError
  >(requestConfig);

  return { data: data?.data?.data, ...rest };
}
