import {
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';

import { useMemo, useState } from 'react';
// import ReactPlayer from 'react-player';
import { FinishOnboardingContainer, OnboardingStepsWrapper } from './style';

import PATH from 'routing/path';
import { useNavigate } from 'react-router-dom';
import { colors } from 'crono-fe-common/theme';
import LinearDeterminate from 'crono-fe-common/components/LinearProgress';

// import usePatchUserOnboardingFeatures from "hooks/services/user/usePatchUserOnboardingFeatures";
import { ReactComponent as OnboardingFeaturesIcon } from 'crono-fe-common/icons/Cursor, Select, Hand, Password.svg';
import { ReactComponent as IconIntegrationsSetup } from '../../../assets/images/Icon_Integrations_Setup.svg';
import { ReactComponent as IconAddContactsCompanies } from '../../../assets/images/Icon_Add_New_Contacts.svg';
import { ReactComponent as IconCreateTemplate } from '../../../assets/images/Icon_Create_Template.svg';
import IconCreateStrategy from '../../../assets/images/Icon_Target_Goal.png';
import { ReactComponent as IconRunStrategy } from '../../../assets/images/Icon_Run_Task.svg';
// import { ReactComponent as IconAircall } from "../../../assets/images/Icon_Aircall_XXS.svg";
import { ReactComponent as IconLinkedin } from '../../../assets/images/Icon_LinkedIn_XXS.svg';
import { ReactComponent as IconGmail } from '../../../assets/images/Icon_Gmail_XXS.svg';
import { ReactComponent as IconOutlook } from '../../../assets/images/Icon_Outlook_XXS.svg';
// import { ReactComponent as GenerateIcon } from "crono-fe-common/icons/Generate.svg";
import { ReactComponent as IconDownload } from 'crono-fe-common/icons/Download_Simple.svg';
// import { ReactComponent as IconStrategyRoad } from "crono-fe-common/icons/Strategy_road.svg";
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';

import OnboardingAccordion from '../onboardingAccordion';
import { FeaturesOnboarding } from 'crono-fe-common/types/DTO/featuresOnboarding';
import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';
import { TabValue } from '..';
import { SettingsTabs } from 'pages/settings';
import { CompletedOnboardingFeatures } from 'context/onboardingFeatures/types';
import { User } from 'crono-fe-common/types/user';
import IconRightArrow from 'crono-fe-common/icons/Icon-Right-Arrow';
import { FeConstants } from 'constants/FeConstants';
import { PlayArrowRounded } from '@mui/icons-material';
import { BlurBackgroundDivAbsolute } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import VideoPlayerPreview from './videoPlayer';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';

export const OnboardingTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & {
    left?: number;
    top?: number;
    minWidth?: number | string;
    width?: number | string;
    height?: number | string;
    maxHeight?: number | string;
    overflow?: string;
    padding?: number | string;
    borderRadius?: number | string;
  }) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(
  ({
    left,
    top,
    width,
    minWidth,
    height,
    maxHeight,
    overflow,
    padding,
    borderRadius,
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      position: 'relative',
      top: top ? top : -8,
      left: left ? left : -8,
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      height: height ? height : undefined,
      maxHeight: maxHeight ? maxHeight : 100,
      width: width ? width : 500,
      minWidth: minWidth ? minWidth : undefined,
      border: `1px solid ${colors.grey4}`,
      borderRadius: borderRadius ? borderRadius : 8,
      padding: padding ? padding : 0,
      WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      display: 'flex',
      overflow: overflow ? overflow : 'auto',
    },
  }),
);

export type OnboardingOptions = {
  id: number;
  icon: JSX.Element;
  title: string;
  component: JSX.Element;
  completed: boolean;
  apiField: keyof FeaturesOnboarding;
  estimatedTime?: string;
};
interface IProps {
  onboardingFeatures: CompletedOnboardingFeatures | null;
  user: User;
  setTabValue: React.Dispatch<React.SetStateAction<TabValue>>;
  handleFindNew: () => void;
  shrink: boolean;
}
const FinishOnboarding = ({
  onboardingFeatures,
  user,
  setTabValue,
  handleFindNew,
  shrink = false,
}: IProps) => {
  const navigate = useNavigate();

  const handleGoSettings = (tab: SettingsTabs) => {
    navigate(PATH.SETTINGS, {
      state: { tab },
    });
    toggleOnboardingProgress();
  };

  const handleGoTasks = () => {
    navigate(PATH.HOME);
    setTabValue(TabValue.TASKS);
    toggleOnboardingProgress();
  };

  const handleGoTemplates = () => {
    navigate(PATH.TEMPLATE);
    setTabValue(TabValue.TEMPLATES);
    toggleOnboardingProgress();
  };

  const handleGoStrategies = () => {
    navigate(PATH.STRATEGY);
    setTabValue(TabValue.STRATEGIES);
    toggleOnboardingProgress();
  };

  const handleGoFindNew = () => {
    navigate(PATH.HOME);
    handleFindNew();
    toggleOnboardingProgress();
  };

  // const { mutate: patchOnboarding } = usePatchUserOnboardingFeatures();
  const [toggleOnboarding, setToggleOnboarding] = useState<boolean>(false);

  // const [videoPlayer, setVideoPlayer] = useState<string | null>(null);

  const toggleOnboardingProgress = () => {
    setToggleOnboarding((prev) => !prev);
  };

  const onboardingStepsOptions: OnboardingOptions[] = [
    {
      id: 1,
      icon: <IconIntegrationsSetup />,
      title: 'Integrations Setup',
      estimatedTime: '5 min',
      component: (
        <div className="onboardingStepsOptions-components">
          <div className="options-container" style={{ gap: '8px' }}>
            {onboardingFeatures?.linkedinIntegrated !== null && (
              <div
                className={`integration-option integration ${
                  onboardingFeatures?.linkedinIntegrated ? 'completed' : ''
                }`}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                  }}
                >
                  <Typography
                    fontSize={'14px'}
                    fontWeight={400}
                    className={
                      onboardingFeatures?.linkedinIntegrated
                        ? 'completed-step'
                        : ''
                    }
                  >
                    Install Chrome extension
                  </Typography>
                  {!onboardingFeatures?.linkedinIntegrated && (
                    <div
                      className="check-instructions-link"
                      onClick={() =>
                        window.open(FeConstants.helpLinkIntegrations, '_blank')
                      }
                    >
                      <Typography fontSize={'12px'} fontWeight={500}>
                        Check instructions
                      </Typography>
                      <IconRightArrow />
                    </div>
                  )}
                </div>
                {onboardingFeatures?.linkedinIntegrated ? (
                  <IconButton className="completed-checkmark">
                    <CheckMarkIcon color={colors.white} />
                  </IconButton>
                ) : (
                  <Typography
                    className="integration-setup-button"
                    fontSize={'14px'}
                    onClick={() => {
                      if (!onboardingFeatures?.linkedinIntegrated) {
                        handleGoSettings(SettingsTabs.Integrations);
                      }
                    }}
                  >
                    <div className="integration-icon-button">
                      <IconLinkedin />
                    </div>
                    Install
                  </Typography>
                )}
              </div>
            )}
            {/* <div className="integration-option integration">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                onClick={() => {
                  handleGoSettings(SettingsTabs.Integrations);
                }}
              >
                Aircall integration
              </Typography>
              <div className="check-instructions-link"  onClick={() =>
                        window.open(
                          FeConstants.helpLinkIntegrations,
                          '_blank',
                        )
                      }>
                <Typography fontSize={"12px"} fontWeight={500}>
                  Check instructions
                </Typography>
                <IconRightArrow />
              </div>
            </div>
            <Typography fontSize={"14px"} className="integration-setup-button">
              <div className="integration-icon-button">
                <IconAircall />
              </div>
              Connect
            </Typography>
          </div> */}
            {onboardingFeatures?.emailIntegrated !== null &&
              user?.otherSettings?.hasEmail &&
              user.company?.emailIntegrationType ===
                EmailIntegrationType.GMAIL && (
                <div
                  className={`integration-option integration ${
                    onboardingFeatures?.emailIntegrated ? 'completed' : ''
                  }`}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2px',
                    }}
                  >
                    <Typography
                      fontSize={'14px'}
                      fontWeight={400}
                      className={
                        onboardingFeatures?.emailIntegrated
                          ? 'completed-step'
                          : ''
                      }
                    >
                      Gmail integration
                    </Typography>
                    {!onboardingFeatures?.emailIntegrated && (
                      <div
                        className="check-instructions-link"
                        onClick={() =>
                          window.open(
                            FeConstants.helpLinkIntegrations,
                            '_blank',
                          )
                        }
                      >
                        <Typography fontSize={'12px'} fontWeight={500}>
                          Check instructions
                        </Typography>
                        <IconRightArrow />
                      </div>
                    )}
                  </div>

                  {onboardingFeatures?.emailIntegrated ? (
                    <IconButton className="completed-checkmark">
                      <CheckMarkIcon color={colors.white} />
                    </IconButton>
                  ) : (
                    <Typography
                      className="integration-setup-button"
                      fontSize={'14px'}
                      onClick={() => {
                        if (!onboardingFeatures?.emailIntegrated) {
                          handleGoSettings(SettingsTabs.Integrations);
                        }
                      }}
                    >
                      <div className="integration-icon-button">
                        <IconGmail />
                      </div>
                      Connect
                    </Typography>
                  )}
                </div>
              )}
            {onboardingFeatures?.emailIntegrated !== null &&
              user?.otherSettings?.hasEmail &&
              user.company?.emailIntegrationType ===
                EmailIntegrationType.OUTLOOK && (
                <div
                  className={`integration-option integration ${
                    onboardingFeatures?.emailIntegrated ? 'completed' : ''
                  }`}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2px',
                    }}
                  >
                    <Typography
                      fontSize={'14px'}
                      fontWeight={400}
                      className={
                        onboardingFeatures?.emailIntegrated
                          ? 'completed-step'
                          : ''
                      }
                    >
                      Outlook integration
                    </Typography>
                    {!onboardingFeatures?.emailIntegrated && (
                      <div
                        className="check-instructions-link"
                        onClick={() =>
                          window.open(
                            FeConstants.helpLinkIntegrations,
                            '_blank',
                          )
                        }
                      >
                        <Typography fontSize={'12px'} fontWeight={500}>
                          Check instructions
                        </Typography>
                        <IconRightArrow />
                      </div>
                    )}
                  </div>
                  {onboardingFeatures?.emailIntegrated ? (
                    <IconButton className="completed-checkmark">
                      <CheckMarkIcon color={colors.white} />
                    </IconButton>
                  ) : (
                    <Typography
                      className="integration-setup-button"
                      fontSize={'14px'}
                      onClick={() => {
                        if (!onboardingFeatures?.emailIntegrated) {
                          handleGoSettings(SettingsTabs.Integrations);
                        }
                      }}
                    >
                      <div className="integration-icon-button">
                        <IconOutlook />
                      </div>
                      Install
                    </Typography>
                  )}
                </div>
              )}
          </div>
          {/* <VideoPlayerPreview
            title="Crono integrations walkthrough"
            width="256px"
            height="136px"
            src={FeConstants.placeholderUrl}
          /> */}
        </div>
      ),
      completed: false,
      apiField: 'integrations',
    },
    {
      id: 2,
      icon: <IconAddContactsCompanies />,
      title: 'Add new contacts/companies',
      estimatedTime: '5 min',
      component: (
        <div className="onboardingStepsOptions-components">
          <div className="options-container" style={{ gap: '0px' }}>
            <div className="onboarding-step-subtitle">
              <Typography fontSize={'14px'} color={colors.grey1}>
                Upload an existing database or create a new one using a
                convenient search
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <div className="integration-option">
                  <Typography
                    noWrap
                    className="integration-setup-button"
                    fontSize={'14px'}
                    onClick={() => {
                      handleGoSettings(SettingsTabs.ImportData);
                    }}
                  >
                    <div className="integration-icon-button">
                      <IconDownload />
                    </div>
                    Upload CSV file
                  </Typography>
                </div>
                <div className="integration-option" onClick={handleGoFindNew}>
                  <Typography
                    className="integration-setup-button"
                    fontSize={'14px'}
                  >
                    <div className="integration-icon-button">
                      <SearchIcon />
                    </div>
                    Find new
                  </Typography>
                </div>
              </div>
              <div
                className="check-instructions-link"
                onClick={() =>
                  window.open(FeConstants.helpLinkContactsCompanies, '_blank')
                }
              >
                <Typography fontSize={'12px'} fontWeight={500}>
                  Check instructions
                </Typography>
                <IconRightArrow />
              </div>
            </div>
          </div>
          <VideoPlayerPreview
            title="Crono companies and contacts walkthrough"
            width="256px"
            height="136px"
            src={FeConstants.companyContactsSetupUrl}
          />
        </div>
      ),
      completed: false,
      apiField: 'createContactOrCompany',
    },
    {
      id: 3,
      icon: <IconCreateTemplate />,
      title: 'Create your template',
      estimatedTime: '5 min',
      component: (
        <div className="onboardingStepsOptions-components">
          <div className="options-container" style={{ gap: '0px' }}>
            <div
              className="onboarding-step-subtitle"
              style={{ width: '280px' }}
            >
              <Typography fontSize={'14px'} color={colors.grey1}>
                Generate templates for emails or LinkedIn messages or create
                manually
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="integration-option">
                <Typography
                  className="integration-setup-button"
                  fontSize={'14px'}
                  onClick={handleGoTemplates}
                >
                  <div className="integration-icon-button">
                    <PlusSmallIcon color={colors.black} />
                  </div>
                  Create
                </Typography>
              </div>
              <div
                className="check-instructions-link"
                onClick={() =>
                  window.open(FeConstants.helpLinkTemplates, '_blank')
                }
              >
                <Typography fontSize={'12px'} fontWeight={500}>
                  Check instructions
                </Typography>
                <IconRightArrow />
              </div>
            </div>
          </div>
          <VideoPlayerPreview
            title="Crono templates walkthrough"
            width="256px"
            height="136px"
            src={FeConstants.templateSetupUrl}
          />
        </div>
      ),
      completed: false,
      apiField: 'createTemplate',
    },
    {
      id: 4,
      icon: (
        <img
          src={IconCreateStrategy}
          alt="strategy-onboarding-option"
          style={{ width: '40px', height: '40px' }}
        />
      ),
      title: 'Create your first strategy',
      estimatedTime: '10 min',
      component: (
        <div className="onboardingStepsOptions-components">
          <div className="options-container" style={{ gap: '0px' }}>
            <div className="onboarding-step-subtitle">
              <Typography fontSize={'14px'} color={colors.grey1}>
                Set the parameters, your goals and create omnichannel sequences
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="integration-option">
                <Typography
                  className="integration-setup-button"
                  fontSize={'14px'}
                  onClick={handleGoStrategies}
                >
                  <div className="integration-icon-button">
                    <PlusSmallIcon color={colors.black} />
                  </div>
                  Create
                </Typography>
              </div>
              <div
                className="check-instructions-link"
                onClick={() =>
                  window.open(FeConstants.helpLinkStrategy, '_blank')
                }
              >
                <Typography fontSize={'12px'} fontWeight={500}>
                  Check instructions
                </Typography>
                <IconRightArrow />
              </div>
            </div>
          </div>
          <VideoPlayerPreview
            title="Crono strategies walkthrough"
            width="256px"
            height="136px"
            src={FeConstants.stategySetupUrl}
          />
        </div>
      ),
      completed: false,
      apiField: 'createStrategy',
    },
    {
      id: 5,
      icon: <IconRunStrategy />,
      title: 'Run your first task',
      estimatedTime: '10 min',
      component: (
        <div className="onboardingStepsOptions-components">
          <div className="options-container" style={{ gap: '0px' }}>
            <div className="onboarding-step-subtitle">
              <Typography fontSize={'14px'} color={colors.grey1}>
                Enrol contacts into a Strategy and go to the Tasks page to run
                your first task
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="integration-option">
                <Typography
                  className="integration-setup-button"
                  fontSize={'14px'}
                  onClick={handleGoTasks}
                >
                  <div className="integration-icon-button">
                    <PlusSmallIcon color={colors.black} />
                  </div>
                  Execute task
                </Typography>
              </div>
              <div
                className="check-instructions-link"
                onClick={() =>
                  window.open(FeConstants.helpLinkDefault, '_blank')
                }
              >
                <Typography fontSize={'12px'} fontWeight={500}>
                  Check instructions
                </Typography>
                <IconRightArrow />
              </div>
            </div>
          </div>
          <VideoPlayerPreview
            title="Crono tasks walkthrough"
            width="256px"
            height="136px"
            src={FeConstants.taskExecutionUrl}
          />
        </div>
      ),
      completed: false,
      apiField: 'executeTask',
    },
  ];

  const renderOnboardingSteps = (options: OnboardingOptions[]) => {
    return options.map((option, index) => {
      return (
        <div key={option.id} style={{ width: '100%' }}>
          <OnboardingAccordion option={option} index={index} />
        </div>
      );
    });
  };

  const newOnboardingSteps = useMemo(() => {
    // returns base options with completed = false as default
    if (onboardingFeatures === undefined) return onboardingStepsOptions;
    // null means onboarding will be done, left for sync possible issues
    if (onboardingFeatures === null) {
      return onboardingStepsOptions.map((option) => {
        return { ...option, completed: true };
      });
    }
    const completedSteps = onboardingStepsOptions.map((option) => {
      return { ...option, completed: onboardingFeatures[option.apiField] };
    });

    return completedSteps;
  }, [onboardingFeatures]);

  return (
    <FinishOnboardingContainer>
      {/* {videoPlayer && (
        <BlurBackgroundDivAbsolute>
          <FlexDiv>
            <ReactPlayer
              url={videoPlayer}
              light={true}
              playIcon={
                <PlayArrowRounded
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setVideoPlayer(FeConstants.onboardingStepURL);
                  }}
                  sx={{
                    "&.MuiSvgIcon-root": {
                      backgroundColor: colors.white,
                      fill: colors.mainDark,
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      padding: "6px",
                      filter: "drop-shadow(2px 4px 6px black)"
                    },
                    "&.MuiSvgIcon-root:hover": {
                      backgroundColor: colors.mainDark,
                      fill: colors.white
                    }
                  }}
                />
              }
              wrapper={({ children }) => (
                <FlexDiv
                  style={{
                    width: 1024,
                    height: 519
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    return;
                  }}
                >
                  {children}
                </FlexDiv>
              )}
            />
          </FlexDiv>
        </BlurBackgroundDivAbsolute>
      )} */}
      <OnboardingStepsWrapper>
        <div className="onboarding-header-container">
          <div className="onboarding-header">
            <Typography fontSize={'16px'} fontWeight={600}>
              Welcome to Crono
            </Typography>
            <Typography fontSize={'14px'} color={colors.grey11}>
              Follow onboarding simple steps to get the best experience
            </Typography>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography
              fontSize={'14px'}
              fontWeight={500}
              color={colors.primaryDark}
            >
              {onboardingFeatures?.percentage ?? 0}%
            </Typography>
            <LinearDeterminate
              value={onboardingFeatures?.percentage ?? 0}
              linearBarSx={{
                width: '100px',
              }}
            />
          </div>
        </div>
        {renderOnboardingSteps(newOnboardingSteps)}
      </OnboardingStepsWrapper>
    </FinishOnboardingContainer>
  );
};

export default FinishOnboarding;
