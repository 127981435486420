import {
  DefaultCompanyStatusOptions,
  DefaultContactStatusOptions,
} from './model';

export interface SuggestionPreferences {
  generateLeadsEnabled: boolean | null;
  generateAccountsEnabled: boolean | null;
  preconditionEnabled: boolean | null;
  followUpMeetingEnabled: boolean | null;
  startSequenceForOldProspectsEnabled: boolean | null;
  startSequenceForNewProspectsEnabled: boolean | null;
  emailMultipleReadEnabled: boolean | null;
  linkedinRepliedEnabled: boolean | null;
  stopSequencesForReplyEnabled: boolean | null;
  stopSequencesForChangeStatusEnabled: boolean | null;
  stopSequencesForChangeStatusAccountStatus:
    | DefaultCompanyStatusOptions[]
    | null;
  stopSequencesForChangeStatusCustomStatusId: number | null;
  stopSequencesForChangeStatusCustomStatusValues: string[] | null;
  emailRepliedEnabled: boolean | null;
  emailRepliedDays: number;
  emailRepliedEntity: SuggestedEntity | null;
  emailRepliedAccountStatus: DefaultCompanyStatusOptions[] | null;
  emailRepliedProspectStatus: DefaultCompanyStatusOptions[] | null;
  emailRepliedProspectStatusNew: DefaultContactStatusOptions[] | null;
  emailRepliedCustomStatusId: number | null;
  emailRepliedCustomStatusValues: string[] | null;
  emailRepliedActivity: SuggestedActivity | null;
  linkedinRepliedDays: number;
  linkedinRepliedEntity: SuggestedEntity | null;
  linkedinRepliedAccountStatus: DefaultCompanyStatusOptions[] | null;
  linkedinRepliedProspectStatus: DefaultCompanyStatusOptions[] | null;
  linkedinRepliedProspectStatusNew: DefaultContactStatusOptions[] | null;
  linkedinRepliedCustomStatusId: number | null;
  linkedinRepliedCustomStatusValues: string[] | null;
  linkedinRepliedActivity: SuggestedActivity | null;
  followUpMeetingDays: number;
  followUpMeetingEntity: SuggestedEntity | null;
  followUpMeetingAccountStatus: DefaultCompanyStatusOptions[] | null;
  followUpMeetingProspectStatus: DefaultCompanyStatusOptions[] | null;
  followUpMeetingProspectStatusNew: DefaultContactStatusOptions[] | null;
  followUpMeetingCustomStatusId: number | null;
  followUpMeetingCustomStatusValues: string[] | null;
  followUpMeetingActivity: SuggestedActivity | null;
  startSequenceForNewProspectsDays: number;
  startSequenceForNewProspectsEntity: SuggestedEntity | null;
  startSequenceForNewProspectsAccountStatus:
    | DefaultCompanyStatusOptions[]
    | null;
  startSequenceForNewProspectsProspectStatus:
    | DefaultCompanyStatusOptions[]
    | null;
  startSequenceForNewProspectsProspectStatusNew:
    | DefaultContactStatusOptions[]
    | null;
  startSequenceForNewProspectsCustomStatusId: number | null;
  startSequenceForNewProspectsCustomStatusValues: string[] | null;
  startSequenceForOldProspectsDays: number;
  startSequenceForOldProspectsEntity: SuggestedEntity | null;
  startSequenceForOldProspectsAccountStatus:
    | DefaultCompanyStatusOptions[]
    | null;
  startSequenceForOldProspectsStatus: DefaultCompanyStatusOptions[] | null;
  startSequenceForOldProspectsStatusNew: DefaultContactStatusOptions[] | null;
  startSequenceForOldProspectsCustomStatusId: number | null;
  startSequenceForOldProspectsCustomStatusValues: string[] | null;
  generateAccountsDays: number;
  generateLeadsDays: number;
  generateLeadsIfNoEngagementEnabled: boolean | null;
  generateLeadsIfNoEngagementDays: number;
  emailMultipleReadTimes: number;
  clearDataEnabled: boolean | null;
  clearDataDays: number;
  followUpOpportunityEnabled: boolean | null;
  suggestAccountStatusEnabled: SuggestedAccountStatusEnabled;
  suggestNewCompanyEnabled: boolean | null;
  suggestNewRoleEnabled: boolean | null;
  suggestNewRoleEnabledProspectStatus: DefaultCompanyStatusOptions[] | null;
  suggestNewRoleEnabledProspectStatusNew: DefaultContactStatusOptions[] | null;
  suggestNewRoleEnabledCustomStatusId: number | null;
  suggestNewRoleEnabledCustomStatusValues: string[] | null;
}

export type SuggestedEntity = 'Account' | 'Prospect' | 'Off';

export type SuggestedActivity = 'Task' | 'Event' | 'Opportunity' | 'Off';

export enum SuggestedAccountStatusEnabled {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Automatic = 'Automatic',
}
