import { useAuth } from 'context/auth';
import { FC, useMemo, useState } from 'react';
import { GeneralWrapper } from '../styles';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import useGetSubscription from 'hooks/services/subscription/useGetSubscription';
import { User } from 'crono-fe-common/types/user';
import Role from 'crono-fe-common/types/enums/role';
import {
  CancelButton,
  MainPrimaryButton,
} from 'crono-fe-common/components/CronoButton';
import { SubscriptionWrapper, UsersTableWrapper } from './style';
import LightBulbIllustration from 'assets/images/Light_bulb_draw_icon.png';
import ChangeSubscriptionIcon from 'assets/images/Change_subscription_icon.png';
import { colors } from 'crono-fe-common/theme';
import moment from 'moment';
import ContactsIconS from 'crono-fe-common/icons/Icon-Contacts-S';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import InviteNewUserForm from './inviteNewUserForm';
import EditUserForm from './editUserForm';
import { useConfirmModal } from 'context/confirmModal';
import usePatchUser from 'hooks/services/subscription/usePatchUser';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import useDeleteUser from 'hooks/services/subscription/useDeleteUser';
import useGetSubscriptionProducts from 'hooks/services/subscription/useGetSubscriptionProducts';
import usePortalSession from 'hooks/services/subscription/usePortalSession';
import { SubscriptionPlansCard } from 'pages/subscriptionPlans';
import { SubscriptionProductDTO } from 'crono-fe-common/types/DTO/SubscriptionPlansDTO';
import { useJuneAnalytics } from 'context/june';

const Subscription: FC = () => {
  const { user: userMe } = useAuth();

  const { data: subscriptionDataDb } = useGetSubscription();

  const subscriptionData = useMemo(() => {
    if (subscriptionDataDb?.data?.data) {
      return subscriptionDataDb.data.data;
    }
    return null;
  }, [subscriptionDataDb]);

  const isMultiCustomer = useMemo(() => {
    if (subscriptionData?.multiCustomer) {
      return subscriptionData.multiCustomer;
    }
    return false;
  }, [subscriptionData]);

  const activeLicenses = useMemo(() => {
    return subscriptionData?.users?.length ?? 0;
  }, [subscriptionData]);

  const { data: subscriptionPlans } = useGetSubscriptionProducts();

  const currentPlan = useMemo(() => {
    let currentPrice: SubscriptionProductDTO | null = null;
    subscriptionPlans?.data?.data?.forEach((plan) => {
      const priceIndex = plan?.prices.findIndex((price) => price.current);
      // console.log("Price index", priceIndex);
      if (priceIndex > -1) {
        currentPrice = plan;
        currentPrice.prices = [currentPrice?.prices[priceIndex]];
      }
    });
    return currentPrice! ?? null;
  }, [subscriptionPlans]);

  const { mutateAsync: createPortalSession } = usePortalSession();

  const handleCustomerPortal = () => {
    createPortalSession({}).then((res) => {
      window.open(res.data?.data.url, '_blank');
    });
  };

  const handleContactUs = () => {
    window.open('mailto:hello@crono.one', '_blank');
  };

  const isStripeCustomer = useMemo(() => {
    if (!userMe) return null;
    return userMe.isStripeCustomer;
  }, [userMe]);

  return (
    <GeneralWrapper>
      <SubscriptionWrapper>
        {isStripeCustomer === null ? (
          <CircularProgress />
        ) : isStripeCustomer ? (
          <>
            <Typography
              fontSize={24}
              fontWeight={700}
              lineHeight={'30px'}
              style={{ paddingBottom: 24 }}
            >
              General
            </Typography>
            <div className="subscription-preview-card">
              <div className="left-side">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 19,
                    paddingTop: 8,
                  }}
                >
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    lineHeight={'30px'}
                  >
                    {subscriptionData?.subscriptionType} plan
                  </Typography>
                  <div
                    style={{ display: 'flex', gap: 40, alignItems: 'center' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                      }}
                    >
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={'24px'}
                        color={colors.grey11}
                      >
                        Billing:
                      </Typography>
                      <Typography
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={'24px'}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                        }}
                      >
                        €{currentPlan?.prices[0]?.monthlyTotalCycle}{' '}
                        <span
                          style={{
                            color: colors.grey11,
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          per month
                        </span>
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                      }}
                    >
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={'24px'}
                        color={colors.grey11}
                      >
                        Next renewal:
                      </Typography>
                      <Typography
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={'24px'}
                      >
                        {moment(
                          isMultiCustomer
                            ? userMe?.company?.expirationDate
                            : subscriptionData?.expirationDate,
                        ).format('LL')}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className="divider-change-plan-card" />
                {!isMultiCustomer && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 14,
                      paddingTop: 8,
                    }}
                  >
                    <Box position="relative">
                      <ContactsIconS
                        className="icon-inside-circular"
                        color={colors.primaryDark}
                      />
                      <CircularProgress
                        value={
                          (activeLicenses /
                            (subscriptionData?.enabledUsers.Basic ?? 10)) *
                          100
                        }
                        thickness={3}
                        variant="determinate"
                        color="primary"
                      />
                      {/*To show the track */}
                      <CircularProgress
                        value={
                          -(
                            1 -
                            activeLicenses /
                              (subscriptionData?.enabledUsers.Basic ?? 10)
                          ) * 100
                        }
                        thickness={3}
                        variant="determinate"
                        style={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          color: colors.primaryLightHover,
                        }}
                      />
                    </Box>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                      }}
                    >
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={'24px'}
                        color={colors.grey11}
                      >
                        Licenses:
                      </Typography>
                      <div className="add-more-container">
                        <Typography
                          fontSize={18}
                          fontWeight={700}
                          lineHeight={'24px'}
                        >
                          {activeLicenses}
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              color: colors.grey11,
                            }}
                          >
                            {' '}
                            / {subscriptionData?.enabledUsers.Basic ?? 10}
                          </span>
                        </Typography>
                        <Button
                          variant="text"
                          color="primary"
                          className="add-more-button"
                          endIcon={<PlusSmallIcon color={colors.primaryDark} />}
                          onClick={handleCustomerPortal}
                          sx={{
                            '&.MuiButton-root': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          Add more
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/*Box for the starter plan */}
              {/*<div className="illustration-change-subscription">*/}
              {/*  <div*/}
              {/*    style={{*/}
              {/*      display: "flex",*/}
              {/*      alignItems: "flex-start",*/}
              {/*      gap: 4,*/}
              {/*      justifyContent: "space-between"*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div style={{ display: "flex", flexDirection: "column" }}>*/}
              {/*      <Typography fontSize={18} fontWeight={700} lineHeight={"24px"}>*/}
              {/*        Change plan*/}
              {/*      </Typography>*/}
              {/*      <Typography fontSize={14} fontWeight={500} lineHeight={"18px"}>*/}
              {/*        Integrate your CRM*/}
              {/*      </Typography>*/}
              {/*    </div>*/}
              {/*    <img src={ChangeSubscriptionIcon}*/}
              {/*         style={{ width: 58, height: 58, }}*/}
              {/*         alt="change subscription" />*/}
              {/*  </div>*/}
              {/*  <CancelButton*/}
              {/*    style={{*/}
              {/*      border: 0,*/}
              {/*      margin: 0,*/}
              {/*      width: "fit-content",*/}
              {/*      height: 40,*/}
              {/*      fontSize: 14,*/}
              {/*      lineHeight: "18px"*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    See plans*/}
              {/*  </CancelButton>*/}
              {/*</div>*/}
              <div className="illustration-contact-us-subscription ">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      fontSize={18}
                      fontWeight={700}
                      lineHeight={'24px'}
                    >
                      Need changes?
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      lineHeight={'18px'}
                      color={colors.grey11}
                    >
                      Or have any questions
                    </Typography>
                  </div>
                  <img
                    src={LightBulbIllustration}
                    alt="light bulb"
                    style={{ width: 42, height: 58 }}
                  />
                </div>
                <CancelButton
                  style={{
                    margin: 0,
                    width: 'fit-content',
                    height: 40,
                    fontSize: 14,
                    lineHeight: '18px',
                  }}
                  onClick={handleCustomerPortal}
                >
                  Customer portal
                </CancelButton>
              </div>
            </div>
            <Typography
              fontSize={14}
              lineHeight={'24px'}
              color={colors.grey11}
              style={{ paddingTop: 8 }}
            >
              Do you have any questions?{' '}
              <span
                style={{
                  cursor: 'pointer',
                  color: colors.primary,
                  fontWeight: 600,
                }}
                onClick={handleContactUs}
              >
                Contact us
              </span>
            </Typography>
          </>
        ) : (
          userMe && (
            <SubscriptionPlansCard
              title={'Pricing plans'}
              subtitle={
                <span>
                  Your {<span className="bold">{userMe.subscriptionType}</span>}{' '}
                  expires on{' '}
                  {
                    <span className="bold">
                      {moment(userMe?.company?.expirationDate).format('MMM D')}
                    </span>
                  }
                  . Select a plan to continue enjoying Crono:
                </span>
              }
              containerHeight="220px"
            />
          )
        )}
        <UsersTable />
      </SubscriptionWrapper>
    </GeneralWrapper>
  );
};

export const UsersTable = () => {
  const { user: userMe } = useAuth();
  const isStripeCustomer = useMemo(() => {
    if (!userMe) return null;
    return userMe.isStripeCustomer;
  }, [userMe]);
  const analytics = useJuneAnalytics();

  const { data: subscriptionDataDb } = useGetSubscription();

  const subscriptionData = useMemo(() => {
    if (subscriptionDataDb?.data.data) {
      return subscriptionDataDb.data.data;
    }
    return null;
  }, [subscriptionDataDb]);

  const isMultiCustomer = useMemo(() => {
    if (subscriptionData?.multiCustomer) {
      return subscriptionData.multiCustomer;
    }
    return false;
  }, [subscriptionData]);

  const activeLicenses = useMemo(() => {
    return subscriptionData?.users?.length ?? 0;
  }, [subscriptionData]);

  const maxLicensesReached = useMemo(() => {
    return activeLicenses === subscriptionData?.enabledUsers.Basic;
  }, [activeLicenses, subscriptionData]);

  const [inviteNewUser, setInviteNewUser] = useState<boolean>(false);

  const [userToEdit, setUserToEdit] = useState<User | null>(null);

  const { openModal: openConfirmModal } = useConfirmModal();

  const {
    mutate: patchUser,
    isSuccess: userPatched,
    error: errorPatchingUser,
  } = usePatchUser();

  const handleChangeAdminPermission = (userId: number, newValue: boolean) => {
    //If new value is positive we are upgrading someone to admin, an unsage operation, so we ask for confirmation
    if (newValue) {
      openConfirmModal({
        title: 'Are you sure you want to upgrade member to admin?',
        text: 'The admin permission allows users to manage licenses and payments.',
        confirmText: 'Upgrade',
        cancelText: 'Cancel',
        confirmFunction: () => {
          patchUser({
            userId: userId,
            subscriptionManager: newValue,
          });
          if (analytics) {
            analytics.track('change-subscription-manager-permission', {
              userId: userId,
              subscriptionManager: newValue,
            });
          }
        },
        cancelFunction: () => {},
      });
    } else {
      patchUser({
        userId: userId,
        subscriptionManager: newValue,
      });
      if (analytics) {
        analytics.track('change-subscription-manager-permission', {
          userId: userId,
          subscriptionManager: newValue,
        });
      }
    }
  };

  const {
    mutate: deleteUser,
    isSuccess: userDeleted,
    error: deleteUserError,
  } = useDeleteUser();

  const handleDeleteUser = (userId: number) => {
    //If new value is positive we are upgrading someone to admin, an unsage operation, so we ask for confirmation
    openConfirmModal({
      title: 'Are you sure you want to delete this user?',
      confirmText: 'Delete',
      cancelText: 'Cancel',
      confirmFunction: () => {
        deleteUser({
          userId,
          delete: true,
        });
        if (analytics) {
          analytics.track('delete-user-license', {
            userId: userId,
          });
        }
      },
      cancelFunction: () => {},
    });
  };

  useConditionalSnackBar([
    {
      condition: userPatched,
      message: 'User updated successfully',
      severity: 'success',
    },
    {
      condition: !!errorPatchingUser,
      message: getError(errorPatchingUser) ?? 'Error while updating the user',
      severity: 'error',
    },
    {
      condition: userDeleted,
      message: 'User deleted successfully',
      severity: 'success',
    },
    {
      condition: !!deleteUserError,
      message: getError(deleteUserError) ?? 'Error while deleting the user',
      severity: 'error',
    },
  ]);

  return (
    <UsersTableWrapper>
      {inviteNewUser && (
        <InviteNewUserForm close={() => setInviteNewUser(false)} />
      )}
      {userToEdit && (
        <EditUserForm close={() => setUserToEdit(null)} user={userToEdit} />
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 24,
          paddingBottom: 16,
        }}
      >
        <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
          Users
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          {!isStripeCustomer && (
            <Typography fontSize={18} fontWeight={700} lineHeight={'24px'}>
              {activeLicenses}
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: colors.grey11,
                }}
              >
                {' '}
                / {subscriptionData?.enabledUsers.Basic ?? 10}
              </span>
            </Typography>
          )}
          <MainPrimaryButton
            startIcon={<PlusSmallIcon color={colors.white} />}
            style={{
              height: 48,
              fontSize: 14,
              lineHeight: '18px',
              fontWeight: 500,
            }}
            onClick={() => setInviteNewUser(true)}
            disabled={!isMultiCustomer && maxLicensesReached}
          >
            Invite new
          </MainPrimaryButton>
        </div>
      </div>
      {subscriptionData?.users && (
        <div className="user-list-container">
          {subscriptionData.users.map((user, index) => {
            const isAdmin = user.userRoles.includes(Role.ADMIN);
            return (
              <div key={index} className="user-card">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      overflow: 'hidden',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      fontSize={16}
                      lineHeight={'24px'}
                      fontWeight={500}
                      noWrap
                    >
                      {user.firstName} {user.lastName}
                    </Typography>
                    <Typography
                      fontSize={12}
                      lineHeight={'16px'}
                      fontWeight={500}
                      color={colors.prospectingHover}
                    >
                      <span style={{ paddingInline: 8, fontWeight: 700 }}>
                        ·
                      </span>
                      {user.userRoles.includes(Role.MANAGER)
                        ? 'Manager'
                        : 'User'}
                    </Typography>
                  </div>
                  <Typography
                    fontSize={14}
                    lineHeight={'18px'}
                    fontWeight={500}
                    color={colors.grey11}
                    noWrap
                  >
                    {user.email}
                  </Typography>
                </div>
                {user.active ? (
                  <div className="active-badge">Active</div>
                ) : (
                  <div className="pending-badge">Pending</div>
                )}
                <div className="admin-checkbox">
                  <Checkbox
                    icon={<IconUnchecked />}
                    checkedIcon={
                      <IconChecked
                        color={
                          user?.id === userMe?.id || isMultiCustomer
                            ? colors.grey11
                            : colors.mainDark
                        }
                      />
                    }
                    checked={user.userRoles.includes(Role.SUBSCRIPTION_MANAGER)}
                    disabled={user?.id === userMe?.id || isMultiCustomer}
                    sx={{
                      padding: 'inherit',
                      margin: 'inherit',
                      '&:hover': {
                        backgroundColor: colors.grey4,
                      },
                      '&.Mui-checked': {
                        '&:hover': {
                          opacity: 0.7,
                        },
                      },
                      marginRight: 1,
                    }}
                    onChange={(ev, newValue) => {
                      handleChangeAdminPermission(user?.id, newValue);
                    }}
                  />
                  <Typography
                    fontSize={14}
                    lineHeight={'18px'}
                    fontWeight={500}
                    color={isAdmin ? colors.black : colors.grey11}
                  >
                    Admin
                  </Typography>
                </div>
                {!isMultiCustomer && (
                  <div className="buttons-user-container">
                    <IconButton
                      className="button-edit-user"
                      onClick={() => setUserToEdit(user)}
                    >
                      <EditIcon color={colors.primaryDark} />
                    </IconButton>
                    {user?.id !== userMe?.id ? (
                      <IconButton
                        className="button-delete-user"
                        onClick={() => handleDeleteUser(user?.id)}
                      >
                        <RemoveIcon color={colors.inactiveHover} />
                      </IconButton>
                    ) : (
                      <div style={{ width: 32 }} />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </UsersTableWrapper>
  );
};

export default Subscription;
