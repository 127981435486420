import styled from '@emotion/styled';
import {
  Autocomplete,
  AutocompleteProps,
  ChipTypeMap,
  Paper,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import React from 'react';

interface IProps {
  textFieldParams?: TextFieldProps;
  setSearchText?: (text: string) => void;
  disablePortal?: boolean;
  placeholder?: string;
  variant?: 'standard' | 'outlined' | 'filled';
}

export function CronoAutocomplete<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
  props: Omit<
    AutocompleteProps<
      T,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent | 'div'
    >,
    'renderInput'
  > &
    IProps,
): JSX.Element {
  const {
    textFieldParams,
    setSearchText,
    disablePortal,
    placeholder,
    variant,
  } = props;

  return (
    <Autocomplete
      {...props}
      disablePortal={disablePortal === undefined ? true : disablePortal}
      // PaperComponent={styled(Paper)`
      //   background: ${colors.white};
      //   -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      //   -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      //   -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      //   box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      //   padding: 6px 0px 6px 6px;
      //   z-index: 100000;
      //   border-radius: 8px;
      // `}
      componentsProps={{
        paper: {
          sx: {
            background: colors.white,
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
            padding: '6px 0px 6px 6px',
            zIndex: 100000,
            borderRadius: '8px',
          },
        },
      }}
      // ListboxComponent={styled('ul')`
      //   overflow-x: hidden;
      //   .MuiAutocomplete-option.Mui-focused {
      //     color: ${colors.primary} !important;
      //     background-color: ${colors.primaryLight} !important;
      //   }
      //   .MuiAutocomplete-option[aria-selected='true'] {
      //     color: ${colors.primary} !important;
      //     background-color: ${colors.white} !important;
      //   }
      //   li {
      //     font-size: 12px;
      //     font-weight: 500;
      //     line-height: 16px;
      //     padding: 8px;
      //     cursor: pointer;
      //     width: 99%;
      //     border-radius: 8px;
      //     z-index: 1000;
      //     :hover {
      //       background: ${colors.primaryLight};
      //       color: ${colors.primary};
      //     }
      //   }
      // `}
      ListboxProps={{
        sx: {
          overflowX: 'hidden',
          li: {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '16px',
            padding: '8px',
            cursor: 'pointer',
            width: '99%',
            borderRadius: '8px',
            zIndex: 1000,
            '&:hover': {
              background: colors.primaryLight,
              color: colors.primary,
            },
          },
          '.MuiAucomplte-option.Mui-focused': {
            color: colors.primary,
            backgroundColor: colors.primaryLight,
          },
          ".MuiAutocomplete-option[aria-selected='true']": {
            color: colors.primary,
            backgroundColor: colors.white,
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          {...params}
          {...textFieldParams}
          variant={variant ?? 'outlined'}
          size="small"
          onChange={(e) => {
            if (setSearchText) setSearchText(e.target.value);
          }}
        />
      )}
    />
  );
}
