import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Constants } from 'crono-fe-common/constants/constants';
import { NoteDTO } from 'crono-fe-common/types/noteGet';

export default function useNotes(
  accountId: string | null,
  opportunityId?: string | null,
  limit: number = Constants.defaultLimit,
) {
  const queryParams = queryString.stringify({
    accountId,
    opportunityId,
    limit,
  });

  const request: Request = {
    url: `${ENDPOINTS.notes.main}?${queryParams}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.notes.main, accountId, opportunityId],
    queryFn: () => client(request),
    staleTime: 5 * 60 * 1000,
    enabled: !!accountId,
  };

  const { data, ...rest } = useQuery<Response<NoteDTO[]>, CronoError>(
    requestConfig,
  );

  return { data, ...rest };
}
