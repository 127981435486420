import React from 'react';
import { LegendWrapper } from './style';

interface IProps {
  colors: string[];
  fields: string[];
  toManyFields?: boolean;
}

const Legend = ({ colors, fields, toManyFields = false }: IProps) => {
  return (
    <LegendWrapper>
      {fields.map((field, index) => {
        return (
          <div className="legend-item" key={field}>
            <div
              className="legend-color"
              style={{
                background: colors[index % colors.length],
              }}
            />

            <span style={toManyFields ? { fontSize: '12px' } : {}}>
              {field}
            </span>
          </div>
        );
      })}
    </LegendWrapper>
  );
};

export default Legend;
