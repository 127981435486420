import { GreyButton } from 'crono-fe-common/components/CronoButton';
import React, { useEffect, useState } from 'react';
import { IcebreakersListWrapper } from './style';
import { ReactComponent as RegenerateIcon } from 'crono-fe-common/icons/Regenerate.svg';
import { CircularProgress, Radio, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import useGenerateIcebreakers from 'hooks/services/assistant/useGenerateIcebreakers';
import { useLinkedinGetActivities } from 'crono-fe-common/hooks/crono-extension/gateway';
import { GenerateIcebreakersInput } from 'crono-fe-common/types/DTO/generateIcebreakers';
import { FeConstants } from 'constants/FeConstants';
import { Prospect } from 'crono-fe-common/types/prospect';
import { useAuth } from 'context/auth';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { useConditionalSnackBar } from 'context/snackbar';
import { Constants } from 'crono-fe-common/constants/constants';
import { getError } from 'crono-fe-common/utils';

interface IProps {
  prospect: Prospect | null;
  publicId: string | null;
  open: boolean;
  close: () => void;
  onSelect: (icebreaker: string) => void;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

// const fakeList = [
//   {
//     content:
//       "Hai mai desiderato un assistente virtuale che potesse rispondere a tutte le tue domande? Bene, permettimi di presentarti [nome dell'azienda], il nostro team di esperti in intelligenza artificiale che può aiutarti a ottenere risposte istantanee su qualsiasi argomento. Qualunque sia la tua domanda, siamo qui per aiutarti!"
//   },
//   {
//     content:
//       "Immagina un luogo in cui le tue idee prendono vita. [Nome dell'azienda] è più di una semplice azienda; siamo un'incubatrice di innovazione. Forniamo un ambiente creativo in cui puoi sviluppare le tue idee più brillanti e trasformarle in realtà. Siamo qui per alimentare la tua passione e portare avanti il tuo progetto."
//   },
//   {
//     content:
//       "Cosa rende davvero speciale [nome dell'azienda]? La nostra mentalità di squadra e l'attenzione per i dettagli. Siamo un gruppo di professionisti dedicati che lavorano insieme per raggiungere obiettivi comuni. Siamo appassionati di fornire soluzioni di alta qualità ai nostri clienti e ci impegniamo a superare le aspettative. Sei pronto a unirti alla nostra squadra di successo?"
//   }
// ];

const IcebreakersList = ({
  prospect,
  publicId,
  open,
  close,
  onSelect,
  setIsLoading,
}: IProps) => {
  const { user } = useAuth();
  //To get posts and hashtags to generate icebreakers
  const {
    call: getLinkedinActivities,
    result: prospectLinkedinInformation,
    isLoading: loadingLinkedinInformation,
    error: linkedinActivitiesError,
  } = useLinkedinGetActivities();

  const {
    mutate: generateIcebreakers,
    data: icebreakers,
    isLoading: loadingIcebreakers,
    error: errorIcebreakers,
  } = useGenerateIcebreakers();

  const [loadingProcess, setLoadingProcess] = useState<boolean>(false);

  //To show the loading spingger on the button
  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(loadingProcess);
    }
  }, [loadingProcess]);

  useEffect(() => {
    if (errorIcebreakers) {
      if (setIsLoading) setIsLoading(false);
      setLoadingProcess(false);
    }
  }, [errorIcebreakers]);

  useEffect(() => {
    if (icebreakers) {
      setLoadingProcess(false);
    }
  }, [icebreakers]);

  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    //When the tab is opened I execute the function to get the posts and hashtags if they are not already loaded
    if (open) {
      //If there are already icebreakers loaded, I don't need to generate them again
      if (!icebreakers?.data?.data) {
        setLoadingProcess(true);
        handleGenerateIcebreakers();
      }
    }
  }, [open]);

  const handleGenerateIcebreakers = () => {
    if (publicId) {
      getLinkedinActivities({ publicId });
    } else if (prospect?.account?.website || prospect?.title) {
      craftObjectAndGenerate();
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      setLoadingProcess(false);
    }
  };

  const craftObjectAndGenerate = () => {
    //I get the 3 most used hashtags if linkedin search went well
    const hashtags = Object.entries(
      prospectLinkedinInformation?.allHashtags ?? [],
    )
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map((hashtag) => hashtag[0]);
    const generateObject: GenerateIcebreakersInput = {
      title: prospect?.title,
      accountId: prospect?.accountId,
      hashtags,
      accountName: prospect?.account?.name,
      prospectName: prospect?.name,
      website: prospect?.account?.website,
      what: user?.userPreferences?.productDescription ?? null,
      language:
        user?.userPreferences?.templateLanguage === 'all'
          ? Constants.defaultLanguage
          : user?.userPreferences?.templateLanguage,
      posts: [],
    };
    generateIcebreakers(generateObject);
  };

  useEffect(() => {
    if (!linkedinActivitiesError && prospectLinkedinInformation) {
      craftObjectAndGenerate();
    } else if (linkedinActivitiesError) {
      if (!prospect?.account?.website && !prospect?.title) {
        //No data to generate the icebreakers
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
        setLoadingProcess(false);
      } else {
        craftObjectAndGenerate();
      }
    }
  }, [linkedinActivitiesError, prospectLinkedinInformation]);

  // const handleRegenerateIcebreakers = () => {
  //   // setSelectedIcebreaker(null);
  //   craftObjectAndGenerate();
  // };

  useConditionalSnackBar([
    {
      condition: !!errorIcebreakers,
      message:
        getError(errorIcebreakers) ?? 'Error while generating icebreakers',
      severity: 'error',
    },
    {
      condition: showError,
      message:
        'Missing contact and company information. Icebreakers unavailable',
      severity: 'error',
    },
    {
      condition: icebreakers?.data?.data.icebreakers?.length === 0,
      message: "We couldn't generate icebreakers for this prospect",
      severity: 'error',
    },
  ]);

  // const [selectedIcebreaker, setSelectedIcebreaker] = useState<number | null>(
  //   null
  // );

  const handleSelect = (content: string, index: number) => {
    // setSelectedIcebreaker(index);
    onSelect(content);
  };

  return (
    <>
      {/*The check on icebreakers is not to show the cirular progress inside the modal but on the button instead */}
      {open && (icebreakers?.data?.data.icebreakers?.length ?? 0) > 0 ? (
        <IcebreakersListWrapper>
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
                color={colors.grey11}
              >
                Select appropriate icebreaker:
              </Typography>
              <CloseMIcon
                color={colors.grey11}
                onClick={close}
                className="close-button"
              />
            </div>
            <div className="icebreakers-list">
              {icebreakers?.data?.data?.icebreakers?.map(
                (icebreaker, index) => (
                  <div
                    className="icebreaker"
                    onClick={() => handleSelect(icebreaker.content, index)}
                    key={index}
                  >
                    {/* <Radio
                          checked={index === selectedIcebreaker}
                          disabled={true}
                          style={{ height: "fit-content" }}
                        /> */}
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      lineHeight={'24px'}
                    >
                      {icebreaker.content}
                    </Typography>
                  </div>
                ),
              )}
            </div>
          </>
          {/* {loadingLinkedinInformation || loadingIcebreakers ? (
            <CircularProgress />
          ) : (
            <GreyButton
              style={{ alignSelf: "flex-end", width: "fit-content" }}
              startIcon={<RegenerateIcon />}
              onClick={handleRegenerateIcebreakers}
            >
              Regenerate
            </GreyButton>
          )} */}
        </IcebreakersListWrapper>
      ) : null}
    </>
  );
};

export default IcebreakersList;
