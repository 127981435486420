import React, { FC } from 'react';
import { TokenErrorPageWrapper } from './styles';
import ResetPasswordFlowContainer from 'components/ResetPasswordFlowContainer';
import ImageForExpired from 'assets/images/ImageForExpired.png';
import useSendResetPasswordEmail from 'hooks/services/auth/useSendResetPasswordEmail';
import PATH from 'routing/path';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckYourEmail from '../../forgotPassword/checkYourEmail';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';

interface IProps {
  titles?: string[];
  subTitles?: string[];
}

const TokenErrorPage: FC<IProps> = ({ titles, subTitles }) => {
  const {
    mutate: sendResetPasswordEmail,
    isLoading,
    error,
    isSuccess: resetPasswordEmailSent,
  } = useSendResetPasswordEmail();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get('email');

  const resendLink = () => {
    if (email) {
      sendResetPasswordEmail({ userEmail: email });
    } else {
      navigate(PATH.FORGOT_PASSWORD);
    }
  };

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) ?? 'Error while sending reset password',
      severity: 'error',
    },
  ]);

  return resetPasswordEmailSent ? (
    <CheckYourEmail
      onClick={resendLink}
      isResending={isLoading}
      resetEmail={email}
    />
  ) : (
    <ResetPasswordFlowContainer>
      <TokenErrorPageWrapper>
        <img src={ImageForExpired} alt="no access to platform" />

        <div className="text">
          {titles?.map((text, index) => <h2 key={`h-${index}`}>{text}</h2>)}
          {subTitles?.map((text, index) => <p key={`p-${index}`}>{text}</p>)}
          <MainButton onClick={resendLink}>Resend link</MainButton>
        </div>
      </TokenErrorPageWrapper>
    </ResetPasswordFlowContainer>
  );
};

export default TokenErrorPage;
