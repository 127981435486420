import { ENDPOINTS } from 'config/endpoints';
import { useAsync } from 'hooks/useAsync';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';

const useDisconnectCustomEmailProviderIntegration = () => {
  const { error, isLoading, isError, isSuccess, run, data } = useAsync<{
    data: { isSuccess: boolean };
  }>();

  const disconnectCustomEmailProviderIntegration = () => {
    const request: Request = {
      url: ENDPOINTS.customEmailProvider.disconnect,
      config: {
        method: 'get',
        data: data,
      },
    };

    run(client(request));
  };

  return {
    disconnectCustomEmailProviderIntegration,
    error,
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

export default useDisconnectCustomEmailProviderIntegration;
