import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinIconMessageDark = ({
  color = colors.white,
  color2 = colors.blueHover,
  className,
  onClick,
  style,
  viewBox = '0 0 32 32',
}: IProps) => {
  return (
    <svg
      width="32"
      height="32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={className}
      onClick={onClick}
      {...style}
    >
      <rect width="32" height="32" rx="16" fill={color2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0859 12.833H15.1286V13.8505C15.4229 13.2653 16.1775 12.7395 17.3111 12.7395C19.2928 12.7395 19.8963 13.7083 19.9872 15.5C19.5 15.5 18 15.5 17.6975 15.5C17.5554 14.9321 17.2578 14.6249 16.7567 14.6249C15.7177 14.6249 15.286 15.3646 15.286 16.5279V20H13.0859V12.833ZM9.3135 19.9065H11.5135V12.7395H9.3135V19.9065ZM11.8287 10.4025C11.8287 10.5869 11.7922 10.7695 11.7211 10.9396C11.65 11.1098 11.5458 11.2641 11.4145 11.3936C11.1485 11.6579 10.7885 11.8059 10.4135 11.805C10.0392 11.8047 9.67997 11.6572 9.4136 11.3942C9.28282 11.2642 9.17896 11.1097 9.10799 10.9395C9.03702 10.7694 9.00032 10.5869 9 10.4025C9 10.0302 9.1485 9.67375 9.41415 9.41085C9.68029 9.14749 10.0396 8.99984 10.4141 9C10.7892 9 11.1488 9.14795 11.4145 9.41085C11.6796 9.67375 11.8287 10.0302 11.8287 10.4025Z"
        fill={color}
      />
      <path
        d="M16.834 17.8975V21.2223C16.834 21.8053 17.3065 22.2779 17.8896 22.2779H22.112C22.6951 22.2779 23.1676 21.8053 23.1676 21.2223V17.8978"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1867 19.7157L22.7722 18.6408C23.0188 18.474 23.1663 18.1957 23.1663 17.898V17.898C23.1663 17.4018 22.7644 17 22.2687 17H17.7355C17.2397 17 16.8379 17.4018 16.8379 17.8976V17.8976C16.8379 18.1953 16.9853 18.4736 17.232 18.6408L18.8175 19.7157C19.5329 20.2006 20.4713 20.2006 21.1867 19.7157V19.7157Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinIconMessageDark;
