import { Request } from 'crono-fe-common/types/request';
import client from 'utils/clients/client';
import { ENDPOINTS } from '../../../config/endpoints';
import { CustomEmailProviderSettings } from 'crono-fe-common/types/customEmailProviderSettings';

export type CustomEmailProviderVerifyIntegrationResult = {
  data: { isSuccess: boolean; data: CustomEmailProviderSettings | null };
};

class CustomEmailProviderService {
  private _alreadyRunningVerifyCustomEmailProviderIntegrationCall: Promise<CustomEmailProviderVerifyIntegrationResult> | null =
    null;

  public async verifyCustomEmailProviderIntegration(): Promise<CustomEmailProviderVerifyIntegrationResult> {
    if (this._alreadyRunningVerifyCustomEmailProviderIntegrationCall) {
      return await this._alreadyRunningVerifyCustomEmailProviderIntegrationCall;
    } else {
      this._alreadyRunningVerifyCustomEmailProviderIntegrationCall =
        this._verifyCustomEmailProviderIntegration();
      try {
        const verifyResult =
          await this._alreadyRunningVerifyCustomEmailProviderIntegrationCall;
        return verifyResult;
      } catch (e) {
        return {
          data: {
            isSuccess: false,
            data: null,
          },
        };
      } finally {
        this._alreadyRunningVerifyCustomEmailProviderIntegrationCall = null;
      }
    }
  }

  private async _verifyCustomEmailProviderIntegration(): Promise<CustomEmailProviderVerifyIntegrationResult> {
    const request: Request = {
      url: ENDPOINTS.customEmailProvider.verify,
      config: {
        method: 'get',
      },
    };

    const result = await client(request);
    return result as CustomEmailProviderVerifyIntegrationResult;
  }
}

// Esporto una sola istanza del servizio, in modo che tutti gli altri file che importano
// questo file importeranno sempre la stessa istanza
export default new CustomEmailProviderService();
