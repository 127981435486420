import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

// strategy is stopped for all prospects that it was applied upon
export default function useStopStrategy() {
  const queryClient = useQueryClient();

  const stopStrategy = (strategyId: number) => {
    const request: Request = {
      url: ENDPOINTS.sequence.stop,
      config: {
        method: 'delete',
        data: { strategyId },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    number
  >(stopStrategy, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.sequence.main]);
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
      queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
      queryClient.invalidateQueries([ENDPOINTS.task.total]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.detail, variables]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.strategy, variables]);
      queryClient.invalidateQueries([ENDPOINTS.prospect.search.strategy]);

      queryClient.invalidateQueries([ENDPOINTS.task.linkedin.quantity]);

      queryClient.invalidateQueries([ENDPOINTS.task.total]);
    },
  });

  return { data, mutate, ...rest };
}
