import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const LinkChainIconS = ({ color = colors.black, className }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            d="M10.6411 4.02448C11.5618 3.10382 13.0538 3.10382 13.9744 4.02448V4.02448C14.8951 4.94515 14.8951 6.43715 13.9744 7.35782L10.0551 11.2772C9.13442 12.1978 7.64242 12.1978 6.72175 11.2772V11.2772C5.80108 10.3565 5.80108 8.86448 6.72175 7.94382L7.30775 7.35782"
            stroke={color}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M5.35586 12.6424C4.4352 13.5631 2.9432 13.5631 2.02253 12.6424V12.6424C1.10186 11.7217 1.10186 10.2297 2.02253 9.30905L5.94186 5.38972C6.86253 4.46905 8.35453 4.46905 9.2752 5.38972V5.38972C10.1959 6.31039 10.1959 7.80239 9.2752 8.72305L8.6652 9.33305"
            stroke={color}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default LinkChainIconS;
