import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconCrono = ({ color = colors.white, className, onClick }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.54167 9.45898H0.294984C0.0620313 9.45898 -0.0769163 9.19936 0.0523027 9.00553L5.63232 0.635506C5.79239 0.395396 6.16667 0.508716 6.16667 0.797293V5.95898C6.16667 6.12007 6.29725 6.25065 6.45833 6.25065H9.69553C9.93038 6.25065 10.0689 6.51403 9.93588 6.70755L4.36535 14.8101C4.20307 15.0462 3.83333 14.9313 3.83333 14.6449V9.75065C3.83333 9.58957 3.70275 9.45898 3.54167 9.45898Z"
        fill="white"
      />
    </svg>
  );
};

export default IconCrono;
