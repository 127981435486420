import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { LogEmail } from 'crono-fe-common/types/logEmail';
import { FileContent } from 'use-file-picker';

export interface ScheduleEmailDto {
  attachments?: FileContent[];
  emailInfo: LogEmail;
  threadId?: string;
  scheduleDatetime: Date;
  attachmentIds?: number[];
}

export default function useScheduleEmail() {
  const queryClient = useQueryClient();

  const sendEmail = (scheduleEmailDto: ScheduleEmailDto) => {
    const request: Request = {
      url: `${ENDPOINTS.email.schedule}`,
      config: {
        method: 'post',
        data: { data: scheduleEmailDto },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    ScheduleEmailDto
  >(sendEmail, {
    onSettled: (_r, _e, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.email.schedule]);
      queryClient.invalidateQueries([ENDPOINTS.user.onboarding.features]);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.sequence.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.sequence.search]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.detail]);
      queryClient.invalidateQueries([ENDPOINTS.sequence.strategy]);
      queryClient.invalidateQueries([ENDPOINTS.task.search]);
      queryClient.invalidateQueries([ENDPOINTS.task.aggregate]);
      queryClient.invalidateQueries([ENDPOINTS.task.total]);
      queryClient.invalidateQueries([ENDPOINTS.task.automationErrors]);
      queryClient.invalidateQueries([
        ENDPOINTS.account.main,
        variables.emailInfo.accountId,
      ]);
      queryClient.invalidateQueries([ENDPOINTS.account.search.main]);
    },
  });

  return { data, mutate, ...rest };
}
