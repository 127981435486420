enum NotificationType {
  EMAIL_ANSWERED = 'EmailAnswered',
  LINKEDIN_ANSWERED = 'LinkedinAnswered',
  LINKEDIN_INVITATION_ACCEPTED = 'LinkedinInvitationAccepted',
  LINKEDIN_IN_MAIL_ANSWERED = 'LinkedinInMailAnswered',
  EMAIL_OPENED = 'EmailOpened',
  EMAIL_BOUNCED = 'EmailBounced',
  EMAIL_LINK_CLICKED = 'EmailLinkClicked',
}

export default NotificationType;
