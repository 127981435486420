import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export interface NotePatch {
  noteId: number;
  description?: string | null;
}

export default function usePatchNote() {
  const queryClient = useQueryClient();

  const patchNote = (updateNote: NotePatch) => {
    const request: Request = {
      url: `${ENDPOINTS.notes.main}`,
      config: {
        method: 'patch',
        data: { data: updateNote },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    NotePatch
  >(patchNote, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.notes.main]);
    },
  });

  return { data, mutate, ...rest };
}
