import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinIconInMailOnlyCompass = ({
  color = colors.mainDark,
  className,
  onClick,
  style,
  viewBox = '0 0 24 24',
  width = '24px',
  height = '24px',
}: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      {...style}
      id="linkedin-inmail-compass-icon"
    >
      <path
        id="path1_fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77002 12C1.77002 6.37093 6.37142 1.76953 12.0005 1.76953C17.6296 1.76953 22.231 6.37093 22.231 12C22.231 17.6291 17.6296 22.2305 12.0005 22.2305C6.37142 22.2305 1.77002 17.6291 1.77002 12ZM12.0005 3.79608C7.49065 3.79608 3.79657 7.49016 3.79657 12C3.79657 16.5098 7.49065 20.2039 12.0005 20.2039C16.5103 20.2039 20.2044 16.5098 20.2044 12C20.2044 7.49016 16.5103 3.79608 12.0005 3.79608Z"
        fill={color}
      />
      <path
        id="path2_fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1524 9.7323C15.3058 9.23495 14.8398 8.76891 14.3424 8.92229L10.5263 10.0991C10.3216 10.1623 10.1613 10.3226 10.0982 10.5273L8.92131 14.3434C8.76794 14.8407 9.23398 15.3068 9.73133 15.1534L13.5474 13.9766C13.7522 13.9134 13.9124 13.7531 13.9756 13.5484L15.1524 9.7323Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkedinIconInMailOnlyCompass;
