import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const AddOpportunityFormWrapper = styled.div`
  .form-add-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: hidden;
  }
  .autocomplete-paper {
    border: 1px solid #dadde9;
    border-radius: 8px;
    background: ${colors.white};
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    padding-inline: 6px;
    font-family: 'Poppins', sans-serif;
  }
  .autocomplete-option {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  .autocomplete-option:hover {
    background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .autocomplete-option:focus {
    // background: ${colors.primaryLight};
    color: ${colors.primary};
  }
  .info-icon {
    min-width: 32px;
    min-height: 32px;
    padding: 4px;
    border-radius: 999px;
    border: 2px solid ${colors.grey4};
    margin-right: 8px;
  }
  .tags-container {
    display: flex;
    column-gap: 12px;
  }
  .external-properties-tag {
    max-width: 50%;
    min-width: 80px;
  }
  .information-label {
    width: 100%;
    color: ${colors.grey11};
    margin-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .field-input {
    // max-width: 100%;
    border-radius: 8px;
    padding: 4px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-input {
      padding: 0px;
    }
    & .MuiSelect-select {
      padding: 0px 30px 0px 4px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .field-input:hover {
    background: ${colors.grey6};
  }
  .default-pipeline {
    border: 1px solid ${colors.greyFieldBorder};
    border-radius: 12px;
    padding: 16px 14px;
  }
  .more-fields-button {
    text-transform: unset !important;
    color: ${colors.grey11};
    width: fit-content;
    align-self: center;
  }
`;
