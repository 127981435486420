import { Tooltip } from '@mui/material';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import React from 'react';

import { ReactComponent as IconHubspotResync } from 'crono-fe-common/icons/Resync-Hubspot.svg';
import { ReactComponent as IconSalesforceResync } from 'crono-fe-common/icons/Resync-Salesforce.svg';
import { ReactComponent as IconPipedriveResync } from 'crono-fe-common/icons/Resync-Pipedrive.svg';

interface IProps {
  CRM: IntegrationType;
  onClick?: () => void;
  title?: string | null;
}

const ResyncButton = ({ CRM, onClick, title }: IProps) => {
  switch (CRM) {
    case IntegrationType.HUBSPOT:
      return (
        <Tooltip
          arrow
          enterDelay={400}
          enterNextDelay={400}
          title={title ?? 'Synchronize tags with CRM'}
        >
          <div style={{ height: 24 }}>
            <IconHubspotResync onClick={onClick} />
          </div>
        </Tooltip>
      );
    case IntegrationType.SALESFORCE:
      return (
        <Tooltip
          arrow
          enterDelay={400}
          enterNextDelay={400}
          title={title ?? 'Synchronize tags with CRM'}
        >
          <div style={{ height: 24 }}>
            <IconSalesforceResync onClick={onClick} />
          </div>
        </Tooltip>
      );
    case IntegrationType.PIPEDRIVE:
      return (
        <Tooltip
          arrow
          enterDelay={400}
          enterNextDelay={400}
          title={title ?? 'Synchronize tags with CRM'}
        >
          <div style={{ height: 24 }}>
            <IconPipedriveResync onClick={onClick} />
          </div>
        </Tooltip>
      );
    default:
      return null;
  }
};

export default ResyncButton;
