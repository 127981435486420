import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconEmailClickedLink = ({
  color = colors.black,
  className,
  onClick,
  style,
  fill = 'white',
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        id="Path"
        d="M10.3814 1.32147C11.21 0.492844 12.5529 0.492844 13.3815 1.32147C14.2101 2.15009 14.2101 3.49293 13.3815 4.32155L10.203 7.5M6.85389 7.84905C6.32489 7.32006 6.13361 6.58149 6.28004 5.90093C6.30945 5.76423 6.3525 5.62986 6.40916 5.5C6.51228 5.2637 6.66052 5.04233 6.85389 4.84897L7.3813 4.32155"
        stroke={color}
        strokeWidth="1.26004"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M9.14905 5.55103C9.97768 4.72241 9.97768 3.37957 9.14905 2.55095C8.32043 1.72232 6.97759 1.72232 6.14897 2.55095L2.62147 6.07845C1.79284 6.90707 1.79284 8.24991 2.62147 9.07853C3.04015 9.49722 3.59012 9.70435 4.13871 9.69993"
        stroke={color}
        strokeWidth="1.26004"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        id="little-hand-click"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39788 10.2115V7.65288C8.39788 7.18209 8.01579 6.8 7.54499 6.8C7.0742 6.8 6.69211 7.18209 6.69211 7.65288V11.9173L5.53673 11.5324C5.19956 11.4198 4.8277 11.5079 4.57582 11.7592C4.1852 12.1499 4.21476 12.7907 4.63836 13.1443L6.944 15.0656C7.14926 15.2356 7.40683 15.3288 7.67236 15.3288H10.2367C10.8104 15.3288 11.2937 14.9018 11.3654 14.3327L11.6741 11.8633C11.7497 11.2577 11.3341 10.7011 10.7325 10.6005L8.39788 10.2115Z"
        fill={fill}
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconEmailClickedLink;
