export enum ImportBehavior {
  IgnoreDuplicates,
  UpdateDuplicates,
  UpdateDuplicatesAndChangeOwnership,
}

export const getImportBehaviorText = (importType: ImportBehavior) => {
  switch (importType) {
    case ImportBehavior.IgnoreDuplicates:
      return 'Ignore duplicates';
    case ImportBehavior.UpdateDuplicates:
      return 'Update duplicates';
    case ImportBehavior.UpdateDuplicatesAndChangeOwnership:
      return 'Update duplicates and change ownership';
  }
};
