import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  getError,
  getNewOptionsWhenSelectingWithStrings,
  mapProspectToProspectLinkedinAndCorrect,
} from 'crono-fe-common/utils';
import { ProspectInputs, initialValues, validationSchema } from './model';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useFormik } from 'formik';
import { trimObject } from 'crono-fe-common/utils/object';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CancelButton,
  MainButton,
  TabSelectionButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useConditionalSnackBar } from 'context/snackbar';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { InsertContactWrapper } from './style';
import { colors } from 'crono-fe-common/theme';
import { Account, AccountStatus } from 'crono-fe-common/types/account';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import { ReactComponent as ToolsIcon } from 'crono-fe-common/icons/Tools.svg';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import useCreateProspect from 'hooks/services/prospect/useCreateProspect';
import useSearchStrategies from 'hooks/services/sequence/useSearchStrategies';
import SequenceCard from 'pages/dashboard/contactTable/sequenceCard';
import FindEmailPreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findEmailPreconditionButton';
import useAccounts from 'hooks/services/account/useAccounts';
import Role from 'crono-fe-common/types/enums/role';
import useAccountLinkedin from 'hooks/services/account/useAccountLinkedin';
import { FeConstants } from 'constants/FeConstants';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import ExternalPropertyNotTag from 'pages/accountTab/externalPropertyNotTag';
import { IExternalValueInsert } from 'pages/searchComponent/searchCompanyTab/insertCompany/model';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import {
  useLinkedinGetCompanyInfo,
  useLinkedinGetProfileInfo,
  useLinkedinGetPublicIdFromLeadId,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import { Constants } from 'crono-fe-common/constants/constants';
import { prospectNameFromLinkedinProfile } from 'utils/fe-utils';
import FindPhonePreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findPhonePreconditionButton';
import VerificationStatusType, {
  EmailScrapeResult,
} from 'crono-fe-common/types/enums/verificationStatusType';
import useFindVerify from 'hooks/services/scrape/useFindVerify';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import { Strategy } from 'crono-fe-common/types/strategy';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { SequenceSelectorWrapper } from 'pages/searchComponent/style';
import MobilePhoneIcon from 'crono-fe-common/icons/Icon-Mobile-Phone';
import { Add } from '@mui/icons-material';
import { renderContactInformationIcon } from 'pages/accountTab/contactView/iconWithValidityBadge';
import { Prospect } from 'crono-fe-common/types/prospect';
import { ContactForSearchTable } from '../model';
import { ProspectLinkedin } from 'crono-fe-common/types/prospectLinkedin';

interface IProps {
  selectedContact: ContactForSearchTable;
  close: () => void;
  selectedCompanyCrono?: Account | null;
  setContact: (contact: ContactForSearchTable) => void;
  handleSetInCrono: (id: string, newContact: ProspectLinkedin | null) => void;
}

interface IInformation {
  name: keyof ProspectInputs;
  label?: string;
  icon: JSX.Element;
}

const contactInformation: IInformation[] = [
  {
    name: 'phone',
    label: 'Phone',
    icon: <CallIcon className="info-icon" />,
  },
  {
    name: 'mobilePhone',
    icon: <MobilePhoneIcon className="info-icon" />,
  },
  {
    name: 'email',
    label: 'Email',
    icon: <EmailIcon className="info-icon" />,
  },
  {
    name: 'linkedin',
    label: 'LinkedIn',
    icon: <LinkedinIcon className="info-icon" />,
  },
];

const prospectInformation: IInformation[] = [
  {
    name: 'firstName',
    label: 'First Name',
    icon: <ToolsIcon className="info-icon" />,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    icon: <ToolsIcon className="info-icon" />,
  },
  {
    name: 'title',
    label: 'Title',
    icon: <ToolsIcon className="info-icon" />,
  },
  // {
  //   name: "company",
  //   label: "Company",
  //   icon: <ToolsIcon className="info-icon" />,
  // },
];

const selectCustomMenuProps = {
  style: {
    zIndex: 35001,
  },
  PaperProps: {
    sx: {
      borderRadius: '8px',
      paddingInline: '6px',
      border: '1px solid #dadde9',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      '& .MuiMenuItem-root': {
        fontSize: '12px',
        lineHeight: '16px',
        height: 'fit-content',
        padding: '8px',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '8px',
        '&:hover': {
          background: colors.primaryLight,
          color: colors.primary,
        },
      },
      '& .Mui-selected': {
        color: colors.primary,
        backgroundColor: 'transparent !important',
      },
    },
  },
};

const InsertContact = ({
  selectedContact,
  close,
  selectedCompanyCrono,
  setContact,
  handleSetInCrono,
}: IProps) => {
  const { hasSalesNavWithCookie } = useAuth();
  const {
    isSuccess,
    isLoading,
    mutateAsync: createProspect,
    error: errorCreateProspect,
  } = useCreateProspect();
  const { call: getLinkedinProfileInfo, result: additionalInformation } =
    useLinkedinGetProfileInfo();

  const currentCompanyInfo = useMemo(() => {
    if (hasSalesNavWithCookie) {
      return {
        name: selectedContact.companyName,
        url: null,
        numericId: selectedContact.companyNumericId,
      };
    } else {
      if (!additionalInformation) return null;
      return additionalInformation?.profile?.currentCompany ?? null;
    }
  }, [hasSalesNavWithCookie, additionalInformation, selectedContact]);

  const { data: companiesFound } = useAccountLinkedin({
    name: currentCompanyInfo?.name ?? '',
    ...(!hasSalesNavWithCookie && {
      publicId:
        LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(
          currentCompanyInfo?.url,
        )[0] ?? '',
    }),
    ...(hasSalesNavWithCookie && {
      numericId: currentCompanyInfo?.numericId ?? '',
    }),
    searchInCrm: false,
    enabled: !selectedCompanyCrono && !!currentCompanyInfo,
  });

  const showCreateNew = useMemo(() => {
    return (companiesFound?.data?.data.findIndex(
      (company) => company.correct,
    ) ?? 0) > -1
      ? false
      : true;
  }, [companiesFound]);

  const { user, highestRole } = useAuth();
  const analytics = useJuneAnalytics();
  const [createAsLead, setCreateAsLead] = useState(false);
  const [enableCreateAsLead, setEnableCreateAsLead] = useState(true);

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  //Additional information that I can obtain from the scape of the email when the scrape
  //is done from the create new tab
  const [accountInformationFromScrape, setAccountInformationFromScrape] =
    useState<{
      website: string | null;
      nOfEmployees: number | null;
      industry: string | null;
    }>({ website: null, nOfEmployees: null, industry: null });

  const [errorMandatoryFields, setErrorMandatoryFields] = useState<
    string | null
  >(null);

  //To clear the error after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorMandatoryFields) {
      timeout = setTimeout(() => {
        setErrorMandatoryFields(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [errorMandatoryFields]);

  const { call: getCompanyInfo, isLoading: isLoadingGetCompanyInfo } =
    useLinkedinGetCompanyInfo();
  const {
    call: getPublicIdFromLeadId,
    isLoading: isLoadingGetPublicIdFromLeadId,
  } = useLinkedinGetPublicIdFromLeadId();

  //Initially set the value of the linkedin if we passed a leadId
  useEffect(() => {
    if (selectedContact.leadId && !selectedContact.url) {
      //If we have the leadId but not the url we can generate it
      getPublicIdFromLeadId({
        leadId: selectedContact.leadId,
      }).then((data) => {
        if (data?.publicId)
          formik.setFieldValue(
            'linkedin',
            `https://www.linkedin.com/in/${data?.publicId}`,
          );
      });
    }
  }, [selectedContact.leadId]);

  const formik = useFormik<ProspectInputs>({
    enableReinitialize: true,
    initialValues:
      {
        firstName: selectedContact.firstName,
        lastName: selectedContact.lastName,
        linkedin: selectedContact.url,
        title:
          selectedContact.title ?? selectedContact.linkedinDescription ?? null,
        company: '',
        email: selectedContact.email ?? '',
        phone: selectedContact.phone ?? '',
        mobilePhone: '',
      } || initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const trimmed = trimObject(values);

      const externalValuesToAdd: {
        [key: number]: string | null;
      } = {};
      externalValuesTag.forEach((value, index) => {
        if (value && externalPropertiesTag[index].id) {
          externalValuesToAdd[externalPropertiesTag[index].id] = value;
        }
      });
      externalValuesNotTag.forEach((value) => {
        if (value && value.value !== '') {
          externalValuesToAdd[value.externalPropertyId] = value.value;
        }
      });
      //Check if the mandatory fields are filled
      const mandatoryToFill: string[] = [];
      externalProperties?.data?.data.forEach((property) => {
        if (
          property.tableType !== 'Account' &&
          property.isMandatory &&
          !externalValuesToAdd[property.id]
        ) {
          mandatoryToFill.push(
            property.publicName ?? property.externalName ?? 'Unnamed property',
          );
        }
      });
      if (mandatoryToFill.length > 0) {
        setErrorMandatoryFields(mandatoryToFill.join(', '));
        return;
      }
      if (analytics) {
        analytics.track('create-prospect', {});
      }
      //If there is no companyLinkedin we can try to get it from the numericId
      if (!trimmed.companyLinkedin && trimmed.companyLinkedinNumericId) {
        const companyInfo = await getCompanyInfo({
          numericId: trimmed.companyLinkedinNumericId,
        });
        if (companyInfo?.company?.url) {
          trimmed.companyLinkedin = companyInfo.company.url;
        }
        trimmed.companyWebsite = companyInfo?.company?.website;
        trimmed.companyNumberOfEmployees =
          companyInfo?.company?.numberOfEmployees;
      }

      const prospectCreationObject = {
        ...trimmed,
        createAsLead,
        externalValues: externalValuesToAdd,
        linkedinLeadId: selectedContact.leadId,
        countryCode: additionalInformation?.profile?.countryCode,
        timeZone: additionalInformation?.profile?.timeZone,
        ...(companyValue === -2 &&
          accountInformationFromScrape.website && {
            companyWebsite: accountInformationFromScrape.website,
          }),
        ...(companyValue === -2 &&
          accountInformationFromScrape.nOfEmployees && {
            companyNumberOfEmployees: accountInformationFromScrape.nOfEmployees,
          }),
        ...(selectedStrategyId &&
          selectedStrategyId !== -1 && { strategyId: selectedStrategyId }),
      };
      const result = await createProspect(prospectCreationObject);
      const newContact = mapProspectToProspectLinkedinAndCorrect(
        result.data?.data ?? null,
      );
      handleSetInCrono(selectedContact.id, newContact);
      close();
    },
  });

  const [showLinkedinMemberError, setShowLinkedinMemberError] =
    useState<boolean>(false);

  useEffect(() => {
    //If the user is a salesNav we already have the information needed
    if (hasSalesNavWithCookie) return;
    if (
      selectedContact.url ===
      'https://www.linkedin.com/search/results/people/headless'
    ) {
      setShowLinkedinMemberError(true);
      setTimeout(() => {
        setShowLinkedinMemberError(false);
      }, 3000);
      setTimeout(() => {
        close();
      }, 200);
    }
  }, [selectedContact.url]);

  //We call the getProfile to retrieve the countryCode and the timeZone
  useEffect(() => {
    if (!selectedContact.url) return;
    getLinkedinProfileInfo({ url: selectedContact.url });
  }, [selectedContact.url]);

  useEffect(() => {
    if (!currentCompanyInfo) return;
    if (selectedCompanyCrono) {
      formik.setFieldValue('company', selectedCompanyCrono.name);
      formik.setFieldValue('accountId', selectedCompanyCrono.objectId);
    } else {
      formik.setFieldValue('company', currentCompanyInfo.name);
      formik.setFieldValue(
        'companyLinkedinNumericId',
        currentCompanyInfo.numericId,
      );
      formik.setFieldValue('companyLinkedin', currentCompanyInfo.url);
    }
  }, [currentCompanyInfo]);

  const { data: externalProperties } = useGetExternalProperty(
    createAsLead ? 'Lead' : 'Prospect',
    null,
    true,
  );

  const externalPropertiesTag: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter(
        (property) => property.isTag && property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  const externalPropertyNotTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter(
        (property) => !property.isTag && property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  //Initialize the externalValuesTag that has default value
  useEffect(() => {
    if (!externalPropertiesTag) return;
    const newExternalProperties: string[] = new Array(
      Constants.numberOfTags,
    ).fill(null);
    externalPropertiesTag.forEach((property, index) => {
      if (property.defaultValue) {
        newExternalProperties[index] = property.defaultValue;
      }
    });
    setExternalValuesTag(newExternalProperties);
  }, [externalPropertiesTag]);

  //Initialize the externalValuesNotTag that has default value
  useEffect(() => {
    if (!externalPropertyNotTags) return;
    const newExternalProperties: IExternalValueInsert[] = [];
    externalPropertyNotTags.forEach((property) => {
      if (property.defaultValue) {
        newExternalProperties.push({
          externalPropertyId: property.id,
          value: property.defaultValue,
          isTag: false,
        });
      }
    });
    setExternalValuesNotTag(newExternalProperties);
  }, [externalPropertyNotTags]);

  useEffect(() => {
    if (user && user.company?.integrationType === IntegrationType.SALESFORCE) {
      setCreateAsLead(true);
    }
  }, [user]);

  useConditionalSnackBar([
    {
      condition: !!errorCreateProspect,
      message:
        'Insert contact error: ' + getError(errorCreateProspect) ??
        'Unknown error',
      severity: 'error',
    },
    {
      condition: !!isSuccess,
      message: 'Contact created succesfully',
      severity: 'success',
    },
    {
      condition: showLinkedinMemberError,
      message: 'Cannot insert Linkedin Members',
      severity: 'error',
    },
    {
      condition: !!errorMandatoryFields,
      message: `The empty fields ${errorMandatoryFields} are mandatory. Add a value to continue`,
      severity: 'error',
    },
  ]);

  const handleChangeCreateAsLead = () => {
    const newLead = !createAsLead;
    setCreateAsLead(newLead);
    patchUserPreferences({
      createAsLead: newLead,
    });
    if (user) {
      if (user.userPreferences) {
        user.userPreferences.createAsLead = newLead;
      } else {
        user.userPreferences = {
          createAsLead: newLead,
        };
      }
    }
  };

  const { mutateAsync: verifyEmail } = useFindVerify();

  const handleChangeInformation = async (event: any) => {
    const { name, value } = event.target;
    let scrapeResult = null;
    if (name === 'email') {
      try {
        const { data } = await verifyEmail({
          email: value,
        });
        if (data?.isSuccess && data?.data.status) {
          scrapeResult = data.data.status ?? VerificationStatusType.UNKNOWN;
        } else {
          scrapeResult = VerificationStatusType.UNKNOWN;
        }
      } catch (_) {
        scrapeResult = VerificationStatusType.UNKNOWN;
      }
      formik.setFieldValue('emailStatus', scrapeResult);
    }
    formik.setFieldValue(name, value);
  };

  const [externalValuesTag, setExternalValuesTag] = useState<(string | null)[]>(
    new Array(Constants.numberOfTags).fill(null),
  );

  const [search, setSearch] = useState<string>('');
  const { data: strategies } = useSearchStrategies({
    limit: FeConstants.strategyLimit,
    name: search,
    filterByTags: true,
    offset: 0,
  });

  const [selectedStrategyId, setSelectedStrategyId] = useState<number | null>(
    -1,
  );

  const selectedStrategy = useMemo(() => {
    return strategies?.data?.data.find(
      (strategy) => strategy.id === selectedStrategyId,
    );
  }, [strategies, selectedStrategyId]);

  const handleSelectStrategy = (strategyId: number) => {
    setSelectedStrategyId(strategyId);
  };

  const [mailScraped, setMailScraped] = useState<EmailScrapeResult | null>(
    null,
  );
  useEffect(() => {
    if (mailScraped) {
      formik.setFieldValue('email', mailScraped.email);
      formik.setFieldValue('emailStatus', mailScraped.verificationStatus);
      setContact({
        ...selectedContact,
        email: mailScraped.email,
        emailStatus: mailScraped.verificationStatus,
      });
    }
  }, [mailScraped]);

  const [phoneScraped, setPhoneScraped] = useState<string | null>(null);
  useEffect(() => {
    if (phoneScraped) {
      formik.setFieldValue('phone', phoneScraped);
      setContact({
        ...selectedContact,
        phone: phoneScraped,
      });
    }
  }, [phoneScraped]);

  //Company selection
  const [companyValue, setCompanyValue] = useState<number>(
    selectedCompanyCrono ? -3 : 0,
  );

  //To avoid reinitializing the select after the first render
  const initialSetDone = useRef(false);

  // When companies are received I update the select, showing the first correct found if any
  useEffect(() => {
    if (companiesFound && !initialSetDone.current) {
      initialSetDone.current = true;
      const isCorrect = companiesFound.data.data.find(
        (company) => company.correct,
      );
      setCompanyValue(isCorrect ? 0 : -2);
    }
  }, [companiesFound]);

  //CompanySelect
  const [searchCompanyText, setSearchCompanyText] = useState<string>('');
  const { data: accounts } = useAccounts({
    name: searchCompanyText,
    limit: 25,
    status: null,
    IsManager: highestRole === Role.MANAGER,
  });

  const mappedAccount = useMemo(() => {
    if (!accounts || !accounts.data) return [];
    return accounts.data?.data.map((acc) => {
      return acc;
    });
  }, [accounts]);

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  //Here I have the acconut to insert, with the accountId
  const selectedAccount = useMemo(() => {
    const acc = mappedAccount.find((el) => el.objectId === selectedCompany);
    return acc;
  }, [selectedCompany, mappedAccount]);

  useEffect(() => {
    if (companyValue === -1) {
      if (selectedAccount) {
        formik.setFieldValue('company', selectedAccount.name);
        formik.setFieldValue('accountId', selectedAccount.objectId);
      } else {
        formik.setFieldValue('company', null);
        formik.setFieldValue('accountId', null);
      }
    }
  }, [selectedAccount]);

  //Account selected between the ones suggested by the system
  const [pickedSuggestedAccount, setPickedSuggestedAccount] =
    useState<Account | null>(null);

  useEffect(() => {
    if (selectedCompanyCrono && !selectedCompanyCrono.isSynced) {
      setEnableCreateAsLead(false);
      setCreateAsLead(true);
    }
  }, [selectedCompanyCrono]);

  useEffect(() => {
    if (selectedAccount) {
      if (!selectedAccount.isSynced) {
        setEnableCreateAsLead(false);
        setCreateAsLead(true);
      } else {
        setEnableCreateAsLead(true);
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (pickedSuggestedAccount) {
      if (!pickedSuggestedAccount.isSynced) {
        setEnableCreateAsLead(false);
        setCreateAsLead(true);
      } else {
        setEnableCreateAsLead(true);
      }
    }
  }, [pickedSuggestedAccount]);

  //When the action to perform regarding the company is changed
  useEffect(() => {
    if (companyValue === -1) {
      //Select existing
      formik.setFieldValue('company', null);
      formik.setFieldValue('accountId', null);
    } else if (companyValue === -2) {
      //Create new
      if (!currentCompanyInfo) return;
      formik.setFieldValue('company', currentCompanyInfo.name);
      formik.setFieldValue(
        'companyLinkedinNumericId',
        currentCompanyInfo.numericId,
      );
      formik.setFieldValue('companyLinkedin', currentCompanyInfo.url);
      // } else if (companyValue === 0) {
      //   console.log("select company from linkedin");
      //   const;
    } else if (companyValue === -3) {
      if (!selectedCompanyCrono) return;
      formik.setFieldValue('company', selectedCompanyCrono.name);
      formik.setFieldValue('accountId', selectedCompanyCrono.objectId);
    } else {
      //Selected a company from the list
      if (!companiesFound || !companiesFound?.data?.data) return;
      const acc = companiesFound.data?.data[companyValue];
      setPickedSuggestedAccount({
        objectId: acc?.objectId,
        name: acc?.name,
        website: acc?.website,
        industry: acc?.industry,
        numberOfEmployees: acc?.numberOfEmployees,
        linkedin: acc?.linkedin,
        linkedinNumericId: acc?.linkedinNumericId,
        externalValues: null,
        actualStatus: acc?.actualStatus ?? AccountStatus.WORKING,
        currentSolution: '',
        owned: acc?.owner,
        id: -1,
        ownerId: '',
        user: '',
        userId: -1,
        prospects: null,
        isSynced: acc?.isSynced,
      });
      formik.setFieldValue('company', acc?.name);
      formik.setFieldValue('accountId', acc?.objectId);
    }
  }, [companyValue]);

  const [externalValuesNotTag, setExternalValuesNotTag] = useState<
    IExternalValueInsert[]
  >([]);

  const handleSetExternalValueNotTag = (
    externalPropertyId: number,
    value: string,
  ) => {
    const newValues = [...externalValuesNotTag];
    const newExternalValue = {
      externalPropertyId,
      value: value,
      isTag: false,
    };
    const oldValue = newValues.filter(
      (val) => val.externalPropertyId === externalPropertyId,
    );
    if (oldValue.length > 0) {
      newValues.splice(newValues.indexOf(oldValue[0]), 1);

      newValues.push(newExternalValue);

      setExternalValuesNotTag(newValues);
    } else {
      newValues.push(newExternalValue);
      setExternalValuesNotTag(newValues);
    }
  };

  const handleAddAccountInformation = (
    website: string | null,
    nOfEmployees: number | null,
    industry: string | null,
  ) => {
    setAccountInformationFromScrape({
      website,
      nOfEmployees,
      industry,
    });
  };

  const accountForFindButton = useMemo(
    () =>
      companyValue === -3
        ? selectedCompanyCrono
        : companyValue === -1
          ? selectedAccount
          : companyValue >= 0
            ? pickedSuggestedAccount
            : null,
    [
      companyValue,
      selectedCompanyCrono,
      selectedAccount,
      pickedSuggestedAccount,
    ],
  );

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const finalOption = getNewOptionsWhenSelectingWithStrings({
      currentValue: externalValuesTag[index],
      option,
      valueType: externalProperty.valueType,
    });
    const newExternalValues = [...externalValuesTag];
    newExternalValues[index] = finalOption;
    setExternalValuesTag(newExternalValues);
  };

  return (
    <BlurBackgroundDivFixed>
      <InsertContactWrapper>
        <CloseTabButton close={close} style={{ paddingRight: '16px' }} />

        <form onSubmit={formik.handleSubmit} className="insert-form">
          <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
            Insert Contact:
          </Typography>
          {user?.company?.integrationType === IntegrationType.SALESFORCE && (
            <div>
              <TabSelectionButton
                selected={!createAsLead}
                sx={{ position: 'relative', marginLeft: 0 }}
                size="large"
                onClick={() => handleChangeCreateAsLead()}
                disableElevation
                disabled={!enableCreateAsLead}
              >
                Create as contact
              </TabSelectionButton>
              <TabSelectionButton
                selected={createAsLead}
                size="large"
                sx={{ position: 'relative', margin: '6px', right: 10 }}
                onClick={() => handleChangeCreateAsLead()}
                disableElevation
              >
                Create as lead
              </TabSelectionButton>
            </div>
          )}
          <div className="form-fields-container">
            <div className="prospect-info-fields">
              {prospectInformation.map((field, index) => {
                const link = formik.values[field.name] || '';
                return (
                  <div className="information-editable-container" key={index}>
                    <Typography className="information-label" fontSize={'14px'}>
                      {field.label}:
                    </Typography>
                    <div className="editable-div">
                      <TextField
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        name={field.name}
                        autoComplete="off"
                        fullWidth
                        value={link || ''}
                        placeholder="Add information..."
                        onChange={(ev) =>
                          formik.setFieldValue(field.name, ev.target.value)
                        }
                        error={
                          formik.touched[field.name] &&
                          Boolean(formik.errors[field.name])
                        }
                        helperText={
                          formik.touched[field.name] &&
                          formik.errors[field.name]
                        }
                      ></TextField>
                    </div>
                  </div>
                );
              })}
            </div>
            <Avatar
              src={selectedContact.pictureUrl ?? ''}
              className="contact-logo"
            />
          </div>

          <div className="divider" />

          <div className="additional-information-container">
            {contactInformation.map((field, index) => {
              const disableEmailFindButton = formik.values['email'] !== '';
              const disablePhoneFindButton = formik.values['phone'] !== '';

              return (
                <div className="contact-info-fields-container" key={index}>
                  {field.name &&
                    renderContactInformationIcon(
                      {
                        name: field.name as keyof Prospect,
                        icon: field.icon,
                      },
                      formik.values,
                      null,
                    )}
                  <div
                    className="editable-div"
                    style={{
                      overflow: 'hidden',
                      width: '100%',
                      marginRight: '12px',
                    }}
                  >
                    <TextField
                      name={field.name}
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      fullWidth
                      placeholder="Insert a new information..."
                      onChange={(ev) =>
                        formik.setFieldValue(field.name, ev.target.value)
                      }
                      value={formik.values[field.name]}
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChangeInformation(ev);
                        }
                      }}
                      onBlur={(ev: any) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChangeInformation(ev);
                      }}
                    />
                    <div className="icons-wrapper">
                      <span className="edit-icon">
                        <EditIcon color={colors.grey2} />
                      </span>
                    </div>
                  </div>
                  {field.name === 'email' && (
                    <FindEmailPreconditionButton
                      account={accountForFindButton}
                      setEmail={setMailScraped}
                      hasLabel={true}
                      disabled={disableEmailFindButton}
                      iconColor={colors.white}
                      prospectInputs={formik.values}
                      accountInputs={currentCompanyInfo || null}
                      handleAddAccountInformation={handleAddAccountInformation}
                    />
                  )}
                  {field.name === 'phone' && (
                    <FindPhonePreconditionButton
                      account={accountForFindButton}
                      setPhone={setPhoneScraped}
                      hasLabel={true}
                      disabled={disablePhoneFindButton}
                      iconColor={colors.white}
                      prospectInputs={formik.values}
                      accountInputs={currentCompanyInfo || null}
                      handleAddAccountInformation={handleAddAccountInformation}
                    />
                  )}
                </div>
              );
            })}
          </div>

          {/*Company options */}
          <div className="company-options">
            {selectedCompanyCrono ? (
              <div className="company-label-field-wrapper">
                <Typography className="information-label" fontSize={'14px'}>
                  Company
                </Typography>
                <div
                  className="editable-div"
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    border: `1px solid ${colors.grey4}`,
                    borderRadius: '8px',
                  }}
                >
                  <TextField
                    className="text-field field-input"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    name={'company'}
                    autoComplete="off"
                    fullWidth
                    value={formik.values.company || ''}
                    placeholder="Add information..."
                    disabled={true}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="company-label-field-wrapper">
                  <Typography className="information-label" fontSize={'14px'}>
                    Company
                  </Typography>
                  <div
                    className="editable-div"
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      border: `1px solid ${colors.grey4}`,
                      borderRadius: '8px',
                    }}
                  >
                    <Select
                      className="field-input"
                      MenuProps={selectCustomMenuProps}
                      variant="outlined"
                      sx={{
                        boxShadow: 'none',
                        '.MuiOutlinedInput-input': {
                          padding: '0px !important',
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 0,
                          borderColor: 'transparent !important',
                        },
                        minWidth: '180px !important',
                      }}
                      fullWidth
                      size={'small'}
                      value={companyValue}
                    >
                      {companiesFound?.data?.data.map((account, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={index}
                            onClick={() => {
                              setCompanyValue(index);
                            }}
                          >
                            {account.name}
                          </MenuItem>
                        );
                      })}
                      <MenuItem
                        value={-1}
                        onClick={() => {
                          setCompanyValue(-1);
                        }}
                      >
                        Select existing
                      </MenuItem>
                      {showCreateNew && (
                        <MenuItem
                          value={-2}
                          onClick={() => {
                            setCompanyValue(-2);
                          }}
                        >
                          Create new: {currentCompanyInfo?.name}
                        </MenuItem>
                      )}
                    </Select>
                  </div>
                </div>
                {companyValue === -1 && (
                  <div className="company-label-field-wrapper">
                    <Typography className="information-label" fontSize={'14px'}>
                      Select existing
                    </Typography>
                    <div
                      className="editable-div"
                      style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        borderRadius: '8px',
                      }}
                    >
                      <Autocomplete
                        id="companySelect"
                        className="add-new-auto-complete"
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                        onChange={(e, value) => {
                          if (value) setSelectedCompany(value.id);
                          else setSelectedCompany(null);
                        }}
                        options={mappedAccount.map((acc) => {
                          return { id: acc.objectId, name: acc.name };
                        })}
                        getOptionLabel={(option) => option.name || ''}
                        PaperComponent={({ children }) => (
                          <div className="autocomplete-paper">{children}</div>
                        )}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            value={option.id || undefined}
                            className="autocomplete-option"
                          >
                            {option.name}
                          </li>
                        )}
                        sx={{
                          width: '100%',
                        }}
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="standard"
                            placeholder="Type company name..."
                            value={searchCompanyText}
                            onChange={(e: any) =>
                              setSearchCompanyText(e.target.value)
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
                {companyValue === -2 && (
                  <div className="company-label-field-wrapper">
                    <Typography className="information-label" fontSize={'14px'}>
                      Company name
                    </Typography>
                    <div
                      className="editable-div"
                      style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        border: `1px solid ${colors.grey4}`,
                        borderRadius: '8px',
                      }}
                    >
                      <TextField
                        className="text-field field-input"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        sx={{
                          '.MuiInput-root': {
                            paddingLeft: '8px',
                          },
                        }}
                        name={'company'}
                        onChange={(ev) =>
                          formik.setFieldValue('company', ev.target.value)
                        }
                        autoComplete="off"
                        fullWidth
                        value={formik.values.company || ''}
                        placeholder="Add information..."
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="divider" />

          <div
            className="information-editable-container"
            style={{
              width: 'fit-content',
              marginBlock: 6,
            }}
          >
            <Select
              IconComponent={() => null}
              className="green-sequence-button"
              MenuProps={{
                ...selectCustomMenuProps,
                PaperProps: {
                  sx: {
                    ...selectCustomMenuProps.PaperProps.sx,
                    minWidth: '400px !important',
                    maxHeight: '500px !important',
                  },
                },
              }}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-input': { padding: '0px !important' },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                  borderColor: 'transparent !important',
                },
              }}
              fullWidth
              size={'small'}
              placeholder="Select contact strategy"
              onChange={(ev) =>
                handleSelectStrategy(ev.target.value as any as number)
              }
              value={selectedStrategyId}
              renderValue={(value) => (
                <div
                  style={{
                    display: 'flex',
                    width: 'fit-content',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <span className="icon-container">
                    <Add fontSize="small" />
                  </span>
                  <Typography fontWeight={500}>
                    {selectedStrategy?.name || 'Add to strategy'}
                  </Typography>
                </div>
              )}
            >
              <SequenceSelectorWrapper
                onKeyDown={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <SearchIcon color={colors.grey11} />
                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment:
                      search === '' ? null : (
                        <CloseMIcon
                          className="remove-text-icon"
                          color={colors.grey11}
                          onClick={() => setSearch('')}
                        />
                      ),
                  }}
                  placeholder="Search..."
                  value={search}
                  onChange={(ev) => {
                    setSearch(ev.target.value);
                  }}
                  style={{
                    marginLeft: '12px',
                    marginTop: '4px',
                    width: '100%',
                  }}
                />
              </SequenceSelectorWrapper>
              <MenuItem
                value={-1}
                style={{ fontWeight: 700, paddingLeft: '12px' }}
              >
                No strategy
              </MenuItem>
              {strategies?.data?.data.map((strategy) => (
                <MenuItem value={strategy.id} key={strategy.id}>
                  <SequenceCard sequence={strategy} />
                </MenuItem>
              ))}
            </Select>
          </div>
          <TagsBoxShowAll
            currentSituation={externalValuesTag}
            handleClickTag={handleClickTag}
            type={createAsLead ? 'Lead' : 'Prospect'}
            onlyInsert={true}
            showMandatoryAsStar={true}
          />

          <div className="divider" />

          <div className="additional-information-container">
            {externalPropertyNotTags.map((property, index) => {
              return (
                <div
                  key={'' + index + property?.valueType + property?.id}
                  className="information-editable-container"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: property.valueType === 'Text' ? '9px 0px' : '',
                  }}
                >
                  <div className="external-properties-tag">
                    <Typography fontSize={'14px'} className="information-label">
                      {property.publicName}
                      {property.isMandatory && '*'}:
                    </Typography>
                  </div>
                  <div
                    className="editable-div"
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <ExternalPropertyNotTag
                      externalProperty={property}
                      type="Prospect"
                      owned={true}
                      externalValues={externalValuesNotTag}
                      handleSetValue={handleSetExternalValueNotTag}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {isLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <FlexDiv width="fit-content" style={{ alignSelf: 'flex-end' }}>
              <CancelButton
                style={{
                  width: '120px',
                  height: '48px',
                }}
                onClick={close}
              >
                Cancel
              </CancelButton>
              {
                <MainButton
                  type="submit"
                  disabled={
                    formik.values.company === null ||
                    formik.values.company === '' ||
                    isLoadingGetPublicIdFromLeadId ||
                    isLoadingGetCompanyInfo
                  }
                  style={{
                    width: '120px',
                    height: '48px',
                    background: !formik.values.company
                      ? colors.grey2
                      : undefined,
                  }}
                >
                  Insert
                </MainButton>
              }
            </FlexDiv>
          )}
        </form>
      </InsertContactWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default InsertContact;
