import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const CallIconDark = ({
  color = colors.white,
  color2 = colors.callScheduled,
  className,
  onClick,
}: IProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect width="32" height="32" rx="16" fill={color2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2387 16.7623C14.4587 15.9823 13.8707 15.1089 13.4801 14.2229C13.3974 14.0356 13.4461 13.8163 13.5907 13.6716L14.1367 13.1263C14.5841 12.6789 14.5841 12.0463 14.1934 11.6556L13.4107 10.8729C12.8901 10.3523 12.0461 10.3523 11.5254 10.8729L11.0907 11.3076C10.5967 11.8016 10.3907 12.5143 10.5241 13.2209C10.8534 14.9629 11.8654 16.8703 13.4981 18.5029C15.1307 20.1356 17.0381 21.1476 18.7801 21.4769C19.4867 21.6103 20.1994 21.4043 20.6934 20.9103L21.1274 20.4763C21.6481 19.9556 21.6481 19.1116 21.1274 18.5909L20.3454 17.8089C19.9547 17.4183 19.3214 17.4183 18.9314 17.8089L18.3294 18.4116C18.1847 18.5563 17.9654 18.6049 17.7781 18.5223C16.8921 18.1309 16.0187 17.5423 15.2387 16.7623Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CallIconDark;
