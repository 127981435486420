import React, { useMemo, useRef, useState } from 'react';
import {
  Heatmap,
  HeatmapConfig,
  LabelStyle,
  Tooltip,
} from '@ant-design/charts';
import { GraphData } from '../tabsControllers/model';
import { AnalyticsTask } from 'crono-fe-common/types/analyticsTask';
import {
  colorsHeatMapCall,
  colorsHeatMapEmail,
  colorsHeatMapLinkedin,
  colorsHeatmapAll,
} from '../legendData';
import { Tab, Tabs, Typography } from '@mui/material';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { colors } from 'crono-fe-common/theme';
import { CustomTooltip } from 'pages/dashboard/contactTable';
import { AnalyticsFilters } from 'crono-fe-common/types/analyticsFilters';
import useTaskAnalytics from 'hooks/services/analytics/useTaskAnalytics';
import { HeatmapPlaceholder } from './chartsPlaceholders';

interface IProps {
  taskAnalytics: AnalyticsTask | undefined;
}

const TaskActivityHeatmapOverview = ({ taskAnalytics }: IProps) => {
  const fillArray = (data: GraphData[], day: string) => {
    data.sort((a, b) => (+a.label < +b.label ? -1 : 1));

    const newHours = [
      '12\nam',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12\npm',
      '1pm',
      '2pm',
      '3pm',
      '4pm',
      '5pm',
      '6pm',
      '7pm',
      '8pm',
      '9pm',
      '10pm',
      '11pm',
    ];
    let index = 0;
    [...Array(24)].forEach((_, hour) => {
      let newVal = 0;
      if (+data[index]?.label === hour) {
        newVal = data[index]?.value;
      }
      data.splice(index, newVal === 0 ? 0 : 1, {
        label: '' + newHours[hour],
        value: newVal,
        type: day,
      });
      index++;
    });
    return data;
  };

  const dataGeneral = useMemo(() => {
    if (!taskAnalytics) return [];

    const newData: GraphData[] = [];

    const arraySunday: GraphData[] = [];
    taskAnalytics?.heatmap.Sunday?.forEach((sunday) => {
      arraySunday.push({
        label: sunday.label,
        value: sunday.count,
        type: 'Su',
      });
    });
    newData.push(...fillArray(arraySunday, 'Su'));

    const arraySaturday: GraphData[] = [];
    taskAnalytics?.heatmap.Saturday?.forEach((saturday) => {
      arraySaturday.push({
        label: saturday.label,
        value: saturday.count,
        type: 'Sa',
      });
    });
    newData.push(...fillArray(arraySaturday, 'Sa'));

    const arrayFriday: GraphData[] = [];
    taskAnalytics?.heatmap.Friday?.forEach((friday) => {
      arrayFriday.push({
        label: friday.label,
        value: friday.count,
        type: 'Fri',
      });
    });
    newData.push(...fillArray(arrayFriday, 'Fri'));

    const arrayThursday: GraphData[] = [];
    taskAnalytics?.heatmap.Thursday?.forEach((thursday) => {
      arrayThursday.push({
        label: thursday.label,
        value: thursday.count,
        type: 'Th',
      });
    });
    newData.push(...fillArray(arrayThursday, 'Th'));

    const arrayWednesday: GraphData[] = [];
    taskAnalytics?.heatmap.Wednesday?.forEach((wednesday) => {
      arrayWednesday.push({
        label: wednesday.label,
        value: wednesday.count,
        type: 'We',
      });
    });
    newData.push(...fillArray(arrayWednesday, 'We'));

    const arrayTuesday: GraphData[] = [];
    taskAnalytics?.heatmap.Tuesday?.forEach((tuesday) => {
      arrayTuesday.push({
        label: tuesday.label,
        value: tuesday.count,
        type: 'Tu',
      });
    });
    newData.push(...fillArray(arrayTuesday, 'Tu'));

    const arrayMonday: GraphData[] = [];
    taskAnalytics?.heatmap.Monday?.forEach((monday) => {
      arrayMonday.push({
        label: monday.label,
        value: monday.count,
        type: 'Mo',
      });
    });
    newData.push(...fillArray(arrayMonday, 'Mo'));
    return newData;
  }, [taskAnalytics]);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const configGeneral = useMemo(() => {
    return {
      data: dataGeneral,
      xField: 'label',
      yField: 'type',
      colorField: 'value',
      color: colorsHeatmapAll,
      shape: 'circle',
      autoFit: true,
      tooltip: {
        customContent: (title: string, data: any[]) => {
          return (
            <div style={{ padding: '14px 4px' }}>
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
                color={colors.grey11}
              >
                {data[0]?.data.type},{' '}
                {data[0]?.data.label.replace('am', '').replace('pm', '')}:00{' '}
                {data[0]?.data.label.includes('pm') ? 'pm' : 'am'}
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={'16px'}
                color={colors.black}
              >
                {data[0]?.data.value}{' '}
                {data[0]?.data.value === 1 ? 'activity' : 'activities'}
              </Typography>
            </div>
          );
        },
        showMarkers: false,
      } as Tooltip,
      xAxis: {
        grid: {
          line: {
            style: { stroke: '#ffffff', opacity: 0 },
          },
        },
        label: {
          formatter: (text: string, item: any, index: number) => {
            return text.includes('12') ? text : text.replace('pm', '');
          },
        },
      } as any,
      yAxis: {
        grid: {
          line: {
            style: {
              stroke: '#ffffff',
              opacity: 0,
            },
          },
        },
      } as any,
      legend: false as any,
      sizeRatio: 0.6,
      randomVal: Math.random() * 10000,
    };
  }, [dataGeneral, chartContainerRef.current?.offsetWidth]);

  return (
    <>
      <Typography className="chart-title" style={{ marginBottom: 16 }}>
        Activity Heatmap
      </Typography>
      <div
        className="chart-container"
        ref={chartContainerRef}
        style={{ height: 'fit-content' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            left: 0,
          }}
        >
          <Tabs
            className="task-heatmap-tabs"
            value={'All'}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {/* <Tab value="all" label="All" className="task-heatmap-tab" /> */}
            <Tab value={'All'} label="All" className="task-heatmap-tab" />
          </Tabs>
          {/* <Legend colors={colorsTaskCall} fields={TaskCallLegend} /> */}
        </div>
        {configGeneral?.data && configGeneral.data.length ? (
          <div style={{ width: '100%', height: 200 }}>
            <Heatmap {...configGeneral} key={configGeneral.randomVal} />
          </div>
        ) : (
          <div style={{ minWidth: '100%' }}>
            <HeatmapPlaceholder />
          </div>
        )}
      </div>
    </>
  );
};

export default TaskActivityHeatmapOverview;
