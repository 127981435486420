import React, { useEffect, useState } from 'react';
import { SuggestionCardWrapper } from './suggestionStyle';
import { Suggestion } from 'crono-fe-common/types/suggestion';
import { Avatar, Badge, Typography } from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { consultDictionary } from './suggestionDictionary';
import { colors } from 'crono-fe-common/theme';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import moment from 'moment';
import { useConditionalSnackBar } from 'context/snackbar';
import { useWindow } from 'context/sideTab';
import { stringAvatar } from './utils';
import { useSelectedAccount } from 'pages/home/context/account';
import usePatchSuggestion from 'hooks/services/suggestion/usePatchSuggestion';
import { FeConstants } from 'constants/FeConstants';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { getError } from 'crono-fe-common/utils';
import { getImageFromUrl, prospectName } from 'utils/fe-utils';
import { useJuneAnalytics } from 'context/june';

interface IProps {
  suggestion: Suggestion;
}

const FollowUpMeetingCard = ({ suggestion }: IProps) => {
  const template = consultDictionary(suggestion.type);

  const [informativeMessage, setInformativeMessage] = useState<string | null>(
    null,
  );

  const analytics = useJuneAnalytics();

  const {
    mutate: patchSuggestion,
    isSuccess: correctlyPatched,
    variables: actionPerformed,
    error: errorPatchSuggestion,
  } = usePatchSuggestion();

  useConditionalSnackBar([
    {
      condition: !!errorPatchSuggestion,
      message: getError(errorPatchSuggestion) ?? 'Error updating suggestion',
      severity: 'error',
    },
    {
      condition: !!informativeMessage,
      message: informativeMessage || 'Action not allowed',
      severity: 'info',
    },
    {
      condition: !!correctlyPatched,
      message: actionPerformed?.skip
        ? 'Suggestion correctly skipped'
        : 'Suggestion correctly performed',
      severity: 'success',
    },
  ]);

  //When the success is received I close the modal
  useEffect(() => {
    if (correctlyPatched) {
      openWindow(null);
    }
  }, [correctlyPatched]);

  const { setSelectedAccountId, setSelectedProspectId } = useSelectedAccount();

  //Function passed to the modal that has to be called when the action is performed correctly
  const callOnSuccess = () => {
    patchSuggestion({
      suggestionId: suggestion.id,
      skip: false,
    });
    if (analytics) {
      analytics.track('execute-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };

  const { openWindow } = useWindow();
  // To handle the trigger of an action
  const handleExecuteTask = (action: 'email' | 'linkedin' | 'call') => {
    // Check if the prospect has the information needed to execute the action
    if (
      action === 'linkedin' &&
      (!suggestion.prospect?.linkedin || suggestion.prospect?.linkedin === '')
    ) {
      //Show information message
      setInformativeMessage(
        "The prospect does not have linkedin. Check the linkedin field in the prospect's profile",
      );
      setTimeout(() => {
        setInformativeMessage(null);
      }, 2000);
      return;
    }
    //Open the sideTab to show the action
    if (suggestion.prospectId) setSelectedAccountId(suggestion.accountId);
    openWindow({
      windowType: 'account',
      tab: action,
      modalVisible: action,
      selectedProspectId: suggestion.prospectId,
      callOnSuccess: callOnSuccess,
      suggestionId: suggestion.id,
    });
  };

  const handleClickNameProspect = (prospectId: string, accountId: string) => {
    setSelectedAccountId(accountId);
    setSelectedProspectId(prospectId);
    openWindow({
      windowType: 'account',
      tab: 'prospect',
      selectedProspectId: prospectId,
    });
  };

  const [badgeVisible, setBadgeVisible] = useState<boolean>(false);

  useEffect(() => {
    const currentTime = new Date();
    // Define the time limit for the last 4 hours
    const timeLimit = new Date(
      currentTime.getTime() - FeConstants.notificationTimeSpan,
    ); // 4 hours in milliseconds
    setBadgeVisible(new Date(suggestion.createdDate) > timeLimit);
  }, []);

  const handleSkipSuggestion = () => {
    patchSuggestion({
      suggestionId: suggestion.id,
      skip: true,
    });
    if (analytics) {
      analytics.track('skip-suggestion', {
        suggestionType: suggestion.type,
        suggestionId: suggestion.id,
      });
    }
  };

  return (
    <SuggestionCardWrapper>
      <Badge
        variant="dot"
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        invisible={!badgeVisible}
        color="primary"
        overlap="circular"
        className="badge-suggestion"
      >
        <Avatar
          className="suggestion-avatar"
          key={`avatar-key-${suggestion.objectId}`}
          alt={suggestion.type}
          src={
            suggestion.account?.website &&
            getImageFromUrl(suggestion.account?.website, null)
          }
          {...stringAvatar(suggestion.account?.name ?? '')}
        />
      </Badge>
      <FlexDiv direction="column" alignItems="start">
        <div className="top-header-suggestion-container">
          <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
            {template?.title({
              prospectName: (
                <b
                  onClick={() =>
                    handleClickNameProspect(
                      suggestion.prospectId,
                      suggestion.accountId,
                    )
                  }
                  className="name-link"
                >
                  {prospectName(suggestion.prospect ?? null)}
                </b>
              ),
            })}
          </Typography>
          <span className="skip-suggestion-x" onClick={handleSkipSuggestion}>
            <CloseMIcon color={colors.grey2} />
          </span>
        </div>
        <Typography fontSize={14} fontWeight={500}>
          {template?.description({
            prospectName: (
              <b
                onClick={() =>
                  handleClickNameProspect(
                    suggestion.prospectId,
                    suggestion.accountId,
                  )
                }
                className="name-link"
              >
                {suggestion.prospect?.name ?? ''}
              </b>
            ),
          })}
        </Typography>
        <FlexDiv justifyContent="start">
          <span
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              handleExecuteTask('call');
            }}
          >
            <CallIcon
              className="action-icon call-icon"
              color={colors.callScheduledHover}
            />
          </span>
          <span
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              handleExecuteTask('email');
            }}
          >
            <EmailIcon
              className="action-icon email-icon"
              color={colors.inactiveHover}
            />
          </span>
          <span
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              handleExecuteTask('linkedin');
            }}
          >
            <LinkedinIcon
              className="action-icon linkedin-icon"
              color={colors.blueHover}
            />
          </span>
        </FlexDiv>
        <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
          {moment(suggestion.createdDate).format('ll')}
        </Typography>
      </FlexDiv>
    </SuggestionCardWrapper>
  );
};

export default FollowUpMeetingCard;
