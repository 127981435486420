import React from 'react';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import { OverlayWrapper } from './styles';

interface LoadingOverlayProps {
  isLoading: boolean;
  loadingText?: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

const LoadingOverlay = ({
  isLoading,
  loadingText = 'Loading...',
  children,
  className,
}: LoadingOverlayProps) => {
  return (
    <OverlayWrapper
      className={isLoading ? `loading-overlay ${className}` : className}
    >
      {isLoading && (
        <div className="loading-state">
          <div className="strategy-loader">
            <span className="loader"></span>
          </div>

          <Typography
            fontSize={18}
            fontWeight={500}
            color={colors.intenseBlue}
            noWrap
          >
            {loadingText}
          </Typography>
        </div>
      )}
      {children}
    </OverlayWrapper>
  );
};

export default LoadingOverlay;
