import { IconButton } from '@mui/material';
import { memo, useEffect } from 'react';
import { ReactComponent as NewsIcon } from 'crono-fe-common/icons/News.svg';
import { CronoNewsButtonWrapper } from './style';

const FrillWidget = memo(() => {
  // This effect will run once when the component has mounted
  useEffect(() => {
    // We need to keep a reference to the widget instance so we can cleanup
    // when the component unmounts
    let widget: any;

    // Define our config. You can get the key from the Widget code snippet
    const config: any = {
      key: '6e059c09-5316-4bad-8c02-741318b384f1', // <-- Add Widget key here
      callbacks: {
        // This will be called when the widget is loaded
        onReady: (frillWidget: any) => {
          widget = frillWidget;
        },
      },
    };

    // Let's check if the Frill script has already loaded
    if ('Frill' in window) {
      // If the Frill api is already available we can create the widget now
      widget = (window as any).Frill.widget(config);
    } else {
      // If the Frill api hasn't been loaded, we need to add our config to the list.
      // When the api loads, it will create all widgets in the Frill_Config list and dispatch the
      // config.callbacks.onReady event for each
      (window as any).Frill_Config = (window as any).Frill_Config || [];
      (window as any).Frill_Config.push(config);
    }

    // Return a cleanup method so we can remove the widget when the component unmounts
    return () => {
      // Check if there is an active widget
      if (widget) {
        // If there is a widget, destroy it, this will remove all event listeners and nodes added
        // to the DOM by the widget
        widget.destroy();
      }
      // We also need to remove our config from the list so it doesn't get initialised.
      // This would only happen if the had component mounted/unmounted before the Frill api
      // had a chance to load.
      if ((window as any).Frill_Config) {
        (window as any).Frill_Config = (window as any).Frill_Config.filter(
          (c: any) => c !== config,
        );
      }
    };
  }, []);
  return (
    <CronoNewsButtonWrapper className="crono-news-selector">
      <NewsIcon />
    </CronoNewsButtonWrapper>
  );
});

export default FrillWidget;
