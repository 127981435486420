import { gatewayService } from 'crono-fe-common/hooks/crono-extension/gateway';
import {
  AsyncEnrichJob,
  CronoGatewayBackgroundGetAsyncEnrichQueueState,
} from 'crono-fe-common/types/crono-extension/background-script';
import { useQuery } from 'react-query';

interface IGetAsyncQueueState {
  isEnriching: boolean;
  contactsToEnrich: Omit<AsyncEnrichJob, 'state'>[];
  contactsEnriched: Omit<AsyncEnrichJob, 'state'>[];
  contactsToEnrichCount: number;
  contactsEnrichedCount: number;
}

export function useGetAsyncEnrichQueueState() {
  return useQuery<IGetAsyncQueueState>({
    queryKey: 'useGetAsyncEnrichQueueState',
    queryFn: async (): Promise<IGetAsyncQueueState> => {
      const queueState =
        await gatewayService.execute<CronoGatewayBackgroundGetAsyncEnrichQueueState>(
          {
            target: 'background-script',
            methodName: 'getAsyncEnrichQueueState',
            params: {},
          },
        );
      // ------- Companies data -------
      const isEnriching = Boolean(
        queueState?.runningJobs.length || queueState?.queuedJobs.length,
      );

      const contactsToEnrich = [
        ...queueState.queuedJobs,
        ...queueState.runningJobs,
      ] as Omit<AsyncEnrichJob, 'state'>[];

      const contactsEnriched = queueState.completedJobs as Omit<
        AsyncEnrichJob,
        'state'
      >[];

      const contactsToEnrichCount = contactsToEnrich.length;

      const contactsEnrichedCount = contactsEnriched.length;

      return {
        isEnriching,
        contactsToEnrich,
        contactsEnriched,
        contactsToEnrichCount,
        contactsEnrichedCount,
      };
    },
    refetchInterval: 2500,
    initialData: {
      isEnriching: false,
      contactsToEnrich: [],
      contactsEnriched: [],
      contactsToEnrichCount: 0,
      contactsEnrichedCount: 0,
    },
  });
}
