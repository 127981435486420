import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { LastLinkedinInvitationResponse } from 'crono-fe-common/types/linkedinInvitation';

export default function useLastTaskInvitation(prospectId: string | null) {
  const queryParams = queryString.stringify({
    ProspectId: prospectId,
  });

  const request: Request = {
    url: `${ENDPOINTS.events.task.invitation}?${queryParams}`,
    config: {
      method: 'get',
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.events.task.last, prospectId],
    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<
    Response<LastLinkedinInvitationResponse>,
    CronoError
  >(requestConfig);

  return { data, ...rest };
}
