import React, { useEffect, useMemo, useState } from 'react';
import { TemplatesSettingsWrapper } from './style';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  ClickAwayListener,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import { colors } from 'crono-fe-common/theme';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import useSearchTemplates from 'hooks/services/templates/useSearchTemplates';
import { useAuth } from 'context/auth';
import { Template } from 'crono-fe-common/types/template';
import TemplateManagement from './TemplateManagement';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import TemplateDisplay from './templateDisplay';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import DeleteTemplateModal from './deleteTemplateModal';
import Pagination from 'pages/dashboard/pagination';
import Role from 'crono-fe-common/types/enums/role';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { Constants } from 'crono-fe-common/constants/constants';
import TagContainerComponent, { TagFilterTooltip } from './tagTooltip';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import ExternalPropertyFilter from 'crono-fe-common/types/externalPropertyFilter';
import { externalPropertiesToTagInsert } from 'crono-fe-common/utils';
import { TemplateFilter } from './model';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { ReactComponent as NoTemplates } from '../../../assets/images/No_templates.svg';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { useNavigate } from 'react-router-dom';

interface IProps {
  initialTab?: TemplateType | null;
}

const TemplatesSettings = ({ initialTab }: IProps) => {
  const { user, highestRole } = useAuth();
  const { data: externalProperties } = useGetExternalProperty(null, true);
  const [tooltipToggle, setTooltipToggle] = useState<boolean>(false);

  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      //To clear the state when we leave this page, so that the next times we are not going to be redirected here
      navigate(location.pathname, { state: undefined });
    };
  }, []);

  const [value, setValue] = useState<TemplateType>(
    initialTab ?? TemplateType.EMAIL,
  );
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const [search, setSearch] = useState<string>('');
  const [tabSelected, setTabSelected] = useState<TemplateType>(
    initialTab ?? TemplateType.EMAIL,
  );

  const [selectedLanguage, setSelectedLanguage] = React.useState(
    user?.userPreferences?.templateLanguage &&
      Constants.templateLanguages.includes(
        user.userPreferences.templateLanguage,
      )
      ? user.userPreferences.templateLanguage
      : Constants.templateLanguages[0],
  );

  const [offset, setOffset] = useState<number>(0);

  const [selectedUser, setSelectedUser] = useState<number | undefined>(
    undefined,
  );

  const { data: users } = useGetUsers();

  // Load user preferences
  useEffect(() => {
    if (!user || !users) return;

    const savedSelectedUserId = user.userPreferences?.templateSelectedUser;
    const isUserInList = !!users.data?.data.find(
      (u) => u.id === savedSelectedUserId,
    );
    if (savedSelectedUserId && isUserInList) {
      setSelectedUser(savedSelectedUserId);
    } else {
      setSelectedUser(-1);
    }
  }, [user, users]);

  // Save user preferences
  useEffect(() => {
    if (selectedUser !== undefined && user) {
      user.userPreferences.templateSelectedUser = selectedUser;
      patchUserPreferences({
        templateSelectedUser: selectedUser,
      });
    }
  }, [selectedUser]);

  const [filterExternalProperties, setFilterExternalProperties] = useState<
    ExternalPropertyFilter[]
  >([]);

  const flatTagsFilter = useMemo(() => {
    return externalPropertiesToTagInsert(filterExternalProperties);
  }, [filterExternalProperties]);

  const { data: templates, isLoading: loadingTemplates } = useSearchTemplates({
    limit: Constants.defaultTemplatesLimit,
    offset: offset,
    type: tabSelected,
    title: search,
    language: selectedLanguage === 'all' ? undefined : selectedLanguage,
    userId: selectedUser === -1 ? undefined : selectedUser,
    templateTags: flatTagsFilter.length ? flatTagsFilter : undefined,
    filterByTags: true,
    //If we are looking for the templates of another user, we have to show only the shared ones if we are not a manager
    ...(selectedUser !== user?.id &&
      highestRole !== Role.MANAGER && { shared: true }),
  });

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setSelectedLanguage(event.target.value);
    if (user) {
      if (user.userPreferences) {
        user.userPreferences.templateLanguage = event.target.value;
      } else {
        user.userPreferences = {
          templateLanguage: event.target.value,
        };
      }
    }
    patchUserPreferences({
      templateLanguage: event.target.value,
    });
  };

  const hideAll = false;
  const hideShared = false;

  const [selectedTemplate, setSelectedTemplate] = useState<
    Template | null | undefined
  >(undefined);

  const showOpenRate = tabSelected === TemplateType.EMAIL;

  const [templateToRemove, setTemplateToRemove] = useState<Template | null>(
    null,
  );

  const handleRemoveTemplateClick = (template: Template) => {
    setTemplateToRemove(template);
  };

  useEffect(() => {
    setOffset(0);
  }, [tabSelected, search, flatTagsFilter, selectedLanguage, selectedUser]);

  // When a property is pressed I update the filters
  const handleChangeExternalProperty = (
    externalPropertyId: number,
    option: string,
  ) => {
    // If the option is not selected, I add it to the filters
    if (
      !filterExternalProperties
        ?.filter((ep) => ep.externalPropertyId === externalPropertyId)
        .at(0)
        ?.values?.includes(option)
    ) {
      const externalProperty =
        filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId === externalPropertyId,
        )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
      externalProperty.values = [...(externalProperty.values ?? []), option];
      const newExternalProperties: ExternalPropertyFilter[] = [
        ...(filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId !== externalPropertyId,
        ) || []),
        externalProperty,
      ];

      setFilterExternalProperties([...newExternalProperties]);
    } else {
      //Remove the filter
      const externalProperty: ExternalPropertyFilter =
        filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId === externalPropertyId,
        )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
      externalProperty.values = externalProperty.values?.filter(
        (value) => value !== option,
      );
      // If the externalProperty has no value I have to remove it from the filters (not adding it)
      const newExternalProperties: ExternalPropertyFilter[] = [
        ...(filterExternalProperties?.filter(
          (ep) => ep.externalPropertyId !== externalPropertyId,
        ) || []),
      ];
      if (externalProperty.values && externalProperty.values?.length > 0) {
        newExternalProperties.push(externalProperty);
      }
      setFilterExternalProperties([...newExternalProperties]);
    }
  };

  return (
    <TemplatesSettingsWrapper id="templates-settings-wrapper">
      {selectedTemplate !== undefined && (
        <TemplateManagement
          selectedTemplate={selectedTemplate}
          actualType={tabSelected}
          close={() => setSelectedTemplate(undefined)}
        />
      )}
      {templateToRemove !== null && (
        <DeleteTemplateModal
          template={templateToRemove}
          close={() => setTemplateToRemove(null)}
        />
      )}
      <div className="template-type-column">
        <div className="tabs-container">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            style={{
              width: '100%',
            }}
            sx={{
              '.MuiTabs-flexContainer': {
                flexDirection: 'column',
                overflowX: 'auto',
                width: '100%',
              },
              '.MuiTabs-indicator': {
                display: 'none',
              },
              '.Mui-selected': {
                backgroundColor: colors.primaryLight,
              },
              '.MuiTabs-root': {
                width: '100%',
              },
            }}
          >
            <Tab
              value={TemplateType.EMAIL}
              icon={
                <EmailIcon
                  color={
                    value === TemplateType.EMAIL
                      ? colors.darkGreen
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
              label="Email"
              className="template-type-tab"
              onClick={() => setTabSelected(TemplateType.EMAIL)}
            />
            <Tab
              value={TemplateType.INVITATION}
              label="LinkedIn Invitation"
              className="template-type-tab"
              icon={
                <LinkedinInvitationIcon
                  fill={colors.white}
                  color={
                    value === TemplateType.INVITATION
                      ? colors.darkGreen
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
              onClick={() => setTabSelected(TemplateType.INVITATION)}
            />
            <Tab
              value={TemplateType.LINKEDIN}
              label="LinkedIn Message"
              className="template-type-tab"
              icon={
                <LinkedinIconMessage
                  color={
                    value === TemplateType.LINKEDIN
                      ? colors.darkGreen
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
              onClick={() => setTabSelected(TemplateType.LINKEDIN)}
            />

            <Tab
              value={TemplateType.IN_MAIL}
              label="LinkedIn InMail"
              className="template-type-tab"
              sx={{
                '.MuiTab-iconWrapper': {
                  marginRight: '6px',
                },
              }}
              icon={
                <LinkedinIconInMailCompass
                  viewBox="3 2 20 20"
                  color={
                    value === TemplateType.IN_MAIL
                      ? colors.darkGreen
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
              onClick={() => setTabSelected(TemplateType.IN_MAIL)}
            />
            <Tab
              value={TemplateType.SCRIPT}
              label="Scripts"
              className="template-type-tab"
              sx={{
                '.MuiTab-iconWrapper': {
                  marginRight: '6px',
                },
              }}
              icon={
                <LinkedinIconInvoice
                  viewBox="2 2 20 20"
                  color={
                    value === TemplateType.SCRIPT
                      ? colors.darkGreen
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
              onClick={() => setTabSelected(TemplateType.SCRIPT)}
            />
          </Tabs>
        </div>
        <div className="template-settings-container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 30px 16px 36px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <SearchIcon color={colors.grey11} />
              <TextField
                className="text-field"
                variant="standard"
                placeholder="Search..."
                autoComplete="off"
                InputProps={{
                  endAdornment:
                    (search || '') === '' ? null : (
                      <CloseMIcon
                        className="remove-text-icon"
                        color={colors.grey11}
                        onClick={() => setSearch('')}
                      />
                    ),
                }}
                sx={{
                  width: '100%',
                  input: {
                    fontWeight: '400',
                    padding: '4px 0px',
                    '&::placeholder': {
                      opacity: 1,
                      color: colors.grey11,
                    },
                  },
                }}
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography
                fontWeight={500}
                lineHeight={'16px'}
                fontSize={12}
                color={colors.grey11}
                whiteSpace={'nowrap'}
              >
                My templates
              </Typography>
              <CronoSwitch
                checked={selectedUser === user?.id}
                onChange={() => {
                  if (selectedUser === user?.id) {
                    setSelectedUser(-1);
                  } else {
                    if (user?.id !== undefined) setSelectedUser(user?.id);
                  }
                }}
              />

              <MainPrimaryButton
                startIcon={<PlusIcon color={colors.white} />}
                onClick={() => setSelectedTemplate(null)}
              >
                Create new
              </MainPrimaryButton>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              columnGap: 8,
              justifyContent: 'space-between',
              padding: '0px 18px 16px 18px',
            }}
          >
            <div style={{ display: 'flex', columnGap: 8 }}>
              {/* Language */}
              <FormControl sx={{ minWidth: 40, mt: 1, mb: 1 }} size="small">
                <Select
                  labelId="select-small"
                  id="select-small"
                  value={selectedLanguage}
                  onChange={handleChangeLanguage}
                  sx={{
                    fontSize: '14px',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: `1px solid ${colors.grey4}`,
                      borderRadius: 2,
                    },
                    padding: '2px',
                  }}
                  MenuProps={{
                    style: { zIndex: 3501 },
                    PaperProps: {
                      sx: {
                        borderRadius: '8px',
                        paddingInline: '6px',
                        border: '1px solid #dadde9',
                        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                        '& .MuiMenuItem-root': {
                          fontSize: '12px',
                          lineHeight: '16px',
                          height: 'fit-content',
                          padding: '8px',
                          cursor: 'pointer',
                          width: '100%',
                          borderRadius: '8px',
                          '&:hover': {
                            background: colors.primaryLight,
                            color: colors.primary,
                          },
                        },
                        '& .Mui-selected': {
                          color: colors.primary,
                          backgroundColor: 'transparent !important',
                        },
                      },
                    },
                  }}
                >
                  {(hideAll
                    ? Constants.templateLanguagesInput
                    : Constants.templateLanguages
                  ).map((language) => (
                    <MenuItem value={language} key={language}>
                      {Constants.languages[language]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* User filter */}
              <FormControl sx={{ minWidth: 120, mt: 1, mb: 1 }} size="small">
                <Select
                  labelId="select-medium"
                  id="select-medium"
                  value={selectedUser ?? -1}
                  onChange={(e) => {
                    setSelectedUser((e.target.value ?? -1) as number);
                  }}
                  sx={{
                    fontSize: '14px',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: `1px solid ${colors.grey4}`,
                      borderRadius: 2,
                    },
                    padding: '2px',
                  }}
                  MenuProps={{
                    style: { zIndex: 3501 },
                    PaperProps: {
                      sx: {
                        borderRadius: '8px',
                        paddingInline: '6px',
                        border: '1px solid #dadde9',
                        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                        '& .MuiMenuItem-root': {
                          fontSize: '12px',
                          lineHeight: '16px',
                          height: 'fit-content',
                          padding: '8px',
                          cursor: 'pointer',
                          width: '100%',
                          borderRadius: '8px',
                          '&:hover': {
                            background: colors.primaryLight,
                            color: colors.primary,
                          },
                        },
                        '& .Mui-selected': {
                          color: colors.primary,
                          backgroundColor: 'transparent !important',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {users?.data?.data
                    // .filter((u) => u.id !== user?.id)
                    .map((user) => {
                      return (
                        <MenuItem value={user.id} key={user.id}>
                          {user.firstName} {user.lastName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {/* Tags filter */}
              {(externalProperties?.data?.data.length ?? 0) > 0 && (
                <ClickAwayListener onClickAway={() => setTooltipToggle(false)}>
                  <FormControl
                    sx={{ minWidth: 120, mt: 1, mb: 1 }}
                    size="small"
                  >
                    <TagFilterTooltip
                      open={tooltipToggle}
                      title={
                        <TagContainerComponent
                          externalProperties={
                            externalProperties?.data?.data.filter(
                              (property) => property.isTag,
                            ) || []
                          }
                          filterExternalProperties={filterExternalProperties}
                          handleChangeExternalProperty={
                            handleChangeExternalProperty
                          }
                        />
                      }
                    >
                      {flatTagsFilter?.length ? (
                        <div
                          className="tag-filter-container tags-selected"
                          onClick={() => setTooltipToggle(!tooltipToggle)}
                        >
                          <Typography
                            fontSize={'14px'}
                            style={{ flex: 1 }}
                            noWrap
                          >
                            Tags ({flatTagsFilter.length} selected)
                          </Typography>

                          <CloseMIcon
                            className="remove-text-icon"
                            color={colors.grey11}
                            onClick={() => {
                              setFilterExternalProperties([]);
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className="tag-filter-container"
                          onClick={() => setTooltipToggle(!tooltipToggle)}
                        >
                          <Typography
                            fontSize={'14px'}
                            style={{ flex: 1 }}
                            noWrap
                          >
                            Tags
                          </Typography>
                          {tooltipToggle ? (
                            <TopArrowIcon className="tag-arrow" />
                          ) : (
                            <BottomArrowIcon className="tag-arrow" />
                          )}
                        </div>
                      )}
                    </TagFilterTooltip>
                  </FormControl>
                </ClickAwayListener>
              )}
            </div>
            <div
              style={{ display: 'flex', marginRight: 18, color: colors.grey11 }}
            >
              <div className="template-rate">Usage</div>

              {showOpenRate && <div className="template-rate">Open</div>}
              <div className="template-rate">
                {tabSelected === TemplateType.INVITATION ? 'Accept' : 'Reply'}
              </div>
            </div>
          </div>
          {loadingTemplates ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : templates && templates?.data?.data.length ? (
            <>
              <div className="templates-list">
                {templates?.data?.data.map((template) => {
                  return (
                    <TemplateDisplay
                      template={template}
                      key={template.id}
                      showOpenRate={showOpenRate}
                      setSelectedTemplate={setSelectedTemplate}
                      handleRemoveTemplateClick={handleRemoveTemplateClick}
                    />
                  );
                })}
              </div>
              <Pagination
                count={templates?.data?.count}
                total={templates?.data?.total}
                itemOffset={offset}
                setItemOffset={setOffset}
                limit={Constants.defaultTemplatesLimit}
              />
            </>
          ) : (
            <FlexDiv
              direction="column"
              gap="20px"
              style={{ paddingBottom: '200px' }}
            >
              <NoTemplates />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'24px'}
                  textAlign={'center'}
                >
                  No template found with these filters
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  textAlign={'center'}
                  maxWidth={'315px'}
                >
                  Try to change the applied filters to see all the available
                  templates.
                </Typography>
              </div>
            </FlexDiv>
          )}
        </div>
      </div>
    </TemplatesSettingsWrapper>
  );
};

export default TemplatesSettings;
