import { Request } from 'crono-fe-common/types/request';
import client from 'utils/clients/client';
import { ENDPOINTS } from '../../../config/endpoints';

export type SalesforceVerifyIntegrationResult = {
  data: { isSuccess: boolean };
};

class SalesforceService {
  private _alreadyRunningVerifySalesforceIntegrationCall: Promise<SalesforceVerifyIntegrationResult> | null =
    null;

  public async verifySalesforceIntegration(): Promise<SalesforceVerifyIntegrationResult> {
    if (this._alreadyRunningVerifySalesforceIntegrationCall) {
      return await this._alreadyRunningVerifySalesforceIntegrationCall;
    } else {
      this._alreadyRunningVerifySalesforceIntegrationCall =
        this._verifySalesforceIntegration();
      try {
        const verifyResult =
          await this._alreadyRunningVerifySalesforceIntegrationCall;
        return verifyResult;
      } catch (e) {
        return {
          data: {
            isSuccess: false,
          },
        };
      } finally {
        this._alreadyRunningVerifySalesforceIntegrationCall = null;
      }
    }
  }

  private async _verifySalesforceIntegration(): Promise<SalesforceVerifyIntegrationResult> {
    const request: Request = {
      url: ENDPOINTS.salesforce.verify,
      config: {
        method: 'get',
      },
    };

    const result = await client(request);
    return result as SalesforceVerifyIntegrationResult;
  }
}

// Esporto una sola istanza del servizio, in modo che tutti gli altri file che importano
// questo file importeranno sempre la stessa istanza
export default new SalesforceService();
