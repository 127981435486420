import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const DateRangePickerWrapper = styled.div`
  //   .rdrDateRangeWrapper {
  //     height: fit-content;
  //   }
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrDaySelected {
    background: ${colors.primary};
    color: ${colors.black} !important;
  }
  .rdrDayInPreview {
    background: ${colors.primaryLight};
    color: ${colors.black} !important;
    border: 0px solid ${colors.primary};
    z-index: 0;
  }
  .rdrDayHovered {
    color: ${colors.black} !important;
  }
  .rdrDayNumber {
    color: ${colors.black} !important;
  }
  .rdrDayStartPreview {
    background: ${colors.primary};
    color: ${colors.black} !important;
  }
  .rdrDayEndPreview {
    background: ${colors.primary};
    color: ${colors.black} !important;
  }
  .rdrStartEdge {
    color: ${colors.black} !important;
  }
  .rdrEndEdge {
    color: ${colors.black} !important;
  }
`;
