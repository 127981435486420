import {
  DeleteOutline,
  RadioButtonUnchecked,
  CheckCircleRounded,
} from '@mui/icons-material';
import {
  Typography,
  Button,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import { useMemo, useState } from 'react';
import {
  getColorsStatus,
  getExternalPropertyLabels,
} from 'crono-fe-common/utils';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { useFilters } from 'pages/home/context/filters';
import { AccountSearch } from 'crono-fe-common/types/DTO/accountSearch';
import ExternalPropertyFilter from 'crono-fe-common/types/externalPropertyFilter';
import { useAuth } from 'context/auth';
import { ISize, sizes } from './sizes';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { RemoveButton } from 'crono-fe-common/components/CronoButton';
import { ReactComponent as BottomArrow } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { ReactComponent as TopArrow } from 'crono-fe-common/icons/Top-Arrow.svg';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { RangeKeyDict } from 'react-date-range';
import DateRangePicker from 'components/DateRangePicker';
import { DateRangePickerTooltip } from 'components/DateRangePicker/modal';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import useGetImports from 'hooks/services/import/useGetImports';
import { Import } from 'crono-fe-common/types/import';
import FilterExternalProperty from './filterExternalProperty';
import { CronoAutocomplete } from 'crono-fe-common/components/Autocomplete';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import { ReactComponent as WebsiteIcon } from 'crono-fe-common/icons/website.svg';
import { getFullName } from 'crono-fe-common/types/user';
import { useGetStatusesOptions } from 'utils/fe-utils';
import { SingleStatusOptionComponent } from 'components/CronoStatus/statusTooltipPicker';
import { setToMidDayOfPassedDate } from 'utils/fe-utils';
import { DefaultCompanyStatusOptions } from 'crono-fe-common/types/model';
import { linkedinIndustries } from 'crono-fe-common/constants/constants';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import MinusSmallIcon from 'crono-fe-common/icons/Icon-Minus-Small';
import { useJuneAnalytics } from 'context/june';

export type externalInformationAcceptedAccount = Pick<
  AccountSearch,
  'hasPhone' | 'hasWebsite' | 'hasLinkedin'
>;

type ExternalInformation = {
  [k in keyof externalInformationAcceptedAccount]: boolean;
};

const externalInformation: ExternalInformation = {
  hasPhone: false,
  hasWebsite: false,
  hasLinkedin: false,
};

interface IProps {
  close: () => void;
}

const AccountFilters = ({ close }: IProps) => {
  const {
    searchAccountParameters: initialFilters,
    setSearchAccountParameters: setSearchParameters,
    resetAccountFilters: resetFilters,
    updateUserPreferences,
  } = useFilters();

  const { user } = useAuth();
  const { data: users } = useGetUsers();
  // State containing the filters applied in this section
  const [filters, setFilters] = useState<AccountSearch>(initialFilters);
  const { data: externalProperties } = useGetExternalProperty('Account', true);

  // When a status is pressed I update the filters
  const handleStatusChange = (status: DefaultCompanyStatusOptions) => {
    if (filters.status?.includes(status)) {
      const newStatus = filters.status?.filter((filter) => filter !== status);
      setFilters({
        ...filters,
        status: newStatus.length === 0 ? null : newStatus,
      });
    } else {
      setFilters({
        ...filters,
        status: [...(filters.status ?? []), status],
      });
    }
  };

  //External property status account
  const {
    externalPropertyStatusId: externalPropertyStatusIdAccount,
    isExternalPropertyStatus: isExternalPropertyStatusAccount,
    statuses: statusesAccount,
  } = useGetStatusesOptions('account');

  const handleChangeStatusAccount = (status: string) => {
    if (isExternalPropertyStatusAccount && externalPropertyStatusIdAccount) {
      handleChangeExternalProperty(externalPropertyStatusIdAccount, status);
    } else {
      handleStatusChange(status as DefaultCompanyStatusOptions);
    }
  };

  const isAccountStatusSelected = (status: string) => {
    if (isExternalPropertyStatusAccount && externalPropertyStatusIdAccount) {
      return (
        filters.externalProperties
          ?.filter(
            (ep) => ep.externalPropertyId === externalPropertyStatusIdAccount,
          )?.[0]
          ?.values?.includes(status) ?? false
      );
    } else {
      return (
        filters.status?.includes(status as DefaultCompanyStatusOptions) ?? false
      );
    }
  };

  // When a property is pressed I update the filters
  const handleChangeExternalProperty = (
    externalPropertyId: number,
    option: string,
  ) => {
    // If the option is not selected, I add it to the filters
    if (
      !filters.externalProperties
        ?.filter((ep) => ep.externalPropertyId === externalPropertyId)
        .at(0)
        ?.values?.includes(option)
    ) {
      const externalProperty =
        filters.externalProperties?.filter(
          (ep) => ep.externalPropertyId === externalPropertyId,
        )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
      externalProperty.values = [...(externalProperty.values ?? []), option];
      const newExternalProperties: ExternalPropertyFilter[] = [
        ...(filters.externalProperties?.filter(
          (ep) => ep.externalPropertyId !== externalPropertyId,
        ) || []),
        externalProperty,
      ];
      setFilters({
        ...filters,
        externalProperties: newExternalProperties,
      });
    } else {
      //Remove the filter
      const externalProperty: ExternalPropertyFilter =
        filters.externalProperties?.filter(
          (ep) => ep.externalPropertyId === externalPropertyId,
        )?.[0] || ({ externalPropertyId } as ExternalPropertyFilter);
      externalProperty.values = externalProperty.values?.filter(
        (value) => value !== option,
      );
      // If the externalProperty has no value I have to remove it from the filters (not adding it)
      const newExternalProperties: ExternalPropertyFilter[] = [
        ...(filters.externalProperties?.filter(
          (ep) => ep.externalPropertyId !== externalPropertyId,
        ) || []),
      ];
      if (externalProperty.values && externalProperty.values?.length > 0) {
        newExternalProperties.push(externalProperty);
      }
      setFilters({
        ...filters,
        externalProperties: newExternalProperties,
      });
    }
  };

  const handleChangeUserId = (userId: number) => {
    setFilters({
      ...filters,
      userId: userId === 0 ? null : userId,
    });
  };

  const handleChangeSize = (size: ISize) => {
    if (
      filters.numberOfEmployeesMin === size.min &&
      filters.numberOfEmployeesMax === size.max
    ) {
      setFilters({
        ...filters,
        numberOfEmployeesMin: null,
        numberOfEmployeesMax: null,
      });
    } else {
      setFilters({
        ...filters,
        numberOfEmployeesMin: size.min,
        numberOfEmployeesMax: size.max,
      });
    }
  };

  const analytics = useJuneAnalytics();

  const handleApply = (filters: AccountSearch) => {
    setSearchParameters(filters);
    updateUserPreferences('Company', filters);
    if (analytics) {
      analytics.track('filter-companies-main-list', {
        filters: filters,
      });
    }
    close();
  };

  const handleClear = () => {
    resetFilters(true);
    close();
  };

  const [moreFiltersOpened, setMoreFiltersOpened] = useState<boolean>(false);

  const handleCreatedDateChanges = (ranges: RangeKeyDict) => {
    const newMin = ranges.selection.startDate;
    const newMax = ranges.selection.endDate;
    let desiredMin = newMin;
    if (newMin) {
      desiredMin = setToMidDayOfPassedDate(newMin);
    }
    let desiredMax = newMax;
    if (newMax) {
      desiredMax = setToMidDayOfPassedDate(newMax);
    }
    setFilters({
      ...filters,
      createdDateMin: desiredMin?.toJSON(),
      createdDateMax: desiredMax?.toJSON(),
    });
  };
  const handleLastActivityDateChanges = (ranges: RangeKeyDict) => {
    const newMin = ranges.selection.startDate;
    const newMax = ranges.selection.endDate;
    let desiredMin = newMin;
    if (newMin) {
      desiredMin = setToMidDayOfPassedDate(newMin);
    }
    let desiredMax = newMax;
    if (newMax) {
      desiredMax = setToMidDayOfPassedDate(newMax);
    }
    setFilters({
      ...filters,
      lastActivityDateMin: desiredMin?.toJSON(),
      lastActivityDateMax: desiredMax?.toJSON(),
    });
  };

  const [openCreatedDateTooltip, setOpenCreatedDateTooltip] =
    useState<boolean>(false);
  const [openLastActivityDateTooltip, setOpenLastActivityDateTooltip] =
    useState<boolean>(false);

  const handleClearCreateDate = () => {
    setFilters({
      ...filters,
      createdDateMin: undefined,
      createdDateMax: undefined,
    });
  };
  const handleClearLastActivityDate = () => {
    setFilters({
      ...filters,
      lastActivityDateMin: undefined,
      lastActivityDateMax: undefined,
    });
  };

  const { data: importFiles } = useGetImports(
    10,
    'Account',
    ImportStatusType.Completed,
  );

  const handleChangeImport = (id: number) => {
    setFilters({
      ...filters,
      importId: id === -1 ? undefined : id,
    });
  };

  const renderExternalInformation = (
    option: keyof externalInformationAcceptedAccount,
    index: number,
  ) => {
    let label = '';
    let icon = null;
    switch (option) {
      case 'hasLinkedin':
        label = 'Linkedin';
        icon = <LinkedinIcon />;
        break;

      case 'hasPhone':
        label = 'Phone';
        icon = <CallIcon />;
        break;
      case 'hasWebsite':
        label = 'Website';
        icon = <WebsiteIcon />;
        break;
      default:
        break;
    }
    return (
      <div
        key={index}
        className={
          filters[option] === true
            ? 'option-selected'
            : filters[option] === false
              ? 'option-negative-selected'
              : 'option'
        }
        onClick={() => handleChangeExistingInformation(option)}
        style={{ userSelect: 'none' }}
      >
        {icon}
        <Typography fontSize={'12px'} fontWeight={500}>
          {label}
        </Typography>

        {filters[option] === true ? (
          <PlusSmallIcon color={colors.green} />
        ) : filters[option] === false ? (
          <MinusSmallIcon color={colors.inactiveHover} />
        ) : null}
      </div>
    );
  };

  const externalInformationOptions = useMemo(
    () =>
      Object.keys(externalInformation).map((option, index) => {
        return renderExternalInformation(
          option as keyof externalInformationAcceptedAccount,
          index,
        );
      }),
    [filters],
  );

  const handleChangeProspectOwnership = (newValue: string) => {
    const newBool = newValue === '1' ? true : null;
    setFilters({
      ...filters,
      ownedProspects: newBool,
    });
  };

  const handleChangeExistingInformation = (
    option: keyof externalInformationAcceptedAccount,
  ) => {
    if (filters[option] === true) {
      setFilters({
        ...filters,
        [option]: false,
      });
    } else if (filters[option] === false) {
      setFilters({
        ...filters,
        [option]: null,
      });
    } else {
      setFilters({
        ...filters,
        [option]: true,
      });
    }
  };

  return (
    <div className="filters-container">
      <div className="filter-header">
        <Typography variant="h5" fontWeight={700}>
          Filters
        </Typography>
        <div>
          <RemoveButton
            variant="contained"
            className="button-filter"
            startIcon={<DeleteOutline />}
            onClick={() => {
              handleClear();
            }}
            disableElevation
          >
            Clear
          </RemoveButton>
          <Button
            color="secondary"
            variant="contained"
            className="button-filter"
            onClick={() => {
              handleApply(filters);
            }}
            disableElevation
          >
            Apply
          </Button>
        </div>
      </div>
      <div className="filter-body">
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Country
          </Typography>
          <TextField
            className="text-field"
            placeholder="Type a country..."
            size="small"
            value={filters.country || ''}
            onChange={(ev) => {
              setFilters({ ...filters, country: ev.target.value });
            }}
          />
        </div>
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Industry
          </Typography>
          <CronoAutocomplete
            className="text-field"
            freeSolo
            value={filters.industry || ''}
            onChange={(ev, value) => {
              setFilters({ ...filters, industry: value || '' });
            }} //To allow the user to see the industry filter even without pressing apply
            onInput={(ev: any) => {
              setFilters({ ...filters, industry: ev.target.value });
            }}
            options={linkedinIndustries.map((el) => el.label)}
            textFieldParams={{
              placeholder: 'Type an industry...',
            }}
          />
        </div>
        {users?.data?.data && (
          <div className="filter-section">
            <Typography fontSize={14} fontWeight={600}>
              Company Owner
            </Typography>
            <Select
              value={filters.userId || 0}
              onChange={(ev) => {
                const value = ev.target.value as number;
                handleChangeUserId(value);
              }}
              className="select"
              MenuProps={{
                style: { zIndex: 35001 },
                PaperProps: {
                  sx: {
                    borderRadius: '8px',
                    paddingInline: '6px',
                    border: '1px solid #dadde9',
                    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      lineHeight: '16px',
                      height: 'fit-content',
                      padding: '8px',
                      cursor: 'pointer',
                      width: '100%',
                      borderRadius: '8px',
                      '&:hover': {
                        background: colors.primaryLight,
                        color: colors.primary,
                      },
                    },
                    '& .Mui-selected': {
                      color: colors.primary,
                      backgroundColor: 'transparent !important',
                    },
                  },
                },
              }}
            >
              <MenuItem value={0}>All</MenuItem>
              {users?.data?.data.map((user) => {
                return (
                  <MenuItem value={user.id} key={user.id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Contacts Owner
          </Typography>
          <Select
            value={filters.ownedProspects ? '1' : '0'}
            onChange={(ev) => {
              handleChangeProspectOwnership(ev.target.value);
            }}
            className="select"
            MenuProps={{
              style: { zIndex: 35001 },
              PaperProps: {
                sx: {
                  borderRadius: '8px',
                  paddingInline: '6px',
                  border: '1px solid #dadde9',
                  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                  '& .MuiMenuItem-root': {
                    fontSize: '12px',
                    lineHeight: '16px',
                    height: 'fit-content',
                    padding: '8px',
                    cursor: 'pointer',
                    width: '100%',
                    borderRadius: '8px',
                    '&:hover': {
                      background: colors.primaryLight,
                      color: colors.primary,
                    },
                  },
                  '& .Mui-selected': {
                    color: colors.primary,
                    backgroundColor: 'transparent !important',
                  },
                },
              },
            }}
          >
            <MenuItem value={'0'} style={{ paddingLeft: '12px' }}>
              All
            </MenuItem>
            <MenuItem value={'1'} style={{ paddingLeft: '12px' }}>
              {user && getFullName(user)}
            </MenuItem>
          </Select>
        </div>
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Status
          </Typography>
          <div className="status-container">
            {statusesAccount.map((status, index) => {
              const statusColors = getColorsStatus(
                index,
                statusesAccount.length,
              );
              return (
                <SingleStatusOptionComponent
                  key={index}
                  statusColor={statusColors}
                  status={status}
                  changeStatus={handleChangeStatusAccount}
                  selected={isAccountStatusSelected(status.option)}
                />
              );
            })}
          </div>
        </div>
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Size (Employees amount)
          </Typography>
          <div className="sizes-container">
            {sizes.map((size, index) => {
              return (
                <div
                  key={index}
                  className={
                    'size ' +
                    (filters.numberOfEmployeesMin === size.min
                      ? 'size-selected'
                      : undefined)
                  }
                  onClick={() => handleChangeSize(size)}
                >
                  {size.toString}
                </div>
              );
            })}
          </div>
        </div>
        <div className="filter-section">
          <Typography fontSize={14} fontWeight={600}>
            Existing information
          </Typography>
          <div className="sizes-container">{externalInformationOptions}</div>
        </div>

        {externalProperties?.data?.data.map((externalProperty, index) => {
          return (
            <div key={externalProperty.id} className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                {externalProperty.publicName}
              </Typography>
              <div className="external-properties-container">
                {getExternalPropertyLabels(externalProperty).map(
                  (option, index2) => {
                    const isSelected = externalProperty.options
                      ? filters.externalProperties
                          ?.filter(
                            (ep) =>
                              ep.externalPropertyId === externalProperty.id,
                          )
                          .at(0)
                          ?.values?.includes(externalProperty.options[index2])
                      : false;
                    return (
                      <FilterExternalProperty
                        key={index2}
                        option={option}
                        isSelected={isSelected ?? false}
                        externalProperty={externalProperty}
                        index={index}
                        index2={index2}
                        handleChangeExternalProperty={
                          handleChangeExternalProperty
                        }
                      />
                    );
                  },
                )}
              </div>
            </div>
          );
        })}

        <Button
          variant="text"
          color="secondary"
          className="more-filters-button"
          endIcon={!moreFiltersOpened ? <BottomArrow /> : <TopArrow />}
          onClick={() => setMoreFiltersOpened((prev) => !prev)}
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          {!moreFiltersOpened ? 'More filters' : 'Hide filters'}
        </Button>
        {moreFiltersOpened && (
          <>
            <div className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                Imported from
              </Typography>
              <Select
                value={filters.importId ?? -1}
                onChange={(ev) => {
                  const value = ev.target.value as number;
                  handleChangeImport(value);
                }}
                className="select"
                MenuProps={{
                  style: { zIndex: 35001 },
                  PaperProps: {
                    sx: {
                      borderRadius: '8px',
                      paddingInline: '6px',
                      border: '1px solid #dadde9',
                      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                      '& .MuiMenuItem-root': {
                        fontSize: '12px',
                        lineHeight: '16px',
                        height: 'fit-content',
                        padding: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        borderRadius: '8px',
                        '&:hover': {
                          background: colors.primaryLight,
                          color: colors.primary,
                        },
                      },
                      '& .Mui-selected': {
                        color: colors.primary,
                        backgroundColor: 'transparent !important',
                      },
                    },
                  },
                }}
                renderValue={(value) => {
                  if (value === -1) {
                    return 'Select a file';
                  }
                  const importFile = importFiles?.data?.data.find(
                    (file: Import) => file.id === value,
                  );
                  return importFile?.fileName ?? 'Import';
                }}
              >
                <MenuItem value={-1}>X</MenuItem>
                {importFiles?.data?.data.map((file: Import) => (
                  <MenuItem value={file.id} key={file.id}>
                    {file.fileName}
                  </MenuItem>
                ))}
              </Select>
              {/* <Autocomplete
                className="add-new-auto-complete"
                // value={{
                //   id: selectedCompany,
                //   name: mappedAccount.find(
                //     (el) => el.objectId === selectedCompany
                //   )?.name,
                // }}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                onChange={(e, value) => {
                  if (value) formik.setFieldValue("industry", value.value);
                  else formik.setFieldValue("industry", null);
                }}
                options={linkedinIndustries}
                getOptionLabel={(option) => option.label || ""}
                // onChange={(e, label) => {
                //   if (label) {
                //     const account = accounts?.data?.data.find(
                //       (item) => item.name === label
                //     );
                //     if (account) {
                //       handleSelectGeoUrn(account.name);
                //     }
                //   } else {
                //     handleSelectGeoUrn("");
                //   }
                // }}
                PaperComponent={({ children }) => (
                  <div className="autocomplete-paper">{children}</div>
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    value={option.value || undefined}
                    className="autocomplete-option"
                  >
                    {option.label}
                  </li>
                )}
                fullWidth
                // renderTags={(value) => {
                //   return value.map((option, index) => {
                //     return <>{option.name}</>;
                //   });
                // }}
                disablePortal
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    placeholder="Start typing keywords..."
                    fullWidth
                    value={formik.values.industry}
                    onChange={formik.handleChange}
                  />
                )}
              /> */}
            </div>
            <div className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                Created Date
              </Typography>
              <ClickAwayListener
                onClickAway={() => setOpenCreatedDateTooltip(false)}
              >
                <div>
                  <DateRangePickerTooltip
                    title={
                      <DateRangePicker
                        startDate={
                          filters.createdDateMin
                            ? new Date(filters.createdDateMin)
                            : undefined
                        }
                        endDate={
                          filters.createdDateMax
                            ? new Date(filters.createdDateMax)
                            : undefined
                        }
                        handleChangeRange={handleCreatedDateChanges}
                      />
                    }
                    open={openCreatedDateTooltip}
                    onClose={() => setOpenCreatedDateTooltip(false)}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div className="date-range-picker-container">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            filters.createdDateMin
                              ? new Date(filters.createdDateMin)
                              : null
                          }
                          onChange={(ev) => {
                            if (moment(ev).isValid()) {
                              const newDate = new Date(ev ?? '');
                              if (newDate?.getUTCHours() === 22) {
                                newDate?.setHours(newDate.getHours() + 4);
                              }
                              setFilters({
                                ...filters,
                                createdDateMin: newDate.toJSON() ?? undefined,
                              });
                            }
                          }}
                          disableOpenPicker
                          className="date-picker-range"
                          slotProps={{
                            textField: {
                              onFocus: () => setOpenCreatedDateTooltip(true),
                              variant: 'standard',
                            },
                          }}
                        />
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            filters.createdDateMax
                              ? new Date(filters.createdDateMax)
                              : null
                          }
                          onChange={(ev) => {
                            if (moment(ev).isValid()) {
                              const newDate = new Date(ev ?? '');
                              if (newDate?.getUTCHours() === 22) {
                                newDate?.setHours(newDate.getHours() + 4);
                              }
                              setFilters({
                                ...filters,
                                createdDateMax: newDate.toJSON() ?? undefined,
                              });
                            }
                          }}
                          disableOpenPicker
                          className="date-picker-range"
                          slotProps={{
                            textField: {
                              onFocus: () => setOpenCreatedDateTooltip(true),
                              variant: 'standard',
                            },
                          }}
                        />
                        {(filters.createdDateMin || filters.createdDateMin) && (
                          <IconButton
                            style={{
                              width: 'fit-content',
                              height: 'fit-content',
                            }}
                            onClick={handleClearCreateDate}
                          >
                            <CloseMIcon color={colors.inactive} />
                          </IconButton>
                        )}
                      </LocalizationProvider>
                    </div>
                  </DateRangePickerTooltip>
                </div>
              </ClickAwayListener>
            </div>
            <div className="filter-section">
              <Typography fontSize={14} fontWeight={600}>
                Last activity Date
              </Typography>
              <ClickAwayListener
                onClickAway={() => setOpenLastActivityDateTooltip(false)}
              >
                <div>
                  <DateRangePickerTooltip
                    title={
                      <DateRangePicker
                        startDate={
                          filters.lastActivityDateMin
                            ? new Date(filters.lastActivityDateMin)
                            : undefined
                        }
                        endDate={
                          filters.lastActivityDateMax
                            ? new Date(filters.lastActivityDateMax)
                            : undefined
                        }
                        handleChangeRange={handleLastActivityDateChanges}
                      />
                    }
                    open={openLastActivityDateTooltip}
                    onClose={() => setOpenLastActivityDateTooltip(false)}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div className="date-range-picker-container">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            filters.lastActivityDateMin
                              ? new Date(filters.lastActivityDateMin)
                              : null
                          }
                          onChange={(ev) => {
                            if (moment(ev).isValid()) {
                              const newDate = new Date(ev ?? '');
                              if (newDate?.getUTCHours() === 22) {
                                newDate?.setHours(newDate.getHours() + 4);
                              }
                              setFilters({
                                ...filters,
                                lastActivityDateMin:
                                  newDate.toJSON() ?? undefined,
                              });
                            }
                          }}
                          disableOpenPicker
                          className="date-picker-range"
                          slotProps={{
                            textField: {
                              onFocus: () =>
                                setOpenLastActivityDateTooltip(true),
                              variant: 'standard',
                            },
                          }}
                        />
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={
                            filters.lastActivityDateMax
                              ? new Date(filters.lastActivityDateMax)
                              : null
                          }
                          onChange={(ev) => {
                            if (moment(ev).isValid()) {
                              const newDate = new Date(ev ?? '');
                              if (newDate?.getUTCHours() === 22) {
                                newDate?.setHours(newDate.getHours() + 4);
                              }
                              setFilters({
                                ...filters,
                                lastActivityDateMax:
                                  newDate.toJSON() ?? undefined,
                              });
                            }
                          }}
                          disableOpenPicker
                          className="date-picker-range"
                          slotProps={{
                            textField: {
                              onFocus: () =>
                                setOpenLastActivityDateTooltip(true),
                              variant: 'standard',
                            },
                          }}
                        />
                        {(filters.lastActivityDateMin ||
                          filters.lastActivityDateMin) && (
                          <IconButton
                            style={{
                              width: 'fit-content',
                              height: 'fit-content',
                            }}
                            onClick={handleClearLastActivityDate}
                          >
                            <CloseMIcon color={colors.inactive} />
                          </IconButton>
                        )}
                      </LocalizationProvider>
                    </div>
                  </DateRangePickerTooltip>
                </div>
              </ClickAwayListener>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountFilters;
