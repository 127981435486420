import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconReports = ({ color = colors.black, className, onClick }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M20.0037 12V7.82623C20.0037 7.29562 19.7927 6.78678 19.4175 6.41164L16.5883 3.58246C16.2132 3.2072 15.7043 2.99622 15.1737 2.99622H5.9979C4.89287 2.99622 3.99707 3.89202 3.99707 4.99705V19.0029C3.99707 20.1079 4.89287 21.0037 5.9979 21.0037H7.99874"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="11"
        y="17.2522"
        width="3.00125"
        height="3.75156"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="14.001"
        y="13.0004"
        width="3.00125"
        height="8.00333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="17.002"
        y="15.2513"
        width="3.00125"
        height="5.7524"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99805 7.99832H15.001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99805 11.4998H10.4991"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconReports;
