import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconArrowSTopRight = ({
  color = colors.black,
  className,
  style,
}: IProps) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
    >
      <path
        d="M8 8V2L2 2"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8L8 2"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconArrowSTopRight;
