export enum CronoCallSearchSortType {
  DATE = 'Date',
  DATEDESC = 'DateDesc',
  COMPANYNAME = 'CompanyName',
  COMPANYNAMEDESC = 'CompanyNameDesc',
  CONTACTNAME = 'ContactName',
  CONTACTNAMEDESC = 'ContactNameDesc',
  FEEDBACKTYPE = 'FeedbackType',
  FEEDBACKTYPEDESC = 'FeedbackTypeDesc',
  OWNERFULLNAME = 'OwnerFullName',
  OWNERFULLNAMEDESC = 'OwnerFullNameDesc',
}
