import SequenceStatusType from '../enums/prospectSequenceStatus';
import { TaskTodoType } from '../enums/taskTodoType';
import { LinkedinType } from '../logLinkedin';

export interface SequenceInstanceDetail {
  id: number;
  strategyId: number;
  active: boolean;
  actualStep: number;
  numberOfSteps: number;
  status: SequenceStatusType;
  prospect: ProspectSequenceInstance;
  account: AccountSequenceInstance;
  tasks: TaskSequenceInstance[];
}

export interface ProspectSequenceInstance {
  objectId?: string | null;
  accountId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  owned: boolean;
  user?: string | null;
  isActive?: boolean | null;
  email?: string | null;
}

interface AccountSequenceInstance {
  objectId?: string | null;
  name?: string | null;
  website?: string;
}

export interface TaskSequenceInstance {
  id: number;
  number: number;
  type: TaskTodoType;
  skipped: boolean | null;
  activityDate: Date;
  call: SequenceInstanceCall | null;
  linkedin: SequenceInstanceLinkedin | null;
  email: SequenceInstanceEmail | null;
}

interface SequenceInstanceCall {
  result: SequenceInstanceCallStatus;
  completed: boolean;
}
interface SequenceInstanceLinkedin {
  result: SequenceInstanceLinkedinStatus;
  completed: boolean;
  type: LinkedinType;
}
interface SequenceInstanceEmail {
  result: SequenceInstanceEmailStatus;
  opened: number;
  replied: number;
  linkClicked: number;
  completed: boolean;
}

export enum SequenceInstanceCallStatus {
  CONNECTED = 'Connected',
  NOT_ANSWERED = 'NotAnswered',
  VOICEMAIL = 'Voicemail',
  GATEKEEPER = 'GateKeeper',
  WRONG_NUMBER = 'WrongNumber',
  UNKNOWN = 'Unknown',
}

export enum SequenceInstanceLinkedinStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  REPLIED = 'Replied',
  NO_REPLY = 'NoReply',
  UNKNOWN = 'Unknown',
  CONNECTED = 'Connected',
}

export enum SequenceInstanceEmailStatus {
  OPENED = 'Opened',
  BOUNCED = 'Bounced',
  REPLIED = 'Replied',
  NOT_OPENED = 'NotOpened',
  UNKNOWN = 'Unknown',
}

interface AggregateStep {
  number: number;
  prospectsOnStep: number;
}

export interface StrategyDetail {
  strategyId: number;
  sequenceInstances?: SequenceInstanceDetail[];
  sequenceSteps?: AggregateStep[] | null;
  maxTotalStep: number;
}
