import { Task } from 'crono-fe-common/types/task';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';

export const findLatesActivity = (tasks: Task[]): any | null => {
  if (tasks.length === 0) return null;
  let max = tasks[0];
  tasks.forEach(
    (task) => (max = task.activityDate > max.activityDate ? task : max),
  );
  return renderActivityIcon(max.taskSubtype);
};

export const renderActivityIcon = (
  taskSubtype: string,
  color?: string,
  className?: string,
) => {
  switch (taskSubtype.toLowerCase()) {
    case 'call':
      return (
        <CallIcon
          color={color}
          className={`activity-icon ${className ?? ''}`}
        />
      );
    case 'email':
      return (
        <EmailIcon
          color={color}
          className={`activity-icon ${className ?? ''}`}
        />
      );
    case 'linkedin':
      return (
        <LinkedinIcon
          color={color}
          className={`activity-icon ${className ?? ''}`}
        />
      );
    default:
      return <></>;
  }
};
