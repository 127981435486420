import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const LinkedinAcceptedInvitationIcon = ({
  color = colors.nurtureHover,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08595 5.83295H8.12865V6.85045C8.4229 6.26525 9.1775 5.73945 10.3111 5.73945C12.4841 5.73945 13 6.90435 13 9.04165V10.5L12 11.5L10.8 10.5V9.5284C10.8 8.31125 10.5058 7.62485 9.75665 7.62485C8.7177 7.62485 8.28595 8.3646 8.28595 9.52785V13H6.08595V5.83295ZM2.3135 12.9065H4.5135V5.73945H2.3135V12.9065ZM4.82865 3.4025C4.82873 3.5869 4.79216 3.76948 4.72106 3.93962C4.64997 4.10977 4.54576 4.26408 4.4145 4.3936C4.14852 4.65794 3.7885 4.80591 3.4135 4.805C3.03916 4.80475 2.67997 4.65716 2.4136 4.39415C2.28282 4.26419 2.17896 4.1097 2.10799 3.93954C2.03702 3.76937 2.00032 3.58687 2 3.4025C2 3.03015 2.1485 2.67375 2.41415 2.41085C2.68029 2.14749 3.03963 1.99984 3.41405 2C3.78915 2 4.14885 2.14795 4.4145 2.41085C4.6796 2.67375 4.82865 3.03015 4.82865 3.4025Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5303 10.4697C15.8232 10.7626 15.8232 11.2374 15.5303 11.5303L12.5303 14.5303C12.2374 14.8232 11.7626 14.8232 11.4697 14.5303L9.46967 12.5303C9.17678 12.2374 9.17678 11.7626 9.46967 11.4697C9.76256 11.1768 10.2374 11.1768 10.5303 11.4697L12 12.9393L14.4697 10.4697C14.7626 10.1768 15.2374 10.1768 15.5303 10.4697Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0008 10.2429L12.0016 11.2421L11.3804 10.6209C11.2078 10.4484 11.011 10.3149 10.8008 10.2205V10.4998L12.0008 11.4998L13.0008 10.4998V10.2429Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkedinAcceptedInvitationIcon;
