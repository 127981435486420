import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { OpportunityPatch } from 'crono-fe-common/types/DTO/opportunityPatch';

export default function usePatchOpportunity() {
  const queryClient = useQueryClient();

  const patchOpportunity = (patchOpportunityBody: OpportunityPatch) => {
    const request: Request = {
      url: `${ENDPOINTS.opportunity.main}`,
      config: {
        method: 'patch',
        data: { data: patchOpportunityBody },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    OpportunityPatch
  >(patchOpportunity, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.opportunity.main]);
      queryClient.invalidateQueries([ENDPOINTS.opportunity.search]);
    },
  });

  return { data, mutate, ...rest };
}
