import React, { SetStateAction } from 'react';
import { Account } from 'crono-fe-common/types/account';
import * as Yup from 'yup';
import { checkIfValidLinkedinProfileUrl } from 'utils/fe-utils';
import VerificationStatusType from 'crono-fe-common/types/enums/verificationStatusType';

export const validationSchemaUpdate = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string(),
  phone: Yup.string(),
  mobilePhone: Yup.string(),
  company: Yup.string().required('Company is required'),
  title: Yup.string(),
  linkedin: Yup.string().test((val) => {
    if (!val) return true;
    return checkIfValidLinkedinProfileUrl(val);
  }),
});

export interface ProspectUpdateInputs {
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  mobilePhone: string;
  company: string;
  title: string | null;
  linkedin: string | null;
  accountId?: string | null;

  companyLinkedin?: string | null;
  companyLinkedinNumericId?: string | null;

  emailStatus?: VerificationStatusType;
}

export const initialValues = {
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  phone: '',
  mobilePhone: '',
  company: '',
  linkedin: '',
};

export interface InsertProspectProps {
  account: Account | null;
  addProspectInitialValues?: ProspectUpdateInputs;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  handleBack?: () => void;
}
