import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const SuggestionsIcon = ({ color = colors.black, className }: IProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.25 14.3918H9.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4057 14.1715C8.0737 13.2303 7.27495 11.587 7.5562 9.77803C7.8607 7.82278 9.49045 6.26203 11.4562 6.03178C14.1847 5.71153 16.5 7.83553 16.5 10.4995C16.5 12.0183 15.7455 13.3585 14.5927 14.173C14.3857 14.3193 14.25 14.5458 14.25 14.7993V16.8745C14.25 17.9103 13.4107 18.7495 12.375 18.7495H11.625C10.5892 18.7495 9.74995 17.9103 9.74995 16.8745V14.8023C9.74995 14.5465 9.61345 14.3185 9.4057 14.1715Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6.74943L18.8925 5.85693"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99992 14.2498L5.10742 15.1423"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74992 5.99943L5.85742 5.10693"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 14.2498L18.8925 15.1423"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 10.4998H20.0175"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.98242 10.4998H5.24992"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 16.4998H14.1525"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuggestionsIcon;
