import { ENDPOINTS } from 'config/endpoints';
import { useAuth } from 'context/auth';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';
import { invalidateEditProspectQueries } from 'hooks/services/prospect/useEditProspect';
import useGetScrapeFindBulkActive from 'hooks/services/scrape/useGetScrapeFindBulkActive';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

export const PollerContext = createContext<
  | {
      pollingEnrichInBulk: boolean;
    }
  | undefined
>(undefined);

const PollerProvider: FC<{ children: any }> = ({ children }) => {
  const { enabledContextCalls } = useAuth();

  const [pollingEnrichInBulk, setPollingEnrichInBulk] = useState(false);

  const {
    data: scrapeFindBulkActive,
    refetch: refetchGetScrapeFindBulkActive,
  } = useGetScrapeFindBulkActive(enabledContextCalls);
  const queryClient = useQueryClient();
  useEffect(() => {
    let t: NodeJS.Timeout;
    const ongoingCount = (
      scrapeFindBulkActive?.data?.data.filter((scrape) => {
        return (
          scrape.scrapeBulk.status !== ImportStatusType.Completed &&
          scrape.scrapeBulk.status !== ImportStatusType.CompletedWithErrors
        );
      }) ?? []
    ).length;

    if (ongoingCount > 0) {
      setPollingEnrichInBulk(true);
      t = setTimeout(() => {
        refetchGetScrapeFindBulkActive();
      }, 5000);
    } else {
      setPollingEnrichInBulk(false);
    }
    //If there are scrape bulks I invalidate the queries. This is called both if there are ongoing imports and if they are just completed
    if ((scrapeFindBulkActive?.data?.data.length ?? 0) > 0) {
      invalidateEditProspectQueries(queryClient);
      queryClient.invalidateQueries([ENDPOINTS.user.credits]);
    }
    return () => {
      clearTimeout(t);
    };
  }, [scrapeFindBulkActive]);

  return (
    <PollerContext.Provider
      value={{
        pollingEnrichInBulk,
      }}
    >
      {children}
    </PollerContext.Provider>
  );
};

export default PollerProvider;

export function usePollerContext() {
  const context = useContext(PollerContext);
  if (context === undefined) {
    throw new Error(`usePoller must be used within a PollerProvider`);
  }

  return context;
}
