import { Pie, Tooltip } from '@ant-design/charts';
import React from 'react';
import { DonutData } from '../tabsControllers/model';
import { Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  colorsSuggestionsDonut,
  colorsSuggestionsDonutHover,
} from '../legendData';

interface IProps {
  data: DonutData[];
  title?: string | number;
  subtitle?: string | number;
  tooltipTitle?: string;
  colorsChart?: string[];
}

const DonutChart = ({
  data,
  title,
  subtitle,
  colorsChart,
  tooltipTitle,
}: IProps) => {
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'label',
    radius: 1,
    innerRadius: 0.6,
    label: false as any,
    color: colorsChart,
    interactions: [
      {
        type: 'element-active',
      },
      // {
      //   type: "element-highlight-by-color",
      // },
    ],
    state: {
      active: {
        style: (element: any) => {
          //I take the original color to find the highlighted version
          const color = element?.model.color;
          const findHoverColor = colorsSuggestionsDonutHover.find((item) => {
            return item[0] === color;
          });
          return {
            fill: findHoverColor ? findHoverColor[1] : color,
          };
        },
      },
    },
    legend: false as any,
    tooltip: {
      customContent: (_: string, data: any[]) => {
        const condition =
          data[0]?.data.label === 'present' ? 'Existing' : 'Missing';
        return (
          <div style={{ padding: '14px 4px' }}>
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight={'16px'}
              color={colors.grey11}
            >
              {condition} {tooltipTitle}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight={'16px'}
              color={colors.black}
            >
              {data[0]?.data.value}{' '}
              {'(' +
                (data[0]?.data.label === 'present'
                  ? (100 - +(title?.toString().replace('%', '') ?? 0)).toFixed(
                      1,
                    ) + '%'
                  : title) +
                ')'}
            </Typography>
          </div>
        );
      },
      showMarkers: false,
    } as Tooltip,
    statistic: {
      title: {
        customHtml: (container: any, view: any, datum: any) => {
          return (
            <Typography fontSize={16} fontWeight={600} lineHeight={'24px'}>
              {title}
            </Typography>
          );
        },
      } as any,

      content: {
        customHtml: (container: any, view: any, datum: any) => {
          return (
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight={'16px'}
              color={colors.grey11}
            >
              {subtitle}
            </Typography>
          );
        },
      } as any,
    },
  };
  return <Pie {...config} />;
};

export default DonutChart;
