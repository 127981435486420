import { createContext, FC, useContext, useState } from 'react';
interface HideTaskConstructionContextType {
  hideProgressTask: boolean;
  setHideProgressTask: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HideTaskConstructionContext = createContext<
  HideTaskConstructionContextType | undefined
>(undefined);

HideTaskConstructionContext.displayName = 'HideTaskConstructionContext';

const HideTaskConstructionProvider: FC<{ children: any }> = ({ children }) => {
  const [hideProgressTask, setHideProgressTask] = useState<boolean>(false);

  return (
    <HideTaskConstructionContext.Provider
      value={{
        hideProgressTask,
        setHideProgressTask,
      }}
    >
      {children}
    </HideTaskConstructionContext.Provider>
  );
};

export default HideTaskConstructionProvider;

export function useHideTaskConstruction() {
  const context = useContext(HideTaskConstructionContext);
  if (context === undefined) {
    throw new Error(
      `useHideTaskConstruction must be used within a HideTaskConstructionProvider`,
    );
  }

  return context;
}
