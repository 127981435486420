// import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from 'context/auth';
// import useGoogleAuthCallback from "hooks/services/google/useGoogleAuthCallback";
// import useDisconnectGoogleIntegration from "hooks/services/google/useDisconnectGoogleIntegration";
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { FeConstants } from 'constants/FeConstants';
import {
  ConnectWrapper,
  LayoutWrapper,
  OnboardingImageContainer,
} from './styles';
import Logo from 'assets/images/logo_crono.png';
import SalesforceLogo from 'assets/images/Icon_Salesforce.svg';
// import GmailConnect from "assets/images/gmailx2.png";
// import GmailLogo from "assets/images/gmail_logo.svg";
import HubspotLogo from 'assets/images/Icon_Hubspot.svg';
// import LinkedinLogo from "assets/images/Icon_Linkedin_extension.svg";
import PipedriveLogo from 'assets/images/Icon_Pipedrive.svg';
// import OutlookLogo from "assets/images/outlook.svg";
// import AircallLogo from "assets/images/Icon_Aircall.svg";
// import {
//   useGetExtensionStatus,
//   CronoExtensionStatus
// } from "hooks/services/crono-extension/useGetExtensionStatus";
import InstallLinkedinExtensionModal from 'crono-fe-common/components/Modal/InstallLinkedinExtensionModal';
import useCRMIntegrationGuard from 'hooks/guards/useCRMIntegrationGuard';
// import useEmailIntegrationGuard from "hooks/guards/useEmailIntegrationGuard";
// import useCallIntegrationGuard from "hooks/guards/useCallIntegrationGuard";
// import { useJuneAnalytics } from "context/june";
// import { useConditionalSnackBar } from "context/snackbar";
import { Button, Tooltip, Typography } from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
// import CloseMIcon from "crono-fe-common/icons/Icon-Close";
// import { EmailIntegrationType } from "crono-fe-common/types/emailSettings";
// import useDisconnectOutlookIntegration from "hooks/services/outlook/useDisconnectGoogleIntegration";
// import CallIntegrationType from "crono-fe-common/types/enums/callIntegrationType";
// import Role from "crono-fe-common/types/enums/role";
// import { FlexDiv } from "crono-fe-common/components/Layout/FlexDiv";
// import OnboardingStepsProgress from "./steps";
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { User } from 'crono-fe-common/types/user';
// import CronoError from "crono-fe-common/types/error";
import useGetActiveMigration from 'hooks/services/migration/useGetActiveMigration';
import { getExternalImage } from 'utils/fe-utils';
import { ImageResources } from 'crono-fe-common/types/enums/imageResources';

const Connect: FC = () => {
  const { user, refreshUser, useAuthGuard } = useAuth();
  useAuthGuard();
  const navigate = useNavigate();

  // const [scopeNotAcceptedError, setScopeNotAcceptedError] =
  useState<boolean>(false);
  const [openInstallLinkedinExtension, setOpenInstallLinkedinExtension] =
    useState<boolean>(false);

  const { data: migration } = useGetActiveMigration(
    user?.company?.integrationType === IntegrationType.CRONO,
  );

  // const analytics = useJuneAnalytics();

  const {
    isSuccessSF,
    isErrorSF,
    isSuccessHS,
    isErrorHS,
    isSuccessPD,
    isErrorPD,
    isSuccess,
    isLoading: isLoadingCRM,
    refetch: refetchCRMIntegrationGuard,
  } = useCRMIntegrationGuard(migration?.data?.data?.type ?? null);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (!isLoadingCRM && !isSuccess) {
      t = setTimeout(() => {
        refetchCRMIntegrationGuard();
      }, 1000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [isLoadingCRM, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      refreshUser();
      navigate(PATH.HOME);
    }
  }, [isSuccess]);

  // useConditionalSnackBar([
  //   {
  //     condition: !!scopeNotAcceptedError,
  //     message: "Accept all options to proceed with the integration",
  //     severity: "info"
  //   }
  // ]);

  // const { data: extensionStatus } = useGetExtensionStatus();

  // const {
  //   // isSuccessGoogle: googleIntegrated,
  //   // isErrorGoogle: googleNotIntegrated,
  //   // errorGoogle: googleError,
  //   // verifyGoogleIntegration,
  //   // isSuccessOutlook: outlookIntegrated,
  //   // isErrorOutlook: outlookNotIntegrated,
  //   // isLoadingOutlook,
  //   // errorOutlook: outlookError,
  //   // verifyOutlookIntegration,
  //   isSuccess: emailIntegrated,
  //   isError: emailNotIntegrated,
  //   error: emailError,
  //   isLoading: isLoadingEmail,
  //   verifyIntegration
  // } = useEmailIntegrationGuard();

  // const { isSuccess: googleDisconnected, disconnectGoogleIntegration } =
  //   useDisconnectGoogleIntegration();

  // const { isSuccess: outlookDisconnected, disconnectOutlookIntegration } =
  //   useDisconnectOutlookIntegration();

  // const {
  //   isSuccess: callIntegrated,
  //   isError: callNotIntegrated,
  //   isLoading: isLoadingCall
  // } = useCallIntegrationGuard();

  // useEffect(() => {
  //   if (googleDisconnected) {
  //     verifyIntegration();
  //   }
  // }, [googleDisconnected]);

  // useEffect(() => {
  //   if (outlookDisconnected) {
  //     verifyIntegration();
  //   }
  // }, [outlookDisconnected]);

  // useEffect(() => {
  //   if (emailError?.response?.status === 401) {
  //     navigate(PATH.LOGIN);
  //   }
  // }, [emailError]);

  // const {
  //   isLoading: isLoadingGoogleAuthCallback,
  //   isSuccess: isSuccessGoogleAuthCallback,
  //   error: errorGoogleAuthCallback,
  //   googleAuthCallback
  // } = useGoogleAuthCallback();

  // useEffect(() => {
  //   if (isSuccessGoogleAuthCallback) {
  //     verifyIntegration();
  //     if (analytics) {
  //       analytics.track("mail-integration", {
  //         crm: "google"
  //       });
  //     }
  //     refreshUser();
  //   }
  // }, [isSuccessGoogleAuthCallback]);

  const salesforceLogin = () => {
    if (!isSuccessSF) {
      window.location.replace(FeConstants.SALESFORCE_LOGIN_URL);
    }
  };

  const hubspotLogin = () => {
    if (!isSuccessHS) {
      window.location.replace(FeConstants.HUBSPOT_LOGIN_URL);
    }
  };

  const pipedriveLogin = () => {
    if (!isSuccessPD) {
      window.location.replace(FeConstants.PIPEDRIVE_LOGIN_URL);
    }
  };

  // const outlookLogin = () => {
  //   window.location.replace(FeConstants.OUTLOOK_LOGIN_URL);
  // };

  // const linkedinLogin = () => {
  //   if (
  //     extensionStatus &&
  //     extensionStatus === CronoExtensionStatus.NOT_INSTALLED
  //   ) {
  //     setOpenInstallLinkedinExtension(true);
  //   }
  // };

  // const googleLogin = useGoogleLogin({
  //   scope:
  //     "email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
  //   onSuccess: async (tokenResponse) => {
  //     const acceptedScopes = tokenResponse.scope
  //       .split(" ")
  //       .filter((scope) => scope !== "");
  //     if (
  //       acceptedScopes.includes("email") &&
  //       acceptedScopes.includes("https://www.googleapis.com/auth/gmail.send") &&
  //       acceptedScopes.includes(
  //         "https://www.googleapis.com/auth/gmail.readonly"
  //       )
  //     ) {
  //       googleAuthCallback(tokenResponse.code);
  //     } else {
  //       setScopeNotAcceptedError(true);
  //       setTimeout(() => {
  //         setScopeNotAcceptedError(false);
  //       }, 3000);
  //     }
  //   },

  //   flow: "auth-code"
  // });

  // const aircallLogin = () => {
  //   window.location.replace(FeConstants.AIRCALL_LOGIN_URL);
  // };

  const goHome = () => {
    if (isSuccess || !user) {
      navigate(`${PATH.HOME}`);
    }
  };

  useEffect(() => {
    //If we are standalone and we don't have an active migration, we should go to the home page
    if (
      user &&
      user.integrationType === IntegrationType.CRONO &&
      migration?.data?.data === null
    ) {
      navigate(PATH.HOME);
    }
  }, [user, migration]);

  const renderConnectCRM = (
    user: User,
    logo: string,
    loading: boolean | undefined,
    success: boolean | undefined,
    error: boolean,
    loginFn: () => void,
  ) => {
    const integrationType =
      user.company?.integrationType === IntegrationType.CRONO
        ? migration?.data?.data?.type ?? IntegrationType.CRONO
        : user.company?.integrationType;
    return (
      <>
        <div className="provider-box">
          <div className="icon-logo-container">
            <img
              src={logo}
              alt={integrationType}
              className={`connection-image ${integrationType}`}
            />
          </div>
          <div className={`connection-label`}>
            <Typography
              fontSize={'16px'}
              fontWeight={600}
              lineHeight={'24px'}
              color={colors.black}
            >
              {integrationType}
            </Typography>
            <Typography
              fontSize={'14px'}
              fontWeight={500}
              lineHeight={'18px'}
              color={
                !loading && success ? colors.darkGreen : colors.inactiveHover
              }
            >
              {success && !loading && 'Connected'}
              {(error || loading) && 'Not Connected'}
            </Typography>
          </div>
          <div
            className={`icons-buttons-container ${
              !loading && success ? 'connected' : 'not-connected'
            }`}
          >
            {!loading && success ? (
              <div className="connection-status">
                <Typography fontWeight={500}>Connected</Typography>
                <CheckMarkIcon color={colors.callScheduled} />
              </div>
            ) : (
              <div
                className="connection-status pointer"
                onClick={() => loginFn()}
              >
                <Typography fontWeight={500}>Connect</Typography>
              </div>
            )}
          </div>
        </div>

        <MainButton
          style={{
            minWidth: '120px',
          }}
          disabled={loading || !success}
          onClick={goHome}
        >
          Continue
        </MainButton>
      </>
    );
  };

  // const otherIntegrations = (
  //   <>
  //     {/* Gmail */}
  //     {user?.otherSettings?.hasEmail &&
  //       user.company?.emailIntegrationType === EmailIntegrationType.GMAIL && (
  //         <div className="provider-box">
  //           <div className={`icon-logo-container email`}>
  //             <img
  //               src={GmailLogo}
  //               alt={"gmail"}
  //               className={`connection-image gmail`}
  //             />
  //           </div>
  //           <div className={`connection-label`}>
  //             <Typography
  //               fontSize={"16px"}
  //               fontWeight={600}
  //               lineHeight={"24px"}
  //               color={colors.black}
  //             >
  //               {EmailIntegrationType.GMAIL}
  //             </Typography>
  //             <Typography
  //               fontSize={"14px"}
  //               fontWeight={500}
  //               lineHeight={"18px"}
  //               color={
  //                 (isLoadingGoogleAuthCallback && !emailIntegrated) ||
  //                 isSuccessGoogleAuthCallback ||
  //                 (emailIntegrated && !isSuccessGoogleAuthCallback)
  //                   ? colors.darkGreen
  //                   : colors.inactiveHover
  //               }
  //             >
  //               {emailIntegrated && !isSuccessGoogleAuthCallback && "Connected"}
  //               {(emailNotIntegrated || googleDisconnected) &&
  //                 !isSuccessGoogleAuthCallback &&
  //                 "Login required"}
  //               {isLoadingGoogleAuthCallback &&
  //                 !emailIntegrated &&
  //                 "Connecting"}
  //               {isSuccessGoogleAuthCallback && "Integration complete!"}
  //               {errorGoogleAuthCallback && "Error during integration"}
  //             </Typography>
  //           </div>
  //           {/* Login button */}
  //           {((emailNotIntegrated && !isSuccessGoogleAuthCallback) ||
  //             (googleDisconnected && !isSuccessGoogleAuthCallback)) && (
  //             <div className={"icons-buttons-container not-connected "}>
  //               <div
  //                 className="connection-status pointer"
  //                 onClick={() => googleLogin()}
  //               >
  //                 <Typography fontWeight={500}>Connect</Typography>
  //               </div>
  //             </div>
  //           )}
  //           {/* Logout */}
  //           {(emailIntegrated || isSuccessGoogleAuthCallback) &&
  //             !(googleDisconnected && !isSuccessGoogleAuthCallback) && (
  //               <div
  //                 className={"icons-buttons-container disconnect-integration"}
  //               >
  //                 <div
  //                   className="pointer"
  //                   onClick={() => {
  //                     disconnectGoogleIntegration();
  //                   }}
  //                 >
  //                   <Typography fontWeight={500}>Disconnect</Typography>
  //                 </div>
  //               </div>
  //             )}
  //         </div>
  //       )}
  //     {/* Outlook */}
  //     {user?.otherSettings?.hasEmail &&
  //       user.company?.emailIntegrationType === EmailIntegrationType.OUTLOOK && (
  //         <div className="provider-box">
  //           <div className={`icon-logo-container email`}>
  //             <img
  //               src={OutlookLogo}
  //               alt={"outlook"}
  //               className={`connection-image outlook`}
  //             />
  //           </div>
  //           <div className={`connection-label`}>
  //             <Typography
  //               fontSize={"16px"}
  //               fontWeight={600}
  //               lineHeight={"24px"}
  //               color={colors.black}
  //             >
  //               {EmailIntegrationType.OUTLOOK}
  //             </Typography>
  //             <Typography
  //               fontSize={"14px"}
  //               fontWeight={500}
  //               lineHeight={"18px"}
  //               color={
  //                 !isLoadingEmail && emailIntegrated
  //                   ? colors.darkGreen
  //                   : colors.inactiveHover
  //               }
  //             >
  //               {emailIntegrated && !isLoadingEmail && "Connected"}
  //               {emailNotIntegrated && !isLoadingEmail && "Login required"}
  //             </Typography>
  //           </div>
  //           {!isLoadingEmail && emailNotIntegrated ? (
  //             <div className={"icons-buttons-container not-connected"}>
  //               <div
  //                 className="connection-status pointer"
  //                 onClick={() => outlookLogin()}
  //               >
  //                 <Typography fontWeight={500}>Connect</Typography>
  //               </div>
  //             </div>
  //           ) : (
  //             <div className={"icons-buttons-container disconnect-integration"}>
  //               <div
  //                 className="pointer"
  //                 onClick={() => {
  //                   disconnectOutlookIntegration();
  //                 }}
  //               >
  //                 <Typography fontWeight={500}>Disconnect</Typography>
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //       )}
  //     {/* Aircall */}
  //     <div className="provider-box">
  //       <div className="icon-logo-container">
  //         <img
  //           src={AircallLogo}
  //           alt={"Aircall"}
  //           className={`connection-image aircall`}
  //         />
  //       </div>
  //       <div className={`connection-label`}>
  //         <Typography
  //           fontSize={"16px"}
  //           fontWeight={600}
  //           lineHeight={"24px"}
  //           color={colors.black}
  //         >
  //           {CallIntegrationType.AIRCALL}
  //         </Typography>
  //         <Typography
  //           fontSize={"14px"}
  //           fontWeight={500}
  //           lineHeight={"18px"}
  //           color={
  //             !isLoadingCall && callIntegrated
  //               ? colors.darkGreen
  //               : colors.inactiveHover
  //           }
  //         >
  //           {callIntegrated && !isLoadingCall && "Connected"}
  //           {callNotIntegrated && !isLoadingCall && "Login required"}
  //         </Typography>
  //       </div>
  //       <div
  //         className={`icons-buttons-container ${
  //           !isLoadingCall && callIntegrated ? "connected" : "not-connected"
  //         }`}
  //       >
  //         {!isLoadingCall && callIntegrated ? (
  //           <div className="connection-status">
  //             <Typography fontWeight={500}>Connected</Typography>
  //             <CheckMarkIcon color={colors.callScheduled} />
  //           </div>
  //         ) : (
  //           <div
  //             className="connection-status pointer"
  //             onClick={() => aircallLogin()}
  //           >
  //             <Typography fontWeight={500}>Connect</Typography>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //     {/* Linkedin extension */}
  //     <div className="provider-box">
  //       <div className="icon-logo-container">
  //         <img
  //           src={LinkedinLogo}
  //           alt={"Linkedin"}
  //           className={`connection-image linkedin`}
  //         />
  //       </div>
  //       <div className={`connection-label`}>
  //         <Typography
  //           fontSize={"16px"}
  //           fontWeight={600}
  //           lineHeight={"24px"}
  //           color={colors.black}
  //         >
  //           Chrome extension
  //         </Typography>
  //         <Typography
  //           fontSize={"14px"}
  //           fontWeight={500}
  //           lineHeight={"18px"}
  //           color={
  //             extensionStatus ===
  //             CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE
  //               ? colors.darkGreen
  //               : colors.inactiveHover
  //           }
  //         >
  //           {extensionStatus ===
  //             CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE &&
  //             "Connected"}
  //           {extensionStatus ===
  //             CronoExtensionStatus.INSTALLED_WITHOUT_LINKEDIN_COOKIE &&
  //             "LinkedIn not open"}
  //           {extensionStatus ===
  //             CronoExtensionStatus.NOT_CHROME_BASED_BROWSER &&
  //             "Use Chrome to integrate"}
  //           {extensionStatus === CronoExtensionStatus.NOT_INSTALLED &&
  //             "Install LinkedIn"}
  //         </Typography>
  //       </div>
  //       <div
  //         className={`icons-buttons-container ${
  //           extensionStatus ===
  //           CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE
  //             ? "connected"
  //             : "not-connected"
  //         }`}
  //       >
  //         {extensionStatus ===
  //         CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE ? (
  //           <div className="connection-status">
  //             <Typography fontWeight={500}>Installed</Typography>
  //             <CheckMarkIcon color={colors.callScheduled} />
  //           </div>
  //         ) : (
  //           <div
  //             className="connection-status pointer"
  //             onClick={() => linkedinLogin()}
  //           >
  //             <Typography fontWeight={500}>Install</Typography>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </>
  // );

  const integrationsLayout = (
    <>
      <InstallLinkedinExtensionModal
        open={openInstallLinkedinExtension}
        setOpen={setOpenInstallLinkedinExtension}
      />

      <div className="connect-card">
        <div className="providers">
          {user &&
            (user?.company?.integrationType === IntegrationType.SALESFORCE ||
              migration?.data?.data?.type === IntegrationType.SALESFORCE) &&
            renderConnectCRM(
              user,
              SalesforceLogo,
              isLoadingCRM,
              isSuccessSF,
              isErrorSF,
              salesforceLogin,
            )}
          {user &&
            (user?.company?.integrationType === IntegrationType.HUBSPOT ||
              migration?.data?.data?.type === IntegrationType.HUBSPOT) &&
            renderConnectCRM(
              user,
              HubspotLogo,
              isLoadingCRM,
              isSuccessHS,
              isErrorHS,
              hubspotLogin,
            )}
          {user &&
            (user?.company?.integrationType === IntegrationType.PIPEDRIVE ||
              migration?.data?.data?.type === IntegrationType.PIPEDRIVE) &&
            renderConnectCRM(
              user,
              PipedriveLogo,
              isLoadingCRM,
              isSuccessPD,
              isErrorPD,
              pipedriveLogin,
            )}
          {/* {otherIntegrations} */}
        </div>
      </div>

      {/* Salesforce webhooks */}
      {/* {user &&
        user.userRoles?.includes(Role.MANAGER) &&
        user.company?.integrationType === IntegrationType.SALESFORCE && (
          <div style={{ display: "flex", maxWidth: "44rem" }}>
            <Typography paddingRight="4px">*</Typography>
            <Typography>
              Crono integrates with Salesforce through Web Hooks. If you are a
              Salesforce Admin, you can check the Web Hooks status{" "}
              <a
                style={{ textDecoration: "none", color: colors.primaryDark }}
                href={`${process.env.REACT_APP_BE_URL}/api/webhookcreator/?token=${user?.token.token}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </Typography>
          </div>
        )} */}
    </>
  );

  return (
    <LayoutWrapper id="layout-wrapper">
      <ConnectWrapper id="connect-wrapper">
        <div className="onboarding-steps-container">
          <div className="logo-container">
            <img src={Logo} className="logo" alt="Crono Logo" />
          </div>
          <div className="divider" />
          <div className="connect-crm">
            <div className="step-title-container">
              <Typography
                fontSize={'28px'}
                fontWeight={700}
                lineHeight={'32px'}
              >
                Connect with your CRM
              </Typography>
              <Typography
                fontSize={'16px'}
                fontWeight={500}
                lineHeight={'24px'}
                color={colors.grey11}
                textAlign={'center'}
                width={'300px'}
              >
                Integrate your usual CRM to transfer all prospects in seconds
              </Typography>
            </div>
            <div className="crm-connect-card">{integrationsLayout}</div>
          </div>
        </div>
      </ConnectWrapper>
      <OnboardingImageContainer>
        <img
          src={getExternalImage(ImageResources.ONBOARDING)}
          alt="onboarding"
          className="onboarding-image"
        />
      </OnboardingImageContainer>
    </LayoutWrapper>
  );
};

export default Connect;
