import React from 'react';
import IProps from './interface';
import { colors } from '../theme';

const IconFindPhone = ({
  color = colors.grey3,
  className,
  onClick,
  style,
  width = '40',
  height = '40',
}: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8842 23.1156C14.9342 21.1656 13.4642 18.9823 12.4876 16.7673C12.2826 16.2989 12.4026 15.7506 12.7642 15.3889L14.1292 14.0239C15.2476 12.9056 15.2476 11.3239 14.2709 10.3473L12.3159 8.39227C11.0142 7.0906 8.90423 7.0906 7.60256 8.39227L6.51589 9.47727C5.28089 10.7123 4.76589 12.4939 5.09923 14.2606C5.92256 18.6156 8.45256 23.3839 12.5342 27.4656C16.6159 31.5473 21.3842 34.0773 25.7392 34.9006C27.5059 35.2339 29.2876 34.7189 30.5226 33.4839L31.6076 32.3989C32.9092 31.0973 32.9092 28.9873 31.6076 27.6856L29.6526 25.7306C28.6759 24.7539 27.0926 24.7539 26.1176 25.7306L24.6126 27.2373C24.2509 27.5989 23.7026 27.7206 23.2342 27.5139C21.0192 26.5356 18.8342 25.0639 16.8842 23.1156Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3888 7.61123C34.5371 9.75954 34.5371 13.2405 32.3888 15.3888C30.2405 17.5371 26.7595 17.5371 24.6112 15.3888C22.4629 13.2405 22.4629 9.75954 24.6112 7.61123C26.7595 5.46292 30.2423 5.46292 32.3888 7.61123"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 19L33 16"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconFindPhone;
