import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const ResetPasswordFlowWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  height: 100vh;

  .logo-header {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);

    .logo {
      width: 98px;
      height: auto;
      cursor: pointer;
    }
  }
`;
