import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { ExternalPropertyStatusMigrate } from 'crono-fe-common/types/DTO/externalPropertyStatusMigrate';
import { useFilters } from 'pages/home/context/filters';

export default function useMigrateExternalPropertyStatus() {
  const queryClient = useQueryClient();

  const migrateExternalPropertyStatus = (
    data: ExternalPropertyStatusMigrate,
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.externalProperty.status.migrate}`,
      config: {
        method: 'post',
        data: { data: data },
      },
    };
    return client(request);
  };
  const { resetAccountFilters, resetProspectFilters } = useFilters();

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    ExternalPropertyStatusMigrate
  >(migrateExternalPropertyStatus, {
    onSettled: () => {
      //This way we remove the filters applied, avoiding possible errors given by the old status being used as externalProperty
      resetAccountFilters(true);
      resetProspectFilters(true);
      queryClient.invalidateQueries([ENDPOINTS.externalProperty.main]);
      queryClient.invalidateQueries([ENDPOINTS.externalProperty.status.main]);
      queryClient.invalidateQueries([ENDPOINTS.applyStatusMigration.main]);
    },
  });

  return { data, mutate, ...rest };
}
