import React from 'react';
import { OnBoardingStep } from '..';
import { Button } from '@mui/material';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import CrmIntegrations from '../pages/crm';
import EmailIntegration from '../pages/email';
import LinkedinIntegration from '../pages/linkedin';
import { IntegrationWrapper } from '../pages/styles';
import AdminSalesforce from '../pages/adminSalesforce';

import { IOnboardingSteps } from 'utils/fe-utils';

interface IProps {
  step: OnBoardingStep;
  completed: boolean;
  firstStep: boolean;
  handleGoNextStep: () => void;
  handleBack: () => void;
  onboardingState: IOnboardingSteps | null;

  //TODO: needed just for test
  // handleDisconnect: (step: OnBoardingStep) => void;
}

//This component is used to render the integration page, depending on what is the current step
const IntegrationStep = ({
  step,
  completed,
  firstStep,
  onboardingState,
  handleGoNextStep,
  handleBack,
}: IProps) => {
  // This is only used for testing
  // const resetButton = completed && (
  //   <Button
  //     variant="contained"
  //     color="error"
  //     // onClick={() => handleDisconnect(step)}
  //   >
  //     Reset (remove before release in dev/prod)
  //   </Button>
  // );

  switch (step) {
    case 'adminCrmConnected':
      return (
        <IntegrationWrapper>
          <AdminSalesforce
            completed={onboardingState?.SalesforceAdmin === 'Completed'}
          />
          <div className="onboarding-navigation">
            {/* {resetButton} */}
            <MainButton
              style={{ width: '120px' }}
              disabled={onboardingState?.SalesforceAdmin === 'ToDo'}
              onClick={handleGoNextStep}
            >
              Next
            </MainButton>
          </div>
        </IntegrationWrapper>
      );
    case 'crmConnected':
      return (
        <IntegrationWrapper>
          <CrmIntegrations />
          <div className="onboarding-navigation">
            {/* {resetButton} */}
            {!firstStep && (
              <CancelButton style={{ width: '120px' }} onClick={handleBack}>
                Back
              </CancelButton>
            )}
            <MainButton
              style={{ width: '120px' }}
              disabled={onboardingState?.CrmConnected === 'ToDo'}
              onClick={handleGoNextStep}
            >
              Next
            </MainButton>
          </div>
        </IntegrationWrapper>
      );
    case 'emailConnected':
      return (
        <IntegrationWrapper style={{ gap: '32px' }}>
          <EmailIntegration />
          <div className="onboarding-navigation">
            {/* {resetButton} */}
            {!firstStep && (
              <CancelButton style={{ width: '120px' }} onClick={handleBack}>
                Back
              </CancelButton>
            )}
            <MainButton style={{ width: '120px' }} onClick={handleGoNextStep}>
              Next
            </MainButton>
          </div>
        </IntegrationWrapper>
      );
    case 'linkedinConnected':
      return (
        <IntegrationWrapper>
          <LinkedinIntegration />
          <div className="onboarding-navigation">
            {/* {resetButton} */}
            {!firstStep && (
              <CancelButton style={{ width: '120px' }} onClick={handleBack}>
                Back
              </CancelButton>
            )}
            <MainButton style={{ width: '120px' }} onClick={handleGoNextStep}>
              Finish
            </MainButton>
          </div>
        </IntegrationWrapper>
      );
  }
};

export default IntegrationStep;
