import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SingleStatusComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  .resync-crm-status {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 8px 12px 8px 8px !important;
    border-radius: 32px;
    min-width: 180px;
    border: 1px solid ${colors.grey444};
    background: ${colors.grey4};
    height: 40px;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${colors.grey4Hover};
    }
  }
  .status-list-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
  }
  .status-list-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid ${colors.grey444};
  }
  .end-of-page-message {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    gap: 10px;
    background: ${colors.primaryLight};
    .text-button-link {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }
  .statuses-container {
    display: flex;
    flex-direction: column;
    padding: 2px 10px 2px 4px;
    gap: 0px;
  }
  .stage-row {
    padding: 8px !important;
  }
  .linked-with-crm-tooltip {
    display: flex;
    align-items: center;
    gap: 4px;
    filter: opacity(0.6);
    height: 32px;
    :hover {
      filter: opacity(1);
    }
  }
  .external-property-name {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    filter: grayscale(1);
    :hover {
      filter: grayscale(0);
    }
  }
  .changing-loader {
    transform: scale3d(0.3, 0.3, 1);
  }
`;
