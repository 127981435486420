import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

type AutomatedProps = {
  progress?: number;
  completed?: boolean;
};

export const AutomatedStepsWrapper = styled.div<AutomatedProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .horizontal-divider {
    display: flex;
    align-self: center;
    border-top: 1px solid ${colors.grey444};
    width: 100%;
  }

  .header-container {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
  }
  .header {
    width: 230px;
  }

  .integration-setup-button {
    display: flex;
    gap: 6px;
    padding: 3px 7px;
    border: 1px solid ${colors.grey33};
    border-radius: 34px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  .integration-setup-button:hover {
    background-color: ${colors.grey444};
  }
  .integration-icon-button {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    padding-bottom: 1px;
  }
  .integration-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .automated-task-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  .automated-task-header-wrapper {
    display: flex;
    width: 100%;
    padding: 16px 18px;
    align-items: center;
    gap: 16px;
  }
  .automated-task-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .next-auto-task {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background-color: ${colors.linkedinRepliedMessageLightBlue};
    width: fit-content;
    padding: 0px 4px;
  }
  .next-auto-task-text {
    color: ${colors.intenseBlue};
    font-size: 11px;
    font-weight: 500;
  }
  .completed-step {
    color: ${colors.grey2};
    text-decoration: line-through;
    text-decoration-thickness: from-font;
  }
  .onboarding-step-subtitle {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50px;
    left: 80px;
    width: 250px;
  }
  .onboarding-component-container {
    margin-left: 50px;
  }
  .onboardingStepsOptions-components {
    display: flex;
    padding: 0px 24px 16px 24px;
    align-items: flex-end;
    gap: 6px;
    margin-top: 26px;
  }
  .completed-checkmark {
    background-color: ${colors.primary};
    width: 32px;
    height: 32px;
    padding: 4px;
    margin-right: 7px;
  }
  .execute-icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .execute-right-side-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .execute-icon-wrapper-error {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.inactiveLight};
    border-radius: 50%;
  }
  .linkedin-log-in-icon {
    padding-bottom: 2px;
    height: 18px;
    width: 18px;
  }
  .extension-action-button {
    margin: 0 !important;
    height: 34px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    white-space: nowrap !important;
    padding: 6px 8px !important;
    color: ${colors.grey1} !important;
  }
`;

export const AutomatedTasksBadgeWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .automation-tasks-button,
  .automation-tasks-error {
    padding: 6px 12px;
    height: 48px;
    width: 170px;
    border-radius: 34px;
    display: flex;
    text-transform: unset;
    margin-right: 16px;
    color: ${colors.black};

    justify-content: space-between;
  }
  .automation-tasks-button {
    background-color: ${colors.blueLight};
  }
  .automation-tasks-error {
    background-color: ${colors.inactiveLight};

    .error-automation-icon {
      min-height: 34px;
      min-width: 34px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-warning-error {
        width: 24px;
        height: 24px;
      }
    }
  }
  .automation-tasks-error:hover {
    background-color: ${colors.inactiveLightHover};
  }
  .automation-tasks-button:hover {
    background-color: ${colors.blueLightHover};
  }
`;
