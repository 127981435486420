import React from 'react';
import { colors } from '../theme';
import IProps from './interface';

const IconEmailOpened = ({
  color = colors.black,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...style}
    >
      <path
        d="M2 6.46866V12.6667C2 13.4033 2.59667 14 3.33333 14H12.666C13.4027 14 13.9993 13.4033 13.9993 12.6667V6.46866"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.59087 5.36267L7.25354 2.226C7.70487 1.92267 8.29487 1.92267 8.7462 2.226L13.4089 5.36267C14.1942 5.89134 14.1942 7.04667 13.4089 7.57534L9.49287 10.21C8.5902 10.8173 7.4102 10.8173 6.50754 10.21L2.59154 7.57534C1.80554 7.04667 1.80554 5.89067 2.59087 5.36267Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.08022 9.92001L2.39355 13.6067"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6066 13.6067L9.91992 9.92001"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconEmailOpened;
