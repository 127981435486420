import { RadioButtonUnchecked, CheckCircleRounded } from '@mui/icons-material';
import { Grid, Typography, Checkbox, Tooltip } from '@mui/material';

import { Opportunity } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';

import { getColorsStages } from 'utils/fe-utils';
import { colors } from 'crono-fe-common/theme';
import { Stage } from 'crono-fe-common/types/stage';

interface IProps {
  stages: Stage[];
  totalNumberOfStages: number;
  changeStage: (stage: string | null) => void;
  opportunity: Opportunity;
}

const PipelineStages = ({
  stages,
  totalNumberOfStages,
  changeStage,
  opportunity,
}: IProps) => {
  return (
    <Grid container rowSpacing={1} padding={1} columnSpacing={1}>
      <Grid item xs={12}>
        <Typography fontSize={'12px'} color={colors.grey11} fontWeight={500}>
          Change stage
        </Typography>
      </Grid>
      {stages.map((stage, index) => {
        const colorsStage = getColorsStages(
          stage.displayOrder,
          totalNumberOfStages,
        );
        return (
          <Grid
            item
            xs={4}
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              className="status"
              style={{
                width: '180px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '4px',
                padding: '4px 10px',
                paddingLeft: '18px',
                backgroundColor:
                  opportunity.stage === stage.externalName
                    ? colorsStage.dark
                    : colorsStage.light,
              }}
              onClick={() => changeStage(stage.externalName)}
            >
              <Tooltip
                title={stage.publicName}
                placement="top"
                arrow
                enterDelay={800}
                enterNextDelay={800}
              >
                <Typography
                  fontSize={'12px'}
                  fontWeight={
                    opportunity.stage === stage.externalName ? 600 : 500
                  }
                  color={
                    opportunity.stage === stage.externalName
                      ? colors.white
                      : colorsStage.dark
                  }
                  noWrap
                >
                  {stage.publicName}
                </Typography>
              </Tooltip>
              <Checkbox
                icon={<RadioButtonUnchecked sx={{ color: colorsStage.dark }} />}
                checkedIcon={
                  <CheckCircleRounded
                    sx={{ color: 'white', cursor: 'initial' }}
                  />
                }
                checked={stage.externalName === opportunity.stage}
                sx={{ padding: 0 }}
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PipelineStages;
