import styled from '@emotion/styled';

export const OverlayWrapper = styled.div`
  position: relative;

  .loading-state {
    border-radius: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    gap: 12px;
    cursor: initial;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .strategy-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
  }
  .strategy-loader .loader {
    transform: scale3d(0.3, 0.3, 1);
  }
`;
