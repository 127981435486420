import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response, ResponsePaginated } from 'crono-fe-common/types/response';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { TaskSearchOverdue } from 'crono-fe-common/types/DTO/taskSearchOverdue';

export default function useSearchOverdueTasks(
  searchParameters: TaskSearchOverdue,
  enabled = true,
) {
  const searchParametersString = JSON.stringify(searchParameters);
  const request: Request = {
    url: ENDPOINTS.task.overdue,
    config: {
      method: 'post',
      data: { data: searchParameters },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.task.overdue, searchParametersString],

    queryFn: () => client(request),
    enabled,
  };

  return useQuery<ResponsePaginated<TaskTodo[]>, CronoError>(requestConfig);
}
