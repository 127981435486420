import queryString from 'query-string';
import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export default function useDeleteNote() {
  const queryClient = useQueryClient();

  const deleteNote = (NoteId: number) => {
    const queryParams = queryString.stringify({ NoteId });

    const request: Request = {
      url: `${ENDPOINTS.notes.main}?${queryParams}`,
      config: {
        method: 'delete',
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    number
  >(deleteNote, {
    onSettled: () => queryClient.invalidateQueries([ENDPOINTS.notes.main]),
  });

  return { data, mutate, ...rest };
}
