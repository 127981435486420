import { FC, createContext, useContext, useMemo, useState } from 'react';
import { InMailReplyContextType, InMailReplyContent } from './types';
import { CloseButton } from 'crono-fe-common/components/CronoButton';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import InMailModal from './InMailModal';
import { useAuth } from 'context/auth';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';

export const InMailReplyContext = createContext<
  InMailReplyContextType | undefined
>(undefined);

// Provider of the sideTab
const InMailReplyProvider: FC<{ children: any }> = ({ children }) => {
  const [inMailReplyContent, setInMailReplyContent] =
    useState<InMailReplyContent | null>(null);

  const { user } = useAuth();
  const open = useMemo(() => {
    //If there is no user I prevent the window from opening
    if (!user) return undefined;
    return !!inMailReplyContent;
  }, [user, inMailReplyContent]);

  // Removed the windowContent && cause it's implemented by the transitions. Be care of what is passed in the windowContent.windowType,
  // because that's what make everything works
  return (
    <InMailReplyContext.Provider
      value={{ setInMailReplyContent, inMailReplyContent }}
    >
      {open && inMailReplyContent ? (
        <div
          className="email-thread-container"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <FlexDiv gap={'20px'} height={'auto'} justifyContent={'start'}>
            <CloseButton
              size="large"
              onClick={() => setInMailReplyContent(null)}
            >
              <CloseMIcon color={colors.grey11} />
            </CloseButton>
            <h2>InMail reply</h2>
          </FlexDiv>

          <InMailModal prospect={inMailReplyContent.prospect} />
        </div>
      ) : null}
      {children}
    </InMailReplyContext.Provider>
  );
};

export function useInMailReplyTab() {
  const context = useContext(InMailReplyContext);
  if (context === undefined) {
    throw new Error(`useEmailThread must be used within a InMailReplyProvider`);
  }

  return context;
}

export default InMailReplyProvider;
