import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useVerifySalesforceIntegration from 'hooks/services/salesforce/useVerifySalesforceIntegration';
import PATH from 'routing/path';
import { useAuth } from 'context/auth';
import useVerifyHubspotIntegration from 'hooks/services/hubspot/useVerifyHubspotIntegration';
import useVerifyPipedriveIntegration from 'hooks/services/pipedrive/useVerifyPipedriveIntegration';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { needsIntegration } from 'crono-fe-common/types/user';

const useCRMIntegrationGuard = (
  migrationIntegrationType: IntegrationType | null = null,
) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isSuccessCronoIntegration, setIsSuccessCronoIntegration] =
    useState(false);

  const [isLoadingCronoIntegration, setIsLoadingCronoIntegration] =
    useState(true);

  const {
    isLoading: isLoadingSF,
    isError: isErrorSF,
    isSuccess: isSuccessSF,
    verifySalesforceIntegration,
  } = useVerifySalesforceIntegration();

  const {
    isLoading: isLoadingHS,
    isError: isErrorHS,
    isSuccess: isSuccessHS,
    verifyHubspotIntegration,
  } = useVerifyHubspotIntegration();

  const {
    isLoading: isLoadingPD,
    isError: isErrorPD,
    isSuccess: isSuccessPD,
    verifyPipedriveIntegration,
  } = useVerifyPipedriveIntegration();

  useEffect(() => {
    if (isErrorSF || isErrorHS || isErrorPD) {
      if (user?.integrationsOnboarding) {
        navigate(PATH.ONBOARDING);
      } else {
        navigate(PATH.CONNECT);
      }
    }
  }, [isErrorSF, isErrorHS, isErrorPD]);

  useEffect(() => {
    if (!user) return;
    const integrationType =
      migrationIntegrationType ?? user?.company?.integrationType;
    if (integrationType === IntegrationType.SALESFORCE) {
      verifySalesforceIntegration();
    } else if (integrationType === IntegrationType.HUBSPOT) {
      verifyHubspotIntegration();
    } else if (integrationType === IntegrationType.CRONO) {
      setIsSuccessCronoIntegration(true);
    } else if (integrationType === IntegrationType.PIPEDRIVE) {
      verifyPipedriveIntegration();
    }

    setIsLoadingCronoIntegration(false);
  }, [user]);

  const refetch = () => {
    const integrationType =
      migrationIntegrationType ?? user?.company?.integrationType;
    if (integrationType === IntegrationType.SALESFORCE) {
      verifySalesforceIntegration();
    } else if (integrationType === IntegrationType.HUBSPOT) {
      verifyHubspotIntegration();
    } else if (integrationType === IntegrationType.PIPEDRIVE) {
      verifyPipedriveIntegration();
    }
  };

  return {
    isSuccess:
      (isSuccessHS ||
        isSuccessSF ||
        isSuccessCronoIntegration ||
        isSuccessPD) ??
      false,
    isLoading:
      isLoadingSF || isLoadingHS || isLoadingCronoIntegration || isLoadingPD,
    isLoadingHS,
    isErrorHS,
    isSuccessHS,
    isLoadingSF,
    isErrorSF,
    isSuccessSF,
    isLoadingPD,
    isSuccessPD,
    isErrorPD,
    needsIntegration: needsIntegration(user ?? null),
    refetch,
  };
};

export default useCRMIntegrationGuard;
