import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SnippetModalWrapper = styled.div`
  .snippet-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 524px;
    height: 376px;
    padding: 48px 48px 42px 48px;
    background-color: ${colors.white};
    border-radius: 16px;
    overflow: hidden;
    box-sizing: border-box;

    &__name {
      margin: 4px 0 24px;
      height: 24px;
      border: none;
      border-bottom: 1px solid ${colors.grey444};

      &:focus {
        outline: none;
      }
    }

    &__buttons {
      align-self: flex-end;
      display: flex;
      justify-content: space-between;
      margin-top: 42px;
    }

    &__text-area {
      resize: none;
      height: 48px;
    }
  }
`;
