import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TaskInvitationPendingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  background: ${colors.white};
  max-width: 490px;
  min-height: 500px;
  border-radius: 16px;
  align-items: center;
  padding: 16px;
  height: 100%;
  .close-button {
    cursor: pointer;
  }
  .task-invitation-pending-container {
    display: flex;
    flex-direction: column;
    padding-top: 20%;
    row-gap: 24px;
    width: 100%;
    align-items: center;
  }
  .footer-buttons {
    display: flex;
    column-gap: 8px;
    // position: absolute;
    align-items: center;
    bottom: 0;
    padding-bottom: 25%;
    margin-bottom: 20px;
  }
  .divider {
    border-top: 1px solid ${colors.grey44};
    width: auto;
    margin-left: 0px;
  }
  .actions-task-invitation-pending {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }
  .action-button-invitation-pending {
    // border: 1px solid ${colors.grey444};
    width: 40px;
    height: 40px;
  }
  .button-pending-call {
    background: ${colors.callScheduledLight};
    &:hover {
      background: ${colors.callScheduledLightHover};
    }
  }
  .button-pending-email {
    background: ${colors.inactiveLight};
    &:hover {
      background: ${colors.inactiveLightHover};
    }
  }
  .button-pending-inmail {
    background: ${colors.purpleLight};
    &:hover {
      background: ${colors.purpleLightHover};
    }
  }
`;
