import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQueryClient } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';

export interface StagePatch {
  pipelineId: number;
  stageId: number;
  publicName?: string | null;
  displayOrder?: number | null;
  probability?: number | null;
}

export default function usePatchStage() {
  const queryClient = useQueryClient();

  const patchStage = (updateStage: StagePatch) => {
    const request: Request = {
      url: `${ENDPOINTS.pipeline.stage}`,
      config: {
        method: 'patch',
        data: { data: updateStage },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    CronoError,
    StagePatch
  >(patchStage, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.pipeline.main]);
      queryClient.invalidateQueries([ENDPOINTS.pipeline.sync]);
      queryClient.invalidateQueries([ENDPOINTS.opportunity.main]);
      queryClient.invalidateQueries([ENDPOINTS.opportunity.search]);
    },
  });

  return { data, mutate, ...rest };
}
