import React from 'react';
import { Typography } from '@mui/material';
import { AddStepModalWrapper } from './style';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { colors } from 'crono-fe-common/theme';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import { TypeOption } from '../../../pages/taskBoard/sequenceInstance/sequenceInstanceTaskComponent';

import CallIconDark from 'crono-fe-common/icons/Icon-Call-Dark';
import EmailIconDark from 'crono-fe-common/icons/Icon-Email-Dark';
import LinkedinIconMessageDark from 'crono-fe-common/icons/Icon-Linkedin-Message-Dark';
import LinkedinInvitationIconDark from 'crono-fe-common/icons/Icon-Linkedin-Invitation-Dark';
import LinkedinIconInvoiceDark from 'crono-fe-common/icons/Icon-Linkedin-Invoice-Dark';
import LinkedinIconInMailCompassDark from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass-Dark';

interface IProps {
  onClose: () => void;
  onSubmit: (
    type: TaskTodoType,
    subtype: TaskTodoSubtype | null,
  ) => Promise<void>;
}

export const typeOptions: TypeOption[] = [
  {
    type: TaskTodoType.EMAIL,
    subtype: null,
    icon: <EmailIconDark />,
    text: 'Email',
  },
  {
    type: TaskTodoType.CALL,
    subtype: null,
    icon: <CallIconDark />,
    text: 'Call',
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinInvitation,
    text: 'Linkedin Invitation',
    icon: <LinkedinInvitationIconDark />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinMessage,
    text: 'Linkedin Message',
    icon: <LinkedinIconMessageDark />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinVoiceNote,
    text: 'Linkedin Voice Note',
    icon: <LinkedinIconInvoiceDark />,
  },
  {
    type: TaskTodoType.IN_MAIL,
    subtype: null,
    icon: <LinkedinIconInMailCompassDark />,
    text: 'Linkedin InMail',
  },
];

export const AddStepModal = ({ onClose, onSubmit }: IProps) => {
  return (
    <BlurBackgroundDivFixed onClick={onClose}>
      <AddStepModalWrapper onClick={(e) => e.stopPropagation()}>
        <div className="cross">
          <CloseTabButton style={{ paddingRight: '24px' }} close={onClose} />
        </div>

        <Typography
          fontSize={24}
          fontWeight={700}
          lineHeight={'30px'}
          color={colors.black}
        >
          Choose type of task:
        </Typography>

        <div className="cards">
          {typeOptions.map((option, index) => (
            <div
              className="card"
              key={index}
              onClick={async () => {
                await onSubmit(option.type, option.subtype);
                onClose();
              }}
            >
              {option.icon}
              <Typography
                fontSize={16}
                fontWeight={700}
                lineHeight={'20px'}
                color={colors.black}
              >
                {option.text}
              </Typography>
            </div>
          ))}
        </div>
      </AddStepModalWrapper>
    </BlurBackgroundDivFixed>
  );
};
