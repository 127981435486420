import React, { useState } from 'react';
import { Typography, Select, MenuItem, Tooltip } from '@mui/material';

import { colors } from 'crono-fe-common/theme';
import { SequenceStep } from 'crono-fe-common/types/sequenceStep';
import { SequenceStepTemplateWrapper } from './style';

import ReplyThreadIcon from 'crono-fe-common/icons/Icon-Reply-Thread';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';

import { SequenceStepTemplate } from 'crono-fe-common/types/DTO/sequenceStepTemplates';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import SelectedTemplateIcon from 'crono-fe-common/icons/Icon-Selected-Template';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { FeConstants } from 'constants/FeConstants';
import { ReactComponent as StarIcon } from '../../../assets/images/Blue_star.svg';
import { useConfirmModal } from 'context/confirmModal';
import EditableTemplate from '../stepDetailComponent/editableTemplate';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { TemplateCardsContainerTooltip } from 'pages/accountTab/linkedinView';

interface IProps {
  sequenceStepTemplate: SequenceStepTemplate;
  step: SequenceStep;
  handleChange: (type: string, payload: any, index: number) => void;
  index: number;
  currentIndex: number;
  sequenceStepTemplates: SequenceStepTemplate[];
  onSuccessEditTemplate: () => void;
  // Template tooltip
  templateComponent?: JSX.Element | null;
  closeTemplateTooltip?: () => void;
  showTemplateTab?: boolean;
  EmailThreadButton?: any;
  previewOnly?: boolean;
}

const ABTestingTemplates = ({
  sequenceStepTemplate,
  step,
  handleChange,
  index,
  currentIndex,
  sequenceStepTemplates,
  onSuccessEditTemplate,
  templateComponent,
  closeTemplateTooltip,
  showTemplateTab,
  EmailThreadButton,
  previewOnly = false,
}: IProps) => {
  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteSequenceStepTemplate = () => {
    // if (checkEnabledTemplates(step) > 1) {
    openConfirmModal({
      title: 'Are you sure you want to delete this template?',
      text: 'If you delete the attached template, then all statistics will be removed and you will not be able to resume it',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      confirmFunction: () =>
        handleChange(
          'delete',
          {
            templateIndex: currentIndex,
            sequenceStepTemplateId: sequenceStepTemplate.id,
          },
          index,
        ),
      cancelFunction: () => {
        return;
      },
    });
    // }
  };

  const checkEnabledTemplates = (step: SequenceStep) => {
    return step.sequenceStepTemplates!.filter((template) => template.enabled)
      .length;
  };

  const renderAnalytics = (template: SequenceStepTemplate) => {
    const countTotal = template.useCount;
    const openPercentage = Math.round((template.openCount / countTotal) * 100);
    const clickPercentage = Math.round(
      (template.clickCount / countTotal) * 100,
    );
    const replyPercentage = Math.round(
      (template.replyCount / countTotal) * 100,
    );

    return (
      <div className="ab-testing-analytics-container">
        <div
          className={`ab-analytics useCount ${
            !template.enabled ? 'disabled-ab-template' : ''
          }`}
        >
          <Typography fontSize={12} fontWeight={500}>
            {countTotal}
          </Typography>
          <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
            Usage
          </Typography>
        </div>
        {template.type === TemplateType.EMAIL && (
          <>
            <div
              className={`ab-analytics openCount ${
                !template.enabled ? 'disabled-ab-template' : ''
              }`}
            >
              <Typography fontSize={12} fontWeight={500}>
                {countTotal ? openPercentage : '0'} %
              </Typography>
              <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
                Open
              </Typography>
            </div>
            <div
              className={`ab-analytics clickCount ${
                !template.enabled ? 'disabled-ab-template' : ''
              }`}
            >
              <Typography fontSize={12} fontWeight={500}>
                {countTotal ? clickPercentage : '0'} %
              </Typography>
              <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
                Clicks
              </Typography>
            </div>
          </>
        )}
        <div
          className={`ab-analytics replyCount ${
            !template.enabled ? 'disabled-ab-template' : ''
          }`}
        >
          <Typography fontSize={12} fontWeight={500}>
            {countTotal ? replyPercentage : '0'} %
          </Typography>
          <Typography fontSize={12} fontWeight={500} color={colors.grey11}>
            Reply
          </Typography>
        </div>
      </div>
    );
  };

  const assignBadge = (
    abTemplate: SequenceStepTemplate,
    sequenceStepTemplates: SequenceStepTemplate[],
  ) => {
    const orderedSequenceSteps = sequenceStepTemplates.sort((a, b) =>
      a.useCount > b.useCount ? -1 : 1,
    );

    if (
      abTemplate.id === orderedSequenceSteps[0].id &&
      abTemplate.useCount >= 10 &&
      abTemplate.replyCount >= 1
    ) {
      return (
        <div className="best-conversion-container">
          <StarIcon />
          <Typography
            fontSize={'12px'}
            fontWeight={500}
            color={colors.intenseBlue}
          >
            Best conversion
          </Typography>
        </div>
      );
    } else {
      return null;
    }
  };

  const isEmail = sequenceStepTemplate.type === TemplateType.EMAIL;

  const [observerEditMode, setObserverEditMode] = useState(false);

  return (
    <SequenceStepTemplateWrapper>
      <div style={{ padding: '10px 0px 0px 16px' }}>
        <Tooltip
          arrow
          placement="top"
          title={
            !previewOnly &&
            sequenceStepTemplate.enabled &&
            checkEnabledTemplates(step) === 1
              ? 'You must have at least 1 template enabled'
              : null
          }
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              border: `1px solid ${colors.grey444}`,
              borderRadius: '8px',
              paddingRight: '8px',
            }}
          >
            <CronoSwitch
              checked={sequenceStepTemplate.enabled}
              disabled={
                previewOnly ||
                (sequenceStepTemplate.enabled &&
                  checkEnabledTemplates(step) === 1) ||
                observerEditMode
              }
              onChange={() => {
                handleChange(
                  'enabled',
                  {
                    templateIndex: currentIndex,
                    enabled: !sequenceStepTemplate.enabled,
                    sequenceStepTemplateId: sequenceStepTemplate.id,
                  },
                  index,
                );
              }}
            />

            <Typography fontSize={14} fontWeight={500} lineHeight={'18px'}>
              {FeConstants.abTestingOptions.charAt(currentIndex)}:
            </Typography>
          </div>
        </Tooltip>
      </div>

      <FlexDiv
        justifyContent="start"
        alignItems="start"
        direction="column"
        style={{ overflow: 'hidden', padding: '8px' }}
      >
        <div className="ab-testing-header">
          <div className="flex-center" style={{ flex: 1, overflow: 'hidden' }}>
            <div
              className={`flex-center ${
                observerEditMode
                  ? 'disable-due-to-edit'
                  : !sequenceStepTemplate.enabled
                    ? 'disabled-ab-template-title'
                    : 'selected-template'
              }`}
            >
              <TemplateCardsContainerTooltip
                title={templateComponent}
                onClose={closeTemplateTooltip}
                open={showTemplateTab}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <>
                  <span className="flex-center icon-wrapper">
                    <SelectedTemplateIcon
                      className={
                        observerEditMode
                          ? 'step-component-button-disabled'
                          : !sequenceStepTemplate.enabled || previewOnly
                            ? 'ab-testing-step-component-button-disabled'
                            : 'step-component-button'
                      }
                      color={colors.grey11}
                    />
                  </span>

                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    noWrap
                    color={previewOnly ? colors.grey11 : undefined}
                    onClick={() => {
                      if (previewOnly) return;

                      // set some sort of function here
                      handleChange(
                        'template',
                        {
                          templateIndex: currentIndex,
                          sequenceStepTemplateId: sequenceStepTemplate.id,
                        },
                        index,
                      );
                    }}
                  >
                    {sequenceStepTemplate.template?.title}
                  </Typography>
                </>
              </TemplateCardsContainerTooltip>

              {!previewOnly && (
                <span
                  onClick={() => {
                    handleDeleteSequenceStepTemplate();
                  }}
                  style={{
                    cursor: observerEditMode ? 'default' : 'pointer',
                    display: 'flex',
                  }}
                  className="close-template-option"
                >
                  <CloseMIcon color={colors.grey11} />
                </span>
              )}
            </div>

            {observerEditMode && !previewOnly && (
              <div className="badge-observable-mode">
                <EditPencilIconS
                  color={colors.nurtureHover}
                  className="pencil-icon"
                />
                Draft
              </div>
            )}
            <div style={{ flex: 1 }} />
            {assignBadge(sequenceStepTemplate, sequenceStepTemplates)}
          </div>

          {renderAnalytics(sequenceStepTemplate)}
        </div>

        {sequenceStepTemplate.template && (
          <EditableTemplate
            previewOnly={previewOnly}
            emailThreadButton={isEmail ? EmailThreadButton : undefined}
            enableSubjectMutation={!step.replyToThread}
            subject={sequenceStepTemplate.template?.subject}
            stepId={step.id}
            isABTesting={true}
            template={sequenceStepTemplate.template}
            setObserverEditMode={(value) => setObserverEditMode(value)}
            onSuccess={onSuccessEditTemplate}
            uniqueIdentifierOfTemplate={sequenceStepTemplate.id}
          />
        )}
      </FlexDiv>
    </SequenceStepTemplateWrapper>
  );
};

export default ABTestingTemplates;
