import React, { useEffect, useState } from 'react';
import { ISubscriptionStep } from '..';
import { User, getFullName } from 'crono-fe-common/types/user';
import { useFormik } from 'formik';
import { validationSchemaWelcome } from './model';
import Role from 'crono-fe-common/types/enums/role';
import { IUserSubscriptionPage } from 'pages/settings/Subscription/inviteNewUserForm';
import WelcomeStep from './welcomeStep';
import IntegrationsStep from './integrationsStep';
import InviteStep from './inviteStep';
import useCompleteSubscription from 'hooks/services/subscription/useCompleteSubscription';
import CallIntegrationType from 'crono-fe-common/types/enums/callIntegrationType';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { EmailIntegrationType } from 'crono-fe-common/types/emailSettings';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { CompleteSubscriptionDTO } from 'crono-fe-common/types/DTO/completeSubscriptionDTO';

interface IProps {
  user: User | null | undefined;
  step: ISubscriptionStep;
  handleGoNextStep: () => void;
  handleBack: () => void;
}

//This component is like a big form divided in 3 steps, so data could be managed accordingly (disable going forward if
// mandatory information is not provided, etc).
const SubscriptionStep = ({
  user,
  step,
  handleGoNextStep,
  handleBack,
}: IProps) => {
  const { setNewUser } = useAuth();

  const analytics = useJuneAnalytics();

  const [selectedCRM, setSelectedCRM] = useState<IntegrationType | null>(null);
  const [selectedEmail, setSelectedEmail] =
    useState<EmailIntegrationType | null>(null);
  const [aircallActive, setAircallActive] = useState<boolean>(false);

  //Default CRM value depending if the user is a free trial or not
  useEffect(() => {
    if (!user) return;
    if (
      user?.subscriptionType === SubscriptionPlanType.FREE_TRIAL ||
      user?.subscriptionType === SubscriptionPlanType.STARTER
    ) {
      setSelectedCRM(IntegrationType.CRONO);
    } else {
      setSelectedCRM(IntegrationType.HUBSPOT);
    }
  }, [user]);

  const formikWelcome = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      companyWebsite: '',
      companyLinkedinUrl: '',
    },
    validationSchema: validationSchemaWelcome,
    onSubmit: (values) => {
      handleGoNextStep();
    },
  });

  const [users, setUsers] = useState<
    (IUserSubscriptionPage & { id: number; userRoles: Role[] })[]
  >([]);

  const navigate = useNavigate();

  //If the user is not a candidate, redirect to home
  useEffect(() => {
    if (!user?.candidate) {
      navigate(PATH.HOME);
    }
  }, [user]);

  const {
    mutate: completeSubscription,
    isSuccess: subscriptionCompleted,
    error: errorCompletingSubscription,
    isLoading: completingSubscription,
    data: subscriptionData,
  } = useCompleteSubscription();

  useEffect(() => {
    if (subscriptionCompleted) {
      navigate(PATH.ONBOARDING);
    }
  }, [subscriptionCompleted]);

  const handleFinishSubscription = () => {
    const requestObject: CompleteSubscriptionDTO = {
      admin: {
        firstName: formikWelcome.values.firstName,
        lastName: formikWelcome.values.lastName,
        email: user?.email ?? '',
        userRoles: user?.userRoles ?? [Role.ADMIN],
      },
      callIntegrationType: aircallActive ? CallIntegrationType.AIRCALL : null,
      integrationType: selectedCRM ?? IntegrationType.HUBSPOT,
      emailIntegrationType:
        selectedEmail === EmailIntegrationType.GMAIL ||
        selectedEmail === EmailIntegrationType.OUTLOOK ||
        selectedEmail === EmailIntegrationType.CUSTOM
          ? selectedEmail
          : null,
      company: {
        name: formikWelcome.values.companyName,
        website: formikWelcome.values.companyWebsite,
        linkedinUrl:
          formikWelcome.values.companyLinkedinUrl?.split('mycompany/')[0],
      },
      otherUsers: users.map((user) => {
        return {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          userRoles: user.userRoles,
        };
      }),
    };
    completeSubscription(requestObject);
  };

  useEffect(() => {
    const handleNewUserComplete = async () => {
      const user = subscriptionData?.data?.data;
      if (user) {
        //Identify the new user
        if (analytics) {
          await analytics.identify(user.id.toString(), {
            email: user.email,
            name: getFullName(user),
          });
          if (user.company) {
            await analytics.group(user.company.id.toString(), {
              id: user.company.id,
              userId: user.id,
              name: user.company.name,
              plan: user.subscriptionType,
            });
          }
          analytics.track('complete-subscription', {});
        }
        setNewUser(user);
      }
    };
    handleNewUserComplete();
  }, [subscriptionData]);

  useConditionalSnackBar([
    {
      condition: !!subscriptionCompleted,
      message: 'Subscription completed!',
      severity: 'success',
    },
    {
      condition: !!errorCompletingSubscription,
      message:
        getError(errorCompletingSubscription) ??
        'Error completing subscription',
      severity: 'error',
    },
  ]);

  switch (step.name) {
    case 'welcome':
      return <WelcomeStep formikWelcome={formikWelcome as any} user={user} />;
    case 'integrations':
      return (
        <IntegrationsStep
          user={user}
          selectedCRM={selectedCRM}
          setSelectedCRM={setSelectedCRM}
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
          aircallActive={aircallActive}
          setAircallActive={setAircallActive}
          handleBack={handleBack}
          handleGoNextStep={handleGoNextStep}
        />
      );
    case 'invite':
      return (
        <InviteStep
          users={users}
          setUsers={setUsers}
          handleBack={handleBack}
          handleFinishSubscription={handleFinishSubscription}
          completingSubscription={completingSubscription}
        />
      );
  }
};

export default SubscriptionStep;
