import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const SequenceStepTemplateWrapper = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
  width: 100%;
  padding-top: 8px;

  .divider {
    border: 1px solid ${colors.grey4};
    height: 26px;
  }
  .horizontal-divider {
    border-top: 1px solid ${colors.grey4};
    width: 100%;
  }

  .vertical-divider {
    border: 1px solid ${colors.grey4};
    height: 100%;
    margin: 0px;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .best-conversion-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 4px;
    background-color: ${colors.linkedinRepliedMessageLightBlue};
    border-radius: 8px;
  }
  .ab-testing-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .ab-testing-analytics-container {
    display: flex;
    gap: 8px;
    padding-left: 8px;
  }
  .ab-analytics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 8px;
  }
  .useCount {
    color: ${colors.prospectingHover};
    background-color: ${colors.linkedinRepliedMessageLightBlue};
  }
  .openCount {
    color: ${colors.intenseLightBlue};
    background-color: ${colors.primaryLight};
  }
  .clickCount {
    color: ${colors.purpleHover};
    background-color: ${colors.purpleLight};
  }
  .replyCount {
    color: ${colors.lightGreen};
    background-color: ${colors.analyticsGreen};
  }

  .disabled-ab-template {
    color: ${colors.grey11};
    background-color: ${colors.grey6};
  }
  .disabled-ab-template-title {
    color: ${colors.grey11} !important;
  }
  .disabled-ab-template-title:hover {
    color: ${colors.grey11} !important;
  }
  disabled-ab-template-title > span > svg > path {
    fill: ${colors.grey11} !important;
  }
  .disabled-ab-template-title:hover > span > svg > path {
    fill: ${colors.grey11} !important;
  }
  .disabled-ab-template-title > span.close-template-option > svg > path {
    stroke: transparent !important;
  }
  .disabled-ab-template-title:hover > span.close-template-option > svg > path {
    stroke: ${colors.grey11} !important;
  }

  .ab-testing-step-component-button-disabled > path {
    fill: ${colors.grey11} !important;
  }

  .email-thread {
    padding-right: 8px;
  }
  .add-template {
    cursor: pointer;
    width: 100%;
    color: ${colors.grey11};
  }
  .add-template:hover {
    color: ${colors.black};
  }
  .add-template:hover > span > svg > path {
    stroke: ${colors.black};
  }
  .selected-template {
    max-width: 100%;
    cursor: pointer;
    color: ${colors.darkGreen};
  }
  .selected-template:hover {
    color: ${colors.darkGreenHover};
  }
  .selected-template:hover > span > svg > path {
    fill: ${colors.darkGreenHover};
  }

  .selected-template > span.close-template-option > svg > path {
    stroke: transparent;
  }
  .selected-template:hover > span.close-template-option > svg > path {
    stroke: ${colors.grey2};
  }

  .close-template-option-conditional:hover {
    border-radius: 15px;
    background-color: ${colors.grey3};
  }
`;
